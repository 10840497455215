import { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import {
  getTimeFormatWithSecString,
  parseTimeFormatString,
} from "dinii-self-js-lib/time-format-string";
import { groupBy, isNumber, range } from "lodash";
import { dayOfWeeks } from "models/dayOfWeek";
import { exportCsv } from "util/exportCsv";
import { isNotNull } from "util/type/primitive";

import { dayjsToDateString } from "libs/DateString";
import { UnreachableError } from "libs/unreachable";
import { salesAnalyticsBusinessOperationHourTypeLabelMap } from "pages/CompanySalesAnalytics";
import {
  SalesAnalyticsColumnId,
  SalesAnalyticsColumnWithSelectState,
} from "pages/SalesAnalytics/types";
import {
  SalesAnalyticsOutputItem,
  SalesAnalyticsReportingTypeType,
  ShopBusinessOperationHour,
} from "types/graphql";

import {
  useGetSalesAnalyticsLazyQuery,
  useGetSalesAnalyticsQuery,
  useSalesAnalyticsGetMonthlySalesBudgetQuery,
} from "./queries";
import {
  EmptySalesAnalyticsRow,
  MonthlySalesBudget,
  NormalizedSalesAnalyticsRow,
  ReportByType,
  SalesAnalyticsRow,
} from "./types";

const getKeys = <T extends { [key: string]: unknown }>(obj: T): (keyof T)[] => Object.keys(obj);

const defaultFixedDecimalPoint = 3;

const calcPercentage = ({ top, bottom }: { top: number; bottom: number }) =>
  Number((top / bottom).toFixed(defaultFixedDecimalPoint));

const formatNumber = (toFormat: number) => (!isFinite(toFormat) ? "" : String(toFormat));

const formatNameColumn = ({ name, reportByType }: { name: string; reportByType: ReportByType }) => {
  switch (reportByType) {
    case ReportByType.day:
      return dayjs(name).format("YYYY/MM/DD");

    case ReportByType.month:
      return dayjs(name).format("YYYY/MM");

    case ReportByType.businessOperationHourType:
      return salesAnalyticsBusinessOperationHourTypeLabelMap[name] ?? name;

    case ReportByType.dayOfWeek:
      return name;

    case ReportByType.hour:
      return dayjs().hour(Number(name)).format("H:00");

    default:
      throw new UnreachableError(reportByType);
  }
};

const getQueryReportingType = (reportByType: ReportByType): SalesAnalyticsReportingTypeType =>
  reportByType === ReportByType.day
    ? SalesAnalyticsReportingTypeType.Day
    : reportByType === ReportByType.businessOperationHourType
    ? SalesAnalyticsReportingTypeType.BusinessOperationHourType
    : reportByType === ReportByType.dayOfWeek
    ? SalesAnalyticsReportingTypeType.DayOfWeek
    : reportByType === ReportByType.hour
    ? SalesAnalyticsReportingTypeType.Hour
    : SalesAnalyticsReportingTypeType.Month;

const normalizeTaxSettingsToMonthlyAnalytics = ({
  rows,
  showTaxIncluded,
  startDate,
  endDate,
  reportByType,
  businessOperationHourTypes,
  monthlySalesBudgets,
  selectedBusinessOperationHourTypes,
}: {
  rows: SalesAnalyticsOutputItem[];
  showTaxIncluded: boolean;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  reportByType: ReportByType;
  businessOperationHourTypes: Pick<
    ShopBusinessOperationHour,
    "businessOperationHourType" | "start" | "end"
  >[];
  monthlySalesBudgets: MonthlySalesBudget[];
  selectedBusinessOperationHourTypes: string[];
}): SalesAnalyticsRow[] => {
  const rowsWithAmountByTaxSetting = rows.map((row) => ({
    isSummaryRow: false,
    name: row.name,
    shopId: row.shopId,
    shopName: row.shopName,
    businessDaysCount: row.businessDaysCount ?? null,
    numPeople: row.numPeople,
    eatInNumPeople: row.eatInNumPeople,
    takeOutNumPeople: row.takeOutNumPeople,
    groupCount: row.groupCount,
    checkedInGroupCount: row.checkedInGroupCount,
    customerCount: row.customerCount,
    dinnerCustomerCount: row.dinnerCustomerCount,
    lunchCustomerCount: row.lunchCustomerCount,
    repeatVisitCustomerCount: row.repeatVisitCustomerCount,
    newCustomerCount: row.newCustomerCount,
    ambassadorCount: row.ambassadorCount,
    introducedCustomerCount: row.introducedCustomerCount,
    mobileOrderQuantity: row.mobileOrderQuantity,
    nonMobileOrderQuantity: row.nonMobileOrderQuantity,

    salesTargetAmount:
      // NOTE: 営業時間帯ごとの売り上げ目標はないのでnullを返す
      selectedBusinessOperationHourTypes.length > 0
        ? null
        : isNumber(row.salesTargetAmount)
        ? row.salesTargetAmount
        : null,
    totalAmount: showTaxIncluded ? row.totalTaxIncludedAmount : row.totalTaxExcludedAmount,
    totalTakeOutAmount: showTaxIncluded
      ? row.takeOutTotalTaxIncludedAmount
      : row.takeOutTotalTaxExcludedAmount,
    totalEatInAmount: showTaxIncluded
      ? row.eatInTotalTaxIncludedAmount
      : row.eatInTotalTaxExcludedAmount,
    totalCostAmount: showTaxIncluded
      ? row.totalTaxIncludedCostAmount || null
      : row.totalTaxExcludedCostAmount || null,
    grossProfitAmount: showTaxIncluded
      ? row.totalTaxIncludedNetProfitAmount
      : row.totalTaxExcludedNetProfitAmount,
    repeaterTableTotalAmount: showTaxIncluded
      ? row.repeaterTableTotalTaxIncludedAmount
      : row.repeaterTableTotalTaxExcludedAmount,
    previousMonthTotalAmount: showTaxIncluded
      ? row.previousMonthTotalTaxIncludedAmount || null
      : row.previousMonthTotalTaxExcludedAmount || null,
    previousMonthSameDowTotalAmount: showTaxIncluded
      ? row.previousMonthSameDowTotalTaxIncludedAmount || null
      : row.previousMonthSameDowTotalTaxExcludedAmount || null,
    dinnerTotalAmount: showTaxIncluded
      ? row.dinnerTotalTaxIncludedAmount
      : row.dinnerTotalTaxExcludedAmount,
    lunchTotalAmount: showTaxIncluded
      ? row.lunchTotalTaxIncludedAmount
      : row.lunchTotalTaxExcludedAmount,
    faveYellTotalAmount: showTaxIncluded
      ? row.faveYellTotalTaxIncludedAmount
      : row.faveYellTotalTaxExcludedAmount,
    drinkTotalAmount: showTaxIncluded
      ? row.drinkTotalTaxIncludedAmount
      : row.drinkTotalTaxExcludedAmount,
    foodTotalAmount: showTaxIncluded
      ? row.foodTotalTaxIncludedAmount
      : row.foodTotalTaxExcludedAmount,
    otherTotalAmount: showTaxIncluded
      ? row.otherTotalTaxIncludedAmount
      : row.otherTotalTaxExcludedAmount,
    planTotalAmount: showTaxIncluded
      ? row.planTotalTaxIncludedAmount
      : row.planTotalTaxExcludedAmount,
    previousYearTotalAmount: showTaxIncluded
      ? row.previousYearTotalTaxIncludedAmount || null
      : row.previousYearTotalTaxExcludedAmount || null,
    previousYearSameDowTotalAmount: showTaxIncluded
      ? row.previousYearSameDowTotalTaxIncludedAmount || null
      : row.previousYearSameDowTotalTaxExcludedAmount || null,
    takeoutTotalAmount: showTaxIncluded
      ? row.takeOutTotalTaxIncludedAmount
      : row.takeOutTotalTaxExcludedAmount,
  }));

  const firstRow = rowsWithAmountByTaxSetting[0];

  if (!firstRow) return [];

  const summaryRow: typeof firstRow = {
    name:
      reportByType === ReportByType.dayOfWeek || reportByType === ReportByType.hour
        ? "平均"
        : "合計",
    isSummaryRow: true,
    ...(reportByType === ReportByType.dayOfWeek || reportByType === ReportByType.hour
      ? Object.fromEntries(
          getKeys(firstRow)
            .map((key) =>
              key !== "name" && key !== "isSummaryRow" && key !== "shopId" && key !== "shopName"
                ? [
                    key,
                    Math.round(
                      (rowsWithAmountByTaxSetting.reduce<number | null>((acc, current) => {
                        const value = current[key];

                        return acc === null ? value : value === null ? acc : acc + value;
                      }, null) ?? 0) / rowsWithAmountByTaxSetting.length,
                    ),
                  ]
                : null,
            )
            .filter(isNotNull),
        )
      : (Object.fromEntries(
          getKeys(firstRow)
            .map((key) =>
              key !== "name" && key !== "isSummaryRow" && key !== "shopId" && key !== "shopName"
                ? [
                    key,
                    rowsWithAmountByTaxSetting.reduce<number | null>((acc, current) => {
                      const value = current[key];

                      return acc === null ? value : value === null ? acc : acc + value;
                    }, null),
                  ]
                : null,
            )
            .filter(isNotNull),
        ) as Omit<typeof firstRow, "name" | "isSummaryRow">)),
  };

  const normalizedRows: NormalizedSalesAnalyticsRow[] = [
    summaryRow,
    ...rowsWithAmountByTaxSetting,
  ].map((row) => ({
    ...row,
    isEmpty: false,
    name: String(row.name),
    shopId: row.shopId,
    shopName: row.shopName,
    eatInSalesPerCustomer: Math.round(row.totalEatInAmount / row.eatInNumPeople),
    takeOutSalesPerCustomer: Math.round(row.takeoutTotalAmount / row.takeOutNumPeople),
    percentageOfLastYearTotalAmount: row.totalAmount / (row.previousYearTotalAmount ?? 0),
    salesPerCustomerAmount: Math.round(row.totalAmount / row.numPeople),
    previousMonthSameDowPercentage: calcPercentage({
      top: row.totalAmount,
      bottom: row.previousMonthSameDowTotalAmount ?? 0,
    }),
    previousYearSameDowPercentage: calcPercentage({
      top: row.totalAmount,
      bottom: row.previousYearSameDowTotalAmount ?? 0,
    }),
    reservationGroupCount: 0, // TODO: implement
    reservationCustomerCount: 0, // TODO: implement
    reservationTotalAmount: 0, // TODO: implement
    grossProfitPercentage: isNumber(row.totalCostAmount)
      ? calcPercentage({
          top: row.totalAmount - row.totalCostAmount,
          bottom: row.totalAmount,
        })
      : null,
    goalCompletionPercentage: isNumber(row.salesTargetAmount)
      ? calcPercentage({ top: row.totalAmount, bottom: row.salesTargetAmount })
      : null,
    goalDifference: isNumber(row.salesTargetAmount)
      ? row.totalAmount - row.salesTargetAmount
      : null,
    costPercentage: row.totalCostAmount
      ? calcPercentage({ top: row.totalCostAmount, bottom: row.totalAmount })
      : null,
    groupCheckInPercentage: calcPercentage({
      top: row.checkedInGroupCount,
      bottom: row.groupCount,
    }),
    customerCheckInPercentage: calcPercentage({ top: row.customerCount, bottom: row.numPeople }),
    previousYearComparisonPercentage: calcPercentage({
      top: row.totalAmount,
      bottom: row.previousYearTotalAmount ?? 0,
    }),
    salesPerCustomer: Math.round(row.totalAmount / row.numPeople),
    dinnerSalesPerCustomer: Math.round(row.dinnerTotalAmount / row.dinnerCustomerCount),
    lunchSalesPerCustomer: Math.round(row.lunchTotalAmount / row.lunchCustomerCount),
    repeaterPercentage: calcPercentage({
      top: row.repeatVisitCustomerCount,
      bottom: row.numPeople,
    }),
    eatInCustomerCount: row.eatInNumPeople,
    notCheckedInNumPeople: row.numPeople - row.customerCount,
    repeaterSalesPercentage: calcPercentage({
      top: row.repeaterTableTotalAmount,
      bottom: row.totalAmount,
    }),
    mobileOrderPercentage: calcPercentage({
      top: row.mobileOrderQuantity,
      bottom: row.mobileOrderQuantity + row.nonMobileOrderQuantity,
    }),
  }));

  if (reportByType === ReportByType.month) {
    const diffMonth = endDate.diff(startDate, "month");

    const rowsWithEmptyDate = Object.entries(groupBy(normalizedRows, ({ shopId }) => shopId))
      .map(([shopId, shopRows]) =>
        range(diffMonth + 1).map((value) => {
          const month = startDate.add(value, "month").format("YYYY-MM-DD");

          const referenceMonth = shopRows.find(({ name }) => name === month);

          if (referenceMonth) {
            return referenceMonth;
          }

          const referenceMonthlySalesBudget = monthlySalesBudgets.find(
            ({ businessDate }) => businessDate === month,
          );

          const shopName = shopRows[0]?.shopName;

          if (!shopName) return null;

          const emptyRow: EmptySalesAnalyticsRow = {
            name: month,
            isEmpty: true,
            shopId,
            shopName,
            salesTargetAmount: referenceMonthlySalesBudget?.taxExcludedAmount ?? null,
          };

          return emptyRow;
        }),
      )
      .flat()
      .filter(isNotNull);

    const summaryRow = normalizedRows.find((row) => row.isSummaryRow);

    return [...(summaryRow ? [summaryRow] : []), ...rowsWithEmptyDate].sort((a, b) =>
      a.shopName.localeCompare(b.shopName),
    );
  }

  if (reportByType === ReportByType.day) {
    const endOfMonthDate = startDate.endOf("month");
    const diffDate = endOfMonthDate.diff(startDate, "day");

    const rowsWithEmptyDate = Object.entries(groupBy(normalizedRows, ({ shopId }) => shopId))
      .map(([shopId, shopRows]) =>
        range(diffDate + 1).map((value) => {
          const date = startDate.add(value, "day").format("YYYY-MM-DD");

          const referenceDate = shopRows.find(({ name }) => name === date);

          if (referenceDate) {
            return referenceDate;
          }

          const referenceDailySalesBudget = monthlySalesBudgets
            .flatMap(({ dailySalesBudgets }) => dailySalesBudgets)
            .find(({ businessDate }) => businessDate === date);

          const shopName = shopRows[0]?.shopName;

          if (!shopName) return null;

          const emptyRow: EmptySalesAnalyticsRow = {
            name: date,
            shopId,
            shopName,
            isEmpty: true,
            salesTargetAmount: referenceDailySalesBudget?.taxExcludedAmount ?? null,
          };

          return emptyRow;
        }),
      )
      .flat()
      .filter(isNotNull);

    const summaryRow = normalizedRows.find((row) => row.isSummaryRow);

    return [...(summaryRow ? [summaryRow] : []), ...rowsWithEmptyDate].sort((a, b) =>
      a.shopName.localeCompare(b.shopName),
    );
  }

  if (reportByType === ReportByType.businessOperationHourType) {
    return normalizedRows
      .filter((row) => row.totalAmount > 0)
      .map((row) => {
        const referenceType = businessOperationHourTypes.find(
          ({ businessOperationHourType }) => businessOperationHourType === row.name,
        );

        if (!referenceType) return row;

        const { hour: startHour, minute: startMinute } = parseTimeFormatString(
          getTimeFormatWithSecString(referenceType.start),
        );
        const { hour: endHour, minute: endMinute } = parseTimeFormatString(
          getTimeFormatWithSecString(referenceType.end),
        );

        return {
          ...row,
          businessOperationHourLabel: `${startHour}:${startMinute} ~ ${endHour}:${endMinute}`,
        };
      })
      .sort((a, b) => a.shopName.localeCompare(b.shopName));
  }

  if (reportByType === ReportByType.dayOfWeek) {
    const rowsWithEmptyDate = Object.entries(groupBy(normalizedRows, ({ shopId }) => shopId))
      .map(([shopId, shopRows]) =>
        dayOfWeeks.map((dayOfWeek, idx) => {
          const referenceDayOfWeek = shopRows.find(({ name }) => name === dayOfWeek);

          if (referenceDayOfWeek) {
            return referenceDayOfWeek;
          }

          const dayOfWeekSalesBudgets = monthlySalesBudgets
            .flatMap(({ dailySalesBudgets }) => dailySalesBudgets)
            .filter(({ businessDate }) => dayjs(businessDate).day() === idx);

          const avgDayOfWeekSalesTargetAmount = Math.floor(
            (dayOfWeekSalesBudgets.reduce<number | null>((acc, current) => {
              const value = current.taxExcludedAmount;

              return acc === null ? value : value === null ? acc : acc + value;
            }, null) ?? 0) / dayOfWeekSalesBudgets.length,
          );

          const shopName = shopRows[0]?.shopName;

          if (!shopName) return null;

          const emptyRow: EmptySalesAnalyticsRow = {
            name: dayOfWeek,
            isEmpty: true,
            shopId,
            shopName,
            salesTargetAmount: avgDayOfWeekSalesTargetAmount ?? null,
          };

          return emptyRow;
        }),
      )
      .flat()
      .filter(isNotNull);

    const summaryRow = normalizedRows.find((row) => row.isSummaryRow);
    return [...(summaryRow ? [summaryRow] : []), ...rowsWithEmptyDate];
  }

  if (reportByType === ReportByType.hour) {
    const hours = range(0, 24);

    const rowsWithEmptyHour = Object.entries(groupBy(normalizedRows, ({ shopId }) => shopId))
      .map(([shopId, shopRows]) =>
        hours.map((hour) => {
          const referenceHour = shopRows.find(({ name }) => name === String(hour));

          if (referenceHour) {
            return referenceHour;
          }

          const shopName = shopRows[0]?.shopName;

          if (!shopName) return null;

          const emptyRow: EmptySalesAnalyticsRow = {
            name: String(hour),
            isEmpty: true,
            shopId,
            shopName,
            salesTargetAmount: null,
          };

          return emptyRow;
        }),
      )
      .flat()
      .filter(isNotNull);

    const summaryRow = normalizedRows.find((row) => row.isSummaryRow);

    return [...(summaryRow ? [summaryRow] : []), ...rowsWithEmptyHour];
  }
  return normalizedRows;
};

export const useSalesAnalytics = ({
  shopId,
  selectedBusinessOperationHourTypes,
  startDate,
  endDate,
  showTaxIncluded,
  reportByType,
  businessOperationHourTypes,
}: {
  shopId: string | null;
  selectedBusinessOperationHourTypes: string[];
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  showTaxIncluded: boolean;
  reportByType: ReportByType;
  businessOperationHourTypes: Pick<
    ShopBusinessOperationHour,
    "businessOperationHourType" | "start" | "end"
  >[];
}) => {
  const {
    data: salesAnalyticsData,
    loading: loadingSalesAnalytics,
    error,
  } = useGetSalesAnalyticsQuery(
    shopId && startDate && endDate
      ? {
          variables: {
            input: {
              reportingType: getQueryReportingType(reportByType),
              shopIds: [shopId],
              startAt: dayjsToDateString(startDate),
              endAt: dayjsToDateString(endDate),
              businessOperationHourTypes: selectedBusinessOperationHourTypes,
            },
          },
        }
      : { skip: true },
  );

  const [
    getSalesAnalytics,
    { loading: loadingLazySalesAnalytics, error: getLazySalesAnalyticsError },
  ] = useGetSalesAnalyticsLazyQuery();

  const rows = useMemo(() => salesAnalyticsData?.salesAnalytics.rows ?? [], [salesAnalyticsData]);

  const {
    data: monthlySalesBudgetData,
    loading: loadingMonthlySalesBudget,
    error: monthlySalesBudgetError,
  } = useSalesAnalyticsGetMonthlySalesBudgetQuery(
    shopId &&
      startDate &&
      endDate &&
      (reportByType === ReportByType.day || reportByType === ReportByType.month)
      ? {
          variables: {
            shopId,
            startMonth: startDate.format("YYYY-MM-DD"),
            endMonth: endDate.format("YYYY-MM-DD"),
          },
        }
      : { skip: true },
  );

  const monthlySalesBudgets = useMemo(
    () => monthlySalesBudgetData?.monthlySalesBudget ?? [],
    [monthlySalesBudgetData?.monthlySalesBudget],
  );

  const normalizedRows = useMemo(
    () =>
      normalizeTaxSettingsToMonthlyAnalytics({
        showTaxIncluded,
        rows,
        startDate,
        endDate,
        reportByType,
        businessOperationHourTypes,
        monthlySalesBudgets,
        selectedBusinessOperationHourTypes,
      }),
    [
      businessOperationHourTypes,
      endDate,
      monthlySalesBudgets,
      reportByType,
      rows,
      showTaxIncluded,
      startDate,
      selectedBusinessOperationHourTypes,
    ],
  );

  const exportSalesAnalyticsCsv = useCallback(
    async ({
      fileName,
      columnsWithEnabledStatus,
      shopIds,
      reportByType,
    }: {
      fileName: string;
      columnsWithEnabledStatus: SalesAnalyticsColumnWithSelectState[];
      shopIds: string[];
      reportByType: ReportByType;
    }) => {
      if (shopIds.length === 0) return;

      const { data: salesAnalyticsData } = await getSalesAnalytics({
        variables: {
          input: {
            reportingType: getQueryReportingType(reportByType),
            shopIds,
            startAt: dayjsToDateString(startDate),
            endAt: dayjsToDateString(endDate),
            businessOperationHourTypes: selectedBusinessOperationHourTypes,
          },
        },
      });

      if (!salesAnalyticsData) return;

      const rows = salesAnalyticsData.salesAnalytics.rows;

      const normalizedRows = normalizeTaxSettingsToMonthlyAnalytics({
        rows,
        showTaxIncluded,
        startDate,
        endDate,
        reportByType,
        businessOperationHourTypes,
        monthlySalesBudgets,
        selectedBusinessOperationHourTypes,
      });

      const filteredColumns = columnsWithEnabledStatus.filter(({ isEnabled }) => isEnabled);

      const columnsWithShopName: { label: string; columnId: SalesAnalyticsColumnId }[] = [
        { label: "店舗名", columnId: "shopName" },
        ...filteredColumns,
      ];

      exportCsv({
        fileName,
        columnHeaders: columnsWithShopName.map((column) => column.label),
        rows: normalizedRows
          .filter((row) => row.isEmpty || !row.isSummaryRow)
          .map((row) => {
            if (row.isEmpty) {
              return columnsWithShopName.map(({ columnId }) =>
                columnId === "name"
                  ? formatNameColumn({ name: row[columnId], reportByType })
                  : columnId === "shopName"
                  ? row[columnId]
                  : "",
              );
            }

            return columnsWithShopName.map(({ columnId }) => {
              if (row[columnId] === undefined || row[columnId] === null) return "";

              if (columnId === "name") {
                if (row.isSummaryRow) return row[columnId];

                return formatNameColumn({ name: row[columnId], reportByType });
              }

              return isNumber(row[columnId])
                ? formatNumber(Number(row[columnId]))
                : String(row[columnId]);
            });
          }),
      });
    },
    [
      businessOperationHourTypes,
      endDate,
      getSalesAnalytics,
      monthlySalesBudgets,
      showTaxIncluded,
      startDate,
      selectedBusinessOperationHourTypes,
    ],
  );

  return {
    normalizedRows,
    exportSalesAnalyticsCsv,
    isLoading: loadingSalesAnalytics || loadingMonthlySalesBudget,
  };
};

import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  bigint: any;
  date: any;
  jsonb: any;
  numeric: number;
  time: string;
  timestamptz: string;
  timetz: any;
  uuid: string;
};

export type AdyenPaymentCreateStoreMerchantCategoryInput = {
  merchantCategory: Scalars['String'];
  shopId: Scalars['String'];
};

export type AdyenPaymentCreateStoreMerchantCategoryResult = {
  __typename?: 'AdyenPaymentCreateStoreMerchantCategoryResult';
  result: Scalars['Boolean'];
};

export type AdyenPaymentUpdateStoreInput = {
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  city: Scalars['String'];
  id: Scalars['String'];
  postalCode: Scalars['String'];
  shopId: Scalars['String'];
  shopNameEn: Scalars['String'];
  telephoneNumber: Scalars['String'];
};

export type AdyenPaymentUpdateStoreResult = {
  __typename?: 'AdyenPaymentUpdateStoreResult';
  result: Scalars['Boolean'];
};

export type AggregatedCategory = {
  __typename?: 'AggregatedCategory';
  categoryId: Scalars['Int'];
  name: Scalars['String'];
  totalQuantity: Scalars['Int'];
  totalTaxExcludedSales: Scalars['Int'];
  totalTaxExcludedSalesIncludingChoiceSales: Scalars['Int'];
  totalTaxIncludedSales: Scalars['Int'];
  totalTaxIncludedSalesIncludingChoiceSales: Scalars['Int'];
};

export type AggregatedChoice = {
  __typename?: 'AggregatedChoice';
  choiceId: Scalars['Int'];
  name: Scalars['String'];
  totalQuantity: Scalars['Int'];
  totalTaxIncludedSales: Scalars['Int'];
};

export type AggregatedMenu = {
  __typename?: 'AggregatedMenu';
  menuId: Scalars['Int'];
  name: Scalars['String'];
  options: Array<AggregatedOption>;
  totalQuantity: Scalars['Int'];
  totalTaxExcludedSales: Scalars['Int'];
  totalTaxExcludedSalesIncludingChoiceSales: Scalars['Int'];
  totalTaxIncludedSales: Scalars['Int'];
  totalTaxIncludedSalesIncludingChoiceSales: Scalars['Int'];
};

export type AggregatedOption = {
  __typename?: 'AggregatedOption';
  choices: Array<AggregatedChoice>;
  name: Scalars['String'];
  optionId: Scalars['Int'];
};

export type AggregatedPlan = {
  __typename?: 'AggregatedPlan';
  name: Scalars['String'];
  options: Array<AggregatedPlanOption>;
  planId: Scalars['Int'];
  totalQuantity: Scalars['Int'];
  totalTaxExcludedSales: Scalars['Int'];
  totalTaxExcludedSalesIncludingChoiceSales: Scalars['Int'];
  totalTaxIncludedSales: Scalars['Int'];
  totalTaxIncludedSalesIncludingChoiceSales: Scalars['Int'];
};

export type AggregatedPlanOption = {
  __typename?: 'AggregatedPlanOption';
  choices: Array<AggregatedChoice>;
  name: Scalars['String'];
  optionId: Scalars['Int'];
  totalQuantity: Scalars['Int'];
};

export type AggregatedQuestionnaireScore = {
  __typename?: 'AggregatedQuestionnaireScore';
  corporationAverageScore?: Maybe<Scalars['Int']>;
  dimension: Scalars['String'];
  shopAverageScore?: Maybe<Scalars['Int']>;
};

export type AggregatedSales = {
  __typename?: 'AggregatedSales';
  dimension: Scalars['String'];
  taxExcludedAmount: Scalars['Float'];
  taxExcludedCostPrice: Scalars['Float'];
  taxIncludedAmount: Scalars['Float'];
  taxIncludedCostPrice: Scalars['Float'];
};

export type AmbassadorTransition = {
  __typename?: 'AmbassadorTransition';
  ambassadorRate: Scalars['Int'];
  date: Scalars['String'];
  newCustomerViaAmbassadorCount: Scalars['Int'];
};

export type ArchiveCompanyInput = {
  companyId: Scalars['String'];
};

export type ArchiveMenuInput = {
  _menuId: Scalars['Int'];
  companyId: Scalars['String'];
  menuId: Scalars['String'];
};

export type ArchiveMenuResult = {
  __typename?: 'ArchiveMenuResult';
  result: Scalars['Boolean'];
};

export type ArchivePlanInput = {
  companyId: Scalars['String'];
  planId: Scalars['String'];
};

export type ArchivePlanResult = {
  __typename?: 'ArchivePlanResult';
  result: Scalars['Boolean'];
};

export type ArchiveShopInput = {
  shopId: Scalars['String'];
};

export type ArchiveTableInput = {
  shopId: Scalars['String'];
  tableId: Scalars['Int'];
};

export type AverageAndMaxSalesByDayOfWeek = {
  __typename?: 'AverageAndMaxSalesByDayOfWeek';
  averageTaxExcludedSalesAmount: Scalars['Int'];
  dayOfWeek: Scalars['String'];
  maxTaxExcludedSalesAmount: Scalars['Int'];
};

export type AverageAndMaxSalesByDayOfWeekInput = {
  targetMonth: Scalars['DateTime'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CashBalanceCashDenominationCount = {
  __typename?: 'CashBalanceCashDenominationCount';
  cashDenominationCountId: Scalars['String'];
  closeCashRegisterId: Scalars['String'];
  createdAt: Scalars['String'];
  fiftyYenCoinCount: Scalars['Int'];
  fiveHundredYenCoinCount: Scalars['Int'];
  fiveThousandYenBillCount: Scalars['Int'];
  fiveYenCoinCount: Scalars['Int'];
  oneHundredYenCoinCount: Scalars['Int'];
  oneThousandYenBillCount: Scalars['Int'];
  oneYenCoinCount: Scalars['Int'];
  tenThousandYenBillCount: Scalars['Int'];
  tenYenCoinCount: Scalars['Int'];
  twoThousandYenBillCount: Scalars['Int'];
};

export type CashManagement = {
  __typename?: 'CashManagement';
  actualCashAmount: Scalars['Int'];
  bankDepositAmount: Scalars['Int'];
  carriedForwardReserveCashAmount: Scalars['Int'];
  cashAmount: Scalars['Int'];
  cashDiscrepancies: Scalars['Int'];
  cashSales: Scalars['Int'];
  closeCashRegisterId: Scalars['String'];
  createdAt: Scalars['String'];
  depositAmount: Scalars['Int'];
  previousReserveCashAmount: Scalars['Int'];
  remark: Scalars['String'];
  shopId: Scalars['String'];
  withdrawalAmount: Scalars['Int'];
};

export type CompanyCustomerSummary = {
  __typename?: 'CompanyCustomerSummary';
  shopMembers: Array<VisitedLineCustomer>;
  targetDate: Scalars['String'];
  visitedCustomerSegments: VisitedCustomerSegments;
};

export type CompanyCustomerSummaryInput = {
  companyId: Scalars['String'];
};

export type CompanyManagerDeleteDashboardAccountInput = {
  dashboardAccountId: Scalars['String'];
};

export type CompanySalesAnalyticsInput = {
  endAt: Scalars['DateTime'];
  shopIds: Array<Scalars['String']>;
  startAt: Scalars['DateTime'];
};

export type CompanySalesAnalyticsOutput = {
  __typename?: 'CompanySalesAnalyticsOutput';
  rows: Array<CompanySalesAnalyticsOutputItem>;
};

export type CompanySalesAnalyticsOutputItem = {
  __typename?: 'CompanySalesAnalyticsOutputItem';
  ambassadorCount: Scalars['Float'];
  businessDaysCount: Scalars['Float'];
  checkedInGroupCount: Scalars['Float'];
  customerCount: Scalars['Float'];
  dinnerCustomerCount: Scalars['Float'];
  dinnerTotalTaxExcludedAmount: Scalars['Float'];
  dinnerTotalTaxIncludedAmount: Scalars['Float'];
  drinkTotalTaxExcludedAmount: Scalars['Float'];
  drinkTotalTaxIncludedAmount: Scalars['Float'];
  eatInNumPeople: Scalars['Float'];
  eatInTotalTaxExcludedAmount: Scalars['Float'];
  eatInTotalTaxIncludedAmount: Scalars['Float'];
  faveYellTotalTaxExcludedAmount: Scalars['Float'];
  faveYellTotalTaxIncludedAmount: Scalars['Float'];
  firstTimeOrderQuantity: Scalars['Float'];
  foodTotalTaxExcludedAmount: Scalars['Float'];
  foodTotalTaxIncludedAmount: Scalars['Float'];
  groupCount: Scalars['Float'];
  introducedCustomerCount: Scalars['Float'];
  lunchCustomerCount: Scalars['Float'];
  lunchTotalTaxExcludedAmount: Scalars['Float'];
  lunchTotalTaxIncludedAmount: Scalars['Float'];
  mobileOrderQuantity: Scalars['Float'];
  name: Scalars['String'];
  newCustomerCount: Scalars['Float'];
  nonMobileOrderQuantity: Scalars['Float'];
  numPeople: Scalars['Float'];
  orderedQuantity: Scalars['Float'];
  otherTotalTaxExcludedAmount: Scalars['Float'];
  otherTotalTaxIncludedAmount: Scalars['Float'];
  planTotalTaxExcludedAmount: Scalars['Float'];
  planTotalTaxIncludedAmount: Scalars['Float'];
  repeatOrderQuantity: Scalars['Float'];
  repeatVisitCustomerCount: Scalars['Float'];
  repeaterTableTotalTaxExcludedAmount: Scalars['Float'];
  repeaterTableTotalTaxIncludedAmount: Scalars['Float'];
  salesTargetAmount?: Maybe<Scalars['Float']>;
  shopId: Scalars['String'];
  takeOutNumPeople: Scalars['Float'];
  takeOutTotalTaxExcludedAmount: Scalars['Float'];
  takeOutTotalTaxIncludedAmount: Scalars['Float'];
  totalTaxExcludedAmount: Scalars['Float'];
  totalTaxExcludedCostAmount?: Maybe<Scalars['Float']>;
  totalTaxExcludedNetProfitAmount: Scalars['Float'];
  totalTaxIncludedAmount: Scalars['Float'];
  totalTaxIncludedCostAmount?: Maybe<Scalars['Float']>;
  totalTaxIncludedNetProfitAmount: Scalars['Float'];
};

export type CopyDataInput = {
  enableCopyTable: Scalars['Boolean'];
  sourceShopId: Scalars['String'];
  targetShopId: Scalars['String'];
};

export type CountMessageDeliveryTargetsInput = {
  messageDeliveryId: Scalars['String'];
};

export type CouponAnalyticsInput = {
  companyId: Scalars['String'];
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
};

export type CouponAnalyticsPerCoupon = {
  __typename?: 'CouponAnalyticsPerCoupon';
  items: Array<CouponAnalyticsPerCouponItem>;
};

export type CouponAnalyticsPerCouponItem = {
  __typename?: 'CouponAnalyticsPerCouponItem';
  couponId: Scalars['String'];
  couponImageUrl?: Maybe<Scalars['String']>;
  couponName: Scalars['String'];
  couponUsedCount: Scalars['Int'];
  shopUsages: Array<ShopCouponUsage>;
  visitedTableUserCustomersCount: Scalars['Int'];
  visitedTableUserCustomersSales: Scalars['Int'];
};

export type CouponAnalyticsSummary = {
  __typename?: 'CouponAnalyticsSummary';
  couponUsedCount: Scalars['Int'];
  shopUsages: Array<ShopCouponUsage>;
  visitedTableUserCustomersCount: Scalars['Int'];
  visitedTableUserCustomersSales: Scalars['Int'];
};

export type CreateCategoryInputCategorySource = {
  companyId: Scalars['String'];
  createTranslationSource?: InputMaybe<CreateCategoryInputTranslationSource>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  shopSideBackgroundColor: Scalars['String'];
  shopSideName?: InputMaybe<Scalars['String']>;
};

export type CreateCategoryInputTranslationSource = {
  nameSources: Array<CreateCategoryTranslationSource>;
};

export type CreateCategoryOutput = {
  __typename?: 'CreateCategoryOutput';
  categoryId: Scalars['String'];
};

export type CreateCategoryTranslationSource = {
  locale: SupportedLocaleEnumType;
  value: Scalars['String'];
};

export type CreateCompanyInput = {
  companyName: Scalars['String'];
  corporationId: Scalars['String'];
};

export type CreateCorporationInput = {
  name: Scalars['String'];
};

export type CreateDashboardAccountInput = {
  companyIds: Array<Scalars['String']>;
  corporationId: Scalars['String'];
  dashboardAccountRoleId: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  role: Scalars['String'];
  shopIds: Array<Scalars['String']>;
};

export type CreateLineOfficialAccountInput = {
  channelId?: InputMaybe<Scalars['String']>;
  channelSecret?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  lineId: Scalars['String'];
  longLivedChannelAccessToken: Scalars['String'];
};

export type CreateMenuInput = {
  categoryMenuSources: Array<CreateMenuInputCategoryMenu>;
  createMenuSource: CreateMenuInputMenuSource;
  createTranslationsSource?: InputMaybe<CreateMenuInputTranslationSource>;
  sourceMenuId?: InputMaybe<Scalars['Int']>;
};

export type CreateMenuInputCategoryMenu = {
  _categoryId: Scalars['Int'];
  categoryId: Scalars['String'];
  displayType: Scalars['String'];
  price?: InputMaybe<Scalars['Int']>;
  priority: Scalars['Int'];
};

export type CreateMenuInputMenuSource = {
  companyId: Scalars['String'];
  costPrice?: InputMaybe<Scalars['Float']>;
  costTaxRate?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  featuredLabelText?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isTakeOut: Scalars['Boolean'];
  menuOptions: Array<CreateMenuInputMenuSourceOption>;
  menuType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  openPrice?: InputMaybe<Scalars['Boolean']>;
  orderMaxNum?: InputMaybe<Scalars['Int']>;
  orderMaxNumPerTableUser?: InputMaybe<Scalars['Int']>;
  orderableTimeId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  receiptDisplayName: Scalars['String'];
  shopSideName?: InputMaybe<Scalars['String']>;
  shouldMergeSlipOptions?: InputMaybe<Scalars['Boolean']>;
  taxMethod?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<Scalars['Float']>;
  unitMaxOrderNumForNumPeople?: InputMaybe<Scalars['Int']>;
  unitMaxOrderNumPerCustomer?: InputMaybe<Scalars['Int']>;
};

export type CreateMenuInputMenuSourceOption = {
  _optionId: Scalars['Int'];
  priority: Scalars['Int'];
};

export type CreateMenuInputTranslationSource = {
  descriptionSources: Array<CreateTranslationSource>;
  featureLabelSources: Array<CreateTranslationSource>;
  nameSources: Array<CreateTranslationSource>;
};

export type CreateMenuOutput = {
  __typename?: 'CreateMenuOutput';
  menuId: Scalars['Float'];
};

export type CreateMenuRecommendationInputSource = {
  companyId: Scalars['String'];
  createTranslationSource?: InputMaybe<CreateMenuRecommendationInputTranslationSource>;
  name: Scalars['String'];
};

export type CreateMenuRecommendationInputTranslationSource = {
  nameSources: Array<CreateMenuRecommendationTranslationSource>;
};

export type CreateMenuRecommendationOutput = {
  __typename?: 'CreateMenuRecommendationOutput';
  menuRecommendationMetaId: Scalars['String'];
};

export type CreateMenuRecommendationTranslationSource = {
  locale: SupportedLocaleEnumType;
  value: Scalars['String'];
};

export type CreateMessageDeliveryInput = {
  _companyId: Scalars['Int'];
  companyId: Scalars['String'];
  customerList?: InputMaybe<CreateMessageDeliveryInputMessageDeliveryCustomerList>;
  customerSegment?: InputMaybe<CreateMessageDeliveryInputMessageDeliveryCustomerSegment>;
  deliverAt: Scalars['DateTime'];
  isDraft: Scalars['Boolean'];
  isSuspended: Scalars['Boolean'];
  messageDeliveryMessages: Array<CreateMessageDeliveryInputMessageDeliveryMessage>;
  name: Scalars['String'];
  repeatDeliveryTime?: InputMaybe<Scalars['String']>;
  scope: Scalars['String'];
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerList = {
  customerListCustomers: Array<CreateMessageDeliveryInputMessageDeliveryCustomerListCustomer>;
  isFromCsv: Scalars['Boolean'];
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerListCustomer = {
  customerId: Scalars['String'];
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerMenu = {
  menuId: Scalars['String'];
  moreThanOrEqualQuantity: Scalars['Int'];
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerSegment = {
  businessOperationHoursConditions: Array<CreateMessageDeliveryInputMessageDeliveryCustomerSegmentBusinessOperationHour>;
  dayOfWeekConditions: Array<CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDayOfWeek>;
  daysCountUntilBirthdayCondition?: InputMaybe<CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDaysCountUntilBirthday>;
  genderCondition?: InputMaybe<CreateMessageDeliveryInputMessageDeliveryCustomerSegmentGender>;
  menuConditions: Array<CreateMessageDeliveryInputMessageDeliveryCustomerMenu>;
  questionnaireAnswerCondition?: InputMaybe<CreateMessageDeliveryInputMessageDeliveryCustomerSegmentQuestionnaireAnswer>;
  shopConditions: Array<CreateMessageDeliveryInputMessageDeliveryCustomerShop>;
  unvisitedDayCountCondition: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentUnVisitedDayCount;
  visitedDayCountCondition: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentVisitedDayCount;
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerSegmentBusinessOperationHour = {
  businessOperationHourType: BusinessOperationHourTypeEnumType;
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDayOfWeek = {
  messageDeliveryDayOfWeek: MessageDeliveryDayOfWeekTypeType;
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDaysCountUntilBirthday = {
  messageDeliveryCustomerSegmentNumericCondition: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentNumericCondition;
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerSegmentGender = {
  messageDeliveryGender: MessageDeliveryGenderType;
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerSegmentNumericCondition = {
  equal?: InputMaybe<Scalars['Int']>;
  lessThanOrEqual?: InputMaybe<Scalars['Int']>;
  moreThanOrEqual?: InputMaybe<Scalars['Int']>;
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerSegmentQuestionnaireAnswer = {
  messageDeliveryQuestionnaireAnswerStatus: MessageDeliveryQuestionnaireAnswerStatusType;
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerSegmentUnVisitedDayCount = {
  messageDeliveryCustomerSegmentNumericCondition: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentNumericCondition;
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerSegmentVisitedDayCount = {
  messageDeliveryCustomerSegmentNumericCondition: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentNumericCondition;
};

export type CreateMessageDeliveryInputMessageDeliveryCustomerShop = {
  shopId: Scalars['String'];
};

export type CreateMessageDeliveryInputMessageDeliveryMessage = {
  couponTypeMeta?: InputMaybe<CreateMessageDeliveryInputMessageDeliveryMessageCouponTypeMeta>;
  imageTypeMeta?: InputMaybe<CreateMessageDeliveryInputMessageDeliveryMessageImageTypeMeta>;
  position: Scalars['Int'];
  questionnaireTypeMeta?: InputMaybe<CreateMessageDeliveryInputMessageDeliveryMessageQuestionnaireTypeMeta>;
  textTypeMeta?: InputMaybe<CreateMessageDeliveryInputMessageDeliveryMessageTextTypeMeta>;
  type: Scalars['String'];
};

export type CreateMessageDeliveryInputMessageDeliveryMessageCouponTypeMeta = {
  couponId: Scalars['String'];
};

export type CreateMessageDeliveryInputMessageDeliveryMessageImageTypeMeta = {
  imageUrl: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type CreateMessageDeliveryInputMessageDeliveryMessageQuestionnaireTypeMeta = {
  questionnaireConfigId: Scalars['String'];
};

export type CreateMessageDeliveryInputMessageDeliveryMessageTextTypeMeta = {
  text: Scalars['String'];
};

export type CreateOptionInputOptionSource = {
  choices: Array<CreateOptionInputOptionSourceChoice>;
  companyId: Scalars['String'];
  createTranslationSource?: InputMaybe<CreateOptionInputTranslationSource>;
  maxChoiceNum?: InputMaybe<Scalars['Int']>;
  minChoiceNum: Scalars['Int'];
  name: Scalars['String'];
  receiptDisplayName: Scalars['String'];
};

export type CreateOptionInputOptionSourceChoice = {
  costPrice?: InputMaybe<Scalars['Float']>;
  costTaxRate?: InputMaybe<Scalars['Float']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDefaultSelection: Scalars['Boolean'];
  menuType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nameSources: Array<CreateOptionTranslationSource>;
  price: Scalars['Int'];
  priority: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
};

export type CreateOptionInputTranslationSource = {
  nameSources: Array<CreateOptionTranslationSource>;
};

export type CreateOptionOutput = {
  __typename?: 'CreateOptionOutput';
  _optionId: Scalars['Int'];
  optionId: Scalars['String'];
};

export type CreateOptionTranslationSource = {
  locale: SupportedLocaleEnumType;
  value: Scalars['String'];
};

export type CreatePlanChoiceInput = {
  createPlanChoiceSource: CreatePlanChoiceInputPlanChoiceSource;
  createTranslationsSource?: InputMaybe<CreatePlanChoiceInputTranslationSource>;
};

export type CreatePlanChoiceInputPlanChoiceSource = {
  _companyId: Scalars['Int'];
  _planOptionId: Scalars['Int'];
  companyId: Scalars['String'];
  costPrice?: InputMaybe<Scalars['Float']>;
  costTaxRate?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDefaultSelection: Scalars['Boolean'];
  maxOrderableNum?: InputMaybe<Scalars['Int']>;
  menuType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  planOptionId: Scalars['String'];
  price: Scalars['Float'];
  priority: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
};

export type CreatePlanChoiceInputTranslationSource = {
  nameSources: Array<CreatePlanChoiceTranslationSource>;
};

export type CreatePlanChoiceOutput = {
  __typename?: 'CreatePlanChoiceOutput';
  planChoiceId: Scalars['Int'];
};

export type CreatePlanChoiceTranslationSource = {
  locale: SupportedLocaleEnumType;
  value: Scalars['String'];
};

export type CreatePlanInput = {
  createPlanSource: CreatePlanInputPlanSource;
  createTranslationsSource?: InputMaybe<CreatePlanInputTranslationSource>;
  sourcePlanId?: InputMaybe<Scalars['Int']>;
};

export type CreatePlanInputPlanSource = {
  _planGroupId?: InputMaybe<Scalars['Int']>;
  companyId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  displayType?: InputMaybe<Scalars['String']>;
  endNotificationThresholdMinutes?: InputMaybe<Scalars['Int']>;
  featuredLabelText?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  orderableTimeId: Scalars['String'];
  planCategoryId: Scalars['Int'];
  planName: Scalars['String'];
  planTime?: InputMaybe<Scalars['Int']>;
  receiptDisplayName: Scalars['String'];
  shopSideName?: InputMaybe<Scalars['String']>;
  taxMethod?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<Scalars['Float']>;
};

export type CreatePlanInputTranslationSource = {
  descriptionSources: Array<CreatePlanTranslationSource>;
  featureLabelSources: Array<CreatePlanTranslationSource>;
  nameSources: Array<CreatePlanTranslationSource>;
};

export type CreatePlanOptionInput = {
  createPlanOptionSource: CreatePlanOptionInputPlanOptionSource;
  createTranslationsSource?: InputMaybe<CreatePlanOptionInputTranslationSource>;
};

export type CreatePlanOptionInputPlanOptionSource = {
  _companyId: Scalars['Int'];
  _planId: Scalars['Int'];
  companyId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  inputType: Scalars['String'];
  isMainOption: Scalars['Boolean'];
  maxChoiceNum?: InputMaybe<Scalars['Int']>;
  minChoiceNum: Scalars['Int'];
  name: Scalars['String'];
  planId: Scalars['String'];
  priority: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
};

export type CreatePlanOptionInputTranslationSource = {
  nameSources: Array<CreatePlanOptionTranslationSource>;
};

export type CreatePlanOptionOutput = {
  __typename?: 'CreatePlanOptionOutput';
  planOptionId: Scalars['Int'];
};

export type CreatePlanOptionTranslationSource = {
  locale: SupportedLocaleEnumType;
  value: Scalars['String'];
};

export type CreatePlanOutput = {
  __typename?: 'CreatePlanOutput';
  planId: Scalars['Int'];
};

export type CreatePlanTranslationSource = {
  locale: SupportedLocaleEnumType;
  value: Scalars['String'];
};

export type CreateServiceAdminInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type CreateShopInput = {
  companyId: Scalars['String'];
  name: Scalars['String'];
  nameEn: Scalars['String'];
};

export type CreateShopMenusInput = {
  currentStockNum?: InputMaybe<Scalars['Int']>;
  dailyStockNum?: InputMaybe<Scalars['Int']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  menuId: Scalars['String'];
  shopIds: Array<Scalars['String']>;
};

export type CreateShopMenusResult = {
  __typename?: 'CreateShopMenusResult';
  result: Scalars['Boolean'];
};

export type CreateShopPlanInput = {
  planId: Scalars['String'];
  shopId: Scalars['String'];
};

export type CreateShopPlanResult = {
  __typename?: 'CreateShopPlanResult';
  result: Scalars['Boolean'];
};

export type CreateSurchargeConfigsInput = {
  serviceChargeConfig?: InputMaybe<ServiceChargeConfigInput>;
  shopId: Scalars['String'];
  specificTimeSurchargeConfig?: InputMaybe<SpecificTimeSurchargeConfigInput>;
};

export type CreateSurchargeConfigsResult = {
  __typename?: 'CreateSurchargeConfigsResult';
  result: Scalars['Boolean'];
};

export type CreateTranslationSource = {
  locale: SupportedLocaleEnumType;
  value: Scalars['String'];
};

export type CumulativeCount = {
  __typename?: 'CumulativeCount';
  lineIdCount: Scalars['Int'];
  shopId: Scalars['String'];
};

export type CurrentSales = {
  __typename?: 'CurrentSales';
  activeNumberOfGroup: Scalars['Int'];
  activeNumberOfPeople: Scalars['Int'];
  activeNumberOfTableUser: Scalars['Int'];
  activeSales: Scalars['Int'];
  activeUnitSales: Scalars['Int'];
  currentNumberOfGroup: Scalars['Int'];
  currentNumberOfPeople: Scalars['Int'];
  currentSales: Scalars['Int'];
  currentTaxExcludedSales: Scalars['Int'];
  currentUnitSales: Scalars['Int'];
  salesBudgetAmount?: Maybe<Scalars['Int']>;
  shopId: Scalars['String'];
  shopName: Scalars['String'];
  tableCount: Scalars['Int'];
};

export type CurrentSaleses = {
  __typename?: 'CurrentSaleses';
  currentSaleses: Array<CurrentSales>;
};

export type CustomerCountTransition = {
  __typename?: 'CustomerCountTransition';
  date: Scalars['String'];
  newCustomer: Scalars['Int'];
  newCustomerViaAmbassador: Scalars['Int'];
  repeater: Scalars['Int'];
};

export type CustomerDashboardKpi = {
  __typename?: 'CustomerDashboardKpi';
  fluctuatingStatus: FluctuatingStatusType;
  value: Scalars['Int'];
};

export type CustomerDashboardKpis = {
  __typename?: 'CustomerDashboardKpis';
  ambassadorCount: CustomerDashboardKpi;
  checkInRate: CustomerDashboardKpi;
  customerCount: CustomerDashboardKpi;
  faveYellAmount: CustomerDashboardKpi;
  faveYellUserCount: CustomerDashboardKpi;
  groupCount: CustomerDashboardKpi;
  newCustomerViaAmbassadorCount: CustomerDashboardKpi;
  newLineIdCount: CustomerDashboardKpi;
  repeaterCount: CustomerDashboardKpi;
  sales: CustomerDashboardKpi;
  salesPerCustomer: CustomerDashboardKpi;
  shopId: Scalars['String'];
};

export type CustomerDashboardKpisInput = {
  shopId: Scalars['String'];
  targetDate: Scalars['String'];
};

export type CustomerDashboardTransitions = {
  __typename?: 'CustomerDashboardTransitions';
  ambassador: Array<AmbassadorTransition>;
  checkInRate: Array<GeneralTransition>;
  customerCount: Array<CustomerCountTransition>;
  faveYell: Array<FaveYellTransition>;
  newLineIdCount: Array<GeneralTransition>;
  repeater: Array<RepeaterTransition>;
  sales: Array<GeneralTransition>;
  salesPerCustomer: Array<GeneralTransition>;
  shopId: Scalars['String'];
};

export type CustomerFaveYellRanking = {
  __typename?: 'CustomerFaveYellRanking';
  customers: Array<CustomerFaveYellRankingCustomer>;
  shopId: Scalars['String'];
};

export type CustomerFaveYellRankingCustomer = {
  __typename?: 'CustomerFaveYellRankingCustomer';
  customerId: Scalars['String'];
  lastFaveYellCreatedAt: Scalars['String'];
  lineProfileImage: Scalars['String'];
  lineProfileName: Scalars['String'];
  value: Scalars['Int'];
};

export type CustomerRanking = {
  __typename?: 'CustomerRanking';
  customers: Array<CustomerRankingCustomer>;
  shopId: Scalars['String'];
};

export type CustomerRankingCustomer = {
  __typename?: 'CustomerRankingCustomer';
  customerId: Scalars['String'];
  lastVisitedAt: Scalars['String'];
  lineProfileImage: Scalars['String'];
  lineProfileName: Scalars['String'];
  value: Scalars['Int'];
};

export type DailyCashRegisterBalancing = {
  __typename?: 'DailyCashRegisterBalancing';
  shopDailyCashRegisterBalancings: Array<ShopDailyCashRegisterBalancing>;
};

export type DailySalesAmount = {
  __typename?: 'DailySalesAmount';
  businessDate: Scalars['String'];
  taxExcludedSalesAmount: Scalars['Int'];
};

export type DailySalesSummary = {
  __typename?: 'DailySalesSummary';
  closeCashRegisterId: Scalars['String'];
  createdAt: Scalars['String'];
  customerCount: Scalars['Int'];
  groupCount: Scalars['Int'];
  totalSales: Scalars['Int'];
};

export type DashboardAccountCancelOnSitePaymentInput = {
  onSitePaymentId: Scalars['Int'];
};

export type DashboardAccountCreateOrUpdateGmoBankAccountInput = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountType: GmoBankAccountTypeType;
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  depositCycleShopIds: DepositCycleShopIds;
  gmoBankAccountId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type DashboardAccountCreateOrUpdateGmoBankAccountResult = {
  __typename?: 'DashboardAccountCreateOrUpdateGmoBankAccountResult';
  gmoBankAccountId: Scalars['String'];
};

export type DashboardAccountCreateTecAggregationMenuOutput = DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode | DashboardUpsertTecAggregationMenuSuccess;

export type DashboardAccountGmoBankAccountDetailInput = {
  gmoBankAccountId: Scalars['String'];
};

export type DashboardAccountGmoBankAccountDetailResult = {
  __typename?: 'DashboardAccountGmoBankAccountDetailResult';
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountType: GmoBankAccountTypeType;
  bankCode: Scalars['String'];
  branchCode: Scalars['String'];
  gmoBankAccountId: Scalars['String'];
};

export type DashboardAccountGmoShopConfigDetailInput = {
  shopId: Scalars['String'];
};

export type DashboardAccountGmoShopConfigDetailOutput = {
  __typename?: 'DashboardAccountGmoShopConfigDetailOutput';
  amexCardFeeRate?: Maybe<Scalars['Float']>;
  dinersCardFeeRate?: Maybe<Scalars['Float']>;
  gmoShopId?: Maybe<Scalars['String']>;
  gmoShopPassword?: Maybe<Scalars['String']>;
  jcbCardFeeRate?: Maybe<Scalars['Float']>;
  masterCardFeeRate?: Maybe<Scalars['Float']>;
  visaCardFeeRate?: Maybe<Scalars['Float']>;
};

export type DashboardAccountOnSitePaymentDetail = {
  changePrice: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  netPrice: Scalars['Int'];
  onSitePaymentDetailType: Scalars['String'];
  receivedPrice: Scalars['Int'];
};

export type DashboardAccountOnSitePaymentDiscount = {
  amount: Scalars['Int'];
  onSitePaymentDiscountType: Scalars['String'];
};

export type DashboardAccountPayingActivePlanChoice = {
  activePlanChoiceId: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type DashboardAccountPayingActivePlanOpenPriceMeta = {
  activePlanOpenPriceMetaId: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type DashboardAccountPayingMenuOrderItem = {
  menuOrderItemId: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type DashboardAccountUpsertDiniiPayConfigInput = {
  onlinePaymentConfig: DashboardAccountUpsertDiniiPayConfigOnlinePaymentConfig;
  shopId: Scalars['String'];
  terminalPaymentConfig: DashboardAccountUpsertDiniiPayConfigTerminalPaymentConfig;
};

export type DashboardAccountUpsertDiniiPayConfigOnlinePaymentConfig = {
  amexCardFeeRate?: InputMaybe<Scalars['Float']>;
  dinersCardFeeRate?: InputMaybe<Scalars['Float']>;
  gmoShopId?: InputMaybe<Scalars['String']>;
  gmoShopPassword?: InputMaybe<Scalars['String']>;
  jcbCardFeeRate?: InputMaybe<Scalars['Float']>;
  masterCardFeeRate?: InputMaybe<Scalars['Float']>;
  paymentProvider?: InputMaybe<PaymentProviderType>;
  visaCardFeeRate?: InputMaybe<Scalars['Float']>;
};

export type DashboardAccountUpsertDiniiPayConfigOutput = {
  __typename?: 'DashboardAccountUpsertDiniiPayConfigOutput';
  result: Scalars['Boolean'];
};

export type DashboardAccountUpsertDiniiPayConfigTerminalPaymentConfig = {
  amexCardFeeRate?: InputMaybe<Scalars['Float']>;
  dinersCardFeeRate?: InputMaybe<Scalars['Float']>;
  gmoTerminalId?: InputMaybe<Scalars['String']>;
  jcbCardFeeRate?: InputMaybe<Scalars['Float']>;
  masterCardFeeRate?: InputMaybe<Scalars['Float']>;
  useAdyenTerminalPayment: Scalars['Boolean'];
  useGmoTerminalPayment: Scalars['Boolean'];
  visaCardFeeRate?: InputMaybe<Scalars['Float']>;
};

export type DashboardAccountUpsertTecAggregationMenuInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  choiceId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  dpCode: Scalars['String'];
  dpName: Scalars['String'];
  gpCode: Scalars['String'];
  gpName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  menuCode: Scalars['String'];
  menuId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  planChoiceId?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['String']>;
};

export type DashboardAccountUpsertTecAggregationMenusBulkInput = {
  menus: Array<DashboardAccountUpsertTecAggregationMenuInput>;
};

export type DashboardAccountUpsertTecAggregationMenusBulkOutput = DashboardUpsertTecAggregationMenuSuccess | DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode;

export type DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode = {
  __typename?: 'DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode';
  dpCode?: Maybe<Scalars['String']>;
  dpName?: Maybe<Scalars['String']>;
  gpCode?: Maybe<Scalars['String']>;
  gpName?: Maybe<Scalars['String']>;
  menuCode: Scalars['String'];
  name: Scalars['String'];
};

export type DashboardUpsertTecAggregationMenuSuccess = {
  __typename?: 'DashboardUpsertTecAggregationMenuSuccess';
  status: Scalars['String'];
};

export type DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode = {
  __typename?: 'DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode';
  dashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode: Array<DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode>;
};

export type DeleteShopChoicesInput = {
  shopChoiceIds: Array<Scalars['String']>;
};

export type DeleteShopChoicesResult = {
  __typename?: 'DeleteShopChoicesResult';
  result: Scalars['Boolean'];
};

export type DeleteShopMenusInput = {
  shopMenuIds: Array<Scalars['String']>;
};

export type DeleteShopMenusResult = {
  __typename?: 'DeleteShopMenusResult';
  result: Scalars['Boolean'];
};

export type DeleteShopPlanChoicesInput = {
  shopPlanChoiceIds: Array<Scalars['String']>;
};

export type DeleteShopPlanChoicesResult = {
  __typename?: 'DeleteShopPlanChoicesResult';
  result: Scalars['Boolean'];
};

export type DeleteShopPlansInput = {
  shopPlanIds: Array<Scalars['String']>;
};

export type DeleteShopPlansResult = {
  __typename?: 'DeleteShopPlansResult';
  result: Scalars['Boolean'];
};

export type DepositCycleShopIds = {
  sixAMonthShopIds: Array<Scalars['String']>;
  twiceAMonthShopIds: Array<Scalars['String']>;
};

export type DistributionItem = {
  __typename?: 'DistributionItem';
  count: Scalars['Int'];
  label: Scalars['String'];
};

export type EbicaApiTable = {
  __typename?: 'EbicaApiTable';
  id: Scalars['Int'];
  maximumCapacity: Scalars['Int'];
  minimumCapacity: Scalars['Int'];
  name: Scalars['String'];
};

export type EbicaApiTablesInput = {
  shopId: Scalars['String'];
};

export type EnableOnlinePaymentInput = {
  shopId: Scalars['String'];
};

export type EnableOnlinePaymentOutput = {
  __typename?: 'EnableOnlinePaymentOutput';
  result: Scalars['Boolean'];
};

export type FaveYellAnalyticsByCorporationInput = {
  corporationId: Scalars['String'];
  endAt: Scalars['DateTime'];
  shopIds: Array<Scalars['String']>;
  startAt: Scalars['DateTime'];
};

export type FaveYellAnalyticsByCorporationOutput = {
  __typename?: 'FaveYellAnalyticsByCorporationOutput';
  items: Array<FaveYellAnalyticsPerStaffByCorporationItem>;
  summary: Array<FaveYellAnalyticsSummaryByCorporationOutputItem>;
};

export type FaveYellAnalyticsPerStaffByCorporationItem = {
  __typename?: 'FaveYellAnalyticsPerStaffByCorporationItem';
  _menuId: Scalars['Int'];
  companyId: Scalars['String'];
  corporationId: Scalars['String'];
  faveYellCount: Scalars['Int'];
  faveYellCustomerCount: Scalars['Int'];
  faveYellSalesRank: Scalars['Int'];
  shopId: Scalars['String'];
  shopName: Scalars['String'];
  staffImageUrl?: Maybe<Scalars['String']>;
  staffName: Scalars['String'];
  totalTaxIncludedSales: Scalars['Int'];
};

export type FaveYellAnalyticsSummaryByCorporationOutputItem = {
  __typename?: 'FaveYellAnalyticsSummaryByCorporationOutputItem';
  corporationId: Scalars['String'];
  faveYellCount: Scalars['Int'];
  faveYellCustomerCount: Scalars['Int'];
  totalTaxIncludedSales: Scalars['Int'];
};

export type FaveYellTransition = {
  __typename?: 'FaveYellTransition';
  date: Scalars['String'];
  faveYellAmount: Scalars['Int'];
  faveYellUserCount: Scalars['Int'];
};

export enum FluctuatingStatusType {
  Down = 'Down',
  Keep = 'Keep',
  Up = 'Up'
}

export type FoodingJournalUploadSalesInput = {
  shopId: Scalars['String'];
  targetDate: Scalars['String'];
};

export type GeneralTransition = {
  __typename?: 'GeneralTransition';
  date: Scalars['String'];
  value: Scalars['Int'];
};

export enum GmoBankAccountTypeType {
  Checking = 'Checking',
  Current = 'Current',
  Savings = 'Savings'
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type IssueLineReportingBotVerificationCodeInput = {
  corporationId: Scalars['String'];
};

export type IssueLineReportingBotVerificationCodeResponse = {
  __typename?: 'IssueLineReportingBotVerificationCodeResponse';
  expiredAt: Scalars['DateTime'];
  lineReportingBotVerificationId: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type LineOfficialAccountProfile = {
  __typename?: 'LineOfficialAccountProfile';
  channelName: Scalars['String'];
  lineOfficialAccountId: Scalars['Int'];
  pictureUrl?: Maybe<Scalars['String']>;
};

export type LineOfficialAccountSummary = {
  __typename?: 'LineOfficialAccountSummary';
  blocks?: Maybe<Scalars['Int']>;
  channelName: Scalars['String'];
  followers?: Maybe<Scalars['Int']>;
  isInvalidAccount: Scalars['Boolean'];
  lineId: Scalars['String'];
  lineOfficialAccountId: Scalars['Int'];
  pictureUrl?: Maybe<Scalars['String']>;
  quotaLimit?: Maybe<Scalars['Int']>;
  targetedReaches?: Maybe<Scalars['Int']>;
};

export type MenuOrderItemAnalyticsInput = {
  businessOperationHourTypes?: InputMaybe<Array<Scalars['String']>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  companyId: Scalars['String'];
  endAt: Scalars['DateTime'];
  menuTypes?: InputMaybe<Array<Scalars['String']>>;
  shopIds: Array<Scalars['String']>;
  startAt: Scalars['DateTime'];
};

export type MenuOrderItemAnalyticsOutput = {
  __typename?: 'MenuOrderItemAnalyticsOutput';
  rows: Array<MenuOrderItemAnalyticsOutputItem>;
};

export type MenuOrderItemAnalyticsOutputItem = {
  __typename?: 'MenuOrderItemAnalyticsOutputItem';
  businessOperationHourType: Scalars['String'];
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  firstTimeOrderQuantity: Scalars['Int'];
  isRepeaterTable: Scalars['Boolean'];
  menuId: Scalars['String'];
  menuName: Scalars['String'];
  menuType?: Maybe<Scalars['String']>;
  orderedQuantity: Scalars['Int'];
  repeatOrderQuantity: Scalars['Int'];
  totalTaxExcludedAmount: Scalars['Int'];
  totalTaxExcludedCostAmount: Scalars['Int'];
  totalTaxExcludedNetProfitAmount: Scalars['Int'];
  totalTaxIncludedAmount: Scalars['Int'];
  totalTaxIncludedCostAmount: Scalars['Int'];
  totalTaxIncludedNetProfitAmount: Scalars['Int'];
};

export type MessageDeliveryAnalyticsInput = {
  companyId: Scalars['String'];
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
};

export type MessageDeliveryAnalyticsRecentVisitedTableUser = {
  __typename?: 'MessageDeliveryAnalyticsRecentVisitedTableUser';
  customerName: Scalars['String'];
  customerProfileImages: Array<Scalars['String']>;
  numPeople: Scalars['Int'];
  sales: Scalars['Int'];
  visitedAt: Scalars['DateTime'];
  visitedCount: Scalars['Int'];
};

export type MessageDeliveryAnalyticsRecentVisitedTableUsersReport = {
  __typename?: 'MessageDeliveryAnalyticsRecentVisitedTableUsersReport';
  recentVisitedTableUsers: Array<MessageDeliveryAnalyticsRecentVisitedTableUser>;
};

export type MessageDeliveryAnalyticsSummary = {
  __typename?: 'MessageDeliveryAnalyticsSummary';
  messageOpenCount?: Maybe<Scalars['Int']>;
  messageSentCount: Scalars['Int'];
  visitedCustomersCount: Scalars['Int'];
  visitedTableUserCustomersCount: Scalars['Int'];
  visitedTableUserCustomersSales: Scalars['Int'];
};

export type MessageDeliveryAnalyticsSummaryPerMessageDelivery = {
  __typename?: 'MessageDeliveryAnalyticsSummaryPerMessageDelivery';
  items: Array<MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem>;
};

export type MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem = {
  __typename?: 'MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem';
  messageOpenCount?: Maybe<Scalars['Int']>;
  messageSentCount: Scalars['Int'];
  shopName: Scalars['String'];
  visitedCustomersCount: Scalars['Int'];
  visitedTableUserCustomersCount: Scalars['Int'];
  visitedTableUserCustomersSales: Scalars['Int'];
};

export type MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem = {
  __typename?: 'MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem';
  endAt: Scalars['DateTime'];
  items: Array<MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem>;
  lastMessageDeliveryJobId: Scalars['String'];
  messageDeliveryImageUrl?: Maybe<Scalars['String']>;
  messageDeliveryName: Scalars['String'];
  messageOpenCount?: Maybe<Scalars['Int']>;
  messageSentCount: Scalars['Int'];
  startAt: Scalars['DateTime'];
  visitedCustomersCount: Scalars['Int'];
  visitedTableUserCustomersCount: Scalars['Int'];
  visitedTableUserCustomersSales: Scalars['Int'];
};

export type MessageDeliveryAnalyticsSummaryPerShop = {
  __typename?: 'MessageDeliveryAnalyticsSummaryPerShop';
  items: Array<MessageDeliveryAnalyticsSummaryPerShopItem>;
};

export type MessageDeliveryAnalyticsSummaryPerShopItem = {
  __typename?: 'MessageDeliveryAnalyticsSummaryPerShopItem';
  messageOpenCount?: Maybe<Scalars['Int']>;
  messageSentCount: Scalars['Int'];
  shopName: Scalars['String'];
  visitedCustomersCount: Scalars['Int'];
  visitedTableUserCustomersCount: Scalars['Int'];
  visitedTableUserCustomersSales: Scalars['Int'];
};

export type MessageDeliveryConsumption = {
  __typename?: 'MessageDeliveryConsumption';
  messageDeliveryConsumption?: Maybe<Scalars['Int']>;
  messageDeliveryLimit?: Maybe<Scalars['Int']>;
};

export type MonthlySalesAmount = {
  __typename?: 'MonthlySalesAmount';
  businessDate: Scalars['String'];
  taxExcludedSalesAmount: Scalars['Int'];
};

export enum PaymentProviderType {
  Adyen = 'Adyen',
  Gmo = 'Gmo'
}

export type QueryCashBalanceInput = {
  shopId: Scalars['String'];
  targetDateString: Scalars['String'];
};

export type QueryCashManagementInput = {
  shopId: Scalars['String'];
  targetDateString: Scalars['String'];
};

export type QueryCumulativeCountInput = {
  shopId: Scalars['String'];
};

export type QueryCurrentSalesInput = {
  shopIds: Array<Scalars['String']>;
};

export type QueryCustomerRankingInput = {
  limit?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['String'];
};

export type QueryDailyCashRegisterBalancingInput = {
  _companyId: Scalars['Int'];
  targetDateString: Scalars['String'];
};

export type QueryDailySalesSummaryInput = {
  shopId: Scalars['String'];
  targetDate: Scalars['String'];
};

export type QueryLineOfficialAccountInput = {
  companyId: Scalars['String'];
};

export type QueryLineOfficialAccountSummaryInput = {
  _companyId: Scalars['Int'];
};

export type QueryMessageDeliveryConsumptionInput = {
  lineOfficialAccountId: Scalars['Int'];
};

export type QuerySalesBreakdownByPaymentMethodInput = {
  shopId: Scalars['String'];
  targetDateString: Scalars['String'];
};

export type QuerySalesInput = {
  dimension: SalesAggregationDimensionType;
  endDate: Scalars['String'];
  shopId: Scalars['String'];
  startDate: Scalars['String'];
};

export type QueryTransitionsInput = {
  shopId: Scalars['String'];
  targetDate: Scalars['String'];
};

export enum QuestionCategoryType {
  Attribute = 'Attribute',
  Cleanliness = 'Cleanliness',
  CustomerService = 'CustomerService',
  Deliciousness = 'Deliciousness',
  Repeatability = 'Repeatability',
  Speed = 'Speed'
}

export type QuestionnaireAnswererAttributeMetricsDistributionItem = {
  __typename?: 'QuestionnaireAnswererAttributeMetricsDistributionItem';
  count: Scalars['Int'];
  label: Scalars['String'];
};

export type QuestionnaireAnswererAttributeMetricsInput = {
  answererSegments?: InputMaybe<Array<QuestionnaireAnswererSegmentType>>;
  corporationId: Scalars['String'];
  endAt: Scalars['DateTime'];
  questionnaireId: Scalars['String'];
  shopId: Scalars['String'];
  startAt: Scalars['DateTime'];
};

export type QuestionnaireAnswererAttributeMetricsOutput = {
  __typename?: 'QuestionnaireAnswererAttributeMetricsOutput';
  distributionItems: Array<QuestionnaireAnswererAttributeMetricsDistributionItem>;
  title: Scalars['String'];
  totalCount: Scalars['Int'];
};

export enum QuestionnaireAnswererSegmentType {
  NewCustomer = 'NewCustomer',
  RepeatCustomer = 'RepeatCustomer'
}

export type QuestionnaireComment = {
  __typename?: 'QuestionnaireComment';
  age?: Maybe<Scalars['Int']>;
  category: QuestionCategoryType;
  checkedInCount: Scalars['Int'];
  customerQuestionnaireId: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  lineId: Scalars['String'];
  questionnaireAnswerId: Scalars['String'];
  score: Scalars['Int'];
  shopName: Scalars['String'];
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  visitedAt: Scalars['String'];
};

export type QuestionnaireCommentCountOutput = {
  __typename?: 'QuestionnaireCommentCountOutput';
  totalCommentsCount: Scalars['Int'];
};

export type QuestionnaireCommentsInput = {
  answererSegments?: InputMaybe<Array<QuestionnaireAnswererSegmentType>>;
  category?: InputMaybe<QuestionCategoryType>;
  corporationId: Scalars['String'];
  endAt: Scalars['DateTime'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  questionnaireId: Scalars['String'];
  shopIds: Array<Scalars['String']>;
  startAt: Scalars['DateTime'];
};

export type QuestionnaireCorporationAverageScore = {
  __typename?: 'QuestionnaireCorporationAverageScore';
  answerCount: Scalars['Int'];
  cleanliness: Scalars['Int'];
  corporationId: Scalars['String'];
  customerService: Scalars['Int'];
  deliciousness: Scalars['Int'];
  repeatability: Scalars['Int'];
  speed: Scalars['Int'];
};

export type QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics = {
  __typename?: 'QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics';
  distributionItems: Array<DistributionItem>;
  title: Scalars['String'];
};

export type QuestionnaireMetricsPerCategoryInput = {
  answererSegments?: InputMaybe<Array<QuestionnaireAnswererSegmentType>>;
  category: QuestionCategoryType;
  corporationId: Scalars['String'];
  endAt: Scalars['DateTime'];
  questionnaireId: Scalars['String'];
  shopId: Scalars['String'];
  startAt: Scalars['DateTime'];
};

export type QuestionnaireMetricsPerCategoryOutput = {
  __typename?: 'QuestionnaireMetricsPerCategoryOutput';
  questionAnswerMetrics: Array<QuestionnaireMetricsPerCategoryQuestionAnswerMetrics>;
  scoreDistributionMetrics?: Maybe<QuestionnaireMetricsPerCategoryScoreDistributionMetrics>;
  scoreMetrics?: Maybe<QuestionnaireMetricsPerCategoryScoreMetrics>;
};

export type QuestionnaireMetricsPerCategoryQuestionAnswerMetrics = QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics | QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics;

export type QuestionnaireMetricsPerCategoryScoreDistributionMetrics = {
  __typename?: 'QuestionnaireMetricsPerCategoryScoreDistributionMetrics';
  distributionItems: Array<DistributionItem>;
};

export type QuestionnaireMetricsPerCategoryScoreMetrics = {
  __typename?: 'QuestionnaireMetricsPerCategoryScoreMetrics';
  corporationAverageScore: Scalars['Int'];
  shopAverageScore: QuestionnaireScoreWithFluctuation;
};

export type QuestionnaireMetricsPerCategoryTextTypeAnswer = {
  __typename?: 'QuestionnaireMetricsPerCategoryTextTypeAnswer';
  attribute?: Maybe<Scalars['String']>;
  customerQuestionnaireId: Scalars['String'];
  detail: Scalars['String'];
  score?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics = {
  __typename?: 'QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics';
  answers: Array<QuestionnaireMetricsPerCategoryTextTypeAnswer>;
  title: Scalars['String'];
};

export enum QuestionnaireScoreChangesDimensionType {
  Day = 'DAY',
  Month = 'MONTH'
}

export type QuestionnaireScoreChangesMetrics = {
  __typename?: 'QuestionnaireScoreChangesMetrics';
  cleanliness: Array<AggregatedQuestionnaireScore>;
  customerService: Array<AggregatedQuestionnaireScore>;
  deliciousness: Array<AggregatedQuestionnaireScore>;
  repeatability: Array<AggregatedQuestionnaireScore>;
  speed: Array<AggregatedQuestionnaireScore>;
};

export type QuestionnaireScoreChangesMetricsInput = {
  answererSegments?: InputMaybe<Array<QuestionnaireAnswererSegmentType>>;
  corporationId: Scalars['String'];
  dimension: QuestionnaireScoreChangesDimensionType;
  endAt: Scalars['DateTime'];
  questionnaireId: Scalars['String'];
  shopId: Scalars['String'];
  startAt: Scalars['DateTime'];
};

export type QuestionnaireScoreMetricsInput = {
  answererSegments?: InputMaybe<Array<QuestionnaireAnswererSegmentType>>;
  corporationId: Scalars['String'];
  endAt: Scalars['DateTime'];
  questionnaireId: Scalars['String'];
  shopIds?: InputMaybe<Array<Scalars['String']>>;
  startAt: Scalars['DateTime'];
};

export type QuestionnaireScoreMetricsOutput = {
  __typename?: 'QuestionnaireScoreMetricsOutput';
  corporationAverageScore: QuestionnaireCorporationAverageScore;
  shopAverageScores: Array<QuestionnaireShopAverageScore>;
};

export type QuestionnaireScoreWithFluctuation = {
  __typename?: 'QuestionnaireScoreWithFluctuation';
  fluctuation: Scalars['Int'];
  score: Scalars['Int'];
};

export type QuestionnaireShopAverageScore = {
  __typename?: 'QuestionnaireShopAverageScore';
  answerCount: Scalars['Int'];
  cleanliness: QuestionnaireScoreWithFluctuation;
  customerService: QuestionnaireScoreWithFluctuation;
  deliciousness: QuestionnaireScoreWithFluctuation;
  repeatability: QuestionnaireScoreWithFluctuation;
  shopId: Scalars['String'];
  shopName: Scalars['String'];
  speed: QuestionnaireScoreWithFluctuation;
};

export type RepeaterTransition = {
  __typename?: 'RepeaterTransition';
  coreRepeaterRate: Scalars['Int'];
  date: Scalars['String'];
  repeaterRate: Scalars['Int'];
};

export type Sales = {
  __typename?: 'Sales';
  aggregatedSales: Array<AggregatedSales>;
  dimension: SalesAggregationDimensionType;
  endDate: Scalars['String'];
  shopId: Scalars['String'];
  startDate: Scalars['String'];
};

export enum SalesAggregationDimensionType {
  Day = 'DAY',
  DayOfWeek = 'DAY_OF_WEEK',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type SalesAnalyticsInput = {
  businessOperationHourTypes: Array<Scalars['String']>;
  endAt: Scalars['DateTime'];
  reportingType: SalesAnalyticsReportingTypeType;
  shopIds: Array<Scalars['String']>;
  startAt: Scalars['DateTime'];
};

export type SalesAnalyticsOutput = {
  __typename?: 'SalesAnalyticsOutput';
  rows: Array<SalesAnalyticsOutputItem>;
};

export type SalesAnalyticsOutputItem = {
  __typename?: 'SalesAnalyticsOutputItem';
  ambassadorCount: Scalars['Float'];
  businessDaysCount?: Maybe<Scalars['Float']>;
  checkedInGroupCount: Scalars['Float'];
  customerCount: Scalars['Float'];
  dinnerCustomerCount: Scalars['Float'];
  dinnerTotalTaxExcludedAmount: Scalars['Float'];
  dinnerTotalTaxIncludedAmount: Scalars['Float'];
  drinkTotalTaxExcludedAmount: Scalars['Float'];
  drinkTotalTaxIncludedAmount: Scalars['Float'];
  eatInNumPeople: Scalars['Float'];
  eatInTotalTaxExcludedAmount: Scalars['Float'];
  eatInTotalTaxIncludedAmount: Scalars['Float'];
  faveYellTotalTaxExcludedAmount: Scalars['Float'];
  faveYellTotalTaxIncludedAmount: Scalars['Float'];
  firstTimeOrderQuantity: Scalars['Float'];
  foodTotalTaxExcludedAmount: Scalars['Float'];
  foodTotalTaxIncludedAmount: Scalars['Float'];
  groupCount: Scalars['Float'];
  introducedCustomerCount: Scalars['Float'];
  lunchCustomerCount: Scalars['Float'];
  lunchTotalTaxExcludedAmount: Scalars['Float'];
  lunchTotalTaxIncludedAmount: Scalars['Float'];
  mobileOrderQuantity: Scalars['Float'];
  name: Scalars['String'];
  newCustomerCount: Scalars['Float'];
  nonMobileOrderQuantity: Scalars['Float'];
  numPeople: Scalars['Float'];
  orderedQuantity: Scalars['Float'];
  otherTotalTaxExcludedAmount: Scalars['Float'];
  otherTotalTaxIncludedAmount: Scalars['Float'];
  planTotalTaxExcludedAmount: Scalars['Float'];
  planTotalTaxIncludedAmount: Scalars['Float'];
  previousMonthSameDowTotalTaxExcludedAmount?: Maybe<Scalars['Float']>;
  previousMonthSameDowTotalTaxIncludedAmount?: Maybe<Scalars['Float']>;
  previousMonthTotalTaxExcludedAmount?: Maybe<Scalars['Float']>;
  previousMonthTotalTaxIncludedAmount?: Maybe<Scalars['Float']>;
  previousYearSameDowTotalTaxExcludedAmount?: Maybe<Scalars['Float']>;
  previousYearSameDowTotalTaxIncludedAmount?: Maybe<Scalars['Float']>;
  previousYearTotalTaxExcludedAmount?: Maybe<Scalars['Float']>;
  previousYearTotalTaxIncludedAmount?: Maybe<Scalars['Float']>;
  repeatOrderQuantity: Scalars['Float'];
  repeatVisitCustomerCount: Scalars['Float'];
  repeaterTableTotalTaxExcludedAmount: Scalars['Float'];
  repeaterTableTotalTaxIncludedAmount: Scalars['Float'];
  salesTargetAmount?: Maybe<Scalars['Float']>;
  shopId: Scalars['String'];
  shopName: Scalars['String'];
  takeOutNumPeople: Scalars['Float'];
  takeOutTotalTaxExcludedAmount: Scalars['Float'];
  takeOutTotalTaxIncludedAmount: Scalars['Float'];
  totalTaxExcludedAmount: Scalars['Float'];
  totalTaxExcludedCostAmount?: Maybe<Scalars['Float']>;
  totalTaxExcludedNetProfitAmount: Scalars['Float'];
  totalTaxIncludedAmount: Scalars['Float'];
  totalTaxIncludedCostAmount?: Maybe<Scalars['Float']>;
  totalTaxIncludedNetProfitAmount: Scalars['Float'];
};

export enum SalesAnalyticsReportingTypeType {
  BusinessOperationHourType = 'businessOperationHourType',
  Day = 'day',
  DayOfWeek = 'dayOfWeek',
  Hour = 'hour',
  Month = 'month'
}

export type SalesBreakdownByPaymentMethod = {
  __typename?: 'SalesBreakdownByPaymentMethod';
  closeCashRegisterId: Scalars['String'];
  createdAt: Scalars['String'];
  salesBreakdown: Array<SalesWithPaymentMethod>;
  shopId: Scalars['String'];
};

export type SalesByDayOfWeekAndHourAnalyticsInput = {
  endAt: Scalars['DateTime'];
  shopId: Scalars['String'];
  startAt: Scalars['DateTime'];
};

export type SalesByDayOfWeekAndHourAnalyticsOutput = {
  __typename?: 'SalesByDayOfWeekAndHourAnalyticsOutput';
  rows: Array<SalesByDayOfWeekAndHourAnalyticsOutputItem>;
};

export type SalesByDayOfWeekAndHourAnalyticsOutputItem = {
  __typename?: 'SalesByDayOfWeekAndHourAnalyticsOutputItem';
  dayofweek: Scalars['String'];
  hour: Scalars['String'];
  numPeople: Scalars['Float'];
  shopId: Scalars['String'];
  shopName: Scalars['String'];
  totalTaxExcludedAmount: Scalars['Float'];
  totalTaxIncludedAmount: Scalars['Float'];
};

export type SalesByDays = {
  __typename?: 'SalesByDays';
  dailySalesAmount: Array<DailySalesAmount>;
};

export type SalesByDaysInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type SalesByMonths = {
  __typename?: 'SalesByMonths';
  monthlySalesAmount: Array<MonthlySalesAmount>;
};

export type SalesByMonthsInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type SalesForBudget = {
  __typename?: 'SalesForBudget';
  averageAndMaxSalesByDayOfWeek?: Maybe<Array<AverageAndMaxSalesByDayOfWeek>>;
  salesByDays?: Maybe<SalesByDays>;
  salesByMonths?: Maybe<SalesByMonths>;
};

export type SalesForBudgetInput = {
  averageAndMaxSalesByDayOfWeekInput?: InputMaybe<AverageAndMaxSalesByDayOfWeekInput>;
  salesByDaysInput?: InputMaybe<SalesByDaysInput>;
  salesByMonthsInput?: InputMaybe<SalesByMonthsInput>;
  shopId: Scalars['String'];
};

export type SalesWithPaymentMethod = {
  __typename?: 'SalesWithPaymentMethod';
  label: Scalars['String'];
  sales: Scalars['Int'];
  type: Scalars['String'];
};

export type SegmentedCustomer = {
  __typename?: 'SegmentedCustomer';
  count: Scalars['Int'];
  percentage: Scalars['Float'];
};

export type ServiceChargeConfigInput = {
  chargeRate: Scalars['Int'];
};

export type SetCurrentCompanyInput = {
  companyId: Scalars['Int'];
};

export type SetCurrentShopInput = {
  shopId: Scalars['String'];
};

export type ShopApiAggregationGetQuantityAndSalesAggregationInput = {
  categoryIds?: InputMaybe<Array<Scalars['Int']>>;
  endDate: Scalars['String'];
  menuIds?: InputMaybe<Array<Scalars['Int']>>;
  startDate: Scalars['String'];
};

export type ShopApiAggregationGetQuantityAndSalesAggregationInputWithShopId = {
  categoryIds?: InputMaybe<Array<Scalars['Int']>>;
  endDate: Scalars['String'];
  menuIds?: InputMaybe<Array<Scalars['Int']>>;
  shopId: Scalars['String'];
  startDate: Scalars['String'];
};

export type ShopApiAggregationGetQuantityAndSalesAggregationOutput = {
  __typename?: 'ShopApiAggregationGetQuantityAndSalesAggregationOutput';
  aggregatedMenus: Array<AggregatedMenu>;
  aggregatedPlans: Array<AggregatedPlan>;
};

export type ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutput = {
  __typename?: 'ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutput';
  aggregatedCategories: Array<AggregatedCategory>;
};

export type ShopCouponUsage = {
  __typename?: 'ShopCouponUsage';
  couponUsedCount: Scalars['Int'];
  shopId: Scalars['String'];
  shopName: Scalars['String'];
  visitedTableUserCustomersCount: Scalars['Int'];
  visitedTableUserCustomersSales: Scalars['Int'];
};

export type ShopDailyCashRegisterBalancing = {
  __typename?: 'ShopDailyCashRegisterBalancing';
  cashDiscrepancies?: Maybe<Scalars['Int']>;
  closeCashRegisterId: Scalars['String'];
  createdAt: Scalars['String'];
  depositAmount?: Maybe<Scalars['Int']>;
  shopId: Scalars['String'];
  totalSales?: Maybe<Scalars['Int']>;
};

export type SpecificTimeSurchargeConfigInput = {
  chargeRate: Scalars['Int'];
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export enum SupportedLocaleEnumType {
  EnUs = 'EN_US',
  KoKr = 'KO_KR',
  ZhCn = 'ZH_CN'
}

export type UpdateBankDepositAmountInput = {
  bankDepositAmount: Scalars['Int'];
  closeCashRegisterId: Scalars['String'];
};

export type UpdateCategoryMenuBulkInput = {
  categoryMenuId: Scalars['Int'];
  menu: UpdateMenuBulkInput;
  price?: InputMaybe<Scalars['Int']>;
};

export type UpdateCategoryMenusBulkInput = {
  categoryMenus: Array<UpdateCategoryMenuBulkInput>;
};

export type UpdateDashboardAccountInput = {
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  dashboardAccountId: Scalars['String'];
  dashboardAccountRoleId: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
  shopIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateLineOfficialAccountInput = {
  _companyId: Scalars['Int'];
  channelId?: InputMaybe<Scalars['String']>;
  channelSecret?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  id: Scalars['String'];
  lineId: Scalars['String'];
  lineOfficialAccountId: Scalars['Int'];
  longLivedChannelAccessToken: Scalars['String'];
};

export type UpdateMenuBulkInput = {
  costPrice?: InputMaybe<Scalars['Float']>;
  costTaxRate?: InputMaybe<Scalars['Float']>;
  displayType?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  taxMethod?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<Scalars['Float']>;
};

export type UpdateMessageDeliveryInput = {
  _companyId: Scalars['Int'];
  companyId: Scalars['String'];
  customerList?: InputMaybe<CreateMessageDeliveryInputMessageDeliveryCustomerList>;
  customerSegment?: InputMaybe<CreateMessageDeliveryInputMessageDeliveryCustomerSegment>;
  deliverAt: Scalars['DateTime'];
  id: Scalars['String'];
  isDraft: Scalars['Boolean'];
  isSuspended: Scalars['Boolean'];
  messageDeliveryMessages: Array<CreateMessageDeliveryInputMessageDeliveryMessage>;
  name: Scalars['String'];
  repeatDeliveryTime?: InputMaybe<Scalars['String']>;
  scope: Scalars['String'];
};

export type UpdateOnSitePaymentInput = {
  _clerkId: Scalars['Int'];
  _onSitePaymentId: Scalars['Int'];
  clerkId: Scalars['String'];
  newOnSitePaymentDetails: Array<DashboardAccountOnSitePaymentDetail>;
  newOnSitePaymentDiscounts: Array<DashboardAccountOnSitePaymentDiscount>;
  newPayingActivePlanChoices: Array<DashboardAccountPayingActivePlanChoice>;
  newPayingActivePlanOpenPriceMetas: Array<DashboardAccountPayingActivePlanOpenPriceMeta>;
  newPayingMenuOrderItems: Array<DashboardAccountPayingMenuOrderItem>;
  onSitePaymentId: Scalars['String'];
  shopId: Scalars['String'];
};

export type UpdateShopMenusBulkInput = {
  choiceIds: Array<Scalars['String']>;
  currentStockNum?: InputMaybe<Scalars['Int']>;
  dailyStockNum?: InputMaybe<Scalars['Int']>;
  isDealing?: InputMaybe<Scalars['Boolean']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  menuIds: Array<Scalars['String']>;
  shopIds: Array<Scalars['String']>;
};

export type UpdateShopMenusBulkResult = {
  __typename?: 'UpdateShopMenusBulkResult';
  result: Scalars['Boolean'];
};

export type UpdateShopMenusInput = {
  choices: Array<UpdateShopMenusInputChoice>;
  menus: Array<UpdateShopMenusInputMenu>;
  shopId: Scalars['String'];
};

export type UpdateShopMenusInputChoice = {
  _choiceId: Scalars['Int'];
  choiceId: Scalars['String'];
  currentStockNum?: InputMaybe<Scalars['Float']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateShopMenusInputMenu = {
  _menuId: Scalars['Int'];
  currentStockNum?: InputMaybe<Scalars['Int']>;
  dailyStockNum?: InputMaybe<Scalars['Int']>;
  dishUpSlipGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  kdDisplayTargetIds?: InputMaybe<Array<Scalars['String']>>;
  kitchenRoleIds?: InputMaybe<Array<Scalars['Int']>>;
  menuId: Scalars['String'];
};

export type UpdateShopPlansBulkInput = {
  isDealing?: InputMaybe<Scalars['Boolean']>;
  isSoldOut?: InputMaybe<Scalars['Boolean']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  planChoiceIds: Array<Scalars['String']>;
  planIds: Array<Scalars['String']>;
  shopIds: Array<Scalars['String']>;
};

export type UpdateShopPlansBulkResult = {
  __typename?: 'UpdateShopPlansBulkResult';
  result: Scalars['Boolean'];
};

export type UpdateTargetCountOutput = {
  __typename?: 'UpdateTargetCountOutput';
  targetCount: Scalars['Int'];
};

export type UpsertShopChoicesInput = {
  choiceId: Scalars['String'];
  currentStockNum?: InputMaybe<Scalars['Int']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  shopIds: Array<Scalars['String']>;
};

export type UpsertShopChoicesResult = {
  __typename?: 'UpsertShopChoicesResult';
  result: Scalars['Boolean'];
};

export type UpsertShopPlanChoiceInput = {
  isSoldOut?: InputMaybe<Scalars['Boolean']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  planChoiceId: Scalars['String'];
  shopId: Scalars['String'];
};

export type UpsertShopPlanChoiceResult = {
  __typename?: 'UpsertShopPlanChoiceResult';
  result: Scalars['Boolean'];
};

export type UpsertTranslationsForCategoryInput = {
  categoryId: Scalars['String'];
  companyId: Scalars['String'];
  nameSources: Array<UpsertTranslationsSource>;
};

export type UpsertTranslationsForChoiceInput = {
  choiceId: Scalars['String'];
  nameSources: Array<UpsertTranslationsSource>;
};

export type UpsertTranslationsForMenuInput = {
  companyId: Scalars['String'];
  descriptionSources?: InputMaybe<Array<UpsertTranslationsSource>>;
  featureLabelSources?: InputMaybe<Array<UpsertTranslationsSource>>;
  menuId: Scalars['String'];
  nameSources: Array<UpsertTranslationsSource>;
};

export type UpsertTranslationsForMenuRecommendationMetaInput = {
  companyId: Scalars['String'];
  menuRecommendationMetaId: Scalars['String'];
  nameSources: Array<UpsertTranslationsSource>;
};

export type UpsertTranslationsForOptionInput = {
  choiceSources?: InputMaybe<Array<UpsertTranslationsForChoiceInput>>;
  companyId: Scalars['String'];
  nameSources: Array<UpsertTranslationsSource>;
  optionId: Scalars['String'];
};

export type UpsertTranslationsForPlanChoiceInput = {
  companyId: Scalars['String'];
  nameSources: Array<UpsertTranslationsSource>;
  planChoiceId: Scalars['String'];
};

export type UpsertTranslationsForPlanInput = {
  companyId: Scalars['String'];
  descriptionSources?: InputMaybe<Array<UpsertTranslationsSource>>;
  featureLabelSources?: InputMaybe<Array<UpsertTranslationsSource>>;
  planId: Scalars['String'];
  planNameSources?: InputMaybe<Array<UpsertTranslationsSource>>;
};

export type UpsertTranslationsForPlanOptionInput = {
  companyId: Scalars['String'];
  nameSources: Array<UpsertTranslationsSource>;
  planOptionId: Scalars['String'];
};

export type UpsertTranslationsSource = {
  locale: SupportedLocaleEnumType;
  value: Scalars['String'];
};

export type ValidLineOfficialAccount = {
  __typename?: 'ValidLineOfficialAccount';
  id: Scalars['String'];
  lineId: Scalars['String'];
  lineOfficialAccountId: Scalars['Int'];
};

export type ValidLineOfficialAccountInput = {
  companyId: Scalars['String'];
};

export type VisitedCustomerSegments = {
  __typename?: 'VisitedCustomerSegments';
  first: SegmentedCustomer;
  nonMember: SegmentedCustomer;
  regular: SegmentedCustomer;
  repeater: SegmentedCustomer;
};

export type VisitedLineCustomer = {
  __typename?: 'VisitedLineCustomer';
  id: Scalars['String'];
  name: Scalars['String'];
  profileImage: Scalars['String'];
  visitedCount: Scalars['Float'];
};

export enum AccountingActionTypeEnum {
  CallClerk = 'callClerk',
  PayAtCashRegister = 'payAtCashRegister',
  WaitAtTable = 'waitAtTable'
}

/** Boolean expression to compare columns of type "accountingActionType_enum". All fields are combined with logical 'AND'. */
export type AccountingActionTypeEnumComparisonExp = {
  _eq?: InputMaybe<AccountingActionTypeEnum>;
  _in?: InputMaybe<Array<AccountingActionTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AccountingActionTypeEnum>;
  _nin?: InputMaybe<Array<AccountingActionTypeEnum>>;
};

/** columns and relationships of "accountingSlipImage" */
export type AccountingSlipImage = {
  __typename?: 'accountingSlipImage';
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  imageUrl: Scalars['String'];
  position: AccountingSlipImagePositionEnum;
  priority: Scalars['Int'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

export enum AccountingSlipImagePositionEnum {
  Footer = 'footer',
  Header = 'header'
}

/**
 * Boolean expression to compare columns of type
 * "accountingSlipImagePosition_enum". All fields are combined with logical 'AND'.
 */
export type AccountingSlipImagePositionEnumComparisonExp = {
  _eq?: InputMaybe<AccountingSlipImagePositionEnum>;
  _in?: InputMaybe<Array<AccountingSlipImagePositionEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AccountingSlipImagePositionEnum>;
  _nin?: InputMaybe<Array<AccountingSlipImagePositionEnum>>;
};

/** order by aggregate values of table "accountingSlipImage" */
export type AccountingSlipImageAggregateOrderBy = {
  avg?: InputMaybe<AccountingSlipImageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccountingSlipImageMaxOrderBy>;
  min?: InputMaybe<AccountingSlipImageMinOrderBy>;
  stddev?: InputMaybe<AccountingSlipImageStddevOrderBy>;
  stddev_pop?: InputMaybe<AccountingSlipImageStddevPopOrderBy>;
  stddev_samp?: InputMaybe<AccountingSlipImageStddevSampOrderBy>;
  sum?: InputMaybe<AccountingSlipImageSumOrderBy>;
  var_pop?: InputMaybe<AccountingSlipImageVarPopOrderBy>;
  var_samp?: InputMaybe<AccountingSlipImageVarSampOrderBy>;
  variance?: InputMaybe<AccountingSlipImageVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "accountingSlipImage" */
export type AccountingSlipImageArrRelInsertInput = {
  data: Array<AccountingSlipImageInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AccountingSlipImageOnConflict>;
};

/** order by avg() on columns of table "accountingSlipImage" */
export type AccountingSlipImageAvgOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "accountingSlipImage". All fields are combined with a logical 'AND'. */
export type AccountingSlipImageBoolExp = {
  _and?: InputMaybe<Array<AccountingSlipImageBoolExp>>;
  _not?: InputMaybe<AccountingSlipImageBoolExp>;
  _or?: InputMaybe<Array<AccountingSlipImageBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  position?: InputMaybe<AccountingSlipImagePositionEnumComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "accountingSlipImage" */
export enum AccountingSlipImageConstraint {
  /** unique or primary key constraint on columns "id" */
  AccountingSlipImagePkey = 'accountingSlipImage_pkey'
}

/** input type for incrementing numeric columns in table "accountingSlipImage" */
export type AccountingSlipImageIncInput = {
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "accountingSlipImage" */
export type AccountingSlipImageInsertInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl: Scalars['String'];
  position: AccountingSlipImagePositionEnum;
  priority?: InputMaybe<Scalars['Int']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "accountingSlipImage" */
export type AccountingSlipImageMaxOrderBy = {
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "accountingSlipImage" */
export type AccountingSlipImageMinOrderBy = {
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "accountingSlipImage" */
export type AccountingSlipImageMutationResponse = {
  __typename?: 'accountingSlipImage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountingSlipImage>;
};

/** on_conflict condition type for table "accountingSlipImage" */
export type AccountingSlipImageOnConflict = {
  constraint: AccountingSlipImageConstraint;
  update_columns?: Array<AccountingSlipImageUpdateColumn>;
  where?: InputMaybe<AccountingSlipImageBoolExp>;
};

/** Ordering options when selecting data from "accountingSlipImage". */
export type AccountingSlipImageOrderBy = {
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: accountingSlipImage */
export type AccountingSlipImagePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "accountingSlipImage" */
export enum AccountingSlipImageSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  Position = 'position',
  /** column name */
  Priority = 'priority',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "accountingSlipImage" */
export type AccountingSlipImageSetInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<AccountingSlipImagePositionEnum>;
  priority?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "accountingSlipImage" */
export type AccountingSlipImageStddevOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "accountingSlipImage" */
export type AccountingSlipImageStddevPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "accountingSlipImage" */
export type AccountingSlipImageStddevSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "accountingSlipImage" */
export type AccountingSlipImageStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AccountingSlipImageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type AccountingSlipImageStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<AccountingSlipImagePositionEnum>;
  priority?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "accountingSlipImage" */
export type AccountingSlipImageSumOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** update columns of table "accountingSlipImage" */
export enum AccountingSlipImageUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  Position = 'position',
  /** column name */
  Priority = 'priority',
  /** column name */
  ShopId = 'shopId'
}

export type AccountingSlipImageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AccountingSlipImageIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccountingSlipImageSetInput>;
  /** filter the rows which have to be updated */
  where: AccountingSlipImageBoolExp;
};

/** order by var_pop() on columns of table "accountingSlipImage" */
export type AccountingSlipImageVarPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "accountingSlipImage" */
export type AccountingSlipImageVarSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "accountingSlipImage" */
export type AccountingSlipImageVarianceOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** columns and relationships of "activePlan" */
export type ActivePlan = {
  __typename?: 'activePlan';
  _planId: Scalars['Int'];
  /** An object relationship */
  activatedClerk?: Maybe<Clerk>;
  /** An object relationship */
  activatedCustomer?: Maybe<Customer>;
  /** A computed field, executes function "activePlan_activePlanId" */
  activePlanId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  activePlanOpenPriceMeta?: Maybe<ActivePlanOpenPriceMeta>;
  activePlanOpenPriceMetaId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  activePlanOpenPriceMetum?: Maybe<ActivePlanOpenPriceMeta>;
  /** An array relationship */
  activePlanOptions: Array<ActivePlanOption>;
  canceledAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  endAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  isPaid: Scalars['Boolean'];
  name: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
  /** An object relationship */
  plan?: Maybe<Plan>;
  planCategoryId: Scalars['Int'];
  planId?: Maybe<Scalars['uuid']>;
  receiptDisplayName: Scalars['String'];
  remark: Scalars['String'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  startAt: Scalars['timestamptz'];
  /** An object relationship */
  tableUser?: Maybe<TableUser>;
  tableUserId: Scalars['uuid'];
  /** An array relationship */
  tableUsersInGroup: Array<TableUser>;
};


/** columns and relationships of "activePlan" */
export type ActivePlanActivePlanOptionsArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanOptionOrderBy>>;
  where?: InputMaybe<ActivePlanOptionBoolExp>;
};


/** columns and relationships of "activePlan" */
export type ActivePlanTableUsersInGroupArgs = {
  distinct_on?: InputMaybe<Array<TableUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserOrderBy>>;
  where?: InputMaybe<TableUserBoolExp>;
};

/** columns and relationships of "activePlanChoice" */
export type ActivePlanChoice = {
  __typename?: 'activePlanChoice';
  _choiceId: Scalars['Int'];
  activePlanChoiceId: Scalars['uuid'];
  /** An object relationship */
  activePlanOption: ActivePlanOption;
  activePlanOptionId: Scalars['uuid'];
  choiceId?: Maybe<Scalars['uuid']>;
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  createdAt: Scalars['timestamptz'];
  name: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
  paidNum: Scalars['Int'];
  /** An array relationship */
  payingActivePlanChoices: Array<PayingActivePlanChoice>;
  /** An object relationship */
  planChoice?: Maybe<PlanChoice>;
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
  taxMethod: Scalars['String'];
  taxRate: Scalars['numeric'];
};


/** columns and relationships of "activePlanChoice" */
export type ActivePlanChoicePayingActivePlanChoicesArgs = {
  distinct_on?: InputMaybe<Array<PayingActivePlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingActivePlanChoiceOrderBy>>;
  where?: InputMaybe<PayingActivePlanChoiceBoolExp>;
};

/** order by aggregate values of table "activePlanChoice" */
export type ActivePlanChoiceAggregateOrderBy = {
  avg?: InputMaybe<ActivePlanChoiceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ActivePlanChoiceMaxOrderBy>;
  min?: InputMaybe<ActivePlanChoiceMinOrderBy>;
  stddev?: InputMaybe<ActivePlanChoiceStddevOrderBy>;
  stddev_pop?: InputMaybe<ActivePlanChoiceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ActivePlanChoiceStddevSampOrderBy>;
  sum?: InputMaybe<ActivePlanChoiceSumOrderBy>;
  var_pop?: InputMaybe<ActivePlanChoiceVarPopOrderBy>;
  var_samp?: InputMaybe<ActivePlanChoiceVarSampOrderBy>;
  variance?: InputMaybe<ActivePlanChoiceVarianceOrderBy>;
};

/** order by avg() on columns of table "activePlanChoice" */
export type ActivePlanChoiceAvgOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "activePlanChoice". All fields are combined with a logical 'AND'. */
export type ActivePlanChoiceBoolExp = {
  _and?: InputMaybe<Array<ActivePlanChoiceBoolExp>>;
  _choiceId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<ActivePlanChoiceBoolExp>;
  _or?: InputMaybe<Array<ActivePlanChoiceBoolExp>>;
  activePlanChoiceId?: InputMaybe<UuidComparisonExp>;
  activePlanOption?: InputMaybe<ActivePlanOptionBoolExp>;
  activePlanOptionId?: InputMaybe<UuidComparisonExp>;
  choiceId?: InputMaybe<UuidComparisonExp>;
  costPrice?: InputMaybe<NumericComparisonExp>;
  costTaxRate?: InputMaybe<NumericComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nameEn?: InputMaybe<StringComparisonExp>;
  paidNum?: InputMaybe<IntComparisonExp>;
  payingActivePlanChoices?: InputMaybe<PayingActivePlanChoiceBoolExp>;
  planChoice?: InputMaybe<PlanChoiceBoolExp>;
  price?: InputMaybe<IntComparisonExp>;
  quantity?: InputMaybe<IntComparisonExp>;
  receiptDisplayName?: InputMaybe<StringComparisonExp>;
  taxMethod?: InputMaybe<StringComparisonExp>;
  taxRate?: InputMaybe<NumericComparisonExp>;
};

/** order by max() on columns of table "activePlanChoice" */
export type ActivePlanChoiceMaxOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  activePlanChoiceId?: InputMaybe<OrderBy>;
  activePlanOptionId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  taxMethod?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "activePlanChoice" */
export type ActivePlanChoiceMinOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  activePlanChoiceId?: InputMaybe<OrderBy>;
  activePlanOptionId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  taxMethod?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "activePlanChoice". */
export type ActivePlanChoiceOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  activePlanChoiceId?: InputMaybe<OrderBy>;
  activePlanOption?: InputMaybe<ActivePlanOptionOrderBy>;
  activePlanOptionId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  payingActivePlanChoices_aggregate?: InputMaybe<PayingActivePlanChoiceAggregateOrderBy>;
  planChoice?: InputMaybe<PlanChoiceOrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  taxMethod?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** select columns of table "activePlanChoice" */
export enum ActivePlanChoiceSelectColumn {
  /** column name */
  _ChoiceId = '_choiceId',
  /** column name */
  ActivePlanChoiceId = 'activePlanChoiceId',
  /** column name */
  ActivePlanOptionId = 'activePlanOptionId',
  /** column name */
  ChoiceId = 'choiceId',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CostTaxRate = 'costTaxRate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'nameEn',
  /** column name */
  PaidNum = 'paidNum',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  TaxMethod = 'taxMethod',
  /** column name */
  TaxRate = 'taxRate'
}

/** order by stddev() on columns of table "activePlanChoice" */
export type ActivePlanChoiceStddevOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "activePlanChoice" */
export type ActivePlanChoiceStddevPopOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "activePlanChoice" */
export type ActivePlanChoiceStddevSampOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "activePlanChoice" */
export type ActivePlanChoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActivePlanChoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ActivePlanChoiceStreamCursorValueInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  activePlanChoiceId?: InputMaybe<Scalars['uuid']>;
  activePlanOptionId?: InputMaybe<Scalars['uuid']>;
  choiceId?: InputMaybe<Scalars['uuid']>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  paidNum?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  taxMethod?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "activePlanChoice" */
export type ActivePlanChoiceSumOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "activePlanChoice" */
export type ActivePlanChoiceVarPopOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "activePlanChoice" */
export type ActivePlanChoiceVarSampOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "activePlanChoice" */
export type ActivePlanChoiceVarianceOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** columns and relationships of "activePlanOpenPriceMeta" */
export type ActivePlanOpenPriceMeta = {
  __typename?: 'activePlanOpenPriceMeta';
  /** An object relationship */
  activePlan: ActivePlan;
  /** An object relationship */
  activePlanById?: Maybe<ActivePlan>;
  activePlanId: Scalars['uuid'];
  id: Scalars['uuid'];
  paidNum: Scalars['Int'];
  /** An array relationship */
  payingActivePlanOpenPriceMeta: Array<PayingActivePlanOpenPriceMeta>;
  price: Scalars['bigint'];
  quantity: Scalars['Int'];
  taxMethod: Scalars['String'];
  taxRate: Scalars['numeric'];
};


/** columns and relationships of "activePlanOpenPriceMeta" */
export type ActivePlanOpenPriceMetaPayingActivePlanOpenPriceMetaArgs = {
  distinct_on?: InputMaybe<Array<PayingActivePlanOpenPriceMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingActivePlanOpenPriceMetaOrderBy>>;
  where?: InputMaybe<PayingActivePlanOpenPriceMetaBoolExp>;
};

/** Boolean expression to filter rows from the table "activePlanOpenPriceMeta". All fields are combined with a logical 'AND'. */
export type ActivePlanOpenPriceMetaBoolExp = {
  _and?: InputMaybe<Array<ActivePlanOpenPriceMetaBoolExp>>;
  _not?: InputMaybe<ActivePlanOpenPriceMetaBoolExp>;
  _or?: InputMaybe<Array<ActivePlanOpenPriceMetaBoolExp>>;
  activePlan?: InputMaybe<ActivePlanBoolExp>;
  activePlanById?: InputMaybe<ActivePlanBoolExp>;
  activePlanId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  paidNum?: InputMaybe<IntComparisonExp>;
  payingActivePlanOpenPriceMeta?: InputMaybe<PayingActivePlanOpenPriceMetaBoolExp>;
  price?: InputMaybe<BigintComparisonExp>;
  quantity?: InputMaybe<IntComparisonExp>;
  taxMethod?: InputMaybe<StringComparisonExp>;
  taxRate?: InputMaybe<NumericComparisonExp>;
};

/** Ordering options when selecting data from "activePlanOpenPriceMeta". */
export type ActivePlanOpenPriceMetaOrderBy = {
  activePlan?: InputMaybe<ActivePlanOrderBy>;
  activePlanById?: InputMaybe<ActivePlanOrderBy>;
  activePlanId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paidNum?: InputMaybe<OrderBy>;
  payingActivePlanOpenPriceMeta_aggregate?: InputMaybe<PayingActivePlanOpenPriceMetaAggregateOrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxMethod?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** select columns of table "activePlanOpenPriceMeta" */
export enum ActivePlanOpenPriceMetaSelectColumn {
  /** column name */
  ActivePlanId = 'activePlanId',
  /** column name */
  Id = 'id',
  /** column name */
  PaidNum = 'paidNum',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  TaxMethod = 'taxMethod',
  /** column name */
  TaxRate = 'taxRate'
}

/** Streaming cursor of the table "activePlanOpenPriceMeta" */
export type ActivePlanOpenPriceMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActivePlanOpenPriceMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ActivePlanOpenPriceMetaStreamCursorValueInput = {
  activePlanId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  paidNum?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['bigint']>;
  quantity?: InputMaybe<Scalars['Int']>;
  taxMethod?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<Scalars['numeric']>;
};

/** columns and relationships of "activePlanOption" */
export type ActivePlanOption = {
  __typename?: 'activePlanOption';
  _optionId: Scalars['Int'];
  /** An object relationship */
  activePlan: ActivePlan;
  /** An array relationship */
  activePlanChoices: Array<ActivePlanChoice>;
  activePlanId: Scalars['uuid'];
  activePlanOptionId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  isMainOption: Scalars['Boolean'];
  name: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
  optionId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  planOption?: Maybe<PlanOption>;
  receiptDisplayName: Scalars['String'];
};


/** columns and relationships of "activePlanOption" */
export type ActivePlanOptionActivePlanChoicesArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanChoiceOrderBy>>;
  where?: InputMaybe<ActivePlanChoiceBoolExp>;
};

/** order by aggregate values of table "activePlanOption" */
export type ActivePlanOptionAggregateOrderBy = {
  avg?: InputMaybe<ActivePlanOptionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ActivePlanOptionMaxOrderBy>;
  min?: InputMaybe<ActivePlanOptionMinOrderBy>;
  stddev?: InputMaybe<ActivePlanOptionStddevOrderBy>;
  stddev_pop?: InputMaybe<ActivePlanOptionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ActivePlanOptionStddevSampOrderBy>;
  sum?: InputMaybe<ActivePlanOptionSumOrderBy>;
  var_pop?: InputMaybe<ActivePlanOptionVarPopOrderBy>;
  var_samp?: InputMaybe<ActivePlanOptionVarSampOrderBy>;
  variance?: InputMaybe<ActivePlanOptionVarianceOrderBy>;
};

/** order by avg() on columns of table "activePlanOption" */
export type ActivePlanOptionAvgOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "activePlanOption". All fields are combined with a logical 'AND'. */
export type ActivePlanOptionBoolExp = {
  _and?: InputMaybe<Array<ActivePlanOptionBoolExp>>;
  _not?: InputMaybe<ActivePlanOptionBoolExp>;
  _optionId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<ActivePlanOptionBoolExp>>;
  activePlan?: InputMaybe<ActivePlanBoolExp>;
  activePlanChoices?: InputMaybe<ActivePlanChoiceBoolExp>;
  activePlanId?: InputMaybe<UuidComparisonExp>;
  activePlanOptionId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  isMainOption?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nameEn?: InputMaybe<StringComparisonExp>;
  optionId?: InputMaybe<UuidComparisonExp>;
  planOption?: InputMaybe<PlanOptionBoolExp>;
  receiptDisplayName?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "activePlanOption" */
export type ActivePlanOptionMaxOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  activePlanId?: InputMaybe<OrderBy>;
  activePlanOptionId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "activePlanOption" */
export type ActivePlanOptionMinOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  activePlanId?: InputMaybe<OrderBy>;
  activePlanOptionId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "activePlanOption". */
export type ActivePlanOptionOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  activePlan?: InputMaybe<ActivePlanOrderBy>;
  activePlanChoices_aggregate?: InputMaybe<ActivePlanChoiceAggregateOrderBy>;
  activePlanId?: InputMaybe<OrderBy>;
  activePlanOptionId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  isMainOption?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  planOption?: InputMaybe<PlanOptionOrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
};

/** select columns of table "activePlanOption" */
export enum ActivePlanOptionSelectColumn {
  /** column name */
  _OptionId = '_optionId',
  /** column name */
  ActivePlanId = 'activePlanId',
  /** column name */
  ActivePlanOptionId = 'activePlanOptionId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsMainOption = 'isMainOption',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'nameEn',
  /** column name */
  OptionId = 'optionId',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName'
}

/** order by stddev() on columns of table "activePlanOption" */
export type ActivePlanOptionStddevOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "activePlanOption" */
export type ActivePlanOptionStddevPopOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "activePlanOption" */
export type ActivePlanOptionStddevSampOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "activePlanOption" */
export type ActivePlanOptionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActivePlanOptionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ActivePlanOptionStreamCursorValueInput = {
  _optionId?: InputMaybe<Scalars['Int']>;
  activePlanId?: InputMaybe<Scalars['uuid']>;
  activePlanOptionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isMainOption?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  optionId?: InputMaybe<Scalars['uuid']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "activePlanOption" */
export type ActivePlanOptionSumOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "activePlanOption" */
export type ActivePlanOptionVarPopOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "activePlanOption" */
export type ActivePlanOptionVarSampOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "activePlanOption" */
export type ActivePlanOptionVarianceOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "activePlan" */
export type ActivePlanAggregateOrderBy = {
  avg?: InputMaybe<ActivePlanAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ActivePlanMaxOrderBy>;
  min?: InputMaybe<ActivePlanMinOrderBy>;
  stddev?: InputMaybe<ActivePlanStddevOrderBy>;
  stddev_pop?: InputMaybe<ActivePlanStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ActivePlanStddevSampOrderBy>;
  sum?: InputMaybe<ActivePlanSumOrderBy>;
  var_pop?: InputMaybe<ActivePlanVarPopOrderBy>;
  var_samp?: InputMaybe<ActivePlanVarSampOrderBy>;
  variance?: InputMaybe<ActivePlanVarianceOrderBy>;
};

/** order by avg() on columns of table "activePlan" */
export type ActivePlanAvgOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "activePlan". All fields are combined with a logical 'AND'. */
export type ActivePlanBoolExp = {
  _and?: InputMaybe<Array<ActivePlanBoolExp>>;
  _not?: InputMaybe<ActivePlanBoolExp>;
  _or?: InputMaybe<Array<ActivePlanBoolExp>>;
  _planId?: InputMaybe<IntComparisonExp>;
  activatedClerk?: InputMaybe<ClerkBoolExp>;
  activatedCustomer?: InputMaybe<CustomerBoolExp>;
  activePlanId?: InputMaybe<UuidComparisonExp>;
  activePlanOpenPriceMeta?: InputMaybe<ActivePlanOpenPriceMetaBoolExp>;
  activePlanOpenPriceMetaId?: InputMaybe<UuidComparisonExp>;
  activePlanOpenPriceMetum?: InputMaybe<ActivePlanOpenPriceMetaBoolExp>;
  activePlanOptions?: InputMaybe<ActivePlanOptionBoolExp>;
  canceledAt?: InputMaybe<TimestamptzComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  endAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isPaid?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nameEn?: InputMaybe<StringComparisonExp>;
  plan?: InputMaybe<PlanBoolExp>;
  planCategoryId?: InputMaybe<IntComparisonExp>;
  planId?: InputMaybe<UuidComparisonExp>;
  receiptDisplayName?: InputMaybe<StringComparisonExp>;
  remark?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  startAt?: InputMaybe<TimestamptzComparisonExp>;
  tableUser?: InputMaybe<TableUserBoolExp>;
  tableUserId?: InputMaybe<UuidComparisonExp>;
  tableUsersInGroup?: InputMaybe<TableUserBoolExp>;
};

/** order by max() on columns of table "activePlan" */
export type ActivePlanMaxOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  activePlanOpenPriceMetaId?: InputMaybe<OrderBy>;
  canceledAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  endAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  remark?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  startAt?: InputMaybe<OrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "activePlan" */
export type ActivePlanMinOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  activePlanOpenPriceMetaId?: InputMaybe<OrderBy>;
  canceledAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  endAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  remark?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  startAt?: InputMaybe<OrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "activePlan". */
export type ActivePlanOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  activatedClerk?: InputMaybe<ClerkOrderBy>;
  activatedCustomer?: InputMaybe<CustomerOrderBy>;
  activePlanId?: InputMaybe<OrderBy>;
  activePlanOpenPriceMeta?: InputMaybe<ActivePlanOpenPriceMetaOrderBy>;
  activePlanOpenPriceMetaId?: InputMaybe<OrderBy>;
  activePlanOpenPriceMetum?: InputMaybe<ActivePlanOpenPriceMetaOrderBy>;
  activePlanOptions_aggregate?: InputMaybe<ActivePlanOptionAggregateOrderBy>;
  canceledAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  endAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isPaid?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlanOrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  remark?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  startAt?: InputMaybe<OrderBy>;
  tableUser?: InputMaybe<TableUserOrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
  tableUsersInGroup_aggregate?: InputMaybe<TableUserAggregateOrderBy>;
};

/** select columns of table "activePlan" */
export enum ActivePlanSelectColumn {
  /** column name */
  _PlanId = '_planId',
  /** column name */
  ActivePlanOpenPriceMetaId = 'activePlanOpenPriceMetaId',
  /** column name */
  CanceledAt = 'canceledAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndAt = 'endAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsPaid = 'isPaid',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'nameEn',
  /** column name */
  PlanCategoryId = 'planCategoryId',
  /** column name */
  PlanId = 'planId',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Remark = 'remark',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  StartAt = 'startAt',
  /** column name */
  TableUserId = 'tableUserId'
}

/** order by stddev() on columns of table "activePlan" */
export type ActivePlanStddevOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "activePlan" */
export type ActivePlanStddevPopOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "activePlan" */
export type ActivePlanStddevSampOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "activePlan" */
export type ActivePlanStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActivePlanStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ActivePlanStreamCursorValueInput = {
  _planId?: InputMaybe<Scalars['Int']>;
  activePlanOpenPriceMetaId?: InputMaybe<Scalars['uuid']>;
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  endAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  planCategoryId?: InputMaybe<Scalars['Int']>;
  planId?: InputMaybe<Scalars['uuid']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  startAt?: InputMaybe<Scalars['timestamptz']>;
  tableUserId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "activePlan" */
export type ActivePlanSumOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "activePlan" */
export type ActivePlanVarPopOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "activePlan" */
export type ActivePlanVarSampOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "activePlan" */
export type ActivePlanVarianceOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
};

export enum AdyenPaymentMethodTypeEnum {
  AmexCard = 'AMEX_CARD',
  DinersCard = 'DINERS_CARD',
  JcbCard = 'JCB_CARD',
  MasterCard = 'MASTER_CARD',
  VisaCard = 'VISA_CARD'
}

/** Boolean expression to compare columns of type "adyenPaymentMethodType_enum". All fields are combined with logical 'AND'. */
export type AdyenPaymentMethodTypeEnumComparisonExp = {
  _eq?: InputMaybe<AdyenPaymentMethodTypeEnum>;
  _in?: InputMaybe<Array<AdyenPaymentMethodTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AdyenPaymentMethodTypeEnum>;
  _nin?: InputMaybe<Array<AdyenPaymentMethodTypeEnum>>;
};

/** columns and relationships of "adyenPaymentReportTerminalPaymentAuthorizedEvent" */
export type AdyenPaymentReportTerminalPaymentAuthorizedEvent = {
  __typename?: 'adyenPaymentReportTerminalPaymentAuthorizedEvent';
  /** An object relationship */
  adyenPaymentReportTerminalPaymentCanceledEvent?: Maybe<AdyenPaymentReportTerminalPaymentCanceledEvent>;
  amount: Scalars['numeric'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  merchantAccountId: Scalars['String'];
  merchantReference: Scalars['String'];
  paymentMethod: AdyenPaymentMethodTypeEnum;
  pspReference: Scalars['String'];
  shopId: Scalars['uuid'];
  terminalId: Scalars['String'];
};

/**
 * Boolean expression to filter rows from the table
 * "adyenPaymentReportTerminalPaymentAuthorizedEvent". All fields are combined with
 * a logical 'AND'.
 */
export type AdyenPaymentReportTerminalPaymentAuthorizedEventBoolExp = {
  _and?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentAuthorizedEventBoolExp>>;
  _not?: InputMaybe<AdyenPaymentReportTerminalPaymentAuthorizedEventBoolExp>;
  _or?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentAuthorizedEventBoolExp>>;
  adyenPaymentReportTerminalPaymentCanceledEvent?: InputMaybe<AdyenPaymentReportTerminalPaymentCanceledEventBoolExp>;
  amount?: InputMaybe<NumericComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  merchantAccountId?: InputMaybe<StringComparisonExp>;
  merchantReference?: InputMaybe<StringComparisonExp>;
  paymentMethod?: InputMaybe<AdyenPaymentMethodTypeEnumComparisonExp>;
  pspReference?: InputMaybe<StringComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  terminalId?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "adyenPaymentReportTerminalPaymentAuthorizedEvent". */
export type AdyenPaymentReportTerminalPaymentAuthorizedEventOrderBy = {
  adyenPaymentReportTerminalPaymentCanceledEvent?: InputMaybe<AdyenPaymentReportTerminalPaymentCanceledEventOrderBy>;
  amount?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantAccountId?: InputMaybe<OrderBy>;
  merchantReference?: InputMaybe<OrderBy>;
  paymentMethod?: InputMaybe<OrderBy>;
  pspReference?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  terminalId?: InputMaybe<OrderBy>;
};

/** select columns of table "adyenPaymentReportTerminalPaymentAuthorizedEvent" */
export enum AdyenPaymentReportTerminalPaymentAuthorizedEventSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantAccountId = 'merchantAccountId',
  /** column name */
  MerchantReference = 'merchantReference',
  /** column name */
  PaymentMethod = 'paymentMethod',
  /** column name */
  PspReference = 'pspReference',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TerminalId = 'terminalId'
}

/** Streaming cursor of the table "adyenPaymentReportTerminalPaymentAuthorizedEvent" */
export type AdyenPaymentReportTerminalPaymentAuthorizedEventStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AdyenPaymentReportTerminalPaymentAuthorizedEventStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type AdyenPaymentReportTerminalPaymentAuthorizedEventStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['numeric']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  merchantAccountId?: InputMaybe<Scalars['String']>;
  merchantReference?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<AdyenPaymentMethodTypeEnum>;
  pspReference?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  terminalId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "adyenPaymentReportTerminalPaymentCanceledEvent" */
export type AdyenPaymentReportTerminalPaymentCanceledEvent = {
  __typename?: 'adyenPaymentReportTerminalPaymentCanceledEvent';
  /** An object relationship */
  adyenPaymentReportTerminalPaymentAuthorizedEvent: AdyenPaymentReportTerminalPaymentAuthorizedEvent;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  originalReference: Scalars['String'];
};

/**
 * Boolean expression to filter rows from the table
 * "adyenPaymentReportTerminalPaymentCanceledEvent". All fields are combined with a logical 'AND'.
 */
export type AdyenPaymentReportTerminalPaymentCanceledEventBoolExp = {
  _and?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentCanceledEventBoolExp>>;
  _not?: InputMaybe<AdyenPaymentReportTerminalPaymentCanceledEventBoolExp>;
  _or?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentCanceledEventBoolExp>>;
  adyenPaymentReportTerminalPaymentAuthorizedEvent?: InputMaybe<AdyenPaymentReportTerminalPaymentAuthorizedEventBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  originalReference?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "adyenPaymentReportTerminalPaymentCanceledEvent". */
export type AdyenPaymentReportTerminalPaymentCanceledEventOrderBy = {
  adyenPaymentReportTerminalPaymentAuthorizedEvent?: InputMaybe<AdyenPaymentReportTerminalPaymentAuthorizedEventOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  originalReference?: InputMaybe<OrderBy>;
};

/** select columns of table "adyenPaymentReportTerminalPaymentCanceledEvent" */
export enum AdyenPaymentReportTerminalPaymentCanceledEventSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalReference = 'originalReference'
}

/** Streaming cursor of the table "adyenPaymentReportTerminalPaymentCanceledEvent" */
export type AdyenPaymentReportTerminalPaymentCanceledEventStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AdyenPaymentReportTerminalPaymentCanceledEventStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type AdyenPaymentReportTerminalPaymentCanceledEventStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  originalReference?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "adyenPaymentShopConfig" */
export type AdyenPaymentShopConfig = {
  __typename?: 'adyenPaymentShopConfig';
  /** An array relationship */
  adyenPaymentShopPaymentMethods: Array<AdyenPaymentShopPaymentMethod>;
  archivedAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  shopId: Scalars['uuid'];
};


/** columns and relationships of "adyenPaymentShopConfig" */
export type AdyenPaymentShopConfigAdyenPaymentShopPaymentMethodsArgs = {
  distinct_on?: InputMaybe<Array<AdyenPaymentShopPaymentMethodSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenPaymentShopPaymentMethodOrderBy>>;
  where?: InputMaybe<AdyenPaymentShopPaymentMethodBoolExp>;
};

/** Boolean expression to filter rows from the table "adyenPaymentShopConfig". All fields are combined with a logical 'AND'. */
export type AdyenPaymentShopConfigBoolExp = {
  _and?: InputMaybe<Array<AdyenPaymentShopConfigBoolExp>>;
  _not?: InputMaybe<AdyenPaymentShopConfigBoolExp>;
  _or?: InputMaybe<Array<AdyenPaymentShopConfigBoolExp>>;
  adyenPaymentShopPaymentMethods?: InputMaybe<AdyenPaymentShopPaymentMethodBoolExp>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** Ordering options when selecting data from "adyenPaymentShopConfig". */
export type AdyenPaymentShopConfigOrderBy = {
  adyenPaymentShopPaymentMethods_aggregate?: InputMaybe<AdyenPaymentShopPaymentMethodAggregateOrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "adyenPaymentShopConfig" */
export enum AdyenPaymentShopConfigSelectColumn {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "adyenPaymentShopConfig" */
export type AdyenPaymentShopConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AdyenPaymentShopConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type AdyenPaymentShopConfigStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethod = {
  __typename?: 'adyenPaymentShopPaymentMethod';
  /** An object relationship */
  adyenPaymentShopConfig: AdyenPaymentShopConfig;
  adyenPaymentShopConfigId: Scalars['uuid'];
  archivedAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  paymentMethod: AdyenPaymentMethodTypeEnum;
  transactionFeeRate: Scalars['numeric'];
};

/** order by aggregate values of table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodAggregateOrderBy = {
  avg?: InputMaybe<AdyenPaymentShopPaymentMethodAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AdyenPaymentShopPaymentMethodMaxOrderBy>;
  min?: InputMaybe<AdyenPaymentShopPaymentMethodMinOrderBy>;
  stddev?: InputMaybe<AdyenPaymentShopPaymentMethodStddevOrderBy>;
  stddev_pop?: InputMaybe<AdyenPaymentShopPaymentMethodStddevPopOrderBy>;
  stddev_samp?: InputMaybe<AdyenPaymentShopPaymentMethodStddevSampOrderBy>;
  sum?: InputMaybe<AdyenPaymentShopPaymentMethodSumOrderBy>;
  var_pop?: InputMaybe<AdyenPaymentShopPaymentMethodVarPopOrderBy>;
  var_samp?: InputMaybe<AdyenPaymentShopPaymentMethodVarSampOrderBy>;
  variance?: InputMaybe<AdyenPaymentShopPaymentMethodVarianceOrderBy>;
};

/** order by avg() on columns of table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodAvgOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "adyenPaymentShopPaymentMethod". All fields are combined with a logical 'AND'.
 */
export type AdyenPaymentShopPaymentMethodBoolExp = {
  _and?: InputMaybe<Array<AdyenPaymentShopPaymentMethodBoolExp>>;
  _not?: InputMaybe<AdyenPaymentShopPaymentMethodBoolExp>;
  _or?: InputMaybe<Array<AdyenPaymentShopPaymentMethodBoolExp>>;
  adyenPaymentShopConfig?: InputMaybe<AdyenPaymentShopConfigBoolExp>;
  adyenPaymentShopConfigId?: InputMaybe<UuidComparisonExp>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  paymentMethod?: InputMaybe<AdyenPaymentMethodTypeEnumComparisonExp>;
  transactionFeeRate?: InputMaybe<NumericComparisonExp>;
};

/** order by max() on columns of table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodMaxOrderBy = {
  adyenPaymentShopConfigId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodMinOrderBy = {
  adyenPaymentShopConfigId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "adyenPaymentShopPaymentMethod". */
export type AdyenPaymentShopPaymentMethodOrderBy = {
  adyenPaymentShopConfig?: InputMaybe<AdyenPaymentShopConfigOrderBy>;
  adyenPaymentShopConfigId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethod?: InputMaybe<OrderBy>;
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** select columns of table "adyenPaymentShopPaymentMethod" */
export enum AdyenPaymentShopPaymentMethodSelectColumn {
  /** column name */
  AdyenPaymentShopConfigId = 'adyenPaymentShopConfigId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethod = 'paymentMethod',
  /** column name */
  TransactionFeeRate = 'transactionFeeRate'
}

/** order by stddev() on columns of table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodStddevOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodStddevPopOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodStddevSampOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AdyenPaymentShopPaymentMethodStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type AdyenPaymentShopPaymentMethodStreamCursorValueInput = {
  adyenPaymentShopConfigId?: InputMaybe<Scalars['uuid']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  paymentMethod?: InputMaybe<AdyenPaymentMethodTypeEnum>;
  transactionFeeRate?: InputMaybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodSumOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodVarPopOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodVarSampOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "adyenPaymentShopPaymentMethod" */
export type AdyenPaymentShopPaymentMethodVarianceOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** columns and relationships of "adyenPaymentStoreMeta" */
export type AdyenPaymentStoreMeta = {
  __typename?: 'adyenPaymentStoreMeta';
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  city: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  postalCode: Scalars['String'];
  shopId: Scalars['uuid'];
  shopNameEn: Scalars['String'];
  telephoneNumber: Scalars['String'];
};

/** Boolean expression to filter rows from the table "adyenPaymentStoreMeta". All fields are combined with a logical 'AND'. */
export type AdyenPaymentStoreMetaBoolExp = {
  _and?: InputMaybe<Array<AdyenPaymentStoreMetaBoolExp>>;
  _not?: InputMaybe<AdyenPaymentStoreMetaBoolExp>;
  _or?: InputMaybe<Array<AdyenPaymentStoreMetaBoolExp>>;
  address1?: InputMaybe<StringComparisonExp>;
  address2?: InputMaybe<StringComparisonExp>;
  address3?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  postalCode?: InputMaybe<StringComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopNameEn?: InputMaybe<StringComparisonExp>;
  telephoneNumber?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "adyenPaymentStoreMeta" */
export enum AdyenPaymentStoreMetaConstraint {
  /** unique or primary key constraint on columns "id" */
  AdyenPaymentStoreMetaPkey = 'adyenPaymentStoreMeta_pkey',
  /** unique or primary key constraint on columns "shopId" */
  AdyenPaymentStoreMetaShopIdKey = 'adyenPaymentStoreMeta_shopId_key'
}

/** input type for inserting data into table "adyenPaymentStoreMeta" */
export type AdyenPaymentStoreMetaInsertInput = {
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  city: Scalars['String'];
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  postalCode: Scalars['String'];
  shopId: Scalars['uuid'];
  shopNameEn: Scalars['String'];
  telephoneNumber: Scalars['String'];
};

/** response of any mutation on the table "adyenPaymentStoreMeta" */
export type AdyenPaymentStoreMetaMutationResponse = {
  __typename?: 'adyenPaymentStoreMeta_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AdyenPaymentStoreMeta>;
};

/** on_conflict condition type for table "adyenPaymentStoreMeta" */
export type AdyenPaymentStoreMetaOnConflict = {
  constraint: AdyenPaymentStoreMetaConstraint;
  update_columns?: Array<AdyenPaymentStoreMetaUpdateColumn>;
  where?: InputMaybe<AdyenPaymentStoreMetaBoolExp>;
};

/** Ordering options when selecting data from "adyenPaymentStoreMeta". */
export type AdyenPaymentStoreMetaOrderBy = {
  address1?: InputMaybe<OrderBy>;
  address2?: InputMaybe<OrderBy>;
  address3?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopNameEn?: InputMaybe<OrderBy>;
  telephoneNumber?: InputMaybe<OrderBy>;
};

/** select columns of table "adyenPaymentStoreMeta" */
export enum AdyenPaymentStoreMetaSelectColumn {
  /** column name */
  Address1 = 'address1',
  /** column name */
  Address2 = 'address2',
  /** column name */
  Address3 = 'address3',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  ShopNameEn = 'shopNameEn',
  /** column name */
  TelephoneNumber = 'telephoneNumber'
}

/** Streaming cursor of the table "adyenPaymentStoreMeta" */
export type AdyenPaymentStoreMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AdyenPaymentStoreMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type AdyenPaymentStoreMetaStreamCursorValueInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  address3?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  postalCode?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  shopNameEn?: InputMaybe<Scalars['String']>;
  telephoneNumber?: InputMaybe<Scalars['String']>;
};

/** placeholder for update columns of table "adyenPaymentStoreMeta" (current role has no relevant permissions) */
export enum AdyenPaymentStoreMetaUpdateColumn {
  /** placeholder (do not use) */
  _Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "adyenTerminalPaymentShopConfig" */
export type AdyenTerminalPaymentShopConfig = {
  __typename?: 'adyenTerminalPaymentShopConfig';
  /** An array relationship */
  adyenTerminalPaymentShopPaymentMethods: Array<AdyenTerminalPaymentShopPaymentMethod>;
  id: Scalars['uuid'];
  shopId: Scalars['uuid'];
};


/** columns and relationships of "adyenTerminalPaymentShopConfig" */
export type AdyenTerminalPaymentShopConfigAdyenTerminalPaymentShopPaymentMethodsArgs = {
  distinct_on?: InputMaybe<Array<AdyenTerminalPaymentShopPaymentMethodSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenTerminalPaymentShopPaymentMethodOrderBy>>;
  where?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodBoolExp>;
};

/**
 * Boolean expression to filter rows from the table
 * "adyenTerminalPaymentShopConfig". All fields are combined with a logical 'AND'.
 */
export type AdyenTerminalPaymentShopConfigBoolExp = {
  _and?: InputMaybe<Array<AdyenTerminalPaymentShopConfigBoolExp>>;
  _not?: InputMaybe<AdyenTerminalPaymentShopConfigBoolExp>;
  _or?: InputMaybe<Array<AdyenTerminalPaymentShopConfigBoolExp>>;
  adyenTerminalPaymentShopPaymentMethods?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** Ordering options when selecting data from "adyenTerminalPaymentShopConfig". */
export type AdyenTerminalPaymentShopConfigOrderBy = {
  adyenTerminalPaymentShopPaymentMethods_aggregate?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "adyenTerminalPaymentShopConfig" */
export enum AdyenTerminalPaymentShopConfigSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "adyenTerminalPaymentShopConfig" */
export type AdyenTerminalPaymentShopConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AdyenTerminalPaymentShopConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type AdyenTerminalPaymentShopConfigStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethod = {
  __typename?: 'adyenTerminalPaymentShopPaymentMethod';
  /** An object relationship */
  adyenTerminalPaymentShopConfig: AdyenTerminalPaymentShopConfig;
  adyenTerminalPaymentShopConfigId: Scalars['uuid'];
  id: Scalars['uuid'];
  paymentMethod: AdyenPaymentMethodTypeEnum;
  transactionFeeRate: Scalars['numeric'];
};

/** order by aggregate values of table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodAggregateOrderBy = {
  avg?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodMaxOrderBy>;
  min?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodMinOrderBy>;
  stddev?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodStddevOrderBy>;
  stddev_pop?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodStddevPopOrderBy>;
  stddev_samp?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodStddevSampOrderBy>;
  sum?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodSumOrderBy>;
  var_pop?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodVarPopOrderBy>;
  var_samp?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodVarSampOrderBy>;
  variance?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodVarianceOrderBy>;
};

/** order by avg() on columns of table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodAvgOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "adyenTerminalPaymentShopPaymentMethod". All fields are combined with a logical 'AND'.
 */
export type AdyenTerminalPaymentShopPaymentMethodBoolExp = {
  _and?: InputMaybe<Array<AdyenTerminalPaymentShopPaymentMethodBoolExp>>;
  _not?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodBoolExp>;
  _or?: InputMaybe<Array<AdyenTerminalPaymentShopPaymentMethodBoolExp>>;
  adyenTerminalPaymentShopConfig?: InputMaybe<AdyenTerminalPaymentShopConfigBoolExp>;
  adyenTerminalPaymentShopConfigId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  paymentMethod?: InputMaybe<AdyenPaymentMethodTypeEnumComparisonExp>;
  transactionFeeRate?: InputMaybe<NumericComparisonExp>;
};

/** order by max() on columns of table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodMaxOrderBy = {
  adyenTerminalPaymentShopConfigId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodMinOrderBy = {
  adyenTerminalPaymentShopConfigId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "adyenTerminalPaymentShopPaymentMethod". */
export type AdyenTerminalPaymentShopPaymentMethodOrderBy = {
  adyenTerminalPaymentShopConfig?: InputMaybe<AdyenTerminalPaymentShopConfigOrderBy>;
  adyenTerminalPaymentShopConfigId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethod?: InputMaybe<OrderBy>;
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** select columns of table "adyenTerminalPaymentShopPaymentMethod" */
export enum AdyenTerminalPaymentShopPaymentMethodSelectColumn {
  /** column name */
  AdyenTerminalPaymentShopConfigId = 'adyenTerminalPaymentShopConfigId',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethod = 'paymentMethod',
  /** column name */
  TransactionFeeRate = 'transactionFeeRate'
}

/** order by stddev() on columns of table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodStddevOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodStddevPopOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodStddevSampOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AdyenTerminalPaymentShopPaymentMethodStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type AdyenTerminalPaymentShopPaymentMethodStreamCursorValueInput = {
  adyenTerminalPaymentShopConfigId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  paymentMethod?: InputMaybe<AdyenPaymentMethodTypeEnum>;
  transactionFeeRate?: InputMaybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodSumOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodVarPopOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodVarSampOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "adyenTerminalPaymentShopPaymentMethod" */
export type AdyenTerminalPaymentShopPaymentMethodVarianceOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** columns and relationships of "analyticsSetting" */
export type AnalyticsSetting = {
  __typename?: 'analyticsSetting';
  /** An object relationship */
  company?: Maybe<Company>;
  /** An object relationship */
  corporation: Corporation;
  corporationId: Scalars['uuid'];
  displayTaxIncluded: Scalars['Boolean'];
  id: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "analyticsSetting". All fields are combined with a logical 'AND'. */
export type AnalyticsSettingBoolExp = {
  _and?: InputMaybe<Array<AnalyticsSettingBoolExp>>;
  _not?: InputMaybe<AnalyticsSettingBoolExp>;
  _or?: InputMaybe<Array<AnalyticsSettingBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  corporation?: InputMaybe<CorporationBoolExp>;
  corporationId?: InputMaybe<UuidComparisonExp>;
  displayTaxIncluded?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "analyticsSetting" */
export enum AnalyticsSettingConstraint {
  /** unique or primary key constraint on columns "companyId" */
  AnalyticsSettingCompanyIdKey = 'analyticsSetting_companyId_key',
  /** unique or primary key constraint on columns "id" */
  AnalyticsSettingPkey = 'analyticsSetting_pkey',
  /** unique or primary key constraint on columns "corporationId" */
  UniqueCorporationId = 'unique_corporationId'
}

/** input type for inserting data into table "analyticsSetting" */
export type AnalyticsSettingInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  corporation?: InputMaybe<CorporationObjRelInsertInput>;
  corporationId: Scalars['uuid'];
  displayTaxIncluded?: InputMaybe<Scalars['Boolean']>;
};

/** response of any mutation on the table "analyticsSetting" */
export type AnalyticsSettingMutationResponse = {
  __typename?: 'analyticsSetting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AnalyticsSetting>;
};

/** on_conflict condition type for table "analyticsSetting" */
export type AnalyticsSettingOnConflict = {
  constraint: AnalyticsSettingConstraint;
  update_columns?: Array<AnalyticsSettingUpdateColumn>;
  where?: InputMaybe<AnalyticsSettingBoolExp>;
};

/** Ordering options when selecting data from "analyticsSetting". */
export type AnalyticsSettingOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  corporation?: InputMaybe<CorporationOrderBy>;
  corporationId?: InputMaybe<OrderBy>;
  displayTaxIncluded?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: analyticsSetting */
export type AnalyticsSettingPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "analyticsSetting" */
export enum AnalyticsSettingSelectColumn {
  /** column name */
  CorporationId = 'corporationId',
  /** column name */
  DisplayTaxIncluded = 'displayTaxIncluded',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "analyticsSetting" */
export type AnalyticsSettingSetInput = {
  displayTaxIncluded?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "analyticsSetting" */
export type AnalyticsSettingStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AnalyticsSettingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type AnalyticsSettingStreamCursorValueInput = {
  corporationId?: InputMaybe<Scalars['uuid']>;
  displayTaxIncluded?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "analyticsSetting" */
export enum AnalyticsSettingUpdateColumn {
  /** column name */
  DisplayTaxIncluded = 'displayTaxIncluded'
}

export type AnalyticsSettingUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AnalyticsSettingSetInput>;
  /** filter the rows which have to be updated */
  where: AnalyticsSettingBoolExp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "birthdayTypeQuestionAnswer" */
export type BirthdayTypeQuestionAnswer = {
  __typename?: 'birthdayTypeQuestionAnswer';
  birthday: Scalars['timestamptz'];
  /** An object relationship */
  questionAnswer?: Maybe<QuestionAnswer>;
};

/** Boolean expression to filter rows from the table "birthdayTypeQuestionAnswer". All fields are combined with a logical 'AND'. */
export type BirthdayTypeQuestionAnswerBoolExp = {
  _and?: InputMaybe<Array<BirthdayTypeQuestionAnswerBoolExp>>;
  _not?: InputMaybe<BirthdayTypeQuestionAnswerBoolExp>;
  _or?: InputMaybe<Array<BirthdayTypeQuestionAnswerBoolExp>>;
  birthday?: InputMaybe<TimestamptzComparisonExp>;
  questionAnswer?: InputMaybe<QuestionAnswerBoolExp>;
};

/** Ordering options when selecting data from "birthdayTypeQuestionAnswer". */
export type BirthdayTypeQuestionAnswerOrderBy = {
  birthday?: InputMaybe<OrderBy>;
  questionAnswer?: InputMaybe<QuestionAnswerOrderBy>;
};

/** select columns of table "birthdayTypeQuestionAnswer" */
export enum BirthdayTypeQuestionAnswerSelectColumn {
  /** column name */
  Birthday = 'birthday'
}

/** Streaming cursor of the table "birthdayTypeQuestionAnswer" */
export type BirthdayTypeQuestionAnswerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: BirthdayTypeQuestionAnswerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type BirthdayTypeQuestionAnswerStreamCursorValueInput = {
  birthday?: InputMaybe<Scalars['timestamptz']>;
};

export enum BusinessOperationHourTypeEnumType {
  Cafe = 'cafe',
  Dinner = 'dinner',
  HappyHour = 'happyHour',
  Lunch = 'lunch',
  Midnight = 'midnight',
  Morning = 'morning'
}

export enum BusinessOperationHourTypeEnum {
  Cafe = 'cafe',
  Dinner = 'dinner',
  HappyHour = 'happyHour',
  Lunch = 'lunch',
  Midnight = 'midnight',
  Morning = 'morning'
}

/** Boolean expression to compare columns of type "businessOperationHourType_enum". All fields are combined with logical 'AND'. */
export type BusinessOperationHourTypeEnumComparisonExp = {
  _eq?: InputMaybe<BusinessOperationHourTypeEnum>;
  _in?: InputMaybe<Array<BusinessOperationHourTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<BusinessOperationHourTypeEnum>;
  _nin?: InputMaybe<Array<BusinessOperationHourTypeEnum>>;
};

/** columns and relationships of "cashRegisterConfig" */
export type CashRegisterConfig = {
  __typename?: 'cashRegisterConfig';
  drawerOpenPassword?: Maybe<Scalars['String']>;
  enableRequiringPasswordForDrawerOpen: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  useStera: Scalars['Boolean'];
};

/** aggregated selection of "cashRegisterConfig" */
export type CashRegisterConfigAggregate = {
  __typename?: 'cashRegisterConfig_aggregate';
  aggregate?: Maybe<CashRegisterConfigAggregateFields>;
  nodes: Array<CashRegisterConfig>;
};

/** aggregate fields of "cashRegisterConfig" */
export type CashRegisterConfigAggregateFields = {
  __typename?: 'cashRegisterConfig_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CashRegisterConfigMaxFields>;
  min?: Maybe<CashRegisterConfigMinFields>;
};


/** aggregate fields of "cashRegisterConfig" */
export type CashRegisterConfigAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CashRegisterConfigSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cashRegisterConfig". All fields are combined with a logical 'AND'. */
export type CashRegisterConfigBoolExp = {
  _and?: InputMaybe<Array<CashRegisterConfigBoolExp>>;
  _not?: InputMaybe<CashRegisterConfigBoolExp>;
  _or?: InputMaybe<Array<CashRegisterConfigBoolExp>>;
  drawerOpenPassword?: InputMaybe<StringComparisonExp>;
  enableRequiringPasswordForDrawerOpen?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  useStera?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "cashRegisterConfig" */
export enum CashRegisterConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  CashRegisterConfigIdKey = 'cashRegisterConfig_id_key',
  /** unique or primary key constraint on columns "id" */
  CashRegisterConfigPkey = 'cashRegisterConfig_pkey',
  /** unique or primary key constraint on columns "shopId" */
  CashRegisterConfigShopIdKey = 'cashRegisterConfig_shopId_key'
}

/** input type for inserting data into table "cashRegisterConfig" */
export type CashRegisterConfigInsertInput = {
  drawerOpenPassword?: InputMaybe<Scalars['String']>;
  enableRequiringPasswordForDrawerOpen?: InputMaybe<Scalars['Boolean']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  useStera?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type CashRegisterConfigMaxFields = {
  __typename?: 'cashRegisterConfig_max_fields';
  drawerOpenPassword?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  shopId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CashRegisterConfigMinFields = {
  __typename?: 'cashRegisterConfig_min_fields';
  drawerOpenPassword?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  shopId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "cashRegisterConfig" */
export type CashRegisterConfigMutationResponse = {
  __typename?: 'cashRegisterConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CashRegisterConfig>;
};

/** input type for inserting object relation for remote table "cashRegisterConfig" */
export type CashRegisterConfigObjRelInsertInput = {
  data: CashRegisterConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CashRegisterConfigOnConflict>;
};

/** on_conflict condition type for table "cashRegisterConfig" */
export type CashRegisterConfigOnConflict = {
  constraint: CashRegisterConfigConstraint;
  update_columns?: Array<CashRegisterConfigUpdateColumn>;
  where?: InputMaybe<CashRegisterConfigBoolExp>;
};

/** Ordering options when selecting data from "cashRegisterConfig". */
export type CashRegisterConfigOrderBy = {
  drawerOpenPassword?: InputMaybe<OrderBy>;
  enableRequiringPasswordForDrawerOpen?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  useStera?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cashRegisterConfig */
export type CashRegisterConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "cashRegisterConfig" */
export enum CashRegisterConfigSelectColumn {
  /** column name */
  DrawerOpenPassword = 'drawerOpenPassword',
  /** column name */
  EnableRequiringPasswordForDrawerOpen = 'enableRequiringPasswordForDrawerOpen',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  UseStera = 'useStera'
}

/** input type for updating data in table "cashRegisterConfig" */
export type CashRegisterConfigSetInput = {
  drawerOpenPassword?: InputMaybe<Scalars['String']>;
  enableRequiringPasswordForDrawerOpen?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  useStera?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "cashRegisterConfig" */
export type CashRegisterConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CashRegisterConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CashRegisterConfigStreamCursorValueInput = {
  drawerOpenPassword?: InputMaybe<Scalars['String']>;
  enableRequiringPasswordForDrawerOpen?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  useStera?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "cashRegisterConfig" */
export enum CashRegisterConfigUpdateColumn {
  /** column name */
  DrawerOpenPassword = 'drawerOpenPassword',
  /** column name */
  EnableRequiringPasswordForDrawerOpen = 'enableRequiringPasswordForDrawerOpen',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  UseStera = 'useStera'
}

export type CashRegisterConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CashRegisterConfigSetInput>;
  /** filter the rows which have to be updated */
  where: CashRegisterConfigBoolExp;
};

/** columns and relationships of "cashRegisterSecurityLevel" */
export type CashRegisterSecurityLevel = {
  __typename?: 'cashRegisterSecurityLevel';
  /** An array relationship */
  shops: Array<Shop>;
  value: Scalars['String'];
};


/** columns and relationships of "cashRegisterSecurityLevel" */
export type CashRegisterSecurityLevelShopsArgs = {
  distinct_on?: InputMaybe<Array<ShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOrderBy>>;
  where?: InputMaybe<ShopBoolExp>;
};

/** Boolean expression to filter rows from the table "cashRegisterSecurityLevel". All fields are combined with a logical 'AND'. */
export type CashRegisterSecurityLevelBoolExp = {
  _and?: InputMaybe<Array<CashRegisterSecurityLevelBoolExp>>;
  _not?: InputMaybe<CashRegisterSecurityLevelBoolExp>;
  _or?: InputMaybe<Array<CashRegisterSecurityLevelBoolExp>>;
  shops?: InputMaybe<ShopBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum CashRegisterSecurityLevelEnum {
  Amendment = 'amendment',
  Cash = 'cash'
}

/** Boolean expression to compare columns of type "cashRegisterSecurityLevel_enum". All fields are combined with logical 'AND'. */
export type CashRegisterSecurityLevelEnumComparisonExp = {
  _eq?: InputMaybe<CashRegisterSecurityLevelEnum>;
  _in?: InputMaybe<Array<CashRegisterSecurityLevelEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CashRegisterSecurityLevelEnum>;
  _nin?: InputMaybe<Array<CashRegisterSecurityLevelEnum>>;
};

/** Ordering options when selecting data from "cashRegisterSecurityLevel". */
export type CashRegisterSecurityLevelOrderBy = {
  shops_aggregate?: InputMaybe<ShopAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "cashRegisterSecurityLevel" */
export enum CashRegisterSecurityLevelSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "cashRegisterSecurityLevel" */
export type CashRegisterSecurityLevelStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CashRegisterSecurityLevelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CashRegisterSecurityLevelStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "category" */
export type Category = {
  __typename?: 'category';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  categoryId: Scalars['Int'];
  /** An array relationship */
  categoryMenus: Array<CategoryMenu>;
  /** An aggregate relationship */
  categoryMenus_aggregate: CategoryMenuAggregate;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  imageUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  menuRecommendationMeta?: Maybe<MenuRecommendationMeta>;
  name: Scalars['String'];
  /** An array relationship */
  planMenuCategories: Array<PlanMenuCategory>;
  /** An array relationship */
  plan_menu_categories_categories: Array<PlanMenuCategoriesCategory>;
  /** An array relationship */
  plans: Array<Plan>;
  priority: Scalars['Int'];
  serial: Scalars['Int'];
  shopSideBackgroundColor: Scalars['String'];
  shopSideName?: Maybe<Scalars['String']>;
  /** An array relationship */
  translations: Array<Translation>;
};


/** columns and relationships of "category" */
export type CategoryCategoryMenusArgs = {
  distinct_on?: InputMaybe<Array<CategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryMenuOrderBy>>;
  where?: InputMaybe<CategoryMenuBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryCategoryMenusAggregateArgs = {
  distinct_on?: InputMaybe<Array<CategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryMenuOrderBy>>;
  where?: InputMaybe<CategoryMenuBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryPlanMenuCategoriesArgs = {
  distinct_on?: InputMaybe<Array<PlanMenuCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanMenuCategoryOrderBy>>;
  where?: InputMaybe<PlanMenuCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryPlanMenuCategoriesCategoriesArgs = {
  distinct_on?: InputMaybe<Array<PlanMenuCategoriesCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanMenuCategoriesCategoryOrderBy>>;
  where?: InputMaybe<PlanMenuCategoriesCategoryBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryPlansArgs = {
  distinct_on?: InputMaybe<Array<PlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOrderBy>>;
  where?: InputMaybe<PlanBoolExp>;
};


/** columns and relationships of "category" */
export type CategoryTranslationsArgs = {
  distinct_on?: InputMaybe<Array<TranslationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TranslationOrderBy>>;
  where?: InputMaybe<TranslationBoolExp>;
};

/** columns and relationships of "categoryMenu" */
export type CategoryMenu = {
  __typename?: 'categoryMenu';
  _categoryId: Scalars['Int'];
  _menuId: Scalars['Int'];
  /** An object relationship */
  category: Category;
  categoryId: Scalars['uuid'];
  categoryMenuId: Scalars['Int'];
  displayType: DisplayTypeEnum;
  id: Scalars['uuid'];
  /** An object relationship */
  menu: Menu;
  menuId: Scalars['uuid'];
  /** An array relationship */
  planFirstOrderCategoryMenus: Array<PlanFirstOrderCategoryMenu>;
  priority: Scalars['Int'];
  serial: Scalars['Int'];
};


/** columns and relationships of "categoryMenu" */
export type CategoryMenuPlanFirstOrderCategoryMenusArgs = {
  distinct_on?: InputMaybe<Array<PlanFirstOrderCategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanFirstOrderCategoryMenuOrderBy>>;
  where?: InputMaybe<PlanFirstOrderCategoryMenuBoolExp>;
};

/** aggregated selection of "categoryMenu" */
export type CategoryMenuAggregate = {
  __typename?: 'categoryMenu_aggregate';
  aggregate?: Maybe<CategoryMenuAggregateFields>;
  nodes: Array<CategoryMenu>;
};

export type CategoryMenuAggregateBoolExp = {
  count?: InputMaybe<CategoryMenuAggregateBoolExpCount>;
};

export type CategoryMenuAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CategoryMenuSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CategoryMenuBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "categoryMenu" */
export type CategoryMenuAggregateFields = {
  __typename?: 'categoryMenu_aggregate_fields';
  avg?: Maybe<CategoryMenuAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CategoryMenuMaxFields>;
  min?: Maybe<CategoryMenuMinFields>;
  stddev?: Maybe<CategoryMenuStddevFields>;
  stddev_pop?: Maybe<CategoryMenuStddevPopFields>;
  stddev_samp?: Maybe<CategoryMenuStddevSampFields>;
  sum?: Maybe<CategoryMenuSumFields>;
  var_pop?: Maybe<CategoryMenuVarPopFields>;
  var_samp?: Maybe<CategoryMenuVarSampFields>;
  variance?: Maybe<CategoryMenuVarianceFields>;
};


/** aggregate fields of "categoryMenu" */
export type CategoryMenuAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CategoryMenuSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "categoryMenu" */
export type CategoryMenuAggregateOrderBy = {
  avg?: InputMaybe<CategoryMenuAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CategoryMenuMaxOrderBy>;
  min?: InputMaybe<CategoryMenuMinOrderBy>;
  stddev?: InputMaybe<CategoryMenuStddevOrderBy>;
  stddev_pop?: InputMaybe<CategoryMenuStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CategoryMenuStddevSampOrderBy>;
  sum?: InputMaybe<CategoryMenuSumOrderBy>;
  var_pop?: InputMaybe<CategoryMenuVarPopOrderBy>;
  var_samp?: InputMaybe<CategoryMenuVarSampOrderBy>;
  variance?: InputMaybe<CategoryMenuVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "categoryMenu" */
export type CategoryMenuArrRelInsertInput = {
  data: Array<CategoryMenuInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CategoryMenuOnConflict>;
};

/** aggregate avg on columns */
export type CategoryMenuAvgFields = {
  __typename?: 'categoryMenu_avg_fields';
  _categoryId?: Maybe<Scalars['Float']>;
  _menuId?: Maybe<Scalars['Float']>;
  categoryMenuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "categoryMenu" */
export type CategoryMenuAvgOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "categoryMenu". All fields are combined with a logical 'AND'. */
export type CategoryMenuBoolExp = {
  _and?: InputMaybe<Array<CategoryMenuBoolExp>>;
  _categoryId?: InputMaybe<IntComparisonExp>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<CategoryMenuBoolExp>;
  _or?: InputMaybe<Array<CategoryMenuBoolExp>>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryId?: InputMaybe<UuidComparisonExp>;
  categoryMenuId?: InputMaybe<IntComparisonExp>;
  displayType?: InputMaybe<DisplayTypeEnumComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  planFirstOrderCategoryMenus?: InputMaybe<PlanFirstOrderCategoryMenuBoolExp>;
  priority?: InputMaybe<IntComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "categoryMenu" */
export enum CategoryMenuConstraint {
  /** unique or primary key constraint on columns "id" */
  CategoryMenuIdKey = 'categoryMenu_id_key',
  /** unique or primary key constraint on columns "_categoryId", "_menuId" */
  CategoryMenuMenuIdCategoryIdKey = 'categoryMenu_menuId_categoryId_key',
  /** unique or primary key constraint on columns "categoryId", "menuId" */
  CategoryMenuMenuUuidCategoryUuidKey = 'categoryMenu_menuUuid_categoryUuid_key',
  /** unique or primary key constraint on columns "serial" */
  CategoryMenuPkey = 'categoryMenu_pkey'
}

/** input type for incrementing numeric columns in table "categoryMenu" */
export type CategoryMenuIncInput = {
  _categoryId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "categoryMenu" */
export type CategoryMenuInsertInput = {
  _categoryId: Scalars['Int'];
  _menuId: Scalars['Int'];
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryId: Scalars['uuid'];
  displayType?: InputMaybe<DisplayTypeEnum>;
  menu?: InputMaybe<MenuObjRelInsertInput>;
  menuId: Scalars['uuid'];
  planFirstOrderCategoryMenus?: InputMaybe<PlanFirstOrderCategoryMenuArrRelInsertInput>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CategoryMenuMaxFields = {
  __typename?: 'categoryMenu_max_fields';
  _categoryId?: Maybe<Scalars['Int']>;
  _menuId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['uuid']>;
  categoryMenuId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  menuId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "categoryMenu" */
export type CategoryMenuMaxOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CategoryMenuMinFields = {
  __typename?: 'categoryMenu_min_fields';
  _categoryId?: Maybe<Scalars['Int']>;
  _menuId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['uuid']>;
  categoryMenuId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  menuId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "categoryMenu" */
export type CategoryMenuMinOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "categoryMenu" */
export type CategoryMenuMutationResponse = {
  __typename?: 'categoryMenu_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CategoryMenu>;
};

/** input type for inserting object relation for remote table "categoryMenu" */
export type CategoryMenuObjRelInsertInput = {
  data: CategoryMenuInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CategoryMenuOnConflict>;
};

/** on_conflict condition type for table "categoryMenu" */
export type CategoryMenuOnConflict = {
  constraint: CategoryMenuConstraint;
  update_columns?: Array<CategoryMenuUpdateColumn>;
  where?: InputMaybe<CategoryMenuBoolExp>;
};

/** Ordering options when selecting data from "categoryMenu". */
export type CategoryMenuOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  category?: InputMaybe<CategoryOrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  displayType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
  planFirstOrderCategoryMenus_aggregate?: InputMaybe<PlanFirstOrderCategoryMenuAggregateOrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: categoryMenu */
export type CategoryMenuPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "categoryMenu" */
export enum CategoryMenuSelectColumn {
  /** column name */
  _CategoryId = '_categoryId',
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CategoryMenuId = 'categoryMenuId',
  /** column name */
  DisplayType = 'displayType',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Priority = 'priority',
  /** column name */
  Serial = 'serial'
}

/** input type for updating data in table "categoryMenu" */
export type CategoryMenuSetInput = {
  _categoryId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['uuid']>;
  displayType?: InputMaybe<DisplayTypeEnum>;
  menuId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CategoryMenuStddevFields = {
  __typename?: 'categoryMenu_stddev_fields';
  _categoryId?: Maybe<Scalars['Float']>;
  _menuId?: Maybe<Scalars['Float']>;
  categoryMenuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "categoryMenu" */
export type CategoryMenuStddevOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CategoryMenuStddevPopFields = {
  __typename?: 'categoryMenu_stddev_pop_fields';
  _categoryId?: Maybe<Scalars['Float']>;
  _menuId?: Maybe<Scalars['Float']>;
  categoryMenuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "categoryMenu" */
export type CategoryMenuStddevPopOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CategoryMenuStddevSampFields = {
  __typename?: 'categoryMenu_stddev_samp_fields';
  _categoryId?: Maybe<Scalars['Float']>;
  _menuId?: Maybe<Scalars['Float']>;
  categoryMenuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "categoryMenu" */
export type CategoryMenuStddevSampOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "categoryMenu" */
export type CategoryMenuStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CategoryMenuStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryMenuStreamCursorValueInput = {
  _categoryId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['uuid']>;
  categoryMenuId?: InputMaybe<Scalars['Int']>;
  displayType?: InputMaybe<DisplayTypeEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type CategoryMenuSumFields = {
  __typename?: 'categoryMenu_sum_fields';
  _categoryId?: Maybe<Scalars['Int']>;
  _menuId?: Maybe<Scalars['Int']>;
  categoryMenuId?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "categoryMenu" */
export type CategoryMenuSumOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "categoryMenu" */
export enum CategoryMenuUpdateColumn {
  /** column name */
  _CategoryId = '_categoryId',
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  DisplayType = 'displayType',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Priority = 'priority',
  /** column name */
  Serial = 'serial'
}

export type CategoryMenuUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CategoryMenuIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CategoryMenuSetInput>;
  /** filter the rows which have to be updated */
  where: CategoryMenuBoolExp;
};

/** aggregate var_pop on columns */
export type CategoryMenuVarPopFields = {
  __typename?: 'categoryMenu_var_pop_fields';
  _categoryId?: Maybe<Scalars['Float']>;
  _menuId?: Maybe<Scalars['Float']>;
  categoryMenuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "categoryMenu" */
export type CategoryMenuVarPopOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CategoryMenuVarSampFields = {
  __typename?: 'categoryMenu_var_samp_fields';
  _categoryId?: Maybe<Scalars['Float']>;
  _menuId?: Maybe<Scalars['Float']>;
  categoryMenuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "categoryMenu" */
export type CategoryMenuVarSampOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CategoryMenuVarianceFields = {
  __typename?: 'categoryMenu_variance_fields';
  _categoryId?: Maybe<Scalars['Float']>;
  _menuId?: Maybe<Scalars['Float']>;
  categoryMenuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "categoryMenu" */
export type CategoryMenuVarianceOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregated selection of "category" */
export type CategoryAggregate = {
  __typename?: 'category_aggregate';
  aggregate?: Maybe<CategoryAggregateFields>;
  nodes: Array<Category>;
};

export type CategoryAggregateBoolExp = {
  count?: InputMaybe<CategoryAggregateBoolExpCount>;
};

export type CategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CategoryBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "category" */
export type CategoryAggregateFields = {
  __typename?: 'category_aggregate_fields';
  avg?: Maybe<CategoryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CategoryMaxFields>;
  min?: Maybe<CategoryMinFields>;
  stddev?: Maybe<CategoryStddevFields>;
  stddev_pop?: Maybe<CategoryStddevPopFields>;
  stddev_samp?: Maybe<CategoryStddevSampFields>;
  sum?: Maybe<CategorySumFields>;
  var_pop?: Maybe<CategoryVarPopFields>;
  var_samp?: Maybe<CategoryVarSampFields>;
  variance?: Maybe<CategoryVarianceFields>;
};


/** aggregate fields of "category" */
export type CategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "category" */
export type CategoryAggregateOrderBy = {
  avg?: InputMaybe<CategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CategoryMaxOrderBy>;
  min?: InputMaybe<CategoryMinOrderBy>;
  stddev?: InputMaybe<CategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<CategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CategoryStddevSampOrderBy>;
  sum?: InputMaybe<CategorySumOrderBy>;
  var_pop?: InputMaybe<CategoryVarPopOrderBy>;
  var_samp?: InputMaybe<CategoryVarSampOrderBy>;
  variance?: InputMaybe<CategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "category" */
export type CategoryArrRelInsertInput = {
  data: Array<CategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CategoryOnConflict>;
};

/** aggregate avg on columns */
export type CategoryAvgFields = {
  __typename?: 'category_avg_fields';
  categoryId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "category" */
export type CategoryAvgOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "category". All fields are combined with a logical 'AND'. */
export type CategoryBoolExp = {
  _and?: InputMaybe<Array<CategoryBoolExp>>;
  _not?: InputMaybe<CategoryBoolExp>;
  _or?: InputMaybe<Array<CategoryBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  categoryId?: InputMaybe<IntComparisonExp>;
  categoryMenus?: InputMaybe<CategoryMenuBoolExp>;
  categoryMenus_aggregate?: InputMaybe<CategoryMenuAggregateBoolExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  menuRecommendationMeta?: InputMaybe<MenuRecommendationMetaBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  planMenuCategories?: InputMaybe<PlanMenuCategoryBoolExp>;
  plan_menu_categories_categories?: InputMaybe<PlanMenuCategoriesCategoryBoolExp>;
  plans?: InputMaybe<PlanBoolExp>;
  priority?: InputMaybe<IntComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shopSideBackgroundColor?: InputMaybe<StringComparisonExp>;
  shopSideName?: InputMaybe<StringComparisonExp>;
  translations?: InputMaybe<TranslationBoolExp>;
};

/** unique or primary key constraints on table "category" */
export enum CategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  CategoryIdKey = 'category_id_key',
  /** unique or primary key constraint on columns "serial" */
  Idx_46459Primary = 'idx_46459_PRIMARY'
}

/** input type for incrementing numeric columns in table "category" */
export type CategoryIncInput = {
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "category" */
export type CategoryInsertInput = {
  categoryMenus?: InputMaybe<CategoryMenuArrRelInsertInput>;
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  imageUrl?: InputMaybe<Scalars['String']>;
  menuRecommendationMeta?: InputMaybe<MenuRecommendationMetaObjRelInsertInput>;
  name: Scalars['String'];
  planMenuCategories?: InputMaybe<PlanMenuCategoryArrRelInsertInput>;
  plan_menu_categories_categories?: InputMaybe<PlanMenuCategoriesCategoryArrRelInsertInput>;
  plans?: InputMaybe<PlanArrRelInsertInput>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopSideBackgroundColor?: InputMaybe<Scalars['String']>;
  shopSideName?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CategoryMaxFields = {
  __typename?: 'category_max_fields';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  categoryId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
  shopSideBackgroundColor?: Maybe<Scalars['String']>;
  shopSideName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "category" */
export type CategoryMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopSideBackgroundColor?: InputMaybe<OrderBy>;
  shopSideName?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CategoryMinFields = {
  __typename?: 'category_min_fields';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  categoryId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
  shopSideBackgroundColor?: Maybe<Scalars['String']>;
  shopSideName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "category" */
export type CategoryMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopSideBackgroundColor?: InputMaybe<OrderBy>;
  shopSideName?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "category" */
export type CategoryMutationResponse = {
  __typename?: 'category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category>;
};

/** input type for inserting object relation for remote table "category" */
export type CategoryObjRelInsertInput = {
  data: CategoryInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CategoryOnConflict>;
};

/** on_conflict condition type for table "category" */
export type CategoryOnConflict = {
  constraint: CategoryConstraint;
  update_columns?: Array<CategoryUpdateColumn>;
  where?: InputMaybe<CategoryBoolExp>;
};

/** Ordering options when selecting data from "category". */
export type CategoryOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  categoryMenus_aggregate?: InputMaybe<CategoryMenuAggregateOrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  menuRecommendationMeta?: InputMaybe<MenuRecommendationMetaOrderBy>;
  name?: InputMaybe<OrderBy>;
  planMenuCategories_aggregate?: InputMaybe<PlanMenuCategoryAggregateOrderBy>;
  plan_menu_categories_categories_aggregate?: InputMaybe<PlanMenuCategoriesCategoryAggregateOrderBy>;
  plans_aggregate?: InputMaybe<PlanAggregateOrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopSideBackgroundColor?: InputMaybe<OrderBy>;
  shopSideName?: InputMaybe<OrderBy>;
  translations_aggregate?: InputMaybe<TranslationAggregateOrderBy>;
};

/** primary key columns input for table: category */
export type CategoryPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "category" */
export enum CategorySelectColumn {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopSideBackgroundColor = 'shopSideBackgroundColor',
  /** column name */
  ShopSideName = 'shopSideName'
}

/** input type for updating data in table "category" */
export type CategorySetInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopSideBackgroundColor?: InputMaybe<Scalars['String']>;
  shopSideName?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type CategoryStddevFields = {
  __typename?: 'category_stddev_fields';
  categoryId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "category" */
export type CategoryStddevOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CategoryStddevPopFields = {
  __typename?: 'category_stddev_pop_fields';
  categoryId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "category" */
export type CategoryStddevPopOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CategoryStddevSampFields = {
  __typename?: 'category_stddev_samp_fields';
  categoryId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "category" */
export type CategoryStddevSampOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "category" */
export type CategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CategoryStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopSideBackgroundColor?: InputMaybe<Scalars['String']>;
  shopSideName?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type CategorySumFields = {
  __typename?: 'category_sum_fields';
  categoryId?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "category" */
export type CategorySumOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "category" */
export enum CategoryUpdateColumn {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopSideBackgroundColor = 'shopSideBackgroundColor',
  /** column name */
  ShopSideName = 'shopSideName'
}

export type CategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CategorySetInput>;
  /** filter the rows which have to be updated */
  where: CategoryBoolExp;
};

/** aggregate var_pop on columns */
export type CategoryVarPopFields = {
  __typename?: 'category_var_pop_fields';
  categoryId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "category" */
export type CategoryVarPopOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CategoryVarSampFields = {
  __typename?: 'category_var_samp_fields';
  categoryId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "category" */
export type CategoryVarSampOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CategoryVarianceFields = {
  __typename?: 'category_variance_fields';
  categoryId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "category" */
export type CategoryVarianceOrderBy = {
  categoryId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** columns and relationships of "checkInEvent" */
export type CheckInEvent = {
  __typename?: 'checkInEvent';
  checkedInAt: Scalars['timestamptz'];
  /** An object relationship */
  customer: Customer;
  /** An object relationship */
  customerQuestionnaire?: Maybe<CustomerQuestionnaire>;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An object relationship */
  tableUser?: Maybe<TableUser>;
};

/** order by aggregate values of table "checkInEvent" */
export type CheckInEventAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CheckInEventMaxOrderBy>;
  min?: InputMaybe<CheckInEventMinOrderBy>;
};

/** Boolean expression to filter rows from the table "checkInEvent". All fields are combined with a logical 'AND'. */
export type CheckInEventBoolExp = {
  _and?: InputMaybe<Array<CheckInEventBoolExp>>;
  _not?: InputMaybe<CheckInEventBoolExp>;
  _or?: InputMaybe<Array<CheckInEventBoolExp>>;
  checkedInAt?: InputMaybe<TimestamptzComparisonExp>;
  customer?: InputMaybe<CustomerBoolExp>;
  customerQuestionnaire?: InputMaybe<CustomerQuestionnaireBoolExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  tableUser?: InputMaybe<TableUserBoolExp>;
};

/** order by max() on columns of table "checkInEvent" */
export type CheckInEventMaxOrderBy = {
  checkedInAt?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "checkInEvent" */
export type CheckInEventMinOrderBy = {
  checkedInAt?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "checkInEvent". */
export type CheckInEventOrderBy = {
  checkedInAt?: InputMaybe<OrderBy>;
  customer?: InputMaybe<CustomerOrderBy>;
  customerQuestionnaire?: InputMaybe<CustomerQuestionnaireOrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableUser?: InputMaybe<TableUserOrderBy>;
};

/** select columns of table "checkInEvent" */
export enum CheckInEventSelectColumn {
  /** column name */
  CheckedInAt = 'checkedInAt',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "checkInEvent" */
export type CheckInEventStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CheckInEventStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CheckInEventStreamCursorValueInput = {
  checkedInAt?: InputMaybe<Scalars['timestamptz']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "choice" */
export type Choice = {
  __typename?: 'choice';
  _optionId: Scalars['Int'];
  archivedAt?: Maybe<Scalars['timestamptz']>;
  choiceId: Scalars['Int'];
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  description?: Maybe<Scalars['String']>;
  discount: Scalars['Int'];
  /** An object relationship */
  foodingJournalMenu?: Maybe<FoodingJournalMenu>;
  id: Scalars['uuid'];
  imageUrl?: Maybe<Scalars['String']>;
  isDefaultSelection: Scalars['Boolean'];
  maxOrderableNum?: Maybe<Scalars['Int']>;
  menuType?: Maybe<MenuTypeEnum>;
  name: Scalars['String'];
  /** An object relationship */
  option: Option;
  optionId: Scalars['uuid'];
  /** An array relationship */
  pikaichiMenuChoices: Array<PikaichiMenuChoice>;
  posChoiceId?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  priority: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
  serial: Scalars['Int'];
  /** An array relationship */
  shopChoices: Array<ShopChoice>;
  /** An object relationship */
  tecAggregationMenu?: Maybe<TecAggregationMenu>;
  /** An array relationship */
  translations: Array<Translation>;
  /** An array relationship */
  winboardMenus: Array<WinboardMenu>;
};


/** columns and relationships of "choice" */
export type ChoicePikaichiMenuChoicesArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuChoiceOrderBy>>;
  where?: InputMaybe<PikaichiMenuChoiceBoolExp>;
};


/** columns and relationships of "choice" */
export type ChoiceShopChoicesArgs = {
  distinct_on?: InputMaybe<Array<ShopChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopChoiceOrderBy>>;
  where?: InputMaybe<ShopChoiceBoolExp>;
};


/** columns and relationships of "choice" */
export type ChoiceTranslationsArgs = {
  distinct_on?: InputMaybe<Array<TranslationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TranslationOrderBy>>;
  where?: InputMaybe<TranslationBoolExp>;
};


/** columns and relationships of "choice" */
export type ChoiceWinboardMenusArgs = {
  distinct_on?: InputMaybe<Array<WinboardMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WinboardMenuOrderBy>>;
  where?: InputMaybe<WinboardMenuBoolExp>;
};

/** aggregated selection of "choice" */
export type ChoiceAggregate = {
  __typename?: 'choice_aggregate';
  aggregate?: Maybe<ChoiceAggregateFields>;
  nodes: Array<Choice>;
};

export type ChoiceAggregateBoolExp = {
  bool_and?: InputMaybe<ChoiceAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ChoiceAggregateBoolExpBoolOr>;
  count?: InputMaybe<ChoiceAggregateBoolExpCount>;
};

export type ChoiceAggregateBoolExpBoolAnd = {
  arguments: ChoiceSelectColumnChoiceAggregateBoolExpBoolAndArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChoiceBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChoiceAggregateBoolExpBoolOr = {
  arguments: ChoiceSelectColumnChoiceAggregateBoolExpBoolOrArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChoiceBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChoiceAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChoiceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChoiceBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "choice" */
export type ChoiceAggregateFields = {
  __typename?: 'choice_aggregate_fields';
  avg?: Maybe<ChoiceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ChoiceMaxFields>;
  min?: Maybe<ChoiceMinFields>;
  stddev?: Maybe<ChoiceStddevFields>;
  stddev_pop?: Maybe<ChoiceStddevPopFields>;
  stddev_samp?: Maybe<ChoiceStddevSampFields>;
  sum?: Maybe<ChoiceSumFields>;
  var_pop?: Maybe<ChoiceVarPopFields>;
  var_samp?: Maybe<ChoiceVarSampFields>;
  variance?: Maybe<ChoiceVarianceFields>;
};


/** aggregate fields of "choice" */
export type ChoiceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChoiceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "choice" */
export type ChoiceAggregateOrderBy = {
  avg?: InputMaybe<ChoiceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChoiceMaxOrderBy>;
  min?: InputMaybe<ChoiceMinOrderBy>;
  stddev?: InputMaybe<ChoiceStddevOrderBy>;
  stddev_pop?: InputMaybe<ChoiceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ChoiceStddevSampOrderBy>;
  sum?: InputMaybe<ChoiceSumOrderBy>;
  var_pop?: InputMaybe<ChoiceVarPopOrderBy>;
  var_samp?: InputMaybe<ChoiceVarSampOrderBy>;
  variance?: InputMaybe<ChoiceVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "choice" */
export type ChoiceArrRelInsertInput = {
  data: Array<ChoiceInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChoiceOnConflict>;
};

/** aggregate avg on columns */
export type ChoiceAvgFields = {
  __typename?: 'choice_avg_fields';
  _optionId?: Maybe<Scalars['Float']>;
  choiceId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  posChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "choice" */
export type ChoiceAvgOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  posChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "choice". All fields are combined with a logical 'AND'. */
export type ChoiceBoolExp = {
  _and?: InputMaybe<Array<ChoiceBoolExp>>;
  _not?: InputMaybe<ChoiceBoolExp>;
  _optionId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<ChoiceBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  choiceId?: InputMaybe<IntComparisonExp>;
  costPrice?: InputMaybe<NumericComparisonExp>;
  costTaxRate?: InputMaybe<NumericComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  discount?: InputMaybe<IntComparisonExp>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  isDefaultSelection?: InputMaybe<BooleanComparisonExp>;
  maxOrderableNum?: InputMaybe<IntComparisonExp>;
  menuType?: InputMaybe<MenuTypeEnumComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  option?: InputMaybe<OptionBoolExp>;
  optionId?: InputMaybe<UuidComparisonExp>;
  pikaichiMenuChoices?: InputMaybe<PikaichiMenuChoiceBoolExp>;
  posChoiceId?: InputMaybe<IntComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  receiptDisplayName?: InputMaybe<StringComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shopChoices?: InputMaybe<ShopChoiceBoolExp>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuBoolExp>;
  translations?: InputMaybe<TranslationBoolExp>;
  winboardMenus?: InputMaybe<WinboardMenuBoolExp>;
};

/** unique or primary key constraints on table "choice" */
export enum ChoiceConstraint {
  /** unique or primary key constraint on columns "id" */
  ChoiceIdKey = 'choice_id_key',
  /** unique or primary key constraint on columns "serial" */
  Idx_46469Primary = 'idx_46469_PRIMARY'
}

/** input type for incrementing numeric columns in table "choice" */
export type ChoiceIncInput = {
  _optionId?: InputMaybe<Scalars['Int']>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  discount?: InputMaybe<Scalars['Int']>;
  maxOrderableNum?: InputMaybe<Scalars['Int']>;
  posChoiceId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "choice" */
export type ChoiceInsertInput = {
  _optionId: Scalars['Int'];
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Int']>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuObjRelInsertInput>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDefaultSelection?: InputMaybe<Scalars['Boolean']>;
  maxOrderableNum?: InputMaybe<Scalars['Int']>;
  menuType?: InputMaybe<MenuTypeEnum>;
  name: Scalars['String'];
  option?: InputMaybe<OptionObjRelInsertInput>;
  optionId: Scalars['uuid'];
  pikaichiMenuChoices?: InputMaybe<PikaichiMenuChoiceArrRelInsertInput>;
  posChoiceId?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  priority: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
  serial?: InputMaybe<Scalars['Int']>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuObjRelInsertInput>;
  winboardMenus?: InputMaybe<WinboardMenuArrRelInsertInput>;
};

/** aggregate max on columns */
export type ChoiceMaxFields = {
  __typename?: 'choice_max_fields';
  _optionId?: Maybe<Scalars['Int']>;
  archivedAt?: Maybe<Scalars['timestamptz']>;
  choiceId?: Maybe<Scalars['Int']>;
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  maxOrderableNum?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  optionId?: Maybe<Scalars['uuid']>;
  posChoiceId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  receiptDisplayName?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "choice" */
export type ChoiceMaxOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChoiceMinFields = {
  __typename?: 'choice_min_fields';
  _optionId?: Maybe<Scalars['Int']>;
  archivedAt?: Maybe<Scalars['timestamptz']>;
  choiceId?: Maybe<Scalars['Int']>;
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  maxOrderableNum?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  optionId?: Maybe<Scalars['uuid']>;
  posChoiceId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  receiptDisplayName?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "choice" */
export type ChoiceMinOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "choice" */
export type ChoiceMutationResponse = {
  __typename?: 'choice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Choice>;
};

/** input type for inserting object relation for remote table "choice" */
export type ChoiceObjRelInsertInput = {
  data: ChoiceInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChoiceOnConflict>;
};

/** on_conflict condition type for table "choice" */
export type ChoiceOnConflict = {
  constraint: ChoiceConstraint;
  update_columns?: Array<ChoiceUpdateColumn>;
  where?: InputMaybe<ChoiceBoolExp>;
};

/** Ordering options when selecting data from "choice". */
export type ChoiceOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuOrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  isDefaultSelection?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  menuType?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  option?: InputMaybe<OptionOrderBy>;
  optionId?: InputMaybe<OrderBy>;
  pikaichiMenuChoices_aggregate?: InputMaybe<PikaichiMenuChoiceAggregateOrderBy>;
  posChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopChoices_aggregate?: InputMaybe<ShopChoiceAggregateOrderBy>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuOrderBy>;
  translations_aggregate?: InputMaybe<TranslationAggregateOrderBy>;
  winboardMenus_aggregate?: InputMaybe<WinboardMenuAggregateOrderBy>;
};

/** primary key columns input for table: choice */
export type ChoicePkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "choice" */
export enum ChoiceSelectColumn {
  /** column name */
  _OptionId = '_optionId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  ChoiceId = 'choiceId',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CostTaxRate = 'costTaxRate',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  IsDefaultSelection = 'isDefaultSelection',
  /** column name */
  MaxOrderableNum = 'maxOrderableNum',
  /** column name */
  MenuType = 'menuType',
  /** column name */
  Name = 'name',
  /** column name */
  OptionId = 'optionId',
  /** column name */
  PosChoiceId = 'posChoiceId',
  /** column name */
  Price = 'price',
  /** column name */
  Priority = 'priority',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial'
}

/** select "choice_aggregate_bool_exp_bool_and_arguments_columns" columns of table "choice" */
export enum ChoiceSelectColumnChoiceAggregateBoolExpBoolAndArgumentsColumnsType {
  /** column name */
  IsDefaultSelection = 'isDefaultSelection'
}

/** select "choice_aggregate_bool_exp_bool_or_arguments_columns" columns of table "choice" */
export enum ChoiceSelectColumnChoiceAggregateBoolExpBoolOrArgumentsColumnsType {
  /** column name */
  IsDefaultSelection = 'isDefaultSelection'
}

/** input type for updating data in table "choice" */
export type ChoiceSetInput = {
  _optionId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Int']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDefaultSelection?: InputMaybe<Scalars['Boolean']>;
  maxOrderableNum?: InputMaybe<Scalars['Int']>;
  menuType?: InputMaybe<MenuTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  optionId?: InputMaybe<Scalars['uuid']>;
  posChoiceId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ChoiceStddevFields = {
  __typename?: 'choice_stddev_fields';
  _optionId?: Maybe<Scalars['Float']>;
  choiceId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  posChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "choice" */
export type ChoiceStddevOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  posChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ChoiceStddevPopFields = {
  __typename?: 'choice_stddev_pop_fields';
  _optionId?: Maybe<Scalars['Float']>;
  choiceId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  posChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "choice" */
export type ChoiceStddevPopOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  posChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ChoiceStddevSampFields = {
  __typename?: 'choice_stddev_samp_fields';
  _optionId?: Maybe<Scalars['Float']>;
  choiceId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  posChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "choice" */
export type ChoiceStddevSampOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  posChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "choice" */
export type ChoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ChoiceStreamCursorValueInput = {
  _optionId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  choiceId?: InputMaybe<Scalars['Int']>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDefaultSelection?: InputMaybe<Scalars['Boolean']>;
  maxOrderableNum?: InputMaybe<Scalars['Int']>;
  menuType?: InputMaybe<MenuTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  optionId?: InputMaybe<Scalars['uuid']>;
  posChoiceId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ChoiceSumFields = {
  __typename?: 'choice_sum_fields';
  _optionId?: Maybe<Scalars['Int']>;
  choiceId?: Maybe<Scalars['Int']>;
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['Int']>;
  maxOrderableNum?: Maybe<Scalars['Int']>;
  posChoiceId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "choice" */
export type ChoiceSumOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  posChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "choice" */
export enum ChoiceUpdateColumn {
  /** column name */
  _OptionId = '_optionId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CostTaxRate = 'costTaxRate',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  IsDefaultSelection = 'isDefaultSelection',
  /** column name */
  MaxOrderableNum = 'maxOrderableNum',
  /** column name */
  MenuType = 'menuType',
  /** column name */
  Name = 'name',
  /** column name */
  OptionId = 'optionId',
  /** column name */
  PosChoiceId = 'posChoiceId',
  /** column name */
  Price = 'price',
  /** column name */
  Priority = 'priority',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial'
}

export type ChoiceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChoiceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChoiceSetInput>;
  /** filter the rows which have to be updated */
  where: ChoiceBoolExp;
};

/** aggregate var_pop on columns */
export type ChoiceVarPopFields = {
  __typename?: 'choice_var_pop_fields';
  _optionId?: Maybe<Scalars['Float']>;
  choiceId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  posChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "choice" */
export type ChoiceVarPopOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  posChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ChoiceVarSampFields = {
  __typename?: 'choice_var_samp_fields';
  _optionId?: Maybe<Scalars['Float']>;
  choiceId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  posChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "choice" */
export type ChoiceVarSampOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  posChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ChoiceVarianceFields = {
  __typename?: 'choice_variance_fields';
  _optionId?: Maybe<Scalars['Float']>;
  choiceId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  posChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "choice" */
export type ChoiceVarianceOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  posChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** 会計などを行う際に選択する店舗スタッフ */
export type Clerk = {
  __typename?: 'clerk';
  /** An array relationship */
  clearedTableUsers: Array<TableUser>;
  clerkId: Scalars['Int'];
  /** An array relationship */
  closeCashRegisters: Array<CloseCashRegister>;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  onSitePaymentDetails: Array<OnSitePaymentDetail>;
  /** An array relationship */
  onSitePaymentDiscounts: Array<OnSitePaymentDiscount>;
  /** An array relationship */
  onSitePayments: Array<OnSitePayment>;
  /** An array relationship */
  receipts: Array<Receipt>;
  serial: Scalars['Int'];
  /** An object relationship */
  shop: Shop;
  /** An array relationship */
  shopClerks: Array<ShopClerk>;
  shopId: Scalars['uuid'];
};


/** 会計などを行う際に選択する店舗スタッフ */
export type ClerkClearedTableUsersArgs = {
  distinct_on?: InputMaybe<Array<TableUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserOrderBy>>;
  where?: InputMaybe<TableUserBoolExp>;
};


/** 会計などを行う際に選択する店舗スタッフ */
export type ClerkCloseCashRegistersArgs = {
  distinct_on?: InputMaybe<Array<CloseCashRegisterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CloseCashRegisterOrderBy>>;
  where?: InputMaybe<CloseCashRegisterBoolExp>;
};


/** 会計などを行う際に選択する店舗スタッフ */
export type ClerkOnSitePaymentDetailsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailBoolExp>;
};


/** 会計などを行う際に選択する店舗スタッフ */
export type ClerkOnSitePaymentDiscountsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountBoolExp>;
};


/** 会計などを行う際に選択する店舗スタッフ */
export type ClerkOnSitePaymentsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentOrderBy>>;
  where?: InputMaybe<OnSitePaymentBoolExp>;
};


/** 会計などを行う際に選択する店舗スタッフ */
export type ClerkReceiptsArgs = {
  distinct_on?: InputMaybe<Array<ReceiptSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReceiptOrderBy>>;
  where?: InputMaybe<ReceiptBoolExp>;
};


/** 会計などを行う際に選択する店舗スタッフ */
export type ClerkShopClerksArgs = {
  distinct_on?: InputMaybe<Array<ShopClerkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopClerkOrderBy>>;
  where?: InputMaybe<ShopClerkBoolExp>;
};

/** order by aggregate values of table "clerk" */
export type ClerkAggregateOrderBy = {
  avg?: InputMaybe<ClerkAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClerkMaxOrderBy>;
  min?: InputMaybe<ClerkMinOrderBy>;
  stddev?: InputMaybe<ClerkStddevOrderBy>;
  stddev_pop?: InputMaybe<ClerkStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ClerkStddevSampOrderBy>;
  sum?: InputMaybe<ClerkSumOrderBy>;
  var_pop?: InputMaybe<ClerkVarPopOrderBy>;
  var_samp?: InputMaybe<ClerkVarSampOrderBy>;
  variance?: InputMaybe<ClerkVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "clerk" */
export type ClerkArrRelInsertInput = {
  data: Array<ClerkInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClerkOnConflict>;
};

/** order by avg() on columns of table "clerk" */
export type ClerkAvgOrderBy = {
  clerkId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "clerk". All fields are combined with a logical 'AND'. */
export type ClerkBoolExp = {
  _and?: InputMaybe<Array<ClerkBoolExp>>;
  _not?: InputMaybe<ClerkBoolExp>;
  _or?: InputMaybe<Array<ClerkBoolExp>>;
  clearedTableUsers?: InputMaybe<TableUserBoolExp>;
  clerkId?: InputMaybe<IntComparisonExp>;
  closeCashRegisters?: InputMaybe<CloseCashRegisterBoolExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  onSitePaymentDetails?: InputMaybe<OnSitePaymentDetailBoolExp>;
  onSitePaymentDiscounts?: InputMaybe<OnSitePaymentDiscountBoolExp>;
  onSitePayments?: InputMaybe<OnSitePaymentBoolExp>;
  receipts?: InputMaybe<ReceiptBoolExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopClerks?: InputMaybe<ShopClerkBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "clerk" */
export enum ClerkConstraint {
  /** unique or primary key constraint on columns "id" */
  ClerkIdKey = 'clerk_id_key',
  /** unique or primary key constraint on columns "serial" */
  Idx_46484Primary = 'idx_46484_PRIMARY'
}

/** input type for incrementing numeric columns in table "clerk" */
export type ClerkIncInput = {
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "clerk" */
export type ClerkInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  serial?: InputMaybe<Scalars['Int']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopClerks?: InputMaybe<ShopClerkArrRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "clerk" */
export type ClerkMaxOrderBy = {
  clerkId?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "clerk" */
export type ClerkMinOrderBy = {
  clerkId?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "clerk" */
export type ClerkMutationResponse = {
  __typename?: 'clerk_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clerk>;
};

/** input type for inserting object relation for remote table "clerk" */
export type ClerkObjRelInsertInput = {
  data: ClerkInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ClerkOnConflict>;
};

/** on_conflict condition type for table "clerk" */
export type ClerkOnConflict = {
  constraint: ClerkConstraint;
  update_columns?: Array<ClerkUpdateColumn>;
  where?: InputMaybe<ClerkBoolExp>;
};

/** Ordering options when selecting data from "clerk". */
export type ClerkOrderBy = {
  clearedTableUsers_aggregate?: InputMaybe<TableUserAggregateOrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  closeCashRegisters_aggregate?: InputMaybe<CloseCashRegisterAggregateOrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  onSitePaymentDetails_aggregate?: InputMaybe<OnSitePaymentDetailAggregateOrderBy>;
  onSitePaymentDiscounts_aggregate?: InputMaybe<OnSitePaymentDiscountAggregateOrderBy>;
  onSitePayments_aggregate?: InputMaybe<OnSitePaymentAggregateOrderBy>;
  receipts_aggregate?: InputMaybe<ReceiptAggregateOrderBy>;
  serial?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopClerks_aggregate?: InputMaybe<ShopClerkAggregateOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: clerk */
export type ClerkPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "clerk" */
export enum ClerkSelectColumn {
  /** column name */
  ClerkId = 'clerkId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "clerk" */
export type ClerkSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "clerk" */
export type ClerkStddevOrderBy = {
  clerkId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "clerk" */
export type ClerkStddevPopOrderBy = {
  clerkId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "clerk" */
export type ClerkStddevSampOrderBy = {
  clerkId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "clerk" */
export type ClerkStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ClerkStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ClerkStreamCursorValueInput = {
  clerkId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "clerk" */
export type ClerkSumOrderBy = {
  clerkId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "clerk" */
export enum ClerkUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Name = 'name',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopId = 'shopId'
}

export type ClerkUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClerkIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClerkSetInput>;
  /** filter the rows which have to be updated */
  where: ClerkBoolExp;
};

/** order by var_pop() on columns of table "clerk" */
export type ClerkVarPopOrderBy = {
  clerkId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "clerk" */
export type ClerkVarSampOrderBy = {
  clerkId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "clerk" */
export type ClerkVarianceOrderBy = {
  clerkId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** columns and relationships of "closeCashRegister" */
export type CloseCashRegister = {
  __typename?: 'closeCashRegister';
  _clerkId: Scalars['Int'];
  _closeCashRegisterId?: Maybe<Scalars['Int']>;
  amount: Scalars['Int'];
  carriedForwardReserveCashAmount?: Maybe<Scalars['Int']>;
  carriedForwardReserveCashDenominationCountId?: Maybe<Scalars['uuid']>;
  cashDenominationCountId: Scalars['uuid'];
  /** An object relationship */
  clerk: Clerk;
  clerkId: Scalars['uuid'];
  /** An array relationship */
  coineySettlements: Array<CoineySettlement>;
  /** timestamp */
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  excludedFromAggregation: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An array relationship */
  onSitePayments: Array<OnSitePayment>;
  reserveCashId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  targetDate: Scalars['date'];
};


/** columns and relationships of "closeCashRegister" */
export type CloseCashRegisterCoineySettlementsArgs = {
  distinct_on?: InputMaybe<Array<CoineySettlementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineySettlementOrderBy>>;
  where?: InputMaybe<CoineySettlementBoolExp>;
};


/** columns and relationships of "closeCashRegister" */
export type CloseCashRegisterOnSitePaymentsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentOrderBy>>;
  where?: InputMaybe<OnSitePaymentBoolExp>;
};

/** order by aggregate values of table "closeCashRegister" */
export type CloseCashRegisterAggregateOrderBy = {
  avg?: InputMaybe<CloseCashRegisterAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CloseCashRegisterMaxOrderBy>;
  min?: InputMaybe<CloseCashRegisterMinOrderBy>;
  stddev?: InputMaybe<CloseCashRegisterStddevOrderBy>;
  stddev_pop?: InputMaybe<CloseCashRegisterStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CloseCashRegisterStddevSampOrderBy>;
  sum?: InputMaybe<CloseCashRegisterSumOrderBy>;
  var_pop?: InputMaybe<CloseCashRegisterVarPopOrderBy>;
  var_samp?: InputMaybe<CloseCashRegisterVarSampOrderBy>;
  variance?: InputMaybe<CloseCashRegisterVarianceOrderBy>;
};

/** order by avg() on columns of table "closeCashRegister" */
export type CloseCashRegisterAvgOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _closeCashRegisterId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashAmount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "closeCashRegister". All fields are combined with a logical 'AND'. */
export type CloseCashRegisterBoolExp = {
  _and?: InputMaybe<Array<CloseCashRegisterBoolExp>>;
  _clerkId?: InputMaybe<IntComparisonExp>;
  _closeCashRegisterId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<CloseCashRegisterBoolExp>;
  _or?: InputMaybe<Array<CloseCashRegisterBoolExp>>;
  amount?: InputMaybe<IntComparisonExp>;
  carriedForwardReserveCashAmount?: InputMaybe<IntComparisonExp>;
  carriedForwardReserveCashDenominationCountId?: InputMaybe<UuidComparisonExp>;
  cashDenominationCountId?: InputMaybe<UuidComparisonExp>;
  clerk?: InputMaybe<ClerkBoolExp>;
  clerkId?: InputMaybe<UuidComparisonExp>;
  coineySettlements?: InputMaybe<CoineySettlementBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  excludedFromAggregation?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  onSitePayments?: InputMaybe<OnSitePaymentBoolExp>;
  reserveCashId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  targetDate?: InputMaybe<DateComparisonExp>;
};

/** order by max() on columns of table "closeCashRegister" */
export type CloseCashRegisterMaxOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _closeCashRegisterId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashAmount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashDenominationCountId?: InputMaybe<OrderBy>;
  cashDenominationCountId?: InputMaybe<OrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  /** timestamp */
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reserveCashId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  targetDate?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "closeCashRegister" */
export type CloseCashRegisterMinOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _closeCashRegisterId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashAmount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashDenominationCountId?: InputMaybe<OrderBy>;
  cashDenominationCountId?: InputMaybe<OrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  /** timestamp */
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  reserveCashId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  targetDate?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "closeCashRegister" */
export type CloseCashRegisterMutationResponse = {
  __typename?: 'closeCashRegister_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CloseCashRegister>;
};

/** Ordering options when selecting data from "closeCashRegister". */
export type CloseCashRegisterOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _closeCashRegisterId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashAmount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashDenominationCountId?: InputMaybe<OrderBy>;
  cashDenominationCountId?: InputMaybe<OrderBy>;
  clerk?: InputMaybe<ClerkOrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  coineySettlements_aggregate?: InputMaybe<CoineySettlementAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  excludedFromAggregation?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onSitePayments_aggregate?: InputMaybe<OnSitePaymentAggregateOrderBy>;
  reserveCashId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  targetDate?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: closeCashRegister */
export type CloseCashRegisterPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "closeCashRegister" */
export enum CloseCashRegisterSelectColumn {
  /** column name */
  _ClerkId = '_clerkId',
  /** column name */
  _CloseCashRegisterId = '_closeCashRegisterId',
  /** column name */
  Amount = 'amount',
  /** column name */
  CarriedForwardReserveCashAmount = 'carriedForwardReserveCashAmount',
  /** column name */
  CarriedForwardReserveCashDenominationCountId = 'carriedForwardReserveCashDenominationCountId',
  /** column name */
  CashDenominationCountId = 'cashDenominationCountId',
  /** column name */
  ClerkId = 'clerkId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  ExcludedFromAggregation = 'excludedFromAggregation',
  /** column name */
  Id = 'id',
  /** column name */
  ReserveCashId = 'reserveCashId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TargetDate = 'targetDate'
}

/** input type for updating data in table "closeCashRegister" */
export type CloseCashRegisterSetInput = {
  excludedFromAggregation?: InputMaybe<Scalars['Boolean']>;
  targetDate?: InputMaybe<Scalars['date']>;
};

/** order by stddev() on columns of table "closeCashRegister" */
export type CloseCashRegisterStddevOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _closeCashRegisterId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashAmount?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "closeCashRegister" */
export type CloseCashRegisterStddevPopOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _closeCashRegisterId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashAmount?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "closeCashRegister" */
export type CloseCashRegisterStddevSampOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _closeCashRegisterId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashAmount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "closeCashRegister" */
export type CloseCashRegisterStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CloseCashRegisterStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CloseCashRegisterStreamCursorValueInput = {
  _clerkId?: InputMaybe<Scalars['Int']>;
  _closeCashRegisterId?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['Int']>;
  carriedForwardReserveCashAmount?: InputMaybe<Scalars['Int']>;
  carriedForwardReserveCashDenominationCountId?: InputMaybe<Scalars['uuid']>;
  cashDenominationCountId?: InputMaybe<Scalars['uuid']>;
  clerkId?: InputMaybe<Scalars['uuid']>;
  /** timestamp */
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  excludedFromAggregation?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  reserveCashId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  targetDate?: InputMaybe<Scalars['date']>;
};

/** order by sum() on columns of table "closeCashRegister" */
export type CloseCashRegisterSumOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _closeCashRegisterId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashAmount?: InputMaybe<OrderBy>;
};

export type CloseCashRegisterUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CloseCashRegisterSetInput>;
  /** filter the rows which have to be updated */
  where: CloseCashRegisterBoolExp;
};

/** order by var_pop() on columns of table "closeCashRegister" */
export type CloseCashRegisterVarPopOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _closeCashRegisterId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashAmount?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "closeCashRegister" */
export type CloseCashRegisterVarSampOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _closeCashRegisterId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashAmount?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "closeCashRegister" */
export type CloseCashRegisterVarianceOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _closeCashRegisterId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  carriedForwardReserveCashAmount?: InputMaybe<OrderBy>;
};

/** columns and relationships of "coineyPayment" */
export type CoineyPayment = {
  __typename?: 'coineyPayment';
  canceledAt?: Maybe<Scalars['timestamptz']>;
  coineyPaymentId: Scalars['Int'];
  /** An array relationship */
  coineyPaymentOrders: Array<CoineyPaymentOrder>;
  /** An array relationship */
  coineyPaymentSalesList: Array<CoineyPaymentSales>;
  /** An object relationship */
  coineySettlement?: Maybe<CoineySettlement>;
  /** An array relationship */
  coineySettlements: Array<CoineySettlement>;
  createdAt: Scalars['timestamptz'];
  serial: Scalars['Int'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An object relationship */
  tableUser: TableUser;
  tableUserId: Scalars['uuid'];
};


/** columns and relationships of "coineyPayment" */
export type CoineyPaymentCoineyPaymentOrdersArgs = {
  distinct_on?: InputMaybe<Array<CoineyPaymentOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineyPaymentOrderOrderBy>>;
  where?: InputMaybe<CoineyPaymentOrderBoolExp>;
};


/** columns and relationships of "coineyPayment" */
export type CoineyPaymentCoineyPaymentSalesListArgs = {
  distinct_on?: InputMaybe<Array<CoineyPaymentSalesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineyPaymentSalesOrderBy>>;
  where?: InputMaybe<CoineyPaymentSalesBoolExp>;
};


/** columns and relationships of "coineyPayment" */
export type CoineyPaymentCoineySettlementsArgs = {
  distinct_on?: InputMaybe<Array<CoineySettlementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineySettlementOrderBy>>;
  where?: InputMaybe<CoineySettlementBoolExp>;
};

/** columns and relationships of "coineyPaymentOrder" */
export type CoineyPaymentOrder = {
  __typename?: 'coineyPaymentOrder';
  _coineyPaymentId: Scalars['Int'];
  /** An object relationship */
  coineyPayment: CoineyPayment;
  /** A computed field, executes function "coineyPaymentOrder_coineyPaymentId" */
  coineyPaymentId?: Maybe<Scalars['Int']>;
  coineyPaymentOrderId: Scalars['Int'];
  orderId: Scalars['String'];
};

/** order by aggregate values of table "coineyPaymentOrder" */
export type CoineyPaymentOrderAggregateOrderBy = {
  avg?: InputMaybe<CoineyPaymentOrderAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoineyPaymentOrderMaxOrderBy>;
  min?: InputMaybe<CoineyPaymentOrderMinOrderBy>;
  stddev?: InputMaybe<CoineyPaymentOrderStddevOrderBy>;
  stddev_pop?: InputMaybe<CoineyPaymentOrderStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoineyPaymentOrderStddevSampOrderBy>;
  sum?: InputMaybe<CoineyPaymentOrderSumOrderBy>;
  var_pop?: InputMaybe<CoineyPaymentOrderVarPopOrderBy>;
  var_samp?: InputMaybe<CoineyPaymentOrderVarSampOrderBy>;
  variance?: InputMaybe<CoineyPaymentOrderVarianceOrderBy>;
};

/** order by avg() on columns of table "coineyPaymentOrder" */
export type CoineyPaymentOrderAvgOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrderId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coineyPaymentOrder". All fields are combined with a logical 'AND'. */
export type CoineyPaymentOrderBoolExp = {
  _and?: InputMaybe<Array<CoineyPaymentOrderBoolExp>>;
  _coineyPaymentId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<CoineyPaymentOrderBoolExp>;
  _or?: InputMaybe<Array<CoineyPaymentOrderBoolExp>>;
  coineyPayment?: InputMaybe<CoineyPaymentBoolExp>;
  coineyPaymentId?: InputMaybe<IntComparisonExp>;
  coineyPaymentOrderId?: InputMaybe<IntComparisonExp>;
  orderId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "coineyPaymentOrder" */
export type CoineyPaymentOrderMaxOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrderId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "coineyPaymentOrder" */
export type CoineyPaymentOrderMinOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrderId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "coineyPaymentOrder". */
export type CoineyPaymentOrderOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPayment?: InputMaybe<CoineyPaymentOrderBy>;
  coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrderId?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** select columns of table "coineyPaymentOrder" */
export enum CoineyPaymentOrderSelectColumn {
  /** column name */
  _CoineyPaymentId = '_coineyPaymentId',
  /** column name */
  CoineyPaymentOrderId = 'coineyPaymentOrderId',
  /** column name */
  OrderId = 'orderId'
}

/** order by stddev() on columns of table "coineyPaymentOrder" */
export type CoineyPaymentOrderStddevOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrderId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "coineyPaymentOrder" */
export type CoineyPaymentOrderStddevPopOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrderId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "coineyPaymentOrder" */
export type CoineyPaymentOrderStddevSampOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrderId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "coineyPaymentOrder" */
export type CoineyPaymentOrderStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CoineyPaymentOrderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CoineyPaymentOrderStreamCursorValueInput = {
  _coineyPaymentId?: InputMaybe<Scalars['Int']>;
  coineyPaymentOrderId?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "coineyPaymentOrder" */
export type CoineyPaymentOrderSumOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrderId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "coineyPaymentOrder" */
export type CoineyPaymentOrderVarPopOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrderId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "coineyPaymentOrder" */
export type CoineyPaymentOrderVarSampOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrderId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "coineyPaymentOrder" */
export type CoineyPaymentOrderVarianceOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrderId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "coineyPaymentSales" */
export type CoineyPaymentSales = {
  __typename?: 'coineyPaymentSales';
  _coineyPaymentId: Scalars['Int'];
  /** An object relationship */
  coineyPayment: CoineyPayment;
  /** A computed field, executes function "coineyPaymentSales_coineyPaymentId" */
  coineyPaymentId?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  taxExcludedAmount: Scalars['Int'];
  taxIncludedAmount: Scalars['Int'];
  taxRate: Scalars['numeric'];
};

/** order by aggregate values of table "coineyPaymentSales" */
export type CoineyPaymentSalesAggregateOrderBy = {
  avg?: InputMaybe<CoineyPaymentSalesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoineyPaymentSalesMaxOrderBy>;
  min?: InputMaybe<CoineyPaymentSalesMinOrderBy>;
  stddev?: InputMaybe<CoineyPaymentSalesStddevOrderBy>;
  stddev_pop?: InputMaybe<CoineyPaymentSalesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoineyPaymentSalesStddevSampOrderBy>;
  sum?: InputMaybe<CoineyPaymentSalesSumOrderBy>;
  var_pop?: InputMaybe<CoineyPaymentSalesVarPopOrderBy>;
  var_samp?: InputMaybe<CoineyPaymentSalesVarSampOrderBy>;
  variance?: InputMaybe<CoineyPaymentSalesVarianceOrderBy>;
};

/** order by avg() on columns of table "coineyPaymentSales" */
export type CoineyPaymentSalesAvgOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coineyPaymentSales". All fields are combined with a logical 'AND'. */
export type CoineyPaymentSalesBoolExp = {
  _and?: InputMaybe<Array<CoineyPaymentSalesBoolExp>>;
  _coineyPaymentId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<CoineyPaymentSalesBoolExp>;
  _or?: InputMaybe<Array<CoineyPaymentSalesBoolExp>>;
  coineyPayment?: InputMaybe<CoineyPaymentBoolExp>;
  coineyPaymentId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  taxExcludedAmount?: InputMaybe<IntComparisonExp>;
  taxIncludedAmount?: InputMaybe<IntComparisonExp>;
  taxRate?: InputMaybe<NumericComparisonExp>;
};

/** order by max() on columns of table "coineyPaymentSales" */
export type CoineyPaymentSalesMaxOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "coineyPaymentSales" */
export type CoineyPaymentSalesMinOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "coineyPaymentSales". */
export type CoineyPaymentSalesOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPayment?: InputMaybe<CoineyPaymentOrderBy>;
  coineyPaymentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** select columns of table "coineyPaymentSales" */
export enum CoineyPaymentSalesSelectColumn {
  /** column name */
  _CoineyPaymentId = '_coineyPaymentId',
  /** column name */
  Id = 'id',
  /** column name */
  TaxExcludedAmount = 'taxExcludedAmount',
  /** column name */
  TaxIncludedAmount = 'taxIncludedAmount',
  /** column name */
  TaxRate = 'taxRate'
}

/** order by stddev() on columns of table "coineyPaymentSales" */
export type CoineyPaymentSalesStddevOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "coineyPaymentSales" */
export type CoineyPaymentSalesStddevPopOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "coineyPaymentSales" */
export type CoineyPaymentSalesStddevSampOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "coineyPaymentSales" */
export type CoineyPaymentSalesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CoineyPaymentSalesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CoineyPaymentSalesStreamCursorValueInput = {
  _coineyPaymentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  taxExcludedAmount?: InputMaybe<Scalars['Int']>;
  taxIncludedAmount?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "coineyPaymentSales" */
export type CoineyPaymentSalesSumOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "coineyPaymentSales" */
export type CoineyPaymentSalesVarPopOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "coineyPaymentSales" */
export type CoineyPaymentSalesVarSampOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "coineyPaymentSales" */
export type CoineyPaymentSalesVarianceOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "coineyPayment" */
export type CoineyPaymentAggregateOrderBy = {
  avg?: InputMaybe<CoineyPaymentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoineyPaymentMaxOrderBy>;
  min?: InputMaybe<CoineyPaymentMinOrderBy>;
  stddev?: InputMaybe<CoineyPaymentStddevOrderBy>;
  stddev_pop?: InputMaybe<CoineyPaymentStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoineyPaymentStddevSampOrderBy>;
  sum?: InputMaybe<CoineyPaymentSumOrderBy>;
  var_pop?: InputMaybe<CoineyPaymentVarPopOrderBy>;
  var_samp?: InputMaybe<CoineyPaymentVarSampOrderBy>;
  variance?: InputMaybe<CoineyPaymentVarianceOrderBy>;
};

/** order by avg() on columns of table "coineyPayment" */
export type CoineyPaymentAvgOrderBy = {
  coineyPaymentId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coineyPayment". All fields are combined with a logical 'AND'. */
export type CoineyPaymentBoolExp = {
  _and?: InputMaybe<Array<CoineyPaymentBoolExp>>;
  _not?: InputMaybe<CoineyPaymentBoolExp>;
  _or?: InputMaybe<Array<CoineyPaymentBoolExp>>;
  canceledAt?: InputMaybe<TimestamptzComparisonExp>;
  coineyPaymentId?: InputMaybe<IntComparisonExp>;
  coineyPaymentOrders?: InputMaybe<CoineyPaymentOrderBoolExp>;
  coineyPaymentSalesList?: InputMaybe<CoineyPaymentSalesBoolExp>;
  coineySettlement?: InputMaybe<CoineySettlementBoolExp>;
  coineySettlements?: InputMaybe<CoineySettlementBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  tableUser?: InputMaybe<TableUserBoolExp>;
  tableUserId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "coineyPayment" */
export type CoineyPaymentMaxOrderBy = {
  canceledAt?: InputMaybe<OrderBy>;
  coineyPaymentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "coineyPayment" */
export type CoineyPaymentMinOrderBy = {
  canceledAt?: InputMaybe<OrderBy>;
  coineyPaymentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coineyPayment" */
export type CoineyPaymentMutationResponse = {
  __typename?: 'coineyPayment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CoineyPayment>;
};

/** Ordering options when selecting data from "coineyPayment". */
export type CoineyPaymentOrderBy = {
  canceledAt?: InputMaybe<OrderBy>;
  coineyPaymentId?: InputMaybe<OrderBy>;
  coineyPaymentOrders_aggregate?: InputMaybe<CoineyPaymentOrderAggregateOrderBy>;
  coineyPaymentSalesList_aggregate?: InputMaybe<CoineyPaymentSalesAggregateOrderBy>;
  coineySettlement?: InputMaybe<CoineySettlementOrderBy>;
  coineySettlements_aggregate?: InputMaybe<CoineySettlementAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableUser?: InputMaybe<TableUserOrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coineyPayment */
export type CoineyPaymentPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "coineyPayment" */
export enum CoineyPaymentSelectColumn {
  /** column name */
  CanceledAt = 'canceledAt',
  /** column name */
  CoineyPaymentId = 'coineyPaymentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TableUserId = 'tableUserId'
}

/** input type for updating data in table "coineyPayment" */
export type CoineyPaymentSetInput = {
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "coineyPayment" */
export type CoineyPaymentStddevOrderBy = {
  coineyPaymentId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "coineyPayment" */
export type CoineyPaymentStddevPopOrderBy = {
  coineyPaymentId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "coineyPayment" */
export type CoineyPaymentStddevSampOrderBy = {
  coineyPaymentId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "coineyPayment" */
export type CoineyPaymentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CoineyPaymentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CoineyPaymentStreamCursorValueInput = {
  canceledAt?: InputMaybe<Scalars['timestamptz']>;
  coineyPaymentId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tableUserId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "coineyPayment" */
export type CoineyPaymentSumOrderBy = {
  coineyPaymentId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

export type CoineyPaymentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CoineyPaymentSetInput>;
  /** filter the rows which have to be updated */
  where: CoineyPaymentBoolExp;
};

/** order by var_pop() on columns of table "coineyPayment" */
export type CoineyPaymentVarPopOrderBy = {
  coineyPaymentId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "coineyPayment" */
export type CoineyPaymentVarSampOrderBy = {
  coineyPaymentId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "coineyPayment" */
export type CoineyPaymentVarianceOrderBy = {
  coineyPaymentId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** columns and relationships of "coineySettlement" */
export type CoineySettlement = {
  __typename?: 'coineySettlement';
  _coineyPaymentId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  cardBrand?: Maybe<Scalars['String']>;
  chargeDate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  closeCashRegister?: Maybe<CloseCashRegister>;
  /** An object relationship */
  coineyPayment?: Maybe<CoineyPayment>;
  /** A computed field, executes function "coineySettlement_coineyPaymentId" */
  coineyPaymentId?: Maybe<Scalars['Int']>;
  emoneyBrand?: Maybe<Scalars['String']>;
  humanReadableIdentifier: Scalars['String'];
  id: Scalars['uuid'];
  identifier: Scalars['String'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  transactionType: Scalars['String'];
};

/** order by aggregate values of table "coineySettlement" */
export type CoineySettlementAggregateOrderBy = {
  avg?: InputMaybe<CoineySettlementAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CoineySettlementMaxOrderBy>;
  min?: InputMaybe<CoineySettlementMinOrderBy>;
  stddev?: InputMaybe<CoineySettlementStddevOrderBy>;
  stddev_pop?: InputMaybe<CoineySettlementStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CoineySettlementStddevSampOrderBy>;
  sum?: InputMaybe<CoineySettlementSumOrderBy>;
  var_pop?: InputMaybe<CoineySettlementVarPopOrderBy>;
  var_samp?: InputMaybe<CoineySettlementVarSampOrderBy>;
  variance?: InputMaybe<CoineySettlementVarianceOrderBy>;
};

/** order by avg() on columns of table "coineySettlement" */
export type CoineySettlementAvgOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coineySettlement". All fields are combined with a logical 'AND'. */
export type CoineySettlementBoolExp = {
  _and?: InputMaybe<Array<CoineySettlementBoolExp>>;
  _coineyPaymentId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<CoineySettlementBoolExp>;
  _or?: InputMaybe<Array<CoineySettlementBoolExp>>;
  amount?: InputMaybe<IntComparisonExp>;
  cardBrand?: InputMaybe<StringComparisonExp>;
  chargeDate?: InputMaybe<TimestamptzComparisonExp>;
  closeCashRegister?: InputMaybe<CloseCashRegisterBoolExp>;
  coineyPayment?: InputMaybe<CoineyPaymentBoolExp>;
  coineyPaymentId?: InputMaybe<IntComparisonExp>;
  emoneyBrand?: InputMaybe<StringComparisonExp>;
  humanReadableIdentifier?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  identifier?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  transactionType?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "coineySettlement" */
export type CoineySettlementMaxOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  cardBrand?: InputMaybe<OrderBy>;
  chargeDate?: InputMaybe<OrderBy>;
  emoneyBrand?: InputMaybe<OrderBy>;
  humanReadableIdentifier?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  identifier?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  transactionType?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "coineySettlement" */
export type CoineySettlementMinOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  cardBrand?: InputMaybe<OrderBy>;
  chargeDate?: InputMaybe<OrderBy>;
  emoneyBrand?: InputMaybe<OrderBy>;
  humanReadableIdentifier?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  identifier?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  transactionType?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "coineySettlement". */
export type CoineySettlementOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  cardBrand?: InputMaybe<OrderBy>;
  chargeDate?: InputMaybe<OrderBy>;
  closeCashRegister?: InputMaybe<CloseCashRegisterOrderBy>;
  coineyPayment?: InputMaybe<CoineyPaymentOrderBy>;
  coineyPaymentId?: InputMaybe<OrderBy>;
  emoneyBrand?: InputMaybe<OrderBy>;
  humanReadableIdentifier?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  identifier?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  transactionType?: InputMaybe<OrderBy>;
};

/** select columns of table "coineySettlement" */
export enum CoineySettlementSelectColumn {
  /** column name */
  _CoineyPaymentId = '_coineyPaymentId',
  /** column name */
  Amount = 'amount',
  /** column name */
  CardBrand = 'cardBrand',
  /** column name */
  ChargeDate = 'chargeDate',
  /** column name */
  EmoneyBrand = 'emoneyBrand',
  /** column name */
  HumanReadableIdentifier = 'humanReadableIdentifier',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TransactionType = 'transactionType'
}

/** order by stddev() on columns of table "coineySettlement" */
export type CoineySettlementStddevOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "coineySettlement" */
export type CoineySettlementStddevPopOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "coineySettlement" */
export type CoineySettlementStddevSampOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "coineySettlement" */
export type CoineySettlementStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CoineySettlementStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CoineySettlementStreamCursorValueInput = {
  _coineyPaymentId?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['Int']>;
  cardBrand?: InputMaybe<Scalars['String']>;
  chargeDate?: InputMaybe<Scalars['timestamptz']>;
  emoneyBrand?: InputMaybe<Scalars['String']>;
  humanReadableIdentifier?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  transactionType?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "coineySettlement" */
export type CoineySettlementSumOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "coineySettlement" */
export type CoineySettlementVarPopOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "coineySettlement" */
export type CoineySettlementVarSampOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "coineySettlement" */
export type CoineySettlementVarianceOrderBy = {
  _coineyPaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** columns and relationships of "company" */
export type Company = {
  __typename?: 'company';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  categories: Array<Category>;
  /** An aggregate relationship */
  categories_aggregate: CategoryAggregate;
  /** An array relationship */
  clerks: Array<Clerk>;
  companyId: Scalars['Int'];
  /** An object relationship */
  connectGameConfig?: Maybe<ConnectGameConfig>;
  /** An array relationship */
  cookingItems: Array<CookingItem>;
  /** An object relationship */
  corporation?: Maybe<Corporation>;
  corporationId: Scalars['uuid'];
  /** An array relationship */
  coupons: Array<Coupon>;
  /** An array relationship */
  dashboardAccountAccessibleCompanies: Array<DashboardAccountAccessibleCompany>;
  defaultCostTaxMethod: TaxMethodEnum;
  defaultMenuTaxMethod: TaxMethodEnum;
  enableAutoTranslation: Scalars['Boolean'];
  enableDarkTheme: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An array relationship */
  inflowSourceTags: Array<InflowSourceTag>;
  /** An aggregate relationship */
  inflowSourceTags_aggregate: InflowSourceTagAggregate;
  /** An array relationship */
  lineOfficialAccounts: Array<LineOfficialAccount>;
  /** An object relationship */
  membershipCard?: Maybe<MembershipCard>;
  /** An array relationship */
  menus: Array<Menu>;
  /** An array relationship */
  messageDeliveries: Array<MessageDelivery>;
  name: Scalars['String'];
  /** An array relationship */
  onSitePaymentDetailTypes: Array<OnSitePaymentDetailType>;
  /** An aggregate relationship */
  onSitePaymentDetailTypes_aggregate: OnSitePaymentDetailTypeAggregate;
  /** An array relationship */
  onSitePaymentDetails: Array<OnSitePaymentDetail>;
  /** An array relationship */
  onSitePaymentDiscountTypes: Array<OnSitePaymentDiscountType>;
  /** An aggregate relationship */
  onSitePaymentDiscountTypes_aggregate: OnSitePaymentDiscountTypeAggregate;
  /** An array relationship */
  onSitePaymentDiscounts: Array<OnSitePaymentDiscount>;
  /** An array relationship */
  options: Array<Option>;
  /** An aggregate relationship */
  options_aggregate: OptionAggregate;
  /** An array relationship */
  orderableTimes: Array<OrderableTime>;
  /** An array relationship */
  pikaichiOnSitePaymentDetailTypes: Array<PikaichiOnSitePaymentDetailType>;
  /** An array relationship */
  pikaichiOnSitePaymentDiscountTypes: Array<PikaichiOnSitePaymentDiscountType>;
  /** An array relationship */
  planGroups: Array<PlanGroup>;
  /** An array relationship */
  plans: Array<Plan>;
  /** An array relationship */
  questionnaireConfigs: Array<QuestionnaireConfig>;
  /** An array relationship */
  questions: Array<Question>;
  /** An array relationship */
  roles: Array<Role>;
  serial: Scalars['Int'];
  /** An object relationship */
  serviceChargeMeta?: Maybe<ServiceChargeMeta>;
  /** An array relationship */
  shopClients: Array<ShopClient>;
  /** An array relationship */
  shopDiscountTypes: Array<ShopDiscountType>;
  /** An array relationship */
  shopPaymentTypes: Array<ShopPaymentType>;
  /** An array relationship */
  shops: Array<Shop>;
  /** An object relationship */
  specificTimeSurchargeMeta?: Maybe<SpecificTimeSurchargeMeta>;
  /** An object relationship */
  steraOnSitePaymentDetailType?: Maybe<SteraOnSitePaymentDetailType>;
  /** An array relationship */
  steraOnSitePaymentDetailTypes: Array<SteraOnSitePaymentDetailType>;
  /** An array relationship */
  tableUsers: Array<TableUser>;
  /** An array relationship */
  tecAggregationMediaMaps: Array<TecAggregationMediaMap>;
  /** An array relationship */
  tecAggregationOnSitePaymentDetailTypes: Array<TecAggregationOnSitePaymentDetailType>;
};


/** columns and relationships of "company" */
export type CompanyCategoriesArgs = {
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyCategoriesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyClerksArgs = {
  distinct_on?: InputMaybe<Array<ClerkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClerkOrderBy>>;
  where?: InputMaybe<ClerkBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyCookingItemsArgs = {
  distinct_on?: InputMaybe<Array<CookingItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CookingItemOrderBy>>;
  where?: InputMaybe<CookingItemBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyCouponsArgs = {
  distinct_on?: InputMaybe<Array<CouponSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponOrderBy>>;
  where?: InputMaybe<CouponBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyDashboardAccountAccessibleCompaniesArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountAccessibleCompanySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountAccessibleCompanyOrderBy>>;
  where?: InputMaybe<DashboardAccountAccessibleCompanyBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyInflowSourceTagsArgs = {
  distinct_on?: InputMaybe<Array<InflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InflowSourceTagOrderBy>>;
  where?: InputMaybe<InflowSourceTagBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyInflowSourceTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InflowSourceTagOrderBy>>;
  where?: InputMaybe<InflowSourceTagBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyLineOfficialAccountsArgs = {
  distinct_on?: InputMaybe<Array<LineOfficialAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineOfficialAccountOrderBy>>;
  where?: InputMaybe<LineOfficialAccountBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyMenusArgs = {
  distinct_on?: InputMaybe<Array<MenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderBy>>;
  where?: InputMaybe<MenuBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyMessageDeliveriesArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliverySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryOrderBy>>;
  where?: InputMaybe<MessageDeliveryBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyOnSitePaymentDetailTypesArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyOnSitePaymentDetailTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyOnSitePaymentDetailsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyOnSitePaymentDiscountTypesArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyOnSitePaymentDiscountTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyOnSitePaymentDiscountsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyOptionsArgs = {
  distinct_on?: InputMaybe<Array<OptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OptionOrderBy>>;
  where?: InputMaybe<OptionBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyOptionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OptionOrderBy>>;
  where?: InputMaybe<OptionBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyOrderableTimesArgs = {
  distinct_on?: InputMaybe<Array<OrderableTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrderableTimeOrderBy>>;
  where?: InputMaybe<OrderableTimeBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyPikaichiOnSitePaymentDetailTypesArgs = {
  distinct_on?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<PikaichiOnSitePaymentDetailTypeBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyPikaichiOnSitePaymentDiscountTypesArgs = {
  distinct_on?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<PikaichiOnSitePaymentDiscountTypeBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyPlanGroupsArgs = {
  distinct_on?: InputMaybe<Array<PlanGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanGroupOrderBy>>;
  where?: InputMaybe<PlanGroupBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyPlansArgs = {
  distinct_on?: InputMaybe<Array<PlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOrderBy>>;
  where?: InputMaybe<PlanBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyQuestionnaireConfigsArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireConfigOrderBy>>;
  where?: InputMaybe<QuestionnaireConfigBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyQuestionsArgs = {
  distinct_on?: InputMaybe<Array<QuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionOrderBy>>;
  where?: InputMaybe<QuestionBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyRolesArgs = {
  distinct_on?: InputMaybe<Array<RoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleOrderBy>>;
  where?: InputMaybe<RoleBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyShopClientsArgs = {
  distinct_on?: InputMaybe<Array<ShopClientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopClientOrderBy>>;
  where?: InputMaybe<ShopClientBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyShopDiscountTypesArgs = {
  distinct_on?: InputMaybe<Array<ShopDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopDiscountTypeOrderBy>>;
  where?: InputMaybe<ShopDiscountTypeBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyShopPaymentTypesArgs = {
  distinct_on?: InputMaybe<Array<ShopPaymentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPaymentTypeOrderBy>>;
  where?: InputMaybe<ShopPaymentTypeBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyShopsArgs = {
  distinct_on?: InputMaybe<Array<ShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOrderBy>>;
  where?: InputMaybe<ShopBoolExp>;
};


/** columns and relationships of "company" */
export type CompanySteraOnSitePaymentDetailTypesArgs = {
  distinct_on?: InputMaybe<Array<SteraOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SteraOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<SteraOnSitePaymentDetailTypeBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyTableUsersArgs = {
  distinct_on?: InputMaybe<Array<TableUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserOrderBy>>;
  where?: InputMaybe<TableUserBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyTecAggregationMediaMapsArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationMediaMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationMediaMapOrderBy>>;
  where?: InputMaybe<TecAggregationMediaMapBoolExp>;
};


/** columns and relationships of "company" */
export type CompanyTecAggregationOnSitePaymentDetailTypesArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
};

/** columns and relationships of "companyMember" */
export type CompanyMember = {
  __typename?: 'companyMember';
  /** An object relationship */
  company: Company;
  /** An object relationship */
  customer: Customer;
  id: Scalars['uuid'];
  membershipRank: MembershipRankEnum;
  /** An object relationship */
  membershipRankByMembershiprank: MembershipRank;
};

/** aggregated selection of "companyMember" */
export type CompanyMemberAggregate = {
  __typename?: 'companyMember_aggregate';
  aggregate?: Maybe<CompanyMemberAggregateFields>;
  nodes: Array<CompanyMember>;
};

export type CompanyMemberAggregateBoolExp = {
  count?: InputMaybe<CompanyMemberAggregateBoolExpCount>;
};

export type CompanyMemberAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CompanyMemberSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CompanyMemberBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "companyMember" */
export type CompanyMemberAggregateFields = {
  __typename?: 'companyMember_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CompanyMemberMaxFields>;
  min?: Maybe<CompanyMemberMinFields>;
};


/** aggregate fields of "companyMember" */
export type CompanyMemberAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CompanyMemberSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "companyMember" */
export type CompanyMemberAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CompanyMemberMaxOrderBy>;
  min?: InputMaybe<CompanyMemberMinOrderBy>;
};

/** Boolean expression to filter rows from the table "companyMember". All fields are combined with a logical 'AND'. */
export type CompanyMemberBoolExp = {
  _and?: InputMaybe<Array<CompanyMemberBoolExp>>;
  _not?: InputMaybe<CompanyMemberBoolExp>;
  _or?: InputMaybe<Array<CompanyMemberBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  customer?: InputMaybe<CustomerBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  membershipRank?: InputMaybe<MembershipRankEnumComparisonExp>;
  membershipRankByMembershiprank?: InputMaybe<MembershipRankBoolExp>;
};

/** aggregate max on columns */
export type CompanyMemberMaxFields = {
  __typename?: 'companyMember_max_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "companyMember" */
export type CompanyMemberMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CompanyMemberMinFields = {
  __typename?: 'companyMember_min_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "companyMember" */
export type CompanyMemberMinOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "companyMember". */
export type CompanyMemberOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  customer?: InputMaybe<CustomerOrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipRank?: InputMaybe<OrderBy>;
  membershipRankByMembershiprank?: InputMaybe<MembershipRankOrderBy>;
};

/** select columns of table "companyMember" */
export enum CompanyMemberSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MembershipRank = 'membershipRank'
}

/** Streaming cursor of the table "companyMember" */
export type CompanyMemberStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CompanyMemberStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CompanyMemberStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  membershipRank?: InputMaybe<MembershipRankEnum>;
};

/** order by aggregate values of table "company" */
export type CompanyAggregateOrderBy = {
  avg?: InputMaybe<CompanyAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CompanyMaxOrderBy>;
  min?: InputMaybe<CompanyMinOrderBy>;
  stddev?: InputMaybe<CompanyStddevOrderBy>;
  stddev_pop?: InputMaybe<CompanyStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CompanyStddevSampOrderBy>;
  sum?: InputMaybe<CompanySumOrderBy>;
  var_pop?: InputMaybe<CompanyVarPopOrderBy>;
  var_samp?: InputMaybe<CompanyVarSampOrderBy>;
  variance?: InputMaybe<CompanyVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "company" */
export type CompanyArrRelInsertInput = {
  data: Array<CompanyInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompanyOnConflict>;
};

/** order by avg() on columns of table "company" */
export type CompanyAvgOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type CompanyBoolExp = {
  _and?: InputMaybe<Array<CompanyBoolExp>>;
  _not?: InputMaybe<CompanyBoolExp>;
  _or?: InputMaybe<Array<CompanyBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  categories?: InputMaybe<CategoryBoolExp>;
  categories_aggregate?: InputMaybe<CategoryAggregateBoolExp>;
  clerks?: InputMaybe<ClerkBoolExp>;
  companyId?: InputMaybe<IntComparisonExp>;
  connectGameConfig?: InputMaybe<ConnectGameConfigBoolExp>;
  cookingItems?: InputMaybe<CookingItemBoolExp>;
  corporation?: InputMaybe<CorporationBoolExp>;
  corporationId?: InputMaybe<UuidComparisonExp>;
  coupons?: InputMaybe<CouponBoolExp>;
  dashboardAccountAccessibleCompanies?: InputMaybe<DashboardAccountAccessibleCompanyBoolExp>;
  defaultCostTaxMethod?: InputMaybe<TaxMethodEnumComparisonExp>;
  defaultMenuTaxMethod?: InputMaybe<TaxMethodEnumComparisonExp>;
  enableAutoTranslation?: InputMaybe<BooleanComparisonExp>;
  enableDarkTheme?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inflowSourceTags?: InputMaybe<InflowSourceTagBoolExp>;
  inflowSourceTags_aggregate?: InputMaybe<InflowSourceTagAggregateBoolExp>;
  lineOfficialAccounts?: InputMaybe<LineOfficialAccountBoolExp>;
  membershipCard?: InputMaybe<MembershipCardBoolExp>;
  menus?: InputMaybe<MenuBoolExp>;
  messageDeliveries?: InputMaybe<MessageDeliveryBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  onSitePaymentDetailTypes?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
  onSitePaymentDetailTypes_aggregate?: InputMaybe<OnSitePaymentDetailTypeAggregateBoolExp>;
  onSitePaymentDetails?: InputMaybe<OnSitePaymentDetailBoolExp>;
  onSitePaymentDiscountTypes?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
  onSitePaymentDiscountTypes_aggregate?: InputMaybe<OnSitePaymentDiscountTypeAggregateBoolExp>;
  onSitePaymentDiscounts?: InputMaybe<OnSitePaymentDiscountBoolExp>;
  options?: InputMaybe<OptionBoolExp>;
  options_aggregate?: InputMaybe<OptionAggregateBoolExp>;
  orderableTimes?: InputMaybe<OrderableTimeBoolExp>;
  pikaichiOnSitePaymentDetailTypes?: InputMaybe<PikaichiOnSitePaymentDetailTypeBoolExp>;
  pikaichiOnSitePaymentDiscountTypes?: InputMaybe<PikaichiOnSitePaymentDiscountTypeBoolExp>;
  planGroups?: InputMaybe<PlanGroupBoolExp>;
  plans?: InputMaybe<PlanBoolExp>;
  questionnaireConfigs?: InputMaybe<QuestionnaireConfigBoolExp>;
  questions?: InputMaybe<QuestionBoolExp>;
  roles?: InputMaybe<RoleBoolExp>;
  serial?: InputMaybe<IntComparisonExp>;
  serviceChargeMeta?: InputMaybe<ServiceChargeMetaBoolExp>;
  shopClients?: InputMaybe<ShopClientBoolExp>;
  shopDiscountTypes?: InputMaybe<ShopDiscountTypeBoolExp>;
  shopPaymentTypes?: InputMaybe<ShopPaymentTypeBoolExp>;
  shops?: InputMaybe<ShopBoolExp>;
  specificTimeSurchargeMeta?: InputMaybe<SpecificTimeSurchargeMetaBoolExp>;
  steraOnSitePaymentDetailType?: InputMaybe<SteraOnSitePaymentDetailTypeBoolExp>;
  steraOnSitePaymentDetailTypes?: InputMaybe<SteraOnSitePaymentDetailTypeBoolExp>;
  tableUsers?: InputMaybe<TableUserBoolExp>;
  tecAggregationMediaMaps?: InputMaybe<TecAggregationMediaMapBoolExp>;
  tecAggregationOnSitePaymentDetailTypes?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
};

/** unique or primary key constraints on table "company" */
export enum CompanyConstraint {
  /** unique or primary key constraint on columns "id" */
  CompanyIdKey = 'company_id_key',
  /** unique or primary key constraint on columns "serial" */
  Idx_46503Primary = 'idx_46503_PRIMARY'
}

/** input type for incrementing numeric columns in table "company" */
export type CompanyIncInput = {
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company" */
export type CompanyInsertInput = {
  categories?: InputMaybe<CategoryArrRelInsertInput>;
  clerks?: InputMaybe<ClerkArrRelInsertInput>;
  connectGameConfig?: InputMaybe<ConnectGameConfigObjRelInsertInput>;
  cookingItems?: InputMaybe<CookingItemArrRelInsertInput>;
  corporation?: InputMaybe<CorporationObjRelInsertInput>;
  coupons?: InputMaybe<CouponArrRelInsertInput>;
  defaultCostTaxMethod?: InputMaybe<TaxMethodEnum>;
  defaultMenuTaxMethod?: InputMaybe<TaxMethodEnum>;
  enableDarkTheme?: InputMaybe<Scalars['Boolean']>;
  inflowSourceTags?: InputMaybe<InflowSourceTagArrRelInsertInput>;
  membershipCard?: InputMaybe<MembershipCardObjRelInsertInput>;
  menus?: InputMaybe<MenuArrRelInsertInput>;
  name: Scalars['String'];
  onSitePaymentDetailTypes?: InputMaybe<OnSitePaymentDetailTypeArrRelInsertInput>;
  onSitePaymentDiscountTypes?: InputMaybe<OnSitePaymentDiscountTypeArrRelInsertInput>;
  options?: InputMaybe<OptionArrRelInsertInput>;
  orderableTimes?: InputMaybe<OrderableTimeArrRelInsertInput>;
  pikaichiOnSitePaymentDetailTypes?: InputMaybe<PikaichiOnSitePaymentDetailTypeArrRelInsertInput>;
  pikaichiOnSitePaymentDiscountTypes?: InputMaybe<PikaichiOnSitePaymentDiscountTypeArrRelInsertInput>;
  planGroups?: InputMaybe<PlanGroupArrRelInsertInput>;
  plans?: InputMaybe<PlanArrRelInsertInput>;
  questionnaireConfigs?: InputMaybe<QuestionnaireConfigArrRelInsertInput>;
  roles?: InputMaybe<RoleArrRelInsertInput>;
  serial?: InputMaybe<Scalars['Int']>;
  shopClients?: InputMaybe<ShopClientArrRelInsertInput>;
  shopDiscountTypes?: InputMaybe<ShopDiscountTypeArrRelInsertInput>;
  shopPaymentTypes?: InputMaybe<ShopPaymentTypeArrRelInsertInput>;
  shops?: InputMaybe<ShopArrRelInsertInput>;
  steraOnSitePaymentDetailType?: InputMaybe<SteraOnSitePaymentDetailTypeObjRelInsertInput>;
  steraOnSitePaymentDetailTypes?: InputMaybe<SteraOnSitePaymentDetailTypeArrRelInsertInput>;
  tecAggregationMediaMaps?: InputMaybe<TecAggregationMediaMapArrRelInsertInput>;
  tecAggregationOnSitePaymentDetailTypes?: InputMaybe<TecAggregationOnSitePaymentDetailTypeArrRelInsertInput>;
};

/** order by max() on columns of table "company" */
export type CompanyMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  corporationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "company" */
export type CompanyMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  corporationId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "company" */
export type CompanyMutationResponse = {
  __typename?: 'company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company>;
};

/** input type for inserting object relation for remote table "company" */
export type CompanyObjRelInsertInput = {
  data: CompanyInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CompanyOnConflict>;
};

/** on_conflict condition type for table "company" */
export type CompanyOnConflict = {
  constraint: CompanyConstraint;
  update_columns?: Array<CompanyUpdateColumn>;
  where?: InputMaybe<CompanyBoolExp>;
};

/** Ordering options when selecting data from "company". */
export type CompanyOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  categories_aggregate?: InputMaybe<CategoryAggregateOrderBy>;
  clerks_aggregate?: InputMaybe<ClerkAggregateOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  connectGameConfig?: InputMaybe<ConnectGameConfigOrderBy>;
  cookingItems_aggregate?: InputMaybe<CookingItemAggregateOrderBy>;
  corporation?: InputMaybe<CorporationOrderBy>;
  corporationId?: InputMaybe<OrderBy>;
  coupons_aggregate?: InputMaybe<CouponAggregateOrderBy>;
  dashboardAccountAccessibleCompanies_aggregate?: InputMaybe<DashboardAccountAccessibleCompanyAggregateOrderBy>;
  defaultCostTaxMethod?: InputMaybe<OrderBy>;
  defaultMenuTaxMethod?: InputMaybe<OrderBy>;
  enableAutoTranslation?: InputMaybe<OrderBy>;
  enableDarkTheme?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inflowSourceTags_aggregate?: InputMaybe<InflowSourceTagAggregateOrderBy>;
  lineOfficialAccounts_aggregate?: InputMaybe<LineOfficialAccountAggregateOrderBy>;
  membershipCard?: InputMaybe<MembershipCardOrderBy>;
  menus_aggregate?: InputMaybe<MenuAggregateOrderBy>;
  messageDeliveries_aggregate?: InputMaybe<MessageDeliveryAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypes_aggregate?: InputMaybe<OnSitePaymentDetailTypeAggregateOrderBy>;
  onSitePaymentDetails_aggregate?: InputMaybe<OnSitePaymentDetailAggregateOrderBy>;
  onSitePaymentDiscountTypes_aggregate?: InputMaybe<OnSitePaymentDiscountTypeAggregateOrderBy>;
  onSitePaymentDiscounts_aggregate?: InputMaybe<OnSitePaymentDiscountAggregateOrderBy>;
  options_aggregate?: InputMaybe<OptionAggregateOrderBy>;
  orderableTimes_aggregate?: InputMaybe<OrderableTimeAggregateOrderBy>;
  pikaichiOnSitePaymentDetailTypes_aggregate?: InputMaybe<PikaichiOnSitePaymentDetailTypeAggregateOrderBy>;
  pikaichiOnSitePaymentDiscountTypes_aggregate?: InputMaybe<PikaichiOnSitePaymentDiscountTypeAggregateOrderBy>;
  planGroups_aggregate?: InputMaybe<PlanGroupAggregateOrderBy>;
  plans_aggregate?: InputMaybe<PlanAggregateOrderBy>;
  questionnaireConfigs_aggregate?: InputMaybe<QuestionnaireConfigAggregateOrderBy>;
  questions_aggregate?: InputMaybe<QuestionAggregateOrderBy>;
  roles_aggregate?: InputMaybe<RoleAggregateOrderBy>;
  serial?: InputMaybe<OrderBy>;
  serviceChargeMeta?: InputMaybe<ServiceChargeMetaOrderBy>;
  shopClients_aggregate?: InputMaybe<ShopClientAggregateOrderBy>;
  shopDiscountTypes_aggregate?: InputMaybe<ShopDiscountTypeAggregateOrderBy>;
  shopPaymentTypes_aggregate?: InputMaybe<ShopPaymentTypeAggregateOrderBy>;
  shops_aggregate?: InputMaybe<ShopAggregateOrderBy>;
  specificTimeSurchargeMeta?: InputMaybe<SpecificTimeSurchargeMetaOrderBy>;
  steraOnSitePaymentDetailType?: InputMaybe<SteraOnSitePaymentDetailTypeOrderBy>;
  steraOnSitePaymentDetailTypes_aggregate?: InputMaybe<SteraOnSitePaymentDetailTypeAggregateOrderBy>;
  tableUsers_aggregate?: InputMaybe<TableUserAggregateOrderBy>;
  tecAggregationMediaMaps_aggregate?: InputMaybe<TecAggregationMediaMapAggregateOrderBy>;
  tecAggregationOnSitePaymentDetailTypes_aggregate?: InputMaybe<TecAggregationOnSitePaymentDetailTypeAggregateOrderBy>;
};

/** primary key columns input for table: company */
export type CompanyPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "company" */
export enum CompanySelectColumn {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CorporationId = 'corporationId',
  /** column name */
  DefaultCostTaxMethod = 'defaultCostTaxMethod',
  /** column name */
  DefaultMenuTaxMethod = 'defaultMenuTaxMethod',
  /** column name */
  EnableAutoTranslation = 'enableAutoTranslation',
  /** column name */
  EnableDarkTheme = 'enableDarkTheme',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Serial = 'serial'
}

/** input type for updating data in table "company" */
export type CompanySetInput = {
  defaultCostTaxMethod?: InputMaybe<TaxMethodEnum>;
  defaultMenuTaxMethod?: InputMaybe<TaxMethodEnum>;
  enableAutoTranslation?: InputMaybe<Scalars['Boolean']>;
  enableDarkTheme?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "company" */
export type CompanyStddevOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "company" */
export type CompanyStddevPopOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "company" */
export type CompanyStddevSampOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "company" */
export type CompanyStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CompanyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CompanyStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  companyId?: InputMaybe<Scalars['Int']>;
  corporationId?: InputMaybe<Scalars['uuid']>;
  defaultCostTaxMethod?: InputMaybe<TaxMethodEnum>;
  defaultMenuTaxMethod?: InputMaybe<TaxMethodEnum>;
  enableAutoTranslation?: InputMaybe<Scalars['Boolean']>;
  enableDarkTheme?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company" */
export type CompanySumOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "company" */
export enum CompanyUpdateColumn {
  /** column name */
  DefaultCostTaxMethod = 'defaultCostTaxMethod',
  /** column name */
  DefaultMenuTaxMethod = 'defaultMenuTaxMethod',
  /** column name */
  EnableAutoTranslation = 'enableAutoTranslation',
  /** column name */
  EnableDarkTheme = 'enableDarkTheme',
  /** column name */
  Name = 'name',
  /** column name */
  Serial = 'serial'
}

export type CompanyUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CompanyIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompanySetInput>;
  /** filter the rows which have to be updated */
  where: CompanyBoolExp;
};

/** order by var_pop() on columns of table "company" */
export type CompanyVarPopOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "company" */
export type CompanyVarSampOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "company" */
export type CompanyVarianceOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** columns and relationships of "compulsoryAppetizerConfig" */
export type CompulsoryAppetizerConfig = {
  __typename?: 'compulsoryAppetizerConfig';
  autoOrderEndTime: Scalars['time'];
  autoOrderStartTime: Scalars['time'];
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  menu: Menu;
  menuId: Scalars['uuid'];
};

/** order by aggregate values of table "compulsoryAppetizerConfig" */
export type CompulsoryAppetizerConfigAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CompulsoryAppetizerConfigMaxOrderBy>;
  min?: InputMaybe<CompulsoryAppetizerConfigMinOrderBy>;
};

/** input type for inserting array relation for remote table "compulsoryAppetizerConfig" */
export type CompulsoryAppetizerConfigArrRelInsertInput = {
  data: Array<CompulsoryAppetizerConfigInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CompulsoryAppetizerConfigOnConflict>;
};

/** Boolean expression to filter rows from the table "compulsoryAppetizerConfig". All fields are combined with a logical 'AND'. */
export type CompulsoryAppetizerConfigBoolExp = {
  _and?: InputMaybe<Array<CompulsoryAppetizerConfigBoolExp>>;
  _not?: InputMaybe<CompulsoryAppetizerConfigBoolExp>;
  _or?: InputMaybe<Array<CompulsoryAppetizerConfigBoolExp>>;
  autoOrderEndTime?: InputMaybe<TimeComparisonExp>;
  autoOrderStartTime?: InputMaybe<TimeComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "compulsoryAppetizerConfig" */
export enum CompulsoryAppetizerConfigConstraint {
  /** unique or primary key constraint on columns "companyId" */
  CompulsoryAppetizerConfigCompanyIdKey = 'compulsoryAppetizerConfig_companyId_key',
  /** unique or primary key constraint on columns "id" */
  CompulsoryAppetizerConfigPkey = 'compulsoryAppetizerConfig_pkey'
}

/** input type for inserting data into table "compulsoryAppetizerConfig" */
export type CompulsoryAppetizerConfigInsertInput = {
  autoOrderEndTime: Scalars['time'];
  autoOrderStartTime: Scalars['time'];
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  menu?: InputMaybe<MenuObjRelInsertInput>;
  menuId: Scalars['uuid'];
};

/** order by max() on columns of table "compulsoryAppetizerConfig" */
export type CompulsoryAppetizerConfigMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "compulsoryAppetizerConfig" */
export type CompulsoryAppetizerConfigMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "compulsoryAppetizerConfig" */
export type CompulsoryAppetizerConfigMutationResponse = {
  __typename?: 'compulsoryAppetizerConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CompulsoryAppetizerConfig>;
};

/** on_conflict condition type for table "compulsoryAppetizerConfig" */
export type CompulsoryAppetizerConfigOnConflict = {
  constraint: CompulsoryAppetizerConfigConstraint;
  update_columns?: Array<CompulsoryAppetizerConfigUpdateColumn>;
  where?: InputMaybe<CompulsoryAppetizerConfigBoolExp>;
};

/** Ordering options when selecting data from "compulsoryAppetizerConfig". */
export type CompulsoryAppetizerConfigOrderBy = {
  autoOrderEndTime?: InputMaybe<OrderBy>;
  autoOrderStartTime?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: compulsoryAppetizerConfig */
export type CompulsoryAppetizerConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "compulsoryAppetizerConfig" */
export enum CompulsoryAppetizerConfigSelectColumn {
  /** column name */
  AutoOrderEndTime = 'autoOrderEndTime',
  /** column name */
  AutoOrderStartTime = 'autoOrderStartTime',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId'
}

/** input type for updating data in table "compulsoryAppetizerConfig" */
export type CompulsoryAppetizerConfigSetInput = {
  autoOrderEndTime?: InputMaybe<Scalars['time']>;
  autoOrderStartTime?: InputMaybe<Scalars['time']>;
  menuId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "compulsoryAppetizerConfig" */
export type CompulsoryAppetizerConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CompulsoryAppetizerConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CompulsoryAppetizerConfigStreamCursorValueInput = {
  autoOrderEndTime?: InputMaybe<Scalars['time']>;
  autoOrderStartTime?: InputMaybe<Scalars['time']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "compulsoryAppetizerConfig" */
export enum CompulsoryAppetizerConfigUpdateColumn {
  /** column name */
  AutoOrderEndTime = 'autoOrderEndTime',
  /** column name */
  AutoOrderStartTime = 'autoOrderStartTime',
  /** column name */
  MenuId = 'menuId'
}

export type CompulsoryAppetizerConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CompulsoryAppetizerConfigSetInput>;
  /** filter the rows which have to be updated */
  where: CompulsoryAppetizerConfigBoolExp;
};

/** columns and relationships of "connectGameConfig" */
export type ConnectGameConfig = {
  __typename?: 'connectGameConfig';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  /** An array relationship */
  connectGameConfigShops: Array<ConnectGameConfigShop>;
  /** An object relationship */
  consolationCoupon?: Maybe<Coupon>;
  consolationCouponId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  enabled: Scalars['Boolean'];
  endTime: Scalars['time'];
  id: Scalars['uuid'];
  isDemo: Scalars['Boolean'];
  maxWinningRate: Scalars['numeric'];
  /** An object relationship */
  prizeCoupon: Coupon;
  prizeCouponId: Scalars['uuid'];
  startImageUrl: Scalars['String'];
  startTime: Scalars['time'];
};


/** columns and relationships of "connectGameConfig" */
export type ConnectGameConfigConnectGameConfigShopsArgs = {
  distinct_on?: InputMaybe<Array<ConnectGameConfigShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConnectGameConfigShopOrderBy>>;
  where?: InputMaybe<ConnectGameConfigShopBoolExp>;
};

/** columns and relationships of "connectGameConfigShop" */
export type ConnectGameConfigShop = {
  __typename?: 'connectGameConfigShop';
  /** An object relationship */
  connectGameConfig: ConnectGameConfig;
  connectGameConfigId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "connectGameConfigShop" */
export type ConnectGameConfigShopAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ConnectGameConfigShopMaxOrderBy>;
  min?: InputMaybe<ConnectGameConfigShopMinOrderBy>;
};

/** input type for inserting array relation for remote table "connectGameConfigShop" */
export type ConnectGameConfigShopArrRelInsertInput = {
  data: Array<ConnectGameConfigShopInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ConnectGameConfigShopOnConflict>;
};

/** Boolean expression to filter rows from the table "connectGameConfigShop". All fields are combined with a logical 'AND'. */
export type ConnectGameConfigShopBoolExp = {
  _and?: InputMaybe<Array<ConnectGameConfigShopBoolExp>>;
  _not?: InputMaybe<ConnectGameConfigShopBoolExp>;
  _or?: InputMaybe<Array<ConnectGameConfigShopBoolExp>>;
  connectGameConfig?: InputMaybe<ConnectGameConfigBoolExp>;
  connectGameConfigId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "connectGameConfigShop" */
export enum ConnectGameConfigShopConstraint {
  /** unique or primary key constraint on columns "id" */
  ConnectGameConfigShopPkey = 'connectGameConfigShop_pkey',
  /** unique or primary key constraint on columns "connectGameConfigId", "shopId" */
  ConnectGameConfigShopShopIdConnectGameConfigIdKey = 'connectGameConfigShop_shopId_connectGameConfigId_key'
}

/** input type for inserting data into table "connectGameConfigShop" */
export type ConnectGameConfigShopInsertInput = {
  connectGameConfig?: InputMaybe<ConnectGameConfigObjRelInsertInput>;
  connectGameConfigId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "connectGameConfigShop" */
export type ConnectGameConfigShopMaxOrderBy = {
  connectGameConfigId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "connectGameConfigShop" */
export type ConnectGameConfigShopMinOrderBy = {
  connectGameConfigId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "connectGameConfigShop" */
export type ConnectGameConfigShopMutationResponse = {
  __typename?: 'connectGameConfigShop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ConnectGameConfigShop>;
};

/** on_conflict condition type for table "connectGameConfigShop" */
export type ConnectGameConfigShopOnConflict = {
  constraint: ConnectGameConfigShopConstraint;
  update_columns?: Array<ConnectGameConfigShopUpdateColumn>;
  where?: InputMaybe<ConnectGameConfigShopBoolExp>;
};

/** Ordering options when selecting data from "connectGameConfigShop". */
export type ConnectGameConfigShopOrderBy = {
  connectGameConfig?: InputMaybe<ConnectGameConfigOrderBy>;
  connectGameConfigId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: connectGameConfigShop */
export type ConnectGameConfigShopPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "connectGameConfigShop" */
export enum ConnectGameConfigShopSelectColumn {
  /** column name */
  ConnectGameConfigId = 'connectGameConfigId',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "connectGameConfigShop" */
export type ConnectGameConfigShopSetInput = {
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "connectGameConfigShop" */
export type ConnectGameConfigShopStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ConnectGameConfigShopStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ConnectGameConfigShopStreamCursorValueInput = {
  connectGameConfigId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "connectGameConfigShop" */
export enum ConnectGameConfigShopUpdateColumn {
  /** column name */
  ShopId = 'shopId'
}

export type ConnectGameConfigShopUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConnectGameConfigShopSetInput>;
  /** filter the rows which have to be updated */
  where: ConnectGameConfigShopBoolExp;
};

/** order by aggregate values of table "connectGameConfig" */
export type ConnectGameConfigAggregateOrderBy = {
  avg?: InputMaybe<ConnectGameConfigAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ConnectGameConfigMaxOrderBy>;
  min?: InputMaybe<ConnectGameConfigMinOrderBy>;
  stddev?: InputMaybe<ConnectGameConfigStddevOrderBy>;
  stddev_pop?: InputMaybe<ConnectGameConfigStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ConnectGameConfigStddevSampOrderBy>;
  sum?: InputMaybe<ConnectGameConfigSumOrderBy>;
  var_pop?: InputMaybe<ConnectGameConfigVarPopOrderBy>;
  var_samp?: InputMaybe<ConnectGameConfigVarSampOrderBy>;
  variance?: InputMaybe<ConnectGameConfigVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "connectGameConfig" */
export type ConnectGameConfigArrRelInsertInput = {
  data: Array<ConnectGameConfigInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ConnectGameConfigOnConflict>;
};

/** order by avg() on columns of table "connectGameConfig" */
export type ConnectGameConfigAvgOrderBy = {
  maxWinningRate?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "connectGameConfig". All fields are combined with a logical 'AND'. */
export type ConnectGameConfigBoolExp = {
  _and?: InputMaybe<Array<ConnectGameConfigBoolExp>>;
  _not?: InputMaybe<ConnectGameConfigBoolExp>;
  _or?: InputMaybe<Array<ConnectGameConfigBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  connectGameConfigShops?: InputMaybe<ConnectGameConfigShopBoolExp>;
  consolationCoupon?: InputMaybe<CouponBoolExp>;
  consolationCouponId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  enabled?: InputMaybe<BooleanComparisonExp>;
  endTime?: InputMaybe<TimeComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDemo?: InputMaybe<BooleanComparisonExp>;
  maxWinningRate?: InputMaybe<NumericComparisonExp>;
  prizeCoupon?: InputMaybe<CouponBoolExp>;
  prizeCouponId?: InputMaybe<UuidComparisonExp>;
  startImageUrl?: InputMaybe<StringComparisonExp>;
  startTime?: InputMaybe<TimeComparisonExp>;
};

/** unique or primary key constraints on table "connectGameConfig" */
export enum ConnectGameConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  ConnectGameConfigPkey = 'connectGameConfig_pkey'
}

/** input type for incrementing numeric columns in table "connectGameConfig" */
export type ConnectGameConfigIncInput = {
  maxWinningRate?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "connectGameConfig" */
export type ConnectGameConfigInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  connectGameConfigShops?: InputMaybe<ConnectGameConfigShopArrRelInsertInput>;
  consolationCoupon?: InputMaybe<CouponObjRelInsertInput>;
  consolationCouponId?: InputMaybe<Scalars['uuid']>;
  enabled: Scalars['Boolean'];
  endTime: Scalars['time'];
  maxWinningRate: Scalars['numeric'];
  prizeCoupon?: InputMaybe<CouponObjRelInsertInput>;
  prizeCouponId: Scalars['uuid'];
  startImageUrl: Scalars['String'];
  startTime: Scalars['time'];
};

/** order by max() on columns of table "connectGameConfig" */
export type ConnectGameConfigMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  consolationCouponId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWinningRate?: InputMaybe<OrderBy>;
  prizeCouponId?: InputMaybe<OrderBy>;
  startImageUrl?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "connectGameConfig" */
export type ConnectGameConfigMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  consolationCouponId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxWinningRate?: InputMaybe<OrderBy>;
  prizeCouponId?: InputMaybe<OrderBy>;
  startImageUrl?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "connectGameConfig" */
export type ConnectGameConfigMutationResponse = {
  __typename?: 'connectGameConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ConnectGameConfig>;
};

/** input type for inserting object relation for remote table "connectGameConfig" */
export type ConnectGameConfigObjRelInsertInput = {
  data: ConnectGameConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ConnectGameConfigOnConflict>;
};

/** on_conflict condition type for table "connectGameConfig" */
export type ConnectGameConfigOnConflict = {
  constraint: ConnectGameConfigConstraint;
  update_columns?: Array<ConnectGameConfigUpdateColumn>;
  where?: InputMaybe<ConnectGameConfigBoolExp>;
};

/** Ordering options when selecting data from "connectGameConfig". */
export type ConnectGameConfigOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  connectGameConfigShops_aggregate?: InputMaybe<ConnectGameConfigShopAggregateOrderBy>;
  consolationCoupon?: InputMaybe<CouponOrderBy>;
  consolationCouponId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  enabled?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDemo?: InputMaybe<OrderBy>;
  maxWinningRate?: InputMaybe<OrderBy>;
  prizeCoupon?: InputMaybe<CouponOrderBy>;
  prizeCouponId?: InputMaybe<OrderBy>;
  startImageUrl?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: connectGameConfig */
export type ConnectGameConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "connectGameConfig" */
export enum ConnectGameConfigSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  ConsolationCouponId = 'consolationCouponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  Id = 'id',
  /** column name */
  IsDemo = 'isDemo',
  /** column name */
  MaxWinningRate = 'maxWinningRate',
  /** column name */
  PrizeCouponId = 'prizeCouponId',
  /** column name */
  StartImageUrl = 'startImageUrl',
  /** column name */
  StartTime = 'startTime'
}

/** input type for updating data in table "connectGameConfig" */
export type ConnectGameConfigSetInput = {
  consolationCouponId?: InputMaybe<Scalars['uuid']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['time']>;
  maxWinningRate?: InputMaybe<Scalars['numeric']>;
  prizeCouponId?: InputMaybe<Scalars['uuid']>;
  startImageUrl?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['time']>;
};

/** order by stddev() on columns of table "connectGameConfig" */
export type ConnectGameConfigStddevOrderBy = {
  maxWinningRate?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "connectGameConfig" */
export type ConnectGameConfigStddevPopOrderBy = {
  maxWinningRate?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "connectGameConfig" */
export type ConnectGameConfigStddevSampOrderBy = {
  maxWinningRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "connectGameConfig" */
export type ConnectGameConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ConnectGameConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ConnectGameConfigStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  consolationCouponId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDemo?: InputMaybe<Scalars['Boolean']>;
  maxWinningRate?: InputMaybe<Scalars['numeric']>;
  prizeCouponId?: InputMaybe<Scalars['uuid']>;
  startImageUrl?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['time']>;
};

/** order by sum() on columns of table "connectGameConfig" */
export type ConnectGameConfigSumOrderBy = {
  maxWinningRate?: InputMaybe<OrderBy>;
};

/** update columns of table "connectGameConfig" */
export enum ConnectGameConfigUpdateColumn {
  /** column name */
  ConsolationCouponId = 'consolationCouponId',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  MaxWinningRate = 'maxWinningRate',
  /** column name */
  PrizeCouponId = 'prizeCouponId',
  /** column name */
  StartImageUrl = 'startImageUrl',
  /** column name */
  StartTime = 'startTime'
}

export type ConnectGameConfigUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ConnectGameConfigIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConnectGameConfigSetInput>;
  /** filter the rows which have to be updated */
  where: ConnectGameConfigBoolExp;
};

/** order by var_pop() on columns of table "connectGameConfig" */
export type ConnectGameConfigVarPopOrderBy = {
  maxWinningRate?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "connectGameConfig" */
export type ConnectGameConfigVarSampOrderBy = {
  maxWinningRate?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "connectGameConfig" */
export type ConnectGameConfigVarianceOrderBy = {
  maxWinningRate?: InputMaybe<OrderBy>;
};

/** columns and relationships of "cookingItem" */
export type CookingItem = {
  __typename?: 'cookingItem';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  cookingItemId: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  serial: Scalars['Int'];
  /** An object relationship */
  shop: Shop;
  /** An object relationship */
  shopCookingItemRole?: Maybe<ShopCookingItemRole>;
  shopId: Scalars['uuid'];
  /** An array relationship */
  shopMenuCookingItems: Array<ShopMenuCookingItem>;
};


/** columns and relationships of "cookingItem" */
export type CookingItemShopMenuCookingItemsArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuCookingItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuCookingItemOrderBy>>;
  where?: InputMaybe<ShopMenuCookingItemBoolExp>;
};

/** order by aggregate values of table "cookingItem" */
export type CookingItemAggregateOrderBy = {
  avg?: InputMaybe<CookingItemAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CookingItemMaxOrderBy>;
  min?: InputMaybe<CookingItemMinOrderBy>;
  stddev?: InputMaybe<CookingItemStddevOrderBy>;
  stddev_pop?: InputMaybe<CookingItemStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CookingItemStddevSampOrderBy>;
  sum?: InputMaybe<CookingItemSumOrderBy>;
  var_pop?: InputMaybe<CookingItemVarPopOrderBy>;
  var_samp?: InputMaybe<CookingItemVarSampOrderBy>;
  variance?: InputMaybe<CookingItemVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "cookingItem" */
export type CookingItemArrRelInsertInput = {
  data: Array<CookingItemInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CookingItemOnConflict>;
};

/** order by avg() on columns of table "cookingItem" */
export type CookingItemAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "cookingItem". All fields are combined with a logical 'AND'. */
export type CookingItemBoolExp = {
  _and?: InputMaybe<Array<CookingItemBoolExp>>;
  _not?: InputMaybe<CookingItemBoolExp>;
  _or?: InputMaybe<Array<CookingItemBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  cookingItemId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopCookingItemRole?: InputMaybe<ShopCookingItemRoleBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopMenuCookingItems?: InputMaybe<ShopMenuCookingItemBoolExp>;
};

/** unique or primary key constraints on table "cookingItem" */
export enum CookingItemConstraint {
  /** unique or primary key constraint on columns "cookingItemId" */
  CookingItemCookingItemIdKey = 'cookingItem_cookingItemId_key',
  /** unique or primary key constraint on columns "serial", "shopId" */
  KitchenSlipItemIdShopIdKey = 'kitchenSlipItem_id_shopId_key',
  /** unique or primary key constraint on columns "serial" */
  KitchenslipitemPkey = 'kitchenslipitem_pkey'
}

/** input type for incrementing numeric columns in table "cookingItem" */
export type CookingItemIncInput = {
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "cookingItem" */
export type CookingItemInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  cookingItemId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  serial?: InputMaybe<Scalars['Int']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopCookingItemRole?: InputMaybe<ShopCookingItemRoleObjRelInsertInput>;
  shopId: Scalars['uuid'];
  shopMenuCookingItems?: InputMaybe<ShopMenuCookingItemArrRelInsertInput>;
};

/** order by max() on columns of table "cookingItem" */
export type CookingItemMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  cookingItemId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "cookingItem" */
export type CookingItemMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  cookingItemId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "cookingItem" */
export type CookingItemMutationResponse = {
  __typename?: 'cookingItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CookingItem>;
};

/** input type for inserting object relation for remote table "cookingItem" */
export type CookingItemObjRelInsertInput = {
  data: CookingItemInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CookingItemOnConflict>;
};

/** on_conflict condition type for table "cookingItem" */
export type CookingItemOnConflict = {
  constraint: CookingItemConstraint;
  update_columns?: Array<CookingItemUpdateColumn>;
  where?: InputMaybe<CookingItemBoolExp>;
};

/** Ordering options when selecting data from "cookingItem". */
export type CookingItemOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  cookingItemId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopCookingItemRole?: InputMaybe<ShopCookingItemRoleOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopMenuCookingItems_aggregate?: InputMaybe<ShopMenuCookingItemAggregateOrderBy>;
};

/** primary key columns input for table: cookingItem */
export type CookingItemPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "cookingItem" */
export enum CookingItemSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CookingItemId = 'cookingItemId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "cookingItem" */
export type CookingItemSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "cookingItem" */
export type CookingItemStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "cookingItem" */
export type CookingItemStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "cookingItem" */
export type CookingItemStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "cookingItem" */
export type CookingItemStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CookingItemStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CookingItemStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  cookingItemId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "cookingItem" */
export type CookingItemSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "cookingItem" */
export enum CookingItemUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopId = 'shopId'
}

export type CookingItemUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CookingItemIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CookingItemSetInput>;
  /** filter the rows which have to be updated */
  where: CookingItemBoolExp;
};

/** order by var_pop() on columns of table "cookingItem" */
export type CookingItemVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "cookingItem" */
export type CookingItemVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "cookingItem" */
export type CookingItemVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** columns and relationships of "corporation" */
export type Corporation = {
  __typename?: 'corporation';
  /** An array relationship */
  companies: Array<Company>;
  /** An array relationship */
  dashboardAccounts: Array<DashboardAccount>;
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "corporation" */
export type CorporationCompaniesArgs = {
  distinct_on?: InputMaybe<Array<CompanySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyOrderBy>>;
  where?: InputMaybe<CompanyBoolExp>;
};


/** columns and relationships of "corporation" */
export type CorporationDashboardAccountsArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountOrderBy>>;
  where?: InputMaybe<DashboardAccountBoolExp>;
};

/** Boolean expression to filter rows from the table "corporation". All fields are combined with a logical 'AND'. */
export type CorporationBoolExp = {
  _and?: InputMaybe<Array<CorporationBoolExp>>;
  _not?: InputMaybe<CorporationBoolExp>;
  _or?: InputMaybe<Array<CorporationBoolExp>>;
  companies?: InputMaybe<CompanyBoolExp>;
  dashboardAccounts?: InputMaybe<DashboardAccountBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "corporation" */
export enum CorporationConstraint {
  /** unique or primary key constraint on columns "id" */
  CorporationPkey = 'corporation_pkey'
}

/** input type for inserting data into table "corporation" */
export type CorporationInsertInput = {
  companies?: InputMaybe<CompanyArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
};

/** response of any mutation on the table "corporation" */
export type CorporationMutationResponse = {
  __typename?: 'corporation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Corporation>;
};

/** input type for inserting object relation for remote table "corporation" */
export type CorporationObjRelInsertInput = {
  data: CorporationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CorporationOnConflict>;
};

/** on_conflict condition type for table "corporation" */
export type CorporationOnConflict = {
  constraint: CorporationConstraint;
  update_columns?: Array<CorporationUpdateColumn>;
  where?: InputMaybe<CorporationBoolExp>;
};

/** Ordering options when selecting data from "corporation". */
export type CorporationOrderBy = {
  companies_aggregate?: InputMaybe<CompanyAggregateOrderBy>;
  dashboardAccounts_aggregate?: InputMaybe<DashboardAccountAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: corporation */
export type CorporationPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "corporation" */
export enum CorporationSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "corporation" */
export type CorporationSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "corporation" */
export type CorporationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CorporationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CorporationStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "corporation" */
export enum CorporationUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type CorporationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CorporationSetInput>;
  /** filter the rows which have to be updated */
  where: CorporationBoolExp;
};

/** columns and relationships of "coupon" */
export type Coupon = {
  __typename?: 'coupon';
  availableDays?: Maybe<Scalars['Int']>;
  availableFrom: Scalars['timestamptz'];
  availableUntil?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  /** An array relationship */
  consolationCouponGameConfigs: Array<ConnectGameConfig>;
  content?: Maybe<Scalars['String']>;
  /** An array relationship */
  couponAppliedEvents: Array<CouponAppliedEvent>;
  /** An aggregate relationship */
  couponAppliedEvents_aggregate: CouponAppliedEventAggregate;
  /** An object relationship */
  couponMenuDiscount?: Maybe<CouponMenuDiscount>;
  /** An array relationship */
  couponMessages: Array<MessageDeliveryMessageCouponTypeMeta>;
  couponType: CouponTypeEnum;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  imageUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  membershipRankConfigs: Array<MembershipRankConfig>;
  monetaryValue: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  prizeCouponGameConfigs: Array<ConnectGameConfig>;
  /** An array relationship */
  questionnaireConfigs: Array<QuestionnaireConfig>;
  termsOfUse: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "coupon" */
export type CouponConsolationCouponGameConfigsArgs = {
  distinct_on?: InputMaybe<Array<ConnectGameConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConnectGameConfigOrderBy>>;
  where?: InputMaybe<ConnectGameConfigBoolExp>;
};


/** columns and relationships of "coupon" */
export type CouponCouponAppliedEventsArgs = {
  distinct_on?: InputMaybe<Array<CouponAppliedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponAppliedEventOrderBy>>;
  where?: InputMaybe<CouponAppliedEventBoolExp>;
};


/** columns and relationships of "coupon" */
export type CouponCouponAppliedEventsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CouponAppliedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponAppliedEventOrderBy>>;
  where?: InputMaybe<CouponAppliedEventBoolExp>;
};


/** columns and relationships of "coupon" */
export type CouponCouponMessagesArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageCouponTypeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageCouponTypeMetaOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageCouponTypeMetaBoolExp>;
};


/** columns and relationships of "coupon" */
export type CouponMembershipRankConfigsArgs = {
  distinct_on?: InputMaybe<Array<MembershipRankConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipRankConfigOrderBy>>;
  where?: InputMaybe<MembershipRankConfigBoolExp>;
};


/** columns and relationships of "coupon" */
export type CouponPrizeCouponGameConfigsArgs = {
  distinct_on?: InputMaybe<Array<ConnectGameConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConnectGameConfigOrderBy>>;
  where?: InputMaybe<ConnectGameConfigBoolExp>;
};


/** columns and relationships of "coupon" */
export type CouponQuestionnaireConfigsArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireConfigOrderBy>>;
  where?: InputMaybe<QuestionnaireConfigBoolExp>;
};

/** columns and relationships of "couponAppliedEvent" */
export type CouponAppliedEvent = {
  __typename?: 'couponAppliedEvent';
  appliedAt: Scalars['timestamptz'];
  /** An object relationship */
  coupon: Coupon;
  couponId: Scalars['uuid'];
  couponIssuer?: Maybe<CouponIssuerEnum>;
  id: Scalars['uuid'];
  /** An object relationship */
  messageDelivery?: Maybe<MessageDelivery>;
  messageDeliveryId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "couponAppliedEvent" */
export type CouponAppliedEventAggregate = {
  __typename?: 'couponAppliedEvent_aggregate';
  aggregate?: Maybe<CouponAppliedEventAggregateFields>;
  nodes: Array<CouponAppliedEvent>;
};

export type CouponAppliedEventAggregateBoolExp = {
  count?: InputMaybe<CouponAppliedEventAggregateBoolExpCount>;
};

export type CouponAppliedEventAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CouponAppliedEventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CouponAppliedEventBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "couponAppliedEvent" */
export type CouponAppliedEventAggregateFields = {
  __typename?: 'couponAppliedEvent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CouponAppliedEventMaxFields>;
  min?: Maybe<CouponAppliedEventMinFields>;
};


/** aggregate fields of "couponAppliedEvent" */
export type CouponAppliedEventAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CouponAppliedEventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "couponAppliedEvent" */
export type CouponAppliedEventAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CouponAppliedEventMaxOrderBy>;
  min?: InputMaybe<CouponAppliedEventMinOrderBy>;
};

/** Boolean expression to filter rows from the table "couponAppliedEvent". All fields are combined with a logical 'AND'. */
export type CouponAppliedEventBoolExp = {
  _and?: InputMaybe<Array<CouponAppliedEventBoolExp>>;
  _not?: InputMaybe<CouponAppliedEventBoolExp>;
  _or?: InputMaybe<Array<CouponAppliedEventBoolExp>>;
  appliedAt?: InputMaybe<TimestamptzComparisonExp>;
  coupon?: InputMaybe<CouponBoolExp>;
  couponId?: InputMaybe<UuidComparisonExp>;
  couponIssuer?: InputMaybe<CouponIssuerEnumComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDelivery?: InputMaybe<MessageDeliveryBoolExp>;
  messageDeliveryId?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type CouponAppliedEventMaxFields = {
  __typename?: 'couponAppliedEvent_max_fields';
  appliedAt?: Maybe<Scalars['timestamptz']>;
  couponId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  messageDeliveryId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "couponAppliedEvent" */
export type CouponAppliedEventMaxOrderBy = {
  appliedAt?: InputMaybe<OrderBy>;
  couponId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CouponAppliedEventMinFields = {
  __typename?: 'couponAppliedEvent_min_fields';
  appliedAt?: Maybe<Scalars['timestamptz']>;
  couponId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  messageDeliveryId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "couponAppliedEvent" */
export type CouponAppliedEventMinOrderBy = {
  appliedAt?: InputMaybe<OrderBy>;
  couponId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "couponAppliedEvent". */
export type CouponAppliedEventOrderBy = {
  appliedAt?: InputMaybe<OrderBy>;
  coupon?: InputMaybe<CouponOrderBy>;
  couponId?: InputMaybe<OrderBy>;
  couponIssuer?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDelivery?: InputMaybe<MessageDeliveryOrderBy>;
  messageDeliveryId?: InputMaybe<OrderBy>;
};

/** select columns of table "couponAppliedEvent" */
export enum CouponAppliedEventSelectColumn {
  /** column name */
  AppliedAt = 'appliedAt',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CouponIssuer = 'couponIssuer',
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryId = 'messageDeliveryId'
}

/** Streaming cursor of the table "couponAppliedEvent" */
export type CouponAppliedEventStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CouponAppliedEventStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CouponAppliedEventStreamCursorValueInput = {
  appliedAt?: InputMaybe<Scalars['timestamptz']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  couponIssuer?: InputMaybe<CouponIssuerEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryId?: InputMaybe<Scalars['uuid']>;
};

export enum CouponIssuerEnum {
  Questionnaire = 'questionnaire'
}

/** Boolean expression to compare columns of type "couponIssuer_enum". All fields are combined with logical 'AND'. */
export type CouponIssuerEnumComparisonExp = {
  _eq?: InputMaybe<CouponIssuerEnum>;
  _in?: InputMaybe<Array<CouponIssuerEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CouponIssuerEnum>;
  _nin?: InputMaybe<Array<CouponIssuerEnum>>;
};

/** columns and relationships of "couponMenuDiscount" */
export type CouponMenuDiscount = {
  __typename?: 'couponMenuDiscount';
  _menuId: Scalars['Int'];
  /** An object relationship */
  coupon: Coupon;
  couponId: Scalars['uuid'];
  discountedPrice: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  menu: Menu;
  menuId: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "couponMenuDiscount". All fields are combined with a logical 'AND'. */
export type CouponMenuDiscountBoolExp = {
  _and?: InputMaybe<Array<CouponMenuDiscountBoolExp>>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<CouponMenuDiscountBoolExp>;
  _or?: InputMaybe<Array<CouponMenuDiscountBoolExp>>;
  coupon?: InputMaybe<CouponBoolExp>;
  couponId?: InputMaybe<UuidComparisonExp>;
  discountedPrice?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "couponMenuDiscount" */
export enum CouponMenuDiscountConstraint {
  /** unique or primary key constraint on columns "couponId" */
  CouponMenuDiscountCouponIdKey = 'couponMenuDiscount_couponId_key',
  /** unique or primary key constraint on columns "id" */
  CouponMenuDiscountPkey = 'couponMenuDiscount_pkey'
}

/** input type for incrementing numeric columns in table "couponMenuDiscount" */
export type CouponMenuDiscountIncInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  discountedPrice?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "couponMenuDiscount" */
export type CouponMenuDiscountInsertInput = {
  _menuId: Scalars['Int'];
  coupon?: InputMaybe<CouponObjRelInsertInput>;
  couponId: Scalars['uuid'];
  discountedPrice: Scalars['Int'];
  menu?: InputMaybe<MenuObjRelInsertInput>;
  menuId: Scalars['uuid'];
};

/** response of any mutation on the table "couponMenuDiscount" */
export type CouponMenuDiscountMutationResponse = {
  __typename?: 'couponMenuDiscount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CouponMenuDiscount>;
};

/** input type for inserting object relation for remote table "couponMenuDiscount" */
export type CouponMenuDiscountObjRelInsertInput = {
  data: CouponMenuDiscountInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CouponMenuDiscountOnConflict>;
};

/** on_conflict condition type for table "couponMenuDiscount" */
export type CouponMenuDiscountOnConflict = {
  constraint: CouponMenuDiscountConstraint;
  update_columns?: Array<CouponMenuDiscountUpdateColumn>;
  where?: InputMaybe<CouponMenuDiscountBoolExp>;
};

/** Ordering options when selecting data from "couponMenuDiscount". */
export type CouponMenuDiscountOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  coupon?: InputMaybe<CouponOrderBy>;
  couponId?: InputMaybe<OrderBy>;
  discountedPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: couponMenuDiscount */
export type CouponMenuDiscountPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "couponMenuDiscount" */
export enum CouponMenuDiscountSelectColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  DiscountedPrice = 'discountedPrice',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId'
}

/** input type for updating data in table "couponMenuDiscount" */
export type CouponMenuDiscountSetInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  discountedPrice?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "couponMenuDiscount" */
export type CouponMenuDiscountStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CouponMenuDiscountStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CouponMenuDiscountStreamCursorValueInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  couponId?: InputMaybe<Scalars['uuid']>;
  discountedPrice?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "couponMenuDiscount" */
export enum CouponMenuDiscountUpdateColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  DiscountedPrice = 'discountedPrice',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId'
}

export type CouponMenuDiscountUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CouponMenuDiscountIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CouponMenuDiscountSetInput>;
  /** filter the rows which have to be updated */
  where: CouponMenuDiscountBoolExp;
};

export enum CouponTypeEnum {
  CheckoutDiscount = 'checkoutDiscount',
  LimitedMenu = 'limitedMenu',
  MenuDiscount = 'menuDiscount',
  Ticket = 'ticket'
}

/** Boolean expression to compare columns of type "couponType_enum". All fields are combined with logical 'AND'. */
export type CouponTypeEnumComparisonExp = {
  _eq?: InputMaybe<CouponTypeEnum>;
  _in?: InputMaybe<Array<CouponTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CouponTypeEnum>;
  _nin?: InputMaybe<Array<CouponTypeEnum>>;
};

/** order by aggregate values of table "coupon" */
export type CouponAggregateOrderBy = {
  avg?: InputMaybe<CouponAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CouponMaxOrderBy>;
  min?: InputMaybe<CouponMinOrderBy>;
  stddev?: InputMaybe<CouponStddevOrderBy>;
  stddev_pop?: InputMaybe<CouponStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CouponStddevSampOrderBy>;
  sum?: InputMaybe<CouponSumOrderBy>;
  var_pop?: InputMaybe<CouponVarPopOrderBy>;
  var_samp?: InputMaybe<CouponVarSampOrderBy>;
  variance?: InputMaybe<CouponVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "coupon" */
export type CouponArrRelInsertInput = {
  data: Array<CouponInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CouponOnConflict>;
};

/** order by avg() on columns of table "coupon" */
export type CouponAvgOrderBy = {
  availableDays?: InputMaybe<OrderBy>;
  monetaryValue?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "coupon". All fields are combined with a logical 'AND'. */
export type CouponBoolExp = {
  _and?: InputMaybe<Array<CouponBoolExp>>;
  _not?: InputMaybe<CouponBoolExp>;
  _or?: InputMaybe<Array<CouponBoolExp>>;
  availableDays?: InputMaybe<IntComparisonExp>;
  availableFrom?: InputMaybe<TimestamptzComparisonExp>;
  availableUntil?: InputMaybe<TimestamptzComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  consolationCouponGameConfigs?: InputMaybe<ConnectGameConfigBoolExp>;
  content?: InputMaybe<StringComparisonExp>;
  couponAppliedEvents?: InputMaybe<CouponAppliedEventBoolExp>;
  couponAppliedEvents_aggregate?: InputMaybe<CouponAppliedEventAggregateBoolExp>;
  couponMenuDiscount?: InputMaybe<CouponMenuDiscountBoolExp>;
  couponMessages?: InputMaybe<MessageDeliveryMessageCouponTypeMetaBoolExp>;
  couponType?: InputMaybe<CouponTypeEnumComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  membershipRankConfigs?: InputMaybe<MembershipRankConfigBoolExp>;
  monetaryValue?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  prizeCouponGameConfigs?: InputMaybe<ConnectGameConfigBoolExp>;
  questionnaireConfigs?: InputMaybe<QuestionnaireConfigBoolExp>;
  termsOfUse?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "coupon" */
export enum CouponConstraint {
  /** unique or primary key constraint on columns "id" */
  CouponPkey = 'coupon_pkey'
}

/** input type for incrementing numeric columns in table "coupon" */
export type CouponIncInput = {
  availableDays?: InputMaybe<Scalars['Int']>;
  monetaryValue?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "coupon" */
export type CouponInsertInput = {
  availableDays?: InputMaybe<Scalars['Int']>;
  availableFrom: Scalars['timestamptz'];
  availableUntil?: InputMaybe<Scalars['timestamptz']>;
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  consolationCouponGameConfigs?: InputMaybe<ConnectGameConfigArrRelInsertInput>;
  content?: InputMaybe<Scalars['String']>;
  couponMenuDiscount?: InputMaybe<CouponMenuDiscountObjRelInsertInput>;
  couponType: CouponTypeEnum;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  monetaryValue?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  prizeCouponGameConfigs?: InputMaybe<ConnectGameConfigArrRelInsertInput>;
  questionnaireConfigs?: InputMaybe<QuestionnaireConfigArrRelInsertInput>;
  termsOfUse: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "coupon" */
export type CouponMaxOrderBy = {
  availableDays?: InputMaybe<OrderBy>;
  availableFrom?: InputMaybe<OrderBy>;
  availableUntil?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  monetaryValue?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  termsOfUse?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "coupon" */
export type CouponMinOrderBy = {
  availableDays?: InputMaybe<OrderBy>;
  availableFrom?: InputMaybe<OrderBy>;
  availableUntil?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  monetaryValue?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  termsOfUse?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "coupon" */
export type CouponMutationResponse = {
  __typename?: 'coupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coupon>;
};

/** input type for inserting object relation for remote table "coupon" */
export type CouponObjRelInsertInput = {
  data: CouponInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CouponOnConflict>;
};

/** on_conflict condition type for table "coupon" */
export type CouponOnConflict = {
  constraint: CouponConstraint;
  update_columns?: Array<CouponUpdateColumn>;
  where?: InputMaybe<CouponBoolExp>;
};

/** Ordering options when selecting data from "coupon". */
export type CouponOrderBy = {
  availableDays?: InputMaybe<OrderBy>;
  availableFrom?: InputMaybe<OrderBy>;
  availableUntil?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  consolationCouponGameConfigs_aggregate?: InputMaybe<ConnectGameConfigAggregateOrderBy>;
  content?: InputMaybe<OrderBy>;
  couponAppliedEvents_aggregate?: InputMaybe<CouponAppliedEventAggregateOrderBy>;
  couponMenuDiscount?: InputMaybe<CouponMenuDiscountOrderBy>;
  couponMessages_aggregate?: InputMaybe<MessageDeliveryMessageCouponTypeMetaAggregateOrderBy>;
  couponType?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  membershipRankConfigs_aggregate?: InputMaybe<MembershipRankConfigAggregateOrderBy>;
  monetaryValue?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  prizeCouponGameConfigs_aggregate?: InputMaybe<ConnectGameConfigAggregateOrderBy>;
  questionnaireConfigs_aggregate?: InputMaybe<QuestionnaireConfigAggregateOrderBy>;
  termsOfUse?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: coupon */
export type CouponPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "coupon" */
export enum CouponSelectColumn {
  /** column name */
  AvailableDays = 'availableDays',
  /** column name */
  AvailableFrom = 'availableFrom',
  /** column name */
  AvailableUntil = 'availableUntil',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Content = 'content',
  /** column name */
  CouponType = 'couponType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  MonetaryValue = 'monetaryValue',
  /** column name */
  Name = 'name',
  /** column name */
  TermsOfUse = 'termsOfUse',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "coupon" */
export type CouponSetInput = {
  availableDays?: InputMaybe<Scalars['Int']>;
  availableFrom?: InputMaybe<Scalars['timestamptz']>;
  availableUntil?: InputMaybe<Scalars['timestamptz']>;
  content?: InputMaybe<Scalars['String']>;
  couponType?: InputMaybe<CouponTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  monetaryValue?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  termsOfUse?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "coupon" */
export type CouponStddevOrderBy = {
  availableDays?: InputMaybe<OrderBy>;
  monetaryValue?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "coupon" */
export type CouponStddevPopOrderBy = {
  availableDays?: InputMaybe<OrderBy>;
  monetaryValue?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "coupon" */
export type CouponStddevSampOrderBy = {
  availableDays?: InputMaybe<OrderBy>;
  monetaryValue?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "coupon" */
export type CouponStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CouponStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CouponStreamCursorValueInput = {
  availableDays?: InputMaybe<Scalars['Int']>;
  availableFrom?: InputMaybe<Scalars['timestamptz']>;
  availableUntil?: InputMaybe<Scalars['timestamptz']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  content?: InputMaybe<Scalars['String']>;
  couponType?: InputMaybe<CouponTypeEnum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  monetaryValue?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  termsOfUse?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "coupon" */
export type CouponSumOrderBy = {
  availableDays?: InputMaybe<OrderBy>;
  monetaryValue?: InputMaybe<OrderBy>;
};

/** update columns of table "coupon" */
export enum CouponUpdateColumn {
  /** column name */
  AvailableDays = 'availableDays',
  /** column name */
  AvailableFrom = 'availableFrom',
  /** column name */
  AvailableUntil = 'availableUntil',
  /** column name */
  Content = 'content',
  /** column name */
  CouponType = 'couponType',
  /** column name */
  Description = 'description',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  MonetaryValue = 'monetaryValue',
  /** column name */
  Name = 'name',
  /** column name */
  TermsOfUse = 'termsOfUse',
  /** column name */
  Title = 'title'
}

export type CouponUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CouponIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CouponSetInput>;
  /** filter the rows which have to be updated */
  where: CouponBoolExp;
};

/** order by var_pop() on columns of table "coupon" */
export type CouponVarPopOrderBy = {
  availableDays?: InputMaybe<OrderBy>;
  monetaryValue?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "coupon" */
export type CouponVarSampOrderBy = {
  availableDays?: InputMaybe<OrderBy>;
  monetaryValue?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "coupon" */
export type CouponVarianceOrderBy = {
  availableDays?: InputMaybe<OrderBy>;
  monetaryValue?: InputMaybe<OrderBy>;
};

/** ordering argument of a cursor */
export enum CursorOrderingType {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "customer" */
export type Customer = {
  __typename?: 'customer';
  /** An array relationship */
  activePlans: Array<ActivePlan>;
  /** An array relationship */
  checkInEvents: Array<CheckInEvent>;
  /** An array relationship */
  customerListCustomers: Array<MessageDeliveryCustomerListCustomer>;
  /** An aggregate relationship */
  customerListCustomers_aggregate: MessageDeliveryCustomerListCustomerAggregate;
  /** An array relationship */
  customerQuestionnaires: Array<CustomerQuestionnaire>;
  emailAndPasswordVerificationInfoId?: Maybe<Scalars['uuid']>;
  id: Scalars['String'];
  lineId?: Maybe<Scalars['String']>;
  /** An object relationship */
  lineProfile?: Maybe<LineProfile>;
  /** An array relationship */
  menuOrderItems: Array<MenuOrderItem>;
  /** An array relationship */
  orders: Array<Order>;
  /** An array relationship */
  questionnaireAnswers: Array<QuestionnaireAnswer>;
  /** An array relationship */
  shopMembers: Array<ShopMember>;
  /** An array relationship */
  tableUserCustomers: Array<TableUserCustomer>;
};


/** columns and relationships of "customer" */
export type CustomerActivePlansArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanOrderBy>>;
  where?: InputMaybe<ActivePlanBoolExp>;
};


/** columns and relationships of "customer" */
export type CustomerCheckInEventsArgs = {
  distinct_on?: InputMaybe<Array<CheckInEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CheckInEventOrderBy>>;
  where?: InputMaybe<CheckInEventBoolExp>;
};


/** columns and relationships of "customer" */
export type CustomerCustomerListCustomersArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerListCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerListCustomerOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
};


/** columns and relationships of "customer" */
export type CustomerCustomerListCustomersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerListCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerListCustomerOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
};


/** columns and relationships of "customer" */
export type CustomerCustomerQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<CustomerQuestionnaireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerQuestionnaireOrderBy>>;
  where?: InputMaybe<CustomerQuestionnaireBoolExp>;
};


/** columns and relationships of "customer" */
export type CustomerMenuOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<MenuOrderItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderItemOrderBy>>;
  where?: InputMaybe<MenuOrderItemBoolExp>;
};


/** columns and relationships of "customer" */
export type CustomerOrdersArgs = {
  distinct_on?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


/** columns and relationships of "customer" */
export type CustomerQuestionnaireAnswersArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireAnswerOrderBy>>;
  where?: InputMaybe<QuestionnaireAnswerBoolExp>;
};


/** columns and relationships of "customer" */
export type CustomerShopMembersArgs = {
  distinct_on?: InputMaybe<Array<ShopMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMemberOrderBy>>;
  where?: InputMaybe<ShopMemberBoolExp>;
};


/** columns and relationships of "customer" */
export type CustomerTableUserCustomersArgs = {
  distinct_on?: InputMaybe<Array<TableUserCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserCustomerOrderBy>>;
  where?: InputMaybe<TableUserCustomerBoolExp>;
};

/** columns and relationships of "customerQuestionnaire" */
export type CustomerQuestionnaire = {
  __typename?: 'customerQuestionnaire';
  /** An object relationship */
  checkInEvent?: Maybe<CheckInEvent>;
  /** An object relationship */
  customer: Customer;
  customerId: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  questionnaire?: Maybe<Questionnaire>;
  /** An object relationship */
  questionnaireAnswer?: Maybe<QuestionnaireAnswer>;
  /** An object relationship */
  shop: Shop;
};

/** order by aggregate values of table "customerQuestionnaire" */
export type CustomerQuestionnaireAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CustomerQuestionnaireMaxOrderBy>;
  min?: InputMaybe<CustomerQuestionnaireMinOrderBy>;
};

/** Boolean expression to filter rows from the table "customerQuestionnaire". All fields are combined with a logical 'AND'. */
export type CustomerQuestionnaireBoolExp = {
  _and?: InputMaybe<Array<CustomerQuestionnaireBoolExp>>;
  _not?: InputMaybe<CustomerQuestionnaireBoolExp>;
  _or?: InputMaybe<Array<CustomerQuestionnaireBoolExp>>;
  checkInEvent?: InputMaybe<CheckInEventBoolExp>;
  customer?: InputMaybe<CustomerBoolExp>;
  customerId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  questionnaire?: InputMaybe<QuestionnaireBoolExp>;
  questionnaireAnswer?: InputMaybe<QuestionnaireAnswerBoolExp>;
  shop?: InputMaybe<ShopBoolExp>;
};

/** order by max() on columns of table "customerQuestionnaire" */
export type CustomerQuestionnaireMaxOrderBy = {
  customerId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "customerQuestionnaire" */
export type CustomerQuestionnaireMinOrderBy = {
  customerId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "customerQuestionnaire". */
export type CustomerQuestionnaireOrderBy = {
  checkInEvent?: InputMaybe<CheckInEventOrderBy>;
  customer?: InputMaybe<CustomerOrderBy>;
  customerId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  questionnaire?: InputMaybe<QuestionnaireOrderBy>;
  questionnaireAnswer?: InputMaybe<QuestionnaireAnswerOrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
};

/** select columns of table "customerQuestionnaire" */
export enum CustomerQuestionnaireSelectColumn {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "customerQuestionnaire" */
export type CustomerQuestionnaireStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CustomerQuestionnaireStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerQuestionnaireStreamCursorValueInput = {
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "customer". All fields are combined with a logical 'AND'. */
export type CustomerBoolExp = {
  _and?: InputMaybe<Array<CustomerBoolExp>>;
  _not?: InputMaybe<CustomerBoolExp>;
  _or?: InputMaybe<Array<CustomerBoolExp>>;
  activePlans?: InputMaybe<ActivePlanBoolExp>;
  checkInEvents?: InputMaybe<CheckInEventBoolExp>;
  customerListCustomers?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
  customerListCustomers_aggregate?: InputMaybe<MessageDeliveryCustomerListCustomerAggregateBoolExp>;
  customerQuestionnaires?: InputMaybe<CustomerQuestionnaireBoolExp>;
  emailAndPasswordVerificationInfoId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lineId?: InputMaybe<StringComparisonExp>;
  lineProfile?: InputMaybe<LineProfileBoolExp>;
  menuOrderItems?: InputMaybe<MenuOrderItemBoolExp>;
  orders?: InputMaybe<OrderBoolExp>;
  questionnaireAnswers?: InputMaybe<QuestionnaireAnswerBoolExp>;
  shopMembers?: InputMaybe<ShopMemberBoolExp>;
  tableUserCustomers?: InputMaybe<TableUserCustomerBoolExp>;
};

/** Ordering options when selecting data from "customer". */
export type CustomerOrderBy = {
  activePlans_aggregate?: InputMaybe<ActivePlanAggregateOrderBy>;
  checkInEvents_aggregate?: InputMaybe<CheckInEventAggregateOrderBy>;
  customerListCustomers_aggregate?: InputMaybe<MessageDeliveryCustomerListCustomerAggregateOrderBy>;
  customerQuestionnaires_aggregate?: InputMaybe<CustomerQuestionnaireAggregateOrderBy>;
  emailAndPasswordVerificationInfoId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lineId?: InputMaybe<OrderBy>;
  lineProfile?: InputMaybe<LineProfileOrderBy>;
  menuOrderItems_aggregate?: InputMaybe<MenuOrderItemAggregateOrderBy>;
  orders_aggregate?: InputMaybe<OrderAggregateOrderBy>;
  questionnaireAnswers_aggregate?: InputMaybe<QuestionnaireAnswerAggregateOrderBy>;
  shopMembers_aggregate?: InputMaybe<ShopMemberAggregateOrderBy>;
  tableUserCustomers_aggregate?: InputMaybe<TableUserCustomerAggregateOrderBy>;
};

/** select columns of table "customer" */
export enum CustomerSelectColumn {
  /** column name */
  EmailAndPasswordVerificationInfoId = 'emailAndPasswordVerificationInfoId',
  /** column name */
  Id = 'id',
  /** column name */
  LineId = 'lineId'
}

/** Streaming cursor of the table "customer" */
export type CustomerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CustomerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerStreamCursorValueInput = {
  emailAndPasswordVerificationInfoId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  lineId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "dailySalesBudget" */
export type DailySalesBudget = {
  __typename?: 'dailySalesBudget';
  businessDate: Scalars['date'];
  id: Scalars['uuid'];
  /** An object relationship */
  monthlySalesBudget: MonthlySalesBudget;
  monthlySalesBudgetId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  taxExcludedAmount: Scalars['Int'];
};

/** order by aggregate values of table "dailySalesBudget" */
export type DailySalesBudgetAggregateOrderBy = {
  avg?: InputMaybe<DailySalesBudgetAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DailySalesBudgetMaxOrderBy>;
  min?: InputMaybe<DailySalesBudgetMinOrderBy>;
  stddev?: InputMaybe<DailySalesBudgetStddevOrderBy>;
  stddev_pop?: InputMaybe<DailySalesBudgetStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DailySalesBudgetStddevSampOrderBy>;
  sum?: InputMaybe<DailySalesBudgetSumOrderBy>;
  var_pop?: InputMaybe<DailySalesBudgetVarPopOrderBy>;
  var_samp?: InputMaybe<DailySalesBudgetVarSampOrderBy>;
  variance?: InputMaybe<DailySalesBudgetVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "dailySalesBudget" */
export type DailySalesBudgetArrRelInsertInput = {
  data: Array<DailySalesBudgetInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DailySalesBudgetOnConflict>;
};

/** order by avg() on columns of table "dailySalesBudget" */
export type DailySalesBudgetAvgOrderBy = {
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "dailySalesBudget". All fields are combined with a logical 'AND'. */
export type DailySalesBudgetBoolExp = {
  _and?: InputMaybe<Array<DailySalesBudgetBoolExp>>;
  _not?: InputMaybe<DailySalesBudgetBoolExp>;
  _or?: InputMaybe<Array<DailySalesBudgetBoolExp>>;
  businessDate?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  monthlySalesBudget?: InputMaybe<MonthlySalesBudgetBoolExp>;
  monthlySalesBudgetId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  taxExcludedAmount?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "dailySalesBudget" */
export enum DailySalesBudgetConstraint {
  /** unique or primary key constraint on columns "id" */
  DailySalesBudgetPkey = 'dailySalesBudget_pkey',
  /** unique or primary key constraint on columns "businessDate", "shopId" */
  DailySalesBudgetShopIdBusinessDateKey = 'dailySalesBudget_shopId_businessDate_key'
}

/** input type for incrementing numeric columns in table "dailySalesBudget" */
export type DailySalesBudgetIncInput = {
  taxExcludedAmount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dailySalesBudget" */
export type DailySalesBudgetInsertInput = {
  businessDate: Scalars['date'];
  createdAt: Scalars['timestamptz'];
  monthlySalesBudget?: InputMaybe<MonthlySalesBudgetObjRelInsertInput>;
  monthlySalesBudgetId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  taxExcludedAmount: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
};

/** order by max() on columns of table "dailySalesBudget" */
export type DailySalesBudgetMaxOrderBy = {
  businessDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  monthlySalesBudgetId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "dailySalesBudget" */
export type DailySalesBudgetMinOrderBy = {
  businessDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  monthlySalesBudgetId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "dailySalesBudget" */
export type DailySalesBudgetMutationResponse = {
  __typename?: 'dailySalesBudget_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DailySalesBudget>;
};

/** on_conflict condition type for table "dailySalesBudget" */
export type DailySalesBudgetOnConflict = {
  constraint: DailySalesBudgetConstraint;
  update_columns?: Array<DailySalesBudgetUpdateColumn>;
  where?: InputMaybe<DailySalesBudgetBoolExp>;
};

/** Ordering options when selecting data from "dailySalesBudget". */
export type DailySalesBudgetOrderBy = {
  businessDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  monthlySalesBudget?: InputMaybe<MonthlySalesBudgetOrderBy>;
  monthlySalesBudgetId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: dailySalesBudget */
export type DailySalesBudgetPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "dailySalesBudget" */
export enum DailySalesBudgetSelectColumn {
  /** column name */
  BusinessDate = 'businessDate',
  /** column name */
  Id = 'id',
  /** column name */
  MonthlySalesBudgetId = 'monthlySalesBudgetId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TaxExcludedAmount = 'taxExcludedAmount'
}

/** input type for updating data in table "dailySalesBudget" */
export type DailySalesBudgetSetInput = {
  taxExcludedAmount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "dailySalesBudget" */
export type DailySalesBudgetStddevOrderBy = {
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "dailySalesBudget" */
export type DailySalesBudgetStddevPopOrderBy = {
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "dailySalesBudget" */
export type DailySalesBudgetStddevSampOrderBy = {
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "dailySalesBudget" */
export type DailySalesBudgetStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DailySalesBudgetStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DailySalesBudgetStreamCursorValueInput = {
  businessDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  monthlySalesBudgetId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  taxExcludedAmount?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dailySalesBudget" */
export type DailySalesBudgetSumOrderBy = {
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** update columns of table "dailySalesBudget" */
export enum DailySalesBudgetUpdateColumn {
  /** column name */
  TaxExcludedAmount = 'taxExcludedAmount',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type DailySalesBudgetUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DailySalesBudgetIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DailySalesBudgetSetInput>;
  /** filter the rows which have to be updated */
  where: DailySalesBudgetBoolExp;
};

/** order by var_pop() on columns of table "dailySalesBudget" */
export type DailySalesBudgetVarPopOrderBy = {
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "dailySalesBudget" */
export type DailySalesBudgetVarSampOrderBy = {
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "dailySalesBudget" */
export type DailySalesBudgetVarianceOrderBy = {
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** columns and relationships of "dashboardAccount" */
export type DashboardAccount = {
  __typename?: 'dashboardAccount';
  /** An object relationship */
  corporation?: Maybe<Corporation>;
  corporationId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  dashboardAccountAccessibleCompanies: Array<DashboardAccountAccessibleCompany>;
  /** An array relationship */
  dashboardAccountAccessibleShops: Array<DashboardAccountAccessibleShop>;
  /** An object relationship */
  dashboardAccountRole: DashboardAccountRole;
  /** An object relationship */
  dashboardAccountRoleType: DashboardAccountRoleType;
  email: Scalars['String'];
  id: Scalars['uuid'];
  role: DashboardAccountRoleTypeEnum;
  userName: Scalars['String'];
};


/** columns and relationships of "dashboardAccount" */
export type DashboardAccountDashboardAccountAccessibleCompaniesArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountAccessibleCompanySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountAccessibleCompanyOrderBy>>;
  where?: InputMaybe<DashboardAccountAccessibleCompanyBoolExp>;
};


/** columns and relationships of "dashboardAccount" */
export type DashboardAccountDashboardAccountAccessibleShopsArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountAccessibleShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountAccessibleShopOrderBy>>;
  where?: InputMaybe<DashboardAccountAccessibleShopBoolExp>;
};

/** columns and relationships of "dashboardAccountAccessibleCompany" */
export type DashboardAccountAccessibleCompany = {
  __typename?: 'dashboardAccountAccessibleCompany';
  /** An object relationship */
  company?: Maybe<Company>;
  companyId: Scalars['uuid'];
  /** An object relationship */
  dashboardAccount: DashboardAccount;
  dashboardAccountId: Scalars['uuid'];
  id: Scalars['uuid'];
};

/** order by aggregate values of table "dashboardAccountAccessibleCompany" */
export type DashboardAccountAccessibleCompanyAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DashboardAccountAccessibleCompanyMaxOrderBy>;
  min?: InputMaybe<DashboardAccountAccessibleCompanyMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "dashboardAccountAccessibleCompany". All fields are combined with a logical 'AND'.
 */
export type DashboardAccountAccessibleCompanyBoolExp = {
  _and?: InputMaybe<Array<DashboardAccountAccessibleCompanyBoolExp>>;
  _not?: InputMaybe<DashboardAccountAccessibleCompanyBoolExp>;
  _or?: InputMaybe<Array<DashboardAccountAccessibleCompanyBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  dashboardAccount?: InputMaybe<DashboardAccountBoolExp>;
  dashboardAccountId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "dashboardAccountAccessibleCompany" */
export type DashboardAccountAccessibleCompanyMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  dashboardAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "dashboardAccountAccessibleCompany" */
export type DashboardAccountAccessibleCompanyMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  dashboardAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "dashboardAccountAccessibleCompany". */
export type DashboardAccountAccessibleCompanyOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  dashboardAccount?: InputMaybe<DashboardAccountOrderBy>;
  dashboardAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "dashboardAccountAccessibleCompany" */
export enum DashboardAccountAccessibleCompanySelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  DashboardAccountId = 'dashboardAccountId',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "dashboardAccountAccessibleCompany" */
export type DashboardAccountAccessibleCompanyStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DashboardAccountAccessibleCompanyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DashboardAccountAccessibleCompanyStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  dashboardAccountId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "dashboardAccountAccessibleShop" */
export type DashboardAccountAccessibleShop = {
  __typename?: 'dashboardAccountAccessibleShop';
  /** An object relationship */
  dashboardAccount: DashboardAccount;
  dashboardAccountId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "dashboardAccountAccessibleShop" */
export type DashboardAccountAccessibleShopAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DashboardAccountAccessibleShopMaxOrderBy>;
  min?: InputMaybe<DashboardAccountAccessibleShopMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "dashboardAccountAccessibleShop". All fields are combined with a logical 'AND'.
 */
export type DashboardAccountAccessibleShopBoolExp = {
  _and?: InputMaybe<Array<DashboardAccountAccessibleShopBoolExp>>;
  _not?: InputMaybe<DashboardAccountAccessibleShopBoolExp>;
  _or?: InputMaybe<Array<DashboardAccountAccessibleShopBoolExp>>;
  dashboardAccount?: InputMaybe<DashboardAccountBoolExp>;
  dashboardAccountId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "dashboardAccountAccessibleShop" */
export type DashboardAccountAccessibleShopMaxOrderBy = {
  dashboardAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "dashboardAccountAccessibleShop" */
export type DashboardAccountAccessibleShopMinOrderBy = {
  dashboardAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "dashboardAccountAccessibleShop". */
export type DashboardAccountAccessibleShopOrderBy = {
  dashboardAccount?: InputMaybe<DashboardAccountOrderBy>;
  dashboardAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "dashboardAccountAccessibleShop" */
export enum DashboardAccountAccessibleShopSelectColumn {
  /** column name */
  DashboardAccountId = 'dashboardAccountId',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "dashboardAccountAccessibleShop" */
export type DashboardAccountAccessibleShopStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DashboardAccountAccessibleShopStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DashboardAccountAccessibleShopStreamCursorValueInput = {
  dashboardAccountId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "dashboardAccountRole" */
export type DashboardAccountRole = {
  __typename?: 'dashboardAccountRole';
  /** An object relationship */
  corporation: Corporation;
  corporationId: Scalars['uuid'];
  /** An array relationship */
  dashboardAccountRolePermissions: Array<DashboardAccountRolePermission>;
  /** An array relationship */
  dashboardAccounts: Array<DashboardAccount>;
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "dashboardAccountRole" */
export type DashboardAccountRoleDashboardAccountRolePermissionsArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountRolePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountRolePermissionOrderBy>>;
  where?: InputMaybe<DashboardAccountRolePermissionBoolExp>;
};


/** columns and relationships of "dashboardAccountRole" */
export type DashboardAccountRoleDashboardAccountsArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountOrderBy>>;
  where?: InputMaybe<DashboardAccountBoolExp>;
};

/** columns and relationships of "dashboardAccountRolePermission" */
export type DashboardAccountRolePermission = {
  __typename?: 'dashboardAccountRolePermission';
  /** An object relationship */
  dashboardAccountRole: DashboardAccountRole;
  dashboardAccountRoleId: Scalars['uuid'];
  feature: DashboardFeatureEnum;
  id: Scalars['uuid'];
  type: DashboardAccountRolePermissionTypeEnum;
};

export enum DashboardAccountRolePermissionTypeEnum {
  Edit = 'edit',
  View = 'view'
}

/**
 * Boolean expression to compare columns of type
 * "dashboardAccountRolePermissionType_enum". All fields are combined with logical 'AND'.
 */
export type DashboardAccountRolePermissionTypeEnumComparisonExp = {
  _eq?: InputMaybe<DashboardAccountRolePermissionTypeEnum>;
  _in?: InputMaybe<Array<DashboardAccountRolePermissionTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<DashboardAccountRolePermissionTypeEnum>;
  _nin?: InputMaybe<Array<DashboardAccountRolePermissionTypeEnum>>;
};

/** order by aggregate values of table "dashboardAccountRolePermission" */
export type DashboardAccountRolePermissionAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DashboardAccountRolePermissionMaxOrderBy>;
  min?: InputMaybe<DashboardAccountRolePermissionMinOrderBy>;
};

/** input type for inserting array relation for remote table "dashboardAccountRolePermission" */
export type DashboardAccountRolePermissionArrRelInsertInput = {
  data: Array<DashboardAccountRolePermissionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DashboardAccountRolePermissionOnConflict>;
};

/**
 * Boolean expression to filter rows from the table
 * "dashboardAccountRolePermission". All fields are combined with a logical 'AND'.
 */
export type DashboardAccountRolePermissionBoolExp = {
  _and?: InputMaybe<Array<DashboardAccountRolePermissionBoolExp>>;
  _not?: InputMaybe<DashboardAccountRolePermissionBoolExp>;
  _or?: InputMaybe<Array<DashboardAccountRolePermissionBoolExp>>;
  dashboardAccountRole?: InputMaybe<DashboardAccountRoleBoolExp>;
  dashboardAccountRoleId?: InputMaybe<UuidComparisonExp>;
  feature?: InputMaybe<DashboardFeatureEnumComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<DashboardAccountRolePermissionTypeEnumComparisonExp>;
};

/** unique or primary key constraints on table "dashboardAccountRolePermission" */
export enum DashboardAccountRolePermissionConstraint {
  /** unique or primary key constraint on columns "feature", "dashboardAccountRoleId", "type" */
  DashboardAccountRolePermissioTypeFeatureDashboardAccountKey = 'dashboardAccountRolePermissio_type_feature_dashboardAccount_key',
  /** unique or primary key constraint on columns "id" */
  DashboardAccountRolePermissionPkey = 'dashboardAccountRolePermission_pkey'
}

/** input type for inserting data into table "dashboardAccountRolePermission" */
export type DashboardAccountRolePermissionInsertInput = {
  dashboardAccountRole?: InputMaybe<DashboardAccountRoleObjRelInsertInput>;
  dashboardAccountRoleId: Scalars['uuid'];
  feature: DashboardFeatureEnum;
  type: DashboardAccountRolePermissionTypeEnum;
};

/** order by max() on columns of table "dashboardAccountRolePermission" */
export type DashboardAccountRolePermissionMaxOrderBy = {
  dashboardAccountRoleId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "dashboardAccountRolePermission" */
export type DashboardAccountRolePermissionMinOrderBy = {
  dashboardAccountRoleId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "dashboardAccountRolePermission" */
export type DashboardAccountRolePermissionMutationResponse = {
  __typename?: 'dashboardAccountRolePermission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DashboardAccountRolePermission>;
};

/** on_conflict condition type for table "dashboardAccountRolePermission" */
export type DashboardAccountRolePermissionOnConflict = {
  constraint: DashboardAccountRolePermissionConstraint;
  update_columns?: Array<DashboardAccountRolePermissionUpdateColumn>;
  where?: InputMaybe<DashboardAccountRolePermissionBoolExp>;
};

/** Ordering options when selecting data from "dashboardAccountRolePermission". */
export type DashboardAccountRolePermissionOrderBy = {
  dashboardAccountRole?: InputMaybe<DashboardAccountRoleOrderBy>;
  dashboardAccountRoleId?: InputMaybe<OrderBy>;
  feature?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: dashboardAccountRolePermission */
export type DashboardAccountRolePermissionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "dashboardAccountRolePermission" */
export enum DashboardAccountRolePermissionSelectColumn {
  /** column name */
  DashboardAccountRoleId = 'dashboardAccountRoleId',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "dashboardAccountRolePermission" */
export type DashboardAccountRolePermissionSetInput = {
  dashboardAccountRoleId?: InputMaybe<Scalars['uuid']>;
  feature?: InputMaybe<DashboardFeatureEnum>;
  type?: InputMaybe<DashboardAccountRolePermissionTypeEnum>;
};

/** Streaming cursor of the table "dashboardAccountRolePermission" */
export type DashboardAccountRolePermissionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DashboardAccountRolePermissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DashboardAccountRolePermissionStreamCursorValueInput = {
  dashboardAccountRoleId?: InputMaybe<Scalars['uuid']>;
  feature?: InputMaybe<DashboardFeatureEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<DashboardAccountRolePermissionTypeEnum>;
};

/** update columns of table "dashboardAccountRolePermission" */
export enum DashboardAccountRolePermissionUpdateColumn {
  /** column name */
  DashboardAccountRoleId = 'dashboardAccountRoleId',
  /** column name */
  Feature = 'feature',
  /** column name */
  Type = 'type'
}

export type DashboardAccountRolePermissionUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DashboardAccountRolePermissionSetInput>;
  /** filter the rows which have to be updated */
  where: DashboardAccountRolePermissionBoolExp;
};

/** columns and relationships of "dashboardAccountRoleType" */
export type DashboardAccountRoleType = {
  __typename?: 'dashboardAccountRoleType';
  /** An array relationship */
  dashboardAccounts: Array<DashboardAccount>;
  name: Scalars['String'];
};


/** columns and relationships of "dashboardAccountRoleType" */
export type DashboardAccountRoleTypeDashboardAccountsArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountOrderBy>>;
  where?: InputMaybe<DashboardAccountBoolExp>;
};

/** Boolean expression to filter rows from the table "dashboardAccountRoleType". All fields are combined with a logical 'AND'. */
export type DashboardAccountRoleTypeBoolExp = {
  _and?: InputMaybe<Array<DashboardAccountRoleTypeBoolExp>>;
  _not?: InputMaybe<DashboardAccountRoleTypeBoolExp>;
  _or?: InputMaybe<Array<DashboardAccountRoleTypeBoolExp>>;
  dashboardAccounts?: InputMaybe<DashboardAccountBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export enum DashboardAccountRoleTypeEnum {
  CompanyManager = 'companyManager',
  CorporationManager = 'corporationManager',
  ShopMember = 'shopMember'
}

/** Boolean expression to compare columns of type "dashboardAccountRoleType_enum". All fields are combined with logical 'AND'. */
export type DashboardAccountRoleTypeEnumComparisonExp = {
  _eq?: InputMaybe<DashboardAccountRoleTypeEnum>;
  _in?: InputMaybe<Array<DashboardAccountRoleTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<DashboardAccountRoleTypeEnum>;
  _nin?: InputMaybe<Array<DashboardAccountRoleTypeEnum>>;
};

/** Ordering options when selecting data from "dashboardAccountRoleType". */
export type DashboardAccountRoleTypeOrderBy = {
  dashboardAccounts_aggregate?: InputMaybe<DashboardAccountAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "dashboardAccountRoleType" */
export enum DashboardAccountRoleTypeSelectColumn {
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "dashboardAccountRoleType" */
export type DashboardAccountRoleTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DashboardAccountRoleTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DashboardAccountRoleTypeStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "dashboardAccountRole". All fields are combined with a logical 'AND'. */
export type DashboardAccountRoleBoolExp = {
  _and?: InputMaybe<Array<DashboardAccountRoleBoolExp>>;
  _not?: InputMaybe<DashboardAccountRoleBoolExp>;
  _or?: InputMaybe<Array<DashboardAccountRoleBoolExp>>;
  corporation?: InputMaybe<CorporationBoolExp>;
  corporationId?: InputMaybe<UuidComparisonExp>;
  dashboardAccountRolePermissions?: InputMaybe<DashboardAccountRolePermissionBoolExp>;
  dashboardAccounts?: InputMaybe<DashboardAccountBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "dashboardAccountRole" */
export enum DashboardAccountRoleConstraint {
  /** unique or primary key constraint on columns "id" */
  DashboardAccountRolePkey1 = 'dashboardAccountRole_pkey1'
}

/** input type for inserting data into table "dashboardAccountRole" */
export type DashboardAccountRoleInsertInput = {
  corporation?: InputMaybe<CorporationObjRelInsertInput>;
  corporationId: Scalars['uuid'];
  dashboardAccountRolePermissions?: InputMaybe<DashboardAccountRolePermissionArrRelInsertInput>;
  name: Scalars['String'];
};

/** response of any mutation on the table "dashboardAccountRole" */
export type DashboardAccountRoleMutationResponse = {
  __typename?: 'dashboardAccountRole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DashboardAccountRole>;
};

/** input type for inserting object relation for remote table "dashboardAccountRole" */
export type DashboardAccountRoleObjRelInsertInput = {
  data: DashboardAccountRoleInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DashboardAccountRoleOnConflict>;
};

/** on_conflict condition type for table "dashboardAccountRole" */
export type DashboardAccountRoleOnConflict = {
  constraint: DashboardAccountRoleConstraint;
  update_columns?: Array<DashboardAccountRoleUpdateColumn>;
  where?: InputMaybe<DashboardAccountRoleBoolExp>;
};

/** Ordering options when selecting data from "dashboardAccountRole". */
export type DashboardAccountRoleOrderBy = {
  corporation?: InputMaybe<CorporationOrderBy>;
  corporationId?: InputMaybe<OrderBy>;
  dashboardAccountRolePermissions_aggregate?: InputMaybe<DashboardAccountRolePermissionAggregateOrderBy>;
  dashboardAccounts_aggregate?: InputMaybe<DashboardAccountAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: dashboardAccountRole */
export type DashboardAccountRolePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "dashboardAccountRole" */
export enum DashboardAccountRoleSelectColumn {
  /** column name */
  CorporationId = 'corporationId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "dashboardAccountRole" */
export type DashboardAccountRoleSetInput = {
  corporationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "dashboardAccountRole" */
export type DashboardAccountRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DashboardAccountRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DashboardAccountRoleStreamCursorValueInput = {
  corporationId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "dashboardAccountRole" */
export enum DashboardAccountRoleUpdateColumn {
  /** column name */
  CorporationId = 'corporationId',
  /** column name */
  Name = 'name'
}

export type DashboardAccountRoleUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DashboardAccountRoleSetInput>;
  /** filter the rows which have to be updated */
  where: DashboardAccountRoleBoolExp;
};

/** order by aggregate values of table "dashboardAccount" */
export type DashboardAccountAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DashboardAccountMaxOrderBy>;
  min?: InputMaybe<DashboardAccountMinOrderBy>;
};

/** Boolean expression to filter rows from the table "dashboardAccount". All fields are combined with a logical 'AND'. */
export type DashboardAccountBoolExp = {
  _and?: InputMaybe<Array<DashboardAccountBoolExp>>;
  _not?: InputMaybe<DashboardAccountBoolExp>;
  _or?: InputMaybe<Array<DashboardAccountBoolExp>>;
  corporation?: InputMaybe<CorporationBoolExp>;
  corporationId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  dashboardAccountAccessibleCompanies?: InputMaybe<DashboardAccountAccessibleCompanyBoolExp>;
  dashboardAccountAccessibleShops?: InputMaybe<DashboardAccountAccessibleShopBoolExp>;
  dashboardAccountRole?: InputMaybe<DashboardAccountRoleBoolExp>;
  dashboardAccountRoleType?: InputMaybe<DashboardAccountRoleTypeBoolExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  role?: InputMaybe<DashboardAccountRoleTypeEnumComparisonExp>;
  userName?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "dashboardAccount" */
export type DashboardAccountMaxOrderBy = {
  corporationId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userName?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "dashboardAccount" */
export type DashboardAccountMinOrderBy = {
  corporationId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userName?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "dashboardAccount". */
export type DashboardAccountOrderBy = {
  corporation?: InputMaybe<CorporationOrderBy>;
  corporationId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  dashboardAccountAccessibleCompanies_aggregate?: InputMaybe<DashboardAccountAccessibleCompanyAggregateOrderBy>;
  dashboardAccountAccessibleShops_aggregate?: InputMaybe<DashboardAccountAccessibleShopAggregateOrderBy>;
  dashboardAccountRole?: InputMaybe<DashboardAccountRoleOrderBy>;
  dashboardAccountRoleType?: InputMaybe<DashboardAccountRoleTypeOrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  userName?: InputMaybe<OrderBy>;
};

/** select columns of table "dashboardAccount" */
export enum DashboardAccountSelectColumn {
  /** column name */
  CorporationId = 'corporationId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserName = 'userName'
}

/** Streaming cursor of the table "dashboardAccount" */
export type DashboardAccountStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DashboardAccountStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DashboardAccountStreamCursorValueInput = {
  corporationId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<DashboardAccountRoleTypeEnum>;
  userName?: InputMaybe<Scalars['String']>;
};

export enum DashboardFeatureEnum {
  AccountingHistory = 'accountingHistory',
  Aggregation = 'aggregation',
  BusinessIntelligenceIntegration = 'businessIntelligenceIntegration',
  CompanyManagement = 'companyManagement',
  CustomerRelationshipManagement = 'customerRelationshipManagement',
  DailyReport = 'dailyReport',
  DashboardAccountManagement = 'dashboardAccountManagement',
  MenuMasterData = 'menuMasterData',
  PaymentMethodSettings = 'paymentMethodSettings',
  Questionnaire = 'questionnaire',
  SalesBudget = 'salesBudget',
  ShopManagement = 'shopManagement',
  TableClearHistory = 'tableClearHistory',
  TerminalPayment = 'terminalPayment'
}

/** Boolean expression to compare columns of type "dashboardFeature_enum". All fields are combined with logical 'AND'. */
export type DashboardFeatureEnumComparisonExp = {
  _eq?: InputMaybe<DashboardFeatureEnum>;
  _in?: InputMaybe<Array<DashboardFeatureEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<DashboardFeatureEnum>;
  _nin?: InputMaybe<Array<DashboardFeatureEnum>>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

export enum DepositCycleTypeEnum {
  SixAMonth = 'SIX_A_MONTH',
  TwiceAMonth = 'TWICE_A_MONTH'
}

/** Boolean expression to compare columns of type "depositCycleType_enum". All fields are combined with logical 'AND'. */
export type DepositCycleTypeEnumComparisonExp = {
  _eq?: InputMaybe<DepositCycleTypeEnum>;
  _in?: InputMaybe<Array<DepositCycleTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<DepositCycleTypeEnum>;
  _nin?: InputMaybe<Array<DepositCycleTypeEnum>>;
};

/** columns and relationships of "dishUpSlipGroup" */
export type DishUpSlipGroup = {
  __typename?: 'dishUpSlipGroup';
  dishUpSlipGroupId: Scalars['uuid'];
  /** An array relationship */
  dishUpSlipGroupRoles: Array<DishUpSlipGroupRoles>;
  /** An array relationship */
  dishUpSlipGroupShopMenus: Array<DishUpSlipGroupShopMenus>;
  /** An array relationship */
  dishUpSlipGroupShopPlans: Array<DishUpSlipGroupShopPlans>;
  /** An array relationship */
  dishUpSlipGroupTables: Array<DishUpSlipGroupTables>;
  id: Scalars['Int'];
  name: Scalars['String'];
  serial: Scalars['Int'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};


/** columns and relationships of "dishUpSlipGroup" */
export type DishUpSlipGroupDishUpSlipGroupRolesArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupRolesOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupRolesBoolExp>;
};


/** columns and relationships of "dishUpSlipGroup" */
export type DishUpSlipGroupDishUpSlipGroupShopMenusArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopMenusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopMenusOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
};


/** columns and relationships of "dishUpSlipGroup" */
export type DishUpSlipGroupDishUpSlipGroupShopPlansArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopPlansOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
};


/** columns and relationships of "dishUpSlipGroup" */
export type DishUpSlipGroupDishUpSlipGroupTablesArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupTablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupTablesOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupTablesBoolExp>;
};

/** columns and relationships of "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRoles = {
  __typename?: 'dishUpSlipGroupRoles';
  _dishUpSlipGroupId: Scalars['Int'];
  _roleId: Scalars['Int'];
  /** An object relationship */
  dishUpSlipGroup?: Maybe<DishUpSlipGroup>;
  dishUpSlipGroupId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  role: Role;
  roleId: Scalars['uuid'];
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesAggregateOrderBy = {
  avg?: InputMaybe<DishUpSlipGroupRolesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DishUpSlipGroupRolesMaxOrderBy>;
  min?: InputMaybe<DishUpSlipGroupRolesMinOrderBy>;
  stddev?: InputMaybe<DishUpSlipGroupRolesStddevOrderBy>;
  stddev_pop?: InputMaybe<DishUpSlipGroupRolesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DishUpSlipGroupRolesStddevSampOrderBy>;
  sum?: InputMaybe<DishUpSlipGroupRolesSumOrderBy>;
  var_pop?: InputMaybe<DishUpSlipGroupRolesVarPopOrderBy>;
  var_samp?: InputMaybe<DishUpSlipGroupRolesVarSampOrderBy>;
  variance?: InputMaybe<DishUpSlipGroupRolesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesArrRelInsertInput = {
  data: Array<DishUpSlipGroupRolesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DishUpSlipGroupRolesOnConflict>;
};

/** order by avg() on columns of table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesAvgOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "dishUpSlipGroupRoles". All fields are combined with a logical 'AND'. */
export type DishUpSlipGroupRolesBoolExp = {
  _and?: InputMaybe<Array<DishUpSlipGroupRolesBoolExp>>;
  _dishUpSlipGroupId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<DishUpSlipGroupRolesBoolExp>;
  _or?: InputMaybe<Array<DishUpSlipGroupRolesBoolExp>>;
  _roleId?: InputMaybe<IntComparisonExp>;
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupBoolExp>;
  dishUpSlipGroupId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  role?: InputMaybe<RoleBoolExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "dishUpSlipGroupRoles" */
export enum DishUpSlipGroupRolesConstraint {
  /** unique or primary key constraint on columns "id" */
  DishUpSlipGroupRolesPkey = 'dishUpSlipGroupRoles_pkey',
  /** unique or primary key constraint on columns "_roleId", "_dishUpSlipGroupId" */
  DishUpSlipGroupRolesRoleIdDishUpSlipGroupIdKey = 'dishUpSlipGroupRoles_roleId_dishUpSlipGroupId_key',
  /** unique or primary key constraint on columns "roleId", "dishUpSlipGroupId" */
  DishUpSlipGroupRolesRoleUuidDishUpSlipGroupUuidKey = 'dishUpSlipGroupRoles_roleUuid_dishUpSlipGroupUuid_key'
}

/** input type for incrementing numeric columns in table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesIncInput = {
  _dishUpSlipGroupId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesInsertInput = {
  _dishUpSlipGroupId: Scalars['Int'];
  _roleId: Scalars['Int'];
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupObjRelInsertInput>;
  dishUpSlipGroupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<RoleObjRelInsertInput>;
  roleId: Scalars['uuid'];
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesMaxOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesMinOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesMutationResponse = {
  __typename?: 'dishUpSlipGroupRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DishUpSlipGroupRoles>;
};

/** on_conflict condition type for table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesOnConflict = {
  constraint: DishUpSlipGroupRolesConstraint;
  update_columns?: Array<DishUpSlipGroupRolesUpdateColumn>;
  where?: InputMaybe<DishUpSlipGroupRolesBoolExp>;
};

/** Ordering options when selecting data from "dishUpSlipGroupRoles". */
export type DishUpSlipGroupRolesOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupOrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  role?: InputMaybe<RoleOrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: dishUpSlipGroupRoles */
export type DishUpSlipGroupRolesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "dishUpSlipGroupRoles" */
export enum DishUpSlipGroupRolesSelectColumn {
  /** column name */
  _DishUpSlipGroupId = '_dishUpSlipGroupId',
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  DishUpSlipGroupId = 'dishUpSlipGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesSetInput = {
  _dishUpSlipGroupId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
  dishUpSlipGroupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesStddevOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesStddevPopOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesStddevSampOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DishUpSlipGroupRolesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DishUpSlipGroupRolesStreamCursorValueInput = {
  _dishUpSlipGroupId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
  dishUpSlipGroupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesSumOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** update columns of table "dishUpSlipGroupRoles" */
export enum DishUpSlipGroupRolesUpdateColumn {
  /** column name */
  _DishUpSlipGroupId = '_dishUpSlipGroupId',
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  DishUpSlipGroupId = 'dishUpSlipGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

export type DishUpSlipGroupRolesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DishUpSlipGroupRolesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DishUpSlipGroupRolesSetInput>;
  /** filter the rows which have to be updated */
  where: DishUpSlipGroupRolesBoolExp;
};

/** order by var_pop() on columns of table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesVarPopOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesVarSampOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "dishUpSlipGroupRoles" */
export type DishUpSlipGroupRolesVarianceOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenus = {
  __typename?: 'dishUpSlipGroupShopMenus';
  _dishUpSlipGroupId: Scalars['Int'];
  _menuId: Scalars['Int'];
  /** An object relationship */
  dishUpSlipGroup?: Maybe<DishUpSlipGroup>;
  dishUpSlipGroupId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  menu: Menu;
  menuId: Scalars['uuid'];
  priority: Scalars['Int'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An object relationship */
  shopMenu?: Maybe<ShopMenu>;
};

/** order by aggregate values of table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusAggregateOrderBy = {
  avg?: InputMaybe<DishUpSlipGroupShopMenusAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DishUpSlipGroupShopMenusMaxOrderBy>;
  min?: InputMaybe<DishUpSlipGroupShopMenusMinOrderBy>;
  stddev?: InputMaybe<DishUpSlipGroupShopMenusStddevOrderBy>;
  stddev_pop?: InputMaybe<DishUpSlipGroupShopMenusStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DishUpSlipGroupShopMenusStddevSampOrderBy>;
  sum?: InputMaybe<DishUpSlipGroupShopMenusSumOrderBy>;
  var_pop?: InputMaybe<DishUpSlipGroupShopMenusVarPopOrderBy>;
  var_samp?: InputMaybe<DishUpSlipGroupShopMenusVarSampOrderBy>;
  variance?: InputMaybe<DishUpSlipGroupShopMenusVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusArrRelInsertInput = {
  data: Array<DishUpSlipGroupShopMenusInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DishUpSlipGroupShopMenusOnConflict>;
};

/** order by avg() on columns of table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusAvgOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "dishUpSlipGroupShopMenus". All fields are combined with a logical 'AND'. */
export type DishUpSlipGroupShopMenusBoolExp = {
  _and?: InputMaybe<Array<DishUpSlipGroupShopMenusBoolExp>>;
  _dishUpSlipGroupId?: InputMaybe<IntComparisonExp>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
  _or?: InputMaybe<Array<DishUpSlipGroupShopMenusBoolExp>>;
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupBoolExp>;
  dishUpSlipGroupId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopMenu?: InputMaybe<ShopMenuBoolExp>;
};

/** unique or primary key constraints on table "dishUpSlipGroupShopMenus" */
export enum DishUpSlipGroupShopMenusConstraint {
  /** unique or primary key constraint on columns "shopId", "dishUpSlipGroupId", "menuId" */
  DishUpSlipGroupShopMenusFkKey = 'dishUpSlipGroupShopMenus_fk_key',
  /** unique or primary key constraint on columns "id" */
  DishUpSlipGroupShopMenusPkey = 'dishUpSlipGroupShopMenus_pkey',
  /** unique or primary key constraint on columns "_dishUpSlipGroupId", "shopId", "_menuId" */
  DishUpSlipGroupShopMenusShopIdMenuIdDishUpSlipGroupIdKey = 'dishUpSlipGroupShopMenus_shopId_menuId_dishUpSlipGroupId_key'
}

/** input type for incrementing numeric columns in table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusIncInput = {
  _dishUpSlipGroupId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusInsertInput = {
  _dishUpSlipGroupId: Scalars['Int'];
  _menuId: Scalars['Int'];
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupObjRelInsertInput>;
  dishUpSlipGroupId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  menu?: InputMaybe<MenuObjRelInsertInput>;
  menuId: Scalars['uuid'];
  priority?: InputMaybe<Scalars['Int']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusMaxOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusMinOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusMutationResponse = {
  __typename?: 'dishUpSlipGroupShopMenus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DishUpSlipGroupShopMenus>;
};

/** on_conflict condition type for table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusOnConflict = {
  constraint: DishUpSlipGroupShopMenusConstraint;
  update_columns?: Array<DishUpSlipGroupShopMenusUpdateColumn>;
  where?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
};

/** Ordering options when selecting data from "dishUpSlipGroupShopMenus". */
export type DishUpSlipGroupShopMenusOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupOrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopMenu?: InputMaybe<ShopMenuOrderBy>;
};

/** primary key columns input for table: dishUpSlipGroupShopMenus */
export type DishUpSlipGroupShopMenusPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "dishUpSlipGroupShopMenus" */
export enum DishUpSlipGroupShopMenusSelectColumn {
  /** column name */
  _DishUpSlipGroupId = '_dishUpSlipGroupId',
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  DishUpSlipGroupId = 'dishUpSlipGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Priority = 'priority',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusSetInput = {
  _dishUpSlipGroupId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  dishUpSlipGroupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusStddevOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusStddevPopOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusStddevSampOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DishUpSlipGroupShopMenusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DishUpSlipGroupShopMenusStreamCursorValueInput = {
  _dishUpSlipGroupId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  dishUpSlipGroupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusSumOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** update columns of table "dishUpSlipGroupShopMenus" */
export enum DishUpSlipGroupShopMenusUpdateColumn {
  /** column name */
  _DishUpSlipGroupId = '_dishUpSlipGroupId',
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  DishUpSlipGroupId = 'dishUpSlipGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Priority = 'priority',
  /** column name */
  ShopId = 'shopId'
}

export type DishUpSlipGroupShopMenusUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DishUpSlipGroupShopMenusIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DishUpSlipGroupShopMenusSetInput>;
  /** filter the rows which have to be updated */
  where: DishUpSlipGroupShopMenusBoolExp;
};

/** order by var_pop() on columns of table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusVarPopOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusVarSampOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "dishUpSlipGroupShopMenus" */
export type DishUpSlipGroupShopMenusVarianceOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** columns and relationships of "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlans = {
  __typename?: 'dishUpSlipGroupShopPlans';
  _dishUpSlipGroupId: Scalars['Int'];
  _planId: Scalars['Int'];
  /** An object relationship */
  dishUpSlipGroup?: Maybe<DishUpSlipGroup>;
  dishUpSlipGroupId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  plan: Plan;
  planId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An object relationship */
  shopPlan?: Maybe<ShopPlan>;
};

/** order by aggregate values of table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansAggregateOrderBy = {
  avg?: InputMaybe<DishUpSlipGroupShopPlansAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DishUpSlipGroupShopPlansMaxOrderBy>;
  min?: InputMaybe<DishUpSlipGroupShopPlansMinOrderBy>;
  stddev?: InputMaybe<DishUpSlipGroupShopPlansStddevOrderBy>;
  stddev_pop?: InputMaybe<DishUpSlipGroupShopPlansStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DishUpSlipGroupShopPlansStddevSampOrderBy>;
  sum?: InputMaybe<DishUpSlipGroupShopPlansSumOrderBy>;
  var_pop?: InputMaybe<DishUpSlipGroupShopPlansVarPopOrderBy>;
  var_samp?: InputMaybe<DishUpSlipGroupShopPlansVarSampOrderBy>;
  variance?: InputMaybe<DishUpSlipGroupShopPlansVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansArrRelInsertInput = {
  data: Array<DishUpSlipGroupShopPlansInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DishUpSlipGroupShopPlansOnConflict>;
};

/** order by avg() on columns of table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansAvgOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "dishUpSlipGroupShopPlans". All fields are combined with a logical 'AND'. */
export type DishUpSlipGroupShopPlansBoolExp = {
  _and?: InputMaybe<Array<DishUpSlipGroupShopPlansBoolExp>>;
  _dishUpSlipGroupId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
  _or?: InputMaybe<Array<DishUpSlipGroupShopPlansBoolExp>>;
  _planId?: InputMaybe<IntComparisonExp>;
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupBoolExp>;
  dishUpSlipGroupId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  plan?: InputMaybe<PlanBoolExp>;
  planId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopPlan?: InputMaybe<ShopPlanBoolExp>;
};

/** unique or primary key constraints on table "dishUpSlipGroupShopPlans" */
export enum DishUpSlipGroupShopPlansConstraint {
  /** unique or primary key constraint on columns "planId", "shopId", "dishUpSlipGroupId" */
  DishUpSlipGroupShopPlansFkKey = 'dishUpSlipGroupShopPlans_fk_key',
  /** unique or primary key constraint on columns "id" */
  DishUpSlipGroupShopPlansPkey = 'dishUpSlipGroupShopPlans_pkey',
  /** unique or primary key constraint on columns "_dishUpSlipGroupId", "shopId", "_planId" */
  DishUpSlipGroupShopPlansShopIdPlanIdDishUpSlipGroupIdKey = 'dishUpSlipGroupShopPlans_shopId_planId_dishUpSlipGroupId_key'
}

/** input type for incrementing numeric columns in table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansIncInput = {
  _dishUpSlipGroupId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansInsertInput = {
  _dishUpSlipGroupId: Scalars['Int'];
  _planId: Scalars['Int'];
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupObjRelInsertInput>;
  dishUpSlipGroupId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  plan?: InputMaybe<PlanObjRelInsertInput>;
  planId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansMaxOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansMinOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansMutationResponse = {
  __typename?: 'dishUpSlipGroupShopPlans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DishUpSlipGroupShopPlans>;
};

/** on_conflict condition type for table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansOnConflict = {
  constraint: DishUpSlipGroupShopPlansConstraint;
  update_columns?: Array<DishUpSlipGroupShopPlansUpdateColumn>;
  where?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
};

/** Ordering options when selecting data from "dishUpSlipGroupShopPlans". */
export type DishUpSlipGroupShopPlansOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupOrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlanOrderBy>;
  planId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopPlan?: InputMaybe<ShopPlanOrderBy>;
};

/** primary key columns input for table: dishUpSlipGroupShopPlans */
export type DishUpSlipGroupShopPlansPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "dishUpSlipGroupShopPlans" */
export enum DishUpSlipGroupShopPlansSelectColumn {
  /** column name */
  _DishUpSlipGroupId = '_dishUpSlipGroupId',
  /** column name */
  _PlanId = '_planId',
  /** column name */
  DishUpSlipGroupId = 'dishUpSlipGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'planId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansSetInput = {
  _dishUpSlipGroupId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  dishUpSlipGroupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansStddevOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansStddevPopOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansStddevSampOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DishUpSlipGroupShopPlansStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DishUpSlipGroupShopPlansStreamCursorValueInput = {
  _dishUpSlipGroupId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  dishUpSlipGroupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansSumOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** update columns of table "dishUpSlipGroupShopPlans" */
export enum DishUpSlipGroupShopPlansUpdateColumn {
  /** column name */
  _DishUpSlipGroupId = '_dishUpSlipGroupId',
  /** column name */
  _PlanId = '_planId',
  /** column name */
  DishUpSlipGroupId = 'dishUpSlipGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'planId',
  /** column name */
  ShopId = 'shopId'
}

export type DishUpSlipGroupShopPlansUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DishUpSlipGroupShopPlansIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DishUpSlipGroupShopPlansSetInput>;
  /** filter the rows which have to be updated */
  where: DishUpSlipGroupShopPlansBoolExp;
};

/** order by var_pop() on columns of table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansVarPopOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansVarSampOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "dishUpSlipGroupShopPlans" */
export type DishUpSlipGroupShopPlansVarianceOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "dishUpSlipGroupTables" */
export type DishUpSlipGroupTables = {
  __typename?: 'dishUpSlipGroupTables';
  _dishUpSlipGroupId: Scalars['Int'];
  _tableId: Scalars['Int'];
  /** An object relationship */
  dishUpSlipGroup?: Maybe<DishUpSlipGroup>;
  dishUpSlipGroupId: Scalars['uuid'];
  id: Scalars['uuid'];
  shopId: Scalars['uuid'];
  /** An object relationship */
  table?: Maybe<Table>;
  tableId: Scalars['uuid'];
};

/** order by aggregate values of table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesAggregateOrderBy = {
  avg?: InputMaybe<DishUpSlipGroupTablesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DishUpSlipGroupTablesMaxOrderBy>;
  min?: InputMaybe<DishUpSlipGroupTablesMinOrderBy>;
  stddev?: InputMaybe<DishUpSlipGroupTablesStddevOrderBy>;
  stddev_pop?: InputMaybe<DishUpSlipGroupTablesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DishUpSlipGroupTablesStddevSampOrderBy>;
  sum?: InputMaybe<DishUpSlipGroupTablesSumOrderBy>;
  var_pop?: InputMaybe<DishUpSlipGroupTablesVarPopOrderBy>;
  var_samp?: InputMaybe<DishUpSlipGroupTablesVarSampOrderBy>;
  variance?: InputMaybe<DishUpSlipGroupTablesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesArrRelInsertInput = {
  data: Array<DishUpSlipGroupTablesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DishUpSlipGroupTablesOnConflict>;
};

/** order by avg() on columns of table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesAvgOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "dishUpSlipGroupTables". All fields are combined with a logical 'AND'. */
export type DishUpSlipGroupTablesBoolExp = {
  _and?: InputMaybe<Array<DishUpSlipGroupTablesBoolExp>>;
  _dishUpSlipGroupId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<DishUpSlipGroupTablesBoolExp>;
  _or?: InputMaybe<Array<DishUpSlipGroupTablesBoolExp>>;
  _tableId?: InputMaybe<IntComparisonExp>;
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupBoolExp>;
  dishUpSlipGroupId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  table?: InputMaybe<TableBoolExp>;
  tableId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "dishUpSlipGroupTables" */
export enum DishUpSlipGroupTablesConstraint {
  /** unique or primary key constraint on columns "id" */
  DishUpSlipGroupTablesPkey = 'dishUpSlipGroupTables_pkey',
  /** unique or primary key constraint on columns "_tableId", "_dishUpSlipGroupId" */
  DishUpSlipGroupTablesTableIdDishUpSlipGroupIdKey = 'dishUpSlipGroupTables_tableId_dishUpSlipGroupId_key',
  /** unique or primary key constraint on columns "dishUpSlipGroupId", "tableId" */
  DishUpSlipGroupTablesTableUuidDishUpSlipGroupUuidKey = 'dishUpSlipGroupTables_tableUuid_dishUpSlipGroupUuid_key'
}

/** input type for inserting data into table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesInsertInput = {
  _dishUpSlipGroupId: Scalars['Int'];
  _tableId: Scalars['Int'];
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupObjRelInsertInput>;
  dishUpSlipGroupId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  shopId: Scalars['uuid'];
  table?: InputMaybe<TableObjRelInsertInput>;
  tableId: Scalars['uuid'];
};

/** order by max() on columns of table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesMaxOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesMinOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesMutationResponse = {
  __typename?: 'dishUpSlipGroupTables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DishUpSlipGroupTables>;
};

/** on_conflict condition type for table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesOnConflict = {
  constraint: DishUpSlipGroupTablesConstraint;
  update_columns?: Array<DishUpSlipGroupTablesUpdateColumn>;
  where?: InputMaybe<DishUpSlipGroupTablesBoolExp>;
};

/** Ordering options when selecting data from "dishUpSlipGroupTables". */
export type DishUpSlipGroupTablesOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
  dishUpSlipGroup?: InputMaybe<DishUpSlipGroupOrderBy>;
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  table?: InputMaybe<TableOrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** select columns of table "dishUpSlipGroupTables" */
export enum DishUpSlipGroupTablesSelectColumn {
  /** column name */
  _DishUpSlipGroupId = '_dishUpSlipGroupId',
  /** column name */
  _TableId = '_tableId',
  /** column name */
  DishUpSlipGroupId = 'dishUpSlipGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TableId = 'tableId'
}

/** order by stddev() on columns of table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesStddevOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesStddevPopOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesStddevSampOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DishUpSlipGroupTablesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DishUpSlipGroupTablesStreamCursorValueInput = {
  _dishUpSlipGroupId?: InputMaybe<Scalars['Int']>;
  _tableId?: InputMaybe<Scalars['Int']>;
  dishUpSlipGroupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tableId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesSumOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** placeholder for update columns of table "dishUpSlipGroupTables" (current role has no relevant permissions) */
export enum DishUpSlipGroupTablesUpdateColumn {
  /** placeholder (do not use) */
  _Placeholder = '_PLACEHOLDER'
}

/** order by var_pop() on columns of table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesVarPopOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesVarSampOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "dishUpSlipGroupTables" */
export type DishUpSlipGroupTablesVarianceOrderBy = {
  _dishUpSlipGroupId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "dishUpSlipGroup" */
export type DishUpSlipGroupAggregateOrderBy = {
  avg?: InputMaybe<DishUpSlipGroupAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DishUpSlipGroupMaxOrderBy>;
  min?: InputMaybe<DishUpSlipGroupMinOrderBy>;
  stddev?: InputMaybe<DishUpSlipGroupStddevOrderBy>;
  stddev_pop?: InputMaybe<DishUpSlipGroupStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DishUpSlipGroupStddevSampOrderBy>;
  sum?: InputMaybe<DishUpSlipGroupSumOrderBy>;
  var_pop?: InputMaybe<DishUpSlipGroupVarPopOrderBy>;
  var_samp?: InputMaybe<DishUpSlipGroupVarSampOrderBy>;
  variance?: InputMaybe<DishUpSlipGroupVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "dishUpSlipGroup" */
export type DishUpSlipGroupArrRelInsertInput = {
  data: Array<DishUpSlipGroupInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DishUpSlipGroupOnConflict>;
};

/** order by avg() on columns of table "dishUpSlipGroup" */
export type DishUpSlipGroupAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "dishUpSlipGroup". All fields are combined with a logical 'AND'. */
export type DishUpSlipGroupBoolExp = {
  _and?: InputMaybe<Array<DishUpSlipGroupBoolExp>>;
  _not?: InputMaybe<DishUpSlipGroupBoolExp>;
  _or?: InputMaybe<Array<DishUpSlipGroupBoolExp>>;
  dishUpSlipGroupId?: InputMaybe<UuidComparisonExp>;
  dishUpSlipGroupRoles?: InputMaybe<DishUpSlipGroupRolesBoolExp>;
  dishUpSlipGroupShopMenus?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
  dishUpSlipGroupShopPlans?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
  dishUpSlipGroupTables?: InputMaybe<DishUpSlipGroupTablesBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "dishUpSlipGroup" */
export enum DishUpSlipGroupConstraint {
  /** unique or primary key constraint on columns "dishUpSlipGroupId" */
  DishUpSlipGroupDishUpSlipGroupIdKey = 'dishUpSlipGroup_dishUpSlipGroupId_key',
  /** unique or primary key constraint on columns "serial", "shopId" */
  DishUpSlipGroupIdShopIdKey = 'dishUpSlipGroup_id_shopId_key',
  /** unique or primary key constraint on columns "serial" */
  DishUpSlipGroupPkey = 'dishUpSlipGroup_pkey'
}

/** input type for incrementing numeric columns in table "dishUpSlipGroup" */
export type DishUpSlipGroupIncInput = {
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dishUpSlipGroup" */
export type DishUpSlipGroupInsertInput = {
  dishUpSlipGroupId?: InputMaybe<Scalars['uuid']>;
  dishUpSlipGroupRoles?: InputMaybe<DishUpSlipGroupRolesArrRelInsertInput>;
  dishUpSlipGroupShopMenus?: InputMaybe<DishUpSlipGroupShopMenusArrRelInsertInput>;
  dishUpSlipGroupShopPlans?: InputMaybe<DishUpSlipGroupShopPlansArrRelInsertInput>;
  dishUpSlipGroupTables?: InputMaybe<DishUpSlipGroupTablesArrRelInsertInput>;
  name: Scalars['String'];
  serial?: InputMaybe<Scalars['Int']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "dishUpSlipGroup" */
export type DishUpSlipGroupMaxOrderBy = {
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "dishUpSlipGroup" */
export type DishUpSlipGroupMinOrderBy = {
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "dishUpSlipGroup" */
export type DishUpSlipGroupMutationResponse = {
  __typename?: 'dishUpSlipGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DishUpSlipGroup>;
};

/** input type for inserting object relation for remote table "dishUpSlipGroup" */
export type DishUpSlipGroupObjRelInsertInput = {
  data: DishUpSlipGroupInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DishUpSlipGroupOnConflict>;
};

/** on_conflict condition type for table "dishUpSlipGroup" */
export type DishUpSlipGroupOnConflict = {
  constraint: DishUpSlipGroupConstraint;
  update_columns?: Array<DishUpSlipGroupUpdateColumn>;
  where?: InputMaybe<DishUpSlipGroupBoolExp>;
};

/** Ordering options when selecting data from "dishUpSlipGroup". */
export type DishUpSlipGroupOrderBy = {
  dishUpSlipGroupId?: InputMaybe<OrderBy>;
  dishUpSlipGroupRoles_aggregate?: InputMaybe<DishUpSlipGroupRolesAggregateOrderBy>;
  dishUpSlipGroupShopMenus_aggregate?: InputMaybe<DishUpSlipGroupShopMenusAggregateOrderBy>;
  dishUpSlipGroupShopPlans_aggregate?: InputMaybe<DishUpSlipGroupShopPlansAggregateOrderBy>;
  dishUpSlipGroupTables_aggregate?: InputMaybe<DishUpSlipGroupTablesAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: dishUpSlipGroup */
export type DishUpSlipGroupPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "dishUpSlipGroup" */
export enum DishUpSlipGroupSelectColumn {
  /** column name */
  DishUpSlipGroupId = 'dishUpSlipGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "dishUpSlipGroup" */
export type DishUpSlipGroupSetInput = {
  name?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "dishUpSlipGroup" */
export type DishUpSlipGroupStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "dishUpSlipGroup" */
export type DishUpSlipGroupStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "dishUpSlipGroup" */
export type DishUpSlipGroupStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "dishUpSlipGroup" */
export type DishUpSlipGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DishUpSlipGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type DishUpSlipGroupStreamCursorValueInput = {
  dishUpSlipGroupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "dishUpSlipGroup" */
export type DishUpSlipGroupSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "dishUpSlipGroup" */
export enum DishUpSlipGroupUpdateColumn {
  /** column name */
  Name = 'name',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopId = 'shopId'
}

export type DishUpSlipGroupUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DishUpSlipGroupIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DishUpSlipGroupSetInput>;
  /** filter the rows which have to be updated */
  where: DishUpSlipGroupBoolExp;
};

/** order by var_pop() on columns of table "dishUpSlipGroup" */
export type DishUpSlipGroupVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "dishUpSlipGroup" */
export type DishUpSlipGroupVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "dishUpSlipGroup" */
export type DishUpSlipGroupVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

export enum DisplayTypeEnum {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  Text = 'text'
}

/** Boolean expression to compare columns of type "displayType_enum". All fields are combined with logical 'AND'. */
export type DisplayTypeEnumComparisonExp = {
  _eq?: InputMaybe<DisplayTypeEnum>;
  _in?: InputMaybe<Array<DisplayTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<DisplayTypeEnum>;
  _nin?: InputMaybe<Array<DisplayTypeEnum>>;
};

/** columns and relationships of "ebicaConfig" */
export type EbicaConfig = {
  __typename?: 'ebicaConfig';
  /** An array relationship */
  ebicaConfigAccessibleDashboardAccounts: Array<ReservationDashboardAccountAccessibleShop>;
  ebicaShopId: Scalars['Int'];
  enabled: Scalars['Boolean'];
  id: Scalars['uuid'];
  shopId: Scalars['uuid'];
  walkInStayTimeInMinute: Scalars['Int'];
};


/** columns and relationships of "ebicaConfig" */
export type EbicaConfigEbicaConfigAccessibleDashboardAccountsArgs = {
  distinct_on?: InputMaybe<Array<ReservationDashboardAccountAccessibleShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReservationDashboardAccountAccessibleShopOrderBy>>;
  where?: InputMaybe<ReservationDashboardAccountAccessibleShopBoolExp>;
};

/** Boolean expression to filter rows from the table "ebicaConfig". All fields are combined with a logical 'AND'. */
export type EbicaConfigBoolExp = {
  _and?: InputMaybe<Array<EbicaConfigBoolExp>>;
  _not?: InputMaybe<EbicaConfigBoolExp>;
  _or?: InputMaybe<Array<EbicaConfigBoolExp>>;
  ebicaConfigAccessibleDashboardAccounts?: InputMaybe<ReservationDashboardAccountAccessibleShopBoolExp>;
  ebicaShopId?: InputMaybe<IntComparisonExp>;
  enabled?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  walkInStayTimeInMinute?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "ebicaConfig" */
export enum EbicaConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  EbicaConfigPkey = 'ebicaConfig_pkey',
  /** unique or primary key constraint on columns "shopId" */
  EbicaConfigShopIdKey = 'ebicaConfig_shopId_key'
}

/** input type for incrementing numeric columns in table "ebicaConfig" */
export type EbicaConfigIncInput = {
  ebicaShopId?: InputMaybe<Scalars['Int']>;
  walkInStayTimeInMinute?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ebicaConfig" */
export type EbicaConfigInsertInput = {
  ebicaShopId: Scalars['Int'];
  enabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['uuid']>;
  shopId: Scalars['uuid'];
  walkInStayTimeInMinute: Scalars['Int'];
};

/** response of any mutation on the table "ebicaConfig" */
export type EbicaConfigMutationResponse = {
  __typename?: 'ebicaConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EbicaConfig>;
};

/** on_conflict condition type for table "ebicaConfig" */
export type EbicaConfigOnConflict = {
  constraint: EbicaConfigConstraint;
  update_columns?: Array<EbicaConfigUpdateColumn>;
  where?: InputMaybe<EbicaConfigBoolExp>;
};

/** Ordering options when selecting data from "ebicaConfig". */
export type EbicaConfigOrderBy = {
  ebicaConfigAccessibleDashboardAccounts_aggregate?: InputMaybe<ReservationDashboardAccountAccessibleShopAggregateOrderBy>;
  ebicaShopId?: InputMaybe<OrderBy>;
  enabled?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  walkInStayTimeInMinute?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: ebicaConfig */
export type EbicaConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "ebicaConfig" */
export enum EbicaConfigSelectColumn {
  /** column name */
  EbicaShopId = 'ebicaShopId',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  WalkInStayTimeInMinute = 'walkInStayTimeInMinute'
}

/** input type for updating data in table "ebicaConfig" */
export type EbicaConfigSetInput = {
  ebicaShopId?: InputMaybe<Scalars['Int']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  walkInStayTimeInMinute?: InputMaybe<Scalars['Int']>;
};

/** Streaming cursor of the table "ebicaConfig" */
export type EbicaConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: EbicaConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type EbicaConfigStreamCursorValueInput = {
  ebicaShopId?: InputMaybe<Scalars['Int']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  walkInStayTimeInMinute?: InputMaybe<Scalars['Int']>;
};

/** update columns of table "ebicaConfig" */
export enum EbicaConfigUpdateColumn {
  /** column name */
  EbicaShopId = 'ebicaShopId',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  WalkInStayTimeInMinute = 'walkInStayTimeInMinute'
}

export type EbicaConfigUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EbicaConfigIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EbicaConfigSetInput>;
  /** filter the rows which have to be updated */
  where: EbicaConfigBoolExp;
};

/** columns and relationships of "ebicaTable" */
export type EbicaTable = {
  __typename?: 'ebicaTable';
  /** An array relationship */
  ebicaTableAccessibleDashboardAccounts: Array<ReservationDashboardAccountAccessibleShop>;
  ebicaTableId: Scalars['Int'];
  id: Scalars['uuid'];
  shop?: Maybe<Shop>;
  shopId: Scalars['uuid'];
  tableId: Scalars['uuid'];
};


/** columns and relationships of "ebicaTable" */
export type EbicaTableEbicaTableAccessibleDashboardAccountsArgs = {
  distinct_on?: InputMaybe<Array<ReservationDashboardAccountAccessibleShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReservationDashboardAccountAccessibleShopOrderBy>>;
  where?: InputMaybe<ReservationDashboardAccountAccessibleShopBoolExp>;
};

/** Boolean expression to filter rows from the table "ebicaTable". All fields are combined with a logical 'AND'. */
export type EbicaTableBoolExp = {
  _and?: InputMaybe<Array<EbicaTableBoolExp>>;
  _not?: InputMaybe<EbicaTableBoolExp>;
  _or?: InputMaybe<Array<EbicaTableBoolExp>>;
  ebicaTableAccessibleDashboardAccounts?: InputMaybe<ReservationDashboardAccountAccessibleShopBoolExp>;
  ebicaTableId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  tableId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "ebicaTable" */
export enum EbicaTableConstraint {
  /** unique or primary key constraint on columns "id" */
  EbicaTablePkey = 'ebicaTable_pkey',
  /** unique or primary key constraint on columns "ebicaTableId", "shopId" */
  EbicaTableShopIdEbicaTableIdKey = 'ebicaTable_shopId_ebicaTableId_key'
}

/** input type for inserting data into table "ebicaTable" */
export type EbicaTableInsertInput = {
  ebicaTableId: Scalars['Int'];
  id?: InputMaybe<Scalars['uuid']>;
  shopId: Scalars['uuid'];
  tableId: Scalars['uuid'];
};

/** response of any mutation on the table "ebicaTable" */
export type EbicaTableMutationResponse = {
  __typename?: 'ebicaTable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EbicaTable>;
};

/** on_conflict condition type for table "ebicaTable" */
export type EbicaTableOnConflict = {
  constraint: EbicaTableConstraint;
  update_columns?: Array<EbicaTableUpdateColumn>;
  where?: InputMaybe<EbicaTableBoolExp>;
};

/** Ordering options when selecting data from "ebicaTable". */
export type EbicaTableOrderBy = {
  ebicaTableAccessibleDashboardAccounts_aggregate?: InputMaybe<ReservationDashboardAccountAccessibleShopAggregateOrderBy>;
  ebicaTableId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** select columns of table "ebicaTable" */
export enum EbicaTableSelectColumn {
  /** column name */
  EbicaTableId = 'ebicaTableId',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TableId = 'tableId'
}

/** Streaming cursor of the table "ebicaTable" */
export type EbicaTableStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: EbicaTableStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type EbicaTableStreamCursorValueInput = {
  ebicaTableId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tableId?: InputMaybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "ebicaTable" (current role has no relevant permissions) */
export enum EbicaTableUpdateColumn {
  /** placeholder (do not use) */
  _Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "externalOnlineMenu" */
export type ExternalOnlineMenu = {
  __typename?: 'externalOnlineMenu';
  /** An array relationship */
  externalOnlineMenuAvailableTimeTerms: Array<ExternalOnlineMenuAvailableTimeTerm>;
  /** An object relationship */
  externalOnlineMenuConfig: ExternalOnlineMenuConfig;
  id: Scalars['uuid'];
  name: Scalars['String'];
  url: Scalars['String'];
};


/** columns and relationships of "externalOnlineMenu" */
export type ExternalOnlineMenuExternalOnlineMenuAvailableTimeTermsArgs = {
  distinct_on?: InputMaybe<Array<ExternalOnlineMenuAvailableTimeTermSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalOnlineMenuAvailableTimeTermOrderBy>>;
  where?: InputMaybe<ExternalOnlineMenuAvailableTimeTermBoolExp>;
};

/** columns and relationships of "externalOnlineMenuAvailableTimeTerm" */
export type ExternalOnlineMenuAvailableTimeTerm = {
  __typename?: 'externalOnlineMenuAvailableTimeTerm';
  dayWeek: OrderableTimeTermDayWeekEnum;
  end: Scalars['time'];
  /** An object relationship */
  externalOnlineMenu: ExternalOnlineMenu;
  externalOnlineMenuId: Scalars['uuid'];
  id: Scalars['uuid'];
  start: Scalars['time'];
};

/** order by aggregate values of table "externalOnlineMenuAvailableTimeTerm" */
export type ExternalOnlineMenuAvailableTimeTermAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ExternalOnlineMenuAvailableTimeTermMaxOrderBy>;
  min?: InputMaybe<ExternalOnlineMenuAvailableTimeTermMinOrderBy>;
};

/** input type for inserting array relation for remote table "externalOnlineMenuAvailableTimeTerm" */
export type ExternalOnlineMenuAvailableTimeTermArrRelInsertInput = {
  data: Array<ExternalOnlineMenuAvailableTimeTermInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ExternalOnlineMenuAvailableTimeTermOnConflict>;
};

/**
 * Boolean expression to filter rows from the table
 * "externalOnlineMenuAvailableTimeTerm". All fields are combined with a logical 'AND'.
 */
export type ExternalOnlineMenuAvailableTimeTermBoolExp = {
  _and?: InputMaybe<Array<ExternalOnlineMenuAvailableTimeTermBoolExp>>;
  _not?: InputMaybe<ExternalOnlineMenuAvailableTimeTermBoolExp>;
  _or?: InputMaybe<Array<ExternalOnlineMenuAvailableTimeTermBoolExp>>;
  dayWeek?: InputMaybe<OrderableTimeTermDayWeekEnumComparisonExp>;
  end?: InputMaybe<TimeComparisonExp>;
  externalOnlineMenu?: InputMaybe<ExternalOnlineMenuBoolExp>;
  externalOnlineMenuId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  start?: InputMaybe<TimeComparisonExp>;
};

/** unique or primary key constraints on table "externalOnlineMenuAvailableTimeTerm" */
export enum ExternalOnlineMenuAvailableTimeTermConstraint {
  /** unique or primary key constraint on columns "id" */
  ExternalOnlineMenuAvailableTimeTermPkey = 'externalOnlineMenuAvailableTimeTerm_pkey',
  /** unique or primary key constraint on columns "externalOnlineMenuId", "dayWeek" */
  ExternalOnlineMenuIdDayWeekKey = 'externalOnlineMenuId_dayWeek_key'
}

/** input type for inserting data into table "externalOnlineMenuAvailableTimeTerm" */
export type ExternalOnlineMenuAvailableTimeTermInsertInput = {
  dayWeek: OrderableTimeTermDayWeekEnum;
  end: Scalars['time'];
  externalOnlineMenu?: InputMaybe<ExternalOnlineMenuObjRelInsertInput>;
  externalOnlineMenuId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  start: Scalars['time'];
};

/** order by max() on columns of table "externalOnlineMenuAvailableTimeTerm" */
export type ExternalOnlineMenuAvailableTimeTermMaxOrderBy = {
  externalOnlineMenuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "externalOnlineMenuAvailableTimeTerm" */
export type ExternalOnlineMenuAvailableTimeTermMinOrderBy = {
  externalOnlineMenuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "externalOnlineMenuAvailableTimeTerm" */
export type ExternalOnlineMenuAvailableTimeTermMutationResponse = {
  __typename?: 'externalOnlineMenuAvailableTimeTerm_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalOnlineMenuAvailableTimeTerm>;
};

/** on_conflict condition type for table "externalOnlineMenuAvailableTimeTerm" */
export type ExternalOnlineMenuAvailableTimeTermOnConflict = {
  constraint: ExternalOnlineMenuAvailableTimeTermConstraint;
  update_columns?: Array<ExternalOnlineMenuAvailableTimeTermUpdateColumn>;
  where?: InputMaybe<ExternalOnlineMenuAvailableTimeTermBoolExp>;
};

/** Ordering options when selecting data from "externalOnlineMenuAvailableTimeTerm". */
export type ExternalOnlineMenuAvailableTimeTermOrderBy = {
  dayWeek?: InputMaybe<OrderBy>;
  end?: InputMaybe<OrderBy>;
  externalOnlineMenu?: InputMaybe<ExternalOnlineMenuOrderBy>;
  externalOnlineMenuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  start?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: externalOnlineMenuAvailableTimeTerm */
export type ExternalOnlineMenuAvailableTimeTermPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "externalOnlineMenuAvailableTimeTerm" */
export enum ExternalOnlineMenuAvailableTimeTermSelectColumn {
  /** column name */
  DayWeek = 'dayWeek',
  /** column name */
  End = 'end',
  /** column name */
  ExternalOnlineMenuId = 'externalOnlineMenuId',
  /** column name */
  Id = 'id',
  /** column name */
  Start = 'start'
}

/** input type for updating data in table "externalOnlineMenuAvailableTimeTerm" */
export type ExternalOnlineMenuAvailableTimeTermSetInput = {
  dayWeek?: InputMaybe<OrderableTimeTermDayWeekEnum>;
  end?: InputMaybe<Scalars['time']>;
  start?: InputMaybe<Scalars['time']>;
};

/** Streaming cursor of the table "externalOnlineMenuAvailableTimeTerm" */
export type ExternalOnlineMenuAvailableTimeTermStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ExternalOnlineMenuAvailableTimeTermStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalOnlineMenuAvailableTimeTermStreamCursorValueInput = {
  dayWeek?: InputMaybe<OrderableTimeTermDayWeekEnum>;
  end?: InputMaybe<Scalars['time']>;
  externalOnlineMenuId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  start?: InputMaybe<Scalars['time']>;
};

/** update columns of table "externalOnlineMenuAvailableTimeTerm" */
export enum ExternalOnlineMenuAvailableTimeTermUpdateColumn {
  /** column name */
  DayWeek = 'dayWeek',
  /** column name */
  End = 'end',
  /** column name */
  Start = 'start'
}

export type ExternalOnlineMenuAvailableTimeTermUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalOnlineMenuAvailableTimeTermSetInput>;
  /** filter the rows which have to be updated */
  where: ExternalOnlineMenuAvailableTimeTermBoolExp;
};

/** columns and relationships of "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfig = {
  __typename?: 'externalOnlineMenuConfig';
  defaultMenuUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  externalOnlineMenus: Array<ExternalOnlineMenu>;
  id: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};


/** columns and relationships of "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfigExternalOnlineMenusArgs = {
  distinct_on?: InputMaybe<Array<ExternalOnlineMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalOnlineMenuOrderBy>>;
  where?: InputMaybe<ExternalOnlineMenuBoolExp>;
};

/** order by aggregate values of table "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfigAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ExternalOnlineMenuConfigMaxOrderBy>;
  min?: InputMaybe<ExternalOnlineMenuConfigMinOrderBy>;
};

/** input type for inserting array relation for remote table "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfigArrRelInsertInput = {
  data: Array<ExternalOnlineMenuConfigInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ExternalOnlineMenuConfigOnConflict>;
};

/** Boolean expression to filter rows from the table "externalOnlineMenuConfig". All fields are combined with a logical 'AND'. */
export type ExternalOnlineMenuConfigBoolExp = {
  _and?: InputMaybe<Array<ExternalOnlineMenuConfigBoolExp>>;
  _not?: InputMaybe<ExternalOnlineMenuConfigBoolExp>;
  _or?: InputMaybe<Array<ExternalOnlineMenuConfigBoolExp>>;
  defaultMenuUrl?: InputMaybe<StringComparisonExp>;
  externalOnlineMenus?: InputMaybe<ExternalOnlineMenuBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "externalOnlineMenuConfig" */
export enum ExternalOnlineMenuConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  ExternalOnlineMenuConfigPkey = 'externalOnlineMenuConfig_pkey'
}

/** input type for inserting data into table "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfigInsertInput = {
  defaultMenuUrl?: InputMaybe<Scalars['String']>;
  externalOnlineMenus?: InputMaybe<ExternalOnlineMenuArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfigMaxOrderBy = {
  defaultMenuUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfigMinOrderBy = {
  defaultMenuUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfigMutationResponse = {
  __typename?: 'externalOnlineMenuConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalOnlineMenuConfig>;
};

/** input type for inserting object relation for remote table "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfigObjRelInsertInput = {
  data: ExternalOnlineMenuConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ExternalOnlineMenuConfigOnConflict>;
};

/** on_conflict condition type for table "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfigOnConflict = {
  constraint: ExternalOnlineMenuConfigConstraint;
  update_columns?: Array<ExternalOnlineMenuConfigUpdateColumn>;
  where?: InputMaybe<ExternalOnlineMenuConfigBoolExp>;
};

/** Ordering options when selecting data from "externalOnlineMenuConfig". */
export type ExternalOnlineMenuConfigOrderBy = {
  defaultMenuUrl?: InputMaybe<OrderBy>;
  externalOnlineMenus_aggregate?: InputMaybe<ExternalOnlineMenuAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: externalOnlineMenuConfig */
export type ExternalOnlineMenuConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "externalOnlineMenuConfig" */
export enum ExternalOnlineMenuConfigSelectColumn {
  /** column name */
  DefaultMenuUrl = 'defaultMenuUrl',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfigSetInput = {
  defaultMenuUrl?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "externalOnlineMenuConfig" */
export type ExternalOnlineMenuConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ExternalOnlineMenuConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalOnlineMenuConfigStreamCursorValueInput = {
  defaultMenuUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "externalOnlineMenuConfig" */
export enum ExternalOnlineMenuConfigUpdateColumn {
  /** column name */
  DefaultMenuUrl = 'defaultMenuUrl'
}

export type ExternalOnlineMenuConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalOnlineMenuConfigSetInput>;
  /** filter the rows which have to be updated */
  where: ExternalOnlineMenuConfigBoolExp;
};

/** order by aggregate values of table "externalOnlineMenu" */
export type ExternalOnlineMenuAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ExternalOnlineMenuMaxOrderBy>;
  min?: InputMaybe<ExternalOnlineMenuMinOrderBy>;
};

/** input type for inserting array relation for remote table "externalOnlineMenu" */
export type ExternalOnlineMenuArrRelInsertInput = {
  data: Array<ExternalOnlineMenuInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ExternalOnlineMenuOnConflict>;
};

/** Boolean expression to filter rows from the table "externalOnlineMenu". All fields are combined with a logical 'AND'. */
export type ExternalOnlineMenuBoolExp = {
  _and?: InputMaybe<Array<ExternalOnlineMenuBoolExp>>;
  _not?: InputMaybe<ExternalOnlineMenuBoolExp>;
  _or?: InputMaybe<Array<ExternalOnlineMenuBoolExp>>;
  externalOnlineMenuAvailableTimeTerms?: InputMaybe<ExternalOnlineMenuAvailableTimeTermBoolExp>;
  externalOnlineMenuConfig?: InputMaybe<ExternalOnlineMenuConfigBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "externalOnlineMenu" */
export enum ExternalOnlineMenuConstraint {
  /** unique or primary key constraint on columns "id" */
  ExternalOnlineMenuPkey = 'externalOnlineMenu_pkey'
}

/** input type for inserting data into table "externalOnlineMenu" */
export type ExternalOnlineMenuInsertInput = {
  externalOnlineMenuAvailableTimeTerms?: InputMaybe<ExternalOnlineMenuAvailableTimeTermArrRelInsertInput>;
  externalOnlineMenuConfig?: InputMaybe<ExternalOnlineMenuConfigObjRelInsertInput>;
  externalOnlineMenuConfigId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  url: Scalars['String'];
};

/** order by max() on columns of table "externalOnlineMenu" */
export type ExternalOnlineMenuMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "externalOnlineMenu" */
export type ExternalOnlineMenuMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "externalOnlineMenu" */
export type ExternalOnlineMenuMutationResponse = {
  __typename?: 'externalOnlineMenu_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ExternalOnlineMenu>;
};

/** input type for inserting object relation for remote table "externalOnlineMenu" */
export type ExternalOnlineMenuObjRelInsertInput = {
  data: ExternalOnlineMenuInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ExternalOnlineMenuOnConflict>;
};

/** on_conflict condition type for table "externalOnlineMenu" */
export type ExternalOnlineMenuOnConflict = {
  constraint: ExternalOnlineMenuConstraint;
  update_columns?: Array<ExternalOnlineMenuUpdateColumn>;
  where?: InputMaybe<ExternalOnlineMenuBoolExp>;
};

/** Ordering options when selecting data from "externalOnlineMenu". */
export type ExternalOnlineMenuOrderBy = {
  externalOnlineMenuAvailableTimeTerms_aggregate?: InputMaybe<ExternalOnlineMenuAvailableTimeTermAggregateOrderBy>;
  externalOnlineMenuConfig?: InputMaybe<ExternalOnlineMenuConfigOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: externalOnlineMenu */
export type ExternalOnlineMenuPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "externalOnlineMenu" */
export enum ExternalOnlineMenuSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "externalOnlineMenu" */
export type ExternalOnlineMenuSetInput = {
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "externalOnlineMenu" */
export type ExternalOnlineMenuStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ExternalOnlineMenuStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ExternalOnlineMenuStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "externalOnlineMenu" */
export enum ExternalOnlineMenuUpdateColumn {
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

export type ExternalOnlineMenuUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ExternalOnlineMenuSetInput>;
  /** filter the rows which have to be updated */
  where: ExternalOnlineMenuBoolExp;
};

/** columns and relationships of "featureFlag" */
export type FeatureFlag = {
  __typename?: 'featureFlag';
  /** An array relationship */
  featureFlagShops: Array<FeatureFlagShop>;
  name: Scalars['String'];
};


/** columns and relationships of "featureFlag" */
export type FeatureFlagFeatureFlagShopsArgs = {
  distinct_on?: InputMaybe<Array<FeatureFlagShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeatureFlagShopOrderBy>>;
  where?: InputMaybe<FeatureFlagShopBoolExp>;
};

/** columns and relationships of "featureFlagShop" */
export type FeatureFlagShop = {
  __typename?: 'featureFlagShop';
  availableFrom?: Maybe<Scalars['timestamptz']>;
  availableUntil?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  featureFlag: FeatureFlag;
  name: FeatureFlagEnum;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "featureFlagShop" */
export type FeatureFlagShopAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FeatureFlagShopMaxOrderBy>;
  min?: InputMaybe<FeatureFlagShopMinOrderBy>;
};

/** input type for inserting array relation for remote table "featureFlagShop" */
export type FeatureFlagShopArrRelInsertInput = {
  data: Array<FeatureFlagShopInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<FeatureFlagShopOnConflict>;
};

/** Boolean expression to filter rows from the table "featureFlagShop". All fields are combined with a logical 'AND'. */
export type FeatureFlagShopBoolExp = {
  _and?: InputMaybe<Array<FeatureFlagShopBoolExp>>;
  _not?: InputMaybe<FeatureFlagShopBoolExp>;
  _or?: InputMaybe<Array<FeatureFlagShopBoolExp>>;
  availableFrom?: InputMaybe<TimestamptzComparisonExp>;
  availableUntil?: InputMaybe<TimestamptzComparisonExp>;
  featureFlag?: InputMaybe<FeatureFlagBoolExp>;
  name?: InputMaybe<FeatureFlagEnumComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "featureFlagShop" */
export enum FeatureFlagShopConstraint {
  /** unique or primary key constraint on columns "shopId", "name" */
  FeatureFlagShopPkey = 'featureFlagShop_pkey'
}

/** input type for inserting data into table "featureFlagShop" */
export type FeatureFlagShopInsertInput = {
  availableFrom?: InputMaybe<Scalars['timestamptz']>;
  availableUntil?: InputMaybe<Scalars['timestamptz']>;
  name: FeatureFlagEnum;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "featureFlagShop" */
export type FeatureFlagShopMaxOrderBy = {
  availableFrom?: InputMaybe<OrderBy>;
  availableUntil?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "featureFlagShop" */
export type FeatureFlagShopMinOrderBy = {
  availableFrom?: InputMaybe<OrderBy>;
  availableUntil?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "featureFlagShop" */
export type FeatureFlagShopMutationResponse = {
  __typename?: 'featureFlagShop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FeatureFlagShop>;
};

/** on_conflict condition type for table "featureFlagShop" */
export type FeatureFlagShopOnConflict = {
  constraint: FeatureFlagShopConstraint;
  update_columns?: Array<FeatureFlagShopUpdateColumn>;
  where?: InputMaybe<FeatureFlagShopBoolExp>;
};

/** Ordering options when selecting data from "featureFlagShop". */
export type FeatureFlagShopOrderBy = {
  availableFrom?: InputMaybe<OrderBy>;
  availableUntil?: InputMaybe<OrderBy>;
  featureFlag?: InputMaybe<FeatureFlagOrderBy>;
  name?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: featureFlagShop */
export type FeatureFlagShopPkColumnsInput = {
  name: FeatureFlagEnum;
  shopId: Scalars['uuid'];
};

/** select columns of table "featureFlagShop" */
export enum FeatureFlagShopSelectColumn {
  /** column name */
  AvailableFrom = 'availableFrom',
  /** column name */
  AvailableUntil = 'availableUntil',
  /** column name */
  Name = 'name',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "featureFlagShop" */
export type FeatureFlagShopSetInput = {
  availableFrom?: InputMaybe<Scalars['timestamptz']>;
  availableUntil?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<FeatureFlagEnum>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "featureFlagShop" */
export type FeatureFlagShopStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FeatureFlagShopStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type FeatureFlagShopStreamCursorValueInput = {
  availableFrom?: InputMaybe<Scalars['timestamptz']>;
  availableUntil?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<FeatureFlagEnum>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "featureFlagShop" */
export enum FeatureFlagShopUpdateColumn {
  /** column name */
  AvailableFrom = 'availableFrom',
  /** column name */
  AvailableUntil = 'availableUntil',
  /** column name */
  Name = 'name',
  /** column name */
  ShopId = 'shopId'
}

export type FeatureFlagShopUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FeatureFlagShopSetInput>;
  /** filter the rows which have to be updated */
  where: FeatureFlagShopBoolExp;
};

/** Boolean expression to filter rows from the table "featureFlag". All fields are combined with a logical 'AND'. */
export type FeatureFlagBoolExp = {
  _and?: InputMaybe<Array<FeatureFlagBoolExp>>;
  _not?: InputMaybe<FeatureFlagBoolExp>;
  _or?: InputMaybe<Array<FeatureFlagBoolExp>>;
  featureFlagShops?: InputMaybe<FeatureFlagShopBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export enum FeatureFlagEnum {
  Connect = 'connect',
  DisableCustomerAttribute = 'disableCustomerAttribute',
  Ebica = 'ebica',
  EnableAdyenOnlinePayment = 'enableAdyenOnlinePayment',
  EnableGmoOnlinePayment = 'enableGmoOnlinePayment',
  EnableOnlinePayment = 'enableOnlinePayment',
  ExternalOnlineMenu = 'externalOnlineMenu',
  Yell = 'yell'
}

/** Boolean expression to compare columns of type "featureFlag_enum". All fields are combined with logical 'AND'. */
export type FeatureFlagEnumComparisonExp = {
  _eq?: InputMaybe<FeatureFlagEnum>;
  _in?: InputMaybe<Array<FeatureFlagEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<FeatureFlagEnum>;
  _nin?: InputMaybe<Array<FeatureFlagEnum>>;
};

/** Ordering options when selecting data from "featureFlag". */
export type FeatureFlagOrderBy = {
  featureFlagShops_aggregate?: InputMaybe<FeatureFlagShopAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "featureFlag" */
export enum FeatureFlagSelectColumn {
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "featureFlag" */
export type FeatureFlagStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FeatureFlagStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type FeatureFlagStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "foodingJournalConfig" */
export type FoodingJournalConfig = {
  __typename?: 'foodingJournalConfig';
  configId: Scalars['String'];
  configPassword: Scalars['String'];
  configSecretKey: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['uuid'];
  inputUrl: Scalars['String'];
  posNumber: Scalars['String'];
  /** An object relationship */
  shop: Shop;
  shopCode: Scalars['String'];
  shopId: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "foodingJournalConfig". All fields are combined with a logical 'AND'. */
export type FoodingJournalConfigBoolExp = {
  _and?: InputMaybe<Array<FoodingJournalConfigBoolExp>>;
  _not?: InputMaybe<FoodingJournalConfigBoolExp>;
  _or?: InputMaybe<Array<FoodingJournalConfigBoolExp>>;
  configId?: InputMaybe<StringComparisonExp>;
  configPassword?: InputMaybe<StringComparisonExp>;
  configSecretKey?: InputMaybe<StringComparisonExp>;
  enabled?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inputUrl?: InputMaybe<StringComparisonExp>;
  posNumber?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopCode?: InputMaybe<StringComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "foodingJournalConfig" */
export enum FoodingJournalConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  FoodingJournalConfigPkey = 'foodingJournalConfig_pkey',
  /** unique or primary key constraint on columns "shopId" */
  FoodingJournalConfigShopIdKey = 'foodingJournalConfig_shopId_key'
}

/** input type for inserting data into table "foodingJournalConfig" */
export type FoodingJournalConfigInsertInput = {
  configId: Scalars['String'];
  configPassword: Scalars['String'];
  configSecretKey: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  inputUrl: Scalars['String'];
  posNumber: Scalars['String'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopCode: Scalars['String'];
  shopId: Scalars['uuid'];
};

/** response of any mutation on the table "foodingJournalConfig" */
export type FoodingJournalConfigMutationResponse = {
  __typename?: 'foodingJournalConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FoodingJournalConfig>;
};

/** input type for inserting object relation for remote table "foodingJournalConfig" */
export type FoodingJournalConfigObjRelInsertInput = {
  data: FoodingJournalConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<FoodingJournalConfigOnConflict>;
};

/** on_conflict condition type for table "foodingJournalConfig" */
export type FoodingJournalConfigOnConflict = {
  constraint: FoodingJournalConfigConstraint;
  update_columns?: Array<FoodingJournalConfigUpdateColumn>;
  where?: InputMaybe<FoodingJournalConfigBoolExp>;
};

/** Ordering options when selecting data from "foodingJournalConfig". */
export type FoodingJournalConfigOrderBy = {
  configId?: InputMaybe<OrderBy>;
  configPassword?: InputMaybe<OrderBy>;
  configSecretKey?: InputMaybe<OrderBy>;
  enabled?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inputUrl?: InputMaybe<OrderBy>;
  posNumber?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopCode?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: foodingJournalConfig */
export type FoodingJournalConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "foodingJournalConfig" */
export enum FoodingJournalConfigSelectColumn {
  /** column name */
  ConfigId = 'configId',
  /** column name */
  ConfigPassword = 'configPassword',
  /** column name */
  ConfigSecretKey = 'configSecretKey',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  InputUrl = 'inputUrl',
  /** column name */
  PosNumber = 'posNumber',
  /** column name */
  ShopCode = 'shopCode',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "foodingJournalConfig" */
export type FoodingJournalConfigSetInput = {
  configId?: InputMaybe<Scalars['String']>;
  configPassword?: InputMaybe<Scalars['String']>;
  configSecretKey?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  inputUrl?: InputMaybe<Scalars['String']>;
  posNumber?: InputMaybe<Scalars['String']>;
  shopCode?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "foodingJournalConfig" */
export type FoodingJournalConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FoodingJournalConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type FoodingJournalConfigStreamCursorValueInput = {
  configId?: InputMaybe<Scalars['String']>;
  configPassword?: InputMaybe<Scalars['String']>;
  configSecretKey?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  inputUrl?: InputMaybe<Scalars['String']>;
  posNumber?: InputMaybe<Scalars['String']>;
  shopCode?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "foodingJournalConfig" */
export enum FoodingJournalConfigUpdateColumn {
  /** column name */
  ConfigId = 'configId',
  /** column name */
  ConfigPassword = 'configPassword',
  /** column name */
  ConfigSecretKey = 'configSecretKey',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  InputUrl = 'inputUrl',
  /** column name */
  PosNumber = 'posNumber',
  /** column name */
  ShopCode = 'shopCode',
  /** column name */
  ShopId = 'shopId'
}

export type FoodingJournalConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FoodingJournalConfigSetInput>;
  /** filter the rows which have to be updated */
  where: FoodingJournalConfigBoolExp;
};

/** columns and relationships of "foodingJournalDepartmentMaster" */
export type FoodingJournalDepartmentMaster = {
  __typename?: 'foodingJournalDepartmentMaster';
  code: Scalars['String'];
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  /** An array relationship */
  foodingJournalMenus: Array<FoodingJournalMenu>;
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "foodingJournalDepartmentMaster" */
export type FoodingJournalDepartmentMasterFoodingJournalMenusArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalMenuOrderBy>>;
  where?: InputMaybe<FoodingJournalMenuBoolExp>;
};

/**
 * Boolean expression to filter rows from the table
 * "foodingJournalDepartmentMaster". All fields are combined with a logical 'AND'.
 */
export type FoodingJournalDepartmentMasterBoolExp = {
  _and?: InputMaybe<Array<FoodingJournalDepartmentMasterBoolExp>>;
  _not?: InputMaybe<FoodingJournalDepartmentMasterBoolExp>;
  _or?: InputMaybe<Array<FoodingJournalDepartmentMasterBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  foodingJournalMenus?: InputMaybe<FoodingJournalMenuBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "foodingJournalDepartmentMaster" */
export enum FoodingJournalDepartmentMasterConstraint {
  /** unique or primary key constraint on columns "companyId", "code" */
  FoodingJournalDepartmentMasterCompanyIdCodeKey = 'foodingJournalDepartmentMaster_companyId_code_key',
  /** unique or primary key constraint on columns "id" */
  FoodingJournalDepartmentMasterPkey = 'foodingJournalDepartmentMaster_pkey'
}

/** input type for inserting data into table "foodingJournalDepartmentMaster" */
export type FoodingJournalDepartmentMasterInsertInput = {
  code: Scalars['String'];
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  foodingJournalMenus?: InputMaybe<FoodingJournalMenuArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
};

/** response of any mutation on the table "foodingJournalDepartmentMaster" */
export type FoodingJournalDepartmentMasterMutationResponse = {
  __typename?: 'foodingJournalDepartmentMaster_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FoodingJournalDepartmentMaster>;
};

/** input type for inserting object relation for remote table "foodingJournalDepartmentMaster" */
export type FoodingJournalDepartmentMasterObjRelInsertInput = {
  data: FoodingJournalDepartmentMasterInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<FoodingJournalDepartmentMasterOnConflict>;
};

/** on_conflict condition type for table "foodingJournalDepartmentMaster" */
export type FoodingJournalDepartmentMasterOnConflict = {
  constraint: FoodingJournalDepartmentMasterConstraint;
  update_columns?: Array<FoodingJournalDepartmentMasterUpdateColumn>;
  where?: InputMaybe<FoodingJournalDepartmentMasterBoolExp>;
};

/** Ordering options when selecting data from "foodingJournalDepartmentMaster". */
export type FoodingJournalDepartmentMasterOrderBy = {
  code?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  foodingJournalMenus_aggregate?: InputMaybe<FoodingJournalMenuAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: foodingJournalDepartmentMaster */
export type FoodingJournalDepartmentMasterPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "foodingJournalDepartmentMaster" */
export enum FoodingJournalDepartmentMasterSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "foodingJournalDepartmentMaster" */
export type FoodingJournalDepartmentMasterSetInput = {
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "foodingJournalDepartmentMaster" */
export type FoodingJournalDepartmentMasterStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FoodingJournalDepartmentMasterStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type FoodingJournalDepartmentMasterStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "foodingJournalDepartmentMaster" */
export enum FoodingJournalDepartmentMasterUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name'
}

export type FoodingJournalDepartmentMasterUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FoodingJournalDepartmentMasterSetInput>;
  /** filter the rows which have to be updated */
  where: FoodingJournalDepartmentMasterBoolExp;
};

/** columns and relationships of "foodingJournalGroupMaster" */
export type FoodingJournalGroupMaster = {
  __typename?: 'foodingJournalGroupMaster';
  code: Scalars['String'];
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  /** An array relationship */
  foodingJournalMenus: Array<FoodingJournalMenu>;
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "foodingJournalGroupMaster" */
export type FoodingJournalGroupMasterFoodingJournalMenusArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalMenuOrderBy>>;
  where?: InputMaybe<FoodingJournalMenuBoolExp>;
};

/** Boolean expression to filter rows from the table "foodingJournalGroupMaster". All fields are combined with a logical 'AND'. */
export type FoodingJournalGroupMasterBoolExp = {
  _and?: InputMaybe<Array<FoodingJournalGroupMasterBoolExp>>;
  _not?: InputMaybe<FoodingJournalGroupMasterBoolExp>;
  _or?: InputMaybe<Array<FoodingJournalGroupMasterBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  foodingJournalMenus?: InputMaybe<FoodingJournalMenuBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "foodingJournalGroupMaster" */
export enum FoodingJournalGroupMasterConstraint {
  /** unique or primary key constraint on columns "companyId", "code" */
  FoodingJournalGroupMasterCompanyIdCodeKey = 'foodingJournalGroupMaster_companyId_code_key',
  /** unique or primary key constraint on columns "id" */
  FoodingJournalGroupMasterPkey = 'foodingJournalGroupMaster_pkey'
}

/** input type for inserting data into table "foodingJournalGroupMaster" */
export type FoodingJournalGroupMasterInsertInput = {
  code: Scalars['String'];
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  foodingJournalMenus?: InputMaybe<FoodingJournalMenuArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
};

/** response of any mutation on the table "foodingJournalGroupMaster" */
export type FoodingJournalGroupMasterMutationResponse = {
  __typename?: 'foodingJournalGroupMaster_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FoodingJournalGroupMaster>;
};

/** input type for inserting object relation for remote table "foodingJournalGroupMaster" */
export type FoodingJournalGroupMasterObjRelInsertInput = {
  data: FoodingJournalGroupMasterInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<FoodingJournalGroupMasterOnConflict>;
};

/** on_conflict condition type for table "foodingJournalGroupMaster" */
export type FoodingJournalGroupMasterOnConflict = {
  constraint: FoodingJournalGroupMasterConstraint;
  update_columns?: Array<FoodingJournalGroupMasterUpdateColumn>;
  where?: InputMaybe<FoodingJournalGroupMasterBoolExp>;
};

/** Ordering options when selecting data from "foodingJournalGroupMaster". */
export type FoodingJournalGroupMasterOrderBy = {
  code?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  foodingJournalMenus_aggregate?: InputMaybe<FoodingJournalMenuAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: foodingJournalGroupMaster */
export type FoodingJournalGroupMasterPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "foodingJournalGroupMaster" */
export enum FoodingJournalGroupMasterSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "foodingJournalGroupMaster" */
export type FoodingJournalGroupMasterSetInput = {
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "foodingJournalGroupMaster" */
export type FoodingJournalGroupMasterStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FoodingJournalGroupMasterStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type FoodingJournalGroupMasterStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "foodingJournalGroupMaster" */
export enum FoodingJournalGroupMasterUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name'
}

export type FoodingJournalGroupMasterUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FoodingJournalGroupMasterSetInput>;
  /** filter the rows which have to be updated */
  where: FoodingJournalGroupMasterBoolExp;
};

/** columns and relationships of "foodingJournalMenu" */
export type FoodingJournalMenu = {
  __typename?: 'foodingJournalMenu';
  choiceId?: Maybe<Scalars['uuid']>;
  code: Scalars['String'];
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  foodingJournalDepartmentId: Scalars['uuid'];
  /** An object relationship */
  foodingJournalDepartmentMaster: FoodingJournalDepartmentMaster;
  foodingJournalGroupId: Scalars['uuid'];
  /** An object relationship */
  foodingJournalGroupMaster: FoodingJournalGroupMaster;
  id: Scalars['uuid'];
  menuId?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  planChoiceId?: Maybe<Scalars['uuid']>;
  planId?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "foodingJournalMenu" */
export type FoodingJournalMenuAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FoodingJournalMenuMaxOrderBy>;
  min?: InputMaybe<FoodingJournalMenuMinOrderBy>;
};

/** input type for inserting array relation for remote table "foodingJournalMenu" */
export type FoodingJournalMenuArrRelInsertInput = {
  data: Array<FoodingJournalMenuInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<FoodingJournalMenuOnConflict>;
};

/** Boolean expression to filter rows from the table "foodingJournalMenu". All fields are combined with a logical 'AND'. */
export type FoodingJournalMenuBoolExp = {
  _and?: InputMaybe<Array<FoodingJournalMenuBoolExp>>;
  _not?: InputMaybe<FoodingJournalMenuBoolExp>;
  _or?: InputMaybe<Array<FoodingJournalMenuBoolExp>>;
  choiceId?: InputMaybe<UuidComparisonExp>;
  code?: InputMaybe<StringComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  foodingJournalDepartmentId?: InputMaybe<UuidComparisonExp>;
  foodingJournalDepartmentMaster?: InputMaybe<FoodingJournalDepartmentMasterBoolExp>;
  foodingJournalGroupId?: InputMaybe<UuidComparisonExp>;
  foodingJournalGroupMaster?: InputMaybe<FoodingJournalGroupMasterBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  planChoiceId?: InputMaybe<UuidComparisonExp>;
  planId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "foodingJournalMenu" */
export enum FoodingJournalMenuConstraint {
  /** unique or primary key constraint on columns "choiceId" */
  FoodingJournalMenuChoiceIdKey = 'foodingJournalMenu_choiceId_key',
  /** unique or primary key constraint on columns "companyId", "code" */
  FoodingJournalMenuCompanyIdCodeKey = 'foodingJournalMenu_companyId_code_key',
  /** unique or primary key constraint on columns "menuId" */
  FoodingJournalMenuMenuIdKey = 'foodingJournalMenu_menuId_key',
  /** unique or primary key constraint on columns "id" */
  FoodingJournalMenuPkey = 'foodingJournalMenu_pkey',
  /** unique or primary key constraint on columns "planChoiceId" */
  FoodingJournalMenuPlanChoiceIdKey = 'foodingJournalMenu_planChoiceId_key',
  /** unique or primary key constraint on columns "planId" */
  FoodingJournalMenuPlanIdKey = 'foodingJournalMenu_planId_key'
}

/** input type for inserting data into table "foodingJournalMenu" */
export type FoodingJournalMenuInsertInput = {
  choiceId?: InputMaybe<Scalars['uuid']>;
  code: Scalars['String'];
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  foodingJournalDepartmentId: Scalars['uuid'];
  foodingJournalDepartmentMaster?: InputMaybe<FoodingJournalDepartmentMasterObjRelInsertInput>;
  foodingJournalGroupId: Scalars['uuid'];
  foodingJournalGroupMaster?: InputMaybe<FoodingJournalGroupMasterObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  planChoiceId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "foodingJournalMenu" */
export type FoodingJournalMenuMaxOrderBy = {
  choiceId?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  foodingJournalDepartmentId?: InputMaybe<OrderBy>;
  foodingJournalGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "foodingJournalMenu" */
export type FoodingJournalMenuMinOrderBy = {
  choiceId?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  foodingJournalDepartmentId?: InputMaybe<OrderBy>;
  foodingJournalGroupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "foodingJournalMenu" */
export type FoodingJournalMenuMutationResponse = {
  __typename?: 'foodingJournalMenu_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FoodingJournalMenu>;
};

/** input type for inserting object relation for remote table "foodingJournalMenu" */
export type FoodingJournalMenuObjRelInsertInput = {
  data: FoodingJournalMenuInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<FoodingJournalMenuOnConflict>;
};

/** on_conflict condition type for table "foodingJournalMenu" */
export type FoodingJournalMenuOnConflict = {
  constraint: FoodingJournalMenuConstraint;
  update_columns?: Array<FoodingJournalMenuUpdateColumn>;
  where?: InputMaybe<FoodingJournalMenuBoolExp>;
};

/** Ordering options when selecting data from "foodingJournalMenu". */
export type FoodingJournalMenuOrderBy = {
  choiceId?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  foodingJournalDepartmentId?: InputMaybe<OrderBy>;
  foodingJournalDepartmentMaster?: InputMaybe<FoodingJournalDepartmentMasterOrderBy>;
  foodingJournalGroupId?: InputMaybe<OrderBy>;
  foodingJournalGroupMaster?: InputMaybe<FoodingJournalGroupMasterOrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: foodingJournalMenu */
export type FoodingJournalMenuPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "foodingJournalMenu" */
export enum FoodingJournalMenuSelectColumn {
  /** column name */
  ChoiceId = 'choiceId',
  /** column name */
  Code = 'code',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  FoodingJournalDepartmentId = 'foodingJournalDepartmentId',
  /** column name */
  FoodingJournalGroupId = 'foodingJournalGroupId',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Name = 'name',
  /** column name */
  PlanChoiceId = 'planChoiceId',
  /** column name */
  PlanId = 'planId'
}

/** input type for updating data in table "foodingJournalMenu" */
export type FoodingJournalMenuSetInput = {
  code?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  foodingJournalDepartmentId?: InputMaybe<Scalars['uuid']>;
  foodingJournalGroupId?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "foodingJournalMenu" */
export type FoodingJournalMenuStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FoodingJournalMenuStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type FoodingJournalMenuStreamCursorValueInput = {
  choiceId?: InputMaybe<Scalars['uuid']>;
  code?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  foodingJournalDepartmentId?: InputMaybe<Scalars['uuid']>;
  foodingJournalGroupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  planChoiceId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "foodingJournalMenu" */
export enum FoodingJournalMenuUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  FoodingJournalDepartmentId = 'foodingJournalDepartmentId',
  /** column name */
  FoodingJournalGroupId = 'foodingJournalGroupId',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Name = 'name'
}

export type FoodingJournalMenuUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FoodingJournalMenuSetInput>;
  /** filter the rows which have to be updated */
  where: FoodingJournalMenuBoolExp;
};

/** columns and relationships of "foodingJournalOnSitePaymentDetailType" */
export type FoodingJournalOnSitePaymentDetailType = {
  __typename?: 'foodingJournalOnSitePaymentDetailType';
  code: Scalars['String'];
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  onSitePaymentDetailType: OnSitePaymentDetailType;
  onSitePaymentDetailTypeId: Scalars['uuid'];
  paymentType: Scalars['Int'];
};

/**
 * Boolean expression to filter rows from the table
 * "foodingJournalOnSitePaymentDetailType". All fields are combined with a logical 'AND'.
 */
export type FoodingJournalOnSitePaymentDetailTypeBoolExp = {
  _and?: InputMaybe<Array<FoodingJournalOnSitePaymentDetailTypeBoolExp>>;
  _not?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeBoolExp>;
  _or?: InputMaybe<Array<FoodingJournalOnSitePaymentDetailTypeBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
  onSitePaymentDetailTypeId?: InputMaybe<UuidComparisonExp>;
  paymentType?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "foodingJournalOnSitePaymentDetailType" */
export enum FoodingJournalOnSitePaymentDetailTypeConstraint {
  /** unique or primary key constraint on columns "onSitePaymentDetailTypeId" */
  FoodingJournalOnSitePaymentDetailTypeOnSitePaymentDetailTypeId = 'foodingJournalOnSitePaymentDetailType_onSitePaymentDetailTypeId',
  /** unique or primary key constraint on columns "id" */
  FoodingJournalOnSitePaymentDetailTypePkey = 'foodingJournalOnSitePaymentDetailType_pkey'
}

/** input type for incrementing numeric columns in table "foodingJournalOnSitePaymentDetailType" */
export type FoodingJournalOnSitePaymentDetailTypeIncInput = {
  paymentType?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "foodingJournalOnSitePaymentDetailType" */
export type FoodingJournalOnSitePaymentDetailTypeInsertInput = {
  code: Scalars['String'];
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeObjRelInsertInput>;
  onSitePaymentDetailTypeId: Scalars['uuid'];
  paymentType: Scalars['Int'];
};

/** response of any mutation on the table "foodingJournalOnSitePaymentDetailType" */
export type FoodingJournalOnSitePaymentDetailTypeMutationResponse = {
  __typename?: 'foodingJournalOnSitePaymentDetailType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FoodingJournalOnSitePaymentDetailType>;
};

/** input type for inserting object relation for remote table "foodingJournalOnSitePaymentDetailType" */
export type FoodingJournalOnSitePaymentDetailTypeObjRelInsertInput = {
  data: FoodingJournalOnSitePaymentDetailTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeOnConflict>;
};

/** on_conflict condition type for table "foodingJournalOnSitePaymentDetailType" */
export type FoodingJournalOnSitePaymentDetailTypeOnConflict = {
  constraint: FoodingJournalOnSitePaymentDetailTypeConstraint;
  update_columns?: Array<FoodingJournalOnSitePaymentDetailTypeUpdateColumn>;
  where?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeBoolExp>;
};

/** Ordering options when selecting data from "foodingJournalOnSitePaymentDetailType". */
export type FoodingJournalOnSitePaymentDetailTypeOrderBy = {
  code?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeOrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
  paymentType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: foodingJournalOnSitePaymentDetailType */
export type FoodingJournalOnSitePaymentDetailTypePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "foodingJournalOnSitePaymentDetailType" */
export enum FoodingJournalOnSitePaymentDetailTypeSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OnSitePaymentDetailTypeId = 'onSitePaymentDetailTypeId',
  /** column name */
  PaymentType = 'paymentType'
}

/** input type for updating data in table "foodingJournalOnSitePaymentDetailType" */
export type FoodingJournalOnSitePaymentDetailTypeSetInput = {
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<Scalars['Int']>;
};

/** Streaming cursor of the table "foodingJournalOnSitePaymentDetailType" */
export type FoodingJournalOnSitePaymentDetailTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FoodingJournalOnSitePaymentDetailTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type FoodingJournalOnSitePaymentDetailTypeStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeId?: InputMaybe<Scalars['uuid']>;
  paymentType?: InputMaybe<Scalars['Int']>;
};

/** update columns of table "foodingJournalOnSitePaymentDetailType" */
export enum FoodingJournalOnSitePaymentDetailTypeUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentType = 'paymentType'
}

export type FoodingJournalOnSitePaymentDetailTypeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeSetInput>;
  /** filter the rows which have to be updated */
  where: FoodingJournalOnSitePaymentDetailTypeBoolExp;
};

/** columns and relationships of "foodingJournalOnSitePaymentDiscountType" */
export type FoodingJournalOnSitePaymentDiscountType = {
  __typename?: 'foodingJournalOnSitePaymentDiscountType';
  code: Scalars['String'];
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  discountType: Scalars['Int'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  onSitePaymentDiscountTypeId: Scalars['uuid'];
};

/**
 * Boolean expression to filter rows from the table
 * "foodingJournalOnSitePaymentDiscountType". All fields are combined with a logical 'AND'.
 */
export type FoodingJournalOnSitePaymentDiscountTypeBoolExp = {
  _and?: InputMaybe<Array<FoodingJournalOnSitePaymentDiscountTypeBoolExp>>;
  _not?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeBoolExp>;
  _or?: InputMaybe<Array<FoodingJournalOnSitePaymentDiscountTypeBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  discountType?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  onSitePaymentDiscountTypeId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "foodingJournalOnSitePaymentDiscountType" */
export enum FoodingJournalOnSitePaymentDiscountTypeConstraint {
  /** unique or primary key constraint on columns "onSitePaymentDiscountTypeId" */
  FoodingJournalOnSitePaymentDiscountTypeOnSitePaymentDiscountTy = 'foodingJournalOnSitePaymentDiscountType_onSitePaymentDiscountTy',
  /** unique or primary key constraint on columns "id" */
  FoodingJournalOnSitePaymentDiscountTypePkey = 'foodingJournalOnSitePaymentDiscountType_pkey'
}

/** input type for incrementing numeric columns in table "foodingJournalOnSitePaymentDiscountType" */
export type FoodingJournalOnSitePaymentDiscountTypeIncInput = {
  discountType?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "foodingJournalOnSitePaymentDiscountType" */
export type FoodingJournalOnSitePaymentDiscountTypeInsertInput = {
  code: Scalars['String'];
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  discountType: Scalars['Int'];
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  onSitePaymentDiscountTypeId: Scalars['uuid'];
};

/** response of any mutation on the table "foodingJournalOnSitePaymentDiscountType" */
export type FoodingJournalOnSitePaymentDiscountTypeMutationResponse = {
  __typename?: 'foodingJournalOnSitePaymentDiscountType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FoodingJournalOnSitePaymentDiscountType>;
};

/** input type for inserting object relation for remote table "foodingJournalOnSitePaymentDiscountType" */
export type FoodingJournalOnSitePaymentDiscountTypeObjRelInsertInput = {
  data: FoodingJournalOnSitePaymentDiscountTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeOnConflict>;
};

/** on_conflict condition type for table "foodingJournalOnSitePaymentDiscountType" */
export type FoodingJournalOnSitePaymentDiscountTypeOnConflict = {
  constraint: FoodingJournalOnSitePaymentDiscountTypeConstraint;
  update_columns?: Array<FoodingJournalOnSitePaymentDiscountTypeUpdateColumn>;
  where?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeBoolExp>;
};

/** Ordering options when selecting data from "foodingJournalOnSitePaymentDiscountType". */
export type FoodingJournalOnSitePaymentDiscountTypeOrderBy = {
  code?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  discountType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  onSitePaymentDiscountTypeId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: foodingJournalOnSitePaymentDiscountType */
export type FoodingJournalOnSitePaymentDiscountTypePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "foodingJournalOnSitePaymentDiscountType" */
export enum FoodingJournalOnSitePaymentDiscountTypeSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  DiscountType = 'discountType',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OnSitePaymentDiscountTypeId = 'onSitePaymentDiscountTypeId'
}

/** input type for updating data in table "foodingJournalOnSitePaymentDiscountType" */
export type FoodingJournalOnSitePaymentDiscountTypeSetInput = {
  code?: InputMaybe<Scalars['String']>;
  discountType?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "foodingJournalOnSitePaymentDiscountType" */
export type FoodingJournalOnSitePaymentDiscountTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FoodingJournalOnSitePaymentDiscountTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type FoodingJournalOnSitePaymentDiscountTypeStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  discountType?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  onSitePaymentDiscountTypeId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "foodingJournalOnSitePaymentDiscountType" */
export enum FoodingJournalOnSitePaymentDiscountTypeUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  DiscountType = 'discountType',
  /** column name */
  Name = 'name'
}

export type FoodingJournalOnSitePaymentDiscountTypeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeSetInput>;
  /** filter the rows which have to be updated */
  where: FoodingJournalOnSitePaymentDiscountTypeBoolExp;
};

/** columns and relationships of "giftMeta" */
export type GiftMeta = {
  __typename?: 'giftMeta';
  _menuId: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  menu: Menu;
  menuId: Scalars['uuid'];
  priority: Scalars['Int'];
};

/** aggregated selection of "giftMeta" */
export type GiftMetaAggregate = {
  __typename?: 'giftMeta_aggregate';
  aggregate?: Maybe<GiftMetaAggregateFields>;
  nodes: Array<GiftMeta>;
};

/** aggregate fields of "giftMeta" */
export type GiftMetaAggregateFields = {
  __typename?: 'giftMeta_aggregate_fields';
  avg?: Maybe<GiftMetaAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<GiftMetaMaxFields>;
  min?: Maybe<GiftMetaMinFields>;
  stddev?: Maybe<GiftMetaStddevFields>;
  stddev_pop?: Maybe<GiftMetaStddevPopFields>;
  stddev_samp?: Maybe<GiftMetaStddevSampFields>;
  sum?: Maybe<GiftMetaSumFields>;
  var_pop?: Maybe<GiftMetaVarPopFields>;
  var_samp?: Maybe<GiftMetaVarSampFields>;
  variance?: Maybe<GiftMetaVarianceFields>;
};


/** aggregate fields of "giftMeta" */
export type GiftMetaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GiftMetaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type GiftMetaAvgFields = {
  __typename?: 'giftMeta_avg_fields';
  _menuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "giftMeta". All fields are combined with a logical 'AND'. */
export type GiftMetaBoolExp = {
  _and?: InputMaybe<Array<GiftMetaBoolExp>>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<GiftMetaBoolExp>;
  _or?: InputMaybe<Array<GiftMetaBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "giftMeta" */
export enum GiftMetaConstraint {
  /** unique or primary key constraint on columns "_menuId" */
  GiftMetaMenuIdKey = 'giftMeta_menuId_key',
  /** unique or primary key constraint on columns "id" */
  GiftMetaPkey = 'giftMeta_pkey'
}

/** input type for incrementing numeric columns in table "giftMeta" */
export type GiftMetaIncInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "giftMeta" */
export type GiftMetaInsertInput = {
  _menuId: Scalars['Int'];
  id?: InputMaybe<Scalars['uuid']>;
  menu?: InputMaybe<MenuObjRelInsertInput>;
  menuId: Scalars['uuid'];
  priority: Scalars['Int'];
};

/** aggregate max on columns */
export type GiftMetaMaxFields = {
  __typename?: 'giftMeta_max_fields';
  _menuId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  menuId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type GiftMetaMinFields = {
  __typename?: 'giftMeta_min_fields';
  _menuId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  menuId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "giftMeta" */
export type GiftMetaMutationResponse = {
  __typename?: 'giftMeta_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GiftMeta>;
};

/** input type for inserting object relation for remote table "giftMeta" */
export type GiftMetaObjRelInsertInput = {
  data: GiftMetaInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<GiftMetaOnConflict>;
};

/** on_conflict condition type for table "giftMeta" */
export type GiftMetaOnConflict = {
  constraint: GiftMetaConstraint;
  update_columns?: Array<GiftMetaUpdateColumn>;
  where?: InputMaybe<GiftMetaBoolExp>;
};

/** Ordering options when selecting data from "giftMeta". */
export type GiftMetaOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: giftMeta */
export type GiftMetaPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "giftMeta" */
export enum GiftMetaSelectColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Priority = 'priority'
}

/** input type for updating data in table "giftMeta" */
export type GiftMetaSetInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type GiftMetaStddevFields = {
  __typename?: 'giftMeta_stddev_fields';
  _menuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type GiftMetaStddevPopFields = {
  __typename?: 'giftMeta_stddev_pop_fields';
  _menuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type GiftMetaStddevSampFields = {
  __typename?: 'giftMeta_stddev_samp_fields';
  _menuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "giftMeta" */
export type GiftMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GiftMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type GiftMetaStreamCursorValueInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type GiftMetaSumFields = {
  __typename?: 'giftMeta_sum_fields';
  _menuId?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

/** update columns of table "giftMeta" */
export enum GiftMetaUpdateColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Priority = 'priority'
}

export type GiftMetaUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GiftMetaIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GiftMetaSetInput>;
  /** filter the rows which have to be updated */
  where: GiftMetaBoolExp;
};

/** aggregate var_pop on columns */
export type GiftMetaVarPopFields = {
  __typename?: 'giftMeta_var_pop_fields';
  _menuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type GiftMetaVarSampFields = {
  __typename?: 'giftMeta_var_samp_fields';
  _menuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type GiftMetaVarianceFields = {
  __typename?: 'giftMeta_variance_fields';
  _menuId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "giftOptionMeta" */
export type GiftOptionMeta = {
  __typename?: 'giftOptionMeta';
  _optionId: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  option: Option;
  optionId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "giftOptionMeta" */
export type GiftOptionMetaAggregateOrderBy = {
  avg?: InputMaybe<GiftOptionMetaAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GiftOptionMetaMaxOrderBy>;
  min?: InputMaybe<GiftOptionMetaMinOrderBy>;
  stddev?: InputMaybe<GiftOptionMetaStddevOrderBy>;
  stddev_pop?: InputMaybe<GiftOptionMetaStddevPopOrderBy>;
  stddev_samp?: InputMaybe<GiftOptionMetaStddevSampOrderBy>;
  sum?: InputMaybe<GiftOptionMetaSumOrderBy>;
  var_pop?: InputMaybe<GiftOptionMetaVarPopOrderBy>;
  var_samp?: InputMaybe<GiftOptionMetaVarSampOrderBy>;
  variance?: InputMaybe<GiftOptionMetaVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "giftOptionMeta" */
export type GiftOptionMetaArrRelInsertInput = {
  data: Array<GiftOptionMetaInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<GiftOptionMetaOnConflict>;
};

/** order by avg() on columns of table "giftOptionMeta" */
export type GiftOptionMetaAvgOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "giftOptionMeta". All fields are combined with a logical 'AND'. */
export type GiftOptionMetaBoolExp = {
  _and?: InputMaybe<Array<GiftOptionMetaBoolExp>>;
  _not?: InputMaybe<GiftOptionMetaBoolExp>;
  _optionId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<GiftOptionMetaBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  option?: InputMaybe<OptionBoolExp>;
  optionId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "giftOptionMeta" */
export enum GiftOptionMetaConstraint {
  /** unique or primary key constraint on columns "_optionId" */
  GiftOptionMetaOptionIdKey = 'giftOptionMeta_optionId_key',
  /** unique or primary key constraint on columns "id" */
  GiftOptionMetaPkey = 'giftOptionMeta_pkey',
  /** unique or primary key constraint on columns "shopId" */
  GiftOptionMetaShopIdKey = 'giftOptionMeta_shopId_key'
}

/** input type for inserting data into table "giftOptionMeta" */
export type GiftOptionMetaInsertInput = {
  _optionId: Scalars['Int'];
  id?: InputMaybe<Scalars['uuid']>;
  option?: InputMaybe<OptionObjRelInsertInput>;
  optionId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "giftOptionMeta" */
export type GiftOptionMetaMaxOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "giftOptionMeta" */
export type GiftOptionMetaMinOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "giftOptionMeta" */
export type GiftOptionMetaMutationResponse = {
  __typename?: 'giftOptionMeta_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GiftOptionMeta>;
};

/** input type for inserting object relation for remote table "giftOptionMeta" */
export type GiftOptionMetaObjRelInsertInput = {
  data: GiftOptionMetaInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<GiftOptionMetaOnConflict>;
};

/** on_conflict condition type for table "giftOptionMeta" */
export type GiftOptionMetaOnConflict = {
  constraint: GiftOptionMetaConstraint;
  update_columns?: Array<GiftOptionMetaUpdateColumn>;
  where?: InputMaybe<GiftOptionMetaBoolExp>;
};

/** Ordering options when selecting data from "giftOptionMeta". */
export type GiftOptionMetaOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  option?: InputMaybe<OptionOrderBy>;
  optionId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "giftOptionMeta" */
export enum GiftOptionMetaSelectColumn {
  /** column name */
  _OptionId = '_optionId',
  /** column name */
  Id = 'id',
  /** column name */
  OptionId = 'optionId',
  /** column name */
  ShopId = 'shopId'
}

/** order by stddev() on columns of table "giftOptionMeta" */
export type GiftOptionMetaStddevOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "giftOptionMeta" */
export type GiftOptionMetaStddevPopOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "giftOptionMeta" */
export type GiftOptionMetaStddevSampOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "giftOptionMeta" */
export type GiftOptionMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GiftOptionMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type GiftOptionMetaStreamCursorValueInput = {
  _optionId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  optionId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "giftOptionMeta" */
export type GiftOptionMetaSumOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** placeholder for update columns of table "giftOptionMeta" (current role has no relevant permissions) */
export enum GiftOptionMetaUpdateColumn {
  /** placeholder (do not use) */
  _Placeholder = '_PLACEHOLDER'
}

/** order by var_pop() on columns of table "giftOptionMeta" */
export type GiftOptionMetaVarPopOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "giftOptionMeta" */
export type GiftOptionMetaVarSampOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "giftOptionMeta" */
export type GiftOptionMetaVarianceOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "gmoBankAccount" */
export type GmoBankAccount = {
  __typename?: 'gmoBankAccount';
  /** An array relationship */
  gmoBankAccountShops: Array<GmoBankAccountShop>;
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "gmoBankAccount" */
export type GmoBankAccountGmoBankAccountShopsArgs = {
  distinct_on?: InputMaybe<Array<GmoBankAccountShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoBankAccountShopOrderBy>>;
  where?: InputMaybe<GmoBankAccountShopBoolExp>;
};

/** columns and relationships of "gmoBankAccountShop" */
export type GmoBankAccountShop = {
  __typename?: 'gmoBankAccountShop';
  depositCycleType: DepositCycleTypeEnum;
  /** An object relationship */
  gmoBankAccount: GmoBankAccount;
  gmoBankAccountId: Scalars['uuid'];
  id: Scalars['uuid'];
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "gmoBankAccountShop" */
export type GmoBankAccountShopAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GmoBankAccountShopMaxOrderBy>;
  min?: InputMaybe<GmoBankAccountShopMinOrderBy>;
};

/** Boolean expression to filter rows from the table "gmoBankAccountShop". All fields are combined with a logical 'AND'. */
export type GmoBankAccountShopBoolExp = {
  _and?: InputMaybe<Array<GmoBankAccountShopBoolExp>>;
  _not?: InputMaybe<GmoBankAccountShopBoolExp>;
  _or?: InputMaybe<Array<GmoBankAccountShopBoolExp>>;
  depositCycleType?: InputMaybe<DepositCycleTypeEnumComparisonExp>;
  gmoBankAccount?: InputMaybe<GmoBankAccountBoolExp>;
  gmoBankAccountId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "gmoBankAccountShop" */
export type GmoBankAccountShopMaxOrderBy = {
  gmoBankAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "gmoBankAccountShop" */
export type GmoBankAccountShopMinOrderBy = {
  gmoBankAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "gmoBankAccountShop". */
export type GmoBankAccountShopOrderBy = {
  depositCycleType?: InputMaybe<OrderBy>;
  gmoBankAccount?: InputMaybe<GmoBankAccountOrderBy>;
  gmoBankAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "gmoBankAccountShop" */
export enum GmoBankAccountShopSelectColumn {
  /** column name */
  DepositCycleType = 'depositCycleType',
  /** column name */
  GmoBankAccountId = 'gmoBankAccountId',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "gmoBankAccountShop" */
export type GmoBankAccountShopStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GmoBankAccountShopStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type GmoBankAccountShopStreamCursorValueInput = {
  depositCycleType?: InputMaybe<DepositCycleTypeEnum>;
  gmoBankAccountId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "gmoBankAccount". All fields are combined with a logical 'AND'. */
export type GmoBankAccountBoolExp = {
  _and?: InputMaybe<Array<GmoBankAccountBoolExp>>;
  _not?: InputMaybe<GmoBankAccountBoolExp>;
  _or?: InputMaybe<Array<GmoBankAccountBoolExp>>;
  gmoBankAccountShops?: InputMaybe<GmoBankAccountShopBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "gmoBankAccount". */
export type GmoBankAccountOrderBy = {
  gmoBankAccountShops_aggregate?: InputMaybe<GmoBankAccountShopAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "gmoBankAccount" */
export enum GmoBankAccountSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "gmoBankAccount" */
export type GmoBankAccountStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GmoBankAccountStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type GmoBankAccountStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "gmoOffPaymentMethodType" */
export type GmoOffPaymentMethodType = {
  __typename?: 'gmoOffPaymentMethodType';
  /** An array relationship */
  gmoOffPaymentOnSitePaymentDetailTypes: Array<GmoOffPaymentOnSitePaymentDetailType>;
  /** An array relationship */
  gmoOffPaymentShopConfigTransactionFeeRates: Array<GmoOffPaymentShopConfigTransactionFeeRate>;
  value: Scalars['String'];
};


/** columns and relationships of "gmoOffPaymentMethodType" */
export type GmoOffPaymentMethodTypeGmoOffPaymentOnSitePaymentDetailTypesArgs = {
  distinct_on?: InputMaybe<Array<GmoOffPaymentOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoOffPaymentOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeBoolExp>;
};


/** columns and relationships of "gmoOffPaymentMethodType" */
export type GmoOffPaymentMethodTypeGmoOffPaymentShopConfigTransactionFeeRatesArgs = {
  distinct_on?: InputMaybe<Array<GmoOffPaymentShopConfigTransactionFeeRateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoOffPaymentShopConfigTransactionFeeRateOrderBy>>;
  where?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateBoolExp>;
};

/** Boolean expression to filter rows from the table "gmoOffPaymentMethodType". All fields are combined with a logical 'AND'. */
export type GmoOffPaymentMethodTypeBoolExp = {
  _and?: InputMaybe<Array<GmoOffPaymentMethodTypeBoolExp>>;
  _not?: InputMaybe<GmoOffPaymentMethodTypeBoolExp>;
  _or?: InputMaybe<Array<GmoOffPaymentMethodTypeBoolExp>>;
  gmoOffPaymentOnSitePaymentDetailTypes?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeBoolExp>;
  gmoOffPaymentShopConfigTransactionFeeRates?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum GmoOffPaymentMethodTypeEnum {
  CreditCard = 'creditCard'
}

/** Boolean expression to compare columns of type "gmoOffPaymentMethodType_enum". All fields are combined with logical 'AND'. */
export type GmoOffPaymentMethodTypeEnumComparisonExp = {
  _eq?: InputMaybe<GmoOffPaymentMethodTypeEnum>;
  _in?: InputMaybe<Array<GmoOffPaymentMethodTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<GmoOffPaymentMethodTypeEnum>;
  _nin?: InputMaybe<Array<GmoOffPaymentMethodTypeEnum>>;
};

/** Ordering options when selecting data from "gmoOffPaymentMethodType". */
export type GmoOffPaymentMethodTypeOrderBy = {
  gmoOffPaymentOnSitePaymentDetailTypes_aggregate?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeAggregateOrderBy>;
  gmoOffPaymentShopConfigTransactionFeeRates_aggregate?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "gmoOffPaymentMethodType" */
export enum GmoOffPaymentMethodTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "gmoOffPaymentMethodType" */
export type GmoOffPaymentMethodTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GmoOffPaymentMethodTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type GmoOffPaymentMethodTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "gmoOffPaymentOnSitePaymentDetailType" */
export type GmoOffPaymentOnSitePaymentDetailType = {
  __typename?: 'gmoOffPaymentOnSitePaymentDetailType';
  companyId: Scalars['uuid'];
  gmoOffPaymentMethodType: GmoOffPaymentMethodTypeEnum;
  /** An object relationship */
  gmoOffPaymentMethodTypeByGmooffpaymentmethodtype: GmoOffPaymentMethodType;
  id: Scalars['uuid'];
  onSitePaymentDetailTypeId: Scalars['uuid'];
};

/** order by aggregate values of table "gmoOffPaymentOnSitePaymentDetailType" */
export type GmoOffPaymentOnSitePaymentDetailTypeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeMaxOrderBy>;
  min?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "gmoOffPaymentOnSitePaymentDetailType". All fields are combined with a logical 'AND'.
 */
export type GmoOffPaymentOnSitePaymentDetailTypeBoolExp = {
  _and?: InputMaybe<Array<GmoOffPaymentOnSitePaymentDetailTypeBoolExp>>;
  _not?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeBoolExp>;
  _or?: InputMaybe<Array<GmoOffPaymentOnSitePaymentDetailTypeBoolExp>>;
  companyId?: InputMaybe<UuidComparisonExp>;
  gmoOffPaymentMethodType?: InputMaybe<GmoOffPaymentMethodTypeEnumComparisonExp>;
  gmoOffPaymentMethodTypeByGmooffpaymentmethodtype?: InputMaybe<GmoOffPaymentMethodTypeBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  onSitePaymentDetailTypeId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "gmoOffPaymentOnSitePaymentDetailType" */
export enum GmoOffPaymentOnSitePaymentDetailTypeConstraint {
  /** unique or primary key constraint on columns "onSitePaymentDetailTypeId" */
  GmoOffPaymentOnSitePaymentDetailTypeOnSitePaymentDetailTypeId = 'gmoOffPaymentOnSitePaymentDetailType_onSitePaymentDetailTypeId_',
  /** unique or primary key constraint on columns "id" */
  GmoOffPaymentOnSitePaymentDetailTypePkey = 'gmoOffPaymentOnSitePaymentDetailType_pkey'
}

/** input type for inserting data into table "gmoOffPaymentOnSitePaymentDetailType" */
export type GmoOffPaymentOnSitePaymentDetailTypeInsertInput = {
  companyId: Scalars['uuid'];
  gmoOffPaymentMethodType: GmoOffPaymentMethodTypeEnum;
  onSitePaymentDetailTypeId: Scalars['uuid'];
};

/** order by max() on columns of table "gmoOffPaymentOnSitePaymentDetailType" */
export type GmoOffPaymentOnSitePaymentDetailTypeMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "gmoOffPaymentOnSitePaymentDetailType" */
export type GmoOffPaymentOnSitePaymentDetailTypeMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "gmoOffPaymentOnSitePaymentDetailType" */
export type GmoOffPaymentOnSitePaymentDetailTypeMutationResponse = {
  __typename?: 'gmoOffPaymentOnSitePaymentDetailType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GmoOffPaymentOnSitePaymentDetailType>;
};

/** on_conflict condition type for table "gmoOffPaymentOnSitePaymentDetailType" */
export type GmoOffPaymentOnSitePaymentDetailTypeOnConflict = {
  constraint: GmoOffPaymentOnSitePaymentDetailTypeConstraint;
  update_columns?: Array<GmoOffPaymentOnSitePaymentDetailTypeUpdateColumn>;
  where?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeBoolExp>;
};

/** Ordering options when selecting data from "gmoOffPaymentOnSitePaymentDetailType". */
export type GmoOffPaymentOnSitePaymentDetailTypeOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  gmoOffPaymentMethodType?: InputMaybe<OrderBy>;
  gmoOffPaymentMethodTypeByGmooffpaymentmethodtype?: InputMaybe<GmoOffPaymentMethodTypeOrderBy>;
  id?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
};

/** select columns of table "gmoOffPaymentOnSitePaymentDetailType" */
export enum GmoOffPaymentOnSitePaymentDetailTypeSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  GmoOffPaymentMethodType = 'gmoOffPaymentMethodType',
  /** column name */
  Id = 'id',
  /** column name */
  OnSitePaymentDetailTypeId = 'onSitePaymentDetailTypeId'
}

/** Streaming cursor of the table "gmoOffPaymentOnSitePaymentDetailType" */
export type GmoOffPaymentOnSitePaymentDetailTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GmoOffPaymentOnSitePaymentDetailTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type GmoOffPaymentOnSitePaymentDetailTypeStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  gmoOffPaymentMethodType?: InputMaybe<GmoOffPaymentMethodTypeEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  onSitePaymentDetailTypeId?: InputMaybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "gmoOffPaymentOnSitePaymentDetailType" (current role has no relevant permissions) */
export enum GmoOffPaymentOnSitePaymentDetailTypeUpdateColumn {
  /** placeholder (do not use) */
  _Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "gmoOffPaymentShopConfig" */
export type GmoOffPaymentShopConfig = {
  __typename?: 'gmoOffPaymentShopConfig';
  /** An array relationship */
  gmoOffPaymentShopConfigTransactionFeeRates: Array<GmoOffPaymentShopConfigTransactionFeeRate>;
  id: Scalars['uuid'];
  isEnabled: Scalars['Boolean'];
  shopId: Scalars['uuid'];
  terminalId: Scalars['String'];
};


/** columns and relationships of "gmoOffPaymentShopConfig" */
export type GmoOffPaymentShopConfigGmoOffPaymentShopConfigTransactionFeeRatesArgs = {
  distinct_on?: InputMaybe<Array<GmoOffPaymentShopConfigTransactionFeeRateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoOffPaymentShopConfigTransactionFeeRateOrderBy>>;
  where?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateBoolExp>;
};

/** columns and relationships of "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRate = {
  __typename?: 'gmoOffPaymentShopConfigTransactionFeeRate';
  /** An object relationship */
  gmoOffPaymentMethodType: GmoOffPaymentMethodType;
  /** An object relationship */
  gmoOffPaymentShopConfig: GmoOffPaymentShopConfig;
  gmoOffPaymentShopConfigId: Scalars['uuid'];
  id: Scalars['uuid'];
  paymentMethodType: GmoOffPaymentMethodTypeEnum;
  transactionFeeRate: Scalars['numeric'];
};

/** order by aggregate values of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateAggregateOrderBy = {
  avg?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateMaxOrderBy>;
  min?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateMinOrderBy>;
  stddev?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateStddevOrderBy>;
  stddev_pop?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateStddevPopOrderBy>;
  stddev_samp?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateStddevSampOrderBy>;
  sum?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateSumOrderBy>;
  var_pop?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateVarPopOrderBy>;
  var_samp?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateVarSampOrderBy>;
  variance?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateArrRelInsertInput = {
  data: Array<GmoOffPaymentShopConfigTransactionFeeRateInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateOnConflict>;
};

/** order by avg() on columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateAvgOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "gmoOffPaymentShopConfigTransactionFeeRate". All fields are combined with a logical 'AND'.
 */
export type GmoOffPaymentShopConfigTransactionFeeRateBoolExp = {
  _and?: InputMaybe<Array<GmoOffPaymentShopConfigTransactionFeeRateBoolExp>>;
  _not?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateBoolExp>;
  _or?: InputMaybe<Array<GmoOffPaymentShopConfigTransactionFeeRateBoolExp>>;
  gmoOffPaymentMethodType?: InputMaybe<GmoOffPaymentMethodTypeBoolExp>;
  gmoOffPaymentShopConfig?: InputMaybe<GmoOffPaymentShopConfigBoolExp>;
  gmoOffPaymentShopConfigId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  paymentMethodType?: InputMaybe<GmoOffPaymentMethodTypeEnumComparisonExp>;
  transactionFeeRate?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "gmoOffPaymentShopConfigTransactionFeeRate" */
export enum GmoOffPaymentShopConfigTransactionFeeRateConstraint {
  /** unique or primary key constraint on columns "paymentMethodType", "gmoOffPaymentShopConfigId" */
  GmoOffPaymentShopConfigTransaGmoOffPaymentShopConfigIdPayKey = 'gmoOffPaymentShopConfigTransa_gmoOffPaymentShopConfigId_pay_key',
  /** unique or primary key constraint on columns "id" */
  GmoOffPaymentShopConfigTransactionFeeRatePkey = 'gmoOffPaymentShopConfigTransactionFeeRate_pkey'
}

/** input type for incrementing numeric columns in table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateIncInput = {
  transactionFeeRate?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateInsertInput = {
  gmoOffPaymentShopConfig?: InputMaybe<GmoOffPaymentShopConfigObjRelInsertInput>;
  gmoOffPaymentShopConfigId: Scalars['uuid'];
  paymentMethodType: GmoOffPaymentMethodTypeEnum;
  transactionFeeRate: Scalars['numeric'];
};

/** order by max() on columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateMaxOrderBy = {
  gmoOffPaymentShopConfigId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateMinOrderBy = {
  gmoOffPaymentShopConfigId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateMutationResponse = {
  __typename?: 'gmoOffPaymentShopConfigTransactionFeeRate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GmoOffPaymentShopConfigTransactionFeeRate>;
};

/** on_conflict condition type for table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateOnConflict = {
  constraint: GmoOffPaymentShopConfigTransactionFeeRateConstraint;
  update_columns?: Array<GmoOffPaymentShopConfigTransactionFeeRateUpdateColumn>;
  where?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateBoolExp>;
};

/** Ordering options when selecting data from "gmoOffPaymentShopConfigTransactionFeeRate". */
export type GmoOffPaymentShopConfigTransactionFeeRateOrderBy = {
  gmoOffPaymentMethodType?: InputMaybe<GmoOffPaymentMethodTypeOrderBy>;
  gmoOffPaymentShopConfig?: InputMaybe<GmoOffPaymentShopConfigOrderBy>;
  gmoOffPaymentShopConfigId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodType?: InputMaybe<OrderBy>;
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: gmoOffPaymentShopConfigTransactionFeeRate */
export type GmoOffPaymentShopConfigTransactionFeeRatePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export enum GmoOffPaymentShopConfigTransactionFeeRateSelectColumn {
  /** column name */
  GmoOffPaymentShopConfigId = 'gmoOffPaymentShopConfigId',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethodType = 'paymentMethodType',
  /** column name */
  TransactionFeeRate = 'transactionFeeRate'
}

/** input type for updating data in table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateSetInput = {
  gmoOffPaymentShopConfigId?: InputMaybe<Scalars['uuid']>;
  paymentMethodType?: InputMaybe<GmoOffPaymentMethodTypeEnum>;
  transactionFeeRate?: InputMaybe<Scalars['numeric']>;
};

/** order by stddev() on columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateStddevOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateStddevPopOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateStddevSampOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GmoOffPaymentShopConfigTransactionFeeRateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type GmoOffPaymentShopConfigTransactionFeeRateStreamCursorValueInput = {
  gmoOffPaymentShopConfigId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  paymentMethodType?: InputMaybe<GmoOffPaymentMethodTypeEnum>;
  transactionFeeRate?: InputMaybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateSumOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** update columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export enum GmoOffPaymentShopConfigTransactionFeeRateUpdateColumn {
  /** column name */
  GmoOffPaymentShopConfigId = 'gmoOffPaymentShopConfigId',
  /** column name */
  PaymentMethodType = 'paymentMethodType',
  /** column name */
  TransactionFeeRate = 'transactionFeeRate'
}

export type GmoOffPaymentShopConfigTransactionFeeRateUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateSetInput>;
  /** filter the rows which have to be updated */
  where: GmoOffPaymentShopConfigTransactionFeeRateBoolExp;
};

/** order by var_pop() on columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateVarPopOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateVarSampOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "gmoOffPaymentShopConfigTransactionFeeRate" */
export type GmoOffPaymentShopConfigTransactionFeeRateVarianceOrderBy = {
  transactionFeeRate?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "gmoOffPaymentShopConfig". All fields are combined with a logical 'AND'. */
export type GmoOffPaymentShopConfigBoolExp = {
  _and?: InputMaybe<Array<GmoOffPaymentShopConfigBoolExp>>;
  _not?: InputMaybe<GmoOffPaymentShopConfigBoolExp>;
  _or?: InputMaybe<Array<GmoOffPaymentShopConfigBoolExp>>;
  gmoOffPaymentShopConfigTransactionFeeRates?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isEnabled?: InputMaybe<BooleanComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  terminalId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "gmoOffPaymentShopConfig" */
export enum GmoOffPaymentShopConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  GmoOffPaymentShopConfigPkey = 'gmoOffPaymentShopConfig_pkey',
  /** unique or primary key constraint on columns "shopId" */
  GmoOffPaymentShopConfigShopIdKey = 'gmoOffPaymentShopConfig_shopId_key'
}

/** input type for inserting data into table "gmoOffPaymentShopConfig" */
export type GmoOffPaymentShopConfigInsertInput = {
  gmoOffPaymentShopConfigTransactionFeeRates?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateArrRelInsertInput>;
  isEnabled: Scalars['Boolean'];
  shopId: Scalars['uuid'];
  terminalId: Scalars['String'];
};

/** response of any mutation on the table "gmoOffPaymentShopConfig" */
export type GmoOffPaymentShopConfigMutationResponse = {
  __typename?: 'gmoOffPaymentShopConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GmoOffPaymentShopConfig>;
};

/** input type for inserting object relation for remote table "gmoOffPaymentShopConfig" */
export type GmoOffPaymentShopConfigObjRelInsertInput = {
  data: GmoOffPaymentShopConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<GmoOffPaymentShopConfigOnConflict>;
};

/** on_conflict condition type for table "gmoOffPaymentShopConfig" */
export type GmoOffPaymentShopConfigOnConflict = {
  constraint: GmoOffPaymentShopConfigConstraint;
  update_columns?: Array<GmoOffPaymentShopConfigUpdateColumn>;
  where?: InputMaybe<GmoOffPaymentShopConfigBoolExp>;
};

/** Ordering options when selecting data from "gmoOffPaymentShopConfig". */
export type GmoOffPaymentShopConfigOrderBy = {
  gmoOffPaymentShopConfigTransactionFeeRates_aggregate?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isEnabled?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  terminalId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: gmoOffPaymentShopConfig */
export type GmoOffPaymentShopConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "gmoOffPaymentShopConfig" */
export enum GmoOffPaymentShopConfigSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'isEnabled',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TerminalId = 'terminalId'
}

/** input type for updating data in table "gmoOffPaymentShopConfig" */
export type GmoOffPaymentShopConfigSetInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  terminalId?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "gmoOffPaymentShopConfig" */
export type GmoOffPaymentShopConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GmoOffPaymentShopConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type GmoOffPaymentShopConfigStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  terminalId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "gmoOffPaymentShopConfig" */
export enum GmoOffPaymentShopConfigUpdateColumn {
  /** column name */
  IsEnabled = 'isEnabled',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TerminalId = 'terminalId'
}

export type GmoOffPaymentShopConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GmoOffPaymentShopConfigSetInput>;
  /** filter the rows which have to be updated */
  where: GmoOffPaymentShopConfigBoolExp;
};

/** columns and relationships of "inflowSourceTag" */
export type InflowSourceTag = {
  __typename?: 'inflowSourceTag';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  displayName: Scalars['String'];
  id: Scalars['uuid'];
  isArchived: Scalars['Boolean'];
  isWalkIn: Scalars['Boolean'];
  priority: Scalars['Int'];
  /** An array relationship */
  shopInflowSourceTags: Array<ShopInflowSourceTag>;
  /** An array relationship */
  tableUsers: Array<TableUser>;
  /** An object relationship */
  winboardInflowSourceTag?: Maybe<WinboardInflowSourceTag>;
};


/** columns and relationships of "inflowSourceTag" */
export type InflowSourceTagShopInflowSourceTagsArgs = {
  distinct_on?: InputMaybe<Array<ShopInflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopInflowSourceTagOrderBy>>;
  where?: InputMaybe<ShopInflowSourceTagBoolExp>;
};


/** columns and relationships of "inflowSourceTag" */
export type InflowSourceTagTableUsersArgs = {
  distinct_on?: InputMaybe<Array<TableUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserOrderBy>>;
  where?: InputMaybe<TableUserBoolExp>;
};

/** aggregated selection of "inflowSourceTag" */
export type InflowSourceTagAggregate = {
  __typename?: 'inflowSourceTag_aggregate';
  aggregate?: Maybe<InflowSourceTagAggregateFields>;
  nodes: Array<InflowSourceTag>;
};

export type InflowSourceTagAggregateBoolExp = {
  bool_and?: InputMaybe<InflowSourceTagAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<InflowSourceTagAggregateBoolExpBoolOr>;
  count?: InputMaybe<InflowSourceTagAggregateBoolExpCount>;
};

export type InflowSourceTagAggregateBoolExpBoolAnd = {
  arguments: InflowSourceTagSelectColumnInflowSourceTagAggregateBoolExpBoolAndArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InflowSourceTagBoolExp>;
  predicate: BooleanComparisonExp;
};

export type InflowSourceTagAggregateBoolExpBoolOr = {
  arguments: InflowSourceTagSelectColumnInflowSourceTagAggregateBoolExpBoolOrArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InflowSourceTagBoolExp>;
  predicate: BooleanComparisonExp;
};

export type InflowSourceTagAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<InflowSourceTagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InflowSourceTagBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "inflowSourceTag" */
export type InflowSourceTagAggregateFields = {
  __typename?: 'inflowSourceTag_aggregate_fields';
  avg?: Maybe<InflowSourceTagAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<InflowSourceTagMaxFields>;
  min?: Maybe<InflowSourceTagMinFields>;
  stddev?: Maybe<InflowSourceTagStddevFields>;
  stddev_pop?: Maybe<InflowSourceTagStddevPopFields>;
  stddev_samp?: Maybe<InflowSourceTagStddevSampFields>;
  sum?: Maybe<InflowSourceTagSumFields>;
  var_pop?: Maybe<InflowSourceTagVarPopFields>;
  var_samp?: Maybe<InflowSourceTagVarSampFields>;
  variance?: Maybe<InflowSourceTagVarianceFields>;
};


/** aggregate fields of "inflowSourceTag" */
export type InflowSourceTagAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InflowSourceTagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "inflowSourceTag" */
export type InflowSourceTagAggregateOrderBy = {
  avg?: InputMaybe<InflowSourceTagAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InflowSourceTagMaxOrderBy>;
  min?: InputMaybe<InflowSourceTagMinOrderBy>;
  stddev?: InputMaybe<InflowSourceTagStddevOrderBy>;
  stddev_pop?: InputMaybe<InflowSourceTagStddevPopOrderBy>;
  stddev_samp?: InputMaybe<InflowSourceTagStddevSampOrderBy>;
  sum?: InputMaybe<InflowSourceTagSumOrderBy>;
  var_pop?: InputMaybe<InflowSourceTagVarPopOrderBy>;
  var_samp?: InputMaybe<InflowSourceTagVarSampOrderBy>;
  variance?: InputMaybe<InflowSourceTagVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "inflowSourceTag" */
export type InflowSourceTagArrRelInsertInput = {
  data: Array<InflowSourceTagInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<InflowSourceTagOnConflict>;
};

/** aggregate avg on columns */
export type InflowSourceTagAvgFields = {
  __typename?: 'inflowSourceTag_avg_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "inflowSourceTag" */
export type InflowSourceTagAvgOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "inflowSourceTag". All fields are combined with a logical 'AND'. */
export type InflowSourceTagBoolExp = {
  _and?: InputMaybe<Array<InflowSourceTagBoolExp>>;
  _not?: InputMaybe<InflowSourceTagBoolExp>;
  _or?: InputMaybe<Array<InflowSourceTagBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  displayName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isArchived?: InputMaybe<BooleanComparisonExp>;
  isWalkIn?: InputMaybe<BooleanComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  shopInflowSourceTags?: InputMaybe<ShopInflowSourceTagBoolExp>;
  tableUsers?: InputMaybe<TableUserBoolExp>;
  winboardInflowSourceTag?: InputMaybe<WinboardInflowSourceTagBoolExp>;
};

/** unique or primary key constraints on table "inflowSourceTag" */
export enum InflowSourceTagConstraint {
  /** unique or primary key constraint on columns "displayName", "_companyId" */
  InflowSourceTagCompanyIdDisplayNameKey = 'inflowSourceTag_companyId_displayName_key',
  /** unique or primary key constraint on columns "isWalkIn", "_companyId" */
  InflowSourceTagCompanyIdIsWalkInKey = 'inflowSourceTag_companyId_isWalkIn_key',
  /** unique or primary key constraint on columns "displayName", "companyId" */
  InflowSourceTagCompanyUuidDisplayNameKey = 'inflowSourceTag_companyUuid_displayName_key',
  /** unique or primary key constraint on columns "companyId", "isWalkIn" */
  InflowSourceTagCompanyUuidIsWalkInKey = 'inflowSourceTag_companyUuid_isWalkIn_key',
  /** unique or primary key constraint on columns "id" */
  InflowSourceTagPkey = 'inflowSourceTag_pkey'
}

/** input type for incrementing numeric columns in table "inflowSourceTag" */
export type InflowSourceTagIncInput = {
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "inflowSourceTag" */
export type InflowSourceTagInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  displayName: Scalars['String'];
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['Int']>;
  shopInflowSourceTags?: InputMaybe<ShopInflowSourceTagArrRelInsertInput>;
  winboardInflowSourceTag?: InputMaybe<WinboardInflowSourceTagObjRelInsertInput>;
};

/** aggregate max on columns */
export type InflowSourceTagMaxFields = {
  __typename?: 'inflowSourceTag_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "inflowSourceTag" */
export type InflowSourceTagMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type InflowSourceTagMinFields = {
  __typename?: 'inflowSourceTag_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "inflowSourceTag" */
export type InflowSourceTagMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "inflowSourceTag" */
export type InflowSourceTagMutationResponse = {
  __typename?: 'inflowSourceTag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InflowSourceTag>;
};

/** input type for inserting object relation for remote table "inflowSourceTag" */
export type InflowSourceTagObjRelInsertInput = {
  data: InflowSourceTagInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<InflowSourceTagOnConflict>;
};

/** on_conflict condition type for table "inflowSourceTag" */
export type InflowSourceTagOnConflict = {
  constraint: InflowSourceTagConstraint;
  update_columns?: Array<InflowSourceTagUpdateColumn>;
  where?: InputMaybe<InflowSourceTagBoolExp>;
};

/** Ordering options when selecting data from "inflowSourceTag". */
export type InflowSourceTagOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isArchived?: InputMaybe<OrderBy>;
  isWalkIn?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shopInflowSourceTags_aggregate?: InputMaybe<ShopInflowSourceTagAggregateOrderBy>;
  tableUsers_aggregate?: InputMaybe<TableUserAggregateOrderBy>;
  winboardInflowSourceTag?: InputMaybe<WinboardInflowSourceTagOrderBy>;
};

/** primary key columns input for table: inflowSourceTag */
export type InflowSourceTagPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "inflowSourceTag" */
export enum InflowSourceTagSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsWalkIn = 'isWalkIn',
  /** column name */
  Priority = 'priority'
}

/** select "inflowSourceTag_aggregate_bool_exp_bool_and_arguments_columns" columns of table "inflowSourceTag" */
export enum InflowSourceTagSelectColumnInflowSourceTagAggregateBoolExpBoolAndArgumentsColumnsType {
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsWalkIn = 'isWalkIn'
}

/** select "inflowSourceTag_aggregate_bool_exp_bool_or_arguments_columns" columns of table "inflowSourceTag" */
export enum InflowSourceTagSelectColumnInflowSourceTagAggregateBoolExpBoolOrArgumentsColumnsType {
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsWalkIn = 'isWalkIn'
}

/** input type for updating data in table "inflowSourceTag" */
export type InflowSourceTagSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type InflowSourceTagStddevFields = {
  __typename?: 'inflowSourceTag_stddev_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "inflowSourceTag" */
export type InflowSourceTagStddevOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type InflowSourceTagStddevPopFields = {
  __typename?: 'inflowSourceTag_stddev_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "inflowSourceTag" */
export type InflowSourceTagStddevPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type InflowSourceTagStddevSampFields = {
  __typename?: 'inflowSourceTag_stddev_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "inflowSourceTag" */
export type InflowSourceTagStddevSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "inflowSourceTag" */
export type InflowSourceTagStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: InflowSourceTagStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type InflowSourceTagStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isWalkIn?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type InflowSourceTagSumFields = {
  __typename?: 'inflowSourceTag_sum_fields';
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "inflowSourceTag" */
export type InflowSourceTagSumOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** update columns of table "inflowSourceTag" */
export enum InflowSourceTagUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Priority = 'priority'
}

export type InflowSourceTagUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InflowSourceTagIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InflowSourceTagSetInput>;
  /** filter the rows which have to be updated */
  where: InflowSourceTagBoolExp;
};

/** aggregate var_pop on columns */
export type InflowSourceTagVarPopFields = {
  __typename?: 'inflowSourceTag_var_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "inflowSourceTag" */
export type InflowSourceTagVarPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type InflowSourceTagVarSampFields = {
  __typename?: 'inflowSourceTag_var_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "inflowSourceTag" */
export type InflowSourceTagVarSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type InflowSourceTagVarianceFields = {
  __typename?: 'inflowSourceTag_variance_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "inflowSourceTag" */
export type InflowSourceTagVarianceOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** columns and relationships of "itemSales" */
export type ItemSales = {
  __typename?: 'itemSales';
  billingAmount: Scalars['Int'];
  discountAmount: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  payingActivePlanChoice?: Maybe<PayingActivePlanChoice>;
  payingActivePlanChoiceId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  payingActivePlanOpenPriceMeta?: Maybe<PayingActivePlanOpenPriceMeta>;
  payingActivePlanOpenPriceMetaId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  payingMenuOrderItem?: Maybe<PayingMenuOrderItem>;
  payingMenuOrderItemId?: Maybe<Scalars['uuid']>;
  sellingAmount: Scalars['Int'];
  taxAmount: Scalars['Int'];
  taxMethod: Scalars['String'];
  taxRate: Scalars['numeric'];
};

/** Boolean expression to filter rows from the table "itemSales". All fields are combined with a logical 'AND'. */
export type ItemSalesBoolExp = {
  _and?: InputMaybe<Array<ItemSalesBoolExp>>;
  _not?: InputMaybe<ItemSalesBoolExp>;
  _or?: InputMaybe<Array<ItemSalesBoolExp>>;
  billingAmount?: InputMaybe<IntComparisonExp>;
  discountAmount?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  payingActivePlanChoice?: InputMaybe<PayingActivePlanChoiceBoolExp>;
  payingActivePlanChoiceId?: InputMaybe<UuidComparisonExp>;
  payingActivePlanOpenPriceMeta?: InputMaybe<PayingActivePlanOpenPriceMetaBoolExp>;
  payingActivePlanOpenPriceMetaId?: InputMaybe<UuidComparisonExp>;
  payingMenuOrderItem?: InputMaybe<PayingMenuOrderItemBoolExp>;
  payingMenuOrderItemId?: InputMaybe<UuidComparisonExp>;
  sellingAmount?: InputMaybe<IntComparisonExp>;
  taxAmount?: InputMaybe<IntComparisonExp>;
  taxMethod?: InputMaybe<StringComparisonExp>;
  taxRate?: InputMaybe<NumericComparisonExp>;
};

/** Ordering options when selecting data from "itemSales". */
export type ItemSalesOrderBy = {
  billingAmount?: InputMaybe<OrderBy>;
  discountAmount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  payingActivePlanChoice?: InputMaybe<PayingActivePlanChoiceOrderBy>;
  payingActivePlanChoiceId?: InputMaybe<OrderBy>;
  payingActivePlanOpenPriceMeta?: InputMaybe<PayingActivePlanOpenPriceMetaOrderBy>;
  payingActivePlanOpenPriceMetaId?: InputMaybe<OrderBy>;
  payingMenuOrderItem?: InputMaybe<PayingMenuOrderItemOrderBy>;
  payingMenuOrderItemId?: InputMaybe<OrderBy>;
  sellingAmount?: InputMaybe<OrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxMethod?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** select columns of table "itemSales" */
export enum ItemSalesSelectColumn {
  /** column name */
  BillingAmount = 'billingAmount',
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  Id = 'id',
  /** column name */
  PayingActivePlanChoiceId = 'payingActivePlanChoiceId',
  /** column name */
  PayingActivePlanOpenPriceMetaId = 'payingActivePlanOpenPriceMetaId',
  /** column name */
  PayingMenuOrderItemId = 'payingMenuOrderItemId',
  /** column name */
  SellingAmount = 'sellingAmount',
  /** column name */
  TaxAmount = 'taxAmount',
  /** column name */
  TaxMethod = 'taxMethod',
  /** column name */
  TaxRate = 'taxRate'
}

/** Streaming cursor of the table "itemSales" */
export type ItemSalesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ItemSalesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ItemSalesStreamCursorValueInput = {
  billingAmount?: InputMaybe<Scalars['Int']>;
  discountAmount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  payingActivePlanChoiceId?: InputMaybe<Scalars['uuid']>;
  payingActivePlanOpenPriceMetaId?: InputMaybe<Scalars['uuid']>;
  payingMenuOrderItemId?: InputMaybe<Scalars['uuid']>;
  sellingAmount?: InputMaybe<Scalars['Int']>;
  taxAmount?: InputMaybe<Scalars['Int']>;
  taxMethod?: InputMaybe<Scalars['String']>;
  taxRate?: InputMaybe<Scalars['numeric']>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
export type KdDisplayItemKdDisplayTargetsKdDisplayTarget = {
  __typename?: 'kdDisplayItemKdDisplayTargetsKdDisplayTarget';
  id: Scalars['uuid'];
  kdDisplayItemId: Scalars['uuid'];
  /** An object relationship */
  kdDisplayTarget: KdDisplayTarget;
  kdDisplayTargetId: Scalars['uuid'];
};

/** order by aggregate values of table "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
export type KdDisplayItemKdDisplayTargetsKdDisplayTargetAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<KdDisplayItemKdDisplayTargetsKdDisplayTargetMaxOrderBy>;
  min?: InputMaybe<KdDisplayItemKdDisplayTargetsKdDisplayTargetMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "kdDisplayItemKdDisplayTargetsKdDisplayTarget". All fields are combined with a logical 'AND'.
 */
export type KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp = {
  _and?: InputMaybe<Array<KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp>>;
  _not?: InputMaybe<KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp>;
  _or?: InputMaybe<Array<KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  kdDisplayItemId?: InputMaybe<UuidComparisonExp>;
  kdDisplayTarget?: InputMaybe<KdDisplayTargetBoolExp>;
  kdDisplayTargetId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
export type KdDisplayItemKdDisplayTargetsKdDisplayTargetMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  kdDisplayItemId?: InputMaybe<OrderBy>;
  kdDisplayTargetId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
export type KdDisplayItemKdDisplayTargetsKdDisplayTargetMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  kdDisplayItemId?: InputMaybe<OrderBy>;
  kdDisplayTargetId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
export type KdDisplayItemKdDisplayTargetsKdDisplayTargetMutationResponse = {
  __typename?: 'kdDisplayItemKdDisplayTargetsKdDisplayTarget_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<KdDisplayItemKdDisplayTargetsKdDisplayTarget>;
};

/** Ordering options when selecting data from "kdDisplayItemKdDisplayTargetsKdDisplayTarget". */
export type KdDisplayItemKdDisplayTargetsKdDisplayTargetOrderBy = {
  id?: InputMaybe<OrderBy>;
  kdDisplayItemId?: InputMaybe<OrderBy>;
  kdDisplayTarget?: InputMaybe<KdDisplayTargetOrderBy>;
  kdDisplayTargetId?: InputMaybe<OrderBy>;
};

/** select columns of table "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
export enum KdDisplayItemKdDisplayTargetsKdDisplayTargetSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  KdDisplayItemId = 'kdDisplayItemId',
  /** column name */
  KdDisplayTargetId = 'kdDisplayTargetId'
}

/** Streaming cursor of the table "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
export type KdDisplayItemKdDisplayTargetsKdDisplayTargetStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: KdDisplayItemKdDisplayTargetsKdDisplayTargetStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type KdDisplayItemKdDisplayTargetsKdDisplayTargetStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  kdDisplayItemId?: InputMaybe<Scalars['uuid']>;
  kdDisplayTargetId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "kdDisplayTarget" */
export type KdDisplayTarget = {
  __typename?: 'kdDisplayTarget';
  _roleId?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** An array relationship */
  kdDisplayItemKdDisplayTargetsKdDisplayTargets: Array<KdDisplayItemKdDisplayTargetsKdDisplayTarget>;
  name: Scalars['String'];
  /** An object relationship */
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An array relationship */
  shopMenuKdDisplayTargets: Array<ShopMenuKdDisplayTarget>;
};


/** columns and relationships of "kdDisplayTarget" */
export type KdDisplayTargetKdDisplayItemKdDisplayTargetsKdDisplayTargetsArgs = {
  distinct_on?: InputMaybe<Array<KdDisplayItemKdDisplayTargetsKdDisplayTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KdDisplayItemKdDisplayTargetsKdDisplayTargetOrderBy>>;
  where?: InputMaybe<KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp>;
};


/** columns and relationships of "kdDisplayTarget" */
export type KdDisplayTargetShopMenuKdDisplayTargetsArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuKdDisplayTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuKdDisplayTargetOrderBy>>;
  where?: InputMaybe<ShopMenuKdDisplayTargetBoolExp>;
};

/** order by aggregate values of table "kdDisplayTarget" */
export type KdDisplayTargetAggregateOrderBy = {
  avg?: InputMaybe<KdDisplayTargetAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<KdDisplayTargetMaxOrderBy>;
  min?: InputMaybe<KdDisplayTargetMinOrderBy>;
  stddev?: InputMaybe<KdDisplayTargetStddevOrderBy>;
  stddev_pop?: InputMaybe<KdDisplayTargetStddevPopOrderBy>;
  stddev_samp?: InputMaybe<KdDisplayTargetStddevSampOrderBy>;
  sum?: InputMaybe<KdDisplayTargetSumOrderBy>;
  var_pop?: InputMaybe<KdDisplayTargetVarPopOrderBy>;
  var_samp?: InputMaybe<KdDisplayTargetVarSampOrderBy>;
  variance?: InputMaybe<KdDisplayTargetVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "kdDisplayTarget" */
export type KdDisplayTargetArrRelInsertInput = {
  data: Array<KdDisplayTargetInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<KdDisplayTargetOnConflict>;
};

/** order by avg() on columns of table "kdDisplayTarget" */
export type KdDisplayTargetAvgOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "kdDisplayTarget". All fields are combined with a logical 'AND'. */
export type KdDisplayTargetBoolExp = {
  _and?: InputMaybe<Array<KdDisplayTargetBoolExp>>;
  _not?: InputMaybe<KdDisplayTargetBoolExp>;
  _or?: InputMaybe<Array<KdDisplayTargetBoolExp>>;
  _roleId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  kdDisplayItemKdDisplayTargetsKdDisplayTargets?: InputMaybe<KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  role?: InputMaybe<RoleBoolExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopMenuKdDisplayTargets?: InputMaybe<ShopMenuKdDisplayTargetBoolExp>;
};

/** unique or primary key constraints on table "kdDisplayTarget" */
export enum KdDisplayTargetConstraint {
  /** unique or primary key constraint on columns "id", "shopId" */
  KdDisplayTargetIdShopIdKey = 'kdDisplayTarget_id_shopId_key',
  /** unique or primary key constraint on columns "id" */
  KdDisplayTargetPkey = 'kdDisplayTarget_pkey'
}

/** input type for incrementing numeric columns in table "kdDisplayTarget" */
export type KdDisplayTargetIncInput = {
  _roleId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "kdDisplayTarget" */
export type KdDisplayTargetInsertInput = {
  _roleId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  role?: InputMaybe<RoleObjRelInsertInput>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  shopMenuKdDisplayTargets?: InputMaybe<ShopMenuKdDisplayTargetArrRelInsertInput>;
};

/** order by max() on columns of table "kdDisplayTarget" */
export type KdDisplayTargetMaxOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "kdDisplayTarget" */
export type KdDisplayTargetMinOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "kdDisplayTarget" */
export type KdDisplayTargetMutationResponse = {
  __typename?: 'kdDisplayTarget_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<KdDisplayTarget>;
};

/** input type for inserting object relation for remote table "kdDisplayTarget" */
export type KdDisplayTargetObjRelInsertInput = {
  data: KdDisplayTargetInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<KdDisplayTargetOnConflict>;
};

/** on_conflict condition type for table "kdDisplayTarget" */
export type KdDisplayTargetOnConflict = {
  constraint: KdDisplayTargetConstraint;
  update_columns?: Array<KdDisplayTargetUpdateColumn>;
  where?: InputMaybe<KdDisplayTargetBoolExp>;
};

/** Ordering options when selecting data from "kdDisplayTarget". */
export type KdDisplayTargetOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  kdDisplayItemKdDisplayTargetsKdDisplayTargets_aggregate?: InputMaybe<KdDisplayItemKdDisplayTargetsKdDisplayTargetAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  role?: InputMaybe<RoleOrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopMenuKdDisplayTargets_aggregate?: InputMaybe<ShopMenuKdDisplayTargetAggregateOrderBy>;
};

/** primary key columns input for table: kdDisplayTarget */
export type KdDisplayTargetPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "kdDisplayTarget" */
export enum KdDisplayTargetSelectColumn {
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "kdDisplayTarget" */
export type KdDisplayTargetSetInput = {
  _roleId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "kdDisplayTarget" */
export type KdDisplayTargetStddevOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "kdDisplayTarget" */
export type KdDisplayTargetStddevPopOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "kdDisplayTarget" */
export type KdDisplayTargetStddevSampOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "kdDisplayTarget" */
export type KdDisplayTargetStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: KdDisplayTargetStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type KdDisplayTargetStreamCursorValueInput = {
  _roleId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "kdDisplayTarget" */
export type KdDisplayTargetSumOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
};

/** update columns of table "kdDisplayTarget" */
export enum KdDisplayTargetUpdateColumn {
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

export type KdDisplayTargetUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<KdDisplayTargetIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<KdDisplayTargetSetInput>;
  /** filter the rows which have to be updated */
  where: KdDisplayTargetBoolExp;
};

/** order by var_pop() on columns of table "kdDisplayTarget" */
export type KdDisplayTargetVarPopOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "kdDisplayTarget" */
export type KdDisplayTargetVarSampOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "kdDisplayTarget" */
export type KdDisplayTargetVarianceOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "lineChannelConfig" */
export type LineChannelConfig = {
  __typename?: 'lineChannelConfig';
  channelId?: Maybe<Scalars['String']>;
  channelSecret?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  liffId: Scalars['String'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "lineChannelConfig". All fields are combined with a logical 'AND'. */
export type LineChannelConfigBoolExp = {
  _and?: InputMaybe<Array<LineChannelConfigBoolExp>>;
  _not?: InputMaybe<LineChannelConfigBoolExp>;
  _or?: InputMaybe<Array<LineChannelConfigBoolExp>>;
  channelId?: InputMaybe<StringComparisonExp>;
  channelSecret?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  liffId?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "lineChannelConfig" */
export enum LineChannelConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  LineMessagingConfigPkey = 'lineMessagingConfig_pkey',
  /** unique or primary key constraint on columns "shopId" */
  LineMessagingConfigShopIdKey = 'lineMessagingConfig_shopId_key'
}

/** input type for inserting data into table "lineChannelConfig" */
export type LineChannelConfigInsertInput = {
  channelId?: InputMaybe<Scalars['String']>;
  channelSecret?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  liffId: Scalars['String'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** response of any mutation on the table "lineChannelConfig" */
export type LineChannelConfigMutationResponse = {
  __typename?: 'lineChannelConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LineChannelConfig>;
};

/** input type for inserting object relation for remote table "lineChannelConfig" */
export type LineChannelConfigObjRelInsertInput = {
  data: LineChannelConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<LineChannelConfigOnConflict>;
};

/** on_conflict condition type for table "lineChannelConfig" */
export type LineChannelConfigOnConflict = {
  constraint: LineChannelConfigConstraint;
  update_columns?: Array<LineChannelConfigUpdateColumn>;
  where?: InputMaybe<LineChannelConfigBoolExp>;
};

/** Ordering options when selecting data from "lineChannelConfig". */
export type LineChannelConfigOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  channelSecret?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  liffId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: lineChannelConfig */
export type LineChannelConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "lineChannelConfig" */
export enum LineChannelConfigSelectColumn {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  ChannelSecret = 'channelSecret',
  /** column name */
  Id = 'id',
  /** column name */
  LiffId = 'liffId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "lineChannelConfig" */
export type LineChannelConfigSetInput = {
  channelId?: InputMaybe<Scalars['String']>;
  channelSecret?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  liffId?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "lineChannelConfig" */
export type LineChannelConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LineChannelConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type LineChannelConfigStreamCursorValueInput = {
  channelId?: InputMaybe<Scalars['String']>;
  channelSecret?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  liffId?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "lineChannelConfig" */
export enum LineChannelConfigUpdateColumn {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  ChannelSecret = 'channelSecret',
  /** column name */
  Id = 'id',
  /** column name */
  LiffId = 'liffId',
  /** column name */
  ShopId = 'shopId'
}

export type LineChannelConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LineChannelConfigSetInput>;
  /** filter the rows which have to be updated */
  where: LineChannelConfigBoolExp;
};

/** columns and relationships of "lineOfficialAccount" */
export type LineOfficialAccount = {
  __typename?: 'lineOfficialAccount';
  channelId?: Maybe<Scalars['String']>;
  channelSecret?: Maybe<Scalars['String']>;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  lineId: Scalars['String'];
  lineOfficialAccountId: Scalars['Int'];
  longLivedChannelAccessToken: Scalars['String'];
  serial: Scalars['Int'];
  /** An array relationship */
  shops: Array<Shop>;
};


/** columns and relationships of "lineOfficialAccount" */
export type LineOfficialAccountShopsArgs = {
  distinct_on?: InputMaybe<Array<ShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOrderBy>>;
  where?: InputMaybe<ShopBoolExp>;
};

/** order by aggregate values of table "lineOfficialAccount" */
export type LineOfficialAccountAggregateOrderBy = {
  avg?: InputMaybe<LineOfficialAccountAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LineOfficialAccountMaxOrderBy>;
  min?: InputMaybe<LineOfficialAccountMinOrderBy>;
  stddev?: InputMaybe<LineOfficialAccountStddevOrderBy>;
  stddev_pop?: InputMaybe<LineOfficialAccountStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LineOfficialAccountStddevSampOrderBy>;
  sum?: InputMaybe<LineOfficialAccountSumOrderBy>;
  var_pop?: InputMaybe<LineOfficialAccountVarPopOrderBy>;
  var_samp?: InputMaybe<LineOfficialAccountVarSampOrderBy>;
  variance?: InputMaybe<LineOfficialAccountVarianceOrderBy>;
};

/** order by avg() on columns of table "lineOfficialAccount" */
export type LineOfficialAccountAvgOrderBy = {
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "lineOfficialAccount". All fields are combined with a logical 'AND'. */
export type LineOfficialAccountBoolExp = {
  _and?: InputMaybe<Array<LineOfficialAccountBoolExp>>;
  _not?: InputMaybe<LineOfficialAccountBoolExp>;
  _or?: InputMaybe<Array<LineOfficialAccountBoolExp>>;
  channelId?: InputMaybe<StringComparisonExp>;
  channelSecret?: InputMaybe<StringComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lineId?: InputMaybe<StringComparisonExp>;
  lineOfficialAccountId?: InputMaybe<IntComparisonExp>;
  longLivedChannelAccessToken?: InputMaybe<StringComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shops?: InputMaybe<ShopBoolExp>;
};

/** order by max() on columns of table "lineOfficialAccount" */
export type LineOfficialAccountMaxOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  channelSecret?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lineId?: InputMaybe<OrderBy>;
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  longLivedChannelAccessToken?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "lineOfficialAccount" */
export type LineOfficialAccountMinOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  channelSecret?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lineId?: InputMaybe<OrderBy>;
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  longLivedChannelAccessToken?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "lineOfficialAccount". */
export type LineOfficialAccountOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  channelSecret?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lineId?: InputMaybe<OrderBy>;
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  longLivedChannelAccessToken?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shops_aggregate?: InputMaybe<ShopAggregateOrderBy>;
};

/** select columns of table "lineOfficialAccount" */
export enum LineOfficialAccountSelectColumn {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  ChannelSecret = 'channelSecret',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  LineId = 'lineId',
  /** column name */
  LineOfficialAccountId = 'lineOfficialAccountId',
  /** column name */
  LongLivedChannelAccessToken = 'longLivedChannelAccessToken',
  /** column name */
  Serial = 'serial'
}

/** order by stddev() on columns of table "lineOfficialAccount" */
export type LineOfficialAccountStddevOrderBy = {
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "lineOfficialAccount" */
export type LineOfficialAccountStddevPopOrderBy = {
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "lineOfficialAccount" */
export type LineOfficialAccountStddevSampOrderBy = {
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "lineOfficialAccount" */
export type LineOfficialAccountStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LineOfficialAccountStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type LineOfficialAccountStreamCursorValueInput = {
  channelId?: InputMaybe<Scalars['String']>;
  channelSecret?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  lineId?: InputMaybe<Scalars['String']>;
  lineOfficialAccountId?: InputMaybe<Scalars['Int']>;
  longLivedChannelAccessToken?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lineOfficialAccount" */
export type LineOfficialAccountSumOrderBy = {
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "lineOfficialAccount" */
export type LineOfficialAccountVarPopOrderBy = {
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "lineOfficialAccount" */
export type LineOfficialAccountVarSampOrderBy = {
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "lineOfficialAccount" */
export type LineOfficialAccountVarianceOrderBy = {
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** columns and relationships of "lineProfile" */
export type LineProfile = {
  __typename?: 'lineProfile';
  /** An object relationship */
  customer: Customer;
  name: Scalars['String'];
  profileImage: Scalars['String'];
};

/** Boolean expression to filter rows from the table "lineProfile". All fields are combined with a logical 'AND'. */
export type LineProfileBoolExp = {
  _and?: InputMaybe<Array<LineProfileBoolExp>>;
  _not?: InputMaybe<LineProfileBoolExp>;
  _or?: InputMaybe<Array<LineProfileBoolExp>>;
  customer?: InputMaybe<CustomerBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  profileImage?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "lineProfile". */
export type LineProfileOrderBy = {
  customer?: InputMaybe<CustomerOrderBy>;
  name?: InputMaybe<OrderBy>;
  profileImage?: InputMaybe<OrderBy>;
};

/** select columns of table "lineProfile" */
export enum LineProfileSelectColumn {
  /** column name */
  Name = 'name',
  /** column name */
  ProfileImage = 'profileImage'
}

/** Streaming cursor of the table "lineProfile" */
export type LineProfileStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LineProfileStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type LineProfileStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "lineReportingBotConfig" */
export type LineReportingBotConfig = {
  __typename?: 'lineReportingBotConfig';
  /** An object relationship */
  corporation: Corporation;
  corporationId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isSuspended: Scalars['Boolean'];
  lineGroupId: Scalars['String'];
  /** An array relationship */
  lineReportingBotConfigShops: Array<LineReportingBotConfigShop>;
  lineReportingBotVerificationId: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "lineReportingBotConfig" */
export type LineReportingBotConfigLineReportingBotConfigShopsArgs = {
  distinct_on?: InputMaybe<Array<LineReportingBotConfigShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineReportingBotConfigShopOrderBy>>;
  where?: InputMaybe<LineReportingBotConfigShopBoolExp>;
};

/** columns and relationships of "lineReportingBotConfigShop" */
export type LineReportingBotConfigShop = {
  __typename?: 'lineReportingBotConfigShop';
  id: Scalars['uuid'];
  /** An object relationship */
  lineReportingBotConfig: LineReportingBotConfig;
  lineReportingBotConfigId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "lineReportingBotConfigShop" */
export type LineReportingBotConfigShopAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LineReportingBotConfigShopMaxOrderBy>;
  min?: InputMaybe<LineReportingBotConfigShopMinOrderBy>;
};

/** Boolean expression to filter rows from the table "lineReportingBotConfigShop". All fields are combined with a logical 'AND'. */
export type LineReportingBotConfigShopBoolExp = {
  _and?: InputMaybe<Array<LineReportingBotConfigShopBoolExp>>;
  _not?: InputMaybe<LineReportingBotConfigShopBoolExp>;
  _or?: InputMaybe<Array<LineReportingBotConfigShopBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  lineReportingBotConfig?: InputMaybe<LineReportingBotConfigBoolExp>;
  lineReportingBotConfigId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "lineReportingBotConfigShop" */
export enum LineReportingBotConfigShopConstraint {
  /** unique or primary key constraint on columns "id" */
  LineReportingBotConfigShopPkey = 'lineReportingBotConfigShop_pkey'
}

/** input type for inserting data into table "lineReportingBotConfigShop" */
export type LineReportingBotConfigShopInsertInput = {
  lineReportingBotConfigId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "lineReportingBotConfigShop" */
export type LineReportingBotConfigShopMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  lineReportingBotConfigId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "lineReportingBotConfigShop" */
export type LineReportingBotConfigShopMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  lineReportingBotConfigId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "lineReportingBotConfigShop" */
export type LineReportingBotConfigShopMutationResponse = {
  __typename?: 'lineReportingBotConfigShop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LineReportingBotConfigShop>;
};

/** on_conflict condition type for table "lineReportingBotConfigShop" */
export type LineReportingBotConfigShopOnConflict = {
  constraint: LineReportingBotConfigShopConstraint;
  update_columns?: Array<LineReportingBotConfigShopUpdateColumn>;
  where?: InputMaybe<LineReportingBotConfigShopBoolExp>;
};

/** Ordering options when selecting data from "lineReportingBotConfigShop". */
export type LineReportingBotConfigShopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lineReportingBotConfig?: InputMaybe<LineReportingBotConfigOrderBy>;
  lineReportingBotConfigId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "lineReportingBotConfigShop" */
export enum LineReportingBotConfigShopSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  LineReportingBotConfigId = 'lineReportingBotConfigId',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "lineReportingBotConfigShop" */
export type LineReportingBotConfigShopStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LineReportingBotConfigShopStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type LineReportingBotConfigShopStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  lineReportingBotConfigId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "lineReportingBotConfigShop" (current role has no relevant permissions) */
export enum LineReportingBotConfigShopUpdateColumn {
  /** placeholder (do not use) */
  _Placeholder = '_PLACEHOLDER'
}

/** Boolean expression to filter rows from the table "lineReportingBotConfig". All fields are combined with a logical 'AND'. */
export type LineReportingBotConfigBoolExp = {
  _and?: InputMaybe<Array<LineReportingBotConfigBoolExp>>;
  _not?: InputMaybe<LineReportingBotConfigBoolExp>;
  _or?: InputMaybe<Array<LineReportingBotConfigBoolExp>>;
  corporation?: InputMaybe<CorporationBoolExp>;
  corporationId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isSuspended?: InputMaybe<BooleanComparisonExp>;
  lineGroupId?: InputMaybe<StringComparisonExp>;
  lineReportingBotConfigShops?: InputMaybe<LineReportingBotConfigShopBoolExp>;
  lineReportingBotVerificationId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** response of any mutation on the table "lineReportingBotConfig" */
export type LineReportingBotConfigMutationResponse = {
  __typename?: 'lineReportingBotConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LineReportingBotConfig>;
};

/** Ordering options when selecting data from "lineReportingBotConfig". */
export type LineReportingBotConfigOrderBy = {
  corporation?: InputMaybe<CorporationOrderBy>;
  corporationId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isSuspended?: InputMaybe<OrderBy>;
  lineGroupId?: InputMaybe<OrderBy>;
  lineReportingBotConfigShops_aggregate?: InputMaybe<LineReportingBotConfigShopAggregateOrderBy>;
  lineReportingBotVerificationId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: lineReportingBotConfig */
export type LineReportingBotConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "lineReportingBotConfig" */
export enum LineReportingBotConfigSelectColumn {
  /** column name */
  CorporationId = 'corporationId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsSuspended = 'isSuspended',
  /** column name */
  LineGroupId = 'lineGroupId',
  /** column name */
  LineReportingBotVerificationId = 'lineReportingBotVerificationId',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "lineReportingBotConfig" */
export type LineReportingBotConfigSetInput = {
  isSuspended?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lineReportingBotConfig" */
export type LineReportingBotConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LineReportingBotConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type LineReportingBotConfigStreamCursorValueInput = {
  corporationId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isSuspended?: InputMaybe<Scalars['Boolean']>;
  lineGroupId?: InputMaybe<Scalars['String']>;
  lineReportingBotVerificationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LineReportingBotConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LineReportingBotConfigSetInput>;
  /** filter the rows which have to be updated */
  where: LineReportingBotConfigBoolExp;
};

/** columns and relationships of "lineReportingBotVerification" */
export type LineReportingBotVerification = {
  __typename?: 'lineReportingBotVerification';
  code: Scalars['String'];
  /** An object relationship */
  corporation: Corporation;
  corporationId: Scalars['uuid'];
  expiredAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  lineReportingBotConfig?: Maybe<LineReportingBotConfig>;
};

/**
 * Boolean expression to filter rows from the table "lineReportingBotVerification".
 * All fields are combined with a logical 'AND'.
 */
export type LineReportingBotVerificationBoolExp = {
  _and?: InputMaybe<Array<LineReportingBotVerificationBoolExp>>;
  _not?: InputMaybe<LineReportingBotVerificationBoolExp>;
  _or?: InputMaybe<Array<LineReportingBotVerificationBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  corporation?: InputMaybe<CorporationBoolExp>;
  corporationId?: InputMaybe<UuidComparisonExp>;
  expiredAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lineReportingBotConfig?: InputMaybe<LineReportingBotConfigBoolExp>;
};

/** Ordering options when selecting data from "lineReportingBotVerification". */
export type LineReportingBotVerificationOrderBy = {
  code?: InputMaybe<OrderBy>;
  corporation?: InputMaybe<CorporationOrderBy>;
  corporationId?: InputMaybe<OrderBy>;
  expiredAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lineReportingBotConfig?: InputMaybe<LineReportingBotConfigOrderBy>;
};

/** select columns of table "lineReportingBotVerification" */
export enum LineReportingBotVerificationSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  CorporationId = 'corporationId',
  /** column name */
  ExpiredAt = 'expiredAt',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "lineReportingBotVerification" */
export type LineReportingBotVerificationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LineReportingBotVerificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type LineReportingBotVerificationStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']>;
  corporationId?: InputMaybe<Scalars['uuid']>;
  expiredAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "membershipCard" */
export type MembershipCard = {
  __typename?: 'membershipCard';
  appearanceType: MembershipCardAppearanceTypeEnum;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  membershipCardAppearanceType: MembershipCardAppearanceType;
  /** An array relationship */
  membershipCardTitles: Array<MembershipCardTitle>;
};


/** columns and relationships of "membershipCard" */
export type MembershipCardMembershipCardTitlesArgs = {
  distinct_on?: InputMaybe<Array<MembershipCardTitleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCardTitleOrderBy>>;
  where?: InputMaybe<MembershipCardTitleBoolExp>;
};

/** columns and relationships of "membershipCardAppearanceType" */
export type MembershipCardAppearanceType = {
  __typename?: 'membershipCardAppearanceType';
  /** An array relationship */
  membershipCards: Array<MembershipCard>;
  value: Scalars['String'];
};


/** columns and relationships of "membershipCardAppearanceType" */
export type MembershipCardAppearanceTypeMembershipCardsArgs = {
  distinct_on?: InputMaybe<Array<MembershipCardSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCardOrderBy>>;
  where?: InputMaybe<MembershipCardBoolExp>;
};

/**
 * Boolean expression to filter rows from the table "membershipCardAppearanceType".
 * All fields are combined with a logical 'AND'.
 */
export type MembershipCardAppearanceTypeBoolExp = {
  _and?: InputMaybe<Array<MembershipCardAppearanceTypeBoolExp>>;
  _not?: InputMaybe<MembershipCardAppearanceTypeBoolExp>;
  _or?: InputMaybe<Array<MembershipCardAppearanceTypeBoolExp>>;
  membershipCards?: InputMaybe<MembershipCardBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum MembershipCardAppearanceTypeEnum {
  Japanese = 'japanese',
  Standard = 'standard'
}

/**
 * Boolean expression to compare columns of type
 * "membershipCardAppearanceType_enum". All fields are combined with logical 'AND'.
 */
export type MembershipCardAppearanceTypeEnumComparisonExp = {
  _eq?: InputMaybe<MembershipCardAppearanceTypeEnum>;
  _in?: InputMaybe<Array<MembershipCardAppearanceTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MembershipCardAppearanceTypeEnum>;
  _nin?: InputMaybe<Array<MembershipCardAppearanceTypeEnum>>;
};

/** Ordering options when selecting data from "membershipCardAppearanceType". */
export type MembershipCardAppearanceTypeOrderBy = {
  membershipCards_aggregate?: InputMaybe<MembershipCardAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "membershipCardAppearanceType" */
export enum MembershipCardAppearanceTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "membershipCardAppearanceType" */
export type MembershipCardAppearanceTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MembershipCardAppearanceTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipCardAppearanceTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "membershipCardTitle" */
export type MembershipCardTitle = {
  __typename?: 'membershipCardTitle';
  id: Scalars['uuid'];
  /** An object relationship */
  membershipCard: MembershipCard;
  membershipCardId: Scalars['uuid'];
  position: Scalars['Int'];
  title: Scalars['String'];
};

/** order by aggregate values of table "membershipCardTitle" */
export type MembershipCardTitleAggregateOrderBy = {
  avg?: InputMaybe<MembershipCardTitleAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MembershipCardTitleMaxOrderBy>;
  min?: InputMaybe<MembershipCardTitleMinOrderBy>;
  stddev?: InputMaybe<MembershipCardTitleStddevOrderBy>;
  stddev_pop?: InputMaybe<MembershipCardTitleStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MembershipCardTitleStddevSampOrderBy>;
  sum?: InputMaybe<MembershipCardTitleSumOrderBy>;
  var_pop?: InputMaybe<MembershipCardTitleVarPopOrderBy>;
  var_samp?: InputMaybe<MembershipCardTitleVarSampOrderBy>;
  variance?: InputMaybe<MembershipCardTitleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "membershipCardTitle" */
export type MembershipCardTitleArrRelInsertInput = {
  data: Array<MembershipCardTitleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MembershipCardTitleOnConflict>;
};

/** order by avg() on columns of table "membershipCardTitle" */
export type MembershipCardTitleAvgOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "membershipCardTitle". All fields are combined with a logical 'AND'. */
export type MembershipCardTitleBoolExp = {
  _and?: InputMaybe<Array<MembershipCardTitleBoolExp>>;
  _not?: InputMaybe<MembershipCardTitleBoolExp>;
  _or?: InputMaybe<Array<MembershipCardTitleBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  membershipCard?: InputMaybe<MembershipCardBoolExp>;
  membershipCardId?: InputMaybe<UuidComparisonExp>;
  position?: InputMaybe<IntComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "membershipCardTitle" */
export enum MembershipCardTitleConstraint {
  /** unique or primary key constraint on columns "membershipCardId", "position" */
  MembershipCardTitleMembershipCardIdPositionKey = 'membershipCardTitle_membershipCardId_position_key',
  /** unique or primary key constraint on columns "id" */
  MembershipCardTitlePkey = 'membershipCardTitle_pkey'
}

/** input type for incrementing numeric columns in table "membershipCardTitle" */
export type MembershipCardTitleIncInput = {
  position?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "membershipCardTitle" */
export type MembershipCardTitleInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  membershipCard?: InputMaybe<MembershipCardObjRelInsertInput>;
  membershipCardId: Scalars['uuid'];
  position: Scalars['Int'];
  title: Scalars['String'];
};

/** order by max() on columns of table "membershipCardTitle" */
export type MembershipCardTitleMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  membershipCardId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "membershipCardTitle" */
export type MembershipCardTitleMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  membershipCardId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "membershipCardTitle" */
export type MembershipCardTitleMutationResponse = {
  __typename?: 'membershipCardTitle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MembershipCardTitle>;
};

/** on_conflict condition type for table "membershipCardTitle" */
export type MembershipCardTitleOnConflict = {
  constraint: MembershipCardTitleConstraint;
  update_columns?: Array<MembershipCardTitleUpdateColumn>;
  where?: InputMaybe<MembershipCardTitleBoolExp>;
};

/** Ordering options when selecting data from "membershipCardTitle". */
export type MembershipCardTitleOrderBy = {
  id?: InputMaybe<OrderBy>;
  membershipCard?: InputMaybe<MembershipCardOrderBy>;
  membershipCardId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: membershipCardTitle */
export type MembershipCardTitlePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "membershipCardTitle" */
export enum MembershipCardTitleSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MembershipCardId = 'membershipCardId',
  /** column name */
  Position = 'position',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "membershipCardTitle" */
export type MembershipCardTitleSetInput = {
  position?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "membershipCardTitle" */
export type MembershipCardTitleStddevOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "membershipCardTitle" */
export type MembershipCardTitleStddevPopOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "membershipCardTitle" */
export type MembershipCardTitleStddevSampOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "membershipCardTitle" */
export type MembershipCardTitleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MembershipCardTitleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipCardTitleStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  membershipCardId?: InputMaybe<Scalars['uuid']>;
  position?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "membershipCardTitle" */
export type MembershipCardTitleSumOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** update columns of table "membershipCardTitle" */
export enum MembershipCardTitleUpdateColumn {
  /** column name */
  Position = 'position',
  /** column name */
  Title = 'title'
}

export type MembershipCardTitleUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MembershipCardTitleIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MembershipCardTitleSetInput>;
  /** filter the rows which have to be updated */
  where: MembershipCardTitleBoolExp;
};

/** order by var_pop() on columns of table "membershipCardTitle" */
export type MembershipCardTitleVarPopOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "membershipCardTitle" */
export type MembershipCardTitleVarSampOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "membershipCardTitle" */
export type MembershipCardTitleVarianceOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "membershipCard" */
export type MembershipCardAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MembershipCardMaxOrderBy>;
  min?: InputMaybe<MembershipCardMinOrderBy>;
};

/** Boolean expression to filter rows from the table "membershipCard". All fields are combined with a logical 'AND'. */
export type MembershipCardBoolExp = {
  _and?: InputMaybe<Array<MembershipCardBoolExp>>;
  _not?: InputMaybe<MembershipCardBoolExp>;
  _or?: InputMaybe<Array<MembershipCardBoolExp>>;
  appearanceType?: InputMaybe<MembershipCardAppearanceTypeEnumComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  membershipCardAppearanceType?: InputMaybe<MembershipCardAppearanceTypeBoolExp>;
  membershipCardTitles?: InputMaybe<MembershipCardTitleBoolExp>;
};

/** unique or primary key constraints on table "membershipCard" */
export enum MembershipCardConstraint {
  /** unique or primary key constraint on columns "_companyId" */
  MembershipCardCompanyIdKey = 'membershipCard_companyId_key',
  /** unique or primary key constraint on columns "companyId" */
  MembershipCardCompanyUuidKey = 'membershipCard_companyUuid_key',
  /** unique or primary key constraint on columns "id" */
  MembershipCardPkey = 'membershipCard_pkey'
}

/** input type for inserting data into table "membershipCard" */
export type MembershipCardInsertInput = {
  appearanceType: MembershipCardAppearanceTypeEnum;
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  membershipCardTitles?: InputMaybe<MembershipCardTitleArrRelInsertInput>;
};

/** order by max() on columns of table "membershipCard" */
export type MembershipCardMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "membershipCard" */
export type MembershipCardMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "membershipCard" */
export type MembershipCardMutationResponse = {
  __typename?: 'membershipCard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MembershipCard>;
};

/** input type for inserting object relation for remote table "membershipCard" */
export type MembershipCardObjRelInsertInput = {
  data: MembershipCardInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MembershipCardOnConflict>;
};

/** on_conflict condition type for table "membershipCard" */
export type MembershipCardOnConflict = {
  constraint: MembershipCardConstraint;
  update_columns?: Array<MembershipCardUpdateColumn>;
  where?: InputMaybe<MembershipCardBoolExp>;
};

/** Ordering options when selecting data from "membershipCard". */
export type MembershipCardOrderBy = {
  appearanceType?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipCardAppearanceType?: InputMaybe<MembershipCardAppearanceTypeOrderBy>;
  membershipCardTitles_aggregate?: InputMaybe<MembershipCardTitleAggregateOrderBy>;
};

/** primary key columns input for table: membershipCard */
export type MembershipCardPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "membershipCard" */
export enum MembershipCardSelectColumn {
  /** column name */
  AppearanceType = 'appearanceType',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "membershipCard" */
export type MembershipCardSetInput = {
  appearanceType?: InputMaybe<MembershipCardAppearanceTypeEnum>;
};

/** Streaming cursor of the table "membershipCard" */
export type MembershipCardStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MembershipCardStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipCardStreamCursorValueInput = {
  appearanceType?: InputMaybe<MembershipCardAppearanceTypeEnum>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "membershipCard" */
export enum MembershipCardUpdateColumn {
  /** column name */
  AppearanceType = 'appearanceType'
}

export type MembershipCardUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MembershipCardSetInput>;
  /** filter the rows which have to be updated */
  where: MembershipCardBoolExp;
};

/** columns and relationships of "membershipRank" */
export type MembershipRank = {
  __typename?: 'membershipRank';
  /** An array relationship */
  companyMembers: Array<CompanyMember>;
  /** An aggregate relationship */
  companyMembers_aggregate: CompanyMemberAggregate;
  value: Scalars['String'];
};


/** columns and relationships of "membershipRank" */
export type MembershipRankCompanyMembersArgs = {
  distinct_on?: InputMaybe<Array<CompanyMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyMemberOrderBy>>;
  where?: InputMaybe<CompanyMemberBoolExp>;
};


/** columns and relationships of "membershipRank" */
export type MembershipRankCompanyMembersAggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyMemberOrderBy>>;
  where?: InputMaybe<CompanyMemberBoolExp>;
};

/** columns and relationships of "membershipRankConfig" */
export type MembershipRankConfig = {
  __typename?: 'membershipRankConfig';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  membershipRank: MembershipRankEnum;
  /** An object relationship */
  membershipRankByMembershipRank: MembershipRank;
  requiredVisitCount: Scalars['Int'];
  /** An object relationship */
  rewardCoupon?: Maybe<Coupon>;
  rewardCouponId?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "membershipRankConfig" */
export type MembershipRankConfigAggregateOrderBy = {
  avg?: InputMaybe<MembershipRankConfigAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MembershipRankConfigMaxOrderBy>;
  min?: InputMaybe<MembershipRankConfigMinOrderBy>;
  stddev?: InputMaybe<MembershipRankConfigStddevOrderBy>;
  stddev_pop?: InputMaybe<MembershipRankConfigStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MembershipRankConfigStddevSampOrderBy>;
  sum?: InputMaybe<MembershipRankConfigSumOrderBy>;
  var_pop?: InputMaybe<MembershipRankConfigVarPopOrderBy>;
  var_samp?: InputMaybe<MembershipRankConfigVarSampOrderBy>;
  variance?: InputMaybe<MembershipRankConfigVarianceOrderBy>;
};

/** order by avg() on columns of table "membershipRankConfig" */
export type MembershipRankConfigAvgOrderBy = {
  requiredVisitCount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "membershipRankConfig". All fields are combined with a logical 'AND'. */
export type MembershipRankConfigBoolExp = {
  _and?: InputMaybe<Array<MembershipRankConfigBoolExp>>;
  _not?: InputMaybe<MembershipRankConfigBoolExp>;
  _or?: InputMaybe<Array<MembershipRankConfigBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  membershipRank?: InputMaybe<MembershipRankEnumComparisonExp>;
  membershipRankByMembershipRank?: InputMaybe<MembershipRankBoolExp>;
  requiredVisitCount?: InputMaybe<IntComparisonExp>;
  rewardCoupon?: InputMaybe<CouponBoolExp>;
  rewardCouponId?: InputMaybe<UuidComparisonExp>;
};

/** input type for incrementing numeric columns in table "membershipRankConfig" */
export type MembershipRankConfigIncInput = {
  requiredVisitCount?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "membershipRankConfig" */
export type MembershipRankConfigMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  requiredVisitCount?: InputMaybe<OrderBy>;
  rewardCouponId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "membershipRankConfig" */
export type MembershipRankConfigMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  requiredVisitCount?: InputMaybe<OrderBy>;
  rewardCouponId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "membershipRankConfig" */
export type MembershipRankConfigMutationResponse = {
  __typename?: 'membershipRankConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MembershipRankConfig>;
};

/** Ordering options when selecting data from "membershipRankConfig". */
export type MembershipRankConfigOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  membershipRank?: InputMaybe<OrderBy>;
  membershipRankByMembershipRank?: InputMaybe<MembershipRankOrderBy>;
  requiredVisitCount?: InputMaybe<OrderBy>;
  rewardCoupon?: InputMaybe<CouponOrderBy>;
  rewardCouponId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: membershipRankConfig */
export type MembershipRankConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "membershipRankConfig" */
export enum MembershipRankConfigSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipRank = 'membershipRank',
  /** column name */
  RequiredVisitCount = 'requiredVisitCount',
  /** column name */
  RewardCouponId = 'rewardCouponId'
}

/** input type for updating data in table "membershipRankConfig" */
export type MembershipRankConfigSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipRank?: InputMaybe<MembershipRankEnum>;
  requiredVisitCount?: InputMaybe<Scalars['Int']>;
  rewardCouponId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "membershipRankConfig" */
export type MembershipRankConfigStddevOrderBy = {
  requiredVisitCount?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "membershipRankConfig" */
export type MembershipRankConfigStddevPopOrderBy = {
  requiredVisitCount?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "membershipRankConfig" */
export type MembershipRankConfigStddevSampOrderBy = {
  requiredVisitCount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "membershipRankConfig" */
export type MembershipRankConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MembershipRankConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipRankConfigStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  membershipRank?: InputMaybe<MembershipRankEnum>;
  requiredVisitCount?: InputMaybe<Scalars['Int']>;
  rewardCouponId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "membershipRankConfig" */
export type MembershipRankConfigSumOrderBy = {
  requiredVisitCount?: InputMaybe<OrderBy>;
};

export type MembershipRankConfigUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MembershipRankConfigIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MembershipRankConfigSetInput>;
  /** filter the rows which have to be updated */
  where: MembershipRankConfigBoolExp;
};

/** order by var_pop() on columns of table "membershipRankConfig" */
export type MembershipRankConfigVarPopOrderBy = {
  requiredVisitCount?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "membershipRankConfig" */
export type MembershipRankConfigVarSampOrderBy = {
  requiredVisitCount?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "membershipRankConfig" */
export type MembershipRankConfigVarianceOrderBy = {
  requiredVisitCount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "membershipRank". All fields are combined with a logical 'AND'. */
export type MembershipRankBoolExp = {
  _and?: InputMaybe<Array<MembershipRankBoolExp>>;
  _not?: InputMaybe<MembershipRankBoolExp>;
  _or?: InputMaybe<Array<MembershipRankBoolExp>>;
  companyMembers?: InputMaybe<CompanyMemberBoolExp>;
  companyMembers_aggregate?: InputMaybe<CompanyMemberAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum MembershipRankEnum {
  Gold = 'gold',
  Platinum = 'platinum',
  Regular = 'regular',
  Silver = 'silver'
}

/** Boolean expression to compare columns of type "membershipRank_enum". All fields are combined with logical 'AND'. */
export type MembershipRankEnumComparisonExp = {
  _eq?: InputMaybe<MembershipRankEnum>;
  _in?: InputMaybe<Array<MembershipRankEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MembershipRankEnum>;
  _nin?: InputMaybe<Array<MembershipRankEnum>>;
};

/** Ordering options when selecting data from "membershipRank". */
export type MembershipRankOrderBy = {
  companyMembers_aggregate?: InputMaybe<CompanyMemberAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "membershipRank" */
export enum MembershipRankSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "membershipRank" */
export type MembershipRankStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MembershipRankStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MembershipRankStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "menu" */
export type Menu = {
  __typename?: 'menu';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  categoryMenus: Array<CategoryMenu>;
  /** An aggregate relationship */
  categoryMenus_aggregate: CategoryMenuAggregate;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  /** An array relationship */
  compulsoryAppetizerConfigs: Array<CompulsoryAppetizerConfig>;
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  dishUpSlipGroupShopMenus: Array<DishUpSlipGroupShopMenus>;
  featuredLabelText?: Maybe<Scalars['String']>;
  /** An object relationship */
  foodingJournalMenu?: Maybe<FoodingJournalMenu>;
  /** An object relationship */
  giftMeta?: Maybe<GiftMeta>;
  id: Scalars['uuid'];
  imageUrl?: Maybe<Scalars['String']>;
  isTakeOut?: Maybe<Scalars['Boolean']>;
  menuId: Scalars['Int'];
  /** An array relationship */
  menuOptions: Array<MenuOption>;
  menuType?: Maybe<MenuTypeEnum>;
  name: Scalars['String'];
  openPrice?: Maybe<Scalars['Boolean']>;
  orderMaxNum?: Maybe<Scalars['Int']>;
  orderMaxNumPerTableUser?: Maybe<Scalars['Int']>;
  /** An object relationship */
  orderableTime?: Maybe<OrderableTime>;
  orderableTimeId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  pikaichiMenuMenus: Array<PikaichiMenuMenu>;
  price: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
  serial: Scalars['Int'];
  /** An object relationship */
  serviceChargeMeta?: Maybe<ServiceChargeMeta>;
  /** An array relationship */
  shopMenuCookingItems: Array<ShopMenuCookingItem>;
  /** An array relationship */
  shopMenuKitchenRoles: Array<ShopMenuKitchenRole>;
  /** An array relationship */
  shopMenus: Array<ShopMenu>;
  shopSideName?: Maybe<Scalars['String']>;
  shouldMergeSlipOptions: Scalars['Boolean'];
  /** An object relationship */
  specificTimeSurchargeMeta?: Maybe<SpecificTimeSurchargeMeta>;
  taxMethod: TaxMethodEnum;
  taxRate: Scalars['numeric'];
  /** An object relationship */
  tecAggregationMenu?: Maybe<TecAggregationMenu>;
  /** A computed field, executes function "menu_totalDealingShopCount" */
  totalDealingShopCount?: Maybe<Scalars['Int']>;
  /** An array relationship */
  translations: Array<Translation>;
  unitMaxOrderNumForNumPeople?: Maybe<Scalars['Int']>;
  unitMaxOrderNumPerCustomer?: Maybe<Scalars['Int']>;
  /** An array relationship */
  winboardMenus: Array<WinboardMenu>;
};


/** columns and relationships of "menu" */
export type MenuCategoryMenusArgs = {
  distinct_on?: InputMaybe<Array<CategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryMenuOrderBy>>;
  where?: InputMaybe<CategoryMenuBoolExp>;
};


/** columns and relationships of "menu" */
export type MenuCategoryMenusAggregateArgs = {
  distinct_on?: InputMaybe<Array<CategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryMenuOrderBy>>;
  where?: InputMaybe<CategoryMenuBoolExp>;
};


/** columns and relationships of "menu" */
export type MenuCompulsoryAppetizerConfigsArgs = {
  distinct_on?: InputMaybe<Array<CompulsoryAppetizerConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompulsoryAppetizerConfigOrderBy>>;
  where?: InputMaybe<CompulsoryAppetizerConfigBoolExp>;
};


/** columns and relationships of "menu" */
export type MenuDishUpSlipGroupShopMenusArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopMenusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopMenusOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
};


/** columns and relationships of "menu" */
export type MenuMenuOptionsArgs = {
  distinct_on?: InputMaybe<Array<MenuOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOptionOrderBy>>;
  where?: InputMaybe<MenuOptionBoolExp>;
};


/** columns and relationships of "menu" */
export type MenuPikaichiMenuMenusArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuMenuOrderBy>>;
  where?: InputMaybe<PikaichiMenuMenuBoolExp>;
};


/** columns and relationships of "menu" */
export type MenuShopMenuCookingItemsArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuCookingItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuCookingItemOrderBy>>;
  where?: InputMaybe<ShopMenuCookingItemBoolExp>;
};


/** columns and relationships of "menu" */
export type MenuShopMenuKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
};


/** columns and relationships of "menu" */
export type MenuShopMenusArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuOrderBy>>;
  where?: InputMaybe<ShopMenuBoolExp>;
};


/** columns and relationships of "menu" */
export type MenuTranslationsArgs = {
  distinct_on?: InputMaybe<Array<TranslationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TranslationOrderBy>>;
  where?: InputMaybe<TranslationBoolExp>;
};


/** columns and relationships of "menu" */
export type MenuWinboardMenusArgs = {
  distinct_on?: InputMaybe<Array<WinboardMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WinboardMenuOrderBy>>;
  where?: InputMaybe<WinboardMenuBoolExp>;
};

/** columns and relationships of "menuOption" */
export type MenuOption = {
  __typename?: 'menuOption';
  _menuId: Scalars['Int'];
  _optionId: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  menu: Menu;
  menuId: Scalars['uuid'];
  /** An object relationship */
  option: Option;
  optionId?: Maybe<Scalars['uuid']>;
  priority: Scalars['Int'];
};

/** order by aggregate values of table "menuOption" */
export type MenuOptionAggregateOrderBy = {
  avg?: InputMaybe<MenuOptionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MenuOptionMaxOrderBy>;
  min?: InputMaybe<MenuOptionMinOrderBy>;
  stddev?: InputMaybe<MenuOptionStddevOrderBy>;
  stddev_pop?: InputMaybe<MenuOptionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MenuOptionStddevSampOrderBy>;
  sum?: InputMaybe<MenuOptionSumOrderBy>;
  var_pop?: InputMaybe<MenuOptionVarPopOrderBy>;
  var_samp?: InputMaybe<MenuOptionVarSampOrderBy>;
  variance?: InputMaybe<MenuOptionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "menuOption" */
export type MenuOptionArrRelInsertInput = {
  data: Array<MenuOptionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MenuOptionOnConflict>;
};

/** order by avg() on columns of table "menuOption" */
export type MenuOptionAvgOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _optionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "menuOption". All fields are combined with a logical 'AND'. */
export type MenuOptionBoolExp = {
  _and?: InputMaybe<Array<MenuOptionBoolExp>>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<MenuOptionBoolExp>;
  _optionId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<MenuOptionBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  option?: InputMaybe<OptionBoolExp>;
  optionId?: InputMaybe<UuidComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "menuOption" */
export enum MenuOptionConstraint {
  /** unique or primary key constraint on columns "_optionId", "_menuId" */
  MenuOptionMenuIdOptionIdKey = 'menuOption_menuId_optionId_key',
  /** unique or primary key constraint on columns "id" */
  MenuOptionPkey = 'menuOption_pkey'
}

/** input type for incrementing numeric columns in table "menuOption" */
export type MenuOptionIncInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  _optionId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "menuOption" */
export type MenuOptionInsertInput = {
  _menuId: Scalars['Int'];
  _optionId: Scalars['Int'];
  id?: InputMaybe<Scalars['uuid']>;
  menu?: InputMaybe<MenuObjRelInsertInput>;
  menuId: Scalars['uuid'];
  option?: InputMaybe<OptionObjRelInsertInput>;
  optionId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "menuOption" */
export type MenuOptionMaxOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _optionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "menuOption" */
export type MenuOptionMinOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _optionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "menuOption" */
export type MenuOptionMutationResponse = {
  __typename?: 'menuOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MenuOption>;
};

/** on_conflict condition type for table "menuOption" */
export type MenuOptionOnConflict = {
  constraint: MenuOptionConstraint;
  update_columns?: Array<MenuOptionUpdateColumn>;
  where?: InputMaybe<MenuOptionBoolExp>;
};

/** Ordering options when selecting data from "menuOption". */
export type MenuOptionOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _optionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
  option?: InputMaybe<OptionOrderBy>;
  optionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: menuOption */
export type MenuOptionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "menuOption" */
export enum MenuOptionSelectColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  _OptionId = '_optionId',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  OptionId = 'optionId',
  /** column name */
  Priority = 'priority'
}

/** input type for updating data in table "menuOption" */
export type MenuOptionSetInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  _optionId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  optionId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "menuOption" */
export type MenuOptionStddevOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _optionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "menuOption" */
export type MenuOptionStddevPopOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _optionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "menuOption" */
export type MenuOptionStddevSampOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _optionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "menuOption" */
export type MenuOptionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MenuOptionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MenuOptionStreamCursorValueInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  _optionId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  optionId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "menuOption" */
export type MenuOptionSumOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _optionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** update columns of table "menuOption" */
export enum MenuOptionUpdateColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  _OptionId = '_optionId',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  OptionId = 'optionId',
  /** column name */
  Priority = 'priority'
}

export type MenuOptionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MenuOptionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MenuOptionSetInput>;
  /** filter the rows which have to be updated */
  where: MenuOptionBoolExp;
};

/** order by var_pop() on columns of table "menuOption" */
export type MenuOptionVarPopOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _optionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "menuOption" */
export type MenuOptionVarSampOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _optionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "menuOption" */
export type MenuOptionVarianceOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _optionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** columns and relationships of "menuOrderItem" */
export type MenuOrderItem = {
  __typename?: 'menuOrderItem';
  _categoryId?: Maybe<Scalars['Int']>;
  _menuId: Scalars['Int'];
  /** An object relationship */
  addedCustomer?: Maybe<Customer>;
  addedUserId?: Maybe<Scalars['String']>;
  addedUserName?: Maybe<Scalars['String']>;
  addedUserProfileImage?: Maybe<Scalars['String']>;
  /** A computed field, executes function "menuOrderItem_categoryId" */
  categoryId?: Maybe<Scalars['Int']>;
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  giftMeta?: Maybe<GiftMeta>;
  id: Scalars['uuid'];
  isOpenPrice: Scalars['Boolean'];
  /** An object relationship */
  menu?: Maybe<Menu>;
  menuId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  menuOrderItemOptions: Array<MenuOrderItemOption>;
  name: Scalars['String'];
  /** An object relationship */
  order: Order;
  orderId: Scalars['uuid'];
  /** An array relationship */
  payingMenuOrderItems: Array<PayingMenuOrderItem>;
  price: Scalars['Int'];
  priority: Scalars['Int'];
  quantity: Scalars['Int'];
  remark: Scalars['String'];
  taxMethod: TaxMethodEnum;
  taxRate: Scalars['numeric'];
};


/** columns and relationships of "menuOrderItem" */
export type MenuOrderItemMenuOrderItemOptionsArgs = {
  distinct_on?: InputMaybe<Array<MenuOrderItemOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderItemOptionOrderBy>>;
  where?: InputMaybe<MenuOrderItemOptionBoolExp>;
};


/** columns and relationships of "menuOrderItem" */
export type MenuOrderItemPayingMenuOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<PayingMenuOrderItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingMenuOrderItemOrderBy>>;
  where?: InputMaybe<PayingMenuOrderItemBoolExp>;
};

/** columns and relationships of "menuOrderItemChoice" */
export type MenuOrderItemChoice = {
  __typename?: 'menuOrderItemChoice';
  _choiceId: Scalars['Int'];
  /** An object relationship */
  choice?: Maybe<Choice>;
  choiceId?: Maybe<Scalars['uuid']>;
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  /** An object relationship */
  menuOrderItemOption: MenuOrderItemOption;
  menuOrderItemOptionId: Scalars['uuid'];
  name: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

/** order by aggregate values of table "menuOrderItemChoice" */
export type MenuOrderItemChoiceAggregateOrderBy = {
  avg?: InputMaybe<MenuOrderItemChoiceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MenuOrderItemChoiceMaxOrderBy>;
  min?: InputMaybe<MenuOrderItemChoiceMinOrderBy>;
  stddev?: InputMaybe<MenuOrderItemChoiceStddevOrderBy>;
  stddev_pop?: InputMaybe<MenuOrderItemChoiceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MenuOrderItemChoiceStddevSampOrderBy>;
  sum?: InputMaybe<MenuOrderItemChoiceSumOrderBy>;
  var_pop?: InputMaybe<MenuOrderItemChoiceVarPopOrderBy>;
  var_samp?: InputMaybe<MenuOrderItemChoiceVarSampOrderBy>;
  variance?: InputMaybe<MenuOrderItemChoiceVarianceOrderBy>;
};

/** order by avg() on columns of table "menuOrderItemChoice" */
export type MenuOrderItemChoiceAvgOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "menuOrderItemChoice". All fields are combined with a logical 'AND'. */
export type MenuOrderItemChoiceBoolExp = {
  _and?: InputMaybe<Array<MenuOrderItemChoiceBoolExp>>;
  _choiceId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<MenuOrderItemChoiceBoolExp>;
  _or?: InputMaybe<Array<MenuOrderItemChoiceBoolExp>>;
  choice?: InputMaybe<ChoiceBoolExp>;
  choiceId?: InputMaybe<UuidComparisonExp>;
  costPrice?: InputMaybe<NumericComparisonExp>;
  costTaxRate?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  menuOrderItemOption?: InputMaybe<MenuOrderItemOptionBoolExp>;
  menuOrderItemOptionId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  quantity?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "menuOrderItemChoice" */
export type MenuOrderItemChoiceMaxOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuOrderItemOptionId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "menuOrderItemChoice" */
export type MenuOrderItemChoiceMinOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuOrderItemOptionId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "menuOrderItemChoice". */
export type MenuOrderItemChoiceOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  choice?: InputMaybe<ChoiceOrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuOrderItemOption?: InputMaybe<MenuOrderItemOptionOrderBy>;
  menuOrderItemOptionId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** select columns of table "menuOrderItemChoice" */
export enum MenuOrderItemChoiceSelectColumn {
  /** column name */
  _ChoiceId = '_choiceId',
  /** column name */
  ChoiceId = 'choiceId',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CostTaxRate = 'costTaxRate',
  /** column name */
  Id = 'id',
  /** column name */
  MenuOrderItemOptionId = 'menuOrderItemOptionId',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity'
}

/** order by stddev() on columns of table "menuOrderItemChoice" */
export type MenuOrderItemChoiceStddevOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "menuOrderItemChoice" */
export type MenuOrderItemChoiceStddevPopOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "menuOrderItemChoice" */
export type MenuOrderItemChoiceStddevSampOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "menuOrderItemChoice" */
export type MenuOrderItemChoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MenuOrderItemChoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MenuOrderItemChoiceStreamCursorValueInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  choiceId?: InputMaybe<Scalars['uuid']>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuOrderItemOptionId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "menuOrderItemChoice" */
export type MenuOrderItemChoiceSumOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "menuOrderItemChoice" */
export type MenuOrderItemChoiceVarPopOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "menuOrderItemChoice" */
export type MenuOrderItemChoiceVarSampOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "menuOrderItemChoice" */
export type MenuOrderItemChoiceVarianceOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** columns and relationships of "menuOrderItemOption" */
export type MenuOrderItemOption = {
  __typename?: 'menuOrderItemOption';
  _optionId: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  menuOrderItem: MenuOrderItem;
  /** An array relationship */
  menuOrderItemChoices: Array<MenuOrderItemChoice>;
  menuOrderItemId: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  option?: Maybe<Option>;
  optionId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "menuOrderItemOption" */
export type MenuOrderItemOptionMenuOrderItemChoicesArgs = {
  distinct_on?: InputMaybe<Array<MenuOrderItemChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderItemChoiceOrderBy>>;
  where?: InputMaybe<MenuOrderItemChoiceBoolExp>;
};

/** order by aggregate values of table "menuOrderItemOption" */
export type MenuOrderItemOptionAggregateOrderBy = {
  avg?: InputMaybe<MenuOrderItemOptionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MenuOrderItemOptionMaxOrderBy>;
  min?: InputMaybe<MenuOrderItemOptionMinOrderBy>;
  stddev?: InputMaybe<MenuOrderItemOptionStddevOrderBy>;
  stddev_pop?: InputMaybe<MenuOrderItemOptionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MenuOrderItemOptionStddevSampOrderBy>;
  sum?: InputMaybe<MenuOrderItemOptionSumOrderBy>;
  var_pop?: InputMaybe<MenuOrderItemOptionVarPopOrderBy>;
  var_samp?: InputMaybe<MenuOrderItemOptionVarSampOrderBy>;
  variance?: InputMaybe<MenuOrderItemOptionVarianceOrderBy>;
};

/** order by avg() on columns of table "menuOrderItemOption" */
export type MenuOrderItemOptionAvgOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "menuOrderItemOption". All fields are combined with a logical 'AND'. */
export type MenuOrderItemOptionBoolExp = {
  _and?: InputMaybe<Array<MenuOrderItemOptionBoolExp>>;
  _not?: InputMaybe<MenuOrderItemOptionBoolExp>;
  _optionId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<MenuOrderItemOptionBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  menuOrderItem?: InputMaybe<MenuOrderItemBoolExp>;
  menuOrderItemChoices?: InputMaybe<MenuOrderItemChoiceBoolExp>;
  menuOrderItemId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  option?: InputMaybe<OptionBoolExp>;
  optionId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "menuOrderItemOption" */
export type MenuOrderItemOptionMaxOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuOrderItemId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "menuOrderItemOption" */
export type MenuOrderItemOptionMinOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuOrderItemId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "menuOrderItemOption". */
export type MenuOrderItemOptionOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuOrderItem?: InputMaybe<MenuOrderItemOrderBy>;
  menuOrderItemChoices_aggregate?: InputMaybe<MenuOrderItemChoiceAggregateOrderBy>;
  menuOrderItemId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  option?: InputMaybe<OptionOrderBy>;
  optionId?: InputMaybe<OrderBy>;
};

/** select columns of table "menuOrderItemOption" */
export enum MenuOrderItemOptionSelectColumn {
  /** column name */
  _OptionId = '_optionId',
  /** column name */
  Id = 'id',
  /** column name */
  MenuOrderItemId = 'menuOrderItemId',
  /** column name */
  Name = 'name',
  /** column name */
  OptionId = 'optionId'
}

/** order by stddev() on columns of table "menuOrderItemOption" */
export type MenuOrderItemOptionStddevOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "menuOrderItemOption" */
export type MenuOrderItemOptionStddevPopOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "menuOrderItemOption" */
export type MenuOrderItemOptionStddevSampOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "menuOrderItemOption" */
export type MenuOrderItemOptionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MenuOrderItemOptionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MenuOrderItemOptionStreamCursorValueInput = {
  _optionId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuOrderItemId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  optionId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "menuOrderItemOption" */
export type MenuOrderItemOptionSumOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "menuOrderItemOption" */
export type MenuOrderItemOptionVarPopOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "menuOrderItemOption" */
export type MenuOrderItemOptionVarSampOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "menuOrderItemOption" */
export type MenuOrderItemOptionVarianceOrderBy = {
  _optionId?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "menuOrderItem" */
export type MenuOrderItemAggregateOrderBy = {
  avg?: InputMaybe<MenuOrderItemAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MenuOrderItemMaxOrderBy>;
  min?: InputMaybe<MenuOrderItemMinOrderBy>;
  stddev?: InputMaybe<MenuOrderItemStddevOrderBy>;
  stddev_pop?: InputMaybe<MenuOrderItemStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MenuOrderItemStddevSampOrderBy>;
  sum?: InputMaybe<MenuOrderItemSumOrderBy>;
  var_pop?: InputMaybe<MenuOrderItemVarPopOrderBy>;
  var_samp?: InputMaybe<MenuOrderItemVarSampOrderBy>;
  variance?: InputMaybe<MenuOrderItemVarianceOrderBy>;
};

/** order by avg() on columns of table "menuOrderItem" */
export type MenuOrderItemAvgOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "menuOrderItem". All fields are combined with a logical 'AND'. */
export type MenuOrderItemBoolExp = {
  _and?: InputMaybe<Array<MenuOrderItemBoolExp>>;
  _categoryId?: InputMaybe<IntComparisonExp>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<MenuOrderItemBoolExp>;
  _or?: InputMaybe<Array<MenuOrderItemBoolExp>>;
  addedCustomer?: InputMaybe<CustomerBoolExp>;
  addedUserId?: InputMaybe<StringComparisonExp>;
  addedUserName?: InputMaybe<StringComparisonExp>;
  addedUserProfileImage?: InputMaybe<StringComparisonExp>;
  categoryId?: InputMaybe<IntComparisonExp>;
  costPrice?: InputMaybe<NumericComparisonExp>;
  costTaxRate?: InputMaybe<NumericComparisonExp>;
  giftMeta?: InputMaybe<GiftMetaBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isOpenPrice?: InputMaybe<BooleanComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  menuOrderItemOptions?: InputMaybe<MenuOrderItemOptionBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<OrderBoolExp>;
  orderId?: InputMaybe<UuidComparisonExp>;
  payingMenuOrderItems?: InputMaybe<PayingMenuOrderItemBoolExp>;
  price?: InputMaybe<IntComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  quantity?: InputMaybe<IntComparisonExp>;
  remark?: InputMaybe<StringComparisonExp>;
  taxMethod?: InputMaybe<TaxMethodEnumComparisonExp>;
  taxRate?: InputMaybe<NumericComparisonExp>;
};

/** order by max() on columns of table "menuOrderItem" */
export type MenuOrderItemMaxOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  addedUserId?: InputMaybe<OrderBy>;
  addedUserName?: InputMaybe<OrderBy>;
  addedUserProfileImage?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  remark?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "menuOrderItem" */
export type MenuOrderItemMinOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  addedUserId?: InputMaybe<OrderBy>;
  addedUserName?: InputMaybe<OrderBy>;
  addedUserProfileImage?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  remark?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "menuOrderItem". */
export type MenuOrderItemOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  addedCustomer?: InputMaybe<CustomerOrderBy>;
  addedUserId?: InputMaybe<OrderBy>;
  addedUserName?: InputMaybe<OrderBy>;
  addedUserProfileImage?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  giftMeta?: InputMaybe<GiftMetaOrderBy>;
  id?: InputMaybe<OrderBy>;
  isOpenPrice?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
  menuOrderItemOptions_aggregate?: InputMaybe<MenuOrderItemOptionAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  payingMenuOrderItems_aggregate?: InputMaybe<PayingMenuOrderItemAggregateOrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  remark?: InputMaybe<OrderBy>;
  taxMethod?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** select columns of table "menuOrderItem" */
export enum MenuOrderItemSelectColumn {
  /** column name */
  _CategoryId = '_categoryId',
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  AddedUserId = 'addedUserId',
  /** column name */
  AddedUserName = 'addedUserName',
  /** column name */
  AddedUserProfileImage = 'addedUserProfileImage',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CostTaxRate = 'costTaxRate',
  /** column name */
  Id = 'id',
  /** column name */
  IsOpenPrice = 'isOpenPrice',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Name = 'name',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Price = 'price',
  /** column name */
  Priority = 'priority',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Remark = 'remark',
  /** column name */
  TaxMethod = 'taxMethod',
  /** column name */
  TaxRate = 'taxRate'
}

/** order by stddev() on columns of table "menuOrderItem" */
export type MenuOrderItemStddevOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "menuOrderItem" */
export type MenuOrderItemStddevPopOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "menuOrderItem" */
export type MenuOrderItemStddevSampOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "menuOrderItem" */
export type MenuOrderItemStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MenuOrderItemStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MenuOrderItemStreamCursorValueInput = {
  _categoryId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  addedUserId?: InputMaybe<Scalars['String']>;
  addedUserName?: InputMaybe<Scalars['String']>;
  addedUserProfileImage?: InputMaybe<Scalars['String']>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  isOpenPrice?: InputMaybe<Scalars['Boolean']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
  taxMethod?: InputMaybe<TaxMethodEnum>;
  taxRate?: InputMaybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "menuOrderItem" */
export type MenuOrderItemSumOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "menuOrderItem" */
export type MenuOrderItemVarPopOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "menuOrderItem" */
export type MenuOrderItemVarSampOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "menuOrderItem" */
export type MenuOrderItemVarianceOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** columns and relationships of "menuRecommendationMeta" */
export type MenuRecommendationMeta = {
  __typename?: 'menuRecommendationMeta';
  _categoryId: Scalars['Int'];
  /** An object relationship */
  category: Category;
  categoryId: Scalars['uuid'];
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  enabled: Scalars['Boolean'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  translations: Array<Translation>;
};


/** columns and relationships of "menuRecommendationMeta" */
export type MenuRecommendationMetaTranslationsArgs = {
  distinct_on?: InputMaybe<Array<TranslationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TranslationOrderBy>>;
  where?: InputMaybe<TranslationBoolExp>;
};

/** Boolean expression to filter rows from the table "menuRecommendationMeta". All fields are combined with a logical 'AND'. */
export type MenuRecommendationMetaBoolExp = {
  _and?: InputMaybe<Array<MenuRecommendationMetaBoolExp>>;
  _categoryId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<MenuRecommendationMetaBoolExp>;
  _or?: InputMaybe<Array<MenuRecommendationMetaBoolExp>>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryId?: InputMaybe<UuidComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  enabled?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  translations?: InputMaybe<TranslationBoolExp>;
};

/** unique or primary key constraints on table "menuRecommendationMeta" */
export enum MenuRecommendationMetaConstraint {
  /** unique or primary key constraint on columns "_categoryId" */
  MenuRecommendationMetaCategoryIdKey = 'menuRecommendationMeta_categoryId_key',
  /** unique or primary key constraint on columns "categoryId" */
  MenuRecommendationMetaCategoryUuidKey = 'menuRecommendationMeta_categoryUuid_key',
  /** unique or primary key constraint on columns "_companyId" */
  MenuRecommendationMetaCompanyIdKey = 'menuRecommendationMeta_companyId_key',
  /** unique or primary key constraint on columns "companyId" */
  MenuRecommendationMetaCompanyIdUuidKey = 'menuRecommendationMeta_companyId_uuid_key',
  /** unique or primary key constraint on columns "id" */
  MenuRecommendationMetaPkey = 'menuRecommendationMeta_pkey'
}

/** input type for incrementing numeric columns in table "menuRecommendationMeta" */
export type MenuRecommendationMetaIncInput = {
  _categoryId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "menuRecommendationMeta" */
export type MenuRecommendationMetaInsertInput = {
  _categoryId: Scalars['Int'];
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryId: Scalars['uuid'];
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  enabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
};

/** response of any mutation on the table "menuRecommendationMeta" */
export type MenuRecommendationMetaMutationResponse = {
  __typename?: 'menuRecommendationMeta_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MenuRecommendationMeta>;
};

/** input type for inserting object relation for remote table "menuRecommendationMeta" */
export type MenuRecommendationMetaObjRelInsertInput = {
  data: MenuRecommendationMetaInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MenuRecommendationMetaOnConflict>;
};

/** on_conflict condition type for table "menuRecommendationMeta" */
export type MenuRecommendationMetaOnConflict = {
  constraint: MenuRecommendationMetaConstraint;
  update_columns?: Array<MenuRecommendationMetaUpdateColumn>;
  where?: InputMaybe<MenuRecommendationMetaBoolExp>;
};

/** Ordering options when selecting data from "menuRecommendationMeta". */
export type MenuRecommendationMetaOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  category?: InputMaybe<CategoryOrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  enabled?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  translations_aggregate?: InputMaybe<TranslationAggregateOrderBy>;
};

/** primary key columns input for table: menuRecommendationMeta */
export type MenuRecommendationMetaPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "menuRecommendationMeta" */
export enum MenuRecommendationMetaSelectColumn {
  /** column name */
  _CategoryId = '_categoryId',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "menuRecommendationMeta" */
export type MenuRecommendationMetaSetInput = {
  _categoryId?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['uuid']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "menuRecommendationMeta" */
export type MenuRecommendationMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MenuRecommendationMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MenuRecommendationMetaStreamCursorValueInput = {
  _categoryId?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['uuid']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "menuRecommendationMeta" */
export enum MenuRecommendationMetaUpdateColumn {
  /** column name */
  _CategoryId = '_categoryId',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type MenuRecommendationMetaUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MenuRecommendationMetaIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MenuRecommendationMetaSetInput>;
  /** filter the rows which have to be updated */
  where: MenuRecommendationMetaBoolExp;
};

export enum MenuTypeEnum {
  Drink = 'drink',
  FaveYell = 'faveYell',
  Food = 'food',
  Other = 'other'
}

/** Boolean expression to compare columns of type "menuType_enum". All fields are combined with logical 'AND'. */
export type MenuTypeEnumComparisonExp = {
  _eq?: InputMaybe<MenuTypeEnum>;
  _in?: InputMaybe<Array<MenuTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MenuTypeEnum>;
  _nin?: InputMaybe<Array<MenuTypeEnum>>;
};

/** order by aggregate values of table "menu" */
export type MenuAggregateOrderBy = {
  avg?: InputMaybe<MenuAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MenuMaxOrderBy>;
  min?: InputMaybe<MenuMinOrderBy>;
  stddev?: InputMaybe<MenuStddevOrderBy>;
  stddev_pop?: InputMaybe<MenuStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MenuStddevSampOrderBy>;
  sum?: InputMaybe<MenuSumOrderBy>;
  var_pop?: InputMaybe<MenuVarPopOrderBy>;
  var_samp?: InputMaybe<MenuVarSampOrderBy>;
  variance?: InputMaybe<MenuVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "menu" */
export type MenuArrRelInsertInput = {
  data: Array<MenuInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MenuOnConflict>;
};

/** order by avg() on columns of table "menu" */
export type MenuAvgOrderBy = {
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  orderMaxNum?: InputMaybe<OrderBy>;
  orderMaxNumPerTableUser?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  unitMaxOrderNumForNumPeople?: InputMaybe<OrderBy>;
  unitMaxOrderNumPerCustomer?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "menu". All fields are combined with a logical 'AND'. */
export type MenuBoolExp = {
  _and?: InputMaybe<Array<MenuBoolExp>>;
  _not?: InputMaybe<MenuBoolExp>;
  _or?: InputMaybe<Array<MenuBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  categoryMenus?: InputMaybe<CategoryMenuBoolExp>;
  categoryMenus_aggregate?: InputMaybe<CategoryMenuAggregateBoolExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  compulsoryAppetizerConfigs?: InputMaybe<CompulsoryAppetizerConfigBoolExp>;
  costPrice?: InputMaybe<NumericComparisonExp>;
  costTaxRate?: InputMaybe<NumericComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  dishUpSlipGroupShopMenus?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
  featuredLabelText?: InputMaybe<StringComparisonExp>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuBoolExp>;
  giftMeta?: InputMaybe<GiftMetaBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  isTakeOut?: InputMaybe<BooleanComparisonExp>;
  menuId?: InputMaybe<IntComparisonExp>;
  menuOptions?: InputMaybe<MenuOptionBoolExp>;
  menuType?: InputMaybe<MenuTypeEnumComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  openPrice?: InputMaybe<BooleanComparisonExp>;
  orderMaxNum?: InputMaybe<IntComparisonExp>;
  orderMaxNumPerTableUser?: InputMaybe<IntComparisonExp>;
  orderableTime?: InputMaybe<OrderableTimeBoolExp>;
  orderableTimeId?: InputMaybe<UuidComparisonExp>;
  pikaichiMenuMenus?: InputMaybe<PikaichiMenuMenuBoolExp>;
  price?: InputMaybe<IntComparisonExp>;
  receiptDisplayName?: InputMaybe<StringComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  serviceChargeMeta?: InputMaybe<ServiceChargeMetaBoolExp>;
  shopMenuCookingItems?: InputMaybe<ShopMenuCookingItemBoolExp>;
  shopMenuKitchenRoles?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
  shopMenus?: InputMaybe<ShopMenuBoolExp>;
  shopSideName?: InputMaybe<StringComparisonExp>;
  shouldMergeSlipOptions?: InputMaybe<BooleanComparisonExp>;
  specificTimeSurchargeMeta?: InputMaybe<SpecificTimeSurchargeMetaBoolExp>;
  taxMethod?: InputMaybe<TaxMethodEnumComparisonExp>;
  taxRate?: InputMaybe<NumericComparisonExp>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuBoolExp>;
  totalDealingShopCount?: InputMaybe<IntComparisonExp>;
  translations?: InputMaybe<TranslationBoolExp>;
  unitMaxOrderNumForNumPeople?: InputMaybe<IntComparisonExp>;
  unitMaxOrderNumPerCustomer?: InputMaybe<IntComparisonExp>;
  winboardMenus?: InputMaybe<WinboardMenuBoolExp>;
};

/** unique or primary key constraints on table "menu" */
export enum MenuConstraint {
  /** unique or primary key constraint on columns "serial" */
  Idx_46512Primary = 'idx_46512_PRIMARY',
  /** unique or primary key constraint on columns "id" */
  MenuIdKey = 'menu_id_key'
}

/** input type for incrementing numeric columns in table "menu" */
export type MenuIncInput = {
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  orderMaxNum?: InputMaybe<Scalars['Int']>;
  orderMaxNumPerTableUser?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['numeric']>;
  unitMaxOrderNumForNumPeople?: InputMaybe<Scalars['Int']>;
  unitMaxOrderNumPerCustomer?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "menu" */
export type MenuInsertInput = {
  categoryMenus?: InputMaybe<CategoryMenuArrRelInsertInput>;
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  compulsoryAppetizerConfigs?: InputMaybe<CompulsoryAppetizerConfigArrRelInsertInput>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  description?: InputMaybe<Scalars['String']>;
  dishUpSlipGroupShopMenus?: InputMaybe<DishUpSlipGroupShopMenusArrRelInsertInput>;
  featuredLabelText?: InputMaybe<Scalars['String']>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuObjRelInsertInput>;
  giftMeta?: InputMaybe<GiftMetaObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isTakeOut?: InputMaybe<Scalars['Boolean']>;
  menuOptions?: InputMaybe<MenuOptionArrRelInsertInput>;
  menuType?: InputMaybe<MenuTypeEnum>;
  name: Scalars['String'];
  openPrice?: InputMaybe<Scalars['Boolean']>;
  orderMaxNum?: InputMaybe<Scalars['Int']>;
  orderableTime?: InputMaybe<OrderableTimeObjRelInsertInput>;
  orderableTimeId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuMenus?: InputMaybe<PikaichiMenuMenuArrRelInsertInput>;
  price: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
  serial?: InputMaybe<Scalars['Int']>;
  shopMenuCookingItems?: InputMaybe<ShopMenuCookingItemArrRelInsertInput>;
  shopMenuKitchenRoles?: InputMaybe<ShopMenuKitchenRoleArrRelInsertInput>;
  shopSideName?: InputMaybe<Scalars['String']>;
  shouldMergeSlipOptions?: InputMaybe<Scalars['Boolean']>;
  taxMethod?: InputMaybe<TaxMethodEnum>;
  taxRate?: InputMaybe<Scalars['numeric']>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuObjRelInsertInput>;
  unitMaxOrderNumForNumPeople?: InputMaybe<Scalars['Int']>;
  unitMaxOrderNumPerCustomer?: InputMaybe<Scalars['Int']>;
  winboardMenus?: InputMaybe<WinboardMenuArrRelInsertInput>;
};

/** order by max() on columns of table "menu" */
export type MenuMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  featuredLabelText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderMaxNum?: InputMaybe<OrderBy>;
  orderMaxNumPerTableUser?: InputMaybe<OrderBy>;
  orderableTimeId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopSideName?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  unitMaxOrderNumForNumPeople?: InputMaybe<OrderBy>;
  unitMaxOrderNumPerCustomer?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "menu" */
export type MenuMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  featuredLabelText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderMaxNum?: InputMaybe<OrderBy>;
  orderMaxNumPerTableUser?: InputMaybe<OrderBy>;
  orderableTimeId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopSideName?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  unitMaxOrderNumForNumPeople?: InputMaybe<OrderBy>;
  unitMaxOrderNumPerCustomer?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "menu" */
export type MenuMutationResponse = {
  __typename?: 'menu_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Menu>;
};

/** input type for inserting object relation for remote table "menu" */
export type MenuObjRelInsertInput = {
  data: MenuInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MenuOnConflict>;
};

/** on_conflict condition type for table "menu" */
export type MenuOnConflict = {
  constraint: MenuConstraint;
  update_columns?: Array<MenuUpdateColumn>;
  where?: InputMaybe<MenuBoolExp>;
};

/** Ordering options when selecting data from "menu". */
export type MenuOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  categoryMenus_aggregate?: InputMaybe<CategoryMenuAggregateOrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  compulsoryAppetizerConfigs_aggregate?: InputMaybe<CompulsoryAppetizerConfigAggregateOrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  dishUpSlipGroupShopMenus_aggregate?: InputMaybe<DishUpSlipGroupShopMenusAggregateOrderBy>;
  featuredLabelText?: InputMaybe<OrderBy>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuOrderBy>;
  giftMeta?: InputMaybe<GiftMetaOrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  isTakeOut?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  menuOptions_aggregate?: InputMaybe<MenuOptionAggregateOrderBy>;
  menuType?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  openPrice?: InputMaybe<OrderBy>;
  orderMaxNum?: InputMaybe<OrderBy>;
  orderMaxNumPerTableUser?: InputMaybe<OrderBy>;
  orderableTime?: InputMaybe<OrderableTimeOrderBy>;
  orderableTimeId?: InputMaybe<OrderBy>;
  pikaichiMenuMenus_aggregate?: InputMaybe<PikaichiMenuMenuAggregateOrderBy>;
  price?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  serviceChargeMeta?: InputMaybe<ServiceChargeMetaOrderBy>;
  shopMenuCookingItems_aggregate?: InputMaybe<ShopMenuCookingItemAggregateOrderBy>;
  shopMenuKitchenRoles_aggregate?: InputMaybe<ShopMenuKitchenRoleAggregateOrderBy>;
  shopMenus_aggregate?: InputMaybe<ShopMenuAggregateOrderBy>;
  shopSideName?: InputMaybe<OrderBy>;
  shouldMergeSlipOptions?: InputMaybe<OrderBy>;
  specificTimeSurchargeMeta?: InputMaybe<SpecificTimeSurchargeMetaOrderBy>;
  taxMethod?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuOrderBy>;
  totalDealingShopCount?: InputMaybe<OrderBy>;
  translations_aggregate?: InputMaybe<TranslationAggregateOrderBy>;
  unitMaxOrderNumForNumPeople?: InputMaybe<OrderBy>;
  unitMaxOrderNumPerCustomer?: InputMaybe<OrderBy>;
  winboardMenus_aggregate?: InputMaybe<WinboardMenuAggregateOrderBy>;
};

/** primary key columns input for table: menu */
export type MenuPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "menu" */
export enum MenuSelectColumn {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CostTaxRate = 'costTaxRate',
  /** column name */
  Description = 'description',
  /** column name */
  FeaturedLabelText = 'featuredLabelText',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  IsTakeOut = 'isTakeOut',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  MenuType = 'menuType',
  /** column name */
  Name = 'name',
  /** column name */
  OpenPrice = 'openPrice',
  /** column name */
  OrderMaxNum = 'orderMaxNum',
  /** column name */
  OrderMaxNumPerTableUser = 'orderMaxNumPerTableUser',
  /** column name */
  OrderableTimeId = 'orderableTimeId',
  /** column name */
  Price = 'price',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopSideName = 'shopSideName',
  /** column name */
  ShouldMergeSlipOptions = 'shouldMergeSlipOptions',
  /** column name */
  TaxMethod = 'taxMethod',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UnitMaxOrderNumForNumPeople = 'unitMaxOrderNumForNumPeople',
  /** column name */
  UnitMaxOrderNumPerCustomer = 'unitMaxOrderNumPerCustomer'
}

/** input type for updating data in table "menu" */
export type MenuSetInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  description?: InputMaybe<Scalars['String']>;
  featuredLabelText?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isTakeOut?: InputMaybe<Scalars['Boolean']>;
  menuType?: InputMaybe<MenuTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  openPrice?: InputMaybe<Scalars['Boolean']>;
  orderMaxNum?: InputMaybe<Scalars['Int']>;
  orderMaxNumPerTableUser?: InputMaybe<Scalars['Int']>;
  orderableTimeId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopSideName?: InputMaybe<Scalars['String']>;
  shouldMergeSlipOptions?: InputMaybe<Scalars['Boolean']>;
  taxMethod?: InputMaybe<TaxMethodEnum>;
  taxRate?: InputMaybe<Scalars['numeric']>;
  unitMaxOrderNumForNumPeople?: InputMaybe<Scalars['Int']>;
  unitMaxOrderNumPerCustomer?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "menu" */
export type MenuStddevOrderBy = {
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  orderMaxNum?: InputMaybe<OrderBy>;
  orderMaxNumPerTableUser?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  unitMaxOrderNumForNumPeople?: InputMaybe<OrderBy>;
  unitMaxOrderNumPerCustomer?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "menu" */
export type MenuStddevPopOrderBy = {
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  orderMaxNum?: InputMaybe<OrderBy>;
  orderMaxNumPerTableUser?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  unitMaxOrderNumForNumPeople?: InputMaybe<OrderBy>;
  unitMaxOrderNumPerCustomer?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "menu" */
export type MenuStddevSampOrderBy = {
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  orderMaxNum?: InputMaybe<OrderBy>;
  orderMaxNumPerTableUser?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  unitMaxOrderNumForNumPeople?: InputMaybe<OrderBy>;
  unitMaxOrderNumPerCustomer?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "menu" */
export type MenuStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MenuStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MenuStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  description?: InputMaybe<Scalars['String']>;
  featuredLabelText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isTakeOut?: InputMaybe<Scalars['Boolean']>;
  menuId?: InputMaybe<Scalars['Int']>;
  menuType?: InputMaybe<MenuTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  openPrice?: InputMaybe<Scalars['Boolean']>;
  orderMaxNum?: InputMaybe<Scalars['Int']>;
  orderMaxNumPerTableUser?: InputMaybe<Scalars['Int']>;
  orderableTimeId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopSideName?: InputMaybe<Scalars['String']>;
  shouldMergeSlipOptions?: InputMaybe<Scalars['Boolean']>;
  taxMethod?: InputMaybe<TaxMethodEnum>;
  taxRate?: InputMaybe<Scalars['numeric']>;
  unitMaxOrderNumForNumPeople?: InputMaybe<Scalars['Int']>;
  unitMaxOrderNumPerCustomer?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "menu" */
export type MenuSumOrderBy = {
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  orderMaxNum?: InputMaybe<OrderBy>;
  orderMaxNumPerTableUser?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  unitMaxOrderNumForNumPeople?: InputMaybe<OrderBy>;
  unitMaxOrderNumPerCustomer?: InputMaybe<OrderBy>;
};

/** update columns of table "menu" */
export enum MenuUpdateColumn {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CostTaxRate = 'costTaxRate',
  /** column name */
  Description = 'description',
  /** column name */
  FeaturedLabelText = 'featuredLabelText',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  IsTakeOut = 'isTakeOut',
  /** column name */
  MenuType = 'menuType',
  /** column name */
  Name = 'name',
  /** column name */
  OpenPrice = 'openPrice',
  /** column name */
  OrderMaxNum = 'orderMaxNum',
  /** column name */
  OrderMaxNumPerTableUser = 'orderMaxNumPerTableUser',
  /** column name */
  OrderableTimeId = 'orderableTimeId',
  /** column name */
  Price = 'price',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopSideName = 'shopSideName',
  /** column name */
  ShouldMergeSlipOptions = 'shouldMergeSlipOptions',
  /** column name */
  TaxMethod = 'taxMethod',
  /** column name */
  TaxRate = 'taxRate',
  /** column name */
  UnitMaxOrderNumForNumPeople = 'unitMaxOrderNumForNumPeople',
  /** column name */
  UnitMaxOrderNumPerCustomer = 'unitMaxOrderNumPerCustomer'
}

export type MenuUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MenuIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MenuSetInput>;
  /** filter the rows which have to be updated */
  where: MenuBoolExp;
};

/** order by var_pop() on columns of table "menu" */
export type MenuVarPopOrderBy = {
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  orderMaxNum?: InputMaybe<OrderBy>;
  orderMaxNumPerTableUser?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  unitMaxOrderNumForNumPeople?: InputMaybe<OrderBy>;
  unitMaxOrderNumPerCustomer?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "menu" */
export type MenuVarSampOrderBy = {
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  orderMaxNum?: InputMaybe<OrderBy>;
  orderMaxNumPerTableUser?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  unitMaxOrderNumForNumPeople?: InputMaybe<OrderBy>;
  unitMaxOrderNumPerCustomer?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "menu" */
export type MenuVarianceOrderBy = {
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  orderMaxNum?: InputMaybe<OrderBy>;
  orderMaxNumPerTableUser?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  unitMaxOrderNumForNumPeople?: InputMaybe<OrderBy>;
  unitMaxOrderNumPerCustomer?: InputMaybe<OrderBy>;
};

/** columns and relationships of "messageDelivery" */
export type MessageDelivery = {
  __typename?: 'messageDelivery';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  /** An array relationship */
  couponAppliedEvents: Array<CouponAppliedEvent>;
  /** An aggregate relationship */
  couponAppliedEvents_aggregate: CouponAppliedEventAggregate;
  /** An object relationship */
  customerList?: Maybe<MessageDeliveryCustomerList>;
  customerListId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  customerQuestionnaires: Array<CustomerQuestionnaire>;
  /** An object relationship */
  customerSegment?: Maybe<MessageDeliveryCustomerSegment>;
  customerSegmentId?: Maybe<Scalars['uuid']>;
  deliverAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isDraft: Scalars['Boolean'];
  isSuspended: Scalars['Boolean'];
  /** An array relationship */
  messageDeliveryJobs: Array<MessageDeliveryJob>;
  /** An array relationship */
  messageDeliveryMessages: Array<MessageDeliveryMessage>;
  name: Scalars['String'];
  repeatDeliveryTime?: Maybe<Scalars['timetz']>;
  scope: Scalars['String'];
  targetCount?: Maybe<Scalars['Int']>;
  targetCountUpdatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "messageDelivery" */
export type MessageDeliveryCouponAppliedEventsArgs = {
  distinct_on?: InputMaybe<Array<CouponAppliedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponAppliedEventOrderBy>>;
  where?: InputMaybe<CouponAppliedEventBoolExp>;
};


/** columns and relationships of "messageDelivery" */
export type MessageDeliveryCouponAppliedEventsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CouponAppliedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponAppliedEventOrderBy>>;
  where?: InputMaybe<CouponAppliedEventBoolExp>;
};


/** columns and relationships of "messageDelivery" */
export type MessageDeliveryCustomerQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<CustomerQuestionnaireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerQuestionnaireOrderBy>>;
  where?: InputMaybe<CustomerQuestionnaireBoolExp>;
};


/** columns and relationships of "messageDelivery" */
export type MessageDeliveryMessageDeliveryJobsArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryJobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryJobOrderBy>>;
  where?: InputMaybe<MessageDeliveryJobBoolExp>;
};


/** columns and relationships of "messageDelivery" */
export type MessageDeliveryMessageDeliveryMessagesArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageBoolExp>;
};

/** columns and relationships of "messageDeliveryCustomerList" */
export type MessageDeliveryCustomerList = {
  __typename?: 'messageDeliveryCustomerList';
  /** An array relationship */
  customerListCustomers: Array<MessageDeliveryCustomerListCustomer>;
  /** An aggregate relationship */
  customerListCustomers_aggregate: MessageDeliveryCustomerListCustomerAggregate;
  id: Scalars['uuid'];
  isFromCsv: Scalars['Boolean'];
  /** An object relationship */
  messageDelivery?: Maybe<MessageDelivery>;
};


/** columns and relationships of "messageDeliveryCustomerList" */
export type MessageDeliveryCustomerListCustomerListCustomersArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerListCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerListCustomerOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
};


/** columns and relationships of "messageDeliveryCustomerList" */
export type MessageDeliveryCustomerListCustomerListCustomersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerListCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerListCustomerOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
};

/** columns and relationships of "messageDeliveryCustomerListCustomer" */
export type MessageDeliveryCustomerListCustomer = {
  __typename?: 'messageDeliveryCustomerListCustomer';
  /** An object relationship */
  customer: Customer;
  customerId: Scalars['String'];
  /** An object relationship */
  customerList: MessageDeliveryCustomerList;
  id: Scalars['uuid'];
  messageDeliveryCustomerListId: Scalars['uuid'];
};

/** aggregated selection of "messageDeliveryCustomerListCustomer" */
export type MessageDeliveryCustomerListCustomerAggregate = {
  __typename?: 'messageDeliveryCustomerListCustomer_aggregate';
  aggregate?: Maybe<MessageDeliveryCustomerListCustomerAggregateFields>;
  nodes: Array<MessageDeliveryCustomerListCustomer>;
};

export type MessageDeliveryCustomerListCustomerAggregateBoolExp = {
  count?: InputMaybe<MessageDeliveryCustomerListCustomerAggregateBoolExpCount>;
};

export type MessageDeliveryCustomerListCustomerAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MessageDeliveryCustomerListCustomerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "messageDeliveryCustomerListCustomer" */
export type MessageDeliveryCustomerListCustomerAggregateFields = {
  __typename?: 'messageDeliveryCustomerListCustomer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MessageDeliveryCustomerListCustomerMaxFields>;
  min?: Maybe<MessageDeliveryCustomerListCustomerMinFields>;
};


/** aggregate fields of "messageDeliveryCustomerListCustomer" */
export type MessageDeliveryCustomerListCustomerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MessageDeliveryCustomerListCustomerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "messageDeliveryCustomerListCustomer" */
export type MessageDeliveryCustomerListCustomerAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MessageDeliveryCustomerListCustomerMaxOrderBy>;
  min?: InputMaybe<MessageDeliveryCustomerListCustomerMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerListCustomer". All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerListCustomerBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerListCustomerBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerListCustomerBoolExp>>;
  customer?: InputMaybe<CustomerBoolExp>;
  customerId?: InputMaybe<StringComparisonExp>;
  customerList?: InputMaybe<MessageDeliveryCustomerListBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerListId?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type MessageDeliveryCustomerListCustomerMaxFields = {
  __typename?: 'messageDeliveryCustomerListCustomer_max_fields';
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  messageDeliveryCustomerListId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "messageDeliveryCustomerListCustomer" */
export type MessageDeliveryCustomerListCustomerMaxOrderBy = {
  customerId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerListId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MessageDeliveryCustomerListCustomerMinFields = {
  __typename?: 'messageDeliveryCustomerListCustomer_min_fields';
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  messageDeliveryCustomerListId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "messageDeliveryCustomerListCustomer" */
export type MessageDeliveryCustomerListCustomerMinOrderBy = {
  customerId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerListId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerListCustomer". */
export type MessageDeliveryCustomerListCustomerOrderBy = {
  customer?: InputMaybe<CustomerOrderBy>;
  customerId?: InputMaybe<OrderBy>;
  customerList?: InputMaybe<MessageDeliveryCustomerListOrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerListId?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerListCustomer" */
export enum MessageDeliveryCustomerListCustomerSelectColumn {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryCustomerListId = 'messageDeliveryCustomerListId'
}

/** Streaming cursor of the table "messageDeliveryCustomerListCustomer" */
export type MessageDeliveryCustomerListCustomerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerListCustomerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerListCustomerStreamCursorValueInput = {
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerListId?: InputMaybe<Scalars['uuid']>;
};

/**
 * Boolean expression to filter rows from the table "messageDeliveryCustomerList".
 * All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerListBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerListBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerListBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerListBoolExp>>;
  customerListCustomers?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
  customerListCustomers_aggregate?: InputMaybe<MessageDeliveryCustomerListCustomerAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isFromCsv?: InputMaybe<BooleanComparisonExp>;
  messageDelivery?: InputMaybe<MessageDeliveryBoolExp>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerList". */
export type MessageDeliveryCustomerListOrderBy = {
  customerListCustomers_aggregate?: InputMaybe<MessageDeliveryCustomerListCustomerAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isFromCsv?: InputMaybe<OrderBy>;
  messageDelivery?: InputMaybe<MessageDeliveryOrderBy>;
};

/** select columns of table "messageDeliveryCustomerList" */
export enum MessageDeliveryCustomerListSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  IsFromCsv = 'isFromCsv'
}

/** Streaming cursor of the table "messageDeliveryCustomerList" */
export type MessageDeliveryCustomerListStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerListStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerListStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  isFromCsv?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "messageDeliveryCustomerSegment" */
export type MessageDeliveryCustomerSegment = {
  __typename?: 'messageDeliveryCustomerSegment';
  conditions?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  /** An object relationship */
  messageDelivery?: Maybe<MessageDelivery>;
  /** An array relationship */
  messageDeliveryCustomerSegmentBusinessOperationHoursConditions: Array<MessageDeliveryCustomerSegmentBusinessOperationHoursCondition>;
  /** An array relationship */
  messageDeliveryCustomerSegmentDayOfWeekConditions: Array<MessageDeliveryCustomerSegmentDayOfWeekCondition>;
  /** An object relationship */
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: Maybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition>;
  /** An object relationship */
  messageDeliveryCustomerSegmentGenderCondition?: Maybe<MessageDeliveryCustomerSegmentGenderCondition>;
  /** An array relationship */
  messageDeliveryCustomerSegmentMenuConditions: Array<MessageDeliveryCustomerSegmentMenuCondition>;
  /** An object relationship */
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: Maybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition>;
  /** An array relationship */
  messageDeliveryCustomerSegmentShopConditions: Array<MessageDeliveryCustomerSegmentShopCondition>;
  /** An object relationship */
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: Maybe<MessageDeliveryCustomerSegmentUnvisitedDayCountCondition>;
  /** An object relationship */
  messageDeliveryCustomerSegmentVisitedDayCountCondition?: Maybe<MessageDeliveryCustomerSegmentVisitedDayCountCondition>;
};


/** columns and relationships of "messageDeliveryCustomerSegment" */
export type MessageDeliveryCustomerSegmentConditionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "messageDeliveryCustomerSegment" */
export type MessageDeliveryCustomerSegmentMessageDeliveryCustomerSegmentBusinessOperationHoursConditionsArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionBoolExp>;
};


/** columns and relationships of "messageDeliveryCustomerSegment" */
export type MessageDeliveryCustomerSegmentMessageDeliveryCustomerSegmentDayOfWeekConditionsArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentDayOfWeekConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentDayOfWeekConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekConditionBoolExp>;
};


/** columns and relationships of "messageDeliveryCustomerSegment" */
export type MessageDeliveryCustomerSegmentMessageDeliveryCustomerSegmentMenuConditionsArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentMenuConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentMenuConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionBoolExp>;
};


/** columns and relationships of "messageDeliveryCustomerSegment" */
export type MessageDeliveryCustomerSegmentMessageDeliveryCustomerSegmentShopConditionsArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentShopConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentShopConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentShopConditionBoolExp>;
};

/** columns and relationships of "messageDeliveryCustomerSegmentBusinessOperationHoursCondition" */
export type MessageDeliveryCustomerSegmentBusinessOperationHoursCondition = {
  __typename?: 'messageDeliveryCustomerSegmentBusinessOperationHoursCondition';
  businessOperationHourType: BusinessOperationHourTypeEnum;
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegment: MessageDeliveryCustomerSegment;
  messageDeliveryCustomerSegmentId: Scalars['uuid'];
};

/** order by aggregate values of table "messageDeliveryCustomerSegmentBusinessOperationHoursCondition" */
export type MessageDeliveryCustomerSegmentBusinessOperationHoursConditionAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionMaxOrderBy>;
  min?: InputMaybe<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentBusinessOperationHoursCondition". All fields are
 * combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentBusinessOperationHoursConditionBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionBoolExp>>;
  businessOperationHourType?: InputMaybe<BusinessOperationHourTypeEnumComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
  messageDeliveryCustomerSegmentId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "messageDeliveryCustomerSegmentBusinessOperationHoursCondition" */
export type MessageDeliveryCustomerSegmentBusinessOperationHoursConditionMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "messageDeliveryCustomerSegmentBusinessOperationHoursCondition" */
export type MessageDeliveryCustomerSegmentBusinessOperationHoursConditionMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentBusinessOperationHoursCondition". */
export type MessageDeliveryCustomerSegmentBusinessOperationHoursConditionOrderBy = {
  businessOperationHourType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentOrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentBusinessOperationHoursCondition" */
export enum MessageDeliveryCustomerSegmentBusinessOperationHoursConditionSelectColumn {
  /** column name */
  BusinessOperationHourType = 'businessOperationHourType',
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryCustomerSegmentId = 'messageDeliveryCustomerSegmentId'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegmentBusinessOperationHoursCondition" */
export type MessageDeliveryCustomerSegmentBusinessOperationHoursConditionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentBusinessOperationHoursConditionStreamCursorValueInput = {
  businessOperationHourType?: InputMaybe<BusinessOperationHourTypeEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "messageDeliveryCustomerSegmentDayOfWeekCondition" */
export type MessageDeliveryCustomerSegmentDayOfWeekCondition = {
  __typename?: 'messageDeliveryCustomerSegmentDayOfWeekCondition';
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegment: MessageDeliveryCustomerSegment;
  messageDeliveryCustomerSegmentId: Scalars['uuid'];
  messageDeliveryDayOfWeek: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum;
};

/** order by aggregate values of table "messageDeliveryCustomerSegmentDayOfWeekCondition" */
export type MessageDeliveryCustomerSegmentDayOfWeekConditionAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekConditionMaxOrderBy>;
  min?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekConditionMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentDayOfWeekCondition". All fields are combined with
 * a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentDayOfWeekConditionBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentDayOfWeekConditionBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekConditionBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentDayOfWeekConditionBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
  messageDeliveryCustomerSegmentId?: InputMaybe<UuidComparisonExp>;
  messageDeliveryDayOfWeek?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekTypeEnumComparisonExp>;
};

/** order by max() on columns of table "messageDeliveryCustomerSegmentDayOfWeekCondition" */
export type MessageDeliveryCustomerSegmentDayOfWeekConditionMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "messageDeliveryCustomerSegmentDayOfWeekCondition" */
export type MessageDeliveryCustomerSegmentDayOfWeekConditionMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentDayOfWeekCondition". */
export type MessageDeliveryCustomerSegmentDayOfWeekConditionOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentOrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  messageDeliveryDayOfWeek?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentDayOfWeekCondition" */
export enum MessageDeliveryCustomerSegmentDayOfWeekConditionSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryCustomerSegmentId = 'messageDeliveryCustomerSegmentId',
  /** column name */
  MessageDeliveryDayOfWeek = 'messageDeliveryDayOfWeek'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegmentDayOfWeekCondition" */
export type MessageDeliveryCustomerSegmentDayOfWeekConditionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentDayOfWeekConditionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentDayOfWeekConditionStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentId?: InputMaybe<Scalars['uuid']>;
  messageDeliveryDayOfWeek?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekTypeEnum>;
};

export enum MessageDeliveryCustomerSegmentDayOfWeekTypeEnum {
  Fri = 'Fri',
  Holiday = 'Holiday',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed'
}

/**
 * Boolean expression to compare columns of type
 * "messageDeliveryCustomerSegmentDayOfWeekType_enum". All fields are combined with logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentDayOfWeekTypeEnumComparisonExp = {
  _eq?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekTypeEnum>;
  _in?: InputMaybe<Array<MessageDeliveryCustomerSegmentDayOfWeekTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekTypeEnum>;
  _nin?: InputMaybe<Array<MessageDeliveryCustomerSegmentDayOfWeekTypeEnum>>;
};

/** columns and relationships of "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition" */
export type MessageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition = {
  __typename?: 'messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition';
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegment: MessageDeliveryCustomerSegment;
  messageDeliveryCustomerSegmentId: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegmentNumericCondition: MessageDeliveryCustomerSegmentNumericCondition;
  messageDeliveryCustomerSegmentNumericConditionId: Scalars['uuid'];
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition". All fields are
 * combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
  messageDeliveryCustomerSegmentId?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegmentNumericCondition?: InputMaybe<MessageDeliveryCustomerSegmentNumericConditionBoolExp>;
  messageDeliveryCustomerSegmentNumericConditionId?: InputMaybe<UuidComparisonExp>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition". */
export type MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentOrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentNumericCondition?: InputMaybe<MessageDeliveryCustomerSegmentNumericConditionOrderBy>;
  messageDeliveryCustomerSegmentNumericConditionId?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition" */
export enum MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryCustomerSegmentId = 'messageDeliveryCustomerSegmentId',
  /** column name */
  MessageDeliveryCustomerSegmentNumericConditionId = 'messageDeliveryCustomerSegmentNumericConditionId'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition" */
export type MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentId?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentNumericConditionId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "messageDeliveryCustomerSegmentGender" */
export type MessageDeliveryCustomerSegmentGender = {
  __typename?: 'messageDeliveryCustomerSegmentGender';
  value: Scalars['String'];
};

/** columns and relationships of "messageDeliveryCustomerSegmentGenderCondition" */
export type MessageDeliveryCustomerSegmentGenderCondition = {
  __typename?: 'messageDeliveryCustomerSegmentGenderCondition';
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegment: MessageDeliveryCustomerSegment;
  /** An object relationship */
  messageDeliveryCustomerSegmentGender: MessageDeliveryCustomerSegmentGender;
  messageDeliveryCustomerSegmentId: Scalars['uuid'];
  messageDeliveryGender: MessageDeliveryCustomerSegmentGenderEnum;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentGenderCondition". All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentGenderConditionBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderConditionBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentGenderConditionBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderConditionBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
  messageDeliveryCustomerSegmentGender?: InputMaybe<MessageDeliveryCustomerSegmentGenderBoolExp>;
  messageDeliveryCustomerSegmentId?: InputMaybe<UuidComparisonExp>;
  messageDeliveryGender?: InputMaybe<MessageDeliveryCustomerSegmentGenderEnumComparisonExp>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentGenderCondition". */
export type MessageDeliveryCustomerSegmentGenderConditionOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentOrderBy>;
  messageDeliveryCustomerSegmentGender?: InputMaybe<MessageDeliveryCustomerSegmentGenderOrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  messageDeliveryGender?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentGenderCondition" */
export enum MessageDeliveryCustomerSegmentGenderConditionSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryCustomerSegmentId = 'messageDeliveryCustomerSegmentId',
  /** column name */
  MessageDeliveryGender = 'messageDeliveryGender'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegmentGenderCondition" */
export type MessageDeliveryCustomerSegmentGenderConditionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentGenderConditionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentGenderConditionStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentId?: InputMaybe<Scalars['uuid']>;
  messageDeliveryGender?: InputMaybe<MessageDeliveryCustomerSegmentGenderEnum>;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentGender". All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentGenderBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentGenderBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum MessageDeliveryCustomerSegmentGenderEnum {
  Female = 'Female',
  Male = 'Male'
}

/**
 * Boolean expression to compare columns of type
 * "messageDeliveryCustomerSegmentGender_enum". All fields are combined with logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentGenderEnumComparisonExp = {
  _eq?: InputMaybe<MessageDeliveryCustomerSegmentGenderEnum>;
  _in?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MessageDeliveryCustomerSegmentGenderEnum>;
  _nin?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderEnum>>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentGender". */
export type MessageDeliveryCustomerSegmentGenderOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentGender" */
export enum MessageDeliveryCustomerSegmentGenderSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegmentGender" */
export type MessageDeliveryCustomerSegmentGenderStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentGenderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentGenderStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuCondition = {
  __typename?: 'messageDeliveryCustomerSegmentMenuCondition';
  id: Scalars['uuid'];
  /** An object relationship */
  menu: Menu;
  menuId: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegment: MessageDeliveryCustomerSegment;
  messageDeliveryCustomerSegmentId: Scalars['uuid'];
  moreThanOrEqualQuantity: Scalars['Int'];
};

/** order by aggregate values of table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionAggregateOrderBy = {
  avg?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionMaxOrderBy>;
  min?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionMinOrderBy>;
  stddev?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionStddevOrderBy>;
  stddev_pop?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionStddevSampOrderBy>;
  sum?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionSumOrderBy>;
  var_pop?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionVarPopOrderBy>;
  var_samp?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionVarSampOrderBy>;
  variance?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionVarianceOrderBy>;
};

/** order by avg() on columns of table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionAvgOrderBy = {
  moreThanOrEqualQuantity?: InputMaybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentMenuCondition". All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentMenuConditionBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentMenuConditionBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentMenuConditionBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
  messageDeliveryCustomerSegmentId?: InputMaybe<UuidComparisonExp>;
  moreThanOrEqualQuantity?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  moreThanOrEqualQuantity?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  moreThanOrEqualQuantity?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentMenuCondition". */
export type MessageDeliveryCustomerSegmentMenuConditionOrderBy = {
  id?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentOrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  moreThanOrEqualQuantity?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentMenuCondition" */
export enum MessageDeliveryCustomerSegmentMenuConditionSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  MessageDeliveryCustomerSegmentId = 'messageDeliveryCustomerSegmentId',
  /** column name */
  MoreThanOrEqualQuantity = 'moreThanOrEqualQuantity'
}

/** order by stddev() on columns of table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionStddevOrderBy = {
  moreThanOrEqualQuantity?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionStddevPopOrderBy = {
  moreThanOrEqualQuantity?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionStddevSampOrderBy = {
  moreThanOrEqualQuantity?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentMenuConditionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentMenuConditionStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentId?: InputMaybe<Scalars['uuid']>;
  moreThanOrEqualQuantity?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionSumOrderBy = {
  moreThanOrEqualQuantity?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionVarPopOrderBy = {
  moreThanOrEqualQuantity?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionVarSampOrderBy = {
  moreThanOrEqualQuantity?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "messageDeliveryCustomerSegmentMenuCondition" */
export type MessageDeliveryCustomerSegmentMenuConditionVarianceOrderBy = {
  moreThanOrEqualQuantity?: InputMaybe<OrderBy>;
};

/** columns and relationships of "messageDeliveryCustomerSegmentNumericCondition" */
export type MessageDeliveryCustomerSegmentNumericCondition = {
  __typename?: 'messageDeliveryCustomerSegmentNumericCondition';
  equal?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  lessThanOrEqual?: Maybe<Scalars['Int']>;
  /** An object relationship */
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: Maybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition>;
  /** An object relationship */
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: Maybe<MessageDeliveryCustomerSegmentUnvisitedDayCountCondition>;
  /** An object relationship */
  messageDeliveryCustomerSegmentVisitedDayCountCondition?: Maybe<MessageDeliveryCustomerSegmentVisitedDayCountCondition>;
  moreThanOrEqual?: Maybe<Scalars['Int']>;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentNumericCondition". All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentNumericConditionBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentNumericConditionBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentNumericConditionBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentNumericConditionBoolExp>>;
  equal?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lessThanOrEqual?: InputMaybe<IntComparisonExp>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: InputMaybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionBoolExp>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: InputMaybe<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionBoolExp>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition?: InputMaybe<MessageDeliveryCustomerSegmentVisitedDayCountConditionBoolExp>;
  moreThanOrEqual?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentNumericCondition". */
export type MessageDeliveryCustomerSegmentNumericConditionOrderBy = {
  equal?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lessThanOrEqual?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: InputMaybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionOrderBy>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: InputMaybe<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionOrderBy>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition?: InputMaybe<MessageDeliveryCustomerSegmentVisitedDayCountConditionOrderBy>;
  moreThanOrEqual?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentNumericCondition" */
export enum MessageDeliveryCustomerSegmentNumericConditionSelectColumn {
  /** column name */
  Equal = 'equal',
  /** column name */
  Id = 'id',
  /** column name */
  LessThanOrEqual = 'lessThanOrEqual',
  /** column name */
  MoreThanOrEqual = 'moreThanOrEqual'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegmentNumericCondition" */
export type MessageDeliveryCustomerSegmentNumericConditionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentNumericConditionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentNumericConditionStreamCursorValueInput = {
  equal?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  lessThanOrEqual?: InputMaybe<Scalars['Int']>;
  moreThanOrEqual?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition" */
export type MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition = {
  __typename?: 'messageDeliveryCustomerSegmentQuestionnaireAnswerCondition';
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegment: MessageDeliveryCustomerSegment;
  messageDeliveryCustomerSegmentId: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatus: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusType;
  messageDeliveryQuestionnaireAnswerStatus: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition". All fields are
 * combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
  messageDeliveryCustomerSegmentId?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatus?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeBoolExp>;
  messageDeliveryQuestionnaireAnswerStatus?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnumComparisonExp>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition". */
export type MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentOrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatus?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeOrderBy>;
  messageDeliveryQuestionnaireAnswerStatus?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition" */
export enum MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryCustomerSegmentId = 'messageDeliveryCustomerSegmentId',
  /** column name */
  MessageDeliveryQuestionnaireAnswerStatus = 'messageDeliveryQuestionnaireAnswerStatus'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition" */
export type MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentId?: InputMaybe<Scalars['uuid']>;
  messageDeliveryQuestionnaireAnswerStatus?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum>;
};

/** columns and relationships of "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType" */
export type MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusType = {
  __typename?: 'messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType';
  value: Scalars['String'];
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType". All fields are
 * combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum {
  Answered = 'Answered',
  Unanswered = 'Unanswered'
}

/**
 * Boolean expression to compare columns of type
 * "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_enum". All fields
 * are combined with logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnumComparisonExp = {
  _eq?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum>;
  _in?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum>;
  _nin?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum>>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType". */
export type MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType" */
export enum MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType" */
export type MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "messageDeliveryCustomerSegmentShopCondition" */
export type MessageDeliveryCustomerSegmentShopCondition = {
  __typename?: 'messageDeliveryCustomerSegmentShopCondition';
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegment: MessageDeliveryCustomerSegment;
  messageDeliveryCustomerSegmentId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "messageDeliveryCustomerSegmentShopCondition" */
export type MessageDeliveryCustomerSegmentShopConditionAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MessageDeliveryCustomerSegmentShopConditionMaxOrderBy>;
  min?: InputMaybe<MessageDeliveryCustomerSegmentShopConditionMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentShopCondition". All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentShopConditionBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentShopConditionBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentShopConditionBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentShopConditionBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
  messageDeliveryCustomerSegmentId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "messageDeliveryCustomerSegmentShopCondition" */
export type MessageDeliveryCustomerSegmentShopConditionMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "messageDeliveryCustomerSegmentShopCondition" */
export type MessageDeliveryCustomerSegmentShopConditionMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentShopCondition". */
export type MessageDeliveryCustomerSegmentShopConditionOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentOrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentShopCondition" */
export enum MessageDeliveryCustomerSegmentShopConditionSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryCustomerSegmentId = 'messageDeliveryCustomerSegmentId',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegmentShopCondition" */
export type MessageDeliveryCustomerSegmentShopConditionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentShopConditionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentShopConditionStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "messageDeliveryCustomerSegmentUnvisitedDayCountCondition" */
export type MessageDeliveryCustomerSegmentUnvisitedDayCountCondition = {
  __typename?: 'messageDeliveryCustomerSegmentUnvisitedDayCountCondition';
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegment: MessageDeliveryCustomerSegment;
  messageDeliveryCustomerSegmentId: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegmentNumericCondition: MessageDeliveryCustomerSegmentNumericCondition;
  messageDeliveryCustomerSegmentNumericConditionId: Scalars['uuid'];
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentUnvisitedDayCountCondition". All fields are
 * combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentUnvisitedDayCountConditionBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
  messageDeliveryCustomerSegmentId?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegmentNumericCondition?: InputMaybe<MessageDeliveryCustomerSegmentNumericConditionBoolExp>;
  messageDeliveryCustomerSegmentNumericConditionId?: InputMaybe<UuidComparisonExp>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentUnvisitedDayCountCondition". */
export type MessageDeliveryCustomerSegmentUnvisitedDayCountConditionOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentOrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentNumericCondition?: InputMaybe<MessageDeliveryCustomerSegmentNumericConditionOrderBy>;
  messageDeliveryCustomerSegmentNumericConditionId?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentUnvisitedDayCountCondition" */
export enum MessageDeliveryCustomerSegmentUnvisitedDayCountConditionSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryCustomerSegmentId = 'messageDeliveryCustomerSegmentId',
  /** column name */
  MessageDeliveryCustomerSegmentNumericConditionId = 'messageDeliveryCustomerSegmentNumericConditionId'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegmentUnvisitedDayCountCondition" */
export type MessageDeliveryCustomerSegmentUnvisitedDayCountConditionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentUnvisitedDayCountConditionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentUnvisitedDayCountConditionStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentId?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentNumericConditionId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "messageDeliveryCustomerSegmentVisitedDayCountCondition" */
export type MessageDeliveryCustomerSegmentVisitedDayCountCondition = {
  __typename?: 'messageDeliveryCustomerSegmentVisitedDayCountCondition';
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegment: MessageDeliveryCustomerSegment;
  messageDeliveryCustomerSegmentId: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryCustomerSegmentNumericCondition: MessageDeliveryCustomerSegmentNumericCondition;
  messageDeliveryCustomerSegmentNumericConditionId: Scalars['uuid'];
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegmentVisitedDayCountCondition". All fields are
 * combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentVisitedDayCountConditionBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentVisitedDayCountConditionBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentVisitedDayCountConditionBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentVisitedDayCountConditionBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
  messageDeliveryCustomerSegmentId?: InputMaybe<UuidComparisonExp>;
  messageDeliveryCustomerSegmentNumericCondition?: InputMaybe<MessageDeliveryCustomerSegmentNumericConditionBoolExp>;
  messageDeliveryCustomerSegmentNumericConditionId?: InputMaybe<UuidComparisonExp>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegmentVisitedDayCountCondition". */
export type MessageDeliveryCustomerSegmentVisitedDayCountConditionOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegment?: InputMaybe<MessageDeliveryCustomerSegmentOrderBy>;
  messageDeliveryCustomerSegmentId?: InputMaybe<OrderBy>;
  messageDeliveryCustomerSegmentNumericCondition?: InputMaybe<MessageDeliveryCustomerSegmentNumericConditionOrderBy>;
  messageDeliveryCustomerSegmentNumericConditionId?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegmentVisitedDayCountCondition" */
export enum MessageDeliveryCustomerSegmentVisitedDayCountConditionSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryCustomerSegmentId = 'messageDeliveryCustomerSegmentId',
  /** column name */
  MessageDeliveryCustomerSegmentNumericConditionId = 'messageDeliveryCustomerSegmentNumericConditionId'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegmentVisitedDayCountCondition" */
export type MessageDeliveryCustomerSegmentVisitedDayCountConditionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentVisitedDayCountConditionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentVisitedDayCountConditionStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentId?: InputMaybe<Scalars['uuid']>;
  messageDeliveryCustomerSegmentNumericConditionId?: InputMaybe<Scalars['uuid']>;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryCustomerSegment". All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryCustomerSegmentBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryCustomerSegmentBoolExp>>;
  _not?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryCustomerSegmentBoolExp>>;
  conditions?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDelivery?: InputMaybe<MessageDeliveryBoolExp>;
  messageDeliveryCustomerSegmentBusinessOperationHoursConditions?: InputMaybe<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionBoolExp>;
  messageDeliveryCustomerSegmentDayOfWeekConditions?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekConditionBoolExp>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: InputMaybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionBoolExp>;
  messageDeliveryCustomerSegmentGenderCondition?: InputMaybe<MessageDeliveryCustomerSegmentGenderConditionBoolExp>;
  messageDeliveryCustomerSegmentMenuConditions?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionBoolExp>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionBoolExp>;
  messageDeliveryCustomerSegmentShopConditions?: InputMaybe<MessageDeliveryCustomerSegmentShopConditionBoolExp>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: InputMaybe<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionBoolExp>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition?: InputMaybe<MessageDeliveryCustomerSegmentVisitedDayCountConditionBoolExp>;
};

/** Ordering options when selecting data from "messageDeliveryCustomerSegment". */
export type MessageDeliveryCustomerSegmentOrderBy = {
  conditions?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDelivery?: InputMaybe<MessageDeliveryOrderBy>;
  messageDeliveryCustomerSegmentBusinessOperationHoursConditions_aggregate?: InputMaybe<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionAggregateOrderBy>;
  messageDeliveryCustomerSegmentDayOfWeekConditions_aggregate?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekConditionAggregateOrderBy>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: InputMaybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionOrderBy>;
  messageDeliveryCustomerSegmentGenderCondition?: InputMaybe<MessageDeliveryCustomerSegmentGenderConditionOrderBy>;
  messageDeliveryCustomerSegmentMenuConditions_aggregate?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionAggregateOrderBy>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionOrderBy>;
  messageDeliveryCustomerSegmentShopConditions_aggregate?: InputMaybe<MessageDeliveryCustomerSegmentShopConditionAggregateOrderBy>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: InputMaybe<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionOrderBy>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition?: InputMaybe<MessageDeliveryCustomerSegmentVisitedDayCountConditionOrderBy>;
};

/** select columns of table "messageDeliveryCustomerSegment" */
export enum MessageDeliveryCustomerSegmentSelectColumn {
  /** column name */
  Conditions = 'conditions',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "messageDeliveryCustomerSegment" */
export type MessageDeliveryCustomerSegmentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryCustomerSegmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryCustomerSegmentStreamCursorValueInput = {
  conditions?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
};

export enum MessageDeliveryDayOfWeekTypeType {
  Fri = 'Fri',
  Holiday = 'Holiday',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed'
}

export enum MessageDeliveryGenderType {
  Female = 'Female',
  Male = 'Male'
}

/** columns and relationships of "messageDeliveryJob" */
export type MessageDeliveryJob = {
  __typename?: 'messageDeliveryJob';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  executedAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  messageDelivery: MessageDelivery;
  messageDeliveryId: Scalars['uuid'];
  /** An array relationship */
  messageDeliveryShopJobs: Array<MessageDeliveryShopJob>;
  succeededLineMessagingCount: Scalars['Int'];
  targetLineMessagingCount: Scalars['Int'];
};


/** columns and relationships of "messageDeliveryJob" */
export type MessageDeliveryJobMessageDeliveryShopJobsArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryShopJobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryShopJobOrderBy>>;
  where?: InputMaybe<MessageDeliveryShopJobBoolExp>;
};

/** order by aggregate values of table "messageDeliveryJob" */
export type MessageDeliveryJobAggregateOrderBy = {
  avg?: InputMaybe<MessageDeliveryJobAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MessageDeliveryJobMaxOrderBy>;
  min?: InputMaybe<MessageDeliveryJobMinOrderBy>;
  stddev?: InputMaybe<MessageDeliveryJobStddevOrderBy>;
  stddev_pop?: InputMaybe<MessageDeliveryJobStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MessageDeliveryJobStddevSampOrderBy>;
  sum?: InputMaybe<MessageDeliveryJobSumOrderBy>;
  var_pop?: InputMaybe<MessageDeliveryJobVarPopOrderBy>;
  var_samp?: InputMaybe<MessageDeliveryJobVarSampOrderBy>;
  variance?: InputMaybe<MessageDeliveryJobVarianceOrderBy>;
};

/** order by avg() on columns of table "messageDeliveryJob" */
export type MessageDeliveryJobAvgOrderBy = {
  succeededLineMessagingCount?: InputMaybe<OrderBy>;
  targetLineMessagingCount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "messageDeliveryJob". All fields are combined with a logical 'AND'. */
export type MessageDeliveryJobBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryJobBoolExp>>;
  _not?: InputMaybe<MessageDeliveryJobBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryJobBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  executedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDelivery?: InputMaybe<MessageDeliveryBoolExp>;
  messageDeliveryId?: InputMaybe<UuidComparisonExp>;
  messageDeliveryShopJobs?: InputMaybe<MessageDeliveryShopJobBoolExp>;
  succeededLineMessagingCount?: InputMaybe<IntComparisonExp>;
  targetLineMessagingCount?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "messageDeliveryJob" */
export type MessageDeliveryJobMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  executedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryId?: InputMaybe<OrderBy>;
  succeededLineMessagingCount?: InputMaybe<OrderBy>;
  targetLineMessagingCount?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "messageDeliveryJob" */
export type MessageDeliveryJobMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  executedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryId?: InputMaybe<OrderBy>;
  succeededLineMessagingCount?: InputMaybe<OrderBy>;
  targetLineMessagingCount?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "messageDeliveryJob". */
export type MessageDeliveryJobOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  executedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDelivery?: InputMaybe<MessageDeliveryOrderBy>;
  messageDeliveryId?: InputMaybe<OrderBy>;
  messageDeliveryShopJobs_aggregate?: InputMaybe<MessageDeliveryShopJobAggregateOrderBy>;
  succeededLineMessagingCount?: InputMaybe<OrderBy>;
  targetLineMessagingCount?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryJob" */
export enum MessageDeliveryJobSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  ExecutedAt = 'executedAt',
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryId = 'messageDeliveryId',
  /** column name */
  SucceededLineMessagingCount = 'succeededLineMessagingCount',
  /** column name */
  TargetLineMessagingCount = 'targetLineMessagingCount'
}

/** order by stddev() on columns of table "messageDeliveryJob" */
export type MessageDeliveryJobStddevOrderBy = {
  succeededLineMessagingCount?: InputMaybe<OrderBy>;
  targetLineMessagingCount?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "messageDeliveryJob" */
export type MessageDeliveryJobStddevPopOrderBy = {
  succeededLineMessagingCount?: InputMaybe<OrderBy>;
  targetLineMessagingCount?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "messageDeliveryJob" */
export type MessageDeliveryJobStddevSampOrderBy = {
  succeededLineMessagingCount?: InputMaybe<OrderBy>;
  targetLineMessagingCount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "messageDeliveryJob" */
export type MessageDeliveryJobStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryJobStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryJobStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  executedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryId?: InputMaybe<Scalars['uuid']>;
  succeededLineMessagingCount?: InputMaybe<Scalars['Int']>;
  targetLineMessagingCount?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "messageDeliveryJob" */
export type MessageDeliveryJobSumOrderBy = {
  succeededLineMessagingCount?: InputMaybe<OrderBy>;
  targetLineMessagingCount?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "messageDeliveryJob" */
export type MessageDeliveryJobVarPopOrderBy = {
  succeededLineMessagingCount?: InputMaybe<OrderBy>;
  targetLineMessagingCount?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "messageDeliveryJob" */
export type MessageDeliveryJobVarSampOrderBy = {
  succeededLineMessagingCount?: InputMaybe<OrderBy>;
  targetLineMessagingCount?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "messageDeliveryJob" */
export type MessageDeliveryJobVarianceOrderBy = {
  succeededLineMessagingCount?: InputMaybe<OrderBy>;
  targetLineMessagingCount?: InputMaybe<OrderBy>;
};

/** columns and relationships of "messageDeliveryMessage" */
export type MessageDeliveryMessage = {
  __typename?: 'messageDeliveryMessage';
  /** An object relationship */
  couponTypeMeta?: Maybe<MessageDeliveryMessageCouponTypeMeta>;
  couponTypeMetaId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  imageTypeMeta?: Maybe<MessageDeliveryMessageImageTypeMeta>;
  imageTypeMetaId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  messageDelivery: MessageDelivery;
  messageDeliveryId: Scalars['uuid'];
  position: Scalars['Int'];
  /** An object relationship */
  questionnaireTypeMeta?: Maybe<MessageDeliveryMessageQuestionnaireTypeMeta>;
  questionnaireTypeMetaId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  textTypeMeta?: Maybe<MessageDeliveryMessageTextTypeMeta>;
  textTypeMetaId?: Maybe<Scalars['uuid']>;
  type: MessageDeliveryMessageTypeEnum;
};

/** columns and relationships of "messageDeliveryMessageCouponTypeMeta" */
export type MessageDeliveryMessageCouponTypeMeta = {
  __typename?: 'messageDeliveryMessageCouponTypeMeta';
  /** An object relationship */
  coupon: Coupon;
  couponId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryMessage?: Maybe<MessageDeliveryMessage>;
};

/** order by aggregate values of table "messageDeliveryMessageCouponTypeMeta" */
export type MessageDeliveryMessageCouponTypeMetaAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MessageDeliveryMessageCouponTypeMetaMaxOrderBy>;
  min?: InputMaybe<MessageDeliveryMessageCouponTypeMetaMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryMessageCouponTypeMeta". All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryMessageCouponTypeMetaBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryMessageCouponTypeMetaBoolExp>>;
  _not?: InputMaybe<MessageDeliveryMessageCouponTypeMetaBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryMessageCouponTypeMetaBoolExp>>;
  coupon?: InputMaybe<CouponBoolExp>;
  couponId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryMessage?: InputMaybe<MessageDeliveryMessageBoolExp>;
};

/** order by max() on columns of table "messageDeliveryMessageCouponTypeMeta" */
export type MessageDeliveryMessageCouponTypeMetaMaxOrderBy = {
  couponId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "messageDeliveryMessageCouponTypeMeta" */
export type MessageDeliveryMessageCouponTypeMetaMinOrderBy = {
  couponId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "messageDeliveryMessageCouponTypeMeta". */
export type MessageDeliveryMessageCouponTypeMetaOrderBy = {
  coupon?: InputMaybe<CouponOrderBy>;
  couponId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryMessage?: InputMaybe<MessageDeliveryMessageOrderBy>;
};

/** select columns of table "messageDeliveryMessageCouponTypeMeta" */
export enum MessageDeliveryMessageCouponTypeMetaSelectColumn {
  /** column name */
  CouponId = 'couponId',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "messageDeliveryMessageCouponTypeMeta" */
export type MessageDeliveryMessageCouponTypeMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryMessageCouponTypeMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryMessageCouponTypeMetaStreamCursorValueInput = {
  couponId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "messageDeliveryMessageImageTypeMeta" */
export type MessageDeliveryMessageImageTypeMeta = {
  __typename?: 'messageDeliveryMessageImageTypeMeta';
  id: Scalars['uuid'];
  imageUrl: Scalars['String'];
  /** An object relationship */
  messageDeliveryMessage?: Maybe<MessageDeliveryMessage>;
  url?: Maybe<Scalars['String']>;
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryMessageImageTypeMeta". All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryMessageImageTypeMetaBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryMessageImageTypeMetaBoolExp>>;
  _not?: InputMaybe<MessageDeliveryMessageImageTypeMetaBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryMessageImageTypeMetaBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  messageDeliveryMessage?: InputMaybe<MessageDeliveryMessageBoolExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "messageDeliveryMessageImageTypeMeta". */
export type MessageDeliveryMessageImageTypeMetaOrderBy = {
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  messageDeliveryMessage?: InputMaybe<MessageDeliveryMessageOrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryMessageImageTypeMeta" */
export enum MessageDeliveryMessageImageTypeMetaSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  Url = 'url'
}

/** Streaming cursor of the table "messageDeliveryMessageImageTypeMeta" */
export type MessageDeliveryMessageImageTypeMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryMessageImageTypeMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryMessageImageTypeMetaStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "messageDeliveryMessageQuestionnaireTypeMeta" */
export type MessageDeliveryMessageQuestionnaireTypeMeta = {
  __typename?: 'messageDeliveryMessageQuestionnaireTypeMeta';
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryMessage?: Maybe<MessageDeliveryMessage>;
  /** An object relationship */
  questionnaireConfig: QuestionnaireConfig;
  questionnaireConfigId: Scalars['uuid'];
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryMessageQuestionnaireTypeMeta". All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryMessageQuestionnaireTypeMetaBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryMessageQuestionnaireTypeMetaBoolExp>>;
  _not?: InputMaybe<MessageDeliveryMessageQuestionnaireTypeMetaBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryMessageQuestionnaireTypeMetaBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryMessage?: InputMaybe<MessageDeliveryMessageBoolExp>;
  questionnaireConfig?: InputMaybe<QuestionnaireConfigBoolExp>;
  questionnaireConfigId?: InputMaybe<UuidComparisonExp>;
};

/** Ordering options when selecting data from "messageDeliveryMessageQuestionnaireTypeMeta". */
export type MessageDeliveryMessageQuestionnaireTypeMetaOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryMessage?: InputMaybe<MessageDeliveryMessageOrderBy>;
  questionnaireConfig?: InputMaybe<QuestionnaireConfigOrderBy>;
  questionnaireConfigId?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryMessageQuestionnaireTypeMeta" */
export enum MessageDeliveryMessageQuestionnaireTypeMetaSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireConfigId = 'questionnaireConfigId'
}

/** Streaming cursor of the table "messageDeliveryMessageQuestionnaireTypeMeta" */
export type MessageDeliveryMessageQuestionnaireTypeMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryMessageQuestionnaireTypeMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryMessageQuestionnaireTypeMetaStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  questionnaireConfigId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "messageDeliveryMessageTextTypeMeta" */
export type MessageDeliveryMessageTextTypeMeta = {
  __typename?: 'messageDeliveryMessageTextTypeMeta';
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryMessage?: Maybe<MessageDeliveryMessage>;
  text: Scalars['String'];
};

/**
 * Boolean expression to filter rows from the table
 * "messageDeliveryMessageTextTypeMeta". All fields are combined with a logical 'AND'.
 */
export type MessageDeliveryMessageTextTypeMetaBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryMessageTextTypeMetaBoolExp>>;
  _not?: InputMaybe<MessageDeliveryMessageTextTypeMetaBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryMessageTextTypeMetaBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryMessage?: InputMaybe<MessageDeliveryMessageBoolExp>;
  text?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "messageDeliveryMessageTextTypeMeta". */
export type MessageDeliveryMessageTextTypeMetaOrderBy = {
  id?: InputMaybe<OrderBy>;
  messageDeliveryMessage?: InputMaybe<MessageDeliveryMessageOrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryMessageTextTypeMeta" */
export enum MessageDeliveryMessageTextTypeMetaSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text'
}

/** Streaming cursor of the table "messageDeliveryMessageTextTypeMeta" */
export type MessageDeliveryMessageTextTypeMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryMessageTextTypeMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryMessageTextTypeMetaStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum MessageDeliveryMessageTypeEnum {
  Coupon = 'coupon',
  Image = 'image',
  Questionnaire = 'questionnaire',
  Text = 'text'
}

/** Boolean expression to compare columns of type "messageDeliveryMessageType_enum". All fields are combined with logical 'AND'. */
export type MessageDeliveryMessageTypeEnumComparisonExp = {
  _eq?: InputMaybe<MessageDeliveryMessageTypeEnum>;
  _in?: InputMaybe<Array<MessageDeliveryMessageTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MessageDeliveryMessageTypeEnum>;
  _nin?: InputMaybe<Array<MessageDeliveryMessageTypeEnum>>;
};

/** order by aggregate values of table "messageDeliveryMessage" */
export type MessageDeliveryMessageAggregateOrderBy = {
  avg?: InputMaybe<MessageDeliveryMessageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MessageDeliveryMessageMaxOrderBy>;
  min?: InputMaybe<MessageDeliveryMessageMinOrderBy>;
  stddev?: InputMaybe<MessageDeliveryMessageStddevOrderBy>;
  stddev_pop?: InputMaybe<MessageDeliveryMessageStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MessageDeliveryMessageStddevSampOrderBy>;
  sum?: InputMaybe<MessageDeliveryMessageSumOrderBy>;
  var_pop?: InputMaybe<MessageDeliveryMessageVarPopOrderBy>;
  var_samp?: InputMaybe<MessageDeliveryMessageVarSampOrderBy>;
  variance?: InputMaybe<MessageDeliveryMessageVarianceOrderBy>;
};

/** order by avg() on columns of table "messageDeliveryMessage" */
export type MessageDeliveryMessageAvgOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "messageDeliveryMessage". All fields are combined with a logical 'AND'. */
export type MessageDeliveryMessageBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryMessageBoolExp>>;
  _not?: InputMaybe<MessageDeliveryMessageBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryMessageBoolExp>>;
  couponTypeMeta?: InputMaybe<MessageDeliveryMessageCouponTypeMetaBoolExp>;
  couponTypeMetaId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageTypeMeta?: InputMaybe<MessageDeliveryMessageImageTypeMetaBoolExp>;
  imageTypeMetaId?: InputMaybe<UuidComparisonExp>;
  messageDelivery?: InputMaybe<MessageDeliveryBoolExp>;
  messageDeliveryId?: InputMaybe<UuidComparisonExp>;
  position?: InputMaybe<IntComparisonExp>;
  questionnaireTypeMeta?: InputMaybe<MessageDeliveryMessageQuestionnaireTypeMetaBoolExp>;
  questionnaireTypeMetaId?: InputMaybe<UuidComparisonExp>;
  textTypeMeta?: InputMaybe<MessageDeliveryMessageTextTypeMetaBoolExp>;
  textTypeMetaId?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<MessageDeliveryMessageTypeEnumComparisonExp>;
};

/** order by max() on columns of table "messageDeliveryMessage" */
export type MessageDeliveryMessageMaxOrderBy = {
  couponTypeMetaId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageTypeMetaId?: InputMaybe<OrderBy>;
  messageDeliveryId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  questionnaireTypeMetaId?: InputMaybe<OrderBy>;
  textTypeMetaId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "messageDeliveryMessage" */
export type MessageDeliveryMessageMinOrderBy = {
  couponTypeMetaId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageTypeMetaId?: InputMaybe<OrderBy>;
  messageDeliveryId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  questionnaireTypeMetaId?: InputMaybe<OrderBy>;
  textTypeMetaId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "messageDeliveryMessage". */
export type MessageDeliveryMessageOrderBy = {
  couponTypeMeta?: InputMaybe<MessageDeliveryMessageCouponTypeMetaOrderBy>;
  couponTypeMetaId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageTypeMeta?: InputMaybe<MessageDeliveryMessageImageTypeMetaOrderBy>;
  imageTypeMetaId?: InputMaybe<OrderBy>;
  messageDelivery?: InputMaybe<MessageDeliveryOrderBy>;
  messageDeliveryId?: InputMaybe<OrderBy>;
  position?: InputMaybe<OrderBy>;
  questionnaireTypeMeta?: InputMaybe<MessageDeliveryMessageQuestionnaireTypeMetaOrderBy>;
  questionnaireTypeMetaId?: InputMaybe<OrderBy>;
  textTypeMeta?: InputMaybe<MessageDeliveryMessageTextTypeMetaOrderBy>;
  textTypeMetaId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryMessage" */
export enum MessageDeliveryMessageSelectColumn {
  /** column name */
  CouponTypeMetaId = 'couponTypeMetaId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageTypeMetaId = 'imageTypeMetaId',
  /** column name */
  MessageDeliveryId = 'messageDeliveryId',
  /** column name */
  Position = 'position',
  /** column name */
  QuestionnaireTypeMetaId = 'questionnaireTypeMetaId',
  /** column name */
  TextTypeMetaId = 'textTypeMetaId',
  /** column name */
  Type = 'type'
}

/** order by stddev() on columns of table "messageDeliveryMessage" */
export type MessageDeliveryMessageStddevOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "messageDeliveryMessage" */
export type MessageDeliveryMessageStddevPopOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "messageDeliveryMessage" */
export type MessageDeliveryMessageStddevSampOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "messageDeliveryMessage" */
export type MessageDeliveryMessageStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryMessageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryMessageStreamCursorValueInput = {
  couponTypeMetaId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageTypeMetaId?: InputMaybe<Scalars['uuid']>;
  messageDeliveryId?: InputMaybe<Scalars['uuid']>;
  position?: InputMaybe<Scalars['Int']>;
  questionnaireTypeMetaId?: InputMaybe<Scalars['uuid']>;
  textTypeMetaId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<MessageDeliveryMessageTypeEnum>;
};

/** order by sum() on columns of table "messageDeliveryMessage" */
export type MessageDeliveryMessageSumOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "messageDeliveryMessage" */
export type MessageDeliveryMessageVarPopOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "messageDeliveryMessage" */
export type MessageDeliveryMessageVarSampOrderBy = {
  position?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "messageDeliveryMessage" */
export type MessageDeliveryMessageVarianceOrderBy = {
  position?: InputMaybe<OrderBy>;
};

export enum MessageDeliveryQuestionnaireAnswerStatusType {
  Answered = 'Answered',
  Unanswered = 'Unanswered'
}

/** columns and relationships of "messageDeliveryShopJob" */
export type MessageDeliveryShopJob = {
  __typename?: 'messageDeliveryShopJob';
  errorType?: Maybe<MessageDeliveryShopJobErrorTypeEnum>;
  executedAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  messageDeliveryJob: MessageDeliveryJob;
  messageDeliveryJobId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  succeededLineMessagingPerShopCount: Scalars['Int'];
  targetLineMessagingPerShopCount: Scalars['Int'];
};

export enum MessageDeliveryShopJobErrorTypeEnum {
  InvalidLineConfigError = 'invalidLineConfigError',
  ReachedMonthlyLimitLineError = 'reachedMonthlyLimitLineError',
  TargetCustomerNotFoundError = 'targetCustomerNotFoundError',
  TooManyTargetCustomerError = 'tooManyTargetCustomerError',
  UnexpectedLineError = 'unexpectedLineError',
  UnexpectedServerError = 'unexpectedServerError'
}

/**
 * Boolean expression to compare columns of type
 * "messageDeliveryShopJobErrorType_enum". All fields are combined with logical 'AND'.
 */
export type MessageDeliveryShopJobErrorTypeEnumComparisonExp = {
  _eq?: InputMaybe<MessageDeliveryShopJobErrorTypeEnum>;
  _in?: InputMaybe<Array<MessageDeliveryShopJobErrorTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MessageDeliveryShopJobErrorTypeEnum>;
  _nin?: InputMaybe<Array<MessageDeliveryShopJobErrorTypeEnum>>;
};

/** order by aggregate values of table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobAggregateOrderBy = {
  avg?: InputMaybe<MessageDeliveryShopJobAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MessageDeliveryShopJobMaxOrderBy>;
  min?: InputMaybe<MessageDeliveryShopJobMinOrderBy>;
  stddev?: InputMaybe<MessageDeliveryShopJobStddevOrderBy>;
  stddev_pop?: InputMaybe<MessageDeliveryShopJobStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MessageDeliveryShopJobStddevSampOrderBy>;
  sum?: InputMaybe<MessageDeliveryShopJobSumOrderBy>;
  var_pop?: InputMaybe<MessageDeliveryShopJobVarPopOrderBy>;
  var_samp?: InputMaybe<MessageDeliveryShopJobVarSampOrderBy>;
  variance?: InputMaybe<MessageDeliveryShopJobVarianceOrderBy>;
};

/** order by avg() on columns of table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobAvgOrderBy = {
  succeededLineMessagingPerShopCount?: InputMaybe<OrderBy>;
  targetLineMessagingPerShopCount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "messageDeliveryShopJob". All fields are combined with a logical 'AND'. */
export type MessageDeliveryShopJobBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryShopJobBoolExp>>;
  _not?: InputMaybe<MessageDeliveryShopJobBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryShopJobBoolExp>>;
  errorType?: InputMaybe<MessageDeliveryShopJobErrorTypeEnumComparisonExp>;
  executedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  messageDeliveryJob?: InputMaybe<MessageDeliveryJobBoolExp>;
  messageDeliveryJobId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  succeededLineMessagingPerShopCount?: InputMaybe<IntComparisonExp>;
  targetLineMessagingPerShopCount?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobMaxOrderBy = {
  executedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryJobId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  succeededLineMessagingPerShopCount?: InputMaybe<OrderBy>;
  targetLineMessagingPerShopCount?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobMinOrderBy = {
  executedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryJobId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  succeededLineMessagingPerShopCount?: InputMaybe<OrderBy>;
  targetLineMessagingPerShopCount?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "messageDeliveryShopJob". */
export type MessageDeliveryShopJobOrderBy = {
  errorType?: InputMaybe<OrderBy>;
  executedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageDeliveryJob?: InputMaybe<MessageDeliveryJobOrderBy>;
  messageDeliveryJobId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  succeededLineMessagingPerShopCount?: InputMaybe<OrderBy>;
  targetLineMessagingPerShopCount?: InputMaybe<OrderBy>;
};

/** select columns of table "messageDeliveryShopJob" */
export enum MessageDeliveryShopJobSelectColumn {
  /** column name */
  ErrorType = 'errorType',
  /** column name */
  ExecutedAt = 'executedAt',
  /** column name */
  Id = 'id',
  /** column name */
  MessageDeliveryJobId = 'messageDeliveryJobId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  SucceededLineMessagingPerShopCount = 'succeededLineMessagingPerShopCount',
  /** column name */
  TargetLineMessagingPerShopCount = 'targetLineMessagingPerShopCount'
}

/** order by stddev() on columns of table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobStddevOrderBy = {
  succeededLineMessagingPerShopCount?: InputMaybe<OrderBy>;
  targetLineMessagingPerShopCount?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobStddevPopOrderBy = {
  succeededLineMessagingPerShopCount?: InputMaybe<OrderBy>;
  targetLineMessagingPerShopCount?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobStddevSampOrderBy = {
  succeededLineMessagingPerShopCount?: InputMaybe<OrderBy>;
  targetLineMessagingPerShopCount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryShopJobStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryShopJobStreamCursorValueInput = {
  errorType?: InputMaybe<MessageDeliveryShopJobErrorTypeEnum>;
  executedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  messageDeliveryJobId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  succeededLineMessagingPerShopCount?: InputMaybe<Scalars['Int']>;
  targetLineMessagingPerShopCount?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobSumOrderBy = {
  succeededLineMessagingPerShopCount?: InputMaybe<OrderBy>;
  targetLineMessagingPerShopCount?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobVarPopOrderBy = {
  succeededLineMessagingPerShopCount?: InputMaybe<OrderBy>;
  targetLineMessagingPerShopCount?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobVarSampOrderBy = {
  succeededLineMessagingPerShopCount?: InputMaybe<OrderBy>;
  targetLineMessagingPerShopCount?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "messageDeliveryShopJob" */
export type MessageDeliveryShopJobVarianceOrderBy = {
  succeededLineMessagingPerShopCount?: InputMaybe<OrderBy>;
  targetLineMessagingPerShopCount?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "messageDelivery" */
export type MessageDeliveryAggregateOrderBy = {
  avg?: InputMaybe<MessageDeliveryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MessageDeliveryMaxOrderBy>;
  min?: InputMaybe<MessageDeliveryMinOrderBy>;
  stddev?: InputMaybe<MessageDeliveryStddevOrderBy>;
  stddev_pop?: InputMaybe<MessageDeliveryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MessageDeliveryStddevSampOrderBy>;
  sum?: InputMaybe<MessageDeliverySumOrderBy>;
  var_pop?: InputMaybe<MessageDeliveryVarPopOrderBy>;
  var_samp?: InputMaybe<MessageDeliveryVarSampOrderBy>;
  variance?: InputMaybe<MessageDeliveryVarianceOrderBy>;
};

/** order by avg() on columns of table "messageDelivery" */
export type MessageDeliveryAvgOrderBy = {
  targetCount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "messageDelivery". All fields are combined with a logical 'AND'. */
export type MessageDeliveryBoolExp = {
  _and?: InputMaybe<Array<MessageDeliveryBoolExp>>;
  _not?: InputMaybe<MessageDeliveryBoolExp>;
  _or?: InputMaybe<Array<MessageDeliveryBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  couponAppliedEvents?: InputMaybe<CouponAppliedEventBoolExp>;
  couponAppliedEvents_aggregate?: InputMaybe<CouponAppliedEventAggregateBoolExp>;
  customerList?: InputMaybe<MessageDeliveryCustomerListBoolExp>;
  customerListId?: InputMaybe<UuidComparisonExp>;
  customerQuestionnaires?: InputMaybe<CustomerQuestionnaireBoolExp>;
  customerSegment?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
  customerSegmentId?: InputMaybe<UuidComparisonExp>;
  deliverAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDraft?: InputMaybe<BooleanComparisonExp>;
  isSuspended?: InputMaybe<BooleanComparisonExp>;
  messageDeliveryJobs?: InputMaybe<MessageDeliveryJobBoolExp>;
  messageDeliveryMessages?: InputMaybe<MessageDeliveryMessageBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  repeatDeliveryTime?: InputMaybe<TimetzComparisonExp>;
  scope?: InputMaybe<StringComparisonExp>;
  targetCount?: InputMaybe<IntComparisonExp>;
  targetCountUpdatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** input type for incrementing numeric columns in table "messageDelivery" */
export type MessageDeliveryIncInput = {
  targetCount?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "messageDelivery" */
export type MessageDeliveryMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  customerListId?: InputMaybe<OrderBy>;
  customerSegmentId?: InputMaybe<OrderBy>;
  deliverAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  repeatDeliveryTime?: InputMaybe<OrderBy>;
  scope?: InputMaybe<OrderBy>;
  targetCount?: InputMaybe<OrderBy>;
  targetCountUpdatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "messageDelivery" */
export type MessageDeliveryMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  customerListId?: InputMaybe<OrderBy>;
  customerSegmentId?: InputMaybe<OrderBy>;
  deliverAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  repeatDeliveryTime?: InputMaybe<OrderBy>;
  scope?: InputMaybe<OrderBy>;
  targetCount?: InputMaybe<OrderBy>;
  targetCountUpdatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "messageDelivery" */
export type MessageDeliveryMutationResponse = {
  __typename?: 'messageDelivery_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MessageDelivery>;
};

/** Ordering options when selecting data from "messageDelivery". */
export type MessageDeliveryOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  couponAppliedEvents_aggregate?: InputMaybe<CouponAppliedEventAggregateOrderBy>;
  customerList?: InputMaybe<MessageDeliveryCustomerListOrderBy>;
  customerListId?: InputMaybe<OrderBy>;
  customerQuestionnaires_aggregate?: InputMaybe<CustomerQuestionnaireAggregateOrderBy>;
  customerSegment?: InputMaybe<MessageDeliveryCustomerSegmentOrderBy>;
  customerSegmentId?: InputMaybe<OrderBy>;
  deliverAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDraft?: InputMaybe<OrderBy>;
  isSuspended?: InputMaybe<OrderBy>;
  messageDeliveryJobs_aggregate?: InputMaybe<MessageDeliveryJobAggregateOrderBy>;
  messageDeliveryMessages_aggregate?: InputMaybe<MessageDeliveryMessageAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  repeatDeliveryTime?: InputMaybe<OrderBy>;
  scope?: InputMaybe<OrderBy>;
  targetCount?: InputMaybe<OrderBy>;
  targetCountUpdatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: messageDelivery */
export type MessageDeliveryPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "messageDelivery" */
export enum MessageDeliverySelectColumn {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CustomerListId = 'customerListId',
  /** column name */
  CustomerSegmentId = 'customerSegmentId',
  /** column name */
  DeliverAt = 'deliverAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsDraft = 'isDraft',
  /** column name */
  IsSuspended = 'isSuspended',
  /** column name */
  Name = 'name',
  /** column name */
  RepeatDeliveryTime = 'repeatDeliveryTime',
  /** column name */
  Scope = 'scope',
  /** column name */
  TargetCount = 'targetCount',
  /** column name */
  TargetCountUpdatedAt = 'targetCountUpdatedAt'
}

/** input type for updating data in table "messageDelivery" */
export type MessageDeliverySetInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  isSuspended?: InputMaybe<Scalars['Boolean']>;
  targetCount?: InputMaybe<Scalars['Int']>;
  targetCountUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "messageDelivery" */
export type MessageDeliveryStddevOrderBy = {
  targetCount?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "messageDelivery" */
export type MessageDeliveryStddevPopOrderBy = {
  targetCount?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "messageDelivery" */
export type MessageDeliveryStddevSampOrderBy = {
  targetCount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "messageDelivery" */
export type MessageDeliveryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MessageDeliveryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MessageDeliveryStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  customerListId?: InputMaybe<Scalars['uuid']>;
  customerSegmentId?: InputMaybe<Scalars['uuid']>;
  deliverAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  isSuspended?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  repeatDeliveryTime?: InputMaybe<Scalars['timetz']>;
  scope?: InputMaybe<Scalars['String']>;
  targetCount?: InputMaybe<Scalars['Int']>;
  targetCountUpdatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "messageDelivery" */
export type MessageDeliverySumOrderBy = {
  targetCount?: InputMaybe<OrderBy>;
};

export type MessageDeliveryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MessageDeliveryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MessageDeliverySetInput>;
  /** filter the rows which have to be updated */
  where: MessageDeliveryBoolExp;
};

/** order by var_pop() on columns of table "messageDelivery" */
export type MessageDeliveryVarPopOrderBy = {
  targetCount?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "messageDelivery" */
export type MessageDeliveryVarSampOrderBy = {
  targetCount?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "messageDelivery" */
export type MessageDeliveryVarianceOrderBy = {
  targetCount?: InputMaybe<OrderBy>;
};

/** columns and relationships of "monthlySalesBudget" */
export type MonthlySalesBudget = {
  __typename?: 'monthlySalesBudget';
  businessDate: Scalars['date'];
  /** An array relationship */
  dailySalesBudgets: Array<DailySalesBudget>;
  id: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  taxExcludedAmount: Scalars['Int'];
};


/** columns and relationships of "monthlySalesBudget" */
export type MonthlySalesBudgetDailySalesBudgetsArgs = {
  distinct_on?: InputMaybe<Array<DailySalesBudgetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DailySalesBudgetOrderBy>>;
  where?: InputMaybe<DailySalesBudgetBoolExp>;
};

/** Boolean expression to filter rows from the table "monthlySalesBudget". All fields are combined with a logical 'AND'. */
export type MonthlySalesBudgetBoolExp = {
  _and?: InputMaybe<Array<MonthlySalesBudgetBoolExp>>;
  _not?: InputMaybe<MonthlySalesBudgetBoolExp>;
  _or?: InputMaybe<Array<MonthlySalesBudgetBoolExp>>;
  businessDate?: InputMaybe<DateComparisonExp>;
  dailySalesBudgets?: InputMaybe<DailySalesBudgetBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  taxExcludedAmount?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "monthlySalesBudget" */
export enum MonthlySalesBudgetConstraint {
  /** unique or primary key constraint on columns "id" */
  MonthlySalesBudgetPkey = 'monthlySalesBudget_pkey',
  /** unique or primary key constraint on columns "businessDate", "shopId" */
  MonthlySalesBudgetShopIdBusinessDateKey = 'monthlySalesBudget_shopId_businessDate_key'
}

/** input type for incrementing numeric columns in table "monthlySalesBudget" */
export type MonthlySalesBudgetIncInput = {
  taxExcludedAmount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "monthlySalesBudget" */
export type MonthlySalesBudgetInsertInput = {
  businessDate: Scalars['date'];
  createdAt: Scalars['timestamptz'];
  dailySalesBudgets?: InputMaybe<DailySalesBudgetArrRelInsertInput>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  taxExcludedAmount: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
};

/** response of any mutation on the table "monthlySalesBudget" */
export type MonthlySalesBudgetMutationResponse = {
  __typename?: 'monthlySalesBudget_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MonthlySalesBudget>;
};

/** input type for inserting object relation for remote table "monthlySalesBudget" */
export type MonthlySalesBudgetObjRelInsertInput = {
  data: MonthlySalesBudgetInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MonthlySalesBudgetOnConflict>;
};

/** on_conflict condition type for table "monthlySalesBudget" */
export type MonthlySalesBudgetOnConflict = {
  constraint: MonthlySalesBudgetConstraint;
  update_columns?: Array<MonthlySalesBudgetUpdateColumn>;
  where?: InputMaybe<MonthlySalesBudgetBoolExp>;
};

/** Ordering options when selecting data from "monthlySalesBudget". */
export type MonthlySalesBudgetOrderBy = {
  businessDate?: InputMaybe<OrderBy>;
  dailySalesBudgets_aggregate?: InputMaybe<DailySalesBudgetAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: monthlySalesBudget */
export type MonthlySalesBudgetPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "monthlySalesBudget" */
export enum MonthlySalesBudgetSelectColumn {
  /** column name */
  BusinessDate = 'businessDate',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TaxExcludedAmount = 'taxExcludedAmount'
}

/** input type for updating data in table "monthlySalesBudget" */
export type MonthlySalesBudgetSetInput = {
  taxExcludedAmount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "monthlySalesBudget" */
export type MonthlySalesBudgetStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MonthlySalesBudgetStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MonthlySalesBudgetStreamCursorValueInput = {
  businessDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  taxExcludedAmount?: InputMaybe<Scalars['Int']>;
};

/** update columns of table "monthlySalesBudget" */
export enum MonthlySalesBudgetUpdateColumn {
  /** column name */
  TaxExcludedAmount = 'taxExcludedAmount',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type MonthlySalesBudgetUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MonthlySalesBudgetIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MonthlySalesBudgetSetInput>;
  /** filter the rows which have to be updated */
  where: MonthlySalesBudgetBoolExp;
};

/** columns and relationships of "multipleChoiceTypeQuestionAnswer" */
export type MultipleChoiceTypeQuestionAnswer = {
  __typename?: 'multipleChoiceTypeQuestionAnswer';
  id: Scalars['uuid'];
  /** An array relationship */
  multipleChoiceTypeQuestionChoiceAnswers: Array<MultipleChoiceTypeQuestionChoiceAnswer>;
  /** An object relationship */
  multipleChoiceTypeQuestionMeta: MultipleChoiceTypeQuestionMeta;
  multipleChoiceTypeQuestionMetaId: Scalars['uuid'];
  /** An object relationship */
  questionAnswer?: Maybe<QuestionAnswer>;
};


/** columns and relationships of "multipleChoiceTypeQuestionAnswer" */
export type MultipleChoiceTypeQuestionAnswerMultipleChoiceTypeQuestionChoiceAnswersArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceAnswerOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerBoolExp>;
};

/** order by aggregate values of table "multipleChoiceTypeQuestionAnswer" */
export type MultipleChoiceTypeQuestionAnswerAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MultipleChoiceTypeQuestionAnswerMaxOrderBy>;
  min?: InputMaybe<MultipleChoiceTypeQuestionAnswerMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "multipleChoiceTypeQuestionAnswer". All fields are combined with a logical 'AND'.
 */
export type MultipleChoiceTypeQuestionAnswerBoolExp = {
  _and?: InputMaybe<Array<MultipleChoiceTypeQuestionAnswerBoolExp>>;
  _not?: InputMaybe<MultipleChoiceTypeQuestionAnswerBoolExp>;
  _or?: InputMaybe<Array<MultipleChoiceTypeQuestionAnswerBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  multipleChoiceTypeQuestionChoiceAnswers?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerBoolExp>;
  multipleChoiceTypeQuestionMeta?: InputMaybe<MultipleChoiceTypeQuestionMetaBoolExp>;
  multipleChoiceTypeQuestionMetaId?: InputMaybe<UuidComparisonExp>;
  questionAnswer?: InputMaybe<QuestionAnswerBoolExp>;
};

/** order by max() on columns of table "multipleChoiceTypeQuestionAnswer" */
export type MultipleChoiceTypeQuestionAnswerMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  multipleChoiceTypeQuestionMetaId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "multipleChoiceTypeQuestionAnswer" */
export type MultipleChoiceTypeQuestionAnswerMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  multipleChoiceTypeQuestionMetaId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "multipleChoiceTypeQuestionAnswer". */
export type MultipleChoiceTypeQuestionAnswerOrderBy = {
  id?: InputMaybe<OrderBy>;
  multipleChoiceTypeQuestionChoiceAnswers_aggregate?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerAggregateOrderBy>;
  multipleChoiceTypeQuestionMeta?: InputMaybe<MultipleChoiceTypeQuestionMetaOrderBy>;
  multipleChoiceTypeQuestionMetaId?: InputMaybe<OrderBy>;
  questionAnswer?: InputMaybe<QuestionAnswerOrderBy>;
};

/** select columns of table "multipleChoiceTypeQuestionAnswer" */
export enum MultipleChoiceTypeQuestionAnswerSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MultipleChoiceTypeQuestionMetaId = 'multipleChoiceTypeQuestionMetaId'
}

/** Streaming cursor of the table "multipleChoiceTypeQuestionAnswer" */
export type MultipleChoiceTypeQuestionAnswerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MultipleChoiceTypeQuestionAnswerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MultipleChoiceTypeQuestionAnswerStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  multipleChoiceTypeQuestionMetaId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "multipleChoiceTypeQuestionChoice" */
export type MultipleChoiceTypeQuestionChoice = {
  __typename?: 'multipleChoiceTypeQuestionChoice';
  /** An array relationship */
  multipleChoiceTypeQuestionChoiceAnswers: Array<MultipleChoiceTypeQuestionChoiceAnswer>;
  /** An object relationship */
  multipleChoiceTypeQuestionMeta: MultipleChoiceTypeQuestionMeta;
  name: Scalars['String'];
};


/** columns and relationships of "multipleChoiceTypeQuestionChoice" */
export type MultipleChoiceTypeQuestionChoiceMultipleChoiceTypeQuestionChoiceAnswersArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceAnswerOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerBoolExp>;
};

/** columns and relationships of "multipleChoiceTypeQuestionChoiceAnswer" */
export type MultipleChoiceTypeQuestionChoiceAnswer = {
  __typename?: 'multipleChoiceTypeQuestionChoiceAnswer';
  id: Scalars['uuid'];
  /** An object relationship */
  multipleChoiceTypeQuestionAnswer: MultipleChoiceTypeQuestionAnswer;
  /** An object relationship */
  questionChoice: MultipleChoiceTypeQuestionChoice;
  questionChoiceId: Scalars['uuid'];
};

/** order by aggregate values of table "multipleChoiceTypeQuestionChoiceAnswer" */
export type MultipleChoiceTypeQuestionChoiceAnswerAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerMaxOrderBy>;
  min?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "multipleChoiceTypeQuestionChoiceAnswer". All fields are combined with a logical 'AND'.
 */
export type MultipleChoiceTypeQuestionChoiceAnswerBoolExp = {
  _and?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceAnswerBoolExp>>;
  _not?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerBoolExp>;
  _or?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceAnswerBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  multipleChoiceTypeQuestionAnswer?: InputMaybe<MultipleChoiceTypeQuestionAnswerBoolExp>;
  questionChoice?: InputMaybe<MultipleChoiceTypeQuestionChoiceBoolExp>;
  questionChoiceId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "multipleChoiceTypeQuestionChoiceAnswer" */
export type MultipleChoiceTypeQuestionChoiceAnswerMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  questionChoiceId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "multipleChoiceTypeQuestionChoiceAnswer" */
export type MultipleChoiceTypeQuestionChoiceAnswerMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  questionChoiceId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "multipleChoiceTypeQuestionChoiceAnswer". */
export type MultipleChoiceTypeQuestionChoiceAnswerOrderBy = {
  id?: InputMaybe<OrderBy>;
  multipleChoiceTypeQuestionAnswer?: InputMaybe<MultipleChoiceTypeQuestionAnswerOrderBy>;
  questionChoice?: InputMaybe<MultipleChoiceTypeQuestionChoiceOrderBy>;
  questionChoiceId?: InputMaybe<OrderBy>;
};

/** select columns of table "multipleChoiceTypeQuestionChoiceAnswer" */
export enum MultipleChoiceTypeQuestionChoiceAnswerSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  QuestionChoiceId = 'questionChoiceId'
}

/** Streaming cursor of the table "multipleChoiceTypeQuestionChoiceAnswer" */
export type MultipleChoiceTypeQuestionChoiceAnswerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MultipleChoiceTypeQuestionChoiceAnswerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MultipleChoiceTypeQuestionChoiceAnswerStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  questionChoiceId?: InputMaybe<Scalars['uuid']>;
};

/** order by aggregate values of table "multipleChoiceTypeQuestionChoice" */
export type MultipleChoiceTypeQuestionChoiceAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MultipleChoiceTypeQuestionChoiceMaxOrderBy>;
  min?: InputMaybe<MultipleChoiceTypeQuestionChoiceMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "multipleChoiceTypeQuestionChoice". All fields are combined with a logical 'AND'.
 */
export type MultipleChoiceTypeQuestionChoiceBoolExp = {
  _and?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceBoolExp>>;
  _not?: InputMaybe<MultipleChoiceTypeQuestionChoiceBoolExp>;
  _or?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceBoolExp>>;
  multipleChoiceTypeQuestionChoiceAnswers?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerBoolExp>;
  multipleChoiceTypeQuestionMeta?: InputMaybe<MultipleChoiceTypeQuestionMetaBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "multipleChoiceTypeQuestionChoice" */
export type MultipleChoiceTypeQuestionChoiceMaxOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "multipleChoiceTypeQuestionChoice" */
export type MultipleChoiceTypeQuestionChoiceMinOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "multipleChoiceTypeQuestionChoice". */
export type MultipleChoiceTypeQuestionChoiceOrderBy = {
  multipleChoiceTypeQuestionChoiceAnswers_aggregate?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerAggregateOrderBy>;
  multipleChoiceTypeQuestionMeta?: InputMaybe<MultipleChoiceTypeQuestionMetaOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "multipleChoiceTypeQuestionChoice" */
export enum MultipleChoiceTypeQuestionChoiceSelectColumn {
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "multipleChoiceTypeQuestionChoice" */
export type MultipleChoiceTypeQuestionChoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MultipleChoiceTypeQuestionChoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MultipleChoiceTypeQuestionChoiceStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "multipleChoiceTypeQuestionMeta" */
export type MultipleChoiceTypeQuestionMeta = {
  __typename?: 'multipleChoiceTypeQuestionMeta';
  id: Scalars['uuid'];
  /** An array relationship */
  multipleChoiceTypeQuestionAnswers: Array<MultipleChoiceTypeQuestionAnswer>;
  /** An array relationship */
  multipleChoiceTypeQuestionChoices: Array<MultipleChoiceTypeQuestionChoice>;
  /** An object relationship */
  question?: Maybe<Question>;
};


/** columns and relationships of "multipleChoiceTypeQuestionMeta" */
export type MultipleChoiceTypeQuestionMetaMultipleChoiceTypeQuestionAnswersArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionAnswerOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionAnswerBoolExp>;
};


/** columns and relationships of "multipleChoiceTypeQuestionMeta" */
export type MultipleChoiceTypeQuestionMetaMultipleChoiceTypeQuestionChoicesArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionChoiceBoolExp>;
};

/**
 * Boolean expression to filter rows from the table
 * "multipleChoiceTypeQuestionMeta". All fields are combined with a logical 'AND'.
 */
export type MultipleChoiceTypeQuestionMetaBoolExp = {
  _and?: InputMaybe<Array<MultipleChoiceTypeQuestionMetaBoolExp>>;
  _not?: InputMaybe<MultipleChoiceTypeQuestionMetaBoolExp>;
  _or?: InputMaybe<Array<MultipleChoiceTypeQuestionMetaBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  multipleChoiceTypeQuestionAnswers?: InputMaybe<MultipleChoiceTypeQuestionAnswerBoolExp>;
  multipleChoiceTypeQuestionChoices?: InputMaybe<MultipleChoiceTypeQuestionChoiceBoolExp>;
  question?: InputMaybe<QuestionBoolExp>;
};

/** Ordering options when selecting data from "multipleChoiceTypeQuestionMeta". */
export type MultipleChoiceTypeQuestionMetaOrderBy = {
  id?: InputMaybe<OrderBy>;
  multipleChoiceTypeQuestionAnswers_aggregate?: InputMaybe<MultipleChoiceTypeQuestionAnswerAggregateOrderBy>;
  multipleChoiceTypeQuestionChoices_aggregate?: InputMaybe<MultipleChoiceTypeQuestionChoiceAggregateOrderBy>;
  question?: InputMaybe<QuestionOrderBy>;
};

/** select columns of table "multipleChoiceTypeQuestionMeta" */
export enum MultipleChoiceTypeQuestionMetaSelectColumn {
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "multipleChoiceTypeQuestionMeta" */
export type MultipleChoiceTypeQuestionMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MultipleChoiceTypeQuestionMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type MultipleChoiceTypeQuestionMetaStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  archiveMenu: ArchiveMenuResult;
  archivePlan: ArchivePlanResult;
  archiveTable: Scalars['Boolean'];
  companyManagerDeleteDashboardAccount: Scalars['Boolean'];
  createCategory: CreateCategoryOutput;
  createDashboardAccount: Scalars['Boolean'];
  createLineOfficialAccount: Scalars['Boolean'];
  createMenu: CreateMenuOutput;
  createMenuRecommendation: CreateMenuRecommendationOutput;
  createOption: CreateOptionOutput;
  createPlan: CreatePlanOutput;
  createPlanChoice: CreatePlanChoiceOutput;
  createPlanOption: CreatePlanOptionOutput;
  createServiceAdmin: Scalars['Boolean'];
  createShop: Scalars['Boolean'];
  createShopMenus: CreateShopMenusResult;
  createShopPlan: CreateShopPlanResult;
  dashboardAccountArchiveCompany: Scalars['Boolean'];
  dashboardAccountArchiveShop: Scalars['Boolean'];
  dashboardAccountCancelOnSitePayment: Scalars['Boolean'];
  dashboardAccountCopyDealingData: Scalars['Boolean'];
  dashboardAccountCopyMasterData: Scalars['Boolean'];
  dashboardAccountCountMessageDeliveryTargets: UpdateTargetCountOutput;
  dashboardAccountCreateCompany: Scalars['Boolean'];
  dashboardAccountCreateCorporation: Scalars['Boolean'];
  dashboardAccountCreateMessageDelivery: Scalars['Boolean'];
  dashboardAccountCreateSurchargeConfigs: CreateSurchargeConfigsResult;
  dashboardAccountEnableAdyenOnlinePayment: EnableOnlinePaymentOutput;
  dashboardAccountEnableGmoOnlinePayment: EnableOnlinePaymentOutput;
  dashboardAccountEnableStripeOnlinePayment: EnableOnlinePaymentOutput;
  dashboardAccountIssueLineReportingBotVerificationCode: IssueLineReportingBotVerificationCodeResponse;
  dashboardAccountUpdateBankDepositAmount: Scalars['Boolean'];
  dashboardAccountUpdateDashboardAccount: Scalars['Boolean'];
  dashboardAccountUpdateMessageDelivery: Scalars['Boolean'];
  dashboardAccountUpdateOnSitePayment: Scalars['Boolean'];
  deleteShopChoices: DeleteShopChoicesResult;
  deleteShopMenus: DeleteShopMenusResult;
  deleteShopPlanChoices: DeleteShopPlanChoicesResult;
  deleteShopPlans: DeleteShopPlansResult;
  /** delete data from the table: "accountingSlipImage" */
  delete_accountingSlipImage?: Maybe<AccountingSlipImageMutationResponse>;
  /** delete single row from the table: "accountingSlipImage" */
  delete_accountingSlipImage_by_pk?: Maybe<AccountingSlipImage>;
  /** delete data from the table: "cashRegisterConfig" */
  delete_cashRegisterConfig?: Maybe<CashRegisterConfigMutationResponse>;
  /** delete single row from the table: "cashRegisterConfig" */
  delete_cashRegisterConfig_by_pk?: Maybe<CashRegisterConfig>;
  /** delete data from the table: "categoryMenu" */
  delete_categoryMenu?: Maybe<CategoryMenuMutationResponse>;
  /** delete single row from the table: "categoryMenu" */
  delete_categoryMenu_by_pk?: Maybe<CategoryMenu>;
  /** delete data from the table: "clerk" */
  delete_clerk?: Maybe<ClerkMutationResponse>;
  /** delete single row from the table: "clerk" */
  delete_clerk_by_pk?: Maybe<Clerk>;
  /** delete data from the table: "company" */
  delete_company?: Maybe<CompanyMutationResponse>;
  /** delete single row from the table: "company" */
  delete_company_by_pk?: Maybe<Company>;
  /** delete data from the table: "compulsoryAppetizerConfig" */
  delete_compulsoryAppetizerConfig?: Maybe<CompulsoryAppetizerConfigMutationResponse>;
  /** delete single row from the table: "compulsoryAppetizerConfig" */
  delete_compulsoryAppetizerConfig_by_pk?: Maybe<CompulsoryAppetizerConfig>;
  /** delete data from the table: "connectGameConfigShop" */
  delete_connectGameConfigShop?: Maybe<ConnectGameConfigShopMutationResponse>;
  /** delete single row from the table: "connectGameConfigShop" */
  delete_connectGameConfigShop_by_pk?: Maybe<ConnectGameConfigShop>;
  /** delete data from the table: "cookingItem" */
  delete_cookingItem?: Maybe<CookingItemMutationResponse>;
  /** delete single row from the table: "cookingItem" */
  delete_cookingItem_by_pk?: Maybe<CookingItem>;
  /** delete data from the table: "corporation" */
  delete_corporation?: Maybe<CorporationMutationResponse>;
  /** delete single row from the table: "corporation" */
  delete_corporation_by_pk?: Maybe<Corporation>;
  /** delete data from the table: "couponMenuDiscount" */
  delete_couponMenuDiscount?: Maybe<CouponMenuDiscountMutationResponse>;
  /** delete single row from the table: "couponMenuDiscount" */
  delete_couponMenuDiscount_by_pk?: Maybe<CouponMenuDiscount>;
  /** delete data from the table: "dashboardAccountRole" */
  delete_dashboardAccountRole?: Maybe<DashboardAccountRoleMutationResponse>;
  /** delete data from the table: "dashboardAccountRolePermission" */
  delete_dashboardAccountRolePermission?: Maybe<DashboardAccountRolePermissionMutationResponse>;
  /** delete single row from the table: "dashboardAccountRolePermission" */
  delete_dashboardAccountRolePermission_by_pk?: Maybe<DashboardAccountRolePermission>;
  /** delete single row from the table: "dashboardAccountRole" */
  delete_dashboardAccountRole_by_pk?: Maybe<DashboardAccountRole>;
  /** delete data from the table: "dishUpSlipGroup" */
  delete_dishUpSlipGroup?: Maybe<DishUpSlipGroupMutationResponse>;
  /** delete data from the table: "dishUpSlipGroupRoles" */
  delete_dishUpSlipGroupRoles?: Maybe<DishUpSlipGroupRolesMutationResponse>;
  /** delete single row from the table: "dishUpSlipGroupRoles" */
  delete_dishUpSlipGroupRoles_by_pk?: Maybe<DishUpSlipGroupRoles>;
  /** delete data from the table: "dishUpSlipGroupShopMenus" */
  delete_dishUpSlipGroupShopMenus?: Maybe<DishUpSlipGroupShopMenusMutationResponse>;
  /** delete single row from the table: "dishUpSlipGroupShopMenus" */
  delete_dishUpSlipGroupShopMenus_by_pk?: Maybe<DishUpSlipGroupShopMenus>;
  /** delete data from the table: "dishUpSlipGroupShopPlans" */
  delete_dishUpSlipGroupShopPlans?: Maybe<DishUpSlipGroupShopPlansMutationResponse>;
  /** delete single row from the table: "dishUpSlipGroupShopPlans" */
  delete_dishUpSlipGroupShopPlans_by_pk?: Maybe<DishUpSlipGroupShopPlans>;
  /** delete data from the table: "dishUpSlipGroupTables" */
  delete_dishUpSlipGroupTables?: Maybe<DishUpSlipGroupTablesMutationResponse>;
  /** delete single row from the table: "dishUpSlipGroupTables" */
  delete_dishUpSlipGroupTables_by_pk?: Maybe<DishUpSlipGroupTables>;
  /** delete single row from the table: "dishUpSlipGroup" */
  delete_dishUpSlipGroup_by_pk?: Maybe<DishUpSlipGroup>;
  /** delete data from the table: "ebicaTable" */
  delete_ebicaTable?: Maybe<EbicaTableMutationResponse>;
  /** delete single row from the table: "ebicaTable" */
  delete_ebicaTable_by_pk?: Maybe<EbicaTable>;
  /** delete data from the table: "externalOnlineMenuAvailableTimeTerm" */
  delete_externalOnlineMenuAvailableTimeTerm?: Maybe<ExternalOnlineMenuAvailableTimeTermMutationResponse>;
  /** delete single row from the table: "externalOnlineMenuAvailableTimeTerm" */
  delete_externalOnlineMenuAvailableTimeTerm_by_pk?: Maybe<ExternalOnlineMenuAvailableTimeTerm>;
  /** delete data from the table: "featureFlagShop" */
  delete_featureFlagShop?: Maybe<FeatureFlagShopMutationResponse>;
  /** delete single row from the table: "featureFlagShop" */
  delete_featureFlagShop_by_pk?: Maybe<FeatureFlagShop>;
  /** delete data from the table: "foodingJournalDepartmentMaster" */
  delete_foodingJournalDepartmentMaster?: Maybe<FoodingJournalDepartmentMasterMutationResponse>;
  /** delete single row from the table: "foodingJournalDepartmentMaster" */
  delete_foodingJournalDepartmentMaster_by_pk?: Maybe<FoodingJournalDepartmentMaster>;
  /** delete data from the table: "foodingJournalGroupMaster" */
  delete_foodingJournalGroupMaster?: Maybe<FoodingJournalGroupMasterMutationResponse>;
  /** delete single row from the table: "foodingJournalGroupMaster" */
  delete_foodingJournalGroupMaster_by_pk?: Maybe<FoodingJournalGroupMaster>;
  /** delete data from the table: "foodingJournalMenu" */
  delete_foodingJournalMenu?: Maybe<FoodingJournalMenuMutationResponse>;
  /** delete single row from the table: "foodingJournalMenu" */
  delete_foodingJournalMenu_by_pk?: Maybe<FoodingJournalMenu>;
  /** delete data from the table: "foodingJournalOnSitePaymentDetailType" */
  delete_foodingJournalOnSitePaymentDetailType?: Maybe<FoodingJournalOnSitePaymentDetailTypeMutationResponse>;
  /** delete single row from the table: "foodingJournalOnSitePaymentDetailType" */
  delete_foodingJournalOnSitePaymentDetailType_by_pk?: Maybe<FoodingJournalOnSitePaymentDetailType>;
  /** delete data from the table: "foodingJournalOnSitePaymentDiscountType" */
  delete_foodingJournalOnSitePaymentDiscountType?: Maybe<FoodingJournalOnSitePaymentDiscountTypeMutationResponse>;
  /** delete single row from the table: "foodingJournalOnSitePaymentDiscountType" */
  delete_foodingJournalOnSitePaymentDiscountType_by_pk?: Maybe<FoodingJournalOnSitePaymentDiscountType>;
  /** delete data from the table: "giftMeta" */
  delete_giftMeta?: Maybe<GiftMetaMutationResponse>;
  /** delete single row from the table: "giftMeta" */
  delete_giftMeta_by_pk?: Maybe<GiftMeta>;
  /** delete data from the table: "gmoOffPaymentOnSitePaymentDetailType" */
  delete_gmoOffPaymentOnSitePaymentDetailType?: Maybe<GmoOffPaymentOnSitePaymentDetailTypeMutationResponse>;
  /** delete single row from the table: "gmoOffPaymentOnSitePaymentDetailType" */
  delete_gmoOffPaymentOnSitePaymentDetailType_by_pk?: Maybe<GmoOffPaymentOnSitePaymentDetailType>;
  /** delete data from the table: "gmoOffPaymentShopConfigTransactionFeeRate" */
  delete_gmoOffPaymentShopConfigTransactionFeeRate?: Maybe<GmoOffPaymentShopConfigTransactionFeeRateMutationResponse>;
  /** delete single row from the table: "gmoOffPaymentShopConfigTransactionFeeRate" */
  delete_gmoOffPaymentShopConfigTransactionFeeRate_by_pk?: Maybe<GmoOffPaymentShopConfigTransactionFeeRate>;
  /** delete data from the table: "inflowSourceTag" */
  delete_inflowSourceTag?: Maybe<InflowSourceTagMutationResponse>;
  /** delete single row from the table: "inflowSourceTag" */
  delete_inflowSourceTag_by_pk?: Maybe<InflowSourceTag>;
  /** delete data from the table: "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
  delete_kdDisplayItemKdDisplayTargetsKdDisplayTarget?: Maybe<KdDisplayItemKdDisplayTargetsKdDisplayTargetMutationResponse>;
  /** delete single row from the table: "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
  delete_kdDisplayItemKdDisplayTargetsKdDisplayTarget_by_pk?: Maybe<KdDisplayItemKdDisplayTargetsKdDisplayTarget>;
  /** delete data from the table: "kdDisplayTarget" */
  delete_kdDisplayTarget?: Maybe<KdDisplayTargetMutationResponse>;
  /** delete single row from the table: "kdDisplayTarget" */
  delete_kdDisplayTarget_by_pk?: Maybe<KdDisplayTarget>;
  /** delete data from the table: "lineChannelConfig" */
  delete_lineChannelConfig?: Maybe<LineChannelConfigMutationResponse>;
  /** delete single row from the table: "lineChannelConfig" */
  delete_lineChannelConfig_by_pk?: Maybe<LineChannelConfig>;
  /** delete data from the table: "lineReportingBotConfigShop" */
  delete_lineReportingBotConfigShop?: Maybe<LineReportingBotConfigShopMutationResponse>;
  /** delete single row from the table: "lineReportingBotConfigShop" */
  delete_lineReportingBotConfigShop_by_pk?: Maybe<LineReportingBotConfigShop>;
  /** delete data from the table: "membershipCardTitle" */
  delete_membershipCardTitle?: Maybe<MembershipCardTitleMutationResponse>;
  /** delete single row from the table: "membershipCardTitle" */
  delete_membershipCardTitle_by_pk?: Maybe<MembershipCardTitle>;
  /** delete data from the table: "membershipRankConfig" */
  delete_membershipRankConfig?: Maybe<MembershipRankConfigMutationResponse>;
  /** delete single row from the table: "membershipRankConfig" */
  delete_membershipRankConfig_by_pk?: Maybe<MembershipRankConfig>;
  /** delete data from the table: "menuOption" */
  delete_menuOption?: Maybe<MenuOptionMutationResponse>;
  /** delete single row from the table: "menuOption" */
  delete_menuOption_by_pk?: Maybe<MenuOption>;
  /** delete data from the table: "onSitePaymentDetailType" */
  delete_onSitePaymentDetailType?: Maybe<OnSitePaymentDetailTypeMutationResponse>;
  /** delete single row from the table: "onSitePaymentDetailType" */
  delete_onSitePaymentDetailType_by_pk?: Maybe<OnSitePaymentDetailType>;
  /** delete data from the table: "onSitePaymentDiscountType" */
  delete_onSitePaymentDiscountType?: Maybe<OnSitePaymentDiscountTypeMutationResponse>;
  /** delete single row from the table: "onSitePaymentDiscountType" */
  delete_onSitePaymentDiscountType_by_pk?: Maybe<OnSitePaymentDiscountType>;
  /** delete data from the table: "onlinePaymentPrinterRoleMeta" */
  delete_onlinePaymentPrinterRoleMeta?: Maybe<OnlinePaymentPrinterRoleMetaMutationResponse>;
  /** delete single row from the table: "onlinePaymentPrinterRoleMeta" */
  delete_onlinePaymentPrinterRoleMeta_by_pk?: Maybe<OnlinePaymentPrinterRoleMeta>;
  /** delete data from the table: "optionInputType" */
  delete_optionInputType?: Maybe<OptionInputTypeMutationResponse>;
  /** delete single row from the table: "optionInputType" */
  delete_optionInputType_by_pk?: Maybe<OptionInputType>;
  /** delete data from the table: "orderableTime" */
  delete_orderableTime?: Maybe<OrderableTimeMutationResponse>;
  /** delete single row from the table: "orderableTime" */
  delete_orderableTime_by_pk?: Maybe<OrderableTime>;
  /** delete data from the table: "paymentReceiptRoleTable" */
  delete_paymentReceiptRoleTable?: Maybe<PaymentReceiptRoleTableMutationResponse>;
  /** delete data from the table: "pikaichiMenu" */
  delete_pikaichiMenu?: Maybe<PikaichiMenuMutationResponse>;
  /** delete data from the table: "pikaichiMenuChoice" */
  delete_pikaichiMenuChoice?: Maybe<PikaichiMenuChoiceMutationResponse>;
  /** delete single row from the table: "pikaichiMenuChoice" */
  delete_pikaichiMenuChoice_by_pk?: Maybe<PikaichiMenuChoice>;
  /** delete data from the table: "pikaichiMenuMenu" */
  delete_pikaichiMenuMenu?: Maybe<PikaichiMenuMenuMutationResponse>;
  /** delete single row from the table: "pikaichiMenuMenu" */
  delete_pikaichiMenuMenu_by_pk?: Maybe<PikaichiMenuMenu>;
  /** delete data from the table: "pikaichiMenuPlan" */
  delete_pikaichiMenuPlan?: Maybe<PikaichiMenuPlanMutationResponse>;
  /** delete data from the table: "pikaichiMenuPlanChoice" */
  delete_pikaichiMenuPlanChoice?: Maybe<PikaichiMenuPlanChoiceMutationResponse>;
  /** delete single row from the table: "pikaichiMenuPlanChoice" */
  delete_pikaichiMenuPlanChoice_by_pk?: Maybe<PikaichiMenuPlanChoice>;
  /** delete single row from the table: "pikaichiMenuPlan" */
  delete_pikaichiMenuPlan_by_pk?: Maybe<PikaichiMenuPlan>;
  /** delete single row from the table: "pikaichiMenu" */
  delete_pikaichiMenu_by_pk?: Maybe<PikaichiMenu>;
  /** delete data from the table: "pikaichiOnSitePaymentDetailType" */
  delete_pikaichiOnSitePaymentDetailType?: Maybe<PikaichiOnSitePaymentDetailTypeMutationResponse>;
  /** delete single row from the table: "pikaichiOnSitePaymentDetailType" */
  delete_pikaichiOnSitePaymentDetailType_by_pk?: Maybe<PikaichiOnSitePaymentDetailType>;
  /** delete data from the table: "planFirstOrderCategoryMenu" */
  delete_planFirstOrderCategoryMenu?: Maybe<PlanFirstOrderCategoryMenuMutationResponse>;
  /** delete single row from the table: "planFirstOrderCategoryMenu" */
  delete_planFirstOrderCategoryMenu_by_pk?: Maybe<PlanFirstOrderCategoryMenu>;
  /** delete data from the table: "planGroup" */
  delete_planGroup?: Maybe<PlanGroupMutationResponse>;
  /** delete single row from the table: "planGroup" */
  delete_planGroup_by_pk?: Maybe<PlanGroup>;
  /** delete data from the table: "planMenuCategory" */
  delete_planMenuCategory?: Maybe<PlanMenuCategoryMutationResponse>;
  /** delete single row from the table: "planMenuCategory" */
  delete_planMenuCategory_by_pk?: Maybe<PlanMenuCategory>;
  /** delete data from the table: "plan_menu_categories_category" */
  delete_plan_menu_categories_category?: Maybe<PlanMenuCategoriesCategoryMutationResponse>;
  /** delete data from the table: "questionnaire" */
  delete_questionnaire?: Maybe<QuestionnaireMutationResponse>;
  /** delete single row from the table: "questionnaire" */
  delete_questionnaire_by_pk?: Maybe<Questionnaire>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<RoleMutationResponse>;
  /** delete data from the table: "roleTablesTable" */
  delete_roleTablesTable?: Maybe<RoleTablesTableMutationResponse>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>;
  /** delete data from the table: "shopBusinessOperationHour" */
  delete_shopBusinessOperationHour?: Maybe<ShopBusinessOperationHourMutationResponse>;
  /** delete single row from the table: "shopBusinessOperationHour" */
  delete_shopBusinessOperationHour_by_pk?: Maybe<ShopBusinessOperationHour>;
  /** delete data from the table: "shopClerk" */
  delete_shopClerk?: Maybe<ShopClerkMutationResponse>;
  /** delete single row from the table: "shopClerk" */
  delete_shopClerk_by_pk?: Maybe<ShopClerk>;
  /** delete data from the table: "shopClient" */
  delete_shopClient?: Maybe<ShopClientMutationResponse>;
  /** delete single row from the table: "shopClient" */
  delete_shopClient_by_pk?: Maybe<ShopClient>;
  /** delete data from the table: "shopCookingItemRole" */
  delete_shopCookingItemRole?: Maybe<ShopCookingItemRoleMutationResponse>;
  /** delete single row from the table: "shopCookingItemRole" */
  delete_shopCookingItemRole_by_pk?: Maybe<ShopCookingItemRole>;
  /** delete data from the table: "shopMenuCookingItem" */
  delete_shopMenuCookingItem?: Maybe<ShopMenuCookingItemMutationResponse>;
  /** delete data from the table: "shopMenuKdDisplayTarget" */
  delete_shopMenuKdDisplayTarget?: Maybe<ShopMenuKdDisplayTargetMutationResponse>;
  /** delete single row from the table: "shopMenuKdDisplayTarget" */
  delete_shopMenuKdDisplayTarget_by_pk?: Maybe<ShopMenuKdDisplayTarget>;
  /** delete data from the table: "shopMenuKitchenRole" */
  delete_shopMenuKitchenRole?: Maybe<ShopMenuKitchenRoleMutationResponse>;
  /** delete data from the table: "shopOptionKitchenRole" */
  delete_shopOptionKitchenRole?: Maybe<ShopOptionKitchenRoleMutationResponse>;
  /** delete single row from the table: "shopOptionKitchenRole" */
  delete_shopOptionKitchenRole_by_pk?: Maybe<ShopOptionKitchenRole>;
  /** delete data from the table: "shopOrderableTimeTerm" */
  delete_shopOrderableTimeTerm?: Maybe<ShopOrderableTimeTermMutationResponse>;
  /** delete single row from the table: "shopOrderableTimeTerm" */
  delete_shopOrderableTimeTerm_by_pk?: Maybe<ShopOrderableTimeTerm>;
  /** delete data from the table: "shopPlanKitchenRole" */
  delete_shopPlanKitchenRole?: Maybe<ShopPlanKitchenRoleMutationResponse>;
  /** delete data from the table: "shopPlanOption" */
  delete_shopPlanOption?: Maybe<ShopPlanOptionMutationResponse>;
  /** delete data from the table: "shopPlanOptionKitchenRole" */
  delete_shopPlanOptionKitchenRole?: Maybe<ShopPlanOptionKitchenRoleMutationResponse>;
  /** delete single row from the table: "shopPlanOption" */
  delete_shopPlanOption_by_pk?: Maybe<ShopPlanOption>;
  /** delete data from the table: "steraOnSitePaymentDetailType" */
  delete_steraOnSitePaymentDetailType?: Maybe<SteraOnSitePaymentDetailTypeMutationResponse>;
  /** delete single row from the table: "steraOnSitePaymentDetailType" */
  delete_steraOnSitePaymentDetailType_by_pk?: Maybe<SteraOnSitePaymentDetailType>;
  /** delete data from the table: "stock" */
  delete_stock?: Maybe<StockMutationResponse>;
  /** delete single row from the table: "stock" */
  delete_stock_by_pk?: Maybe<Stock>;
  /** delete data from the table: "tecAggregationMenu" */
  delete_tecAggregationMenu?: Maybe<TecAggregationMenuMutationResponse>;
  /** delete single row from the table: "tecAggregationMenu" */
  delete_tecAggregationMenu_by_pk?: Maybe<TecAggregationMenu>;
  /** delete data from the table: "tecAggregationOnSitePaymentDetailType" */
  delete_tecAggregationOnSitePaymentDetailType?: Maybe<TecAggregationOnSitePaymentDetailTypeMutationResponse>;
  /** delete single row from the table: "tecAggregationOnSitePaymentDetailType" */
  delete_tecAggregationOnSitePaymentDetailType_by_pk?: Maybe<TecAggregationOnSitePaymentDetailType>;
  /** delete data from the table: "winboardConfig" */
  delete_winboardConfig?: Maybe<WinboardConfigMutationResponse>;
  /** delete single row from the table: "winboardConfig" */
  delete_winboardConfig_by_pk?: Maybe<WinboardConfig>;
  /** delete data from the table: "winboardInflowSourceTag" */
  delete_winboardInflowSourceTag?: Maybe<WinboardInflowSourceTagMutationResponse>;
  /** delete single row from the table: "winboardInflowSourceTag" */
  delete_winboardInflowSourceTag_by_pk?: Maybe<WinboardInflowSourceTag>;
  /** delete data from the table: "winboardMenu" */
  delete_winboardMenu?: Maybe<WinboardMenuMutationResponse>;
  /** delete single row from the table: "winboardMenu" */
  delete_winboardMenu_by_pk?: Maybe<WinboardMenu>;
  foodingJournalUploadSales: Scalars['Boolean'];
  /** insert data into the table: "accountingSlipImage" */
  insert_accountingSlipImage?: Maybe<AccountingSlipImageMutationResponse>;
  /** insert a single row into the table: "accountingSlipImage" */
  insert_accountingSlipImage_one?: Maybe<AccountingSlipImage>;
  /** insert data into the table: "adyenPaymentStoreMeta" */
  insert_adyenPaymentStoreMeta?: Maybe<AdyenPaymentStoreMetaMutationResponse>;
  /** insert a single row into the table: "adyenPaymentStoreMeta" */
  insert_adyenPaymentStoreMeta_one?: Maybe<AdyenPaymentStoreMeta>;
  /** insert data into the table: "analyticsSetting" */
  insert_analyticsSetting?: Maybe<AnalyticsSettingMutationResponse>;
  /** insert a single row into the table: "analyticsSetting" */
  insert_analyticsSetting_one?: Maybe<AnalyticsSetting>;
  /** insert data into the table: "cashRegisterConfig" */
  insert_cashRegisterConfig?: Maybe<CashRegisterConfigMutationResponse>;
  /** insert a single row into the table: "cashRegisterConfig" */
  insert_cashRegisterConfig_one?: Maybe<CashRegisterConfig>;
  /** insert data into the table: "category" */
  insert_category?: Maybe<CategoryMutationResponse>;
  /** insert data into the table: "categoryMenu" */
  insert_categoryMenu?: Maybe<CategoryMenuMutationResponse>;
  /** insert a single row into the table: "categoryMenu" */
  insert_categoryMenu_one?: Maybe<CategoryMenu>;
  /** insert a single row into the table: "category" */
  insert_category_one?: Maybe<Category>;
  /** insert data into the table: "choice" */
  insert_choice?: Maybe<ChoiceMutationResponse>;
  /** insert a single row into the table: "choice" */
  insert_choice_one?: Maybe<Choice>;
  /** insert data into the table: "clerk" */
  insert_clerk?: Maybe<ClerkMutationResponse>;
  /** insert a single row into the table: "clerk" */
  insert_clerk_one?: Maybe<Clerk>;
  /** insert data into the table: "company" */
  insert_company?: Maybe<CompanyMutationResponse>;
  /** insert a single row into the table: "company" */
  insert_company_one?: Maybe<Company>;
  /** insert data into the table: "compulsoryAppetizerConfig" */
  insert_compulsoryAppetizerConfig?: Maybe<CompulsoryAppetizerConfigMutationResponse>;
  /** insert a single row into the table: "compulsoryAppetizerConfig" */
  insert_compulsoryAppetizerConfig_one?: Maybe<CompulsoryAppetizerConfig>;
  /** insert data into the table: "connectGameConfig" */
  insert_connectGameConfig?: Maybe<ConnectGameConfigMutationResponse>;
  /** insert data into the table: "connectGameConfigShop" */
  insert_connectGameConfigShop?: Maybe<ConnectGameConfigShopMutationResponse>;
  /** insert a single row into the table: "connectGameConfigShop" */
  insert_connectGameConfigShop_one?: Maybe<ConnectGameConfigShop>;
  /** insert a single row into the table: "connectGameConfig" */
  insert_connectGameConfig_one?: Maybe<ConnectGameConfig>;
  /** insert data into the table: "cookingItem" */
  insert_cookingItem?: Maybe<CookingItemMutationResponse>;
  /** insert a single row into the table: "cookingItem" */
  insert_cookingItem_one?: Maybe<CookingItem>;
  /** insert data into the table: "corporation" */
  insert_corporation?: Maybe<CorporationMutationResponse>;
  /** insert a single row into the table: "corporation" */
  insert_corporation_one?: Maybe<Corporation>;
  /** insert data into the table: "coupon" */
  insert_coupon?: Maybe<CouponMutationResponse>;
  /** insert data into the table: "couponMenuDiscount" */
  insert_couponMenuDiscount?: Maybe<CouponMenuDiscountMutationResponse>;
  /** insert a single row into the table: "couponMenuDiscount" */
  insert_couponMenuDiscount_one?: Maybe<CouponMenuDiscount>;
  /** insert a single row into the table: "coupon" */
  insert_coupon_one?: Maybe<Coupon>;
  /** insert data into the table: "dailySalesBudget" */
  insert_dailySalesBudget?: Maybe<DailySalesBudgetMutationResponse>;
  /** insert a single row into the table: "dailySalesBudget" */
  insert_dailySalesBudget_one?: Maybe<DailySalesBudget>;
  /** insert data into the table: "dashboardAccountRole" */
  insert_dashboardAccountRole?: Maybe<DashboardAccountRoleMutationResponse>;
  /** insert data into the table: "dashboardAccountRolePermission" */
  insert_dashboardAccountRolePermission?: Maybe<DashboardAccountRolePermissionMutationResponse>;
  /** insert a single row into the table: "dashboardAccountRolePermission" */
  insert_dashboardAccountRolePermission_one?: Maybe<DashboardAccountRolePermission>;
  /** insert a single row into the table: "dashboardAccountRole" */
  insert_dashboardAccountRole_one?: Maybe<DashboardAccountRole>;
  /** insert data into the table: "dishUpSlipGroup" */
  insert_dishUpSlipGroup?: Maybe<DishUpSlipGroupMutationResponse>;
  /** insert data into the table: "dishUpSlipGroupRoles" */
  insert_dishUpSlipGroupRoles?: Maybe<DishUpSlipGroupRolesMutationResponse>;
  /** insert a single row into the table: "dishUpSlipGroupRoles" */
  insert_dishUpSlipGroupRoles_one?: Maybe<DishUpSlipGroupRoles>;
  /** insert data into the table: "dishUpSlipGroupShopMenus" */
  insert_dishUpSlipGroupShopMenus?: Maybe<DishUpSlipGroupShopMenusMutationResponse>;
  /** insert a single row into the table: "dishUpSlipGroupShopMenus" */
  insert_dishUpSlipGroupShopMenus_one?: Maybe<DishUpSlipGroupShopMenus>;
  /** insert data into the table: "dishUpSlipGroupShopPlans" */
  insert_dishUpSlipGroupShopPlans?: Maybe<DishUpSlipGroupShopPlansMutationResponse>;
  /** insert a single row into the table: "dishUpSlipGroupShopPlans" */
  insert_dishUpSlipGroupShopPlans_one?: Maybe<DishUpSlipGroupShopPlans>;
  /** insert data into the table: "dishUpSlipGroupTables" */
  insert_dishUpSlipGroupTables?: Maybe<DishUpSlipGroupTablesMutationResponse>;
  /** insert a single row into the table: "dishUpSlipGroupTables" */
  insert_dishUpSlipGroupTables_one?: Maybe<DishUpSlipGroupTables>;
  /** insert a single row into the table: "dishUpSlipGroup" */
  insert_dishUpSlipGroup_one?: Maybe<DishUpSlipGroup>;
  /** insert data into the table: "ebicaConfig" */
  insert_ebicaConfig?: Maybe<EbicaConfigMutationResponse>;
  /** insert a single row into the table: "ebicaConfig" */
  insert_ebicaConfig_one?: Maybe<EbicaConfig>;
  /** insert data into the table: "ebicaTable" */
  insert_ebicaTable?: Maybe<EbicaTableMutationResponse>;
  /** insert a single row into the table: "ebicaTable" */
  insert_ebicaTable_one?: Maybe<EbicaTable>;
  /** insert data into the table: "externalOnlineMenu" */
  insert_externalOnlineMenu?: Maybe<ExternalOnlineMenuMutationResponse>;
  /** insert data into the table: "externalOnlineMenuAvailableTimeTerm" */
  insert_externalOnlineMenuAvailableTimeTerm?: Maybe<ExternalOnlineMenuAvailableTimeTermMutationResponse>;
  /** insert a single row into the table: "externalOnlineMenuAvailableTimeTerm" */
  insert_externalOnlineMenuAvailableTimeTerm_one?: Maybe<ExternalOnlineMenuAvailableTimeTerm>;
  /** insert data into the table: "externalOnlineMenuConfig" */
  insert_externalOnlineMenuConfig?: Maybe<ExternalOnlineMenuConfigMutationResponse>;
  /** insert a single row into the table: "externalOnlineMenuConfig" */
  insert_externalOnlineMenuConfig_one?: Maybe<ExternalOnlineMenuConfig>;
  /** insert a single row into the table: "externalOnlineMenu" */
  insert_externalOnlineMenu_one?: Maybe<ExternalOnlineMenu>;
  /** insert data into the table: "featureFlagShop" */
  insert_featureFlagShop?: Maybe<FeatureFlagShopMutationResponse>;
  /** insert a single row into the table: "featureFlagShop" */
  insert_featureFlagShop_one?: Maybe<FeatureFlagShop>;
  /** insert data into the table: "foodingJournalConfig" */
  insert_foodingJournalConfig?: Maybe<FoodingJournalConfigMutationResponse>;
  /** insert a single row into the table: "foodingJournalConfig" */
  insert_foodingJournalConfig_one?: Maybe<FoodingJournalConfig>;
  /** insert data into the table: "foodingJournalDepartmentMaster" */
  insert_foodingJournalDepartmentMaster?: Maybe<FoodingJournalDepartmentMasterMutationResponse>;
  /** insert a single row into the table: "foodingJournalDepartmentMaster" */
  insert_foodingJournalDepartmentMaster_one?: Maybe<FoodingJournalDepartmentMaster>;
  /** insert data into the table: "foodingJournalGroupMaster" */
  insert_foodingJournalGroupMaster?: Maybe<FoodingJournalGroupMasterMutationResponse>;
  /** insert a single row into the table: "foodingJournalGroupMaster" */
  insert_foodingJournalGroupMaster_one?: Maybe<FoodingJournalGroupMaster>;
  /** insert data into the table: "foodingJournalMenu" */
  insert_foodingJournalMenu?: Maybe<FoodingJournalMenuMutationResponse>;
  /** insert a single row into the table: "foodingJournalMenu" */
  insert_foodingJournalMenu_one?: Maybe<FoodingJournalMenu>;
  /** insert data into the table: "foodingJournalOnSitePaymentDetailType" */
  insert_foodingJournalOnSitePaymentDetailType?: Maybe<FoodingJournalOnSitePaymentDetailTypeMutationResponse>;
  /** insert a single row into the table: "foodingJournalOnSitePaymentDetailType" */
  insert_foodingJournalOnSitePaymentDetailType_one?: Maybe<FoodingJournalOnSitePaymentDetailType>;
  /** insert data into the table: "foodingJournalOnSitePaymentDiscountType" */
  insert_foodingJournalOnSitePaymentDiscountType?: Maybe<FoodingJournalOnSitePaymentDiscountTypeMutationResponse>;
  /** insert a single row into the table: "foodingJournalOnSitePaymentDiscountType" */
  insert_foodingJournalOnSitePaymentDiscountType_one?: Maybe<FoodingJournalOnSitePaymentDiscountType>;
  /** insert data into the table: "giftMeta" */
  insert_giftMeta?: Maybe<GiftMetaMutationResponse>;
  /** insert a single row into the table: "giftMeta" */
  insert_giftMeta_one?: Maybe<GiftMeta>;
  /** insert data into the table: "giftOptionMeta" */
  insert_giftOptionMeta?: Maybe<GiftOptionMetaMutationResponse>;
  /** insert a single row into the table: "giftOptionMeta" */
  insert_giftOptionMeta_one?: Maybe<GiftOptionMeta>;
  /** insert data into the table: "gmoOffPaymentOnSitePaymentDetailType" */
  insert_gmoOffPaymentOnSitePaymentDetailType?: Maybe<GmoOffPaymentOnSitePaymentDetailTypeMutationResponse>;
  /** insert a single row into the table: "gmoOffPaymentOnSitePaymentDetailType" */
  insert_gmoOffPaymentOnSitePaymentDetailType_one?: Maybe<GmoOffPaymentOnSitePaymentDetailType>;
  /** insert data into the table: "gmoOffPaymentShopConfig" */
  insert_gmoOffPaymentShopConfig?: Maybe<GmoOffPaymentShopConfigMutationResponse>;
  /** insert data into the table: "gmoOffPaymentShopConfigTransactionFeeRate" */
  insert_gmoOffPaymentShopConfigTransactionFeeRate?: Maybe<GmoOffPaymentShopConfigTransactionFeeRateMutationResponse>;
  /** insert a single row into the table: "gmoOffPaymentShopConfigTransactionFeeRate" */
  insert_gmoOffPaymentShopConfigTransactionFeeRate_one?: Maybe<GmoOffPaymentShopConfigTransactionFeeRate>;
  /** insert a single row into the table: "gmoOffPaymentShopConfig" */
  insert_gmoOffPaymentShopConfig_one?: Maybe<GmoOffPaymentShopConfig>;
  /** insert data into the table: "inflowSourceTag" */
  insert_inflowSourceTag?: Maybe<InflowSourceTagMutationResponse>;
  /** insert a single row into the table: "inflowSourceTag" */
  insert_inflowSourceTag_one?: Maybe<InflowSourceTag>;
  /** insert data into the table: "kdDisplayTarget" */
  insert_kdDisplayTarget?: Maybe<KdDisplayTargetMutationResponse>;
  /** insert a single row into the table: "kdDisplayTarget" */
  insert_kdDisplayTarget_one?: Maybe<KdDisplayTarget>;
  /** insert data into the table: "lineChannelConfig" */
  insert_lineChannelConfig?: Maybe<LineChannelConfigMutationResponse>;
  /** insert a single row into the table: "lineChannelConfig" */
  insert_lineChannelConfig_one?: Maybe<LineChannelConfig>;
  /** insert data into the table: "lineReportingBotConfigShop" */
  insert_lineReportingBotConfigShop?: Maybe<LineReportingBotConfigShopMutationResponse>;
  /** insert a single row into the table: "lineReportingBotConfigShop" */
  insert_lineReportingBotConfigShop_one?: Maybe<LineReportingBotConfigShop>;
  /** insert data into the table: "membershipCard" */
  insert_membershipCard?: Maybe<MembershipCardMutationResponse>;
  /** insert data into the table: "membershipCardTitle" */
  insert_membershipCardTitle?: Maybe<MembershipCardTitleMutationResponse>;
  /** insert a single row into the table: "membershipCardTitle" */
  insert_membershipCardTitle_one?: Maybe<MembershipCardTitle>;
  /** insert a single row into the table: "membershipCard" */
  insert_membershipCard_one?: Maybe<MembershipCard>;
  /** insert data into the table: "menu" */
  insert_menu?: Maybe<MenuMutationResponse>;
  /** insert data into the table: "menuOption" */
  insert_menuOption?: Maybe<MenuOptionMutationResponse>;
  /** insert a single row into the table: "menuOption" */
  insert_menuOption_one?: Maybe<MenuOption>;
  /** insert data into the table: "menuRecommendationMeta" */
  insert_menuRecommendationMeta?: Maybe<MenuRecommendationMetaMutationResponse>;
  /** insert a single row into the table: "menuRecommendationMeta" */
  insert_menuRecommendationMeta_one?: Maybe<MenuRecommendationMeta>;
  /** insert a single row into the table: "menu" */
  insert_menu_one?: Maybe<Menu>;
  /** insert data into the table: "monthlySalesBudget" */
  insert_monthlySalesBudget?: Maybe<MonthlySalesBudgetMutationResponse>;
  /** insert a single row into the table: "monthlySalesBudget" */
  insert_monthlySalesBudget_one?: Maybe<MonthlySalesBudget>;
  /** insert data into the table: "onSitePaymentDetailType" */
  insert_onSitePaymentDetailType?: Maybe<OnSitePaymentDetailTypeMutationResponse>;
  /** insert a single row into the table: "onSitePaymentDetailType" */
  insert_onSitePaymentDetailType_one?: Maybe<OnSitePaymentDetailType>;
  /** insert data into the table: "onSitePaymentDiscountType" */
  insert_onSitePaymentDiscountType?: Maybe<OnSitePaymentDiscountTypeMutationResponse>;
  /** insert a single row into the table: "onSitePaymentDiscountType" */
  insert_onSitePaymentDiscountType_one?: Maybe<OnSitePaymentDiscountType>;
  /** insert data into the table: "onlinePaymentPrinterRoleMeta" */
  insert_onlinePaymentPrinterRoleMeta?: Maybe<OnlinePaymentPrinterRoleMetaMutationResponse>;
  /** insert a single row into the table: "onlinePaymentPrinterRoleMeta" */
  insert_onlinePaymentPrinterRoleMeta_one?: Maybe<OnlinePaymentPrinterRoleMeta>;
  /** insert data into the table: "option" */
  insert_option?: Maybe<OptionMutationResponse>;
  /** insert data into the table: "optionInputType" */
  insert_optionInputType?: Maybe<OptionInputTypeMutationResponse>;
  /** insert a single row into the table: "optionInputType" */
  insert_optionInputType_one?: Maybe<OptionInputType>;
  /** insert a single row into the table: "option" */
  insert_option_one?: Maybe<Option>;
  /** insert data into the table: "orderableTime" */
  insert_orderableTime?: Maybe<OrderableTimeMutationResponse>;
  /** insert a single row into the table: "orderableTime" */
  insert_orderableTime_one?: Maybe<OrderableTime>;
  /** insert data into the table: "paymentReceiptRoleTable" */
  insert_paymentReceiptRoleTable?: Maybe<PaymentReceiptRoleTableMutationResponse>;
  /** insert a single row into the table: "paymentReceiptRoleTable" */
  insert_paymentReceiptRoleTable_one?: Maybe<PaymentReceiptRoleTable>;
  /** insert data into the table: "pikaichiConfig" */
  insert_pikaichiConfig?: Maybe<PikaichiConfigMutationResponse>;
  /** insert a single row into the table: "pikaichiConfig" */
  insert_pikaichiConfig_one?: Maybe<PikaichiConfig>;
  /** insert data into the table: "pikaichiMenu" */
  insert_pikaichiMenu?: Maybe<PikaichiMenuMutationResponse>;
  /** insert data into the table: "pikaichiMenuChoice" */
  insert_pikaichiMenuChoice?: Maybe<PikaichiMenuChoiceMutationResponse>;
  /** insert a single row into the table: "pikaichiMenuChoice" */
  insert_pikaichiMenuChoice_one?: Maybe<PikaichiMenuChoice>;
  /** insert data into the table: "pikaichiMenuMenu" */
  insert_pikaichiMenuMenu?: Maybe<PikaichiMenuMenuMutationResponse>;
  /** insert a single row into the table: "pikaichiMenuMenu" */
  insert_pikaichiMenuMenu_one?: Maybe<PikaichiMenuMenu>;
  /** insert data into the table: "pikaichiMenuPlan" */
  insert_pikaichiMenuPlan?: Maybe<PikaichiMenuPlanMutationResponse>;
  /** insert data into the table: "pikaichiMenuPlanChoice" */
  insert_pikaichiMenuPlanChoice?: Maybe<PikaichiMenuPlanChoiceMutationResponse>;
  /** insert a single row into the table: "pikaichiMenuPlanChoice" */
  insert_pikaichiMenuPlanChoice_one?: Maybe<PikaichiMenuPlanChoice>;
  /** insert a single row into the table: "pikaichiMenuPlan" */
  insert_pikaichiMenuPlan_one?: Maybe<PikaichiMenuPlan>;
  /** insert a single row into the table: "pikaichiMenu" */
  insert_pikaichiMenu_one?: Maybe<PikaichiMenu>;
  /** insert data into the table: "pikaichiOnSitePaymentDetailType" */
  insert_pikaichiOnSitePaymentDetailType?: Maybe<PikaichiOnSitePaymentDetailTypeMutationResponse>;
  /** insert a single row into the table: "pikaichiOnSitePaymentDetailType" */
  insert_pikaichiOnSitePaymentDetailType_one?: Maybe<PikaichiOnSitePaymentDetailType>;
  /** insert data into the table: "pikaichiOnSitePaymentDiscountType" */
  insert_pikaichiOnSitePaymentDiscountType?: Maybe<PikaichiOnSitePaymentDiscountTypeMutationResponse>;
  /** insert a single row into the table: "pikaichiOnSitePaymentDiscountType" */
  insert_pikaichiOnSitePaymentDiscountType_one?: Maybe<PikaichiOnSitePaymentDiscountType>;
  /** insert data into the table: "plan" */
  insert_plan?: Maybe<PlanMutationResponse>;
  /** insert data into the table: "planChoice" */
  insert_planChoice?: Maybe<PlanChoiceMutationResponse>;
  /** insert a single row into the table: "planChoice" */
  insert_planChoice_one?: Maybe<PlanChoice>;
  /** insert data into the table: "planFirstOrderCategoryMenu" */
  insert_planFirstOrderCategoryMenu?: Maybe<PlanFirstOrderCategoryMenuMutationResponse>;
  /** insert a single row into the table: "planFirstOrderCategoryMenu" */
  insert_planFirstOrderCategoryMenu_one?: Maybe<PlanFirstOrderCategoryMenu>;
  /** insert data into the table: "planGroup" */
  insert_planGroup?: Maybe<PlanGroupMutationResponse>;
  /** insert a single row into the table: "planGroup" */
  insert_planGroup_one?: Maybe<PlanGroup>;
  /** insert data into the table: "planMenuCategory" */
  insert_planMenuCategory?: Maybe<PlanMenuCategoryMutationResponse>;
  /** insert a single row into the table: "planMenuCategory" */
  insert_planMenuCategory_one?: Maybe<PlanMenuCategory>;
  /** insert data into the table: "planOption" */
  insert_planOption?: Maybe<PlanOptionMutationResponse>;
  /** insert a single row into the table: "planOption" */
  insert_planOption_one?: Maybe<PlanOption>;
  /** insert data into the table: "plan_menu_categories_category" */
  insert_plan_menu_categories_category?: Maybe<PlanMenuCategoriesCategoryMutationResponse>;
  /** insert a single row into the table: "plan_menu_categories_category" */
  insert_plan_menu_categories_category_one?: Maybe<PlanMenuCategoriesCategory>;
  /** insert a single row into the table: "plan" */
  insert_plan_one?: Maybe<Plan>;
  /** insert data into the table: "questionnaire" */
  insert_questionnaire?: Maybe<QuestionnaireMutationResponse>;
  /** insert data into the table: "questionnaireConfig" */
  insert_questionnaireConfig?: Maybe<QuestionnaireConfigMutationResponse>;
  /** insert a single row into the table: "questionnaireConfig" */
  insert_questionnaireConfig_one?: Maybe<QuestionnaireConfig>;
  /** insert a single row into the table: "questionnaire" */
  insert_questionnaire_one?: Maybe<Questionnaire>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<RoleMutationResponse>;
  /** insert data into the table: "roleTablesTable" */
  insert_roleTablesTable?: Maybe<RoleTablesTableMutationResponse>;
  /** insert a single row into the table: "roleTablesTable" */
  insert_roleTablesTable_one?: Maybe<RoleTablesTable>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>;
  /** insert data into the table: "salesBudgetAllocationSetting" */
  insert_salesBudgetAllocationSetting?: Maybe<SalesBudgetAllocationSettingMutationResponse>;
  /** insert a single row into the table: "salesBudgetAllocationSetting" */
  insert_salesBudgetAllocationSetting_one?: Maybe<SalesBudgetAllocationSetting>;
  /** insert data into the table: "serviceChargeConfig" */
  insert_serviceChargeConfig?: Maybe<ServiceChargeConfigMutationResponse>;
  /** insert a single row into the table: "serviceChargeConfig" */
  insert_serviceChargeConfig_one?: Maybe<ServiceChargeConfig>;
  /** insert data into the table: "shop" */
  insert_shop?: Maybe<ShopMutationResponse>;
  /** insert data into the table: "shopBusinessOperationHour" */
  insert_shopBusinessOperationHour?: Maybe<ShopBusinessOperationHourMutationResponse>;
  /** insert a single row into the table: "shopBusinessOperationHour" */
  insert_shopBusinessOperationHour_one?: Maybe<ShopBusinessOperationHour>;
  /** insert data into the table: "shopClerk" */
  insert_shopClerk?: Maybe<ShopClerkMutationResponse>;
  /** insert a single row into the table: "shopClerk" */
  insert_shopClerk_one?: Maybe<ShopClerk>;
  /** insert data into the table: "shopClient" */
  insert_shopClient?: Maybe<ShopClientMutationResponse>;
  /** insert a single row into the table: "shopClient" */
  insert_shopClient_one?: Maybe<ShopClient>;
  /** insert data into the table: "shopCookingItemRole" */
  insert_shopCookingItemRole?: Maybe<ShopCookingItemRoleMutationResponse>;
  /** insert a single row into the table: "shopCookingItemRole" */
  insert_shopCookingItemRole_one?: Maybe<ShopCookingItemRole>;
  /** insert data into the table: "shopDiscountType" */
  insert_shopDiscountType?: Maybe<ShopDiscountTypeMutationResponse>;
  /** insert a single row into the table: "shopDiscountType" */
  insert_shopDiscountType_one?: Maybe<ShopDiscountType>;
  /** insert data into the table: "shopInflowSourceTag" */
  insert_shopInflowSourceTag?: Maybe<ShopInflowSourceTagMutationResponse>;
  /** insert a single row into the table: "shopInflowSourceTag" */
  insert_shopInflowSourceTag_one?: Maybe<ShopInflowSourceTag>;
  /** insert data into the table: "shopMenuCookingItem" */
  insert_shopMenuCookingItem?: Maybe<ShopMenuCookingItemMutationResponse>;
  /** insert a single row into the table: "shopMenuCookingItem" */
  insert_shopMenuCookingItem_one?: Maybe<ShopMenuCookingItem>;
  /** insert data into the table: "shopMenuKdDisplayTarget" */
  insert_shopMenuKdDisplayTarget?: Maybe<ShopMenuKdDisplayTargetMutationResponse>;
  /** insert a single row into the table: "shopMenuKdDisplayTarget" */
  insert_shopMenuKdDisplayTarget_one?: Maybe<ShopMenuKdDisplayTarget>;
  /** insert data into the table: "shopMenuKitchenRole" */
  insert_shopMenuKitchenRole?: Maybe<ShopMenuKitchenRoleMutationResponse>;
  /** insert a single row into the table: "shopMenuKitchenRole" */
  insert_shopMenuKitchenRole_one?: Maybe<ShopMenuKitchenRole>;
  /** insert data into the table: "shopOptionKitchenRole" */
  insert_shopOptionKitchenRole?: Maybe<ShopOptionKitchenRoleMutationResponse>;
  /** insert a single row into the table: "shopOptionKitchenRole" */
  insert_shopOptionKitchenRole_one?: Maybe<ShopOptionKitchenRole>;
  /** insert data into the table: "shopOrderableTimeTerm" */
  insert_shopOrderableTimeTerm?: Maybe<ShopOrderableTimeTermMutationResponse>;
  /** insert a single row into the table: "shopOrderableTimeTerm" */
  insert_shopOrderableTimeTerm_one?: Maybe<ShopOrderableTimeTerm>;
  /** insert data into the table: "shopPaymentType" */
  insert_shopPaymentType?: Maybe<ShopPaymentTypeMutationResponse>;
  /** insert a single row into the table: "shopPaymentType" */
  insert_shopPaymentType_one?: Maybe<ShopPaymentType>;
  /** insert data into the table: "shopPlanKitchenRole" */
  insert_shopPlanKitchenRole?: Maybe<ShopPlanKitchenRoleMutationResponse>;
  /** insert a single row into the table: "shopPlanKitchenRole" */
  insert_shopPlanKitchenRole_one?: Maybe<ShopPlanKitchenRole>;
  /** insert data into the table: "shopPlanOption" */
  insert_shopPlanOption?: Maybe<ShopPlanOptionMutationResponse>;
  /** insert data into the table: "shopPlanOptionKitchenRole" */
  insert_shopPlanOptionKitchenRole?: Maybe<ShopPlanOptionKitchenRoleMutationResponse>;
  /** insert a single row into the table: "shopPlanOptionKitchenRole" */
  insert_shopPlanOptionKitchenRole_one?: Maybe<ShopPlanOptionKitchenRole>;
  /** insert a single row into the table: "shopPlanOption" */
  insert_shopPlanOption_one?: Maybe<ShopPlanOption>;
  /** insert a single row into the table: "shop" */
  insert_shop_one?: Maybe<Shop>;
  /** insert data into the table: "specificTimeSurchargeConfig" */
  insert_specificTimeSurchargeConfig?: Maybe<SpecificTimeSurchargeConfigMutationResponse>;
  /** insert a single row into the table: "specificTimeSurchargeConfig" */
  insert_specificTimeSurchargeConfig_one?: Maybe<SpecificTimeSurchargeConfig>;
  /** insert data into the table: "steraOnSitePaymentDetailType" */
  insert_steraOnSitePaymentDetailType?: Maybe<SteraOnSitePaymentDetailTypeMutationResponse>;
  /** insert a single row into the table: "steraOnSitePaymentDetailType" */
  insert_steraOnSitePaymentDetailType_one?: Maybe<SteraOnSitePaymentDetailType>;
  /** insert data into the table: "stock" */
  insert_stock?: Maybe<StockMutationResponse>;
  /** insert a single row into the table: "stock" */
  insert_stock_one?: Maybe<Stock>;
  /** insert data into the table: "table" */
  insert_table?: Maybe<TableMutationResponse>;
  /** insert data into the table: "tableArea" */
  insert_tableArea?: Maybe<TableAreaMutationResponse>;
  /** insert a single row into the table: "tableArea" */
  insert_tableArea_one?: Maybe<TableArea>;
  /** insert a single row into the table: "table" */
  insert_table_one?: Maybe<Table>;
  /** insert data into the table: "tecAggregationConfig" */
  insert_tecAggregationConfig?: Maybe<TecAggregationConfigMutationResponse>;
  /** insert a single row into the table: "tecAggregationConfig" */
  insert_tecAggregationConfig_one?: Maybe<TecAggregationConfig>;
  /** insert data into the table: "tecAggregationMediaMap" */
  insert_tecAggregationMediaMap?: Maybe<TecAggregationMediaMapMutationResponse>;
  /** insert a single row into the table: "tecAggregationMediaMap" */
  insert_tecAggregationMediaMap_one?: Maybe<TecAggregationMediaMap>;
  /** insert data into the table: "tecAggregationMenu" */
  insert_tecAggregationMenu?: Maybe<TecAggregationMenuMutationResponse>;
  /** insert a single row into the table: "tecAggregationMenu" */
  insert_tecAggregationMenu_one?: Maybe<TecAggregationMenu>;
  /** insert data into the table: "tecAggregationOnSitePaymentDetailType" */
  insert_tecAggregationOnSitePaymentDetailType?: Maybe<TecAggregationOnSitePaymentDetailTypeMutationResponse>;
  /** insert a single row into the table: "tecAggregationOnSitePaymentDetailType" */
  insert_tecAggregationOnSitePaymentDetailType_one?: Maybe<TecAggregationOnSitePaymentDetailType>;
  /** insert data into the table: "winboardConfig" */
  insert_winboardConfig?: Maybe<WinboardConfigMutationResponse>;
  /** insert a single row into the table: "winboardConfig" */
  insert_winboardConfig_one?: Maybe<WinboardConfig>;
  /** insert data into the table: "winboardInflowSourceTag" */
  insert_winboardInflowSourceTag?: Maybe<WinboardInflowSourceTagMutationResponse>;
  /** insert a single row into the table: "winboardInflowSourceTag" */
  insert_winboardInflowSourceTag_one?: Maybe<WinboardInflowSourceTag>;
  /** insert data into the table: "winboardMenu" */
  insert_winboardMenu?: Maybe<WinboardMenuMutationResponse>;
  /** insert a single row into the table: "winboardMenu" */
  insert_winboardMenu_one?: Maybe<WinboardMenu>;
  onlinePaymentRoot?: Maybe<OnlinePaymentRootMutation>;
  signIn: Scalars['Boolean'];
  signInToDashboardAccount: Scalars['Boolean'];
  signOut: Scalars['Boolean'];
  updateLineOfficialAccount: Scalars['Boolean'];
  updateMenusBulk: Scalars['Boolean'];
  updateShopMenus: Scalars['Boolean'];
  updateShopMenusBulk: UpdateShopMenusBulkResult;
  updateShopPlansBulk: UpdateShopPlansBulkResult;
  /** update data of the table: "accountingSlipImage" */
  update_accountingSlipImage?: Maybe<AccountingSlipImageMutationResponse>;
  /** update single row of the table: "accountingSlipImage" */
  update_accountingSlipImage_by_pk?: Maybe<AccountingSlipImage>;
  /** update multiples rows of table: "accountingSlipImage" */
  update_accountingSlipImage_many?: Maybe<Array<Maybe<AccountingSlipImageMutationResponse>>>;
  /** update data of the table: "analyticsSetting" */
  update_analyticsSetting?: Maybe<AnalyticsSettingMutationResponse>;
  /** update single row of the table: "analyticsSetting" */
  update_analyticsSetting_by_pk?: Maybe<AnalyticsSetting>;
  /** update multiples rows of table: "analyticsSetting" */
  update_analyticsSetting_many?: Maybe<Array<Maybe<AnalyticsSettingMutationResponse>>>;
  /** update data of the table: "cashRegisterConfig" */
  update_cashRegisterConfig?: Maybe<CashRegisterConfigMutationResponse>;
  /** update single row of the table: "cashRegisterConfig" */
  update_cashRegisterConfig_by_pk?: Maybe<CashRegisterConfig>;
  /** update multiples rows of table: "cashRegisterConfig" */
  update_cashRegisterConfig_many?: Maybe<Array<Maybe<CashRegisterConfigMutationResponse>>>;
  /** update data of the table: "category" */
  update_category?: Maybe<CategoryMutationResponse>;
  /** update data of the table: "categoryMenu" */
  update_categoryMenu?: Maybe<CategoryMenuMutationResponse>;
  /** update single row of the table: "categoryMenu" */
  update_categoryMenu_by_pk?: Maybe<CategoryMenu>;
  /** update multiples rows of table: "categoryMenu" */
  update_categoryMenu_many?: Maybe<Array<Maybe<CategoryMenuMutationResponse>>>;
  /** update single row of the table: "category" */
  update_category_by_pk?: Maybe<Category>;
  /** update multiples rows of table: "category" */
  update_category_many?: Maybe<Array<Maybe<CategoryMutationResponse>>>;
  /** update data of the table: "choice" */
  update_choice?: Maybe<ChoiceMutationResponse>;
  /** update single row of the table: "choice" */
  update_choice_by_pk?: Maybe<Choice>;
  /** update multiples rows of table: "choice" */
  update_choice_many?: Maybe<Array<Maybe<ChoiceMutationResponse>>>;
  /** update data of the table: "clerk" */
  update_clerk?: Maybe<ClerkMutationResponse>;
  /** update single row of the table: "clerk" */
  update_clerk_by_pk?: Maybe<Clerk>;
  /** update multiples rows of table: "clerk" */
  update_clerk_many?: Maybe<Array<Maybe<ClerkMutationResponse>>>;
  /** update data of the table: "closeCashRegister" */
  update_closeCashRegister?: Maybe<CloseCashRegisterMutationResponse>;
  /** update single row of the table: "closeCashRegister" */
  update_closeCashRegister_by_pk?: Maybe<CloseCashRegister>;
  /** update multiples rows of table: "closeCashRegister" */
  update_closeCashRegister_many?: Maybe<Array<Maybe<CloseCashRegisterMutationResponse>>>;
  /** update data of the table: "coineyPayment" */
  update_coineyPayment?: Maybe<CoineyPaymentMutationResponse>;
  /** update single row of the table: "coineyPayment" */
  update_coineyPayment_by_pk?: Maybe<CoineyPayment>;
  /** update multiples rows of table: "coineyPayment" */
  update_coineyPayment_many?: Maybe<Array<Maybe<CoineyPaymentMutationResponse>>>;
  /** update data of the table: "company" */
  update_company?: Maybe<CompanyMutationResponse>;
  /** update single row of the table: "company" */
  update_company_by_pk?: Maybe<Company>;
  /** update multiples rows of table: "company" */
  update_company_many?: Maybe<Array<Maybe<CompanyMutationResponse>>>;
  /** update data of the table: "compulsoryAppetizerConfig" */
  update_compulsoryAppetizerConfig?: Maybe<CompulsoryAppetizerConfigMutationResponse>;
  /** update single row of the table: "compulsoryAppetizerConfig" */
  update_compulsoryAppetizerConfig_by_pk?: Maybe<CompulsoryAppetizerConfig>;
  /** update multiples rows of table: "compulsoryAppetizerConfig" */
  update_compulsoryAppetizerConfig_many?: Maybe<Array<Maybe<CompulsoryAppetizerConfigMutationResponse>>>;
  /** update data of the table: "connectGameConfig" */
  update_connectGameConfig?: Maybe<ConnectGameConfigMutationResponse>;
  /** update data of the table: "connectGameConfigShop" */
  update_connectGameConfigShop?: Maybe<ConnectGameConfigShopMutationResponse>;
  /** update single row of the table: "connectGameConfigShop" */
  update_connectGameConfigShop_by_pk?: Maybe<ConnectGameConfigShop>;
  /** update multiples rows of table: "connectGameConfigShop" */
  update_connectGameConfigShop_many?: Maybe<Array<Maybe<ConnectGameConfigShopMutationResponse>>>;
  /** update single row of the table: "connectGameConfig" */
  update_connectGameConfig_by_pk?: Maybe<ConnectGameConfig>;
  /** update multiples rows of table: "connectGameConfig" */
  update_connectGameConfig_many?: Maybe<Array<Maybe<ConnectGameConfigMutationResponse>>>;
  /** update data of the table: "cookingItem" */
  update_cookingItem?: Maybe<CookingItemMutationResponse>;
  /** update single row of the table: "cookingItem" */
  update_cookingItem_by_pk?: Maybe<CookingItem>;
  /** update multiples rows of table: "cookingItem" */
  update_cookingItem_many?: Maybe<Array<Maybe<CookingItemMutationResponse>>>;
  /** update data of the table: "corporation" */
  update_corporation?: Maybe<CorporationMutationResponse>;
  /** update single row of the table: "corporation" */
  update_corporation_by_pk?: Maybe<Corporation>;
  /** update multiples rows of table: "corporation" */
  update_corporation_many?: Maybe<Array<Maybe<CorporationMutationResponse>>>;
  /** update data of the table: "coupon" */
  update_coupon?: Maybe<CouponMutationResponse>;
  /** update data of the table: "couponMenuDiscount" */
  update_couponMenuDiscount?: Maybe<CouponMenuDiscountMutationResponse>;
  /** update single row of the table: "couponMenuDiscount" */
  update_couponMenuDiscount_by_pk?: Maybe<CouponMenuDiscount>;
  /** update multiples rows of table: "couponMenuDiscount" */
  update_couponMenuDiscount_many?: Maybe<Array<Maybe<CouponMenuDiscountMutationResponse>>>;
  /** update single row of the table: "coupon" */
  update_coupon_by_pk?: Maybe<Coupon>;
  /** update multiples rows of table: "coupon" */
  update_coupon_many?: Maybe<Array<Maybe<CouponMutationResponse>>>;
  /** update data of the table: "dailySalesBudget" */
  update_dailySalesBudget?: Maybe<DailySalesBudgetMutationResponse>;
  /** update single row of the table: "dailySalesBudget" */
  update_dailySalesBudget_by_pk?: Maybe<DailySalesBudget>;
  /** update multiples rows of table: "dailySalesBudget" */
  update_dailySalesBudget_many?: Maybe<Array<Maybe<DailySalesBudgetMutationResponse>>>;
  /** update data of the table: "dashboardAccountRole" */
  update_dashboardAccountRole?: Maybe<DashboardAccountRoleMutationResponse>;
  /** update data of the table: "dashboardAccountRolePermission" */
  update_dashboardAccountRolePermission?: Maybe<DashboardAccountRolePermissionMutationResponse>;
  /** update single row of the table: "dashboardAccountRolePermission" */
  update_dashboardAccountRolePermission_by_pk?: Maybe<DashboardAccountRolePermission>;
  /** update multiples rows of table: "dashboardAccountRolePermission" */
  update_dashboardAccountRolePermission_many?: Maybe<Array<Maybe<DashboardAccountRolePermissionMutationResponse>>>;
  /** update single row of the table: "dashboardAccountRole" */
  update_dashboardAccountRole_by_pk?: Maybe<DashboardAccountRole>;
  /** update multiples rows of table: "dashboardAccountRole" */
  update_dashboardAccountRole_many?: Maybe<Array<Maybe<DashboardAccountRoleMutationResponse>>>;
  /** update data of the table: "dishUpSlipGroup" */
  update_dishUpSlipGroup?: Maybe<DishUpSlipGroupMutationResponse>;
  /** update data of the table: "dishUpSlipGroupRoles" */
  update_dishUpSlipGroupRoles?: Maybe<DishUpSlipGroupRolesMutationResponse>;
  /** update single row of the table: "dishUpSlipGroupRoles" */
  update_dishUpSlipGroupRoles_by_pk?: Maybe<DishUpSlipGroupRoles>;
  /** update multiples rows of table: "dishUpSlipGroupRoles" */
  update_dishUpSlipGroupRoles_many?: Maybe<Array<Maybe<DishUpSlipGroupRolesMutationResponse>>>;
  /** update data of the table: "dishUpSlipGroupShopMenus" */
  update_dishUpSlipGroupShopMenus?: Maybe<DishUpSlipGroupShopMenusMutationResponse>;
  /** update single row of the table: "dishUpSlipGroupShopMenus" */
  update_dishUpSlipGroupShopMenus_by_pk?: Maybe<DishUpSlipGroupShopMenus>;
  /** update multiples rows of table: "dishUpSlipGroupShopMenus" */
  update_dishUpSlipGroupShopMenus_many?: Maybe<Array<Maybe<DishUpSlipGroupShopMenusMutationResponse>>>;
  /** update data of the table: "dishUpSlipGroupShopPlans" */
  update_dishUpSlipGroupShopPlans?: Maybe<DishUpSlipGroupShopPlansMutationResponse>;
  /** update single row of the table: "dishUpSlipGroupShopPlans" */
  update_dishUpSlipGroupShopPlans_by_pk?: Maybe<DishUpSlipGroupShopPlans>;
  /** update multiples rows of table: "dishUpSlipGroupShopPlans" */
  update_dishUpSlipGroupShopPlans_many?: Maybe<Array<Maybe<DishUpSlipGroupShopPlansMutationResponse>>>;
  /** update single row of the table: "dishUpSlipGroup" */
  update_dishUpSlipGroup_by_pk?: Maybe<DishUpSlipGroup>;
  /** update multiples rows of table: "dishUpSlipGroup" */
  update_dishUpSlipGroup_many?: Maybe<Array<Maybe<DishUpSlipGroupMutationResponse>>>;
  /** update data of the table: "ebicaConfig" */
  update_ebicaConfig?: Maybe<EbicaConfigMutationResponse>;
  /** update single row of the table: "ebicaConfig" */
  update_ebicaConfig_by_pk?: Maybe<EbicaConfig>;
  /** update multiples rows of table: "ebicaConfig" */
  update_ebicaConfig_many?: Maybe<Array<Maybe<EbicaConfigMutationResponse>>>;
  /** update data of the table: "externalOnlineMenu" */
  update_externalOnlineMenu?: Maybe<ExternalOnlineMenuMutationResponse>;
  /** update data of the table: "externalOnlineMenuAvailableTimeTerm" */
  update_externalOnlineMenuAvailableTimeTerm?: Maybe<ExternalOnlineMenuAvailableTimeTermMutationResponse>;
  /** update single row of the table: "externalOnlineMenuAvailableTimeTerm" */
  update_externalOnlineMenuAvailableTimeTerm_by_pk?: Maybe<ExternalOnlineMenuAvailableTimeTerm>;
  /** update multiples rows of table: "externalOnlineMenuAvailableTimeTerm" */
  update_externalOnlineMenuAvailableTimeTerm_many?: Maybe<Array<Maybe<ExternalOnlineMenuAvailableTimeTermMutationResponse>>>;
  /** update data of the table: "externalOnlineMenuConfig" */
  update_externalOnlineMenuConfig?: Maybe<ExternalOnlineMenuConfigMutationResponse>;
  /** update single row of the table: "externalOnlineMenuConfig" */
  update_externalOnlineMenuConfig_by_pk?: Maybe<ExternalOnlineMenuConfig>;
  /** update multiples rows of table: "externalOnlineMenuConfig" */
  update_externalOnlineMenuConfig_many?: Maybe<Array<Maybe<ExternalOnlineMenuConfigMutationResponse>>>;
  /** update single row of the table: "externalOnlineMenu" */
  update_externalOnlineMenu_by_pk?: Maybe<ExternalOnlineMenu>;
  /** update multiples rows of table: "externalOnlineMenu" */
  update_externalOnlineMenu_many?: Maybe<Array<Maybe<ExternalOnlineMenuMutationResponse>>>;
  /** update data of the table: "featureFlagShop" */
  update_featureFlagShop?: Maybe<FeatureFlagShopMutationResponse>;
  /** update single row of the table: "featureFlagShop" */
  update_featureFlagShop_by_pk?: Maybe<FeatureFlagShop>;
  /** update multiples rows of table: "featureFlagShop" */
  update_featureFlagShop_many?: Maybe<Array<Maybe<FeatureFlagShopMutationResponse>>>;
  /** update data of the table: "foodingJournalConfig" */
  update_foodingJournalConfig?: Maybe<FoodingJournalConfigMutationResponse>;
  /** update single row of the table: "foodingJournalConfig" */
  update_foodingJournalConfig_by_pk?: Maybe<FoodingJournalConfig>;
  /** update multiples rows of table: "foodingJournalConfig" */
  update_foodingJournalConfig_many?: Maybe<Array<Maybe<FoodingJournalConfigMutationResponse>>>;
  /** update data of the table: "foodingJournalDepartmentMaster" */
  update_foodingJournalDepartmentMaster?: Maybe<FoodingJournalDepartmentMasterMutationResponse>;
  /** update single row of the table: "foodingJournalDepartmentMaster" */
  update_foodingJournalDepartmentMaster_by_pk?: Maybe<FoodingJournalDepartmentMaster>;
  /** update multiples rows of table: "foodingJournalDepartmentMaster" */
  update_foodingJournalDepartmentMaster_many?: Maybe<Array<Maybe<FoodingJournalDepartmentMasterMutationResponse>>>;
  /** update data of the table: "foodingJournalGroupMaster" */
  update_foodingJournalGroupMaster?: Maybe<FoodingJournalGroupMasterMutationResponse>;
  /** update single row of the table: "foodingJournalGroupMaster" */
  update_foodingJournalGroupMaster_by_pk?: Maybe<FoodingJournalGroupMaster>;
  /** update multiples rows of table: "foodingJournalGroupMaster" */
  update_foodingJournalGroupMaster_many?: Maybe<Array<Maybe<FoodingJournalGroupMasterMutationResponse>>>;
  /** update data of the table: "foodingJournalMenu" */
  update_foodingJournalMenu?: Maybe<FoodingJournalMenuMutationResponse>;
  /** update single row of the table: "foodingJournalMenu" */
  update_foodingJournalMenu_by_pk?: Maybe<FoodingJournalMenu>;
  /** update multiples rows of table: "foodingJournalMenu" */
  update_foodingJournalMenu_many?: Maybe<Array<Maybe<FoodingJournalMenuMutationResponse>>>;
  /** update data of the table: "foodingJournalOnSitePaymentDetailType" */
  update_foodingJournalOnSitePaymentDetailType?: Maybe<FoodingJournalOnSitePaymentDetailTypeMutationResponse>;
  /** update single row of the table: "foodingJournalOnSitePaymentDetailType" */
  update_foodingJournalOnSitePaymentDetailType_by_pk?: Maybe<FoodingJournalOnSitePaymentDetailType>;
  /** update multiples rows of table: "foodingJournalOnSitePaymentDetailType" */
  update_foodingJournalOnSitePaymentDetailType_many?: Maybe<Array<Maybe<FoodingJournalOnSitePaymentDetailTypeMutationResponse>>>;
  /** update data of the table: "foodingJournalOnSitePaymentDiscountType" */
  update_foodingJournalOnSitePaymentDiscountType?: Maybe<FoodingJournalOnSitePaymentDiscountTypeMutationResponse>;
  /** update single row of the table: "foodingJournalOnSitePaymentDiscountType" */
  update_foodingJournalOnSitePaymentDiscountType_by_pk?: Maybe<FoodingJournalOnSitePaymentDiscountType>;
  /** update multiples rows of table: "foodingJournalOnSitePaymentDiscountType" */
  update_foodingJournalOnSitePaymentDiscountType_many?: Maybe<Array<Maybe<FoodingJournalOnSitePaymentDiscountTypeMutationResponse>>>;
  /** update data of the table: "giftMeta" */
  update_giftMeta?: Maybe<GiftMetaMutationResponse>;
  /** update single row of the table: "giftMeta" */
  update_giftMeta_by_pk?: Maybe<GiftMeta>;
  /** update multiples rows of table: "giftMeta" */
  update_giftMeta_many?: Maybe<Array<Maybe<GiftMetaMutationResponse>>>;
  /** update data of the table: "gmoOffPaymentShopConfig" */
  update_gmoOffPaymentShopConfig?: Maybe<GmoOffPaymentShopConfigMutationResponse>;
  /** update data of the table: "gmoOffPaymentShopConfigTransactionFeeRate" */
  update_gmoOffPaymentShopConfigTransactionFeeRate?: Maybe<GmoOffPaymentShopConfigTransactionFeeRateMutationResponse>;
  /** update single row of the table: "gmoOffPaymentShopConfigTransactionFeeRate" */
  update_gmoOffPaymentShopConfigTransactionFeeRate_by_pk?: Maybe<GmoOffPaymentShopConfigTransactionFeeRate>;
  /** update multiples rows of table: "gmoOffPaymentShopConfigTransactionFeeRate" */
  update_gmoOffPaymentShopConfigTransactionFeeRate_many?: Maybe<Array<Maybe<GmoOffPaymentShopConfigTransactionFeeRateMutationResponse>>>;
  /** update single row of the table: "gmoOffPaymentShopConfig" */
  update_gmoOffPaymentShopConfig_by_pk?: Maybe<GmoOffPaymentShopConfig>;
  /** update multiples rows of table: "gmoOffPaymentShopConfig" */
  update_gmoOffPaymentShopConfig_many?: Maybe<Array<Maybe<GmoOffPaymentShopConfigMutationResponse>>>;
  /** update data of the table: "inflowSourceTag" */
  update_inflowSourceTag?: Maybe<InflowSourceTagMutationResponse>;
  /** update single row of the table: "inflowSourceTag" */
  update_inflowSourceTag_by_pk?: Maybe<InflowSourceTag>;
  /** update multiples rows of table: "inflowSourceTag" */
  update_inflowSourceTag_many?: Maybe<Array<Maybe<InflowSourceTagMutationResponse>>>;
  /** update data of the table: "kdDisplayTarget" */
  update_kdDisplayTarget?: Maybe<KdDisplayTargetMutationResponse>;
  /** update single row of the table: "kdDisplayTarget" */
  update_kdDisplayTarget_by_pk?: Maybe<KdDisplayTarget>;
  /** update multiples rows of table: "kdDisplayTarget" */
  update_kdDisplayTarget_many?: Maybe<Array<Maybe<KdDisplayTargetMutationResponse>>>;
  /** update data of the table: "lineChannelConfig" */
  update_lineChannelConfig?: Maybe<LineChannelConfigMutationResponse>;
  /** update single row of the table: "lineChannelConfig" */
  update_lineChannelConfig_by_pk?: Maybe<LineChannelConfig>;
  /** update multiples rows of table: "lineChannelConfig" */
  update_lineChannelConfig_many?: Maybe<Array<Maybe<LineChannelConfigMutationResponse>>>;
  /** update data of the table: "lineReportingBotConfig" */
  update_lineReportingBotConfig?: Maybe<LineReportingBotConfigMutationResponse>;
  /** update single row of the table: "lineReportingBotConfig" */
  update_lineReportingBotConfig_by_pk?: Maybe<LineReportingBotConfig>;
  /** update multiples rows of table: "lineReportingBotConfig" */
  update_lineReportingBotConfig_many?: Maybe<Array<Maybe<LineReportingBotConfigMutationResponse>>>;
  /** update data of the table: "membershipCard" */
  update_membershipCard?: Maybe<MembershipCardMutationResponse>;
  /** update data of the table: "membershipCardTitle" */
  update_membershipCardTitle?: Maybe<MembershipCardTitleMutationResponse>;
  /** update single row of the table: "membershipCardTitle" */
  update_membershipCardTitle_by_pk?: Maybe<MembershipCardTitle>;
  /** update multiples rows of table: "membershipCardTitle" */
  update_membershipCardTitle_many?: Maybe<Array<Maybe<MembershipCardTitleMutationResponse>>>;
  /** update single row of the table: "membershipCard" */
  update_membershipCard_by_pk?: Maybe<MembershipCard>;
  /** update multiples rows of table: "membershipCard" */
  update_membershipCard_many?: Maybe<Array<Maybe<MembershipCardMutationResponse>>>;
  /** update data of the table: "membershipRankConfig" */
  update_membershipRankConfig?: Maybe<MembershipRankConfigMutationResponse>;
  /** update single row of the table: "membershipRankConfig" */
  update_membershipRankConfig_by_pk?: Maybe<MembershipRankConfig>;
  /** update multiples rows of table: "membershipRankConfig" */
  update_membershipRankConfig_many?: Maybe<Array<Maybe<MembershipRankConfigMutationResponse>>>;
  /** update data of the table: "menu" */
  update_menu?: Maybe<MenuMutationResponse>;
  /** update data of the table: "menuOption" */
  update_menuOption?: Maybe<MenuOptionMutationResponse>;
  /** update single row of the table: "menuOption" */
  update_menuOption_by_pk?: Maybe<MenuOption>;
  /** update multiples rows of table: "menuOption" */
  update_menuOption_many?: Maybe<Array<Maybe<MenuOptionMutationResponse>>>;
  /** update data of the table: "menuRecommendationMeta" */
  update_menuRecommendationMeta?: Maybe<MenuRecommendationMetaMutationResponse>;
  /** update single row of the table: "menuRecommendationMeta" */
  update_menuRecommendationMeta_by_pk?: Maybe<MenuRecommendationMeta>;
  /** update multiples rows of table: "menuRecommendationMeta" */
  update_menuRecommendationMeta_many?: Maybe<Array<Maybe<MenuRecommendationMetaMutationResponse>>>;
  /** update single row of the table: "menu" */
  update_menu_by_pk?: Maybe<Menu>;
  /** update multiples rows of table: "menu" */
  update_menu_many?: Maybe<Array<Maybe<MenuMutationResponse>>>;
  /** update data of the table: "messageDelivery" */
  update_messageDelivery?: Maybe<MessageDeliveryMutationResponse>;
  /** update single row of the table: "messageDelivery" */
  update_messageDelivery_by_pk?: Maybe<MessageDelivery>;
  /** update multiples rows of table: "messageDelivery" */
  update_messageDelivery_many?: Maybe<Array<Maybe<MessageDeliveryMutationResponse>>>;
  /** update data of the table: "monthlySalesBudget" */
  update_monthlySalesBudget?: Maybe<MonthlySalesBudgetMutationResponse>;
  /** update single row of the table: "monthlySalesBudget" */
  update_monthlySalesBudget_by_pk?: Maybe<MonthlySalesBudget>;
  /** update multiples rows of table: "monthlySalesBudget" */
  update_monthlySalesBudget_many?: Maybe<Array<Maybe<MonthlySalesBudgetMutationResponse>>>;
  /** update data of the table: "onSitePaymentDetailType" */
  update_onSitePaymentDetailType?: Maybe<OnSitePaymentDetailTypeMutationResponse>;
  /** update single row of the table: "onSitePaymentDetailType" */
  update_onSitePaymentDetailType_by_pk?: Maybe<OnSitePaymentDetailType>;
  /** update multiples rows of table: "onSitePaymentDetailType" */
  update_onSitePaymentDetailType_many?: Maybe<Array<Maybe<OnSitePaymentDetailTypeMutationResponse>>>;
  /** update data of the table: "onSitePaymentDiscountType" */
  update_onSitePaymentDiscountType?: Maybe<OnSitePaymentDiscountTypeMutationResponse>;
  /** update single row of the table: "onSitePaymentDiscountType" */
  update_onSitePaymentDiscountType_by_pk?: Maybe<OnSitePaymentDiscountType>;
  /** update multiples rows of table: "onSitePaymentDiscountType" */
  update_onSitePaymentDiscountType_many?: Maybe<Array<Maybe<OnSitePaymentDiscountTypeMutationResponse>>>;
  /** update data of the table: "option" */
  update_option?: Maybe<OptionMutationResponse>;
  /** update data of the table: "optionInputType" */
  update_optionInputType?: Maybe<OptionInputTypeMutationResponse>;
  /** update single row of the table: "optionInputType" */
  update_optionInputType_by_pk?: Maybe<OptionInputType>;
  /** update multiples rows of table: "optionInputType" */
  update_optionInputType_many?: Maybe<Array<Maybe<OptionInputTypeMutationResponse>>>;
  /** update single row of the table: "option" */
  update_option_by_pk?: Maybe<Option>;
  /** update multiples rows of table: "option" */
  update_option_many?: Maybe<Array<Maybe<OptionMutationResponse>>>;
  /** update data of the table: "orderableTime" */
  update_orderableTime?: Maybe<OrderableTimeMutationResponse>;
  /** update single row of the table: "orderableTime" */
  update_orderableTime_by_pk?: Maybe<OrderableTime>;
  /** update multiples rows of table: "orderableTime" */
  update_orderableTime_many?: Maybe<Array<Maybe<OrderableTimeMutationResponse>>>;
  /** update data of the table: "paymentReceiptRoleTable" */
  update_paymentReceiptRoleTable?: Maybe<PaymentReceiptRoleTableMutationResponse>;
  /** update multiples rows of table: "paymentReceiptRoleTable" */
  update_paymentReceiptRoleTable_many?: Maybe<Array<Maybe<PaymentReceiptRoleTableMutationResponse>>>;
  /** update data of the table: "pikaichiConfig" */
  update_pikaichiConfig?: Maybe<PikaichiConfigMutationResponse>;
  /** update single row of the table: "pikaichiConfig" */
  update_pikaichiConfig_by_pk?: Maybe<PikaichiConfig>;
  /** update multiples rows of table: "pikaichiConfig" */
  update_pikaichiConfig_many?: Maybe<Array<Maybe<PikaichiConfigMutationResponse>>>;
  /** update data of the table: "pikaichiMenu" */
  update_pikaichiMenu?: Maybe<PikaichiMenuMutationResponse>;
  /** update data of the table: "pikaichiMenuChoice" */
  update_pikaichiMenuChoice?: Maybe<PikaichiMenuChoiceMutationResponse>;
  /** update single row of the table: "pikaichiMenuChoice" */
  update_pikaichiMenuChoice_by_pk?: Maybe<PikaichiMenuChoice>;
  /** update multiples rows of table: "pikaichiMenuChoice" */
  update_pikaichiMenuChoice_many?: Maybe<Array<Maybe<PikaichiMenuChoiceMutationResponse>>>;
  /** update data of the table: "pikaichiMenuMenu" */
  update_pikaichiMenuMenu?: Maybe<PikaichiMenuMenuMutationResponse>;
  /** update single row of the table: "pikaichiMenuMenu" */
  update_pikaichiMenuMenu_by_pk?: Maybe<PikaichiMenuMenu>;
  /** update multiples rows of table: "pikaichiMenuMenu" */
  update_pikaichiMenuMenu_many?: Maybe<Array<Maybe<PikaichiMenuMenuMutationResponse>>>;
  /** update data of the table: "pikaichiMenuPlan" */
  update_pikaichiMenuPlan?: Maybe<PikaichiMenuPlanMutationResponse>;
  /** update data of the table: "pikaichiMenuPlanChoice" */
  update_pikaichiMenuPlanChoice?: Maybe<PikaichiMenuPlanChoiceMutationResponse>;
  /** update single row of the table: "pikaichiMenuPlanChoice" */
  update_pikaichiMenuPlanChoice_by_pk?: Maybe<PikaichiMenuPlanChoice>;
  /** update multiples rows of table: "pikaichiMenuPlanChoice" */
  update_pikaichiMenuPlanChoice_many?: Maybe<Array<Maybe<PikaichiMenuPlanChoiceMutationResponse>>>;
  /** update single row of the table: "pikaichiMenuPlan" */
  update_pikaichiMenuPlan_by_pk?: Maybe<PikaichiMenuPlan>;
  /** update multiples rows of table: "pikaichiMenuPlan" */
  update_pikaichiMenuPlan_many?: Maybe<Array<Maybe<PikaichiMenuPlanMutationResponse>>>;
  /** update single row of the table: "pikaichiMenu" */
  update_pikaichiMenu_by_pk?: Maybe<PikaichiMenu>;
  /** update multiples rows of table: "pikaichiMenu" */
  update_pikaichiMenu_many?: Maybe<Array<Maybe<PikaichiMenuMutationResponse>>>;
  /** update data of the table: "pikaichiOnSitePaymentDetailType" */
  update_pikaichiOnSitePaymentDetailType?: Maybe<PikaichiOnSitePaymentDetailTypeMutationResponse>;
  /** update single row of the table: "pikaichiOnSitePaymentDetailType" */
  update_pikaichiOnSitePaymentDetailType_by_pk?: Maybe<PikaichiOnSitePaymentDetailType>;
  /** update multiples rows of table: "pikaichiOnSitePaymentDetailType" */
  update_pikaichiOnSitePaymentDetailType_many?: Maybe<Array<Maybe<PikaichiOnSitePaymentDetailTypeMutationResponse>>>;
  /** update data of the table: "pikaichiOnSitePaymentDiscountType" */
  update_pikaichiOnSitePaymentDiscountType?: Maybe<PikaichiOnSitePaymentDiscountTypeMutationResponse>;
  /** update single row of the table: "pikaichiOnSitePaymentDiscountType" */
  update_pikaichiOnSitePaymentDiscountType_by_pk?: Maybe<PikaichiOnSitePaymentDiscountType>;
  /** update multiples rows of table: "pikaichiOnSitePaymentDiscountType" */
  update_pikaichiOnSitePaymentDiscountType_many?: Maybe<Array<Maybe<PikaichiOnSitePaymentDiscountTypeMutationResponse>>>;
  /** update data of the table: "plan" */
  update_plan?: Maybe<PlanMutationResponse>;
  /** update data of the table: "planChoice" */
  update_planChoice?: Maybe<PlanChoiceMutationResponse>;
  /** update single row of the table: "planChoice" */
  update_planChoice_by_pk?: Maybe<PlanChoice>;
  /** update multiples rows of table: "planChoice" */
  update_planChoice_many?: Maybe<Array<Maybe<PlanChoiceMutationResponse>>>;
  /** update data of the table: "planFirstOrderCategoryMenu" */
  update_planFirstOrderCategoryMenu?: Maybe<PlanFirstOrderCategoryMenuMutationResponse>;
  /** update single row of the table: "planFirstOrderCategoryMenu" */
  update_planFirstOrderCategoryMenu_by_pk?: Maybe<PlanFirstOrderCategoryMenu>;
  /** update multiples rows of table: "planFirstOrderCategoryMenu" */
  update_planFirstOrderCategoryMenu_many?: Maybe<Array<Maybe<PlanFirstOrderCategoryMenuMutationResponse>>>;
  /** update data of the table: "planGroup" */
  update_planGroup?: Maybe<PlanGroupMutationResponse>;
  /** update single row of the table: "planGroup" */
  update_planGroup_by_pk?: Maybe<PlanGroup>;
  /** update multiples rows of table: "planGroup" */
  update_planGroup_many?: Maybe<Array<Maybe<PlanGroupMutationResponse>>>;
  /** update data of the table: "planMenuCategory" */
  update_planMenuCategory?: Maybe<PlanMenuCategoryMutationResponse>;
  /** update single row of the table: "planMenuCategory" */
  update_planMenuCategory_by_pk?: Maybe<PlanMenuCategory>;
  /** update multiples rows of table: "planMenuCategory" */
  update_planMenuCategory_many?: Maybe<Array<Maybe<PlanMenuCategoryMutationResponse>>>;
  /** update data of the table: "planOption" */
  update_planOption?: Maybe<PlanOptionMutationResponse>;
  /** update single row of the table: "planOption" */
  update_planOption_by_pk?: Maybe<PlanOption>;
  /** update multiples rows of table: "planOption" */
  update_planOption_many?: Maybe<Array<Maybe<PlanOptionMutationResponse>>>;
  /** update single row of the table: "plan" */
  update_plan_by_pk?: Maybe<Plan>;
  /** update multiples rows of table: "plan" */
  update_plan_many?: Maybe<Array<Maybe<PlanMutationResponse>>>;
  /** update data of the table: "plan_menu_categories_category" */
  update_plan_menu_categories_category?: Maybe<PlanMenuCategoriesCategoryMutationResponse>;
  /** update multiples rows of table: "plan_menu_categories_category" */
  update_plan_menu_categories_category_many?: Maybe<Array<Maybe<PlanMenuCategoriesCategoryMutationResponse>>>;
  /** update data of the table: "questionnaire" */
  update_questionnaire?: Maybe<QuestionnaireMutationResponse>;
  /** update data of the table: "questionnaireConfig" */
  update_questionnaireConfig?: Maybe<QuestionnaireConfigMutationResponse>;
  /** update single row of the table: "questionnaireConfig" */
  update_questionnaireConfig_by_pk?: Maybe<QuestionnaireConfig>;
  /** update multiples rows of table: "questionnaireConfig" */
  update_questionnaireConfig_many?: Maybe<Array<Maybe<QuestionnaireConfigMutationResponse>>>;
  /** update single row of the table: "questionnaire" */
  update_questionnaire_by_pk?: Maybe<Questionnaire>;
  /** update multiples rows of table: "questionnaire" */
  update_questionnaire_many?: Maybe<Array<Maybe<QuestionnaireMutationResponse>>>;
  /** update data of the table: "role" */
  update_role?: Maybe<RoleMutationResponse>;
  /** update data of the table: "roleTablesTable" */
  update_roleTablesTable?: Maybe<RoleTablesTableMutationResponse>;
  /** update multiples rows of table: "roleTablesTable" */
  update_roleTablesTable_many?: Maybe<Array<Maybe<RoleTablesTableMutationResponse>>>;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>;
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<RoleMutationResponse>>>;
  /** update data of the table: "salesBudgetAllocationSetting" */
  update_salesBudgetAllocationSetting?: Maybe<SalesBudgetAllocationSettingMutationResponse>;
  /** update single row of the table: "salesBudgetAllocationSetting" */
  update_salesBudgetAllocationSetting_by_pk?: Maybe<SalesBudgetAllocationSetting>;
  /** update multiples rows of table: "salesBudgetAllocationSetting" */
  update_salesBudgetAllocationSetting_many?: Maybe<Array<Maybe<SalesBudgetAllocationSettingMutationResponse>>>;
  /** update data of the table: "serviceChargeConfig" */
  update_serviceChargeConfig?: Maybe<ServiceChargeConfigMutationResponse>;
  /** update single row of the table: "serviceChargeConfig" */
  update_serviceChargeConfig_by_pk?: Maybe<ServiceChargeConfig>;
  /** update multiples rows of table: "serviceChargeConfig" */
  update_serviceChargeConfig_many?: Maybe<Array<Maybe<ServiceChargeConfigMutationResponse>>>;
  /** update data of the table: "shop" */
  update_shop?: Maybe<ShopMutationResponse>;
  /** update data of the table: "shopBusinessOperationHour" */
  update_shopBusinessOperationHour?: Maybe<ShopBusinessOperationHourMutationResponse>;
  /** update single row of the table: "shopBusinessOperationHour" */
  update_shopBusinessOperationHour_by_pk?: Maybe<ShopBusinessOperationHour>;
  /** update multiples rows of table: "shopBusinessOperationHour" */
  update_shopBusinessOperationHour_many?: Maybe<Array<Maybe<ShopBusinessOperationHourMutationResponse>>>;
  /** update data of the table: "shopClerk" */
  update_shopClerk?: Maybe<ShopClerkMutationResponse>;
  /** update single row of the table: "shopClerk" */
  update_shopClerk_by_pk?: Maybe<ShopClerk>;
  /** update multiples rows of table: "shopClerk" */
  update_shopClerk_many?: Maybe<Array<Maybe<ShopClerkMutationResponse>>>;
  /** update data of the table: "shopClient" */
  update_shopClient?: Maybe<ShopClientMutationResponse>;
  /** update single row of the table: "shopClient" */
  update_shopClient_by_pk?: Maybe<ShopClient>;
  /** update multiples rows of table: "shopClient" */
  update_shopClient_many?: Maybe<Array<Maybe<ShopClientMutationResponse>>>;
  /** update data of the table: "shopCookingItemRole" */
  update_shopCookingItemRole?: Maybe<ShopCookingItemRoleMutationResponse>;
  /** update single row of the table: "shopCookingItemRole" */
  update_shopCookingItemRole_by_pk?: Maybe<ShopCookingItemRole>;
  /** update multiples rows of table: "shopCookingItemRole" */
  update_shopCookingItemRole_many?: Maybe<Array<Maybe<ShopCookingItemRoleMutationResponse>>>;
  /** update data of the table: "shopDiscountType" */
  update_shopDiscountType?: Maybe<ShopDiscountTypeMutationResponse>;
  /** update single row of the table: "shopDiscountType" */
  update_shopDiscountType_by_pk?: Maybe<ShopDiscountType>;
  /** update multiples rows of table: "shopDiscountType" */
  update_shopDiscountType_many?: Maybe<Array<Maybe<ShopDiscountTypeMutationResponse>>>;
  /** update data of the table: "shopInflowSourceTag" */
  update_shopInflowSourceTag?: Maybe<ShopInflowSourceTagMutationResponse>;
  /** update multiples rows of table: "shopInflowSourceTag" */
  update_shopInflowSourceTag_many?: Maybe<Array<Maybe<ShopInflowSourceTagMutationResponse>>>;
  /** update data of the table: "shopMenu" */
  update_shopMenu?: Maybe<ShopMenuMutationResponse>;
  /** update data of the table: "shopMenuCookingItem" */
  update_shopMenuCookingItem?: Maybe<ShopMenuCookingItemMutationResponse>;
  /** update multiples rows of table: "shopMenuCookingItem" */
  update_shopMenuCookingItem_many?: Maybe<Array<Maybe<ShopMenuCookingItemMutationResponse>>>;
  /** update data of the table: "shopMenuKdDisplayTarget" */
  update_shopMenuKdDisplayTarget?: Maybe<ShopMenuKdDisplayTargetMutationResponse>;
  /** update single row of the table: "shopMenuKdDisplayTarget" */
  update_shopMenuKdDisplayTarget_by_pk?: Maybe<ShopMenuKdDisplayTarget>;
  /** update multiples rows of table: "shopMenuKdDisplayTarget" */
  update_shopMenuKdDisplayTarget_many?: Maybe<Array<Maybe<ShopMenuKdDisplayTargetMutationResponse>>>;
  /** update data of the table: "shopMenuKitchenRole" */
  update_shopMenuKitchenRole?: Maybe<ShopMenuKitchenRoleMutationResponse>;
  /** update multiples rows of table: "shopMenuKitchenRole" */
  update_shopMenuKitchenRole_many?: Maybe<Array<Maybe<ShopMenuKitchenRoleMutationResponse>>>;
  /** update single row of the table: "shopMenu" */
  update_shopMenu_by_pk?: Maybe<ShopMenu>;
  /** update multiples rows of table: "shopMenu" */
  update_shopMenu_many?: Maybe<Array<Maybe<ShopMenuMutationResponse>>>;
  /** update data of the table: "shopOrderableTimeTerm" */
  update_shopOrderableTimeTerm?: Maybe<ShopOrderableTimeTermMutationResponse>;
  /** update single row of the table: "shopOrderableTimeTerm" */
  update_shopOrderableTimeTerm_by_pk?: Maybe<ShopOrderableTimeTerm>;
  /** update multiples rows of table: "shopOrderableTimeTerm" */
  update_shopOrderableTimeTerm_many?: Maybe<Array<Maybe<ShopOrderableTimeTermMutationResponse>>>;
  /** update data of the table: "shopPaymentType" */
  update_shopPaymentType?: Maybe<ShopPaymentTypeMutationResponse>;
  /** update single row of the table: "shopPaymentType" */
  update_shopPaymentType_by_pk?: Maybe<ShopPaymentType>;
  /** update multiples rows of table: "shopPaymentType" */
  update_shopPaymentType_many?: Maybe<Array<Maybe<ShopPaymentTypeMutationResponse>>>;
  /** update data of the table: "shopPlan" */
  update_shopPlan?: Maybe<ShopPlanMutationResponse>;
  /** update data of the table: "shopPlanChoice" */
  update_shopPlanChoice?: Maybe<ShopPlanChoiceMutationResponse>;
  /** update single row of the table: "shopPlanChoice" */
  update_shopPlanChoice_by_pk?: Maybe<ShopPlanChoice>;
  /** update multiples rows of table: "shopPlanChoice" */
  update_shopPlanChoice_many?: Maybe<Array<Maybe<ShopPlanChoiceMutationResponse>>>;
  /** update data of the table: "shopPlanKitchenRole" */
  update_shopPlanKitchenRole?: Maybe<ShopPlanKitchenRoleMutationResponse>;
  /** update multiples rows of table: "shopPlanKitchenRole" */
  update_shopPlanKitchenRole_many?: Maybe<Array<Maybe<ShopPlanKitchenRoleMutationResponse>>>;
  /** update data of the table: "shopPlanOption" */
  update_shopPlanOption?: Maybe<ShopPlanOptionMutationResponse>;
  /** update data of the table: "shopPlanOptionKitchenRole" */
  update_shopPlanOptionKitchenRole?: Maybe<ShopPlanOptionKitchenRoleMutationResponse>;
  /** update multiples rows of table: "shopPlanOptionKitchenRole" */
  update_shopPlanOptionKitchenRole_many?: Maybe<Array<Maybe<ShopPlanOptionKitchenRoleMutationResponse>>>;
  /** update single row of the table: "shopPlanOption" */
  update_shopPlanOption_by_pk?: Maybe<ShopPlanOption>;
  /** update multiples rows of table: "shopPlanOption" */
  update_shopPlanOption_many?: Maybe<Array<Maybe<ShopPlanOptionMutationResponse>>>;
  /** update single row of the table: "shopPlan" */
  update_shopPlan_by_pk?: Maybe<ShopPlan>;
  /** update multiples rows of table: "shopPlan" */
  update_shopPlan_many?: Maybe<Array<Maybe<ShopPlanMutationResponse>>>;
  /** update single row of the table: "shop" */
  update_shop_by_pk?: Maybe<Shop>;
  /** update multiples rows of table: "shop" */
  update_shop_many?: Maybe<Array<Maybe<ShopMutationResponse>>>;
  /** update data of the table: "specificTimeSurchargeConfig" */
  update_specificTimeSurchargeConfig?: Maybe<SpecificTimeSurchargeConfigMutationResponse>;
  /** update single row of the table: "specificTimeSurchargeConfig" */
  update_specificTimeSurchargeConfig_by_pk?: Maybe<SpecificTimeSurchargeConfig>;
  /** update multiples rows of table: "specificTimeSurchargeConfig" */
  update_specificTimeSurchargeConfig_many?: Maybe<Array<Maybe<SpecificTimeSurchargeConfigMutationResponse>>>;
  /** update data of the table: "steraOnSitePaymentDetailType" */
  update_steraOnSitePaymentDetailType?: Maybe<SteraOnSitePaymentDetailTypeMutationResponse>;
  /** update single row of the table: "steraOnSitePaymentDetailType" */
  update_steraOnSitePaymentDetailType_by_pk?: Maybe<SteraOnSitePaymentDetailType>;
  /** update multiples rows of table: "steraOnSitePaymentDetailType" */
  update_steraOnSitePaymentDetailType_many?: Maybe<Array<Maybe<SteraOnSitePaymentDetailTypeMutationResponse>>>;
  /** update data of the table: "stock" */
  update_stock?: Maybe<StockMutationResponse>;
  /** update single row of the table: "stock" */
  update_stock_by_pk?: Maybe<Stock>;
  /** update multiples rows of table: "stock" */
  update_stock_many?: Maybe<Array<Maybe<StockMutationResponse>>>;
  /** update data of the table: "table" */
  update_table?: Maybe<TableMutationResponse>;
  /** update data of the table: "tableArea" */
  update_tableArea?: Maybe<TableAreaMutationResponse>;
  /** update single row of the table: "tableArea" */
  update_tableArea_by_pk?: Maybe<TableArea>;
  /** update multiples rows of table: "tableArea" */
  update_tableArea_many?: Maybe<Array<Maybe<TableAreaMutationResponse>>>;
  /** update single row of the table: "table" */
  update_table_by_pk?: Maybe<Table>;
  /** update multiples rows of table: "table" */
  update_table_many?: Maybe<Array<Maybe<TableMutationResponse>>>;
  /** update data of the table: "tecAggregationConfig" */
  update_tecAggregationConfig?: Maybe<TecAggregationConfigMutationResponse>;
  /** update single row of the table: "tecAggregationConfig" */
  update_tecAggregationConfig_by_pk?: Maybe<TecAggregationConfig>;
  /** update multiples rows of table: "tecAggregationConfig" */
  update_tecAggregationConfig_many?: Maybe<Array<Maybe<TecAggregationConfigMutationResponse>>>;
  /** update data of the table: "tecAggregationMediaMap" */
  update_tecAggregationMediaMap?: Maybe<TecAggregationMediaMapMutationResponse>;
  /** update single row of the table: "tecAggregationMediaMap" */
  update_tecAggregationMediaMap_by_pk?: Maybe<TecAggregationMediaMap>;
  /** update multiples rows of table: "tecAggregationMediaMap" */
  update_tecAggregationMediaMap_many?: Maybe<Array<Maybe<TecAggregationMediaMapMutationResponse>>>;
  /** update data of the table: "tecAggregationMenu" */
  update_tecAggregationMenu?: Maybe<TecAggregationMenuMutationResponse>;
  /** update single row of the table: "tecAggregationMenu" */
  update_tecAggregationMenu_by_pk?: Maybe<TecAggregationMenu>;
  /** update multiples rows of table: "tecAggregationMenu" */
  update_tecAggregationMenu_many?: Maybe<Array<Maybe<TecAggregationMenuMutationResponse>>>;
  /** update data of the table: "tecAggregationOnSitePaymentDetailType" */
  update_tecAggregationOnSitePaymentDetailType?: Maybe<TecAggregationOnSitePaymentDetailTypeMutationResponse>;
  /** update single row of the table: "tecAggregationOnSitePaymentDetailType" */
  update_tecAggregationOnSitePaymentDetailType_by_pk?: Maybe<TecAggregationOnSitePaymentDetailType>;
  /** update multiples rows of table: "tecAggregationOnSitePaymentDetailType" */
  update_tecAggregationOnSitePaymentDetailType_many?: Maybe<Array<Maybe<TecAggregationOnSitePaymentDetailTypeMutationResponse>>>;
  /** update data of the table: "translation" */
  update_translation?: Maybe<TranslationMutationResponse>;
  /** update single row of the table: "translation" */
  update_translation_by_pk?: Maybe<Translation>;
  /** update multiples rows of table: "translation" */
  update_translation_many?: Maybe<Array<Maybe<TranslationMutationResponse>>>;
  /** update data of the table: "winboardConfig" */
  update_winboardConfig?: Maybe<WinboardConfigMutationResponse>;
  /** update single row of the table: "winboardConfig" */
  update_winboardConfig_by_pk?: Maybe<WinboardConfig>;
  /** update multiples rows of table: "winboardConfig" */
  update_winboardConfig_many?: Maybe<Array<Maybe<WinboardConfigMutationResponse>>>;
  /** update data of the table: "winboardInflowSourceTag" */
  update_winboardInflowSourceTag?: Maybe<WinboardInflowSourceTagMutationResponse>;
  /** update single row of the table: "winboardInflowSourceTag" */
  update_winboardInflowSourceTag_by_pk?: Maybe<WinboardInflowSourceTag>;
  /** update multiples rows of table: "winboardInflowSourceTag" */
  update_winboardInflowSourceTag_many?: Maybe<Array<Maybe<WinboardInflowSourceTagMutationResponse>>>;
  /** update data of the table: "winboardMenu" */
  update_winboardMenu?: Maybe<WinboardMenuMutationResponse>;
  /** update single row of the table: "winboardMenu" */
  update_winboardMenu_by_pk?: Maybe<WinboardMenu>;
  /** update multiples rows of table: "winboardMenu" */
  update_winboardMenu_many?: Maybe<Array<Maybe<WinboardMenuMutationResponse>>>;
  upsertShopChoices: UpsertShopChoicesResult;
  upsertShopPlanChoice: UpsertShopPlanChoiceResult;
  upsertTecAggregationMenu: DashboardAccountCreateTecAggregationMenuOutput;
  upsertTecAggregationMenusBulk: DashboardAccountUpsertTecAggregationMenusBulkOutput;
  upsertTranslationsForCategory: Scalars['Boolean'];
  upsertTranslationsForMenu: Scalars['Boolean'];
  upsertTranslationsForMenuRecommendationMeta: Scalars['Boolean'];
  upsertTranslationsForOption: Scalars['Boolean'];
  upsertTranslationsForPlan: Scalars['Boolean'];
  upsertTranslationsForPlanChoice: Scalars['Boolean'];
  upsertTranslationsForPlanOption: Scalars['Boolean'];
};


/** mutation root */
export type MutationRootArchiveMenuArgs = {
  input: ArchiveMenuInput;
};


/** mutation root */
export type MutationRootArchivePlanArgs = {
  input: ArchivePlanInput;
};


/** mutation root */
export type MutationRootArchiveTableArgs = {
  input: ArchiveTableInput;
};


/** mutation root */
export type MutationRootCompanyManagerDeleteDashboardAccountArgs = {
  input: CompanyManagerDeleteDashboardAccountInput;
};


/** mutation root */
export type MutationRootCreateCategoryArgs = {
  input: CreateCategoryInputCategorySource;
};


/** mutation root */
export type MutationRootCreateDashboardAccountArgs = {
  input: CreateDashboardAccountInput;
};


/** mutation root */
export type MutationRootCreateLineOfficialAccountArgs = {
  input: CreateLineOfficialAccountInput;
};


/** mutation root */
export type MutationRootCreateMenuArgs = {
  input: CreateMenuInput;
};


/** mutation root */
export type MutationRootCreateMenuRecommendationArgs = {
  input: CreateMenuRecommendationInputSource;
};


/** mutation root */
export type MutationRootCreateOptionArgs = {
  input: CreateOptionInputOptionSource;
};


/** mutation root */
export type MutationRootCreatePlanArgs = {
  input: CreatePlanInput;
};


/** mutation root */
export type MutationRootCreatePlanChoiceArgs = {
  input: CreatePlanChoiceInput;
};


/** mutation root */
export type MutationRootCreatePlanOptionArgs = {
  input: CreatePlanOptionInput;
};


/** mutation root */
export type MutationRootCreateServiceAdminArgs = {
  input: CreateServiceAdminInput;
};


/** mutation root */
export type MutationRootCreateShopArgs = {
  input: CreateShopInput;
};


/** mutation root */
export type MutationRootCreateShopMenusArgs = {
  input: CreateShopMenusInput;
};


/** mutation root */
export type MutationRootCreateShopPlanArgs = {
  input: CreateShopPlanInput;
};


/** mutation root */
export type MutationRootDashboardAccountArchiveCompanyArgs = {
  input: ArchiveCompanyInput;
};


/** mutation root */
export type MutationRootDashboardAccountArchiveShopArgs = {
  input: ArchiveShopInput;
};


/** mutation root */
export type MutationRootDashboardAccountCancelOnSitePaymentArgs = {
  input: DashboardAccountCancelOnSitePaymentInput;
};


/** mutation root */
export type MutationRootDashboardAccountCopyDealingDataArgs = {
  input: CopyDataInput;
};


/** mutation root */
export type MutationRootDashboardAccountCopyMasterDataArgs = {
  input: CopyDataInput;
};


/** mutation root */
export type MutationRootDashboardAccountCountMessageDeliveryTargetsArgs = {
  input: CountMessageDeliveryTargetsInput;
};


/** mutation root */
export type MutationRootDashboardAccountCreateCompanyArgs = {
  input: CreateCompanyInput;
};


/** mutation root */
export type MutationRootDashboardAccountCreateCorporationArgs = {
  input: CreateCorporationInput;
};


/** mutation root */
export type MutationRootDashboardAccountCreateMessageDeliveryArgs = {
  input: CreateMessageDeliveryInput;
};


/** mutation root */
export type MutationRootDashboardAccountCreateSurchargeConfigsArgs = {
  input: CreateSurchargeConfigsInput;
};


/** mutation root */
export type MutationRootDashboardAccountEnableAdyenOnlinePaymentArgs = {
  input: EnableOnlinePaymentInput;
};


/** mutation root */
export type MutationRootDashboardAccountEnableGmoOnlinePaymentArgs = {
  input: EnableOnlinePaymentInput;
};


/** mutation root */
export type MutationRootDashboardAccountEnableStripeOnlinePaymentArgs = {
  input: EnableOnlinePaymentInput;
};


/** mutation root */
export type MutationRootDashboardAccountIssueLineReportingBotVerificationCodeArgs = {
  input: IssueLineReportingBotVerificationCodeInput;
};


/** mutation root */
export type MutationRootDashboardAccountUpdateBankDepositAmountArgs = {
  input: UpdateBankDepositAmountInput;
};


/** mutation root */
export type MutationRootDashboardAccountUpdateDashboardAccountArgs = {
  input: UpdateDashboardAccountInput;
};


/** mutation root */
export type MutationRootDashboardAccountUpdateMessageDeliveryArgs = {
  input: UpdateMessageDeliveryInput;
};


/** mutation root */
export type MutationRootDashboardAccountUpdateOnSitePaymentArgs = {
  input: UpdateOnSitePaymentInput;
};


/** mutation root */
export type MutationRootDeleteShopChoicesArgs = {
  input: DeleteShopChoicesInput;
};


/** mutation root */
export type MutationRootDeleteShopMenusArgs = {
  input: DeleteShopMenusInput;
};


/** mutation root */
export type MutationRootDeleteShopPlanChoicesArgs = {
  input: DeleteShopPlanChoicesInput;
};


/** mutation root */
export type MutationRootDeleteShopPlansArgs = {
  input: DeleteShopPlansInput;
};


/** mutation root */
export type MutationRootDeleteAccountingSlipImageArgs = {
  where: AccountingSlipImageBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountingSlipImageByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteCashRegisterConfigArgs = {
  where: CashRegisterConfigBoolExp;
};


/** mutation root */
export type MutationRootDeleteCashRegisterConfigByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteCategoryMenuArgs = {
  where: CategoryMenuBoolExp;
};


/** mutation root */
export type MutationRootDeleteCategoryMenuByPkArgs = {
  serial: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteClerkArgs = {
  where: ClerkBoolExp;
};


/** mutation root */
export type MutationRootDeleteClerkByPkArgs = {
  serial: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteCompanyArgs = {
  where: CompanyBoolExp;
};


/** mutation root */
export type MutationRootDeleteCompanyByPkArgs = {
  serial: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteCompulsoryAppetizerConfigArgs = {
  where: CompulsoryAppetizerConfigBoolExp;
};


/** mutation root */
export type MutationRootDeleteCompulsoryAppetizerConfigByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteConnectGameConfigShopArgs = {
  where: ConnectGameConfigShopBoolExp;
};


/** mutation root */
export type MutationRootDeleteConnectGameConfigShopByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteCookingItemArgs = {
  where: CookingItemBoolExp;
};


/** mutation root */
export type MutationRootDeleteCookingItemByPkArgs = {
  serial: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteCorporationArgs = {
  where: CorporationBoolExp;
};


/** mutation root */
export type MutationRootDeleteCorporationByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteCouponMenuDiscountArgs = {
  where: CouponMenuDiscountBoolExp;
};


/** mutation root */
export type MutationRootDeleteCouponMenuDiscountByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteDashboardAccountRoleArgs = {
  where: DashboardAccountRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteDashboardAccountRolePermissionArgs = {
  where: DashboardAccountRolePermissionBoolExp;
};


/** mutation root */
export type MutationRootDeleteDashboardAccountRolePermissionByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteDashboardAccountRoleByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteDishUpSlipGroupArgs = {
  where: DishUpSlipGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteDishUpSlipGroupRolesArgs = {
  where: DishUpSlipGroupRolesBoolExp;
};


/** mutation root */
export type MutationRootDeleteDishUpSlipGroupRolesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteDishUpSlipGroupShopMenusArgs = {
  where: DishUpSlipGroupShopMenusBoolExp;
};


/** mutation root */
export type MutationRootDeleteDishUpSlipGroupShopMenusByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteDishUpSlipGroupShopPlansArgs = {
  where: DishUpSlipGroupShopPlansBoolExp;
};


/** mutation root */
export type MutationRootDeleteDishUpSlipGroupShopPlansByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteDishUpSlipGroupTablesArgs = {
  where: DishUpSlipGroupTablesBoolExp;
};


/** mutation root */
export type MutationRootDeleteDishUpSlipGroupTablesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteDishUpSlipGroupByPkArgs = {
  serial: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteEbicaTableArgs = {
  where: EbicaTableBoolExp;
};


/** mutation root */
export type MutationRootDeleteEbicaTableByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteExternalOnlineMenuAvailableTimeTermArgs = {
  where: ExternalOnlineMenuAvailableTimeTermBoolExp;
};


/** mutation root */
export type MutationRootDeleteExternalOnlineMenuAvailableTimeTermByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteFeatureFlagShopArgs = {
  where: FeatureFlagShopBoolExp;
};


/** mutation root */
export type MutationRootDeleteFeatureFlagShopByPkArgs = {
  name: FeatureFlagEnum;
  shopId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteFoodingJournalDepartmentMasterArgs = {
  where: FoodingJournalDepartmentMasterBoolExp;
};


/** mutation root */
export type MutationRootDeleteFoodingJournalDepartmentMasterByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteFoodingJournalGroupMasterArgs = {
  where: FoodingJournalGroupMasterBoolExp;
};


/** mutation root */
export type MutationRootDeleteFoodingJournalGroupMasterByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteFoodingJournalMenuArgs = {
  where: FoodingJournalMenuBoolExp;
};


/** mutation root */
export type MutationRootDeleteFoodingJournalMenuByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteFoodingJournalOnSitePaymentDetailTypeArgs = {
  where: FoodingJournalOnSitePaymentDetailTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteFoodingJournalOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteFoodingJournalOnSitePaymentDiscountTypeArgs = {
  where: FoodingJournalOnSitePaymentDiscountTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteFoodingJournalOnSitePaymentDiscountTypeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteGiftMetaArgs = {
  where: GiftMetaBoolExp;
};


/** mutation root */
export type MutationRootDeleteGiftMetaByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteGmoOffPaymentOnSitePaymentDetailTypeArgs = {
  where: GmoOffPaymentOnSitePaymentDetailTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteGmoOffPaymentOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteGmoOffPaymentShopConfigTransactionFeeRateArgs = {
  where: GmoOffPaymentShopConfigTransactionFeeRateBoolExp;
};


/** mutation root */
export type MutationRootDeleteGmoOffPaymentShopConfigTransactionFeeRateByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteInflowSourceTagArgs = {
  where: InflowSourceTagBoolExp;
};


/** mutation root */
export type MutationRootDeleteInflowSourceTagByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteKdDisplayItemKdDisplayTargetsKdDisplayTargetArgs = {
  where: KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp;
};


/** mutation root */
export type MutationRootDeleteKdDisplayItemKdDisplayTargetsKdDisplayTargetByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteKdDisplayTargetArgs = {
  where: KdDisplayTargetBoolExp;
};


/** mutation root */
export type MutationRootDeleteKdDisplayTargetByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLineChannelConfigArgs = {
  where: LineChannelConfigBoolExp;
};


/** mutation root */
export type MutationRootDeleteLineChannelConfigByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteLineReportingBotConfigShopArgs = {
  where: LineReportingBotConfigShopBoolExp;
};


/** mutation root */
export type MutationRootDeleteLineReportingBotConfigShopByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMembershipCardTitleArgs = {
  where: MembershipCardTitleBoolExp;
};


/** mutation root */
export type MutationRootDeleteMembershipCardTitleByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMembershipRankConfigArgs = {
  where: MembershipRankConfigBoolExp;
};


/** mutation root */
export type MutationRootDeleteMembershipRankConfigByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMenuOptionArgs = {
  where: MenuOptionBoolExp;
};


/** mutation root */
export type MutationRootDeleteMenuOptionByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteOnSitePaymentDetailTypeArgs = {
  where: OnSitePaymentDetailTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteOnSitePaymentDiscountTypeArgs = {
  where: OnSitePaymentDiscountTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteOnSitePaymentDiscountTypeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteOnlinePaymentPrinterRoleMetaArgs = {
  where: OnlinePaymentPrinterRoleMetaBoolExp;
};


/** mutation root */
export type MutationRootDeleteOnlinePaymentPrinterRoleMetaByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteOptionInputTypeArgs = {
  where: OptionInputTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteOptionInputTypeByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteOrderableTimeArgs = {
  where: OrderableTimeBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrderableTimeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeletePaymentReceiptRoleTableArgs = {
  where: PaymentReceiptRoleTableBoolExp;
};


/** mutation root */
export type MutationRootDeletePikaichiMenuArgs = {
  where: PikaichiMenuBoolExp;
};


/** mutation root */
export type MutationRootDeletePikaichiMenuChoiceArgs = {
  where: PikaichiMenuChoiceBoolExp;
};


/** mutation root */
export type MutationRootDeletePikaichiMenuChoiceByPkArgs = {
  pikaichiMenuChoiceId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeletePikaichiMenuMenuArgs = {
  where: PikaichiMenuMenuBoolExp;
};


/** mutation root */
export type MutationRootDeletePikaichiMenuMenuByPkArgs = {
  pikaichiMenuMenuId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeletePikaichiMenuPlanArgs = {
  where: PikaichiMenuPlanBoolExp;
};


/** mutation root */
export type MutationRootDeletePikaichiMenuPlanChoiceArgs = {
  where: PikaichiMenuPlanChoiceBoolExp;
};


/** mutation root */
export type MutationRootDeletePikaichiMenuPlanChoiceByPkArgs = {
  pikaichiMenuPlanChoiceId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeletePikaichiMenuPlanByPkArgs = {
  pikaichiMenuPlanId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeletePikaichiMenuByPkArgs = {
  pikaichiMenuId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeletePikaichiOnSitePaymentDetailTypeArgs = {
  where: PikaichiOnSitePaymentDetailTypeBoolExp;
};


/** mutation root */
export type MutationRootDeletePikaichiOnSitePaymentDetailTypeByPkArgs = {
  pikaichiPaymentDetailTypeId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeletePlanFirstOrderCategoryMenuArgs = {
  where: PlanFirstOrderCategoryMenuBoolExp;
};


/** mutation root */
export type MutationRootDeletePlanFirstOrderCategoryMenuByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeletePlanGroupArgs = {
  where: PlanGroupBoolExp;
};


/** mutation root */
export type MutationRootDeletePlanGroupByPkArgs = {
  serial: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeletePlanMenuCategoryArgs = {
  where: PlanMenuCategoryBoolExp;
};


/** mutation root */
export type MutationRootDeletePlanMenuCategoryByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeletePlanMenuCategoriesCategoryArgs = {
  where: PlanMenuCategoriesCategoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteQuestionnaireArgs = {
  where: QuestionnaireBoolExp;
};


/** mutation root */
export type MutationRootDeleteQuestionnaireByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteRoleArgs = {
  where: RoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteRoleTablesTableArgs = {
  where: RoleTablesTableBoolExp;
};


/** mutation root */
export type MutationRootDeleteRoleByPkArgs = {
  serial: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteShopBusinessOperationHourArgs = {
  where: ShopBusinessOperationHourBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopBusinessOperationHourByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteShopClerkArgs = {
  where: ShopClerkBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopClerkByPkArgs = {
  _clerkId: Scalars['Int'];
  shopId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteShopClientArgs = {
  where: ShopClientBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopClientByPkArgs = {
  shopClientId: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteShopCookingItemRoleArgs = {
  where: ShopCookingItemRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopCookingItemRoleByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteShopMenuCookingItemArgs = {
  where: ShopMenuCookingItemBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopMenuKdDisplayTargetArgs = {
  where: ShopMenuKdDisplayTargetBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopMenuKdDisplayTargetByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteShopMenuKitchenRoleArgs = {
  where: ShopMenuKitchenRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopOptionKitchenRoleArgs = {
  where: ShopOptionKitchenRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopOptionKitchenRoleByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteShopOrderableTimeTermArgs = {
  where: ShopOrderableTimeTermBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopOrderableTimeTermByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteShopPlanKitchenRoleArgs = {
  where: ShopPlanKitchenRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopPlanOptionArgs = {
  where: ShopPlanOptionBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopPlanOptionKitchenRoleArgs = {
  where: ShopPlanOptionKitchenRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteShopPlanOptionByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteSteraOnSitePaymentDetailTypeArgs = {
  where: SteraOnSitePaymentDetailTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteSteraOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteStockArgs = {
  where: StockBoolExp;
};


/** mutation root */
export type MutationRootDeleteStockByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteTecAggregationMenuArgs = {
  where: TecAggregationMenuBoolExp;
};


/** mutation root */
export type MutationRootDeleteTecAggregationMenuByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteTecAggregationOnSitePaymentDetailTypeArgs = {
  where: TecAggregationOnSitePaymentDetailTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteTecAggregationOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteWinboardConfigArgs = {
  where: WinboardConfigBoolExp;
};


/** mutation root */
export type MutationRootDeleteWinboardConfigByPkArgs = {
  winboardConfigId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteWinboardInflowSourceTagArgs = {
  where: WinboardInflowSourceTagBoolExp;
};


/** mutation root */
export type MutationRootDeleteWinboardInflowSourceTagByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteWinboardMenuArgs = {
  where: WinboardMenuBoolExp;
};


/** mutation root */
export type MutationRootDeleteWinboardMenuByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootFoodingJournalUploadSalesArgs = {
  input: FoodingJournalUploadSalesInput;
};


/** mutation root */
export type MutationRootInsertAccountingSlipImageArgs = {
  objects: Array<AccountingSlipImageInsertInput>;
  on_conflict?: InputMaybe<AccountingSlipImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountingSlipImageOneArgs = {
  object: AccountingSlipImageInsertInput;
  on_conflict?: InputMaybe<AccountingSlipImageOnConflict>;
};


/** mutation root */
export type MutationRootInsertAdyenPaymentStoreMetaArgs = {
  objects: Array<AdyenPaymentStoreMetaInsertInput>;
  on_conflict?: InputMaybe<AdyenPaymentStoreMetaOnConflict>;
};


/** mutation root */
export type MutationRootInsertAdyenPaymentStoreMetaOneArgs = {
  object: AdyenPaymentStoreMetaInsertInput;
  on_conflict?: InputMaybe<AdyenPaymentStoreMetaOnConflict>;
};


/** mutation root */
export type MutationRootInsertAnalyticsSettingArgs = {
  objects: Array<AnalyticsSettingInsertInput>;
  on_conflict?: InputMaybe<AnalyticsSettingOnConflict>;
};


/** mutation root */
export type MutationRootInsertAnalyticsSettingOneArgs = {
  object: AnalyticsSettingInsertInput;
  on_conflict?: InputMaybe<AnalyticsSettingOnConflict>;
};


/** mutation root */
export type MutationRootInsertCashRegisterConfigArgs = {
  objects: Array<CashRegisterConfigInsertInput>;
  on_conflict?: InputMaybe<CashRegisterConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertCashRegisterConfigOneArgs = {
  object: CashRegisterConfigInsertInput;
  on_conflict?: InputMaybe<CashRegisterConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertCategoryArgs = {
  objects: Array<CategoryInsertInput>;
  on_conflict?: InputMaybe<CategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertCategoryMenuArgs = {
  objects: Array<CategoryMenuInsertInput>;
  on_conflict?: InputMaybe<CategoryMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertCategoryMenuOneArgs = {
  object: CategoryMenuInsertInput;
  on_conflict?: InputMaybe<CategoryMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertCategoryOneArgs = {
  object: CategoryInsertInput;
  on_conflict?: InputMaybe<CategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertChoiceArgs = {
  objects: Array<ChoiceInsertInput>;
  on_conflict?: InputMaybe<ChoiceOnConflict>;
};


/** mutation root */
export type MutationRootInsertChoiceOneArgs = {
  object: ChoiceInsertInput;
  on_conflict?: InputMaybe<ChoiceOnConflict>;
};


/** mutation root */
export type MutationRootInsertClerkArgs = {
  objects: Array<ClerkInsertInput>;
  on_conflict?: InputMaybe<ClerkOnConflict>;
};


/** mutation root */
export type MutationRootInsertClerkOneArgs = {
  object: ClerkInsertInput;
  on_conflict?: InputMaybe<ClerkOnConflict>;
};


/** mutation root */
export type MutationRootInsertCompanyArgs = {
  objects: Array<CompanyInsertInput>;
  on_conflict?: InputMaybe<CompanyOnConflict>;
};


/** mutation root */
export type MutationRootInsertCompanyOneArgs = {
  object: CompanyInsertInput;
  on_conflict?: InputMaybe<CompanyOnConflict>;
};


/** mutation root */
export type MutationRootInsertCompulsoryAppetizerConfigArgs = {
  objects: Array<CompulsoryAppetizerConfigInsertInput>;
  on_conflict?: InputMaybe<CompulsoryAppetizerConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertCompulsoryAppetizerConfigOneArgs = {
  object: CompulsoryAppetizerConfigInsertInput;
  on_conflict?: InputMaybe<CompulsoryAppetizerConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertConnectGameConfigArgs = {
  objects: Array<ConnectGameConfigInsertInput>;
  on_conflict?: InputMaybe<ConnectGameConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertConnectGameConfigShopArgs = {
  objects: Array<ConnectGameConfigShopInsertInput>;
  on_conflict?: InputMaybe<ConnectGameConfigShopOnConflict>;
};


/** mutation root */
export type MutationRootInsertConnectGameConfigShopOneArgs = {
  object: ConnectGameConfigShopInsertInput;
  on_conflict?: InputMaybe<ConnectGameConfigShopOnConflict>;
};


/** mutation root */
export type MutationRootInsertConnectGameConfigOneArgs = {
  object: ConnectGameConfigInsertInput;
  on_conflict?: InputMaybe<ConnectGameConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertCookingItemArgs = {
  objects: Array<CookingItemInsertInput>;
  on_conflict?: InputMaybe<CookingItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertCookingItemOneArgs = {
  object: CookingItemInsertInput;
  on_conflict?: InputMaybe<CookingItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertCorporationArgs = {
  objects: Array<CorporationInsertInput>;
  on_conflict?: InputMaybe<CorporationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCorporationOneArgs = {
  object: CorporationInsertInput;
  on_conflict?: InputMaybe<CorporationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCouponArgs = {
  objects: Array<CouponInsertInput>;
  on_conflict?: InputMaybe<CouponOnConflict>;
};


/** mutation root */
export type MutationRootInsertCouponMenuDiscountArgs = {
  objects: Array<CouponMenuDiscountInsertInput>;
  on_conflict?: InputMaybe<CouponMenuDiscountOnConflict>;
};


/** mutation root */
export type MutationRootInsertCouponMenuDiscountOneArgs = {
  object: CouponMenuDiscountInsertInput;
  on_conflict?: InputMaybe<CouponMenuDiscountOnConflict>;
};


/** mutation root */
export type MutationRootInsertCouponOneArgs = {
  object: CouponInsertInput;
  on_conflict?: InputMaybe<CouponOnConflict>;
};


/** mutation root */
export type MutationRootInsertDailySalesBudgetArgs = {
  objects: Array<DailySalesBudgetInsertInput>;
  on_conflict?: InputMaybe<DailySalesBudgetOnConflict>;
};


/** mutation root */
export type MutationRootInsertDailySalesBudgetOneArgs = {
  object: DailySalesBudgetInsertInput;
  on_conflict?: InputMaybe<DailySalesBudgetOnConflict>;
};


/** mutation root */
export type MutationRootInsertDashboardAccountRoleArgs = {
  objects: Array<DashboardAccountRoleInsertInput>;
  on_conflict?: InputMaybe<DashboardAccountRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertDashboardAccountRolePermissionArgs = {
  objects: Array<DashboardAccountRolePermissionInsertInput>;
  on_conflict?: InputMaybe<DashboardAccountRolePermissionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDashboardAccountRolePermissionOneArgs = {
  object: DashboardAccountRolePermissionInsertInput;
  on_conflict?: InputMaybe<DashboardAccountRolePermissionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDashboardAccountRoleOneArgs = {
  object: DashboardAccountRoleInsertInput;
  on_conflict?: InputMaybe<DashboardAccountRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertDishUpSlipGroupArgs = {
  objects: Array<DishUpSlipGroupInsertInput>;
  on_conflict?: InputMaybe<DishUpSlipGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertDishUpSlipGroupRolesArgs = {
  objects: Array<DishUpSlipGroupRolesInsertInput>;
  on_conflict?: InputMaybe<DishUpSlipGroupRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertDishUpSlipGroupRolesOneArgs = {
  object: DishUpSlipGroupRolesInsertInput;
  on_conflict?: InputMaybe<DishUpSlipGroupRolesOnConflict>;
};


/** mutation root */
export type MutationRootInsertDishUpSlipGroupShopMenusArgs = {
  objects: Array<DishUpSlipGroupShopMenusInsertInput>;
  on_conflict?: InputMaybe<DishUpSlipGroupShopMenusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDishUpSlipGroupShopMenusOneArgs = {
  object: DishUpSlipGroupShopMenusInsertInput;
  on_conflict?: InputMaybe<DishUpSlipGroupShopMenusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDishUpSlipGroupShopPlansArgs = {
  objects: Array<DishUpSlipGroupShopPlansInsertInput>;
  on_conflict?: InputMaybe<DishUpSlipGroupShopPlansOnConflict>;
};


/** mutation root */
export type MutationRootInsertDishUpSlipGroupShopPlansOneArgs = {
  object: DishUpSlipGroupShopPlansInsertInput;
  on_conflict?: InputMaybe<DishUpSlipGroupShopPlansOnConflict>;
};


/** mutation root */
export type MutationRootInsertDishUpSlipGroupTablesArgs = {
  objects: Array<DishUpSlipGroupTablesInsertInput>;
  on_conflict?: InputMaybe<DishUpSlipGroupTablesOnConflict>;
};


/** mutation root */
export type MutationRootInsertDishUpSlipGroupTablesOneArgs = {
  object: DishUpSlipGroupTablesInsertInput;
  on_conflict?: InputMaybe<DishUpSlipGroupTablesOnConflict>;
};


/** mutation root */
export type MutationRootInsertDishUpSlipGroupOneArgs = {
  object: DishUpSlipGroupInsertInput;
  on_conflict?: InputMaybe<DishUpSlipGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertEbicaConfigArgs = {
  objects: Array<EbicaConfigInsertInput>;
  on_conflict?: InputMaybe<EbicaConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertEbicaConfigOneArgs = {
  object: EbicaConfigInsertInput;
  on_conflict?: InputMaybe<EbicaConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertEbicaTableArgs = {
  objects: Array<EbicaTableInsertInput>;
  on_conflict?: InputMaybe<EbicaTableOnConflict>;
};


/** mutation root */
export type MutationRootInsertEbicaTableOneArgs = {
  object: EbicaTableInsertInput;
  on_conflict?: InputMaybe<EbicaTableOnConflict>;
};


/** mutation root */
export type MutationRootInsertExternalOnlineMenuArgs = {
  objects: Array<ExternalOnlineMenuInsertInput>;
  on_conflict?: InputMaybe<ExternalOnlineMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertExternalOnlineMenuAvailableTimeTermArgs = {
  objects: Array<ExternalOnlineMenuAvailableTimeTermInsertInput>;
  on_conflict?: InputMaybe<ExternalOnlineMenuAvailableTimeTermOnConflict>;
};


/** mutation root */
export type MutationRootInsertExternalOnlineMenuAvailableTimeTermOneArgs = {
  object: ExternalOnlineMenuAvailableTimeTermInsertInput;
  on_conflict?: InputMaybe<ExternalOnlineMenuAvailableTimeTermOnConflict>;
};


/** mutation root */
export type MutationRootInsertExternalOnlineMenuConfigArgs = {
  objects: Array<ExternalOnlineMenuConfigInsertInput>;
  on_conflict?: InputMaybe<ExternalOnlineMenuConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertExternalOnlineMenuConfigOneArgs = {
  object: ExternalOnlineMenuConfigInsertInput;
  on_conflict?: InputMaybe<ExternalOnlineMenuConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertExternalOnlineMenuOneArgs = {
  object: ExternalOnlineMenuInsertInput;
  on_conflict?: InputMaybe<ExternalOnlineMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertFeatureFlagShopArgs = {
  objects: Array<FeatureFlagShopInsertInput>;
  on_conflict?: InputMaybe<FeatureFlagShopOnConflict>;
};


/** mutation root */
export type MutationRootInsertFeatureFlagShopOneArgs = {
  object: FeatureFlagShopInsertInput;
  on_conflict?: InputMaybe<FeatureFlagShopOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalConfigArgs = {
  objects: Array<FoodingJournalConfigInsertInput>;
  on_conflict?: InputMaybe<FoodingJournalConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalConfigOneArgs = {
  object: FoodingJournalConfigInsertInput;
  on_conflict?: InputMaybe<FoodingJournalConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalDepartmentMasterArgs = {
  objects: Array<FoodingJournalDepartmentMasterInsertInput>;
  on_conflict?: InputMaybe<FoodingJournalDepartmentMasterOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalDepartmentMasterOneArgs = {
  object: FoodingJournalDepartmentMasterInsertInput;
  on_conflict?: InputMaybe<FoodingJournalDepartmentMasterOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalGroupMasterArgs = {
  objects: Array<FoodingJournalGroupMasterInsertInput>;
  on_conflict?: InputMaybe<FoodingJournalGroupMasterOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalGroupMasterOneArgs = {
  object: FoodingJournalGroupMasterInsertInput;
  on_conflict?: InputMaybe<FoodingJournalGroupMasterOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalMenuArgs = {
  objects: Array<FoodingJournalMenuInsertInput>;
  on_conflict?: InputMaybe<FoodingJournalMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalMenuOneArgs = {
  object: FoodingJournalMenuInsertInput;
  on_conflict?: InputMaybe<FoodingJournalMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalOnSitePaymentDetailTypeArgs = {
  objects: Array<FoodingJournalOnSitePaymentDetailTypeInsertInput>;
  on_conflict?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalOnSitePaymentDetailTypeOneArgs = {
  object: FoodingJournalOnSitePaymentDetailTypeInsertInput;
  on_conflict?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalOnSitePaymentDiscountTypeArgs = {
  objects: Array<FoodingJournalOnSitePaymentDiscountTypeInsertInput>;
  on_conflict?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertFoodingJournalOnSitePaymentDiscountTypeOneArgs = {
  object: FoodingJournalOnSitePaymentDiscountTypeInsertInput;
  on_conflict?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertGiftMetaArgs = {
  objects: Array<GiftMetaInsertInput>;
  on_conflict?: InputMaybe<GiftMetaOnConflict>;
};


/** mutation root */
export type MutationRootInsertGiftMetaOneArgs = {
  object: GiftMetaInsertInput;
  on_conflict?: InputMaybe<GiftMetaOnConflict>;
};


/** mutation root */
export type MutationRootInsertGiftOptionMetaArgs = {
  objects: Array<GiftOptionMetaInsertInput>;
  on_conflict?: InputMaybe<GiftOptionMetaOnConflict>;
};


/** mutation root */
export type MutationRootInsertGiftOptionMetaOneArgs = {
  object: GiftOptionMetaInsertInput;
  on_conflict?: InputMaybe<GiftOptionMetaOnConflict>;
};


/** mutation root */
export type MutationRootInsertGmoOffPaymentOnSitePaymentDetailTypeArgs = {
  objects: Array<GmoOffPaymentOnSitePaymentDetailTypeInsertInput>;
  on_conflict?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertGmoOffPaymentOnSitePaymentDetailTypeOneArgs = {
  object: GmoOffPaymentOnSitePaymentDetailTypeInsertInput;
  on_conflict?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertGmoOffPaymentShopConfigArgs = {
  objects: Array<GmoOffPaymentShopConfigInsertInput>;
  on_conflict?: InputMaybe<GmoOffPaymentShopConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertGmoOffPaymentShopConfigTransactionFeeRateArgs = {
  objects: Array<GmoOffPaymentShopConfigTransactionFeeRateInsertInput>;
  on_conflict?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateOnConflict>;
};


/** mutation root */
export type MutationRootInsertGmoOffPaymentShopConfigTransactionFeeRateOneArgs = {
  object: GmoOffPaymentShopConfigTransactionFeeRateInsertInput;
  on_conflict?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateOnConflict>;
};


/** mutation root */
export type MutationRootInsertGmoOffPaymentShopConfigOneArgs = {
  object: GmoOffPaymentShopConfigInsertInput;
  on_conflict?: InputMaybe<GmoOffPaymentShopConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertInflowSourceTagArgs = {
  objects: Array<InflowSourceTagInsertInput>;
  on_conflict?: InputMaybe<InflowSourceTagOnConflict>;
};


/** mutation root */
export type MutationRootInsertInflowSourceTagOneArgs = {
  object: InflowSourceTagInsertInput;
  on_conflict?: InputMaybe<InflowSourceTagOnConflict>;
};


/** mutation root */
export type MutationRootInsertKdDisplayTargetArgs = {
  objects: Array<KdDisplayTargetInsertInput>;
  on_conflict?: InputMaybe<KdDisplayTargetOnConflict>;
};


/** mutation root */
export type MutationRootInsertKdDisplayTargetOneArgs = {
  object: KdDisplayTargetInsertInput;
  on_conflict?: InputMaybe<KdDisplayTargetOnConflict>;
};


/** mutation root */
export type MutationRootInsertLineChannelConfigArgs = {
  objects: Array<LineChannelConfigInsertInput>;
  on_conflict?: InputMaybe<LineChannelConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertLineChannelConfigOneArgs = {
  object: LineChannelConfigInsertInput;
  on_conflict?: InputMaybe<LineChannelConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertLineReportingBotConfigShopArgs = {
  objects: Array<LineReportingBotConfigShopInsertInput>;
  on_conflict?: InputMaybe<LineReportingBotConfigShopOnConflict>;
};


/** mutation root */
export type MutationRootInsertLineReportingBotConfigShopOneArgs = {
  object: LineReportingBotConfigShopInsertInput;
  on_conflict?: InputMaybe<LineReportingBotConfigShopOnConflict>;
};


/** mutation root */
export type MutationRootInsertMembershipCardArgs = {
  objects: Array<MembershipCardInsertInput>;
  on_conflict?: InputMaybe<MembershipCardOnConflict>;
};


/** mutation root */
export type MutationRootInsertMembershipCardTitleArgs = {
  objects: Array<MembershipCardTitleInsertInput>;
  on_conflict?: InputMaybe<MembershipCardTitleOnConflict>;
};


/** mutation root */
export type MutationRootInsertMembershipCardTitleOneArgs = {
  object: MembershipCardTitleInsertInput;
  on_conflict?: InputMaybe<MembershipCardTitleOnConflict>;
};


/** mutation root */
export type MutationRootInsertMembershipCardOneArgs = {
  object: MembershipCardInsertInput;
  on_conflict?: InputMaybe<MembershipCardOnConflict>;
};


/** mutation root */
export type MutationRootInsertMenuArgs = {
  objects: Array<MenuInsertInput>;
  on_conflict?: InputMaybe<MenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertMenuOptionArgs = {
  objects: Array<MenuOptionInsertInput>;
  on_conflict?: InputMaybe<MenuOptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertMenuOptionOneArgs = {
  object: MenuOptionInsertInput;
  on_conflict?: InputMaybe<MenuOptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertMenuRecommendationMetaArgs = {
  objects: Array<MenuRecommendationMetaInsertInput>;
  on_conflict?: InputMaybe<MenuRecommendationMetaOnConflict>;
};


/** mutation root */
export type MutationRootInsertMenuRecommendationMetaOneArgs = {
  object: MenuRecommendationMetaInsertInput;
  on_conflict?: InputMaybe<MenuRecommendationMetaOnConflict>;
};


/** mutation root */
export type MutationRootInsertMenuOneArgs = {
  object: MenuInsertInput;
  on_conflict?: InputMaybe<MenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertMonthlySalesBudgetArgs = {
  objects: Array<MonthlySalesBudgetInsertInput>;
  on_conflict?: InputMaybe<MonthlySalesBudgetOnConflict>;
};


/** mutation root */
export type MutationRootInsertMonthlySalesBudgetOneArgs = {
  object: MonthlySalesBudgetInsertInput;
  on_conflict?: InputMaybe<MonthlySalesBudgetOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnSitePaymentDetailTypeArgs = {
  objects: Array<OnSitePaymentDetailTypeInsertInput>;
  on_conflict?: InputMaybe<OnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnSitePaymentDetailTypeOneArgs = {
  object: OnSitePaymentDetailTypeInsertInput;
  on_conflict?: InputMaybe<OnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnSitePaymentDiscountTypeArgs = {
  objects: Array<OnSitePaymentDiscountTypeInsertInput>;
  on_conflict?: InputMaybe<OnSitePaymentDiscountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnSitePaymentDiscountTypeOneArgs = {
  object: OnSitePaymentDiscountTypeInsertInput;
  on_conflict?: InputMaybe<OnSitePaymentDiscountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnlinePaymentPrinterRoleMetaArgs = {
  objects: Array<OnlinePaymentPrinterRoleMetaInsertInput>;
  on_conflict?: InputMaybe<OnlinePaymentPrinterRoleMetaOnConflict>;
};


/** mutation root */
export type MutationRootInsertOnlinePaymentPrinterRoleMetaOneArgs = {
  object: OnlinePaymentPrinterRoleMetaInsertInput;
  on_conflict?: InputMaybe<OnlinePaymentPrinterRoleMetaOnConflict>;
};


/** mutation root */
export type MutationRootInsertOptionArgs = {
  objects: Array<OptionInsertInput>;
  on_conflict?: InputMaybe<OptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertOptionInputTypeArgs = {
  objects: Array<OptionInputTypeInsertInput>;
  on_conflict?: InputMaybe<OptionInputTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertOptionInputTypeOneArgs = {
  object: OptionInputTypeInsertInput;
  on_conflict?: InputMaybe<OptionInputTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertOptionOneArgs = {
  object: OptionInsertInput;
  on_conflict?: InputMaybe<OptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrderableTimeArgs = {
  objects: Array<OrderableTimeInsertInput>;
  on_conflict?: InputMaybe<OrderableTimeOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrderableTimeOneArgs = {
  object: OrderableTimeInsertInput;
  on_conflict?: InputMaybe<OrderableTimeOnConflict>;
};


/** mutation root */
export type MutationRootInsertPaymentReceiptRoleTableArgs = {
  objects: Array<PaymentReceiptRoleTableInsertInput>;
  on_conflict?: InputMaybe<PaymentReceiptRoleTableOnConflict>;
};


/** mutation root */
export type MutationRootInsertPaymentReceiptRoleTableOneArgs = {
  object: PaymentReceiptRoleTableInsertInput;
  on_conflict?: InputMaybe<PaymentReceiptRoleTableOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiConfigArgs = {
  objects: Array<PikaichiConfigInsertInput>;
  on_conflict?: InputMaybe<PikaichiConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiConfigOneArgs = {
  object: PikaichiConfigInsertInput;
  on_conflict?: InputMaybe<PikaichiConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiMenuArgs = {
  objects: Array<PikaichiMenuInsertInput>;
  on_conflict?: InputMaybe<PikaichiMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiMenuChoiceArgs = {
  objects: Array<PikaichiMenuChoiceInsertInput>;
  on_conflict?: InputMaybe<PikaichiMenuChoiceOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiMenuChoiceOneArgs = {
  object: PikaichiMenuChoiceInsertInput;
  on_conflict?: InputMaybe<PikaichiMenuChoiceOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiMenuMenuArgs = {
  objects: Array<PikaichiMenuMenuInsertInput>;
  on_conflict?: InputMaybe<PikaichiMenuMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiMenuMenuOneArgs = {
  object: PikaichiMenuMenuInsertInput;
  on_conflict?: InputMaybe<PikaichiMenuMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiMenuPlanArgs = {
  objects: Array<PikaichiMenuPlanInsertInput>;
  on_conflict?: InputMaybe<PikaichiMenuPlanOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiMenuPlanChoiceArgs = {
  objects: Array<PikaichiMenuPlanChoiceInsertInput>;
  on_conflict?: InputMaybe<PikaichiMenuPlanChoiceOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiMenuPlanChoiceOneArgs = {
  object: PikaichiMenuPlanChoiceInsertInput;
  on_conflict?: InputMaybe<PikaichiMenuPlanChoiceOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiMenuPlanOneArgs = {
  object: PikaichiMenuPlanInsertInput;
  on_conflict?: InputMaybe<PikaichiMenuPlanOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiMenuOneArgs = {
  object: PikaichiMenuInsertInput;
  on_conflict?: InputMaybe<PikaichiMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiOnSitePaymentDetailTypeArgs = {
  objects: Array<PikaichiOnSitePaymentDetailTypeInsertInput>;
  on_conflict?: InputMaybe<PikaichiOnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiOnSitePaymentDetailTypeOneArgs = {
  object: PikaichiOnSitePaymentDetailTypeInsertInput;
  on_conflict?: InputMaybe<PikaichiOnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiOnSitePaymentDiscountTypeArgs = {
  objects: Array<PikaichiOnSitePaymentDiscountTypeInsertInput>;
  on_conflict?: InputMaybe<PikaichiOnSitePaymentDiscountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertPikaichiOnSitePaymentDiscountTypeOneArgs = {
  object: PikaichiOnSitePaymentDiscountTypeInsertInput;
  on_conflict?: InputMaybe<PikaichiOnSitePaymentDiscountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanArgs = {
  objects: Array<PlanInsertInput>;
  on_conflict?: InputMaybe<PlanOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanChoiceArgs = {
  objects: Array<PlanChoiceInsertInput>;
  on_conflict?: InputMaybe<PlanChoiceOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanChoiceOneArgs = {
  object: PlanChoiceInsertInput;
  on_conflict?: InputMaybe<PlanChoiceOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanFirstOrderCategoryMenuArgs = {
  objects: Array<PlanFirstOrderCategoryMenuInsertInput>;
  on_conflict?: InputMaybe<PlanFirstOrderCategoryMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanFirstOrderCategoryMenuOneArgs = {
  object: PlanFirstOrderCategoryMenuInsertInput;
  on_conflict?: InputMaybe<PlanFirstOrderCategoryMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanGroupArgs = {
  objects: Array<PlanGroupInsertInput>;
  on_conflict?: InputMaybe<PlanGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanGroupOneArgs = {
  object: PlanGroupInsertInput;
  on_conflict?: InputMaybe<PlanGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanMenuCategoryArgs = {
  objects: Array<PlanMenuCategoryInsertInput>;
  on_conflict?: InputMaybe<PlanMenuCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanMenuCategoryOneArgs = {
  object: PlanMenuCategoryInsertInput;
  on_conflict?: InputMaybe<PlanMenuCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanOptionArgs = {
  objects: Array<PlanOptionInsertInput>;
  on_conflict?: InputMaybe<PlanOptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanOptionOneArgs = {
  object: PlanOptionInsertInput;
  on_conflict?: InputMaybe<PlanOptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertPlanMenuCategoriesCategoryArgs = {
  objects: Array<PlanMenuCategoriesCategoryInsertInput>;
};


/** mutation root */
export type MutationRootInsertPlanMenuCategoriesCategoryOneArgs = {
  object: PlanMenuCategoriesCategoryInsertInput;
};


/** mutation root */
export type MutationRootInsertPlanOneArgs = {
  object: PlanInsertInput;
  on_conflict?: InputMaybe<PlanOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireArgs = {
  objects: Array<QuestionnaireInsertInput>;
  on_conflict?: InputMaybe<QuestionnaireOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireConfigArgs = {
  objects: Array<QuestionnaireConfigInsertInput>;
  on_conflict?: InputMaybe<QuestionnaireConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireConfigOneArgs = {
  object: QuestionnaireConfigInsertInput;
  on_conflict?: InputMaybe<QuestionnaireConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireOneArgs = {
  object: QuestionnaireInsertInput;
  on_conflict?: InputMaybe<QuestionnaireOnConflict>;
};


/** mutation root */
export type MutationRootInsertRoleArgs = {
  objects: Array<RoleInsertInput>;
  on_conflict?: InputMaybe<RoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertRoleTablesTableArgs = {
  objects: Array<RoleTablesTableInsertInput>;
  on_conflict?: InputMaybe<RoleTablesTableOnConflict>;
};


/** mutation root */
export type MutationRootInsertRoleTablesTableOneArgs = {
  object: RoleTablesTableInsertInput;
  on_conflict?: InputMaybe<RoleTablesTableOnConflict>;
};


/** mutation root */
export type MutationRootInsertRoleOneArgs = {
  object: RoleInsertInput;
  on_conflict?: InputMaybe<RoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertSalesBudgetAllocationSettingArgs = {
  objects: Array<SalesBudgetAllocationSettingInsertInput>;
  on_conflict?: InputMaybe<SalesBudgetAllocationSettingOnConflict>;
};


/** mutation root */
export type MutationRootInsertSalesBudgetAllocationSettingOneArgs = {
  object: SalesBudgetAllocationSettingInsertInput;
  on_conflict?: InputMaybe<SalesBudgetAllocationSettingOnConflict>;
};


/** mutation root */
export type MutationRootInsertServiceChargeConfigArgs = {
  objects: Array<ServiceChargeConfigInsertInput>;
  on_conflict?: InputMaybe<ServiceChargeConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertServiceChargeConfigOneArgs = {
  object: ServiceChargeConfigInsertInput;
  on_conflict?: InputMaybe<ServiceChargeConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopArgs = {
  objects: Array<ShopInsertInput>;
  on_conflict?: InputMaybe<ShopOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopBusinessOperationHourArgs = {
  objects: Array<ShopBusinessOperationHourInsertInput>;
  on_conflict?: InputMaybe<ShopBusinessOperationHourOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopBusinessOperationHourOneArgs = {
  object: ShopBusinessOperationHourInsertInput;
  on_conflict?: InputMaybe<ShopBusinessOperationHourOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopClerkArgs = {
  objects: Array<ShopClerkInsertInput>;
  on_conflict?: InputMaybe<ShopClerkOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopClerkOneArgs = {
  object: ShopClerkInsertInput;
  on_conflict?: InputMaybe<ShopClerkOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopClientArgs = {
  objects: Array<ShopClientInsertInput>;
  on_conflict?: InputMaybe<ShopClientOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopClientOneArgs = {
  object: ShopClientInsertInput;
  on_conflict?: InputMaybe<ShopClientOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopCookingItemRoleArgs = {
  objects: Array<ShopCookingItemRoleInsertInput>;
  on_conflict?: InputMaybe<ShopCookingItemRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopCookingItemRoleOneArgs = {
  object: ShopCookingItemRoleInsertInput;
  on_conflict?: InputMaybe<ShopCookingItemRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopDiscountTypeArgs = {
  objects: Array<ShopDiscountTypeInsertInput>;
  on_conflict?: InputMaybe<ShopDiscountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopDiscountTypeOneArgs = {
  object: ShopDiscountTypeInsertInput;
  on_conflict?: InputMaybe<ShopDiscountTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopInflowSourceTagArgs = {
  objects: Array<ShopInflowSourceTagInsertInput>;
  on_conflict?: InputMaybe<ShopInflowSourceTagOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopInflowSourceTagOneArgs = {
  object: ShopInflowSourceTagInsertInput;
  on_conflict?: InputMaybe<ShopInflowSourceTagOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopMenuCookingItemArgs = {
  objects: Array<ShopMenuCookingItemInsertInput>;
  on_conflict?: InputMaybe<ShopMenuCookingItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopMenuCookingItemOneArgs = {
  object: ShopMenuCookingItemInsertInput;
  on_conflict?: InputMaybe<ShopMenuCookingItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopMenuKdDisplayTargetArgs = {
  objects: Array<ShopMenuKdDisplayTargetInsertInput>;
  on_conflict?: InputMaybe<ShopMenuKdDisplayTargetOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopMenuKdDisplayTargetOneArgs = {
  object: ShopMenuKdDisplayTargetInsertInput;
  on_conflict?: InputMaybe<ShopMenuKdDisplayTargetOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopMenuKitchenRoleArgs = {
  objects: Array<ShopMenuKitchenRoleInsertInput>;
  on_conflict?: InputMaybe<ShopMenuKitchenRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopMenuKitchenRoleOneArgs = {
  object: ShopMenuKitchenRoleInsertInput;
  on_conflict?: InputMaybe<ShopMenuKitchenRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopOptionKitchenRoleArgs = {
  objects: Array<ShopOptionKitchenRoleInsertInput>;
  on_conflict?: InputMaybe<ShopOptionKitchenRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopOptionKitchenRoleOneArgs = {
  object: ShopOptionKitchenRoleInsertInput;
  on_conflict?: InputMaybe<ShopOptionKitchenRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopOrderableTimeTermArgs = {
  objects: Array<ShopOrderableTimeTermInsertInput>;
  on_conflict?: InputMaybe<ShopOrderableTimeTermOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopOrderableTimeTermOneArgs = {
  object: ShopOrderableTimeTermInsertInput;
  on_conflict?: InputMaybe<ShopOrderableTimeTermOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopPaymentTypeArgs = {
  objects: Array<ShopPaymentTypeInsertInput>;
  on_conflict?: InputMaybe<ShopPaymentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopPaymentTypeOneArgs = {
  object: ShopPaymentTypeInsertInput;
  on_conflict?: InputMaybe<ShopPaymentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopPlanKitchenRoleArgs = {
  objects: Array<ShopPlanKitchenRoleInsertInput>;
  on_conflict?: InputMaybe<ShopPlanKitchenRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopPlanKitchenRoleOneArgs = {
  object: ShopPlanKitchenRoleInsertInput;
  on_conflict?: InputMaybe<ShopPlanKitchenRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopPlanOptionArgs = {
  objects: Array<ShopPlanOptionInsertInput>;
  on_conflict?: InputMaybe<ShopPlanOptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopPlanOptionKitchenRoleArgs = {
  objects: Array<ShopPlanOptionKitchenRoleInsertInput>;
  on_conflict?: InputMaybe<ShopPlanOptionKitchenRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopPlanOptionKitchenRoleOneArgs = {
  object: ShopPlanOptionKitchenRoleInsertInput;
  on_conflict?: InputMaybe<ShopPlanOptionKitchenRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopPlanOptionOneArgs = {
  object: ShopPlanOptionInsertInput;
  on_conflict?: InputMaybe<ShopPlanOptionOnConflict>;
};


/** mutation root */
export type MutationRootInsertShopOneArgs = {
  object: ShopInsertInput;
  on_conflict?: InputMaybe<ShopOnConflict>;
};


/** mutation root */
export type MutationRootInsertSpecificTimeSurchargeConfigArgs = {
  objects: Array<SpecificTimeSurchargeConfigInsertInput>;
  on_conflict?: InputMaybe<SpecificTimeSurchargeConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertSpecificTimeSurchargeConfigOneArgs = {
  object: SpecificTimeSurchargeConfigInsertInput;
  on_conflict?: InputMaybe<SpecificTimeSurchargeConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertSteraOnSitePaymentDetailTypeArgs = {
  objects: Array<SteraOnSitePaymentDetailTypeInsertInput>;
  on_conflict?: InputMaybe<SteraOnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertSteraOnSitePaymentDetailTypeOneArgs = {
  object: SteraOnSitePaymentDetailTypeInsertInput;
  on_conflict?: InputMaybe<SteraOnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertStockArgs = {
  objects: Array<StockInsertInput>;
  on_conflict?: InputMaybe<StockOnConflict>;
};


/** mutation root */
export type MutationRootInsertStockOneArgs = {
  object: StockInsertInput;
  on_conflict?: InputMaybe<StockOnConflict>;
};


/** mutation root */
export type MutationRootInsertTableArgs = {
  objects: Array<TableInsertInput>;
  on_conflict?: InputMaybe<TableOnConflict>;
};


/** mutation root */
export type MutationRootInsertTableAreaArgs = {
  objects: Array<TableAreaInsertInput>;
  on_conflict?: InputMaybe<TableAreaOnConflict>;
};


/** mutation root */
export type MutationRootInsertTableAreaOneArgs = {
  object: TableAreaInsertInput;
  on_conflict?: InputMaybe<TableAreaOnConflict>;
};


/** mutation root */
export type MutationRootInsertTableOneArgs = {
  object: TableInsertInput;
  on_conflict?: InputMaybe<TableOnConflict>;
};


/** mutation root */
export type MutationRootInsertTecAggregationConfigArgs = {
  objects: Array<TecAggregationConfigInsertInput>;
  on_conflict?: InputMaybe<TecAggregationConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertTecAggregationConfigOneArgs = {
  object: TecAggregationConfigInsertInput;
  on_conflict?: InputMaybe<TecAggregationConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertTecAggregationMediaMapArgs = {
  objects: Array<TecAggregationMediaMapInsertInput>;
  on_conflict?: InputMaybe<TecAggregationMediaMapOnConflict>;
};


/** mutation root */
export type MutationRootInsertTecAggregationMediaMapOneArgs = {
  object: TecAggregationMediaMapInsertInput;
  on_conflict?: InputMaybe<TecAggregationMediaMapOnConflict>;
};


/** mutation root */
export type MutationRootInsertTecAggregationMenuArgs = {
  objects: Array<TecAggregationMenuInsertInput>;
  on_conflict?: InputMaybe<TecAggregationMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertTecAggregationMenuOneArgs = {
  object: TecAggregationMenuInsertInput;
  on_conflict?: InputMaybe<TecAggregationMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertTecAggregationOnSitePaymentDetailTypeArgs = {
  objects: Array<TecAggregationOnSitePaymentDetailTypeInsertInput>;
  on_conflict?: InputMaybe<TecAggregationOnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertTecAggregationOnSitePaymentDetailTypeOneArgs = {
  object: TecAggregationOnSitePaymentDetailTypeInsertInput;
  on_conflict?: InputMaybe<TecAggregationOnSitePaymentDetailTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertWinboardConfigArgs = {
  objects: Array<WinboardConfigInsertInput>;
  on_conflict?: InputMaybe<WinboardConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertWinboardConfigOneArgs = {
  object: WinboardConfigInsertInput;
  on_conflict?: InputMaybe<WinboardConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertWinboardInflowSourceTagArgs = {
  objects: Array<WinboardInflowSourceTagInsertInput>;
  on_conflict?: InputMaybe<WinboardInflowSourceTagOnConflict>;
};


/** mutation root */
export type MutationRootInsertWinboardInflowSourceTagOneArgs = {
  object: WinboardInflowSourceTagInsertInput;
  on_conflict?: InputMaybe<WinboardInflowSourceTagOnConflict>;
};


/** mutation root */
export type MutationRootInsertWinboardMenuArgs = {
  objects: Array<WinboardMenuInsertInput>;
  on_conflict?: InputMaybe<WinboardMenuOnConflict>;
};


/** mutation root */
export type MutationRootInsertWinboardMenuOneArgs = {
  object: WinboardMenuInsertInput;
  on_conflict?: InputMaybe<WinboardMenuOnConflict>;
};


/** mutation root */
export type MutationRootSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateLineOfficialAccountArgs = {
  input: UpdateLineOfficialAccountInput;
};


/** mutation root */
export type MutationRootUpdateMenusBulkArgs = {
  input: UpdateCategoryMenusBulkInput;
};


/** mutation root */
export type MutationRootUpdateShopMenusArgs = {
  input: UpdateShopMenusInput;
};


/** mutation root */
export type MutationRootUpdateShopMenusBulkArgs = {
  input: UpdateShopMenusBulkInput;
};


/** mutation root */
export type MutationRootUpdateShopPlansBulkArgs = {
  input: UpdateShopPlansBulkInput;
};


/** mutation root */
export type MutationRootUpdateAccountingSlipImageArgs = {
  _inc?: InputMaybe<AccountingSlipImageIncInput>;
  _set?: InputMaybe<AccountingSlipImageSetInput>;
  where: AccountingSlipImageBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountingSlipImageByPkArgs = {
  _inc?: InputMaybe<AccountingSlipImageIncInput>;
  _set?: InputMaybe<AccountingSlipImageSetInput>;
  pk_columns: AccountingSlipImagePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountingSlipImageManyArgs = {
  updates: Array<AccountingSlipImageUpdates>;
};


/** mutation root */
export type MutationRootUpdateAnalyticsSettingArgs = {
  _set?: InputMaybe<AnalyticsSettingSetInput>;
  where: AnalyticsSettingBoolExp;
};


/** mutation root */
export type MutationRootUpdateAnalyticsSettingByPkArgs = {
  _set?: InputMaybe<AnalyticsSettingSetInput>;
  pk_columns: AnalyticsSettingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAnalyticsSettingManyArgs = {
  updates: Array<AnalyticsSettingUpdates>;
};


/** mutation root */
export type MutationRootUpdateCashRegisterConfigArgs = {
  _set?: InputMaybe<CashRegisterConfigSetInput>;
  where: CashRegisterConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateCashRegisterConfigByPkArgs = {
  _set?: InputMaybe<CashRegisterConfigSetInput>;
  pk_columns: CashRegisterConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCashRegisterConfigManyArgs = {
  updates: Array<CashRegisterConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateCategoryArgs = {
  _inc?: InputMaybe<CategoryIncInput>;
  _set?: InputMaybe<CategorySetInput>;
  where: CategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateCategoryMenuArgs = {
  _inc?: InputMaybe<CategoryMenuIncInput>;
  _set?: InputMaybe<CategoryMenuSetInput>;
  where: CategoryMenuBoolExp;
};


/** mutation root */
export type MutationRootUpdateCategoryMenuByPkArgs = {
  _inc?: InputMaybe<CategoryMenuIncInput>;
  _set?: InputMaybe<CategoryMenuSetInput>;
  pk_columns: CategoryMenuPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCategoryMenuManyArgs = {
  updates: Array<CategoryMenuUpdates>;
};


/** mutation root */
export type MutationRootUpdateCategoryByPkArgs = {
  _inc?: InputMaybe<CategoryIncInput>;
  _set?: InputMaybe<CategorySetInput>;
  pk_columns: CategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCategoryManyArgs = {
  updates: Array<CategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdateChoiceArgs = {
  _inc?: InputMaybe<ChoiceIncInput>;
  _set?: InputMaybe<ChoiceSetInput>;
  where: ChoiceBoolExp;
};


/** mutation root */
export type MutationRootUpdateChoiceByPkArgs = {
  _inc?: InputMaybe<ChoiceIncInput>;
  _set?: InputMaybe<ChoiceSetInput>;
  pk_columns: ChoicePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChoiceManyArgs = {
  updates: Array<ChoiceUpdates>;
};


/** mutation root */
export type MutationRootUpdateClerkArgs = {
  _inc?: InputMaybe<ClerkIncInput>;
  _set?: InputMaybe<ClerkSetInput>;
  where: ClerkBoolExp;
};


/** mutation root */
export type MutationRootUpdateClerkByPkArgs = {
  _inc?: InputMaybe<ClerkIncInput>;
  _set?: InputMaybe<ClerkSetInput>;
  pk_columns: ClerkPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateClerkManyArgs = {
  updates: Array<ClerkUpdates>;
};


/** mutation root */
export type MutationRootUpdateCloseCashRegisterArgs = {
  _set?: InputMaybe<CloseCashRegisterSetInput>;
  where: CloseCashRegisterBoolExp;
};


/** mutation root */
export type MutationRootUpdateCloseCashRegisterByPkArgs = {
  _set?: InputMaybe<CloseCashRegisterSetInput>;
  pk_columns: CloseCashRegisterPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCloseCashRegisterManyArgs = {
  updates: Array<CloseCashRegisterUpdates>;
};


/** mutation root */
export type MutationRootUpdateCoineyPaymentArgs = {
  _set?: InputMaybe<CoineyPaymentSetInput>;
  where: CoineyPaymentBoolExp;
};


/** mutation root */
export type MutationRootUpdateCoineyPaymentByPkArgs = {
  _set?: InputMaybe<CoineyPaymentSetInput>;
  pk_columns: CoineyPaymentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCoineyPaymentManyArgs = {
  updates: Array<CoineyPaymentUpdates>;
};


/** mutation root */
export type MutationRootUpdateCompanyArgs = {
  _inc?: InputMaybe<CompanyIncInput>;
  _set?: InputMaybe<CompanySetInput>;
  where: CompanyBoolExp;
};


/** mutation root */
export type MutationRootUpdateCompanyByPkArgs = {
  _inc?: InputMaybe<CompanyIncInput>;
  _set?: InputMaybe<CompanySetInput>;
  pk_columns: CompanyPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCompanyManyArgs = {
  updates: Array<CompanyUpdates>;
};


/** mutation root */
export type MutationRootUpdateCompulsoryAppetizerConfigArgs = {
  _set?: InputMaybe<CompulsoryAppetizerConfigSetInput>;
  where: CompulsoryAppetizerConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateCompulsoryAppetizerConfigByPkArgs = {
  _set?: InputMaybe<CompulsoryAppetizerConfigSetInput>;
  pk_columns: CompulsoryAppetizerConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCompulsoryAppetizerConfigManyArgs = {
  updates: Array<CompulsoryAppetizerConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateConnectGameConfigArgs = {
  _inc?: InputMaybe<ConnectGameConfigIncInput>;
  _set?: InputMaybe<ConnectGameConfigSetInput>;
  where: ConnectGameConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateConnectGameConfigShopArgs = {
  _set?: InputMaybe<ConnectGameConfigShopSetInput>;
  where: ConnectGameConfigShopBoolExp;
};


/** mutation root */
export type MutationRootUpdateConnectGameConfigShopByPkArgs = {
  _set?: InputMaybe<ConnectGameConfigShopSetInput>;
  pk_columns: ConnectGameConfigShopPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateConnectGameConfigShopManyArgs = {
  updates: Array<ConnectGameConfigShopUpdates>;
};


/** mutation root */
export type MutationRootUpdateConnectGameConfigByPkArgs = {
  _inc?: InputMaybe<ConnectGameConfigIncInput>;
  _set?: InputMaybe<ConnectGameConfigSetInput>;
  pk_columns: ConnectGameConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateConnectGameConfigManyArgs = {
  updates: Array<ConnectGameConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateCookingItemArgs = {
  _inc?: InputMaybe<CookingItemIncInput>;
  _set?: InputMaybe<CookingItemSetInput>;
  where: CookingItemBoolExp;
};


/** mutation root */
export type MutationRootUpdateCookingItemByPkArgs = {
  _inc?: InputMaybe<CookingItemIncInput>;
  _set?: InputMaybe<CookingItemSetInput>;
  pk_columns: CookingItemPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCookingItemManyArgs = {
  updates: Array<CookingItemUpdates>;
};


/** mutation root */
export type MutationRootUpdateCorporationArgs = {
  _set?: InputMaybe<CorporationSetInput>;
  where: CorporationBoolExp;
};


/** mutation root */
export type MutationRootUpdateCorporationByPkArgs = {
  _set?: InputMaybe<CorporationSetInput>;
  pk_columns: CorporationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCorporationManyArgs = {
  updates: Array<CorporationUpdates>;
};


/** mutation root */
export type MutationRootUpdateCouponArgs = {
  _inc?: InputMaybe<CouponIncInput>;
  _set?: InputMaybe<CouponSetInput>;
  where: CouponBoolExp;
};


/** mutation root */
export type MutationRootUpdateCouponMenuDiscountArgs = {
  _inc?: InputMaybe<CouponMenuDiscountIncInput>;
  _set?: InputMaybe<CouponMenuDiscountSetInput>;
  where: CouponMenuDiscountBoolExp;
};


/** mutation root */
export type MutationRootUpdateCouponMenuDiscountByPkArgs = {
  _inc?: InputMaybe<CouponMenuDiscountIncInput>;
  _set?: InputMaybe<CouponMenuDiscountSetInput>;
  pk_columns: CouponMenuDiscountPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCouponMenuDiscountManyArgs = {
  updates: Array<CouponMenuDiscountUpdates>;
};


/** mutation root */
export type MutationRootUpdateCouponByPkArgs = {
  _inc?: InputMaybe<CouponIncInput>;
  _set?: InputMaybe<CouponSetInput>;
  pk_columns: CouponPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCouponManyArgs = {
  updates: Array<CouponUpdates>;
};


/** mutation root */
export type MutationRootUpdateDailySalesBudgetArgs = {
  _inc?: InputMaybe<DailySalesBudgetIncInput>;
  _set?: InputMaybe<DailySalesBudgetSetInput>;
  where: DailySalesBudgetBoolExp;
};


/** mutation root */
export type MutationRootUpdateDailySalesBudgetByPkArgs = {
  _inc?: InputMaybe<DailySalesBudgetIncInput>;
  _set?: InputMaybe<DailySalesBudgetSetInput>;
  pk_columns: DailySalesBudgetPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDailySalesBudgetManyArgs = {
  updates: Array<DailySalesBudgetUpdates>;
};


/** mutation root */
export type MutationRootUpdateDashboardAccountRoleArgs = {
  _set?: InputMaybe<DashboardAccountRoleSetInput>;
  where: DashboardAccountRoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateDashboardAccountRolePermissionArgs = {
  _set?: InputMaybe<DashboardAccountRolePermissionSetInput>;
  where: DashboardAccountRolePermissionBoolExp;
};


/** mutation root */
export type MutationRootUpdateDashboardAccountRolePermissionByPkArgs = {
  _set?: InputMaybe<DashboardAccountRolePermissionSetInput>;
  pk_columns: DashboardAccountRolePermissionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDashboardAccountRolePermissionManyArgs = {
  updates: Array<DashboardAccountRolePermissionUpdates>;
};


/** mutation root */
export type MutationRootUpdateDashboardAccountRoleByPkArgs = {
  _set?: InputMaybe<DashboardAccountRoleSetInput>;
  pk_columns: DashboardAccountRolePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDashboardAccountRoleManyArgs = {
  updates: Array<DashboardAccountRoleUpdates>;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupArgs = {
  _inc?: InputMaybe<DishUpSlipGroupIncInput>;
  _set?: InputMaybe<DishUpSlipGroupSetInput>;
  where: DishUpSlipGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupRolesArgs = {
  _inc?: InputMaybe<DishUpSlipGroupRolesIncInput>;
  _set?: InputMaybe<DishUpSlipGroupRolesSetInput>;
  where: DishUpSlipGroupRolesBoolExp;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupRolesByPkArgs = {
  _inc?: InputMaybe<DishUpSlipGroupRolesIncInput>;
  _set?: InputMaybe<DishUpSlipGroupRolesSetInput>;
  pk_columns: DishUpSlipGroupRolesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupRolesManyArgs = {
  updates: Array<DishUpSlipGroupRolesUpdates>;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupShopMenusArgs = {
  _inc?: InputMaybe<DishUpSlipGroupShopMenusIncInput>;
  _set?: InputMaybe<DishUpSlipGroupShopMenusSetInput>;
  where: DishUpSlipGroupShopMenusBoolExp;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupShopMenusByPkArgs = {
  _inc?: InputMaybe<DishUpSlipGroupShopMenusIncInput>;
  _set?: InputMaybe<DishUpSlipGroupShopMenusSetInput>;
  pk_columns: DishUpSlipGroupShopMenusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupShopMenusManyArgs = {
  updates: Array<DishUpSlipGroupShopMenusUpdates>;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupShopPlansArgs = {
  _inc?: InputMaybe<DishUpSlipGroupShopPlansIncInput>;
  _set?: InputMaybe<DishUpSlipGroupShopPlansSetInput>;
  where: DishUpSlipGroupShopPlansBoolExp;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupShopPlansByPkArgs = {
  _inc?: InputMaybe<DishUpSlipGroupShopPlansIncInput>;
  _set?: InputMaybe<DishUpSlipGroupShopPlansSetInput>;
  pk_columns: DishUpSlipGroupShopPlansPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupShopPlansManyArgs = {
  updates: Array<DishUpSlipGroupShopPlansUpdates>;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupByPkArgs = {
  _inc?: InputMaybe<DishUpSlipGroupIncInput>;
  _set?: InputMaybe<DishUpSlipGroupSetInput>;
  pk_columns: DishUpSlipGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDishUpSlipGroupManyArgs = {
  updates: Array<DishUpSlipGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdateEbicaConfigArgs = {
  _inc?: InputMaybe<EbicaConfigIncInput>;
  _set?: InputMaybe<EbicaConfigSetInput>;
  where: EbicaConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateEbicaConfigByPkArgs = {
  _inc?: InputMaybe<EbicaConfigIncInput>;
  _set?: InputMaybe<EbicaConfigSetInput>;
  pk_columns: EbicaConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEbicaConfigManyArgs = {
  updates: Array<EbicaConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateExternalOnlineMenuArgs = {
  _set?: InputMaybe<ExternalOnlineMenuSetInput>;
  where: ExternalOnlineMenuBoolExp;
};


/** mutation root */
export type MutationRootUpdateExternalOnlineMenuAvailableTimeTermArgs = {
  _set?: InputMaybe<ExternalOnlineMenuAvailableTimeTermSetInput>;
  where: ExternalOnlineMenuAvailableTimeTermBoolExp;
};


/** mutation root */
export type MutationRootUpdateExternalOnlineMenuAvailableTimeTermByPkArgs = {
  _set?: InputMaybe<ExternalOnlineMenuAvailableTimeTermSetInput>;
  pk_columns: ExternalOnlineMenuAvailableTimeTermPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateExternalOnlineMenuAvailableTimeTermManyArgs = {
  updates: Array<ExternalOnlineMenuAvailableTimeTermUpdates>;
};


/** mutation root */
export type MutationRootUpdateExternalOnlineMenuConfigArgs = {
  _set?: InputMaybe<ExternalOnlineMenuConfigSetInput>;
  where: ExternalOnlineMenuConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateExternalOnlineMenuConfigByPkArgs = {
  _set?: InputMaybe<ExternalOnlineMenuConfigSetInput>;
  pk_columns: ExternalOnlineMenuConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateExternalOnlineMenuConfigManyArgs = {
  updates: Array<ExternalOnlineMenuConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateExternalOnlineMenuByPkArgs = {
  _set?: InputMaybe<ExternalOnlineMenuSetInput>;
  pk_columns: ExternalOnlineMenuPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateExternalOnlineMenuManyArgs = {
  updates: Array<ExternalOnlineMenuUpdates>;
};


/** mutation root */
export type MutationRootUpdateFeatureFlagShopArgs = {
  _set?: InputMaybe<FeatureFlagShopSetInput>;
  where: FeatureFlagShopBoolExp;
};


/** mutation root */
export type MutationRootUpdateFeatureFlagShopByPkArgs = {
  _set?: InputMaybe<FeatureFlagShopSetInput>;
  pk_columns: FeatureFlagShopPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFeatureFlagShopManyArgs = {
  updates: Array<FeatureFlagShopUpdates>;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalConfigArgs = {
  _set?: InputMaybe<FoodingJournalConfigSetInput>;
  where: FoodingJournalConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalConfigByPkArgs = {
  _set?: InputMaybe<FoodingJournalConfigSetInput>;
  pk_columns: FoodingJournalConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalConfigManyArgs = {
  updates: Array<FoodingJournalConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalDepartmentMasterArgs = {
  _set?: InputMaybe<FoodingJournalDepartmentMasterSetInput>;
  where: FoodingJournalDepartmentMasterBoolExp;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalDepartmentMasterByPkArgs = {
  _set?: InputMaybe<FoodingJournalDepartmentMasterSetInput>;
  pk_columns: FoodingJournalDepartmentMasterPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalDepartmentMasterManyArgs = {
  updates: Array<FoodingJournalDepartmentMasterUpdates>;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalGroupMasterArgs = {
  _set?: InputMaybe<FoodingJournalGroupMasterSetInput>;
  where: FoodingJournalGroupMasterBoolExp;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalGroupMasterByPkArgs = {
  _set?: InputMaybe<FoodingJournalGroupMasterSetInput>;
  pk_columns: FoodingJournalGroupMasterPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalGroupMasterManyArgs = {
  updates: Array<FoodingJournalGroupMasterUpdates>;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalMenuArgs = {
  _set?: InputMaybe<FoodingJournalMenuSetInput>;
  where: FoodingJournalMenuBoolExp;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalMenuByPkArgs = {
  _set?: InputMaybe<FoodingJournalMenuSetInput>;
  pk_columns: FoodingJournalMenuPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalMenuManyArgs = {
  updates: Array<FoodingJournalMenuUpdates>;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalOnSitePaymentDetailTypeArgs = {
  _inc?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeIncInput>;
  _set?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeSetInput>;
  where: FoodingJournalOnSitePaymentDetailTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalOnSitePaymentDetailTypeByPkArgs = {
  _inc?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeIncInput>;
  _set?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeSetInput>;
  pk_columns: FoodingJournalOnSitePaymentDetailTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalOnSitePaymentDetailTypeManyArgs = {
  updates: Array<FoodingJournalOnSitePaymentDetailTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalOnSitePaymentDiscountTypeArgs = {
  _inc?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeIncInput>;
  _set?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeSetInput>;
  where: FoodingJournalOnSitePaymentDiscountTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalOnSitePaymentDiscountTypeByPkArgs = {
  _inc?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeIncInput>;
  _set?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeSetInput>;
  pk_columns: FoodingJournalOnSitePaymentDiscountTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFoodingJournalOnSitePaymentDiscountTypeManyArgs = {
  updates: Array<FoodingJournalOnSitePaymentDiscountTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateGiftMetaArgs = {
  _inc?: InputMaybe<GiftMetaIncInput>;
  _set?: InputMaybe<GiftMetaSetInput>;
  where: GiftMetaBoolExp;
};


/** mutation root */
export type MutationRootUpdateGiftMetaByPkArgs = {
  _inc?: InputMaybe<GiftMetaIncInput>;
  _set?: InputMaybe<GiftMetaSetInput>;
  pk_columns: GiftMetaPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGiftMetaManyArgs = {
  updates: Array<GiftMetaUpdates>;
};


/** mutation root */
export type MutationRootUpdateGmoOffPaymentShopConfigArgs = {
  _set?: InputMaybe<GmoOffPaymentShopConfigSetInput>;
  where: GmoOffPaymentShopConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateGmoOffPaymentShopConfigTransactionFeeRateArgs = {
  _inc?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateIncInput>;
  _set?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateSetInput>;
  where: GmoOffPaymentShopConfigTransactionFeeRateBoolExp;
};


/** mutation root */
export type MutationRootUpdateGmoOffPaymentShopConfigTransactionFeeRateByPkArgs = {
  _inc?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateIncInput>;
  _set?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateSetInput>;
  pk_columns: GmoOffPaymentShopConfigTransactionFeeRatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGmoOffPaymentShopConfigTransactionFeeRateManyArgs = {
  updates: Array<GmoOffPaymentShopConfigTransactionFeeRateUpdates>;
};


/** mutation root */
export type MutationRootUpdateGmoOffPaymentShopConfigByPkArgs = {
  _set?: InputMaybe<GmoOffPaymentShopConfigSetInput>;
  pk_columns: GmoOffPaymentShopConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGmoOffPaymentShopConfigManyArgs = {
  updates: Array<GmoOffPaymentShopConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateInflowSourceTagArgs = {
  _inc?: InputMaybe<InflowSourceTagIncInput>;
  _set?: InputMaybe<InflowSourceTagSetInput>;
  where: InflowSourceTagBoolExp;
};


/** mutation root */
export type MutationRootUpdateInflowSourceTagByPkArgs = {
  _inc?: InputMaybe<InflowSourceTagIncInput>;
  _set?: InputMaybe<InflowSourceTagSetInput>;
  pk_columns: InflowSourceTagPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateInflowSourceTagManyArgs = {
  updates: Array<InflowSourceTagUpdates>;
};


/** mutation root */
export type MutationRootUpdateKdDisplayTargetArgs = {
  _inc?: InputMaybe<KdDisplayTargetIncInput>;
  _set?: InputMaybe<KdDisplayTargetSetInput>;
  where: KdDisplayTargetBoolExp;
};


/** mutation root */
export type MutationRootUpdateKdDisplayTargetByPkArgs = {
  _inc?: InputMaybe<KdDisplayTargetIncInput>;
  _set?: InputMaybe<KdDisplayTargetSetInput>;
  pk_columns: KdDisplayTargetPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateKdDisplayTargetManyArgs = {
  updates: Array<KdDisplayTargetUpdates>;
};


/** mutation root */
export type MutationRootUpdateLineChannelConfigArgs = {
  _set?: InputMaybe<LineChannelConfigSetInput>;
  where: LineChannelConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateLineChannelConfigByPkArgs = {
  _set?: InputMaybe<LineChannelConfigSetInput>;
  pk_columns: LineChannelConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLineChannelConfigManyArgs = {
  updates: Array<LineChannelConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateLineReportingBotConfigArgs = {
  _set?: InputMaybe<LineReportingBotConfigSetInput>;
  where: LineReportingBotConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateLineReportingBotConfigByPkArgs = {
  _set?: InputMaybe<LineReportingBotConfigSetInput>;
  pk_columns: LineReportingBotConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLineReportingBotConfigManyArgs = {
  updates: Array<LineReportingBotConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateMembershipCardArgs = {
  _set?: InputMaybe<MembershipCardSetInput>;
  where: MembershipCardBoolExp;
};


/** mutation root */
export type MutationRootUpdateMembershipCardTitleArgs = {
  _inc?: InputMaybe<MembershipCardTitleIncInput>;
  _set?: InputMaybe<MembershipCardTitleSetInput>;
  where: MembershipCardTitleBoolExp;
};


/** mutation root */
export type MutationRootUpdateMembershipCardTitleByPkArgs = {
  _inc?: InputMaybe<MembershipCardTitleIncInput>;
  _set?: InputMaybe<MembershipCardTitleSetInput>;
  pk_columns: MembershipCardTitlePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMembershipCardTitleManyArgs = {
  updates: Array<MembershipCardTitleUpdates>;
};


/** mutation root */
export type MutationRootUpdateMembershipCardByPkArgs = {
  _set?: InputMaybe<MembershipCardSetInput>;
  pk_columns: MembershipCardPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMembershipCardManyArgs = {
  updates: Array<MembershipCardUpdates>;
};


/** mutation root */
export type MutationRootUpdateMembershipRankConfigArgs = {
  _inc?: InputMaybe<MembershipRankConfigIncInput>;
  _set?: InputMaybe<MembershipRankConfigSetInput>;
  where: MembershipRankConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateMembershipRankConfigByPkArgs = {
  _inc?: InputMaybe<MembershipRankConfigIncInput>;
  _set?: InputMaybe<MembershipRankConfigSetInput>;
  pk_columns: MembershipRankConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMembershipRankConfigManyArgs = {
  updates: Array<MembershipRankConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateMenuArgs = {
  _inc?: InputMaybe<MenuIncInput>;
  _set?: InputMaybe<MenuSetInput>;
  where: MenuBoolExp;
};


/** mutation root */
export type MutationRootUpdateMenuOptionArgs = {
  _inc?: InputMaybe<MenuOptionIncInput>;
  _set?: InputMaybe<MenuOptionSetInput>;
  where: MenuOptionBoolExp;
};


/** mutation root */
export type MutationRootUpdateMenuOptionByPkArgs = {
  _inc?: InputMaybe<MenuOptionIncInput>;
  _set?: InputMaybe<MenuOptionSetInput>;
  pk_columns: MenuOptionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMenuOptionManyArgs = {
  updates: Array<MenuOptionUpdates>;
};


/** mutation root */
export type MutationRootUpdateMenuRecommendationMetaArgs = {
  _inc?: InputMaybe<MenuRecommendationMetaIncInput>;
  _set?: InputMaybe<MenuRecommendationMetaSetInput>;
  where: MenuRecommendationMetaBoolExp;
};


/** mutation root */
export type MutationRootUpdateMenuRecommendationMetaByPkArgs = {
  _inc?: InputMaybe<MenuRecommendationMetaIncInput>;
  _set?: InputMaybe<MenuRecommendationMetaSetInput>;
  pk_columns: MenuRecommendationMetaPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMenuRecommendationMetaManyArgs = {
  updates: Array<MenuRecommendationMetaUpdates>;
};


/** mutation root */
export type MutationRootUpdateMenuByPkArgs = {
  _inc?: InputMaybe<MenuIncInput>;
  _set?: InputMaybe<MenuSetInput>;
  pk_columns: MenuPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMenuManyArgs = {
  updates: Array<MenuUpdates>;
};


/** mutation root */
export type MutationRootUpdateMessageDeliveryArgs = {
  _inc?: InputMaybe<MessageDeliveryIncInput>;
  _set?: InputMaybe<MessageDeliverySetInput>;
  where: MessageDeliveryBoolExp;
};


/** mutation root */
export type MutationRootUpdateMessageDeliveryByPkArgs = {
  _inc?: InputMaybe<MessageDeliveryIncInput>;
  _set?: InputMaybe<MessageDeliverySetInput>;
  pk_columns: MessageDeliveryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMessageDeliveryManyArgs = {
  updates: Array<MessageDeliveryUpdates>;
};


/** mutation root */
export type MutationRootUpdateMonthlySalesBudgetArgs = {
  _inc?: InputMaybe<MonthlySalesBudgetIncInput>;
  _set?: InputMaybe<MonthlySalesBudgetSetInput>;
  where: MonthlySalesBudgetBoolExp;
};


/** mutation root */
export type MutationRootUpdateMonthlySalesBudgetByPkArgs = {
  _inc?: InputMaybe<MonthlySalesBudgetIncInput>;
  _set?: InputMaybe<MonthlySalesBudgetSetInput>;
  pk_columns: MonthlySalesBudgetPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMonthlySalesBudgetManyArgs = {
  updates: Array<MonthlySalesBudgetUpdates>;
};


/** mutation root */
export type MutationRootUpdateOnSitePaymentDetailTypeArgs = {
  _inc?: InputMaybe<OnSitePaymentDetailTypeIncInput>;
  _set?: InputMaybe<OnSitePaymentDetailTypeSetInput>;
  where: OnSitePaymentDetailTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateOnSitePaymentDetailTypeByPkArgs = {
  _inc?: InputMaybe<OnSitePaymentDetailTypeIncInput>;
  _set?: InputMaybe<OnSitePaymentDetailTypeSetInput>;
  pk_columns: OnSitePaymentDetailTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOnSitePaymentDetailTypeManyArgs = {
  updates: Array<OnSitePaymentDetailTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateOnSitePaymentDiscountTypeArgs = {
  _inc?: InputMaybe<OnSitePaymentDiscountTypeIncInput>;
  _set?: InputMaybe<OnSitePaymentDiscountTypeSetInput>;
  where: OnSitePaymentDiscountTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateOnSitePaymentDiscountTypeByPkArgs = {
  _inc?: InputMaybe<OnSitePaymentDiscountTypeIncInput>;
  _set?: InputMaybe<OnSitePaymentDiscountTypeSetInput>;
  pk_columns: OnSitePaymentDiscountTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOnSitePaymentDiscountTypeManyArgs = {
  updates: Array<OnSitePaymentDiscountTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateOptionArgs = {
  _inc?: InputMaybe<OptionIncInput>;
  _set?: InputMaybe<OptionSetInput>;
  where: OptionBoolExp;
};


/** mutation root */
export type MutationRootUpdateOptionInputTypeArgs = {
  _set?: InputMaybe<OptionInputTypeSetInput>;
  where: OptionInputTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateOptionInputTypeByPkArgs = {
  _set?: InputMaybe<OptionInputTypeSetInput>;
  pk_columns: OptionInputTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOptionInputTypeManyArgs = {
  updates: Array<OptionInputTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateOptionByPkArgs = {
  _inc?: InputMaybe<OptionIncInput>;
  _set?: InputMaybe<OptionSetInput>;
  pk_columns: OptionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOptionManyArgs = {
  updates: Array<OptionUpdates>;
};


/** mutation root */
export type MutationRootUpdateOrderableTimeArgs = {
  _set?: InputMaybe<OrderableTimeSetInput>;
  where: OrderableTimeBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrderableTimeByPkArgs = {
  _set?: InputMaybe<OrderableTimeSetInput>;
  pk_columns: OrderableTimePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrderableTimeManyArgs = {
  updates: Array<OrderableTimeUpdates>;
};


/** mutation root */
export type MutationRootUpdatePaymentReceiptRoleTableArgs = {
  _inc?: InputMaybe<PaymentReceiptRoleTableIncInput>;
  _set?: InputMaybe<PaymentReceiptRoleTableSetInput>;
  where: PaymentReceiptRoleTableBoolExp;
};


/** mutation root */
export type MutationRootUpdatePaymentReceiptRoleTableManyArgs = {
  updates: Array<PaymentReceiptRoleTableUpdates>;
};


/** mutation root */
export type MutationRootUpdatePikaichiConfigArgs = {
  _inc?: InputMaybe<PikaichiConfigIncInput>;
  _set?: InputMaybe<PikaichiConfigSetInput>;
  where: PikaichiConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdatePikaichiConfigByPkArgs = {
  _inc?: InputMaybe<PikaichiConfigIncInput>;
  _set?: InputMaybe<PikaichiConfigSetInput>;
  pk_columns: PikaichiConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePikaichiConfigManyArgs = {
  updates: Array<PikaichiConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuArgs = {
  _set?: InputMaybe<PikaichiMenuSetInput>;
  where: PikaichiMenuBoolExp;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuChoiceArgs = {
  _inc?: InputMaybe<PikaichiMenuChoiceIncInput>;
  _set?: InputMaybe<PikaichiMenuChoiceSetInput>;
  where: PikaichiMenuChoiceBoolExp;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuChoiceByPkArgs = {
  _inc?: InputMaybe<PikaichiMenuChoiceIncInput>;
  _set?: InputMaybe<PikaichiMenuChoiceSetInput>;
  pk_columns: PikaichiMenuChoicePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuChoiceManyArgs = {
  updates: Array<PikaichiMenuChoiceUpdates>;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuMenuArgs = {
  _inc?: InputMaybe<PikaichiMenuMenuIncInput>;
  _set?: InputMaybe<PikaichiMenuMenuSetInput>;
  where: PikaichiMenuMenuBoolExp;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuMenuByPkArgs = {
  _inc?: InputMaybe<PikaichiMenuMenuIncInput>;
  _set?: InputMaybe<PikaichiMenuMenuSetInput>;
  pk_columns: PikaichiMenuMenuPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuMenuManyArgs = {
  updates: Array<PikaichiMenuMenuUpdates>;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuPlanArgs = {
  _inc?: InputMaybe<PikaichiMenuPlanIncInput>;
  _set?: InputMaybe<PikaichiMenuPlanSetInput>;
  where: PikaichiMenuPlanBoolExp;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuPlanChoiceArgs = {
  _inc?: InputMaybe<PikaichiMenuPlanChoiceIncInput>;
  _set?: InputMaybe<PikaichiMenuPlanChoiceSetInput>;
  where: PikaichiMenuPlanChoiceBoolExp;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuPlanChoiceByPkArgs = {
  _inc?: InputMaybe<PikaichiMenuPlanChoiceIncInput>;
  _set?: InputMaybe<PikaichiMenuPlanChoiceSetInput>;
  pk_columns: PikaichiMenuPlanChoicePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuPlanChoiceManyArgs = {
  updates: Array<PikaichiMenuPlanChoiceUpdates>;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuPlanByPkArgs = {
  _inc?: InputMaybe<PikaichiMenuPlanIncInput>;
  _set?: InputMaybe<PikaichiMenuPlanSetInput>;
  pk_columns: PikaichiMenuPlanPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuPlanManyArgs = {
  updates: Array<PikaichiMenuPlanUpdates>;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuByPkArgs = {
  _set?: InputMaybe<PikaichiMenuSetInput>;
  pk_columns: PikaichiMenuPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePikaichiMenuManyArgs = {
  updates: Array<PikaichiMenuUpdates>;
};


/** mutation root */
export type MutationRootUpdatePikaichiOnSitePaymentDetailTypeArgs = {
  _set?: InputMaybe<PikaichiOnSitePaymentDetailTypeSetInput>;
  where: PikaichiOnSitePaymentDetailTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdatePikaichiOnSitePaymentDetailTypeByPkArgs = {
  _set?: InputMaybe<PikaichiOnSitePaymentDetailTypeSetInput>;
  pk_columns: PikaichiOnSitePaymentDetailTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePikaichiOnSitePaymentDetailTypeManyArgs = {
  updates: Array<PikaichiOnSitePaymentDetailTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdatePikaichiOnSitePaymentDiscountTypeArgs = {
  _inc?: InputMaybe<PikaichiOnSitePaymentDiscountTypeIncInput>;
  _set?: InputMaybe<PikaichiOnSitePaymentDiscountTypeSetInput>;
  where: PikaichiOnSitePaymentDiscountTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdatePikaichiOnSitePaymentDiscountTypeByPkArgs = {
  _inc?: InputMaybe<PikaichiOnSitePaymentDiscountTypeIncInput>;
  _set?: InputMaybe<PikaichiOnSitePaymentDiscountTypeSetInput>;
  pk_columns: PikaichiOnSitePaymentDiscountTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePikaichiOnSitePaymentDiscountTypeManyArgs = {
  updates: Array<PikaichiOnSitePaymentDiscountTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdatePlanArgs = {
  _inc?: InputMaybe<PlanIncInput>;
  _set?: InputMaybe<PlanSetInput>;
  where: PlanBoolExp;
};


/** mutation root */
export type MutationRootUpdatePlanChoiceArgs = {
  _inc?: InputMaybe<PlanChoiceIncInput>;
  _set?: InputMaybe<PlanChoiceSetInput>;
  where: PlanChoiceBoolExp;
};


/** mutation root */
export type MutationRootUpdatePlanChoiceByPkArgs = {
  _inc?: InputMaybe<PlanChoiceIncInput>;
  _set?: InputMaybe<PlanChoiceSetInput>;
  pk_columns: PlanChoicePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePlanChoiceManyArgs = {
  updates: Array<PlanChoiceUpdates>;
};


/** mutation root */
export type MutationRootUpdatePlanFirstOrderCategoryMenuArgs = {
  _inc?: InputMaybe<PlanFirstOrderCategoryMenuIncInput>;
  _set?: InputMaybe<PlanFirstOrderCategoryMenuSetInput>;
  where: PlanFirstOrderCategoryMenuBoolExp;
};


/** mutation root */
export type MutationRootUpdatePlanFirstOrderCategoryMenuByPkArgs = {
  _inc?: InputMaybe<PlanFirstOrderCategoryMenuIncInput>;
  _set?: InputMaybe<PlanFirstOrderCategoryMenuSetInput>;
  pk_columns: PlanFirstOrderCategoryMenuPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePlanFirstOrderCategoryMenuManyArgs = {
  updates: Array<PlanFirstOrderCategoryMenuUpdates>;
};


/** mutation root */
export type MutationRootUpdatePlanGroupArgs = {
  _inc?: InputMaybe<PlanGroupIncInput>;
  _set?: InputMaybe<PlanGroupSetInput>;
  where: PlanGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdatePlanGroupByPkArgs = {
  _inc?: InputMaybe<PlanGroupIncInput>;
  _set?: InputMaybe<PlanGroupSetInput>;
  pk_columns: PlanGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePlanGroupManyArgs = {
  updates: Array<PlanGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdatePlanMenuCategoryArgs = {
  _inc?: InputMaybe<PlanMenuCategoryIncInput>;
  _set?: InputMaybe<PlanMenuCategorySetInput>;
  where: PlanMenuCategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdatePlanMenuCategoryByPkArgs = {
  _inc?: InputMaybe<PlanMenuCategoryIncInput>;
  _set?: InputMaybe<PlanMenuCategorySetInput>;
  pk_columns: PlanMenuCategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePlanMenuCategoryManyArgs = {
  updates: Array<PlanMenuCategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdatePlanOptionArgs = {
  _inc?: InputMaybe<PlanOptionIncInput>;
  _set?: InputMaybe<PlanOptionSetInput>;
  where: PlanOptionBoolExp;
};


/** mutation root */
export type MutationRootUpdatePlanOptionByPkArgs = {
  _inc?: InputMaybe<PlanOptionIncInput>;
  _set?: InputMaybe<PlanOptionSetInput>;
  pk_columns: PlanOptionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePlanOptionManyArgs = {
  updates: Array<PlanOptionUpdates>;
};


/** mutation root */
export type MutationRootUpdatePlanByPkArgs = {
  _inc?: InputMaybe<PlanIncInput>;
  _set?: InputMaybe<PlanSetInput>;
  pk_columns: PlanPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePlanManyArgs = {
  updates: Array<PlanUpdates>;
};


/** mutation root */
export type MutationRootUpdatePlanMenuCategoriesCategoryArgs = {
  _inc?: InputMaybe<PlanMenuCategoriesCategoryIncInput>;
  _set?: InputMaybe<PlanMenuCategoriesCategorySetInput>;
  where: PlanMenuCategoriesCategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdatePlanMenuCategoriesCategoryManyArgs = {
  updates: Array<PlanMenuCategoriesCategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireArgs = {
  _set?: InputMaybe<QuestionnaireSetInput>;
  where: QuestionnaireBoolExp;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireConfigArgs = {
  _set?: InputMaybe<QuestionnaireConfigSetInput>;
  where: QuestionnaireConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireConfigByPkArgs = {
  _set?: InputMaybe<QuestionnaireConfigSetInput>;
  pk_columns: QuestionnaireConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireConfigManyArgs = {
  updates: Array<QuestionnaireConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireByPkArgs = {
  _set?: InputMaybe<QuestionnaireSetInput>;
  pk_columns: QuestionnairePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireManyArgs = {
  updates: Array<QuestionnaireUpdates>;
};


/** mutation root */
export type MutationRootUpdateRoleArgs = {
  _inc?: InputMaybe<RoleIncInput>;
  _set?: InputMaybe<RoleSetInput>;
  where: RoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateRoleTablesTableArgs = {
  _inc?: InputMaybe<RoleTablesTableIncInput>;
  _set?: InputMaybe<RoleTablesTableSetInput>;
  where: RoleTablesTableBoolExp;
};


/** mutation root */
export type MutationRootUpdateRoleTablesTableManyArgs = {
  updates: Array<RoleTablesTableUpdates>;
};


/** mutation root */
export type MutationRootUpdateRoleByPkArgs = {
  _inc?: InputMaybe<RoleIncInput>;
  _set?: InputMaybe<RoleSetInput>;
  pk_columns: RolePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRoleManyArgs = {
  updates: Array<RoleUpdates>;
};


/** mutation root */
export type MutationRootUpdateSalesBudgetAllocationSettingArgs = {
  _inc?: InputMaybe<SalesBudgetAllocationSettingIncInput>;
  _set?: InputMaybe<SalesBudgetAllocationSettingSetInput>;
  where: SalesBudgetAllocationSettingBoolExp;
};


/** mutation root */
export type MutationRootUpdateSalesBudgetAllocationSettingByPkArgs = {
  _inc?: InputMaybe<SalesBudgetAllocationSettingIncInput>;
  _set?: InputMaybe<SalesBudgetAllocationSettingSetInput>;
  pk_columns: SalesBudgetAllocationSettingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSalesBudgetAllocationSettingManyArgs = {
  updates: Array<SalesBudgetAllocationSettingUpdates>;
};


/** mutation root */
export type MutationRootUpdateServiceChargeConfigArgs = {
  _inc?: InputMaybe<ServiceChargeConfigIncInput>;
  _set?: InputMaybe<ServiceChargeConfigSetInput>;
  where: ServiceChargeConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateServiceChargeConfigByPkArgs = {
  _inc?: InputMaybe<ServiceChargeConfigIncInput>;
  _set?: InputMaybe<ServiceChargeConfigSetInput>;
  pk_columns: ServiceChargeConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateServiceChargeConfigManyArgs = {
  updates: Array<ServiceChargeConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopArgs = {
  _inc?: InputMaybe<ShopIncInput>;
  _set?: InputMaybe<ShopSetInput>;
  where: ShopBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopBusinessOperationHourArgs = {
  _set?: InputMaybe<ShopBusinessOperationHourSetInput>;
  where: ShopBusinessOperationHourBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopBusinessOperationHourByPkArgs = {
  _set?: InputMaybe<ShopBusinessOperationHourSetInput>;
  pk_columns: ShopBusinessOperationHourPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopBusinessOperationHourManyArgs = {
  updates: Array<ShopBusinessOperationHourUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopClerkArgs = {
  _inc?: InputMaybe<ShopClerkIncInput>;
  _set?: InputMaybe<ShopClerkSetInput>;
  where: ShopClerkBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopClerkByPkArgs = {
  _inc?: InputMaybe<ShopClerkIncInput>;
  _set?: InputMaybe<ShopClerkSetInput>;
  pk_columns: ShopClerkPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopClerkManyArgs = {
  updates: Array<ShopClerkUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopClientArgs = {
  _set?: InputMaybe<ShopClientSetInput>;
  where: ShopClientBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopClientByPkArgs = {
  _set?: InputMaybe<ShopClientSetInput>;
  pk_columns: ShopClientPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopClientManyArgs = {
  updates: Array<ShopClientUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopCookingItemRoleArgs = {
  _inc?: InputMaybe<ShopCookingItemRoleIncInput>;
  _set?: InputMaybe<ShopCookingItemRoleSetInput>;
  where: ShopCookingItemRoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopCookingItemRoleByPkArgs = {
  _inc?: InputMaybe<ShopCookingItemRoleIncInput>;
  _set?: InputMaybe<ShopCookingItemRoleSetInput>;
  pk_columns: ShopCookingItemRolePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopCookingItemRoleManyArgs = {
  updates: Array<ShopCookingItemRoleUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopDiscountTypeArgs = {
  _set?: InputMaybe<ShopDiscountTypeSetInput>;
  where: ShopDiscountTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopDiscountTypeByPkArgs = {
  _set?: InputMaybe<ShopDiscountTypeSetInput>;
  pk_columns: ShopDiscountTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopDiscountTypeManyArgs = {
  updates: Array<ShopDiscountTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopInflowSourceTagArgs = {
  _set?: InputMaybe<ShopInflowSourceTagSetInput>;
  where: ShopInflowSourceTagBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopInflowSourceTagManyArgs = {
  updates: Array<ShopInflowSourceTagUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopMenuArgs = {
  _inc?: InputMaybe<ShopMenuIncInput>;
  _set?: InputMaybe<ShopMenuSetInput>;
  where: ShopMenuBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopMenuCookingItemArgs = {
  _inc?: InputMaybe<ShopMenuCookingItemIncInput>;
  _set?: InputMaybe<ShopMenuCookingItemSetInput>;
  where: ShopMenuCookingItemBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopMenuCookingItemManyArgs = {
  updates: Array<ShopMenuCookingItemUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopMenuKdDisplayTargetArgs = {
  _inc?: InputMaybe<ShopMenuKdDisplayTargetIncInput>;
  _set?: InputMaybe<ShopMenuKdDisplayTargetSetInput>;
  where: ShopMenuKdDisplayTargetBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopMenuKdDisplayTargetByPkArgs = {
  _inc?: InputMaybe<ShopMenuKdDisplayTargetIncInput>;
  _set?: InputMaybe<ShopMenuKdDisplayTargetSetInput>;
  pk_columns: ShopMenuKdDisplayTargetPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopMenuKdDisplayTargetManyArgs = {
  updates: Array<ShopMenuKdDisplayTargetUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopMenuKitchenRoleArgs = {
  _inc?: InputMaybe<ShopMenuKitchenRoleIncInput>;
  _set?: InputMaybe<ShopMenuKitchenRoleSetInput>;
  where: ShopMenuKitchenRoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopMenuKitchenRoleManyArgs = {
  updates: Array<ShopMenuKitchenRoleUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopMenuByPkArgs = {
  _inc?: InputMaybe<ShopMenuIncInput>;
  _set?: InputMaybe<ShopMenuSetInput>;
  pk_columns: ShopMenuPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopMenuManyArgs = {
  updates: Array<ShopMenuUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopOrderableTimeTermArgs = {
  _set?: InputMaybe<ShopOrderableTimeTermSetInput>;
  where: ShopOrderableTimeTermBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopOrderableTimeTermByPkArgs = {
  _set?: InputMaybe<ShopOrderableTimeTermSetInput>;
  pk_columns: ShopOrderableTimeTermPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopOrderableTimeTermManyArgs = {
  updates: Array<ShopOrderableTimeTermUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopPaymentTypeArgs = {
  _set?: InputMaybe<ShopPaymentTypeSetInput>;
  where: ShopPaymentTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopPaymentTypeByPkArgs = {
  _set?: InputMaybe<ShopPaymentTypeSetInput>;
  pk_columns: ShopPaymentTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopPaymentTypeManyArgs = {
  updates: Array<ShopPaymentTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopPlanArgs = {
  _inc?: InputMaybe<ShopPlanIncInput>;
  _set?: InputMaybe<ShopPlanSetInput>;
  where: ShopPlanBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopPlanChoiceArgs = {
  _inc?: InputMaybe<ShopPlanChoiceIncInput>;
  _set?: InputMaybe<ShopPlanChoiceSetInput>;
  where: ShopPlanChoiceBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopPlanChoiceByPkArgs = {
  _inc?: InputMaybe<ShopPlanChoiceIncInput>;
  _set?: InputMaybe<ShopPlanChoiceSetInput>;
  pk_columns: ShopPlanChoicePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopPlanChoiceManyArgs = {
  updates: Array<ShopPlanChoiceUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopPlanKitchenRoleArgs = {
  _inc?: InputMaybe<ShopPlanKitchenRoleIncInput>;
  _set?: InputMaybe<ShopPlanKitchenRoleSetInput>;
  where: ShopPlanKitchenRoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopPlanKitchenRoleManyArgs = {
  updates: Array<ShopPlanKitchenRoleUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopPlanOptionArgs = {
  _inc?: InputMaybe<ShopPlanOptionIncInput>;
  _set?: InputMaybe<ShopPlanOptionSetInput>;
  where: ShopPlanOptionBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopPlanOptionKitchenRoleArgs = {
  _inc?: InputMaybe<ShopPlanOptionKitchenRoleIncInput>;
  _set?: InputMaybe<ShopPlanOptionKitchenRoleSetInput>;
  where: ShopPlanOptionKitchenRoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateShopPlanOptionKitchenRoleManyArgs = {
  updates: Array<ShopPlanOptionKitchenRoleUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopPlanOptionByPkArgs = {
  _inc?: InputMaybe<ShopPlanOptionIncInput>;
  _set?: InputMaybe<ShopPlanOptionSetInput>;
  pk_columns: ShopPlanOptionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopPlanOptionManyArgs = {
  updates: Array<ShopPlanOptionUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopPlanByPkArgs = {
  _inc?: InputMaybe<ShopPlanIncInput>;
  _set?: InputMaybe<ShopPlanSetInput>;
  pk_columns: ShopPlanPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopPlanManyArgs = {
  updates: Array<ShopPlanUpdates>;
};


/** mutation root */
export type MutationRootUpdateShopByPkArgs = {
  _inc?: InputMaybe<ShopIncInput>;
  _set?: InputMaybe<ShopSetInput>;
  pk_columns: ShopPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateShopManyArgs = {
  updates: Array<ShopUpdates>;
};


/** mutation root */
export type MutationRootUpdateSpecificTimeSurchargeConfigArgs = {
  _inc?: InputMaybe<SpecificTimeSurchargeConfigIncInput>;
  _set?: InputMaybe<SpecificTimeSurchargeConfigSetInput>;
  where: SpecificTimeSurchargeConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateSpecificTimeSurchargeConfigByPkArgs = {
  _inc?: InputMaybe<SpecificTimeSurchargeConfigIncInput>;
  _set?: InputMaybe<SpecificTimeSurchargeConfigSetInput>;
  pk_columns: SpecificTimeSurchargeConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSpecificTimeSurchargeConfigManyArgs = {
  updates: Array<SpecificTimeSurchargeConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateSteraOnSitePaymentDetailTypeArgs = {
  _set?: InputMaybe<SteraOnSitePaymentDetailTypeSetInput>;
  where: SteraOnSitePaymentDetailTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateSteraOnSitePaymentDetailTypeByPkArgs = {
  _set?: InputMaybe<SteraOnSitePaymentDetailTypeSetInput>;
  pk_columns: SteraOnSitePaymentDetailTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSteraOnSitePaymentDetailTypeManyArgs = {
  updates: Array<SteraOnSitePaymentDetailTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateStockArgs = {
  _inc?: InputMaybe<StockIncInput>;
  _set?: InputMaybe<StockSetInput>;
  where: StockBoolExp;
};


/** mutation root */
export type MutationRootUpdateStockByPkArgs = {
  _inc?: InputMaybe<StockIncInput>;
  _set?: InputMaybe<StockSetInput>;
  pk_columns: StockPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateStockManyArgs = {
  updates: Array<StockUpdates>;
};


/** mutation root */
export type MutationRootUpdateTableArgs = {
  _inc?: InputMaybe<TableIncInput>;
  _set?: InputMaybe<TableSetInput>;
  where: TableBoolExp;
};


/** mutation root */
export type MutationRootUpdateTableAreaArgs = {
  _inc?: InputMaybe<TableAreaIncInput>;
  _set?: InputMaybe<TableAreaSetInput>;
  where: TableAreaBoolExp;
};


/** mutation root */
export type MutationRootUpdateTableAreaByPkArgs = {
  _inc?: InputMaybe<TableAreaIncInput>;
  _set?: InputMaybe<TableAreaSetInput>;
  pk_columns: TableAreaPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTableAreaManyArgs = {
  updates: Array<TableAreaUpdates>;
};


/** mutation root */
export type MutationRootUpdateTableByPkArgs = {
  _inc?: InputMaybe<TableIncInput>;
  _set?: InputMaybe<TableSetInput>;
  pk_columns: TablePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTableManyArgs = {
  updates: Array<TableUpdates>;
};


/** mutation root */
export type MutationRootUpdateTecAggregationConfigArgs = {
  _inc?: InputMaybe<TecAggregationConfigIncInput>;
  _set?: InputMaybe<TecAggregationConfigSetInput>;
  where: TecAggregationConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateTecAggregationConfigByPkArgs = {
  _inc?: InputMaybe<TecAggregationConfigIncInput>;
  _set?: InputMaybe<TecAggregationConfigSetInput>;
  pk_columns: TecAggregationConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTecAggregationConfigManyArgs = {
  updates: Array<TecAggregationConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateTecAggregationMediaMapArgs = {
  _inc?: InputMaybe<TecAggregationMediaMapIncInput>;
  _set?: InputMaybe<TecAggregationMediaMapSetInput>;
  where: TecAggregationMediaMapBoolExp;
};


/** mutation root */
export type MutationRootUpdateTecAggregationMediaMapByPkArgs = {
  _inc?: InputMaybe<TecAggregationMediaMapIncInput>;
  _set?: InputMaybe<TecAggregationMediaMapSetInput>;
  pk_columns: TecAggregationMediaMapPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTecAggregationMediaMapManyArgs = {
  updates: Array<TecAggregationMediaMapUpdates>;
};


/** mutation root */
export type MutationRootUpdateTecAggregationMenuArgs = {
  _inc?: InputMaybe<TecAggregationMenuIncInput>;
  _set?: InputMaybe<TecAggregationMenuSetInput>;
  where: TecAggregationMenuBoolExp;
};


/** mutation root */
export type MutationRootUpdateTecAggregationMenuByPkArgs = {
  _inc?: InputMaybe<TecAggregationMenuIncInput>;
  _set?: InputMaybe<TecAggregationMenuSetInput>;
  pk_columns: TecAggregationMenuPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTecAggregationMenuManyArgs = {
  updates: Array<TecAggregationMenuUpdates>;
};


/** mutation root */
export type MutationRootUpdateTecAggregationOnSitePaymentDetailTypeArgs = {
  _set?: InputMaybe<TecAggregationOnSitePaymentDetailTypeSetInput>;
  where: TecAggregationOnSitePaymentDetailTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateTecAggregationOnSitePaymentDetailTypeByPkArgs = {
  _set?: InputMaybe<TecAggregationOnSitePaymentDetailTypeSetInput>;
  pk_columns: TecAggregationOnSitePaymentDetailTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTecAggregationOnSitePaymentDetailTypeManyArgs = {
  updates: Array<TecAggregationOnSitePaymentDetailTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateTranslationArgs = {
  _set?: InputMaybe<TranslationSetInput>;
  where: TranslationBoolExp;
};


/** mutation root */
export type MutationRootUpdateTranslationByPkArgs = {
  _set?: InputMaybe<TranslationSetInput>;
  pk_columns: TranslationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTranslationManyArgs = {
  updates: Array<TranslationUpdates>;
};


/** mutation root */
export type MutationRootUpdateWinboardConfigArgs = {
  _inc?: InputMaybe<WinboardConfigIncInput>;
  _set?: InputMaybe<WinboardConfigSetInput>;
  where: WinboardConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateWinboardConfigByPkArgs = {
  _inc?: InputMaybe<WinboardConfigIncInput>;
  _set?: InputMaybe<WinboardConfigSetInput>;
  pk_columns: WinboardConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWinboardConfigManyArgs = {
  updates: Array<WinboardConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateWinboardInflowSourceTagArgs = {
  _set?: InputMaybe<WinboardInflowSourceTagSetInput>;
  where: WinboardInflowSourceTagBoolExp;
};


/** mutation root */
export type MutationRootUpdateWinboardInflowSourceTagByPkArgs = {
  _set?: InputMaybe<WinboardInflowSourceTagSetInput>;
  pk_columns: WinboardInflowSourceTagPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWinboardInflowSourceTagManyArgs = {
  updates: Array<WinboardInflowSourceTagUpdates>;
};


/** mutation root */
export type MutationRootUpdateWinboardMenuArgs = {
  _inc?: InputMaybe<WinboardMenuIncInput>;
  _set?: InputMaybe<WinboardMenuSetInput>;
  where: WinboardMenuBoolExp;
};


/** mutation root */
export type MutationRootUpdateWinboardMenuByPkArgs = {
  _inc?: InputMaybe<WinboardMenuIncInput>;
  _set?: InputMaybe<WinboardMenuSetInput>;
  pk_columns: WinboardMenuPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWinboardMenuManyArgs = {
  updates: Array<WinboardMenuUpdates>;
};


/** mutation root */
export type MutationRootUpsertShopChoicesArgs = {
  input: UpsertShopChoicesInput;
};


/** mutation root */
export type MutationRootUpsertShopPlanChoiceArgs = {
  input: UpsertShopPlanChoiceInput;
};


/** mutation root */
export type MutationRootUpsertTecAggregationMenuArgs = {
  input: DashboardAccountUpsertTecAggregationMenuInput;
};


/** mutation root */
export type MutationRootUpsertTecAggregationMenusBulkArgs = {
  input: DashboardAccountUpsertTecAggregationMenusBulkInput;
};


/** mutation root */
export type MutationRootUpsertTranslationsForCategoryArgs = {
  input: UpsertTranslationsForCategoryInput;
};


/** mutation root */
export type MutationRootUpsertTranslationsForMenuArgs = {
  input: UpsertTranslationsForMenuInput;
};


/** mutation root */
export type MutationRootUpsertTranslationsForMenuRecommendationMetaArgs = {
  input: UpsertTranslationsForMenuRecommendationMetaInput;
};


/** mutation root */
export type MutationRootUpsertTranslationsForOptionArgs = {
  input: UpsertTranslationsForOptionInput;
};


/** mutation root */
export type MutationRootUpsertTranslationsForPlanArgs = {
  input: UpsertTranslationsForPlanInput;
};


/** mutation root */
export type MutationRootUpsertTranslationsForPlanChoiceArgs = {
  input: UpsertTranslationsForPlanChoiceInput;
};


/** mutation root */
export type MutationRootUpsertTranslationsForPlanOptionArgs = {
  input: UpsertTranslationsForPlanOptionInput;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "onSitePayment" */
export type OnSitePayment = {
  __typename?: 'onSitePayment';
  /** An object relationship */
  closeCashRegister?: Maybe<CloseCashRegister>;
  closeCashRegisterId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  localAccountingSlipId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  onSitePaymentDetails: Array<OnSitePaymentDetail>;
  /** An array relationship */
  onSitePaymentDiscounts: Array<OnSitePaymentDiscount>;
  onSitePaymentId: Scalars['Int'];
  /** An array relationship */
  onSitePaymentSaleses: Array<OnSitePaymentSales>;
  /** An array relationship */
  onSitePaymentTableUsers: Array<OnSitePaymentTableUsers>;
  /** An array relationship */
  payingActivePlanChoices: Array<PayingActivePlanChoice>;
  /** An array relationship */
  payingActivePlanOpenPriceMetas: Array<PayingActivePlanOpenPriceMeta>;
  /** An array relationship */
  payingMenuOrderItems: Array<PayingMenuOrderItem>;
  /** An array relationship */
  receipts: Array<Receipt>;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  voidedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  voidedByClerk?: Maybe<Clerk>;
  voidedByClerkId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "onSitePayment" */
export type OnSitePaymentOnSitePaymentDetailsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailBoolExp>;
};


/** columns and relationships of "onSitePayment" */
export type OnSitePaymentOnSitePaymentDiscountsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountBoolExp>;
};


/** columns and relationships of "onSitePayment" */
export type OnSitePaymentOnSitePaymentSalesesArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentSalesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentSalesOrderBy>>;
  where?: InputMaybe<OnSitePaymentSalesBoolExp>;
};


/** columns and relationships of "onSitePayment" */
export type OnSitePaymentOnSitePaymentTableUsersArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentTableUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentTableUsersOrderBy>>;
  where?: InputMaybe<OnSitePaymentTableUsersBoolExp>;
};


/** columns and relationships of "onSitePayment" */
export type OnSitePaymentPayingActivePlanChoicesArgs = {
  distinct_on?: InputMaybe<Array<PayingActivePlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingActivePlanChoiceOrderBy>>;
  where?: InputMaybe<PayingActivePlanChoiceBoolExp>;
};


/** columns and relationships of "onSitePayment" */
export type OnSitePaymentPayingActivePlanOpenPriceMetasArgs = {
  distinct_on?: InputMaybe<Array<PayingActivePlanOpenPriceMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingActivePlanOpenPriceMetaOrderBy>>;
  where?: InputMaybe<PayingActivePlanOpenPriceMetaBoolExp>;
};


/** columns and relationships of "onSitePayment" */
export type OnSitePaymentPayingMenuOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<PayingMenuOrderItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingMenuOrderItemOrderBy>>;
  where?: InputMaybe<PayingMenuOrderItemBoolExp>;
};


/** columns and relationships of "onSitePayment" */
export type OnSitePaymentReceiptsArgs = {
  distinct_on?: InputMaybe<Array<ReceiptSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReceiptOrderBy>>;
  where?: InputMaybe<ReceiptBoolExp>;
};

/** columns and relationships of "onSitePaymentDetail" */
export type OnSitePaymentDetail = {
  __typename?: 'onSitePaymentDetail';
  _clerkId?: Maybe<Scalars['Int']>;
  _onSitePaymentId?: Maybe<Scalars['Int']>;
  changePrice: Scalars['Int'];
  /** An object relationship */
  clerk?: Maybe<Clerk>;
  clerkId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  company: Company;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  netPrice: Scalars['Int'];
  /** An object relationship */
  onSitePayment?: Maybe<OnSitePayment>;
  onSitePaymentDetailType: Scalars['String'];
  /** An object relationship */
  onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype: OnSitePaymentDetailType;
  onSitePaymentId?: Maybe<Scalars['uuid']>;
  receivedPrice: Scalars['Int'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** columns and relationships of "onSitePaymentDetailType" */
export type OnSitePaymentDetailType = {
  __typename?: 'onSitePaymentDetailType';
  allowChange: Scalars['Boolean'];
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  /** An object relationship */
  foodingJournalOnSitePaymentDetailType?: Maybe<FoodingJournalOnSitePaymentDetailType>;
  id: Scalars['uuid'];
  isArchived: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  label: Scalars['String'];
  onSitePaymentDetailTypeCategory: OnSitePaymentDetailTypeCategoryEnum;
  /** An array relationship */
  onSitePaymentDetails: Array<OnSitePaymentDetail>;
  /** An array relationship */
  pikaichiOnSitePaymentDetailTypes: Array<PikaichiOnSitePaymentDetailType>;
  priority: Scalars['Int'];
  /** An array relationship */
  shopPaymentTypes: Array<ShopPaymentType>;
  /** An array relationship */
  tecAggregationOnSitePaymentDetailTypes: Array<TecAggregationOnSitePaymentDetailType>;
  type: Scalars['String'];
};


/** columns and relationships of "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeOnSitePaymentDetailsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailBoolExp>;
};


/** columns and relationships of "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypePikaichiOnSitePaymentDetailTypesArgs = {
  distinct_on?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<PikaichiOnSitePaymentDetailTypeBoolExp>;
};


/** columns and relationships of "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeShopPaymentTypesArgs = {
  distinct_on?: InputMaybe<Array<ShopPaymentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPaymentTypeOrderBy>>;
  where?: InputMaybe<ShopPaymentTypeBoolExp>;
};


/** columns and relationships of "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeTecAggregationOnSitePaymentDetailTypesArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
};

/** columns and relationships of "onSitePaymentDetailTypeCategoryPriority" */
export type OnSitePaymentDetailTypeCategoryPriority = {
  __typename?: 'onSitePaymentDetailTypeCategoryPriority';
  id: Scalars['uuid'];
  priority: Scalars['Int'];
  value: OnSitePaymentDetailTypeCategoryEnum;
};

/**
 * Boolean expression to filter rows from the table
 * "onSitePaymentDetailTypeCategoryPriority". All fields are combined with a logical 'AND'.
 */
export type OnSitePaymentDetailTypeCategoryPriorityBoolExp = {
  _and?: InputMaybe<Array<OnSitePaymentDetailTypeCategoryPriorityBoolExp>>;
  _not?: InputMaybe<OnSitePaymentDetailTypeCategoryPriorityBoolExp>;
  _or?: InputMaybe<Array<OnSitePaymentDetailTypeCategoryPriorityBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  value?: InputMaybe<OnSitePaymentDetailTypeCategoryEnumComparisonExp>;
};

/** Ordering options when selecting data from "onSitePaymentDetailTypeCategoryPriority". */
export type OnSitePaymentDetailTypeCategoryPriorityOrderBy = {
  id?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "onSitePaymentDetailTypeCategoryPriority" */
export enum OnSitePaymentDetailTypeCategoryPrioritySelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "onSitePaymentDetailTypeCategoryPriority" */
export type OnSitePaymentDetailTypeCategoryPriorityStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OnSitePaymentDetailTypeCategoryPriorityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OnSitePaymentDetailTypeCategoryPriorityStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<OnSitePaymentDetailTypeCategoryEnum>;
};

export enum OnSitePaymentDetailTypeCategoryEnum {
  QrPayment = 'QRPayment',
  Campaign = 'campaign',
  Cash = 'cash',
  CreditCard = 'creditCard',
  ElectronicMoney = 'electronicMoney',
  GiftCertificate = 'giftCertificate',
  InstallmentSales = 'installmentSales',
  OnlinePayment = 'onlinePayment',
  Other = 'other',
  Point = 'point'
}

/**
 * Boolean expression to compare columns of type
 * "onSitePaymentDetailTypeCategory_enum". All fields are combined with logical 'AND'.
 */
export type OnSitePaymentDetailTypeCategoryEnumComparisonExp = {
  _eq?: InputMaybe<OnSitePaymentDetailTypeCategoryEnum>;
  _in?: InputMaybe<Array<OnSitePaymentDetailTypeCategoryEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<OnSitePaymentDetailTypeCategoryEnum>;
  _nin?: InputMaybe<Array<OnSitePaymentDetailTypeCategoryEnum>>;
};

/** aggregated selection of "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeAggregate = {
  __typename?: 'onSitePaymentDetailType_aggregate';
  aggregate?: Maybe<OnSitePaymentDetailTypeAggregateFields>;
  nodes: Array<OnSitePaymentDetailType>;
};

export type OnSitePaymentDetailTypeAggregateBoolExp = {
  bool_and?: InputMaybe<OnSitePaymentDetailTypeAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<OnSitePaymentDetailTypeAggregateBoolExpBoolOr>;
  count?: InputMaybe<OnSitePaymentDetailTypeAggregateBoolExpCount>;
};

export type OnSitePaymentDetailTypeAggregateBoolExpBoolAnd = {
  arguments: OnSitePaymentDetailTypeSelectColumnOnSitePaymentDetailTypeAggregateBoolExpBoolAndArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type OnSitePaymentDetailTypeAggregateBoolExpBoolOr = {
  arguments: OnSitePaymentDetailTypeSelectColumnOnSitePaymentDetailTypeAggregateBoolExpBoolOrArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type OnSitePaymentDetailTypeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OnSitePaymentDetailTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeAggregateFields = {
  __typename?: 'onSitePaymentDetailType_aggregate_fields';
  avg?: Maybe<OnSitePaymentDetailTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OnSitePaymentDetailTypeMaxFields>;
  min?: Maybe<OnSitePaymentDetailTypeMinFields>;
  stddev?: Maybe<OnSitePaymentDetailTypeStddevFields>;
  stddev_pop?: Maybe<OnSitePaymentDetailTypeStddevPopFields>;
  stddev_samp?: Maybe<OnSitePaymentDetailTypeStddevSampFields>;
  sum?: Maybe<OnSitePaymentDetailTypeSumFields>;
  var_pop?: Maybe<OnSitePaymentDetailTypeVarPopFields>;
  var_samp?: Maybe<OnSitePaymentDetailTypeVarSampFields>;
  variance?: Maybe<OnSitePaymentDetailTypeVarianceFields>;
};


/** aggregate fields of "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OnSitePaymentDetailTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeAggregateOrderBy = {
  avg?: InputMaybe<OnSitePaymentDetailTypeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OnSitePaymentDetailTypeMaxOrderBy>;
  min?: InputMaybe<OnSitePaymentDetailTypeMinOrderBy>;
  stddev?: InputMaybe<OnSitePaymentDetailTypeStddevOrderBy>;
  stddev_pop?: InputMaybe<OnSitePaymentDetailTypeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OnSitePaymentDetailTypeStddevSampOrderBy>;
  sum?: InputMaybe<OnSitePaymentDetailTypeSumOrderBy>;
  var_pop?: InputMaybe<OnSitePaymentDetailTypeVarPopOrderBy>;
  var_samp?: InputMaybe<OnSitePaymentDetailTypeVarSampOrderBy>;
  variance?: InputMaybe<OnSitePaymentDetailTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeArrRelInsertInput = {
  data: Array<OnSitePaymentDetailTypeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OnSitePaymentDetailTypeOnConflict>;
};

/** aggregate avg on columns */
export type OnSitePaymentDetailTypeAvgFields = {
  __typename?: 'onSitePaymentDetailType_avg_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeAvgOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "onSitePaymentDetailType". All fields are combined with a logical 'AND'. */
export type OnSitePaymentDetailTypeBoolExp = {
  _and?: InputMaybe<Array<OnSitePaymentDetailTypeBoolExp>>;
  _not?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
  _or?: InputMaybe<Array<OnSitePaymentDetailTypeBoolExp>>;
  allowChange?: InputMaybe<BooleanComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  foodingJournalOnSitePaymentDetailType?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isArchived?: InputMaybe<BooleanComparisonExp>;
  isEditable?: InputMaybe<BooleanComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  onSitePaymentDetailTypeCategory?: InputMaybe<OnSitePaymentDetailTypeCategoryEnumComparisonExp>;
  onSitePaymentDetails?: InputMaybe<OnSitePaymentDetailBoolExp>;
  pikaichiOnSitePaymentDetailTypes?: InputMaybe<PikaichiOnSitePaymentDetailTypeBoolExp>;
  priority?: InputMaybe<IntComparisonExp>;
  shopPaymentTypes?: InputMaybe<ShopPaymentTypeBoolExp>;
  tecAggregationOnSitePaymentDetailTypes?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "onSitePaymentDetailType" */
export enum OnSitePaymentDetailTypeConstraint {
  /** unique or primary key constraint on columns "id" */
  OnSitePaymentDetailTypePkey = 'onSitePaymentDetailType_pkey'
}

/** input type for incrementing numeric columns in table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeIncInput = {
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeInsertInput = {
  allowChange?: InputMaybe<Scalars['Boolean']>;
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  foodingJournalOnSitePaymentDetailType?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isEditable?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeCategory: OnSitePaymentDetailTypeCategoryEnum;
  pikaichiOnSitePaymentDetailTypes?: InputMaybe<PikaichiOnSitePaymentDetailTypeArrRelInsertInput>;
  priority?: InputMaybe<Scalars['Int']>;
  shopPaymentTypes?: InputMaybe<ShopPaymentTypeArrRelInsertInput>;
  tecAggregationOnSitePaymentDetailTypes?: InputMaybe<TecAggregationOnSitePaymentDetailTypeArrRelInsertInput>;
  type: Scalars['String'];
};

/** aggregate max on columns */
export type OnSitePaymentDetailTypeMaxFields = {
  __typename?: 'onSitePaymentDetailType_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OnSitePaymentDetailTypeMinFields = {
  __typename?: 'onSitePaymentDetailType_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeMutationResponse = {
  __typename?: 'onSitePaymentDetailType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OnSitePaymentDetailType>;
};

/** input type for inserting object relation for remote table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeObjRelInsertInput = {
  data: OnSitePaymentDetailTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OnSitePaymentDetailTypeOnConflict>;
};

/** on_conflict condition type for table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeOnConflict = {
  constraint: OnSitePaymentDetailTypeConstraint;
  update_columns?: Array<OnSitePaymentDetailTypeUpdateColumn>;
  where?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
};

/** Ordering options when selecting data from "onSitePaymentDetailType". */
export type OnSitePaymentDetailTypeOrderBy = {
  allowChange?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  foodingJournalOnSitePaymentDetailType?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeOrderBy>;
  id?: InputMaybe<OrderBy>;
  isArchived?: InputMaybe<OrderBy>;
  isEditable?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeCategory?: InputMaybe<OrderBy>;
  onSitePaymentDetails_aggregate?: InputMaybe<OnSitePaymentDetailAggregateOrderBy>;
  pikaichiOnSitePaymentDetailTypes_aggregate?: InputMaybe<PikaichiOnSitePaymentDetailTypeAggregateOrderBy>;
  priority?: InputMaybe<OrderBy>;
  shopPaymentTypes_aggregate?: InputMaybe<ShopPaymentTypeAggregateOrderBy>;
  tecAggregationOnSitePaymentDetailTypes_aggregate?: InputMaybe<TecAggregationOnSitePaymentDetailTypeAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: onSitePaymentDetailType */
export type OnSitePaymentDetailTypePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "onSitePaymentDetailType" */
export enum OnSitePaymentDetailTypeSelectColumn {
  /** column name */
  AllowChange = 'allowChange',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsEditable = 'isEditable',
  /** column name */
  Label = 'label',
  /** column name */
  OnSitePaymentDetailTypeCategory = 'onSitePaymentDetailTypeCategory',
  /** column name */
  Priority = 'priority',
  /** column name */
  Type = 'type'
}

/** select "onSitePaymentDetailType_aggregate_bool_exp_bool_and_arguments_columns" columns of table "onSitePaymentDetailType" */
export enum OnSitePaymentDetailTypeSelectColumnOnSitePaymentDetailTypeAggregateBoolExpBoolAndArgumentsColumnsType {
  /** column name */
  AllowChange = 'allowChange',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsEditable = 'isEditable'
}

/** select "onSitePaymentDetailType_aggregate_bool_exp_bool_or_arguments_columns" columns of table "onSitePaymentDetailType" */
export enum OnSitePaymentDetailTypeSelectColumnOnSitePaymentDetailTypeAggregateBoolExpBoolOrArgumentsColumnsType {
  /** column name */
  AllowChange = 'allowChange',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsEditable = 'isEditable'
}

/** input type for updating data in table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeSetInput = {
  allowChange?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeCategory?: InputMaybe<OnSitePaymentDetailTypeCategoryEnum>;
  priority?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type OnSitePaymentDetailTypeStddevFields = {
  __typename?: 'onSitePaymentDetailType_stddev_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeStddevOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OnSitePaymentDetailTypeStddevPopFields = {
  __typename?: 'onSitePaymentDetailType_stddev_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeStddevPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OnSitePaymentDetailTypeStddevSampFields = {
  __typename?: 'onSitePaymentDetailType_stddev_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeStddevSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OnSitePaymentDetailTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OnSitePaymentDetailTypeStreamCursorValueInput = {
  allowChange?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isEditable?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeCategory?: InputMaybe<OnSitePaymentDetailTypeCategoryEnum>;
  priority?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type OnSitePaymentDetailTypeSumFields = {
  __typename?: 'onSitePaymentDetailType_sum_fields';
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeSumOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** update columns of table "onSitePaymentDetailType" */
export enum OnSitePaymentDetailTypeUpdateColumn {
  /** column name */
  AllowChange = 'allowChange',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Label = 'label',
  /** column name */
  OnSitePaymentDetailTypeCategory = 'onSitePaymentDetailTypeCategory',
  /** column name */
  Priority = 'priority',
  /** column name */
  Type = 'type'
}

export type OnSitePaymentDetailTypeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OnSitePaymentDetailTypeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OnSitePaymentDetailTypeSetInput>;
  /** filter the rows which have to be updated */
  where: OnSitePaymentDetailTypeBoolExp;
};

/** aggregate var_pop on columns */
export type OnSitePaymentDetailTypeVarPopFields = {
  __typename?: 'onSitePaymentDetailType_var_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeVarPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OnSitePaymentDetailTypeVarSampFields = {
  __typename?: 'onSitePaymentDetailType_var_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeVarSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OnSitePaymentDetailTypeVarianceFields = {
  __typename?: 'onSitePaymentDetailType_variance_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "onSitePaymentDetailType" */
export type OnSitePaymentDetailTypeVarianceOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "onSitePaymentDetail" */
export type OnSitePaymentDetailAggregateOrderBy = {
  avg?: InputMaybe<OnSitePaymentDetailAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OnSitePaymentDetailMaxOrderBy>;
  min?: InputMaybe<OnSitePaymentDetailMinOrderBy>;
  stddev?: InputMaybe<OnSitePaymentDetailStddevOrderBy>;
  stddev_pop?: InputMaybe<OnSitePaymentDetailStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OnSitePaymentDetailStddevSampOrderBy>;
  sum?: InputMaybe<OnSitePaymentDetailSumOrderBy>;
  var_pop?: InputMaybe<OnSitePaymentDetailVarPopOrderBy>;
  var_samp?: InputMaybe<OnSitePaymentDetailVarSampOrderBy>;
  variance?: InputMaybe<OnSitePaymentDetailVarianceOrderBy>;
};

/** order by avg() on columns of table "onSitePaymentDetail" */
export type OnSitePaymentDetailAvgOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  changePrice?: InputMaybe<OrderBy>;
  netPrice?: InputMaybe<OrderBy>;
  receivedPrice?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "onSitePaymentDetail". All fields are combined with a logical 'AND'. */
export type OnSitePaymentDetailBoolExp = {
  _and?: InputMaybe<Array<OnSitePaymentDetailBoolExp>>;
  _clerkId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<OnSitePaymentDetailBoolExp>;
  _onSitePaymentId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<OnSitePaymentDetailBoolExp>>;
  changePrice?: InputMaybe<IntComparisonExp>;
  clerk?: InputMaybe<ClerkBoolExp>;
  clerkId?: InputMaybe<UuidComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  netPrice?: InputMaybe<IntComparisonExp>;
  onSitePayment?: InputMaybe<OnSitePaymentBoolExp>;
  onSitePaymentDetailType?: InputMaybe<StringComparisonExp>;
  onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
  onSitePaymentId?: InputMaybe<UuidComparisonExp>;
  receivedPrice?: InputMaybe<IntComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "onSitePaymentDetail" */
export type OnSitePaymentDetailMaxOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  changePrice?: InputMaybe<OrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  netPrice?: InputMaybe<OrderBy>;
  onSitePaymentDetailType?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  receivedPrice?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "onSitePaymentDetail" */
export type OnSitePaymentDetailMinOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  changePrice?: InputMaybe<OrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  netPrice?: InputMaybe<OrderBy>;
  onSitePaymentDetailType?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  receivedPrice?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "onSitePaymentDetail". */
export type OnSitePaymentDetailOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  changePrice?: InputMaybe<OrderBy>;
  clerk?: InputMaybe<ClerkOrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  netPrice?: InputMaybe<OrderBy>;
  onSitePayment?: InputMaybe<OnSitePaymentOrderBy>;
  onSitePaymentDetailType?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype?: InputMaybe<OnSitePaymentDetailTypeOrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  receivedPrice?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "onSitePaymentDetail" */
export enum OnSitePaymentDetailSelectColumn {
  /** column name */
  _ClerkId = '_clerkId',
  /** column name */
  _OnSitePaymentId = '_onSitePaymentId',
  /** column name */
  ChangePrice = 'changePrice',
  /** column name */
  ClerkId = 'clerkId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NetPrice = 'netPrice',
  /** column name */
  OnSitePaymentDetailType = 'onSitePaymentDetailType',
  /** column name */
  OnSitePaymentId = 'onSitePaymentId',
  /** column name */
  ReceivedPrice = 'receivedPrice',
  /** column name */
  ShopId = 'shopId'
}

/** order by stddev() on columns of table "onSitePaymentDetail" */
export type OnSitePaymentDetailStddevOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  changePrice?: InputMaybe<OrderBy>;
  netPrice?: InputMaybe<OrderBy>;
  receivedPrice?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "onSitePaymentDetail" */
export type OnSitePaymentDetailStddevPopOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  changePrice?: InputMaybe<OrderBy>;
  netPrice?: InputMaybe<OrderBy>;
  receivedPrice?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "onSitePaymentDetail" */
export type OnSitePaymentDetailStddevSampOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  changePrice?: InputMaybe<OrderBy>;
  netPrice?: InputMaybe<OrderBy>;
  receivedPrice?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "onSitePaymentDetail" */
export type OnSitePaymentDetailStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OnSitePaymentDetailStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OnSitePaymentDetailStreamCursorValueInput = {
  _clerkId?: InputMaybe<Scalars['Int']>;
  _onSitePaymentId?: InputMaybe<Scalars['Int']>;
  changePrice?: InputMaybe<Scalars['Int']>;
  clerkId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  netPrice?: InputMaybe<Scalars['Int']>;
  onSitePaymentDetailType?: InputMaybe<Scalars['String']>;
  onSitePaymentId?: InputMaybe<Scalars['uuid']>;
  receivedPrice?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "onSitePaymentDetail" */
export type OnSitePaymentDetailSumOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  changePrice?: InputMaybe<OrderBy>;
  netPrice?: InputMaybe<OrderBy>;
  receivedPrice?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "onSitePaymentDetail" */
export type OnSitePaymentDetailVarPopOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  changePrice?: InputMaybe<OrderBy>;
  netPrice?: InputMaybe<OrderBy>;
  receivedPrice?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "onSitePaymentDetail" */
export type OnSitePaymentDetailVarSampOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  changePrice?: InputMaybe<OrderBy>;
  netPrice?: InputMaybe<OrderBy>;
  receivedPrice?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "onSitePaymentDetail" */
export type OnSitePaymentDetailVarianceOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  changePrice?: InputMaybe<OrderBy>;
  netPrice?: InputMaybe<OrderBy>;
  receivedPrice?: InputMaybe<OrderBy>;
};

/** columns and relationships of "onSitePaymentDiscount" */
export type OnSitePaymentDiscount = {
  __typename?: 'onSitePaymentDiscount';
  _clerkId?: Maybe<Scalars['Int']>;
  _onSitePaymentId?: Maybe<Scalars['Int']>;
  amount: Scalars['Int'];
  /** An object relationship */
  clerk?: Maybe<Clerk>;
  clerkId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  company: Company;
  /** timestamp */
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  onSitePayment?: Maybe<OnSitePayment>;
  onSitePaymentDiscountId: Scalars['uuid'];
  onSitePaymentDiscountType: Scalars['String'];
  /** An object relationship */
  onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype: OnSitePaymentDiscountType;
  onSitePaymentId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** columns and relationships of "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountType = {
  __typename?: 'onSitePaymentDiscountType';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  /** An object relationship */
  foodingJournalOnSitePaymentDiscountType?: Maybe<FoodingJournalOnSitePaymentDiscountType>;
  id: Scalars['uuid'];
  isArchived: Scalars['Boolean'];
  label: Scalars['String'];
  /** An array relationship */
  onSitePaymentDiscounts: Array<OnSitePaymentDiscount>;
  /** An array relationship */
  pikaichiOnSitePaymentDiscountTypes: Array<PikaichiOnSitePaymentDiscountType>;
  priority: Scalars['Int'];
  /** An array relationship */
  shopDiscountTypes: Array<ShopDiscountType>;
  type: Scalars['String'];
};


/** columns and relationships of "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeOnSitePaymentDiscountsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountBoolExp>;
};


/** columns and relationships of "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypePikaichiOnSitePaymentDiscountTypesArgs = {
  distinct_on?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<PikaichiOnSitePaymentDiscountTypeBoolExp>;
};


/** columns and relationships of "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeShopDiscountTypesArgs = {
  distinct_on?: InputMaybe<Array<ShopDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopDiscountTypeOrderBy>>;
  where?: InputMaybe<ShopDiscountTypeBoolExp>;
};

/** aggregated selection of "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeAggregate = {
  __typename?: 'onSitePaymentDiscountType_aggregate';
  aggregate?: Maybe<OnSitePaymentDiscountTypeAggregateFields>;
  nodes: Array<OnSitePaymentDiscountType>;
};

export type OnSitePaymentDiscountTypeAggregateBoolExp = {
  bool_and?: InputMaybe<OnSitePaymentDiscountTypeAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<OnSitePaymentDiscountTypeAggregateBoolExpBoolOr>;
  count?: InputMaybe<OnSitePaymentDiscountTypeAggregateBoolExpCount>;
};

export type OnSitePaymentDiscountTypeAggregateBoolExpBoolAnd = {
  arguments: OnSitePaymentDiscountTypeSelectColumnOnSitePaymentDiscountTypeAggregateBoolExpBoolAndArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type OnSitePaymentDiscountTypeAggregateBoolExpBoolOr = {
  arguments: OnSitePaymentDiscountTypeSelectColumnOnSitePaymentDiscountTypeAggregateBoolExpBoolOrArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type OnSitePaymentDiscountTypeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OnSitePaymentDiscountTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeAggregateFields = {
  __typename?: 'onSitePaymentDiscountType_aggregate_fields';
  avg?: Maybe<OnSitePaymentDiscountTypeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OnSitePaymentDiscountTypeMaxFields>;
  min?: Maybe<OnSitePaymentDiscountTypeMinFields>;
  stddev?: Maybe<OnSitePaymentDiscountTypeStddevFields>;
  stddev_pop?: Maybe<OnSitePaymentDiscountTypeStddevPopFields>;
  stddev_samp?: Maybe<OnSitePaymentDiscountTypeStddevSampFields>;
  sum?: Maybe<OnSitePaymentDiscountTypeSumFields>;
  var_pop?: Maybe<OnSitePaymentDiscountTypeVarPopFields>;
  var_samp?: Maybe<OnSitePaymentDiscountTypeVarSampFields>;
  variance?: Maybe<OnSitePaymentDiscountTypeVarianceFields>;
};


/** aggregate fields of "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OnSitePaymentDiscountTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeAggregateOrderBy = {
  avg?: InputMaybe<OnSitePaymentDiscountTypeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OnSitePaymentDiscountTypeMaxOrderBy>;
  min?: InputMaybe<OnSitePaymentDiscountTypeMinOrderBy>;
  stddev?: InputMaybe<OnSitePaymentDiscountTypeStddevOrderBy>;
  stddev_pop?: InputMaybe<OnSitePaymentDiscountTypeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OnSitePaymentDiscountTypeStddevSampOrderBy>;
  sum?: InputMaybe<OnSitePaymentDiscountTypeSumOrderBy>;
  var_pop?: InputMaybe<OnSitePaymentDiscountTypeVarPopOrderBy>;
  var_samp?: InputMaybe<OnSitePaymentDiscountTypeVarSampOrderBy>;
  variance?: InputMaybe<OnSitePaymentDiscountTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeArrRelInsertInput = {
  data: Array<OnSitePaymentDiscountTypeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OnSitePaymentDiscountTypeOnConflict>;
};

/** aggregate avg on columns */
export type OnSitePaymentDiscountTypeAvgFields = {
  __typename?: 'onSitePaymentDiscountType_avg_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeAvgOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "onSitePaymentDiscountType". All fields are combined with a logical 'AND'. */
export type OnSitePaymentDiscountTypeBoolExp = {
  _and?: InputMaybe<Array<OnSitePaymentDiscountTypeBoolExp>>;
  _not?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
  _or?: InputMaybe<Array<OnSitePaymentDiscountTypeBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  foodingJournalOnSitePaymentDiscountType?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isArchived?: InputMaybe<BooleanComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  onSitePaymentDiscounts?: InputMaybe<OnSitePaymentDiscountBoolExp>;
  pikaichiOnSitePaymentDiscountTypes?: InputMaybe<PikaichiOnSitePaymentDiscountTypeBoolExp>;
  priority?: InputMaybe<IntComparisonExp>;
  shopDiscountTypes?: InputMaybe<ShopDiscountTypeBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "onSitePaymentDiscountType" */
export enum OnSitePaymentDiscountTypeConstraint {
  /** unique or primary key constraint on columns "id" */
  OnSitePaymentDiscountTypePkey = 'onSitePaymentDiscountType_pkey'
}

/** input type for incrementing numeric columns in table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeIncInput = {
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  foodingJournalOnSitePaymentDiscountType?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  pikaichiOnSitePaymentDiscountTypes?: InputMaybe<PikaichiOnSitePaymentDiscountTypeArrRelInsertInput>;
  priority?: InputMaybe<Scalars['Int']>;
  shopDiscountTypes?: InputMaybe<ShopDiscountTypeArrRelInsertInput>;
  type: Scalars['String'];
};

/** aggregate max on columns */
export type OnSitePaymentDiscountTypeMaxFields = {
  __typename?: 'onSitePaymentDiscountType_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OnSitePaymentDiscountTypeMinFields = {
  __typename?: 'onSitePaymentDiscountType_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeMutationResponse = {
  __typename?: 'onSitePaymentDiscountType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OnSitePaymentDiscountType>;
};

/** input type for inserting object relation for remote table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeObjRelInsertInput = {
  data: OnSitePaymentDiscountTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OnSitePaymentDiscountTypeOnConflict>;
};

/** on_conflict condition type for table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeOnConflict = {
  constraint: OnSitePaymentDiscountTypeConstraint;
  update_columns?: Array<OnSitePaymentDiscountTypeUpdateColumn>;
  where?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
};

/** Ordering options when selecting data from "onSitePaymentDiscountType". */
export type OnSitePaymentDiscountTypeOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  foodingJournalOnSitePaymentDiscountType?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeOrderBy>;
  id?: InputMaybe<OrderBy>;
  isArchived?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  onSitePaymentDiscounts_aggregate?: InputMaybe<OnSitePaymentDiscountAggregateOrderBy>;
  pikaichiOnSitePaymentDiscountTypes_aggregate?: InputMaybe<PikaichiOnSitePaymentDiscountTypeAggregateOrderBy>;
  priority?: InputMaybe<OrderBy>;
  shopDiscountTypes_aggregate?: InputMaybe<ShopDiscountTypeAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: onSitePaymentDiscountType */
export type OnSitePaymentDiscountTypePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "onSitePaymentDiscountType" */
export enum OnSitePaymentDiscountTypeSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Label = 'label',
  /** column name */
  Priority = 'priority',
  /** column name */
  Type = 'type'
}

/**
 * select "onSitePaymentDiscountType_aggregate_bool_exp_bool_and_arguments_columns"
 * columns of table "onSitePaymentDiscountType"
 */
export enum OnSitePaymentDiscountTypeSelectColumnOnSitePaymentDiscountTypeAggregateBoolExpBoolAndArgumentsColumnsType {
  /** column name */
  IsArchived = 'isArchived'
}

/** select "onSitePaymentDiscountType_aggregate_bool_exp_bool_or_arguments_columns" columns of table "onSitePaymentDiscountType" */
export enum OnSitePaymentDiscountTypeSelectColumnOnSitePaymentDiscountTypeAggregateBoolExpBoolOrArgumentsColumnsType {
  /** column name */
  IsArchived = 'isArchived'
}

/** input type for updating data in table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type OnSitePaymentDiscountTypeStddevFields = {
  __typename?: 'onSitePaymentDiscountType_stddev_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeStddevOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OnSitePaymentDiscountTypeStddevPopFields = {
  __typename?: 'onSitePaymentDiscountType_stddev_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeStddevPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OnSitePaymentDiscountTypeStddevSampFields = {
  __typename?: 'onSitePaymentDiscountType_stddev_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeStddevSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OnSitePaymentDiscountTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OnSitePaymentDiscountTypeStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type OnSitePaymentDiscountTypeSumFields = {
  __typename?: 'onSitePaymentDiscountType_sum_fields';
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeSumOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** update columns of table "onSitePaymentDiscountType" */
export enum OnSitePaymentDiscountTypeUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Label = 'label',
  /** column name */
  Priority = 'priority',
  /** column name */
  Type = 'type'
}

export type OnSitePaymentDiscountTypeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OnSitePaymentDiscountTypeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OnSitePaymentDiscountTypeSetInput>;
  /** filter the rows which have to be updated */
  where: OnSitePaymentDiscountTypeBoolExp;
};

/** aggregate var_pop on columns */
export type OnSitePaymentDiscountTypeVarPopFields = {
  __typename?: 'onSitePaymentDiscountType_var_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeVarPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OnSitePaymentDiscountTypeVarSampFields = {
  __typename?: 'onSitePaymentDiscountType_var_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeVarSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OnSitePaymentDiscountTypeVarianceFields = {
  __typename?: 'onSitePaymentDiscountType_variance_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "onSitePaymentDiscountType" */
export type OnSitePaymentDiscountTypeVarianceOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountAggregateOrderBy = {
  avg?: InputMaybe<OnSitePaymentDiscountAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OnSitePaymentDiscountMaxOrderBy>;
  min?: InputMaybe<OnSitePaymentDiscountMinOrderBy>;
  stddev?: InputMaybe<OnSitePaymentDiscountStddevOrderBy>;
  stddev_pop?: InputMaybe<OnSitePaymentDiscountStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OnSitePaymentDiscountStddevSampOrderBy>;
  sum?: InputMaybe<OnSitePaymentDiscountSumOrderBy>;
  var_pop?: InputMaybe<OnSitePaymentDiscountVarPopOrderBy>;
  var_samp?: InputMaybe<OnSitePaymentDiscountVarSampOrderBy>;
  variance?: InputMaybe<OnSitePaymentDiscountVarianceOrderBy>;
};

/** order by avg() on columns of table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountAvgOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "onSitePaymentDiscount". All fields are combined with a logical 'AND'. */
export type OnSitePaymentDiscountBoolExp = {
  _and?: InputMaybe<Array<OnSitePaymentDiscountBoolExp>>;
  _clerkId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<OnSitePaymentDiscountBoolExp>;
  _onSitePaymentId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<OnSitePaymentDiscountBoolExp>>;
  amount?: InputMaybe<IntComparisonExp>;
  clerk?: InputMaybe<ClerkBoolExp>;
  clerkId?: InputMaybe<UuidComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  onSitePayment?: InputMaybe<OnSitePaymentBoolExp>;
  onSitePaymentDiscountId?: InputMaybe<UuidComparisonExp>;
  onSitePaymentDiscountType?: InputMaybe<StringComparisonExp>;
  onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
  onSitePaymentId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountMaxOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  /** timestamp */
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  onSitePaymentDiscountId?: InputMaybe<OrderBy>;
  onSitePaymentDiscountType?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountMinOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  /** timestamp */
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  onSitePaymentDiscountId?: InputMaybe<OrderBy>;
  onSitePaymentDiscountType?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "onSitePaymentDiscount". */
export type OnSitePaymentDiscountOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
  clerk?: InputMaybe<ClerkOrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  onSitePayment?: InputMaybe<OnSitePaymentOrderBy>;
  onSitePaymentDiscountId?: InputMaybe<OrderBy>;
  onSitePaymentDiscountType?: InputMaybe<OrderBy>;
  onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype?: InputMaybe<OnSitePaymentDiscountTypeOrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "onSitePaymentDiscount" */
export enum OnSitePaymentDiscountSelectColumn {
  /** column name */
  _ClerkId = '_clerkId',
  /** column name */
  _OnSitePaymentId = '_onSitePaymentId',
  /** column name */
  Amount = 'amount',
  /** column name */
  ClerkId = 'clerkId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  OnSitePaymentDiscountId = 'onSitePaymentDiscountId',
  /** column name */
  OnSitePaymentDiscountType = 'onSitePaymentDiscountType',
  /** column name */
  OnSitePaymentId = 'onSitePaymentId',
  /** column name */
  ShopId = 'shopId'
}

/** order by stddev() on columns of table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountStddevOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountStddevPopOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountStddevSampOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OnSitePaymentDiscountStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OnSitePaymentDiscountStreamCursorValueInput = {
  _clerkId?: InputMaybe<Scalars['Int']>;
  _onSitePaymentId?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['Int']>;
  clerkId?: InputMaybe<Scalars['uuid']>;
  /** timestamp */
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  onSitePaymentDiscountId?: InputMaybe<Scalars['uuid']>;
  onSitePaymentDiscountType?: InputMaybe<Scalars['String']>;
  onSitePaymentId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountSumOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountVarPopOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountVarSampOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "onSitePaymentDiscount" */
export type OnSitePaymentDiscountVarianceOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  amount?: InputMaybe<OrderBy>;
};

/** columns and relationships of "onSitePaymentSales" */
export type OnSitePaymentSales = {
  __typename?: 'onSitePaymentSales';
  _onSitePaymentId: Scalars['Int'];
  /** An object relationship */
  onSitePayment: OnSitePayment;
  onSitePaymentId: Scalars['uuid'];
  onSitePaymentSalesId: Scalars['uuid'];
  taxExcludedAmount: Scalars['Int'];
  taxIncludedAmount: Scalars['Int'];
  taxRate: Scalars['numeric'];
};

/** order by aggregate values of table "onSitePaymentSales" */
export type OnSitePaymentSalesAggregateOrderBy = {
  avg?: InputMaybe<OnSitePaymentSalesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OnSitePaymentSalesMaxOrderBy>;
  min?: InputMaybe<OnSitePaymentSalesMinOrderBy>;
  stddev?: InputMaybe<OnSitePaymentSalesStddevOrderBy>;
  stddev_pop?: InputMaybe<OnSitePaymentSalesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OnSitePaymentSalesStddevSampOrderBy>;
  sum?: InputMaybe<OnSitePaymentSalesSumOrderBy>;
  var_pop?: InputMaybe<OnSitePaymentSalesVarPopOrderBy>;
  var_samp?: InputMaybe<OnSitePaymentSalesVarSampOrderBy>;
  variance?: InputMaybe<OnSitePaymentSalesVarianceOrderBy>;
};

/** order by avg() on columns of table "onSitePaymentSales" */
export type OnSitePaymentSalesAvgOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "onSitePaymentSales". All fields are combined with a logical 'AND'. */
export type OnSitePaymentSalesBoolExp = {
  _and?: InputMaybe<Array<OnSitePaymentSalesBoolExp>>;
  _not?: InputMaybe<OnSitePaymentSalesBoolExp>;
  _onSitePaymentId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<OnSitePaymentSalesBoolExp>>;
  onSitePayment?: InputMaybe<OnSitePaymentBoolExp>;
  onSitePaymentId?: InputMaybe<UuidComparisonExp>;
  onSitePaymentSalesId?: InputMaybe<UuidComparisonExp>;
  taxExcludedAmount?: InputMaybe<IntComparisonExp>;
  taxIncludedAmount?: InputMaybe<IntComparisonExp>;
  taxRate?: InputMaybe<NumericComparisonExp>;
};

/** order by max() on columns of table "onSitePaymentSales" */
export type OnSitePaymentSalesMaxOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  onSitePaymentSalesId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "onSitePaymentSales" */
export type OnSitePaymentSalesMinOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  onSitePaymentSalesId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "onSitePaymentSales". */
export type OnSitePaymentSalesOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  onSitePayment?: InputMaybe<OnSitePaymentOrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  onSitePaymentSalesId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** select columns of table "onSitePaymentSales" */
export enum OnSitePaymentSalesSelectColumn {
  /** column name */
  _OnSitePaymentId = '_onSitePaymentId',
  /** column name */
  OnSitePaymentId = 'onSitePaymentId',
  /** column name */
  OnSitePaymentSalesId = 'onSitePaymentSalesId',
  /** column name */
  TaxExcludedAmount = 'taxExcludedAmount',
  /** column name */
  TaxIncludedAmount = 'taxIncludedAmount',
  /** column name */
  TaxRate = 'taxRate'
}

/** order by stddev() on columns of table "onSitePaymentSales" */
export type OnSitePaymentSalesStddevOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "onSitePaymentSales" */
export type OnSitePaymentSalesStddevPopOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "onSitePaymentSales" */
export type OnSitePaymentSalesStddevSampOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "onSitePaymentSales" */
export type OnSitePaymentSalesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OnSitePaymentSalesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OnSitePaymentSalesStreamCursorValueInput = {
  _onSitePaymentId?: InputMaybe<Scalars['Int']>;
  onSitePaymentId?: InputMaybe<Scalars['uuid']>;
  onSitePaymentSalesId?: InputMaybe<Scalars['uuid']>;
  taxExcludedAmount?: InputMaybe<Scalars['Int']>;
  taxIncludedAmount?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "onSitePaymentSales" */
export type OnSitePaymentSalesSumOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "onSitePaymentSales" */
export type OnSitePaymentSalesVarPopOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "onSitePaymentSales" */
export type OnSitePaymentSalesVarSampOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "onSitePaymentSales" */
export type OnSitePaymentSalesVarianceOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  taxExcludedAmount?: InputMaybe<OrderBy>;
  taxIncludedAmount?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** columns and relationships of "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsers = {
  __typename?: 'onSitePaymentTableUsers';
  _onSitePaymentId: Scalars['Int'];
  /** An object relationship */
  onSitePayment: OnSitePayment;
  onSitePaymentId: Scalars['uuid'];
  /** An object relationship */
  tableUser: TableUser;
  tableUserId: Scalars['uuid'];
};

/** order by aggregate values of table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersAggregateOrderBy = {
  avg?: InputMaybe<OnSitePaymentTableUsersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OnSitePaymentTableUsersMaxOrderBy>;
  min?: InputMaybe<OnSitePaymentTableUsersMinOrderBy>;
  stddev?: InputMaybe<OnSitePaymentTableUsersStddevOrderBy>;
  stddev_pop?: InputMaybe<OnSitePaymentTableUsersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OnSitePaymentTableUsersStddevSampOrderBy>;
  sum?: InputMaybe<OnSitePaymentTableUsersSumOrderBy>;
  var_pop?: InputMaybe<OnSitePaymentTableUsersVarPopOrderBy>;
  var_samp?: InputMaybe<OnSitePaymentTableUsersVarSampOrderBy>;
  variance?: InputMaybe<OnSitePaymentTableUsersVarianceOrderBy>;
};

/** order by avg() on columns of table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersAvgOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "onSitePaymentTableUsers". All fields are combined with a logical 'AND'. */
export type OnSitePaymentTableUsersBoolExp = {
  _and?: InputMaybe<Array<OnSitePaymentTableUsersBoolExp>>;
  _not?: InputMaybe<OnSitePaymentTableUsersBoolExp>;
  _onSitePaymentId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<OnSitePaymentTableUsersBoolExp>>;
  onSitePayment?: InputMaybe<OnSitePaymentBoolExp>;
  onSitePaymentId?: InputMaybe<UuidComparisonExp>;
  tableUser?: InputMaybe<TableUserBoolExp>;
  tableUserId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersMaxOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersMinOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "onSitePaymentTableUsers". */
export type OnSitePaymentTableUsersOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  onSitePayment?: InputMaybe<OnSitePaymentOrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  tableUser?: InputMaybe<TableUserOrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
};

/** select columns of table "onSitePaymentTableUsers" */
export enum OnSitePaymentTableUsersSelectColumn {
  /** column name */
  _OnSitePaymentId = '_onSitePaymentId',
  /** column name */
  OnSitePaymentId = 'onSitePaymentId',
  /** column name */
  TableUserId = 'tableUserId'
}

/** order by stddev() on columns of table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersStddevOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersStddevPopOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersStddevSampOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OnSitePaymentTableUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OnSitePaymentTableUsersStreamCursorValueInput = {
  _onSitePaymentId?: InputMaybe<Scalars['Int']>;
  onSitePaymentId?: InputMaybe<Scalars['uuid']>;
  tableUserId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersSumOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersVarPopOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersVarSampOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "onSitePaymentTableUsers" */
export type OnSitePaymentTableUsersVarianceOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "onSitePayment" */
export type OnSitePaymentAggregateOrderBy = {
  avg?: InputMaybe<OnSitePaymentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OnSitePaymentMaxOrderBy>;
  min?: InputMaybe<OnSitePaymentMinOrderBy>;
  stddev?: InputMaybe<OnSitePaymentStddevOrderBy>;
  stddev_pop?: InputMaybe<OnSitePaymentStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OnSitePaymentStddevSampOrderBy>;
  sum?: InputMaybe<OnSitePaymentSumOrderBy>;
  var_pop?: InputMaybe<OnSitePaymentVarPopOrderBy>;
  var_samp?: InputMaybe<OnSitePaymentVarSampOrderBy>;
  variance?: InputMaybe<OnSitePaymentVarianceOrderBy>;
};

/** order by avg() on columns of table "onSitePayment" */
export type OnSitePaymentAvgOrderBy = {
  localAccountingSlipId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  voidedByClerkId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "onSitePayment". All fields are combined with a logical 'AND'. */
export type OnSitePaymentBoolExp = {
  _and?: InputMaybe<Array<OnSitePaymentBoolExp>>;
  _not?: InputMaybe<OnSitePaymentBoolExp>;
  _or?: InputMaybe<Array<OnSitePaymentBoolExp>>;
  closeCashRegister?: InputMaybe<CloseCashRegisterBoolExp>;
  closeCashRegisterId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  localAccountingSlipId?: InputMaybe<IntComparisonExp>;
  onSitePaymentDetails?: InputMaybe<OnSitePaymentDetailBoolExp>;
  onSitePaymentDiscounts?: InputMaybe<OnSitePaymentDiscountBoolExp>;
  onSitePaymentId?: InputMaybe<IntComparisonExp>;
  onSitePaymentSaleses?: InputMaybe<OnSitePaymentSalesBoolExp>;
  onSitePaymentTableUsers?: InputMaybe<OnSitePaymentTableUsersBoolExp>;
  payingActivePlanChoices?: InputMaybe<PayingActivePlanChoiceBoolExp>;
  payingActivePlanOpenPriceMetas?: InputMaybe<PayingActivePlanOpenPriceMetaBoolExp>;
  payingMenuOrderItems?: InputMaybe<PayingMenuOrderItemBoolExp>;
  receipts?: InputMaybe<ReceiptBoolExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  voidedAt?: InputMaybe<TimestamptzComparisonExp>;
  voidedByClerk?: InputMaybe<ClerkBoolExp>;
  voidedByClerkId?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "onSitePayment" */
export type OnSitePaymentMaxOrderBy = {
  closeCashRegisterId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  localAccountingSlipId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  voidedAt?: InputMaybe<OrderBy>;
  voidedByClerkId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "onSitePayment" */
export type OnSitePaymentMinOrderBy = {
  closeCashRegisterId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  localAccountingSlipId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  voidedAt?: InputMaybe<OrderBy>;
  voidedByClerkId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "onSitePayment". */
export type OnSitePaymentOrderBy = {
  closeCashRegister?: InputMaybe<CloseCashRegisterOrderBy>;
  closeCashRegisterId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  localAccountingSlipId?: InputMaybe<OrderBy>;
  onSitePaymentDetails_aggregate?: InputMaybe<OnSitePaymentDetailAggregateOrderBy>;
  onSitePaymentDiscounts_aggregate?: InputMaybe<OnSitePaymentDiscountAggregateOrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  onSitePaymentSaleses_aggregate?: InputMaybe<OnSitePaymentSalesAggregateOrderBy>;
  onSitePaymentTableUsers_aggregate?: InputMaybe<OnSitePaymentTableUsersAggregateOrderBy>;
  payingActivePlanChoices_aggregate?: InputMaybe<PayingActivePlanChoiceAggregateOrderBy>;
  payingActivePlanOpenPriceMetas_aggregate?: InputMaybe<PayingActivePlanOpenPriceMetaAggregateOrderBy>;
  payingMenuOrderItems_aggregate?: InputMaybe<PayingMenuOrderItemAggregateOrderBy>;
  receipts_aggregate?: InputMaybe<ReceiptAggregateOrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  voidedAt?: InputMaybe<OrderBy>;
  voidedByClerk?: InputMaybe<ClerkOrderBy>;
  voidedByClerkId?: InputMaybe<OrderBy>;
};

/** select columns of table "onSitePayment" */
export enum OnSitePaymentSelectColumn {
  /** column name */
  CloseCashRegisterId = 'closeCashRegisterId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LocalAccountingSlipId = 'localAccountingSlipId',
  /** column name */
  OnSitePaymentId = 'onSitePaymentId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  VoidedAt = 'voidedAt',
  /** column name */
  VoidedByClerkId = 'voidedByClerkId'
}

/** order by stddev() on columns of table "onSitePayment" */
export type OnSitePaymentStddevOrderBy = {
  localAccountingSlipId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  voidedByClerkId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "onSitePayment" */
export type OnSitePaymentStddevPopOrderBy = {
  localAccountingSlipId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  voidedByClerkId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "onSitePayment" */
export type OnSitePaymentStddevSampOrderBy = {
  localAccountingSlipId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  voidedByClerkId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "onSitePayment" */
export type OnSitePaymentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OnSitePaymentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OnSitePaymentStreamCursorValueInput = {
  closeCashRegisterId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  localAccountingSlipId?: InputMaybe<Scalars['Int']>;
  onSitePaymentId?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  voidedAt?: InputMaybe<Scalars['timestamptz']>;
  voidedByClerkId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "onSitePayment" */
export type OnSitePaymentSumOrderBy = {
  localAccountingSlipId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  voidedByClerkId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "onSitePayment" */
export type OnSitePaymentVarPopOrderBy = {
  localAccountingSlipId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  voidedByClerkId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "onSitePayment" */
export type OnSitePaymentVarSampOrderBy = {
  localAccountingSlipId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  voidedByClerkId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "onSitePayment" */
export type OnSitePaymentVarianceOrderBy = {
  localAccountingSlipId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  voidedByClerkId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "onlinePaymentPrinterRoleMeta" */
export type OnlinePaymentPrinterRoleMeta = {
  __typename?: 'onlinePaymentPrinterRoleMeta';
  id: Scalars['uuid'];
  printerRoleId: Scalars['uuid'];
  shopId: Scalars['uuid'];
};

/**
 * Boolean expression to filter rows from the table "onlinePaymentPrinterRoleMeta".
 * All fields are combined with a logical 'AND'.
 */
export type OnlinePaymentPrinterRoleMetaBoolExp = {
  _and?: InputMaybe<Array<OnlinePaymentPrinterRoleMetaBoolExp>>;
  _not?: InputMaybe<OnlinePaymentPrinterRoleMetaBoolExp>;
  _or?: InputMaybe<Array<OnlinePaymentPrinterRoleMetaBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  printerRoleId?: InputMaybe<UuidComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "onlinePaymentPrinterRoleMeta" */
export enum OnlinePaymentPrinterRoleMetaConstraint {
  /** unique or primary key constraint on columns "id" */
  OnlinePaymentPrinterRoleMetaPkey = 'onlinePaymentPrinterRoleMeta_pkey',
  /** unique or primary key constraint on columns "shopId", "printerRoleId" */
  OnlinePaymentPrinterRoleMetaShopIdPrinterRoleIdKey = 'onlinePaymentPrinterRoleMeta_shopId_printerRoleId_key'
}

/** input type for inserting data into table "onlinePaymentPrinterRoleMeta" */
export type OnlinePaymentPrinterRoleMetaInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  printerRoleId: Scalars['uuid'];
  shopId: Scalars['uuid'];
};

/** response of any mutation on the table "onlinePaymentPrinterRoleMeta" */
export type OnlinePaymentPrinterRoleMetaMutationResponse = {
  __typename?: 'onlinePaymentPrinterRoleMeta_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OnlinePaymentPrinterRoleMeta>;
};

/** on_conflict condition type for table "onlinePaymentPrinterRoleMeta" */
export type OnlinePaymentPrinterRoleMetaOnConflict = {
  constraint: OnlinePaymentPrinterRoleMetaConstraint;
  update_columns?: Array<OnlinePaymentPrinterRoleMetaUpdateColumn>;
  where?: InputMaybe<OnlinePaymentPrinterRoleMetaBoolExp>;
};

/** Ordering options when selecting data from "onlinePaymentPrinterRoleMeta". */
export type OnlinePaymentPrinterRoleMetaOrderBy = {
  id?: InputMaybe<OrderBy>;
  printerRoleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "onlinePaymentPrinterRoleMeta" */
export enum OnlinePaymentPrinterRoleMetaSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  PrinterRoleId = 'printerRoleId',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "onlinePaymentPrinterRoleMeta" */
export type OnlinePaymentPrinterRoleMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OnlinePaymentPrinterRoleMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OnlinePaymentPrinterRoleMetaStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  printerRoleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "onlinePaymentPrinterRoleMeta" (current role has no relevant permissions) */
export enum OnlinePaymentPrinterRoleMetaUpdateColumn {
  /** placeholder (do not use) */
  _Placeholder = '_PLACEHOLDER'
}

export type OnlinePaymentRootMutation = {
  __typename?: 'onlinePaymentRootMutation';
  createAdyenPaymentStoreMerchantCategory: AdyenPaymentCreateStoreMerchantCategoryResult;
  createOrUpdateGmoBankAccount: DashboardAccountCreateOrUpdateGmoBankAccountResult;
  updateAdyenPaymentStoreMeta: AdyenPaymentUpdateStoreResult;
  upsertDiniiPayConfig: DashboardAccountUpsertDiniiPayConfigOutput;
};


export type OnlinePaymentRootMutationCreateAdyenPaymentStoreMerchantCategoryArgs = {
  input: AdyenPaymentCreateStoreMerchantCategoryInput;
};


export type OnlinePaymentRootMutationCreateOrUpdateGmoBankAccountArgs = {
  input: DashboardAccountCreateOrUpdateGmoBankAccountInput;
};


export type OnlinePaymentRootMutationUpdateAdyenPaymentStoreMetaArgs = {
  input: AdyenPaymentUpdateStoreInput;
};


export type OnlinePaymentRootMutationUpsertDiniiPayConfigArgs = {
  input: DashboardAccountUpsertDiniiPayConfigInput;
};

export type OnlinePaymentRootQuery = {
  __typename?: 'onlinePaymentRootQuery';
  gmoBankAccountDetail: DashboardAccountGmoBankAccountDetailResult;
  gmoShopConfigDetail?: Maybe<DashboardAccountGmoShopConfigDetailOutput>;
};


export type OnlinePaymentRootQueryGmoBankAccountDetailArgs = {
  input: DashboardAccountGmoBankAccountDetailInput;
};


export type OnlinePaymentRootQueryGmoShopConfigDetailArgs = {
  input: DashboardAccountGmoShopConfigDetailInput;
};

/** columns and relationships of "option" */
export type Option = {
  __typename?: 'option';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  choices: Array<Choice>;
  /** An aggregate relationship */
  choices_aggregate: ChoiceAggregate;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  giftOptionMeta?: Maybe<GiftOptionMeta>;
  /** An array relationship */
  giftOptionMetaByOptionid: Array<GiftOptionMeta>;
  id: Scalars['uuid'];
  inputType: OptionInputTypeEnum;
  maxChoiceNum?: Maybe<Scalars['Int']>;
  /** An array relationship */
  menuOptions: Array<MenuOption>;
  minChoiceNum: Scalars['Int'];
  name: Scalars['String'];
  optionId: Scalars['Int'];
  /** An object relationship */
  optionInputType: OptionInputType;
  posOptionId?: Maybe<Scalars['Int']>;
  receiptDisplayName: Scalars['String'];
  serial: Scalars['Int'];
  /** An array relationship */
  shopOptionKitchenRoles: Array<ShopOptionKitchenRole>;
  /** An array relationship */
  translations: Array<Translation>;
};


/** columns and relationships of "option" */
export type OptionChoicesArgs = {
  distinct_on?: InputMaybe<Array<ChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChoiceOrderBy>>;
  where?: InputMaybe<ChoiceBoolExp>;
};


/** columns and relationships of "option" */
export type OptionChoicesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChoiceOrderBy>>;
  where?: InputMaybe<ChoiceBoolExp>;
};


/** columns and relationships of "option" */
export type OptionGiftOptionMetaByOptionidArgs = {
  distinct_on?: InputMaybe<Array<GiftOptionMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftOptionMetaOrderBy>>;
  where?: InputMaybe<GiftOptionMetaBoolExp>;
};


/** columns and relationships of "option" */
export type OptionMenuOptionsArgs = {
  distinct_on?: InputMaybe<Array<MenuOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOptionOrderBy>>;
  where?: InputMaybe<MenuOptionBoolExp>;
};


/** columns and relationships of "option" */
export type OptionShopOptionKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopOptionKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOptionKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopOptionKitchenRoleBoolExp>;
};


/** columns and relationships of "option" */
export type OptionTranslationsArgs = {
  distinct_on?: InputMaybe<Array<TranslationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TranslationOrderBy>>;
  where?: InputMaybe<TranslationBoolExp>;
};

/** columns and relationships of "optionInputType" */
export type OptionInputType = {
  __typename?: 'optionInputType';
  /** An array relationship */
  options: Array<Option>;
  /** An aggregate relationship */
  options_aggregate: OptionAggregate;
  /** An array relationship */
  planOptions: Array<PlanOption>;
  /** An aggregate relationship */
  planOptions_aggregate: PlanOptionAggregate;
  value: Scalars['String'];
};


/** columns and relationships of "optionInputType" */
export type OptionInputTypeOptionsArgs = {
  distinct_on?: InputMaybe<Array<OptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OptionOrderBy>>;
  where?: InputMaybe<OptionBoolExp>;
};


/** columns and relationships of "optionInputType" */
export type OptionInputTypeOptionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OptionOrderBy>>;
  where?: InputMaybe<OptionBoolExp>;
};


/** columns and relationships of "optionInputType" */
export type OptionInputTypePlanOptionsArgs = {
  distinct_on?: InputMaybe<Array<PlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOptionOrderBy>>;
  where?: InputMaybe<PlanOptionBoolExp>;
};


/** columns and relationships of "optionInputType" */
export type OptionInputTypePlanOptionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOptionOrderBy>>;
  where?: InputMaybe<PlanOptionBoolExp>;
};

/** Boolean expression to filter rows from the table "optionInputType". All fields are combined with a logical 'AND'. */
export type OptionInputTypeBoolExp = {
  _and?: InputMaybe<Array<OptionInputTypeBoolExp>>;
  _not?: InputMaybe<OptionInputTypeBoolExp>;
  _or?: InputMaybe<Array<OptionInputTypeBoolExp>>;
  options?: InputMaybe<OptionBoolExp>;
  options_aggregate?: InputMaybe<OptionAggregateBoolExp>;
  planOptions?: InputMaybe<PlanOptionBoolExp>;
  planOptions_aggregate?: InputMaybe<PlanOptionAggregateBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "optionInputType" */
export enum OptionInputTypeConstraint {
  /** unique or primary key constraint on columns "value" */
  OptionInputTypePkey = 'optionInputType_pkey'
}

export enum OptionInputTypeEnum {
  Count = 'count',
  Select = 'select'
}

/** Boolean expression to compare columns of type "optionInputType_enum". All fields are combined with logical 'AND'. */
export type OptionInputTypeEnumComparisonExp = {
  _eq?: InputMaybe<OptionInputTypeEnum>;
  _in?: InputMaybe<Array<OptionInputTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<OptionInputTypeEnum>;
  _nin?: InputMaybe<Array<OptionInputTypeEnum>>;
};

/** input type for inserting data into table "optionInputType" */
export type OptionInputTypeInsertInput = {
  options?: InputMaybe<OptionArrRelInsertInput>;
  planOptions?: InputMaybe<PlanOptionArrRelInsertInput>;
  value: Scalars['String'];
};

/** response of any mutation on the table "optionInputType" */
export type OptionInputTypeMutationResponse = {
  __typename?: 'optionInputType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OptionInputType>;
};

/** input type for inserting object relation for remote table "optionInputType" */
export type OptionInputTypeObjRelInsertInput = {
  data: OptionInputTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OptionInputTypeOnConflict>;
};

/** on_conflict condition type for table "optionInputType" */
export type OptionInputTypeOnConflict = {
  constraint: OptionInputTypeConstraint;
  update_columns?: Array<OptionInputTypeUpdateColumn>;
  where?: InputMaybe<OptionInputTypeBoolExp>;
};

/** Ordering options when selecting data from "optionInputType". */
export type OptionInputTypeOrderBy = {
  options_aggregate?: InputMaybe<OptionAggregateOrderBy>;
  planOptions_aggregate?: InputMaybe<PlanOptionAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: optionInputType */
export type OptionInputTypePkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "optionInputType" */
export enum OptionInputTypeSelectColumn {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "optionInputType" */
export type OptionInputTypeSetInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "optionInputType" */
export type OptionInputTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OptionInputTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OptionInputTypeStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "optionInputType" */
export enum OptionInputTypeUpdateColumn {
  /** column name */
  Value = 'value'
}

export type OptionInputTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OptionInputTypeSetInput>;
  /** filter the rows which have to be updated */
  where: OptionInputTypeBoolExp;
};

/** aggregated selection of "option" */
export type OptionAggregate = {
  __typename?: 'option_aggregate';
  aggregate?: Maybe<OptionAggregateFields>;
  nodes: Array<Option>;
};

export type OptionAggregateBoolExp = {
  count?: InputMaybe<OptionAggregateBoolExpCount>;
};

export type OptionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OptionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "option" */
export type OptionAggregateFields = {
  __typename?: 'option_aggregate_fields';
  avg?: Maybe<OptionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OptionMaxFields>;
  min?: Maybe<OptionMinFields>;
  stddev?: Maybe<OptionStddevFields>;
  stddev_pop?: Maybe<OptionStddevPopFields>;
  stddev_samp?: Maybe<OptionStddevSampFields>;
  sum?: Maybe<OptionSumFields>;
  var_pop?: Maybe<OptionVarPopFields>;
  var_samp?: Maybe<OptionVarSampFields>;
  variance?: Maybe<OptionVarianceFields>;
};


/** aggregate fields of "option" */
export type OptionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "option" */
export type OptionAggregateOrderBy = {
  avg?: InputMaybe<OptionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OptionMaxOrderBy>;
  min?: InputMaybe<OptionMinOrderBy>;
  stddev?: InputMaybe<OptionStddevOrderBy>;
  stddev_pop?: InputMaybe<OptionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OptionStddevSampOrderBy>;
  sum?: InputMaybe<OptionSumOrderBy>;
  var_pop?: InputMaybe<OptionVarPopOrderBy>;
  var_samp?: InputMaybe<OptionVarSampOrderBy>;
  variance?: InputMaybe<OptionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "option" */
export type OptionArrRelInsertInput = {
  data: Array<OptionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OptionOnConflict>;
};

/** aggregate avg on columns */
export type OptionAvgFields = {
  __typename?: 'option_avg_fields';
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  optionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "option" */
export type OptionAvgOrderBy = {
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "option". All fields are combined with a logical 'AND'. */
export type OptionBoolExp = {
  _and?: InputMaybe<Array<OptionBoolExp>>;
  _not?: InputMaybe<OptionBoolExp>;
  _or?: InputMaybe<Array<OptionBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  choices?: InputMaybe<ChoiceBoolExp>;
  choices_aggregate?: InputMaybe<ChoiceAggregateBoolExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  giftOptionMeta?: InputMaybe<GiftOptionMetaBoolExp>;
  giftOptionMetaByOptionid?: InputMaybe<GiftOptionMetaBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inputType?: InputMaybe<OptionInputTypeEnumComparisonExp>;
  maxChoiceNum?: InputMaybe<IntComparisonExp>;
  menuOptions?: InputMaybe<MenuOptionBoolExp>;
  minChoiceNum?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  optionId?: InputMaybe<IntComparisonExp>;
  optionInputType?: InputMaybe<OptionInputTypeBoolExp>;
  posOptionId?: InputMaybe<IntComparisonExp>;
  receiptDisplayName?: InputMaybe<StringComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shopOptionKitchenRoles?: InputMaybe<ShopOptionKitchenRoleBoolExp>;
  translations?: InputMaybe<TranslationBoolExp>;
};

/** unique or primary key constraints on table "option" */
export enum OptionConstraint {
  /** unique or primary key constraint on columns "serial" */
  Idx_46568Primary = 'idx_46568_PRIMARY',
  /** unique or primary key constraint on columns "id" */
  OptionIdKey = 'option_id_key'
}

/** input type for incrementing numeric columns in table "option" */
export type OptionIncInput = {
  maxChoiceNum?: InputMaybe<Scalars['Int']>;
  minChoiceNum?: InputMaybe<Scalars['Int']>;
  posOptionId?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "option" */
export type OptionInsertInput = {
  choices?: InputMaybe<ChoiceArrRelInsertInput>;
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  description?: InputMaybe<Scalars['String']>;
  giftOptionMeta?: InputMaybe<GiftOptionMetaObjRelInsertInput>;
  giftOptionMetaByOptionid?: InputMaybe<GiftOptionMetaArrRelInsertInput>;
  inputType?: InputMaybe<OptionInputTypeEnum>;
  maxChoiceNum?: InputMaybe<Scalars['Int']>;
  menuOptions?: InputMaybe<MenuOptionArrRelInsertInput>;
  minChoiceNum?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  optionInputType?: InputMaybe<OptionInputTypeObjRelInsertInput>;
  posOptionId?: InputMaybe<Scalars['Int']>;
  receiptDisplayName: Scalars['String'];
  serial?: InputMaybe<Scalars['Int']>;
  shopOptionKitchenRoles?: InputMaybe<ShopOptionKitchenRoleArrRelInsertInput>;
};

/** aggregate max on columns */
export type OptionMaxFields = {
  __typename?: 'option_max_fields';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  companyId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  maxChoiceNum?: Maybe<Scalars['Int']>;
  minChoiceNum?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  optionId?: Maybe<Scalars['Int']>;
  posOptionId?: Maybe<Scalars['Int']>;
  receiptDisplayName?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "option" */
export type OptionMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OptionMinFields = {
  __typename?: 'option_min_fields';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  companyId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  maxChoiceNum?: Maybe<Scalars['Int']>;
  minChoiceNum?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  optionId?: Maybe<Scalars['Int']>;
  posOptionId?: Maybe<Scalars['Int']>;
  receiptDisplayName?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "option" */
export type OptionMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "option" */
export type OptionMutationResponse = {
  __typename?: 'option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Option>;
};

/** input type for inserting object relation for remote table "option" */
export type OptionObjRelInsertInput = {
  data: OptionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OptionOnConflict>;
};

/** on_conflict condition type for table "option" */
export type OptionOnConflict = {
  constraint: OptionConstraint;
  update_columns?: Array<OptionUpdateColumn>;
  where?: InputMaybe<OptionBoolExp>;
};

/** Ordering options when selecting data from "option". */
export type OptionOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  choices_aggregate?: InputMaybe<ChoiceAggregateOrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  giftOptionMeta?: InputMaybe<GiftOptionMetaOrderBy>;
  giftOptionMetaByOptionid_aggregate?: InputMaybe<GiftOptionMetaAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  inputType?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  menuOptions_aggregate?: InputMaybe<MenuOptionAggregateOrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  optionInputType?: InputMaybe<OptionInputTypeOrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopOptionKitchenRoles_aggregate?: InputMaybe<ShopOptionKitchenRoleAggregateOrderBy>;
  translations_aggregate?: InputMaybe<TranslationAggregateOrderBy>;
};

/** primary key columns input for table: option */
export type OptionPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "option" */
export enum OptionSelectColumn {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InputType = 'inputType',
  /** column name */
  MaxChoiceNum = 'maxChoiceNum',
  /** column name */
  MinChoiceNum = 'minChoiceNum',
  /** column name */
  Name = 'name',
  /** column name */
  OptionId = 'optionId',
  /** column name */
  PosOptionId = 'posOptionId',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial'
}

/** input type for updating data in table "option" */
export type OptionSetInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  inputType?: InputMaybe<OptionInputTypeEnum>;
  maxChoiceNum?: InputMaybe<Scalars['Int']>;
  minChoiceNum?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  posOptionId?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type OptionStddevFields = {
  __typename?: 'option_stddev_fields';
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  optionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "option" */
export type OptionStddevOrderBy = {
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OptionStddevPopFields = {
  __typename?: 'option_stddev_pop_fields';
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  optionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "option" */
export type OptionStddevPopOrderBy = {
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OptionStddevSampFields = {
  __typename?: 'option_stddev_samp_fields';
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  optionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "option" */
export type OptionStddevSampOrderBy = {
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "option" */
export type OptionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OptionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OptionStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inputType?: InputMaybe<OptionInputTypeEnum>;
  maxChoiceNum?: InputMaybe<Scalars['Int']>;
  minChoiceNum?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  optionId?: InputMaybe<Scalars['Int']>;
  posOptionId?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type OptionSumFields = {
  __typename?: 'option_sum_fields';
  maxChoiceNum?: Maybe<Scalars['Int']>;
  minChoiceNum?: Maybe<Scalars['Int']>;
  optionId?: Maybe<Scalars['Int']>;
  posOptionId?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "option" */
export type OptionSumOrderBy = {
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "option" */
export enum OptionUpdateColumn {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Description = 'description',
  /** column name */
  InputType = 'inputType',
  /** column name */
  MaxChoiceNum = 'maxChoiceNum',
  /** column name */
  MinChoiceNum = 'minChoiceNum',
  /** column name */
  Name = 'name',
  /** column name */
  PosOptionId = 'posOptionId',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial'
}

export type OptionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OptionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OptionSetInput>;
  /** filter the rows which have to be updated */
  where: OptionBoolExp;
};

/** aggregate var_pop on columns */
export type OptionVarPopFields = {
  __typename?: 'option_var_pop_fields';
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  optionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "option" */
export type OptionVarPopOrderBy = {
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OptionVarSampFields = {
  __typename?: 'option_var_samp_fields';
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  optionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "option" */
export type OptionVarSampOrderBy = {
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OptionVarianceFields = {
  __typename?: 'option_variance_fields';
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  optionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "option" */
export type OptionVarianceOrderBy = {
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** columns and relationships of "order" */
export type Order = {
  __typename?: 'order';
  billableTableUserId: Scalars['uuid'];
  id: Scalars['uuid'];
  isCanceled: Scalars['Boolean'];
  /** An array relationship */
  menuOrderItems: Array<MenuOrderItem>;
  orderedAt: Scalars['timestamptz'];
  /** An object relationship */
  orderedClerk?: Maybe<Clerk>;
  /** An object relationship */
  orderedCustomer?: Maybe<Customer>;
  orderedTableUserId: Scalars['uuid'];
  orderedUserId?: Maybe<Scalars['String']>;
  orderedUserName?: Maybe<Scalars['String']>;
  orderedUserProfileImage?: Maybe<Scalars['String']>;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An object relationship */
  tableUser: TableUser;
  /** An object relationship */
  tableUserByOrderedtableuserid: TableUser;
  /** An array relationship */
  tableUsersInGroup: Array<TableUser>;
};


/** columns and relationships of "order" */
export type OrderMenuOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<MenuOrderItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderItemOrderBy>>;
  where?: InputMaybe<MenuOrderItemBoolExp>;
};


/** columns and relationships of "order" */
export type OrderTableUsersInGroupArgs = {
  distinct_on?: InputMaybe<Array<TableUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserOrderBy>>;
  where?: InputMaybe<TableUserBoolExp>;
};

/** order by aggregate values of table "order" */
export type OrderAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderMaxOrderBy>;
  min?: InputMaybe<OrderMinOrderBy>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type OrderBoolExp = {
  _and?: InputMaybe<Array<OrderBoolExp>>;
  _not?: InputMaybe<OrderBoolExp>;
  _or?: InputMaybe<Array<OrderBoolExp>>;
  billableTableUserId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isCanceled?: InputMaybe<BooleanComparisonExp>;
  menuOrderItems?: InputMaybe<MenuOrderItemBoolExp>;
  orderedAt?: InputMaybe<TimestamptzComparisonExp>;
  orderedClerk?: InputMaybe<ClerkBoolExp>;
  orderedCustomer?: InputMaybe<CustomerBoolExp>;
  orderedTableUserId?: InputMaybe<UuidComparisonExp>;
  orderedUserId?: InputMaybe<StringComparisonExp>;
  orderedUserName?: InputMaybe<StringComparisonExp>;
  orderedUserProfileImage?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  tableUser?: InputMaybe<TableUserBoolExp>;
  tableUserByOrderedtableuserid?: InputMaybe<TableUserBoolExp>;
  tableUsersInGroup?: InputMaybe<TableUserBoolExp>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** order by max() on columns of table "order" */
export type OrderMaxOrderBy = {
  billableTableUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderedAt?: InputMaybe<OrderBy>;
  orderedTableUserId?: InputMaybe<OrderBy>;
  orderedUserId?: InputMaybe<OrderBy>;
  orderedUserName?: InputMaybe<OrderBy>;
  orderedUserProfileImage?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "order" */
export type OrderMinOrderBy = {
  billableTableUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderedAt?: InputMaybe<OrderBy>;
  orderedTableUserId?: InputMaybe<OrderBy>;
  orderedUserId?: InputMaybe<OrderBy>;
  orderedUserName?: InputMaybe<OrderBy>;
  orderedUserProfileImage?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "order". */
export type OrderOrderBy = {
  billableTableUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isCanceled?: InputMaybe<OrderBy>;
  menuOrderItems_aggregate?: InputMaybe<MenuOrderItemAggregateOrderBy>;
  orderedAt?: InputMaybe<OrderBy>;
  orderedClerk?: InputMaybe<ClerkOrderBy>;
  orderedCustomer?: InputMaybe<CustomerOrderBy>;
  orderedTableUserId?: InputMaybe<OrderBy>;
  orderedUserId?: InputMaybe<OrderBy>;
  orderedUserName?: InputMaybe<OrderBy>;
  orderedUserProfileImage?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableUser?: InputMaybe<TableUserOrderBy>;
  tableUserByOrderedtableuserid?: InputMaybe<TableUserOrderBy>;
  tableUsersInGroup_aggregate?: InputMaybe<TableUserAggregateOrderBy>;
};

/** select columns of table "order" */
export enum OrderSelectColumn {
  /** column name */
  BillableTableUserId = 'billableTableUserId',
  /** column name */
  Id = 'id',
  /** column name */
  IsCanceled = 'isCanceled',
  /** column name */
  OrderedAt = 'orderedAt',
  /** column name */
  OrderedTableUserId = 'orderedTableUserId',
  /** column name */
  OrderedUserId = 'orderedUserId',
  /** column name */
  OrderedUserName = 'orderedUserName',
  /** column name */
  OrderedUserProfileImage = 'orderedUserProfileImage',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "order" */
export type OrderStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OrderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OrderStreamCursorValueInput = {
  billableTableUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isCanceled?: InputMaybe<Scalars['Boolean']>;
  orderedAt?: InputMaybe<Scalars['timestamptz']>;
  orderedTableUserId?: InputMaybe<Scalars['uuid']>;
  orderedUserId?: InputMaybe<Scalars['String']>;
  orderedUserName?: InputMaybe<Scalars['String']>;
  orderedUserProfileImage?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "orderableTime" */
export type OrderableTime = {
  __typename?: 'orderableTime';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An array relationship */
  menus: Array<Menu>;
  name: Scalars['String'];
  /** An array relationship */
  plans: Array<Plan>;
  /** An array relationship */
  shopOrderableTimeTerms: Array<ShopOrderableTimeTerm>;
};


/** columns and relationships of "orderableTime" */
export type OrderableTimeMenusArgs = {
  distinct_on?: InputMaybe<Array<MenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderBy>>;
  where?: InputMaybe<MenuBoolExp>;
};


/** columns and relationships of "orderableTime" */
export type OrderableTimePlansArgs = {
  distinct_on?: InputMaybe<Array<PlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOrderBy>>;
  where?: InputMaybe<PlanBoolExp>;
};


/** columns and relationships of "orderableTime" */
export type OrderableTimeShopOrderableTimeTermsArgs = {
  distinct_on?: InputMaybe<Array<ShopOrderableTimeTermSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOrderableTimeTermOrderBy>>;
  where?: InputMaybe<ShopOrderableTimeTermBoolExp>;
};

export enum OrderableTimeTermDayWeekEnum {
  Fri = 'Fri',
  Holiday = 'Holiday',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed'
}

/** Boolean expression to compare columns of type "orderableTimeTermDayWeek_enum". All fields are combined with logical 'AND'. */
export type OrderableTimeTermDayWeekEnumComparisonExp = {
  _eq?: InputMaybe<OrderableTimeTermDayWeekEnum>;
  _in?: InputMaybe<Array<OrderableTimeTermDayWeekEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<OrderableTimeTermDayWeekEnum>;
  _nin?: InputMaybe<Array<OrderableTimeTermDayWeekEnum>>;
};

/** order by aggregate values of table "orderableTime" */
export type OrderableTimeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderableTimeMaxOrderBy>;
  min?: InputMaybe<OrderableTimeMinOrderBy>;
};

/** input type for inserting array relation for remote table "orderableTime" */
export type OrderableTimeArrRelInsertInput = {
  data: Array<OrderableTimeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OrderableTimeOnConflict>;
};

/** Boolean expression to filter rows from the table "orderableTime". All fields are combined with a logical 'AND'. */
export type OrderableTimeBoolExp = {
  _and?: InputMaybe<Array<OrderableTimeBoolExp>>;
  _not?: InputMaybe<OrderableTimeBoolExp>;
  _or?: InputMaybe<Array<OrderableTimeBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  menus?: InputMaybe<MenuBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  plans?: InputMaybe<PlanBoolExp>;
  shopOrderableTimeTerms?: InputMaybe<ShopOrderableTimeTermBoolExp>;
};

/** unique or primary key constraints on table "orderableTime" */
export enum OrderableTimeConstraint {
  /** unique or primary key constraint on columns "companyId", "name" */
  OrderableTimeCompanyIdNameKey = 'orderableTime_companyId_name_key',
  /** unique or primary key constraint on columns "id" */
  OrderableTimePkey = 'orderableTime_pkey'
}

/** input type for inserting data into table "orderableTime" */
export type OrderableTimeInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  menus?: InputMaybe<MenuArrRelInsertInput>;
  name: Scalars['String'];
  plans?: InputMaybe<PlanArrRelInsertInput>;
  shopOrderableTimeTerms?: InputMaybe<ShopOrderableTimeTermArrRelInsertInput>;
};

/** order by max() on columns of table "orderableTime" */
export type OrderableTimeMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "orderableTime" */
export type OrderableTimeMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "orderableTime" */
export type OrderableTimeMutationResponse = {
  __typename?: 'orderableTime_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderableTime>;
};

/** input type for inserting object relation for remote table "orderableTime" */
export type OrderableTimeObjRelInsertInput = {
  data: OrderableTimeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OrderableTimeOnConflict>;
};

/** on_conflict condition type for table "orderableTime" */
export type OrderableTimeOnConflict = {
  constraint: OrderableTimeConstraint;
  update_columns?: Array<OrderableTimeUpdateColumn>;
  where?: InputMaybe<OrderableTimeBoolExp>;
};

/** Ordering options when selecting data from "orderableTime". */
export type OrderableTimeOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menus_aggregate?: InputMaybe<MenuAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  plans_aggregate?: InputMaybe<PlanAggregateOrderBy>;
  shopOrderableTimeTerms_aggregate?: InputMaybe<ShopOrderableTimeTermAggregateOrderBy>;
};

/** primary key columns input for table: orderableTime */
export type OrderableTimePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "orderableTime" */
export enum OrderableTimeSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "orderableTime" */
export type OrderableTimeSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "orderableTime" */
export type OrderableTimeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OrderableTimeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type OrderableTimeStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "orderableTime" */
export enum OrderableTimeUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type OrderableTimeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrderableTimeSetInput>;
  /** filter the rows which have to be updated */
  where: OrderableTimeBoolExp;
};

/** columns and relationships of "payingActivePlanChoice" */
export type PayingActivePlanChoice = {
  __typename?: 'payingActivePlanChoice';
  _onSitePaymentId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  activePlanChoice: ActivePlanChoice;
  activePlanChoiceId: Scalars['uuid'];
  editedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  itemSales?: Maybe<ItemSales>;
  /** An object relationship */
  onSitePayment?: Maybe<OnSitePayment>;
  onSitePaymentId?: Maybe<Scalars['uuid']>;
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

/** order by aggregate values of table "payingActivePlanChoice" */
export type PayingActivePlanChoiceAggregateOrderBy = {
  avg?: InputMaybe<PayingActivePlanChoiceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PayingActivePlanChoiceMaxOrderBy>;
  min?: InputMaybe<PayingActivePlanChoiceMinOrderBy>;
  stddev?: InputMaybe<PayingActivePlanChoiceStddevOrderBy>;
  stddev_pop?: InputMaybe<PayingActivePlanChoiceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PayingActivePlanChoiceStddevSampOrderBy>;
  sum?: InputMaybe<PayingActivePlanChoiceSumOrderBy>;
  var_pop?: InputMaybe<PayingActivePlanChoiceVarPopOrderBy>;
  var_samp?: InputMaybe<PayingActivePlanChoiceVarSampOrderBy>;
  variance?: InputMaybe<PayingActivePlanChoiceVarianceOrderBy>;
};

/** order by avg() on columns of table "payingActivePlanChoice" */
export type PayingActivePlanChoiceAvgOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "payingActivePlanChoice". All fields are combined with a logical 'AND'. */
export type PayingActivePlanChoiceBoolExp = {
  _and?: InputMaybe<Array<PayingActivePlanChoiceBoolExp>>;
  _not?: InputMaybe<PayingActivePlanChoiceBoolExp>;
  _onSitePaymentId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<PayingActivePlanChoiceBoolExp>>;
  activePlanChoice?: InputMaybe<ActivePlanChoiceBoolExp>;
  activePlanChoiceId?: InputMaybe<UuidComparisonExp>;
  editedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  itemSales?: InputMaybe<ItemSalesBoolExp>;
  onSitePayment?: InputMaybe<OnSitePaymentBoolExp>;
  onSitePaymentId?: InputMaybe<UuidComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  quantity?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "payingActivePlanChoice" */
export type PayingActivePlanChoiceMaxOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  activePlanChoiceId?: InputMaybe<OrderBy>;
  editedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "payingActivePlanChoice" */
export type PayingActivePlanChoiceMinOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  activePlanChoiceId?: InputMaybe<OrderBy>;
  editedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "payingActivePlanChoice". */
export type PayingActivePlanChoiceOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  activePlanChoice?: InputMaybe<ActivePlanChoiceOrderBy>;
  activePlanChoiceId?: InputMaybe<OrderBy>;
  editedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemSales?: InputMaybe<ItemSalesOrderBy>;
  onSitePayment?: InputMaybe<OnSitePaymentOrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** select columns of table "payingActivePlanChoice" */
export enum PayingActivePlanChoiceSelectColumn {
  /** column name */
  _OnSitePaymentId = '_onSitePaymentId',
  /** column name */
  ActivePlanChoiceId = 'activePlanChoiceId',
  /** column name */
  EditedAt = 'editedAt',
  /** column name */
  Id = 'id',
  /** column name */
  OnSitePaymentId = 'onSitePaymentId',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity'
}

/** order by stddev() on columns of table "payingActivePlanChoice" */
export type PayingActivePlanChoiceStddevOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "payingActivePlanChoice" */
export type PayingActivePlanChoiceStddevPopOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "payingActivePlanChoice" */
export type PayingActivePlanChoiceStddevSampOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "payingActivePlanChoice" */
export type PayingActivePlanChoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PayingActivePlanChoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PayingActivePlanChoiceStreamCursorValueInput = {
  _onSitePaymentId?: InputMaybe<Scalars['Int']>;
  activePlanChoiceId?: InputMaybe<Scalars['uuid']>;
  editedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  onSitePaymentId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payingActivePlanChoice" */
export type PayingActivePlanChoiceSumOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "payingActivePlanChoice" */
export type PayingActivePlanChoiceVarPopOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "payingActivePlanChoice" */
export type PayingActivePlanChoiceVarSampOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "payingActivePlanChoice" */
export type PayingActivePlanChoiceVarianceOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** columns and relationships of "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMeta = {
  __typename?: 'payingActivePlanOpenPriceMeta';
  _onSitePaymentId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  activePlanOpenPriceMeta: ActivePlanOpenPriceMeta;
  activePlanOpenPriceMetaId: Scalars['uuid'];
  editedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  itemSales?: Maybe<ItemSales>;
  /** An object relationship */
  onSitePayment?: Maybe<OnSitePayment>;
  onSitePaymentId?: Maybe<Scalars['uuid']>;
  payingActivePlanOpenPriceMetaId: Scalars['uuid'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

/** order by aggregate values of table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaAggregateOrderBy = {
  avg?: InputMaybe<PayingActivePlanOpenPriceMetaAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PayingActivePlanOpenPriceMetaMaxOrderBy>;
  min?: InputMaybe<PayingActivePlanOpenPriceMetaMinOrderBy>;
  stddev?: InputMaybe<PayingActivePlanOpenPriceMetaStddevOrderBy>;
  stddev_pop?: InputMaybe<PayingActivePlanOpenPriceMetaStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PayingActivePlanOpenPriceMetaStddevSampOrderBy>;
  sum?: InputMaybe<PayingActivePlanOpenPriceMetaSumOrderBy>;
  var_pop?: InputMaybe<PayingActivePlanOpenPriceMetaVarPopOrderBy>;
  var_samp?: InputMaybe<PayingActivePlanOpenPriceMetaVarSampOrderBy>;
  variance?: InputMaybe<PayingActivePlanOpenPriceMetaVarianceOrderBy>;
};

/** order by avg() on columns of table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaAvgOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "payingActivePlanOpenPriceMeta". All fields are combined with a logical 'AND'.
 */
export type PayingActivePlanOpenPriceMetaBoolExp = {
  _and?: InputMaybe<Array<PayingActivePlanOpenPriceMetaBoolExp>>;
  _not?: InputMaybe<PayingActivePlanOpenPriceMetaBoolExp>;
  _onSitePaymentId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<PayingActivePlanOpenPriceMetaBoolExp>>;
  activePlanOpenPriceMeta?: InputMaybe<ActivePlanOpenPriceMetaBoolExp>;
  activePlanOpenPriceMetaId?: InputMaybe<UuidComparisonExp>;
  editedAt?: InputMaybe<TimestamptzComparisonExp>;
  itemSales?: InputMaybe<ItemSalesBoolExp>;
  onSitePayment?: InputMaybe<OnSitePaymentBoolExp>;
  onSitePaymentId?: InputMaybe<UuidComparisonExp>;
  payingActivePlanOpenPriceMetaId?: InputMaybe<UuidComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  quantity?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaMaxOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  activePlanOpenPriceMetaId?: InputMaybe<OrderBy>;
  editedAt?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  payingActivePlanOpenPriceMetaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaMinOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  activePlanOpenPriceMetaId?: InputMaybe<OrderBy>;
  editedAt?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  payingActivePlanOpenPriceMetaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "payingActivePlanOpenPriceMeta". */
export type PayingActivePlanOpenPriceMetaOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  activePlanOpenPriceMeta?: InputMaybe<ActivePlanOpenPriceMetaOrderBy>;
  activePlanOpenPriceMetaId?: InputMaybe<OrderBy>;
  editedAt?: InputMaybe<OrderBy>;
  itemSales?: InputMaybe<ItemSalesOrderBy>;
  onSitePayment?: InputMaybe<OnSitePaymentOrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  payingActivePlanOpenPriceMetaId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** select columns of table "payingActivePlanOpenPriceMeta" */
export enum PayingActivePlanOpenPriceMetaSelectColumn {
  /** column name */
  _OnSitePaymentId = '_onSitePaymentId',
  /** column name */
  ActivePlanOpenPriceMetaId = 'activePlanOpenPriceMetaId',
  /** column name */
  EditedAt = 'editedAt',
  /** column name */
  OnSitePaymentId = 'onSitePaymentId',
  /** column name */
  PayingActivePlanOpenPriceMetaId = 'payingActivePlanOpenPriceMetaId',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity'
}

/** order by stddev() on columns of table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaStddevOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaStddevPopOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaStddevSampOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PayingActivePlanOpenPriceMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PayingActivePlanOpenPriceMetaStreamCursorValueInput = {
  _onSitePaymentId?: InputMaybe<Scalars['Int']>;
  activePlanOpenPriceMetaId?: InputMaybe<Scalars['uuid']>;
  editedAt?: InputMaybe<Scalars['timestamptz']>;
  onSitePaymentId?: InputMaybe<Scalars['uuid']>;
  payingActivePlanOpenPriceMetaId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaSumOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaVarPopOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaVarSampOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "payingActivePlanOpenPriceMeta" */
export type PayingActivePlanOpenPriceMetaVarianceOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** columns and relationships of "payingMenuOrderItem" */
export type PayingMenuOrderItem = {
  __typename?: 'payingMenuOrderItem';
  _onSitePaymentId?: Maybe<Scalars['Int']>;
  editedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  itemSales?: Maybe<ItemSales>;
  /** An object relationship */
  menuOrderItem: MenuOrderItem;
  menuOrderItemId: Scalars['uuid'];
  /** An object relationship */
  onSitePayment?: Maybe<OnSitePayment>;
  onSitePaymentId?: Maybe<Scalars['uuid']>;
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

/** order by aggregate values of table "payingMenuOrderItem" */
export type PayingMenuOrderItemAggregateOrderBy = {
  avg?: InputMaybe<PayingMenuOrderItemAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PayingMenuOrderItemMaxOrderBy>;
  min?: InputMaybe<PayingMenuOrderItemMinOrderBy>;
  stddev?: InputMaybe<PayingMenuOrderItemStddevOrderBy>;
  stddev_pop?: InputMaybe<PayingMenuOrderItemStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PayingMenuOrderItemStddevSampOrderBy>;
  sum?: InputMaybe<PayingMenuOrderItemSumOrderBy>;
  var_pop?: InputMaybe<PayingMenuOrderItemVarPopOrderBy>;
  var_samp?: InputMaybe<PayingMenuOrderItemVarSampOrderBy>;
  variance?: InputMaybe<PayingMenuOrderItemVarianceOrderBy>;
};

/** order by avg() on columns of table "payingMenuOrderItem" */
export type PayingMenuOrderItemAvgOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "payingMenuOrderItem". All fields are combined with a logical 'AND'. */
export type PayingMenuOrderItemBoolExp = {
  _and?: InputMaybe<Array<PayingMenuOrderItemBoolExp>>;
  _not?: InputMaybe<PayingMenuOrderItemBoolExp>;
  _onSitePaymentId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<PayingMenuOrderItemBoolExp>>;
  editedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  itemSales?: InputMaybe<ItemSalesBoolExp>;
  menuOrderItem?: InputMaybe<MenuOrderItemBoolExp>;
  menuOrderItemId?: InputMaybe<UuidComparisonExp>;
  onSitePayment?: InputMaybe<OnSitePaymentBoolExp>;
  onSitePaymentId?: InputMaybe<UuidComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  quantity?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "payingMenuOrderItem" */
export type PayingMenuOrderItemMaxOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  editedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuOrderItemId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "payingMenuOrderItem" */
export type PayingMenuOrderItemMinOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  editedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuOrderItemId?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "payingMenuOrderItem". */
export type PayingMenuOrderItemOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  editedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  itemSales?: InputMaybe<ItemSalesOrderBy>;
  menuOrderItem?: InputMaybe<MenuOrderItemOrderBy>;
  menuOrderItemId?: InputMaybe<OrderBy>;
  onSitePayment?: InputMaybe<OnSitePaymentOrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** select columns of table "payingMenuOrderItem" */
export enum PayingMenuOrderItemSelectColumn {
  /** column name */
  _OnSitePaymentId = '_onSitePaymentId',
  /** column name */
  EditedAt = 'editedAt',
  /** column name */
  Id = 'id',
  /** column name */
  MenuOrderItemId = 'menuOrderItemId',
  /** column name */
  OnSitePaymentId = 'onSitePaymentId',
  /** column name */
  Price = 'price',
  /** column name */
  Quantity = 'quantity'
}

/** order by stddev() on columns of table "payingMenuOrderItem" */
export type PayingMenuOrderItemStddevOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "payingMenuOrderItem" */
export type PayingMenuOrderItemStddevPopOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "payingMenuOrderItem" */
export type PayingMenuOrderItemStddevSampOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "payingMenuOrderItem" */
export type PayingMenuOrderItemStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PayingMenuOrderItemStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PayingMenuOrderItemStreamCursorValueInput = {
  _onSitePaymentId?: InputMaybe<Scalars['Int']>;
  editedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuOrderItemId?: InputMaybe<Scalars['uuid']>;
  onSitePaymentId?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payingMenuOrderItem" */
export type PayingMenuOrderItemSumOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "payingMenuOrderItem" */
export type PayingMenuOrderItemVarPopOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "payingMenuOrderItem" */
export type PayingMenuOrderItemVarSampOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "payingMenuOrderItem" */
export type PayingMenuOrderItemVarianceOrderBy = {
  _onSitePaymentId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  quantity?: InputMaybe<OrderBy>;
};

/** 自動会計伝票テーブル出し分け設定テーブル */
export type PaymentReceiptRoleTable = {
  __typename?: 'paymentReceiptRoleTable';
  _roleId: Scalars['Int'];
  _tableId: Scalars['Int'];
  /** An object relationship */
  role?: Maybe<Role>;
  roleId: Scalars['uuid'];
  shopId: Scalars['uuid'];
  /** An object relationship */
  table?: Maybe<Table>;
  tableId: Scalars['uuid'];
};

/** order by aggregate values of table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableAggregateOrderBy = {
  avg?: InputMaybe<PaymentReceiptRoleTableAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PaymentReceiptRoleTableMaxOrderBy>;
  min?: InputMaybe<PaymentReceiptRoleTableMinOrderBy>;
  stddev?: InputMaybe<PaymentReceiptRoleTableStddevOrderBy>;
  stddev_pop?: InputMaybe<PaymentReceiptRoleTableStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PaymentReceiptRoleTableStddevSampOrderBy>;
  sum?: InputMaybe<PaymentReceiptRoleTableSumOrderBy>;
  var_pop?: InputMaybe<PaymentReceiptRoleTableVarPopOrderBy>;
  var_samp?: InputMaybe<PaymentReceiptRoleTableVarSampOrderBy>;
  variance?: InputMaybe<PaymentReceiptRoleTableVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableArrRelInsertInput = {
  data: Array<PaymentReceiptRoleTableInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PaymentReceiptRoleTableOnConflict>;
};

/** order by avg() on columns of table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableAvgOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "paymentReceiptRoleTable". All fields are combined with a logical 'AND'. */
export type PaymentReceiptRoleTableBoolExp = {
  _and?: InputMaybe<Array<PaymentReceiptRoleTableBoolExp>>;
  _not?: InputMaybe<PaymentReceiptRoleTableBoolExp>;
  _or?: InputMaybe<Array<PaymentReceiptRoleTableBoolExp>>;
  _roleId?: InputMaybe<IntComparisonExp>;
  _tableId?: InputMaybe<IntComparisonExp>;
  role?: InputMaybe<RoleBoolExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  table?: InputMaybe<TableBoolExp>;
  tableId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "paymentReceiptRoleTable" */
export enum PaymentReceiptRoleTableConstraint {
  /** unique or primary key constraint on columns "id" */
  PaymentReceiptRoleTablePkey = 'paymentReceiptRoleTable_pkey',
  /** unique or primary key constraint on columns "_roleId", "_tableId" */
  PaymentReceiptRoleTableRoleIdTableIdKey = 'paymentReceiptRoleTable_roleId_tableId_key',
  /** unique or primary key constraint on columns "roleId", "tableId" */
  PaymentReceiptRoleTableRoleUuidTableUuidKey = 'paymentReceiptRoleTable_roleUuid_tableUuid_key'
}

/** input type for incrementing numeric columns in table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableIncInput = {
  _roleId?: InputMaybe<Scalars['Int']>;
  _tableId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableInsertInput = {
  _roleId: Scalars['Int'];
  _tableId: Scalars['Int'];
  role?: InputMaybe<RoleObjRelInsertInput>;
  roleId: Scalars['uuid'];
  shopId: Scalars['uuid'];
  table?: InputMaybe<TableObjRelInsertInput>;
  tableId: Scalars['uuid'];
};

/** order by max() on columns of table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableMaxOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableMinOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableMutationResponse = {
  __typename?: 'paymentReceiptRoleTable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PaymentReceiptRoleTable>;
};

/** on_conflict condition type for table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableOnConflict = {
  constraint: PaymentReceiptRoleTableConstraint;
  update_columns?: Array<PaymentReceiptRoleTableUpdateColumn>;
  where?: InputMaybe<PaymentReceiptRoleTableBoolExp>;
};

/** Ordering options when selecting data from "paymentReceiptRoleTable". */
export type PaymentReceiptRoleTableOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
  role?: InputMaybe<RoleOrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  table?: InputMaybe<TableOrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** select columns of table "paymentReceiptRoleTable" */
export enum PaymentReceiptRoleTableSelectColumn {
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  _TableId = '_tableId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TableId = 'tableId'
}

/** input type for updating data in table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableSetInput = {
  _roleId?: InputMaybe<Scalars['Int']>;
  _tableId?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tableId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableStddevOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableStddevPopOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableStddevSampOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PaymentReceiptRoleTableStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentReceiptRoleTableStreamCursorValueInput = {
  _roleId?: InputMaybe<Scalars['Int']>;
  _tableId?: InputMaybe<Scalars['Int']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tableId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableSumOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** update columns of table "paymentReceiptRoleTable" */
export enum PaymentReceiptRoleTableUpdateColumn {
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  _TableId = '_tableId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TableId = 'tableId'
}

export type PaymentReceiptRoleTableUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PaymentReceiptRoleTableIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentReceiptRoleTableSetInput>;
  /** filter the rows which have to be updated */
  where: PaymentReceiptRoleTableBoolExp;
};

/** order by var_pop() on columns of table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableVarPopOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableVarSampOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "paymentReceiptRoleTable" */
export type PaymentReceiptRoleTableVarianceOrderBy = {
  _roleId?: InputMaybe<OrderBy>;
  _tableId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "pikaichiConfig" */
export type PikaichiConfig = {
  __typename?: 'pikaichiConfig';
  enabled: Scalars['Boolean'];
  freeCsvType?: Maybe<Scalars['String']>;
  ftpHost?: Maybe<Scalars['String']>;
  ftpPassword?: Maybe<Scalars['String']>;
  ftpPort?: Maybe<Scalars['Int']>;
  ftpUser?: Maybe<Scalars['String']>;
  pikaichiCompanyCd: Scalars['String'];
  pikaichiConfigId: Scalars['uuid'];
  pikaichiPosNumber: Scalars['String'];
  pikaichiShopCd: Scalars['String'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  useKansa: Scalars['Boolean'];
  useKinken: Scalars['Boolean'];
  useNewari: Scalars['Boolean'];
};

/** Boolean expression to filter rows from the table "pikaichiConfig". All fields are combined with a logical 'AND'. */
export type PikaichiConfigBoolExp = {
  _and?: InputMaybe<Array<PikaichiConfigBoolExp>>;
  _not?: InputMaybe<PikaichiConfigBoolExp>;
  _or?: InputMaybe<Array<PikaichiConfigBoolExp>>;
  enabled?: InputMaybe<BooleanComparisonExp>;
  freeCsvType?: InputMaybe<StringComparisonExp>;
  ftpHost?: InputMaybe<StringComparisonExp>;
  ftpPassword?: InputMaybe<StringComparisonExp>;
  ftpPort?: InputMaybe<IntComparisonExp>;
  ftpUser?: InputMaybe<StringComparisonExp>;
  pikaichiCompanyCd?: InputMaybe<StringComparisonExp>;
  pikaichiConfigId?: InputMaybe<UuidComparisonExp>;
  pikaichiPosNumber?: InputMaybe<StringComparisonExp>;
  pikaichiShopCd?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  useKansa?: InputMaybe<BooleanComparisonExp>;
  useKinken?: InputMaybe<BooleanComparisonExp>;
  useNewari?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "pikaichiConfig" */
export enum PikaichiConfigConstraint {
  /** unique or primary key constraint on columns "pikaichiConfigId" */
  PikaichiConfigPkey = 'pikaichiConfig_pkey',
  /** unique or primary key constraint on columns "shopId" */
  PikaichiConfigShopIdKey = 'pikaichiConfig_shopId_key'
}

/** input type for incrementing numeric columns in table "pikaichiConfig" */
export type PikaichiConfigIncInput = {
  ftpPort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pikaichiConfig" */
export type PikaichiConfigInsertInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  freeCsvType?: InputMaybe<Scalars['String']>;
  ftpHost?: InputMaybe<Scalars['String']>;
  ftpPassword?: InputMaybe<Scalars['String']>;
  ftpPort?: InputMaybe<Scalars['Int']>;
  ftpUser?: InputMaybe<Scalars['String']>;
  pikaichiCompanyCd: Scalars['String'];
  pikaichiConfigId?: InputMaybe<Scalars['uuid']>;
  pikaichiPosNumber: Scalars['String'];
  pikaichiShopCd: Scalars['String'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  useKansa?: InputMaybe<Scalars['Boolean']>;
  useKinken?: InputMaybe<Scalars['Boolean']>;
  useNewari?: InputMaybe<Scalars['Boolean']>;
};

/** response of any mutation on the table "pikaichiConfig" */
export type PikaichiConfigMutationResponse = {
  __typename?: 'pikaichiConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PikaichiConfig>;
};

/** input type for inserting object relation for remote table "pikaichiConfig" */
export type PikaichiConfigObjRelInsertInput = {
  data: PikaichiConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PikaichiConfigOnConflict>;
};

/** on_conflict condition type for table "pikaichiConfig" */
export type PikaichiConfigOnConflict = {
  constraint: PikaichiConfigConstraint;
  update_columns?: Array<PikaichiConfigUpdateColumn>;
  where?: InputMaybe<PikaichiConfigBoolExp>;
};

/** Ordering options when selecting data from "pikaichiConfig". */
export type PikaichiConfigOrderBy = {
  enabled?: InputMaybe<OrderBy>;
  freeCsvType?: InputMaybe<OrderBy>;
  ftpHost?: InputMaybe<OrderBy>;
  ftpPassword?: InputMaybe<OrderBy>;
  ftpPort?: InputMaybe<OrderBy>;
  ftpUser?: InputMaybe<OrderBy>;
  pikaichiCompanyCd?: InputMaybe<OrderBy>;
  pikaichiConfigId?: InputMaybe<OrderBy>;
  pikaichiPosNumber?: InputMaybe<OrderBy>;
  pikaichiShopCd?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  useKansa?: InputMaybe<OrderBy>;
  useKinken?: InputMaybe<OrderBy>;
  useNewari?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: pikaichiConfig */
export type PikaichiConfigPkColumnsInput = {
  pikaichiConfigId: Scalars['uuid'];
};

/** select columns of table "pikaichiConfig" */
export enum PikaichiConfigSelectColumn {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FreeCsvType = 'freeCsvType',
  /** column name */
  FtpHost = 'ftpHost',
  /** column name */
  FtpPassword = 'ftpPassword',
  /** column name */
  FtpPort = 'ftpPort',
  /** column name */
  FtpUser = 'ftpUser',
  /** column name */
  PikaichiCompanyCd = 'pikaichiCompanyCd',
  /** column name */
  PikaichiConfigId = 'pikaichiConfigId',
  /** column name */
  PikaichiPosNumber = 'pikaichiPosNumber',
  /** column name */
  PikaichiShopCd = 'pikaichiShopCd',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  UseKansa = 'useKansa',
  /** column name */
  UseKinken = 'useKinken',
  /** column name */
  UseNewari = 'useNewari'
}

/** input type for updating data in table "pikaichiConfig" */
export type PikaichiConfigSetInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  freeCsvType?: InputMaybe<Scalars['String']>;
  ftpHost?: InputMaybe<Scalars['String']>;
  ftpPassword?: InputMaybe<Scalars['String']>;
  ftpPort?: InputMaybe<Scalars['Int']>;
  ftpUser?: InputMaybe<Scalars['String']>;
  pikaichiCompanyCd?: InputMaybe<Scalars['String']>;
  pikaichiConfigId?: InputMaybe<Scalars['uuid']>;
  pikaichiPosNumber?: InputMaybe<Scalars['String']>;
  pikaichiShopCd?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  useKansa?: InputMaybe<Scalars['Boolean']>;
  useKinken?: InputMaybe<Scalars['Boolean']>;
  useNewari?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "pikaichiConfig" */
export type PikaichiConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PikaichiConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PikaichiConfigStreamCursorValueInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  freeCsvType?: InputMaybe<Scalars['String']>;
  ftpHost?: InputMaybe<Scalars['String']>;
  ftpPassword?: InputMaybe<Scalars['String']>;
  ftpPort?: InputMaybe<Scalars['Int']>;
  ftpUser?: InputMaybe<Scalars['String']>;
  pikaichiCompanyCd?: InputMaybe<Scalars['String']>;
  pikaichiConfigId?: InputMaybe<Scalars['uuid']>;
  pikaichiPosNumber?: InputMaybe<Scalars['String']>;
  pikaichiShopCd?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  useKansa?: InputMaybe<Scalars['Boolean']>;
  useKinken?: InputMaybe<Scalars['Boolean']>;
  useNewari?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "pikaichiConfig" */
export enum PikaichiConfigUpdateColumn {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FreeCsvType = 'freeCsvType',
  /** column name */
  FtpHost = 'ftpHost',
  /** column name */
  FtpPassword = 'ftpPassword',
  /** column name */
  FtpPort = 'ftpPort',
  /** column name */
  FtpUser = 'ftpUser',
  /** column name */
  PikaichiCompanyCd = 'pikaichiCompanyCd',
  /** column name */
  PikaichiConfigId = 'pikaichiConfigId',
  /** column name */
  PikaichiPosNumber = 'pikaichiPosNumber',
  /** column name */
  PikaichiShopCd = 'pikaichiShopCd',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  UseKansa = 'useKansa',
  /** column name */
  UseKinken = 'useKinken',
  /** column name */
  UseNewari = 'useNewari'
}

export type PikaichiConfigUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PikaichiConfigIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PikaichiConfigSetInput>;
  /** filter the rows which have to be updated */
  where: PikaichiConfigBoolExp;
};

/** columns and relationships of "pikaichiMenu" */
export type PikaichiMenu = {
  __typename?: 'pikaichiMenu';
  companyId: Scalars['uuid'];
  pikaichiBumonCd?: Maybe<Scalars['String']>;
  pikaichiBumonName?: Maybe<Scalars['String']>;
  pikaichiCategoryCd?: Maybe<Scalars['String']>;
  pikaichiCategoryName?: Maybe<Scalars['String']>;
  pikaichiMenuCd: Scalars['String'];
  /** An array relationship */
  pikaichiMenuChoices: Array<PikaichiMenuChoice>;
  pikaichiMenuId: Scalars['uuid'];
  /** An array relationship */
  pikaichiMenuMenus: Array<PikaichiMenuMenu>;
  pikaichiMenuName: Scalars['String'];
  /** An array relationship */
  pikaichiMenuPlanChoices: Array<PikaichiMenuPlanChoice>;
  /** An array relationship */
  pikaichiMenuPlans: Array<PikaichiMenuPlan>;
  /** An object relationship */
  shop?: Maybe<Shop>;
  shopId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "pikaichiMenu" */
export type PikaichiMenuPikaichiMenuChoicesArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuChoiceOrderBy>>;
  where?: InputMaybe<PikaichiMenuChoiceBoolExp>;
};


/** columns and relationships of "pikaichiMenu" */
export type PikaichiMenuPikaichiMenuMenusArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuMenuOrderBy>>;
  where?: InputMaybe<PikaichiMenuMenuBoolExp>;
};


/** columns and relationships of "pikaichiMenu" */
export type PikaichiMenuPikaichiMenuPlanChoicesArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuPlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuPlanChoiceOrderBy>>;
  where?: InputMaybe<PikaichiMenuPlanChoiceBoolExp>;
};


/** columns and relationships of "pikaichiMenu" */
export type PikaichiMenuPikaichiMenuPlansArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuPlanOrderBy>>;
  where?: InputMaybe<PikaichiMenuPlanBoolExp>;
};

/** columns and relationships of "pikaichiMenuChoice" */
export type PikaichiMenuChoice = {
  __typename?: 'pikaichiMenuChoice';
  _choiceId: Scalars['Int'];
  /** An object relationship */
  choice: Choice;
  choiceId: Scalars['uuid'];
  /** An object relationship */
  pikaichiMenu: PikaichiMenu;
  pikaichiMenuChoiceId: Scalars['uuid'];
  pikaichiMenuId: Scalars['uuid'];
};

/** order by aggregate values of table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceAggregateOrderBy = {
  avg?: InputMaybe<PikaichiMenuChoiceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PikaichiMenuChoiceMaxOrderBy>;
  min?: InputMaybe<PikaichiMenuChoiceMinOrderBy>;
  stddev?: InputMaybe<PikaichiMenuChoiceStddevOrderBy>;
  stddev_pop?: InputMaybe<PikaichiMenuChoiceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PikaichiMenuChoiceStddevSampOrderBy>;
  sum?: InputMaybe<PikaichiMenuChoiceSumOrderBy>;
  var_pop?: InputMaybe<PikaichiMenuChoiceVarPopOrderBy>;
  var_samp?: InputMaybe<PikaichiMenuChoiceVarSampOrderBy>;
  variance?: InputMaybe<PikaichiMenuChoiceVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceArrRelInsertInput = {
  data: Array<PikaichiMenuChoiceInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PikaichiMenuChoiceOnConflict>;
};

/** order by avg() on columns of table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceAvgOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pikaichiMenuChoice". All fields are combined with a logical 'AND'. */
export type PikaichiMenuChoiceBoolExp = {
  _and?: InputMaybe<Array<PikaichiMenuChoiceBoolExp>>;
  _choiceId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<PikaichiMenuChoiceBoolExp>;
  _or?: InputMaybe<Array<PikaichiMenuChoiceBoolExp>>;
  choice?: InputMaybe<ChoiceBoolExp>;
  choiceId?: InputMaybe<UuidComparisonExp>;
  pikaichiMenu?: InputMaybe<PikaichiMenuBoolExp>;
  pikaichiMenuChoiceId?: InputMaybe<UuidComparisonExp>;
  pikaichiMenuId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "pikaichiMenuChoice" */
export enum PikaichiMenuChoiceConstraint {
  /** unique or primary key constraint on columns "pikaichiMenuId", "_choiceId" */
  PikaichiMenuChoicePikaichiMenuIdChoiceIdKey = 'pikaichiMenuChoice_pikaichiMenuId_choiceId_key',
  /** unique or primary key constraint on columns "pikaichiMenuChoiceId" */
  PikaichiMenuChoicePkey = 'pikaichiMenuChoice_pkey'
}

/** input type for incrementing numeric columns in table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceIncInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceInsertInput = {
  _choiceId: Scalars['Int'];
  choice?: InputMaybe<ChoiceObjRelInsertInput>;
  choiceId: Scalars['uuid'];
  pikaichiMenu?: InputMaybe<PikaichiMenuObjRelInsertInput>;
  pikaichiMenuChoiceId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuId: Scalars['uuid'];
};

/** order by max() on columns of table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceMaxOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  pikaichiMenuChoiceId?: InputMaybe<OrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceMinOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  pikaichiMenuChoiceId?: InputMaybe<OrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceMutationResponse = {
  __typename?: 'pikaichiMenuChoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PikaichiMenuChoice>;
};

/** on_conflict condition type for table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceOnConflict = {
  constraint: PikaichiMenuChoiceConstraint;
  update_columns?: Array<PikaichiMenuChoiceUpdateColumn>;
  where?: InputMaybe<PikaichiMenuChoiceBoolExp>;
};

/** Ordering options when selecting data from "pikaichiMenuChoice". */
export type PikaichiMenuChoiceOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  choice?: InputMaybe<ChoiceOrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  pikaichiMenu?: InputMaybe<PikaichiMenuOrderBy>;
  pikaichiMenuChoiceId?: InputMaybe<OrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: pikaichiMenuChoice */
export type PikaichiMenuChoicePkColumnsInput = {
  pikaichiMenuChoiceId: Scalars['uuid'];
};

/** select columns of table "pikaichiMenuChoice" */
export enum PikaichiMenuChoiceSelectColumn {
  /** column name */
  _ChoiceId = '_choiceId',
  /** column name */
  ChoiceId = 'choiceId',
  /** column name */
  PikaichiMenuChoiceId = 'pikaichiMenuChoiceId',
  /** column name */
  PikaichiMenuId = 'pikaichiMenuId'
}

/** input type for updating data in table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceSetInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  choiceId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuChoiceId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceStddevOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceStddevPopOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceStddevSampOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PikaichiMenuChoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PikaichiMenuChoiceStreamCursorValueInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  choiceId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuChoiceId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceSumOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** update columns of table "pikaichiMenuChoice" */
export enum PikaichiMenuChoiceUpdateColumn {
  /** column name */
  _ChoiceId = '_choiceId',
  /** column name */
  ChoiceId = 'choiceId',
  /** column name */
  PikaichiMenuChoiceId = 'pikaichiMenuChoiceId',
  /** column name */
  PikaichiMenuId = 'pikaichiMenuId'
}

export type PikaichiMenuChoiceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PikaichiMenuChoiceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PikaichiMenuChoiceSetInput>;
  /** filter the rows which have to be updated */
  where: PikaichiMenuChoiceBoolExp;
};

/** order by var_pop() on columns of table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceVarPopOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceVarSampOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "pikaichiMenuChoice" */
export type PikaichiMenuChoiceVarianceOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "pikaichiMenuMenu" */
export type PikaichiMenuMenu = {
  __typename?: 'pikaichiMenuMenu';
  _menuId: Scalars['Int'];
  /** An object relationship */
  menu: Menu;
  menuId: Scalars['uuid'];
  /** An object relationship */
  pikaichiMenu: PikaichiMenu;
  pikaichiMenuId: Scalars['uuid'];
  pikaichiMenuMenuId: Scalars['uuid'];
};

/** order by aggregate values of table "pikaichiMenuMenu" */
export type PikaichiMenuMenuAggregateOrderBy = {
  avg?: InputMaybe<PikaichiMenuMenuAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PikaichiMenuMenuMaxOrderBy>;
  min?: InputMaybe<PikaichiMenuMenuMinOrderBy>;
  stddev?: InputMaybe<PikaichiMenuMenuStddevOrderBy>;
  stddev_pop?: InputMaybe<PikaichiMenuMenuStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PikaichiMenuMenuStddevSampOrderBy>;
  sum?: InputMaybe<PikaichiMenuMenuSumOrderBy>;
  var_pop?: InputMaybe<PikaichiMenuMenuVarPopOrderBy>;
  var_samp?: InputMaybe<PikaichiMenuMenuVarSampOrderBy>;
  variance?: InputMaybe<PikaichiMenuMenuVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pikaichiMenuMenu" */
export type PikaichiMenuMenuArrRelInsertInput = {
  data: Array<PikaichiMenuMenuInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PikaichiMenuMenuOnConflict>;
};

/** order by avg() on columns of table "pikaichiMenuMenu" */
export type PikaichiMenuMenuAvgOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pikaichiMenuMenu". All fields are combined with a logical 'AND'. */
export type PikaichiMenuMenuBoolExp = {
  _and?: InputMaybe<Array<PikaichiMenuMenuBoolExp>>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<PikaichiMenuMenuBoolExp>;
  _or?: InputMaybe<Array<PikaichiMenuMenuBoolExp>>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  pikaichiMenu?: InputMaybe<PikaichiMenuBoolExp>;
  pikaichiMenuId?: InputMaybe<UuidComparisonExp>;
  pikaichiMenuMenuId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "pikaichiMenuMenu" */
export enum PikaichiMenuMenuConstraint {
  /** unique or primary key constraint on columns "pikaichiMenuId", "_menuId" */
  PikaichiMenuMenuPikaichiMenuIdMenuIdKey = 'pikaichiMenuMenu_pikaichiMenuId_menuId_key',
  /** unique or primary key constraint on columns "pikaichiMenuMenuId" */
  PikaichiMenuMenuPkey = 'pikaichiMenuMenu_pkey'
}

/** input type for incrementing numeric columns in table "pikaichiMenuMenu" */
export type PikaichiMenuMenuIncInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pikaichiMenuMenu" */
export type PikaichiMenuMenuInsertInput = {
  _menuId: Scalars['Int'];
  menu?: InputMaybe<MenuObjRelInsertInput>;
  menuId: Scalars['uuid'];
  pikaichiMenu?: InputMaybe<PikaichiMenuObjRelInsertInput>;
  pikaichiMenuId: Scalars['uuid'];
  pikaichiMenuMenuId?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "pikaichiMenuMenu" */
export type PikaichiMenuMenuMaxOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuMenuId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "pikaichiMenuMenu" */
export type PikaichiMenuMenuMinOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuMenuId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "pikaichiMenuMenu" */
export type PikaichiMenuMenuMutationResponse = {
  __typename?: 'pikaichiMenuMenu_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PikaichiMenuMenu>;
};

/** on_conflict condition type for table "pikaichiMenuMenu" */
export type PikaichiMenuMenuOnConflict = {
  constraint: PikaichiMenuMenuConstraint;
  update_columns?: Array<PikaichiMenuMenuUpdateColumn>;
  where?: InputMaybe<PikaichiMenuMenuBoolExp>;
};

/** Ordering options when selecting data from "pikaichiMenuMenu". */
export type PikaichiMenuMenuOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
  pikaichiMenu?: InputMaybe<PikaichiMenuOrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuMenuId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: pikaichiMenuMenu */
export type PikaichiMenuMenuPkColumnsInput = {
  pikaichiMenuMenuId: Scalars['uuid'];
};

/** select columns of table "pikaichiMenuMenu" */
export enum PikaichiMenuMenuSelectColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  PikaichiMenuId = 'pikaichiMenuId',
  /** column name */
  PikaichiMenuMenuId = 'pikaichiMenuMenuId'
}

/** input type for updating data in table "pikaichiMenuMenu" */
export type PikaichiMenuMenuSetInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuMenuId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "pikaichiMenuMenu" */
export type PikaichiMenuMenuStddevOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "pikaichiMenuMenu" */
export type PikaichiMenuMenuStddevPopOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "pikaichiMenuMenu" */
export type PikaichiMenuMenuStddevSampOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "pikaichiMenuMenu" */
export type PikaichiMenuMenuStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PikaichiMenuMenuStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PikaichiMenuMenuStreamCursorValueInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuMenuId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "pikaichiMenuMenu" */
export type PikaichiMenuMenuSumOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** update columns of table "pikaichiMenuMenu" */
export enum PikaichiMenuMenuUpdateColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  PikaichiMenuId = 'pikaichiMenuId',
  /** column name */
  PikaichiMenuMenuId = 'pikaichiMenuMenuId'
}

export type PikaichiMenuMenuUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PikaichiMenuMenuIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PikaichiMenuMenuSetInput>;
  /** filter the rows which have to be updated */
  where: PikaichiMenuMenuBoolExp;
};

/** order by var_pop() on columns of table "pikaichiMenuMenu" */
export type PikaichiMenuMenuVarPopOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "pikaichiMenuMenu" */
export type PikaichiMenuMenuVarSampOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "pikaichiMenuMenu" */
export type PikaichiMenuMenuVarianceOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "pikaichiMenuPlan" */
export type PikaichiMenuPlan = {
  __typename?: 'pikaichiMenuPlan';
  _planId: Scalars['Int'];
  /** An object relationship */
  pikaichiMenu: PikaichiMenu;
  pikaichiMenuId: Scalars['uuid'];
  pikaichiMenuPlanId: Scalars['uuid'];
  /** An object relationship */
  plan: Plan;
  planId: Scalars['uuid'];
};

/** columns and relationships of "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoice = {
  __typename?: 'pikaichiMenuPlanChoice';
  _planChoiceId: Scalars['Int'];
  /** An object relationship */
  pikaichiMenu: PikaichiMenu;
  pikaichiMenuId: Scalars['uuid'];
  pikaichiMenuPlanChoiceId: Scalars['uuid'];
  /** An object relationship */
  planChoice: PlanChoice;
  planChoiceId: Scalars['uuid'];
};

/** order by aggregate values of table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceAggregateOrderBy = {
  avg?: InputMaybe<PikaichiMenuPlanChoiceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PikaichiMenuPlanChoiceMaxOrderBy>;
  min?: InputMaybe<PikaichiMenuPlanChoiceMinOrderBy>;
  stddev?: InputMaybe<PikaichiMenuPlanChoiceStddevOrderBy>;
  stddev_pop?: InputMaybe<PikaichiMenuPlanChoiceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PikaichiMenuPlanChoiceStddevSampOrderBy>;
  sum?: InputMaybe<PikaichiMenuPlanChoiceSumOrderBy>;
  var_pop?: InputMaybe<PikaichiMenuPlanChoiceVarPopOrderBy>;
  var_samp?: InputMaybe<PikaichiMenuPlanChoiceVarSampOrderBy>;
  variance?: InputMaybe<PikaichiMenuPlanChoiceVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceArrRelInsertInput = {
  data: Array<PikaichiMenuPlanChoiceInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PikaichiMenuPlanChoiceOnConflict>;
};

/** order by avg() on columns of table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceAvgOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pikaichiMenuPlanChoice". All fields are combined with a logical 'AND'. */
export type PikaichiMenuPlanChoiceBoolExp = {
  _and?: InputMaybe<Array<PikaichiMenuPlanChoiceBoolExp>>;
  _not?: InputMaybe<PikaichiMenuPlanChoiceBoolExp>;
  _or?: InputMaybe<Array<PikaichiMenuPlanChoiceBoolExp>>;
  _planChoiceId?: InputMaybe<IntComparisonExp>;
  pikaichiMenu?: InputMaybe<PikaichiMenuBoolExp>;
  pikaichiMenuId?: InputMaybe<UuidComparisonExp>;
  pikaichiMenuPlanChoiceId?: InputMaybe<UuidComparisonExp>;
  planChoice?: InputMaybe<PlanChoiceBoolExp>;
  planChoiceId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "pikaichiMenuPlanChoice" */
export enum PikaichiMenuPlanChoiceConstraint {
  /** unique or primary key constraint on columns "pikaichiMenuId", "_planChoiceId" */
  PikaichiMenuPlanChoicePikaichiMenuIdPlanChoiceIdKey = 'pikaichiMenuPlanChoice_pikaichiMenuId_planChoiceId_key',
  /** unique or primary key constraint on columns "pikaichiMenuPlanChoiceId" */
  PikaichiMenuPlanChoicePkey = 'pikaichiMenuPlanChoice_pkey'
}

/** input type for incrementing numeric columns in table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceIncInput = {
  _planChoiceId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceInsertInput = {
  _planChoiceId: Scalars['Int'];
  pikaichiMenu?: InputMaybe<PikaichiMenuObjRelInsertInput>;
  pikaichiMenuId: Scalars['uuid'];
  pikaichiMenuPlanChoiceId?: InputMaybe<Scalars['uuid']>;
  planChoice?: InputMaybe<PlanChoiceObjRelInsertInput>;
  planChoiceId: Scalars['uuid'];
};

/** order by max() on columns of table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceMaxOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuPlanChoiceId?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceMinOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuPlanChoiceId?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceMutationResponse = {
  __typename?: 'pikaichiMenuPlanChoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PikaichiMenuPlanChoice>;
};

/** on_conflict condition type for table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceOnConflict = {
  constraint: PikaichiMenuPlanChoiceConstraint;
  update_columns?: Array<PikaichiMenuPlanChoiceUpdateColumn>;
  where?: InputMaybe<PikaichiMenuPlanChoiceBoolExp>;
};

/** Ordering options when selecting data from "pikaichiMenuPlanChoice". */
export type PikaichiMenuPlanChoiceOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
  pikaichiMenu?: InputMaybe<PikaichiMenuOrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuPlanChoiceId?: InputMaybe<OrderBy>;
  planChoice?: InputMaybe<PlanChoiceOrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: pikaichiMenuPlanChoice */
export type PikaichiMenuPlanChoicePkColumnsInput = {
  pikaichiMenuPlanChoiceId: Scalars['uuid'];
};

/** select columns of table "pikaichiMenuPlanChoice" */
export enum PikaichiMenuPlanChoiceSelectColumn {
  /** column name */
  _PlanChoiceId = '_planChoiceId',
  /** column name */
  PikaichiMenuId = 'pikaichiMenuId',
  /** column name */
  PikaichiMenuPlanChoiceId = 'pikaichiMenuPlanChoiceId',
  /** column name */
  PlanChoiceId = 'planChoiceId'
}

/** input type for updating data in table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceSetInput = {
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  pikaichiMenuId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuPlanChoiceId?: InputMaybe<Scalars['uuid']>;
  planChoiceId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceStddevOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceStddevPopOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceStddevSampOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PikaichiMenuPlanChoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PikaichiMenuPlanChoiceStreamCursorValueInput = {
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  pikaichiMenuId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuPlanChoiceId?: InputMaybe<Scalars['uuid']>;
  planChoiceId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceSumOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** update columns of table "pikaichiMenuPlanChoice" */
export enum PikaichiMenuPlanChoiceUpdateColumn {
  /** column name */
  _PlanChoiceId = '_planChoiceId',
  /** column name */
  PikaichiMenuId = 'pikaichiMenuId',
  /** column name */
  PikaichiMenuPlanChoiceId = 'pikaichiMenuPlanChoiceId',
  /** column name */
  PlanChoiceId = 'planChoiceId'
}

export type PikaichiMenuPlanChoiceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PikaichiMenuPlanChoiceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PikaichiMenuPlanChoiceSetInput>;
  /** filter the rows which have to be updated */
  where: PikaichiMenuPlanChoiceBoolExp;
};

/** order by var_pop() on columns of table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceVarPopOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceVarSampOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "pikaichiMenuPlanChoice" */
export type PikaichiMenuPlanChoiceVarianceOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "pikaichiMenuPlan" */
export type PikaichiMenuPlanAggregateOrderBy = {
  avg?: InputMaybe<PikaichiMenuPlanAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PikaichiMenuPlanMaxOrderBy>;
  min?: InputMaybe<PikaichiMenuPlanMinOrderBy>;
  stddev?: InputMaybe<PikaichiMenuPlanStddevOrderBy>;
  stddev_pop?: InputMaybe<PikaichiMenuPlanStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PikaichiMenuPlanStddevSampOrderBy>;
  sum?: InputMaybe<PikaichiMenuPlanSumOrderBy>;
  var_pop?: InputMaybe<PikaichiMenuPlanVarPopOrderBy>;
  var_samp?: InputMaybe<PikaichiMenuPlanVarSampOrderBy>;
  variance?: InputMaybe<PikaichiMenuPlanVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pikaichiMenuPlan" */
export type PikaichiMenuPlanArrRelInsertInput = {
  data: Array<PikaichiMenuPlanInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PikaichiMenuPlanOnConflict>;
};

/** order by avg() on columns of table "pikaichiMenuPlan" */
export type PikaichiMenuPlanAvgOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pikaichiMenuPlan". All fields are combined with a logical 'AND'. */
export type PikaichiMenuPlanBoolExp = {
  _and?: InputMaybe<Array<PikaichiMenuPlanBoolExp>>;
  _not?: InputMaybe<PikaichiMenuPlanBoolExp>;
  _or?: InputMaybe<Array<PikaichiMenuPlanBoolExp>>;
  _planId?: InputMaybe<IntComparisonExp>;
  pikaichiMenu?: InputMaybe<PikaichiMenuBoolExp>;
  pikaichiMenuId?: InputMaybe<UuidComparisonExp>;
  pikaichiMenuPlanId?: InputMaybe<UuidComparisonExp>;
  plan?: InputMaybe<PlanBoolExp>;
  planId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "pikaichiMenuPlan" */
export enum PikaichiMenuPlanConstraint {
  /** unique or primary key constraint on columns "pikaichiMenuId", "_planId" */
  PikaichiMenuPlanPikaichiMenuIdPlanIdKey = 'pikaichiMenuPlan_pikaichiMenuId_planId_key',
  /** unique or primary key constraint on columns "pikaichiMenuPlanId" */
  PikaichiMenuPlanPkey = 'pikaichiMenuPlan_pkey'
}

/** input type for incrementing numeric columns in table "pikaichiMenuPlan" */
export type PikaichiMenuPlanIncInput = {
  _planId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pikaichiMenuPlan" */
export type PikaichiMenuPlanInsertInput = {
  _planId: Scalars['Int'];
  pikaichiMenu?: InputMaybe<PikaichiMenuObjRelInsertInput>;
  pikaichiMenuId: Scalars['uuid'];
  pikaichiMenuPlanId?: InputMaybe<Scalars['uuid']>;
  plan?: InputMaybe<PlanObjRelInsertInput>;
  planId: Scalars['uuid'];
};

/** order by max() on columns of table "pikaichiMenuPlan" */
export type PikaichiMenuPlanMaxOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuPlanId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "pikaichiMenuPlan" */
export type PikaichiMenuPlanMinOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuPlanId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "pikaichiMenuPlan" */
export type PikaichiMenuPlanMutationResponse = {
  __typename?: 'pikaichiMenuPlan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PikaichiMenuPlan>;
};

/** on_conflict condition type for table "pikaichiMenuPlan" */
export type PikaichiMenuPlanOnConflict = {
  constraint: PikaichiMenuPlanConstraint;
  update_columns?: Array<PikaichiMenuPlanUpdateColumn>;
  where?: InputMaybe<PikaichiMenuPlanBoolExp>;
};

/** Ordering options when selecting data from "pikaichiMenuPlan". */
export type PikaichiMenuPlanOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  pikaichiMenu?: InputMaybe<PikaichiMenuOrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuPlanId?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlanOrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: pikaichiMenuPlan */
export type PikaichiMenuPlanPkColumnsInput = {
  pikaichiMenuPlanId: Scalars['uuid'];
};

/** select columns of table "pikaichiMenuPlan" */
export enum PikaichiMenuPlanSelectColumn {
  /** column name */
  _PlanId = '_planId',
  /** column name */
  PikaichiMenuId = 'pikaichiMenuId',
  /** column name */
  PikaichiMenuPlanId = 'pikaichiMenuPlanId',
  /** column name */
  PlanId = 'planId'
}

/** input type for updating data in table "pikaichiMenuPlan" */
export type PikaichiMenuPlanSetInput = {
  _planId?: InputMaybe<Scalars['Int']>;
  pikaichiMenuId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuPlanId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "pikaichiMenuPlan" */
export type PikaichiMenuPlanStddevOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "pikaichiMenuPlan" */
export type PikaichiMenuPlanStddevPopOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "pikaichiMenuPlan" */
export type PikaichiMenuPlanStddevSampOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "pikaichiMenuPlan" */
export type PikaichiMenuPlanStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PikaichiMenuPlanStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PikaichiMenuPlanStreamCursorValueInput = {
  _planId?: InputMaybe<Scalars['Int']>;
  pikaichiMenuId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuPlanId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "pikaichiMenuPlan" */
export type PikaichiMenuPlanSumOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** update columns of table "pikaichiMenuPlan" */
export enum PikaichiMenuPlanUpdateColumn {
  /** column name */
  _PlanId = '_planId',
  /** column name */
  PikaichiMenuId = 'pikaichiMenuId',
  /** column name */
  PikaichiMenuPlanId = 'pikaichiMenuPlanId',
  /** column name */
  PlanId = 'planId'
}

export type PikaichiMenuPlanUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PikaichiMenuPlanIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PikaichiMenuPlanSetInput>;
  /** filter the rows which have to be updated */
  where: PikaichiMenuPlanBoolExp;
};

/** order by var_pop() on columns of table "pikaichiMenuPlan" */
export type PikaichiMenuPlanVarPopOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "pikaichiMenuPlan" */
export type PikaichiMenuPlanVarSampOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "pikaichiMenuPlan" */
export type PikaichiMenuPlanVarianceOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "pikaichiMenu" */
export type PikaichiMenuAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PikaichiMenuMaxOrderBy>;
  min?: InputMaybe<PikaichiMenuMinOrderBy>;
};

/** input type for inserting array relation for remote table "pikaichiMenu" */
export type PikaichiMenuArrRelInsertInput = {
  data: Array<PikaichiMenuInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PikaichiMenuOnConflict>;
};

/** Boolean expression to filter rows from the table "pikaichiMenu". All fields are combined with a logical 'AND'. */
export type PikaichiMenuBoolExp = {
  _and?: InputMaybe<Array<PikaichiMenuBoolExp>>;
  _not?: InputMaybe<PikaichiMenuBoolExp>;
  _or?: InputMaybe<Array<PikaichiMenuBoolExp>>;
  companyId?: InputMaybe<UuidComparisonExp>;
  pikaichiBumonCd?: InputMaybe<StringComparisonExp>;
  pikaichiBumonName?: InputMaybe<StringComparisonExp>;
  pikaichiCategoryCd?: InputMaybe<StringComparisonExp>;
  pikaichiCategoryName?: InputMaybe<StringComparisonExp>;
  pikaichiMenuCd?: InputMaybe<StringComparisonExp>;
  pikaichiMenuChoices?: InputMaybe<PikaichiMenuChoiceBoolExp>;
  pikaichiMenuId?: InputMaybe<UuidComparisonExp>;
  pikaichiMenuMenus?: InputMaybe<PikaichiMenuMenuBoolExp>;
  pikaichiMenuName?: InputMaybe<StringComparisonExp>;
  pikaichiMenuPlanChoices?: InputMaybe<PikaichiMenuPlanChoiceBoolExp>;
  pikaichiMenuPlans?: InputMaybe<PikaichiMenuPlanBoolExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "pikaichiMenu" */
export enum PikaichiMenuConstraint {
  /** unique or primary key constraint on columns "pikaichiMenuId" */
  PikaichiMenuPkey = 'pikaichiMenu_pkey'
}

/** input type for inserting data into table "pikaichiMenu" */
export type PikaichiMenuInsertInput = {
  companyId: Scalars['uuid'];
  pikaichiBumonCd?: InputMaybe<Scalars['String']>;
  pikaichiBumonName?: InputMaybe<Scalars['String']>;
  pikaichiCategoryCd?: InputMaybe<Scalars['String']>;
  pikaichiCategoryName?: InputMaybe<Scalars['String']>;
  pikaichiMenuCd: Scalars['String'];
  pikaichiMenuChoices?: InputMaybe<PikaichiMenuChoiceArrRelInsertInput>;
  pikaichiMenuId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuMenus?: InputMaybe<PikaichiMenuMenuArrRelInsertInput>;
  pikaichiMenuName: Scalars['String'];
  pikaichiMenuPlanChoices?: InputMaybe<PikaichiMenuPlanChoiceArrRelInsertInput>;
  pikaichiMenuPlans?: InputMaybe<PikaichiMenuPlanArrRelInsertInput>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "pikaichiMenu" */
export type PikaichiMenuMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  pikaichiBumonCd?: InputMaybe<OrderBy>;
  pikaichiBumonName?: InputMaybe<OrderBy>;
  pikaichiCategoryCd?: InputMaybe<OrderBy>;
  pikaichiCategoryName?: InputMaybe<OrderBy>;
  pikaichiMenuCd?: InputMaybe<OrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuName?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "pikaichiMenu" */
export type PikaichiMenuMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  pikaichiBumonCd?: InputMaybe<OrderBy>;
  pikaichiBumonName?: InputMaybe<OrderBy>;
  pikaichiCategoryCd?: InputMaybe<OrderBy>;
  pikaichiCategoryName?: InputMaybe<OrderBy>;
  pikaichiMenuCd?: InputMaybe<OrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuName?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "pikaichiMenu" */
export type PikaichiMenuMutationResponse = {
  __typename?: 'pikaichiMenu_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PikaichiMenu>;
};

/** input type for inserting object relation for remote table "pikaichiMenu" */
export type PikaichiMenuObjRelInsertInput = {
  data: PikaichiMenuInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PikaichiMenuOnConflict>;
};

/** on_conflict condition type for table "pikaichiMenu" */
export type PikaichiMenuOnConflict = {
  constraint: PikaichiMenuConstraint;
  update_columns?: Array<PikaichiMenuUpdateColumn>;
  where?: InputMaybe<PikaichiMenuBoolExp>;
};

/** Ordering options when selecting data from "pikaichiMenu". */
export type PikaichiMenuOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  pikaichiBumonCd?: InputMaybe<OrderBy>;
  pikaichiBumonName?: InputMaybe<OrderBy>;
  pikaichiCategoryCd?: InputMaybe<OrderBy>;
  pikaichiCategoryName?: InputMaybe<OrderBy>;
  pikaichiMenuCd?: InputMaybe<OrderBy>;
  pikaichiMenuChoices_aggregate?: InputMaybe<PikaichiMenuChoiceAggregateOrderBy>;
  pikaichiMenuId?: InputMaybe<OrderBy>;
  pikaichiMenuMenus_aggregate?: InputMaybe<PikaichiMenuMenuAggregateOrderBy>;
  pikaichiMenuName?: InputMaybe<OrderBy>;
  pikaichiMenuPlanChoices_aggregate?: InputMaybe<PikaichiMenuPlanChoiceAggregateOrderBy>;
  pikaichiMenuPlans_aggregate?: InputMaybe<PikaichiMenuPlanAggregateOrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: pikaichiMenu */
export type PikaichiMenuPkColumnsInput = {
  pikaichiMenuId: Scalars['uuid'];
};

/** select columns of table "pikaichiMenu" */
export enum PikaichiMenuSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  PikaichiBumonCd = 'pikaichiBumonCd',
  /** column name */
  PikaichiBumonName = 'pikaichiBumonName',
  /** column name */
  PikaichiCategoryCd = 'pikaichiCategoryCd',
  /** column name */
  PikaichiCategoryName = 'pikaichiCategoryName',
  /** column name */
  PikaichiMenuCd = 'pikaichiMenuCd',
  /** column name */
  PikaichiMenuId = 'pikaichiMenuId',
  /** column name */
  PikaichiMenuName = 'pikaichiMenuName',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "pikaichiMenu" */
export type PikaichiMenuSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  pikaichiBumonCd?: InputMaybe<Scalars['String']>;
  pikaichiBumonName?: InputMaybe<Scalars['String']>;
  pikaichiCategoryCd?: InputMaybe<Scalars['String']>;
  pikaichiCategoryName?: InputMaybe<Scalars['String']>;
  pikaichiMenuCd?: InputMaybe<Scalars['String']>;
  pikaichiMenuId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuName?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "pikaichiMenu" */
export type PikaichiMenuStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PikaichiMenuStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PikaichiMenuStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  pikaichiBumonCd?: InputMaybe<Scalars['String']>;
  pikaichiBumonName?: InputMaybe<Scalars['String']>;
  pikaichiCategoryCd?: InputMaybe<Scalars['String']>;
  pikaichiCategoryName?: InputMaybe<Scalars['String']>;
  pikaichiMenuCd?: InputMaybe<Scalars['String']>;
  pikaichiMenuId?: InputMaybe<Scalars['uuid']>;
  pikaichiMenuName?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "pikaichiMenu" */
export enum PikaichiMenuUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  PikaichiBumonCd = 'pikaichiBumonCd',
  /** column name */
  PikaichiBumonName = 'pikaichiBumonName',
  /** column name */
  PikaichiCategoryCd = 'pikaichiCategoryCd',
  /** column name */
  PikaichiCategoryName = 'pikaichiCategoryName',
  /** column name */
  PikaichiMenuCd = 'pikaichiMenuCd',
  /** column name */
  PikaichiMenuId = 'pikaichiMenuId',
  /** column name */
  PikaichiMenuName = 'pikaichiMenuName',
  /** column name */
  ShopId = 'shopId'
}

export type PikaichiMenuUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PikaichiMenuSetInput>;
  /** filter the rows which have to be updated */
  where: PikaichiMenuBoolExp;
};

/** columns and relationships of "pikaichiOnSitePaymentDetailType" */
export type PikaichiOnSitePaymentDetailType = {
  __typename?: 'pikaichiOnSitePaymentDetailType';
  /** An object relationship */
  company: Company;
  /** An object relationship */
  onSitePaymentDetailType: OnSitePaymentDetailType;
  onSitePaymentDetailTypeEntityType: Scalars['String'];
  onSitePaymentDetailTypeId: Scalars['uuid'];
  paymentMediaCd: Scalars['String'];
  paymentMediaName: Scalars['String'];
  paymentUchiwakeCd: Scalars['String'];
  paymentUchiwakeName: Scalars['String'];
  pikaichiPaymentDetailTypeId: Scalars['uuid'];
  /** An object relationship */
  shop?: Maybe<Shop>;
  shopId?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "pikaichiOnSitePaymentDetailType" */
export type PikaichiOnSitePaymentDetailTypeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PikaichiOnSitePaymentDetailTypeMaxOrderBy>;
  min?: InputMaybe<PikaichiOnSitePaymentDetailTypeMinOrderBy>;
};

/** input type for inserting array relation for remote table "pikaichiOnSitePaymentDetailType" */
export type PikaichiOnSitePaymentDetailTypeArrRelInsertInput = {
  data: Array<PikaichiOnSitePaymentDetailTypeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PikaichiOnSitePaymentDetailTypeOnConflict>;
};

/**
 * Boolean expression to filter rows from the table
 * "pikaichiOnSitePaymentDetailType". All fields are combined with a logical 'AND'.
 */
export type PikaichiOnSitePaymentDetailTypeBoolExp = {
  _and?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeBoolExp>>;
  _not?: InputMaybe<PikaichiOnSitePaymentDetailTypeBoolExp>;
  _or?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<StringComparisonExp>;
  onSitePaymentDetailTypeId?: InputMaybe<UuidComparisonExp>;
  paymentMediaCd?: InputMaybe<StringComparisonExp>;
  paymentMediaName?: InputMaybe<StringComparisonExp>;
  paymentUchiwakeCd?: InputMaybe<StringComparisonExp>;
  paymentUchiwakeName?: InputMaybe<StringComparisonExp>;
  pikaichiPaymentDetailTypeId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "pikaichiOnSitePaymentDetailType" */
export enum PikaichiOnSitePaymentDetailTypeConstraint {
  /** unique or primary key constraint on columns "onSitePaymentDetailTypeEntityType", "shopId" */
  PikaichiOnSitePaymentDetailTyShopIdOnSitePaymentDetailTypKey = 'pikaichiOnSitePaymentDetailTy_shopId_onSitePaymentDetailTyp_key',
  /** unique or primary key constraint on columns "pikaichiPaymentDetailTypeId" */
  PikaichiOnSitePaymentDetailTypePkey = 'pikaichiOnSitePaymentDetailType_pkey'
}

/** input type for inserting data into table "pikaichiOnSitePaymentDetailType" */
export type PikaichiOnSitePaymentDetailTypeInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeObjRelInsertInput>;
  onSitePaymentDetailTypeEntityType: Scalars['String'];
  onSitePaymentDetailTypeId: Scalars['uuid'];
  paymentMediaCd: Scalars['String'];
  paymentMediaName: Scalars['String'];
  paymentUchiwakeCd: Scalars['String'];
  paymentUchiwakeName: Scalars['String'];
  pikaichiPaymentDetailTypeId?: InputMaybe<Scalars['uuid']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "pikaichiOnSitePaymentDetailType" */
export type PikaichiOnSitePaymentDetailTypeMaxOrderBy = {
  onSitePaymentDetailTypeEntityType?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
  paymentMediaCd?: InputMaybe<OrderBy>;
  paymentMediaName?: InputMaybe<OrderBy>;
  paymentUchiwakeCd?: InputMaybe<OrderBy>;
  paymentUchiwakeName?: InputMaybe<OrderBy>;
  pikaichiPaymentDetailTypeId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "pikaichiOnSitePaymentDetailType" */
export type PikaichiOnSitePaymentDetailTypeMinOrderBy = {
  onSitePaymentDetailTypeEntityType?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
  paymentMediaCd?: InputMaybe<OrderBy>;
  paymentMediaName?: InputMaybe<OrderBy>;
  paymentUchiwakeCd?: InputMaybe<OrderBy>;
  paymentUchiwakeName?: InputMaybe<OrderBy>;
  pikaichiPaymentDetailTypeId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "pikaichiOnSitePaymentDetailType" */
export type PikaichiOnSitePaymentDetailTypeMutationResponse = {
  __typename?: 'pikaichiOnSitePaymentDetailType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PikaichiOnSitePaymentDetailType>;
};

/** on_conflict condition type for table "pikaichiOnSitePaymentDetailType" */
export type PikaichiOnSitePaymentDetailTypeOnConflict = {
  constraint: PikaichiOnSitePaymentDetailTypeConstraint;
  update_columns?: Array<PikaichiOnSitePaymentDetailTypeUpdateColumn>;
  where?: InputMaybe<PikaichiOnSitePaymentDetailTypeBoolExp>;
};

/** Ordering options when selecting data from "pikaichiOnSitePaymentDetailType". */
export type PikaichiOnSitePaymentDetailTypeOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeOrderBy>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
  paymentMediaCd?: InputMaybe<OrderBy>;
  paymentMediaName?: InputMaybe<OrderBy>;
  paymentUchiwakeCd?: InputMaybe<OrderBy>;
  paymentUchiwakeName?: InputMaybe<OrderBy>;
  pikaichiPaymentDetailTypeId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: pikaichiOnSitePaymentDetailType */
export type PikaichiOnSitePaymentDetailTypePkColumnsInput = {
  pikaichiPaymentDetailTypeId: Scalars['uuid'];
};

/** select columns of table "pikaichiOnSitePaymentDetailType" */
export enum PikaichiOnSitePaymentDetailTypeSelectColumn {
  /** column name */
  OnSitePaymentDetailTypeEntityType = 'onSitePaymentDetailTypeEntityType',
  /** column name */
  OnSitePaymentDetailTypeId = 'onSitePaymentDetailTypeId',
  /** column name */
  PaymentMediaCd = 'paymentMediaCd',
  /** column name */
  PaymentMediaName = 'paymentMediaName',
  /** column name */
  PaymentUchiwakeCd = 'paymentUchiwakeCd',
  /** column name */
  PaymentUchiwakeName = 'paymentUchiwakeName',
  /** column name */
  PikaichiPaymentDetailTypeId = 'pikaichiPaymentDetailTypeId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "pikaichiOnSitePaymentDetailType" */
export type PikaichiOnSitePaymentDetailTypeSetInput = {
  onSitePaymentDetailTypeEntityType?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeId?: InputMaybe<Scalars['uuid']>;
  paymentMediaCd?: InputMaybe<Scalars['String']>;
  paymentMediaName?: InputMaybe<Scalars['String']>;
  paymentUchiwakeCd?: InputMaybe<Scalars['String']>;
  paymentUchiwakeName?: InputMaybe<Scalars['String']>;
  pikaichiPaymentDetailTypeId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "pikaichiOnSitePaymentDetailType" */
export type PikaichiOnSitePaymentDetailTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PikaichiOnSitePaymentDetailTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PikaichiOnSitePaymentDetailTypeStreamCursorValueInput = {
  onSitePaymentDetailTypeEntityType?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeId?: InputMaybe<Scalars['uuid']>;
  paymentMediaCd?: InputMaybe<Scalars['String']>;
  paymentMediaName?: InputMaybe<Scalars['String']>;
  paymentUchiwakeCd?: InputMaybe<Scalars['String']>;
  paymentUchiwakeName?: InputMaybe<Scalars['String']>;
  pikaichiPaymentDetailTypeId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "pikaichiOnSitePaymentDetailType" */
export enum PikaichiOnSitePaymentDetailTypeUpdateColumn {
  /** column name */
  OnSitePaymentDetailTypeEntityType = 'onSitePaymentDetailTypeEntityType',
  /** column name */
  OnSitePaymentDetailTypeId = 'onSitePaymentDetailTypeId',
  /** column name */
  PaymentMediaCd = 'paymentMediaCd',
  /** column name */
  PaymentMediaName = 'paymentMediaName',
  /** column name */
  PaymentUchiwakeCd = 'paymentUchiwakeCd',
  /** column name */
  PaymentUchiwakeName = 'paymentUchiwakeName',
  /** column name */
  PikaichiPaymentDetailTypeId = 'pikaichiPaymentDetailTypeId'
}

export type PikaichiOnSitePaymentDetailTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PikaichiOnSitePaymentDetailTypeSetInput>;
  /** filter the rows which have to be updated */
  where: PikaichiOnSitePaymentDetailTypeBoolExp;
};

/** columns and relationships of "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountType = {
  __typename?: 'pikaichiOnSitePaymentDiscountType';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  newariCd: Scalars['Int'];
  newariKubun: Scalars['Int'];
  newariName: Scalars['String'];
  /** An object relationship */
  onSitePaymentDiscountType: OnSitePaymentDiscountType;
  onSitePaymentDiscountTypeId: Scalars['uuid'];
  onSitePaymentDiscountTypeKey: Scalars['String'];
  pikaichiDiscountTypeId: Scalars['uuid'];
  /** An object relationship */
  shop?: Maybe<Shop>;
  shopId?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeAggregateOrderBy = {
  avg?: InputMaybe<PikaichiOnSitePaymentDiscountTypeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PikaichiOnSitePaymentDiscountTypeMaxOrderBy>;
  min?: InputMaybe<PikaichiOnSitePaymentDiscountTypeMinOrderBy>;
  stddev?: InputMaybe<PikaichiOnSitePaymentDiscountTypeStddevOrderBy>;
  stddev_pop?: InputMaybe<PikaichiOnSitePaymentDiscountTypeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PikaichiOnSitePaymentDiscountTypeStddevSampOrderBy>;
  sum?: InputMaybe<PikaichiOnSitePaymentDiscountTypeSumOrderBy>;
  var_pop?: InputMaybe<PikaichiOnSitePaymentDiscountTypeVarPopOrderBy>;
  var_samp?: InputMaybe<PikaichiOnSitePaymentDiscountTypeVarSampOrderBy>;
  variance?: InputMaybe<PikaichiOnSitePaymentDiscountTypeVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeArrRelInsertInput = {
  data: Array<PikaichiOnSitePaymentDiscountTypeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PikaichiOnSitePaymentDiscountTypeOnConflict>;
};

/** order by avg() on columns of table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeAvgOrderBy = {
  newariCd?: InputMaybe<OrderBy>;
  newariKubun?: InputMaybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "pikaichiOnSitePaymentDiscountType". All fields are combined with a logical 'AND'.
 */
export type PikaichiOnSitePaymentDiscountTypeBoolExp = {
  _and?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeBoolExp>>;
  _not?: InputMaybe<PikaichiOnSitePaymentDiscountTypeBoolExp>;
  _or?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  newariCd?: InputMaybe<IntComparisonExp>;
  newariKubun?: InputMaybe<IntComparisonExp>;
  newariName?: InputMaybe<StringComparisonExp>;
  onSitePaymentDiscountType?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
  onSitePaymentDiscountTypeId?: InputMaybe<UuidComparisonExp>;
  onSitePaymentDiscountTypeKey?: InputMaybe<StringComparisonExp>;
  pikaichiDiscountTypeId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "pikaichiOnSitePaymentDiscountType" */
export enum PikaichiOnSitePaymentDiscountTypeConstraint {
  /** unique or primary key constraint on columns "pikaichiDiscountTypeId" */
  PikaichiOnSitePaymentDiscountTypePkey = 'PikaichiOnSitePaymentDiscountType_pkey'
}

/** input type for incrementing numeric columns in table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeIncInput = {
  newariCd?: InputMaybe<Scalars['Int']>;
  newariKubun?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  newariCd: Scalars['Int'];
  newariKubun: Scalars['Int'];
  newariName: Scalars['String'];
  onSitePaymentDiscountType?: InputMaybe<OnSitePaymentDiscountTypeObjRelInsertInput>;
  onSitePaymentDiscountTypeId: Scalars['uuid'];
  onSitePaymentDiscountTypeKey: Scalars['String'];
  pikaichiDiscountTypeId?: InputMaybe<Scalars['uuid']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  newariCd?: InputMaybe<OrderBy>;
  newariKubun?: InputMaybe<OrderBy>;
  newariName?: InputMaybe<OrderBy>;
  onSitePaymentDiscountTypeId?: InputMaybe<OrderBy>;
  onSitePaymentDiscountTypeKey?: InputMaybe<OrderBy>;
  pikaichiDiscountTypeId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  newariCd?: InputMaybe<OrderBy>;
  newariKubun?: InputMaybe<OrderBy>;
  newariName?: InputMaybe<OrderBy>;
  onSitePaymentDiscountTypeId?: InputMaybe<OrderBy>;
  onSitePaymentDiscountTypeKey?: InputMaybe<OrderBy>;
  pikaichiDiscountTypeId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeMutationResponse = {
  __typename?: 'pikaichiOnSitePaymentDiscountType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PikaichiOnSitePaymentDiscountType>;
};

/** on_conflict condition type for table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeOnConflict = {
  constraint: PikaichiOnSitePaymentDiscountTypeConstraint;
  update_columns?: Array<PikaichiOnSitePaymentDiscountTypeUpdateColumn>;
  where?: InputMaybe<PikaichiOnSitePaymentDiscountTypeBoolExp>;
};

/** Ordering options when selecting data from "pikaichiOnSitePaymentDiscountType". */
export type PikaichiOnSitePaymentDiscountTypeOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  newariCd?: InputMaybe<OrderBy>;
  newariKubun?: InputMaybe<OrderBy>;
  newariName?: InputMaybe<OrderBy>;
  onSitePaymentDiscountType?: InputMaybe<OnSitePaymentDiscountTypeOrderBy>;
  onSitePaymentDiscountTypeId?: InputMaybe<OrderBy>;
  onSitePaymentDiscountTypeKey?: InputMaybe<OrderBy>;
  pikaichiDiscountTypeId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: pikaichiOnSitePaymentDiscountType */
export type PikaichiOnSitePaymentDiscountTypePkColumnsInput = {
  pikaichiDiscountTypeId: Scalars['uuid'];
};

/** select columns of table "pikaichiOnSitePaymentDiscountType" */
export enum PikaichiOnSitePaymentDiscountTypeSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  NewariCd = 'newariCd',
  /** column name */
  NewariKubun = 'newariKubun',
  /** column name */
  NewariName = 'newariName',
  /** column name */
  OnSitePaymentDiscountTypeId = 'onSitePaymentDiscountTypeId',
  /** column name */
  OnSitePaymentDiscountTypeKey = 'onSitePaymentDiscountTypeKey',
  /** column name */
  PikaichiDiscountTypeId = 'pikaichiDiscountTypeId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeSetInput = {
  newariCd?: InputMaybe<Scalars['Int']>;
  newariKubun?: InputMaybe<Scalars['Int']>;
  newariName?: InputMaybe<Scalars['String']>;
  onSitePaymentDiscountTypeId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeStddevOrderBy = {
  newariCd?: InputMaybe<OrderBy>;
  newariKubun?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeStddevPopOrderBy = {
  newariCd?: InputMaybe<OrderBy>;
  newariKubun?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeStddevSampOrderBy = {
  newariCd?: InputMaybe<OrderBy>;
  newariKubun?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PikaichiOnSitePaymentDiscountTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PikaichiOnSitePaymentDiscountTypeStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  newariCd?: InputMaybe<Scalars['Int']>;
  newariKubun?: InputMaybe<Scalars['Int']>;
  newariName?: InputMaybe<Scalars['String']>;
  onSitePaymentDiscountTypeId?: InputMaybe<Scalars['uuid']>;
  onSitePaymentDiscountTypeKey?: InputMaybe<Scalars['String']>;
  pikaichiDiscountTypeId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeSumOrderBy = {
  newariCd?: InputMaybe<OrderBy>;
  newariKubun?: InputMaybe<OrderBy>;
};

/** update columns of table "pikaichiOnSitePaymentDiscountType" */
export enum PikaichiOnSitePaymentDiscountTypeUpdateColumn {
  /** column name */
  NewariCd = 'newariCd',
  /** column name */
  NewariKubun = 'newariKubun',
  /** column name */
  NewariName = 'newariName',
  /** column name */
  OnSitePaymentDiscountTypeId = 'onSitePaymentDiscountTypeId'
}

export type PikaichiOnSitePaymentDiscountTypeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PikaichiOnSitePaymentDiscountTypeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PikaichiOnSitePaymentDiscountTypeSetInput>;
  /** filter the rows which have to be updated */
  where: PikaichiOnSitePaymentDiscountTypeBoolExp;
};

/** order by var_pop() on columns of table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeVarPopOrderBy = {
  newariCd?: InputMaybe<OrderBy>;
  newariKubun?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeVarSampOrderBy = {
  newariCd?: InputMaybe<OrderBy>;
  newariKubun?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "pikaichiOnSitePaymentDiscountType" */
export type PikaichiOnSitePaymentDiscountTypeVarianceOrderBy = {
  newariCd?: InputMaybe<OrderBy>;
  newariKubun?: InputMaybe<OrderBy>;
};

/** columns and relationships of "plan" */
export type Plan = {
  __typename?: 'plan';
  _planGroupId?: Maybe<Scalars['Int']>;
  archivedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  category: Category;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  dishUpSlipGroupShopPlans: Array<DishUpSlipGroupShopPlans>;
  displayType?: Maybe<DisplayTypeEnum>;
  endNotificationThresholdMinutes?: Maybe<Scalars['Int']>;
  featuredLabelText?: Maybe<Scalars['String']>;
  /** An object relationship */
  foodingJournalMenu?: Maybe<FoodingJournalMenu>;
  id: Scalars['uuid'];
  imageUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  orderableTime: OrderableTime;
  orderableTimeId: Scalars['uuid'];
  /** An array relationship */
  pikaichiMenuPlans: Array<PikaichiMenuPlan>;
  planCategoryId: Scalars['Int'];
  /** An array relationship */
  planFirstOrderCategoryMenus: Array<PlanFirstOrderCategoryMenu>;
  /** An object relationship */
  planGroup?: Maybe<PlanGroup>;
  planGroupId?: Maybe<Scalars['uuid']>;
  planId: Scalars['Int'];
  /** An array relationship */
  planMenuCategories: Array<PlanMenuCategory>;
  planName: Scalars['String'];
  /** An array relationship */
  planOptions: Array<PlanOption>;
  /** An aggregate relationship */
  planOptions_aggregate: PlanOptionAggregate;
  planTime?: Maybe<Scalars['Int']>;
  /** An array relationship */
  plan_menu_categories_categories: Array<PlanMenuCategoriesCategory>;
  priority: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
  serial: Scalars['Int'];
  /** An array relationship */
  shopPlanKitchenRoles: Array<ShopPlanKitchenRole>;
  /** An array relationship */
  shopPlans: Array<ShopPlan>;
  shopSideName?: Maybe<Scalars['String']>;
  taxMethod: TaxMethodEnum;
  taxRate: Scalars['numeric'];
  /** An object relationship */
  tecAggregationMenu?: Maybe<TecAggregationMenu>;
  /** A computed field, executes function "plan_totalDealingShopCount" */
  totalDealingShopCount?: Maybe<Scalars['Int']>;
  /** An array relationship */
  translations: Array<Translation>;
  /** An array relationship */
  winboardMenus: Array<WinboardMenu>;
};


/** columns and relationships of "plan" */
export type PlanDishUpSlipGroupShopPlansArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopPlansOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
};


/** columns and relationships of "plan" */
export type PlanPikaichiMenuPlansArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuPlanOrderBy>>;
  where?: InputMaybe<PikaichiMenuPlanBoolExp>;
};


/** columns and relationships of "plan" */
export type PlanPlanFirstOrderCategoryMenusArgs = {
  distinct_on?: InputMaybe<Array<PlanFirstOrderCategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanFirstOrderCategoryMenuOrderBy>>;
  where?: InputMaybe<PlanFirstOrderCategoryMenuBoolExp>;
};


/** columns and relationships of "plan" */
export type PlanPlanMenuCategoriesArgs = {
  distinct_on?: InputMaybe<Array<PlanMenuCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanMenuCategoryOrderBy>>;
  where?: InputMaybe<PlanMenuCategoryBoolExp>;
};


/** columns and relationships of "plan" */
export type PlanPlanOptionsArgs = {
  distinct_on?: InputMaybe<Array<PlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOptionOrderBy>>;
  where?: InputMaybe<PlanOptionBoolExp>;
};


/** columns and relationships of "plan" */
export type PlanPlanOptionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOptionOrderBy>>;
  where?: InputMaybe<PlanOptionBoolExp>;
};


/** columns and relationships of "plan" */
export type PlanPlanMenuCategoriesCategoriesArgs = {
  distinct_on?: InputMaybe<Array<PlanMenuCategoriesCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanMenuCategoriesCategoryOrderBy>>;
  where?: InputMaybe<PlanMenuCategoriesCategoryBoolExp>;
};


/** columns and relationships of "plan" */
export type PlanShopPlanKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
};


/** columns and relationships of "plan" */
export type PlanShopPlansArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOrderBy>>;
  where?: InputMaybe<ShopPlanBoolExp>;
};


/** columns and relationships of "plan" */
export type PlanTranslationsArgs = {
  distinct_on?: InputMaybe<Array<TranslationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TranslationOrderBy>>;
  where?: InputMaybe<TranslationBoolExp>;
};


/** columns and relationships of "plan" */
export type PlanWinboardMenusArgs = {
  distinct_on?: InputMaybe<Array<WinboardMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WinboardMenuOrderBy>>;
  where?: InputMaybe<WinboardMenuBoolExp>;
};

/** columns and relationships of "planChoice" */
export type PlanChoice = {
  __typename?: 'planChoice';
  _planOptionId: Scalars['Int'];
  archivedAt?: Maybe<Scalars['timestamptz']>;
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  countAsFirstOrderMenuQuantity: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  discount: Scalars['Int'];
  /** An object relationship */
  foodingJournalMenu?: Maybe<FoodingJournalMenu>;
  id: Scalars['uuid'];
  imageUrl?: Maybe<Scalars['String']>;
  isDefaultSelection: Scalars['Boolean'];
  maxOrderableNum?: Maybe<Scalars['Int']>;
  menuType?: Maybe<MenuTypeEnum>;
  name: Scalars['String'];
  /** An array relationship */
  pikaichiMenuPlanChoices: Array<PikaichiMenuPlanChoice>;
  planChoiceId: Scalars['Int'];
  /** An object relationship */
  planOption: PlanOption;
  planOptionId: Scalars['uuid'];
  posPlanChoiceId?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  priority: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
  serial: Scalars['Int'];
  /** An array relationship */
  shopPlanChoices: Array<ShopPlanChoice>;
  /** An object relationship */
  tecAggregationMenu?: Maybe<TecAggregationMenu>;
  /** An array relationship */
  translations: Array<Translation>;
  /** An array relationship */
  winboardMenus: Array<WinboardMenu>;
};


/** columns and relationships of "planChoice" */
export type PlanChoicePikaichiMenuPlanChoicesArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuPlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuPlanChoiceOrderBy>>;
  where?: InputMaybe<PikaichiMenuPlanChoiceBoolExp>;
};


/** columns and relationships of "planChoice" */
export type PlanChoiceShopPlanChoicesArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanChoiceOrderBy>>;
  where?: InputMaybe<ShopPlanChoiceBoolExp>;
};


/** columns and relationships of "planChoice" */
export type PlanChoiceTranslationsArgs = {
  distinct_on?: InputMaybe<Array<TranslationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TranslationOrderBy>>;
  where?: InputMaybe<TranslationBoolExp>;
};


/** columns and relationships of "planChoice" */
export type PlanChoiceWinboardMenusArgs = {
  distinct_on?: InputMaybe<Array<WinboardMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WinboardMenuOrderBy>>;
  where?: InputMaybe<WinboardMenuBoolExp>;
};

/** aggregated selection of "planChoice" */
export type PlanChoiceAggregate = {
  __typename?: 'planChoice_aggregate';
  aggregate?: Maybe<PlanChoiceAggregateFields>;
  nodes: Array<PlanChoice>;
};

export type PlanChoiceAggregateBoolExp = {
  bool_and?: InputMaybe<PlanChoiceAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<PlanChoiceAggregateBoolExpBoolOr>;
  count?: InputMaybe<PlanChoiceAggregateBoolExpCount>;
};

export type PlanChoiceAggregateBoolExpBoolAnd = {
  arguments: PlanChoiceSelectColumnPlanChoiceAggregateBoolExpBoolAndArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PlanChoiceBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PlanChoiceAggregateBoolExpBoolOr = {
  arguments: PlanChoiceSelectColumnPlanChoiceAggregateBoolExpBoolOrArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PlanChoiceBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PlanChoiceAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PlanChoiceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PlanChoiceBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "planChoice" */
export type PlanChoiceAggregateFields = {
  __typename?: 'planChoice_aggregate_fields';
  avg?: Maybe<PlanChoiceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PlanChoiceMaxFields>;
  min?: Maybe<PlanChoiceMinFields>;
  stddev?: Maybe<PlanChoiceStddevFields>;
  stddev_pop?: Maybe<PlanChoiceStddevPopFields>;
  stddev_samp?: Maybe<PlanChoiceStddevSampFields>;
  sum?: Maybe<PlanChoiceSumFields>;
  var_pop?: Maybe<PlanChoiceVarPopFields>;
  var_samp?: Maybe<PlanChoiceVarSampFields>;
  variance?: Maybe<PlanChoiceVarianceFields>;
};


/** aggregate fields of "planChoice" */
export type PlanChoiceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlanChoiceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "planChoice" */
export type PlanChoiceAggregateOrderBy = {
  avg?: InputMaybe<PlanChoiceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PlanChoiceMaxOrderBy>;
  min?: InputMaybe<PlanChoiceMinOrderBy>;
  stddev?: InputMaybe<PlanChoiceStddevOrderBy>;
  stddev_pop?: InputMaybe<PlanChoiceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PlanChoiceStddevSampOrderBy>;
  sum?: InputMaybe<PlanChoiceSumOrderBy>;
  var_pop?: InputMaybe<PlanChoiceVarPopOrderBy>;
  var_samp?: InputMaybe<PlanChoiceVarSampOrderBy>;
  variance?: InputMaybe<PlanChoiceVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "planChoice" */
export type PlanChoiceArrRelInsertInput = {
  data: Array<PlanChoiceInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanChoiceOnConflict>;
};

/** aggregate avg on columns */
export type PlanChoiceAvgFields = {
  __typename?: 'planChoice_avg_fields';
  _planOptionId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  planChoiceId?: Maybe<Scalars['Float']>;
  posPlanChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "planChoice" */
export type PlanChoiceAvgOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  posPlanChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "planChoice". All fields are combined with a logical 'AND'. */
export type PlanChoiceBoolExp = {
  _and?: InputMaybe<Array<PlanChoiceBoolExp>>;
  _not?: InputMaybe<PlanChoiceBoolExp>;
  _or?: InputMaybe<Array<PlanChoiceBoolExp>>;
  _planOptionId?: InputMaybe<IntComparisonExp>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  costPrice?: InputMaybe<NumericComparisonExp>;
  costTaxRate?: InputMaybe<NumericComparisonExp>;
  countAsFirstOrderMenuQuantity?: InputMaybe<BooleanComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  discount?: InputMaybe<IntComparisonExp>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  isDefaultSelection?: InputMaybe<BooleanComparisonExp>;
  maxOrderableNum?: InputMaybe<IntComparisonExp>;
  menuType?: InputMaybe<MenuTypeEnumComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  pikaichiMenuPlanChoices?: InputMaybe<PikaichiMenuPlanChoiceBoolExp>;
  planChoiceId?: InputMaybe<IntComparisonExp>;
  planOption?: InputMaybe<PlanOptionBoolExp>;
  planOptionId?: InputMaybe<UuidComparisonExp>;
  posPlanChoiceId?: InputMaybe<IntComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  receiptDisplayName?: InputMaybe<StringComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shopPlanChoices?: InputMaybe<ShopPlanChoiceBoolExp>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuBoolExp>;
  translations?: InputMaybe<TranslationBoolExp>;
  winboardMenus?: InputMaybe<WinboardMenuBoolExp>;
};

/** unique or primary key constraints on table "planChoice" */
export enum PlanChoiceConstraint {
  /** unique or primary key constraint on columns "serial" */
  Idx_46612Primary = 'idx_46612_PRIMARY',
  /** unique or primary key constraint on columns "id" */
  PlanChoiceIdKey = 'planChoice_id_key'
}

/** input type for incrementing numeric columns in table "planChoice" */
export type PlanChoiceIncInput = {
  _planOptionId?: InputMaybe<Scalars['Int']>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  discount?: InputMaybe<Scalars['Int']>;
  maxOrderableNum?: InputMaybe<Scalars['Int']>;
  posPlanChoiceId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "planChoice" */
export type PlanChoiceInsertInput = {
  _planOptionId: Scalars['Int'];
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  countAsFirstOrderMenuQuantity?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Int']>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuObjRelInsertInput>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDefaultSelection?: InputMaybe<Scalars['Boolean']>;
  maxOrderableNum?: InputMaybe<Scalars['Int']>;
  menuType?: InputMaybe<MenuTypeEnum>;
  name: Scalars['String'];
  pikaichiMenuPlanChoices?: InputMaybe<PikaichiMenuPlanChoiceArrRelInsertInput>;
  planOption?: InputMaybe<PlanOptionObjRelInsertInput>;
  planOptionId: Scalars['uuid'];
  posPlanChoiceId?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  priority: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
  serial?: InputMaybe<Scalars['Int']>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuObjRelInsertInput>;
  winboardMenus?: InputMaybe<WinboardMenuArrRelInsertInput>;
};

/** aggregate max on columns */
export type PlanChoiceMaxFields = {
  __typename?: 'planChoice_max_fields';
  _planOptionId?: Maybe<Scalars['Int']>;
  archivedAt?: Maybe<Scalars['timestamptz']>;
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  maxOrderableNum?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  planChoiceId?: Maybe<Scalars['Int']>;
  planOptionId?: Maybe<Scalars['uuid']>;
  posPlanChoiceId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  receiptDisplayName?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "planChoice" */
export type PlanChoiceMaxOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posPlanChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PlanChoiceMinFields = {
  __typename?: 'planChoice_min_fields';
  _planOptionId?: Maybe<Scalars['Int']>;
  archivedAt?: Maybe<Scalars['timestamptz']>;
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  imageUrl?: Maybe<Scalars['String']>;
  maxOrderableNum?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  planChoiceId?: Maybe<Scalars['Int']>;
  planOptionId?: Maybe<Scalars['uuid']>;
  posPlanChoiceId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  receiptDisplayName?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "planChoice" */
export type PlanChoiceMinOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posPlanChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "planChoice" */
export type PlanChoiceMutationResponse = {
  __typename?: 'planChoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PlanChoice>;
};

/** input type for inserting object relation for remote table "planChoice" */
export type PlanChoiceObjRelInsertInput = {
  data: PlanChoiceInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanChoiceOnConflict>;
};

/** on_conflict condition type for table "planChoice" */
export type PlanChoiceOnConflict = {
  constraint: PlanChoiceConstraint;
  update_columns?: Array<PlanChoiceUpdateColumn>;
  where?: InputMaybe<PlanChoiceBoolExp>;
};

/** Ordering options when selecting data from "planChoice". */
export type PlanChoiceOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  countAsFirstOrderMenuQuantity?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuOrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  isDefaultSelection?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  menuType?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  pikaichiMenuPlanChoices_aggregate?: InputMaybe<PikaichiMenuPlanChoiceAggregateOrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  planOption?: InputMaybe<PlanOptionOrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posPlanChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopPlanChoices_aggregate?: InputMaybe<ShopPlanChoiceAggregateOrderBy>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuOrderBy>;
  translations_aggregate?: InputMaybe<TranslationAggregateOrderBy>;
  winboardMenus_aggregate?: InputMaybe<WinboardMenuAggregateOrderBy>;
};

/** primary key columns input for table: planChoice */
export type PlanChoicePkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "planChoice" */
export enum PlanChoiceSelectColumn {
  /** column name */
  _PlanOptionId = '_planOptionId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CostTaxRate = 'costTaxRate',
  /** column name */
  CountAsFirstOrderMenuQuantity = 'countAsFirstOrderMenuQuantity',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  IsDefaultSelection = 'isDefaultSelection',
  /** column name */
  MaxOrderableNum = 'maxOrderableNum',
  /** column name */
  MenuType = 'menuType',
  /** column name */
  Name = 'name',
  /** column name */
  PlanChoiceId = 'planChoiceId',
  /** column name */
  PlanOptionId = 'planOptionId',
  /** column name */
  PosPlanChoiceId = 'posPlanChoiceId',
  /** column name */
  Price = 'price',
  /** column name */
  Priority = 'priority',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial'
}

/** select "planChoice_aggregate_bool_exp_bool_and_arguments_columns" columns of table "planChoice" */
export enum PlanChoiceSelectColumnPlanChoiceAggregateBoolExpBoolAndArgumentsColumnsType {
  /** column name */
  CountAsFirstOrderMenuQuantity = 'countAsFirstOrderMenuQuantity',
  /** column name */
  IsDefaultSelection = 'isDefaultSelection'
}

/** select "planChoice_aggregate_bool_exp_bool_or_arguments_columns" columns of table "planChoice" */
export enum PlanChoiceSelectColumnPlanChoiceAggregateBoolExpBoolOrArgumentsColumnsType {
  /** column name */
  CountAsFirstOrderMenuQuantity = 'countAsFirstOrderMenuQuantity',
  /** column name */
  IsDefaultSelection = 'isDefaultSelection'
}

/** input type for updating data in table "planChoice" */
export type PlanChoiceSetInput = {
  _planOptionId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  countAsFirstOrderMenuQuantity?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDefaultSelection?: InputMaybe<Scalars['Boolean']>;
  maxOrderableNum?: InputMaybe<Scalars['Int']>;
  menuType?: InputMaybe<MenuTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  planOptionId?: InputMaybe<Scalars['uuid']>;
  posPlanChoiceId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PlanChoiceStddevFields = {
  __typename?: 'planChoice_stddev_fields';
  _planOptionId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  planChoiceId?: Maybe<Scalars['Float']>;
  posPlanChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "planChoice" */
export type PlanChoiceStddevOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  posPlanChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type PlanChoiceStddevPopFields = {
  __typename?: 'planChoice_stddev_pop_fields';
  _planOptionId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  planChoiceId?: Maybe<Scalars['Float']>;
  posPlanChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "planChoice" */
export type PlanChoiceStddevPopOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  posPlanChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type PlanChoiceStddevSampFields = {
  __typename?: 'planChoice_stddev_samp_fields';
  _planOptionId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  planChoiceId?: Maybe<Scalars['Float']>;
  posPlanChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "planChoice" */
export type PlanChoiceStddevSampOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  posPlanChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "planChoice" */
export type PlanChoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PlanChoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PlanChoiceStreamCursorValueInput = {
  _planOptionId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  costPrice?: InputMaybe<Scalars['numeric']>;
  costTaxRate?: InputMaybe<Scalars['numeric']>;
  countAsFirstOrderMenuQuantity?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isDefaultSelection?: InputMaybe<Scalars['Boolean']>;
  maxOrderableNum?: InputMaybe<Scalars['Int']>;
  menuType?: InputMaybe<MenuTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  planChoiceId?: InputMaybe<Scalars['Int']>;
  planOptionId?: InputMaybe<Scalars['uuid']>;
  posPlanChoiceId?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type PlanChoiceSumFields = {
  __typename?: 'planChoice_sum_fields';
  _planOptionId?: Maybe<Scalars['Int']>;
  costPrice?: Maybe<Scalars['numeric']>;
  costTaxRate?: Maybe<Scalars['numeric']>;
  discount?: Maybe<Scalars['Int']>;
  maxOrderableNum?: Maybe<Scalars['Int']>;
  planChoiceId?: Maybe<Scalars['Int']>;
  posPlanChoiceId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "planChoice" */
export type PlanChoiceSumOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  posPlanChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "planChoice" */
export enum PlanChoiceUpdateColumn {
  /** column name */
  _PlanOptionId = '_planOptionId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CostPrice = 'costPrice',
  /** column name */
  CostTaxRate = 'costTaxRate',
  /** column name */
  CountAsFirstOrderMenuQuantity = 'countAsFirstOrderMenuQuantity',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  IsDefaultSelection = 'isDefaultSelection',
  /** column name */
  MaxOrderableNum = 'maxOrderableNum',
  /** column name */
  MenuType = 'menuType',
  /** column name */
  Name = 'name',
  /** column name */
  PlanOptionId = 'planOptionId',
  /** column name */
  PosPlanChoiceId = 'posPlanChoiceId',
  /** column name */
  Price = 'price',
  /** column name */
  Priority = 'priority',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial'
}

export type PlanChoiceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlanChoiceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlanChoiceSetInput>;
  /** filter the rows which have to be updated */
  where: PlanChoiceBoolExp;
};

/** aggregate var_pop on columns */
export type PlanChoiceVarPopFields = {
  __typename?: 'planChoice_var_pop_fields';
  _planOptionId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  planChoiceId?: Maybe<Scalars['Float']>;
  posPlanChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "planChoice" */
export type PlanChoiceVarPopOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  posPlanChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type PlanChoiceVarSampFields = {
  __typename?: 'planChoice_var_samp_fields';
  _planOptionId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  planChoiceId?: Maybe<Scalars['Float']>;
  posPlanChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "planChoice" */
export type PlanChoiceVarSampOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  posPlanChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PlanChoiceVarianceFields = {
  __typename?: 'planChoice_variance_fields';
  _planOptionId?: Maybe<Scalars['Float']>;
  costPrice?: Maybe<Scalars['Float']>;
  costTaxRate?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  maxOrderableNum?: Maybe<Scalars['Float']>;
  planChoiceId?: Maybe<Scalars['Float']>;
  posPlanChoiceId?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "planChoice" */
export type PlanChoiceVarianceOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  costPrice?: InputMaybe<OrderBy>;
  costTaxRate?: InputMaybe<OrderBy>;
  discount?: InputMaybe<OrderBy>;
  maxOrderableNum?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  posPlanChoiceId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** columns and relationships of "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenu = {
  __typename?: 'planFirstOrderCategoryMenu';
  _categoryMenuId: Scalars['Int'];
  _planId: Scalars['Int'];
  /** An object relationship */
  categoryMenu: CategoryMenu;
  categoryMenuId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  plan: Plan;
  planId: Scalars['uuid'];
  priority: Scalars['Int'];
};

/** order by aggregate values of table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuAggregateOrderBy = {
  avg?: InputMaybe<PlanFirstOrderCategoryMenuAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PlanFirstOrderCategoryMenuMaxOrderBy>;
  min?: InputMaybe<PlanFirstOrderCategoryMenuMinOrderBy>;
  stddev?: InputMaybe<PlanFirstOrderCategoryMenuStddevOrderBy>;
  stddev_pop?: InputMaybe<PlanFirstOrderCategoryMenuStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PlanFirstOrderCategoryMenuStddevSampOrderBy>;
  sum?: InputMaybe<PlanFirstOrderCategoryMenuSumOrderBy>;
  var_pop?: InputMaybe<PlanFirstOrderCategoryMenuVarPopOrderBy>;
  var_samp?: InputMaybe<PlanFirstOrderCategoryMenuVarSampOrderBy>;
  variance?: InputMaybe<PlanFirstOrderCategoryMenuVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuArrRelInsertInput = {
  data: Array<PlanFirstOrderCategoryMenuInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanFirstOrderCategoryMenuOnConflict>;
};

/** order by avg() on columns of table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuAvgOrderBy = {
  _categoryMenuId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "planFirstOrderCategoryMenu". All fields are combined with a logical 'AND'. */
export type PlanFirstOrderCategoryMenuBoolExp = {
  _and?: InputMaybe<Array<PlanFirstOrderCategoryMenuBoolExp>>;
  _categoryMenuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<PlanFirstOrderCategoryMenuBoolExp>;
  _or?: InputMaybe<Array<PlanFirstOrderCategoryMenuBoolExp>>;
  _planId?: InputMaybe<IntComparisonExp>;
  categoryMenu?: InputMaybe<CategoryMenuBoolExp>;
  categoryMenuId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  plan?: InputMaybe<PlanBoolExp>;
  planId?: InputMaybe<UuidComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "planFirstOrderCategoryMenu" */
export enum PlanFirstOrderCategoryMenuConstraint {
  /** unique or primary key constraint on columns "id" */
  PlanFirstOrderCategoryMenuPkey = 'planFirstOrderCategoryMenu_pkey',
  /** unique or primary key constraint on columns "_categoryMenuId", "_planId" */
  PlanFirstOrderCategoryMenuPlanIdCategoryMenuIdKey = 'planFirstOrderCategoryMenu_planId_categoryMenuId_key',
  /** unique or primary key constraint on columns "planId", "categoryMenuId" */
  PlanFirstOrderCategoryMenuPlanUuidCategoryMenuUuidKey = 'planFirstOrderCategoryMenu_planUuid_categoryMenuUuid_key'
}

/** input type for incrementing numeric columns in table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuIncInput = {
  _categoryMenuId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuInsertInput = {
  _categoryMenuId: Scalars['Int'];
  _planId: Scalars['Int'];
  categoryMenu?: InputMaybe<CategoryMenuObjRelInsertInput>;
  categoryMenuId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  plan?: InputMaybe<PlanObjRelInsertInput>;
  planId: Scalars['uuid'];
  priority?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuMaxOrderBy = {
  _categoryMenuId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuMinOrderBy = {
  _categoryMenuId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuMutationResponse = {
  __typename?: 'planFirstOrderCategoryMenu_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PlanFirstOrderCategoryMenu>;
};

/** on_conflict condition type for table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuOnConflict = {
  constraint: PlanFirstOrderCategoryMenuConstraint;
  update_columns?: Array<PlanFirstOrderCategoryMenuUpdateColumn>;
  where?: InputMaybe<PlanFirstOrderCategoryMenuBoolExp>;
};

/** Ordering options when selecting data from "planFirstOrderCategoryMenu". */
export type PlanFirstOrderCategoryMenuOrderBy = {
  _categoryMenuId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  categoryMenu?: InputMaybe<CategoryMenuOrderBy>;
  categoryMenuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlanOrderBy>;
  planId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: planFirstOrderCategoryMenu */
export type PlanFirstOrderCategoryMenuPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "planFirstOrderCategoryMenu" */
export enum PlanFirstOrderCategoryMenuSelectColumn {
  /** column name */
  _CategoryMenuId = '_categoryMenuId',
  /** column name */
  _PlanId = '_planId',
  /** column name */
  CategoryMenuId = 'categoryMenuId',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'planId',
  /** column name */
  Priority = 'priority'
}

/** input type for updating data in table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuSetInput = {
  _categoryMenuId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  categoryMenuId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuStddevOrderBy = {
  _categoryMenuId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuStddevPopOrderBy = {
  _categoryMenuId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuStddevSampOrderBy = {
  _categoryMenuId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PlanFirstOrderCategoryMenuStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PlanFirstOrderCategoryMenuStreamCursorValueInput = {
  _categoryMenuId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  categoryMenuId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuSumOrderBy = {
  _categoryMenuId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** update columns of table "planFirstOrderCategoryMenu" */
export enum PlanFirstOrderCategoryMenuUpdateColumn {
  /** column name */
  _CategoryMenuId = '_categoryMenuId',
  /** column name */
  _PlanId = '_planId',
  /** column name */
  CategoryMenuId = 'categoryMenuId',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'planId',
  /** column name */
  Priority = 'priority'
}

export type PlanFirstOrderCategoryMenuUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlanFirstOrderCategoryMenuIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlanFirstOrderCategoryMenuSetInput>;
  /** filter the rows which have to be updated */
  where: PlanFirstOrderCategoryMenuBoolExp;
};

/** order by var_pop() on columns of table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuVarPopOrderBy = {
  _categoryMenuId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuVarSampOrderBy = {
  _categoryMenuId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "planFirstOrderCategoryMenu" */
export type PlanFirstOrderCategoryMenuVarianceOrderBy = {
  _categoryMenuId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** columns and relationships of "planGroup" */
export type PlanGroup = {
  __typename?: 'planGroup';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  name: Scalars['String'];
  planGroupId: Scalars['Int'];
  /** An array relationship */
  plans: Array<Plan>;
  serial: Scalars['Int'];
};


/** columns and relationships of "planGroup" */
export type PlanGroupPlansArgs = {
  distinct_on?: InputMaybe<Array<PlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOrderBy>>;
  where?: InputMaybe<PlanBoolExp>;
};

/** order by aggregate values of table "planGroup" */
export type PlanGroupAggregateOrderBy = {
  avg?: InputMaybe<PlanGroupAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PlanGroupMaxOrderBy>;
  min?: InputMaybe<PlanGroupMinOrderBy>;
  stddev?: InputMaybe<PlanGroupStddevOrderBy>;
  stddev_pop?: InputMaybe<PlanGroupStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PlanGroupStddevSampOrderBy>;
  sum?: InputMaybe<PlanGroupSumOrderBy>;
  var_pop?: InputMaybe<PlanGroupVarPopOrderBy>;
  var_samp?: InputMaybe<PlanGroupVarSampOrderBy>;
  variance?: InputMaybe<PlanGroupVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "planGroup" */
export type PlanGroupArrRelInsertInput = {
  data: Array<PlanGroupInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanGroupOnConflict>;
};

/** order by avg() on columns of table "planGroup" */
export type PlanGroupAvgOrderBy = {
  planGroupId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "planGroup". All fields are combined with a logical 'AND'. */
export type PlanGroupBoolExp = {
  _and?: InputMaybe<Array<PlanGroupBoolExp>>;
  _not?: InputMaybe<PlanGroupBoolExp>;
  _or?: InputMaybe<Array<PlanGroupBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  planGroupId?: InputMaybe<IntComparisonExp>;
  plans?: InputMaybe<PlanBoolExp>;
  serial?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "planGroup" */
export enum PlanGroupConstraint {
  /** unique or primary key constraint on columns "serial" */
  Idx_46627Primary = 'idx_46627_PRIMARY',
  /** unique or primary key constraint on columns "id" */
  PlanGroupIdKey = 'planGroup_id_key'
}

/** input type for incrementing numeric columns in table "planGroup" */
export type PlanGroupIncInput = {
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "planGroup" */
export type PlanGroupInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  name: Scalars['String'];
  plans?: InputMaybe<PlanArrRelInsertInput>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "planGroup" */
export type PlanGroupMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planGroupId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "planGroup" */
export type PlanGroupMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planGroupId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "planGroup" */
export type PlanGroupMutationResponse = {
  __typename?: 'planGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PlanGroup>;
};

/** input type for inserting object relation for remote table "planGroup" */
export type PlanGroupObjRelInsertInput = {
  data: PlanGroupInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanGroupOnConflict>;
};

/** on_conflict condition type for table "planGroup" */
export type PlanGroupOnConflict = {
  constraint: PlanGroupConstraint;
  update_columns?: Array<PlanGroupUpdateColumn>;
  where?: InputMaybe<PlanGroupBoolExp>;
};

/** Ordering options when selecting data from "planGroup". */
export type PlanGroupOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planGroupId?: InputMaybe<OrderBy>;
  plans_aggregate?: InputMaybe<PlanAggregateOrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: planGroup */
export type PlanGroupPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "planGroup" */
export enum PlanGroupSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Name = 'name',
  /** column name */
  PlanGroupId = 'planGroupId',
  /** column name */
  Serial = 'serial'
}

/** input type for updating data in table "planGroup" */
export type PlanGroupSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "planGroup" */
export type PlanGroupStddevOrderBy = {
  planGroupId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "planGroup" */
export type PlanGroupStddevPopOrderBy = {
  planGroupId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "planGroup" */
export type PlanGroupStddevSampOrderBy = {
  planGroupId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "planGroup" */
export type PlanGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PlanGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PlanGroupStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  planGroupId?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "planGroup" */
export type PlanGroupSumOrderBy = {
  planGroupId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "planGroup" */
export enum PlanGroupUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Name = 'name',
  /** column name */
  Serial = 'serial'
}

export type PlanGroupUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlanGroupIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlanGroupSetInput>;
  /** filter the rows which have to be updated */
  where: PlanGroupBoolExp;
};

/** order by var_pop() on columns of table "planGroup" */
export type PlanGroupVarPopOrderBy = {
  planGroupId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "planGroup" */
export type PlanGroupVarSampOrderBy = {
  planGroupId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "planGroup" */
export type PlanGroupVarianceOrderBy = {
  planGroupId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** columns and relationships of "planMenuCategory" */
export type PlanMenuCategory = {
  __typename?: 'planMenuCategory';
  _categoryId: Scalars['Int'];
  _planId: Scalars['Int'];
  /** An object relationship */
  category: Category;
  categoryId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  plan: Plan;
  planId: Scalars['uuid'];
};

/** order by aggregate values of table "planMenuCategory" */
export type PlanMenuCategoryAggregateOrderBy = {
  avg?: InputMaybe<PlanMenuCategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PlanMenuCategoryMaxOrderBy>;
  min?: InputMaybe<PlanMenuCategoryMinOrderBy>;
  stddev?: InputMaybe<PlanMenuCategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<PlanMenuCategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PlanMenuCategoryStddevSampOrderBy>;
  sum?: InputMaybe<PlanMenuCategorySumOrderBy>;
  var_pop?: InputMaybe<PlanMenuCategoryVarPopOrderBy>;
  var_samp?: InputMaybe<PlanMenuCategoryVarSampOrderBy>;
  variance?: InputMaybe<PlanMenuCategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "planMenuCategory" */
export type PlanMenuCategoryArrRelInsertInput = {
  data: Array<PlanMenuCategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanMenuCategoryOnConflict>;
};

/** order by avg() on columns of table "planMenuCategory" */
export type PlanMenuCategoryAvgOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "planMenuCategory". All fields are combined with a logical 'AND'. */
export type PlanMenuCategoryBoolExp = {
  _and?: InputMaybe<Array<PlanMenuCategoryBoolExp>>;
  _categoryId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<PlanMenuCategoryBoolExp>;
  _or?: InputMaybe<Array<PlanMenuCategoryBoolExp>>;
  _planId?: InputMaybe<IntComparisonExp>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  plan?: InputMaybe<PlanBoolExp>;
  planId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "planMenuCategory" */
export enum PlanMenuCategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_46650Primary = 'idx_46650_PRIMARY',
  /** unique or primary key constraint on columns "_categoryId", "_planId" */
  PlanMenuCategoryPlanIdCategoryIdKey = 'planMenuCategory_planId_categoryId_key',
  /** unique or primary key constraint on columns "planId", "categoryId" */
  PlanMenuCategoryPlanUuidCategoryUuidKey = 'planMenuCategory_planUuid_categoryUuid_key'
}

/** input type for incrementing numeric columns in table "planMenuCategory" */
export type PlanMenuCategoryIncInput = {
  _categoryId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "planMenuCategory" */
export type PlanMenuCategoryInsertInput = {
  _categoryId: Scalars['Int'];
  _planId: Scalars['Int'];
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  plan?: InputMaybe<PlanObjRelInsertInput>;
  planId: Scalars['uuid'];
};

/** order by max() on columns of table "planMenuCategory" */
export type PlanMenuCategoryMaxOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "planMenuCategory" */
export type PlanMenuCategoryMinOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "planMenuCategory" */
export type PlanMenuCategoryMutationResponse = {
  __typename?: 'planMenuCategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PlanMenuCategory>;
};

/** on_conflict condition type for table "planMenuCategory" */
export type PlanMenuCategoryOnConflict = {
  constraint: PlanMenuCategoryConstraint;
  update_columns?: Array<PlanMenuCategoryUpdateColumn>;
  where?: InputMaybe<PlanMenuCategoryBoolExp>;
};

/** Ordering options when selecting data from "planMenuCategory". */
export type PlanMenuCategoryOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  category?: InputMaybe<CategoryOrderBy>;
  categoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlanOrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: planMenuCategory */
export type PlanMenuCategoryPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "planMenuCategory" */
export enum PlanMenuCategorySelectColumn {
  /** column name */
  _CategoryId = '_categoryId',
  /** column name */
  _PlanId = '_planId',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'planId'
}

/** input type for updating data in table "planMenuCategory" */
export type PlanMenuCategorySetInput = {
  _categoryId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "planMenuCategory" */
export type PlanMenuCategoryStddevOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "planMenuCategory" */
export type PlanMenuCategoryStddevPopOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "planMenuCategory" */
export type PlanMenuCategoryStddevSampOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "planMenuCategory" */
export type PlanMenuCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PlanMenuCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PlanMenuCategoryStreamCursorValueInput = {
  _categoryId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "planMenuCategory" */
export type PlanMenuCategorySumOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** update columns of table "planMenuCategory" */
export enum PlanMenuCategoryUpdateColumn {
  /** column name */
  _CategoryId = '_categoryId',
  /** column name */
  _PlanId = '_planId',
  /** column name */
  CategoryId = 'categoryId',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'planId'
}

export type PlanMenuCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlanMenuCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlanMenuCategorySetInput>;
  /** filter the rows which have to be updated */
  where: PlanMenuCategoryBoolExp;
};

/** order by var_pop() on columns of table "planMenuCategory" */
export type PlanMenuCategoryVarPopOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "planMenuCategory" */
export type PlanMenuCategoryVarSampOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "planMenuCategory" */
export type PlanMenuCategoryVarianceOrderBy = {
  _categoryId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "planOption" */
export type PlanOption = {
  __typename?: 'planOption';
  _planId: Scalars['Int'];
  archivedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  inputType: OptionInputTypeEnum;
  isMainOption: Scalars['Boolean'];
  maxChoiceNum?: Maybe<Scalars['Int']>;
  minChoiceNum: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  optionInputType: OptionInputType;
  /** An object relationship */
  plan: Plan;
  /** An array relationship */
  planChoices: Array<PlanChoice>;
  /** An aggregate relationship */
  planChoices_aggregate: PlanChoiceAggregate;
  planId: Scalars['uuid'];
  planOptionId: Scalars['Int'];
  posOptionId?: Maybe<Scalars['Int']>;
  priority: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
  serial: Scalars['Int'];
  /** An array relationship */
  shopPlanOptionKitchenRoles: Array<ShopPlanOptionKitchenRole>;
  /** An array relationship */
  shopPlanOptions: Array<ShopPlanOption>;
  /** An array relationship */
  translations: Array<Translation>;
  validateChoiceSelectionByNumPeople: Scalars['Boolean'];
};


/** columns and relationships of "planOption" */
export type PlanOptionPlanChoicesArgs = {
  distinct_on?: InputMaybe<Array<PlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanChoiceOrderBy>>;
  where?: InputMaybe<PlanChoiceBoolExp>;
};


/** columns and relationships of "planOption" */
export type PlanOptionPlanChoicesAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanChoiceOrderBy>>;
  where?: InputMaybe<PlanChoiceBoolExp>;
};


/** columns and relationships of "planOption" */
export type PlanOptionShopPlanOptionKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanOptionKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOptionKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopPlanOptionKitchenRoleBoolExp>;
};


/** columns and relationships of "planOption" */
export type PlanOptionShopPlanOptionsArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOptionOrderBy>>;
  where?: InputMaybe<ShopPlanOptionBoolExp>;
};


/** columns and relationships of "planOption" */
export type PlanOptionTranslationsArgs = {
  distinct_on?: InputMaybe<Array<TranslationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TranslationOrderBy>>;
  where?: InputMaybe<TranslationBoolExp>;
};

/** aggregated selection of "planOption" */
export type PlanOptionAggregate = {
  __typename?: 'planOption_aggregate';
  aggregate?: Maybe<PlanOptionAggregateFields>;
  nodes: Array<PlanOption>;
};

export type PlanOptionAggregateBoolExp = {
  bool_and?: InputMaybe<PlanOptionAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<PlanOptionAggregateBoolExpBoolOr>;
  count?: InputMaybe<PlanOptionAggregateBoolExpCount>;
};

export type PlanOptionAggregateBoolExpBoolAnd = {
  arguments: PlanOptionSelectColumnPlanOptionAggregateBoolExpBoolAndArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PlanOptionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PlanOptionAggregateBoolExpBoolOr = {
  arguments: PlanOptionSelectColumnPlanOptionAggregateBoolExpBoolOrArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PlanOptionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type PlanOptionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PlanOptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PlanOptionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "planOption" */
export type PlanOptionAggregateFields = {
  __typename?: 'planOption_aggregate_fields';
  avg?: Maybe<PlanOptionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PlanOptionMaxFields>;
  min?: Maybe<PlanOptionMinFields>;
  stddev?: Maybe<PlanOptionStddevFields>;
  stddev_pop?: Maybe<PlanOptionStddevPopFields>;
  stddev_samp?: Maybe<PlanOptionStddevSampFields>;
  sum?: Maybe<PlanOptionSumFields>;
  var_pop?: Maybe<PlanOptionVarPopFields>;
  var_samp?: Maybe<PlanOptionVarSampFields>;
  variance?: Maybe<PlanOptionVarianceFields>;
};


/** aggregate fields of "planOption" */
export type PlanOptionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlanOptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "planOption" */
export type PlanOptionAggregateOrderBy = {
  avg?: InputMaybe<PlanOptionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PlanOptionMaxOrderBy>;
  min?: InputMaybe<PlanOptionMinOrderBy>;
  stddev?: InputMaybe<PlanOptionStddevOrderBy>;
  stddev_pop?: InputMaybe<PlanOptionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PlanOptionStddevSampOrderBy>;
  sum?: InputMaybe<PlanOptionSumOrderBy>;
  var_pop?: InputMaybe<PlanOptionVarPopOrderBy>;
  var_samp?: InputMaybe<PlanOptionVarSampOrderBy>;
  variance?: InputMaybe<PlanOptionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "planOption" */
export type PlanOptionArrRelInsertInput = {
  data: Array<PlanOptionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanOptionOnConflict>;
};

/** aggregate avg on columns */
export type PlanOptionAvgFields = {
  __typename?: 'planOption_avg_fields';
  _planId?: Maybe<Scalars['Float']>;
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  planOptionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "planOption" */
export type PlanOptionAvgOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "planOption". All fields are combined with a logical 'AND'. */
export type PlanOptionBoolExp = {
  _and?: InputMaybe<Array<PlanOptionBoolExp>>;
  _not?: InputMaybe<PlanOptionBoolExp>;
  _or?: InputMaybe<Array<PlanOptionBoolExp>>;
  _planId?: InputMaybe<IntComparisonExp>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inputType?: InputMaybe<OptionInputTypeEnumComparisonExp>;
  isMainOption?: InputMaybe<BooleanComparisonExp>;
  maxChoiceNum?: InputMaybe<IntComparisonExp>;
  minChoiceNum?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  optionInputType?: InputMaybe<OptionInputTypeBoolExp>;
  plan?: InputMaybe<PlanBoolExp>;
  planChoices?: InputMaybe<PlanChoiceBoolExp>;
  planChoices_aggregate?: InputMaybe<PlanChoiceAggregateBoolExp>;
  planId?: InputMaybe<UuidComparisonExp>;
  planOptionId?: InputMaybe<IntComparisonExp>;
  posOptionId?: InputMaybe<IntComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  receiptDisplayName?: InputMaybe<StringComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shopPlanOptionKitchenRoles?: InputMaybe<ShopPlanOptionKitchenRoleBoolExp>;
  shopPlanOptions?: InputMaybe<ShopPlanOptionBoolExp>;
  translations?: InputMaybe<TranslationBoolExp>;
  validateChoiceSelectionByNumPeople?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "planOption" */
export enum PlanOptionConstraint {
  /** unique or primary key constraint on columns "serial" */
  Idx_46636Primary = 'idx_46636_PRIMARY',
  /** unique or primary key constraint on columns "id" */
  PlanOptionIdKey = 'planOption_id_key',
  /** unique or primary key constraint on columns "serial" */
  PlanOptionPlanOptionIdKey = 'planOption_planOptionId_key'
}

/** input type for incrementing numeric columns in table "planOption" */
export type PlanOptionIncInput = {
  _planId?: InputMaybe<Scalars['Int']>;
  maxChoiceNum?: InputMaybe<Scalars['Int']>;
  minChoiceNum?: InputMaybe<Scalars['Int']>;
  posOptionId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "planOption" */
export type PlanOptionInsertInput = {
  _planId: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  inputType?: InputMaybe<OptionInputTypeEnum>;
  isMainOption?: InputMaybe<Scalars['Boolean']>;
  maxChoiceNum?: InputMaybe<Scalars['Int']>;
  minChoiceNum?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  optionInputType?: InputMaybe<OptionInputTypeObjRelInsertInput>;
  plan?: InputMaybe<PlanObjRelInsertInput>;
  planChoices?: InputMaybe<PlanChoiceArrRelInsertInput>;
  planId: Scalars['uuid'];
  posOptionId?: InputMaybe<Scalars['Int']>;
  priority: Scalars['Int'];
  receiptDisplayName: Scalars['String'];
  serial?: InputMaybe<Scalars['Int']>;
  shopPlanOptionKitchenRoles?: InputMaybe<ShopPlanOptionKitchenRoleArrRelInsertInput>;
  shopPlanOptions?: InputMaybe<ShopPlanOptionArrRelInsertInput>;
  validateChoiceSelectionByNumPeople?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type PlanOptionMaxFields = {
  __typename?: 'planOption_max_fields';
  _planId?: Maybe<Scalars['Int']>;
  archivedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  maxChoiceNum?: Maybe<Scalars['Int']>;
  minChoiceNum?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['uuid']>;
  planOptionId?: Maybe<Scalars['Int']>;
  posOptionId?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  receiptDisplayName?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "planOption" */
export type PlanOptionMaxOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PlanOptionMinFields = {
  __typename?: 'planOption_min_fields';
  _planId?: Maybe<Scalars['Int']>;
  archivedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  maxChoiceNum?: Maybe<Scalars['Int']>;
  minChoiceNum?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['uuid']>;
  planOptionId?: Maybe<Scalars['Int']>;
  posOptionId?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  receiptDisplayName?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "planOption" */
export type PlanOptionMinOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "planOption" */
export type PlanOptionMutationResponse = {
  __typename?: 'planOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PlanOption>;
};

/** input type for inserting object relation for remote table "planOption" */
export type PlanOptionObjRelInsertInput = {
  data: PlanOptionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanOptionOnConflict>;
};

/** on_conflict condition type for table "planOption" */
export type PlanOptionOnConflict = {
  constraint: PlanOptionConstraint;
  update_columns?: Array<PlanOptionUpdateColumn>;
  where?: InputMaybe<PlanOptionBoolExp>;
};

/** Ordering options when selecting data from "planOption". */
export type PlanOptionOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inputType?: InputMaybe<OrderBy>;
  isMainOption?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  optionInputType?: InputMaybe<OptionInputTypeOrderBy>;
  plan?: InputMaybe<PlanOrderBy>;
  planChoices_aggregate?: InputMaybe<PlanChoiceAggregateOrderBy>;
  planId?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopPlanOptionKitchenRoles_aggregate?: InputMaybe<ShopPlanOptionKitchenRoleAggregateOrderBy>;
  shopPlanOptions_aggregate?: InputMaybe<ShopPlanOptionAggregateOrderBy>;
  translations_aggregate?: InputMaybe<TranslationAggregateOrderBy>;
  validateChoiceSelectionByNumPeople?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: planOption */
export type PlanOptionPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "planOption" */
export enum PlanOptionSelectColumn {
  /** column name */
  _PlanId = '_planId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InputType = 'inputType',
  /** column name */
  IsMainOption = 'isMainOption',
  /** column name */
  MaxChoiceNum = 'maxChoiceNum',
  /** column name */
  MinChoiceNum = 'minChoiceNum',
  /** column name */
  Name = 'name',
  /** column name */
  PlanId = 'planId',
  /** column name */
  PlanOptionId = 'planOptionId',
  /** column name */
  PosOptionId = 'posOptionId',
  /** column name */
  Priority = 'priority',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial',
  /** column name */
  ValidateChoiceSelectionByNumPeople = 'validateChoiceSelectionByNumPeople'
}

/** select "planOption_aggregate_bool_exp_bool_and_arguments_columns" columns of table "planOption" */
export enum PlanOptionSelectColumnPlanOptionAggregateBoolExpBoolAndArgumentsColumnsType {
  /** column name */
  IsMainOption = 'isMainOption',
  /** column name */
  ValidateChoiceSelectionByNumPeople = 'validateChoiceSelectionByNumPeople'
}

/** select "planOption_aggregate_bool_exp_bool_or_arguments_columns" columns of table "planOption" */
export enum PlanOptionSelectColumnPlanOptionAggregateBoolExpBoolOrArgumentsColumnsType {
  /** column name */
  IsMainOption = 'isMainOption',
  /** column name */
  ValidateChoiceSelectionByNumPeople = 'validateChoiceSelectionByNumPeople'
}

/** input type for updating data in table "planOption" */
export type PlanOptionSetInput = {
  _planId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  inputType?: InputMaybe<OptionInputTypeEnum>;
  isMainOption?: InputMaybe<Scalars['Boolean']>;
  maxChoiceNum?: InputMaybe<Scalars['Int']>;
  minChoiceNum?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['uuid']>;
  posOptionId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  validateChoiceSelectionByNumPeople?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type PlanOptionStddevFields = {
  __typename?: 'planOption_stddev_fields';
  _planId?: Maybe<Scalars['Float']>;
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  planOptionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "planOption" */
export type PlanOptionStddevOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type PlanOptionStddevPopFields = {
  __typename?: 'planOption_stddev_pop_fields';
  _planId?: Maybe<Scalars['Float']>;
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  planOptionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "planOption" */
export type PlanOptionStddevPopOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type PlanOptionStddevSampFields = {
  __typename?: 'planOption_stddev_samp_fields';
  _planId?: Maybe<Scalars['Float']>;
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  planOptionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "planOption" */
export type PlanOptionStddevSampOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "planOption" */
export type PlanOptionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PlanOptionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PlanOptionStreamCursorValueInput = {
  _planId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inputType?: InputMaybe<OptionInputTypeEnum>;
  isMainOption?: InputMaybe<Scalars['Boolean']>;
  maxChoiceNum?: InputMaybe<Scalars['Int']>;
  minChoiceNum?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['uuid']>;
  planOptionId?: InputMaybe<Scalars['Int']>;
  posOptionId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  validateChoiceSelectionByNumPeople?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type PlanOptionSumFields = {
  __typename?: 'planOption_sum_fields';
  _planId?: Maybe<Scalars['Int']>;
  maxChoiceNum?: Maybe<Scalars['Int']>;
  minChoiceNum?: Maybe<Scalars['Int']>;
  planOptionId?: Maybe<Scalars['Int']>;
  posOptionId?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "planOption" */
export type PlanOptionSumOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "planOption" */
export enum PlanOptionUpdateColumn {
  /** column name */
  _PlanId = '_planId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  Description = 'description',
  /** column name */
  InputType = 'inputType',
  /** column name */
  IsMainOption = 'isMainOption',
  /** column name */
  MaxChoiceNum = 'maxChoiceNum',
  /** column name */
  MinChoiceNum = 'minChoiceNum',
  /** column name */
  Name = 'name',
  /** column name */
  PlanId = 'planId',
  /** column name */
  PosOptionId = 'posOptionId',
  /** column name */
  Priority = 'priority',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial',
  /** column name */
  ValidateChoiceSelectionByNumPeople = 'validateChoiceSelectionByNumPeople'
}

export type PlanOptionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlanOptionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlanOptionSetInput>;
  /** filter the rows which have to be updated */
  where: PlanOptionBoolExp;
};

/** aggregate var_pop on columns */
export type PlanOptionVarPopFields = {
  __typename?: 'planOption_var_pop_fields';
  _planId?: Maybe<Scalars['Float']>;
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  planOptionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "planOption" */
export type PlanOptionVarPopOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type PlanOptionVarSampFields = {
  __typename?: 'planOption_var_samp_fields';
  _planId?: Maybe<Scalars['Float']>;
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  planOptionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "planOption" */
export type PlanOptionVarSampOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PlanOptionVarianceFields = {
  __typename?: 'planOption_variance_fields';
  _planId?: Maybe<Scalars['Float']>;
  maxChoiceNum?: Maybe<Scalars['Float']>;
  minChoiceNum?: Maybe<Scalars['Float']>;
  planOptionId?: Maybe<Scalars['Float']>;
  posOptionId?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "planOption" */
export type PlanOptionVarianceOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  maxChoiceNum?: InputMaybe<OrderBy>;
  minChoiceNum?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  posOptionId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "plan" */
export type PlanAggregateOrderBy = {
  avg?: InputMaybe<PlanAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PlanMaxOrderBy>;
  min?: InputMaybe<PlanMinOrderBy>;
  stddev?: InputMaybe<PlanStddevOrderBy>;
  stddev_pop?: InputMaybe<PlanStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PlanStddevSampOrderBy>;
  sum?: InputMaybe<PlanSumOrderBy>;
  var_pop?: InputMaybe<PlanVarPopOrderBy>;
  var_samp?: InputMaybe<PlanVarSampOrderBy>;
  variance?: InputMaybe<PlanVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "plan" */
export type PlanArrRelInsertInput = {
  data: Array<PlanInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanOnConflict>;
};

/** order by avg() on columns of table "plan" */
export type PlanAvgOrderBy = {
  _planGroupId?: InputMaybe<OrderBy>;
  endNotificationThresholdMinutes?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planTime?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "plan". All fields are combined with a logical 'AND'. */
export type PlanBoolExp = {
  _and?: InputMaybe<Array<PlanBoolExp>>;
  _not?: InputMaybe<PlanBoolExp>;
  _or?: InputMaybe<Array<PlanBoolExp>>;
  _planGroupId?: InputMaybe<IntComparisonExp>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  category?: InputMaybe<CategoryBoolExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  dishUpSlipGroupShopPlans?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
  displayType?: InputMaybe<DisplayTypeEnumComparisonExp>;
  endNotificationThresholdMinutes?: InputMaybe<IntComparisonExp>;
  featuredLabelText?: InputMaybe<StringComparisonExp>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  orderableTime?: InputMaybe<OrderableTimeBoolExp>;
  orderableTimeId?: InputMaybe<UuidComparisonExp>;
  pikaichiMenuPlans?: InputMaybe<PikaichiMenuPlanBoolExp>;
  planCategoryId?: InputMaybe<IntComparisonExp>;
  planFirstOrderCategoryMenus?: InputMaybe<PlanFirstOrderCategoryMenuBoolExp>;
  planGroup?: InputMaybe<PlanGroupBoolExp>;
  planGroupId?: InputMaybe<UuidComparisonExp>;
  planId?: InputMaybe<IntComparisonExp>;
  planMenuCategories?: InputMaybe<PlanMenuCategoryBoolExp>;
  planName?: InputMaybe<StringComparisonExp>;
  planOptions?: InputMaybe<PlanOptionBoolExp>;
  planOptions_aggregate?: InputMaybe<PlanOptionAggregateBoolExp>;
  planTime?: InputMaybe<IntComparisonExp>;
  plan_menu_categories_categories?: InputMaybe<PlanMenuCategoriesCategoryBoolExp>;
  priority?: InputMaybe<IntComparisonExp>;
  receiptDisplayName?: InputMaybe<StringComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shopPlanKitchenRoles?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
  shopPlans?: InputMaybe<ShopPlanBoolExp>;
  shopSideName?: InputMaybe<StringComparisonExp>;
  taxMethod?: InputMaybe<TaxMethodEnumComparisonExp>;
  taxRate?: InputMaybe<NumericComparisonExp>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuBoolExp>;
  totalDealingShopCount?: InputMaybe<IntComparisonExp>;
  translations?: InputMaybe<TranslationBoolExp>;
  winboardMenus?: InputMaybe<WinboardMenuBoolExp>;
};

/** unique or primary key constraints on table "plan" */
export enum PlanConstraint {
  /** unique or primary key constraint on columns "serial" */
  Idx_46598Primary = 'idx_46598_PRIMARY',
  /** unique or primary key constraint on columns "id" */
  PlanIdKey = 'plan_id_key'
}

/** input type for incrementing numeric columns in table "plan" */
export type PlanIncInput = {
  _planGroupId?: InputMaybe<Scalars['Int']>;
  endNotificationThresholdMinutes?: InputMaybe<Scalars['Int']>;
  planCategoryId?: InputMaybe<Scalars['Int']>;
  planTime?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
  taxRate?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "plan" */
export type PlanInsertInput = {
  _planGroupId?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<CategoryObjRelInsertInput>;
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  description?: InputMaybe<Scalars['String']>;
  dishUpSlipGroupShopPlans?: InputMaybe<DishUpSlipGroupShopPlansArrRelInsertInput>;
  displayType?: InputMaybe<DisplayTypeEnum>;
  endNotificationThresholdMinutes?: InputMaybe<Scalars['Int']>;
  featuredLabelText?: InputMaybe<Scalars['String']>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuObjRelInsertInput>;
  imageUrl?: InputMaybe<Scalars['String']>;
  orderableTime?: InputMaybe<OrderableTimeObjRelInsertInput>;
  orderableTimeId: Scalars['uuid'];
  pikaichiMenuPlans?: InputMaybe<PikaichiMenuPlanArrRelInsertInput>;
  planCategoryId: Scalars['Int'];
  planFirstOrderCategoryMenus?: InputMaybe<PlanFirstOrderCategoryMenuArrRelInsertInput>;
  planGroup?: InputMaybe<PlanGroupObjRelInsertInput>;
  planGroupId?: InputMaybe<Scalars['uuid']>;
  planMenuCategories?: InputMaybe<PlanMenuCategoryArrRelInsertInput>;
  planName: Scalars['String'];
  planOptions?: InputMaybe<PlanOptionArrRelInsertInput>;
  planTime?: InputMaybe<Scalars['Int']>;
  plan_menu_categories_categories?: InputMaybe<PlanMenuCategoriesCategoryArrRelInsertInput>;
  priority?: InputMaybe<Scalars['Int']>;
  receiptDisplayName: Scalars['String'];
  serial?: InputMaybe<Scalars['Int']>;
  shopPlanKitchenRoles?: InputMaybe<ShopPlanKitchenRoleArrRelInsertInput>;
  shopSideName?: InputMaybe<Scalars['String']>;
  taxMethod?: InputMaybe<TaxMethodEnum>;
  taxRate?: InputMaybe<Scalars['numeric']>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuObjRelInsertInput>;
  winboardMenus?: InputMaybe<WinboardMenuArrRelInsertInput>;
};

/** order by max() on columns of table "plan" */
export type PlanMaxOrderBy = {
  _planGroupId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endNotificationThresholdMinutes?: InputMaybe<OrderBy>;
  featuredLabelText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  orderableTimeId?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planGroupId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planName?: InputMaybe<OrderBy>;
  planTime?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopSideName?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** columns and relationships of "plan_menu_categories_category" */
export type PlanMenuCategoriesCategory = {
  __typename?: 'plan_menu_categories_category';
  /** An object relationship */
  category?: Maybe<Category>;
  categoryCategoryId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  plan?: Maybe<Plan>;
  planPlanId?: Maybe<Scalars['Int']>;
};

/** order by aggregate values of table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryAggregateOrderBy = {
  avg?: InputMaybe<PlanMenuCategoriesCategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PlanMenuCategoriesCategoryMaxOrderBy>;
  min?: InputMaybe<PlanMenuCategoriesCategoryMinOrderBy>;
  stddev?: InputMaybe<PlanMenuCategoriesCategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<PlanMenuCategoriesCategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PlanMenuCategoriesCategoryStddevSampOrderBy>;
  sum?: InputMaybe<PlanMenuCategoriesCategorySumOrderBy>;
  var_pop?: InputMaybe<PlanMenuCategoriesCategoryVarPopOrderBy>;
  var_samp?: InputMaybe<PlanMenuCategoriesCategoryVarSampOrderBy>;
  variance?: InputMaybe<PlanMenuCategoriesCategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryArrRelInsertInput = {
  data: Array<PlanMenuCategoriesCategoryInsertInput>;
};

/** order by avg() on columns of table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryAvgOrderBy = {
  categoryCategoryId?: InputMaybe<OrderBy>;
  planPlanId?: InputMaybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "plan_menu_categories_category". All fields are combined with a logical 'AND'.
 */
export type PlanMenuCategoriesCategoryBoolExp = {
  _and?: InputMaybe<Array<PlanMenuCategoriesCategoryBoolExp>>;
  _not?: InputMaybe<PlanMenuCategoriesCategoryBoolExp>;
  _or?: InputMaybe<Array<PlanMenuCategoriesCategoryBoolExp>>;
  category?: InputMaybe<CategoryBoolExp>;
  categoryCategoryId?: InputMaybe<IntComparisonExp>;
  plan?: InputMaybe<PlanBoolExp>;
  planPlanId?: InputMaybe<IntComparisonExp>;
};

/** input type for incrementing numeric columns in table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryIncInput = {
  categoryCategoryId?: InputMaybe<Scalars['Int']>;
  planPlanId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryInsertInput = {
  category?: InputMaybe<CategoryObjRelInsertInput>;
  categoryCategoryId?: InputMaybe<Scalars['Int']>;
  plan?: InputMaybe<PlanObjRelInsertInput>;
  planPlanId?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryMaxOrderBy = {
  categoryCategoryId?: InputMaybe<OrderBy>;
  planPlanId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryMinOrderBy = {
  categoryCategoryId?: InputMaybe<OrderBy>;
  planPlanId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryMutationResponse = {
  __typename?: 'plan_menu_categories_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PlanMenuCategoriesCategory>;
};

/** Ordering options when selecting data from "plan_menu_categories_category". */
export type PlanMenuCategoriesCategoryOrderBy = {
  category?: InputMaybe<CategoryOrderBy>;
  categoryCategoryId?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlanOrderBy>;
  planPlanId?: InputMaybe<OrderBy>;
};

/** select columns of table "plan_menu_categories_category" */
export enum PlanMenuCategoriesCategorySelectColumn {
  /** column name */
  CategoryCategoryId = 'categoryCategoryId',
  /** column name */
  PlanPlanId = 'planPlanId'
}

/** input type for updating data in table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategorySetInput = {
  categoryCategoryId?: InputMaybe<Scalars['Int']>;
  planPlanId?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryStddevOrderBy = {
  categoryCategoryId?: InputMaybe<OrderBy>;
  planPlanId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryStddevPopOrderBy = {
  categoryCategoryId?: InputMaybe<OrderBy>;
  planPlanId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryStddevSampOrderBy = {
  categoryCategoryId?: InputMaybe<OrderBy>;
  planPlanId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PlanMenuCategoriesCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PlanMenuCategoriesCategoryStreamCursorValueInput = {
  categoryCategoryId?: InputMaybe<Scalars['Int']>;
  planPlanId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategorySumOrderBy = {
  categoryCategoryId?: InputMaybe<OrderBy>;
  planPlanId?: InputMaybe<OrderBy>;
};

export type PlanMenuCategoriesCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlanMenuCategoriesCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlanMenuCategoriesCategorySetInput>;
  /** filter the rows which have to be updated */
  where: PlanMenuCategoriesCategoryBoolExp;
};

/** order by var_pop() on columns of table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryVarPopOrderBy = {
  categoryCategoryId?: InputMaybe<OrderBy>;
  planPlanId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryVarSampOrderBy = {
  categoryCategoryId?: InputMaybe<OrderBy>;
  planPlanId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "plan_menu_categories_category" */
export type PlanMenuCategoriesCategoryVarianceOrderBy = {
  categoryCategoryId?: InputMaybe<OrderBy>;
  planPlanId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "plan" */
export type PlanMinOrderBy = {
  _planGroupId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  endNotificationThresholdMinutes?: InputMaybe<OrderBy>;
  featuredLabelText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  orderableTimeId?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planGroupId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planName?: InputMaybe<OrderBy>;
  planTime?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopSideName?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "plan" */
export type PlanMutationResponse = {
  __typename?: 'plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plan>;
};

/** input type for inserting object relation for remote table "plan" */
export type PlanObjRelInsertInput = {
  data: PlanInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PlanOnConflict>;
};

/** on_conflict condition type for table "plan" */
export type PlanOnConflict = {
  constraint: PlanConstraint;
  update_columns?: Array<PlanUpdateColumn>;
  where?: InputMaybe<PlanBoolExp>;
};

/** Ordering options when selecting data from "plan". */
export type PlanOrderBy = {
  _planGroupId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  category?: InputMaybe<CategoryOrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  dishUpSlipGroupShopPlans_aggregate?: InputMaybe<DishUpSlipGroupShopPlansAggregateOrderBy>;
  displayType?: InputMaybe<OrderBy>;
  endNotificationThresholdMinutes?: InputMaybe<OrderBy>;
  featuredLabelText?: InputMaybe<OrderBy>;
  foodingJournalMenu?: InputMaybe<FoodingJournalMenuOrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  orderableTime?: InputMaybe<OrderableTimeOrderBy>;
  orderableTimeId?: InputMaybe<OrderBy>;
  pikaichiMenuPlans_aggregate?: InputMaybe<PikaichiMenuPlanAggregateOrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planFirstOrderCategoryMenus_aggregate?: InputMaybe<PlanFirstOrderCategoryMenuAggregateOrderBy>;
  planGroup?: InputMaybe<PlanGroupOrderBy>;
  planGroupId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planMenuCategories_aggregate?: InputMaybe<PlanMenuCategoryAggregateOrderBy>;
  planName?: InputMaybe<OrderBy>;
  planOptions_aggregate?: InputMaybe<PlanOptionAggregateOrderBy>;
  planTime?: InputMaybe<OrderBy>;
  plan_menu_categories_categories_aggregate?: InputMaybe<PlanMenuCategoriesCategoryAggregateOrderBy>;
  priority?: InputMaybe<OrderBy>;
  receiptDisplayName?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopPlanKitchenRoles_aggregate?: InputMaybe<ShopPlanKitchenRoleAggregateOrderBy>;
  shopPlans_aggregate?: InputMaybe<ShopPlanAggregateOrderBy>;
  shopSideName?: InputMaybe<OrderBy>;
  taxMethod?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
  tecAggregationMenu?: InputMaybe<TecAggregationMenuOrderBy>;
  totalDealingShopCount?: InputMaybe<OrderBy>;
  translations_aggregate?: InputMaybe<TranslationAggregateOrderBy>;
  winboardMenus_aggregate?: InputMaybe<WinboardMenuAggregateOrderBy>;
};

/** primary key columns input for table: plan */
export type PlanPkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "plan" */
export enum PlanSelectColumn {
  /** column name */
  _PlanGroupId = '_planGroupId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayType = 'displayType',
  /** column name */
  EndNotificationThresholdMinutes = 'endNotificationThresholdMinutes',
  /** column name */
  FeaturedLabelText = 'featuredLabelText',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  OrderableTimeId = 'orderableTimeId',
  /** column name */
  PlanCategoryId = 'planCategoryId',
  /** column name */
  PlanGroupId = 'planGroupId',
  /** column name */
  PlanId = 'planId',
  /** column name */
  PlanName = 'planName',
  /** column name */
  PlanTime = 'planTime',
  /** column name */
  Priority = 'priority',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopSideName = 'shopSideName',
  /** column name */
  TaxMethod = 'taxMethod',
  /** column name */
  TaxRate = 'taxRate'
}

/** input type for updating data in table "plan" */
export type PlanSetInput = {
  _planGroupId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  displayType?: InputMaybe<DisplayTypeEnum>;
  endNotificationThresholdMinutes?: InputMaybe<Scalars['Int']>;
  featuredLabelText?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  orderableTimeId?: InputMaybe<Scalars['uuid']>;
  planCategoryId?: InputMaybe<Scalars['Int']>;
  planGroupId?: InputMaybe<Scalars['uuid']>;
  planName?: InputMaybe<Scalars['String']>;
  planTime?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopSideName?: InputMaybe<Scalars['String']>;
  taxMethod?: InputMaybe<TaxMethodEnum>;
  taxRate?: InputMaybe<Scalars['numeric']>;
};

/** order by stddev() on columns of table "plan" */
export type PlanStddevOrderBy = {
  _planGroupId?: InputMaybe<OrderBy>;
  endNotificationThresholdMinutes?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planTime?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "plan" */
export type PlanStddevPopOrderBy = {
  _planGroupId?: InputMaybe<OrderBy>;
  endNotificationThresholdMinutes?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planTime?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "plan" */
export type PlanStddevSampOrderBy = {
  _planGroupId?: InputMaybe<OrderBy>;
  endNotificationThresholdMinutes?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planTime?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "plan" */
export type PlanStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PlanStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type PlanStreamCursorValueInput = {
  _planGroupId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  displayType?: InputMaybe<DisplayTypeEnum>;
  endNotificationThresholdMinutes?: InputMaybe<Scalars['Int']>;
  featuredLabelText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  orderableTimeId?: InputMaybe<Scalars['uuid']>;
  planCategoryId?: InputMaybe<Scalars['Int']>;
  planGroupId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['Int']>;
  planName?: InputMaybe<Scalars['String']>;
  planTime?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  receiptDisplayName?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopSideName?: InputMaybe<Scalars['String']>;
  taxMethod?: InputMaybe<TaxMethodEnum>;
  taxRate?: InputMaybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "plan" */
export type PlanSumOrderBy = {
  _planGroupId?: InputMaybe<OrderBy>;
  endNotificationThresholdMinutes?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planTime?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** update columns of table "plan" */
export enum PlanUpdateColumn {
  /** column name */
  _PlanGroupId = '_planGroupId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayType = 'displayType',
  /** column name */
  EndNotificationThresholdMinutes = 'endNotificationThresholdMinutes',
  /** column name */
  FeaturedLabelText = 'featuredLabelText',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  OrderableTimeId = 'orderableTimeId',
  /** column name */
  PlanCategoryId = 'planCategoryId',
  /** column name */
  PlanGroupId = 'planGroupId',
  /** column name */
  PlanName = 'planName',
  /** column name */
  PlanTime = 'planTime',
  /** column name */
  Priority = 'priority',
  /** column name */
  ReceiptDisplayName = 'receiptDisplayName',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopSideName = 'shopSideName',
  /** column name */
  TaxMethod = 'taxMethod',
  /** column name */
  TaxRate = 'taxRate'
}

export type PlanUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlanIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlanSetInput>;
  /** filter the rows which have to be updated */
  where: PlanBoolExp;
};

/** order by var_pop() on columns of table "plan" */
export type PlanVarPopOrderBy = {
  _planGroupId?: InputMaybe<OrderBy>;
  endNotificationThresholdMinutes?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planTime?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "plan" */
export type PlanVarSampOrderBy = {
  _planGroupId?: InputMaybe<OrderBy>;
  endNotificationThresholdMinutes?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planTime?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "plan" */
export type PlanVarianceOrderBy = {
  _planGroupId?: InputMaybe<OrderBy>;
  endNotificationThresholdMinutes?: InputMaybe<OrderBy>;
  planCategoryId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  planTime?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  taxRate?: InputMaybe<OrderBy>;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "accountingSlipImage" */
  accountingSlipImage: Array<AccountingSlipImage>;
  /** fetch data from the table: "accountingSlipImage" using primary key columns */
  accountingSlipImage_by_pk?: Maybe<AccountingSlipImage>;
  /** fetch data from the table: "activePlan" */
  activePlan: Array<ActivePlan>;
  /** fetch data from the table: "activePlanChoice" */
  activePlanChoice: Array<ActivePlanChoice>;
  /** fetch data from the table: "activePlanChoice" using primary key columns */
  activePlanChoice_by_pk?: Maybe<ActivePlanChoice>;
  /** fetch data from the table: "activePlanOpenPriceMeta" */
  activePlanOpenPriceMeta: Array<ActivePlanOpenPriceMeta>;
  /** fetch data from the table: "activePlanOpenPriceMeta" using primary key columns */
  activePlanOpenPriceMeta_by_pk?: Maybe<ActivePlanOpenPriceMeta>;
  /** fetch data from the table: "activePlanOption" */
  activePlanOption: Array<ActivePlanOption>;
  /** fetch data from the table: "activePlanOption" using primary key columns */
  activePlanOption_by_pk?: Maybe<ActivePlanOption>;
  /** fetch data from the table: "activePlan" using primary key columns */
  activePlan_by_pk?: Maybe<ActivePlan>;
  /** fetch data from the table: "adyenPaymentReportTerminalPaymentAuthorizedEvent" */
  adyenPaymentReportTerminalPaymentAuthorizedEvent: Array<AdyenPaymentReportTerminalPaymentAuthorizedEvent>;
  /** fetch data from the table: "adyenPaymentReportTerminalPaymentAuthorizedEvent" using primary key columns */
  adyenPaymentReportTerminalPaymentAuthorizedEvent_by_pk?: Maybe<AdyenPaymentReportTerminalPaymentAuthorizedEvent>;
  /** fetch data from the table: "adyenPaymentReportTerminalPaymentCanceledEvent" */
  adyenPaymentReportTerminalPaymentCanceledEvent: Array<AdyenPaymentReportTerminalPaymentCanceledEvent>;
  /** fetch data from the table: "adyenPaymentReportTerminalPaymentCanceledEvent" using primary key columns */
  adyenPaymentReportTerminalPaymentCanceledEvent_by_pk?: Maybe<AdyenPaymentReportTerminalPaymentCanceledEvent>;
  /** fetch data from the table: "adyenPaymentShopConfig" */
  adyenPaymentShopConfig: Array<AdyenPaymentShopConfig>;
  /** fetch data from the table: "adyenPaymentShopConfig" using primary key columns */
  adyenPaymentShopConfig_by_pk?: Maybe<AdyenPaymentShopConfig>;
  /** fetch data from the table: "adyenPaymentShopPaymentMethod" */
  adyenPaymentShopPaymentMethod: Array<AdyenPaymentShopPaymentMethod>;
  /** fetch data from the table: "adyenPaymentShopPaymentMethod" using primary key columns */
  adyenPaymentShopPaymentMethod_by_pk?: Maybe<AdyenPaymentShopPaymentMethod>;
  /** fetch data from the table: "adyenPaymentStoreMeta" */
  adyenPaymentStoreMeta: Array<AdyenPaymentStoreMeta>;
  /** fetch data from the table: "adyenPaymentStoreMeta" using primary key columns */
  adyenPaymentStoreMeta_by_pk?: Maybe<AdyenPaymentStoreMeta>;
  /** fetch data from the table: "adyenTerminalPaymentShopConfig" */
  adyenTerminalPaymentShopConfig: Array<AdyenTerminalPaymentShopConfig>;
  /** fetch data from the table: "adyenTerminalPaymentShopConfig" using primary key columns */
  adyenTerminalPaymentShopConfig_by_pk?: Maybe<AdyenTerminalPaymentShopConfig>;
  /** fetch data from the table: "adyenTerminalPaymentShopPaymentMethod" */
  adyenTerminalPaymentShopPaymentMethod: Array<AdyenTerminalPaymentShopPaymentMethod>;
  /** fetch data from the table: "adyenTerminalPaymentShopPaymentMethod" using primary key columns */
  adyenTerminalPaymentShopPaymentMethod_by_pk?: Maybe<AdyenTerminalPaymentShopPaymentMethod>;
  /** fetch data from the table: "analyticsSetting" */
  analyticsSetting: Array<AnalyticsSetting>;
  /** fetch data from the table: "analyticsSetting" using primary key columns */
  analyticsSetting_by_pk?: Maybe<AnalyticsSetting>;
  /** fetch data from the table: "birthdayTypeQuestionAnswer" */
  birthdayTypeQuestionAnswer: Array<BirthdayTypeQuestionAnswer>;
  cashBalance: Array<CashBalanceCashDenominationCount>;
  cashManagement: Array<CashManagement>;
  /** fetch data from the table: "cashRegisterConfig" */
  cashRegisterConfig: Array<CashRegisterConfig>;
  /** fetch aggregated fields from the table: "cashRegisterConfig" */
  cashRegisterConfig_aggregate: CashRegisterConfigAggregate;
  /** fetch data from the table: "cashRegisterConfig" using primary key columns */
  cashRegisterConfig_by_pk?: Maybe<CashRegisterConfig>;
  /** fetch data from the table: "cashRegisterSecurityLevel" */
  cashRegisterSecurityLevel: Array<CashRegisterSecurityLevel>;
  /** fetch data from the table: "cashRegisterSecurityLevel" using primary key columns */
  cashRegisterSecurityLevel_by_pk?: Maybe<CashRegisterSecurityLevel>;
  /** fetch data from the table: "category" */
  category: Array<Category>;
  /** fetch data from the table: "categoryMenu" */
  categoryMenu: Array<CategoryMenu>;
  /** fetch aggregated fields from the table: "categoryMenu" */
  categoryMenu_aggregate: CategoryMenuAggregate;
  /** fetch data from the table: "categoryMenu" using primary key columns */
  categoryMenu_by_pk?: Maybe<CategoryMenu>;
  /** fetch aggregated fields from the table: "category" */
  category_aggregate: CategoryAggregate;
  /** fetch data from the table: "category" using primary key columns */
  category_by_pk?: Maybe<Category>;
  /** fetch data from the table: "checkInEvent" */
  checkInEvent: Array<CheckInEvent>;
  /** fetch data from the table: "choice" */
  choice: Array<Choice>;
  /** fetch aggregated fields from the table: "choice" */
  choice_aggregate: ChoiceAggregate;
  /** fetch data from the table: "choice" using primary key columns */
  choice_by_pk?: Maybe<Choice>;
  /** fetch data from the table: "clerk" */
  clerk: Array<Clerk>;
  /** fetch data from the table: "clerk" using primary key columns */
  clerk_by_pk?: Maybe<Clerk>;
  /** fetch data from the table: "closeCashRegister" */
  closeCashRegister: Array<CloseCashRegister>;
  /** fetch data from the table: "closeCashRegister" using primary key columns */
  closeCashRegister_by_pk?: Maybe<CloseCashRegister>;
  /** fetch data from the table: "coineyPayment" */
  coineyPayment: Array<CoineyPayment>;
  /** fetch data from the table: "coineyPaymentOrder" */
  coineyPaymentOrder: Array<CoineyPaymentOrder>;
  /** fetch data from the table: "coineyPaymentOrder" using primary key columns */
  coineyPaymentOrder_by_pk?: Maybe<CoineyPaymentOrder>;
  /** fetch data from the table: "coineyPaymentSales" */
  coineyPaymentSales: Array<CoineyPaymentSales>;
  /** fetch data from the table: "coineyPaymentSales" using primary key columns */
  coineyPaymentSales_by_pk?: Maybe<CoineyPaymentSales>;
  /** fetch data from the table: "coineyPayment" using primary key columns */
  coineyPayment_by_pk?: Maybe<CoineyPayment>;
  /** fetch data from the table: "coineySettlement" */
  coineySettlement: Array<CoineySettlement>;
  /** fetch data from the table: "coineySettlement" using primary key columns */
  coineySettlement_by_pk?: Maybe<CoineySettlement>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch data from the table: "companyMember" */
  companyMember: Array<CompanyMember>;
  /** fetch aggregated fields from the table: "companyMember" */
  companyMember_aggregate: CompanyMemberAggregate;
  /** fetch data from the table: "companyMember" using primary key columns */
  companyMember_by_pk?: Maybe<CompanyMember>;
  companySalesAnalytics: CompanySalesAnalyticsOutput;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "compulsoryAppetizerConfig" */
  compulsoryAppetizerConfig: Array<CompulsoryAppetizerConfig>;
  /** fetch data from the table: "compulsoryAppetizerConfig" using primary key columns */
  compulsoryAppetizerConfig_by_pk?: Maybe<CompulsoryAppetizerConfig>;
  /** fetch data from the table: "connectGameConfig" */
  connectGameConfig: Array<ConnectGameConfig>;
  /** fetch data from the table: "connectGameConfigShop" */
  connectGameConfigShop: Array<ConnectGameConfigShop>;
  /** fetch data from the table: "connectGameConfigShop" using primary key columns */
  connectGameConfigShop_by_pk?: Maybe<ConnectGameConfigShop>;
  /** fetch data from the table: "connectGameConfig" using primary key columns */
  connectGameConfig_by_pk?: Maybe<ConnectGameConfig>;
  /** fetch data from the table: "cookingItem" */
  cookingItem: Array<CookingItem>;
  /** fetch data from the table: "cookingItem" using primary key columns */
  cookingItem_by_pk?: Maybe<CookingItem>;
  /** fetch data from the table: "corporation" */
  corporation: Array<Corporation>;
  /** fetch data from the table: "corporation" using primary key columns */
  corporation_by_pk?: Maybe<Corporation>;
  /** fetch data from the table: "coupon" */
  coupon: Array<Coupon>;
  couponAnalyticsPerCoupon: CouponAnalyticsPerCoupon;
  couponAnalyticsSummary: CouponAnalyticsSummary;
  /** fetch data from the table: "couponAppliedEvent" */
  couponAppliedEvent: Array<CouponAppliedEvent>;
  /** fetch aggregated fields from the table: "couponAppliedEvent" */
  couponAppliedEvent_aggregate: CouponAppliedEventAggregate;
  /** fetch data from the table: "couponAppliedEvent" using primary key columns */
  couponAppliedEvent_by_pk?: Maybe<CouponAppliedEvent>;
  /** fetch data from the table: "couponMenuDiscount" */
  couponMenuDiscount: Array<CouponMenuDiscount>;
  /** fetch data from the table: "couponMenuDiscount" using primary key columns */
  couponMenuDiscount_by_pk?: Maybe<CouponMenuDiscount>;
  /** fetch data from the table: "coupon" using primary key columns */
  coupon_by_pk?: Maybe<Coupon>;
  cumulativeCount: CumulativeCount;
  /** fetch data from the table: "customer" */
  customer: Array<Customer>;
  customerDashboardKpis: CustomerDashboardKpis;
  customerDashboardTransitions: CustomerDashboardTransitions;
  customerFaveYellAmountRanking: CustomerFaveYellRanking;
  customerFaveYellCountRanking: CustomerFaveYellRanking;
  /** fetch data from the table: "customerQuestionnaire" */
  customerQuestionnaire: Array<CustomerQuestionnaire>;
  /** fetch data from the table: "customerQuestionnaire" using primary key columns */
  customerQuestionnaire_by_pk?: Maybe<CustomerQuestionnaire>;
  customerViralCountRanking: CustomerRanking;
  customerVisitedCountRanking: CustomerRanking;
  /** fetch data from the table: "customer" using primary key columns */
  customer_by_pk?: Maybe<Customer>;
  dailyCashRegisterBalancing: DailyCashRegisterBalancing;
  /** fetch data from the table: "dailySalesBudget" */
  dailySalesBudget: Array<DailySalesBudget>;
  /** fetch data from the table: "dailySalesBudget" using primary key columns */
  dailySalesBudget_by_pk?: Maybe<DailySalesBudget>;
  dailySalesSummary: Array<DailySalesSummary>;
  /** fetch data from the table: "dashboardAccount" */
  dashboardAccount: Array<DashboardAccount>;
  /** fetch data from the table: "dashboardAccountAccessibleCompany" */
  dashboardAccountAccessibleCompany: Array<DashboardAccountAccessibleCompany>;
  /** fetch data from the table: "dashboardAccountAccessibleCompany" using primary key columns */
  dashboardAccountAccessibleCompany_by_pk?: Maybe<DashboardAccountAccessibleCompany>;
  /** fetch data from the table: "dashboardAccountAccessibleShop" */
  dashboardAccountAccessibleShop: Array<DashboardAccountAccessibleShop>;
  /** fetch data from the table: "dashboardAccountAccessibleShop" using primary key columns */
  dashboardAccountAccessibleShop_by_pk?: Maybe<DashboardAccountAccessibleShop>;
  dashboardAccountGetMessageDeliveryConsumption: MessageDeliveryConsumption;
  dashboardAccountPing: Scalars['Boolean'];
  /** fetch data from the table: "dashboardAccountRole" */
  dashboardAccountRole: Array<DashboardAccountRole>;
  /** fetch data from the table: "dashboardAccountRolePermission" */
  dashboardAccountRolePermission: Array<DashboardAccountRolePermission>;
  /** fetch data from the table: "dashboardAccountRolePermission" using primary key columns */
  dashboardAccountRolePermission_by_pk?: Maybe<DashboardAccountRolePermission>;
  /** fetch data from the table: "dashboardAccountRoleType" */
  dashboardAccountRoleType: Array<DashboardAccountRoleType>;
  /** fetch data from the table: "dashboardAccountRoleType" using primary key columns */
  dashboardAccountRoleType_by_pk?: Maybe<DashboardAccountRoleType>;
  /** fetch data from the table: "dashboardAccountRole" using primary key columns */
  dashboardAccountRole_by_pk?: Maybe<DashboardAccountRole>;
  /** fetch data from the table: "dashboardAccount" using primary key columns */
  dashboardAccount_by_pk?: Maybe<DashboardAccount>;
  dashboardCurrentSaleses: CurrentSaleses;
  /** fetch data from the table: "dishUpSlipGroup" */
  dishUpSlipGroup: Array<DishUpSlipGroup>;
  /** An array relationship */
  dishUpSlipGroupRoles: Array<DishUpSlipGroupRoles>;
  /** fetch data from the table: "dishUpSlipGroupRoles" using primary key columns */
  dishUpSlipGroupRoles_by_pk?: Maybe<DishUpSlipGroupRoles>;
  /** An array relationship */
  dishUpSlipGroupShopMenus: Array<DishUpSlipGroupShopMenus>;
  /** fetch data from the table: "dishUpSlipGroupShopMenus" using primary key columns */
  dishUpSlipGroupShopMenus_by_pk?: Maybe<DishUpSlipGroupShopMenus>;
  /** An array relationship */
  dishUpSlipGroupShopPlans: Array<DishUpSlipGroupShopPlans>;
  /** fetch data from the table: "dishUpSlipGroupShopPlans" using primary key columns */
  dishUpSlipGroupShopPlans_by_pk?: Maybe<DishUpSlipGroupShopPlans>;
  /** An array relationship */
  dishUpSlipGroupTables: Array<DishUpSlipGroupTables>;
  /** fetch data from the table: "dishUpSlipGroupTables" using primary key columns */
  dishUpSlipGroupTables_by_pk?: Maybe<DishUpSlipGroupTables>;
  /** fetch data from the table: "dishUpSlipGroup" using primary key columns */
  dishUpSlipGroup_by_pk?: Maybe<DishUpSlipGroup>;
  ebicaApiTables: Array<EbicaApiTable>;
  /** fetch data from the table: "ebicaConfig" */
  ebicaConfig: Array<EbicaConfig>;
  /** fetch data from the table: "ebicaConfig" using primary key columns */
  ebicaConfig_by_pk?: Maybe<EbicaConfig>;
  /** fetch data from the table: "ebicaTable" */
  ebicaTable: Array<EbicaTable>;
  /** fetch data from the table: "ebicaTable" using primary key columns */
  ebicaTable_by_pk?: Maybe<EbicaTable>;
  /** fetch data from the table: "externalOnlineMenu" */
  externalOnlineMenu: Array<ExternalOnlineMenu>;
  /** fetch data from the table: "externalOnlineMenuAvailableTimeTerm" */
  externalOnlineMenuAvailableTimeTerm: Array<ExternalOnlineMenuAvailableTimeTerm>;
  /** fetch data from the table: "externalOnlineMenuAvailableTimeTerm" using primary key columns */
  externalOnlineMenuAvailableTimeTerm_by_pk?: Maybe<ExternalOnlineMenuAvailableTimeTerm>;
  /** fetch data from the table: "externalOnlineMenuConfig" */
  externalOnlineMenuConfig: Array<ExternalOnlineMenuConfig>;
  /** fetch data from the table: "externalOnlineMenuConfig" using primary key columns */
  externalOnlineMenuConfig_by_pk?: Maybe<ExternalOnlineMenuConfig>;
  /** fetch data from the table: "externalOnlineMenu" using primary key columns */
  externalOnlineMenu_by_pk?: Maybe<ExternalOnlineMenu>;
  faveYellAnalyticsByCorporation: FaveYellAnalyticsByCorporationOutput;
  /** fetch data from the table: "featureFlag" */
  featureFlag: Array<FeatureFlag>;
  /** fetch data from the table: "featureFlagShop" */
  featureFlagShop: Array<FeatureFlagShop>;
  /** fetch data from the table: "featureFlagShop" using primary key columns */
  featureFlagShop_by_pk?: Maybe<FeatureFlagShop>;
  /** fetch data from the table: "featureFlag" using primary key columns */
  featureFlag_by_pk?: Maybe<FeatureFlag>;
  /** fetch data from the table: "foodingJournalConfig" */
  foodingJournalConfig: Array<FoodingJournalConfig>;
  /** fetch data from the table: "foodingJournalConfig" using primary key columns */
  foodingJournalConfig_by_pk?: Maybe<FoodingJournalConfig>;
  /** fetch data from the table: "foodingJournalDepartmentMaster" */
  foodingJournalDepartmentMaster: Array<FoodingJournalDepartmentMaster>;
  /** fetch data from the table: "foodingJournalDepartmentMaster" using primary key columns */
  foodingJournalDepartmentMaster_by_pk?: Maybe<FoodingJournalDepartmentMaster>;
  /** fetch data from the table: "foodingJournalGroupMaster" */
  foodingJournalGroupMaster: Array<FoodingJournalGroupMaster>;
  /** fetch data from the table: "foodingJournalGroupMaster" using primary key columns */
  foodingJournalGroupMaster_by_pk?: Maybe<FoodingJournalGroupMaster>;
  /** fetch data from the table: "foodingJournalMenu" */
  foodingJournalMenu: Array<FoodingJournalMenu>;
  /** fetch data from the table: "foodingJournalMenu" using primary key columns */
  foodingJournalMenu_by_pk?: Maybe<FoodingJournalMenu>;
  /** fetch data from the table: "foodingJournalOnSitePaymentDetailType" */
  foodingJournalOnSitePaymentDetailType: Array<FoodingJournalOnSitePaymentDetailType>;
  /** fetch data from the table: "foodingJournalOnSitePaymentDetailType" using primary key columns */
  foodingJournalOnSitePaymentDetailType_by_pk?: Maybe<FoodingJournalOnSitePaymentDetailType>;
  /** fetch data from the table: "foodingJournalOnSitePaymentDiscountType" */
  foodingJournalOnSitePaymentDiscountType: Array<FoodingJournalOnSitePaymentDiscountType>;
  /** fetch data from the table: "foodingJournalOnSitePaymentDiscountType" using primary key columns */
  foodingJournalOnSitePaymentDiscountType_by_pk?: Maybe<FoodingJournalOnSitePaymentDiscountType>;
  /** fetch data from the table: "giftMeta" */
  giftMeta: Array<GiftMeta>;
  /** fetch aggregated fields from the table: "giftMeta" */
  giftMeta_aggregate: GiftMetaAggregate;
  /** fetch data from the table: "giftMeta" using primary key columns */
  giftMeta_by_pk?: Maybe<GiftMeta>;
  /** An array relationship */
  giftOptionMeta: Array<GiftOptionMeta>;
  /** fetch data from the table: "giftOptionMeta" using primary key columns */
  giftOptionMeta_by_pk?: Maybe<GiftOptionMeta>;
  /** fetch data from the table: "gmoBankAccount" */
  gmoBankAccount: Array<GmoBankAccount>;
  /** fetch data from the table: "gmoBankAccountShop" */
  gmoBankAccountShop: Array<GmoBankAccountShop>;
  /** fetch data from the table: "gmoBankAccountShop" using primary key columns */
  gmoBankAccountShop_by_pk?: Maybe<GmoBankAccountShop>;
  /** fetch data from the table: "gmoBankAccount" using primary key columns */
  gmoBankAccount_by_pk?: Maybe<GmoBankAccount>;
  /** fetch data from the table: "gmoOffPaymentMethodType" */
  gmoOffPaymentMethodType: Array<GmoOffPaymentMethodType>;
  /** fetch data from the table: "gmoOffPaymentMethodType" using primary key columns */
  gmoOffPaymentMethodType_by_pk?: Maybe<GmoOffPaymentMethodType>;
  /** fetch data from the table: "gmoOffPaymentOnSitePaymentDetailType" */
  gmoOffPaymentOnSitePaymentDetailType: Array<GmoOffPaymentOnSitePaymentDetailType>;
  /** fetch data from the table: "gmoOffPaymentOnSitePaymentDetailType" using primary key columns */
  gmoOffPaymentOnSitePaymentDetailType_by_pk?: Maybe<GmoOffPaymentOnSitePaymentDetailType>;
  /** fetch data from the table: "gmoOffPaymentShopConfig" */
  gmoOffPaymentShopConfig: Array<GmoOffPaymentShopConfig>;
  /** fetch data from the table: "gmoOffPaymentShopConfigTransactionFeeRate" */
  gmoOffPaymentShopConfigTransactionFeeRate: Array<GmoOffPaymentShopConfigTransactionFeeRate>;
  /** fetch data from the table: "gmoOffPaymentShopConfigTransactionFeeRate" using primary key columns */
  gmoOffPaymentShopConfigTransactionFeeRate_by_pk?: Maybe<GmoOffPaymentShopConfigTransactionFeeRate>;
  /** fetch data from the table: "gmoOffPaymentShopConfig" using primary key columns */
  gmoOffPaymentShopConfig_by_pk?: Maybe<GmoOffPaymentShopConfig>;
  /** fetch data from the table: "inflowSourceTag" */
  inflowSourceTag: Array<InflowSourceTag>;
  /** fetch aggregated fields from the table: "inflowSourceTag" */
  inflowSourceTag_aggregate: InflowSourceTagAggregate;
  /** fetch data from the table: "inflowSourceTag" using primary key columns */
  inflowSourceTag_by_pk?: Maybe<InflowSourceTag>;
  /** fetch data from the table: "itemSales" */
  itemSales: Array<ItemSales>;
  /** fetch data from the table: "itemSales" using primary key columns */
  itemSales_by_pk?: Maybe<ItemSales>;
  /** fetch data from the table: "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
  kdDisplayItemKdDisplayTargetsKdDisplayTarget: Array<KdDisplayItemKdDisplayTargetsKdDisplayTarget>;
  /** fetch data from the table: "kdDisplayItemKdDisplayTargetsKdDisplayTarget" using primary key columns */
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_by_pk?: Maybe<KdDisplayItemKdDisplayTargetsKdDisplayTarget>;
  /** fetch data from the table: "kdDisplayTarget" */
  kdDisplayTarget: Array<KdDisplayTarget>;
  /** fetch data from the table: "kdDisplayTarget" using primary key columns */
  kdDisplayTarget_by_pk?: Maybe<KdDisplayTarget>;
  latestCompanyCustomerSummaryByDate?: Maybe<CompanyCustomerSummary>;
  /** fetch data from the table: "lineChannelConfig" */
  lineChannelConfig: Array<LineChannelConfig>;
  /** fetch data from the table: "lineChannelConfig" using primary key columns */
  lineChannelConfig_by_pk?: Maybe<LineChannelConfig>;
  /** fetch data from the table: "lineOfficialAccount" */
  lineOfficialAccount: Array<LineOfficialAccount>;
  lineOfficialAccountSummaries: Array<LineOfficialAccountSummary>;
  /** fetch data from the table: "lineOfficialAccount" using primary key columns */
  lineOfficialAccount_by_pk?: Maybe<LineOfficialAccount>;
  lineOfficialAccounts: Array<LineOfficialAccountProfile>;
  /** fetch data from the table: "lineProfile" */
  lineProfile: Array<LineProfile>;
  /** fetch data from the table: "lineReportingBotConfig" */
  lineReportingBotConfig: Array<LineReportingBotConfig>;
  /** fetch data from the table: "lineReportingBotConfigShop" */
  lineReportingBotConfigShop: Array<LineReportingBotConfigShop>;
  /** fetch data from the table: "lineReportingBotConfigShop" using primary key columns */
  lineReportingBotConfigShop_by_pk?: Maybe<LineReportingBotConfigShop>;
  /** fetch data from the table: "lineReportingBotConfig" using primary key columns */
  lineReportingBotConfig_by_pk?: Maybe<LineReportingBotConfig>;
  /** fetch data from the table: "lineReportingBotVerification" */
  lineReportingBotVerification: Array<LineReportingBotVerification>;
  /** fetch data from the table: "lineReportingBotVerification" using primary key columns */
  lineReportingBotVerification_by_pk?: Maybe<LineReportingBotVerification>;
  /** fetch data from the table: "membershipCard" */
  membershipCard: Array<MembershipCard>;
  /** fetch data from the table: "membershipCardAppearanceType" */
  membershipCardAppearanceType: Array<MembershipCardAppearanceType>;
  /** fetch data from the table: "membershipCardAppearanceType" using primary key columns */
  membershipCardAppearanceType_by_pk?: Maybe<MembershipCardAppearanceType>;
  /** fetch data from the table: "membershipCardTitle" */
  membershipCardTitle: Array<MembershipCardTitle>;
  /** fetch data from the table: "membershipCardTitle" using primary key columns */
  membershipCardTitle_by_pk?: Maybe<MembershipCardTitle>;
  /** fetch data from the table: "membershipCard" using primary key columns */
  membershipCard_by_pk?: Maybe<MembershipCard>;
  /** fetch data from the table: "membershipRank" */
  membershipRank: Array<MembershipRank>;
  /** fetch data from the table: "membershipRankConfig" */
  membershipRankConfig: Array<MembershipRankConfig>;
  /** fetch data from the table: "membershipRankConfig" using primary key columns */
  membershipRankConfig_by_pk?: Maybe<MembershipRankConfig>;
  /** fetch data from the table: "membershipRank" using primary key columns */
  membershipRank_by_pk?: Maybe<MembershipRank>;
  /** fetch data from the table: "menu" */
  menu: Array<Menu>;
  /** fetch data from the table: "menuOption" */
  menuOption: Array<MenuOption>;
  /** fetch data from the table: "menuOption" using primary key columns */
  menuOption_by_pk?: Maybe<MenuOption>;
  /** fetch data from the table: "menuOrderItem" */
  menuOrderItem: Array<MenuOrderItem>;
  menuOrderItemAnalytics: MenuOrderItemAnalyticsOutput;
  /** fetch data from the table: "menuOrderItemChoice" */
  menuOrderItemChoice: Array<MenuOrderItemChoice>;
  /** fetch data from the table: "menuOrderItemChoice" using primary key columns */
  menuOrderItemChoice_by_pk?: Maybe<MenuOrderItemChoice>;
  /** fetch data from the table: "menuOrderItemOption" */
  menuOrderItemOption: Array<MenuOrderItemOption>;
  /** fetch data from the table: "menuOrderItemOption" using primary key columns */
  menuOrderItemOption_by_pk?: Maybe<MenuOrderItemOption>;
  /** fetch data from the table: "menuOrderItem" using primary key columns */
  menuOrderItem_by_pk?: Maybe<MenuOrderItem>;
  /** fetch data from the table: "menuRecommendationMeta" */
  menuRecommendationMeta: Array<MenuRecommendationMeta>;
  /** fetch data from the table: "menuRecommendationMeta" using primary key columns */
  menuRecommendationMeta_by_pk?: Maybe<MenuRecommendationMeta>;
  /** fetch data from the table: "menu" using primary key columns */
  menu_by_pk?: Maybe<Menu>;
  /** fetch data from the table: "messageDelivery" */
  messageDelivery: Array<MessageDelivery>;
  messageDeliveryAnalyticsRecentVisitedTableUserReport: MessageDeliveryAnalyticsRecentVisitedTableUsersReport;
  messageDeliveryAnalyticsSummary: MessageDeliveryAnalyticsSummary;
  messageDeliveryAnalyticsSummaryPerMessageDelivery: MessageDeliveryAnalyticsSummaryPerMessageDelivery;
  messageDeliveryAnalyticsSummaryPerShop: MessageDeliveryAnalyticsSummaryPerShop;
  /** fetch data from the table: "messageDeliveryCustomerList" */
  messageDeliveryCustomerList: Array<MessageDeliveryCustomerList>;
  /** fetch data from the table: "messageDeliveryCustomerListCustomer" */
  messageDeliveryCustomerListCustomer: Array<MessageDeliveryCustomerListCustomer>;
  /** fetch aggregated fields from the table: "messageDeliveryCustomerListCustomer" */
  messageDeliveryCustomerListCustomer_aggregate: MessageDeliveryCustomerListCustomerAggregate;
  /** fetch data from the table: "messageDeliveryCustomerListCustomer" using primary key columns */
  messageDeliveryCustomerListCustomer_by_pk?: Maybe<MessageDeliveryCustomerListCustomer>;
  /** fetch data from the table: "messageDeliveryCustomerList" using primary key columns */
  messageDeliveryCustomerList_by_pk?: Maybe<MessageDeliveryCustomerList>;
  /** fetch data from the table: "messageDeliveryCustomerSegment" */
  messageDeliveryCustomerSegment: Array<MessageDeliveryCustomerSegment>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentBusinessOperationHoursCondition" */
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition: Array<MessageDeliveryCustomerSegmentBusinessOperationHoursCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentBusinessOperationHoursCondition" using primary key columns */
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentBusinessOperationHoursCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentDayOfWeekCondition" */
  messageDeliveryCustomerSegmentDayOfWeekCondition: Array<MessageDeliveryCustomerSegmentDayOfWeekCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentDayOfWeekCondition" using primary key columns */
  messageDeliveryCustomerSegmentDayOfWeekCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentDayOfWeekCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition" */
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition: Array<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition" using primary key columns */
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentGender" */
  messageDeliveryCustomerSegmentGender: Array<MessageDeliveryCustomerSegmentGender>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentGenderCondition" */
  messageDeliveryCustomerSegmentGenderCondition: Array<MessageDeliveryCustomerSegmentGenderCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentGenderCondition" using primary key columns */
  messageDeliveryCustomerSegmentGenderCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentGenderCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentGender" using primary key columns */
  messageDeliveryCustomerSegmentGender_by_pk?: Maybe<MessageDeliveryCustomerSegmentGender>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentMenuCondition" */
  messageDeliveryCustomerSegmentMenuCondition: Array<MessageDeliveryCustomerSegmentMenuCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentMenuCondition" using primary key columns */
  messageDeliveryCustomerSegmentMenuCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentMenuCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentNumericCondition" */
  messageDeliveryCustomerSegmentNumericCondition: Array<MessageDeliveryCustomerSegmentNumericCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentNumericCondition" using primary key columns */
  messageDeliveryCustomerSegmentNumericCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentNumericCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition" */
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition: Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition" using primary key columns */
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType" */
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType: Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusType>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType" using primary key columns */
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_by_pk?: Maybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusType>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentShopCondition" */
  messageDeliveryCustomerSegmentShopCondition: Array<MessageDeliveryCustomerSegmentShopCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentShopCondition" using primary key columns */
  messageDeliveryCustomerSegmentShopCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentShopCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentUnvisitedDayCountCondition" */
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition: Array<MessageDeliveryCustomerSegmentUnvisitedDayCountCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentUnvisitedDayCountCondition" using primary key columns */
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentUnvisitedDayCountCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentVisitedDayCountCondition" */
  messageDeliveryCustomerSegmentVisitedDayCountCondition: Array<MessageDeliveryCustomerSegmentVisitedDayCountCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentVisitedDayCountCondition" using primary key columns */
  messageDeliveryCustomerSegmentVisitedDayCountCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentVisitedDayCountCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegment" using primary key columns */
  messageDeliveryCustomerSegment_by_pk?: Maybe<MessageDeliveryCustomerSegment>;
  /** fetch data from the table: "messageDeliveryJob" */
  messageDeliveryJob: Array<MessageDeliveryJob>;
  /** fetch data from the table: "messageDeliveryJob" using primary key columns */
  messageDeliveryJob_by_pk?: Maybe<MessageDeliveryJob>;
  /** fetch data from the table: "messageDeliveryMessage" */
  messageDeliveryMessage: Array<MessageDeliveryMessage>;
  /** fetch data from the table: "messageDeliveryMessageCouponTypeMeta" */
  messageDeliveryMessageCouponTypeMeta: Array<MessageDeliveryMessageCouponTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageCouponTypeMeta" using primary key columns */
  messageDeliveryMessageCouponTypeMeta_by_pk?: Maybe<MessageDeliveryMessageCouponTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageImageTypeMeta" */
  messageDeliveryMessageImageTypeMeta: Array<MessageDeliveryMessageImageTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageImageTypeMeta" using primary key columns */
  messageDeliveryMessageImageTypeMeta_by_pk?: Maybe<MessageDeliveryMessageImageTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageQuestionnaireTypeMeta" */
  messageDeliveryMessageQuestionnaireTypeMeta: Array<MessageDeliveryMessageQuestionnaireTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageQuestionnaireTypeMeta" using primary key columns */
  messageDeliveryMessageQuestionnaireTypeMeta_by_pk?: Maybe<MessageDeliveryMessageQuestionnaireTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageTextTypeMeta" */
  messageDeliveryMessageTextTypeMeta: Array<MessageDeliveryMessageTextTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageTextTypeMeta" using primary key columns */
  messageDeliveryMessageTextTypeMeta_by_pk?: Maybe<MessageDeliveryMessageTextTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessage" using primary key columns */
  messageDeliveryMessage_by_pk?: Maybe<MessageDeliveryMessage>;
  /** fetch data from the table: "messageDeliveryShopJob" */
  messageDeliveryShopJob: Array<MessageDeliveryShopJob>;
  /** fetch data from the table: "messageDeliveryShopJob" using primary key columns */
  messageDeliveryShopJob_by_pk?: Maybe<MessageDeliveryShopJob>;
  /** fetch data from the table: "messageDelivery" using primary key columns */
  messageDelivery_by_pk?: Maybe<MessageDelivery>;
  /** fetch data from the table: "monthlySalesBudget" */
  monthlySalesBudget: Array<MonthlySalesBudget>;
  /** fetch data from the table: "monthlySalesBudget" using primary key columns */
  monthlySalesBudget_by_pk?: Maybe<MonthlySalesBudget>;
  /** fetch data from the table: "multipleChoiceTypeQuestionAnswer" */
  multipleChoiceTypeQuestionAnswer: Array<MultipleChoiceTypeQuestionAnswer>;
  /** fetch data from the table: "multipleChoiceTypeQuestionAnswer" using primary key columns */
  multipleChoiceTypeQuestionAnswer_by_pk?: Maybe<MultipleChoiceTypeQuestionAnswer>;
  /** fetch data from the table: "multipleChoiceTypeQuestionChoice" */
  multipleChoiceTypeQuestionChoice: Array<MultipleChoiceTypeQuestionChoice>;
  /** fetch data from the table: "multipleChoiceTypeQuestionChoiceAnswer" */
  multipleChoiceTypeQuestionChoiceAnswer: Array<MultipleChoiceTypeQuestionChoiceAnswer>;
  /** fetch data from the table: "multipleChoiceTypeQuestionChoiceAnswer" using primary key columns */
  multipleChoiceTypeQuestionChoiceAnswer_by_pk?: Maybe<MultipleChoiceTypeQuestionChoiceAnswer>;
  /** fetch data from the table: "multipleChoiceTypeQuestionMeta" */
  multipleChoiceTypeQuestionMeta: Array<MultipleChoiceTypeQuestionMeta>;
  /** fetch data from the table: "multipleChoiceTypeQuestionMeta" using primary key columns */
  multipleChoiceTypeQuestionMeta_by_pk?: Maybe<MultipleChoiceTypeQuestionMeta>;
  /** fetch data from the table: "onSitePayment" */
  onSitePayment: Array<OnSitePayment>;
  /** fetch data from the table: "onSitePaymentDetail" */
  onSitePaymentDetail: Array<OnSitePaymentDetail>;
  /** fetch data from the table: "onSitePaymentDetailType" */
  onSitePaymentDetailType: Array<OnSitePaymentDetailType>;
  /** fetch data from the table: "onSitePaymentDetailTypeCategoryPriority" */
  onSitePaymentDetailTypeCategoryPriority: Array<OnSitePaymentDetailTypeCategoryPriority>;
  /** fetch data from the table: "onSitePaymentDetailTypeCategoryPriority" using primary key columns */
  onSitePaymentDetailTypeCategoryPriority_by_pk?: Maybe<OnSitePaymentDetailTypeCategoryPriority>;
  /** fetch aggregated fields from the table: "onSitePaymentDetailType" */
  onSitePaymentDetailType_aggregate: OnSitePaymentDetailTypeAggregate;
  /** fetch data from the table: "onSitePaymentDetailType" using primary key columns */
  onSitePaymentDetailType_by_pk?: Maybe<OnSitePaymentDetailType>;
  /** fetch data from the table: "onSitePaymentDetail" using primary key columns */
  onSitePaymentDetail_by_pk?: Maybe<OnSitePaymentDetail>;
  /** fetch data from the table: "onSitePaymentDiscount" */
  onSitePaymentDiscount: Array<OnSitePaymentDiscount>;
  /** fetch data from the table: "onSitePaymentDiscountType" */
  onSitePaymentDiscountType: Array<OnSitePaymentDiscountType>;
  /** fetch aggregated fields from the table: "onSitePaymentDiscountType" */
  onSitePaymentDiscountType_aggregate: OnSitePaymentDiscountTypeAggregate;
  /** fetch data from the table: "onSitePaymentDiscountType" using primary key columns */
  onSitePaymentDiscountType_by_pk?: Maybe<OnSitePaymentDiscountType>;
  /** fetch data from the table: "onSitePaymentDiscount" using primary key columns */
  onSitePaymentDiscount_by_pk?: Maybe<OnSitePaymentDiscount>;
  /** fetch data from the table: "onSitePaymentSales" */
  onSitePaymentSales: Array<OnSitePaymentSales>;
  /** fetch data from the table: "onSitePaymentSales" using primary key columns */
  onSitePaymentSales_by_pk?: Maybe<OnSitePaymentSales>;
  /** An array relationship */
  onSitePaymentTableUsers: Array<OnSitePaymentTableUsers>;
  /** fetch data from the table: "onSitePayment" using primary key columns */
  onSitePayment_by_pk?: Maybe<OnSitePayment>;
  /** fetch data from the table: "onlinePaymentPrinterRoleMeta" */
  onlinePaymentPrinterRoleMeta: Array<OnlinePaymentPrinterRoleMeta>;
  /** fetch data from the table: "onlinePaymentPrinterRoleMeta" using primary key columns */
  onlinePaymentPrinterRoleMeta_by_pk?: Maybe<OnlinePaymentPrinterRoleMeta>;
  onlinePaymentRoot?: Maybe<OnlinePaymentRootQuery>;
  /** fetch data from the table: "option" */
  option: Array<Option>;
  /** fetch data from the table: "optionInputType" */
  optionInputType: Array<OptionInputType>;
  /** fetch data from the table: "optionInputType" using primary key columns */
  optionInputType_by_pk?: Maybe<OptionInputType>;
  /** fetch aggregated fields from the table: "option" */
  option_aggregate: OptionAggregate;
  /** fetch data from the table: "option" using primary key columns */
  option_by_pk?: Maybe<Option>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "orderableTime" */
  orderableTime: Array<OrderableTime>;
  /** fetch data from the table: "orderableTime" using primary key columns */
  orderableTime_by_pk?: Maybe<OrderableTime>;
  /** fetch data from the table: "payingActivePlanChoice" */
  payingActivePlanChoice: Array<PayingActivePlanChoice>;
  /** fetch data from the table: "payingActivePlanChoice" using primary key columns */
  payingActivePlanChoice_by_pk?: Maybe<PayingActivePlanChoice>;
  /** An array relationship */
  payingActivePlanOpenPriceMeta: Array<PayingActivePlanOpenPriceMeta>;
  /** fetch data from the table: "payingActivePlanOpenPriceMeta" using primary key columns */
  payingActivePlanOpenPriceMeta_by_pk?: Maybe<PayingActivePlanOpenPriceMeta>;
  /** fetch data from the table: "payingMenuOrderItem" */
  payingMenuOrderItem: Array<PayingMenuOrderItem>;
  /** fetch data from the table: "payingMenuOrderItem" using primary key columns */
  payingMenuOrderItem_by_pk?: Maybe<PayingMenuOrderItem>;
  /** fetch data from the table: "paymentReceiptRoleTable" */
  paymentReceiptRoleTable: Array<PaymentReceiptRoleTable>;
  /** fetch data from the table: "pikaichiConfig" */
  pikaichiConfig: Array<PikaichiConfig>;
  /** fetch data from the table: "pikaichiConfig" using primary key columns */
  pikaichiConfig_by_pk?: Maybe<PikaichiConfig>;
  /** fetch data from the table: "pikaichiMenu" */
  pikaichiMenu: Array<PikaichiMenu>;
  /** fetch data from the table: "pikaichiMenuChoice" */
  pikaichiMenuChoice: Array<PikaichiMenuChoice>;
  /** fetch data from the table: "pikaichiMenuChoice" using primary key columns */
  pikaichiMenuChoice_by_pk?: Maybe<PikaichiMenuChoice>;
  /** fetch data from the table: "pikaichiMenuMenu" */
  pikaichiMenuMenu: Array<PikaichiMenuMenu>;
  /** fetch data from the table: "pikaichiMenuMenu" using primary key columns */
  pikaichiMenuMenu_by_pk?: Maybe<PikaichiMenuMenu>;
  /** fetch data from the table: "pikaichiMenuPlan" */
  pikaichiMenuPlan: Array<PikaichiMenuPlan>;
  /** fetch data from the table: "pikaichiMenuPlanChoice" */
  pikaichiMenuPlanChoice: Array<PikaichiMenuPlanChoice>;
  /** fetch data from the table: "pikaichiMenuPlanChoice" using primary key columns */
  pikaichiMenuPlanChoice_by_pk?: Maybe<PikaichiMenuPlanChoice>;
  /** fetch data from the table: "pikaichiMenuPlan" using primary key columns */
  pikaichiMenuPlan_by_pk?: Maybe<PikaichiMenuPlan>;
  /** fetch data from the table: "pikaichiMenu" using primary key columns */
  pikaichiMenu_by_pk?: Maybe<PikaichiMenu>;
  /** fetch data from the table: "pikaichiOnSitePaymentDetailType" */
  pikaichiOnSitePaymentDetailType: Array<PikaichiOnSitePaymentDetailType>;
  /** fetch data from the table: "pikaichiOnSitePaymentDetailType" using primary key columns */
  pikaichiOnSitePaymentDetailType_by_pk?: Maybe<PikaichiOnSitePaymentDetailType>;
  /** fetch data from the table: "pikaichiOnSitePaymentDiscountType" */
  pikaichiOnSitePaymentDiscountType: Array<PikaichiOnSitePaymentDiscountType>;
  /** fetch data from the table: "pikaichiOnSitePaymentDiscountType" using primary key columns */
  pikaichiOnSitePaymentDiscountType_by_pk?: Maybe<PikaichiOnSitePaymentDiscountType>;
  ping: Scalars['Boolean'];
  /** fetch data from the table: "plan" */
  plan: Array<Plan>;
  /** fetch data from the table: "planChoice" */
  planChoice: Array<PlanChoice>;
  /** fetch aggregated fields from the table: "planChoice" */
  planChoice_aggregate: PlanChoiceAggregate;
  /** fetch data from the table: "planChoice" using primary key columns */
  planChoice_by_pk?: Maybe<PlanChoice>;
  /** fetch data from the table: "planFirstOrderCategoryMenu" */
  planFirstOrderCategoryMenu: Array<PlanFirstOrderCategoryMenu>;
  /** fetch data from the table: "planFirstOrderCategoryMenu" using primary key columns */
  planFirstOrderCategoryMenu_by_pk?: Maybe<PlanFirstOrderCategoryMenu>;
  /** fetch data from the table: "planGroup" */
  planGroup: Array<PlanGroup>;
  /** fetch data from the table: "planGroup" using primary key columns */
  planGroup_by_pk?: Maybe<PlanGroup>;
  /** fetch data from the table: "planMenuCategory" */
  planMenuCategory: Array<PlanMenuCategory>;
  /** fetch data from the table: "planMenuCategory" using primary key columns */
  planMenuCategory_by_pk?: Maybe<PlanMenuCategory>;
  /** fetch data from the table: "planOption" */
  planOption: Array<PlanOption>;
  /** fetch aggregated fields from the table: "planOption" */
  planOption_aggregate: PlanOptionAggregate;
  /** fetch data from the table: "planOption" using primary key columns */
  planOption_by_pk?: Maybe<PlanOption>;
  /** fetch data from the table: "plan" using primary key columns */
  plan_by_pk?: Maybe<Plan>;
  /** fetch data from the table: "plan_menu_categories_category" */
  plan_menu_categories_category: Array<PlanMenuCategoriesCategory>;
  quantityAndSalesAggregationByActivePlanAndMenuOrderItem: ShopApiAggregationGetQuantityAndSalesAggregationOutput;
  quantityAndSalesAggregationByActivePlanAndMenuOrderItemByShopId: ShopApiAggregationGetQuantityAndSalesAggregationOutput;
  quantityAndSalesAggregationByActivePlanAndMenuOrderItemForBreakingNews: ShopApiAggregationGetQuantityAndSalesAggregationOutput;
  quantityAndSalesAggregationByCategoryByShopId: ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutput;
  /** fetch data from the table: "question" */
  question: Array<Question>;
  /** fetch data from the table: "questionAnswer" */
  questionAnswer: Array<QuestionAnswer>;
  /** fetch data from the table: "questionAnswer" using primary key columns */
  questionAnswer_by_pk?: Maybe<QuestionAnswer>;
  /** fetch data from the table: "question" using primary key columns */
  question_by_pk?: Maybe<Question>;
  /** fetch data from the table: "questionnaire" */
  questionnaire: Array<Questionnaire>;
  /** fetch data from the table: "questionnaireAnswer" */
  questionnaireAnswer: Array<QuestionnaireAnswer>;
  /** fetch data from the table: "questionnaireAnswer" using primary key columns */
  questionnaireAnswer_by_pk?: Maybe<QuestionnaireAnswer>;
  questionnaireAnswererAttributeMetrics: Array<QuestionnaireAnswererAttributeMetricsOutput>;
  questionnaireComments: Array<QuestionnaireComment>;
  questionnaireCommentsCount: QuestionnaireCommentCountOutput;
  /** fetch data from the table: "questionnaireConfig" */
  questionnaireConfig: Array<QuestionnaireConfig>;
  /** fetch data from the table: "questionnaireConfig" using primary key columns */
  questionnaireConfig_by_pk?: Maybe<QuestionnaireConfig>;
  questionnaireMetricsPerCategory: QuestionnaireMetricsPerCategoryOutput;
  /** fetch data from the table: "questionnaireQuestion" */
  questionnaireQuestion: Array<QuestionnaireQuestion>;
  questionnaireScoreChangesMetrics: QuestionnaireScoreChangesMetrics;
  questionnaireScoreMetrics: QuestionnaireScoreMetricsOutput;
  /** fetch data from the table: "questionnaire" using primary key columns */
  questionnaire_by_pk?: Maybe<Questionnaire>;
  /** fetch data from the table: "receipt" */
  receipt: Array<Receipt>;
  /** fetch data from the table: "receipt" using primary key columns */
  receipt_by_pk?: Maybe<Receipt>;
  /** fetch data from the table: "reservationDashboardAccountAccessibleShop" */
  reservationDashboardAccountAccessibleShop: Array<ReservationDashboardAccountAccessibleShop>;
  /** fetch data from the table: "reservationDashboardAccountAccessibleShop" using primary key columns */
  reservationDashboardAccountAccessibleShop_by_pk?: Maybe<ReservationDashboardAccountAccessibleShop>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch data from the table: "roleTablesTable" */
  roleTablesTable: Array<RoleTablesTable>;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  sales: Sales;
  salesAnalytics: SalesAnalyticsOutput;
  salesBreakdownByPaymentMethod: Array<SalesBreakdownByPaymentMethod>;
  /** fetch data from the table: "salesBudgetAllocationSetting" */
  salesBudgetAllocationSetting: Array<SalesBudgetAllocationSetting>;
  /** fetch data from the table: "salesBudgetAllocationSetting" using primary key columns */
  salesBudgetAllocationSetting_by_pk?: Maybe<SalesBudgetAllocationSetting>;
  salesByDayOfWeekAndHourAnalytics: SalesByDayOfWeekAndHourAnalyticsOutput;
  salesForBudget: SalesForBudget;
  /** fetch data from the table: "scoreTypeQuestionAnswer" */
  scoreTypeQuestionAnswer: Array<ScoreTypeQuestionAnswer>;
  /** fetch data from the table: "serviceAdmin" */
  serviceAdmin: Array<ServiceAdmin>;
  /** fetch data from the table: "serviceAdmin" using primary key columns */
  serviceAdmin_by_pk?: Maybe<ServiceAdmin>;
  /** fetch data from the table: "serviceChargeConfig" */
  serviceChargeConfig: Array<ServiceChargeConfig>;
  /** fetch data from the table: "serviceChargeConfig" using primary key columns */
  serviceChargeConfig_by_pk?: Maybe<ServiceChargeConfig>;
  /** fetch data from the table: "serviceChargeMeta" */
  serviceChargeMeta: Array<ServiceChargeMeta>;
  /** fetch data from the table: "serviceChargeMeta" using primary key columns */
  serviceChargeMeta_by_pk?: Maybe<ServiceChargeMeta>;
  /** fetch data from the table: "shop" */
  shop: Array<Shop>;
  /** fetch data from the table: "shopBusinessOperationHour" */
  shopBusinessOperationHour: Array<ShopBusinessOperationHour>;
  /** fetch data from the table: "shopBusinessOperationHour" using primary key columns */
  shopBusinessOperationHour_by_pk?: Maybe<ShopBusinessOperationHour>;
  /** fetch data from the table: "shopChoice" */
  shopChoice: Array<ShopChoice>;
  /** fetch data from the table: "shopChoice" using primary key columns */
  shopChoice_by_pk?: Maybe<ShopChoice>;
  /** fetch data from the table: "shopClerk" */
  shopClerk: Array<ShopClerk>;
  /** fetch data from the table: "shopClerk" using primary key columns */
  shopClerk_by_pk?: Maybe<ShopClerk>;
  /** fetch data from the table: "shopClient" */
  shopClient: Array<ShopClient>;
  /** fetch data from the table: "shopClient" using primary key columns */
  shopClient_by_pk?: Maybe<ShopClient>;
  /** fetch data from the table: "shopCookingItemRole" */
  shopCookingItemRole: Array<ShopCookingItemRole>;
  /** fetch data from the table: "shopCookingItemRole" using primary key columns */
  shopCookingItemRole_by_pk?: Maybe<ShopCookingItemRole>;
  /** fetch data from the table: "shopDiscountType" */
  shopDiscountType: Array<ShopDiscountType>;
  /** fetch data from the table: "shopDiscountType" using primary key columns */
  shopDiscountType_by_pk?: Maybe<ShopDiscountType>;
  /** fetch data from the table: "shopInflowSourceTag" */
  shopInflowSourceTag: Array<ShopInflowSourceTag>;
  /** fetch data from the table: "shopMember" */
  shopMember: Array<ShopMember>;
  /** fetch data from the table: "shopMenu" */
  shopMenu: Array<ShopMenu>;
  /** fetch data from the table: "shopMenuCookingItem" */
  shopMenuCookingItem: Array<ShopMenuCookingItem>;
  /** fetch data from the table: "shopMenuKdDisplayTarget" */
  shopMenuKdDisplayTarget: Array<ShopMenuKdDisplayTarget>;
  /** fetch data from the table: "shopMenuKdDisplayTarget" using primary key columns */
  shopMenuKdDisplayTarget_by_pk?: Maybe<ShopMenuKdDisplayTarget>;
  /** fetch data from the table: "shopMenuKitchenRole" */
  shopMenuKitchenRole: Array<ShopMenuKitchenRole>;
  /** fetch data from the table: "shopMenu" using primary key columns */
  shopMenu_by_pk?: Maybe<ShopMenu>;
  /** fetch data from the table: "shopOptionKitchenRole" */
  shopOptionKitchenRole: Array<ShopOptionKitchenRole>;
  /** fetch data from the table: "shopOptionKitchenRole" using primary key columns */
  shopOptionKitchenRole_by_pk?: Maybe<ShopOptionKitchenRole>;
  /** fetch data from the table: "shopOrderableTimeTerm" */
  shopOrderableTimeTerm: Array<ShopOrderableTimeTerm>;
  /** fetch data from the table: "shopOrderableTimeTerm" using primary key columns */
  shopOrderableTimeTerm_by_pk?: Maybe<ShopOrderableTimeTerm>;
  /** fetch data from the table: "shopPaymentType" */
  shopPaymentType: Array<ShopPaymentType>;
  /** fetch data from the table: "shopPaymentType" using primary key columns */
  shopPaymentType_by_pk?: Maybe<ShopPaymentType>;
  /** fetch data from the table: "shopPlan" */
  shopPlan: Array<ShopPlan>;
  /** fetch data from the table: "shopPlanChoice" */
  shopPlanChoice: Array<ShopPlanChoice>;
  /** fetch data from the table: "shopPlanChoice" using primary key columns */
  shopPlanChoice_by_pk?: Maybe<ShopPlanChoice>;
  /** fetch data from the table: "shopPlanKitchenRole" */
  shopPlanKitchenRole: Array<ShopPlanKitchenRole>;
  /** fetch data from the table: "shopPlanOption" */
  shopPlanOption: Array<ShopPlanOption>;
  /** fetch data from the table: "shopPlanOptionKitchenRole" */
  shopPlanOptionKitchenRole: Array<ShopPlanOptionKitchenRole>;
  /** fetch data from the table: "shopPlanOption" using primary key columns */
  shopPlanOption_by_pk?: Maybe<ShopPlanOption>;
  /** fetch data from the table: "shopPlan" using primary key columns */
  shopPlan_by_pk?: Maybe<ShopPlan>;
  /** fetch data from the table: "shop" using primary key columns */
  shop_by_pk?: Maybe<Shop>;
  /** fetch data from the table: "specificTimeSurchargeConfig" */
  specificTimeSurchargeConfig: Array<SpecificTimeSurchargeConfig>;
  /** fetch data from the table: "specificTimeSurchargeConfig" using primary key columns */
  specificTimeSurchargeConfig_by_pk?: Maybe<SpecificTimeSurchargeConfig>;
  /** fetch data from the table: "specificTimeSurchargeMeta" */
  specificTimeSurchargeMeta: Array<SpecificTimeSurchargeMeta>;
  /** fetch data from the table: "specificTimeSurchargeMeta" using primary key columns */
  specificTimeSurchargeMeta_by_pk?: Maybe<SpecificTimeSurchargeMeta>;
  /** fetch data from the table: "steraOnSitePaymentDetailType" */
  steraOnSitePaymentDetailType: Array<SteraOnSitePaymentDetailType>;
  /** fetch data from the table: "steraOnSitePaymentDetailType" using primary key columns */
  steraOnSitePaymentDetailType_by_pk?: Maybe<SteraOnSitePaymentDetailType>;
  /** fetch data from the table: "stock" */
  stock: Array<Stock>;
  /** fetch data from the table: "stock" using primary key columns */
  stock_by_pk?: Maybe<Stock>;
  /** fetch data from the table: "supportedLocale" */
  supportedLocale: Array<SupportedLocale>;
  /** fetch data from the table: "supportedLocale" using primary key columns */
  supportedLocale_by_pk?: Maybe<SupportedLocale>;
  /** fetch data from the table: "table" */
  table: Array<Table>;
  /** fetch data from the table: "tableArea" */
  tableArea: Array<TableArea>;
  /** fetch aggregated fields from the table: "tableArea" */
  tableArea_aggregate: TableAreaAggregate;
  /** fetch data from the table: "tableArea" using primary key columns */
  tableArea_by_pk?: Maybe<TableArea>;
  /** fetch data from the table: "tableUser" */
  tableUser: Array<TableUser>;
  /** fetch data from the table: "tableUserCustomer" */
  tableUserCustomer: Array<TableUserCustomer>;
  /** fetch data from the table: "tableUserCustomer" using primary key columns */
  tableUserCustomer_by_pk?: Maybe<TableUserCustomer>;
  /** fetch data from the table: "tableUser" using primary key columns */
  tableUser_by_pk?: Maybe<TableUser>;
  /** fetch aggregated fields from the table: "table" */
  table_aggregate: TableAggregate;
  /** fetch data from the table: "table" using primary key columns */
  table_by_pk?: Maybe<Table>;
  /** fetch data from the table: "taxOffice" */
  taxOffice: Array<TaxOffice>;
  /** fetch data from the table: "taxOffice" using primary key columns */
  taxOffice_by_pk?: Maybe<TaxOffice>;
  /** fetch data from the table: "tecAggregationConfig" */
  tecAggregationConfig: Array<TecAggregationConfig>;
  /** fetch data from the table: "tecAggregationConfig" using primary key columns */
  tecAggregationConfig_by_pk?: Maybe<TecAggregationConfig>;
  /** fetch data from the table: "tecAggregationMediaMap" */
  tecAggregationMediaMap: Array<TecAggregationMediaMap>;
  /** fetch data from the table: "tecAggregationMediaMap" using primary key columns */
  tecAggregationMediaMap_by_pk?: Maybe<TecAggregationMediaMap>;
  /** fetch data from the table: "tecAggregationMenu" */
  tecAggregationMenu: Array<TecAggregationMenu>;
  /** fetch data from the table: "tecAggregationMenu" using primary key columns */
  tecAggregationMenu_by_pk?: Maybe<TecAggregationMenu>;
  /** fetch data from the table: "tecAggregationOnSitePaymentDetailType" */
  tecAggregationOnSitePaymentDetailType: Array<TecAggregationOnSitePaymentDetailType>;
  /** fetch data from the table: "tecAggregationOnSitePaymentDetailType" using primary key columns */
  tecAggregationOnSitePaymentDetailType_by_pk?: Maybe<TecAggregationOnSitePaymentDetailType>;
  /** fetch data from the table: "textTypeQuestionAnswer" */
  textTypeQuestionAnswer: Array<TextTypeQuestionAnswer>;
  /** fetch data from the table: "translation" */
  translation: Array<Translation>;
  /** fetch data from the table: "translation" using primary key columns */
  translation_by_pk?: Maybe<Translation>;
  validLineOfficialAccounts: Array<ValidLineOfficialAccount>;
  /** fetch data from the table: "winboardConfig" */
  winboardConfig: Array<WinboardConfig>;
  /** fetch data from the table: "winboardConfig" using primary key columns */
  winboardConfig_by_pk?: Maybe<WinboardConfig>;
  /** fetch data from the table: "winboardInflowSourceTag" */
  winboardInflowSourceTag: Array<WinboardInflowSourceTag>;
  /** fetch data from the table: "winboardInflowSourceTag" using primary key columns */
  winboardInflowSourceTag_by_pk?: Maybe<WinboardInflowSourceTag>;
  /** fetch data from the table: "winboardMenu" */
  winboardMenu: Array<WinboardMenu>;
  /** fetch data from the table: "winboardMenu" using primary key columns */
  winboardMenu_by_pk?: Maybe<WinboardMenu>;
};


export type QueryRootAccountingSlipImageArgs = {
  distinct_on?: InputMaybe<Array<AccountingSlipImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccountingSlipImageOrderBy>>;
  where?: InputMaybe<AccountingSlipImageBoolExp>;
};


export type QueryRootAccountingSlipImageByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootActivePlanArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanOrderBy>>;
  where?: InputMaybe<ActivePlanBoolExp>;
};


export type QueryRootActivePlanChoiceArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanChoiceOrderBy>>;
  where?: InputMaybe<ActivePlanChoiceBoolExp>;
};


export type QueryRootActivePlanChoiceByPkArgs = {
  activePlanChoiceId: Scalars['uuid'];
};


export type QueryRootActivePlanOpenPriceMetaArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanOpenPriceMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanOpenPriceMetaOrderBy>>;
  where?: InputMaybe<ActivePlanOpenPriceMetaBoolExp>;
};


export type QueryRootActivePlanOpenPriceMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootActivePlanOptionArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanOptionOrderBy>>;
  where?: InputMaybe<ActivePlanOptionBoolExp>;
};


export type QueryRootActivePlanOptionByPkArgs = {
  activePlanOptionId: Scalars['uuid'];
};


export type QueryRootActivePlanByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAdyenPaymentReportTerminalPaymentAuthorizedEventArgs = {
  distinct_on?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentAuthorizedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentAuthorizedEventOrderBy>>;
  where?: InputMaybe<AdyenPaymentReportTerminalPaymentAuthorizedEventBoolExp>;
};


export type QueryRootAdyenPaymentReportTerminalPaymentAuthorizedEventByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAdyenPaymentReportTerminalPaymentCanceledEventArgs = {
  distinct_on?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentCanceledEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentCanceledEventOrderBy>>;
  where?: InputMaybe<AdyenPaymentReportTerminalPaymentCanceledEventBoolExp>;
};


export type QueryRootAdyenPaymentReportTerminalPaymentCanceledEventByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAdyenPaymentShopConfigArgs = {
  distinct_on?: InputMaybe<Array<AdyenPaymentShopConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenPaymentShopConfigOrderBy>>;
  where?: InputMaybe<AdyenPaymentShopConfigBoolExp>;
};


export type QueryRootAdyenPaymentShopConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAdyenPaymentShopPaymentMethodArgs = {
  distinct_on?: InputMaybe<Array<AdyenPaymentShopPaymentMethodSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenPaymentShopPaymentMethodOrderBy>>;
  where?: InputMaybe<AdyenPaymentShopPaymentMethodBoolExp>;
};


export type QueryRootAdyenPaymentShopPaymentMethodByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAdyenPaymentStoreMetaArgs = {
  distinct_on?: InputMaybe<Array<AdyenPaymentStoreMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenPaymentStoreMetaOrderBy>>;
  where?: InputMaybe<AdyenPaymentStoreMetaBoolExp>;
};


export type QueryRootAdyenPaymentStoreMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAdyenTerminalPaymentShopConfigArgs = {
  distinct_on?: InputMaybe<Array<AdyenTerminalPaymentShopConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenTerminalPaymentShopConfigOrderBy>>;
  where?: InputMaybe<AdyenTerminalPaymentShopConfigBoolExp>;
};


export type QueryRootAdyenTerminalPaymentShopConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAdyenTerminalPaymentShopPaymentMethodArgs = {
  distinct_on?: InputMaybe<Array<AdyenTerminalPaymentShopPaymentMethodSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenTerminalPaymentShopPaymentMethodOrderBy>>;
  where?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodBoolExp>;
};


export type QueryRootAdyenTerminalPaymentShopPaymentMethodByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootAnalyticsSettingArgs = {
  distinct_on?: InputMaybe<Array<AnalyticsSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AnalyticsSettingOrderBy>>;
  where?: InputMaybe<AnalyticsSettingBoolExp>;
};


export type QueryRootAnalyticsSettingByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootBirthdayTypeQuestionAnswerArgs = {
  distinct_on?: InputMaybe<Array<BirthdayTypeQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BirthdayTypeQuestionAnswerOrderBy>>;
  where?: InputMaybe<BirthdayTypeQuestionAnswerBoolExp>;
};


export type QueryRootCashBalanceArgs = {
  input: QueryCashBalanceInput;
};


export type QueryRootCashManagementArgs = {
  input: QueryCashManagementInput;
};


export type QueryRootCashRegisterConfigArgs = {
  distinct_on?: InputMaybe<Array<CashRegisterConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CashRegisterConfigOrderBy>>;
  where?: InputMaybe<CashRegisterConfigBoolExp>;
};


export type QueryRootCashRegisterConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<CashRegisterConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CashRegisterConfigOrderBy>>;
  where?: InputMaybe<CashRegisterConfigBoolExp>;
};


export type QueryRootCashRegisterConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCashRegisterSecurityLevelArgs = {
  distinct_on?: InputMaybe<Array<CashRegisterSecurityLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CashRegisterSecurityLevelOrderBy>>;
  where?: InputMaybe<CashRegisterSecurityLevelBoolExp>;
};


export type QueryRootCashRegisterSecurityLevelByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootCategoryArgs = {
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


export type QueryRootCategoryMenuArgs = {
  distinct_on?: InputMaybe<Array<CategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryMenuOrderBy>>;
  where?: InputMaybe<CategoryMenuBoolExp>;
};


export type QueryRootCategoryMenuAggregateArgs = {
  distinct_on?: InputMaybe<Array<CategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryMenuOrderBy>>;
  where?: InputMaybe<CategoryMenuBoolExp>;
};


export type QueryRootCategoryMenuByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


export type QueryRootCategoryByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootCheckInEventArgs = {
  distinct_on?: InputMaybe<Array<CheckInEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CheckInEventOrderBy>>;
  where?: InputMaybe<CheckInEventBoolExp>;
};


export type QueryRootChoiceArgs = {
  distinct_on?: InputMaybe<Array<ChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChoiceOrderBy>>;
  where?: InputMaybe<ChoiceBoolExp>;
};


export type QueryRootChoiceAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChoiceOrderBy>>;
  where?: InputMaybe<ChoiceBoolExp>;
};


export type QueryRootChoiceByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootClerkArgs = {
  distinct_on?: InputMaybe<Array<ClerkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClerkOrderBy>>;
  where?: InputMaybe<ClerkBoolExp>;
};


export type QueryRootClerkByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootCloseCashRegisterArgs = {
  distinct_on?: InputMaybe<Array<CloseCashRegisterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CloseCashRegisterOrderBy>>;
  where?: InputMaybe<CloseCashRegisterBoolExp>;
};


export type QueryRootCloseCashRegisterByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCoineyPaymentArgs = {
  distinct_on?: InputMaybe<Array<CoineyPaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineyPaymentOrderBy>>;
  where?: InputMaybe<CoineyPaymentBoolExp>;
};


export type QueryRootCoineyPaymentOrderArgs = {
  distinct_on?: InputMaybe<Array<CoineyPaymentOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineyPaymentOrderOrderBy>>;
  where?: InputMaybe<CoineyPaymentOrderBoolExp>;
};


export type QueryRootCoineyPaymentOrderByPkArgs = {
  coineyPaymentOrderId: Scalars['Int'];
  orderId: Scalars['String'];
};


export type QueryRootCoineyPaymentSalesArgs = {
  distinct_on?: InputMaybe<Array<CoineyPaymentSalesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineyPaymentSalesOrderBy>>;
  where?: InputMaybe<CoineyPaymentSalesBoolExp>;
};


export type QueryRootCoineyPaymentSalesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCoineyPaymentByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootCoineySettlementArgs = {
  distinct_on?: InputMaybe<Array<CoineySettlementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineySettlementOrderBy>>;
  where?: InputMaybe<CoineySettlementBoolExp>;
};


export type QueryRootCoineySettlementByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCompanyArgs = {
  distinct_on?: InputMaybe<Array<CompanySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyOrderBy>>;
  where?: InputMaybe<CompanyBoolExp>;
};


export type QueryRootCompanyMemberArgs = {
  distinct_on?: InputMaybe<Array<CompanyMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyMemberOrderBy>>;
  where?: InputMaybe<CompanyMemberBoolExp>;
};


export type QueryRootCompanyMemberAggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyMemberOrderBy>>;
  where?: InputMaybe<CompanyMemberBoolExp>;
};


export type QueryRootCompanyMemberByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCompanySalesAnalyticsArgs = {
  input: CompanySalesAnalyticsInput;
};


export type QueryRootCompanyByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootCompulsoryAppetizerConfigArgs = {
  distinct_on?: InputMaybe<Array<CompulsoryAppetizerConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompulsoryAppetizerConfigOrderBy>>;
  where?: InputMaybe<CompulsoryAppetizerConfigBoolExp>;
};


export type QueryRootCompulsoryAppetizerConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootConnectGameConfigArgs = {
  distinct_on?: InputMaybe<Array<ConnectGameConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConnectGameConfigOrderBy>>;
  where?: InputMaybe<ConnectGameConfigBoolExp>;
};


export type QueryRootConnectGameConfigShopArgs = {
  distinct_on?: InputMaybe<Array<ConnectGameConfigShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConnectGameConfigShopOrderBy>>;
  where?: InputMaybe<ConnectGameConfigShopBoolExp>;
};


export type QueryRootConnectGameConfigShopByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootConnectGameConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCookingItemArgs = {
  distinct_on?: InputMaybe<Array<CookingItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CookingItemOrderBy>>;
  where?: InputMaybe<CookingItemBoolExp>;
};


export type QueryRootCookingItemByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootCorporationArgs = {
  distinct_on?: InputMaybe<Array<CorporationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CorporationOrderBy>>;
  where?: InputMaybe<CorporationBoolExp>;
};


export type QueryRootCorporationByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCouponArgs = {
  distinct_on?: InputMaybe<Array<CouponSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponOrderBy>>;
  where?: InputMaybe<CouponBoolExp>;
};


export type QueryRootCouponAnalyticsPerCouponArgs = {
  input: CouponAnalyticsInput;
};


export type QueryRootCouponAnalyticsSummaryArgs = {
  input: CouponAnalyticsInput;
};


export type QueryRootCouponAppliedEventArgs = {
  distinct_on?: InputMaybe<Array<CouponAppliedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponAppliedEventOrderBy>>;
  where?: InputMaybe<CouponAppliedEventBoolExp>;
};


export type QueryRootCouponAppliedEventAggregateArgs = {
  distinct_on?: InputMaybe<Array<CouponAppliedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponAppliedEventOrderBy>>;
  where?: InputMaybe<CouponAppliedEventBoolExp>;
};


export type QueryRootCouponAppliedEventByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCouponMenuDiscountArgs = {
  distinct_on?: InputMaybe<Array<CouponMenuDiscountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponMenuDiscountOrderBy>>;
  where?: InputMaybe<CouponMenuDiscountBoolExp>;
};


export type QueryRootCouponMenuDiscountByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCouponByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCumulativeCountArgs = {
  input: QueryCumulativeCountInput;
};


export type QueryRootCustomerArgs = {
  distinct_on?: InputMaybe<Array<CustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerOrderBy>>;
  where?: InputMaybe<CustomerBoolExp>;
};


export type QueryRootCustomerDashboardKpisArgs = {
  input: CustomerDashboardKpisInput;
};


export type QueryRootCustomerDashboardTransitionsArgs = {
  input: QueryTransitionsInput;
};


export type QueryRootCustomerFaveYellAmountRankingArgs = {
  input: QueryCustomerRankingInput;
};


export type QueryRootCustomerFaveYellCountRankingArgs = {
  input: QueryCustomerRankingInput;
};


export type QueryRootCustomerQuestionnaireArgs = {
  distinct_on?: InputMaybe<Array<CustomerQuestionnaireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerQuestionnaireOrderBy>>;
  where?: InputMaybe<CustomerQuestionnaireBoolExp>;
};


export type QueryRootCustomerQuestionnaireByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCustomerViralCountRankingArgs = {
  input: QueryCustomerRankingInput;
};


export type QueryRootCustomerVisitedCountRankingArgs = {
  input: QueryCustomerRankingInput;
};


export type QueryRootCustomerByPkArgs = {
  id: Scalars['String'];
};


export type QueryRootDailyCashRegisterBalancingArgs = {
  input: QueryDailyCashRegisterBalancingInput;
};


export type QueryRootDailySalesBudgetArgs = {
  distinct_on?: InputMaybe<Array<DailySalesBudgetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DailySalesBudgetOrderBy>>;
  where?: InputMaybe<DailySalesBudgetBoolExp>;
};


export type QueryRootDailySalesBudgetByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootDailySalesSummaryArgs = {
  input: QueryDailySalesSummaryInput;
};


export type QueryRootDashboardAccountArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountOrderBy>>;
  where?: InputMaybe<DashboardAccountBoolExp>;
};


export type QueryRootDashboardAccountAccessibleCompanyArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountAccessibleCompanySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountAccessibleCompanyOrderBy>>;
  where?: InputMaybe<DashboardAccountAccessibleCompanyBoolExp>;
};


export type QueryRootDashboardAccountAccessibleCompanyByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootDashboardAccountAccessibleShopArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountAccessibleShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountAccessibleShopOrderBy>>;
  where?: InputMaybe<DashboardAccountAccessibleShopBoolExp>;
};


export type QueryRootDashboardAccountAccessibleShopByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootDashboardAccountGetMessageDeliveryConsumptionArgs = {
  input: QueryMessageDeliveryConsumptionInput;
};


export type QueryRootDashboardAccountRoleArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountRoleOrderBy>>;
  where?: InputMaybe<DashboardAccountRoleBoolExp>;
};


export type QueryRootDashboardAccountRolePermissionArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountRolePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountRolePermissionOrderBy>>;
  where?: InputMaybe<DashboardAccountRolePermissionBoolExp>;
};


export type QueryRootDashboardAccountRolePermissionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootDashboardAccountRoleTypeArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountRoleTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountRoleTypeOrderBy>>;
  where?: InputMaybe<DashboardAccountRoleTypeBoolExp>;
};


export type QueryRootDashboardAccountRoleTypeByPkArgs = {
  name: Scalars['String'];
};


export type QueryRootDashboardAccountRoleByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootDashboardAccountByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootDashboardCurrentSalesesArgs = {
  input: QueryCurrentSalesInput;
};


export type QueryRootDishUpSlipGroupArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupBoolExp>;
};


export type QueryRootDishUpSlipGroupRolesArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupRolesOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupRolesBoolExp>;
};


export type QueryRootDishUpSlipGroupRolesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootDishUpSlipGroupShopMenusArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopMenusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopMenusOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
};


export type QueryRootDishUpSlipGroupShopMenusByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootDishUpSlipGroupShopPlansArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopPlansOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
};


export type QueryRootDishUpSlipGroupShopPlansByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootDishUpSlipGroupTablesArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupTablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupTablesOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupTablesBoolExp>;
};


export type QueryRootDishUpSlipGroupTablesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootDishUpSlipGroupByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootEbicaApiTablesArgs = {
  input: EbicaApiTablesInput;
};


export type QueryRootEbicaConfigArgs = {
  distinct_on?: InputMaybe<Array<EbicaConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EbicaConfigOrderBy>>;
  where?: InputMaybe<EbicaConfigBoolExp>;
};


export type QueryRootEbicaConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootEbicaTableArgs = {
  distinct_on?: InputMaybe<Array<EbicaTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EbicaTableOrderBy>>;
  where?: InputMaybe<EbicaTableBoolExp>;
};


export type QueryRootEbicaTableByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootExternalOnlineMenuArgs = {
  distinct_on?: InputMaybe<Array<ExternalOnlineMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalOnlineMenuOrderBy>>;
  where?: InputMaybe<ExternalOnlineMenuBoolExp>;
};


export type QueryRootExternalOnlineMenuAvailableTimeTermArgs = {
  distinct_on?: InputMaybe<Array<ExternalOnlineMenuAvailableTimeTermSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalOnlineMenuAvailableTimeTermOrderBy>>;
  where?: InputMaybe<ExternalOnlineMenuAvailableTimeTermBoolExp>;
};


export type QueryRootExternalOnlineMenuAvailableTimeTermByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootExternalOnlineMenuConfigArgs = {
  distinct_on?: InputMaybe<Array<ExternalOnlineMenuConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalOnlineMenuConfigOrderBy>>;
  where?: InputMaybe<ExternalOnlineMenuConfigBoolExp>;
};


export type QueryRootExternalOnlineMenuConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootExternalOnlineMenuByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootFaveYellAnalyticsByCorporationArgs = {
  input: FaveYellAnalyticsByCorporationInput;
};


export type QueryRootFeatureFlagArgs = {
  distinct_on?: InputMaybe<Array<FeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeatureFlagOrderBy>>;
  where?: InputMaybe<FeatureFlagBoolExp>;
};


export type QueryRootFeatureFlagShopArgs = {
  distinct_on?: InputMaybe<Array<FeatureFlagShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeatureFlagShopOrderBy>>;
  where?: InputMaybe<FeatureFlagShopBoolExp>;
};


export type QueryRootFeatureFlagShopByPkArgs = {
  name: FeatureFlagEnum;
  shopId: Scalars['uuid'];
};


export type QueryRootFeatureFlagByPkArgs = {
  name: Scalars['String'];
};


export type QueryRootFoodingJournalConfigArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalConfigOrderBy>>;
  where?: InputMaybe<FoodingJournalConfigBoolExp>;
};


export type QueryRootFoodingJournalConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootFoodingJournalDepartmentMasterArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalDepartmentMasterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalDepartmentMasterOrderBy>>;
  where?: InputMaybe<FoodingJournalDepartmentMasterBoolExp>;
};


export type QueryRootFoodingJournalDepartmentMasterByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootFoodingJournalGroupMasterArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalGroupMasterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalGroupMasterOrderBy>>;
  where?: InputMaybe<FoodingJournalGroupMasterBoolExp>;
};


export type QueryRootFoodingJournalGroupMasterByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootFoodingJournalMenuArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalMenuOrderBy>>;
  where?: InputMaybe<FoodingJournalMenuBoolExp>;
};


export type QueryRootFoodingJournalMenuByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootFoodingJournalOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeBoolExp>;
};


export type QueryRootFoodingJournalOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootFoodingJournalOnSitePaymentDiscountTypeArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalOnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalOnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeBoolExp>;
};


export type QueryRootFoodingJournalOnSitePaymentDiscountTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootGiftMetaArgs = {
  distinct_on?: InputMaybe<Array<GiftMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftMetaOrderBy>>;
  where?: InputMaybe<GiftMetaBoolExp>;
};


export type QueryRootGiftMetaAggregateArgs = {
  distinct_on?: InputMaybe<Array<GiftMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftMetaOrderBy>>;
  where?: InputMaybe<GiftMetaBoolExp>;
};


export type QueryRootGiftMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootGiftOptionMetaArgs = {
  distinct_on?: InputMaybe<Array<GiftOptionMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftOptionMetaOrderBy>>;
  where?: InputMaybe<GiftOptionMetaBoolExp>;
};


export type QueryRootGiftOptionMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootGmoBankAccountArgs = {
  distinct_on?: InputMaybe<Array<GmoBankAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoBankAccountOrderBy>>;
  where?: InputMaybe<GmoBankAccountBoolExp>;
};


export type QueryRootGmoBankAccountShopArgs = {
  distinct_on?: InputMaybe<Array<GmoBankAccountShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoBankAccountShopOrderBy>>;
  where?: InputMaybe<GmoBankAccountShopBoolExp>;
};


export type QueryRootGmoBankAccountShopByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootGmoBankAccountByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootGmoOffPaymentMethodTypeArgs = {
  distinct_on?: InputMaybe<Array<GmoOffPaymentMethodTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoOffPaymentMethodTypeOrderBy>>;
  where?: InputMaybe<GmoOffPaymentMethodTypeBoolExp>;
};


export type QueryRootGmoOffPaymentMethodTypeByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootGmoOffPaymentOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<GmoOffPaymentOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoOffPaymentOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeBoolExp>;
};


export type QueryRootGmoOffPaymentOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootGmoOffPaymentShopConfigArgs = {
  distinct_on?: InputMaybe<Array<GmoOffPaymentShopConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoOffPaymentShopConfigOrderBy>>;
  where?: InputMaybe<GmoOffPaymentShopConfigBoolExp>;
};


export type QueryRootGmoOffPaymentShopConfigTransactionFeeRateArgs = {
  distinct_on?: InputMaybe<Array<GmoOffPaymentShopConfigTransactionFeeRateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoOffPaymentShopConfigTransactionFeeRateOrderBy>>;
  where?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateBoolExp>;
};


export type QueryRootGmoOffPaymentShopConfigTransactionFeeRateByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootGmoOffPaymentShopConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootInflowSourceTagArgs = {
  distinct_on?: InputMaybe<Array<InflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InflowSourceTagOrderBy>>;
  where?: InputMaybe<InflowSourceTagBoolExp>;
};


export type QueryRootInflowSourceTagAggregateArgs = {
  distinct_on?: InputMaybe<Array<InflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InflowSourceTagOrderBy>>;
  where?: InputMaybe<InflowSourceTagBoolExp>;
};


export type QueryRootInflowSourceTagByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootItemSalesArgs = {
  distinct_on?: InputMaybe<Array<ItemSalesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItemSalesOrderBy>>;
  where?: InputMaybe<ItemSalesBoolExp>;
};


export type QueryRootItemSalesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootKdDisplayItemKdDisplayTargetsKdDisplayTargetArgs = {
  distinct_on?: InputMaybe<Array<KdDisplayItemKdDisplayTargetsKdDisplayTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KdDisplayItemKdDisplayTargetsKdDisplayTargetOrderBy>>;
  where?: InputMaybe<KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp>;
};


export type QueryRootKdDisplayItemKdDisplayTargetsKdDisplayTargetByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootKdDisplayTargetArgs = {
  distinct_on?: InputMaybe<Array<KdDisplayTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KdDisplayTargetOrderBy>>;
  where?: InputMaybe<KdDisplayTargetBoolExp>;
};


export type QueryRootKdDisplayTargetByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLatestCompanyCustomerSummaryByDateArgs = {
  input: CompanyCustomerSummaryInput;
};


export type QueryRootLineChannelConfigArgs = {
  distinct_on?: InputMaybe<Array<LineChannelConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineChannelConfigOrderBy>>;
  where?: InputMaybe<LineChannelConfigBoolExp>;
};


export type QueryRootLineChannelConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLineOfficialAccountArgs = {
  distinct_on?: InputMaybe<Array<LineOfficialAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineOfficialAccountOrderBy>>;
  where?: InputMaybe<LineOfficialAccountBoolExp>;
};


export type QueryRootLineOfficialAccountSummariesArgs = {
  input: QueryLineOfficialAccountSummaryInput;
};


export type QueryRootLineOfficialAccountByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootLineOfficialAccountsArgs = {
  input: QueryLineOfficialAccountInput;
};


export type QueryRootLineProfileArgs = {
  distinct_on?: InputMaybe<Array<LineProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineProfileOrderBy>>;
  where?: InputMaybe<LineProfileBoolExp>;
};


export type QueryRootLineReportingBotConfigArgs = {
  distinct_on?: InputMaybe<Array<LineReportingBotConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineReportingBotConfigOrderBy>>;
  where?: InputMaybe<LineReportingBotConfigBoolExp>;
};


export type QueryRootLineReportingBotConfigShopArgs = {
  distinct_on?: InputMaybe<Array<LineReportingBotConfigShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineReportingBotConfigShopOrderBy>>;
  where?: InputMaybe<LineReportingBotConfigShopBoolExp>;
};


export type QueryRootLineReportingBotConfigShopByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLineReportingBotConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootLineReportingBotVerificationArgs = {
  distinct_on?: InputMaybe<Array<LineReportingBotVerificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineReportingBotVerificationOrderBy>>;
  where?: InputMaybe<LineReportingBotVerificationBoolExp>;
};


export type QueryRootLineReportingBotVerificationByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMembershipCardArgs = {
  distinct_on?: InputMaybe<Array<MembershipCardSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCardOrderBy>>;
  where?: InputMaybe<MembershipCardBoolExp>;
};


export type QueryRootMembershipCardAppearanceTypeArgs = {
  distinct_on?: InputMaybe<Array<MembershipCardAppearanceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCardAppearanceTypeOrderBy>>;
  where?: InputMaybe<MembershipCardAppearanceTypeBoolExp>;
};


export type QueryRootMembershipCardAppearanceTypeByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootMembershipCardTitleArgs = {
  distinct_on?: InputMaybe<Array<MembershipCardTitleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCardTitleOrderBy>>;
  where?: InputMaybe<MembershipCardTitleBoolExp>;
};


export type QueryRootMembershipCardTitleByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMembershipCardByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMembershipRankArgs = {
  distinct_on?: InputMaybe<Array<MembershipRankSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipRankOrderBy>>;
  where?: InputMaybe<MembershipRankBoolExp>;
};


export type QueryRootMembershipRankConfigArgs = {
  distinct_on?: InputMaybe<Array<MembershipRankConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipRankConfigOrderBy>>;
  where?: InputMaybe<MembershipRankConfigBoolExp>;
};


export type QueryRootMembershipRankConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMembershipRankByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootMenuArgs = {
  distinct_on?: InputMaybe<Array<MenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderBy>>;
  where?: InputMaybe<MenuBoolExp>;
};


export type QueryRootMenuOptionArgs = {
  distinct_on?: InputMaybe<Array<MenuOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOptionOrderBy>>;
  where?: InputMaybe<MenuOptionBoolExp>;
};


export type QueryRootMenuOptionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMenuOrderItemArgs = {
  distinct_on?: InputMaybe<Array<MenuOrderItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderItemOrderBy>>;
  where?: InputMaybe<MenuOrderItemBoolExp>;
};


export type QueryRootMenuOrderItemAnalyticsArgs = {
  input: MenuOrderItemAnalyticsInput;
};


export type QueryRootMenuOrderItemChoiceArgs = {
  distinct_on?: InputMaybe<Array<MenuOrderItemChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderItemChoiceOrderBy>>;
  where?: InputMaybe<MenuOrderItemChoiceBoolExp>;
};


export type QueryRootMenuOrderItemChoiceByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMenuOrderItemOptionArgs = {
  distinct_on?: InputMaybe<Array<MenuOrderItemOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderItemOptionOrderBy>>;
  where?: InputMaybe<MenuOrderItemOptionBoolExp>;
};


export type QueryRootMenuOrderItemOptionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMenuOrderItemByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMenuRecommendationMetaArgs = {
  distinct_on?: InputMaybe<Array<MenuRecommendationMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuRecommendationMetaOrderBy>>;
  where?: InputMaybe<MenuRecommendationMetaBoolExp>;
};


export type QueryRootMenuRecommendationMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMenuByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootMessageDeliveryArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliverySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryOrderBy>>;
  where?: InputMaybe<MessageDeliveryBoolExp>;
};


export type QueryRootMessageDeliveryAnalyticsRecentVisitedTableUserReportArgs = {
  input: MessageDeliveryAnalyticsInput;
};


export type QueryRootMessageDeliveryAnalyticsSummaryArgs = {
  input: MessageDeliveryAnalyticsInput;
};


export type QueryRootMessageDeliveryAnalyticsSummaryPerMessageDeliveryArgs = {
  input: MessageDeliveryAnalyticsInput;
};


export type QueryRootMessageDeliveryAnalyticsSummaryPerShopArgs = {
  input: MessageDeliveryAnalyticsInput;
};


export type QueryRootMessageDeliveryCustomerListArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerListSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerListOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerListBoolExp>;
};


export type QueryRootMessageDeliveryCustomerListCustomerArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerListCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerListCustomerOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
};


export type QueryRootMessageDeliveryCustomerListCustomerAggregateArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerListCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerListCustomerOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
};


export type QueryRootMessageDeliveryCustomerListCustomerByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerListByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerSegmentArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentBusinessOperationHoursConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentBusinessOperationHoursConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerSegmentDayOfWeekConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentDayOfWeekConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentDayOfWeekConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekConditionBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentDayOfWeekConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerSegmentGenderArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentGenderBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentGenderConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentGenderConditionBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentGenderConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerSegmentGenderByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootMessageDeliveryCustomerSegmentMenuConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentMenuConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentMenuConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentMenuConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerSegmentNumericConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentNumericConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentNumericConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentNumericConditionBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentNumericConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerSegmentQuestionnaireAnswerConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentQuestionnaireAnswerConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootMessageDeliveryCustomerSegmentShopConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentShopConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentShopConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentShopConditionBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentShopConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerSegmentUnvisitedDayCountConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentUnvisitedDayCountConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerSegmentVisitedDayCountConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentVisitedDayCountConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentVisitedDayCountConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentVisitedDayCountConditionBoolExp>;
};


export type QueryRootMessageDeliveryCustomerSegmentVisitedDayCountConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryCustomerSegmentByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryJobArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryJobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryJobOrderBy>>;
  where?: InputMaybe<MessageDeliveryJobBoolExp>;
};


export type QueryRootMessageDeliveryJobByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryMessageArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageBoolExp>;
};


export type QueryRootMessageDeliveryMessageCouponTypeMetaArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageCouponTypeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageCouponTypeMetaOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageCouponTypeMetaBoolExp>;
};


export type QueryRootMessageDeliveryMessageCouponTypeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryMessageImageTypeMetaArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageImageTypeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageImageTypeMetaOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageImageTypeMetaBoolExp>;
};


export type QueryRootMessageDeliveryMessageImageTypeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryMessageQuestionnaireTypeMetaArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageQuestionnaireTypeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageQuestionnaireTypeMetaOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageQuestionnaireTypeMetaBoolExp>;
};


export type QueryRootMessageDeliveryMessageQuestionnaireTypeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryMessageTextTypeMetaArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageTextTypeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageTextTypeMetaOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageTextTypeMetaBoolExp>;
};


export type QueryRootMessageDeliveryMessageTextTypeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryMessageByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryShopJobArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryShopJobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryShopJobOrderBy>>;
  where?: InputMaybe<MessageDeliveryShopJobBoolExp>;
};


export type QueryRootMessageDeliveryShopJobByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMessageDeliveryByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMonthlySalesBudgetArgs = {
  distinct_on?: InputMaybe<Array<MonthlySalesBudgetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MonthlySalesBudgetOrderBy>>;
  where?: InputMaybe<MonthlySalesBudgetBoolExp>;
};


export type QueryRootMonthlySalesBudgetByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMultipleChoiceTypeQuestionAnswerArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionAnswerOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionAnswerBoolExp>;
};


export type QueryRootMultipleChoiceTypeQuestionAnswerByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMultipleChoiceTypeQuestionChoiceArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionChoiceBoolExp>;
};


export type QueryRootMultipleChoiceTypeQuestionChoiceAnswerArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceAnswerOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerBoolExp>;
};


export type QueryRootMultipleChoiceTypeQuestionChoiceAnswerByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMultipleChoiceTypeQuestionMetaArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionMetaOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionMetaBoolExp>;
};


export type QueryRootMultipleChoiceTypeQuestionMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootOnSitePaymentArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentOrderBy>>;
  where?: InputMaybe<OnSitePaymentBoolExp>;
};


export type QueryRootOnSitePaymentDetailArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailBoolExp>;
};


export type QueryRootOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
};


export type QueryRootOnSitePaymentDetailTypeCategoryPriorityArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailTypeCategoryPrioritySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailTypeCategoryPriorityOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailTypeCategoryPriorityBoolExp>;
};


export type QueryRootOnSitePaymentDetailTypeCategoryPriorityByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootOnSitePaymentDetailTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
};


export type QueryRootOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootOnSitePaymentDetailByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootOnSitePaymentDiscountArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountBoolExp>;
};


export type QueryRootOnSitePaymentDiscountTypeArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
};


export type QueryRootOnSitePaymentDiscountTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
};


export type QueryRootOnSitePaymentDiscountTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootOnSitePaymentDiscountByPkArgs = {
  onSitePaymentDiscountId: Scalars['uuid'];
};


export type QueryRootOnSitePaymentSalesArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentSalesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentSalesOrderBy>>;
  where?: InputMaybe<OnSitePaymentSalesBoolExp>;
};


export type QueryRootOnSitePaymentSalesByPkArgs = {
  onSitePaymentSalesId: Scalars['uuid'];
};


export type QueryRootOnSitePaymentTableUsersArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentTableUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentTableUsersOrderBy>>;
  where?: InputMaybe<OnSitePaymentTableUsersBoolExp>;
};


export type QueryRootOnSitePaymentByPkArgs = {
  onSitePaymentId: Scalars['Int'];
};


export type QueryRootOnlinePaymentPrinterRoleMetaArgs = {
  distinct_on?: InputMaybe<Array<OnlinePaymentPrinterRoleMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlinePaymentPrinterRoleMetaOrderBy>>;
  where?: InputMaybe<OnlinePaymentPrinterRoleMetaBoolExp>;
};


export type QueryRootOnlinePaymentPrinterRoleMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootOptionArgs = {
  distinct_on?: InputMaybe<Array<OptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OptionOrderBy>>;
  where?: InputMaybe<OptionBoolExp>;
};


export type QueryRootOptionInputTypeArgs = {
  distinct_on?: InputMaybe<Array<OptionInputTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OptionInputTypeOrderBy>>;
  where?: InputMaybe<OptionInputTypeBoolExp>;
};


export type QueryRootOptionInputTypeByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootOptionAggregateArgs = {
  distinct_on?: InputMaybe<Array<OptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OptionOrderBy>>;
  where?: InputMaybe<OptionBoolExp>;
};


export type QueryRootOptionByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootOrderArgs = {
  distinct_on?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


export type QueryRootOrderByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootOrderableTimeArgs = {
  distinct_on?: InputMaybe<Array<OrderableTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrderableTimeOrderBy>>;
  where?: InputMaybe<OrderableTimeBoolExp>;
};


export type QueryRootOrderableTimeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootPayingActivePlanChoiceArgs = {
  distinct_on?: InputMaybe<Array<PayingActivePlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingActivePlanChoiceOrderBy>>;
  where?: InputMaybe<PayingActivePlanChoiceBoolExp>;
};


export type QueryRootPayingActivePlanChoiceByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootPayingActivePlanOpenPriceMetaArgs = {
  distinct_on?: InputMaybe<Array<PayingActivePlanOpenPriceMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingActivePlanOpenPriceMetaOrderBy>>;
  where?: InputMaybe<PayingActivePlanOpenPriceMetaBoolExp>;
};


export type QueryRootPayingActivePlanOpenPriceMetaByPkArgs = {
  payingActivePlanOpenPriceMetaId: Scalars['uuid'];
};


export type QueryRootPayingMenuOrderItemArgs = {
  distinct_on?: InputMaybe<Array<PayingMenuOrderItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingMenuOrderItemOrderBy>>;
  where?: InputMaybe<PayingMenuOrderItemBoolExp>;
};


export type QueryRootPayingMenuOrderItemByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootPaymentReceiptRoleTableArgs = {
  distinct_on?: InputMaybe<Array<PaymentReceiptRoleTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentReceiptRoleTableOrderBy>>;
  where?: InputMaybe<PaymentReceiptRoleTableBoolExp>;
};


export type QueryRootPikaichiConfigArgs = {
  distinct_on?: InputMaybe<Array<PikaichiConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiConfigOrderBy>>;
  where?: InputMaybe<PikaichiConfigBoolExp>;
};


export type QueryRootPikaichiConfigByPkArgs = {
  pikaichiConfigId: Scalars['uuid'];
};


export type QueryRootPikaichiMenuArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuOrderBy>>;
  where?: InputMaybe<PikaichiMenuBoolExp>;
};


export type QueryRootPikaichiMenuChoiceArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuChoiceOrderBy>>;
  where?: InputMaybe<PikaichiMenuChoiceBoolExp>;
};


export type QueryRootPikaichiMenuChoiceByPkArgs = {
  pikaichiMenuChoiceId: Scalars['uuid'];
};


export type QueryRootPikaichiMenuMenuArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuMenuOrderBy>>;
  where?: InputMaybe<PikaichiMenuMenuBoolExp>;
};


export type QueryRootPikaichiMenuMenuByPkArgs = {
  pikaichiMenuMenuId: Scalars['uuid'];
};


export type QueryRootPikaichiMenuPlanArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuPlanOrderBy>>;
  where?: InputMaybe<PikaichiMenuPlanBoolExp>;
};


export type QueryRootPikaichiMenuPlanChoiceArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuPlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuPlanChoiceOrderBy>>;
  where?: InputMaybe<PikaichiMenuPlanChoiceBoolExp>;
};


export type QueryRootPikaichiMenuPlanChoiceByPkArgs = {
  pikaichiMenuPlanChoiceId: Scalars['uuid'];
};


export type QueryRootPikaichiMenuPlanByPkArgs = {
  pikaichiMenuPlanId: Scalars['uuid'];
};


export type QueryRootPikaichiMenuByPkArgs = {
  pikaichiMenuId: Scalars['uuid'];
};


export type QueryRootPikaichiOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<PikaichiOnSitePaymentDetailTypeBoolExp>;
};


export type QueryRootPikaichiOnSitePaymentDetailTypeByPkArgs = {
  pikaichiPaymentDetailTypeId: Scalars['uuid'];
};


export type QueryRootPikaichiOnSitePaymentDiscountTypeArgs = {
  distinct_on?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<PikaichiOnSitePaymentDiscountTypeBoolExp>;
};


export type QueryRootPikaichiOnSitePaymentDiscountTypeByPkArgs = {
  pikaichiDiscountTypeId: Scalars['uuid'];
};


export type QueryRootPlanArgs = {
  distinct_on?: InputMaybe<Array<PlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOrderBy>>;
  where?: InputMaybe<PlanBoolExp>;
};


export type QueryRootPlanChoiceArgs = {
  distinct_on?: InputMaybe<Array<PlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanChoiceOrderBy>>;
  where?: InputMaybe<PlanChoiceBoolExp>;
};


export type QueryRootPlanChoiceAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanChoiceOrderBy>>;
  where?: InputMaybe<PlanChoiceBoolExp>;
};


export type QueryRootPlanChoiceByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootPlanFirstOrderCategoryMenuArgs = {
  distinct_on?: InputMaybe<Array<PlanFirstOrderCategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanFirstOrderCategoryMenuOrderBy>>;
  where?: InputMaybe<PlanFirstOrderCategoryMenuBoolExp>;
};


export type QueryRootPlanFirstOrderCategoryMenuByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootPlanGroupArgs = {
  distinct_on?: InputMaybe<Array<PlanGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanGroupOrderBy>>;
  where?: InputMaybe<PlanGroupBoolExp>;
};


export type QueryRootPlanGroupByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootPlanMenuCategoryArgs = {
  distinct_on?: InputMaybe<Array<PlanMenuCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanMenuCategoryOrderBy>>;
  where?: InputMaybe<PlanMenuCategoryBoolExp>;
};


export type QueryRootPlanMenuCategoryByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootPlanOptionArgs = {
  distinct_on?: InputMaybe<Array<PlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOptionOrderBy>>;
  where?: InputMaybe<PlanOptionBoolExp>;
};


export type QueryRootPlanOptionAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOptionOrderBy>>;
  where?: InputMaybe<PlanOptionBoolExp>;
};


export type QueryRootPlanOptionByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootPlanByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootPlanMenuCategoriesCategoryArgs = {
  distinct_on?: InputMaybe<Array<PlanMenuCategoriesCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanMenuCategoriesCategoryOrderBy>>;
  where?: InputMaybe<PlanMenuCategoriesCategoryBoolExp>;
};


export type QueryRootQuantityAndSalesAggregationByActivePlanAndMenuOrderItemArgs = {
  input: ShopApiAggregationGetQuantityAndSalesAggregationInput;
};


export type QueryRootQuantityAndSalesAggregationByActivePlanAndMenuOrderItemByShopIdArgs = {
  input: ShopApiAggregationGetQuantityAndSalesAggregationInputWithShopId;
};


export type QueryRootQuantityAndSalesAggregationByCategoryByShopIdArgs = {
  input: ShopApiAggregationGetQuantityAndSalesAggregationInputWithShopId;
};


export type QueryRootQuestionArgs = {
  distinct_on?: InputMaybe<Array<QuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionOrderBy>>;
  where?: InputMaybe<QuestionBoolExp>;
};


export type QueryRootQuestionAnswerArgs = {
  distinct_on?: InputMaybe<Array<QuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionAnswerOrderBy>>;
  where?: InputMaybe<QuestionAnswerBoolExp>;
};


export type QueryRootQuestionAnswerByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootQuestionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootQuestionnaireArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireOrderBy>>;
  where?: InputMaybe<QuestionnaireBoolExp>;
};


export type QueryRootQuestionnaireAnswerArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireAnswerOrderBy>>;
  where?: InputMaybe<QuestionnaireAnswerBoolExp>;
};


export type QueryRootQuestionnaireAnswerByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootQuestionnaireAnswererAttributeMetricsArgs = {
  input: QuestionnaireAnswererAttributeMetricsInput;
};


export type QueryRootQuestionnaireCommentsArgs = {
  input: QuestionnaireCommentsInput;
};


export type QueryRootQuestionnaireCommentsCountArgs = {
  input: QuestionnaireCommentsInput;
};


export type QueryRootQuestionnaireConfigArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireConfigOrderBy>>;
  where?: InputMaybe<QuestionnaireConfigBoolExp>;
};


export type QueryRootQuestionnaireConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootQuestionnaireMetricsPerCategoryArgs = {
  input: QuestionnaireMetricsPerCategoryInput;
};


export type QueryRootQuestionnaireQuestionArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireQuestionOrderBy>>;
  where?: InputMaybe<QuestionnaireQuestionBoolExp>;
};


export type QueryRootQuestionnaireScoreChangesMetricsArgs = {
  input: QuestionnaireScoreChangesMetricsInput;
};


export type QueryRootQuestionnaireScoreMetricsArgs = {
  input: QuestionnaireScoreMetricsInput;
};


export type QueryRootQuestionnaireByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootReceiptArgs = {
  distinct_on?: InputMaybe<Array<ReceiptSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReceiptOrderBy>>;
  where?: InputMaybe<ReceiptBoolExp>;
};


export type QueryRootReceiptByPkArgs = {
  receiptId: Scalars['uuid'];
};


export type QueryRootReservationDashboardAccountAccessibleShopArgs = {
  distinct_on?: InputMaybe<Array<ReservationDashboardAccountAccessibleShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReservationDashboardAccountAccessibleShopOrderBy>>;
  where?: InputMaybe<ReservationDashboardAccountAccessibleShopBoolExp>;
};


export type QueryRootReservationDashboardAccountAccessibleShopByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootRoleArgs = {
  distinct_on?: InputMaybe<Array<RoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleOrderBy>>;
  where?: InputMaybe<RoleBoolExp>;
};


export type QueryRootRoleTablesTableArgs = {
  distinct_on?: InputMaybe<Array<RoleTablesTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleTablesTableOrderBy>>;
  where?: InputMaybe<RoleTablesTableBoolExp>;
};


export type QueryRootRoleByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootSalesArgs = {
  input: QuerySalesInput;
};


export type QueryRootSalesAnalyticsArgs = {
  input: SalesAnalyticsInput;
};


export type QueryRootSalesBreakdownByPaymentMethodArgs = {
  input: QuerySalesBreakdownByPaymentMethodInput;
};


export type QueryRootSalesBudgetAllocationSettingArgs = {
  distinct_on?: InputMaybe<Array<SalesBudgetAllocationSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesBudgetAllocationSettingOrderBy>>;
  where?: InputMaybe<SalesBudgetAllocationSettingBoolExp>;
};


export type QueryRootSalesBudgetAllocationSettingByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootSalesByDayOfWeekAndHourAnalyticsArgs = {
  input: SalesByDayOfWeekAndHourAnalyticsInput;
};


export type QueryRootSalesForBudgetArgs = {
  input: SalesForBudgetInput;
};


export type QueryRootScoreTypeQuestionAnswerArgs = {
  distinct_on?: InputMaybe<Array<ScoreTypeQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScoreTypeQuestionAnswerOrderBy>>;
  where?: InputMaybe<ScoreTypeQuestionAnswerBoolExp>;
};


export type QueryRootServiceAdminArgs = {
  distinct_on?: InputMaybe<Array<ServiceAdminSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceAdminOrderBy>>;
  where?: InputMaybe<ServiceAdminBoolExp>;
};


export type QueryRootServiceAdminByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootServiceChargeConfigArgs = {
  distinct_on?: InputMaybe<Array<ServiceChargeConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceChargeConfigOrderBy>>;
  where?: InputMaybe<ServiceChargeConfigBoolExp>;
};


export type QueryRootServiceChargeConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootServiceChargeMetaArgs = {
  distinct_on?: InputMaybe<Array<ServiceChargeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceChargeMetaOrderBy>>;
  where?: InputMaybe<ServiceChargeMetaBoolExp>;
};


export type QueryRootServiceChargeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootShopArgs = {
  distinct_on?: InputMaybe<Array<ShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOrderBy>>;
  where?: InputMaybe<ShopBoolExp>;
};


export type QueryRootShopBusinessOperationHourArgs = {
  distinct_on?: InputMaybe<Array<ShopBusinessOperationHourSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopBusinessOperationHourOrderBy>>;
  where?: InputMaybe<ShopBusinessOperationHourBoolExp>;
};


export type QueryRootShopBusinessOperationHourByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootShopChoiceArgs = {
  distinct_on?: InputMaybe<Array<ShopChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopChoiceOrderBy>>;
  where?: InputMaybe<ShopChoiceBoolExp>;
};


export type QueryRootShopChoiceByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootShopClerkArgs = {
  distinct_on?: InputMaybe<Array<ShopClerkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopClerkOrderBy>>;
  where?: InputMaybe<ShopClerkBoolExp>;
};


export type QueryRootShopClerkByPkArgs = {
  _clerkId: Scalars['Int'];
  shopId: Scalars['uuid'];
};


export type QueryRootShopClientArgs = {
  distinct_on?: InputMaybe<Array<ShopClientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopClientOrderBy>>;
  where?: InputMaybe<ShopClientBoolExp>;
};


export type QueryRootShopClientByPkArgs = {
  shopClientId: Scalars['String'];
};


export type QueryRootShopCookingItemRoleArgs = {
  distinct_on?: InputMaybe<Array<ShopCookingItemRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopCookingItemRoleOrderBy>>;
  where?: InputMaybe<ShopCookingItemRoleBoolExp>;
};


export type QueryRootShopCookingItemRoleByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootShopDiscountTypeArgs = {
  distinct_on?: InputMaybe<Array<ShopDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopDiscountTypeOrderBy>>;
  where?: InputMaybe<ShopDiscountTypeBoolExp>;
};


export type QueryRootShopDiscountTypeByPkArgs = {
  shopId: Scalars['uuid'];
  type: Scalars['String'];
};


export type QueryRootShopInflowSourceTagArgs = {
  distinct_on?: InputMaybe<Array<ShopInflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopInflowSourceTagOrderBy>>;
  where?: InputMaybe<ShopInflowSourceTagBoolExp>;
};


export type QueryRootShopMemberArgs = {
  distinct_on?: InputMaybe<Array<ShopMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMemberOrderBy>>;
  where?: InputMaybe<ShopMemberBoolExp>;
};


export type QueryRootShopMenuArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuOrderBy>>;
  where?: InputMaybe<ShopMenuBoolExp>;
};


export type QueryRootShopMenuCookingItemArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuCookingItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuCookingItemOrderBy>>;
  where?: InputMaybe<ShopMenuCookingItemBoolExp>;
};


export type QueryRootShopMenuKdDisplayTargetArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuKdDisplayTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuKdDisplayTargetOrderBy>>;
  where?: InputMaybe<ShopMenuKdDisplayTargetBoolExp>;
};


export type QueryRootShopMenuKdDisplayTargetByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootShopMenuKitchenRoleArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
};


export type QueryRootShopMenuByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootShopOptionKitchenRoleArgs = {
  distinct_on?: InputMaybe<Array<ShopOptionKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOptionKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopOptionKitchenRoleBoolExp>;
};


export type QueryRootShopOptionKitchenRoleByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootShopOrderableTimeTermArgs = {
  distinct_on?: InputMaybe<Array<ShopOrderableTimeTermSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOrderableTimeTermOrderBy>>;
  where?: InputMaybe<ShopOrderableTimeTermBoolExp>;
};


export type QueryRootShopOrderableTimeTermByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootShopPaymentTypeArgs = {
  distinct_on?: InputMaybe<Array<ShopPaymentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPaymentTypeOrderBy>>;
  where?: InputMaybe<ShopPaymentTypeBoolExp>;
};


export type QueryRootShopPaymentTypeByPkArgs = {
  shopId: Scalars['uuid'];
  type: Scalars['String'];
};


export type QueryRootShopPlanArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOrderBy>>;
  where?: InputMaybe<ShopPlanBoolExp>;
};


export type QueryRootShopPlanChoiceArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanChoiceOrderBy>>;
  where?: InputMaybe<ShopPlanChoiceBoolExp>;
};


export type QueryRootShopPlanChoiceByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootShopPlanKitchenRoleArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
};


export type QueryRootShopPlanOptionArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOptionOrderBy>>;
  where?: InputMaybe<ShopPlanOptionBoolExp>;
};


export type QueryRootShopPlanOptionKitchenRoleArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanOptionKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOptionKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopPlanOptionKitchenRoleBoolExp>;
};


export type QueryRootShopPlanOptionByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootShopPlanByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootShopByPkArgs = {
  shopId: Scalars['uuid'];
};


export type QueryRootSpecificTimeSurchargeConfigArgs = {
  distinct_on?: InputMaybe<Array<SpecificTimeSurchargeConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SpecificTimeSurchargeConfigOrderBy>>;
  where?: InputMaybe<SpecificTimeSurchargeConfigBoolExp>;
};


export type QueryRootSpecificTimeSurchargeConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootSpecificTimeSurchargeMetaArgs = {
  distinct_on?: InputMaybe<Array<SpecificTimeSurchargeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SpecificTimeSurchargeMetaOrderBy>>;
  where?: InputMaybe<SpecificTimeSurchargeMetaBoolExp>;
};


export type QueryRootSpecificTimeSurchargeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootSteraOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<SteraOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SteraOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<SteraOnSitePaymentDetailTypeBoolExp>;
};


export type QueryRootSteraOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootStockArgs = {
  distinct_on?: InputMaybe<Array<StockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StockOrderBy>>;
  where?: InputMaybe<StockBoolExp>;
};


export type QueryRootStockByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootSupportedLocaleArgs = {
  distinct_on?: InputMaybe<Array<SupportedLocaleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SupportedLocaleOrderBy>>;
  where?: InputMaybe<SupportedLocaleBoolExp>;
};


export type QueryRootSupportedLocaleByPkArgs = {
  value: Scalars['String'];
};


export type QueryRootTableArgs = {
  distinct_on?: InputMaybe<Array<TableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableOrderBy>>;
  where?: InputMaybe<TableBoolExp>;
};


export type QueryRootTableAreaArgs = {
  distinct_on?: InputMaybe<Array<TableAreaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableAreaOrderBy>>;
  where?: InputMaybe<TableAreaBoolExp>;
};


export type QueryRootTableAreaAggregateArgs = {
  distinct_on?: InputMaybe<Array<TableAreaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableAreaOrderBy>>;
  where?: InputMaybe<TableAreaBoolExp>;
};


export type QueryRootTableAreaByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTableUserArgs = {
  distinct_on?: InputMaybe<Array<TableUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserOrderBy>>;
  where?: InputMaybe<TableUserBoolExp>;
};


export type QueryRootTableUserCustomerArgs = {
  distinct_on?: InputMaybe<Array<TableUserCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserCustomerOrderBy>>;
  where?: InputMaybe<TableUserCustomerBoolExp>;
};


export type QueryRootTableUserCustomerByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTableUserByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTableAggregateArgs = {
  distinct_on?: InputMaybe<Array<TableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableOrderBy>>;
  where?: InputMaybe<TableBoolExp>;
};


export type QueryRootTableByPkArgs = {
  serial: Scalars['Int'];
};


export type QueryRootTaxOfficeArgs = {
  distinct_on?: InputMaybe<Array<TaxOfficeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaxOfficeOrderBy>>;
  where?: InputMaybe<TaxOfficeBoolExp>;
};


export type QueryRootTaxOfficeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTecAggregationConfigArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationConfigOrderBy>>;
  where?: InputMaybe<TecAggregationConfigBoolExp>;
};


export type QueryRootTecAggregationConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTecAggregationMediaMapArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationMediaMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationMediaMapOrderBy>>;
  where?: InputMaybe<TecAggregationMediaMapBoolExp>;
};


export type QueryRootTecAggregationMediaMapByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTecAggregationMenuArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationMenuOrderBy>>;
  where?: InputMaybe<TecAggregationMenuBoolExp>;
};


export type QueryRootTecAggregationMenuByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTecAggregationOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
};


export type QueryRootTecAggregationOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTextTypeQuestionAnswerArgs = {
  distinct_on?: InputMaybe<Array<TextTypeQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TextTypeQuestionAnswerOrderBy>>;
  where?: InputMaybe<TextTypeQuestionAnswerBoolExp>;
};


export type QueryRootTranslationArgs = {
  distinct_on?: InputMaybe<Array<TranslationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TranslationOrderBy>>;
  where?: InputMaybe<TranslationBoolExp>;
};


export type QueryRootTranslationByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootValidLineOfficialAccountsArgs = {
  input: ValidLineOfficialAccountInput;
};


export type QueryRootWinboardConfigArgs = {
  distinct_on?: InputMaybe<Array<WinboardConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WinboardConfigOrderBy>>;
  where?: InputMaybe<WinboardConfigBoolExp>;
};


export type QueryRootWinboardConfigByPkArgs = {
  winboardConfigId: Scalars['uuid'];
};


export type QueryRootWinboardInflowSourceTagArgs = {
  distinct_on?: InputMaybe<Array<WinboardInflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WinboardInflowSourceTagOrderBy>>;
  where?: InputMaybe<WinboardInflowSourceTagBoolExp>;
};


export type QueryRootWinboardInflowSourceTagByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootWinboardMenuArgs = {
  distinct_on?: InputMaybe<Array<WinboardMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WinboardMenuOrderBy>>;
  where?: InputMaybe<WinboardMenuBoolExp>;
};


export type QueryRootWinboardMenuByPkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "question" */
export type Question = {
  __typename?: 'question';
  category: QuestionCategoryEnum;
  /** An object relationship */
  company?: Maybe<Company>;
  id: Scalars['uuid'];
  /** An object relationship */
  multipleChoiceTypeQuestionMeta?: Maybe<MultipleChoiceTypeQuestionMeta>;
  name: Scalars['String'];
  /** An array relationship */
  questionAnswers: Array<QuestionAnswer>;
  /** An array relationship */
  questionnaireQuestions: Array<QuestionnaireQuestion>;
  required: Scalars['Boolean'];
  type: QuestionTypeEnum;
};


/** columns and relationships of "question" */
export type QuestionQuestionAnswersArgs = {
  distinct_on?: InputMaybe<Array<QuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionAnswerOrderBy>>;
  where?: InputMaybe<QuestionAnswerBoolExp>;
};


/** columns and relationships of "question" */
export type QuestionQuestionnaireQuestionsArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireQuestionOrderBy>>;
  where?: InputMaybe<QuestionnaireQuestionBoolExp>;
};

/** columns and relationships of "questionAnswer" */
export type QuestionAnswer = {
  __typename?: 'questionAnswer';
  /** An object relationship */
  birthdayTypeQuestionAnswer?: Maybe<BirthdayTypeQuestionAnswer>;
  id: Scalars['uuid'];
  /** An object relationship */
  multipleChoiceTypeQuestionAnswer?: Maybe<MultipleChoiceTypeQuestionAnswer>;
  multipleChoiceTypeQuestionAnswerId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  question: Question;
  questionId: Scalars['uuid'];
  /** An object relationship */
  questionnaireAnswer: QuestionnaireAnswer;
  /** An object relationship */
  scoreTypeQuestionAnswer?: Maybe<ScoreTypeQuestionAnswer>;
  /** An object relationship */
  textTypeQuestionAnswer?: Maybe<TextTypeQuestionAnswer>;
};

/** order by aggregate values of table "questionAnswer" */
export type QuestionAnswerAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<QuestionAnswerMaxOrderBy>;
  min?: InputMaybe<QuestionAnswerMinOrderBy>;
};

/** Boolean expression to filter rows from the table "questionAnswer". All fields are combined with a logical 'AND'. */
export type QuestionAnswerBoolExp = {
  _and?: InputMaybe<Array<QuestionAnswerBoolExp>>;
  _not?: InputMaybe<QuestionAnswerBoolExp>;
  _or?: InputMaybe<Array<QuestionAnswerBoolExp>>;
  birthdayTypeQuestionAnswer?: InputMaybe<BirthdayTypeQuestionAnswerBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  multipleChoiceTypeQuestionAnswer?: InputMaybe<MultipleChoiceTypeQuestionAnswerBoolExp>;
  multipleChoiceTypeQuestionAnswerId?: InputMaybe<UuidComparisonExp>;
  question?: InputMaybe<QuestionBoolExp>;
  questionId?: InputMaybe<UuidComparisonExp>;
  questionnaireAnswer?: InputMaybe<QuestionnaireAnswerBoolExp>;
  scoreTypeQuestionAnswer?: InputMaybe<ScoreTypeQuestionAnswerBoolExp>;
  textTypeQuestionAnswer?: InputMaybe<TextTypeQuestionAnswerBoolExp>;
};

/** order by max() on columns of table "questionAnswer" */
export type QuestionAnswerMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  multipleChoiceTypeQuestionAnswerId?: InputMaybe<OrderBy>;
  questionId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "questionAnswer" */
export type QuestionAnswerMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  multipleChoiceTypeQuestionAnswerId?: InputMaybe<OrderBy>;
  questionId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "questionAnswer". */
export type QuestionAnswerOrderBy = {
  birthdayTypeQuestionAnswer?: InputMaybe<BirthdayTypeQuestionAnswerOrderBy>;
  id?: InputMaybe<OrderBy>;
  multipleChoiceTypeQuestionAnswer?: InputMaybe<MultipleChoiceTypeQuestionAnswerOrderBy>;
  multipleChoiceTypeQuestionAnswerId?: InputMaybe<OrderBy>;
  question?: InputMaybe<QuestionOrderBy>;
  questionId?: InputMaybe<OrderBy>;
  questionnaireAnswer?: InputMaybe<QuestionnaireAnswerOrderBy>;
  scoreTypeQuestionAnswer?: InputMaybe<ScoreTypeQuestionAnswerOrderBy>;
  textTypeQuestionAnswer?: InputMaybe<TextTypeQuestionAnswerOrderBy>;
};

/** select columns of table "questionAnswer" */
export enum QuestionAnswerSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MultipleChoiceTypeQuestionAnswerId = 'multipleChoiceTypeQuestionAnswerId',
  /** column name */
  QuestionId = 'questionId'
}

/** Streaming cursor of the table "questionAnswer" */
export type QuestionAnswerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: QuestionAnswerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionAnswerStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  multipleChoiceTypeQuestionAnswerId?: InputMaybe<Scalars['uuid']>;
  questionId?: InputMaybe<Scalars['uuid']>;
};

export enum QuestionCategoryEnum {
  Attribute = 'attribute',
  Cleanliness = 'cleanliness',
  CustomerService = 'customerService',
  Deliciousness = 'deliciousness',
  Repeatability = 'repeatability',
  Speed = 'speed'
}

/** Boolean expression to compare columns of type "questionCategory_enum". All fields are combined with logical 'AND'. */
export type QuestionCategoryEnumComparisonExp = {
  _eq?: InputMaybe<QuestionCategoryEnum>;
  _in?: InputMaybe<Array<QuestionCategoryEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<QuestionCategoryEnum>;
  _nin?: InputMaybe<Array<QuestionCategoryEnum>>;
};

export enum QuestionTypeEnum {
  Birthday = 'birthday',
  MultipleChoice = 'multipleChoice',
  Score = 'score',
  Text = 'text'
}

/** Boolean expression to compare columns of type "questionType_enum". All fields are combined with logical 'AND'. */
export type QuestionTypeEnumComparisonExp = {
  _eq?: InputMaybe<QuestionTypeEnum>;
  _in?: InputMaybe<Array<QuestionTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<QuestionTypeEnum>;
  _nin?: InputMaybe<Array<QuestionTypeEnum>>;
};

/** order by aggregate values of table "question" */
export type QuestionAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<QuestionMaxOrderBy>;
  min?: InputMaybe<QuestionMinOrderBy>;
};

/** Boolean expression to filter rows from the table "question". All fields are combined with a logical 'AND'. */
export type QuestionBoolExp = {
  _and?: InputMaybe<Array<QuestionBoolExp>>;
  _not?: InputMaybe<QuestionBoolExp>;
  _or?: InputMaybe<Array<QuestionBoolExp>>;
  category?: InputMaybe<QuestionCategoryEnumComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  multipleChoiceTypeQuestionMeta?: InputMaybe<MultipleChoiceTypeQuestionMetaBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  questionAnswers?: InputMaybe<QuestionAnswerBoolExp>;
  questionnaireQuestions?: InputMaybe<QuestionnaireQuestionBoolExp>;
  required?: InputMaybe<BooleanComparisonExp>;
  type?: InputMaybe<QuestionTypeEnumComparisonExp>;
};

/** order by max() on columns of table "question" */
export type QuestionMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "question" */
export type QuestionMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "question". */
export type QuestionOrderBy = {
  category?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  id?: InputMaybe<OrderBy>;
  multipleChoiceTypeQuestionMeta?: InputMaybe<MultipleChoiceTypeQuestionMetaOrderBy>;
  name?: InputMaybe<OrderBy>;
  questionAnswers_aggregate?: InputMaybe<QuestionAnswerAggregateOrderBy>;
  questionnaireQuestions_aggregate?: InputMaybe<QuestionnaireQuestionAggregateOrderBy>;
  required?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** select columns of table "question" */
export enum QuestionSelectColumn {
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Required = 'required',
  /** column name */
  Type = 'type'
}

/** Streaming cursor of the table "question" */
export type QuestionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: QuestionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionStreamCursorValueInput = {
  category?: InputMaybe<QuestionCategoryEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<QuestionTypeEnum>;
};

/** columns and relationships of "questionnaire" */
export type Questionnaire = {
  __typename?: 'questionnaire';
  /** An array relationship */
  customerQuestionnaires: Array<CustomerQuestionnaire>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  questionnaireAnswers: Array<QuestionnaireAnswer>;
  /** An array relationship */
  questionnaireConfigs: Array<QuestionnaireConfig>;
  /** An array relationship */
  questionnaireQuestions: Array<QuestionnaireQuestion>;
};


/** columns and relationships of "questionnaire" */
export type QuestionnaireCustomerQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<CustomerQuestionnaireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerQuestionnaireOrderBy>>;
  where?: InputMaybe<CustomerQuestionnaireBoolExp>;
};


/** columns and relationships of "questionnaire" */
export type QuestionnaireQuestionnaireAnswersArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireAnswerOrderBy>>;
  where?: InputMaybe<QuestionnaireAnswerBoolExp>;
};


/** columns and relationships of "questionnaire" */
export type QuestionnaireQuestionnaireConfigsArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireConfigOrderBy>>;
  where?: InputMaybe<QuestionnaireConfigBoolExp>;
};


/** columns and relationships of "questionnaire" */
export type QuestionnaireQuestionnaireQuestionsArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireQuestionOrderBy>>;
  where?: InputMaybe<QuestionnaireQuestionBoolExp>;
};

/** columns and relationships of "questionnaireAnswer" */
export type QuestionnaireAnswer = {
  __typename?: 'questionnaireAnswer';
  answeredAt: Scalars['timestamptz'];
  /** An object relationship */
  customer: Customer;
  /** An object relationship */
  customerQuestionnaire: CustomerQuestionnaire;
  id: Scalars['uuid'];
  /** An array relationship */
  questionAnswers: Array<QuestionAnswer>;
  /** An object relationship */
  questionnaire?: Maybe<Questionnaire>;
  questionnaireId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  visitedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "questionnaireAnswer" */
export type QuestionnaireAnswerQuestionAnswersArgs = {
  distinct_on?: InputMaybe<Array<QuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionAnswerOrderBy>>;
  where?: InputMaybe<QuestionAnswerBoolExp>;
};

/** order by aggregate values of table "questionnaireAnswer" */
export type QuestionnaireAnswerAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<QuestionnaireAnswerMaxOrderBy>;
  min?: InputMaybe<QuestionnaireAnswerMinOrderBy>;
};

/** Boolean expression to filter rows from the table "questionnaireAnswer". All fields are combined with a logical 'AND'. */
export type QuestionnaireAnswerBoolExp = {
  _and?: InputMaybe<Array<QuestionnaireAnswerBoolExp>>;
  _not?: InputMaybe<QuestionnaireAnswerBoolExp>;
  _or?: InputMaybe<Array<QuestionnaireAnswerBoolExp>>;
  answeredAt?: InputMaybe<TimestamptzComparisonExp>;
  customer?: InputMaybe<CustomerBoolExp>;
  customerQuestionnaire?: InputMaybe<CustomerQuestionnaireBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  questionAnswers?: InputMaybe<QuestionAnswerBoolExp>;
  questionnaire?: InputMaybe<QuestionnaireBoolExp>;
  questionnaireId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  visitedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** order by max() on columns of table "questionnaireAnswer" */
export type QuestionnaireAnswerMaxOrderBy = {
  answeredAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  questionnaireId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  visitedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "questionnaireAnswer" */
export type QuestionnaireAnswerMinOrderBy = {
  answeredAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  questionnaireId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  visitedAt?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "questionnaireAnswer". */
export type QuestionnaireAnswerOrderBy = {
  answeredAt?: InputMaybe<OrderBy>;
  customer?: InputMaybe<CustomerOrderBy>;
  customerQuestionnaire?: InputMaybe<CustomerQuestionnaireOrderBy>;
  id?: InputMaybe<OrderBy>;
  questionAnswers_aggregate?: InputMaybe<QuestionAnswerAggregateOrderBy>;
  questionnaire?: InputMaybe<QuestionnaireOrderBy>;
  questionnaireId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  visitedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "questionnaireAnswer" */
export enum QuestionnaireAnswerSelectColumn {
  /** column name */
  AnsweredAt = 'answeredAt',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaireId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  VisitedAt = 'visitedAt'
}

/** Streaming cursor of the table "questionnaireAnswer" */
export type QuestionnaireAnswerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: QuestionnaireAnswerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionnaireAnswerStreamCursorValueInput = {
  answeredAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questionnaireId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  visitedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "questionnaireConfig" */
export type QuestionnaireConfig = {
  __typename?: 'questionnaireConfig';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  /** An object relationship */
  coupon?: Maybe<Coupon>;
  id: Scalars['uuid'];
  imageUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  questionnaire: Questionnaire;
  questionnaireId: Scalars['uuid'];
  rewardCouponId?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "questionnaireConfig" */
export type QuestionnaireConfigAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<QuestionnaireConfigMaxOrderBy>;
  min?: InputMaybe<QuestionnaireConfigMinOrderBy>;
};

/** input type for inserting array relation for remote table "questionnaireConfig" */
export type QuestionnaireConfigArrRelInsertInput = {
  data: Array<QuestionnaireConfigInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionnaireConfigOnConflict>;
};

/** Boolean expression to filter rows from the table "questionnaireConfig". All fields are combined with a logical 'AND'. */
export type QuestionnaireConfigBoolExp = {
  _and?: InputMaybe<Array<QuestionnaireConfigBoolExp>>;
  _not?: InputMaybe<QuestionnaireConfigBoolExp>;
  _or?: InputMaybe<Array<QuestionnaireConfigBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  coupon?: InputMaybe<CouponBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  imageUrl?: InputMaybe<StringComparisonExp>;
  questionnaire?: InputMaybe<QuestionnaireBoolExp>;
  questionnaireId?: InputMaybe<UuidComparisonExp>;
  rewardCouponId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "questionnaireConfig" */
export enum QuestionnaireConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnaireConfigPkey = 'questionnaireConfig_pkey',
  /** unique or primary key constraint on columns "questionnaireId", "_companyId" */
  QuestionnaireConfigQuestionnaireIdCompanyIdKey = 'questionnaireConfig_questionnaireId_companyId_key',
  /** unique or primary key constraint on columns "questionnaireId", "companyId" */
  QuestionnaireConfigQuestionnaireIdCompanyUuidKey = 'questionnaireConfig_questionnaireId_companyUuid_key'
}

/** input type for inserting data into table "questionnaireConfig" */
export type QuestionnaireConfigInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  coupon?: InputMaybe<CouponObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  questionnaire?: InputMaybe<QuestionnaireObjRelInsertInput>;
  questionnaireId: Scalars['uuid'];
  rewardCouponId?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "questionnaireConfig" */
export type QuestionnaireConfigMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  questionnaireId?: InputMaybe<OrderBy>;
  rewardCouponId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "questionnaireConfig" */
export type QuestionnaireConfigMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  questionnaireId?: InputMaybe<OrderBy>;
  rewardCouponId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "questionnaireConfig" */
export type QuestionnaireConfigMutationResponse = {
  __typename?: 'questionnaireConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionnaireConfig>;
};

/** on_conflict condition type for table "questionnaireConfig" */
export type QuestionnaireConfigOnConflict = {
  constraint: QuestionnaireConfigConstraint;
  update_columns?: Array<QuestionnaireConfigUpdateColumn>;
  where?: InputMaybe<QuestionnaireConfigBoolExp>;
};

/** Ordering options when selecting data from "questionnaireConfig". */
export type QuestionnaireConfigOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  coupon?: InputMaybe<CouponOrderBy>;
  id?: InputMaybe<OrderBy>;
  imageUrl?: InputMaybe<OrderBy>;
  questionnaire?: InputMaybe<QuestionnaireOrderBy>;
  questionnaireId?: InputMaybe<OrderBy>;
  rewardCouponId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: questionnaireConfig */
export type QuestionnaireConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "questionnaireConfig" */
export enum QuestionnaireConfigSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  QuestionnaireId = 'questionnaireId',
  /** column name */
  RewardCouponId = 'rewardCouponId'
}

/** input type for updating data in table "questionnaireConfig" */
export type QuestionnaireConfigSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  questionnaireId?: InputMaybe<Scalars['uuid']>;
  rewardCouponId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "questionnaireConfig" */
export type QuestionnaireConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: QuestionnaireConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionnaireConfigStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  questionnaireId?: InputMaybe<Scalars['uuid']>;
  rewardCouponId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "questionnaireConfig" */
export enum QuestionnaireConfigUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageUrl',
  /** column name */
  QuestionnaireId = 'questionnaireId',
  /** column name */
  RewardCouponId = 'rewardCouponId'
}

export type QuestionnaireConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionnaireConfigSetInput>;
  /** filter the rows which have to be updated */
  where: QuestionnaireConfigBoolExp;
};

/** columns and relationships of "questionnaireQuestion" */
export type QuestionnaireQuestion = {
  __typename?: 'questionnaireQuestion';
  priority: Scalars['Int'];
  /** An object relationship */
  question: Question;
  questionId: Scalars['uuid'];
  /** An object relationship */
  questionnaire: Questionnaire;
};

/** order by aggregate values of table "questionnaireQuestion" */
export type QuestionnaireQuestionAggregateOrderBy = {
  avg?: InputMaybe<QuestionnaireQuestionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<QuestionnaireQuestionMaxOrderBy>;
  min?: InputMaybe<QuestionnaireQuestionMinOrderBy>;
  stddev?: InputMaybe<QuestionnaireQuestionStddevOrderBy>;
  stddev_pop?: InputMaybe<QuestionnaireQuestionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<QuestionnaireQuestionStddevSampOrderBy>;
  sum?: InputMaybe<QuestionnaireQuestionSumOrderBy>;
  var_pop?: InputMaybe<QuestionnaireQuestionVarPopOrderBy>;
  var_samp?: InputMaybe<QuestionnaireQuestionVarSampOrderBy>;
  variance?: InputMaybe<QuestionnaireQuestionVarianceOrderBy>;
};

/** order by avg() on columns of table "questionnaireQuestion" */
export type QuestionnaireQuestionAvgOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "questionnaireQuestion". All fields are combined with a logical 'AND'. */
export type QuestionnaireQuestionBoolExp = {
  _and?: InputMaybe<Array<QuestionnaireQuestionBoolExp>>;
  _not?: InputMaybe<QuestionnaireQuestionBoolExp>;
  _or?: InputMaybe<Array<QuestionnaireQuestionBoolExp>>;
  priority?: InputMaybe<IntComparisonExp>;
  question?: InputMaybe<QuestionBoolExp>;
  questionId?: InputMaybe<UuidComparisonExp>;
  questionnaire?: InputMaybe<QuestionnaireBoolExp>;
};

/** order by max() on columns of table "questionnaireQuestion" */
export type QuestionnaireQuestionMaxOrderBy = {
  priority?: InputMaybe<OrderBy>;
  questionId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "questionnaireQuestion" */
export type QuestionnaireQuestionMinOrderBy = {
  priority?: InputMaybe<OrderBy>;
  questionId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "questionnaireQuestion". */
export type QuestionnaireQuestionOrderBy = {
  priority?: InputMaybe<OrderBy>;
  question?: InputMaybe<QuestionOrderBy>;
  questionId?: InputMaybe<OrderBy>;
  questionnaire?: InputMaybe<QuestionnaireOrderBy>;
};

/** select columns of table "questionnaireQuestion" */
export enum QuestionnaireQuestionSelectColumn {
  /** column name */
  Priority = 'priority',
  /** column name */
  QuestionId = 'questionId'
}

/** order by stddev() on columns of table "questionnaireQuestion" */
export type QuestionnaireQuestionStddevOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "questionnaireQuestion" */
export type QuestionnaireQuestionStddevPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "questionnaireQuestion" */
export type QuestionnaireQuestionStddevSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "questionnaireQuestion" */
export type QuestionnaireQuestionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: QuestionnaireQuestionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionnaireQuestionStreamCursorValueInput = {
  priority?: InputMaybe<Scalars['Int']>;
  questionId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "questionnaireQuestion" */
export type QuestionnaireQuestionSumOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "questionnaireQuestion" */
export type QuestionnaireQuestionVarPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "questionnaireQuestion" */
export type QuestionnaireQuestionVarSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "questionnaireQuestion" */
export type QuestionnaireQuestionVarianceOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "questionnaire". All fields are combined with a logical 'AND'. */
export type QuestionnaireBoolExp = {
  _and?: InputMaybe<Array<QuestionnaireBoolExp>>;
  _not?: InputMaybe<QuestionnaireBoolExp>;
  _or?: InputMaybe<Array<QuestionnaireBoolExp>>;
  customerQuestionnaires?: InputMaybe<CustomerQuestionnaireBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  questionnaireAnswers?: InputMaybe<QuestionnaireAnswerBoolExp>;
  questionnaireConfigs?: InputMaybe<QuestionnaireConfigBoolExp>;
  questionnaireQuestions?: InputMaybe<QuestionnaireQuestionBoolExp>;
};

/** unique or primary key constraints on table "questionnaire" */
export enum QuestionnaireConstraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnairePkey = 'questionnaire_pkey'
}

/** input type for inserting data into table "questionnaire" */
export type QuestionnaireInsertInput = {
  name: Scalars['String'];
  questionnaireConfigs?: InputMaybe<QuestionnaireConfigArrRelInsertInput>;
};

/** response of any mutation on the table "questionnaire" */
export type QuestionnaireMutationResponse = {
  __typename?: 'questionnaire_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaire>;
};

/** input type for inserting object relation for remote table "questionnaire" */
export type QuestionnaireObjRelInsertInput = {
  data: QuestionnaireInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionnaireOnConflict>;
};

/** on_conflict condition type for table "questionnaire" */
export type QuestionnaireOnConflict = {
  constraint: QuestionnaireConstraint;
  update_columns?: Array<QuestionnaireUpdateColumn>;
  where?: InputMaybe<QuestionnaireBoolExp>;
};

/** Ordering options when selecting data from "questionnaire". */
export type QuestionnaireOrderBy = {
  customerQuestionnaires_aggregate?: InputMaybe<CustomerQuestionnaireAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  questionnaireAnswers_aggregate?: InputMaybe<QuestionnaireAnswerAggregateOrderBy>;
  questionnaireConfigs_aggregate?: InputMaybe<QuestionnaireConfigAggregateOrderBy>;
  questionnaireQuestions_aggregate?: InputMaybe<QuestionnaireQuestionAggregateOrderBy>;
};

/** primary key columns input for table: questionnaire */
export type QuestionnairePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "questionnaire" */
export enum QuestionnaireSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "questionnaire" */
export type QuestionnaireSetInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "questionnaire" */
export type QuestionnaireStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: QuestionnaireStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionnaireStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "questionnaire" */
export enum QuestionnaireUpdateColumn {
  /** column name */
  Name = 'name'
}

export type QuestionnaireUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionnaireSetInput>;
  /** filter the rows which have to be updated */
  where: QuestionnaireBoolExp;
};

/** columns and relationships of "receipt" */
export type Receipt = {
  __typename?: 'receipt';
  _clerkId: Scalars['Int'];
  _onSitePaymentId?: Maybe<Scalars['Int']>;
  charge: Scalars['Int'];
  /** An object relationship */
  clerk: Clerk;
  clerkId: Scalars['uuid'];
  createdAt: Scalars['Int'];
  /** An object relationship */
  onSitePayment?: Maybe<OnSitePayment>;
  onSitePaymentId?: Maybe<Scalars['uuid']>;
  receiptId: Scalars['uuid'];
};

/** order by aggregate values of table "receipt" */
export type ReceiptAggregateOrderBy = {
  avg?: InputMaybe<ReceiptAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReceiptMaxOrderBy>;
  min?: InputMaybe<ReceiptMinOrderBy>;
  stddev?: InputMaybe<ReceiptStddevOrderBy>;
  stddev_pop?: InputMaybe<ReceiptStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ReceiptStddevSampOrderBy>;
  sum?: InputMaybe<ReceiptSumOrderBy>;
  var_pop?: InputMaybe<ReceiptVarPopOrderBy>;
  var_samp?: InputMaybe<ReceiptVarSampOrderBy>;
  variance?: InputMaybe<ReceiptVarianceOrderBy>;
};

/** order by avg() on columns of table "receipt" */
export type ReceiptAvgOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  charge?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "receipt". All fields are combined with a logical 'AND'. */
export type ReceiptBoolExp = {
  _and?: InputMaybe<Array<ReceiptBoolExp>>;
  _clerkId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<ReceiptBoolExp>;
  _onSitePaymentId?: InputMaybe<IntComparisonExp>;
  _or?: InputMaybe<Array<ReceiptBoolExp>>;
  charge?: InputMaybe<IntComparisonExp>;
  clerk?: InputMaybe<ClerkBoolExp>;
  clerkId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<IntComparisonExp>;
  onSitePayment?: InputMaybe<OnSitePaymentBoolExp>;
  onSitePaymentId?: InputMaybe<UuidComparisonExp>;
  receiptId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "receipt" */
export type ReceiptMaxOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  charge?: InputMaybe<OrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  receiptId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "receipt" */
export type ReceiptMinOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  charge?: InputMaybe<OrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  receiptId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "receipt". */
export type ReceiptOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  charge?: InputMaybe<OrderBy>;
  clerk?: InputMaybe<ClerkOrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  onSitePayment?: InputMaybe<OnSitePaymentOrderBy>;
  onSitePaymentId?: InputMaybe<OrderBy>;
  receiptId?: InputMaybe<OrderBy>;
};

/** select columns of table "receipt" */
export enum ReceiptSelectColumn {
  /** column name */
  _ClerkId = '_clerkId',
  /** column name */
  _OnSitePaymentId = '_onSitePaymentId',
  /** column name */
  Charge = 'charge',
  /** column name */
  ClerkId = 'clerkId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  OnSitePaymentId = 'onSitePaymentId',
  /** column name */
  ReceiptId = 'receiptId'
}

/** order by stddev() on columns of table "receipt" */
export type ReceiptStddevOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  charge?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "receipt" */
export type ReceiptStddevPopOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  charge?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "receipt" */
export type ReceiptStddevSampOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  charge?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "receipt" */
export type ReceiptStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ReceiptStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ReceiptStreamCursorValueInput = {
  _clerkId?: InputMaybe<Scalars['Int']>;
  _onSitePaymentId?: InputMaybe<Scalars['Int']>;
  charge?: InputMaybe<Scalars['Int']>;
  clerkId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['Int']>;
  onSitePaymentId?: InputMaybe<Scalars['uuid']>;
  receiptId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "receipt" */
export type ReceiptSumOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  charge?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "receipt" */
export type ReceiptVarPopOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  charge?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "receipt" */
export type ReceiptVarSampOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  charge?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "receipt" */
export type ReceiptVarianceOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  _onSitePaymentId?: InputMaybe<OrderBy>;
  charge?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
};

/** columns and relationships of "reservationDashboardAccountAccessibleShop" */
export type ReservationDashboardAccountAccessibleShop = {
  __typename?: 'reservationDashboardAccountAccessibleShop';
  dashboardAccountId: Scalars['uuid'];
  id: Scalars['uuid'];
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "reservationDashboardAccountAccessibleShop" */
export type ReservationDashboardAccountAccessibleShopAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ReservationDashboardAccountAccessibleShopMaxOrderBy>;
  min?: InputMaybe<ReservationDashboardAccountAccessibleShopMinOrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "reservationDashboardAccountAccessibleShop". All fields are combined with a logical 'AND'.
 */
export type ReservationDashboardAccountAccessibleShopBoolExp = {
  _and?: InputMaybe<Array<ReservationDashboardAccountAccessibleShopBoolExp>>;
  _not?: InputMaybe<ReservationDashboardAccountAccessibleShopBoolExp>;
  _or?: InputMaybe<Array<ReservationDashboardAccountAccessibleShopBoolExp>>;
  dashboardAccountId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "reservationDashboardAccountAccessibleShop" */
export type ReservationDashboardAccountAccessibleShopMaxOrderBy = {
  dashboardAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "reservationDashboardAccountAccessibleShop" */
export type ReservationDashboardAccountAccessibleShopMinOrderBy = {
  dashboardAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "reservationDashboardAccountAccessibleShop". */
export type ReservationDashboardAccountAccessibleShopOrderBy = {
  dashboardAccountId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "reservationDashboardAccountAccessibleShop" */
export enum ReservationDashboardAccountAccessibleShopSelectColumn {
  /** column name */
  DashboardAccountId = 'dashboardAccountId',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "reservationDashboardAccountAccessibleShop" */
export type ReservationDashboardAccountAccessibleShopStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ReservationDashboardAccountAccessibleShopStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ReservationDashboardAccountAccessibleShopStreamCursorValueInput = {
  dashboardAccountId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  /** An array relationship */
  dishUpSlipGroupRoles: Array<DishUpSlipGroupRoles>;
  /** An object relationship */
  firstSpareRole?: Maybe<Role>;
  firstSpareRoleId?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** An array relationship */
  kdDisplayTargets: Array<KdDisplayTarget>;
  name: Scalars['String'];
  /** An array relationship */
  paymentReceiptRoleTables: Array<PaymentReceiptRoleTable>;
  printDishUpSlipAsPrimarySlip: Scalars['Boolean'];
  printerSound: Scalars['Int'];
  removeFreeOrderFromDishUpSlip: Scalars['Boolean'];
  roleId: Scalars['Int'];
  /** An array relationship */
  roleTablesTables: Array<RoleTablesTable>;
  /** An array relationship */
  roles: Array<Role>;
  /** An array relationship */
  rolesBySecondspareroleidShopid: Array<Role>;
  /** An object relationship */
  secondSpareRole?: Maybe<Role>;
  secondSpareRoleId?: Maybe<Scalars['Int']>;
  serial: Scalars['Int'];
  /** An object relationship */
  shop: Shop;
  /** An array relationship */
  shopCookingItemRoles: Array<ShopCookingItemRole>;
  shopId: Scalars['uuid'];
  /** An array relationship */
  shopMenuKitchenRoles: Array<ShopMenuKitchenRole>;
  /** An array relationship */
  shopOptionKitchenRoles: Array<ShopOptionKitchenRole>;
  /** An array relationship */
  shopPlanKitchenRoles: Array<ShopPlanKitchenRole>;
  /** An array relationship */
  shopPlanOptionKitchenRoles: Array<ShopPlanOptionKitchenRole>;
  shouldPrintClerkCallSlip: Scalars['Boolean'];
  shouldPrintDishUpSlip: Scalars['Boolean'];
  shouldPrintPaymentReceipt: Scalars['Boolean'];
};


/** columns and relationships of "role" */
export type RoleDishUpSlipGroupRolesArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupRolesOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupRolesBoolExp>;
};


/** columns and relationships of "role" */
export type RoleKdDisplayTargetsArgs = {
  distinct_on?: InputMaybe<Array<KdDisplayTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KdDisplayTargetOrderBy>>;
  where?: InputMaybe<KdDisplayTargetBoolExp>;
};


/** columns and relationships of "role" */
export type RolePaymentReceiptRoleTablesArgs = {
  distinct_on?: InputMaybe<Array<PaymentReceiptRoleTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentReceiptRoleTableOrderBy>>;
  where?: InputMaybe<PaymentReceiptRoleTableBoolExp>;
};


/** columns and relationships of "role" */
export type RoleRoleTablesTablesArgs = {
  distinct_on?: InputMaybe<Array<RoleTablesTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleTablesTableOrderBy>>;
  where?: InputMaybe<RoleTablesTableBoolExp>;
};


/** columns and relationships of "role" */
export type RoleRolesArgs = {
  distinct_on?: InputMaybe<Array<RoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleOrderBy>>;
  where?: InputMaybe<RoleBoolExp>;
};


/** columns and relationships of "role" */
export type RoleRolesBySecondspareroleidShopidArgs = {
  distinct_on?: InputMaybe<Array<RoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleOrderBy>>;
  where?: InputMaybe<RoleBoolExp>;
};


/** columns and relationships of "role" */
export type RoleShopCookingItemRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopCookingItemRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopCookingItemRoleOrderBy>>;
  where?: InputMaybe<ShopCookingItemRoleBoolExp>;
};


/** columns and relationships of "role" */
export type RoleShopMenuKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
};


/** columns and relationships of "role" */
export type RoleShopOptionKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopOptionKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOptionKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopOptionKitchenRoleBoolExp>;
};


/** columns and relationships of "role" */
export type RoleShopPlanKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
};


/** columns and relationships of "role" */
export type RoleShopPlanOptionKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanOptionKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOptionKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopPlanOptionKitchenRoleBoolExp>;
};

/** columns and relationships of "roleTablesTable" */
export type RoleTablesTable = {
  __typename?: 'roleTablesTable';
  /** An object relationship */
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['uuid']>;
  roleRoleId: Scalars['Int'];
  shopId: Scalars['uuid'];
  /** An object relationship */
  table?: Maybe<Table>;
  tableId?: Maybe<Scalars['uuid']>;
  tableTableId: Scalars['Int'];
};

/** order by aggregate values of table "roleTablesTable" */
export type RoleTablesTableAggregateOrderBy = {
  avg?: InputMaybe<RoleTablesTableAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RoleTablesTableMaxOrderBy>;
  min?: InputMaybe<RoleTablesTableMinOrderBy>;
  stddev?: InputMaybe<RoleTablesTableStddevOrderBy>;
  stddev_pop?: InputMaybe<RoleTablesTableStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RoleTablesTableStddevSampOrderBy>;
  sum?: InputMaybe<RoleTablesTableSumOrderBy>;
  var_pop?: InputMaybe<RoleTablesTableVarPopOrderBy>;
  var_samp?: InputMaybe<RoleTablesTableVarSampOrderBy>;
  variance?: InputMaybe<RoleTablesTableVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "roleTablesTable" */
export type RoleTablesTableArrRelInsertInput = {
  data: Array<RoleTablesTableInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RoleTablesTableOnConflict>;
};

/** order by avg() on columns of table "roleTablesTable" */
export type RoleTablesTableAvgOrderBy = {
  roleRoleId?: InputMaybe<OrderBy>;
  tableTableId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "roleTablesTable". All fields are combined with a logical 'AND'. */
export type RoleTablesTableBoolExp = {
  _and?: InputMaybe<Array<RoleTablesTableBoolExp>>;
  _not?: InputMaybe<RoleTablesTableBoolExp>;
  _or?: InputMaybe<Array<RoleTablesTableBoolExp>>;
  role?: InputMaybe<RoleBoolExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
  roleRoleId?: InputMaybe<IntComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  table?: InputMaybe<TableBoolExp>;
  tableId?: InputMaybe<UuidComparisonExp>;
  tableTableId?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "roleTablesTable" */
export enum RoleTablesTableConstraint {
  /** unique or primary key constraint on columns "id" */
  RoleTablesTablePkey = 'roleTablesTable_pkey',
  /** unique or primary key constraint on columns "roleId", "tableId" */
  RoleTablesTableRoleIdTableIdKey = 'roleTablesTable_roleId_tableId_key',
  /** unique or primary key constraint on columns "tableTableId", "roleRoleId" */
  RoleTablesTableRoleRoleIdTableTableIdKey = 'roleTablesTable_roleRoleId_tableTableId_key'
}

/** input type for incrementing numeric columns in table "roleTablesTable" */
export type RoleTablesTableIncInput = {
  roleRoleId?: InputMaybe<Scalars['Int']>;
  tableTableId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "roleTablesTable" */
export type RoleTablesTableInsertInput = {
  role?: InputMaybe<RoleObjRelInsertInput>;
  roleId?: InputMaybe<Scalars['uuid']>;
  roleRoleId: Scalars['Int'];
  shopId: Scalars['uuid'];
  table?: InputMaybe<TableObjRelInsertInput>;
  tableId?: InputMaybe<Scalars['uuid']>;
  tableTableId: Scalars['Int'];
};

/** order by max() on columns of table "roleTablesTable" */
export type RoleTablesTableMaxOrderBy = {
  roleId?: InputMaybe<OrderBy>;
  roleRoleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
  tableTableId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "roleTablesTable" */
export type RoleTablesTableMinOrderBy = {
  roleId?: InputMaybe<OrderBy>;
  roleRoleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
  tableTableId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "roleTablesTable" */
export type RoleTablesTableMutationResponse = {
  __typename?: 'roleTablesTable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RoleTablesTable>;
};

/** on_conflict condition type for table "roleTablesTable" */
export type RoleTablesTableOnConflict = {
  constraint: RoleTablesTableConstraint;
  update_columns?: Array<RoleTablesTableUpdateColumn>;
  where?: InputMaybe<RoleTablesTableBoolExp>;
};

/** Ordering options when selecting data from "roleTablesTable". */
export type RoleTablesTableOrderBy = {
  role?: InputMaybe<RoleOrderBy>;
  roleId?: InputMaybe<OrderBy>;
  roleRoleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  table?: InputMaybe<TableOrderBy>;
  tableId?: InputMaybe<OrderBy>;
  tableTableId?: InputMaybe<OrderBy>;
};

/** select columns of table "roleTablesTable" */
export enum RoleTablesTableSelectColumn {
  /** column name */
  RoleId = 'roleId',
  /** column name */
  RoleRoleId = 'roleRoleId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TableId = 'tableId',
  /** column name */
  TableTableId = 'tableTableId'
}

/** input type for updating data in table "roleTablesTable" */
export type RoleTablesTableSetInput = {
  roleId?: InputMaybe<Scalars['uuid']>;
  roleRoleId?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tableId?: InputMaybe<Scalars['uuid']>;
  tableTableId?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "roleTablesTable" */
export type RoleTablesTableStddevOrderBy = {
  roleRoleId?: InputMaybe<OrderBy>;
  tableTableId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "roleTablesTable" */
export type RoleTablesTableStddevPopOrderBy = {
  roleRoleId?: InputMaybe<OrderBy>;
  tableTableId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "roleTablesTable" */
export type RoleTablesTableStddevSampOrderBy = {
  roleRoleId?: InputMaybe<OrderBy>;
  tableTableId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "roleTablesTable" */
export type RoleTablesTableStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RoleTablesTableStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type RoleTablesTableStreamCursorValueInput = {
  roleId?: InputMaybe<Scalars['uuid']>;
  roleRoleId?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tableId?: InputMaybe<Scalars['uuid']>;
  tableTableId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "roleTablesTable" */
export type RoleTablesTableSumOrderBy = {
  roleRoleId?: InputMaybe<OrderBy>;
  tableTableId?: InputMaybe<OrderBy>;
};

/** update columns of table "roleTablesTable" */
export enum RoleTablesTableUpdateColumn {
  /** column name */
  RoleId = 'roleId',
  /** column name */
  RoleRoleId = 'roleRoleId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TableId = 'tableId',
  /** column name */
  TableTableId = 'tableTableId'
}

export type RoleTablesTableUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RoleTablesTableIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RoleTablesTableSetInput>;
  /** filter the rows which have to be updated */
  where: RoleTablesTableBoolExp;
};

/** order by var_pop() on columns of table "roleTablesTable" */
export type RoleTablesTableVarPopOrderBy = {
  roleRoleId?: InputMaybe<OrderBy>;
  tableTableId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "roleTablesTable" */
export type RoleTablesTableVarSampOrderBy = {
  roleRoleId?: InputMaybe<OrderBy>;
  tableTableId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "roleTablesTable" */
export type RoleTablesTableVarianceOrderBy = {
  roleRoleId?: InputMaybe<OrderBy>;
  tableTableId?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "role" */
export type RoleAggregateOrderBy = {
  avg?: InputMaybe<RoleAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RoleMaxOrderBy>;
  min?: InputMaybe<RoleMinOrderBy>;
  stddev?: InputMaybe<RoleStddevOrderBy>;
  stddev_pop?: InputMaybe<RoleStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RoleStddevSampOrderBy>;
  sum?: InputMaybe<RoleSumOrderBy>;
  var_pop?: InputMaybe<RoleVarPopOrderBy>;
  var_samp?: InputMaybe<RoleVarSampOrderBy>;
  variance?: InputMaybe<RoleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "role" */
export type RoleArrRelInsertInput = {
  data: Array<RoleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RoleOnConflict>;
};

/** order by avg() on columns of table "role" */
export type RoleAvgOrderBy = {
  firstSpareRoleId?: InputMaybe<OrderBy>;
  printerSound?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  secondSpareRoleId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type RoleBoolExp = {
  _and?: InputMaybe<Array<RoleBoolExp>>;
  _not?: InputMaybe<RoleBoolExp>;
  _or?: InputMaybe<Array<RoleBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  dishUpSlipGroupRoles?: InputMaybe<DishUpSlipGroupRolesBoolExp>;
  firstSpareRole?: InputMaybe<RoleBoolExp>;
  firstSpareRoleId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  kdDisplayTargets?: InputMaybe<KdDisplayTargetBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  paymentReceiptRoleTables?: InputMaybe<PaymentReceiptRoleTableBoolExp>;
  printDishUpSlipAsPrimarySlip?: InputMaybe<BooleanComparisonExp>;
  printerSound?: InputMaybe<IntComparisonExp>;
  removeFreeOrderFromDishUpSlip?: InputMaybe<BooleanComparisonExp>;
  roleId?: InputMaybe<IntComparisonExp>;
  roleTablesTables?: InputMaybe<RoleTablesTableBoolExp>;
  roles?: InputMaybe<RoleBoolExp>;
  rolesBySecondspareroleidShopid?: InputMaybe<RoleBoolExp>;
  secondSpareRole?: InputMaybe<RoleBoolExp>;
  secondSpareRoleId?: InputMaybe<IntComparisonExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopCookingItemRoles?: InputMaybe<ShopCookingItemRoleBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopMenuKitchenRoles?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
  shopOptionKitchenRoles?: InputMaybe<ShopOptionKitchenRoleBoolExp>;
  shopPlanKitchenRoles?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
  shopPlanOptionKitchenRoles?: InputMaybe<ShopPlanOptionKitchenRoleBoolExp>;
  shouldPrintClerkCallSlip?: InputMaybe<BooleanComparisonExp>;
  shouldPrintDishUpSlip?: InputMaybe<BooleanComparisonExp>;
  shouldPrintPaymentReceipt?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "role" */
export enum RoleConstraint {
  /** unique or primary key constraint on columns "serial" */
  Idx_46665Primary = 'idx_46665_PRIMARY',
  /** unique or primary key constraint on columns "id" */
  RoleIdKey = 'role_id_key',
  /** unique or primary key constraint on columns "serial", "shopId" */
  RoleRoleIdShopIdKey = 'role_roleId_shopId_key'
}

/** input type for incrementing numeric columns in table "role" */
export type RoleIncInput = {
  firstSpareRoleId?: InputMaybe<Scalars['Int']>;
  printerSound?: InputMaybe<Scalars['Int']>;
  secondSpareRoleId?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "role" */
export type RoleInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  dishUpSlipGroupRoles?: InputMaybe<DishUpSlipGroupRolesArrRelInsertInput>;
  firstSpareRole?: InputMaybe<RoleObjRelInsertInput>;
  firstSpareRoleId?: InputMaybe<Scalars['Int']>;
  kdDisplayTargets?: InputMaybe<KdDisplayTargetArrRelInsertInput>;
  name: Scalars['String'];
  paymentReceiptRoleTables?: InputMaybe<PaymentReceiptRoleTableArrRelInsertInput>;
  printDishUpSlipAsPrimarySlip?: InputMaybe<Scalars['Boolean']>;
  printerSound?: InputMaybe<Scalars['Int']>;
  removeFreeOrderFromDishUpSlip?: InputMaybe<Scalars['Boolean']>;
  roleTablesTables?: InputMaybe<RoleTablesTableArrRelInsertInput>;
  roles?: InputMaybe<RoleArrRelInsertInput>;
  rolesBySecondspareroleidShopid?: InputMaybe<RoleArrRelInsertInput>;
  secondSpareRole?: InputMaybe<RoleObjRelInsertInput>;
  secondSpareRoleId?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopCookingItemRoles?: InputMaybe<ShopCookingItemRoleArrRelInsertInput>;
  shopId: Scalars['uuid'];
  shopMenuKitchenRoles?: InputMaybe<ShopMenuKitchenRoleArrRelInsertInput>;
  shopOptionKitchenRoles?: InputMaybe<ShopOptionKitchenRoleArrRelInsertInput>;
  shopPlanKitchenRoles?: InputMaybe<ShopPlanKitchenRoleArrRelInsertInput>;
  shopPlanOptionKitchenRoles?: InputMaybe<ShopPlanOptionKitchenRoleArrRelInsertInput>;
  shouldPrintClerkCallSlip?: InputMaybe<Scalars['Boolean']>;
  shouldPrintDishUpSlip?: InputMaybe<Scalars['Boolean']>;
  shouldPrintPaymentReceipt?: InputMaybe<Scalars['Boolean']>;
};

/** order by max() on columns of table "role" */
export type RoleMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  firstSpareRoleId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  printerSound?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  secondSpareRoleId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "role" */
export type RoleMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  firstSpareRoleId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  printerSound?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  secondSpareRoleId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "role" */
export type RoleMutationResponse = {
  __typename?: 'role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
};

/** input type for inserting object relation for remote table "role" */
export type RoleObjRelInsertInput = {
  data: RoleInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RoleOnConflict>;
};

/** on_conflict condition type for table "role" */
export type RoleOnConflict = {
  constraint: RoleConstraint;
  update_columns?: Array<RoleUpdateColumn>;
  where?: InputMaybe<RoleBoolExp>;
};

/** Ordering options when selecting data from "role". */
export type RoleOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  dishUpSlipGroupRoles_aggregate?: InputMaybe<DishUpSlipGroupRolesAggregateOrderBy>;
  firstSpareRole?: InputMaybe<RoleOrderBy>;
  firstSpareRoleId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  kdDisplayTargets_aggregate?: InputMaybe<KdDisplayTargetAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  paymentReceiptRoleTables_aggregate?: InputMaybe<PaymentReceiptRoleTableAggregateOrderBy>;
  printDishUpSlipAsPrimarySlip?: InputMaybe<OrderBy>;
  printerSound?: InputMaybe<OrderBy>;
  removeFreeOrderFromDishUpSlip?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  roleTablesTables_aggregate?: InputMaybe<RoleTablesTableAggregateOrderBy>;
  rolesBySecondspareroleidShopid_aggregate?: InputMaybe<RoleAggregateOrderBy>;
  roles_aggregate?: InputMaybe<RoleAggregateOrderBy>;
  secondSpareRole?: InputMaybe<RoleOrderBy>;
  secondSpareRoleId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopCookingItemRoles_aggregate?: InputMaybe<ShopCookingItemRoleAggregateOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopMenuKitchenRoles_aggregate?: InputMaybe<ShopMenuKitchenRoleAggregateOrderBy>;
  shopOptionKitchenRoles_aggregate?: InputMaybe<ShopOptionKitchenRoleAggregateOrderBy>;
  shopPlanKitchenRoles_aggregate?: InputMaybe<ShopPlanKitchenRoleAggregateOrderBy>;
  shopPlanOptionKitchenRoles_aggregate?: InputMaybe<ShopPlanOptionKitchenRoleAggregateOrderBy>;
  shouldPrintClerkCallSlip?: InputMaybe<OrderBy>;
  shouldPrintDishUpSlip?: InputMaybe<OrderBy>;
  shouldPrintPaymentReceipt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: role */
export type RolePkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "role" */
export enum RoleSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  FirstSpareRoleId = 'firstSpareRoleId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrintDishUpSlipAsPrimarySlip = 'printDishUpSlipAsPrimarySlip',
  /** column name */
  PrinterSound = 'printerSound',
  /** column name */
  RemoveFreeOrderFromDishUpSlip = 'removeFreeOrderFromDishUpSlip',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  SecondSpareRoleId = 'secondSpareRoleId',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  ShouldPrintClerkCallSlip = 'shouldPrintClerkCallSlip',
  /** column name */
  ShouldPrintDishUpSlip = 'shouldPrintDishUpSlip',
  /** column name */
  ShouldPrintPaymentReceipt = 'shouldPrintPaymentReceipt'
}

/** input type for updating data in table "role" */
export type RoleSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  firstSpareRoleId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  printDishUpSlipAsPrimarySlip?: InputMaybe<Scalars['Boolean']>;
  printerSound?: InputMaybe<Scalars['Int']>;
  removeFreeOrderFromDishUpSlip?: InputMaybe<Scalars['Boolean']>;
  secondSpareRoleId?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  shouldPrintClerkCallSlip?: InputMaybe<Scalars['Boolean']>;
  shouldPrintDishUpSlip?: InputMaybe<Scalars['Boolean']>;
  shouldPrintPaymentReceipt?: InputMaybe<Scalars['Boolean']>;
};

/** order by stddev() on columns of table "role" */
export type RoleStddevOrderBy = {
  firstSpareRoleId?: InputMaybe<OrderBy>;
  printerSound?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  secondSpareRoleId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "role" */
export type RoleStddevPopOrderBy = {
  firstSpareRoleId?: InputMaybe<OrderBy>;
  printerSound?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  secondSpareRoleId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "role" */
export type RoleStddevSampOrderBy = {
  firstSpareRoleId?: InputMaybe<OrderBy>;
  printerSound?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  secondSpareRoleId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "role" */
export type RoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type RoleStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  firstSpareRoleId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  printDishUpSlipAsPrimarySlip?: InputMaybe<Scalars['Boolean']>;
  printerSound?: InputMaybe<Scalars['Int']>;
  removeFreeOrderFromDishUpSlip?: InputMaybe<Scalars['Boolean']>;
  roleId?: InputMaybe<Scalars['Int']>;
  secondSpareRoleId?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  shouldPrintClerkCallSlip?: InputMaybe<Scalars['Boolean']>;
  shouldPrintDishUpSlip?: InputMaybe<Scalars['Boolean']>;
  shouldPrintPaymentReceipt?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "role" */
export type RoleSumOrderBy = {
  firstSpareRoleId?: InputMaybe<OrderBy>;
  printerSound?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  secondSpareRoleId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** update columns of table "role" */
export enum RoleUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  FirstSpareRoleId = 'firstSpareRoleId',
  /** column name */
  Name = 'name',
  /** column name */
  PrintDishUpSlipAsPrimarySlip = 'printDishUpSlipAsPrimarySlip',
  /** column name */
  PrinterSound = 'printerSound',
  /** column name */
  RemoveFreeOrderFromDishUpSlip = 'removeFreeOrderFromDishUpSlip',
  /** column name */
  SecondSpareRoleId = 'secondSpareRoleId',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  ShouldPrintClerkCallSlip = 'shouldPrintClerkCallSlip',
  /** column name */
  ShouldPrintDishUpSlip = 'shouldPrintDishUpSlip',
  /** column name */
  ShouldPrintPaymentReceipt = 'shouldPrintPaymentReceipt'
}

export type RoleUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RoleIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RoleSetInput>;
  /** filter the rows which have to be updated */
  where: RoleBoolExp;
};

/** order by var_pop() on columns of table "role" */
export type RoleVarPopOrderBy = {
  firstSpareRoleId?: InputMaybe<OrderBy>;
  printerSound?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  secondSpareRoleId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "role" */
export type RoleVarSampOrderBy = {
  firstSpareRoleId?: InputMaybe<OrderBy>;
  printerSound?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  secondSpareRoleId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "role" */
export type RoleVarianceOrderBy = {
  firstSpareRoleId?: InputMaybe<OrderBy>;
  printerSound?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  secondSpareRoleId?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
};

/** columns and relationships of "salesBudgetAllocationSetting" */
export type SalesBudgetAllocationSetting = {
  __typename?: 'salesBudgetAllocationSetting';
  friRate: Scalars['numeric'];
  id: Scalars['uuid'];
  monRate: Scalars['numeric'];
  satRate: Scalars['numeric'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  sunRate: Scalars['numeric'];
  thuRate: Scalars['numeric'];
  tueRate: Scalars['numeric'];
  wedRate: Scalars['numeric'];
};

/**
 * Boolean expression to filter rows from the table "salesBudgetAllocationSetting".
 * All fields are combined with a logical 'AND'.
 */
export type SalesBudgetAllocationSettingBoolExp = {
  _and?: InputMaybe<Array<SalesBudgetAllocationSettingBoolExp>>;
  _not?: InputMaybe<SalesBudgetAllocationSettingBoolExp>;
  _or?: InputMaybe<Array<SalesBudgetAllocationSettingBoolExp>>;
  friRate?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  monRate?: InputMaybe<NumericComparisonExp>;
  satRate?: InputMaybe<NumericComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  sunRate?: InputMaybe<NumericComparisonExp>;
  thuRate?: InputMaybe<NumericComparisonExp>;
  tueRate?: InputMaybe<NumericComparisonExp>;
  wedRate?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "salesBudgetAllocationSetting" */
export enum SalesBudgetAllocationSettingConstraint {
  /** unique or primary key constraint on columns "id" */
  SalesBudgetAllocationSettingPkey = 'salesBudgetAllocationSetting_pkey',
  /** unique or primary key constraint on columns "shopId" */
  SalesBudgetAllocationSettingShopIdKey = 'salesBudgetAllocationSetting_shopId_key'
}

/** input type for incrementing numeric columns in table "salesBudgetAllocationSetting" */
export type SalesBudgetAllocationSettingIncInput = {
  friRate?: InputMaybe<Scalars['numeric']>;
  monRate?: InputMaybe<Scalars['numeric']>;
  satRate?: InputMaybe<Scalars['numeric']>;
  sunRate?: InputMaybe<Scalars['numeric']>;
  thuRate?: InputMaybe<Scalars['numeric']>;
  tueRate?: InputMaybe<Scalars['numeric']>;
  wedRate?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "salesBudgetAllocationSetting" */
export type SalesBudgetAllocationSettingInsertInput = {
  friRate: Scalars['numeric'];
  monRate: Scalars['numeric'];
  satRate: Scalars['numeric'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  sunRate: Scalars['numeric'];
  thuRate: Scalars['numeric'];
  tueRate: Scalars['numeric'];
  wedRate: Scalars['numeric'];
};

/** response of any mutation on the table "salesBudgetAllocationSetting" */
export type SalesBudgetAllocationSettingMutationResponse = {
  __typename?: 'salesBudgetAllocationSetting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SalesBudgetAllocationSetting>;
};

/** on_conflict condition type for table "salesBudgetAllocationSetting" */
export type SalesBudgetAllocationSettingOnConflict = {
  constraint: SalesBudgetAllocationSettingConstraint;
  update_columns?: Array<SalesBudgetAllocationSettingUpdateColumn>;
  where?: InputMaybe<SalesBudgetAllocationSettingBoolExp>;
};

/** Ordering options when selecting data from "salesBudgetAllocationSetting". */
export type SalesBudgetAllocationSettingOrderBy = {
  friRate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  monRate?: InputMaybe<OrderBy>;
  satRate?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  sunRate?: InputMaybe<OrderBy>;
  thuRate?: InputMaybe<OrderBy>;
  tueRate?: InputMaybe<OrderBy>;
  wedRate?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: salesBudgetAllocationSetting */
export type SalesBudgetAllocationSettingPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "salesBudgetAllocationSetting" */
export enum SalesBudgetAllocationSettingSelectColumn {
  /** column name */
  FriRate = 'friRate',
  /** column name */
  Id = 'id',
  /** column name */
  MonRate = 'monRate',
  /** column name */
  SatRate = 'satRate',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  SunRate = 'sunRate',
  /** column name */
  ThuRate = 'thuRate',
  /** column name */
  TueRate = 'tueRate',
  /** column name */
  WedRate = 'wedRate'
}

/** input type for updating data in table "salesBudgetAllocationSetting" */
export type SalesBudgetAllocationSettingSetInput = {
  friRate?: InputMaybe<Scalars['numeric']>;
  monRate?: InputMaybe<Scalars['numeric']>;
  satRate?: InputMaybe<Scalars['numeric']>;
  sunRate?: InputMaybe<Scalars['numeric']>;
  thuRate?: InputMaybe<Scalars['numeric']>;
  tueRate?: InputMaybe<Scalars['numeric']>;
  wedRate?: InputMaybe<Scalars['numeric']>;
};

/** Streaming cursor of the table "salesBudgetAllocationSetting" */
export type SalesBudgetAllocationSettingStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SalesBudgetAllocationSettingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type SalesBudgetAllocationSettingStreamCursorValueInput = {
  friRate?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  monRate?: InputMaybe<Scalars['numeric']>;
  satRate?: InputMaybe<Scalars['numeric']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  sunRate?: InputMaybe<Scalars['numeric']>;
  thuRate?: InputMaybe<Scalars['numeric']>;
  tueRate?: InputMaybe<Scalars['numeric']>;
  wedRate?: InputMaybe<Scalars['numeric']>;
};

/** update columns of table "salesBudgetAllocationSetting" */
export enum SalesBudgetAllocationSettingUpdateColumn {
  /** column name */
  FriRate = 'friRate',
  /** column name */
  MonRate = 'monRate',
  /** column name */
  SatRate = 'satRate',
  /** column name */
  SunRate = 'sunRate',
  /** column name */
  ThuRate = 'thuRate',
  /** column name */
  TueRate = 'tueRate',
  /** column name */
  WedRate = 'wedRate'
}

export type SalesBudgetAllocationSettingUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SalesBudgetAllocationSettingIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SalesBudgetAllocationSettingSetInput>;
  /** filter the rows which have to be updated */
  where: SalesBudgetAllocationSettingBoolExp;
};

/** columns and relationships of "scoreTypeQuestionAnswer" */
export type ScoreTypeQuestionAnswer = {
  __typename?: 'scoreTypeQuestionAnswer';
  /** An object relationship */
  questionAnswer?: Maybe<QuestionAnswer>;
  score: Scalars['Int'];
};

/** Boolean expression to filter rows from the table "scoreTypeQuestionAnswer". All fields are combined with a logical 'AND'. */
export type ScoreTypeQuestionAnswerBoolExp = {
  _and?: InputMaybe<Array<ScoreTypeQuestionAnswerBoolExp>>;
  _not?: InputMaybe<ScoreTypeQuestionAnswerBoolExp>;
  _or?: InputMaybe<Array<ScoreTypeQuestionAnswerBoolExp>>;
  questionAnswer?: InputMaybe<QuestionAnswerBoolExp>;
  score?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "scoreTypeQuestionAnswer". */
export type ScoreTypeQuestionAnswerOrderBy = {
  questionAnswer?: InputMaybe<QuestionAnswerOrderBy>;
  score?: InputMaybe<OrderBy>;
};

/** select columns of table "scoreTypeQuestionAnswer" */
export enum ScoreTypeQuestionAnswerSelectColumn {
  /** column name */
  Score = 'score'
}

/** Streaming cursor of the table "scoreTypeQuestionAnswer" */
export type ScoreTypeQuestionAnswerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ScoreTypeQuestionAnswerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ScoreTypeQuestionAnswerStreamCursorValueInput = {
  score?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "serviceAdmin" */
export type ServiceAdmin = {
  __typename?: 'serviceAdmin';
  email: Scalars['String'];
  id: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "serviceAdmin". All fields are combined with a logical 'AND'. */
export type ServiceAdminBoolExp = {
  _and?: InputMaybe<Array<ServiceAdminBoolExp>>;
  _not?: InputMaybe<ServiceAdminBoolExp>;
  _or?: InputMaybe<Array<ServiceAdminBoolExp>>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
};

/** Ordering options when selecting data from "serviceAdmin". */
export type ServiceAdminOrderBy = {
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "serviceAdmin" */
export enum ServiceAdminSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "serviceAdmin" */
export type ServiceAdminStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServiceAdminStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceAdminStreamCursorValueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "serviceChargeConfig" */
export type ServiceChargeConfig = {
  __typename?: 'serviceChargeConfig';
  chargeRate: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  serviceChargeMeta: ServiceChargeMeta;
  /** An object relationship */
  shop: Shop;
  updatedAt: Scalars['timestamptz'];
};

/** order by aggregate values of table "serviceChargeConfig" */
export type ServiceChargeConfigAggregateOrderBy = {
  avg?: InputMaybe<ServiceChargeConfigAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ServiceChargeConfigMaxOrderBy>;
  min?: InputMaybe<ServiceChargeConfigMinOrderBy>;
  stddev?: InputMaybe<ServiceChargeConfigStddevOrderBy>;
  stddev_pop?: InputMaybe<ServiceChargeConfigStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ServiceChargeConfigStddevSampOrderBy>;
  sum?: InputMaybe<ServiceChargeConfigSumOrderBy>;
  var_pop?: InputMaybe<ServiceChargeConfigVarPopOrderBy>;
  var_samp?: InputMaybe<ServiceChargeConfigVarSampOrderBy>;
  variance?: InputMaybe<ServiceChargeConfigVarianceOrderBy>;
};

/** order by avg() on columns of table "serviceChargeConfig" */
export type ServiceChargeConfigAvgOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "serviceChargeConfig". All fields are combined with a logical 'AND'. */
export type ServiceChargeConfigBoolExp = {
  _and?: InputMaybe<Array<ServiceChargeConfigBoolExp>>;
  _not?: InputMaybe<ServiceChargeConfigBoolExp>;
  _or?: InputMaybe<Array<ServiceChargeConfigBoolExp>>;
  chargeRate?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  serviceChargeMeta?: InputMaybe<ServiceChargeMetaBoolExp>;
  shop?: InputMaybe<ShopBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "serviceChargeConfig" */
export enum ServiceChargeConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  ServiceChargeConfigPkey = 'serviceChargeConfig_pkey',
  /** unique or primary key constraint on columns "shopId" */
  ServiceChargeConfigShopIdKey = 'serviceChargeConfig_shopId_key'
}

/** input type for incrementing numeric columns in table "serviceChargeConfig" */
export type ServiceChargeConfigIncInput = {
  chargeRate?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "serviceChargeConfig" */
export type ServiceChargeConfigInsertInput = {
  chargeRate: Scalars['Int'];
  serviceChargeMetaId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "serviceChargeConfig" */
export type ServiceChargeConfigMaxOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "serviceChargeConfig" */
export type ServiceChargeConfigMinOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "serviceChargeConfig" */
export type ServiceChargeConfigMutationResponse = {
  __typename?: 'serviceChargeConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ServiceChargeConfig>;
};

/** input type for inserting object relation for remote table "serviceChargeConfig" */
export type ServiceChargeConfigObjRelInsertInput = {
  data: ServiceChargeConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ServiceChargeConfigOnConflict>;
};

/** on_conflict condition type for table "serviceChargeConfig" */
export type ServiceChargeConfigOnConflict = {
  constraint: ServiceChargeConfigConstraint;
  update_columns?: Array<ServiceChargeConfigUpdateColumn>;
  where?: InputMaybe<ServiceChargeConfigBoolExp>;
};

/** Ordering options when selecting data from "serviceChargeConfig". */
export type ServiceChargeConfigOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  serviceChargeMeta?: InputMaybe<ServiceChargeMetaOrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: serviceChargeConfig */
export type ServiceChargeConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "serviceChargeConfig" */
export enum ServiceChargeConfigSelectColumn {
  /** column name */
  ChargeRate = 'chargeRate',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "serviceChargeConfig" */
export type ServiceChargeConfigSetInput = {
  chargeRate?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "serviceChargeConfig" */
export type ServiceChargeConfigStddevOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "serviceChargeConfig" */
export type ServiceChargeConfigStddevPopOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "serviceChargeConfig" */
export type ServiceChargeConfigStddevSampOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "serviceChargeConfig" */
export type ServiceChargeConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServiceChargeConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceChargeConfigStreamCursorValueInput = {
  chargeRate?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "serviceChargeConfig" */
export type ServiceChargeConfigSumOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** update columns of table "serviceChargeConfig" */
export enum ServiceChargeConfigUpdateColumn {
  /** column name */
  ChargeRate = 'chargeRate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ServiceChargeConfigUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ServiceChargeConfigIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ServiceChargeConfigSetInput>;
  /** filter the rows which have to be updated */
  where: ServiceChargeConfigBoolExp;
};

/** order by var_pop() on columns of table "serviceChargeConfig" */
export type ServiceChargeConfigVarPopOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "serviceChargeConfig" */
export type ServiceChargeConfigVarSampOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "serviceChargeConfig" */
export type ServiceChargeConfigVarianceOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** columns and relationships of "serviceChargeMeta" */
export type ServiceChargeMeta = {
  __typename?: 'serviceChargeMeta';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  menu: Menu;
  /** An array relationship */
  serviceChargeConfigs: Array<ServiceChargeConfig>;
};


/** columns and relationships of "serviceChargeMeta" */
export type ServiceChargeMetaServiceChargeConfigsArgs = {
  distinct_on?: InputMaybe<Array<ServiceChargeConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceChargeConfigOrderBy>>;
  where?: InputMaybe<ServiceChargeConfigBoolExp>;
};

/** Boolean expression to filter rows from the table "serviceChargeMeta". All fields are combined with a logical 'AND'. */
export type ServiceChargeMetaBoolExp = {
  _and?: InputMaybe<Array<ServiceChargeMetaBoolExp>>;
  _not?: InputMaybe<ServiceChargeMetaBoolExp>;
  _or?: InputMaybe<Array<ServiceChargeMetaBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  serviceChargeConfigs?: InputMaybe<ServiceChargeConfigBoolExp>;
};

/** Ordering options when selecting data from "serviceChargeMeta". */
export type ServiceChargeMetaOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  serviceChargeConfigs_aggregate?: InputMaybe<ServiceChargeConfigAggregateOrderBy>;
};

/** select columns of table "serviceChargeMeta" */
export enum ServiceChargeMetaSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "serviceChargeMeta" */
export type ServiceChargeMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ServiceChargeMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ServiceChargeMetaStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "shop" */
export type Shop = {
  __typename?: 'shop';
  _lineOfficialAccountId?: Maybe<Scalars['Int']>;
  accountingActionType?: Maybe<AccountingActionTypeEnum>;
  /** An array relationship */
  accountingSlipImages: Array<AccountingSlipImage>;
  /** An array relationship */
  activePlans: Array<ActivePlan>;
  archivedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  cashRegisterConfig?: Maybe<CashRegisterConfig>;
  cashRegisterSecurityLevel?: Maybe<CashRegisterSecurityLevelEnum>;
  /** An object relationship */
  cashRegisterSecurityLevelByCashregistersecuritylevel?: Maybe<CashRegisterSecurityLevel>;
  changeDateTime: Scalars['String'];
  checkInElapsedTimeAlertThresholdMinutes: Scalars['Int'];
  /** An array relationship */
  checkInEvents: Array<CheckInEvent>;
  /** An array relationship */
  clerks: Array<Clerk>;
  close: Scalars['String'];
  /** An array relationship */
  closeCashRegisters: Array<CloseCashRegister>;
  /** An array relationship */
  coineyPayments: Array<CoineyPayment>;
  /** An array relationship */
  coineySettlements: Array<CoineySettlement>;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  /** An array relationship */
  connectGameConfigShops: Array<ConnectGameConfigShop>;
  contactInfo?: Maybe<Scalars['String']>;
  /** An array relationship */
  cookingItems: Array<CookingItem>;
  /** An array relationship */
  customerQuestionnaires: Array<CustomerQuestionnaire>;
  /** An array relationship */
  dashboardAccountAccessibleShops: Array<DashboardAccountAccessibleShop>;
  defaultTaxMethod: TaxMethodEnum;
  defaultTaxRate: Scalars['numeric'];
  disableCallClerk: Scalars['Boolean'];
  disableClerkCache: Scalars['Boolean'];
  disableSelfAccountingStart: Scalars['Boolean'];
  /** An array relationship */
  dishUpSlipGroupShopMenus: Array<DishUpSlipGroupShopMenus>;
  /** An array relationship */
  dishUpSlipGroupShopPlans: Array<DishUpSlipGroupShopPlans>;
  /** An array relationship */
  dishUpSlipGroups: Array<DishUpSlipGroup>;
  eligibleInvoiceIssuerRegistrationNumber?: Maybe<Scalars['String']>;
  enableAlert: Scalars['Boolean'];
  enableAutoCompulsoryAppetizerOrder?: Maybe<Scalars['Boolean']>;
  enableAutoFractionalDiscount: Scalars['Boolean'];
  enableAutoPrintReceipt: Scalars['Boolean'];
  enableAutoResetMenuSoldOut: Scalars['Boolean'];
  enableCheckInElapsedTimeAlert: Scalars['Boolean'];
  enableCustomerSegmentSelection?: Maybe<Scalars['Boolean']>;
  enableDefaultInflowSourceTag: Scalars['Boolean'];
  enableDisplayTaxExcludedPriceForCustomer: Scalars['Boolean'];
  enableGoogleMapReview: Scalars['Boolean'];
  enableLatestOrderElapsedTimeAlert: Scalars['Boolean'];
  enableRemark: Scalars['Boolean'];
  enableReserveCashSeparation: Scalars['Boolean'];
  enableTableUserGrouping: Scalars['Boolean'];
  exTax: Scalars['Boolean'];
  /** An array relationship */
  externalOnlineMenuConfigs: Array<ExternalOnlineMenuConfig>;
  /** An array relationship */
  featureFlagShops: Array<FeatureFlagShop>;
  /** An object relationship */
  foodingJournalConfig?: Maybe<FoodingJournalConfig>;
  /** An array relationship */
  giftOptionMeta: Array<GiftOptionMeta>;
  googleMapPlaceId?: Maybe<Scalars['String']>;
  hideFreeItemInAccountingSlip: Scalars['Boolean'];
  iconImageUrl?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  /** An array relationship */
  kdDisplayTargets: Array<KdDisplayTarget>;
  lastOrderText: Scalars['String'];
  latestOrderElapsedTimeAlertThresholdMinutes: Scalars['Int'];
  /** An object relationship */
  lineChannelConfig?: Maybe<LineChannelConfig>;
  /** An object relationship */
  lineOfficialAccount?: Maybe<LineOfficialAccount>;
  lineOfficialAccountId?: Maybe<Scalars['uuid']>;
  maxNumForNumPeople?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  nameEn: Scalars['String'];
  offlinePaymentDescription: Scalars['String'];
  /** An array relationship */
  onSitePaymentDetails: Array<OnSitePaymentDetail>;
  /** An array relationship */
  onSitePaymentDiscounts: Array<OnSitePaymentDiscount>;
  /** An array relationship */
  onSitePayments: Array<OnSitePayment>;
  onboardingHandout?: Maybe<Scalars['String']>;
  open: Scalars['String'];
  orderIntervalSeconds?: Maybe<Scalars['Int']>;
  /** An array relationship */
  orders: Array<Order>;
  /** An object relationship */
  pikaichiConfig?: Maybe<PikaichiConfig>;
  /** An array relationship */
  pikaichiMenus: Array<PikaichiMenu>;
  /** An array relationship */
  pikaichiOnSitePaymentDetailTypes: Array<PikaichiOnSitePaymentDetailType>;
  /** An array relationship */
  pikaichiOnSitePaymentDiscountTypes: Array<PikaichiOnSitePaymentDiscountType>;
  /** An array relationship */
  questionnaireAnswers: Array<QuestionnaireAnswer>;
  /** An array relationship */
  roles: Array<Role>;
  /** An object relationship */
  serviceChargeConfig?: Maybe<ServiceChargeConfig>;
  /** An array relationship */
  shopBusinessOperationHours: Array<ShopBusinessOperationHour>;
  /** An array relationship */
  shopChoices: Array<ShopChoice>;
  /** An array relationship */
  shopClerks: Array<ShopClerk>;
  /** An array relationship */
  shopClients: Array<ShopClient>;
  shopCloseConnectionTime: Scalars['String'];
  /** An array relationship */
  shopCookingItemRoles: Array<ShopCookingItemRole>;
  /** An array relationship */
  shopDiscountTypes: Array<ShopDiscountType>;
  shopId: Scalars['uuid'];
  /** An array relationship */
  shopInflowSourceTags: Array<ShopInflowSourceTag>;
  /** An array relationship */
  shopMembers: Array<ShopMember>;
  /** An array relationship */
  shopMenuCookingItems: Array<ShopMenuCookingItem>;
  /** An array relationship */
  shopMenuKitchenRoles: Array<ShopMenuKitchenRole>;
  /** An array relationship */
  shopMenus: Array<ShopMenu>;
  /** An array relationship */
  shopOptionKitchenRoles: Array<ShopOptionKitchenRole>;
  /** An array relationship */
  shopOrderableTimeTerms: Array<ShopOrderableTimeTerm>;
  /** An array relationship */
  shopPaymentTypes: Array<ShopPaymentType>;
  /** An array relationship */
  shopPlanChoices: Array<ShopPlanChoice>;
  /** An array relationship */
  shopPlanKitchenRoles: Array<ShopPlanKitchenRole>;
  /** An array relationship */
  shopPlanOptionKitchenRoles: Array<ShopPlanOptionKitchenRole>;
  /** An array relationship */
  shopPlanOptions: Array<ShopPlanOption>;
  /** An array relationship */
  shopPlans: Array<ShopPlan>;
  shopRebootTime: Scalars['String'];
  shouldOpenDrawerAtOnSitePaymentEveryTime: Scalars['Boolean'];
  /** An object relationship */
  specificTimeSurchargeConfig?: Maybe<SpecificTimeSurchargeConfig>;
  /** An array relationship */
  stocks: Array<Stock>;
  /** An array relationship */
  tableAreas: Array<TableArea>;
  /** An aggregate relationship */
  tableAreas_aggregate: TableAreaAggregate;
  /** An array relationship */
  tableUsers: Array<TableUser>;
  /** An array relationship */
  tables: Array<Table>;
  /** An aggregate relationship */
  tables_aggregate: TableAggregate;
  /** An object relationship */
  taxOffice?: Maybe<TaxOffice>;
  taxOfficeId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  tecAggregationConfigs: Array<TecAggregationConfig>;
  /** An array relationship */
  tecAggregationOnSitePaymentDetailTypes: Array<TecAggregationOnSitePaymentDetailType>;
  useKd: Scalars['Boolean'];
  useKiosk: Scalars['Boolean'];
  /** An object relationship */
  winboardConfig?: Maybe<WinboardConfig>;
};


/** columns and relationships of "shop" */
export type ShopAccountingSlipImagesArgs = {
  distinct_on?: InputMaybe<Array<AccountingSlipImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccountingSlipImageOrderBy>>;
  where?: InputMaybe<AccountingSlipImageBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopActivePlansArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanOrderBy>>;
  where?: InputMaybe<ActivePlanBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopCheckInEventsArgs = {
  distinct_on?: InputMaybe<Array<CheckInEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CheckInEventOrderBy>>;
  where?: InputMaybe<CheckInEventBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopClerksArgs = {
  distinct_on?: InputMaybe<Array<ClerkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClerkOrderBy>>;
  where?: InputMaybe<ClerkBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopCloseCashRegistersArgs = {
  distinct_on?: InputMaybe<Array<CloseCashRegisterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CloseCashRegisterOrderBy>>;
  where?: InputMaybe<CloseCashRegisterBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopCoineyPaymentsArgs = {
  distinct_on?: InputMaybe<Array<CoineyPaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineyPaymentOrderBy>>;
  where?: InputMaybe<CoineyPaymentBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopCoineySettlementsArgs = {
  distinct_on?: InputMaybe<Array<CoineySettlementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineySettlementOrderBy>>;
  where?: InputMaybe<CoineySettlementBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopConnectGameConfigShopsArgs = {
  distinct_on?: InputMaybe<Array<ConnectGameConfigShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConnectGameConfigShopOrderBy>>;
  where?: InputMaybe<ConnectGameConfigShopBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopCookingItemsArgs = {
  distinct_on?: InputMaybe<Array<CookingItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CookingItemOrderBy>>;
  where?: InputMaybe<CookingItemBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopCustomerQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<CustomerQuestionnaireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerQuestionnaireOrderBy>>;
  where?: InputMaybe<CustomerQuestionnaireBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopDashboardAccountAccessibleShopsArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountAccessibleShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountAccessibleShopOrderBy>>;
  where?: InputMaybe<DashboardAccountAccessibleShopBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopDishUpSlipGroupShopMenusArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopMenusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopMenusOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopDishUpSlipGroupShopPlansArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopPlansOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopDishUpSlipGroupsArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopExternalOnlineMenuConfigsArgs = {
  distinct_on?: InputMaybe<Array<ExternalOnlineMenuConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalOnlineMenuConfigOrderBy>>;
  where?: InputMaybe<ExternalOnlineMenuConfigBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopFeatureFlagShopsArgs = {
  distinct_on?: InputMaybe<Array<FeatureFlagShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeatureFlagShopOrderBy>>;
  where?: InputMaybe<FeatureFlagShopBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopGiftOptionMetaArgs = {
  distinct_on?: InputMaybe<Array<GiftOptionMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftOptionMetaOrderBy>>;
  where?: InputMaybe<GiftOptionMetaBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopKdDisplayTargetsArgs = {
  distinct_on?: InputMaybe<Array<KdDisplayTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KdDisplayTargetOrderBy>>;
  where?: InputMaybe<KdDisplayTargetBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopOnSitePaymentDetailsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopOnSitePaymentDiscountsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopOnSitePaymentsArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentOrderBy>>;
  where?: InputMaybe<OnSitePaymentBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopOrdersArgs = {
  distinct_on?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopPikaichiMenusArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuOrderBy>>;
  where?: InputMaybe<PikaichiMenuBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopPikaichiOnSitePaymentDetailTypesArgs = {
  distinct_on?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<PikaichiOnSitePaymentDetailTypeBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopPikaichiOnSitePaymentDiscountTypesArgs = {
  distinct_on?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<PikaichiOnSitePaymentDiscountTypeBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopQuestionnaireAnswersArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireAnswerOrderBy>>;
  where?: InputMaybe<QuestionnaireAnswerBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopRolesArgs = {
  distinct_on?: InputMaybe<Array<RoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleOrderBy>>;
  where?: InputMaybe<RoleBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopBusinessOperationHoursArgs = {
  distinct_on?: InputMaybe<Array<ShopBusinessOperationHourSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopBusinessOperationHourOrderBy>>;
  where?: InputMaybe<ShopBusinessOperationHourBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopChoicesArgs = {
  distinct_on?: InputMaybe<Array<ShopChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopChoiceOrderBy>>;
  where?: InputMaybe<ShopChoiceBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopClerksArgs = {
  distinct_on?: InputMaybe<Array<ShopClerkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopClerkOrderBy>>;
  where?: InputMaybe<ShopClerkBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopClientsArgs = {
  distinct_on?: InputMaybe<Array<ShopClientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopClientOrderBy>>;
  where?: InputMaybe<ShopClientBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopCookingItemRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopCookingItemRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopCookingItemRoleOrderBy>>;
  where?: InputMaybe<ShopCookingItemRoleBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopDiscountTypesArgs = {
  distinct_on?: InputMaybe<Array<ShopDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopDiscountTypeOrderBy>>;
  where?: InputMaybe<ShopDiscountTypeBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopInflowSourceTagsArgs = {
  distinct_on?: InputMaybe<Array<ShopInflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopInflowSourceTagOrderBy>>;
  where?: InputMaybe<ShopInflowSourceTagBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopMembersArgs = {
  distinct_on?: InputMaybe<Array<ShopMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMemberOrderBy>>;
  where?: InputMaybe<ShopMemberBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopMenuCookingItemsArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuCookingItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuCookingItemOrderBy>>;
  where?: InputMaybe<ShopMenuCookingItemBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopMenuKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopMenusArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuOrderBy>>;
  where?: InputMaybe<ShopMenuBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopOptionKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopOptionKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOptionKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopOptionKitchenRoleBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopOrderableTimeTermsArgs = {
  distinct_on?: InputMaybe<Array<ShopOrderableTimeTermSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOrderableTimeTermOrderBy>>;
  where?: InputMaybe<ShopOrderableTimeTermBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopPaymentTypesArgs = {
  distinct_on?: InputMaybe<Array<ShopPaymentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPaymentTypeOrderBy>>;
  where?: InputMaybe<ShopPaymentTypeBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopPlanChoicesArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanChoiceOrderBy>>;
  where?: InputMaybe<ShopPlanChoiceBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopPlanKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopPlanOptionKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanOptionKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOptionKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopPlanOptionKitchenRoleBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopPlanOptionsArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOptionOrderBy>>;
  where?: InputMaybe<ShopPlanOptionBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopShopPlansArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOrderBy>>;
  where?: InputMaybe<ShopPlanBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopStocksArgs = {
  distinct_on?: InputMaybe<Array<StockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StockOrderBy>>;
  where?: InputMaybe<StockBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopTableAreasArgs = {
  distinct_on?: InputMaybe<Array<TableAreaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableAreaOrderBy>>;
  where?: InputMaybe<TableAreaBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopTableAreasAggregateArgs = {
  distinct_on?: InputMaybe<Array<TableAreaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableAreaOrderBy>>;
  where?: InputMaybe<TableAreaBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopTableUsersArgs = {
  distinct_on?: InputMaybe<Array<TableUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserOrderBy>>;
  where?: InputMaybe<TableUserBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopTablesArgs = {
  distinct_on?: InputMaybe<Array<TableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableOrderBy>>;
  where?: InputMaybe<TableBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopTablesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableOrderBy>>;
  where?: InputMaybe<TableBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopTecAggregationConfigsArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationConfigOrderBy>>;
  where?: InputMaybe<TecAggregationConfigBoolExp>;
};


/** columns and relationships of "shop" */
export type ShopTecAggregationOnSitePaymentDetailTypesArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
};

/** columns and relationships of "shopBusinessOperationHour" */
export type ShopBusinessOperationHour = {
  __typename?: 'shopBusinessOperationHour';
  businessOperationHourType: BusinessOperationHourTypeEnum;
  end: Scalars['time'];
  id: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  start: Scalars['time'];
};

/** order by aggregate values of table "shopBusinessOperationHour" */
export type ShopBusinessOperationHourAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopBusinessOperationHourMaxOrderBy>;
  min?: InputMaybe<ShopBusinessOperationHourMinOrderBy>;
};

/** input type for inserting array relation for remote table "shopBusinessOperationHour" */
export type ShopBusinessOperationHourArrRelInsertInput = {
  data: Array<ShopBusinessOperationHourInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopBusinessOperationHourOnConflict>;
};

/** Boolean expression to filter rows from the table "shopBusinessOperationHour". All fields are combined with a logical 'AND'. */
export type ShopBusinessOperationHourBoolExp = {
  _and?: InputMaybe<Array<ShopBusinessOperationHourBoolExp>>;
  _not?: InputMaybe<ShopBusinessOperationHourBoolExp>;
  _or?: InputMaybe<Array<ShopBusinessOperationHourBoolExp>>;
  businessOperationHourType?: InputMaybe<BusinessOperationHourTypeEnumComparisonExp>;
  end?: InputMaybe<TimeComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  start?: InputMaybe<TimeComparisonExp>;
};

/** unique or primary key constraints on table "shopBusinessOperationHour" */
export enum ShopBusinessOperationHourConstraint {
  /** unique or primary key constraint on columns "id" */
  ShopBusinessOperationHourPkey = 'shopBusinessOperationHour_pkey',
  /** unique or primary key constraint on columns "shopId", "businessOperationHourType" */
  ShopBusinessOperationHourShopIdBusinessOperationHourTypeKey = 'shopBusinessOperationHour_shopId_businessOperationHourType_key'
}

/** input type for inserting data into table "shopBusinessOperationHour" */
export type ShopBusinessOperationHourInsertInput = {
  businessOperationHourType: BusinessOperationHourTypeEnum;
  end: Scalars['time'];
  id?: InputMaybe<Scalars['uuid']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  start: Scalars['time'];
};

/** order by max() on columns of table "shopBusinessOperationHour" */
export type ShopBusinessOperationHourMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopBusinessOperationHour" */
export type ShopBusinessOperationHourMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopBusinessOperationHour" */
export type ShopBusinessOperationHourMutationResponse = {
  __typename?: 'shopBusinessOperationHour_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopBusinessOperationHour>;
};

/** on_conflict condition type for table "shopBusinessOperationHour" */
export type ShopBusinessOperationHourOnConflict = {
  constraint: ShopBusinessOperationHourConstraint;
  update_columns?: Array<ShopBusinessOperationHourUpdateColumn>;
  where?: InputMaybe<ShopBusinessOperationHourBoolExp>;
};

/** Ordering options when selecting data from "shopBusinessOperationHour". */
export type ShopBusinessOperationHourOrderBy = {
  businessOperationHourType?: InputMaybe<OrderBy>;
  end?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  start?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shopBusinessOperationHour */
export type ShopBusinessOperationHourPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "shopBusinessOperationHour" */
export enum ShopBusinessOperationHourSelectColumn {
  /** column name */
  BusinessOperationHourType = 'businessOperationHourType',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  Start = 'start'
}

/** input type for updating data in table "shopBusinessOperationHour" */
export type ShopBusinessOperationHourSetInput = {
  businessOperationHourType?: InputMaybe<BusinessOperationHourTypeEnum>;
  end?: InputMaybe<Scalars['time']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  start?: InputMaybe<Scalars['time']>;
};

/** Streaming cursor of the table "shopBusinessOperationHour" */
export type ShopBusinessOperationHourStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopBusinessOperationHourStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopBusinessOperationHourStreamCursorValueInput = {
  businessOperationHourType?: InputMaybe<BusinessOperationHourTypeEnum>;
  end?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  start?: InputMaybe<Scalars['time']>;
};

/** update columns of table "shopBusinessOperationHour" */
export enum ShopBusinessOperationHourUpdateColumn {
  /** column name */
  BusinessOperationHourType = 'businessOperationHourType',
  /** column name */
  End = 'end',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  Start = 'start'
}

export type ShopBusinessOperationHourUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopBusinessOperationHourSetInput>;
  /** filter the rows which have to be updated */
  where: ShopBusinessOperationHourBoolExp;
};

/** columns and relationships of "shopChoice" */
export type ShopChoice = {
  __typename?: 'shopChoice';
  _choiceId: Scalars['Int'];
  archivedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  choice: Choice;
  choiceId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  isVisibleForCustomer: Scalars['Boolean'];
  isVisibleForStaff: Scalars['Boolean'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An object relationship */
  stock?: Maybe<Stock>;
  stockId?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "shopChoice" */
export type ShopChoiceAggregateOrderBy = {
  avg?: InputMaybe<ShopChoiceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopChoiceMaxOrderBy>;
  min?: InputMaybe<ShopChoiceMinOrderBy>;
  stddev?: InputMaybe<ShopChoiceStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopChoiceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopChoiceStddevSampOrderBy>;
  sum?: InputMaybe<ShopChoiceSumOrderBy>;
  var_pop?: InputMaybe<ShopChoiceVarPopOrderBy>;
  var_samp?: InputMaybe<ShopChoiceVarSampOrderBy>;
  variance?: InputMaybe<ShopChoiceVarianceOrderBy>;
};

/** order by avg() on columns of table "shopChoice" */
export type ShopChoiceAvgOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopChoice". All fields are combined with a logical 'AND'. */
export type ShopChoiceBoolExp = {
  _and?: InputMaybe<Array<ShopChoiceBoolExp>>;
  _choiceId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<ShopChoiceBoolExp>;
  _or?: InputMaybe<Array<ShopChoiceBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  choice?: InputMaybe<ChoiceBoolExp>;
  choiceId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isVisibleForCustomer?: InputMaybe<BooleanComparisonExp>;
  isVisibleForStaff?: InputMaybe<BooleanComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  stock?: InputMaybe<StockBoolExp>;
  stockId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "shopChoice" */
export type ShopChoiceMaxOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopChoice" */
export type ShopChoiceMinOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "shopChoice". */
export type ShopChoiceOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  choice?: InputMaybe<ChoiceOrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isVisibleForCustomer?: InputMaybe<OrderBy>;
  isVisibleForStaff?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  stock?: InputMaybe<StockOrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** select columns of table "shopChoice" */
export enum ShopChoiceSelectColumn {
  /** column name */
  _ChoiceId = '_choiceId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  ChoiceId = 'choiceId',
  /** column name */
  Id = 'id',
  /** column name */
  IsVisibleForCustomer = 'isVisibleForCustomer',
  /** column name */
  IsVisibleForStaff = 'isVisibleForStaff',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  StockId = 'stockId'
}

/** order by stddev() on columns of table "shopChoice" */
export type ShopChoiceStddevOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopChoice" */
export type ShopChoiceStddevPopOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopChoice" */
export type ShopChoiceStddevSampOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopChoice" */
export type ShopChoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopChoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopChoiceStreamCursorValueInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  choiceId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  stockId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopChoice" */
export type ShopChoiceSumOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "shopChoice" */
export type ShopChoiceVarPopOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopChoice" */
export type ShopChoiceVarSampOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopChoice" */
export type ShopChoiceVarianceOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "shopClerk" */
export type ShopClerk = {
  __typename?: 'shopClerk';
  _clerkId: Scalars['Int'];
  /** An object relationship */
  clerk: Clerk;
  clerkId: Scalars['uuid'];
  isDisplay: Scalars['Boolean'];
  priority: Scalars['Int'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "shopClerk" */
export type ShopClerkAggregateOrderBy = {
  avg?: InputMaybe<ShopClerkAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopClerkMaxOrderBy>;
  min?: InputMaybe<ShopClerkMinOrderBy>;
  stddev?: InputMaybe<ShopClerkStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopClerkStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopClerkStddevSampOrderBy>;
  sum?: InputMaybe<ShopClerkSumOrderBy>;
  var_pop?: InputMaybe<ShopClerkVarPopOrderBy>;
  var_samp?: InputMaybe<ShopClerkVarSampOrderBy>;
  variance?: InputMaybe<ShopClerkVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "shopClerk" */
export type ShopClerkArrRelInsertInput = {
  data: Array<ShopClerkInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopClerkOnConflict>;
};

/** order by avg() on columns of table "shopClerk" */
export type ShopClerkAvgOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopClerk". All fields are combined with a logical 'AND'. */
export type ShopClerkBoolExp = {
  _and?: InputMaybe<Array<ShopClerkBoolExp>>;
  _clerkId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<ShopClerkBoolExp>;
  _or?: InputMaybe<Array<ShopClerkBoolExp>>;
  clerk?: InputMaybe<ClerkBoolExp>;
  clerkId?: InputMaybe<UuidComparisonExp>;
  isDisplay?: InputMaybe<BooleanComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "shopClerk" */
export enum ShopClerkConstraint {
  /** unique or primary key constraint on columns "_clerkId", "shopId" */
  ShopClerkPkey = 'shopClerk_pkey',
  /** unique or primary key constraint on columns "_clerkId", "shopId" */
  ShopClerkShopIdClerkIdKey = 'shopClerk_shopId_clerkId_key',
  /** unique or primary key constraint on columns "shopId", "clerkId" */
  ShopClerkShopIdClerkUuidKey = 'shopClerk_shopId_clerkUuid_key'
}

/** input type for incrementing numeric columns in table "shopClerk" */
export type ShopClerkIncInput = {
  _clerkId?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shopClerk" */
export type ShopClerkInsertInput = {
  _clerkId: Scalars['Int'];
  clerk?: InputMaybe<ClerkObjRelInsertInput>;
  clerkId: Scalars['uuid'];
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['Int']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "shopClerk" */
export type ShopClerkMaxOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopClerk" */
export type ShopClerkMinOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopClerk" */
export type ShopClerkMutationResponse = {
  __typename?: 'shopClerk_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopClerk>;
};

/** on_conflict condition type for table "shopClerk" */
export type ShopClerkOnConflict = {
  constraint: ShopClerkConstraint;
  update_columns?: Array<ShopClerkUpdateColumn>;
  where?: InputMaybe<ShopClerkBoolExp>;
};

/** Ordering options when selecting data from "shopClerk". */
export type ShopClerkOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  clerk?: InputMaybe<ClerkOrderBy>;
  clerkId?: InputMaybe<OrderBy>;
  isDisplay?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shopClerk */
export type ShopClerkPkColumnsInput = {
  _clerkId: Scalars['Int'];
  shopId: Scalars['uuid'];
};

/** select columns of table "shopClerk" */
export enum ShopClerkSelectColumn {
  /** column name */
  _ClerkId = '_clerkId',
  /** column name */
  ClerkId = 'clerkId',
  /** column name */
  IsDisplay = 'isDisplay',
  /** column name */
  Priority = 'priority',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "shopClerk" */
export type ShopClerkSetInput = {
  _clerkId?: InputMaybe<Scalars['Int']>;
  clerkId?: InputMaybe<Scalars['uuid']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shopClerk" */
export type ShopClerkStddevOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopClerk" */
export type ShopClerkStddevPopOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopClerk" */
export type ShopClerkStddevSampOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopClerk" */
export type ShopClerkStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopClerkStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopClerkStreamCursorValueInput = {
  _clerkId?: InputMaybe<Scalars['Int']>;
  clerkId?: InputMaybe<Scalars['uuid']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopClerk" */
export type ShopClerkSumOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** update columns of table "shopClerk" */
export enum ShopClerkUpdateColumn {
  /** column name */
  _ClerkId = '_clerkId',
  /** column name */
  ClerkId = 'clerkId',
  /** column name */
  IsDisplay = 'isDisplay',
  /** column name */
  Priority = 'priority',
  /** column name */
  ShopId = 'shopId'
}

export type ShopClerkUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopClerkIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopClerkSetInput>;
  /** filter the rows which have to be updated */
  where: ShopClerkBoolExp;
};

/** order by var_pop() on columns of table "shopClerk" */
export type ShopClerkVarPopOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopClerk" */
export type ShopClerkVarSampOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopClerk" */
export type ShopClerkVarianceOrderBy = {
  _clerkId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
};

/** columns and relationships of "shopClient" */
export type ShopClient = {
  __typename?: 'shopClient';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  roles: Scalars['String'];
  /** An object relationship */
  shop: Shop;
  shopClientId: Scalars['String'];
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "shopClient" */
export type ShopClientAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopClientMaxOrderBy>;
  min?: InputMaybe<ShopClientMinOrderBy>;
};

/** input type for inserting array relation for remote table "shopClient" */
export type ShopClientArrRelInsertInput = {
  data: Array<ShopClientInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopClientOnConflict>;
};

/** Boolean expression to filter rows from the table "shopClient". All fields are combined with a logical 'AND'. */
export type ShopClientBoolExp = {
  _and?: InputMaybe<Array<ShopClientBoolExp>>;
  _not?: InputMaybe<ShopClientBoolExp>;
  _or?: InputMaybe<Array<ShopClientBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  roles?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopClientId?: InputMaybe<StringComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "shopClient" */
export enum ShopClientConstraint {
  /** unique or primary key constraint on columns "email" */
  Idx_46675Idx_256be13d4858ef16e5158d9e35 = 'idx_46675_IDX_256be13d4858ef16e5158d9e35',
  /** unique or primary key constraint on columns "shopClientId" */
  Idx_46675Primary = 'idx_46675_PRIMARY',
  /** unique or primary key constraint on columns "email" */
  ShopClientEmailKey = 'shopClient_email_key'
}

/** input type for inserting data into table "shopClient" */
export type ShopClientInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  roles: Scalars['String'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopClientId: Scalars['String'];
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "shopClient" */
export type ShopClientMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  roles?: InputMaybe<OrderBy>;
  shopClientId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopClient" */
export type ShopClientMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  roles?: InputMaybe<OrderBy>;
  shopClientId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopClient" */
export type ShopClientMutationResponse = {
  __typename?: 'shopClient_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopClient>;
};

/** on_conflict condition type for table "shopClient" */
export type ShopClientOnConflict = {
  constraint: ShopClientConstraint;
  update_columns?: Array<ShopClientUpdateColumn>;
  where?: InputMaybe<ShopClientBoolExp>;
};

/** Ordering options when selecting data from "shopClient". */
export type ShopClientOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  roles?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopClientId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shopClient */
export type ShopClientPkColumnsInput = {
  shopClientId: Scalars['String'];
};

/** select columns of table "shopClient" */
export enum ShopClientSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Name = 'name',
  /** column name */
  Roles = 'roles',
  /** column name */
  ShopClientId = 'shopClientId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "shopClient" */
export type ShopClientSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Scalars['String']>;
  shopClientId?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "shopClient" */
export type ShopClientStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopClientStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopClientStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Scalars['String']>;
  shopClientId?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "shopClient" */
export enum ShopClientUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Name = 'name',
  /** column name */
  Roles = 'roles',
  /** column name */
  ShopClientId = 'shopClientId',
  /** column name */
  ShopId = 'shopId'
}

export type ShopClientUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopClientSetInput>;
  /** filter the rows which have to be updated */
  where: ShopClientBoolExp;
};

/** columns and relationships of "shopCookingItemRole" */
export type ShopCookingItemRole = {
  __typename?: 'shopCookingItemRole';
  _cookingItemId: Scalars['Int'];
  _roleId: Scalars['Int'];
  /** An object relationship */
  cookingItem: CookingItem;
  cookingItemId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  role: Role;
  roleId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "shopCookingItemRole" */
export type ShopCookingItemRoleAggregateOrderBy = {
  avg?: InputMaybe<ShopCookingItemRoleAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopCookingItemRoleMaxOrderBy>;
  min?: InputMaybe<ShopCookingItemRoleMinOrderBy>;
  stddev?: InputMaybe<ShopCookingItemRoleStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopCookingItemRoleStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopCookingItemRoleStddevSampOrderBy>;
  sum?: InputMaybe<ShopCookingItemRoleSumOrderBy>;
  var_pop?: InputMaybe<ShopCookingItemRoleVarPopOrderBy>;
  var_samp?: InputMaybe<ShopCookingItemRoleVarSampOrderBy>;
  variance?: InputMaybe<ShopCookingItemRoleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "shopCookingItemRole" */
export type ShopCookingItemRoleArrRelInsertInput = {
  data: Array<ShopCookingItemRoleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopCookingItemRoleOnConflict>;
};

/** order by avg() on columns of table "shopCookingItemRole" */
export type ShopCookingItemRoleAvgOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopCookingItemRole". All fields are combined with a logical 'AND'. */
export type ShopCookingItemRoleBoolExp = {
  _and?: InputMaybe<Array<ShopCookingItemRoleBoolExp>>;
  _cookingItemId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<ShopCookingItemRoleBoolExp>;
  _or?: InputMaybe<Array<ShopCookingItemRoleBoolExp>>;
  _roleId?: InputMaybe<IntComparisonExp>;
  cookingItem?: InputMaybe<CookingItemBoolExp>;
  cookingItemId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  role?: InputMaybe<RoleBoolExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "shopCookingItemRole" */
export enum ShopCookingItemRoleConstraint {
  /** unique or primary key constraint on columns "_cookingItemId" */
  ShopCookingItemRoleCookingItemIdKey = 'shopCookingItemRole__cookingItemId_key',
  /** unique or primary key constraint on columns "id" */
  ShopKitchenSlipItemRolePkey = 'shopKitchenSlipItemRole_pkey'
}

/** input type for incrementing numeric columns in table "shopCookingItemRole" */
export type ShopCookingItemRoleIncInput = {
  _cookingItemId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shopCookingItemRole" */
export type ShopCookingItemRoleInsertInput = {
  _cookingItemId: Scalars['Int'];
  _roleId: Scalars['Int'];
  cookingItem?: InputMaybe<CookingItemObjRelInsertInput>;
  cookingItemId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<RoleObjRelInsertInput>;
  roleId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "shopCookingItemRole" */
export type ShopCookingItemRoleMaxOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  cookingItemId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopCookingItemRole" */
export type ShopCookingItemRoleMinOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  cookingItemId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopCookingItemRole" */
export type ShopCookingItemRoleMutationResponse = {
  __typename?: 'shopCookingItemRole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopCookingItemRole>;
};

/** input type for inserting object relation for remote table "shopCookingItemRole" */
export type ShopCookingItemRoleObjRelInsertInput = {
  data: ShopCookingItemRoleInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopCookingItemRoleOnConflict>;
};

/** on_conflict condition type for table "shopCookingItemRole" */
export type ShopCookingItemRoleOnConflict = {
  constraint: ShopCookingItemRoleConstraint;
  update_columns?: Array<ShopCookingItemRoleUpdateColumn>;
  where?: InputMaybe<ShopCookingItemRoleBoolExp>;
};

/** Ordering options when selecting data from "shopCookingItemRole". */
export type ShopCookingItemRoleOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  cookingItem?: InputMaybe<CookingItemOrderBy>;
  cookingItemId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  role?: InputMaybe<RoleOrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shopCookingItemRole */
export type ShopCookingItemRolePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "shopCookingItemRole" */
export enum ShopCookingItemRoleSelectColumn {
  /** column name */
  _CookingItemId = '_cookingItemId',
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  CookingItemId = 'cookingItemId',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "shopCookingItemRole" */
export type ShopCookingItemRoleSetInput = {
  _cookingItemId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
  cookingItemId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shopCookingItemRole" */
export type ShopCookingItemRoleStddevOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopCookingItemRole" */
export type ShopCookingItemRoleStddevPopOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopCookingItemRole" */
export type ShopCookingItemRoleStddevSampOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopCookingItemRole" */
export type ShopCookingItemRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopCookingItemRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopCookingItemRoleStreamCursorValueInput = {
  _cookingItemId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
  cookingItemId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopCookingItemRole" */
export type ShopCookingItemRoleSumOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** update columns of table "shopCookingItemRole" */
export enum ShopCookingItemRoleUpdateColumn {
  /** column name */
  _CookingItemId = '_cookingItemId',
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  CookingItemId = 'cookingItemId',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

export type ShopCookingItemRoleUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopCookingItemRoleIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopCookingItemRoleSetInput>;
  /** filter the rows which have to be updated */
  where: ShopCookingItemRoleBoolExp;
};

/** order by var_pop() on columns of table "shopCookingItemRole" */
export type ShopCookingItemRoleVarPopOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopCookingItemRole" */
export type ShopCookingItemRoleVarSampOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopCookingItemRole" */
export type ShopCookingItemRoleVarianceOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "shopDiscountType" */
export type ShopDiscountType = {
  __typename?: 'shopDiscountType';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  isDisplay: Scalars['Boolean'];
  /** An object relationship */
  onSitePaymentDiscountType: OnSitePaymentDiscountType;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  type: Scalars['String'];
};

/** order by aggregate values of table "shopDiscountType" */
export type ShopDiscountTypeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopDiscountTypeMaxOrderBy>;
  min?: InputMaybe<ShopDiscountTypeMinOrderBy>;
};

/** input type for inserting array relation for remote table "shopDiscountType" */
export type ShopDiscountTypeArrRelInsertInput = {
  data: Array<ShopDiscountTypeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopDiscountTypeOnConflict>;
};

/** Boolean expression to filter rows from the table "shopDiscountType". All fields are combined with a logical 'AND'. */
export type ShopDiscountTypeBoolExp = {
  _and?: InputMaybe<Array<ShopDiscountTypeBoolExp>>;
  _not?: InputMaybe<ShopDiscountTypeBoolExp>;
  _or?: InputMaybe<Array<ShopDiscountTypeBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  isDisplay?: InputMaybe<BooleanComparisonExp>;
  onSitePaymentDiscountType?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "shopDiscountType" */
export enum ShopDiscountTypeConstraint {
  /** unique or primary key constraint on columns "shopId", "type" */
  ShopDiscountTypePkey = 'shopDiscountType_pkey'
}

/** input type for inserting data into table "shopDiscountType" */
export type ShopDiscountTypeInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  onSitePaymentDiscountType?: InputMaybe<OnSitePaymentDiscountTypeObjRelInsertInput>;
  onSitePaymentDiscountTypeId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  type: Scalars['String'];
};

/** order by max() on columns of table "shopDiscountType" */
export type ShopDiscountTypeMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopDiscountType" */
export type ShopDiscountTypeMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopDiscountType" */
export type ShopDiscountTypeMutationResponse = {
  __typename?: 'shopDiscountType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopDiscountType>;
};

/** on_conflict condition type for table "shopDiscountType" */
export type ShopDiscountTypeOnConflict = {
  constraint: ShopDiscountTypeConstraint;
  update_columns?: Array<ShopDiscountTypeUpdateColumn>;
  where?: InputMaybe<ShopDiscountTypeBoolExp>;
};

/** Ordering options when selecting data from "shopDiscountType". */
export type ShopDiscountTypeOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  isDisplay?: InputMaybe<OrderBy>;
  onSitePaymentDiscountType?: InputMaybe<OnSitePaymentDiscountTypeOrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shopDiscountType */
export type ShopDiscountTypePkColumnsInput = {
  shopId: Scalars['uuid'];
  type: Scalars['String'];
};

/** select columns of table "shopDiscountType" */
export enum ShopDiscountTypeSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  IsDisplay = 'isDisplay',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "shopDiscountType" */
export type ShopDiscountTypeSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "shopDiscountType" */
export type ShopDiscountTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopDiscountTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopDiscountTypeStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "shopDiscountType" */
export enum ShopDiscountTypeUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  IsDisplay = 'isDisplay',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  Type = 'type'
}

export type ShopDiscountTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopDiscountTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ShopDiscountTypeBoolExp;
};

/** columns and relationships of "shopInflowSourceTag" */
export type ShopInflowSourceTag = {
  __typename?: 'shopInflowSourceTag';
  /** An object relationship */
  inflowSourceTag: InflowSourceTag;
  inflowSourceTagId: Scalars['uuid'];
  isDisplay: Scalars['Boolean'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "shopInflowSourceTag" */
export type ShopInflowSourceTagAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopInflowSourceTagMaxOrderBy>;
  min?: InputMaybe<ShopInflowSourceTagMinOrderBy>;
};

/** input type for inserting array relation for remote table "shopInflowSourceTag" */
export type ShopInflowSourceTagArrRelInsertInput = {
  data: Array<ShopInflowSourceTagInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopInflowSourceTagOnConflict>;
};

/** Boolean expression to filter rows from the table "shopInflowSourceTag". All fields are combined with a logical 'AND'. */
export type ShopInflowSourceTagBoolExp = {
  _and?: InputMaybe<Array<ShopInflowSourceTagBoolExp>>;
  _not?: InputMaybe<ShopInflowSourceTagBoolExp>;
  _or?: InputMaybe<Array<ShopInflowSourceTagBoolExp>>;
  inflowSourceTag?: InputMaybe<InflowSourceTagBoolExp>;
  inflowSourceTagId?: InputMaybe<UuidComparisonExp>;
  isDisplay?: InputMaybe<BooleanComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "shopInflowSourceTag" */
export enum ShopInflowSourceTagConstraint {
  /** unique or primary key constraint on columns "id" */
  ShopInflowSourceTagPkey = 'shopInflowSourceTag_pkey',
  /** unique or primary key constraint on columns "shopId", "inflowSourceTagId" */
  ShopInflowSourceTagShopIdInflowSourceTagIdKey = 'shopInflowSourceTag_shopId_inflowSourceTagId_key'
}

/** input type for inserting data into table "shopInflowSourceTag" */
export type ShopInflowSourceTagInsertInput = {
  inflowSourceTag?: InputMaybe<InflowSourceTagObjRelInsertInput>;
  inflowSourceTagId: Scalars['uuid'];
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "shopInflowSourceTag" */
export type ShopInflowSourceTagMaxOrderBy = {
  inflowSourceTagId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopInflowSourceTag" */
export type ShopInflowSourceTagMinOrderBy = {
  inflowSourceTagId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopInflowSourceTag" */
export type ShopInflowSourceTagMutationResponse = {
  __typename?: 'shopInflowSourceTag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopInflowSourceTag>;
};

/** on_conflict condition type for table "shopInflowSourceTag" */
export type ShopInflowSourceTagOnConflict = {
  constraint: ShopInflowSourceTagConstraint;
  update_columns?: Array<ShopInflowSourceTagUpdateColumn>;
  where?: InputMaybe<ShopInflowSourceTagBoolExp>;
};

/** Ordering options when selecting data from "shopInflowSourceTag". */
export type ShopInflowSourceTagOrderBy = {
  inflowSourceTag?: InputMaybe<InflowSourceTagOrderBy>;
  inflowSourceTagId?: InputMaybe<OrderBy>;
  isDisplay?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "shopInflowSourceTag" */
export enum ShopInflowSourceTagSelectColumn {
  /** column name */
  InflowSourceTagId = 'inflowSourceTagId',
  /** column name */
  IsDisplay = 'isDisplay',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "shopInflowSourceTag" */
export type ShopInflowSourceTagSetInput = {
  inflowSourceTagId?: InputMaybe<Scalars['uuid']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "shopInflowSourceTag" */
export type ShopInflowSourceTagStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopInflowSourceTagStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopInflowSourceTagStreamCursorValueInput = {
  inflowSourceTagId?: InputMaybe<Scalars['uuid']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "shopInflowSourceTag" */
export enum ShopInflowSourceTagUpdateColumn {
  /** column name */
  InflowSourceTagId = 'inflowSourceTagId',
  /** column name */
  IsDisplay = 'isDisplay',
  /** column name */
  ShopId = 'shopId'
}

export type ShopInflowSourceTagUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopInflowSourceTagSetInput>;
  /** filter the rows which have to be updated */
  where: ShopInflowSourceTagBoolExp;
};

/** columns and relationships of "shopMember" */
export type ShopMember = {
  __typename?: 'shopMember';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  customer: Customer;
  customerId: Scalars['String'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "shopMember" */
export type ShopMemberAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopMemberMaxOrderBy>;
  min?: InputMaybe<ShopMemberMinOrderBy>;
};

/** Boolean expression to filter rows from the table "shopMember". All fields are combined with a logical 'AND'. */
export type ShopMemberBoolExp = {
  _and?: InputMaybe<Array<ShopMemberBoolExp>>;
  _not?: InputMaybe<ShopMemberBoolExp>;
  _or?: InputMaybe<Array<ShopMemberBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  customer?: InputMaybe<CustomerBoolExp>;
  customerId?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "shopMember" */
export type ShopMemberMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  customerId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopMember" */
export type ShopMemberMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  customerId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "shopMember". */
export type ShopMemberOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  customer?: InputMaybe<CustomerOrderBy>;
  customerId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "shopMember" */
export enum ShopMemberSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "shopMember" */
export type ShopMemberStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopMemberStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopMemberStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "shopMenu" */
export type ShopMenu = {
  __typename?: 'shopMenu';
  _menuId: Scalars['Int'];
  archivedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  dishUpSlipGroupShopMenus: Array<DishUpSlipGroupShopMenus>;
  id: Scalars['uuid'];
  isVisibleForCustomer: Scalars['Boolean'];
  isVisibleForStaff: Scalars['Boolean'];
  /** An object relationship */
  menu: Menu;
  menuId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An array relationship */
  shopMenuCookingItems: Array<ShopMenuCookingItem>;
  /** An array relationship */
  shopMenuKdDisplayTargets: Array<ShopMenuKdDisplayTarget>;
  /** An array relationship */
  shopMenuKitchenRoles: Array<ShopMenuKitchenRole>;
  /** An object relationship */
  stock?: Maybe<Stock>;
  stockId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "shopMenu" */
export type ShopMenuDishUpSlipGroupShopMenusArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopMenusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopMenusOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
};


/** columns and relationships of "shopMenu" */
export type ShopMenuShopMenuCookingItemsArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuCookingItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuCookingItemOrderBy>>;
  where?: InputMaybe<ShopMenuCookingItemBoolExp>;
};


/** columns and relationships of "shopMenu" */
export type ShopMenuShopMenuKdDisplayTargetsArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuKdDisplayTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuKdDisplayTargetOrderBy>>;
  where?: InputMaybe<ShopMenuKdDisplayTargetBoolExp>;
};


/** columns and relationships of "shopMenu" */
export type ShopMenuShopMenuKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
};

/** columns and relationships of "shopMenuCookingItem" */
export type ShopMenuCookingItem = {
  __typename?: 'shopMenuCookingItem';
  _cookingItemId: Scalars['Int'];
  _menuId: Scalars['Int'];
  /** An object relationship */
  cookingItem?: Maybe<CookingItem>;
  cookingItemId: Scalars['uuid'];
  /** An object relationship */
  menu: Menu;
  menuId: Scalars['uuid'];
  /** An object relationship */
  shop?: Maybe<Shop>;
  /** An object relationship */
  shopCookingItemRole?: Maybe<ShopCookingItemRole>;
  shopId: Scalars['uuid'];
  /** An object relationship */
  shopMenu?: Maybe<ShopMenu>;
};

/** order by aggregate values of table "shopMenuCookingItem" */
export type ShopMenuCookingItemAggregateOrderBy = {
  avg?: InputMaybe<ShopMenuCookingItemAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopMenuCookingItemMaxOrderBy>;
  min?: InputMaybe<ShopMenuCookingItemMinOrderBy>;
  stddev?: InputMaybe<ShopMenuCookingItemStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopMenuCookingItemStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopMenuCookingItemStddevSampOrderBy>;
  sum?: InputMaybe<ShopMenuCookingItemSumOrderBy>;
  var_pop?: InputMaybe<ShopMenuCookingItemVarPopOrderBy>;
  var_samp?: InputMaybe<ShopMenuCookingItemVarSampOrderBy>;
  variance?: InputMaybe<ShopMenuCookingItemVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "shopMenuCookingItem" */
export type ShopMenuCookingItemArrRelInsertInput = {
  data: Array<ShopMenuCookingItemInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopMenuCookingItemOnConflict>;
};

/** order by avg() on columns of table "shopMenuCookingItem" */
export type ShopMenuCookingItemAvgOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopMenuCookingItem". All fields are combined with a logical 'AND'. */
export type ShopMenuCookingItemBoolExp = {
  _and?: InputMaybe<Array<ShopMenuCookingItemBoolExp>>;
  _cookingItemId?: InputMaybe<IntComparisonExp>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<ShopMenuCookingItemBoolExp>;
  _or?: InputMaybe<Array<ShopMenuCookingItemBoolExp>>;
  cookingItem?: InputMaybe<CookingItemBoolExp>;
  cookingItemId?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopCookingItemRole?: InputMaybe<ShopCookingItemRoleBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopMenu?: InputMaybe<ShopMenuBoolExp>;
};

/** unique or primary key constraints on table "shopMenuCookingItem" */
export enum ShopMenuCookingItemConstraint {
  /** unique or primary key constraint on columns "shopId", "_cookingItemId", "_menuId" */
  ShopMenuCookingItemFkKey = 'shopMenuCookingItem_fk_key',
  /** unique or primary key constraint on columns "id" */
  ShopMenuCookingItemPkey = 'shopMenuCookingItem_pkey',
  /** unique or primary key constraint on columns "shopId", "cookingItemId", "menuId" */
  ShopMenuCookingItemUuidFkKey = 'shopMenuCookingItem_uuid_fk_key'
}

/** input type for incrementing numeric columns in table "shopMenuCookingItem" */
export type ShopMenuCookingItemIncInput = {
  _cookingItemId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shopMenuCookingItem" */
export type ShopMenuCookingItemInsertInput = {
  _cookingItemId: Scalars['Int'];
  _menuId: Scalars['Int'];
  cookingItem?: InputMaybe<CookingItemObjRelInsertInput>;
  cookingItemId: Scalars['uuid'];
  menu?: InputMaybe<MenuObjRelInsertInput>;
  menuId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopCookingItemRole?: InputMaybe<ShopCookingItemRoleObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "shopMenuCookingItem" */
export type ShopMenuCookingItemMaxOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  cookingItemId?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopMenuCookingItem" */
export type ShopMenuCookingItemMinOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  cookingItemId?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopMenuCookingItem" */
export type ShopMenuCookingItemMutationResponse = {
  __typename?: 'shopMenuCookingItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopMenuCookingItem>;
};

/** on_conflict condition type for table "shopMenuCookingItem" */
export type ShopMenuCookingItemOnConflict = {
  constraint: ShopMenuCookingItemConstraint;
  update_columns?: Array<ShopMenuCookingItemUpdateColumn>;
  where?: InputMaybe<ShopMenuCookingItemBoolExp>;
};

/** Ordering options when selecting data from "shopMenuCookingItem". */
export type ShopMenuCookingItemOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  cookingItem?: InputMaybe<CookingItemOrderBy>;
  cookingItemId?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopCookingItemRole?: InputMaybe<ShopCookingItemRoleOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopMenu?: InputMaybe<ShopMenuOrderBy>;
};

/** select columns of table "shopMenuCookingItem" */
export enum ShopMenuCookingItemSelectColumn {
  /** column name */
  _CookingItemId = '_cookingItemId',
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  CookingItemId = 'cookingItemId',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "shopMenuCookingItem" */
export type ShopMenuCookingItemSetInput = {
  _cookingItemId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  cookingItemId?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shopMenuCookingItem" */
export type ShopMenuCookingItemStddevOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopMenuCookingItem" */
export type ShopMenuCookingItemStddevPopOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopMenuCookingItem" */
export type ShopMenuCookingItemStddevSampOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopMenuCookingItem" */
export type ShopMenuCookingItemStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopMenuCookingItemStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopMenuCookingItemStreamCursorValueInput = {
  _cookingItemId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  cookingItemId?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopMenuCookingItem" */
export type ShopMenuCookingItemSumOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
};

/** update columns of table "shopMenuCookingItem" */
export enum ShopMenuCookingItemUpdateColumn {
  /** column name */
  _CookingItemId = '_cookingItemId',
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  CookingItemId = 'cookingItemId',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  ShopId = 'shopId'
}

export type ShopMenuCookingItemUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopMenuCookingItemIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopMenuCookingItemSetInput>;
  /** filter the rows which have to be updated */
  where: ShopMenuCookingItemBoolExp;
};

/** order by var_pop() on columns of table "shopMenuCookingItem" */
export type ShopMenuCookingItemVarPopOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopMenuCookingItem" */
export type ShopMenuCookingItemVarSampOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopMenuCookingItem" */
export type ShopMenuCookingItemVarianceOrderBy = {
  _cookingItemId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTarget = {
  __typename?: 'shopMenuKdDisplayTarget';
  _menuId: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  kdDisplayTarget?: Maybe<KdDisplayTarget>;
  kdDisplayTargetId: Scalars['uuid'];
  menuId: Scalars['uuid'];
  /** An object relationship */
  shop?: Maybe<Shop>;
  shopId: Scalars['uuid'];
  /** An object relationship */
  shopMenu?: Maybe<ShopMenu>;
};

/** order by aggregate values of table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetAggregateOrderBy = {
  avg?: InputMaybe<ShopMenuKdDisplayTargetAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopMenuKdDisplayTargetMaxOrderBy>;
  min?: InputMaybe<ShopMenuKdDisplayTargetMinOrderBy>;
  stddev?: InputMaybe<ShopMenuKdDisplayTargetStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopMenuKdDisplayTargetStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopMenuKdDisplayTargetStddevSampOrderBy>;
  sum?: InputMaybe<ShopMenuKdDisplayTargetSumOrderBy>;
  var_pop?: InputMaybe<ShopMenuKdDisplayTargetVarPopOrderBy>;
  var_samp?: InputMaybe<ShopMenuKdDisplayTargetVarSampOrderBy>;
  variance?: InputMaybe<ShopMenuKdDisplayTargetVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetArrRelInsertInput = {
  data: Array<ShopMenuKdDisplayTargetInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopMenuKdDisplayTargetOnConflict>;
};

/** order by avg() on columns of table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetAvgOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopMenuKdDisplayTarget". All fields are combined with a logical 'AND'. */
export type ShopMenuKdDisplayTargetBoolExp = {
  _and?: InputMaybe<Array<ShopMenuKdDisplayTargetBoolExp>>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<ShopMenuKdDisplayTargetBoolExp>;
  _or?: InputMaybe<Array<ShopMenuKdDisplayTargetBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  kdDisplayTarget?: InputMaybe<KdDisplayTargetBoolExp>;
  kdDisplayTargetId?: InputMaybe<UuidComparisonExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopMenu?: InputMaybe<ShopMenuBoolExp>;
};

/** unique or primary key constraints on table "shopMenuKdDisplayTarget" */
export enum ShopMenuKdDisplayTargetConstraint {
  /** unique or primary key constraint on columns "kdDisplayTargetId", "_menuId" */
  ShopMenuKdDisplayTargetMenuIdKdDisplayTargetIdKey = 'shopMenuKdDisplayTarget_menuId_kdDisplayTargetId_key',
  /** unique or primary key constraint on columns "id" */
  ShopMenuKdDisplayTargetPkey = 'shopMenuKdDisplayTarget_pkey'
}

/** input type for incrementing numeric columns in table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetIncInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetInsertInput = {
  _menuId: Scalars['Int'];
  id?: InputMaybe<Scalars['uuid']>;
  kdDisplayTarget?: InputMaybe<KdDisplayTargetObjRelInsertInput>;
  kdDisplayTargetId: Scalars['uuid'];
  menuId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetMaxOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  kdDisplayTargetId?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetMinOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  kdDisplayTargetId?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetMutationResponse = {
  __typename?: 'shopMenuKdDisplayTarget_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopMenuKdDisplayTarget>;
};

/** on_conflict condition type for table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetOnConflict = {
  constraint: ShopMenuKdDisplayTargetConstraint;
  update_columns?: Array<ShopMenuKdDisplayTargetUpdateColumn>;
  where?: InputMaybe<ShopMenuKdDisplayTargetBoolExp>;
};

/** Ordering options when selecting data from "shopMenuKdDisplayTarget". */
export type ShopMenuKdDisplayTargetOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  kdDisplayTarget?: InputMaybe<KdDisplayTargetOrderBy>;
  kdDisplayTargetId?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopMenu?: InputMaybe<ShopMenuOrderBy>;
};

/** primary key columns input for table: shopMenuKdDisplayTarget */
export type ShopMenuKdDisplayTargetPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "shopMenuKdDisplayTarget" */
export enum ShopMenuKdDisplayTargetSelectColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  Id = 'id',
  /** column name */
  KdDisplayTargetId = 'kdDisplayTargetId',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetSetInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  kdDisplayTargetId?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetStddevOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetStddevPopOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetStddevSampOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopMenuKdDisplayTargetStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopMenuKdDisplayTargetStreamCursorValueInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  kdDisplayTargetId?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetSumOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** update columns of table "shopMenuKdDisplayTarget" */
export enum ShopMenuKdDisplayTargetUpdateColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  Id = 'id',
  /** column name */
  KdDisplayTargetId = 'kdDisplayTargetId',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  ShopId = 'shopId'
}

export type ShopMenuKdDisplayTargetUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopMenuKdDisplayTargetIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopMenuKdDisplayTargetSetInput>;
  /** filter the rows which have to be updated */
  where: ShopMenuKdDisplayTargetBoolExp;
};

/** order by var_pop() on columns of table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetVarPopOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetVarSampOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopMenuKdDisplayTarget" */
export type ShopMenuKdDisplayTargetVarianceOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "shopMenuKitchenRole" */
export type ShopMenuKitchenRole = {
  __typename?: 'shopMenuKitchenRole';
  _menuId: Scalars['Int'];
  _roleId: Scalars['Int'];
  /** An object relationship */
  menu: Menu;
  menuId: Scalars['uuid'];
  /** An object relationship */
  role: Role;
  roleId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An object relationship */
  shopMenu?: Maybe<ShopMenu>;
};

/** order by aggregate values of table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleAggregateOrderBy = {
  avg?: InputMaybe<ShopMenuKitchenRoleAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopMenuKitchenRoleMaxOrderBy>;
  min?: InputMaybe<ShopMenuKitchenRoleMinOrderBy>;
  stddev?: InputMaybe<ShopMenuKitchenRoleStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopMenuKitchenRoleStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopMenuKitchenRoleStddevSampOrderBy>;
  sum?: InputMaybe<ShopMenuKitchenRoleSumOrderBy>;
  var_pop?: InputMaybe<ShopMenuKitchenRoleVarPopOrderBy>;
  var_samp?: InputMaybe<ShopMenuKitchenRoleVarSampOrderBy>;
  variance?: InputMaybe<ShopMenuKitchenRoleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleArrRelInsertInput = {
  data: Array<ShopMenuKitchenRoleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopMenuKitchenRoleOnConflict>;
};

/** order by avg() on columns of table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleAvgOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopMenuKitchenRole". All fields are combined with a logical 'AND'. */
export type ShopMenuKitchenRoleBoolExp = {
  _and?: InputMaybe<Array<ShopMenuKitchenRoleBoolExp>>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
  _or?: InputMaybe<Array<ShopMenuKitchenRoleBoolExp>>;
  _roleId?: InputMaybe<IntComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  role?: InputMaybe<RoleBoolExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopMenu?: InputMaybe<ShopMenuBoolExp>;
};

/** unique or primary key constraints on table "shopMenuKitchenRole" */
export enum ShopMenuKitchenRoleConstraint {
  /** unique or primary key constraint on columns "id" */
  ShopMenuKitchenRolePkey = 'shopMenuKitchenRole_pkey',
  /** unique or primary key constraint on columns "_roleId", "shopId", "_menuId" */
  ShopMenuKitchenRoleShopIdMenuIdRoleIdKey = 'shopMenuKitchenRole_shopId_menuId_roleId_key'
}

/** input type for incrementing numeric columns in table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleIncInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleInsertInput = {
  _menuId: Scalars['Int'];
  _roleId: Scalars['Int'];
  menu?: InputMaybe<MenuObjRelInsertInput>;
  menuId: Scalars['uuid'];
  role?: InputMaybe<RoleObjRelInsertInput>;
  roleId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleMaxOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleMinOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleMutationResponse = {
  __typename?: 'shopMenuKitchenRole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopMenuKitchenRole>;
};

/** on_conflict condition type for table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleOnConflict = {
  constraint: ShopMenuKitchenRoleConstraint;
  update_columns?: Array<ShopMenuKitchenRoleUpdateColumn>;
  where?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
};

/** Ordering options when selecting data from "shopMenuKitchenRole". */
export type ShopMenuKitchenRoleOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
  role?: InputMaybe<RoleOrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopMenu?: InputMaybe<ShopMenuOrderBy>;
};

/** select columns of table "shopMenuKitchenRole" */
export enum ShopMenuKitchenRoleSelectColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleSetInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleStddevOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleStddevPopOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleStddevSampOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopMenuKitchenRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopMenuKitchenRoleStreamCursorValueInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleSumOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** update columns of table "shopMenuKitchenRole" */
export enum ShopMenuKitchenRoleUpdateColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

export type ShopMenuKitchenRoleUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopMenuKitchenRoleIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopMenuKitchenRoleSetInput>;
  /** filter the rows which have to be updated */
  where: ShopMenuKitchenRoleBoolExp;
};

/** order by var_pop() on columns of table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleVarPopOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleVarSampOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopMenuKitchenRole" */
export type ShopMenuKitchenRoleVarianceOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "shopMenu" */
export type ShopMenuAggregateOrderBy = {
  avg?: InputMaybe<ShopMenuAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopMenuMaxOrderBy>;
  min?: InputMaybe<ShopMenuMinOrderBy>;
  stddev?: InputMaybe<ShopMenuStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopMenuStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopMenuStddevSampOrderBy>;
  sum?: InputMaybe<ShopMenuSumOrderBy>;
  var_pop?: InputMaybe<ShopMenuVarPopOrderBy>;
  var_samp?: InputMaybe<ShopMenuVarSampOrderBy>;
  variance?: InputMaybe<ShopMenuVarianceOrderBy>;
};

/** order by avg() on columns of table "shopMenu" */
export type ShopMenuAvgOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopMenu". All fields are combined with a logical 'AND'. */
export type ShopMenuBoolExp = {
  _and?: InputMaybe<Array<ShopMenuBoolExp>>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<ShopMenuBoolExp>;
  _or?: InputMaybe<Array<ShopMenuBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  dishUpSlipGroupShopMenus?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isVisibleForCustomer?: InputMaybe<BooleanComparisonExp>;
  isVisibleForStaff?: InputMaybe<BooleanComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopMenuCookingItems?: InputMaybe<ShopMenuCookingItemBoolExp>;
  shopMenuKdDisplayTargets?: InputMaybe<ShopMenuKdDisplayTargetBoolExp>;
  shopMenuKitchenRoles?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
  stock?: InputMaybe<StockBoolExp>;
  stockId?: InputMaybe<UuidComparisonExp>;
};

/** input type for incrementing numeric columns in table "shopMenu" */
export type ShopMenuIncInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "shopMenu" */
export type ShopMenuMaxOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopMenu" */
export type ShopMenuMinOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopMenu" */
export type ShopMenuMutationResponse = {
  __typename?: 'shopMenu_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopMenu>;
};

/** Ordering options when selecting data from "shopMenu". */
export type ShopMenuOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  dishUpSlipGroupShopMenus_aggregate?: InputMaybe<DishUpSlipGroupShopMenusAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isVisibleForCustomer?: InputMaybe<OrderBy>;
  isVisibleForStaff?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopMenuCookingItems_aggregate?: InputMaybe<ShopMenuCookingItemAggregateOrderBy>;
  shopMenuKdDisplayTargets_aggregate?: InputMaybe<ShopMenuKdDisplayTargetAggregateOrderBy>;
  shopMenuKitchenRoles_aggregate?: InputMaybe<ShopMenuKitchenRoleAggregateOrderBy>;
  stock?: InputMaybe<StockOrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shopMenu */
export type ShopMenuPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "shopMenu" */
export enum ShopMenuSelectColumn {
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsVisibleForCustomer = 'isVisibleForCustomer',
  /** column name */
  IsVisibleForStaff = 'isVisibleForStaff',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  StockId = 'stockId'
}

/** input type for updating data in table "shopMenu" */
export type ShopMenuSetInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  stockId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shopMenu" */
export type ShopMenuStddevOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopMenu" */
export type ShopMenuStddevPopOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopMenu" */
export type ShopMenuStddevSampOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopMenu" */
export type ShopMenuStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopMenuStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopMenuStreamCursorValueInput = {
  _menuId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  stockId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopMenu" */
export type ShopMenuSumOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

export type ShopMenuUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopMenuIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopMenuSetInput>;
  /** filter the rows which have to be updated */
  where: ShopMenuBoolExp;
};

/** order by var_pop() on columns of table "shopMenu" */
export type ShopMenuVarPopOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopMenu" */
export type ShopMenuVarSampOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopMenu" */
export type ShopMenuVarianceOrderBy = {
  _menuId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "shopOptionKitchenRole" */
export type ShopOptionKitchenRole = {
  __typename?: 'shopOptionKitchenRole';
  id: Scalars['uuid'];
  /** An object relationship */
  kitchenRole: Role;
  kitchenRoleId: Scalars['uuid'];
  /** An object relationship */
  option: Option;
  optionId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "shopOptionKitchenRole" */
export type ShopOptionKitchenRoleAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopOptionKitchenRoleMaxOrderBy>;
  min?: InputMaybe<ShopOptionKitchenRoleMinOrderBy>;
};

/** input type for inserting array relation for remote table "shopOptionKitchenRole" */
export type ShopOptionKitchenRoleArrRelInsertInput = {
  data: Array<ShopOptionKitchenRoleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopOptionKitchenRoleOnConflict>;
};

/** Boolean expression to filter rows from the table "shopOptionKitchenRole". All fields are combined with a logical 'AND'. */
export type ShopOptionKitchenRoleBoolExp = {
  _and?: InputMaybe<Array<ShopOptionKitchenRoleBoolExp>>;
  _not?: InputMaybe<ShopOptionKitchenRoleBoolExp>;
  _or?: InputMaybe<Array<ShopOptionKitchenRoleBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  kitchenRole?: InputMaybe<RoleBoolExp>;
  kitchenRoleId?: InputMaybe<UuidComparisonExp>;
  option?: InputMaybe<OptionBoolExp>;
  optionId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "shopOptionKitchenRole" */
export enum ShopOptionKitchenRoleConstraint {
  /** unique or primary key constraint on columns "id" */
  ShopOptionKitchenRolePkey = 'shopOptionKitchenRole_pkey',
  /** unique or primary key constraint on columns "shopId", "optionId", "kitchenRoleId" */
  ShopOptionKitchenRoleShopIdOptionIdKitchenRoleIdKey = 'shopOptionKitchenRole_shopId_optionId_kitchenRoleId_key'
}

/** input type for inserting data into table "shopOptionKitchenRole" */
export type ShopOptionKitchenRoleInsertInput = {
  kitchenRole?: InputMaybe<RoleObjRelInsertInput>;
  kitchenRoleId: Scalars['uuid'];
  option?: InputMaybe<OptionObjRelInsertInput>;
  optionId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "shopOptionKitchenRole" */
export type ShopOptionKitchenRoleMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  kitchenRoleId?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopOptionKitchenRole" */
export type ShopOptionKitchenRoleMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  kitchenRoleId?: InputMaybe<OrderBy>;
  optionId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopOptionKitchenRole" */
export type ShopOptionKitchenRoleMutationResponse = {
  __typename?: 'shopOptionKitchenRole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopOptionKitchenRole>;
};

/** on_conflict condition type for table "shopOptionKitchenRole" */
export type ShopOptionKitchenRoleOnConflict = {
  constraint: ShopOptionKitchenRoleConstraint;
  update_columns?: Array<ShopOptionKitchenRoleUpdateColumn>;
  where?: InputMaybe<ShopOptionKitchenRoleBoolExp>;
};

/** Ordering options when selecting data from "shopOptionKitchenRole". */
export type ShopOptionKitchenRoleOrderBy = {
  id?: InputMaybe<OrderBy>;
  kitchenRole?: InputMaybe<RoleOrderBy>;
  kitchenRoleId?: InputMaybe<OrderBy>;
  option?: InputMaybe<OptionOrderBy>;
  optionId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "shopOptionKitchenRole" */
export enum ShopOptionKitchenRoleSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  KitchenRoleId = 'kitchenRoleId',
  /** column name */
  OptionId = 'optionId',
  /** column name */
  ShopId = 'shopId'
}

/** Streaming cursor of the table "shopOptionKitchenRole" */
export type ShopOptionKitchenRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopOptionKitchenRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopOptionKitchenRoleStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  kitchenRoleId?: InputMaybe<Scalars['uuid']>;
  optionId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "shopOptionKitchenRole" (current role has no relevant permissions) */
export enum ShopOptionKitchenRoleUpdateColumn {
  /** placeholder (do not use) */
  _Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "shopOrderableTimeTerm" */
export type ShopOrderableTimeTerm = {
  __typename?: 'shopOrderableTimeTerm';
  dayWeek: Scalars['String'];
  end: Scalars['time'];
  id: Scalars['uuid'];
  /** An object relationship */
  orderableTime: OrderableTime;
  orderableTimeId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  start: Scalars['time'];
};

/** order by aggregate values of table "shopOrderableTimeTerm" */
export type ShopOrderableTimeTermAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopOrderableTimeTermMaxOrderBy>;
  min?: InputMaybe<ShopOrderableTimeTermMinOrderBy>;
};

/** input type for inserting array relation for remote table "shopOrderableTimeTerm" */
export type ShopOrderableTimeTermArrRelInsertInput = {
  data: Array<ShopOrderableTimeTermInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopOrderableTimeTermOnConflict>;
};

/** Boolean expression to filter rows from the table "shopOrderableTimeTerm". All fields are combined with a logical 'AND'. */
export type ShopOrderableTimeTermBoolExp = {
  _and?: InputMaybe<Array<ShopOrderableTimeTermBoolExp>>;
  _not?: InputMaybe<ShopOrderableTimeTermBoolExp>;
  _or?: InputMaybe<Array<ShopOrderableTimeTermBoolExp>>;
  dayWeek?: InputMaybe<StringComparisonExp>;
  end?: InputMaybe<TimeComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  orderableTime?: InputMaybe<OrderableTimeBoolExp>;
  orderableTimeId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  start?: InputMaybe<TimeComparisonExp>;
};

/** unique or primary key constraints on table "shopOrderableTimeTerm" */
export enum ShopOrderableTimeTermConstraint {
  /** unique or primary key constraint on columns "start", "shopId", "orderableTimeId", "dayWeek" */
  ShopOrderableTimeTermDayWeekShopIdOrderableTimeIdStartKey = 'shopOrderableTimeTerm_dayWeek_shopId_orderableTimeId_start_key',
  /** unique or primary key constraint on columns "id" */
  ShopOrderableTimeTermPkey = 'shopOrderableTimeTerm_pkey'
}

/** input type for inserting data into table "shopOrderableTimeTerm" */
export type ShopOrderableTimeTermInsertInput = {
  dayWeek: Scalars['String'];
  end: Scalars['time'];
  id?: InputMaybe<Scalars['uuid']>;
  orderableTime?: InputMaybe<OrderableTimeObjRelInsertInput>;
  orderableTimeId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  start: Scalars['time'];
};

/** order by max() on columns of table "shopOrderableTimeTerm" */
export type ShopOrderableTimeTermMaxOrderBy = {
  dayWeek?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableTimeId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopOrderableTimeTerm" */
export type ShopOrderableTimeTermMinOrderBy = {
  dayWeek?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableTimeId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopOrderableTimeTerm" */
export type ShopOrderableTimeTermMutationResponse = {
  __typename?: 'shopOrderableTimeTerm_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopOrderableTimeTerm>;
};

/** on_conflict condition type for table "shopOrderableTimeTerm" */
export type ShopOrderableTimeTermOnConflict = {
  constraint: ShopOrderableTimeTermConstraint;
  update_columns?: Array<ShopOrderableTimeTermUpdateColumn>;
  where?: InputMaybe<ShopOrderableTimeTermBoolExp>;
};

/** Ordering options when selecting data from "shopOrderableTimeTerm". */
export type ShopOrderableTimeTermOrderBy = {
  dayWeek?: InputMaybe<OrderBy>;
  end?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderableTime?: InputMaybe<OrderableTimeOrderBy>;
  orderableTimeId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  start?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shopOrderableTimeTerm */
export type ShopOrderableTimeTermPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "shopOrderableTimeTerm" */
export enum ShopOrderableTimeTermSelectColumn {
  /** column name */
  DayWeek = 'dayWeek',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  OrderableTimeId = 'orderableTimeId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  Start = 'start'
}

/** input type for updating data in table "shopOrderableTimeTerm" */
export type ShopOrderableTimeTermSetInput = {
  dayWeek?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['uuid']>;
  orderableTimeId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  start?: InputMaybe<Scalars['time']>;
};

/** Streaming cursor of the table "shopOrderableTimeTerm" */
export type ShopOrderableTimeTermStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopOrderableTimeTermStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopOrderableTimeTermStreamCursorValueInput = {
  dayWeek?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['uuid']>;
  orderableTimeId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  start?: InputMaybe<Scalars['time']>;
};

/** update columns of table "shopOrderableTimeTerm" */
export enum ShopOrderableTimeTermUpdateColumn {
  /** column name */
  DayWeek = 'dayWeek',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  OrderableTimeId = 'orderableTimeId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  Start = 'start'
}

export type ShopOrderableTimeTermUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopOrderableTimeTermSetInput>;
  /** filter the rows which have to be updated */
  where: ShopOrderableTimeTermBoolExp;
};

/** columns and relationships of "shopPaymentType" */
export type ShopPaymentType = {
  __typename?: 'shopPaymentType';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  isDisplay: Scalars['Boolean'];
  /** An object relationship */
  onSitePaymentDetailType: OnSitePaymentDetailType;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  type: Scalars['String'];
};

/** order by aggregate values of table "shopPaymentType" */
export type ShopPaymentTypeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopPaymentTypeMaxOrderBy>;
  min?: InputMaybe<ShopPaymentTypeMinOrderBy>;
};

/** input type for inserting array relation for remote table "shopPaymentType" */
export type ShopPaymentTypeArrRelInsertInput = {
  data: Array<ShopPaymentTypeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopPaymentTypeOnConflict>;
};

/** Boolean expression to filter rows from the table "shopPaymentType". All fields are combined with a logical 'AND'. */
export type ShopPaymentTypeBoolExp = {
  _and?: InputMaybe<Array<ShopPaymentTypeBoolExp>>;
  _not?: InputMaybe<ShopPaymentTypeBoolExp>;
  _or?: InputMaybe<Array<ShopPaymentTypeBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  isDisplay?: InputMaybe<BooleanComparisonExp>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "shopPaymentType" */
export enum ShopPaymentTypeConstraint {
  /** unique or primary key constraint on columns "shopId", "type" */
  ShopPaymentTypePkey = 'shopPaymentType_pkey'
}

/** input type for inserting data into table "shopPaymentType" */
export type ShopPaymentTypeInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeObjRelInsertInput>;
  onSitePaymentDetailTypeId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  type: Scalars['String'];
};

/** order by max() on columns of table "shopPaymentType" */
export type ShopPaymentTypeMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopPaymentType" */
export type ShopPaymentTypeMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopPaymentType" */
export type ShopPaymentTypeMutationResponse = {
  __typename?: 'shopPaymentType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopPaymentType>;
};

/** on_conflict condition type for table "shopPaymentType" */
export type ShopPaymentTypeOnConflict = {
  constraint: ShopPaymentTypeConstraint;
  update_columns?: Array<ShopPaymentTypeUpdateColumn>;
  where?: InputMaybe<ShopPaymentTypeBoolExp>;
};

/** Ordering options when selecting data from "shopPaymentType". */
export type ShopPaymentTypeOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  isDisplay?: InputMaybe<OrderBy>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeOrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shopPaymentType */
export type ShopPaymentTypePkColumnsInput = {
  shopId: Scalars['uuid'];
  type: Scalars['String'];
};

/** select columns of table "shopPaymentType" */
export enum ShopPaymentTypeSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  IsDisplay = 'isDisplay',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "shopPaymentType" */
export type ShopPaymentTypeSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "shopPaymentType" */
export type ShopPaymentTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopPaymentTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopPaymentTypeStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "shopPaymentType" */
export enum ShopPaymentTypeUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  IsDisplay = 'isDisplay',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  Type = 'type'
}

export type ShopPaymentTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopPaymentTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ShopPaymentTypeBoolExp;
};

/** columns and relationships of "shopPlan" */
export type ShopPlan = {
  __typename?: 'shopPlan';
  _planId: Scalars['Int'];
  archivedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  dishUpSlipGroupShopPlans: Array<DishUpSlipGroupShopPlans>;
  id: Scalars['uuid'];
  isVisibleForCustomer: Scalars['Boolean'];
  isVisibleForStaff: Scalars['Boolean'];
  /** An object relationship */
  plan: Plan;
  planId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An array relationship */
  shopPlanKitchenRoles: Array<ShopPlanKitchenRole>;
  /** An object relationship */
  stock?: Maybe<Stock>;
  /** An object relationship */
  stockByStockidShopid?: Maybe<Stock>;
  stockId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "shopPlan" */
export type ShopPlanDishUpSlipGroupShopPlansArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopPlansOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
};


/** columns and relationships of "shopPlan" */
export type ShopPlanShopPlanKitchenRolesArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
};

/** columns and relationships of "shopPlanChoice" */
export type ShopPlanChoice = {
  __typename?: 'shopPlanChoice';
  _planChoiceId: Scalars['Int'];
  archivedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  isVisibleForCustomer: Scalars['Boolean'];
  isVisibleForStaff: Scalars['Boolean'];
  /** An object relationship */
  planChoice: PlanChoice;
  planChoiceId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An object relationship */
  stock?: Maybe<Stock>;
  stockId?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "shopPlanChoice" */
export type ShopPlanChoiceAggregateOrderBy = {
  avg?: InputMaybe<ShopPlanChoiceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopPlanChoiceMaxOrderBy>;
  min?: InputMaybe<ShopPlanChoiceMinOrderBy>;
  stddev?: InputMaybe<ShopPlanChoiceStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopPlanChoiceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopPlanChoiceStddevSampOrderBy>;
  sum?: InputMaybe<ShopPlanChoiceSumOrderBy>;
  var_pop?: InputMaybe<ShopPlanChoiceVarPopOrderBy>;
  var_samp?: InputMaybe<ShopPlanChoiceVarSampOrderBy>;
  variance?: InputMaybe<ShopPlanChoiceVarianceOrderBy>;
};

/** order by avg() on columns of table "shopPlanChoice" */
export type ShopPlanChoiceAvgOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopPlanChoice". All fields are combined with a logical 'AND'. */
export type ShopPlanChoiceBoolExp = {
  _and?: InputMaybe<Array<ShopPlanChoiceBoolExp>>;
  _not?: InputMaybe<ShopPlanChoiceBoolExp>;
  _or?: InputMaybe<Array<ShopPlanChoiceBoolExp>>;
  _planChoiceId?: InputMaybe<IntComparisonExp>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isVisibleForCustomer?: InputMaybe<BooleanComparisonExp>;
  isVisibleForStaff?: InputMaybe<BooleanComparisonExp>;
  planChoice?: InputMaybe<PlanChoiceBoolExp>;
  planChoiceId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  stock?: InputMaybe<StockBoolExp>;
  stockId?: InputMaybe<UuidComparisonExp>;
};

/** input type for incrementing numeric columns in table "shopPlanChoice" */
export type ShopPlanChoiceIncInput = {
  _planChoiceId?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "shopPlanChoice" */
export type ShopPlanChoiceMaxOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopPlanChoice" */
export type ShopPlanChoiceMinOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopPlanChoice" */
export type ShopPlanChoiceMutationResponse = {
  __typename?: 'shopPlanChoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopPlanChoice>;
};

/** Ordering options when selecting data from "shopPlanChoice". */
export type ShopPlanChoiceOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isVisibleForCustomer?: InputMaybe<OrderBy>;
  isVisibleForStaff?: InputMaybe<OrderBy>;
  planChoice?: InputMaybe<PlanChoiceOrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  stock?: InputMaybe<StockOrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shopPlanChoice */
export type ShopPlanChoicePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "shopPlanChoice" */
export enum ShopPlanChoiceSelectColumn {
  /** column name */
  _PlanChoiceId = '_planChoiceId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsVisibleForCustomer = 'isVisibleForCustomer',
  /** column name */
  IsVisibleForStaff = 'isVisibleForStaff',
  /** column name */
  PlanChoiceId = 'planChoiceId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  StockId = 'stockId'
}

/** input type for updating data in table "shopPlanChoice" */
export type ShopPlanChoiceSetInput = {
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  planChoiceId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  stockId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shopPlanChoice" */
export type ShopPlanChoiceStddevOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopPlanChoice" */
export type ShopPlanChoiceStddevPopOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopPlanChoice" */
export type ShopPlanChoiceStddevSampOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopPlanChoice" */
export type ShopPlanChoiceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopPlanChoiceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopPlanChoiceStreamCursorValueInput = {
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  planChoiceId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  stockId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopPlanChoice" */
export type ShopPlanChoiceSumOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

export type ShopPlanChoiceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopPlanChoiceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopPlanChoiceSetInput>;
  /** filter the rows which have to be updated */
  where: ShopPlanChoiceBoolExp;
};

/** order by var_pop() on columns of table "shopPlanChoice" */
export type ShopPlanChoiceVarPopOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopPlanChoice" */
export type ShopPlanChoiceVarSampOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopPlanChoice" */
export type ShopPlanChoiceVarianceOrderBy = {
  _planChoiceId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "shopPlanKitchenRole" */
export type ShopPlanKitchenRole = {
  __typename?: 'shopPlanKitchenRole';
  _planId: Scalars['Int'];
  _roleId: Scalars['Int'];
  /** An object relationship */
  plan: Plan;
  planId: Scalars['uuid'];
  /** An object relationship */
  role: Role;
  roleId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An object relationship */
  shopPlan?: Maybe<ShopPlan>;
};

/** order by aggregate values of table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleAggregateOrderBy = {
  avg?: InputMaybe<ShopPlanKitchenRoleAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopPlanKitchenRoleMaxOrderBy>;
  min?: InputMaybe<ShopPlanKitchenRoleMinOrderBy>;
  stddev?: InputMaybe<ShopPlanKitchenRoleStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopPlanKitchenRoleStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopPlanKitchenRoleStddevSampOrderBy>;
  sum?: InputMaybe<ShopPlanKitchenRoleSumOrderBy>;
  var_pop?: InputMaybe<ShopPlanKitchenRoleVarPopOrderBy>;
  var_samp?: InputMaybe<ShopPlanKitchenRoleVarSampOrderBy>;
  variance?: InputMaybe<ShopPlanKitchenRoleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleArrRelInsertInput = {
  data: Array<ShopPlanKitchenRoleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopPlanKitchenRoleOnConflict>;
};

/** order by avg() on columns of table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleAvgOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopPlanKitchenRole". All fields are combined with a logical 'AND'. */
export type ShopPlanKitchenRoleBoolExp = {
  _and?: InputMaybe<Array<ShopPlanKitchenRoleBoolExp>>;
  _not?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
  _or?: InputMaybe<Array<ShopPlanKitchenRoleBoolExp>>;
  _planId?: InputMaybe<IntComparisonExp>;
  _roleId?: InputMaybe<IntComparisonExp>;
  plan?: InputMaybe<PlanBoolExp>;
  planId?: InputMaybe<UuidComparisonExp>;
  role?: InputMaybe<RoleBoolExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopPlan?: InputMaybe<ShopPlanBoolExp>;
};

/** unique or primary key constraints on table "shopPlanKitchenRole" */
export enum ShopPlanKitchenRoleConstraint {
  /** unique or primary key constraint on columns "id" */
  ShopPlanKitchenRolePkey = 'shopPlanKitchenRole_pkey',
  /** unique or primary key constraint on columns "_roleId", "shopId", "_planId" */
  ShopPlanKitchenRoleShopIdPlanIdRoleIdKey = 'shopPlanKitchenRole_shopId_planId_roleId_key'
}

/** input type for incrementing numeric columns in table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleIncInput = {
  _planId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleInsertInput = {
  _planId: Scalars['Int'];
  _roleId: Scalars['Int'];
  plan?: InputMaybe<PlanObjRelInsertInput>;
  planId: Scalars['uuid'];
  role?: InputMaybe<RoleObjRelInsertInput>;
  roleId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleMaxOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleMinOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleMutationResponse = {
  __typename?: 'shopPlanKitchenRole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopPlanKitchenRole>;
};

/** on_conflict condition type for table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleOnConflict = {
  constraint: ShopPlanKitchenRoleConstraint;
  update_columns?: Array<ShopPlanKitchenRoleUpdateColumn>;
  where?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
};

/** Ordering options when selecting data from "shopPlanKitchenRole". */
export type ShopPlanKitchenRoleOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlanOrderBy>;
  planId?: InputMaybe<OrderBy>;
  role?: InputMaybe<RoleOrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopPlan?: InputMaybe<ShopPlanOrderBy>;
};

/** select columns of table "shopPlanKitchenRole" */
export enum ShopPlanKitchenRoleSelectColumn {
  /** column name */
  _PlanId = '_planId',
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  PlanId = 'planId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleSetInput = {
  _planId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
  planId?: InputMaybe<Scalars['uuid']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleStddevOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleStddevPopOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleStddevSampOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopPlanKitchenRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopPlanKitchenRoleStreamCursorValueInput = {
  _planId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
  planId?: InputMaybe<Scalars['uuid']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleSumOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** update columns of table "shopPlanKitchenRole" */
export enum ShopPlanKitchenRoleUpdateColumn {
  /** column name */
  _PlanId = '_planId',
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  PlanId = 'planId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

export type ShopPlanKitchenRoleUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopPlanKitchenRoleIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopPlanKitchenRoleSetInput>;
  /** filter the rows which have to be updated */
  where: ShopPlanKitchenRoleBoolExp;
};

/** order by var_pop() on columns of table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleVarPopOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleVarSampOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopPlanKitchenRole" */
export type ShopPlanKitchenRoleVarianceOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "shopPlanOption" */
export type ShopPlanOption = {
  __typename?: 'shopPlanOption';
  _planOptionId: Scalars['Int'];
  id: Scalars['uuid'];
  isAvailable: Scalars['Boolean'];
  isDisplay: Scalars['Boolean'];
  /** An object relationship */
  planOption: PlanOption;
  planOptionId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** columns and relationships of "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRole = {
  __typename?: 'shopPlanOptionKitchenRole';
  _planOptionId: Scalars['Int'];
  _roleId: Scalars['Int'];
  /** An object relationship */
  planOption: PlanOption;
  planOptionId: Scalars['uuid'];
  /** An object relationship */
  role: Role;
  roleId: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleAggregateOrderBy = {
  avg?: InputMaybe<ShopPlanOptionKitchenRoleAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopPlanOptionKitchenRoleMaxOrderBy>;
  min?: InputMaybe<ShopPlanOptionKitchenRoleMinOrderBy>;
  stddev?: InputMaybe<ShopPlanOptionKitchenRoleStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopPlanOptionKitchenRoleStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopPlanOptionKitchenRoleStddevSampOrderBy>;
  sum?: InputMaybe<ShopPlanOptionKitchenRoleSumOrderBy>;
  var_pop?: InputMaybe<ShopPlanOptionKitchenRoleVarPopOrderBy>;
  var_samp?: InputMaybe<ShopPlanOptionKitchenRoleVarSampOrderBy>;
  variance?: InputMaybe<ShopPlanOptionKitchenRoleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleArrRelInsertInput = {
  data: Array<ShopPlanOptionKitchenRoleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopPlanOptionKitchenRoleOnConflict>;
};

/** order by avg() on columns of table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleAvgOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopPlanOptionKitchenRole". All fields are combined with a logical 'AND'. */
export type ShopPlanOptionKitchenRoleBoolExp = {
  _and?: InputMaybe<Array<ShopPlanOptionKitchenRoleBoolExp>>;
  _not?: InputMaybe<ShopPlanOptionKitchenRoleBoolExp>;
  _or?: InputMaybe<Array<ShopPlanOptionKitchenRoleBoolExp>>;
  _planOptionId?: InputMaybe<IntComparisonExp>;
  _roleId?: InputMaybe<IntComparisonExp>;
  planOption?: InputMaybe<PlanOptionBoolExp>;
  planOptionId?: InputMaybe<UuidComparisonExp>;
  role?: InputMaybe<RoleBoolExp>;
  roleId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "shopPlanOptionKitchenRole" */
export enum ShopPlanOptionKitchenRoleConstraint {
  /** unique or primary key constraint on columns "id" */
  ShopPlanOptionKitchenRolePkey = 'shopPlanOptionKitchenRole_pkey',
  /** unique or primary key constraint on columns "_roleId", "_planOptionId", "shopId" */
  ShopPlanOptionKitchenRoleShopIdPlanOptionIdRoleIdKey = 'shopPlanOptionKitchenRole_shopId_planOptionId_roleId_key'
}

/** input type for incrementing numeric columns in table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleIncInput = {
  _planOptionId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleInsertInput = {
  _planOptionId: Scalars['Int'];
  _roleId: Scalars['Int'];
  planOption?: InputMaybe<PlanOptionObjRelInsertInput>;
  planOptionId: Scalars['uuid'];
  role?: InputMaybe<RoleObjRelInsertInput>;
  roleId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleMaxOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleMinOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleMutationResponse = {
  __typename?: 'shopPlanOptionKitchenRole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopPlanOptionKitchenRole>;
};

/** on_conflict condition type for table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleOnConflict = {
  constraint: ShopPlanOptionKitchenRoleConstraint;
  update_columns?: Array<ShopPlanOptionKitchenRoleUpdateColumn>;
  where?: InputMaybe<ShopPlanOptionKitchenRoleBoolExp>;
};

/** Ordering options when selecting data from "shopPlanOptionKitchenRole". */
export type ShopPlanOptionKitchenRoleOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
  planOption?: InputMaybe<PlanOptionOrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  role?: InputMaybe<RoleOrderBy>;
  roleId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "shopPlanOptionKitchenRole" */
export enum ShopPlanOptionKitchenRoleSelectColumn {
  /** column name */
  _PlanOptionId = '_planOptionId',
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  PlanOptionId = 'planOptionId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleSetInput = {
  _planOptionId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
  planOptionId?: InputMaybe<Scalars['uuid']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleStddevOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleStddevPopOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleStddevSampOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopPlanOptionKitchenRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopPlanOptionKitchenRoleStreamCursorValueInput = {
  _planOptionId?: InputMaybe<Scalars['Int']>;
  _roleId?: InputMaybe<Scalars['Int']>;
  planOptionId?: InputMaybe<Scalars['uuid']>;
  roleId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleSumOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** update columns of table "shopPlanOptionKitchenRole" */
export enum ShopPlanOptionKitchenRoleUpdateColumn {
  /** column name */
  _PlanOptionId = '_planOptionId',
  /** column name */
  _RoleId = '_roleId',
  /** column name */
  PlanOptionId = 'planOptionId',
  /** column name */
  RoleId = 'roleId',
  /** column name */
  ShopId = 'shopId'
}

export type ShopPlanOptionKitchenRoleUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopPlanOptionKitchenRoleIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopPlanOptionKitchenRoleSetInput>;
  /** filter the rows which have to be updated */
  where: ShopPlanOptionKitchenRoleBoolExp;
};

/** order by var_pop() on columns of table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleVarPopOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleVarSampOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopPlanOptionKitchenRole" */
export type ShopPlanOptionKitchenRoleVarianceOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  _roleId?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "shopPlanOption" */
export type ShopPlanOptionAggregateOrderBy = {
  avg?: InputMaybe<ShopPlanOptionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopPlanOptionMaxOrderBy>;
  min?: InputMaybe<ShopPlanOptionMinOrderBy>;
  stddev?: InputMaybe<ShopPlanOptionStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopPlanOptionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopPlanOptionStddevSampOrderBy>;
  sum?: InputMaybe<ShopPlanOptionSumOrderBy>;
  var_pop?: InputMaybe<ShopPlanOptionVarPopOrderBy>;
  var_samp?: InputMaybe<ShopPlanOptionVarSampOrderBy>;
  variance?: InputMaybe<ShopPlanOptionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "shopPlanOption" */
export type ShopPlanOptionArrRelInsertInput = {
  data: Array<ShopPlanOptionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopPlanOptionOnConflict>;
};

/** order by avg() on columns of table "shopPlanOption" */
export type ShopPlanOptionAvgOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopPlanOption". All fields are combined with a logical 'AND'. */
export type ShopPlanOptionBoolExp = {
  _and?: InputMaybe<Array<ShopPlanOptionBoolExp>>;
  _not?: InputMaybe<ShopPlanOptionBoolExp>;
  _or?: InputMaybe<Array<ShopPlanOptionBoolExp>>;
  _planOptionId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isAvailable?: InputMaybe<BooleanComparisonExp>;
  isDisplay?: InputMaybe<BooleanComparisonExp>;
  planOption?: InputMaybe<PlanOptionBoolExp>;
  planOptionId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "shopPlanOption" */
export enum ShopPlanOptionConstraint {
  /** unique or primary key constraint on columns "id" */
  ShopPlanOptionPkey = 'shopPlanOption_pkey',
  /** unique or primary key constraint on columns "_planOptionId", "shopId" */
  ShopPlanOptionShopIdPlanOptionIdKey = 'shopPlanOption_shopId_planOptionId_key'
}

/** input type for incrementing numeric columns in table "shopPlanOption" */
export type ShopPlanOptionIncInput = {
  _planOptionId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shopPlanOption" */
export type ShopPlanOptionInsertInput = {
  _planOptionId: Scalars['Int'];
  id?: InputMaybe<Scalars['uuid']>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  planOption?: InputMaybe<PlanOptionObjRelInsertInput>;
  planOptionId: Scalars['uuid'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "shopPlanOption" */
export type ShopPlanOptionMaxOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopPlanOption" */
export type ShopPlanOptionMinOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopPlanOption" */
export type ShopPlanOptionMutationResponse = {
  __typename?: 'shopPlanOption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopPlanOption>;
};

/** on_conflict condition type for table "shopPlanOption" */
export type ShopPlanOptionOnConflict = {
  constraint: ShopPlanOptionConstraint;
  update_columns?: Array<ShopPlanOptionUpdateColumn>;
  where?: InputMaybe<ShopPlanOptionBoolExp>;
};

/** Ordering options when selecting data from "shopPlanOption". */
export type ShopPlanOptionOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAvailable?: InputMaybe<OrderBy>;
  isDisplay?: InputMaybe<OrderBy>;
  planOption?: InputMaybe<PlanOptionOrderBy>;
  planOptionId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shopPlanOption */
export type ShopPlanOptionPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "shopPlanOption" */
export enum ShopPlanOptionSelectColumn {
  /** column name */
  _PlanOptionId = '_planOptionId',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailable = 'isAvailable',
  /** column name */
  IsDisplay = 'isDisplay',
  /** column name */
  PlanOptionId = 'planOptionId',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "shopPlanOption" */
export type ShopPlanOptionSetInput = {
  _planOptionId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  planOptionId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shopPlanOption" */
export type ShopPlanOptionStddevOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopPlanOption" */
export type ShopPlanOptionStddevPopOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopPlanOption" */
export type ShopPlanOptionStddevSampOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopPlanOption" */
export type ShopPlanOptionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopPlanOptionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopPlanOptionStreamCursorValueInput = {
  _planOptionId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isDisplay?: InputMaybe<Scalars['Boolean']>;
  planOptionId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopPlanOption" */
export type ShopPlanOptionSumOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
};

/** update columns of table "shopPlanOption" */
export enum ShopPlanOptionUpdateColumn {
  /** column name */
  _PlanOptionId = '_planOptionId',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailable = 'isAvailable',
  /** column name */
  IsDisplay = 'isDisplay',
  /** column name */
  PlanOptionId = 'planOptionId',
  /** column name */
  ShopId = 'shopId'
}

export type ShopPlanOptionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopPlanOptionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopPlanOptionSetInput>;
  /** filter the rows which have to be updated */
  where: ShopPlanOptionBoolExp;
};

/** order by var_pop() on columns of table "shopPlanOption" */
export type ShopPlanOptionVarPopOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopPlanOption" */
export type ShopPlanOptionVarSampOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopPlanOption" */
export type ShopPlanOptionVarianceOrderBy = {
  _planOptionId?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "shopPlan" */
export type ShopPlanAggregateOrderBy = {
  avg?: InputMaybe<ShopPlanAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopPlanMaxOrderBy>;
  min?: InputMaybe<ShopPlanMinOrderBy>;
  stddev?: InputMaybe<ShopPlanStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopPlanStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopPlanStddevSampOrderBy>;
  sum?: InputMaybe<ShopPlanSumOrderBy>;
  var_pop?: InputMaybe<ShopPlanVarPopOrderBy>;
  var_samp?: InputMaybe<ShopPlanVarSampOrderBy>;
  variance?: InputMaybe<ShopPlanVarianceOrderBy>;
};

/** order by avg() on columns of table "shopPlan" */
export type ShopPlanAvgOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shopPlan". All fields are combined with a logical 'AND'. */
export type ShopPlanBoolExp = {
  _and?: InputMaybe<Array<ShopPlanBoolExp>>;
  _not?: InputMaybe<ShopPlanBoolExp>;
  _or?: InputMaybe<Array<ShopPlanBoolExp>>;
  _planId?: InputMaybe<IntComparisonExp>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  dishUpSlipGroupShopPlans?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isVisibleForCustomer?: InputMaybe<BooleanComparisonExp>;
  isVisibleForStaff?: InputMaybe<BooleanComparisonExp>;
  plan?: InputMaybe<PlanBoolExp>;
  planId?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopPlanKitchenRoles?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
  stock?: InputMaybe<StockBoolExp>;
  stockByStockidShopid?: InputMaybe<StockBoolExp>;
  stockId?: InputMaybe<UuidComparisonExp>;
};

/** input type for incrementing numeric columns in table "shopPlan" */
export type ShopPlanIncInput = {
  _planId?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "shopPlan" */
export type ShopPlanMaxOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shopPlan" */
export type ShopPlanMinOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shopPlan" */
export type ShopPlanMutationResponse = {
  __typename?: 'shopPlan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ShopPlan>;
};

/** Ordering options when selecting data from "shopPlan". */
export type ShopPlanOrderBy = {
  _planId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  dishUpSlipGroupShopPlans_aggregate?: InputMaybe<DishUpSlipGroupShopPlansAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isVisibleForCustomer?: InputMaybe<OrderBy>;
  isVisibleForStaff?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlanOrderBy>;
  planId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopPlanKitchenRoles_aggregate?: InputMaybe<ShopPlanKitchenRoleAggregateOrderBy>;
  stock?: InputMaybe<StockOrderBy>;
  stockByStockidShopid?: InputMaybe<StockOrderBy>;
  stockId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: shopPlan */
export type ShopPlanPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "shopPlan" */
export enum ShopPlanSelectColumn {
  /** column name */
  _PlanId = '_planId',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsVisibleForCustomer = 'isVisibleForCustomer',
  /** column name */
  IsVisibleForStaff = 'isVisibleForStaff',
  /** column name */
  PlanId = 'planId',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  StockId = 'stockId'
}

/** input type for updating data in table "shopPlan" */
export type ShopPlanSetInput = {
  _planId?: InputMaybe<Scalars['Int']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  stockId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shopPlan" */
export type ShopPlanStddevOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shopPlan" */
export type ShopPlanStddevPopOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shopPlan" */
export type ShopPlanStddevSampOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shopPlan" */
export type ShopPlanStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopPlanStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopPlanStreamCursorValueInput = {
  _planId?: InputMaybe<Scalars['Int']>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  isVisibleForCustomer?: InputMaybe<Scalars['Boolean']>;
  isVisibleForStaff?: InputMaybe<Scalars['Boolean']>;
  planId?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  stockId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shopPlan" */
export type ShopPlanSumOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

export type ShopPlanUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopPlanIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopPlanSetInput>;
  /** filter the rows which have to be updated */
  where: ShopPlanBoolExp;
};

/** order by var_pop() on columns of table "shopPlan" */
export type ShopPlanVarPopOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shopPlan" */
export type ShopPlanVarSampOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shopPlan" */
export type ShopPlanVarianceOrderBy = {
  _planId?: InputMaybe<OrderBy>;
};

/** order by aggregate values of table "shop" */
export type ShopAggregateOrderBy = {
  avg?: InputMaybe<ShopAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ShopMaxOrderBy>;
  min?: InputMaybe<ShopMinOrderBy>;
  stddev?: InputMaybe<ShopStddevOrderBy>;
  stddev_pop?: InputMaybe<ShopStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ShopStddevSampOrderBy>;
  sum?: InputMaybe<ShopSumOrderBy>;
  var_pop?: InputMaybe<ShopVarPopOrderBy>;
  var_samp?: InputMaybe<ShopVarSampOrderBy>;
  variance?: InputMaybe<ShopVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "shop" */
export type ShopArrRelInsertInput = {
  data: Array<ShopInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopOnConflict>;
};

/** order by avg() on columns of table "shop" */
export type ShopAvgOrderBy = {
  _lineOfficialAccountId?: InputMaybe<OrderBy>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  defaultTaxRate?: InputMaybe<OrderBy>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  maxNumForNumPeople?: InputMaybe<OrderBy>;
  orderIntervalSeconds?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "shop". All fields are combined with a logical 'AND'. */
export type ShopBoolExp = {
  _and?: InputMaybe<Array<ShopBoolExp>>;
  _lineOfficialAccountId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<ShopBoolExp>;
  _or?: InputMaybe<Array<ShopBoolExp>>;
  accountingActionType?: InputMaybe<AccountingActionTypeEnumComparisonExp>;
  accountingSlipImages?: InputMaybe<AccountingSlipImageBoolExp>;
  activePlans?: InputMaybe<ActivePlanBoolExp>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  cashRegisterConfig?: InputMaybe<CashRegisterConfigBoolExp>;
  cashRegisterSecurityLevel?: InputMaybe<CashRegisterSecurityLevelEnumComparisonExp>;
  cashRegisterSecurityLevelByCashregistersecuritylevel?: InputMaybe<CashRegisterSecurityLevelBoolExp>;
  changeDateTime?: InputMaybe<StringComparisonExp>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<IntComparisonExp>;
  checkInEvents?: InputMaybe<CheckInEventBoolExp>;
  clerks?: InputMaybe<ClerkBoolExp>;
  close?: InputMaybe<StringComparisonExp>;
  closeCashRegisters?: InputMaybe<CloseCashRegisterBoolExp>;
  coineyPayments?: InputMaybe<CoineyPaymentBoolExp>;
  coineySettlements?: InputMaybe<CoineySettlementBoolExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  connectGameConfigShops?: InputMaybe<ConnectGameConfigShopBoolExp>;
  contactInfo?: InputMaybe<StringComparisonExp>;
  cookingItems?: InputMaybe<CookingItemBoolExp>;
  customerQuestionnaires?: InputMaybe<CustomerQuestionnaireBoolExp>;
  dashboardAccountAccessibleShops?: InputMaybe<DashboardAccountAccessibleShopBoolExp>;
  defaultTaxMethod?: InputMaybe<TaxMethodEnumComparisonExp>;
  defaultTaxRate?: InputMaybe<NumericComparisonExp>;
  disableCallClerk?: InputMaybe<BooleanComparisonExp>;
  disableClerkCache?: InputMaybe<BooleanComparisonExp>;
  disableSelfAccountingStart?: InputMaybe<BooleanComparisonExp>;
  dishUpSlipGroupShopMenus?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
  dishUpSlipGroupShopPlans?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
  dishUpSlipGroups?: InputMaybe<DishUpSlipGroupBoolExp>;
  eligibleInvoiceIssuerRegistrationNumber?: InputMaybe<StringComparisonExp>;
  enableAlert?: InputMaybe<BooleanComparisonExp>;
  enableAutoCompulsoryAppetizerOrder?: InputMaybe<BooleanComparisonExp>;
  enableAutoFractionalDiscount?: InputMaybe<BooleanComparisonExp>;
  enableAutoPrintReceipt?: InputMaybe<BooleanComparisonExp>;
  enableAutoResetMenuSoldOut?: InputMaybe<BooleanComparisonExp>;
  enableCheckInElapsedTimeAlert?: InputMaybe<BooleanComparisonExp>;
  enableCustomerSegmentSelection?: InputMaybe<BooleanComparisonExp>;
  enableDefaultInflowSourceTag?: InputMaybe<BooleanComparisonExp>;
  enableDisplayTaxExcludedPriceForCustomer?: InputMaybe<BooleanComparisonExp>;
  enableGoogleMapReview?: InputMaybe<BooleanComparisonExp>;
  enableLatestOrderElapsedTimeAlert?: InputMaybe<BooleanComparisonExp>;
  enableRemark?: InputMaybe<BooleanComparisonExp>;
  enableReserveCashSeparation?: InputMaybe<BooleanComparisonExp>;
  enableTableUserGrouping?: InputMaybe<BooleanComparisonExp>;
  exTax?: InputMaybe<BooleanComparisonExp>;
  externalOnlineMenuConfigs?: InputMaybe<ExternalOnlineMenuConfigBoolExp>;
  featureFlagShops?: InputMaybe<FeatureFlagShopBoolExp>;
  foodingJournalConfig?: InputMaybe<FoodingJournalConfigBoolExp>;
  giftOptionMeta?: InputMaybe<GiftOptionMetaBoolExp>;
  googleMapPlaceId?: InputMaybe<StringComparisonExp>;
  hideFreeItemInAccountingSlip?: InputMaybe<BooleanComparisonExp>;
  iconImageUrl?: InputMaybe<StringComparisonExp>;
  issuer?: InputMaybe<StringComparisonExp>;
  kdDisplayTargets?: InputMaybe<KdDisplayTargetBoolExp>;
  lastOrderText?: InputMaybe<StringComparisonExp>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<IntComparisonExp>;
  lineChannelConfig?: InputMaybe<LineChannelConfigBoolExp>;
  lineOfficialAccount?: InputMaybe<LineOfficialAccountBoolExp>;
  lineOfficialAccountId?: InputMaybe<UuidComparisonExp>;
  maxNumForNumPeople?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nameEn?: InputMaybe<StringComparisonExp>;
  offlinePaymentDescription?: InputMaybe<StringComparisonExp>;
  onSitePaymentDetails?: InputMaybe<OnSitePaymentDetailBoolExp>;
  onSitePaymentDiscounts?: InputMaybe<OnSitePaymentDiscountBoolExp>;
  onSitePayments?: InputMaybe<OnSitePaymentBoolExp>;
  onboardingHandout?: InputMaybe<StringComparisonExp>;
  open?: InputMaybe<StringComparisonExp>;
  orderIntervalSeconds?: InputMaybe<IntComparisonExp>;
  orders?: InputMaybe<OrderBoolExp>;
  pikaichiConfig?: InputMaybe<PikaichiConfigBoolExp>;
  pikaichiMenus?: InputMaybe<PikaichiMenuBoolExp>;
  pikaichiOnSitePaymentDetailTypes?: InputMaybe<PikaichiOnSitePaymentDetailTypeBoolExp>;
  pikaichiOnSitePaymentDiscountTypes?: InputMaybe<PikaichiOnSitePaymentDiscountTypeBoolExp>;
  questionnaireAnswers?: InputMaybe<QuestionnaireAnswerBoolExp>;
  roles?: InputMaybe<RoleBoolExp>;
  serviceChargeConfig?: InputMaybe<ServiceChargeConfigBoolExp>;
  shopBusinessOperationHours?: InputMaybe<ShopBusinessOperationHourBoolExp>;
  shopChoices?: InputMaybe<ShopChoiceBoolExp>;
  shopClerks?: InputMaybe<ShopClerkBoolExp>;
  shopClients?: InputMaybe<ShopClientBoolExp>;
  shopCloseConnectionTime?: InputMaybe<StringComparisonExp>;
  shopCookingItemRoles?: InputMaybe<ShopCookingItemRoleBoolExp>;
  shopDiscountTypes?: InputMaybe<ShopDiscountTypeBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopInflowSourceTags?: InputMaybe<ShopInflowSourceTagBoolExp>;
  shopMembers?: InputMaybe<ShopMemberBoolExp>;
  shopMenuCookingItems?: InputMaybe<ShopMenuCookingItemBoolExp>;
  shopMenuKitchenRoles?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
  shopMenus?: InputMaybe<ShopMenuBoolExp>;
  shopOptionKitchenRoles?: InputMaybe<ShopOptionKitchenRoleBoolExp>;
  shopOrderableTimeTerms?: InputMaybe<ShopOrderableTimeTermBoolExp>;
  shopPaymentTypes?: InputMaybe<ShopPaymentTypeBoolExp>;
  shopPlanChoices?: InputMaybe<ShopPlanChoiceBoolExp>;
  shopPlanKitchenRoles?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
  shopPlanOptionKitchenRoles?: InputMaybe<ShopPlanOptionKitchenRoleBoolExp>;
  shopPlanOptions?: InputMaybe<ShopPlanOptionBoolExp>;
  shopPlans?: InputMaybe<ShopPlanBoolExp>;
  shopRebootTime?: InputMaybe<StringComparisonExp>;
  shouldOpenDrawerAtOnSitePaymentEveryTime?: InputMaybe<BooleanComparisonExp>;
  specificTimeSurchargeConfig?: InputMaybe<SpecificTimeSurchargeConfigBoolExp>;
  stocks?: InputMaybe<StockBoolExp>;
  tableAreas?: InputMaybe<TableAreaBoolExp>;
  tableAreas_aggregate?: InputMaybe<TableAreaAggregateBoolExp>;
  tableUsers?: InputMaybe<TableUserBoolExp>;
  tables?: InputMaybe<TableBoolExp>;
  tables_aggregate?: InputMaybe<TableAggregateBoolExp>;
  taxOffice?: InputMaybe<TaxOfficeBoolExp>;
  taxOfficeId?: InputMaybe<UuidComparisonExp>;
  tecAggregationConfigs?: InputMaybe<TecAggregationConfigBoolExp>;
  tecAggregationOnSitePaymentDetailTypes?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
  useKd?: InputMaybe<BooleanComparisonExp>;
  useKiosk?: InputMaybe<BooleanComparisonExp>;
  winboardConfig?: InputMaybe<WinboardConfigBoolExp>;
};

/** unique or primary key constraints on table "shop" */
export enum ShopConstraint {
  /** unique or primary key constraint on columns "shopId" */
  ShopPkey = 'shop_pkey'
}

/** input type for incrementing numeric columns in table "shop" */
export type ShopIncInput = {
  _lineOfficialAccountId?: InputMaybe<Scalars['Int']>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<Scalars['Int']>;
  defaultTaxRate?: InputMaybe<Scalars['numeric']>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<Scalars['Int']>;
  maxNumForNumPeople?: InputMaybe<Scalars['Int']>;
  orderIntervalSeconds?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shop" */
export type ShopInsertInput = {
  _lineOfficialAccountId?: InputMaybe<Scalars['Int']>;
  accountingActionType?: InputMaybe<AccountingActionTypeEnum>;
  accountingSlipImages?: InputMaybe<AccountingSlipImageArrRelInsertInput>;
  cashRegisterConfig?: InputMaybe<CashRegisterConfigObjRelInsertInput>;
  cashRegisterSecurityLevel?: InputMaybe<CashRegisterSecurityLevelEnum>;
  changeDateTime?: InputMaybe<Scalars['String']>;
  clerks?: InputMaybe<ClerkArrRelInsertInput>;
  close?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  connectGameConfigShops?: InputMaybe<ConnectGameConfigShopArrRelInsertInput>;
  contactInfo?: InputMaybe<Scalars['String']>;
  cookingItems?: InputMaybe<CookingItemArrRelInsertInput>;
  defaultTaxMethod?: InputMaybe<TaxMethodEnum>;
  defaultTaxRate?: InputMaybe<Scalars['numeric']>;
  disableCallClerk?: InputMaybe<Scalars['Boolean']>;
  disableClerkCache?: InputMaybe<Scalars['Boolean']>;
  disableSelfAccountingStart?: InputMaybe<Scalars['Boolean']>;
  dishUpSlipGroupShopMenus?: InputMaybe<DishUpSlipGroupShopMenusArrRelInsertInput>;
  dishUpSlipGroupShopPlans?: InputMaybe<DishUpSlipGroupShopPlansArrRelInsertInput>;
  dishUpSlipGroups?: InputMaybe<DishUpSlipGroupArrRelInsertInput>;
  eligibleInvoiceIssuerRegistrationNumber?: InputMaybe<Scalars['String']>;
  enableAlert?: InputMaybe<Scalars['Boolean']>;
  enableAutoCompulsoryAppetizerOrder?: InputMaybe<Scalars['Boolean']>;
  enableAutoFractionalDiscount?: InputMaybe<Scalars['Boolean']>;
  enableAutoResetMenuSoldOut?: InputMaybe<Scalars['Boolean']>;
  enableCustomerSegmentSelection?: InputMaybe<Scalars['Boolean']>;
  enableDefaultInflowSourceTag?: InputMaybe<Scalars['Boolean']>;
  enableRemark?: InputMaybe<Scalars['Boolean']>;
  enableReserveCashSeparation?: InputMaybe<Scalars['Boolean']>;
  enableTableUserGrouping?: InputMaybe<Scalars['Boolean']>;
  exTax?: InputMaybe<Scalars['Boolean']>;
  externalOnlineMenuConfigs?: InputMaybe<ExternalOnlineMenuConfigArrRelInsertInput>;
  featureFlagShops?: InputMaybe<FeatureFlagShopArrRelInsertInput>;
  foodingJournalConfig?: InputMaybe<FoodingJournalConfigObjRelInsertInput>;
  giftOptionMeta?: InputMaybe<GiftOptionMetaArrRelInsertInput>;
  hideFreeItemInAccountingSlip?: InputMaybe<Scalars['Boolean']>;
  iconImageUrl?: InputMaybe<Scalars['String']>;
  issuer?: InputMaybe<Scalars['String']>;
  kdDisplayTargets?: InputMaybe<KdDisplayTargetArrRelInsertInput>;
  lastOrderText?: InputMaybe<Scalars['String']>;
  lineChannelConfig?: InputMaybe<LineChannelConfigObjRelInsertInput>;
  lineOfficialAccountId?: InputMaybe<Scalars['uuid']>;
  maxNumForNumPeople?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  nameEn: Scalars['String'];
  open?: InputMaybe<Scalars['String']>;
  orderIntervalSeconds?: InputMaybe<Scalars['Int']>;
  pikaichiConfig?: InputMaybe<PikaichiConfigObjRelInsertInput>;
  pikaichiMenus?: InputMaybe<PikaichiMenuArrRelInsertInput>;
  pikaichiOnSitePaymentDetailTypes?: InputMaybe<PikaichiOnSitePaymentDetailTypeArrRelInsertInput>;
  pikaichiOnSitePaymentDiscountTypes?: InputMaybe<PikaichiOnSitePaymentDiscountTypeArrRelInsertInput>;
  roles?: InputMaybe<RoleArrRelInsertInput>;
  serviceChargeConfig?: InputMaybe<ServiceChargeConfigObjRelInsertInput>;
  shopBusinessOperationHours?: InputMaybe<ShopBusinessOperationHourArrRelInsertInput>;
  shopClerks?: InputMaybe<ShopClerkArrRelInsertInput>;
  shopClients?: InputMaybe<ShopClientArrRelInsertInput>;
  shopCloseConnectionTime?: InputMaybe<Scalars['String']>;
  shopCookingItemRoles?: InputMaybe<ShopCookingItemRoleArrRelInsertInput>;
  shopDiscountTypes?: InputMaybe<ShopDiscountTypeArrRelInsertInput>;
  shopId?: InputMaybe<Scalars['uuid']>;
  shopInflowSourceTags?: InputMaybe<ShopInflowSourceTagArrRelInsertInput>;
  shopMenuCookingItems?: InputMaybe<ShopMenuCookingItemArrRelInsertInput>;
  shopMenuKitchenRoles?: InputMaybe<ShopMenuKitchenRoleArrRelInsertInput>;
  shopOptionKitchenRoles?: InputMaybe<ShopOptionKitchenRoleArrRelInsertInput>;
  shopOrderableTimeTerms?: InputMaybe<ShopOrderableTimeTermArrRelInsertInput>;
  shopPaymentTypes?: InputMaybe<ShopPaymentTypeArrRelInsertInput>;
  shopPlanKitchenRoles?: InputMaybe<ShopPlanKitchenRoleArrRelInsertInput>;
  shopPlanOptionKitchenRoles?: InputMaybe<ShopPlanOptionKitchenRoleArrRelInsertInput>;
  shopPlanOptions?: InputMaybe<ShopPlanOptionArrRelInsertInput>;
  shopRebootTime?: InputMaybe<Scalars['String']>;
  shouldOpenDrawerAtOnSitePaymentEveryTime?: InputMaybe<Scalars['Boolean']>;
  specificTimeSurchargeConfig?: InputMaybe<SpecificTimeSurchargeConfigObjRelInsertInput>;
  stocks?: InputMaybe<StockArrRelInsertInput>;
  tableAreas?: InputMaybe<TableAreaArrRelInsertInput>;
  tables?: InputMaybe<TableArrRelInsertInput>;
  tecAggregationConfigs?: InputMaybe<TecAggregationConfigArrRelInsertInput>;
  tecAggregationOnSitePaymentDetailTypes?: InputMaybe<TecAggregationOnSitePaymentDetailTypeArrRelInsertInput>;
  useKd?: InputMaybe<Scalars['Boolean']>;
  useKiosk?: InputMaybe<Scalars['Boolean']>;
  winboardConfig?: InputMaybe<WinboardConfigObjRelInsertInput>;
};

/** order by max() on columns of table "shop" */
export type ShopMaxOrderBy = {
  _lineOfficialAccountId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  changeDateTime?: InputMaybe<OrderBy>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  close?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  contactInfo?: InputMaybe<OrderBy>;
  defaultTaxRate?: InputMaybe<OrderBy>;
  eligibleInvoiceIssuerRegistrationNumber?: InputMaybe<OrderBy>;
  googleMapPlaceId?: InputMaybe<OrderBy>;
  iconImageUrl?: InputMaybe<OrderBy>;
  issuer?: InputMaybe<OrderBy>;
  lastOrderText?: InputMaybe<OrderBy>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  maxNumForNumPeople?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  offlinePaymentDescription?: InputMaybe<OrderBy>;
  onboardingHandout?: InputMaybe<OrderBy>;
  open?: InputMaybe<OrderBy>;
  orderIntervalSeconds?: InputMaybe<OrderBy>;
  shopCloseConnectionTime?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopRebootTime?: InputMaybe<OrderBy>;
  taxOfficeId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "shop" */
export type ShopMinOrderBy = {
  _lineOfficialAccountId?: InputMaybe<OrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  changeDateTime?: InputMaybe<OrderBy>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  close?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  contactInfo?: InputMaybe<OrderBy>;
  defaultTaxRate?: InputMaybe<OrderBy>;
  eligibleInvoiceIssuerRegistrationNumber?: InputMaybe<OrderBy>;
  googleMapPlaceId?: InputMaybe<OrderBy>;
  iconImageUrl?: InputMaybe<OrderBy>;
  issuer?: InputMaybe<OrderBy>;
  lastOrderText?: InputMaybe<OrderBy>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  maxNumForNumPeople?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  offlinePaymentDescription?: InputMaybe<OrderBy>;
  onboardingHandout?: InputMaybe<OrderBy>;
  open?: InputMaybe<OrderBy>;
  orderIntervalSeconds?: InputMaybe<OrderBy>;
  shopCloseConnectionTime?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopRebootTime?: InputMaybe<OrderBy>;
  taxOfficeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "shop" */
export type ShopMutationResponse = {
  __typename?: 'shop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Shop>;
};

/** input type for inserting object relation for remote table "shop" */
export type ShopObjRelInsertInput = {
  data: ShopInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ShopOnConflict>;
};

/** on_conflict condition type for table "shop" */
export type ShopOnConflict = {
  constraint: ShopConstraint;
  update_columns?: Array<ShopUpdateColumn>;
  where?: InputMaybe<ShopBoolExp>;
};

/** Ordering options when selecting data from "shop". */
export type ShopOrderBy = {
  _lineOfficialAccountId?: InputMaybe<OrderBy>;
  accountingActionType?: InputMaybe<OrderBy>;
  accountingSlipImages_aggregate?: InputMaybe<AccountingSlipImageAggregateOrderBy>;
  activePlans_aggregate?: InputMaybe<ActivePlanAggregateOrderBy>;
  archivedAt?: InputMaybe<OrderBy>;
  cashRegisterConfig?: InputMaybe<CashRegisterConfigOrderBy>;
  cashRegisterSecurityLevel?: InputMaybe<OrderBy>;
  cashRegisterSecurityLevelByCashregistersecuritylevel?: InputMaybe<CashRegisterSecurityLevelOrderBy>;
  changeDateTime?: InputMaybe<OrderBy>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  checkInEvents_aggregate?: InputMaybe<CheckInEventAggregateOrderBy>;
  clerks_aggregate?: InputMaybe<ClerkAggregateOrderBy>;
  close?: InputMaybe<OrderBy>;
  closeCashRegisters_aggregate?: InputMaybe<CloseCashRegisterAggregateOrderBy>;
  coineyPayments_aggregate?: InputMaybe<CoineyPaymentAggregateOrderBy>;
  coineySettlements_aggregate?: InputMaybe<CoineySettlementAggregateOrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  connectGameConfigShops_aggregate?: InputMaybe<ConnectGameConfigShopAggregateOrderBy>;
  contactInfo?: InputMaybe<OrderBy>;
  cookingItems_aggregate?: InputMaybe<CookingItemAggregateOrderBy>;
  customerQuestionnaires_aggregate?: InputMaybe<CustomerQuestionnaireAggregateOrderBy>;
  dashboardAccountAccessibleShops_aggregate?: InputMaybe<DashboardAccountAccessibleShopAggregateOrderBy>;
  defaultTaxMethod?: InputMaybe<OrderBy>;
  defaultTaxRate?: InputMaybe<OrderBy>;
  disableCallClerk?: InputMaybe<OrderBy>;
  disableClerkCache?: InputMaybe<OrderBy>;
  disableSelfAccountingStart?: InputMaybe<OrderBy>;
  dishUpSlipGroupShopMenus_aggregate?: InputMaybe<DishUpSlipGroupShopMenusAggregateOrderBy>;
  dishUpSlipGroupShopPlans_aggregate?: InputMaybe<DishUpSlipGroupShopPlansAggregateOrderBy>;
  dishUpSlipGroups_aggregate?: InputMaybe<DishUpSlipGroupAggregateOrderBy>;
  eligibleInvoiceIssuerRegistrationNumber?: InputMaybe<OrderBy>;
  enableAlert?: InputMaybe<OrderBy>;
  enableAutoCompulsoryAppetizerOrder?: InputMaybe<OrderBy>;
  enableAutoFractionalDiscount?: InputMaybe<OrderBy>;
  enableAutoPrintReceipt?: InputMaybe<OrderBy>;
  enableAutoResetMenuSoldOut?: InputMaybe<OrderBy>;
  enableCheckInElapsedTimeAlert?: InputMaybe<OrderBy>;
  enableCustomerSegmentSelection?: InputMaybe<OrderBy>;
  enableDefaultInflowSourceTag?: InputMaybe<OrderBy>;
  enableDisplayTaxExcludedPriceForCustomer?: InputMaybe<OrderBy>;
  enableGoogleMapReview?: InputMaybe<OrderBy>;
  enableLatestOrderElapsedTimeAlert?: InputMaybe<OrderBy>;
  enableRemark?: InputMaybe<OrderBy>;
  enableReserveCashSeparation?: InputMaybe<OrderBy>;
  enableTableUserGrouping?: InputMaybe<OrderBy>;
  exTax?: InputMaybe<OrderBy>;
  externalOnlineMenuConfigs_aggregate?: InputMaybe<ExternalOnlineMenuConfigAggregateOrderBy>;
  featureFlagShops_aggregate?: InputMaybe<FeatureFlagShopAggregateOrderBy>;
  foodingJournalConfig?: InputMaybe<FoodingJournalConfigOrderBy>;
  giftOptionMeta_aggregate?: InputMaybe<GiftOptionMetaAggregateOrderBy>;
  googleMapPlaceId?: InputMaybe<OrderBy>;
  hideFreeItemInAccountingSlip?: InputMaybe<OrderBy>;
  iconImageUrl?: InputMaybe<OrderBy>;
  issuer?: InputMaybe<OrderBy>;
  kdDisplayTargets_aggregate?: InputMaybe<KdDisplayTargetAggregateOrderBy>;
  lastOrderText?: InputMaybe<OrderBy>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  lineChannelConfig?: InputMaybe<LineChannelConfigOrderBy>;
  lineOfficialAccount?: InputMaybe<LineOfficialAccountOrderBy>;
  lineOfficialAccountId?: InputMaybe<OrderBy>;
  maxNumForNumPeople?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nameEn?: InputMaybe<OrderBy>;
  offlinePaymentDescription?: InputMaybe<OrderBy>;
  onSitePaymentDetails_aggregate?: InputMaybe<OnSitePaymentDetailAggregateOrderBy>;
  onSitePaymentDiscounts_aggregate?: InputMaybe<OnSitePaymentDiscountAggregateOrderBy>;
  onSitePayments_aggregate?: InputMaybe<OnSitePaymentAggregateOrderBy>;
  onboardingHandout?: InputMaybe<OrderBy>;
  open?: InputMaybe<OrderBy>;
  orderIntervalSeconds?: InputMaybe<OrderBy>;
  orders_aggregate?: InputMaybe<OrderAggregateOrderBy>;
  pikaichiConfig?: InputMaybe<PikaichiConfigOrderBy>;
  pikaichiMenus_aggregate?: InputMaybe<PikaichiMenuAggregateOrderBy>;
  pikaichiOnSitePaymentDetailTypes_aggregate?: InputMaybe<PikaichiOnSitePaymentDetailTypeAggregateOrderBy>;
  pikaichiOnSitePaymentDiscountTypes_aggregate?: InputMaybe<PikaichiOnSitePaymentDiscountTypeAggregateOrderBy>;
  questionnaireAnswers_aggregate?: InputMaybe<QuestionnaireAnswerAggregateOrderBy>;
  roles_aggregate?: InputMaybe<RoleAggregateOrderBy>;
  serviceChargeConfig?: InputMaybe<ServiceChargeConfigOrderBy>;
  shopBusinessOperationHours_aggregate?: InputMaybe<ShopBusinessOperationHourAggregateOrderBy>;
  shopChoices_aggregate?: InputMaybe<ShopChoiceAggregateOrderBy>;
  shopClerks_aggregate?: InputMaybe<ShopClerkAggregateOrderBy>;
  shopClients_aggregate?: InputMaybe<ShopClientAggregateOrderBy>;
  shopCloseConnectionTime?: InputMaybe<OrderBy>;
  shopCookingItemRoles_aggregate?: InputMaybe<ShopCookingItemRoleAggregateOrderBy>;
  shopDiscountTypes_aggregate?: InputMaybe<ShopDiscountTypeAggregateOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopInflowSourceTags_aggregate?: InputMaybe<ShopInflowSourceTagAggregateOrderBy>;
  shopMembers_aggregate?: InputMaybe<ShopMemberAggregateOrderBy>;
  shopMenuCookingItems_aggregate?: InputMaybe<ShopMenuCookingItemAggregateOrderBy>;
  shopMenuKitchenRoles_aggregate?: InputMaybe<ShopMenuKitchenRoleAggregateOrderBy>;
  shopMenus_aggregate?: InputMaybe<ShopMenuAggregateOrderBy>;
  shopOptionKitchenRoles_aggregate?: InputMaybe<ShopOptionKitchenRoleAggregateOrderBy>;
  shopOrderableTimeTerms_aggregate?: InputMaybe<ShopOrderableTimeTermAggregateOrderBy>;
  shopPaymentTypes_aggregate?: InputMaybe<ShopPaymentTypeAggregateOrderBy>;
  shopPlanChoices_aggregate?: InputMaybe<ShopPlanChoiceAggregateOrderBy>;
  shopPlanKitchenRoles_aggregate?: InputMaybe<ShopPlanKitchenRoleAggregateOrderBy>;
  shopPlanOptionKitchenRoles_aggregate?: InputMaybe<ShopPlanOptionKitchenRoleAggregateOrderBy>;
  shopPlanOptions_aggregate?: InputMaybe<ShopPlanOptionAggregateOrderBy>;
  shopPlans_aggregate?: InputMaybe<ShopPlanAggregateOrderBy>;
  shopRebootTime?: InputMaybe<OrderBy>;
  shouldOpenDrawerAtOnSitePaymentEveryTime?: InputMaybe<OrderBy>;
  specificTimeSurchargeConfig?: InputMaybe<SpecificTimeSurchargeConfigOrderBy>;
  stocks_aggregate?: InputMaybe<StockAggregateOrderBy>;
  tableAreas_aggregate?: InputMaybe<TableAreaAggregateOrderBy>;
  tableUsers_aggregate?: InputMaybe<TableUserAggregateOrderBy>;
  tables_aggregate?: InputMaybe<TableAggregateOrderBy>;
  taxOffice?: InputMaybe<TaxOfficeOrderBy>;
  taxOfficeId?: InputMaybe<OrderBy>;
  tecAggregationConfigs_aggregate?: InputMaybe<TecAggregationConfigAggregateOrderBy>;
  tecAggregationOnSitePaymentDetailTypes_aggregate?: InputMaybe<TecAggregationOnSitePaymentDetailTypeAggregateOrderBy>;
  useKd?: InputMaybe<OrderBy>;
  useKiosk?: InputMaybe<OrderBy>;
  winboardConfig?: InputMaybe<WinboardConfigOrderBy>;
};

/** primary key columns input for table: shop */
export type ShopPkColumnsInput = {
  shopId: Scalars['uuid'];
};

/** select columns of table "shop" */
export enum ShopSelectColumn {
  /** column name */
  _LineOfficialAccountId = '_lineOfficialAccountId',
  /** column name */
  AccountingActionType = 'accountingActionType',
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  CashRegisterSecurityLevel = 'cashRegisterSecurityLevel',
  /** column name */
  ChangeDateTime = 'changeDateTime',
  /** column name */
  CheckInElapsedTimeAlertThresholdMinutes = 'checkInElapsedTimeAlertThresholdMinutes',
  /** column name */
  Close = 'close',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  ContactInfo = 'contactInfo',
  /** column name */
  DefaultTaxMethod = 'defaultTaxMethod',
  /** column name */
  DefaultTaxRate = 'defaultTaxRate',
  /** column name */
  DisableCallClerk = 'disableCallClerk',
  /** column name */
  DisableClerkCache = 'disableClerkCache',
  /** column name */
  DisableSelfAccountingStart = 'disableSelfAccountingStart',
  /** column name */
  EligibleInvoiceIssuerRegistrationNumber = 'eligibleInvoiceIssuerRegistrationNumber',
  /** column name */
  EnableAlert = 'enableAlert',
  /** column name */
  EnableAutoCompulsoryAppetizerOrder = 'enableAutoCompulsoryAppetizerOrder',
  /** column name */
  EnableAutoFractionalDiscount = 'enableAutoFractionalDiscount',
  /** column name */
  EnableAutoPrintReceipt = 'enableAutoPrintReceipt',
  /** column name */
  EnableAutoResetMenuSoldOut = 'enableAutoResetMenuSoldOut',
  /** column name */
  EnableCheckInElapsedTimeAlert = 'enableCheckInElapsedTimeAlert',
  /** column name */
  EnableCustomerSegmentSelection = 'enableCustomerSegmentSelection',
  /** column name */
  EnableDefaultInflowSourceTag = 'enableDefaultInflowSourceTag',
  /** column name */
  EnableDisplayTaxExcludedPriceForCustomer = 'enableDisplayTaxExcludedPriceForCustomer',
  /** column name */
  EnableGoogleMapReview = 'enableGoogleMapReview',
  /** column name */
  EnableLatestOrderElapsedTimeAlert = 'enableLatestOrderElapsedTimeAlert',
  /** column name */
  EnableRemark = 'enableRemark',
  /** column name */
  EnableReserveCashSeparation = 'enableReserveCashSeparation',
  /** column name */
  EnableTableUserGrouping = 'enableTableUserGrouping',
  /** column name */
  ExTax = 'exTax',
  /** column name */
  GoogleMapPlaceId = 'googleMapPlaceId',
  /** column name */
  HideFreeItemInAccountingSlip = 'hideFreeItemInAccountingSlip',
  /** column name */
  IconImageUrl = 'iconImageUrl',
  /** column name */
  Issuer = 'issuer',
  /** column name */
  LastOrderText = 'lastOrderText',
  /** column name */
  LatestOrderElapsedTimeAlertThresholdMinutes = 'latestOrderElapsedTimeAlertThresholdMinutes',
  /** column name */
  LineOfficialAccountId = 'lineOfficialAccountId',
  /** column name */
  MaxNumForNumPeople = 'maxNumForNumPeople',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'nameEn',
  /** column name */
  OfflinePaymentDescription = 'offlinePaymentDescription',
  /** column name */
  OnboardingHandout = 'onboardingHandout',
  /** column name */
  Open = 'open',
  /** column name */
  OrderIntervalSeconds = 'orderIntervalSeconds',
  /** column name */
  ShopCloseConnectionTime = 'shopCloseConnectionTime',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  ShopRebootTime = 'shopRebootTime',
  /** column name */
  ShouldOpenDrawerAtOnSitePaymentEveryTime = 'shouldOpenDrawerAtOnSitePaymentEveryTime',
  /** column name */
  TaxOfficeId = 'taxOfficeId',
  /** column name */
  UseKd = 'useKd',
  /** column name */
  UseKiosk = 'useKiosk'
}

/** input type for updating data in table "shop" */
export type ShopSetInput = {
  _lineOfficialAccountId?: InputMaybe<Scalars['Int']>;
  accountingActionType?: InputMaybe<AccountingActionTypeEnum>;
  cashRegisterSecurityLevel?: InputMaybe<CashRegisterSecurityLevelEnum>;
  changeDateTime?: InputMaybe<Scalars['String']>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<Scalars['Int']>;
  close?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  contactInfo?: InputMaybe<Scalars['String']>;
  defaultTaxMethod?: InputMaybe<TaxMethodEnum>;
  defaultTaxRate?: InputMaybe<Scalars['numeric']>;
  disableCallClerk?: InputMaybe<Scalars['Boolean']>;
  disableClerkCache?: InputMaybe<Scalars['Boolean']>;
  disableSelfAccountingStart?: InputMaybe<Scalars['Boolean']>;
  eligibleInvoiceIssuerRegistrationNumber?: InputMaybe<Scalars['String']>;
  enableAlert?: InputMaybe<Scalars['Boolean']>;
  enableAutoCompulsoryAppetizerOrder?: InputMaybe<Scalars['Boolean']>;
  enableAutoFractionalDiscount?: InputMaybe<Scalars['Boolean']>;
  enableAutoPrintReceipt?: InputMaybe<Scalars['Boolean']>;
  enableAutoResetMenuSoldOut?: InputMaybe<Scalars['Boolean']>;
  enableCheckInElapsedTimeAlert?: InputMaybe<Scalars['Boolean']>;
  enableCustomerSegmentSelection?: InputMaybe<Scalars['Boolean']>;
  enableDefaultInflowSourceTag?: InputMaybe<Scalars['Boolean']>;
  enableDisplayTaxExcludedPriceForCustomer?: InputMaybe<Scalars['Boolean']>;
  enableGoogleMapReview?: InputMaybe<Scalars['Boolean']>;
  enableLatestOrderElapsedTimeAlert?: InputMaybe<Scalars['Boolean']>;
  enableRemark?: InputMaybe<Scalars['Boolean']>;
  enableReserveCashSeparation?: InputMaybe<Scalars['Boolean']>;
  enableTableUserGrouping?: InputMaybe<Scalars['Boolean']>;
  exTax?: InputMaybe<Scalars['Boolean']>;
  googleMapPlaceId?: InputMaybe<Scalars['String']>;
  hideFreeItemInAccountingSlip?: InputMaybe<Scalars['Boolean']>;
  iconImageUrl?: InputMaybe<Scalars['String']>;
  issuer?: InputMaybe<Scalars['String']>;
  lastOrderText?: InputMaybe<Scalars['String']>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<Scalars['Int']>;
  lineOfficialAccountId?: InputMaybe<Scalars['uuid']>;
  maxNumForNumPeople?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  offlinePaymentDescription?: InputMaybe<Scalars['String']>;
  onboardingHandout?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['String']>;
  orderIntervalSeconds?: InputMaybe<Scalars['Int']>;
  shopCloseConnectionTime?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  shopRebootTime?: InputMaybe<Scalars['String']>;
  shouldOpenDrawerAtOnSitePaymentEveryTime?: InputMaybe<Scalars['Boolean']>;
  taxOfficeId?: InputMaybe<Scalars['uuid']>;
  useKd?: InputMaybe<Scalars['Boolean']>;
  useKiosk?: InputMaybe<Scalars['Boolean']>;
};

/** order by stddev() on columns of table "shop" */
export type ShopStddevOrderBy = {
  _lineOfficialAccountId?: InputMaybe<OrderBy>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  defaultTaxRate?: InputMaybe<OrderBy>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  maxNumForNumPeople?: InputMaybe<OrderBy>;
  orderIntervalSeconds?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "shop" */
export type ShopStddevPopOrderBy = {
  _lineOfficialAccountId?: InputMaybe<OrderBy>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  defaultTaxRate?: InputMaybe<OrderBy>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  maxNumForNumPeople?: InputMaybe<OrderBy>;
  orderIntervalSeconds?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "shop" */
export type ShopStddevSampOrderBy = {
  _lineOfficialAccountId?: InputMaybe<OrderBy>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  defaultTaxRate?: InputMaybe<OrderBy>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  maxNumForNumPeople?: InputMaybe<OrderBy>;
  orderIntervalSeconds?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "shop" */
export type ShopStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ShopStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type ShopStreamCursorValueInput = {
  _lineOfficialAccountId?: InputMaybe<Scalars['Int']>;
  accountingActionType?: InputMaybe<AccountingActionTypeEnum>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  cashRegisterSecurityLevel?: InputMaybe<CashRegisterSecurityLevelEnum>;
  changeDateTime?: InputMaybe<Scalars['String']>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<Scalars['Int']>;
  close?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  contactInfo?: InputMaybe<Scalars['String']>;
  defaultTaxMethod?: InputMaybe<TaxMethodEnum>;
  defaultTaxRate?: InputMaybe<Scalars['numeric']>;
  disableCallClerk?: InputMaybe<Scalars['Boolean']>;
  disableClerkCache?: InputMaybe<Scalars['Boolean']>;
  disableSelfAccountingStart?: InputMaybe<Scalars['Boolean']>;
  eligibleInvoiceIssuerRegistrationNumber?: InputMaybe<Scalars['String']>;
  enableAlert?: InputMaybe<Scalars['Boolean']>;
  enableAutoCompulsoryAppetizerOrder?: InputMaybe<Scalars['Boolean']>;
  enableAutoFractionalDiscount?: InputMaybe<Scalars['Boolean']>;
  enableAutoPrintReceipt?: InputMaybe<Scalars['Boolean']>;
  enableAutoResetMenuSoldOut?: InputMaybe<Scalars['Boolean']>;
  enableCheckInElapsedTimeAlert?: InputMaybe<Scalars['Boolean']>;
  enableCustomerSegmentSelection?: InputMaybe<Scalars['Boolean']>;
  enableDefaultInflowSourceTag?: InputMaybe<Scalars['Boolean']>;
  enableDisplayTaxExcludedPriceForCustomer?: InputMaybe<Scalars['Boolean']>;
  enableGoogleMapReview?: InputMaybe<Scalars['Boolean']>;
  enableLatestOrderElapsedTimeAlert?: InputMaybe<Scalars['Boolean']>;
  enableRemark?: InputMaybe<Scalars['Boolean']>;
  enableReserveCashSeparation?: InputMaybe<Scalars['Boolean']>;
  enableTableUserGrouping?: InputMaybe<Scalars['Boolean']>;
  exTax?: InputMaybe<Scalars['Boolean']>;
  googleMapPlaceId?: InputMaybe<Scalars['String']>;
  hideFreeItemInAccountingSlip?: InputMaybe<Scalars['Boolean']>;
  iconImageUrl?: InputMaybe<Scalars['String']>;
  issuer?: InputMaybe<Scalars['String']>;
  lastOrderText?: InputMaybe<Scalars['String']>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<Scalars['Int']>;
  lineOfficialAccountId?: InputMaybe<Scalars['uuid']>;
  maxNumForNumPeople?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nameEn?: InputMaybe<Scalars['String']>;
  offlinePaymentDescription?: InputMaybe<Scalars['String']>;
  onboardingHandout?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['String']>;
  orderIntervalSeconds?: InputMaybe<Scalars['Int']>;
  shopCloseConnectionTime?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  shopRebootTime?: InputMaybe<Scalars['String']>;
  shouldOpenDrawerAtOnSitePaymentEveryTime?: InputMaybe<Scalars['Boolean']>;
  taxOfficeId?: InputMaybe<Scalars['uuid']>;
  useKd?: InputMaybe<Scalars['Boolean']>;
  useKiosk?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "shop" */
export type ShopSumOrderBy = {
  _lineOfficialAccountId?: InputMaybe<OrderBy>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  defaultTaxRate?: InputMaybe<OrderBy>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  maxNumForNumPeople?: InputMaybe<OrderBy>;
  orderIntervalSeconds?: InputMaybe<OrderBy>;
};

/** update columns of table "shop" */
export enum ShopUpdateColumn {
  /** column name */
  _LineOfficialAccountId = '_lineOfficialAccountId',
  /** column name */
  AccountingActionType = 'accountingActionType',
  /** column name */
  CashRegisterSecurityLevel = 'cashRegisterSecurityLevel',
  /** column name */
  ChangeDateTime = 'changeDateTime',
  /** column name */
  CheckInElapsedTimeAlertThresholdMinutes = 'checkInElapsedTimeAlertThresholdMinutes',
  /** column name */
  Close = 'close',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  ContactInfo = 'contactInfo',
  /** column name */
  DefaultTaxMethod = 'defaultTaxMethod',
  /** column name */
  DefaultTaxRate = 'defaultTaxRate',
  /** column name */
  DisableCallClerk = 'disableCallClerk',
  /** column name */
  DisableClerkCache = 'disableClerkCache',
  /** column name */
  DisableSelfAccountingStart = 'disableSelfAccountingStart',
  /** column name */
  EligibleInvoiceIssuerRegistrationNumber = 'eligibleInvoiceIssuerRegistrationNumber',
  /** column name */
  EnableAlert = 'enableAlert',
  /** column name */
  EnableAutoCompulsoryAppetizerOrder = 'enableAutoCompulsoryAppetizerOrder',
  /** column name */
  EnableAutoFractionalDiscount = 'enableAutoFractionalDiscount',
  /** column name */
  EnableAutoPrintReceipt = 'enableAutoPrintReceipt',
  /** column name */
  EnableAutoResetMenuSoldOut = 'enableAutoResetMenuSoldOut',
  /** column name */
  EnableCheckInElapsedTimeAlert = 'enableCheckInElapsedTimeAlert',
  /** column name */
  EnableCustomerSegmentSelection = 'enableCustomerSegmentSelection',
  /** column name */
  EnableDefaultInflowSourceTag = 'enableDefaultInflowSourceTag',
  /** column name */
  EnableDisplayTaxExcludedPriceForCustomer = 'enableDisplayTaxExcludedPriceForCustomer',
  /** column name */
  EnableGoogleMapReview = 'enableGoogleMapReview',
  /** column name */
  EnableLatestOrderElapsedTimeAlert = 'enableLatestOrderElapsedTimeAlert',
  /** column name */
  EnableRemark = 'enableRemark',
  /** column name */
  EnableReserveCashSeparation = 'enableReserveCashSeparation',
  /** column name */
  EnableTableUserGrouping = 'enableTableUserGrouping',
  /** column name */
  ExTax = 'exTax',
  /** column name */
  GoogleMapPlaceId = 'googleMapPlaceId',
  /** column name */
  HideFreeItemInAccountingSlip = 'hideFreeItemInAccountingSlip',
  /** column name */
  IconImageUrl = 'iconImageUrl',
  /** column name */
  Issuer = 'issuer',
  /** column name */
  LastOrderText = 'lastOrderText',
  /** column name */
  LatestOrderElapsedTimeAlertThresholdMinutes = 'latestOrderElapsedTimeAlertThresholdMinutes',
  /** column name */
  LineOfficialAccountId = 'lineOfficialAccountId',
  /** column name */
  MaxNumForNumPeople = 'maxNumForNumPeople',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'nameEn',
  /** column name */
  OfflinePaymentDescription = 'offlinePaymentDescription',
  /** column name */
  OnboardingHandout = 'onboardingHandout',
  /** column name */
  Open = 'open',
  /** column name */
  OrderIntervalSeconds = 'orderIntervalSeconds',
  /** column name */
  ShopCloseConnectionTime = 'shopCloseConnectionTime',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  ShopRebootTime = 'shopRebootTime',
  /** column name */
  ShouldOpenDrawerAtOnSitePaymentEveryTime = 'shouldOpenDrawerAtOnSitePaymentEveryTime',
  /** column name */
  TaxOfficeId = 'taxOfficeId',
  /** column name */
  UseKd = 'useKd',
  /** column name */
  UseKiosk = 'useKiosk'
}

export type ShopUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ShopIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ShopSetInput>;
  /** filter the rows which have to be updated */
  where: ShopBoolExp;
};

/** order by var_pop() on columns of table "shop" */
export type ShopVarPopOrderBy = {
  _lineOfficialAccountId?: InputMaybe<OrderBy>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  defaultTaxRate?: InputMaybe<OrderBy>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  maxNumForNumPeople?: InputMaybe<OrderBy>;
  orderIntervalSeconds?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "shop" */
export type ShopVarSampOrderBy = {
  _lineOfficialAccountId?: InputMaybe<OrderBy>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  defaultTaxRate?: InputMaybe<OrderBy>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  maxNumForNumPeople?: InputMaybe<OrderBy>;
  orderIntervalSeconds?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "shop" */
export type ShopVarianceOrderBy = {
  _lineOfficialAccountId?: InputMaybe<OrderBy>;
  checkInElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  defaultTaxRate?: InputMaybe<OrderBy>;
  latestOrderElapsedTimeAlertThresholdMinutes?: InputMaybe<OrderBy>;
  maxNumForNumPeople?: InputMaybe<OrderBy>;
  orderIntervalSeconds?: InputMaybe<OrderBy>;
};

/** columns and relationships of "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfig = {
  __typename?: 'specificTimeSurchargeConfig';
  chargeRate: Scalars['Int'];
  endTime: Scalars['time'];
  id: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  /** An object relationship */
  specificTimeSurchargeMeta: SpecificTimeSurchargeMeta;
  startTime: Scalars['time'];
  updatedAt: Scalars['timestamptz'];
};

/** order by aggregate values of table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigAggregateOrderBy = {
  avg?: InputMaybe<SpecificTimeSurchargeConfigAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SpecificTimeSurchargeConfigMaxOrderBy>;
  min?: InputMaybe<SpecificTimeSurchargeConfigMinOrderBy>;
  stddev?: InputMaybe<SpecificTimeSurchargeConfigStddevOrderBy>;
  stddev_pop?: InputMaybe<SpecificTimeSurchargeConfigStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SpecificTimeSurchargeConfigStddevSampOrderBy>;
  sum?: InputMaybe<SpecificTimeSurchargeConfigSumOrderBy>;
  var_pop?: InputMaybe<SpecificTimeSurchargeConfigVarPopOrderBy>;
  var_samp?: InputMaybe<SpecificTimeSurchargeConfigVarSampOrderBy>;
  variance?: InputMaybe<SpecificTimeSurchargeConfigVarianceOrderBy>;
};

/** order by avg() on columns of table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigAvgOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table "specificTimeSurchargeConfig".
 * All fields are combined with a logical 'AND'.
 */
export type SpecificTimeSurchargeConfigBoolExp = {
  _and?: InputMaybe<Array<SpecificTimeSurchargeConfigBoolExp>>;
  _not?: InputMaybe<SpecificTimeSurchargeConfigBoolExp>;
  _or?: InputMaybe<Array<SpecificTimeSurchargeConfigBoolExp>>;
  chargeRate?: InputMaybe<IntComparisonExp>;
  endTime?: InputMaybe<TimeComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  specificTimeSurchargeMeta?: InputMaybe<SpecificTimeSurchargeMetaBoolExp>;
  startTime?: InputMaybe<TimeComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "specificTimeSurchargeConfig" */
export enum SpecificTimeSurchargeConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  SpecificTimeSurchargeConfigPkey = 'specificTimeSurchargeConfig_pkey',
  /** unique or primary key constraint on columns "shopId" */
  SpecificTimeSurchargeConfigShopIdKey = 'specificTimeSurchargeConfig_shopId_key'
}

/** input type for incrementing numeric columns in table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigIncInput = {
  chargeRate?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigInsertInput = {
  chargeRate: Scalars['Int'];
  endTime: Scalars['time'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  specificTimeSurchargeMetaId: Scalars['uuid'];
  startTime: Scalars['time'];
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigMaxOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigMinOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigMutationResponse = {
  __typename?: 'specificTimeSurchargeConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SpecificTimeSurchargeConfig>;
};

/** input type for inserting object relation for remote table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigObjRelInsertInput = {
  data: SpecificTimeSurchargeConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<SpecificTimeSurchargeConfigOnConflict>;
};

/** on_conflict condition type for table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigOnConflict = {
  constraint: SpecificTimeSurchargeConfigConstraint;
  update_columns?: Array<SpecificTimeSurchargeConfigUpdateColumn>;
  where?: InputMaybe<SpecificTimeSurchargeConfigBoolExp>;
};

/** Ordering options when selecting data from "specificTimeSurchargeConfig". */
export type SpecificTimeSurchargeConfigOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  specificTimeSurchargeMeta?: InputMaybe<SpecificTimeSurchargeMetaOrderBy>;
  startTime?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: specificTimeSurchargeConfig */
export type SpecificTimeSurchargeConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "specificTimeSurchargeConfig" */
export enum SpecificTimeSurchargeConfigSelectColumn {
  /** column name */
  ChargeRate = 'chargeRate',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigSetInput = {
  chargeRate?: InputMaybe<Scalars['Int']>;
  endTime?: InputMaybe<Scalars['time']>;
  startTime?: InputMaybe<Scalars['time']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigStddevOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigStddevPopOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigStddevSampOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SpecificTimeSurchargeConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type SpecificTimeSurchargeConfigStreamCursorValueInput = {
  chargeRate?: InputMaybe<Scalars['Int']>;
  endTime?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['uuid']>;
  startTime?: InputMaybe<Scalars['time']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigSumOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** update columns of table "specificTimeSurchargeConfig" */
export enum SpecificTimeSurchargeConfigUpdateColumn {
  /** column name */
  ChargeRate = 'chargeRate',
  /** column name */
  EndTime = 'endTime',
  /** column name */
  StartTime = 'startTime',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type SpecificTimeSurchargeConfigUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SpecificTimeSurchargeConfigIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SpecificTimeSurchargeConfigSetInput>;
  /** filter the rows which have to be updated */
  where: SpecificTimeSurchargeConfigBoolExp;
};

/** order by var_pop() on columns of table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigVarPopOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigVarSampOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "specificTimeSurchargeConfig" */
export type SpecificTimeSurchargeConfigVarianceOrderBy = {
  chargeRate?: InputMaybe<OrderBy>;
};

/** columns and relationships of "specificTimeSurchargeMeta" */
export type SpecificTimeSurchargeMeta = {
  __typename?: 'specificTimeSurchargeMeta';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  menu: Menu;
  /** An array relationship */
  specificTimeSurchargeConfigs: Array<SpecificTimeSurchargeConfig>;
};


/** columns and relationships of "specificTimeSurchargeMeta" */
export type SpecificTimeSurchargeMetaSpecificTimeSurchargeConfigsArgs = {
  distinct_on?: InputMaybe<Array<SpecificTimeSurchargeConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SpecificTimeSurchargeConfigOrderBy>>;
  where?: InputMaybe<SpecificTimeSurchargeConfigBoolExp>;
};

/** Boolean expression to filter rows from the table "specificTimeSurchargeMeta". All fields are combined with a logical 'AND'. */
export type SpecificTimeSurchargeMetaBoolExp = {
  _and?: InputMaybe<Array<SpecificTimeSurchargeMetaBoolExp>>;
  _not?: InputMaybe<SpecificTimeSurchargeMetaBoolExp>;
  _or?: InputMaybe<Array<SpecificTimeSurchargeMetaBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  specificTimeSurchargeConfigs?: InputMaybe<SpecificTimeSurchargeConfigBoolExp>;
};

/** Ordering options when selecting data from "specificTimeSurchargeMeta". */
export type SpecificTimeSurchargeMetaOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  specificTimeSurchargeConfigs_aggregate?: InputMaybe<SpecificTimeSurchargeConfigAggregateOrderBy>;
};

/** select columns of table "specificTimeSurchargeMeta" */
export enum SpecificTimeSurchargeMetaSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id'
}

/** Streaming cursor of the table "specificTimeSurchargeMeta" */
export type SpecificTimeSurchargeMetaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SpecificTimeSurchargeMetaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type SpecificTimeSurchargeMetaStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "steraOnSitePaymentDetailType" */
export type SteraOnSitePaymentDetailType = {
  __typename?: 'steraOnSitePaymentDetailType';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  onSitePaymentDetailType: OnSitePaymentDetailType;
  onSitePaymentDetailTypeEntityType: Scalars['String'];
  onSitePaymentDetailTypeId: Scalars['uuid'];
  steraPaymentMethodType: SteraPaymentMethodTypeEnum;
};

/** order by aggregate values of table "steraOnSitePaymentDetailType" */
export type SteraOnSitePaymentDetailTypeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SteraOnSitePaymentDetailTypeMaxOrderBy>;
  min?: InputMaybe<SteraOnSitePaymentDetailTypeMinOrderBy>;
};

/** input type for inserting array relation for remote table "steraOnSitePaymentDetailType" */
export type SteraOnSitePaymentDetailTypeArrRelInsertInput = {
  data: Array<SteraOnSitePaymentDetailTypeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SteraOnSitePaymentDetailTypeOnConflict>;
};

/**
 * Boolean expression to filter rows from the table "steraOnSitePaymentDetailType".
 * All fields are combined with a logical 'AND'.
 */
export type SteraOnSitePaymentDetailTypeBoolExp = {
  _and?: InputMaybe<Array<SteraOnSitePaymentDetailTypeBoolExp>>;
  _not?: InputMaybe<SteraOnSitePaymentDetailTypeBoolExp>;
  _or?: InputMaybe<Array<SteraOnSitePaymentDetailTypeBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<StringComparisonExp>;
  onSitePaymentDetailTypeId?: InputMaybe<UuidComparisonExp>;
  steraPaymentMethodType?: InputMaybe<SteraPaymentMethodTypeEnumComparisonExp>;
};

/** unique or primary key constraints on table "steraOnSitePaymentDetailType" */
export enum SteraOnSitePaymentDetailTypeConstraint {
  /** unique or primary key constraint on columns "onSitePaymentDetailTypeEntityType", "_companyId" */
  SteraOnSitePaymentDetailTypeOnSitePaymentDetailTypeEntityType = 'steraOnSitePaymentDetailType_onSitePaymentDetailTypeEntityType_',
  /** unique or primary key constraint on columns "id" */
  SteraOnSitePaymentDetailTypePkey = 'steraOnSitePaymentDetailType_pkey',
  /** unique or primary key constraint on columns "onSitePaymentDetailTypeId" */
  SteraOnSitePaymentDetailTypeTypeIdIdx = 'steraOnSitePaymentDetailType_typeId_idx',
  /** unique or primary key constraint on columns "onSitePaymentDetailTypeEntityType", "companyId" */
  SteraOnSitePaymentDetailTypeTypeCompanyIdKey = 'steraOnSitePaymentDetailType_type_companyId_key'
}

/** input type for inserting data into table "steraOnSitePaymentDetailType" */
export type SteraOnSitePaymentDetailTypeInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeObjRelInsertInput>;
  onSitePaymentDetailTypeEntityType: Scalars['String'];
  onSitePaymentDetailTypeId: Scalars['uuid'];
  steraPaymentMethodType: SteraPaymentMethodTypeEnum;
};

/** order by max() on columns of table "steraOnSitePaymentDetailType" */
export type SteraOnSitePaymentDetailTypeMaxOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "steraOnSitePaymentDetailType" */
export type SteraOnSitePaymentDetailTypeMinOrderBy = {
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "steraOnSitePaymentDetailType" */
export type SteraOnSitePaymentDetailTypeMutationResponse = {
  __typename?: 'steraOnSitePaymentDetailType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SteraOnSitePaymentDetailType>;
};

/** input type for inserting object relation for remote table "steraOnSitePaymentDetailType" */
export type SteraOnSitePaymentDetailTypeObjRelInsertInput = {
  data: SteraOnSitePaymentDetailTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<SteraOnSitePaymentDetailTypeOnConflict>;
};

/** on_conflict condition type for table "steraOnSitePaymentDetailType" */
export type SteraOnSitePaymentDetailTypeOnConflict = {
  constraint: SteraOnSitePaymentDetailTypeConstraint;
  update_columns?: Array<SteraOnSitePaymentDetailTypeUpdateColumn>;
  where?: InputMaybe<SteraOnSitePaymentDetailTypeBoolExp>;
};

/** Ordering options when selecting data from "steraOnSitePaymentDetailType". */
export type SteraOnSitePaymentDetailTypeOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeOrderBy>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
  steraPaymentMethodType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: steraOnSitePaymentDetailType */
export type SteraOnSitePaymentDetailTypePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "steraOnSitePaymentDetailType" */
export enum SteraOnSitePaymentDetailTypeSelectColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  OnSitePaymentDetailTypeEntityType = 'onSitePaymentDetailTypeEntityType',
  /** column name */
  OnSitePaymentDetailTypeId = 'onSitePaymentDetailTypeId',
  /** column name */
  SteraPaymentMethodType = 'steraPaymentMethodType'
}

/** input type for updating data in table "steraOnSitePaymentDetailType" */
export type SteraOnSitePaymentDetailTypeSetInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeId?: InputMaybe<Scalars['uuid']>;
  steraPaymentMethodType?: InputMaybe<SteraPaymentMethodTypeEnum>;
};

/** Streaming cursor of the table "steraOnSitePaymentDetailType" */
export type SteraOnSitePaymentDetailTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SteraOnSitePaymentDetailTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type SteraOnSitePaymentDetailTypeStreamCursorValueInput = {
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeId?: InputMaybe<Scalars['uuid']>;
  steraPaymentMethodType?: InputMaybe<SteraPaymentMethodTypeEnum>;
};

/** update columns of table "steraOnSitePaymentDetailType" */
export enum SteraOnSitePaymentDetailTypeUpdateColumn {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  OnSitePaymentDetailTypeEntityType = 'onSitePaymentDetailTypeEntityType',
  /** column name */
  OnSitePaymentDetailTypeId = 'onSitePaymentDetailTypeId',
  /** column name */
  SteraPaymentMethodType = 'steraPaymentMethodType'
}

export type SteraOnSitePaymentDetailTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SteraOnSitePaymentDetailTypeSetInput>;
  /** filter the rows which have to be updated */
  where: SteraOnSitePaymentDetailTypeBoolExp;
};

export enum SteraPaymentMethodTypeEnum {
  Edy = 'Edy',
  Nfc = 'NFC',
  PiTaPa = 'PiTaPa',
  Qr = 'QR',
  QuicPay = 'QUICPay',
  Waon = 'WAON',
  CreditCard = 'creditCard',
  ID = 'iD',
  Nanaco = 'nanaco',
  PrepaidTransportationCard = 'prepaidTransportationCard',
  ThreeWayCreditCard = 'threeWayCreditCard',
  UnionPay = 'unionPay'
}

/** Boolean expression to compare columns of type "steraPaymentMethodType_enum". All fields are combined with logical 'AND'. */
export type SteraPaymentMethodTypeEnumComparisonExp = {
  _eq?: InputMaybe<SteraPaymentMethodTypeEnum>;
  _in?: InputMaybe<Array<SteraPaymentMethodTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SteraPaymentMethodTypeEnum>;
  _nin?: InputMaybe<Array<SteraPaymentMethodTypeEnum>>;
};

/** columns and relationships of "stock" */
export type Stock = {
  __typename?: 'stock';
  currentStockNum?: Maybe<Scalars['Int']>;
  dailyStockNum?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** An object relationship */
  shop: Shop;
  /** An array relationship */
  shopChoices: Array<ShopChoice>;
  /** An array relationship */
  shopChoicesByStockid: Array<ShopChoice>;
  shopId: Scalars['uuid'];
  /** An array relationship */
  shopMenus: Array<ShopMenu>;
  /** An array relationship */
  shopMenusByStockid: Array<ShopMenu>;
  /** An array relationship */
  shopPlanChoices: Array<ShopPlanChoice>;
  /** An array relationship */
  shopPlanChoicesByStockid: Array<ShopPlanChoice>;
  /** An array relationship */
  shopPlans: Array<ShopPlan>;
  /** An array relationship */
  shopPlansByStockid: Array<ShopPlan>;
};


/** columns and relationships of "stock" */
export type StockShopChoicesArgs = {
  distinct_on?: InputMaybe<Array<ShopChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopChoiceOrderBy>>;
  where?: InputMaybe<ShopChoiceBoolExp>;
};


/** columns and relationships of "stock" */
export type StockShopChoicesByStockidArgs = {
  distinct_on?: InputMaybe<Array<ShopChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopChoiceOrderBy>>;
  where?: InputMaybe<ShopChoiceBoolExp>;
};


/** columns and relationships of "stock" */
export type StockShopMenusArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuOrderBy>>;
  where?: InputMaybe<ShopMenuBoolExp>;
};


/** columns and relationships of "stock" */
export type StockShopMenusByStockidArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuOrderBy>>;
  where?: InputMaybe<ShopMenuBoolExp>;
};


/** columns and relationships of "stock" */
export type StockShopPlanChoicesArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanChoiceOrderBy>>;
  where?: InputMaybe<ShopPlanChoiceBoolExp>;
};


/** columns and relationships of "stock" */
export type StockShopPlanChoicesByStockidArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanChoiceOrderBy>>;
  where?: InputMaybe<ShopPlanChoiceBoolExp>;
};


/** columns and relationships of "stock" */
export type StockShopPlansArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOrderBy>>;
  where?: InputMaybe<ShopPlanBoolExp>;
};


/** columns and relationships of "stock" */
export type StockShopPlansByStockidArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOrderBy>>;
  where?: InputMaybe<ShopPlanBoolExp>;
};

/** order by aggregate values of table "stock" */
export type StockAggregateOrderBy = {
  avg?: InputMaybe<StockAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<StockMaxOrderBy>;
  min?: InputMaybe<StockMinOrderBy>;
  stddev?: InputMaybe<StockStddevOrderBy>;
  stddev_pop?: InputMaybe<StockStddevPopOrderBy>;
  stddev_samp?: InputMaybe<StockStddevSampOrderBy>;
  sum?: InputMaybe<StockSumOrderBy>;
  var_pop?: InputMaybe<StockVarPopOrderBy>;
  var_samp?: InputMaybe<StockVarSampOrderBy>;
  variance?: InputMaybe<StockVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "stock" */
export type StockArrRelInsertInput = {
  data: Array<StockInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<StockOnConflict>;
};

/** order by avg() on columns of table "stock" */
export type StockAvgOrderBy = {
  currentStockNum?: InputMaybe<OrderBy>;
  dailyStockNum?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "stock". All fields are combined with a logical 'AND'. */
export type StockBoolExp = {
  _and?: InputMaybe<Array<StockBoolExp>>;
  _not?: InputMaybe<StockBoolExp>;
  _or?: InputMaybe<Array<StockBoolExp>>;
  currentStockNum?: InputMaybe<IntComparisonExp>;
  dailyStockNum?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopChoices?: InputMaybe<ShopChoiceBoolExp>;
  shopChoicesByStockid?: InputMaybe<ShopChoiceBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  shopMenus?: InputMaybe<ShopMenuBoolExp>;
  shopMenusByStockid?: InputMaybe<ShopMenuBoolExp>;
  shopPlanChoices?: InputMaybe<ShopPlanChoiceBoolExp>;
  shopPlanChoicesByStockid?: InputMaybe<ShopPlanChoiceBoolExp>;
  shopPlans?: InputMaybe<ShopPlanBoolExp>;
  shopPlansByStockid?: InputMaybe<ShopPlanBoolExp>;
};

/** unique or primary key constraints on table "stock" */
export enum StockConstraint {
  /** unique or primary key constraint on columns "id", "shopId" */
  StockIdShopIdKey = 'stock_id_shopId_key',
  /** unique or primary key constraint on columns "id" */
  StockPkey = 'stock_pkey'
}

/** input type for incrementing numeric columns in table "stock" */
export type StockIncInput = {
  currentStockNum?: InputMaybe<Scalars['Int']>;
  dailyStockNum?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock" */
export type StockInsertInput = {
  currentStockNum?: InputMaybe<Scalars['Int']>;
  dailyStockNum?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "stock" */
export type StockMaxOrderBy = {
  currentStockNum?: InputMaybe<OrderBy>;
  dailyStockNum?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "stock" */
export type StockMinOrderBy = {
  currentStockNum?: InputMaybe<OrderBy>;
  dailyStockNum?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "stock" */
export type StockMutationResponse = {
  __typename?: 'stock_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stock>;
};

/** on_conflict condition type for table "stock" */
export type StockOnConflict = {
  constraint: StockConstraint;
  update_columns?: Array<StockUpdateColumn>;
  where?: InputMaybe<StockBoolExp>;
};

/** Ordering options when selecting data from "stock". */
export type StockOrderBy = {
  currentStockNum?: InputMaybe<OrderBy>;
  dailyStockNum?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopChoicesByStockid_aggregate?: InputMaybe<ShopChoiceAggregateOrderBy>;
  shopChoices_aggregate?: InputMaybe<ShopChoiceAggregateOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopMenusByStockid_aggregate?: InputMaybe<ShopMenuAggregateOrderBy>;
  shopMenus_aggregate?: InputMaybe<ShopMenuAggregateOrderBy>;
  shopPlanChoicesByStockid_aggregate?: InputMaybe<ShopPlanChoiceAggregateOrderBy>;
  shopPlanChoices_aggregate?: InputMaybe<ShopPlanChoiceAggregateOrderBy>;
  shopPlansByStockid_aggregate?: InputMaybe<ShopPlanAggregateOrderBy>;
  shopPlans_aggregate?: InputMaybe<ShopPlanAggregateOrderBy>;
};

/** primary key columns input for table: stock */
export type StockPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "stock" */
export enum StockSelectColumn {
  /** column name */
  CurrentStockNum = 'currentStockNum',
  /** column name */
  DailyStockNum = 'dailyStockNum',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "stock" */
export type StockSetInput = {
  currentStockNum?: InputMaybe<Scalars['Int']>;
  dailyStockNum?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "stock" */
export type StockStddevOrderBy = {
  currentStockNum?: InputMaybe<OrderBy>;
  dailyStockNum?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "stock" */
export type StockStddevPopOrderBy = {
  currentStockNum?: InputMaybe<OrderBy>;
  dailyStockNum?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "stock" */
export type StockStddevSampOrderBy = {
  currentStockNum?: InputMaybe<OrderBy>;
  dailyStockNum?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "stock" */
export type StockStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: StockStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type StockStreamCursorValueInput = {
  currentStockNum?: InputMaybe<Scalars['Int']>;
  dailyStockNum?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "stock" */
export type StockSumOrderBy = {
  currentStockNum?: InputMaybe<OrderBy>;
  dailyStockNum?: InputMaybe<OrderBy>;
};

/** update columns of table "stock" */
export enum StockUpdateColumn {
  /** column name */
  CurrentStockNum = 'currentStockNum',
  /** column name */
  DailyStockNum = 'dailyStockNum',
  /** column name */
  Id = 'id',
  /** column name */
  ShopId = 'shopId'
}

export type StockUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StockIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StockSetInput>;
  /** filter the rows which have to be updated */
  where: StockBoolExp;
};

/** order by var_pop() on columns of table "stock" */
export type StockVarPopOrderBy = {
  currentStockNum?: InputMaybe<OrderBy>;
  dailyStockNum?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "stock" */
export type StockVarSampOrderBy = {
  currentStockNum?: InputMaybe<OrderBy>;
  dailyStockNum?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "stock" */
export type StockVarianceOrderBy = {
  currentStockNum?: InputMaybe<OrderBy>;
  dailyStockNum?: InputMaybe<OrderBy>;
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "accountingSlipImage" */
  accountingSlipImage: Array<AccountingSlipImage>;
  /** fetch data from the table: "accountingSlipImage" using primary key columns */
  accountingSlipImage_by_pk?: Maybe<AccountingSlipImage>;
  /** fetch data from the table in a streaming manner: "accountingSlipImage" */
  accountingSlipImage_stream: Array<AccountingSlipImage>;
  /** fetch data from the table: "activePlan" */
  activePlan: Array<ActivePlan>;
  /** fetch data from the table: "activePlanChoice" */
  activePlanChoice: Array<ActivePlanChoice>;
  /** fetch data from the table: "activePlanChoice" using primary key columns */
  activePlanChoice_by_pk?: Maybe<ActivePlanChoice>;
  /** fetch data from the table in a streaming manner: "activePlanChoice" */
  activePlanChoice_stream: Array<ActivePlanChoice>;
  /** fetch data from the table: "activePlanOpenPriceMeta" */
  activePlanOpenPriceMeta: Array<ActivePlanOpenPriceMeta>;
  /** fetch data from the table: "activePlanOpenPriceMeta" using primary key columns */
  activePlanOpenPriceMeta_by_pk?: Maybe<ActivePlanOpenPriceMeta>;
  /** fetch data from the table in a streaming manner: "activePlanOpenPriceMeta" */
  activePlanOpenPriceMeta_stream: Array<ActivePlanOpenPriceMeta>;
  /** fetch data from the table: "activePlanOption" */
  activePlanOption: Array<ActivePlanOption>;
  /** fetch data from the table: "activePlanOption" using primary key columns */
  activePlanOption_by_pk?: Maybe<ActivePlanOption>;
  /** fetch data from the table in a streaming manner: "activePlanOption" */
  activePlanOption_stream: Array<ActivePlanOption>;
  /** fetch data from the table: "activePlan" using primary key columns */
  activePlan_by_pk?: Maybe<ActivePlan>;
  /** fetch data from the table in a streaming manner: "activePlan" */
  activePlan_stream: Array<ActivePlan>;
  /** fetch data from the table: "adyenPaymentReportTerminalPaymentAuthorizedEvent" */
  adyenPaymentReportTerminalPaymentAuthorizedEvent: Array<AdyenPaymentReportTerminalPaymentAuthorizedEvent>;
  /** fetch data from the table: "adyenPaymentReportTerminalPaymentAuthorizedEvent" using primary key columns */
  adyenPaymentReportTerminalPaymentAuthorizedEvent_by_pk?: Maybe<AdyenPaymentReportTerminalPaymentAuthorizedEvent>;
  /** fetch data from the table in a streaming manner: "adyenPaymentReportTerminalPaymentAuthorizedEvent" */
  adyenPaymentReportTerminalPaymentAuthorizedEvent_stream: Array<AdyenPaymentReportTerminalPaymentAuthorizedEvent>;
  /** fetch data from the table: "adyenPaymentReportTerminalPaymentCanceledEvent" */
  adyenPaymentReportTerminalPaymentCanceledEvent: Array<AdyenPaymentReportTerminalPaymentCanceledEvent>;
  /** fetch data from the table: "adyenPaymentReportTerminalPaymentCanceledEvent" using primary key columns */
  adyenPaymentReportTerminalPaymentCanceledEvent_by_pk?: Maybe<AdyenPaymentReportTerminalPaymentCanceledEvent>;
  /** fetch data from the table in a streaming manner: "adyenPaymentReportTerminalPaymentCanceledEvent" */
  adyenPaymentReportTerminalPaymentCanceledEvent_stream: Array<AdyenPaymentReportTerminalPaymentCanceledEvent>;
  /** fetch data from the table: "adyenPaymentShopConfig" */
  adyenPaymentShopConfig: Array<AdyenPaymentShopConfig>;
  /** fetch data from the table: "adyenPaymentShopConfig" using primary key columns */
  adyenPaymentShopConfig_by_pk?: Maybe<AdyenPaymentShopConfig>;
  /** fetch data from the table in a streaming manner: "adyenPaymentShopConfig" */
  adyenPaymentShopConfig_stream: Array<AdyenPaymentShopConfig>;
  /** fetch data from the table: "adyenPaymentShopPaymentMethod" */
  adyenPaymentShopPaymentMethod: Array<AdyenPaymentShopPaymentMethod>;
  /** fetch data from the table: "adyenPaymentShopPaymentMethod" using primary key columns */
  adyenPaymentShopPaymentMethod_by_pk?: Maybe<AdyenPaymentShopPaymentMethod>;
  /** fetch data from the table in a streaming manner: "adyenPaymentShopPaymentMethod" */
  adyenPaymentShopPaymentMethod_stream: Array<AdyenPaymentShopPaymentMethod>;
  /** fetch data from the table: "adyenPaymentStoreMeta" */
  adyenPaymentStoreMeta: Array<AdyenPaymentStoreMeta>;
  /** fetch data from the table: "adyenPaymentStoreMeta" using primary key columns */
  adyenPaymentStoreMeta_by_pk?: Maybe<AdyenPaymentStoreMeta>;
  /** fetch data from the table in a streaming manner: "adyenPaymentStoreMeta" */
  adyenPaymentStoreMeta_stream: Array<AdyenPaymentStoreMeta>;
  /** fetch data from the table: "adyenTerminalPaymentShopConfig" */
  adyenTerminalPaymentShopConfig: Array<AdyenTerminalPaymentShopConfig>;
  /** fetch data from the table: "adyenTerminalPaymentShopConfig" using primary key columns */
  adyenTerminalPaymentShopConfig_by_pk?: Maybe<AdyenTerminalPaymentShopConfig>;
  /** fetch data from the table in a streaming manner: "adyenTerminalPaymentShopConfig" */
  adyenTerminalPaymentShopConfig_stream: Array<AdyenTerminalPaymentShopConfig>;
  /** fetch data from the table: "adyenTerminalPaymentShopPaymentMethod" */
  adyenTerminalPaymentShopPaymentMethod: Array<AdyenTerminalPaymentShopPaymentMethod>;
  /** fetch data from the table: "adyenTerminalPaymentShopPaymentMethod" using primary key columns */
  adyenTerminalPaymentShopPaymentMethod_by_pk?: Maybe<AdyenTerminalPaymentShopPaymentMethod>;
  /** fetch data from the table in a streaming manner: "adyenTerminalPaymentShopPaymentMethod" */
  adyenTerminalPaymentShopPaymentMethod_stream: Array<AdyenTerminalPaymentShopPaymentMethod>;
  /** fetch data from the table: "analyticsSetting" */
  analyticsSetting: Array<AnalyticsSetting>;
  /** fetch data from the table: "analyticsSetting" using primary key columns */
  analyticsSetting_by_pk?: Maybe<AnalyticsSetting>;
  /** fetch data from the table in a streaming manner: "analyticsSetting" */
  analyticsSetting_stream: Array<AnalyticsSetting>;
  /** fetch data from the table: "birthdayTypeQuestionAnswer" */
  birthdayTypeQuestionAnswer: Array<BirthdayTypeQuestionAnswer>;
  /** fetch data from the table in a streaming manner: "birthdayTypeQuestionAnswer" */
  birthdayTypeQuestionAnswer_stream: Array<BirthdayTypeQuestionAnswer>;
  /** fetch data from the table: "cashRegisterConfig" */
  cashRegisterConfig: Array<CashRegisterConfig>;
  /** fetch aggregated fields from the table: "cashRegisterConfig" */
  cashRegisterConfig_aggregate: CashRegisterConfigAggregate;
  /** fetch data from the table: "cashRegisterConfig" using primary key columns */
  cashRegisterConfig_by_pk?: Maybe<CashRegisterConfig>;
  /** fetch data from the table in a streaming manner: "cashRegisterConfig" */
  cashRegisterConfig_stream: Array<CashRegisterConfig>;
  /** fetch data from the table: "cashRegisterSecurityLevel" */
  cashRegisterSecurityLevel: Array<CashRegisterSecurityLevel>;
  /** fetch data from the table: "cashRegisterSecurityLevel" using primary key columns */
  cashRegisterSecurityLevel_by_pk?: Maybe<CashRegisterSecurityLevel>;
  /** fetch data from the table in a streaming manner: "cashRegisterSecurityLevel" */
  cashRegisterSecurityLevel_stream: Array<CashRegisterSecurityLevel>;
  /** fetch data from the table: "category" */
  category: Array<Category>;
  /** fetch data from the table: "categoryMenu" */
  categoryMenu: Array<CategoryMenu>;
  /** fetch aggregated fields from the table: "categoryMenu" */
  categoryMenu_aggregate: CategoryMenuAggregate;
  /** fetch data from the table: "categoryMenu" using primary key columns */
  categoryMenu_by_pk?: Maybe<CategoryMenu>;
  /** fetch data from the table in a streaming manner: "categoryMenu" */
  categoryMenu_stream: Array<CategoryMenu>;
  /** fetch aggregated fields from the table: "category" */
  category_aggregate: CategoryAggregate;
  /** fetch data from the table: "category" using primary key columns */
  category_by_pk?: Maybe<Category>;
  /** fetch data from the table in a streaming manner: "category" */
  category_stream: Array<Category>;
  /** fetch data from the table: "checkInEvent" */
  checkInEvent: Array<CheckInEvent>;
  /** fetch data from the table in a streaming manner: "checkInEvent" */
  checkInEvent_stream: Array<CheckInEvent>;
  /** fetch data from the table: "choice" */
  choice: Array<Choice>;
  /** fetch aggregated fields from the table: "choice" */
  choice_aggregate: ChoiceAggregate;
  /** fetch data from the table: "choice" using primary key columns */
  choice_by_pk?: Maybe<Choice>;
  /** fetch data from the table in a streaming manner: "choice" */
  choice_stream: Array<Choice>;
  /** fetch data from the table: "clerk" */
  clerk: Array<Clerk>;
  /** fetch data from the table: "clerk" using primary key columns */
  clerk_by_pk?: Maybe<Clerk>;
  /** fetch data from the table in a streaming manner: "clerk" */
  clerk_stream: Array<Clerk>;
  /** fetch data from the table: "closeCashRegister" */
  closeCashRegister: Array<CloseCashRegister>;
  /** fetch data from the table: "closeCashRegister" using primary key columns */
  closeCashRegister_by_pk?: Maybe<CloseCashRegister>;
  /** fetch data from the table in a streaming manner: "closeCashRegister" */
  closeCashRegister_stream: Array<CloseCashRegister>;
  /** fetch data from the table: "coineyPayment" */
  coineyPayment: Array<CoineyPayment>;
  /** fetch data from the table: "coineyPaymentOrder" */
  coineyPaymentOrder: Array<CoineyPaymentOrder>;
  /** fetch data from the table: "coineyPaymentOrder" using primary key columns */
  coineyPaymentOrder_by_pk?: Maybe<CoineyPaymentOrder>;
  /** fetch data from the table in a streaming manner: "coineyPaymentOrder" */
  coineyPaymentOrder_stream: Array<CoineyPaymentOrder>;
  /** fetch data from the table: "coineyPaymentSales" */
  coineyPaymentSales: Array<CoineyPaymentSales>;
  /** fetch data from the table: "coineyPaymentSales" using primary key columns */
  coineyPaymentSales_by_pk?: Maybe<CoineyPaymentSales>;
  /** fetch data from the table in a streaming manner: "coineyPaymentSales" */
  coineyPaymentSales_stream: Array<CoineyPaymentSales>;
  /** fetch data from the table: "coineyPayment" using primary key columns */
  coineyPayment_by_pk?: Maybe<CoineyPayment>;
  /** fetch data from the table in a streaming manner: "coineyPayment" */
  coineyPayment_stream: Array<CoineyPayment>;
  /** fetch data from the table: "coineySettlement" */
  coineySettlement: Array<CoineySettlement>;
  /** fetch data from the table: "coineySettlement" using primary key columns */
  coineySettlement_by_pk?: Maybe<CoineySettlement>;
  /** fetch data from the table in a streaming manner: "coineySettlement" */
  coineySettlement_stream: Array<CoineySettlement>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch data from the table: "companyMember" */
  companyMember: Array<CompanyMember>;
  /** fetch aggregated fields from the table: "companyMember" */
  companyMember_aggregate: CompanyMemberAggregate;
  /** fetch data from the table: "companyMember" using primary key columns */
  companyMember_by_pk?: Maybe<CompanyMember>;
  /** fetch data from the table in a streaming manner: "companyMember" */
  companyMember_stream: Array<CompanyMember>;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table in a streaming manner: "company" */
  company_stream: Array<Company>;
  /** fetch data from the table: "compulsoryAppetizerConfig" */
  compulsoryAppetizerConfig: Array<CompulsoryAppetizerConfig>;
  /** fetch data from the table: "compulsoryAppetizerConfig" using primary key columns */
  compulsoryAppetizerConfig_by_pk?: Maybe<CompulsoryAppetizerConfig>;
  /** fetch data from the table in a streaming manner: "compulsoryAppetizerConfig" */
  compulsoryAppetizerConfig_stream: Array<CompulsoryAppetizerConfig>;
  /** fetch data from the table: "connectGameConfig" */
  connectGameConfig: Array<ConnectGameConfig>;
  /** fetch data from the table: "connectGameConfigShop" */
  connectGameConfigShop: Array<ConnectGameConfigShop>;
  /** fetch data from the table: "connectGameConfigShop" using primary key columns */
  connectGameConfigShop_by_pk?: Maybe<ConnectGameConfigShop>;
  /** fetch data from the table in a streaming manner: "connectGameConfigShop" */
  connectGameConfigShop_stream: Array<ConnectGameConfigShop>;
  /** fetch data from the table: "connectGameConfig" using primary key columns */
  connectGameConfig_by_pk?: Maybe<ConnectGameConfig>;
  /** fetch data from the table in a streaming manner: "connectGameConfig" */
  connectGameConfig_stream: Array<ConnectGameConfig>;
  /** fetch data from the table: "cookingItem" */
  cookingItem: Array<CookingItem>;
  /** fetch data from the table: "cookingItem" using primary key columns */
  cookingItem_by_pk?: Maybe<CookingItem>;
  /** fetch data from the table in a streaming manner: "cookingItem" */
  cookingItem_stream: Array<CookingItem>;
  /** fetch data from the table: "corporation" */
  corporation: Array<Corporation>;
  /** fetch data from the table: "corporation" using primary key columns */
  corporation_by_pk?: Maybe<Corporation>;
  /** fetch data from the table in a streaming manner: "corporation" */
  corporation_stream: Array<Corporation>;
  /** fetch data from the table: "coupon" */
  coupon: Array<Coupon>;
  /** fetch data from the table: "couponAppliedEvent" */
  couponAppliedEvent: Array<CouponAppliedEvent>;
  /** fetch aggregated fields from the table: "couponAppliedEvent" */
  couponAppliedEvent_aggregate: CouponAppliedEventAggregate;
  /** fetch data from the table: "couponAppliedEvent" using primary key columns */
  couponAppliedEvent_by_pk?: Maybe<CouponAppliedEvent>;
  /** fetch data from the table in a streaming manner: "couponAppliedEvent" */
  couponAppliedEvent_stream: Array<CouponAppliedEvent>;
  /** fetch data from the table: "couponMenuDiscount" */
  couponMenuDiscount: Array<CouponMenuDiscount>;
  /** fetch data from the table: "couponMenuDiscount" using primary key columns */
  couponMenuDiscount_by_pk?: Maybe<CouponMenuDiscount>;
  /** fetch data from the table in a streaming manner: "couponMenuDiscount" */
  couponMenuDiscount_stream: Array<CouponMenuDiscount>;
  /** fetch data from the table: "coupon" using primary key columns */
  coupon_by_pk?: Maybe<Coupon>;
  /** fetch data from the table in a streaming manner: "coupon" */
  coupon_stream: Array<Coupon>;
  /** fetch data from the table: "customer" */
  customer: Array<Customer>;
  /** fetch data from the table: "customerQuestionnaire" */
  customerQuestionnaire: Array<CustomerQuestionnaire>;
  /** fetch data from the table: "customerQuestionnaire" using primary key columns */
  customerQuestionnaire_by_pk?: Maybe<CustomerQuestionnaire>;
  /** fetch data from the table in a streaming manner: "customerQuestionnaire" */
  customerQuestionnaire_stream: Array<CustomerQuestionnaire>;
  /** fetch data from the table: "customer" using primary key columns */
  customer_by_pk?: Maybe<Customer>;
  /** fetch data from the table in a streaming manner: "customer" */
  customer_stream: Array<Customer>;
  /** fetch data from the table: "dailySalesBudget" */
  dailySalesBudget: Array<DailySalesBudget>;
  /** fetch data from the table: "dailySalesBudget" using primary key columns */
  dailySalesBudget_by_pk?: Maybe<DailySalesBudget>;
  /** fetch data from the table in a streaming manner: "dailySalesBudget" */
  dailySalesBudget_stream: Array<DailySalesBudget>;
  /** fetch data from the table: "dashboardAccount" */
  dashboardAccount: Array<DashboardAccount>;
  /** fetch data from the table: "dashboardAccountAccessibleCompany" */
  dashboardAccountAccessibleCompany: Array<DashboardAccountAccessibleCompany>;
  /** fetch data from the table: "dashboardAccountAccessibleCompany" using primary key columns */
  dashboardAccountAccessibleCompany_by_pk?: Maybe<DashboardAccountAccessibleCompany>;
  /** fetch data from the table in a streaming manner: "dashboardAccountAccessibleCompany" */
  dashboardAccountAccessibleCompany_stream: Array<DashboardAccountAccessibleCompany>;
  /** fetch data from the table: "dashboardAccountAccessibleShop" */
  dashboardAccountAccessibleShop: Array<DashboardAccountAccessibleShop>;
  /** fetch data from the table: "dashboardAccountAccessibleShop" using primary key columns */
  dashboardAccountAccessibleShop_by_pk?: Maybe<DashboardAccountAccessibleShop>;
  /** fetch data from the table in a streaming manner: "dashboardAccountAccessibleShop" */
  dashboardAccountAccessibleShop_stream: Array<DashboardAccountAccessibleShop>;
  /** fetch data from the table: "dashboardAccountRole" */
  dashboardAccountRole: Array<DashboardAccountRole>;
  /** fetch data from the table: "dashboardAccountRolePermission" */
  dashboardAccountRolePermission: Array<DashboardAccountRolePermission>;
  /** fetch data from the table: "dashboardAccountRolePermission" using primary key columns */
  dashboardAccountRolePermission_by_pk?: Maybe<DashboardAccountRolePermission>;
  /** fetch data from the table in a streaming manner: "dashboardAccountRolePermission" */
  dashboardAccountRolePermission_stream: Array<DashboardAccountRolePermission>;
  /** fetch data from the table: "dashboardAccountRoleType" */
  dashboardAccountRoleType: Array<DashboardAccountRoleType>;
  /** fetch data from the table: "dashboardAccountRoleType" using primary key columns */
  dashboardAccountRoleType_by_pk?: Maybe<DashboardAccountRoleType>;
  /** fetch data from the table in a streaming manner: "dashboardAccountRoleType" */
  dashboardAccountRoleType_stream: Array<DashboardAccountRoleType>;
  /** fetch data from the table: "dashboardAccountRole" using primary key columns */
  dashboardAccountRole_by_pk?: Maybe<DashboardAccountRole>;
  /** fetch data from the table in a streaming manner: "dashboardAccountRole" */
  dashboardAccountRole_stream: Array<DashboardAccountRole>;
  /** fetch data from the table: "dashboardAccount" using primary key columns */
  dashboardAccount_by_pk?: Maybe<DashboardAccount>;
  /** fetch data from the table in a streaming manner: "dashboardAccount" */
  dashboardAccount_stream: Array<DashboardAccount>;
  /** fetch data from the table: "dishUpSlipGroup" */
  dishUpSlipGroup: Array<DishUpSlipGroup>;
  /** An array relationship */
  dishUpSlipGroupRoles: Array<DishUpSlipGroupRoles>;
  /** fetch data from the table: "dishUpSlipGroupRoles" using primary key columns */
  dishUpSlipGroupRoles_by_pk?: Maybe<DishUpSlipGroupRoles>;
  /** fetch data from the table in a streaming manner: "dishUpSlipGroupRoles" */
  dishUpSlipGroupRoles_stream: Array<DishUpSlipGroupRoles>;
  /** An array relationship */
  dishUpSlipGroupShopMenus: Array<DishUpSlipGroupShopMenus>;
  /** fetch data from the table: "dishUpSlipGroupShopMenus" using primary key columns */
  dishUpSlipGroupShopMenus_by_pk?: Maybe<DishUpSlipGroupShopMenus>;
  /** fetch data from the table in a streaming manner: "dishUpSlipGroupShopMenus" */
  dishUpSlipGroupShopMenus_stream: Array<DishUpSlipGroupShopMenus>;
  /** An array relationship */
  dishUpSlipGroupShopPlans: Array<DishUpSlipGroupShopPlans>;
  /** fetch data from the table: "dishUpSlipGroupShopPlans" using primary key columns */
  dishUpSlipGroupShopPlans_by_pk?: Maybe<DishUpSlipGroupShopPlans>;
  /** fetch data from the table in a streaming manner: "dishUpSlipGroupShopPlans" */
  dishUpSlipGroupShopPlans_stream: Array<DishUpSlipGroupShopPlans>;
  /** An array relationship */
  dishUpSlipGroupTables: Array<DishUpSlipGroupTables>;
  /** fetch data from the table: "dishUpSlipGroupTables" using primary key columns */
  dishUpSlipGroupTables_by_pk?: Maybe<DishUpSlipGroupTables>;
  /** fetch data from the table in a streaming manner: "dishUpSlipGroupTables" */
  dishUpSlipGroupTables_stream: Array<DishUpSlipGroupTables>;
  /** fetch data from the table: "dishUpSlipGroup" using primary key columns */
  dishUpSlipGroup_by_pk?: Maybe<DishUpSlipGroup>;
  /** fetch data from the table in a streaming manner: "dishUpSlipGroup" */
  dishUpSlipGroup_stream: Array<DishUpSlipGroup>;
  /** fetch data from the table: "ebicaConfig" */
  ebicaConfig: Array<EbicaConfig>;
  /** fetch data from the table: "ebicaConfig" using primary key columns */
  ebicaConfig_by_pk?: Maybe<EbicaConfig>;
  /** fetch data from the table in a streaming manner: "ebicaConfig" */
  ebicaConfig_stream: Array<EbicaConfig>;
  /** fetch data from the table: "ebicaTable" */
  ebicaTable: Array<EbicaTable>;
  /** fetch data from the table: "ebicaTable" using primary key columns */
  ebicaTable_by_pk?: Maybe<EbicaTable>;
  /** fetch data from the table in a streaming manner: "ebicaTable" */
  ebicaTable_stream: Array<EbicaTable>;
  /** fetch data from the table: "externalOnlineMenu" */
  externalOnlineMenu: Array<ExternalOnlineMenu>;
  /** fetch data from the table: "externalOnlineMenuAvailableTimeTerm" */
  externalOnlineMenuAvailableTimeTerm: Array<ExternalOnlineMenuAvailableTimeTerm>;
  /** fetch data from the table: "externalOnlineMenuAvailableTimeTerm" using primary key columns */
  externalOnlineMenuAvailableTimeTerm_by_pk?: Maybe<ExternalOnlineMenuAvailableTimeTerm>;
  /** fetch data from the table in a streaming manner: "externalOnlineMenuAvailableTimeTerm" */
  externalOnlineMenuAvailableTimeTerm_stream: Array<ExternalOnlineMenuAvailableTimeTerm>;
  /** fetch data from the table: "externalOnlineMenuConfig" */
  externalOnlineMenuConfig: Array<ExternalOnlineMenuConfig>;
  /** fetch data from the table: "externalOnlineMenuConfig" using primary key columns */
  externalOnlineMenuConfig_by_pk?: Maybe<ExternalOnlineMenuConfig>;
  /** fetch data from the table in a streaming manner: "externalOnlineMenuConfig" */
  externalOnlineMenuConfig_stream: Array<ExternalOnlineMenuConfig>;
  /** fetch data from the table: "externalOnlineMenu" using primary key columns */
  externalOnlineMenu_by_pk?: Maybe<ExternalOnlineMenu>;
  /** fetch data from the table in a streaming manner: "externalOnlineMenu" */
  externalOnlineMenu_stream: Array<ExternalOnlineMenu>;
  /** fetch data from the table: "featureFlag" */
  featureFlag: Array<FeatureFlag>;
  /** fetch data from the table: "featureFlagShop" */
  featureFlagShop: Array<FeatureFlagShop>;
  /** fetch data from the table: "featureFlagShop" using primary key columns */
  featureFlagShop_by_pk?: Maybe<FeatureFlagShop>;
  /** fetch data from the table in a streaming manner: "featureFlagShop" */
  featureFlagShop_stream: Array<FeatureFlagShop>;
  /** fetch data from the table: "featureFlag" using primary key columns */
  featureFlag_by_pk?: Maybe<FeatureFlag>;
  /** fetch data from the table in a streaming manner: "featureFlag" */
  featureFlag_stream: Array<FeatureFlag>;
  /** fetch data from the table: "foodingJournalConfig" */
  foodingJournalConfig: Array<FoodingJournalConfig>;
  /** fetch data from the table: "foodingJournalConfig" using primary key columns */
  foodingJournalConfig_by_pk?: Maybe<FoodingJournalConfig>;
  /** fetch data from the table in a streaming manner: "foodingJournalConfig" */
  foodingJournalConfig_stream: Array<FoodingJournalConfig>;
  /** fetch data from the table: "foodingJournalDepartmentMaster" */
  foodingJournalDepartmentMaster: Array<FoodingJournalDepartmentMaster>;
  /** fetch data from the table: "foodingJournalDepartmentMaster" using primary key columns */
  foodingJournalDepartmentMaster_by_pk?: Maybe<FoodingJournalDepartmentMaster>;
  /** fetch data from the table in a streaming manner: "foodingJournalDepartmentMaster" */
  foodingJournalDepartmentMaster_stream: Array<FoodingJournalDepartmentMaster>;
  /** fetch data from the table: "foodingJournalGroupMaster" */
  foodingJournalGroupMaster: Array<FoodingJournalGroupMaster>;
  /** fetch data from the table: "foodingJournalGroupMaster" using primary key columns */
  foodingJournalGroupMaster_by_pk?: Maybe<FoodingJournalGroupMaster>;
  /** fetch data from the table in a streaming manner: "foodingJournalGroupMaster" */
  foodingJournalGroupMaster_stream: Array<FoodingJournalGroupMaster>;
  /** fetch data from the table: "foodingJournalMenu" */
  foodingJournalMenu: Array<FoodingJournalMenu>;
  /** fetch data from the table: "foodingJournalMenu" using primary key columns */
  foodingJournalMenu_by_pk?: Maybe<FoodingJournalMenu>;
  /** fetch data from the table in a streaming manner: "foodingJournalMenu" */
  foodingJournalMenu_stream: Array<FoodingJournalMenu>;
  /** fetch data from the table: "foodingJournalOnSitePaymentDetailType" */
  foodingJournalOnSitePaymentDetailType: Array<FoodingJournalOnSitePaymentDetailType>;
  /** fetch data from the table: "foodingJournalOnSitePaymentDetailType" using primary key columns */
  foodingJournalOnSitePaymentDetailType_by_pk?: Maybe<FoodingJournalOnSitePaymentDetailType>;
  /** fetch data from the table in a streaming manner: "foodingJournalOnSitePaymentDetailType" */
  foodingJournalOnSitePaymentDetailType_stream: Array<FoodingJournalOnSitePaymentDetailType>;
  /** fetch data from the table: "foodingJournalOnSitePaymentDiscountType" */
  foodingJournalOnSitePaymentDiscountType: Array<FoodingJournalOnSitePaymentDiscountType>;
  /** fetch data from the table: "foodingJournalOnSitePaymentDiscountType" using primary key columns */
  foodingJournalOnSitePaymentDiscountType_by_pk?: Maybe<FoodingJournalOnSitePaymentDiscountType>;
  /** fetch data from the table in a streaming manner: "foodingJournalOnSitePaymentDiscountType" */
  foodingJournalOnSitePaymentDiscountType_stream: Array<FoodingJournalOnSitePaymentDiscountType>;
  /** fetch data from the table: "giftMeta" */
  giftMeta: Array<GiftMeta>;
  /** fetch aggregated fields from the table: "giftMeta" */
  giftMeta_aggregate: GiftMetaAggregate;
  /** fetch data from the table: "giftMeta" using primary key columns */
  giftMeta_by_pk?: Maybe<GiftMeta>;
  /** fetch data from the table in a streaming manner: "giftMeta" */
  giftMeta_stream: Array<GiftMeta>;
  /** An array relationship */
  giftOptionMeta: Array<GiftOptionMeta>;
  /** fetch data from the table: "giftOptionMeta" using primary key columns */
  giftOptionMeta_by_pk?: Maybe<GiftOptionMeta>;
  /** fetch data from the table in a streaming manner: "giftOptionMeta" */
  giftOptionMeta_stream: Array<GiftOptionMeta>;
  /** fetch data from the table: "gmoBankAccount" */
  gmoBankAccount: Array<GmoBankAccount>;
  /** fetch data from the table: "gmoBankAccountShop" */
  gmoBankAccountShop: Array<GmoBankAccountShop>;
  /** fetch data from the table: "gmoBankAccountShop" using primary key columns */
  gmoBankAccountShop_by_pk?: Maybe<GmoBankAccountShop>;
  /** fetch data from the table in a streaming manner: "gmoBankAccountShop" */
  gmoBankAccountShop_stream: Array<GmoBankAccountShop>;
  /** fetch data from the table: "gmoBankAccount" using primary key columns */
  gmoBankAccount_by_pk?: Maybe<GmoBankAccount>;
  /** fetch data from the table in a streaming manner: "gmoBankAccount" */
  gmoBankAccount_stream: Array<GmoBankAccount>;
  /** fetch data from the table: "gmoOffPaymentMethodType" */
  gmoOffPaymentMethodType: Array<GmoOffPaymentMethodType>;
  /** fetch data from the table: "gmoOffPaymentMethodType" using primary key columns */
  gmoOffPaymentMethodType_by_pk?: Maybe<GmoOffPaymentMethodType>;
  /** fetch data from the table in a streaming manner: "gmoOffPaymentMethodType" */
  gmoOffPaymentMethodType_stream: Array<GmoOffPaymentMethodType>;
  /** fetch data from the table: "gmoOffPaymentOnSitePaymentDetailType" */
  gmoOffPaymentOnSitePaymentDetailType: Array<GmoOffPaymentOnSitePaymentDetailType>;
  /** fetch data from the table: "gmoOffPaymentOnSitePaymentDetailType" using primary key columns */
  gmoOffPaymentOnSitePaymentDetailType_by_pk?: Maybe<GmoOffPaymentOnSitePaymentDetailType>;
  /** fetch data from the table in a streaming manner: "gmoOffPaymentOnSitePaymentDetailType" */
  gmoOffPaymentOnSitePaymentDetailType_stream: Array<GmoOffPaymentOnSitePaymentDetailType>;
  /** fetch data from the table: "gmoOffPaymentShopConfig" */
  gmoOffPaymentShopConfig: Array<GmoOffPaymentShopConfig>;
  /** fetch data from the table: "gmoOffPaymentShopConfigTransactionFeeRate" */
  gmoOffPaymentShopConfigTransactionFeeRate: Array<GmoOffPaymentShopConfigTransactionFeeRate>;
  /** fetch data from the table: "gmoOffPaymentShopConfigTransactionFeeRate" using primary key columns */
  gmoOffPaymentShopConfigTransactionFeeRate_by_pk?: Maybe<GmoOffPaymentShopConfigTransactionFeeRate>;
  /** fetch data from the table in a streaming manner: "gmoOffPaymentShopConfigTransactionFeeRate" */
  gmoOffPaymentShopConfigTransactionFeeRate_stream: Array<GmoOffPaymentShopConfigTransactionFeeRate>;
  /** fetch data from the table: "gmoOffPaymentShopConfig" using primary key columns */
  gmoOffPaymentShopConfig_by_pk?: Maybe<GmoOffPaymentShopConfig>;
  /** fetch data from the table in a streaming manner: "gmoOffPaymentShopConfig" */
  gmoOffPaymentShopConfig_stream: Array<GmoOffPaymentShopConfig>;
  /** fetch data from the table: "inflowSourceTag" */
  inflowSourceTag: Array<InflowSourceTag>;
  /** fetch aggregated fields from the table: "inflowSourceTag" */
  inflowSourceTag_aggregate: InflowSourceTagAggregate;
  /** fetch data from the table: "inflowSourceTag" using primary key columns */
  inflowSourceTag_by_pk?: Maybe<InflowSourceTag>;
  /** fetch data from the table in a streaming manner: "inflowSourceTag" */
  inflowSourceTag_stream: Array<InflowSourceTag>;
  /** fetch data from the table: "itemSales" */
  itemSales: Array<ItemSales>;
  /** fetch data from the table: "itemSales" using primary key columns */
  itemSales_by_pk?: Maybe<ItemSales>;
  /** fetch data from the table in a streaming manner: "itemSales" */
  itemSales_stream: Array<ItemSales>;
  /** fetch data from the table: "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
  kdDisplayItemKdDisplayTargetsKdDisplayTarget: Array<KdDisplayItemKdDisplayTargetsKdDisplayTarget>;
  /** fetch data from the table: "kdDisplayItemKdDisplayTargetsKdDisplayTarget" using primary key columns */
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_by_pk?: Maybe<KdDisplayItemKdDisplayTargetsKdDisplayTarget>;
  /** fetch data from the table in a streaming manner: "kdDisplayItemKdDisplayTargetsKdDisplayTarget" */
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_stream: Array<KdDisplayItemKdDisplayTargetsKdDisplayTarget>;
  /** fetch data from the table: "kdDisplayTarget" */
  kdDisplayTarget: Array<KdDisplayTarget>;
  /** fetch data from the table: "kdDisplayTarget" using primary key columns */
  kdDisplayTarget_by_pk?: Maybe<KdDisplayTarget>;
  /** fetch data from the table in a streaming manner: "kdDisplayTarget" */
  kdDisplayTarget_stream: Array<KdDisplayTarget>;
  /** fetch data from the table: "lineChannelConfig" */
  lineChannelConfig: Array<LineChannelConfig>;
  /** fetch data from the table: "lineChannelConfig" using primary key columns */
  lineChannelConfig_by_pk?: Maybe<LineChannelConfig>;
  /** fetch data from the table in a streaming manner: "lineChannelConfig" */
  lineChannelConfig_stream: Array<LineChannelConfig>;
  /** fetch data from the table: "lineOfficialAccount" */
  lineOfficialAccount: Array<LineOfficialAccount>;
  /** fetch data from the table: "lineOfficialAccount" using primary key columns */
  lineOfficialAccount_by_pk?: Maybe<LineOfficialAccount>;
  /** fetch data from the table in a streaming manner: "lineOfficialAccount" */
  lineOfficialAccount_stream: Array<LineOfficialAccount>;
  /** fetch data from the table: "lineProfile" */
  lineProfile: Array<LineProfile>;
  /** fetch data from the table in a streaming manner: "lineProfile" */
  lineProfile_stream: Array<LineProfile>;
  /** fetch data from the table: "lineReportingBotConfig" */
  lineReportingBotConfig: Array<LineReportingBotConfig>;
  /** fetch data from the table: "lineReportingBotConfigShop" */
  lineReportingBotConfigShop: Array<LineReportingBotConfigShop>;
  /** fetch data from the table: "lineReportingBotConfigShop" using primary key columns */
  lineReportingBotConfigShop_by_pk?: Maybe<LineReportingBotConfigShop>;
  /** fetch data from the table in a streaming manner: "lineReportingBotConfigShop" */
  lineReportingBotConfigShop_stream: Array<LineReportingBotConfigShop>;
  /** fetch data from the table: "lineReportingBotConfig" using primary key columns */
  lineReportingBotConfig_by_pk?: Maybe<LineReportingBotConfig>;
  /** fetch data from the table in a streaming manner: "lineReportingBotConfig" */
  lineReportingBotConfig_stream: Array<LineReportingBotConfig>;
  /** fetch data from the table: "lineReportingBotVerification" */
  lineReportingBotVerification: Array<LineReportingBotVerification>;
  /** fetch data from the table: "lineReportingBotVerification" using primary key columns */
  lineReportingBotVerification_by_pk?: Maybe<LineReportingBotVerification>;
  /** fetch data from the table in a streaming manner: "lineReportingBotVerification" */
  lineReportingBotVerification_stream: Array<LineReportingBotVerification>;
  /** fetch data from the table: "membershipCard" */
  membershipCard: Array<MembershipCard>;
  /** fetch data from the table: "membershipCardAppearanceType" */
  membershipCardAppearanceType: Array<MembershipCardAppearanceType>;
  /** fetch data from the table: "membershipCardAppearanceType" using primary key columns */
  membershipCardAppearanceType_by_pk?: Maybe<MembershipCardAppearanceType>;
  /** fetch data from the table in a streaming manner: "membershipCardAppearanceType" */
  membershipCardAppearanceType_stream: Array<MembershipCardAppearanceType>;
  /** fetch data from the table: "membershipCardTitle" */
  membershipCardTitle: Array<MembershipCardTitle>;
  /** fetch data from the table: "membershipCardTitle" using primary key columns */
  membershipCardTitle_by_pk?: Maybe<MembershipCardTitle>;
  /** fetch data from the table in a streaming manner: "membershipCardTitle" */
  membershipCardTitle_stream: Array<MembershipCardTitle>;
  /** fetch data from the table: "membershipCard" using primary key columns */
  membershipCard_by_pk?: Maybe<MembershipCard>;
  /** fetch data from the table in a streaming manner: "membershipCard" */
  membershipCard_stream: Array<MembershipCard>;
  /** fetch data from the table: "membershipRank" */
  membershipRank: Array<MembershipRank>;
  /** fetch data from the table: "membershipRankConfig" */
  membershipRankConfig: Array<MembershipRankConfig>;
  /** fetch data from the table: "membershipRankConfig" using primary key columns */
  membershipRankConfig_by_pk?: Maybe<MembershipRankConfig>;
  /** fetch data from the table in a streaming manner: "membershipRankConfig" */
  membershipRankConfig_stream: Array<MembershipRankConfig>;
  /** fetch data from the table: "membershipRank" using primary key columns */
  membershipRank_by_pk?: Maybe<MembershipRank>;
  /** fetch data from the table in a streaming manner: "membershipRank" */
  membershipRank_stream: Array<MembershipRank>;
  /** fetch data from the table: "menu" */
  menu: Array<Menu>;
  /** fetch data from the table: "menuOption" */
  menuOption: Array<MenuOption>;
  /** fetch data from the table: "menuOption" using primary key columns */
  menuOption_by_pk?: Maybe<MenuOption>;
  /** fetch data from the table in a streaming manner: "menuOption" */
  menuOption_stream: Array<MenuOption>;
  /** fetch data from the table: "menuOrderItem" */
  menuOrderItem: Array<MenuOrderItem>;
  /** fetch data from the table: "menuOrderItemChoice" */
  menuOrderItemChoice: Array<MenuOrderItemChoice>;
  /** fetch data from the table: "menuOrderItemChoice" using primary key columns */
  menuOrderItemChoice_by_pk?: Maybe<MenuOrderItemChoice>;
  /** fetch data from the table in a streaming manner: "menuOrderItemChoice" */
  menuOrderItemChoice_stream: Array<MenuOrderItemChoice>;
  /** fetch data from the table: "menuOrderItemOption" */
  menuOrderItemOption: Array<MenuOrderItemOption>;
  /** fetch data from the table: "menuOrderItemOption" using primary key columns */
  menuOrderItemOption_by_pk?: Maybe<MenuOrderItemOption>;
  /** fetch data from the table in a streaming manner: "menuOrderItemOption" */
  menuOrderItemOption_stream: Array<MenuOrderItemOption>;
  /** fetch data from the table: "menuOrderItem" using primary key columns */
  menuOrderItem_by_pk?: Maybe<MenuOrderItem>;
  /** fetch data from the table in a streaming manner: "menuOrderItem" */
  menuOrderItem_stream: Array<MenuOrderItem>;
  /** fetch data from the table: "menuRecommendationMeta" */
  menuRecommendationMeta: Array<MenuRecommendationMeta>;
  /** fetch data from the table: "menuRecommendationMeta" using primary key columns */
  menuRecommendationMeta_by_pk?: Maybe<MenuRecommendationMeta>;
  /** fetch data from the table in a streaming manner: "menuRecommendationMeta" */
  menuRecommendationMeta_stream: Array<MenuRecommendationMeta>;
  /** fetch data from the table: "menu" using primary key columns */
  menu_by_pk?: Maybe<Menu>;
  /** fetch data from the table in a streaming manner: "menu" */
  menu_stream: Array<Menu>;
  /** fetch data from the table: "messageDelivery" */
  messageDelivery: Array<MessageDelivery>;
  /** fetch data from the table: "messageDeliveryCustomerList" */
  messageDeliveryCustomerList: Array<MessageDeliveryCustomerList>;
  /** fetch data from the table: "messageDeliveryCustomerListCustomer" */
  messageDeliveryCustomerListCustomer: Array<MessageDeliveryCustomerListCustomer>;
  /** fetch aggregated fields from the table: "messageDeliveryCustomerListCustomer" */
  messageDeliveryCustomerListCustomer_aggregate: MessageDeliveryCustomerListCustomerAggregate;
  /** fetch data from the table: "messageDeliveryCustomerListCustomer" using primary key columns */
  messageDeliveryCustomerListCustomer_by_pk?: Maybe<MessageDeliveryCustomerListCustomer>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerListCustomer" */
  messageDeliveryCustomerListCustomer_stream: Array<MessageDeliveryCustomerListCustomer>;
  /** fetch data from the table: "messageDeliveryCustomerList" using primary key columns */
  messageDeliveryCustomerList_by_pk?: Maybe<MessageDeliveryCustomerList>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerList" */
  messageDeliveryCustomerList_stream: Array<MessageDeliveryCustomerList>;
  /** fetch data from the table: "messageDeliveryCustomerSegment" */
  messageDeliveryCustomerSegment: Array<MessageDeliveryCustomerSegment>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentBusinessOperationHoursCondition" */
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition: Array<MessageDeliveryCustomerSegmentBusinessOperationHoursCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentBusinessOperationHoursCondition" using primary key columns */
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentBusinessOperationHoursCondition>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentBusinessOperationHoursCondition" */
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_stream: Array<MessageDeliveryCustomerSegmentBusinessOperationHoursCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentDayOfWeekCondition" */
  messageDeliveryCustomerSegmentDayOfWeekCondition: Array<MessageDeliveryCustomerSegmentDayOfWeekCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentDayOfWeekCondition" using primary key columns */
  messageDeliveryCustomerSegmentDayOfWeekCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentDayOfWeekCondition>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentDayOfWeekCondition" */
  messageDeliveryCustomerSegmentDayOfWeekCondition_stream: Array<MessageDeliveryCustomerSegmentDayOfWeekCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition" */
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition: Array<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition" using primary key columns */
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition" */
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_stream: Array<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentGender" */
  messageDeliveryCustomerSegmentGender: Array<MessageDeliveryCustomerSegmentGender>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentGenderCondition" */
  messageDeliveryCustomerSegmentGenderCondition: Array<MessageDeliveryCustomerSegmentGenderCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentGenderCondition" using primary key columns */
  messageDeliveryCustomerSegmentGenderCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentGenderCondition>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentGenderCondition" */
  messageDeliveryCustomerSegmentGenderCondition_stream: Array<MessageDeliveryCustomerSegmentGenderCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentGender" using primary key columns */
  messageDeliveryCustomerSegmentGender_by_pk?: Maybe<MessageDeliveryCustomerSegmentGender>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentGender" */
  messageDeliveryCustomerSegmentGender_stream: Array<MessageDeliveryCustomerSegmentGender>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentMenuCondition" */
  messageDeliveryCustomerSegmentMenuCondition: Array<MessageDeliveryCustomerSegmentMenuCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentMenuCondition" using primary key columns */
  messageDeliveryCustomerSegmentMenuCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentMenuCondition>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentMenuCondition" */
  messageDeliveryCustomerSegmentMenuCondition_stream: Array<MessageDeliveryCustomerSegmentMenuCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentNumericCondition" */
  messageDeliveryCustomerSegmentNumericCondition: Array<MessageDeliveryCustomerSegmentNumericCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentNumericCondition" using primary key columns */
  messageDeliveryCustomerSegmentNumericCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentNumericCondition>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentNumericCondition" */
  messageDeliveryCustomerSegmentNumericCondition_stream: Array<MessageDeliveryCustomerSegmentNumericCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition" */
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition: Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition" using primary key columns */
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition" */
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_stream: Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType" */
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType: Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusType>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType" using primary key columns */
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_by_pk?: Maybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusType>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType" */
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_stream: Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusType>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentShopCondition" */
  messageDeliveryCustomerSegmentShopCondition: Array<MessageDeliveryCustomerSegmentShopCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentShopCondition" using primary key columns */
  messageDeliveryCustomerSegmentShopCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentShopCondition>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentShopCondition" */
  messageDeliveryCustomerSegmentShopCondition_stream: Array<MessageDeliveryCustomerSegmentShopCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentUnvisitedDayCountCondition" */
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition: Array<MessageDeliveryCustomerSegmentUnvisitedDayCountCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentUnvisitedDayCountCondition" using primary key columns */
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentUnvisitedDayCountCondition>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentUnvisitedDayCountCondition" */
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_stream: Array<MessageDeliveryCustomerSegmentUnvisitedDayCountCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentVisitedDayCountCondition" */
  messageDeliveryCustomerSegmentVisitedDayCountCondition: Array<MessageDeliveryCustomerSegmentVisitedDayCountCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegmentVisitedDayCountCondition" using primary key columns */
  messageDeliveryCustomerSegmentVisitedDayCountCondition_by_pk?: Maybe<MessageDeliveryCustomerSegmentVisitedDayCountCondition>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegmentVisitedDayCountCondition" */
  messageDeliveryCustomerSegmentVisitedDayCountCondition_stream: Array<MessageDeliveryCustomerSegmentVisitedDayCountCondition>;
  /** fetch data from the table: "messageDeliveryCustomerSegment" using primary key columns */
  messageDeliveryCustomerSegment_by_pk?: Maybe<MessageDeliveryCustomerSegment>;
  /** fetch data from the table in a streaming manner: "messageDeliveryCustomerSegment" */
  messageDeliveryCustomerSegment_stream: Array<MessageDeliveryCustomerSegment>;
  /** fetch data from the table: "messageDeliveryJob" */
  messageDeliveryJob: Array<MessageDeliveryJob>;
  /** fetch data from the table: "messageDeliveryJob" using primary key columns */
  messageDeliveryJob_by_pk?: Maybe<MessageDeliveryJob>;
  /** fetch data from the table in a streaming manner: "messageDeliveryJob" */
  messageDeliveryJob_stream: Array<MessageDeliveryJob>;
  /** fetch data from the table: "messageDeliveryMessage" */
  messageDeliveryMessage: Array<MessageDeliveryMessage>;
  /** fetch data from the table: "messageDeliveryMessageCouponTypeMeta" */
  messageDeliveryMessageCouponTypeMeta: Array<MessageDeliveryMessageCouponTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageCouponTypeMeta" using primary key columns */
  messageDeliveryMessageCouponTypeMeta_by_pk?: Maybe<MessageDeliveryMessageCouponTypeMeta>;
  /** fetch data from the table in a streaming manner: "messageDeliveryMessageCouponTypeMeta" */
  messageDeliveryMessageCouponTypeMeta_stream: Array<MessageDeliveryMessageCouponTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageImageTypeMeta" */
  messageDeliveryMessageImageTypeMeta: Array<MessageDeliveryMessageImageTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageImageTypeMeta" using primary key columns */
  messageDeliveryMessageImageTypeMeta_by_pk?: Maybe<MessageDeliveryMessageImageTypeMeta>;
  /** fetch data from the table in a streaming manner: "messageDeliveryMessageImageTypeMeta" */
  messageDeliveryMessageImageTypeMeta_stream: Array<MessageDeliveryMessageImageTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageQuestionnaireTypeMeta" */
  messageDeliveryMessageQuestionnaireTypeMeta: Array<MessageDeliveryMessageQuestionnaireTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageQuestionnaireTypeMeta" using primary key columns */
  messageDeliveryMessageQuestionnaireTypeMeta_by_pk?: Maybe<MessageDeliveryMessageQuestionnaireTypeMeta>;
  /** fetch data from the table in a streaming manner: "messageDeliveryMessageQuestionnaireTypeMeta" */
  messageDeliveryMessageQuestionnaireTypeMeta_stream: Array<MessageDeliveryMessageQuestionnaireTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageTextTypeMeta" */
  messageDeliveryMessageTextTypeMeta: Array<MessageDeliveryMessageTextTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessageTextTypeMeta" using primary key columns */
  messageDeliveryMessageTextTypeMeta_by_pk?: Maybe<MessageDeliveryMessageTextTypeMeta>;
  /** fetch data from the table in a streaming manner: "messageDeliveryMessageTextTypeMeta" */
  messageDeliveryMessageTextTypeMeta_stream: Array<MessageDeliveryMessageTextTypeMeta>;
  /** fetch data from the table: "messageDeliveryMessage" using primary key columns */
  messageDeliveryMessage_by_pk?: Maybe<MessageDeliveryMessage>;
  /** fetch data from the table in a streaming manner: "messageDeliveryMessage" */
  messageDeliveryMessage_stream: Array<MessageDeliveryMessage>;
  /** fetch data from the table: "messageDeliveryShopJob" */
  messageDeliveryShopJob: Array<MessageDeliveryShopJob>;
  /** fetch data from the table: "messageDeliveryShopJob" using primary key columns */
  messageDeliveryShopJob_by_pk?: Maybe<MessageDeliveryShopJob>;
  /** fetch data from the table in a streaming manner: "messageDeliveryShopJob" */
  messageDeliveryShopJob_stream: Array<MessageDeliveryShopJob>;
  /** fetch data from the table: "messageDelivery" using primary key columns */
  messageDelivery_by_pk?: Maybe<MessageDelivery>;
  /** fetch data from the table in a streaming manner: "messageDelivery" */
  messageDelivery_stream: Array<MessageDelivery>;
  /** fetch data from the table: "monthlySalesBudget" */
  monthlySalesBudget: Array<MonthlySalesBudget>;
  /** fetch data from the table: "monthlySalesBudget" using primary key columns */
  monthlySalesBudget_by_pk?: Maybe<MonthlySalesBudget>;
  /** fetch data from the table in a streaming manner: "monthlySalesBudget" */
  monthlySalesBudget_stream: Array<MonthlySalesBudget>;
  /** fetch data from the table: "multipleChoiceTypeQuestionAnswer" */
  multipleChoiceTypeQuestionAnswer: Array<MultipleChoiceTypeQuestionAnswer>;
  /** fetch data from the table: "multipleChoiceTypeQuestionAnswer" using primary key columns */
  multipleChoiceTypeQuestionAnswer_by_pk?: Maybe<MultipleChoiceTypeQuestionAnswer>;
  /** fetch data from the table in a streaming manner: "multipleChoiceTypeQuestionAnswer" */
  multipleChoiceTypeQuestionAnswer_stream: Array<MultipleChoiceTypeQuestionAnswer>;
  /** fetch data from the table: "multipleChoiceTypeQuestionChoice" */
  multipleChoiceTypeQuestionChoice: Array<MultipleChoiceTypeQuestionChoice>;
  /** fetch data from the table: "multipleChoiceTypeQuestionChoiceAnswer" */
  multipleChoiceTypeQuestionChoiceAnswer: Array<MultipleChoiceTypeQuestionChoiceAnswer>;
  /** fetch data from the table: "multipleChoiceTypeQuestionChoiceAnswer" using primary key columns */
  multipleChoiceTypeQuestionChoiceAnswer_by_pk?: Maybe<MultipleChoiceTypeQuestionChoiceAnswer>;
  /** fetch data from the table in a streaming manner: "multipleChoiceTypeQuestionChoiceAnswer" */
  multipleChoiceTypeQuestionChoiceAnswer_stream: Array<MultipleChoiceTypeQuestionChoiceAnswer>;
  /** fetch data from the table in a streaming manner: "multipleChoiceTypeQuestionChoice" */
  multipleChoiceTypeQuestionChoice_stream: Array<MultipleChoiceTypeQuestionChoice>;
  /** fetch data from the table: "multipleChoiceTypeQuestionMeta" */
  multipleChoiceTypeQuestionMeta: Array<MultipleChoiceTypeQuestionMeta>;
  /** fetch data from the table: "multipleChoiceTypeQuestionMeta" using primary key columns */
  multipleChoiceTypeQuestionMeta_by_pk?: Maybe<MultipleChoiceTypeQuestionMeta>;
  /** fetch data from the table in a streaming manner: "multipleChoiceTypeQuestionMeta" */
  multipleChoiceTypeQuestionMeta_stream: Array<MultipleChoiceTypeQuestionMeta>;
  /** fetch data from the table: "onSitePayment" */
  onSitePayment: Array<OnSitePayment>;
  /** fetch data from the table: "onSitePaymentDetail" */
  onSitePaymentDetail: Array<OnSitePaymentDetail>;
  /** fetch data from the table: "onSitePaymentDetailType" */
  onSitePaymentDetailType: Array<OnSitePaymentDetailType>;
  /** fetch data from the table: "onSitePaymentDetailTypeCategoryPriority" */
  onSitePaymentDetailTypeCategoryPriority: Array<OnSitePaymentDetailTypeCategoryPriority>;
  /** fetch data from the table: "onSitePaymentDetailTypeCategoryPriority" using primary key columns */
  onSitePaymentDetailTypeCategoryPriority_by_pk?: Maybe<OnSitePaymentDetailTypeCategoryPriority>;
  /** fetch data from the table in a streaming manner: "onSitePaymentDetailTypeCategoryPriority" */
  onSitePaymentDetailTypeCategoryPriority_stream: Array<OnSitePaymentDetailTypeCategoryPriority>;
  /** fetch aggregated fields from the table: "onSitePaymentDetailType" */
  onSitePaymentDetailType_aggregate: OnSitePaymentDetailTypeAggregate;
  /** fetch data from the table: "onSitePaymentDetailType" using primary key columns */
  onSitePaymentDetailType_by_pk?: Maybe<OnSitePaymentDetailType>;
  /** fetch data from the table in a streaming manner: "onSitePaymentDetailType" */
  onSitePaymentDetailType_stream: Array<OnSitePaymentDetailType>;
  /** fetch data from the table: "onSitePaymentDetail" using primary key columns */
  onSitePaymentDetail_by_pk?: Maybe<OnSitePaymentDetail>;
  /** fetch data from the table in a streaming manner: "onSitePaymentDetail" */
  onSitePaymentDetail_stream: Array<OnSitePaymentDetail>;
  /** fetch data from the table: "onSitePaymentDiscount" */
  onSitePaymentDiscount: Array<OnSitePaymentDiscount>;
  /** fetch data from the table: "onSitePaymentDiscountType" */
  onSitePaymentDiscountType: Array<OnSitePaymentDiscountType>;
  /** fetch aggregated fields from the table: "onSitePaymentDiscountType" */
  onSitePaymentDiscountType_aggregate: OnSitePaymentDiscountTypeAggregate;
  /** fetch data from the table: "onSitePaymentDiscountType" using primary key columns */
  onSitePaymentDiscountType_by_pk?: Maybe<OnSitePaymentDiscountType>;
  /** fetch data from the table in a streaming manner: "onSitePaymentDiscountType" */
  onSitePaymentDiscountType_stream: Array<OnSitePaymentDiscountType>;
  /** fetch data from the table: "onSitePaymentDiscount" using primary key columns */
  onSitePaymentDiscount_by_pk?: Maybe<OnSitePaymentDiscount>;
  /** fetch data from the table in a streaming manner: "onSitePaymentDiscount" */
  onSitePaymentDiscount_stream: Array<OnSitePaymentDiscount>;
  /** fetch data from the table: "onSitePaymentSales" */
  onSitePaymentSales: Array<OnSitePaymentSales>;
  /** fetch data from the table: "onSitePaymentSales" using primary key columns */
  onSitePaymentSales_by_pk?: Maybe<OnSitePaymentSales>;
  /** fetch data from the table in a streaming manner: "onSitePaymentSales" */
  onSitePaymentSales_stream: Array<OnSitePaymentSales>;
  /** An array relationship */
  onSitePaymentTableUsers: Array<OnSitePaymentTableUsers>;
  /** fetch data from the table in a streaming manner: "onSitePaymentTableUsers" */
  onSitePaymentTableUsers_stream: Array<OnSitePaymentTableUsers>;
  /** fetch data from the table: "onSitePayment" using primary key columns */
  onSitePayment_by_pk?: Maybe<OnSitePayment>;
  /** fetch data from the table in a streaming manner: "onSitePayment" */
  onSitePayment_stream: Array<OnSitePayment>;
  /** fetch data from the table: "onlinePaymentPrinterRoleMeta" */
  onlinePaymentPrinterRoleMeta: Array<OnlinePaymentPrinterRoleMeta>;
  /** fetch data from the table: "onlinePaymentPrinterRoleMeta" using primary key columns */
  onlinePaymentPrinterRoleMeta_by_pk?: Maybe<OnlinePaymentPrinterRoleMeta>;
  /** fetch data from the table in a streaming manner: "onlinePaymentPrinterRoleMeta" */
  onlinePaymentPrinterRoleMeta_stream: Array<OnlinePaymentPrinterRoleMeta>;
  /** fetch data from the table: "option" */
  option: Array<Option>;
  /** fetch data from the table: "optionInputType" */
  optionInputType: Array<OptionInputType>;
  /** fetch data from the table: "optionInputType" using primary key columns */
  optionInputType_by_pk?: Maybe<OptionInputType>;
  /** fetch data from the table in a streaming manner: "optionInputType" */
  optionInputType_stream: Array<OptionInputType>;
  /** fetch aggregated fields from the table: "option" */
  option_aggregate: OptionAggregate;
  /** fetch data from the table: "option" using primary key columns */
  option_by_pk?: Maybe<Option>;
  /** fetch data from the table in a streaming manner: "option" */
  option_stream: Array<Option>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table in a streaming manner: "order" */
  order_stream: Array<Order>;
  /** fetch data from the table: "orderableTime" */
  orderableTime: Array<OrderableTime>;
  /** fetch data from the table: "orderableTime" using primary key columns */
  orderableTime_by_pk?: Maybe<OrderableTime>;
  /** fetch data from the table in a streaming manner: "orderableTime" */
  orderableTime_stream: Array<OrderableTime>;
  /** fetch data from the table: "payingActivePlanChoice" */
  payingActivePlanChoice: Array<PayingActivePlanChoice>;
  /** fetch data from the table: "payingActivePlanChoice" using primary key columns */
  payingActivePlanChoice_by_pk?: Maybe<PayingActivePlanChoice>;
  /** fetch data from the table in a streaming manner: "payingActivePlanChoice" */
  payingActivePlanChoice_stream: Array<PayingActivePlanChoice>;
  /** An array relationship */
  payingActivePlanOpenPriceMeta: Array<PayingActivePlanOpenPriceMeta>;
  /** fetch data from the table: "payingActivePlanOpenPriceMeta" using primary key columns */
  payingActivePlanOpenPriceMeta_by_pk?: Maybe<PayingActivePlanOpenPriceMeta>;
  /** fetch data from the table in a streaming manner: "payingActivePlanOpenPriceMeta" */
  payingActivePlanOpenPriceMeta_stream: Array<PayingActivePlanOpenPriceMeta>;
  /** fetch data from the table: "payingMenuOrderItem" */
  payingMenuOrderItem: Array<PayingMenuOrderItem>;
  /** fetch data from the table: "payingMenuOrderItem" using primary key columns */
  payingMenuOrderItem_by_pk?: Maybe<PayingMenuOrderItem>;
  /** fetch data from the table in a streaming manner: "payingMenuOrderItem" */
  payingMenuOrderItem_stream: Array<PayingMenuOrderItem>;
  /** fetch data from the table: "paymentReceiptRoleTable" */
  paymentReceiptRoleTable: Array<PaymentReceiptRoleTable>;
  /** fetch data from the table in a streaming manner: "paymentReceiptRoleTable" */
  paymentReceiptRoleTable_stream: Array<PaymentReceiptRoleTable>;
  /** fetch data from the table: "pikaichiConfig" */
  pikaichiConfig: Array<PikaichiConfig>;
  /** fetch data from the table: "pikaichiConfig" using primary key columns */
  pikaichiConfig_by_pk?: Maybe<PikaichiConfig>;
  /** fetch data from the table in a streaming manner: "pikaichiConfig" */
  pikaichiConfig_stream: Array<PikaichiConfig>;
  /** fetch data from the table: "pikaichiMenu" */
  pikaichiMenu: Array<PikaichiMenu>;
  /** fetch data from the table: "pikaichiMenuChoice" */
  pikaichiMenuChoice: Array<PikaichiMenuChoice>;
  /** fetch data from the table: "pikaichiMenuChoice" using primary key columns */
  pikaichiMenuChoice_by_pk?: Maybe<PikaichiMenuChoice>;
  /** fetch data from the table in a streaming manner: "pikaichiMenuChoice" */
  pikaichiMenuChoice_stream: Array<PikaichiMenuChoice>;
  /** fetch data from the table: "pikaichiMenuMenu" */
  pikaichiMenuMenu: Array<PikaichiMenuMenu>;
  /** fetch data from the table: "pikaichiMenuMenu" using primary key columns */
  pikaichiMenuMenu_by_pk?: Maybe<PikaichiMenuMenu>;
  /** fetch data from the table in a streaming manner: "pikaichiMenuMenu" */
  pikaichiMenuMenu_stream: Array<PikaichiMenuMenu>;
  /** fetch data from the table: "pikaichiMenuPlan" */
  pikaichiMenuPlan: Array<PikaichiMenuPlan>;
  /** fetch data from the table: "pikaichiMenuPlanChoice" */
  pikaichiMenuPlanChoice: Array<PikaichiMenuPlanChoice>;
  /** fetch data from the table: "pikaichiMenuPlanChoice" using primary key columns */
  pikaichiMenuPlanChoice_by_pk?: Maybe<PikaichiMenuPlanChoice>;
  /** fetch data from the table in a streaming manner: "pikaichiMenuPlanChoice" */
  pikaichiMenuPlanChoice_stream: Array<PikaichiMenuPlanChoice>;
  /** fetch data from the table: "pikaichiMenuPlan" using primary key columns */
  pikaichiMenuPlan_by_pk?: Maybe<PikaichiMenuPlan>;
  /** fetch data from the table in a streaming manner: "pikaichiMenuPlan" */
  pikaichiMenuPlan_stream: Array<PikaichiMenuPlan>;
  /** fetch data from the table: "pikaichiMenu" using primary key columns */
  pikaichiMenu_by_pk?: Maybe<PikaichiMenu>;
  /** fetch data from the table in a streaming manner: "pikaichiMenu" */
  pikaichiMenu_stream: Array<PikaichiMenu>;
  /** fetch data from the table: "pikaichiOnSitePaymentDetailType" */
  pikaichiOnSitePaymentDetailType: Array<PikaichiOnSitePaymentDetailType>;
  /** fetch data from the table: "pikaichiOnSitePaymentDetailType" using primary key columns */
  pikaichiOnSitePaymentDetailType_by_pk?: Maybe<PikaichiOnSitePaymentDetailType>;
  /** fetch data from the table in a streaming manner: "pikaichiOnSitePaymentDetailType" */
  pikaichiOnSitePaymentDetailType_stream: Array<PikaichiOnSitePaymentDetailType>;
  /** fetch data from the table: "pikaichiOnSitePaymentDiscountType" */
  pikaichiOnSitePaymentDiscountType: Array<PikaichiOnSitePaymentDiscountType>;
  /** fetch data from the table: "pikaichiOnSitePaymentDiscountType" using primary key columns */
  pikaichiOnSitePaymentDiscountType_by_pk?: Maybe<PikaichiOnSitePaymentDiscountType>;
  /** fetch data from the table in a streaming manner: "pikaichiOnSitePaymentDiscountType" */
  pikaichiOnSitePaymentDiscountType_stream: Array<PikaichiOnSitePaymentDiscountType>;
  /** fetch data from the table: "plan" */
  plan: Array<Plan>;
  /** fetch data from the table: "planChoice" */
  planChoice: Array<PlanChoice>;
  /** fetch aggregated fields from the table: "planChoice" */
  planChoice_aggregate: PlanChoiceAggregate;
  /** fetch data from the table: "planChoice" using primary key columns */
  planChoice_by_pk?: Maybe<PlanChoice>;
  /** fetch data from the table in a streaming manner: "planChoice" */
  planChoice_stream: Array<PlanChoice>;
  /** fetch data from the table: "planFirstOrderCategoryMenu" */
  planFirstOrderCategoryMenu: Array<PlanFirstOrderCategoryMenu>;
  /** fetch data from the table: "planFirstOrderCategoryMenu" using primary key columns */
  planFirstOrderCategoryMenu_by_pk?: Maybe<PlanFirstOrderCategoryMenu>;
  /** fetch data from the table in a streaming manner: "planFirstOrderCategoryMenu" */
  planFirstOrderCategoryMenu_stream: Array<PlanFirstOrderCategoryMenu>;
  /** fetch data from the table: "planGroup" */
  planGroup: Array<PlanGroup>;
  /** fetch data from the table: "planGroup" using primary key columns */
  planGroup_by_pk?: Maybe<PlanGroup>;
  /** fetch data from the table in a streaming manner: "planGroup" */
  planGroup_stream: Array<PlanGroup>;
  /** fetch data from the table: "planMenuCategory" */
  planMenuCategory: Array<PlanMenuCategory>;
  /** fetch data from the table: "planMenuCategory" using primary key columns */
  planMenuCategory_by_pk?: Maybe<PlanMenuCategory>;
  /** fetch data from the table in a streaming manner: "planMenuCategory" */
  planMenuCategory_stream: Array<PlanMenuCategory>;
  /** fetch data from the table: "planOption" */
  planOption: Array<PlanOption>;
  /** fetch aggregated fields from the table: "planOption" */
  planOption_aggregate: PlanOptionAggregate;
  /** fetch data from the table: "planOption" using primary key columns */
  planOption_by_pk?: Maybe<PlanOption>;
  /** fetch data from the table in a streaming manner: "planOption" */
  planOption_stream: Array<PlanOption>;
  /** fetch data from the table: "plan" using primary key columns */
  plan_by_pk?: Maybe<Plan>;
  /** fetch data from the table: "plan_menu_categories_category" */
  plan_menu_categories_category: Array<PlanMenuCategoriesCategory>;
  /** fetch data from the table in a streaming manner: "plan_menu_categories_category" */
  plan_menu_categories_category_stream: Array<PlanMenuCategoriesCategory>;
  /** fetch data from the table in a streaming manner: "plan" */
  plan_stream: Array<Plan>;
  /** fetch data from the table: "question" */
  question: Array<Question>;
  /** fetch data from the table: "questionAnswer" */
  questionAnswer: Array<QuestionAnswer>;
  /** fetch data from the table: "questionAnswer" using primary key columns */
  questionAnswer_by_pk?: Maybe<QuestionAnswer>;
  /** fetch data from the table in a streaming manner: "questionAnswer" */
  questionAnswer_stream: Array<QuestionAnswer>;
  /** fetch data from the table: "question" using primary key columns */
  question_by_pk?: Maybe<Question>;
  /** fetch data from the table in a streaming manner: "question" */
  question_stream: Array<Question>;
  /** fetch data from the table: "questionnaire" */
  questionnaire: Array<Questionnaire>;
  /** fetch data from the table: "questionnaireAnswer" */
  questionnaireAnswer: Array<QuestionnaireAnswer>;
  /** fetch data from the table: "questionnaireAnswer" using primary key columns */
  questionnaireAnswer_by_pk?: Maybe<QuestionnaireAnswer>;
  /** fetch data from the table in a streaming manner: "questionnaireAnswer" */
  questionnaireAnswer_stream: Array<QuestionnaireAnswer>;
  /** fetch data from the table: "questionnaireConfig" */
  questionnaireConfig: Array<QuestionnaireConfig>;
  /** fetch data from the table: "questionnaireConfig" using primary key columns */
  questionnaireConfig_by_pk?: Maybe<QuestionnaireConfig>;
  /** fetch data from the table in a streaming manner: "questionnaireConfig" */
  questionnaireConfig_stream: Array<QuestionnaireConfig>;
  /** fetch data from the table: "questionnaireQuestion" */
  questionnaireQuestion: Array<QuestionnaireQuestion>;
  /** fetch data from the table in a streaming manner: "questionnaireQuestion" */
  questionnaireQuestion_stream: Array<QuestionnaireQuestion>;
  /** fetch data from the table: "questionnaire" using primary key columns */
  questionnaire_by_pk?: Maybe<Questionnaire>;
  /** fetch data from the table in a streaming manner: "questionnaire" */
  questionnaire_stream: Array<Questionnaire>;
  /** fetch data from the table: "receipt" */
  receipt: Array<Receipt>;
  /** fetch data from the table: "receipt" using primary key columns */
  receipt_by_pk?: Maybe<Receipt>;
  /** fetch data from the table in a streaming manner: "receipt" */
  receipt_stream: Array<Receipt>;
  /** fetch data from the table: "reservationDashboardAccountAccessibleShop" */
  reservationDashboardAccountAccessibleShop: Array<ReservationDashboardAccountAccessibleShop>;
  /** fetch data from the table: "reservationDashboardAccountAccessibleShop" using primary key columns */
  reservationDashboardAccountAccessibleShop_by_pk?: Maybe<ReservationDashboardAccountAccessibleShop>;
  /** fetch data from the table in a streaming manner: "reservationDashboardAccountAccessibleShop" */
  reservationDashboardAccountAccessibleShop_stream: Array<ReservationDashboardAccountAccessibleShop>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch data from the table: "roleTablesTable" */
  roleTablesTable: Array<RoleTablesTable>;
  /** fetch data from the table in a streaming manner: "roleTablesTable" */
  roleTablesTable_stream: Array<RoleTablesTable>;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>;
  /** fetch data from the table: "salesBudgetAllocationSetting" */
  salesBudgetAllocationSetting: Array<SalesBudgetAllocationSetting>;
  /** fetch data from the table: "salesBudgetAllocationSetting" using primary key columns */
  salesBudgetAllocationSetting_by_pk?: Maybe<SalesBudgetAllocationSetting>;
  /** fetch data from the table in a streaming manner: "salesBudgetAllocationSetting" */
  salesBudgetAllocationSetting_stream: Array<SalesBudgetAllocationSetting>;
  /** fetch data from the table: "scoreTypeQuestionAnswer" */
  scoreTypeQuestionAnswer: Array<ScoreTypeQuestionAnswer>;
  /** fetch data from the table in a streaming manner: "scoreTypeQuestionAnswer" */
  scoreTypeQuestionAnswer_stream: Array<ScoreTypeQuestionAnswer>;
  /** fetch data from the table: "serviceAdmin" */
  serviceAdmin: Array<ServiceAdmin>;
  /** fetch data from the table: "serviceAdmin" using primary key columns */
  serviceAdmin_by_pk?: Maybe<ServiceAdmin>;
  /** fetch data from the table in a streaming manner: "serviceAdmin" */
  serviceAdmin_stream: Array<ServiceAdmin>;
  /** fetch data from the table: "serviceChargeConfig" */
  serviceChargeConfig: Array<ServiceChargeConfig>;
  /** fetch data from the table: "serviceChargeConfig" using primary key columns */
  serviceChargeConfig_by_pk?: Maybe<ServiceChargeConfig>;
  /** fetch data from the table in a streaming manner: "serviceChargeConfig" */
  serviceChargeConfig_stream: Array<ServiceChargeConfig>;
  /** fetch data from the table: "serviceChargeMeta" */
  serviceChargeMeta: Array<ServiceChargeMeta>;
  /** fetch data from the table: "serviceChargeMeta" using primary key columns */
  serviceChargeMeta_by_pk?: Maybe<ServiceChargeMeta>;
  /** fetch data from the table in a streaming manner: "serviceChargeMeta" */
  serviceChargeMeta_stream: Array<ServiceChargeMeta>;
  /** fetch data from the table: "shop" */
  shop: Array<Shop>;
  /** fetch data from the table: "shopBusinessOperationHour" */
  shopBusinessOperationHour: Array<ShopBusinessOperationHour>;
  /** fetch data from the table: "shopBusinessOperationHour" using primary key columns */
  shopBusinessOperationHour_by_pk?: Maybe<ShopBusinessOperationHour>;
  /** fetch data from the table in a streaming manner: "shopBusinessOperationHour" */
  shopBusinessOperationHour_stream: Array<ShopBusinessOperationHour>;
  /** fetch data from the table: "shopChoice" */
  shopChoice: Array<ShopChoice>;
  /** fetch data from the table: "shopChoice" using primary key columns */
  shopChoice_by_pk?: Maybe<ShopChoice>;
  /** fetch data from the table in a streaming manner: "shopChoice" */
  shopChoice_stream: Array<ShopChoice>;
  /** fetch data from the table: "shopClerk" */
  shopClerk: Array<ShopClerk>;
  /** fetch data from the table: "shopClerk" using primary key columns */
  shopClerk_by_pk?: Maybe<ShopClerk>;
  /** fetch data from the table in a streaming manner: "shopClerk" */
  shopClerk_stream: Array<ShopClerk>;
  /** fetch data from the table: "shopClient" */
  shopClient: Array<ShopClient>;
  /** fetch data from the table: "shopClient" using primary key columns */
  shopClient_by_pk?: Maybe<ShopClient>;
  /** fetch data from the table in a streaming manner: "shopClient" */
  shopClient_stream: Array<ShopClient>;
  /** fetch data from the table: "shopCookingItemRole" */
  shopCookingItemRole: Array<ShopCookingItemRole>;
  /** fetch data from the table: "shopCookingItemRole" using primary key columns */
  shopCookingItemRole_by_pk?: Maybe<ShopCookingItemRole>;
  /** fetch data from the table in a streaming manner: "shopCookingItemRole" */
  shopCookingItemRole_stream: Array<ShopCookingItemRole>;
  /** fetch data from the table: "shopDiscountType" */
  shopDiscountType: Array<ShopDiscountType>;
  /** fetch data from the table: "shopDiscountType" using primary key columns */
  shopDiscountType_by_pk?: Maybe<ShopDiscountType>;
  /** fetch data from the table in a streaming manner: "shopDiscountType" */
  shopDiscountType_stream: Array<ShopDiscountType>;
  /** fetch data from the table: "shopInflowSourceTag" */
  shopInflowSourceTag: Array<ShopInflowSourceTag>;
  /** fetch data from the table in a streaming manner: "shopInflowSourceTag" */
  shopInflowSourceTag_stream: Array<ShopInflowSourceTag>;
  /** fetch data from the table: "shopMember" */
  shopMember: Array<ShopMember>;
  /** fetch data from the table in a streaming manner: "shopMember" */
  shopMember_stream: Array<ShopMember>;
  /** fetch data from the table: "shopMenu" */
  shopMenu: Array<ShopMenu>;
  /** fetch data from the table: "shopMenuCookingItem" */
  shopMenuCookingItem: Array<ShopMenuCookingItem>;
  /** fetch data from the table in a streaming manner: "shopMenuCookingItem" */
  shopMenuCookingItem_stream: Array<ShopMenuCookingItem>;
  /** fetch data from the table: "shopMenuKdDisplayTarget" */
  shopMenuKdDisplayTarget: Array<ShopMenuKdDisplayTarget>;
  /** fetch data from the table: "shopMenuKdDisplayTarget" using primary key columns */
  shopMenuKdDisplayTarget_by_pk?: Maybe<ShopMenuKdDisplayTarget>;
  /** fetch data from the table in a streaming manner: "shopMenuKdDisplayTarget" */
  shopMenuKdDisplayTarget_stream: Array<ShopMenuKdDisplayTarget>;
  /** fetch data from the table: "shopMenuKitchenRole" */
  shopMenuKitchenRole: Array<ShopMenuKitchenRole>;
  /** fetch data from the table in a streaming manner: "shopMenuKitchenRole" */
  shopMenuKitchenRole_stream: Array<ShopMenuKitchenRole>;
  /** fetch data from the table: "shopMenu" using primary key columns */
  shopMenu_by_pk?: Maybe<ShopMenu>;
  /** fetch data from the table in a streaming manner: "shopMenu" */
  shopMenu_stream: Array<ShopMenu>;
  /** fetch data from the table: "shopOptionKitchenRole" */
  shopOptionKitchenRole: Array<ShopOptionKitchenRole>;
  /** fetch data from the table: "shopOptionKitchenRole" using primary key columns */
  shopOptionKitchenRole_by_pk?: Maybe<ShopOptionKitchenRole>;
  /** fetch data from the table in a streaming manner: "shopOptionKitchenRole" */
  shopOptionKitchenRole_stream: Array<ShopOptionKitchenRole>;
  /** fetch data from the table: "shopOrderableTimeTerm" */
  shopOrderableTimeTerm: Array<ShopOrderableTimeTerm>;
  /** fetch data from the table: "shopOrderableTimeTerm" using primary key columns */
  shopOrderableTimeTerm_by_pk?: Maybe<ShopOrderableTimeTerm>;
  /** fetch data from the table in a streaming manner: "shopOrderableTimeTerm" */
  shopOrderableTimeTerm_stream: Array<ShopOrderableTimeTerm>;
  /** fetch data from the table: "shopPaymentType" */
  shopPaymentType: Array<ShopPaymentType>;
  /** fetch data from the table: "shopPaymentType" using primary key columns */
  shopPaymentType_by_pk?: Maybe<ShopPaymentType>;
  /** fetch data from the table in a streaming manner: "shopPaymentType" */
  shopPaymentType_stream: Array<ShopPaymentType>;
  /** fetch data from the table: "shopPlan" */
  shopPlan: Array<ShopPlan>;
  /** fetch data from the table: "shopPlanChoice" */
  shopPlanChoice: Array<ShopPlanChoice>;
  /** fetch data from the table: "shopPlanChoice" using primary key columns */
  shopPlanChoice_by_pk?: Maybe<ShopPlanChoice>;
  /** fetch data from the table in a streaming manner: "shopPlanChoice" */
  shopPlanChoice_stream: Array<ShopPlanChoice>;
  /** fetch data from the table: "shopPlanKitchenRole" */
  shopPlanKitchenRole: Array<ShopPlanKitchenRole>;
  /** fetch data from the table in a streaming manner: "shopPlanKitchenRole" */
  shopPlanKitchenRole_stream: Array<ShopPlanKitchenRole>;
  /** fetch data from the table: "shopPlanOption" */
  shopPlanOption: Array<ShopPlanOption>;
  /** fetch data from the table: "shopPlanOptionKitchenRole" */
  shopPlanOptionKitchenRole: Array<ShopPlanOptionKitchenRole>;
  /** fetch data from the table in a streaming manner: "shopPlanOptionKitchenRole" */
  shopPlanOptionKitchenRole_stream: Array<ShopPlanOptionKitchenRole>;
  /** fetch data from the table: "shopPlanOption" using primary key columns */
  shopPlanOption_by_pk?: Maybe<ShopPlanOption>;
  /** fetch data from the table in a streaming manner: "shopPlanOption" */
  shopPlanOption_stream: Array<ShopPlanOption>;
  /** fetch data from the table: "shopPlan" using primary key columns */
  shopPlan_by_pk?: Maybe<ShopPlan>;
  /** fetch data from the table in a streaming manner: "shopPlan" */
  shopPlan_stream: Array<ShopPlan>;
  /** fetch data from the table: "shop" using primary key columns */
  shop_by_pk?: Maybe<Shop>;
  /** fetch data from the table in a streaming manner: "shop" */
  shop_stream: Array<Shop>;
  /** fetch data from the table: "specificTimeSurchargeConfig" */
  specificTimeSurchargeConfig: Array<SpecificTimeSurchargeConfig>;
  /** fetch data from the table: "specificTimeSurchargeConfig" using primary key columns */
  specificTimeSurchargeConfig_by_pk?: Maybe<SpecificTimeSurchargeConfig>;
  /** fetch data from the table in a streaming manner: "specificTimeSurchargeConfig" */
  specificTimeSurchargeConfig_stream: Array<SpecificTimeSurchargeConfig>;
  /** fetch data from the table: "specificTimeSurchargeMeta" */
  specificTimeSurchargeMeta: Array<SpecificTimeSurchargeMeta>;
  /** fetch data from the table: "specificTimeSurchargeMeta" using primary key columns */
  specificTimeSurchargeMeta_by_pk?: Maybe<SpecificTimeSurchargeMeta>;
  /** fetch data from the table in a streaming manner: "specificTimeSurchargeMeta" */
  specificTimeSurchargeMeta_stream: Array<SpecificTimeSurchargeMeta>;
  /** fetch data from the table: "steraOnSitePaymentDetailType" */
  steraOnSitePaymentDetailType: Array<SteraOnSitePaymentDetailType>;
  /** fetch data from the table: "steraOnSitePaymentDetailType" using primary key columns */
  steraOnSitePaymentDetailType_by_pk?: Maybe<SteraOnSitePaymentDetailType>;
  /** fetch data from the table in a streaming manner: "steraOnSitePaymentDetailType" */
  steraOnSitePaymentDetailType_stream: Array<SteraOnSitePaymentDetailType>;
  /** fetch data from the table: "stock" */
  stock: Array<Stock>;
  /** fetch data from the table: "stock" using primary key columns */
  stock_by_pk?: Maybe<Stock>;
  /** fetch data from the table in a streaming manner: "stock" */
  stock_stream: Array<Stock>;
  /** fetch data from the table: "supportedLocale" */
  supportedLocale: Array<SupportedLocale>;
  /** fetch data from the table: "supportedLocale" using primary key columns */
  supportedLocale_by_pk?: Maybe<SupportedLocale>;
  /** fetch data from the table in a streaming manner: "supportedLocale" */
  supportedLocale_stream: Array<SupportedLocale>;
  /** fetch data from the table: "table" */
  table: Array<Table>;
  /** fetch data from the table: "tableArea" */
  tableArea: Array<TableArea>;
  /** fetch aggregated fields from the table: "tableArea" */
  tableArea_aggregate: TableAreaAggregate;
  /** fetch data from the table: "tableArea" using primary key columns */
  tableArea_by_pk?: Maybe<TableArea>;
  /** fetch data from the table in a streaming manner: "tableArea" */
  tableArea_stream: Array<TableArea>;
  /** fetch data from the table: "tableUser" */
  tableUser: Array<TableUser>;
  /** fetch data from the table: "tableUserCustomer" */
  tableUserCustomer: Array<TableUserCustomer>;
  /** fetch data from the table: "tableUserCustomer" using primary key columns */
  tableUserCustomer_by_pk?: Maybe<TableUserCustomer>;
  /** fetch data from the table in a streaming manner: "tableUserCustomer" */
  tableUserCustomer_stream: Array<TableUserCustomer>;
  /** fetch data from the table: "tableUser" using primary key columns */
  tableUser_by_pk?: Maybe<TableUser>;
  /** fetch data from the table in a streaming manner: "tableUser" */
  tableUser_stream: Array<TableUser>;
  /** fetch aggregated fields from the table: "table" */
  table_aggregate: TableAggregate;
  /** fetch data from the table: "table" using primary key columns */
  table_by_pk?: Maybe<Table>;
  /** fetch data from the table in a streaming manner: "table" */
  table_stream: Array<Table>;
  /** fetch data from the table: "taxOffice" */
  taxOffice: Array<TaxOffice>;
  /** fetch data from the table: "taxOffice" using primary key columns */
  taxOffice_by_pk?: Maybe<TaxOffice>;
  /** fetch data from the table in a streaming manner: "taxOffice" */
  taxOffice_stream: Array<TaxOffice>;
  /** fetch data from the table: "tecAggregationConfig" */
  tecAggregationConfig: Array<TecAggregationConfig>;
  /** fetch data from the table: "tecAggregationConfig" using primary key columns */
  tecAggregationConfig_by_pk?: Maybe<TecAggregationConfig>;
  /** fetch data from the table in a streaming manner: "tecAggregationConfig" */
  tecAggregationConfig_stream: Array<TecAggregationConfig>;
  /** fetch data from the table: "tecAggregationMediaMap" */
  tecAggregationMediaMap: Array<TecAggregationMediaMap>;
  /** fetch data from the table: "tecAggregationMediaMap" using primary key columns */
  tecAggregationMediaMap_by_pk?: Maybe<TecAggregationMediaMap>;
  /** fetch data from the table in a streaming manner: "tecAggregationMediaMap" */
  tecAggregationMediaMap_stream: Array<TecAggregationMediaMap>;
  /** fetch data from the table: "tecAggregationMenu" */
  tecAggregationMenu: Array<TecAggregationMenu>;
  /** fetch data from the table: "tecAggregationMenu" using primary key columns */
  tecAggregationMenu_by_pk?: Maybe<TecAggregationMenu>;
  /** fetch data from the table in a streaming manner: "tecAggregationMenu" */
  tecAggregationMenu_stream: Array<TecAggregationMenu>;
  /** fetch data from the table: "tecAggregationOnSitePaymentDetailType" */
  tecAggregationOnSitePaymentDetailType: Array<TecAggregationOnSitePaymentDetailType>;
  /** fetch data from the table: "tecAggregationOnSitePaymentDetailType" using primary key columns */
  tecAggregationOnSitePaymentDetailType_by_pk?: Maybe<TecAggregationOnSitePaymentDetailType>;
  /** fetch data from the table in a streaming manner: "tecAggregationOnSitePaymentDetailType" */
  tecAggregationOnSitePaymentDetailType_stream: Array<TecAggregationOnSitePaymentDetailType>;
  /** fetch data from the table: "textTypeQuestionAnswer" */
  textTypeQuestionAnswer: Array<TextTypeQuestionAnswer>;
  /** fetch data from the table in a streaming manner: "textTypeQuestionAnswer" */
  textTypeQuestionAnswer_stream: Array<TextTypeQuestionAnswer>;
  /** fetch data from the table: "translation" */
  translation: Array<Translation>;
  /** fetch data from the table: "translation" using primary key columns */
  translation_by_pk?: Maybe<Translation>;
  /** fetch data from the table in a streaming manner: "translation" */
  translation_stream: Array<Translation>;
  /** fetch data from the table: "winboardConfig" */
  winboardConfig: Array<WinboardConfig>;
  /** fetch data from the table: "winboardConfig" using primary key columns */
  winboardConfig_by_pk?: Maybe<WinboardConfig>;
  /** fetch data from the table in a streaming manner: "winboardConfig" */
  winboardConfig_stream: Array<WinboardConfig>;
  /** fetch data from the table: "winboardInflowSourceTag" */
  winboardInflowSourceTag: Array<WinboardInflowSourceTag>;
  /** fetch data from the table: "winboardInflowSourceTag" using primary key columns */
  winboardInflowSourceTag_by_pk?: Maybe<WinboardInflowSourceTag>;
  /** fetch data from the table in a streaming manner: "winboardInflowSourceTag" */
  winboardInflowSourceTag_stream: Array<WinboardInflowSourceTag>;
  /** fetch data from the table: "winboardMenu" */
  winboardMenu: Array<WinboardMenu>;
  /** fetch data from the table: "winboardMenu" using primary key columns */
  winboardMenu_by_pk?: Maybe<WinboardMenu>;
  /** fetch data from the table in a streaming manner: "winboardMenu" */
  winboardMenu_stream: Array<WinboardMenu>;
};


export type SubscriptionRootAccountingSlipImageArgs = {
  distinct_on?: InputMaybe<Array<AccountingSlipImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccountingSlipImageOrderBy>>;
  where?: InputMaybe<AccountingSlipImageBoolExp>;
};


export type SubscriptionRootAccountingSlipImageByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAccountingSlipImageStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AccountingSlipImageStreamCursorInput>>;
  where?: InputMaybe<AccountingSlipImageBoolExp>;
};


export type SubscriptionRootActivePlanArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanOrderBy>>;
  where?: InputMaybe<ActivePlanBoolExp>;
};


export type SubscriptionRootActivePlanChoiceArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanChoiceOrderBy>>;
  where?: InputMaybe<ActivePlanChoiceBoolExp>;
};


export type SubscriptionRootActivePlanChoiceByPkArgs = {
  activePlanChoiceId: Scalars['uuid'];
};


export type SubscriptionRootActivePlanChoiceStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ActivePlanChoiceStreamCursorInput>>;
  where?: InputMaybe<ActivePlanChoiceBoolExp>;
};


export type SubscriptionRootActivePlanOpenPriceMetaArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanOpenPriceMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanOpenPriceMetaOrderBy>>;
  where?: InputMaybe<ActivePlanOpenPriceMetaBoolExp>;
};


export type SubscriptionRootActivePlanOpenPriceMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootActivePlanOpenPriceMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ActivePlanOpenPriceMetaStreamCursorInput>>;
  where?: InputMaybe<ActivePlanOpenPriceMetaBoolExp>;
};


export type SubscriptionRootActivePlanOptionArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanOptionOrderBy>>;
  where?: InputMaybe<ActivePlanOptionBoolExp>;
};


export type SubscriptionRootActivePlanOptionByPkArgs = {
  activePlanOptionId: Scalars['uuid'];
};


export type SubscriptionRootActivePlanOptionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ActivePlanOptionStreamCursorInput>>;
  where?: InputMaybe<ActivePlanOptionBoolExp>;
};


export type SubscriptionRootActivePlanByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootActivePlanStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ActivePlanStreamCursorInput>>;
  where?: InputMaybe<ActivePlanBoolExp>;
};


export type SubscriptionRootAdyenPaymentReportTerminalPaymentAuthorizedEventArgs = {
  distinct_on?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentAuthorizedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentAuthorizedEventOrderBy>>;
  where?: InputMaybe<AdyenPaymentReportTerminalPaymentAuthorizedEventBoolExp>;
};


export type SubscriptionRootAdyenPaymentReportTerminalPaymentAuthorizedEventByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAdyenPaymentReportTerminalPaymentAuthorizedEventStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AdyenPaymentReportTerminalPaymentAuthorizedEventStreamCursorInput>>;
  where?: InputMaybe<AdyenPaymentReportTerminalPaymentAuthorizedEventBoolExp>;
};


export type SubscriptionRootAdyenPaymentReportTerminalPaymentCanceledEventArgs = {
  distinct_on?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentCanceledEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenPaymentReportTerminalPaymentCanceledEventOrderBy>>;
  where?: InputMaybe<AdyenPaymentReportTerminalPaymentCanceledEventBoolExp>;
};


export type SubscriptionRootAdyenPaymentReportTerminalPaymentCanceledEventByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAdyenPaymentReportTerminalPaymentCanceledEventStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AdyenPaymentReportTerminalPaymentCanceledEventStreamCursorInput>>;
  where?: InputMaybe<AdyenPaymentReportTerminalPaymentCanceledEventBoolExp>;
};


export type SubscriptionRootAdyenPaymentShopConfigArgs = {
  distinct_on?: InputMaybe<Array<AdyenPaymentShopConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenPaymentShopConfigOrderBy>>;
  where?: InputMaybe<AdyenPaymentShopConfigBoolExp>;
};


export type SubscriptionRootAdyenPaymentShopConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAdyenPaymentShopConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AdyenPaymentShopConfigStreamCursorInput>>;
  where?: InputMaybe<AdyenPaymentShopConfigBoolExp>;
};


export type SubscriptionRootAdyenPaymentShopPaymentMethodArgs = {
  distinct_on?: InputMaybe<Array<AdyenPaymentShopPaymentMethodSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenPaymentShopPaymentMethodOrderBy>>;
  where?: InputMaybe<AdyenPaymentShopPaymentMethodBoolExp>;
};


export type SubscriptionRootAdyenPaymentShopPaymentMethodByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAdyenPaymentShopPaymentMethodStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AdyenPaymentShopPaymentMethodStreamCursorInput>>;
  where?: InputMaybe<AdyenPaymentShopPaymentMethodBoolExp>;
};


export type SubscriptionRootAdyenPaymentStoreMetaArgs = {
  distinct_on?: InputMaybe<Array<AdyenPaymentStoreMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenPaymentStoreMetaOrderBy>>;
  where?: InputMaybe<AdyenPaymentStoreMetaBoolExp>;
};


export type SubscriptionRootAdyenPaymentStoreMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAdyenPaymentStoreMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AdyenPaymentStoreMetaStreamCursorInput>>;
  where?: InputMaybe<AdyenPaymentStoreMetaBoolExp>;
};


export type SubscriptionRootAdyenTerminalPaymentShopConfigArgs = {
  distinct_on?: InputMaybe<Array<AdyenTerminalPaymentShopConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenTerminalPaymentShopConfigOrderBy>>;
  where?: InputMaybe<AdyenTerminalPaymentShopConfigBoolExp>;
};


export type SubscriptionRootAdyenTerminalPaymentShopConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAdyenTerminalPaymentShopConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AdyenTerminalPaymentShopConfigStreamCursorInput>>;
  where?: InputMaybe<AdyenTerminalPaymentShopConfigBoolExp>;
};


export type SubscriptionRootAdyenTerminalPaymentShopPaymentMethodArgs = {
  distinct_on?: InputMaybe<Array<AdyenTerminalPaymentShopPaymentMethodSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AdyenTerminalPaymentShopPaymentMethodOrderBy>>;
  where?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodBoolExp>;
};


export type SubscriptionRootAdyenTerminalPaymentShopPaymentMethodByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAdyenTerminalPaymentShopPaymentMethodStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AdyenTerminalPaymentShopPaymentMethodStreamCursorInput>>;
  where?: InputMaybe<AdyenTerminalPaymentShopPaymentMethodBoolExp>;
};


export type SubscriptionRootAnalyticsSettingArgs = {
  distinct_on?: InputMaybe<Array<AnalyticsSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AnalyticsSettingOrderBy>>;
  where?: InputMaybe<AnalyticsSettingBoolExp>;
};


export type SubscriptionRootAnalyticsSettingByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootAnalyticsSettingStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AnalyticsSettingStreamCursorInput>>;
  where?: InputMaybe<AnalyticsSettingBoolExp>;
};


export type SubscriptionRootBirthdayTypeQuestionAnswerArgs = {
  distinct_on?: InputMaybe<Array<BirthdayTypeQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<BirthdayTypeQuestionAnswerOrderBy>>;
  where?: InputMaybe<BirthdayTypeQuestionAnswerBoolExp>;
};


export type SubscriptionRootBirthdayTypeQuestionAnswerStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<BirthdayTypeQuestionAnswerStreamCursorInput>>;
  where?: InputMaybe<BirthdayTypeQuestionAnswerBoolExp>;
};


export type SubscriptionRootCashRegisterConfigArgs = {
  distinct_on?: InputMaybe<Array<CashRegisterConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CashRegisterConfigOrderBy>>;
  where?: InputMaybe<CashRegisterConfigBoolExp>;
};


export type SubscriptionRootCashRegisterConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<CashRegisterConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CashRegisterConfigOrderBy>>;
  where?: InputMaybe<CashRegisterConfigBoolExp>;
};


export type SubscriptionRootCashRegisterConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCashRegisterConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CashRegisterConfigStreamCursorInput>>;
  where?: InputMaybe<CashRegisterConfigBoolExp>;
};


export type SubscriptionRootCashRegisterSecurityLevelArgs = {
  distinct_on?: InputMaybe<Array<CashRegisterSecurityLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CashRegisterSecurityLevelOrderBy>>;
  where?: InputMaybe<CashRegisterSecurityLevelBoolExp>;
};


export type SubscriptionRootCashRegisterSecurityLevelByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootCashRegisterSecurityLevelStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CashRegisterSecurityLevelStreamCursorInput>>;
  where?: InputMaybe<CashRegisterSecurityLevelBoolExp>;
};


export type SubscriptionRootCategoryArgs = {
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


export type SubscriptionRootCategoryMenuArgs = {
  distinct_on?: InputMaybe<Array<CategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryMenuOrderBy>>;
  where?: InputMaybe<CategoryMenuBoolExp>;
};


export type SubscriptionRootCategoryMenuAggregateArgs = {
  distinct_on?: InputMaybe<Array<CategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryMenuOrderBy>>;
  where?: InputMaybe<CategoryMenuBoolExp>;
};


export type SubscriptionRootCategoryMenuByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootCategoryMenuStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CategoryMenuStreamCursorInput>>;
  where?: InputMaybe<CategoryMenuBoolExp>;
};


export type SubscriptionRootCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<CategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CategoryOrderBy>>;
  where?: InputMaybe<CategoryBoolExp>;
};


export type SubscriptionRootCategoryByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootCategoryStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CategoryStreamCursorInput>>;
  where?: InputMaybe<CategoryBoolExp>;
};


export type SubscriptionRootCheckInEventArgs = {
  distinct_on?: InputMaybe<Array<CheckInEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CheckInEventOrderBy>>;
  where?: InputMaybe<CheckInEventBoolExp>;
};


export type SubscriptionRootCheckInEventStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CheckInEventStreamCursorInput>>;
  where?: InputMaybe<CheckInEventBoolExp>;
};


export type SubscriptionRootChoiceArgs = {
  distinct_on?: InputMaybe<Array<ChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChoiceOrderBy>>;
  where?: InputMaybe<ChoiceBoolExp>;
};


export type SubscriptionRootChoiceAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChoiceOrderBy>>;
  where?: InputMaybe<ChoiceBoolExp>;
};


export type SubscriptionRootChoiceByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootChoiceStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChoiceStreamCursorInput>>;
  where?: InputMaybe<ChoiceBoolExp>;
};


export type SubscriptionRootClerkArgs = {
  distinct_on?: InputMaybe<Array<ClerkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ClerkOrderBy>>;
  where?: InputMaybe<ClerkBoolExp>;
};


export type SubscriptionRootClerkByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootClerkStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ClerkStreamCursorInput>>;
  where?: InputMaybe<ClerkBoolExp>;
};


export type SubscriptionRootCloseCashRegisterArgs = {
  distinct_on?: InputMaybe<Array<CloseCashRegisterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CloseCashRegisterOrderBy>>;
  where?: InputMaybe<CloseCashRegisterBoolExp>;
};


export type SubscriptionRootCloseCashRegisterByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCloseCashRegisterStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CloseCashRegisterStreamCursorInput>>;
  where?: InputMaybe<CloseCashRegisterBoolExp>;
};


export type SubscriptionRootCoineyPaymentArgs = {
  distinct_on?: InputMaybe<Array<CoineyPaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineyPaymentOrderBy>>;
  where?: InputMaybe<CoineyPaymentBoolExp>;
};


export type SubscriptionRootCoineyPaymentOrderArgs = {
  distinct_on?: InputMaybe<Array<CoineyPaymentOrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineyPaymentOrderOrderBy>>;
  where?: InputMaybe<CoineyPaymentOrderBoolExp>;
};


export type SubscriptionRootCoineyPaymentOrderByPkArgs = {
  coineyPaymentOrderId: Scalars['Int'];
  orderId: Scalars['String'];
};


export type SubscriptionRootCoineyPaymentOrderStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CoineyPaymentOrderStreamCursorInput>>;
  where?: InputMaybe<CoineyPaymentOrderBoolExp>;
};


export type SubscriptionRootCoineyPaymentSalesArgs = {
  distinct_on?: InputMaybe<Array<CoineyPaymentSalesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineyPaymentSalesOrderBy>>;
  where?: InputMaybe<CoineyPaymentSalesBoolExp>;
};


export type SubscriptionRootCoineyPaymentSalesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCoineyPaymentSalesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CoineyPaymentSalesStreamCursorInput>>;
  where?: InputMaybe<CoineyPaymentSalesBoolExp>;
};


export type SubscriptionRootCoineyPaymentByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootCoineyPaymentStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CoineyPaymentStreamCursorInput>>;
  where?: InputMaybe<CoineyPaymentBoolExp>;
};


export type SubscriptionRootCoineySettlementArgs = {
  distinct_on?: InputMaybe<Array<CoineySettlementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineySettlementOrderBy>>;
  where?: InputMaybe<CoineySettlementBoolExp>;
};


export type SubscriptionRootCoineySettlementByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCoineySettlementStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CoineySettlementStreamCursorInput>>;
  where?: InputMaybe<CoineySettlementBoolExp>;
};


export type SubscriptionRootCompanyArgs = {
  distinct_on?: InputMaybe<Array<CompanySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyOrderBy>>;
  where?: InputMaybe<CompanyBoolExp>;
};


export type SubscriptionRootCompanyMemberArgs = {
  distinct_on?: InputMaybe<Array<CompanyMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyMemberOrderBy>>;
  where?: InputMaybe<CompanyMemberBoolExp>;
};


export type SubscriptionRootCompanyMemberAggregateArgs = {
  distinct_on?: InputMaybe<Array<CompanyMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompanyMemberOrderBy>>;
  where?: InputMaybe<CompanyMemberBoolExp>;
};


export type SubscriptionRootCompanyMemberByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCompanyMemberStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompanyMemberStreamCursorInput>>;
  where?: InputMaybe<CompanyMemberBoolExp>;
};


export type SubscriptionRootCompanyByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootCompanyStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompanyStreamCursorInput>>;
  where?: InputMaybe<CompanyBoolExp>;
};


export type SubscriptionRootCompulsoryAppetizerConfigArgs = {
  distinct_on?: InputMaybe<Array<CompulsoryAppetizerConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CompulsoryAppetizerConfigOrderBy>>;
  where?: InputMaybe<CompulsoryAppetizerConfigBoolExp>;
};


export type SubscriptionRootCompulsoryAppetizerConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCompulsoryAppetizerConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CompulsoryAppetizerConfigStreamCursorInput>>;
  where?: InputMaybe<CompulsoryAppetizerConfigBoolExp>;
};


export type SubscriptionRootConnectGameConfigArgs = {
  distinct_on?: InputMaybe<Array<ConnectGameConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConnectGameConfigOrderBy>>;
  where?: InputMaybe<ConnectGameConfigBoolExp>;
};


export type SubscriptionRootConnectGameConfigShopArgs = {
  distinct_on?: InputMaybe<Array<ConnectGameConfigShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ConnectGameConfigShopOrderBy>>;
  where?: InputMaybe<ConnectGameConfigShopBoolExp>;
};


export type SubscriptionRootConnectGameConfigShopByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootConnectGameConfigShopStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ConnectGameConfigShopStreamCursorInput>>;
  where?: InputMaybe<ConnectGameConfigShopBoolExp>;
};


export type SubscriptionRootConnectGameConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootConnectGameConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ConnectGameConfigStreamCursorInput>>;
  where?: InputMaybe<ConnectGameConfigBoolExp>;
};


export type SubscriptionRootCookingItemArgs = {
  distinct_on?: InputMaybe<Array<CookingItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CookingItemOrderBy>>;
  where?: InputMaybe<CookingItemBoolExp>;
};


export type SubscriptionRootCookingItemByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootCookingItemStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CookingItemStreamCursorInput>>;
  where?: InputMaybe<CookingItemBoolExp>;
};


export type SubscriptionRootCorporationArgs = {
  distinct_on?: InputMaybe<Array<CorporationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CorporationOrderBy>>;
  where?: InputMaybe<CorporationBoolExp>;
};


export type SubscriptionRootCorporationByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCorporationStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CorporationStreamCursorInput>>;
  where?: InputMaybe<CorporationBoolExp>;
};


export type SubscriptionRootCouponArgs = {
  distinct_on?: InputMaybe<Array<CouponSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponOrderBy>>;
  where?: InputMaybe<CouponBoolExp>;
};


export type SubscriptionRootCouponAppliedEventArgs = {
  distinct_on?: InputMaybe<Array<CouponAppliedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponAppliedEventOrderBy>>;
  where?: InputMaybe<CouponAppliedEventBoolExp>;
};


export type SubscriptionRootCouponAppliedEventAggregateArgs = {
  distinct_on?: InputMaybe<Array<CouponAppliedEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponAppliedEventOrderBy>>;
  where?: InputMaybe<CouponAppliedEventBoolExp>;
};


export type SubscriptionRootCouponAppliedEventByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCouponAppliedEventStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CouponAppliedEventStreamCursorInput>>;
  where?: InputMaybe<CouponAppliedEventBoolExp>;
};


export type SubscriptionRootCouponMenuDiscountArgs = {
  distinct_on?: InputMaybe<Array<CouponMenuDiscountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CouponMenuDiscountOrderBy>>;
  where?: InputMaybe<CouponMenuDiscountBoolExp>;
};


export type SubscriptionRootCouponMenuDiscountByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCouponMenuDiscountStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CouponMenuDiscountStreamCursorInput>>;
  where?: InputMaybe<CouponMenuDiscountBoolExp>;
};


export type SubscriptionRootCouponByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCouponStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CouponStreamCursorInput>>;
  where?: InputMaybe<CouponBoolExp>;
};


export type SubscriptionRootCustomerArgs = {
  distinct_on?: InputMaybe<Array<CustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerOrderBy>>;
  where?: InputMaybe<CustomerBoolExp>;
};


export type SubscriptionRootCustomerQuestionnaireArgs = {
  distinct_on?: InputMaybe<Array<CustomerQuestionnaireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerQuestionnaireOrderBy>>;
  where?: InputMaybe<CustomerQuestionnaireBoolExp>;
};


export type SubscriptionRootCustomerQuestionnaireByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCustomerQuestionnaireStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerQuestionnaireStreamCursorInput>>;
  where?: InputMaybe<CustomerQuestionnaireBoolExp>;
};


export type SubscriptionRootCustomerByPkArgs = {
  id: Scalars['String'];
};


export type SubscriptionRootCustomerStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerStreamCursorInput>>;
  where?: InputMaybe<CustomerBoolExp>;
};


export type SubscriptionRootDailySalesBudgetArgs = {
  distinct_on?: InputMaybe<Array<DailySalesBudgetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DailySalesBudgetOrderBy>>;
  where?: InputMaybe<DailySalesBudgetBoolExp>;
};


export type SubscriptionRootDailySalesBudgetByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootDailySalesBudgetStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DailySalesBudgetStreamCursorInput>>;
  where?: InputMaybe<DailySalesBudgetBoolExp>;
};


export type SubscriptionRootDashboardAccountArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountOrderBy>>;
  where?: InputMaybe<DashboardAccountBoolExp>;
};


export type SubscriptionRootDashboardAccountAccessibleCompanyArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountAccessibleCompanySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountAccessibleCompanyOrderBy>>;
  where?: InputMaybe<DashboardAccountAccessibleCompanyBoolExp>;
};


export type SubscriptionRootDashboardAccountAccessibleCompanyByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootDashboardAccountAccessibleCompanyStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DashboardAccountAccessibleCompanyStreamCursorInput>>;
  where?: InputMaybe<DashboardAccountAccessibleCompanyBoolExp>;
};


export type SubscriptionRootDashboardAccountAccessibleShopArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountAccessibleShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountAccessibleShopOrderBy>>;
  where?: InputMaybe<DashboardAccountAccessibleShopBoolExp>;
};


export type SubscriptionRootDashboardAccountAccessibleShopByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootDashboardAccountAccessibleShopStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DashboardAccountAccessibleShopStreamCursorInput>>;
  where?: InputMaybe<DashboardAccountAccessibleShopBoolExp>;
};


export type SubscriptionRootDashboardAccountRoleArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountRoleOrderBy>>;
  where?: InputMaybe<DashboardAccountRoleBoolExp>;
};


export type SubscriptionRootDashboardAccountRolePermissionArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountRolePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountRolePermissionOrderBy>>;
  where?: InputMaybe<DashboardAccountRolePermissionBoolExp>;
};


export type SubscriptionRootDashboardAccountRolePermissionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootDashboardAccountRolePermissionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DashboardAccountRolePermissionStreamCursorInput>>;
  where?: InputMaybe<DashboardAccountRolePermissionBoolExp>;
};


export type SubscriptionRootDashboardAccountRoleTypeArgs = {
  distinct_on?: InputMaybe<Array<DashboardAccountRoleTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DashboardAccountRoleTypeOrderBy>>;
  where?: InputMaybe<DashboardAccountRoleTypeBoolExp>;
};


export type SubscriptionRootDashboardAccountRoleTypeByPkArgs = {
  name: Scalars['String'];
};


export type SubscriptionRootDashboardAccountRoleTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DashboardAccountRoleTypeStreamCursorInput>>;
  where?: InputMaybe<DashboardAccountRoleTypeBoolExp>;
};


export type SubscriptionRootDashboardAccountRoleByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootDashboardAccountRoleStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DashboardAccountRoleStreamCursorInput>>;
  where?: InputMaybe<DashboardAccountRoleBoolExp>;
};


export type SubscriptionRootDashboardAccountByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootDashboardAccountStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DashboardAccountStreamCursorInput>>;
  where?: InputMaybe<DashboardAccountBoolExp>;
};


export type SubscriptionRootDishUpSlipGroupArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupBoolExp>;
};


export type SubscriptionRootDishUpSlipGroupRolesArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupRolesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupRolesOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupRolesBoolExp>;
};


export type SubscriptionRootDishUpSlipGroupRolesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootDishUpSlipGroupRolesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DishUpSlipGroupRolesStreamCursorInput>>;
  where?: InputMaybe<DishUpSlipGroupRolesBoolExp>;
};


export type SubscriptionRootDishUpSlipGroupShopMenusArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopMenusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopMenusOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
};


export type SubscriptionRootDishUpSlipGroupShopMenusByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootDishUpSlipGroupShopMenusStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DishUpSlipGroupShopMenusStreamCursorInput>>;
  where?: InputMaybe<DishUpSlipGroupShopMenusBoolExp>;
};


export type SubscriptionRootDishUpSlipGroupShopPlansArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupShopPlansSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupShopPlansOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
};


export type SubscriptionRootDishUpSlipGroupShopPlansByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootDishUpSlipGroupShopPlansStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DishUpSlipGroupShopPlansStreamCursorInput>>;
  where?: InputMaybe<DishUpSlipGroupShopPlansBoolExp>;
};


export type SubscriptionRootDishUpSlipGroupTablesArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupTablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupTablesOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupTablesBoolExp>;
};


export type SubscriptionRootDishUpSlipGroupTablesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootDishUpSlipGroupTablesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DishUpSlipGroupTablesStreamCursorInput>>;
  where?: InputMaybe<DishUpSlipGroupTablesBoolExp>;
};


export type SubscriptionRootDishUpSlipGroupByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootDishUpSlipGroupStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<DishUpSlipGroupStreamCursorInput>>;
  where?: InputMaybe<DishUpSlipGroupBoolExp>;
};


export type SubscriptionRootEbicaConfigArgs = {
  distinct_on?: InputMaybe<Array<EbicaConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EbicaConfigOrderBy>>;
  where?: InputMaybe<EbicaConfigBoolExp>;
};


export type SubscriptionRootEbicaConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootEbicaConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EbicaConfigStreamCursorInput>>;
  where?: InputMaybe<EbicaConfigBoolExp>;
};


export type SubscriptionRootEbicaTableArgs = {
  distinct_on?: InputMaybe<Array<EbicaTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EbicaTableOrderBy>>;
  where?: InputMaybe<EbicaTableBoolExp>;
};


export type SubscriptionRootEbicaTableByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootEbicaTableStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EbicaTableStreamCursorInput>>;
  where?: InputMaybe<EbicaTableBoolExp>;
};


export type SubscriptionRootExternalOnlineMenuArgs = {
  distinct_on?: InputMaybe<Array<ExternalOnlineMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalOnlineMenuOrderBy>>;
  where?: InputMaybe<ExternalOnlineMenuBoolExp>;
};


export type SubscriptionRootExternalOnlineMenuAvailableTimeTermArgs = {
  distinct_on?: InputMaybe<Array<ExternalOnlineMenuAvailableTimeTermSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalOnlineMenuAvailableTimeTermOrderBy>>;
  where?: InputMaybe<ExternalOnlineMenuAvailableTimeTermBoolExp>;
};


export type SubscriptionRootExternalOnlineMenuAvailableTimeTermByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootExternalOnlineMenuAvailableTimeTermStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalOnlineMenuAvailableTimeTermStreamCursorInput>>;
  where?: InputMaybe<ExternalOnlineMenuAvailableTimeTermBoolExp>;
};


export type SubscriptionRootExternalOnlineMenuConfigArgs = {
  distinct_on?: InputMaybe<Array<ExternalOnlineMenuConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ExternalOnlineMenuConfigOrderBy>>;
  where?: InputMaybe<ExternalOnlineMenuConfigBoolExp>;
};


export type SubscriptionRootExternalOnlineMenuConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootExternalOnlineMenuConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalOnlineMenuConfigStreamCursorInput>>;
  where?: InputMaybe<ExternalOnlineMenuConfigBoolExp>;
};


export type SubscriptionRootExternalOnlineMenuByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootExternalOnlineMenuStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ExternalOnlineMenuStreamCursorInput>>;
  where?: InputMaybe<ExternalOnlineMenuBoolExp>;
};


export type SubscriptionRootFeatureFlagArgs = {
  distinct_on?: InputMaybe<Array<FeatureFlagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeatureFlagOrderBy>>;
  where?: InputMaybe<FeatureFlagBoolExp>;
};


export type SubscriptionRootFeatureFlagShopArgs = {
  distinct_on?: InputMaybe<Array<FeatureFlagShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FeatureFlagShopOrderBy>>;
  where?: InputMaybe<FeatureFlagShopBoolExp>;
};


export type SubscriptionRootFeatureFlagShopByPkArgs = {
  name: FeatureFlagEnum;
  shopId: Scalars['uuid'];
};


export type SubscriptionRootFeatureFlagShopStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FeatureFlagShopStreamCursorInput>>;
  where?: InputMaybe<FeatureFlagShopBoolExp>;
};


export type SubscriptionRootFeatureFlagByPkArgs = {
  name: Scalars['String'];
};


export type SubscriptionRootFeatureFlagStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FeatureFlagStreamCursorInput>>;
  where?: InputMaybe<FeatureFlagBoolExp>;
};


export type SubscriptionRootFoodingJournalConfigArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalConfigOrderBy>>;
  where?: InputMaybe<FoodingJournalConfigBoolExp>;
};


export type SubscriptionRootFoodingJournalConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootFoodingJournalConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FoodingJournalConfigStreamCursorInput>>;
  where?: InputMaybe<FoodingJournalConfigBoolExp>;
};


export type SubscriptionRootFoodingJournalDepartmentMasterArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalDepartmentMasterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalDepartmentMasterOrderBy>>;
  where?: InputMaybe<FoodingJournalDepartmentMasterBoolExp>;
};


export type SubscriptionRootFoodingJournalDepartmentMasterByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootFoodingJournalDepartmentMasterStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FoodingJournalDepartmentMasterStreamCursorInput>>;
  where?: InputMaybe<FoodingJournalDepartmentMasterBoolExp>;
};


export type SubscriptionRootFoodingJournalGroupMasterArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalGroupMasterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalGroupMasterOrderBy>>;
  where?: InputMaybe<FoodingJournalGroupMasterBoolExp>;
};


export type SubscriptionRootFoodingJournalGroupMasterByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootFoodingJournalGroupMasterStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FoodingJournalGroupMasterStreamCursorInput>>;
  where?: InputMaybe<FoodingJournalGroupMasterBoolExp>;
};


export type SubscriptionRootFoodingJournalMenuArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalMenuOrderBy>>;
  where?: InputMaybe<FoodingJournalMenuBoolExp>;
};


export type SubscriptionRootFoodingJournalMenuByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootFoodingJournalMenuStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FoodingJournalMenuStreamCursorInput>>;
  where?: InputMaybe<FoodingJournalMenuBoolExp>;
};


export type SubscriptionRootFoodingJournalOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootFoodingJournalOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootFoodingJournalOnSitePaymentDetailTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FoodingJournalOnSitePaymentDetailTypeStreamCursorInput>>;
  where?: InputMaybe<FoodingJournalOnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootFoodingJournalOnSitePaymentDiscountTypeArgs = {
  distinct_on?: InputMaybe<Array<FoodingJournalOnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FoodingJournalOnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeBoolExp>;
};


export type SubscriptionRootFoodingJournalOnSitePaymentDiscountTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootFoodingJournalOnSitePaymentDiscountTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FoodingJournalOnSitePaymentDiscountTypeStreamCursorInput>>;
  where?: InputMaybe<FoodingJournalOnSitePaymentDiscountTypeBoolExp>;
};


export type SubscriptionRootGiftMetaArgs = {
  distinct_on?: InputMaybe<Array<GiftMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftMetaOrderBy>>;
  where?: InputMaybe<GiftMetaBoolExp>;
};


export type SubscriptionRootGiftMetaAggregateArgs = {
  distinct_on?: InputMaybe<Array<GiftMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftMetaOrderBy>>;
  where?: InputMaybe<GiftMetaBoolExp>;
};


export type SubscriptionRootGiftMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootGiftMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GiftMetaStreamCursorInput>>;
  where?: InputMaybe<GiftMetaBoolExp>;
};


export type SubscriptionRootGiftOptionMetaArgs = {
  distinct_on?: InputMaybe<Array<GiftOptionMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GiftOptionMetaOrderBy>>;
  where?: InputMaybe<GiftOptionMetaBoolExp>;
};


export type SubscriptionRootGiftOptionMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootGiftOptionMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GiftOptionMetaStreamCursorInput>>;
  where?: InputMaybe<GiftOptionMetaBoolExp>;
};


export type SubscriptionRootGmoBankAccountArgs = {
  distinct_on?: InputMaybe<Array<GmoBankAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoBankAccountOrderBy>>;
  where?: InputMaybe<GmoBankAccountBoolExp>;
};


export type SubscriptionRootGmoBankAccountShopArgs = {
  distinct_on?: InputMaybe<Array<GmoBankAccountShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoBankAccountShopOrderBy>>;
  where?: InputMaybe<GmoBankAccountShopBoolExp>;
};


export type SubscriptionRootGmoBankAccountShopByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootGmoBankAccountShopStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GmoBankAccountShopStreamCursorInput>>;
  where?: InputMaybe<GmoBankAccountShopBoolExp>;
};


export type SubscriptionRootGmoBankAccountByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootGmoBankAccountStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GmoBankAccountStreamCursorInput>>;
  where?: InputMaybe<GmoBankAccountBoolExp>;
};


export type SubscriptionRootGmoOffPaymentMethodTypeArgs = {
  distinct_on?: InputMaybe<Array<GmoOffPaymentMethodTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoOffPaymentMethodTypeOrderBy>>;
  where?: InputMaybe<GmoOffPaymentMethodTypeBoolExp>;
};


export type SubscriptionRootGmoOffPaymentMethodTypeByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootGmoOffPaymentMethodTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GmoOffPaymentMethodTypeStreamCursorInput>>;
  where?: InputMaybe<GmoOffPaymentMethodTypeBoolExp>;
};


export type SubscriptionRootGmoOffPaymentOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<GmoOffPaymentOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoOffPaymentOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootGmoOffPaymentOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootGmoOffPaymentOnSitePaymentDetailTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeStreamCursorInput>>;
  where?: InputMaybe<GmoOffPaymentOnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootGmoOffPaymentShopConfigArgs = {
  distinct_on?: InputMaybe<Array<GmoOffPaymentShopConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoOffPaymentShopConfigOrderBy>>;
  where?: InputMaybe<GmoOffPaymentShopConfigBoolExp>;
};


export type SubscriptionRootGmoOffPaymentShopConfigTransactionFeeRateArgs = {
  distinct_on?: InputMaybe<Array<GmoOffPaymentShopConfigTransactionFeeRateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GmoOffPaymentShopConfigTransactionFeeRateOrderBy>>;
  where?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateBoolExp>;
};


export type SubscriptionRootGmoOffPaymentShopConfigTransactionFeeRateByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootGmoOffPaymentShopConfigTransactionFeeRateStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateStreamCursorInput>>;
  where?: InputMaybe<GmoOffPaymentShopConfigTransactionFeeRateBoolExp>;
};


export type SubscriptionRootGmoOffPaymentShopConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootGmoOffPaymentShopConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GmoOffPaymentShopConfigStreamCursorInput>>;
  where?: InputMaybe<GmoOffPaymentShopConfigBoolExp>;
};


export type SubscriptionRootInflowSourceTagArgs = {
  distinct_on?: InputMaybe<Array<InflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InflowSourceTagOrderBy>>;
  where?: InputMaybe<InflowSourceTagBoolExp>;
};


export type SubscriptionRootInflowSourceTagAggregateArgs = {
  distinct_on?: InputMaybe<Array<InflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InflowSourceTagOrderBy>>;
  where?: InputMaybe<InflowSourceTagBoolExp>;
};


export type SubscriptionRootInflowSourceTagByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootInflowSourceTagStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InflowSourceTagStreamCursorInput>>;
  where?: InputMaybe<InflowSourceTagBoolExp>;
};


export type SubscriptionRootItemSalesArgs = {
  distinct_on?: InputMaybe<Array<ItemSalesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItemSalesOrderBy>>;
  where?: InputMaybe<ItemSalesBoolExp>;
};


export type SubscriptionRootItemSalesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootItemSalesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ItemSalesStreamCursorInput>>;
  where?: InputMaybe<ItemSalesBoolExp>;
};


export type SubscriptionRootKdDisplayItemKdDisplayTargetsKdDisplayTargetArgs = {
  distinct_on?: InputMaybe<Array<KdDisplayItemKdDisplayTargetsKdDisplayTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KdDisplayItemKdDisplayTargetsKdDisplayTargetOrderBy>>;
  where?: InputMaybe<KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp>;
};


export type SubscriptionRootKdDisplayItemKdDisplayTargetsKdDisplayTargetByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootKdDisplayItemKdDisplayTargetsKdDisplayTargetStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<KdDisplayItemKdDisplayTargetsKdDisplayTargetStreamCursorInput>>;
  where?: InputMaybe<KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp>;
};


export type SubscriptionRootKdDisplayTargetArgs = {
  distinct_on?: InputMaybe<Array<KdDisplayTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<KdDisplayTargetOrderBy>>;
  where?: InputMaybe<KdDisplayTargetBoolExp>;
};


export type SubscriptionRootKdDisplayTargetByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootKdDisplayTargetStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<KdDisplayTargetStreamCursorInput>>;
  where?: InputMaybe<KdDisplayTargetBoolExp>;
};


export type SubscriptionRootLineChannelConfigArgs = {
  distinct_on?: InputMaybe<Array<LineChannelConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineChannelConfigOrderBy>>;
  where?: InputMaybe<LineChannelConfigBoolExp>;
};


export type SubscriptionRootLineChannelConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLineChannelConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LineChannelConfigStreamCursorInput>>;
  where?: InputMaybe<LineChannelConfigBoolExp>;
};


export type SubscriptionRootLineOfficialAccountArgs = {
  distinct_on?: InputMaybe<Array<LineOfficialAccountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineOfficialAccountOrderBy>>;
  where?: InputMaybe<LineOfficialAccountBoolExp>;
};


export type SubscriptionRootLineOfficialAccountByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootLineOfficialAccountStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LineOfficialAccountStreamCursorInput>>;
  where?: InputMaybe<LineOfficialAccountBoolExp>;
};


export type SubscriptionRootLineProfileArgs = {
  distinct_on?: InputMaybe<Array<LineProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineProfileOrderBy>>;
  where?: InputMaybe<LineProfileBoolExp>;
};


export type SubscriptionRootLineProfileStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LineProfileStreamCursorInput>>;
  where?: InputMaybe<LineProfileBoolExp>;
};


export type SubscriptionRootLineReportingBotConfigArgs = {
  distinct_on?: InputMaybe<Array<LineReportingBotConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineReportingBotConfigOrderBy>>;
  where?: InputMaybe<LineReportingBotConfigBoolExp>;
};


export type SubscriptionRootLineReportingBotConfigShopArgs = {
  distinct_on?: InputMaybe<Array<LineReportingBotConfigShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineReportingBotConfigShopOrderBy>>;
  where?: InputMaybe<LineReportingBotConfigShopBoolExp>;
};


export type SubscriptionRootLineReportingBotConfigShopByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLineReportingBotConfigShopStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LineReportingBotConfigShopStreamCursorInput>>;
  where?: InputMaybe<LineReportingBotConfigShopBoolExp>;
};


export type SubscriptionRootLineReportingBotConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLineReportingBotConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LineReportingBotConfigStreamCursorInput>>;
  where?: InputMaybe<LineReportingBotConfigBoolExp>;
};


export type SubscriptionRootLineReportingBotVerificationArgs = {
  distinct_on?: InputMaybe<Array<LineReportingBotVerificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LineReportingBotVerificationOrderBy>>;
  where?: InputMaybe<LineReportingBotVerificationBoolExp>;
};


export type SubscriptionRootLineReportingBotVerificationByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootLineReportingBotVerificationStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<LineReportingBotVerificationStreamCursorInput>>;
  where?: InputMaybe<LineReportingBotVerificationBoolExp>;
};


export type SubscriptionRootMembershipCardArgs = {
  distinct_on?: InputMaybe<Array<MembershipCardSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCardOrderBy>>;
  where?: InputMaybe<MembershipCardBoolExp>;
};


export type SubscriptionRootMembershipCardAppearanceTypeArgs = {
  distinct_on?: InputMaybe<Array<MembershipCardAppearanceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCardAppearanceTypeOrderBy>>;
  where?: InputMaybe<MembershipCardAppearanceTypeBoolExp>;
};


export type SubscriptionRootMembershipCardAppearanceTypeByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootMembershipCardAppearanceTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MembershipCardAppearanceTypeStreamCursorInput>>;
  where?: InputMaybe<MembershipCardAppearanceTypeBoolExp>;
};


export type SubscriptionRootMembershipCardTitleArgs = {
  distinct_on?: InputMaybe<Array<MembershipCardTitleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipCardTitleOrderBy>>;
  where?: InputMaybe<MembershipCardTitleBoolExp>;
};


export type SubscriptionRootMembershipCardTitleByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMembershipCardTitleStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MembershipCardTitleStreamCursorInput>>;
  where?: InputMaybe<MembershipCardTitleBoolExp>;
};


export type SubscriptionRootMembershipCardByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMembershipCardStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MembershipCardStreamCursorInput>>;
  where?: InputMaybe<MembershipCardBoolExp>;
};


export type SubscriptionRootMembershipRankArgs = {
  distinct_on?: InputMaybe<Array<MembershipRankSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipRankOrderBy>>;
  where?: InputMaybe<MembershipRankBoolExp>;
};


export type SubscriptionRootMembershipRankConfigArgs = {
  distinct_on?: InputMaybe<Array<MembershipRankConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MembershipRankConfigOrderBy>>;
  where?: InputMaybe<MembershipRankConfigBoolExp>;
};


export type SubscriptionRootMembershipRankConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMembershipRankConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MembershipRankConfigStreamCursorInput>>;
  where?: InputMaybe<MembershipRankConfigBoolExp>;
};


export type SubscriptionRootMembershipRankByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootMembershipRankStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MembershipRankStreamCursorInput>>;
  where?: InputMaybe<MembershipRankBoolExp>;
};


export type SubscriptionRootMenuArgs = {
  distinct_on?: InputMaybe<Array<MenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderBy>>;
  where?: InputMaybe<MenuBoolExp>;
};


export type SubscriptionRootMenuOptionArgs = {
  distinct_on?: InputMaybe<Array<MenuOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOptionOrderBy>>;
  where?: InputMaybe<MenuOptionBoolExp>;
};


export type SubscriptionRootMenuOptionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMenuOptionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MenuOptionStreamCursorInput>>;
  where?: InputMaybe<MenuOptionBoolExp>;
};


export type SubscriptionRootMenuOrderItemArgs = {
  distinct_on?: InputMaybe<Array<MenuOrderItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderItemOrderBy>>;
  where?: InputMaybe<MenuOrderItemBoolExp>;
};


export type SubscriptionRootMenuOrderItemChoiceArgs = {
  distinct_on?: InputMaybe<Array<MenuOrderItemChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderItemChoiceOrderBy>>;
  where?: InputMaybe<MenuOrderItemChoiceBoolExp>;
};


export type SubscriptionRootMenuOrderItemChoiceByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMenuOrderItemChoiceStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MenuOrderItemChoiceStreamCursorInput>>;
  where?: InputMaybe<MenuOrderItemChoiceBoolExp>;
};


export type SubscriptionRootMenuOrderItemOptionArgs = {
  distinct_on?: InputMaybe<Array<MenuOrderItemOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuOrderItemOptionOrderBy>>;
  where?: InputMaybe<MenuOrderItemOptionBoolExp>;
};


export type SubscriptionRootMenuOrderItemOptionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMenuOrderItemOptionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MenuOrderItemOptionStreamCursorInput>>;
  where?: InputMaybe<MenuOrderItemOptionBoolExp>;
};


export type SubscriptionRootMenuOrderItemByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMenuOrderItemStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MenuOrderItemStreamCursorInput>>;
  where?: InputMaybe<MenuOrderItemBoolExp>;
};


export type SubscriptionRootMenuRecommendationMetaArgs = {
  distinct_on?: InputMaybe<Array<MenuRecommendationMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MenuRecommendationMetaOrderBy>>;
  where?: InputMaybe<MenuRecommendationMetaBoolExp>;
};


export type SubscriptionRootMenuRecommendationMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMenuRecommendationMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MenuRecommendationMetaStreamCursorInput>>;
  where?: InputMaybe<MenuRecommendationMetaBoolExp>;
};


export type SubscriptionRootMenuByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootMenuStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MenuStreamCursorInput>>;
  where?: InputMaybe<MenuBoolExp>;
};


export type SubscriptionRootMessageDeliveryArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliverySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryOrderBy>>;
  where?: InputMaybe<MessageDeliveryBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerListArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerListSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerListOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerListBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerListCustomerArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerListCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerListCustomerOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerListCustomerAggregateArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerListCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerListCustomerOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerListCustomerByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerListCustomerStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerListCustomerStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerListCustomerBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerListByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerListStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerListStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerListBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentBusinessOperationHoursConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentBusinessOperationHoursConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentBusinessOperationHoursConditionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentBusinessOperationHoursConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentDayOfWeekConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentDayOfWeekConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentDayOfWeekConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentDayOfWeekConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentDayOfWeekConditionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekConditionStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentDayOfWeekConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentGenderArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentGenderBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentGenderConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentGenderConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentGenderConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentGenderConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentGenderConditionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentGenderConditionStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentGenderConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentGenderByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentGenderStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentGenderStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentGenderBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentMenuConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentMenuConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentMenuConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentMenuConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentMenuConditionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentMenuConditionStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentMenuConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentNumericConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentNumericConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentNumericConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentNumericConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentNumericConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentNumericConditionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentNumericConditionStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentNumericConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerConditionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentShopConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentShopConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentShopConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentShopConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentShopConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentShopConditionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentShopConditionStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentShopConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentUnvisitedDayCountConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentUnvisitedDayCountConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentUnvisitedDayCountConditionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentUnvisitedDayCountConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentVisitedDayCountConditionArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryCustomerSegmentVisitedDayCountConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryCustomerSegmentVisitedDayCountConditionOrderBy>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentVisitedDayCountConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentVisitedDayCountConditionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentVisitedDayCountConditionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentVisitedDayCountConditionStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentVisitedDayCountConditionBoolExp>;
};


export type SubscriptionRootMessageDeliveryCustomerSegmentByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryCustomerSegmentStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryCustomerSegmentStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryCustomerSegmentBoolExp>;
};


export type SubscriptionRootMessageDeliveryJobArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryJobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryJobOrderBy>>;
  where?: InputMaybe<MessageDeliveryJobBoolExp>;
};


export type SubscriptionRootMessageDeliveryJobByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryJobStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryJobStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryJobBoolExp>;
};


export type SubscriptionRootMessageDeliveryMessageArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageBoolExp>;
};


export type SubscriptionRootMessageDeliveryMessageCouponTypeMetaArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageCouponTypeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageCouponTypeMetaOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageCouponTypeMetaBoolExp>;
};


export type SubscriptionRootMessageDeliveryMessageCouponTypeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryMessageCouponTypeMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryMessageCouponTypeMetaStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryMessageCouponTypeMetaBoolExp>;
};


export type SubscriptionRootMessageDeliveryMessageImageTypeMetaArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageImageTypeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageImageTypeMetaOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageImageTypeMetaBoolExp>;
};


export type SubscriptionRootMessageDeliveryMessageImageTypeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryMessageImageTypeMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryMessageImageTypeMetaStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryMessageImageTypeMetaBoolExp>;
};


export type SubscriptionRootMessageDeliveryMessageQuestionnaireTypeMetaArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageQuestionnaireTypeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageQuestionnaireTypeMetaOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageQuestionnaireTypeMetaBoolExp>;
};


export type SubscriptionRootMessageDeliveryMessageQuestionnaireTypeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryMessageQuestionnaireTypeMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryMessageQuestionnaireTypeMetaStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryMessageQuestionnaireTypeMetaBoolExp>;
};


export type SubscriptionRootMessageDeliveryMessageTextTypeMetaArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryMessageTextTypeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryMessageTextTypeMetaOrderBy>>;
  where?: InputMaybe<MessageDeliveryMessageTextTypeMetaBoolExp>;
};


export type SubscriptionRootMessageDeliveryMessageTextTypeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryMessageTextTypeMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryMessageTextTypeMetaStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryMessageTextTypeMetaBoolExp>;
};


export type SubscriptionRootMessageDeliveryMessageByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryMessageStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryMessageStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryMessageBoolExp>;
};


export type SubscriptionRootMessageDeliveryShopJobArgs = {
  distinct_on?: InputMaybe<Array<MessageDeliveryShopJobSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MessageDeliveryShopJobOrderBy>>;
  where?: InputMaybe<MessageDeliveryShopJobBoolExp>;
};


export type SubscriptionRootMessageDeliveryShopJobByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryShopJobStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryShopJobStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryShopJobBoolExp>;
};


export type SubscriptionRootMessageDeliveryByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMessageDeliveryStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MessageDeliveryStreamCursorInput>>;
  where?: InputMaybe<MessageDeliveryBoolExp>;
};


export type SubscriptionRootMonthlySalesBudgetArgs = {
  distinct_on?: InputMaybe<Array<MonthlySalesBudgetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MonthlySalesBudgetOrderBy>>;
  where?: InputMaybe<MonthlySalesBudgetBoolExp>;
};


export type SubscriptionRootMonthlySalesBudgetByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMonthlySalesBudgetStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MonthlySalesBudgetStreamCursorInput>>;
  where?: InputMaybe<MonthlySalesBudgetBoolExp>;
};


export type SubscriptionRootMultipleChoiceTypeQuestionAnswerArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionAnswerOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionAnswerBoolExp>;
};


export type SubscriptionRootMultipleChoiceTypeQuestionAnswerByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMultipleChoiceTypeQuestionAnswerStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MultipleChoiceTypeQuestionAnswerStreamCursorInput>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionAnswerBoolExp>;
};


export type SubscriptionRootMultipleChoiceTypeQuestionChoiceArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionChoiceBoolExp>;
};


export type SubscriptionRootMultipleChoiceTypeQuestionChoiceAnswerArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionChoiceAnswerOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerBoolExp>;
};


export type SubscriptionRootMultipleChoiceTypeQuestionChoiceAnswerByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMultipleChoiceTypeQuestionChoiceAnswerStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerStreamCursorInput>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionChoiceAnswerBoolExp>;
};


export type SubscriptionRootMultipleChoiceTypeQuestionChoiceStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MultipleChoiceTypeQuestionChoiceStreamCursorInput>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionChoiceBoolExp>;
};


export type SubscriptionRootMultipleChoiceTypeQuestionMetaArgs = {
  distinct_on?: InputMaybe<Array<MultipleChoiceTypeQuestionMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MultipleChoiceTypeQuestionMetaOrderBy>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionMetaBoolExp>;
};


export type SubscriptionRootMultipleChoiceTypeQuestionMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMultipleChoiceTypeQuestionMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MultipleChoiceTypeQuestionMetaStreamCursorInput>>;
  where?: InputMaybe<MultipleChoiceTypeQuestionMetaBoolExp>;
};


export type SubscriptionRootOnSitePaymentArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentOrderBy>>;
  where?: InputMaybe<OnSitePaymentBoolExp>;
};


export type SubscriptionRootOnSitePaymentDetailArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailBoolExp>;
};


export type SubscriptionRootOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootOnSitePaymentDetailTypeCategoryPriorityArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailTypeCategoryPrioritySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailTypeCategoryPriorityOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailTypeCategoryPriorityBoolExp>;
};


export type SubscriptionRootOnSitePaymentDetailTypeCategoryPriorityByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootOnSitePaymentDetailTypeCategoryPriorityStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OnSitePaymentDetailTypeCategoryPriorityStreamCursorInput>>;
  where?: InputMaybe<OnSitePaymentDetailTypeCategoryPriorityBoolExp>;
};


export type SubscriptionRootOnSitePaymentDetailTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootOnSitePaymentDetailTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OnSitePaymentDetailTypeStreamCursorInput>>;
  where?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootOnSitePaymentDetailByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootOnSitePaymentDetailStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OnSitePaymentDetailStreamCursorInput>>;
  where?: InputMaybe<OnSitePaymentDetailBoolExp>;
};


export type SubscriptionRootOnSitePaymentDiscountArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountBoolExp>;
};


export type SubscriptionRootOnSitePaymentDiscountTypeArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
};


export type SubscriptionRootOnSitePaymentDiscountTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
};


export type SubscriptionRootOnSitePaymentDiscountTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootOnSitePaymentDiscountTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OnSitePaymentDiscountTypeStreamCursorInput>>;
  where?: InputMaybe<OnSitePaymentDiscountTypeBoolExp>;
};


export type SubscriptionRootOnSitePaymentDiscountByPkArgs = {
  onSitePaymentDiscountId: Scalars['uuid'];
};


export type SubscriptionRootOnSitePaymentDiscountStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OnSitePaymentDiscountStreamCursorInput>>;
  where?: InputMaybe<OnSitePaymentDiscountBoolExp>;
};


export type SubscriptionRootOnSitePaymentSalesArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentSalesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentSalesOrderBy>>;
  where?: InputMaybe<OnSitePaymentSalesBoolExp>;
};


export type SubscriptionRootOnSitePaymentSalesByPkArgs = {
  onSitePaymentSalesId: Scalars['uuid'];
};


export type SubscriptionRootOnSitePaymentSalesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OnSitePaymentSalesStreamCursorInput>>;
  where?: InputMaybe<OnSitePaymentSalesBoolExp>;
};


export type SubscriptionRootOnSitePaymentTableUsersArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentTableUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentTableUsersOrderBy>>;
  where?: InputMaybe<OnSitePaymentTableUsersBoolExp>;
};


export type SubscriptionRootOnSitePaymentTableUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OnSitePaymentTableUsersStreamCursorInput>>;
  where?: InputMaybe<OnSitePaymentTableUsersBoolExp>;
};


export type SubscriptionRootOnSitePaymentByPkArgs = {
  onSitePaymentId: Scalars['Int'];
};


export type SubscriptionRootOnSitePaymentStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OnSitePaymentStreamCursorInput>>;
  where?: InputMaybe<OnSitePaymentBoolExp>;
};


export type SubscriptionRootOnlinePaymentPrinterRoleMetaArgs = {
  distinct_on?: InputMaybe<Array<OnlinePaymentPrinterRoleMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnlinePaymentPrinterRoleMetaOrderBy>>;
  where?: InputMaybe<OnlinePaymentPrinterRoleMetaBoolExp>;
};


export type SubscriptionRootOnlinePaymentPrinterRoleMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootOnlinePaymentPrinterRoleMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OnlinePaymentPrinterRoleMetaStreamCursorInput>>;
  where?: InputMaybe<OnlinePaymentPrinterRoleMetaBoolExp>;
};


export type SubscriptionRootOptionArgs = {
  distinct_on?: InputMaybe<Array<OptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OptionOrderBy>>;
  where?: InputMaybe<OptionBoolExp>;
};


export type SubscriptionRootOptionInputTypeArgs = {
  distinct_on?: InputMaybe<Array<OptionInputTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OptionInputTypeOrderBy>>;
  where?: InputMaybe<OptionInputTypeBoolExp>;
};


export type SubscriptionRootOptionInputTypeByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootOptionInputTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OptionInputTypeStreamCursorInput>>;
  where?: InputMaybe<OptionInputTypeBoolExp>;
};


export type SubscriptionRootOptionAggregateArgs = {
  distinct_on?: InputMaybe<Array<OptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OptionOrderBy>>;
  where?: InputMaybe<OptionBoolExp>;
};


export type SubscriptionRootOptionByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootOptionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OptionStreamCursorInput>>;
  where?: InputMaybe<OptionBoolExp>;
};


export type SubscriptionRootOrderArgs = {
  distinct_on?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


export type SubscriptionRootOrderByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootOrderStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OrderStreamCursorInput>>;
  where?: InputMaybe<OrderBoolExp>;
};


export type SubscriptionRootOrderableTimeArgs = {
  distinct_on?: InputMaybe<Array<OrderableTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrderableTimeOrderBy>>;
  where?: InputMaybe<OrderableTimeBoolExp>;
};


export type SubscriptionRootOrderableTimeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootOrderableTimeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OrderableTimeStreamCursorInput>>;
  where?: InputMaybe<OrderableTimeBoolExp>;
};


export type SubscriptionRootPayingActivePlanChoiceArgs = {
  distinct_on?: InputMaybe<Array<PayingActivePlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingActivePlanChoiceOrderBy>>;
  where?: InputMaybe<PayingActivePlanChoiceBoolExp>;
};


export type SubscriptionRootPayingActivePlanChoiceByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootPayingActivePlanChoiceStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PayingActivePlanChoiceStreamCursorInput>>;
  where?: InputMaybe<PayingActivePlanChoiceBoolExp>;
};


export type SubscriptionRootPayingActivePlanOpenPriceMetaArgs = {
  distinct_on?: InputMaybe<Array<PayingActivePlanOpenPriceMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingActivePlanOpenPriceMetaOrderBy>>;
  where?: InputMaybe<PayingActivePlanOpenPriceMetaBoolExp>;
};


export type SubscriptionRootPayingActivePlanOpenPriceMetaByPkArgs = {
  payingActivePlanOpenPriceMetaId: Scalars['uuid'];
};


export type SubscriptionRootPayingActivePlanOpenPriceMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PayingActivePlanOpenPriceMetaStreamCursorInput>>;
  where?: InputMaybe<PayingActivePlanOpenPriceMetaBoolExp>;
};


export type SubscriptionRootPayingMenuOrderItemArgs = {
  distinct_on?: InputMaybe<Array<PayingMenuOrderItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PayingMenuOrderItemOrderBy>>;
  where?: InputMaybe<PayingMenuOrderItemBoolExp>;
};


export type SubscriptionRootPayingMenuOrderItemByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootPayingMenuOrderItemStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PayingMenuOrderItemStreamCursorInput>>;
  where?: InputMaybe<PayingMenuOrderItemBoolExp>;
};


export type SubscriptionRootPaymentReceiptRoleTableArgs = {
  distinct_on?: InputMaybe<Array<PaymentReceiptRoleTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentReceiptRoleTableOrderBy>>;
  where?: InputMaybe<PaymentReceiptRoleTableBoolExp>;
};


export type SubscriptionRootPaymentReceiptRoleTableStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PaymentReceiptRoleTableStreamCursorInput>>;
  where?: InputMaybe<PaymentReceiptRoleTableBoolExp>;
};


export type SubscriptionRootPikaichiConfigArgs = {
  distinct_on?: InputMaybe<Array<PikaichiConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiConfigOrderBy>>;
  where?: InputMaybe<PikaichiConfigBoolExp>;
};


export type SubscriptionRootPikaichiConfigByPkArgs = {
  pikaichiConfigId: Scalars['uuid'];
};


export type SubscriptionRootPikaichiConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PikaichiConfigStreamCursorInput>>;
  where?: InputMaybe<PikaichiConfigBoolExp>;
};


export type SubscriptionRootPikaichiMenuArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuOrderBy>>;
  where?: InputMaybe<PikaichiMenuBoolExp>;
};


export type SubscriptionRootPikaichiMenuChoiceArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuChoiceOrderBy>>;
  where?: InputMaybe<PikaichiMenuChoiceBoolExp>;
};


export type SubscriptionRootPikaichiMenuChoiceByPkArgs = {
  pikaichiMenuChoiceId: Scalars['uuid'];
};


export type SubscriptionRootPikaichiMenuChoiceStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PikaichiMenuChoiceStreamCursorInput>>;
  where?: InputMaybe<PikaichiMenuChoiceBoolExp>;
};


export type SubscriptionRootPikaichiMenuMenuArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuMenuOrderBy>>;
  where?: InputMaybe<PikaichiMenuMenuBoolExp>;
};


export type SubscriptionRootPikaichiMenuMenuByPkArgs = {
  pikaichiMenuMenuId: Scalars['uuid'];
};


export type SubscriptionRootPikaichiMenuMenuStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PikaichiMenuMenuStreamCursorInput>>;
  where?: InputMaybe<PikaichiMenuMenuBoolExp>;
};


export type SubscriptionRootPikaichiMenuPlanArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuPlanOrderBy>>;
  where?: InputMaybe<PikaichiMenuPlanBoolExp>;
};


export type SubscriptionRootPikaichiMenuPlanChoiceArgs = {
  distinct_on?: InputMaybe<Array<PikaichiMenuPlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiMenuPlanChoiceOrderBy>>;
  where?: InputMaybe<PikaichiMenuPlanChoiceBoolExp>;
};


export type SubscriptionRootPikaichiMenuPlanChoiceByPkArgs = {
  pikaichiMenuPlanChoiceId: Scalars['uuid'];
};


export type SubscriptionRootPikaichiMenuPlanChoiceStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PikaichiMenuPlanChoiceStreamCursorInput>>;
  where?: InputMaybe<PikaichiMenuPlanChoiceBoolExp>;
};


export type SubscriptionRootPikaichiMenuPlanByPkArgs = {
  pikaichiMenuPlanId: Scalars['uuid'];
};


export type SubscriptionRootPikaichiMenuPlanStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PikaichiMenuPlanStreamCursorInput>>;
  where?: InputMaybe<PikaichiMenuPlanBoolExp>;
};


export type SubscriptionRootPikaichiMenuByPkArgs = {
  pikaichiMenuId: Scalars['uuid'];
};


export type SubscriptionRootPikaichiMenuStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PikaichiMenuStreamCursorInput>>;
  where?: InputMaybe<PikaichiMenuBoolExp>;
};


export type SubscriptionRootPikaichiOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<PikaichiOnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootPikaichiOnSitePaymentDetailTypeByPkArgs = {
  pikaichiPaymentDetailTypeId: Scalars['uuid'];
};


export type SubscriptionRootPikaichiOnSitePaymentDetailTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PikaichiOnSitePaymentDetailTypeStreamCursorInput>>;
  where?: InputMaybe<PikaichiOnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootPikaichiOnSitePaymentDiscountTypeArgs = {
  distinct_on?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PikaichiOnSitePaymentDiscountTypeOrderBy>>;
  where?: InputMaybe<PikaichiOnSitePaymentDiscountTypeBoolExp>;
};


export type SubscriptionRootPikaichiOnSitePaymentDiscountTypeByPkArgs = {
  pikaichiDiscountTypeId: Scalars['uuid'];
};


export type SubscriptionRootPikaichiOnSitePaymentDiscountTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PikaichiOnSitePaymentDiscountTypeStreamCursorInput>>;
  where?: InputMaybe<PikaichiOnSitePaymentDiscountTypeBoolExp>;
};


export type SubscriptionRootPlanArgs = {
  distinct_on?: InputMaybe<Array<PlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOrderBy>>;
  where?: InputMaybe<PlanBoolExp>;
};


export type SubscriptionRootPlanChoiceArgs = {
  distinct_on?: InputMaybe<Array<PlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanChoiceOrderBy>>;
  where?: InputMaybe<PlanChoiceBoolExp>;
};


export type SubscriptionRootPlanChoiceAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanChoiceOrderBy>>;
  where?: InputMaybe<PlanChoiceBoolExp>;
};


export type SubscriptionRootPlanChoiceByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootPlanChoiceStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PlanChoiceStreamCursorInput>>;
  where?: InputMaybe<PlanChoiceBoolExp>;
};


export type SubscriptionRootPlanFirstOrderCategoryMenuArgs = {
  distinct_on?: InputMaybe<Array<PlanFirstOrderCategoryMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanFirstOrderCategoryMenuOrderBy>>;
  where?: InputMaybe<PlanFirstOrderCategoryMenuBoolExp>;
};


export type SubscriptionRootPlanFirstOrderCategoryMenuByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootPlanFirstOrderCategoryMenuStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PlanFirstOrderCategoryMenuStreamCursorInput>>;
  where?: InputMaybe<PlanFirstOrderCategoryMenuBoolExp>;
};


export type SubscriptionRootPlanGroupArgs = {
  distinct_on?: InputMaybe<Array<PlanGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanGroupOrderBy>>;
  where?: InputMaybe<PlanGroupBoolExp>;
};


export type SubscriptionRootPlanGroupByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootPlanGroupStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PlanGroupStreamCursorInput>>;
  where?: InputMaybe<PlanGroupBoolExp>;
};


export type SubscriptionRootPlanMenuCategoryArgs = {
  distinct_on?: InputMaybe<Array<PlanMenuCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanMenuCategoryOrderBy>>;
  where?: InputMaybe<PlanMenuCategoryBoolExp>;
};


export type SubscriptionRootPlanMenuCategoryByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootPlanMenuCategoryStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PlanMenuCategoryStreamCursorInput>>;
  where?: InputMaybe<PlanMenuCategoryBoolExp>;
};


export type SubscriptionRootPlanOptionArgs = {
  distinct_on?: InputMaybe<Array<PlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOptionOrderBy>>;
  where?: InputMaybe<PlanOptionBoolExp>;
};


export type SubscriptionRootPlanOptionAggregateArgs = {
  distinct_on?: InputMaybe<Array<PlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanOptionOrderBy>>;
  where?: InputMaybe<PlanOptionBoolExp>;
};


export type SubscriptionRootPlanOptionByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootPlanOptionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PlanOptionStreamCursorInput>>;
  where?: InputMaybe<PlanOptionBoolExp>;
};


export type SubscriptionRootPlanByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootPlanMenuCategoriesCategoryArgs = {
  distinct_on?: InputMaybe<Array<PlanMenuCategoriesCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PlanMenuCategoriesCategoryOrderBy>>;
  where?: InputMaybe<PlanMenuCategoriesCategoryBoolExp>;
};


export type SubscriptionRootPlanMenuCategoriesCategoryStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PlanMenuCategoriesCategoryStreamCursorInput>>;
  where?: InputMaybe<PlanMenuCategoriesCategoryBoolExp>;
};


export type SubscriptionRootPlanStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PlanStreamCursorInput>>;
  where?: InputMaybe<PlanBoolExp>;
};


export type SubscriptionRootQuestionArgs = {
  distinct_on?: InputMaybe<Array<QuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionOrderBy>>;
  where?: InputMaybe<QuestionBoolExp>;
};


export type SubscriptionRootQuestionAnswerArgs = {
  distinct_on?: InputMaybe<Array<QuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionAnswerOrderBy>>;
  where?: InputMaybe<QuestionAnswerBoolExp>;
};


export type SubscriptionRootQuestionAnswerByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootQuestionAnswerStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QuestionAnswerStreamCursorInput>>;
  where?: InputMaybe<QuestionAnswerBoolExp>;
};


export type SubscriptionRootQuestionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootQuestionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QuestionStreamCursorInput>>;
  where?: InputMaybe<QuestionBoolExp>;
};


export type SubscriptionRootQuestionnaireArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireOrderBy>>;
  where?: InputMaybe<QuestionnaireBoolExp>;
};


export type SubscriptionRootQuestionnaireAnswerArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireAnswerOrderBy>>;
  where?: InputMaybe<QuestionnaireAnswerBoolExp>;
};


export type SubscriptionRootQuestionnaireAnswerByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootQuestionnaireAnswerStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QuestionnaireAnswerStreamCursorInput>>;
  where?: InputMaybe<QuestionnaireAnswerBoolExp>;
};


export type SubscriptionRootQuestionnaireConfigArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireConfigOrderBy>>;
  where?: InputMaybe<QuestionnaireConfigBoolExp>;
};


export type SubscriptionRootQuestionnaireConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootQuestionnaireConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QuestionnaireConfigStreamCursorInput>>;
  where?: InputMaybe<QuestionnaireConfigBoolExp>;
};


export type SubscriptionRootQuestionnaireQuestionArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireQuestionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<QuestionnaireQuestionOrderBy>>;
  where?: InputMaybe<QuestionnaireQuestionBoolExp>;
};


export type SubscriptionRootQuestionnaireQuestionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QuestionnaireQuestionStreamCursorInput>>;
  where?: InputMaybe<QuestionnaireQuestionBoolExp>;
};


export type SubscriptionRootQuestionnaireByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootQuestionnaireStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<QuestionnaireStreamCursorInput>>;
  where?: InputMaybe<QuestionnaireBoolExp>;
};


export type SubscriptionRootReceiptArgs = {
  distinct_on?: InputMaybe<Array<ReceiptSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReceiptOrderBy>>;
  where?: InputMaybe<ReceiptBoolExp>;
};


export type SubscriptionRootReceiptByPkArgs = {
  receiptId: Scalars['uuid'];
};


export type SubscriptionRootReceiptStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReceiptStreamCursorInput>>;
  where?: InputMaybe<ReceiptBoolExp>;
};


export type SubscriptionRootReservationDashboardAccountAccessibleShopArgs = {
  distinct_on?: InputMaybe<Array<ReservationDashboardAccountAccessibleShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ReservationDashboardAccountAccessibleShopOrderBy>>;
  where?: InputMaybe<ReservationDashboardAccountAccessibleShopBoolExp>;
};


export type SubscriptionRootReservationDashboardAccountAccessibleShopByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootReservationDashboardAccountAccessibleShopStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ReservationDashboardAccountAccessibleShopStreamCursorInput>>;
  where?: InputMaybe<ReservationDashboardAccountAccessibleShopBoolExp>;
};


export type SubscriptionRootRoleArgs = {
  distinct_on?: InputMaybe<Array<RoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleOrderBy>>;
  where?: InputMaybe<RoleBoolExp>;
};


export type SubscriptionRootRoleTablesTableArgs = {
  distinct_on?: InputMaybe<Array<RoleTablesTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleTablesTableOrderBy>>;
  where?: InputMaybe<RoleTablesTableBoolExp>;
};


export type SubscriptionRootRoleTablesTableStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RoleTablesTableStreamCursorInput>>;
  where?: InputMaybe<RoleTablesTableBoolExp>;
};


export type SubscriptionRootRoleByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootRoleStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RoleStreamCursorInput>>;
  where?: InputMaybe<RoleBoolExp>;
};


export type SubscriptionRootSalesBudgetAllocationSettingArgs = {
  distinct_on?: InputMaybe<Array<SalesBudgetAllocationSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesBudgetAllocationSettingOrderBy>>;
  where?: InputMaybe<SalesBudgetAllocationSettingBoolExp>;
};


export type SubscriptionRootSalesBudgetAllocationSettingByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootSalesBudgetAllocationSettingStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SalesBudgetAllocationSettingStreamCursorInput>>;
  where?: InputMaybe<SalesBudgetAllocationSettingBoolExp>;
};


export type SubscriptionRootScoreTypeQuestionAnswerArgs = {
  distinct_on?: InputMaybe<Array<ScoreTypeQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ScoreTypeQuestionAnswerOrderBy>>;
  where?: InputMaybe<ScoreTypeQuestionAnswerBoolExp>;
};


export type SubscriptionRootScoreTypeQuestionAnswerStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ScoreTypeQuestionAnswerStreamCursorInput>>;
  where?: InputMaybe<ScoreTypeQuestionAnswerBoolExp>;
};


export type SubscriptionRootServiceAdminArgs = {
  distinct_on?: InputMaybe<Array<ServiceAdminSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceAdminOrderBy>>;
  where?: InputMaybe<ServiceAdminBoolExp>;
};


export type SubscriptionRootServiceAdminByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootServiceAdminStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceAdminStreamCursorInput>>;
  where?: InputMaybe<ServiceAdminBoolExp>;
};


export type SubscriptionRootServiceChargeConfigArgs = {
  distinct_on?: InputMaybe<Array<ServiceChargeConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceChargeConfigOrderBy>>;
  where?: InputMaybe<ServiceChargeConfigBoolExp>;
};


export type SubscriptionRootServiceChargeConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootServiceChargeConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceChargeConfigStreamCursorInput>>;
  where?: InputMaybe<ServiceChargeConfigBoolExp>;
};


export type SubscriptionRootServiceChargeMetaArgs = {
  distinct_on?: InputMaybe<Array<ServiceChargeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ServiceChargeMetaOrderBy>>;
  where?: InputMaybe<ServiceChargeMetaBoolExp>;
};


export type SubscriptionRootServiceChargeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootServiceChargeMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ServiceChargeMetaStreamCursorInput>>;
  where?: InputMaybe<ServiceChargeMetaBoolExp>;
};


export type SubscriptionRootShopArgs = {
  distinct_on?: InputMaybe<Array<ShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOrderBy>>;
  where?: InputMaybe<ShopBoolExp>;
};


export type SubscriptionRootShopBusinessOperationHourArgs = {
  distinct_on?: InputMaybe<Array<ShopBusinessOperationHourSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopBusinessOperationHourOrderBy>>;
  where?: InputMaybe<ShopBusinessOperationHourBoolExp>;
};


export type SubscriptionRootShopBusinessOperationHourByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootShopBusinessOperationHourStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopBusinessOperationHourStreamCursorInput>>;
  where?: InputMaybe<ShopBusinessOperationHourBoolExp>;
};


export type SubscriptionRootShopChoiceArgs = {
  distinct_on?: InputMaybe<Array<ShopChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopChoiceOrderBy>>;
  where?: InputMaybe<ShopChoiceBoolExp>;
};


export type SubscriptionRootShopChoiceByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootShopChoiceStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopChoiceStreamCursorInput>>;
  where?: InputMaybe<ShopChoiceBoolExp>;
};


export type SubscriptionRootShopClerkArgs = {
  distinct_on?: InputMaybe<Array<ShopClerkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopClerkOrderBy>>;
  where?: InputMaybe<ShopClerkBoolExp>;
};


export type SubscriptionRootShopClerkByPkArgs = {
  _clerkId: Scalars['Int'];
  shopId: Scalars['uuid'];
};


export type SubscriptionRootShopClerkStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopClerkStreamCursorInput>>;
  where?: InputMaybe<ShopClerkBoolExp>;
};


export type SubscriptionRootShopClientArgs = {
  distinct_on?: InputMaybe<Array<ShopClientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopClientOrderBy>>;
  where?: InputMaybe<ShopClientBoolExp>;
};


export type SubscriptionRootShopClientByPkArgs = {
  shopClientId: Scalars['String'];
};


export type SubscriptionRootShopClientStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopClientStreamCursorInput>>;
  where?: InputMaybe<ShopClientBoolExp>;
};


export type SubscriptionRootShopCookingItemRoleArgs = {
  distinct_on?: InputMaybe<Array<ShopCookingItemRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopCookingItemRoleOrderBy>>;
  where?: InputMaybe<ShopCookingItemRoleBoolExp>;
};


export type SubscriptionRootShopCookingItemRoleByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootShopCookingItemRoleStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopCookingItemRoleStreamCursorInput>>;
  where?: InputMaybe<ShopCookingItemRoleBoolExp>;
};


export type SubscriptionRootShopDiscountTypeArgs = {
  distinct_on?: InputMaybe<Array<ShopDiscountTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopDiscountTypeOrderBy>>;
  where?: InputMaybe<ShopDiscountTypeBoolExp>;
};


export type SubscriptionRootShopDiscountTypeByPkArgs = {
  shopId: Scalars['uuid'];
  type: Scalars['String'];
};


export type SubscriptionRootShopDiscountTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopDiscountTypeStreamCursorInput>>;
  where?: InputMaybe<ShopDiscountTypeBoolExp>;
};


export type SubscriptionRootShopInflowSourceTagArgs = {
  distinct_on?: InputMaybe<Array<ShopInflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopInflowSourceTagOrderBy>>;
  where?: InputMaybe<ShopInflowSourceTagBoolExp>;
};


export type SubscriptionRootShopInflowSourceTagStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopInflowSourceTagStreamCursorInput>>;
  where?: InputMaybe<ShopInflowSourceTagBoolExp>;
};


export type SubscriptionRootShopMemberArgs = {
  distinct_on?: InputMaybe<Array<ShopMemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMemberOrderBy>>;
  where?: InputMaybe<ShopMemberBoolExp>;
};


export type SubscriptionRootShopMemberStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopMemberStreamCursorInput>>;
  where?: InputMaybe<ShopMemberBoolExp>;
};


export type SubscriptionRootShopMenuArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuOrderBy>>;
  where?: InputMaybe<ShopMenuBoolExp>;
};


export type SubscriptionRootShopMenuCookingItemArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuCookingItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuCookingItemOrderBy>>;
  where?: InputMaybe<ShopMenuCookingItemBoolExp>;
};


export type SubscriptionRootShopMenuCookingItemStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopMenuCookingItemStreamCursorInput>>;
  where?: InputMaybe<ShopMenuCookingItemBoolExp>;
};


export type SubscriptionRootShopMenuKdDisplayTargetArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuKdDisplayTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuKdDisplayTargetOrderBy>>;
  where?: InputMaybe<ShopMenuKdDisplayTargetBoolExp>;
};


export type SubscriptionRootShopMenuKdDisplayTargetByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootShopMenuKdDisplayTargetStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopMenuKdDisplayTargetStreamCursorInput>>;
  where?: InputMaybe<ShopMenuKdDisplayTargetBoolExp>;
};


export type SubscriptionRootShopMenuKitchenRoleArgs = {
  distinct_on?: InputMaybe<Array<ShopMenuKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopMenuKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
};


export type SubscriptionRootShopMenuKitchenRoleStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopMenuKitchenRoleStreamCursorInput>>;
  where?: InputMaybe<ShopMenuKitchenRoleBoolExp>;
};


export type SubscriptionRootShopMenuByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootShopMenuStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopMenuStreamCursorInput>>;
  where?: InputMaybe<ShopMenuBoolExp>;
};


export type SubscriptionRootShopOptionKitchenRoleArgs = {
  distinct_on?: InputMaybe<Array<ShopOptionKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOptionKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopOptionKitchenRoleBoolExp>;
};


export type SubscriptionRootShopOptionKitchenRoleByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootShopOptionKitchenRoleStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopOptionKitchenRoleStreamCursorInput>>;
  where?: InputMaybe<ShopOptionKitchenRoleBoolExp>;
};


export type SubscriptionRootShopOrderableTimeTermArgs = {
  distinct_on?: InputMaybe<Array<ShopOrderableTimeTermSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopOrderableTimeTermOrderBy>>;
  where?: InputMaybe<ShopOrderableTimeTermBoolExp>;
};


export type SubscriptionRootShopOrderableTimeTermByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootShopOrderableTimeTermStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopOrderableTimeTermStreamCursorInput>>;
  where?: InputMaybe<ShopOrderableTimeTermBoolExp>;
};


export type SubscriptionRootShopPaymentTypeArgs = {
  distinct_on?: InputMaybe<Array<ShopPaymentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPaymentTypeOrderBy>>;
  where?: InputMaybe<ShopPaymentTypeBoolExp>;
};


export type SubscriptionRootShopPaymentTypeByPkArgs = {
  shopId: Scalars['uuid'];
  type: Scalars['String'];
};


export type SubscriptionRootShopPaymentTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopPaymentTypeStreamCursorInput>>;
  where?: InputMaybe<ShopPaymentTypeBoolExp>;
};


export type SubscriptionRootShopPlanArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOrderBy>>;
  where?: InputMaybe<ShopPlanBoolExp>;
};


export type SubscriptionRootShopPlanChoiceArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanChoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanChoiceOrderBy>>;
  where?: InputMaybe<ShopPlanChoiceBoolExp>;
};


export type SubscriptionRootShopPlanChoiceByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootShopPlanChoiceStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopPlanChoiceStreamCursorInput>>;
  where?: InputMaybe<ShopPlanChoiceBoolExp>;
};


export type SubscriptionRootShopPlanKitchenRoleArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
};


export type SubscriptionRootShopPlanKitchenRoleStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopPlanKitchenRoleStreamCursorInput>>;
  where?: InputMaybe<ShopPlanKitchenRoleBoolExp>;
};


export type SubscriptionRootShopPlanOptionArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanOptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOptionOrderBy>>;
  where?: InputMaybe<ShopPlanOptionBoolExp>;
};


export type SubscriptionRootShopPlanOptionKitchenRoleArgs = {
  distinct_on?: InputMaybe<Array<ShopPlanOptionKitchenRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ShopPlanOptionKitchenRoleOrderBy>>;
  where?: InputMaybe<ShopPlanOptionKitchenRoleBoolExp>;
};


export type SubscriptionRootShopPlanOptionKitchenRoleStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopPlanOptionKitchenRoleStreamCursorInput>>;
  where?: InputMaybe<ShopPlanOptionKitchenRoleBoolExp>;
};


export type SubscriptionRootShopPlanOptionByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootShopPlanOptionStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopPlanOptionStreamCursorInput>>;
  where?: InputMaybe<ShopPlanOptionBoolExp>;
};


export type SubscriptionRootShopPlanByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootShopPlanStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopPlanStreamCursorInput>>;
  where?: InputMaybe<ShopPlanBoolExp>;
};


export type SubscriptionRootShopByPkArgs = {
  shopId: Scalars['uuid'];
};


export type SubscriptionRootShopStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ShopStreamCursorInput>>;
  where?: InputMaybe<ShopBoolExp>;
};


export type SubscriptionRootSpecificTimeSurchargeConfigArgs = {
  distinct_on?: InputMaybe<Array<SpecificTimeSurchargeConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SpecificTimeSurchargeConfigOrderBy>>;
  where?: InputMaybe<SpecificTimeSurchargeConfigBoolExp>;
};


export type SubscriptionRootSpecificTimeSurchargeConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootSpecificTimeSurchargeConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SpecificTimeSurchargeConfigStreamCursorInput>>;
  where?: InputMaybe<SpecificTimeSurchargeConfigBoolExp>;
};


export type SubscriptionRootSpecificTimeSurchargeMetaArgs = {
  distinct_on?: InputMaybe<Array<SpecificTimeSurchargeMetaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SpecificTimeSurchargeMetaOrderBy>>;
  where?: InputMaybe<SpecificTimeSurchargeMetaBoolExp>;
};


export type SubscriptionRootSpecificTimeSurchargeMetaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootSpecificTimeSurchargeMetaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SpecificTimeSurchargeMetaStreamCursorInput>>;
  where?: InputMaybe<SpecificTimeSurchargeMetaBoolExp>;
};


export type SubscriptionRootSteraOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<SteraOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SteraOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<SteraOnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootSteraOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootSteraOnSitePaymentDetailTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SteraOnSitePaymentDetailTypeStreamCursorInput>>;
  where?: InputMaybe<SteraOnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootStockArgs = {
  distinct_on?: InputMaybe<Array<StockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<StockOrderBy>>;
  where?: InputMaybe<StockBoolExp>;
};


export type SubscriptionRootStockByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootStockStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<StockStreamCursorInput>>;
  where?: InputMaybe<StockBoolExp>;
};


export type SubscriptionRootSupportedLocaleArgs = {
  distinct_on?: InputMaybe<Array<SupportedLocaleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SupportedLocaleOrderBy>>;
  where?: InputMaybe<SupportedLocaleBoolExp>;
};


export type SubscriptionRootSupportedLocaleByPkArgs = {
  value: Scalars['String'];
};


export type SubscriptionRootSupportedLocaleStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SupportedLocaleStreamCursorInput>>;
  where?: InputMaybe<SupportedLocaleBoolExp>;
};


export type SubscriptionRootTableArgs = {
  distinct_on?: InputMaybe<Array<TableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableOrderBy>>;
  where?: InputMaybe<TableBoolExp>;
};


export type SubscriptionRootTableAreaArgs = {
  distinct_on?: InputMaybe<Array<TableAreaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableAreaOrderBy>>;
  where?: InputMaybe<TableAreaBoolExp>;
};


export type SubscriptionRootTableAreaAggregateArgs = {
  distinct_on?: InputMaybe<Array<TableAreaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableAreaOrderBy>>;
  where?: InputMaybe<TableAreaBoolExp>;
};


export type SubscriptionRootTableAreaByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTableAreaStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TableAreaStreamCursorInput>>;
  where?: InputMaybe<TableAreaBoolExp>;
};


export type SubscriptionRootTableUserArgs = {
  distinct_on?: InputMaybe<Array<TableUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserOrderBy>>;
  where?: InputMaybe<TableUserBoolExp>;
};


export type SubscriptionRootTableUserCustomerArgs = {
  distinct_on?: InputMaybe<Array<TableUserCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserCustomerOrderBy>>;
  where?: InputMaybe<TableUserCustomerBoolExp>;
};


export type SubscriptionRootTableUserCustomerByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTableUserCustomerStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TableUserCustomerStreamCursorInput>>;
  where?: InputMaybe<TableUserCustomerBoolExp>;
};


export type SubscriptionRootTableUserByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTableUserStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TableUserStreamCursorInput>>;
  where?: InputMaybe<TableUserBoolExp>;
};


export type SubscriptionRootTableAggregateArgs = {
  distinct_on?: InputMaybe<Array<TableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableOrderBy>>;
  where?: InputMaybe<TableBoolExp>;
};


export type SubscriptionRootTableByPkArgs = {
  serial: Scalars['Int'];
};


export type SubscriptionRootTableStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TableStreamCursorInput>>;
  where?: InputMaybe<TableBoolExp>;
};


export type SubscriptionRootTaxOfficeArgs = {
  distinct_on?: InputMaybe<Array<TaxOfficeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TaxOfficeOrderBy>>;
  where?: InputMaybe<TaxOfficeBoolExp>;
};


export type SubscriptionRootTaxOfficeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTaxOfficeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TaxOfficeStreamCursorInput>>;
  where?: InputMaybe<TaxOfficeBoolExp>;
};


export type SubscriptionRootTecAggregationConfigArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationConfigOrderBy>>;
  where?: InputMaybe<TecAggregationConfigBoolExp>;
};


export type SubscriptionRootTecAggregationConfigByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTecAggregationConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TecAggregationConfigStreamCursorInput>>;
  where?: InputMaybe<TecAggregationConfigBoolExp>;
};


export type SubscriptionRootTecAggregationMediaMapArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationMediaMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationMediaMapOrderBy>>;
  where?: InputMaybe<TecAggregationMediaMapBoolExp>;
};


export type SubscriptionRootTecAggregationMediaMapByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTecAggregationMediaMapStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TecAggregationMediaMapStreamCursorInput>>;
  where?: InputMaybe<TecAggregationMediaMapBoolExp>;
};


export type SubscriptionRootTecAggregationMenuArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationMenuOrderBy>>;
  where?: InputMaybe<TecAggregationMenuBoolExp>;
};


export type SubscriptionRootTecAggregationMenuByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTecAggregationMenuStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TecAggregationMenuStreamCursorInput>>;
  where?: InputMaybe<TecAggregationMenuBoolExp>;
};


export type SubscriptionRootTecAggregationOnSitePaymentDetailTypeArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootTecAggregationOnSitePaymentDetailTypeByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTecAggregationOnSitePaymentDetailTypeStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TecAggregationOnSitePaymentDetailTypeStreamCursorInput>>;
  where?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
};


export type SubscriptionRootTextTypeQuestionAnswerArgs = {
  distinct_on?: InputMaybe<Array<TextTypeQuestionAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TextTypeQuestionAnswerOrderBy>>;
  where?: InputMaybe<TextTypeQuestionAnswerBoolExp>;
};


export type SubscriptionRootTextTypeQuestionAnswerStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TextTypeQuestionAnswerStreamCursorInput>>;
  where?: InputMaybe<TextTypeQuestionAnswerBoolExp>;
};


export type SubscriptionRootTranslationArgs = {
  distinct_on?: InputMaybe<Array<TranslationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TranslationOrderBy>>;
  where?: InputMaybe<TranslationBoolExp>;
};


export type SubscriptionRootTranslationByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTranslationStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TranslationStreamCursorInput>>;
  where?: InputMaybe<TranslationBoolExp>;
};


export type SubscriptionRootWinboardConfigArgs = {
  distinct_on?: InputMaybe<Array<WinboardConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WinboardConfigOrderBy>>;
  where?: InputMaybe<WinboardConfigBoolExp>;
};


export type SubscriptionRootWinboardConfigByPkArgs = {
  winboardConfigId: Scalars['uuid'];
};


export type SubscriptionRootWinboardConfigStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WinboardConfigStreamCursorInput>>;
  where?: InputMaybe<WinboardConfigBoolExp>;
};


export type SubscriptionRootWinboardInflowSourceTagArgs = {
  distinct_on?: InputMaybe<Array<WinboardInflowSourceTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WinboardInflowSourceTagOrderBy>>;
  where?: InputMaybe<WinboardInflowSourceTagBoolExp>;
};


export type SubscriptionRootWinboardInflowSourceTagByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootWinboardInflowSourceTagStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WinboardInflowSourceTagStreamCursorInput>>;
  where?: InputMaybe<WinboardInflowSourceTagBoolExp>;
};


export type SubscriptionRootWinboardMenuArgs = {
  distinct_on?: InputMaybe<Array<WinboardMenuSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WinboardMenuOrderBy>>;
  where?: InputMaybe<WinboardMenuBoolExp>;
};


export type SubscriptionRootWinboardMenuByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootWinboardMenuStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WinboardMenuStreamCursorInput>>;
  where?: InputMaybe<WinboardMenuBoolExp>;
};

/** columns and relationships of "supportedLocale" */
export type SupportedLocale = {
  __typename?: 'supportedLocale';
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "supportedLocale". All fields are combined with a logical 'AND'. */
export type SupportedLocaleBoolExp = {
  _and?: InputMaybe<Array<SupportedLocaleBoolExp>>;
  _not?: InputMaybe<SupportedLocaleBoolExp>;
  _or?: InputMaybe<Array<SupportedLocaleBoolExp>>;
  value?: InputMaybe<StringComparisonExp>;
};

export enum SupportedLocaleEnum {
  EnUs = 'en_US',
  KoKr = 'ko_KR',
  ZhCn = 'zh_CN'
}

/** Boolean expression to compare columns of type "supportedLocale_enum". All fields are combined with logical 'AND'. */
export type SupportedLocaleEnumComparisonExp = {
  _eq?: InputMaybe<SupportedLocaleEnum>;
  _in?: InputMaybe<Array<SupportedLocaleEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SupportedLocaleEnum>;
  _nin?: InputMaybe<Array<SupportedLocaleEnum>>;
};

/** Ordering options when selecting data from "supportedLocale". */
export type SupportedLocaleOrderBy = {
  value?: InputMaybe<OrderBy>;
};

/** select columns of table "supportedLocale" */
export enum SupportedLocaleSelectColumn {
  /** column name */
  Value = 'value'
}

/** Streaming cursor of the table "supportedLocale" */
export type SupportedLocaleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SupportedLocaleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type SupportedLocaleStreamCursorValueInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "table" */
export type Table = {
  __typename?: 'table';
  code: Scalars['uuid'];
  /** An array relationship */
  dishUpSlipGroupTables: Array<DishUpSlipGroupTables>;
  id: Scalars['uuid'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  /** An array relationship */
  paymentReceiptRoleTables: Array<PaymentReceiptRoleTable>;
  posTableId?: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  /** An array relationship */
  roleTablesTables: Array<RoleTablesTable>;
  serial: Scalars['Int'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An object relationship */
  tableArea: TableArea;
  tableAreaId: Scalars['uuid'];
  tableId: Scalars['Int'];
  /** An array relationship */
  tableUsers: Array<TableUser>;
};


/** columns and relationships of "table" */
export type TableDishUpSlipGroupTablesArgs = {
  distinct_on?: InputMaybe<Array<DishUpSlipGroupTablesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DishUpSlipGroupTablesOrderBy>>;
  where?: InputMaybe<DishUpSlipGroupTablesBoolExp>;
};


/** columns and relationships of "table" */
export type TablePaymentReceiptRoleTablesArgs = {
  distinct_on?: InputMaybe<Array<PaymentReceiptRoleTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaymentReceiptRoleTableOrderBy>>;
  where?: InputMaybe<PaymentReceiptRoleTableBoolExp>;
};


/** columns and relationships of "table" */
export type TableRoleTablesTablesArgs = {
  distinct_on?: InputMaybe<Array<RoleTablesTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RoleTablesTableOrderBy>>;
  where?: InputMaybe<RoleTablesTableBoolExp>;
};


/** columns and relationships of "table" */
export type TableTableUsersArgs = {
  distinct_on?: InputMaybe<Array<TableUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserOrderBy>>;
  where?: InputMaybe<TableUserBoolExp>;
};

/** columns and relationships of "tableArea" */
export type TableArea = {
  __typename?: 'tableArea';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  /** An array relationship */
  tables: Array<Table>;
  /** An aggregate relationship */
  tables_aggregate: TableAggregate;
};


/** columns and relationships of "tableArea" */
export type TableAreaTablesArgs = {
  distinct_on?: InputMaybe<Array<TableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableOrderBy>>;
  where?: InputMaybe<TableBoolExp>;
};


/** columns and relationships of "tableArea" */
export type TableAreaTablesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableOrderBy>>;
  where?: InputMaybe<TableBoolExp>;
};

/** aggregated selection of "tableArea" */
export type TableAreaAggregate = {
  __typename?: 'tableArea_aggregate';
  aggregate?: Maybe<TableAreaAggregateFields>;
  nodes: Array<TableArea>;
};

export type TableAreaAggregateBoolExp = {
  count?: InputMaybe<TableAreaAggregateBoolExpCount>;
};

export type TableAreaAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TableAreaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TableAreaBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "tableArea" */
export type TableAreaAggregateFields = {
  __typename?: 'tableArea_aggregate_fields';
  avg?: Maybe<TableAreaAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TableAreaMaxFields>;
  min?: Maybe<TableAreaMinFields>;
  stddev?: Maybe<TableAreaStddevFields>;
  stddev_pop?: Maybe<TableAreaStddevPopFields>;
  stddev_samp?: Maybe<TableAreaStddevSampFields>;
  sum?: Maybe<TableAreaSumFields>;
  var_pop?: Maybe<TableAreaVarPopFields>;
  var_samp?: Maybe<TableAreaVarSampFields>;
  variance?: Maybe<TableAreaVarianceFields>;
};


/** aggregate fields of "tableArea" */
export type TableAreaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TableAreaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tableArea" */
export type TableAreaAggregateOrderBy = {
  avg?: InputMaybe<TableAreaAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TableAreaMaxOrderBy>;
  min?: InputMaybe<TableAreaMinOrderBy>;
  stddev?: InputMaybe<TableAreaStddevOrderBy>;
  stddev_pop?: InputMaybe<TableAreaStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TableAreaStddevSampOrderBy>;
  sum?: InputMaybe<TableAreaSumOrderBy>;
  var_pop?: InputMaybe<TableAreaVarPopOrderBy>;
  var_samp?: InputMaybe<TableAreaVarSampOrderBy>;
  variance?: InputMaybe<TableAreaVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "tableArea" */
export type TableAreaArrRelInsertInput = {
  data: Array<TableAreaInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TableAreaOnConflict>;
};

/** aggregate avg on columns */
export type TableAreaAvgFields = {
  __typename?: 'tableArea_avg_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tableArea" */
export type TableAreaAvgOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "tableArea". All fields are combined with a logical 'AND'. */
export type TableAreaBoolExp = {
  _and?: InputMaybe<Array<TableAreaBoolExp>>;
  _not?: InputMaybe<TableAreaBoolExp>;
  _or?: InputMaybe<Array<TableAreaBoolExp>>;
  archivedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  tables?: InputMaybe<TableBoolExp>;
  tables_aggregate?: InputMaybe<TableAggregateBoolExp>;
};

/** unique or primary key constraints on table "tableArea" */
export enum TableAreaConstraint {
  /** unique or primary key constraint on columns "id" */
  TableAreaPkey = 'tableArea_pkey'
}

/** input type for incrementing numeric columns in table "tableArea" */
export type TableAreaIncInput = {
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tableArea" */
export type TableAreaInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  tables?: InputMaybe<TableArrRelInsertInput>;
};

/** aggregate max on columns */
export type TableAreaMaxFields = {
  __typename?: 'tableArea_max_fields';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  shopId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tableArea" */
export type TableAreaMaxOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TableAreaMinFields = {
  __typename?: 'tableArea_min_fields';
  archivedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  shopId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tableArea" */
export type TableAreaMinOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tableArea" */
export type TableAreaMutationResponse = {
  __typename?: 'tableArea_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TableArea>;
};

/** input type for inserting object relation for remote table "tableArea" */
export type TableAreaObjRelInsertInput = {
  data: TableAreaInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TableAreaOnConflict>;
};

/** on_conflict condition type for table "tableArea" */
export type TableAreaOnConflict = {
  constraint: TableAreaConstraint;
  update_columns?: Array<TableAreaUpdateColumn>;
  where?: InputMaybe<TableAreaBoolExp>;
};

/** Ordering options when selecting data from "tableArea". */
export type TableAreaOrderBy = {
  archivedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tables_aggregate?: InputMaybe<TableAggregateOrderBy>;
};

/** primary key columns input for table: tableArea */
export type TableAreaPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "tableArea" */
export enum TableAreaSelectColumn {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "tableArea" */
export type TableAreaSetInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type TableAreaStddevFields = {
  __typename?: 'tableArea_stddev_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tableArea" */
export type TableAreaStddevOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TableAreaStddevPopFields = {
  __typename?: 'tableArea_stddev_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tableArea" */
export type TableAreaStddevPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TableAreaStddevSampFields = {
  __typename?: 'tableArea_stddev_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tableArea" */
export type TableAreaStddevSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "tableArea" */
export type TableAreaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TableAreaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type TableAreaStreamCursorValueInput = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type TableAreaSumFields = {
  __typename?: 'tableArea_sum_fields';
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tableArea" */
export type TableAreaSumOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** update columns of table "tableArea" */
export enum TableAreaUpdateColumn {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  ShopId = 'shopId'
}

export type TableAreaUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TableAreaIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TableAreaSetInput>;
  /** filter the rows which have to be updated */
  where: TableAreaBoolExp;
};

/** aggregate var_pop on columns */
export type TableAreaVarPopFields = {
  __typename?: 'tableArea_var_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tableArea" */
export type TableAreaVarPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TableAreaVarSampFields = {
  __typename?: 'tableArea_var_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tableArea" */
export type TableAreaVarSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TableAreaVarianceFields = {
  __typename?: 'tableArea_variance_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tableArea" */
export type TableAreaVarianceOrderBy = {
  priority?: InputMaybe<OrderBy>;
};

/** columns and relationships of "tableUser" */
export type TableUser = {
  __typename?: 'tableUser';
  _tableId: Scalars['Int'];
  activatedAt: Scalars['timestamptz'];
  /** An array relationship */
  activePlans: Array<ActivePlan>;
  billableTableUserId: Scalars['uuid'];
  /** An array relationship */
  checkInEvents: Array<CheckInEvent>;
  /** An array relationship */
  coineyPayments: Array<CoineyPayment>;
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  customerSegment?: Maybe<Scalars['String']>;
  deactivatedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  inflowSourceTag: InflowSourceTag;
  inflowSourceTagId: Scalars['uuid'];
  numPeople: Scalars['Int'];
  /** An array relationship */
  onSitePaymentTableUsers: Array<OnSitePaymentTableUsers>;
  /** An array relationship */
  orders: Array<Order>;
  /** An array relationship */
  ordersByOrderedtableuserid: Array<Order>;
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  state: TableUserStateEnum;
  /** An object relationship */
  table: Table;
  tableClearReason?: Maybe<Scalars['String']>;
  /** An object relationship */
  tableClearedClerk?: Maybe<Clerk>;
  tableClearedClerkId?: Maybe<Scalars['Int']>;
  tableId: Scalars['uuid'];
  /** An array relationship */
  tableUserCustomers: Array<TableUserCustomer>;
  /** An array relationship */
  tableUsersInGroup: Array<TableUser>;
  taxAmount: Scalars['Int'];
  taxIncludedPaidAmount: Scalars['Int'];
};


/** columns and relationships of "tableUser" */
export type TableUserActivePlansArgs = {
  distinct_on?: InputMaybe<Array<ActivePlanSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ActivePlanOrderBy>>;
  where?: InputMaybe<ActivePlanBoolExp>;
};


/** columns and relationships of "tableUser" */
export type TableUserCheckInEventsArgs = {
  distinct_on?: InputMaybe<Array<CheckInEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CheckInEventOrderBy>>;
  where?: InputMaybe<CheckInEventBoolExp>;
};


/** columns and relationships of "tableUser" */
export type TableUserCoineyPaymentsArgs = {
  distinct_on?: InputMaybe<Array<CoineyPaymentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CoineyPaymentOrderBy>>;
  where?: InputMaybe<CoineyPaymentBoolExp>;
};


/** columns and relationships of "tableUser" */
export type TableUserOnSitePaymentTableUsersArgs = {
  distinct_on?: InputMaybe<Array<OnSitePaymentTableUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OnSitePaymentTableUsersOrderBy>>;
  where?: InputMaybe<OnSitePaymentTableUsersBoolExp>;
};


/** columns and relationships of "tableUser" */
export type TableUserOrdersArgs = {
  distinct_on?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


/** columns and relationships of "tableUser" */
export type TableUserOrdersByOrderedtableuseridArgs = {
  distinct_on?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


/** columns and relationships of "tableUser" */
export type TableUserTableUserCustomersArgs = {
  distinct_on?: InputMaybe<Array<TableUserCustomerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserCustomerOrderBy>>;
  where?: InputMaybe<TableUserCustomerBoolExp>;
};


/** columns and relationships of "tableUser" */
export type TableUserTableUsersInGroupArgs = {
  distinct_on?: InputMaybe<Array<TableUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TableUserOrderBy>>;
  where?: InputMaybe<TableUserBoolExp>;
};

/** columns and relationships of "tableUserCustomer" */
export type TableUserCustomer = {
  __typename?: 'tableUserCustomer';
  /** An object relationship */
  customer: Customer;
  customerId: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  tableUser: TableUser;
  tableUserId: Scalars['uuid'];
};

/** order by aggregate values of table "tableUserCustomer" */
export type TableUserCustomerAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TableUserCustomerMaxOrderBy>;
  min?: InputMaybe<TableUserCustomerMinOrderBy>;
};

/** Boolean expression to filter rows from the table "tableUserCustomer". All fields are combined with a logical 'AND'. */
export type TableUserCustomerBoolExp = {
  _and?: InputMaybe<Array<TableUserCustomerBoolExp>>;
  _not?: InputMaybe<TableUserCustomerBoolExp>;
  _or?: InputMaybe<Array<TableUserCustomerBoolExp>>;
  customer?: InputMaybe<CustomerBoolExp>;
  customerId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  tableUser?: InputMaybe<TableUserBoolExp>;
  tableUserId?: InputMaybe<UuidComparisonExp>;
};

/** order by max() on columns of table "tableUserCustomer" */
export type TableUserCustomerMaxOrderBy = {
  customerId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "tableUserCustomer" */
export type TableUserCustomerMinOrderBy = {
  customerId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "tableUserCustomer". */
export type TableUserCustomerOrderBy = {
  customer?: InputMaybe<CustomerOrderBy>;
  customerId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  tableUser?: InputMaybe<TableUserOrderBy>;
  tableUserId?: InputMaybe<OrderBy>;
};

/** select columns of table "tableUserCustomer" */
export enum TableUserCustomerSelectColumn {
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Id = 'id',
  /** column name */
  TableUserId = 'tableUserId'
}

/** Streaming cursor of the table "tableUserCustomer" */
export type TableUserCustomerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TableUserCustomerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type TableUserCustomerStreamCursorValueInput = {
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  tableUserId?: InputMaybe<Scalars['uuid']>;
};

export enum TableUserStateEnum {
  Active = 'active',
  Inactive = 'inactive',
  RegisterCheckInProgress = 'registerCheckInProgress',
  TableCheckInProgress = 'tableCheckInProgress',
  TableCheckRequested = 'tableCheckRequested'
}

/** Boolean expression to compare columns of type "tableUserState_enum". All fields are combined with logical 'AND'. */
export type TableUserStateEnumComparisonExp = {
  _eq?: InputMaybe<TableUserStateEnum>;
  _in?: InputMaybe<Array<TableUserStateEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TableUserStateEnum>;
  _nin?: InputMaybe<Array<TableUserStateEnum>>;
};

/** order by aggregate values of table "tableUser" */
export type TableUserAggregateOrderBy = {
  avg?: InputMaybe<TableUserAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TableUserMaxOrderBy>;
  min?: InputMaybe<TableUserMinOrderBy>;
  stddev?: InputMaybe<TableUserStddevOrderBy>;
  stddev_pop?: InputMaybe<TableUserStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TableUserStddevSampOrderBy>;
  sum?: InputMaybe<TableUserSumOrderBy>;
  var_pop?: InputMaybe<TableUserVarPopOrderBy>;
  var_samp?: InputMaybe<TableUserVarSampOrderBy>;
  variance?: InputMaybe<TableUserVarianceOrderBy>;
};

/** order by avg() on columns of table "tableUser" */
export type TableUserAvgOrderBy = {
  _tableId?: InputMaybe<OrderBy>;
  numPeople?: InputMaybe<OrderBy>;
  tableClearedClerkId?: InputMaybe<OrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxIncludedPaidAmount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "tableUser". All fields are combined with a logical 'AND'. */
export type TableUserBoolExp = {
  _and?: InputMaybe<Array<TableUserBoolExp>>;
  _not?: InputMaybe<TableUserBoolExp>;
  _or?: InputMaybe<Array<TableUserBoolExp>>;
  _tableId?: InputMaybe<IntComparisonExp>;
  activatedAt?: InputMaybe<TimestamptzComparisonExp>;
  activePlans?: InputMaybe<ActivePlanBoolExp>;
  billableTableUserId?: InputMaybe<UuidComparisonExp>;
  checkInEvents?: InputMaybe<CheckInEventBoolExp>;
  coineyPayments?: InputMaybe<CoineyPaymentBoolExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  customerSegment?: InputMaybe<StringComparisonExp>;
  deactivatedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inflowSourceTag?: InputMaybe<InflowSourceTagBoolExp>;
  inflowSourceTagId?: InputMaybe<UuidComparisonExp>;
  numPeople?: InputMaybe<IntComparisonExp>;
  onSitePaymentTableUsers?: InputMaybe<OnSitePaymentTableUsersBoolExp>;
  orders?: InputMaybe<OrderBoolExp>;
  ordersByOrderedtableuserid?: InputMaybe<OrderBoolExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  state?: InputMaybe<TableUserStateEnumComparisonExp>;
  table?: InputMaybe<TableBoolExp>;
  tableClearReason?: InputMaybe<StringComparisonExp>;
  tableClearedClerk?: InputMaybe<ClerkBoolExp>;
  tableClearedClerkId?: InputMaybe<IntComparisonExp>;
  tableId?: InputMaybe<UuidComparisonExp>;
  tableUserCustomers?: InputMaybe<TableUserCustomerBoolExp>;
  tableUsersInGroup?: InputMaybe<TableUserBoolExp>;
  taxAmount?: InputMaybe<IntComparisonExp>;
  taxIncludedPaidAmount?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "tableUser" */
export type TableUserMaxOrderBy = {
  _tableId?: InputMaybe<OrderBy>;
  activatedAt?: InputMaybe<OrderBy>;
  billableTableUserId?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  customerSegment?: InputMaybe<OrderBy>;
  deactivatedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inflowSourceTagId?: InputMaybe<OrderBy>;
  numPeople?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableClearReason?: InputMaybe<OrderBy>;
  tableClearedClerkId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxIncludedPaidAmount?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "tableUser" */
export type TableUserMinOrderBy = {
  _tableId?: InputMaybe<OrderBy>;
  activatedAt?: InputMaybe<OrderBy>;
  billableTableUserId?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  customerSegment?: InputMaybe<OrderBy>;
  deactivatedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inflowSourceTagId?: InputMaybe<OrderBy>;
  numPeople?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableClearReason?: InputMaybe<OrderBy>;
  tableClearedClerkId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxIncludedPaidAmount?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "tableUser". */
export type TableUserOrderBy = {
  _tableId?: InputMaybe<OrderBy>;
  activatedAt?: InputMaybe<OrderBy>;
  activePlans_aggregate?: InputMaybe<ActivePlanAggregateOrderBy>;
  billableTableUserId?: InputMaybe<OrderBy>;
  checkInEvents_aggregate?: InputMaybe<CheckInEventAggregateOrderBy>;
  coineyPayments_aggregate?: InputMaybe<CoineyPaymentAggregateOrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  customerSegment?: InputMaybe<OrderBy>;
  deactivatedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inflowSourceTag?: InputMaybe<InflowSourceTagOrderBy>;
  inflowSourceTagId?: InputMaybe<OrderBy>;
  numPeople?: InputMaybe<OrderBy>;
  onSitePaymentTableUsers_aggregate?: InputMaybe<OnSitePaymentTableUsersAggregateOrderBy>;
  ordersByOrderedtableuserid_aggregate?: InputMaybe<OrderAggregateOrderBy>;
  orders_aggregate?: InputMaybe<OrderAggregateOrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  table?: InputMaybe<TableOrderBy>;
  tableClearReason?: InputMaybe<OrderBy>;
  tableClearedClerk?: InputMaybe<ClerkOrderBy>;
  tableClearedClerkId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
  tableUserCustomers_aggregate?: InputMaybe<TableUserCustomerAggregateOrderBy>;
  tableUsersInGroup_aggregate?: InputMaybe<TableUserAggregateOrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxIncludedPaidAmount?: InputMaybe<OrderBy>;
};

/** select columns of table "tableUser" */
export enum TableUserSelectColumn {
  /** column name */
  _TableId = '_tableId',
  /** column name */
  ActivatedAt = 'activatedAt',
  /** column name */
  BillableTableUserId = 'billableTableUserId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CustomerSegment = 'customerSegment',
  /** column name */
  DeactivatedAt = 'deactivatedAt',
  /** column name */
  Id = 'id',
  /** column name */
  InflowSourceTagId = 'inflowSourceTagId',
  /** column name */
  NumPeople = 'numPeople',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  State = 'state',
  /** column name */
  TableClearReason = 'tableClearReason',
  /** column name */
  TableClearedClerkId = 'tableClearedClerkId',
  /** column name */
  TableId = 'tableId',
  /** column name */
  TaxAmount = 'taxAmount',
  /** column name */
  TaxIncludedPaidAmount = 'taxIncludedPaidAmount'
}

/** order by stddev() on columns of table "tableUser" */
export type TableUserStddevOrderBy = {
  _tableId?: InputMaybe<OrderBy>;
  numPeople?: InputMaybe<OrderBy>;
  tableClearedClerkId?: InputMaybe<OrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxIncludedPaidAmount?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "tableUser" */
export type TableUserStddevPopOrderBy = {
  _tableId?: InputMaybe<OrderBy>;
  numPeople?: InputMaybe<OrderBy>;
  tableClearedClerkId?: InputMaybe<OrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxIncludedPaidAmount?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "tableUser" */
export type TableUserStddevSampOrderBy = {
  _tableId?: InputMaybe<OrderBy>;
  numPeople?: InputMaybe<OrderBy>;
  tableClearedClerkId?: InputMaybe<OrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxIncludedPaidAmount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "tableUser" */
export type TableUserStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TableUserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type TableUserStreamCursorValueInput = {
  _tableId?: InputMaybe<Scalars['Int']>;
  activatedAt?: InputMaybe<Scalars['timestamptz']>;
  billableTableUserId?: InputMaybe<Scalars['uuid']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  customerSegment?: InputMaybe<Scalars['String']>;
  deactivatedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  inflowSourceTagId?: InputMaybe<Scalars['uuid']>;
  numPeople?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<TableUserStateEnum>;
  tableClearReason?: InputMaybe<Scalars['String']>;
  tableClearedClerkId?: InputMaybe<Scalars['Int']>;
  tableId?: InputMaybe<Scalars['uuid']>;
  taxAmount?: InputMaybe<Scalars['Int']>;
  taxIncludedPaidAmount?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tableUser" */
export type TableUserSumOrderBy = {
  _tableId?: InputMaybe<OrderBy>;
  numPeople?: InputMaybe<OrderBy>;
  tableClearedClerkId?: InputMaybe<OrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxIncludedPaidAmount?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "tableUser" */
export type TableUserVarPopOrderBy = {
  _tableId?: InputMaybe<OrderBy>;
  numPeople?: InputMaybe<OrderBy>;
  tableClearedClerkId?: InputMaybe<OrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxIncludedPaidAmount?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "tableUser" */
export type TableUserVarSampOrderBy = {
  _tableId?: InputMaybe<OrderBy>;
  numPeople?: InputMaybe<OrderBy>;
  tableClearedClerkId?: InputMaybe<OrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxIncludedPaidAmount?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "tableUser" */
export type TableUserVarianceOrderBy = {
  _tableId?: InputMaybe<OrderBy>;
  numPeople?: InputMaybe<OrderBy>;
  tableClearedClerkId?: InputMaybe<OrderBy>;
  taxAmount?: InputMaybe<OrderBy>;
  taxIncludedPaidAmount?: InputMaybe<OrderBy>;
};

/** aggregated selection of "table" */
export type TableAggregate = {
  __typename?: 'table_aggregate';
  aggregate?: Maybe<TableAggregateFields>;
  nodes: Array<Table>;
};

export type TableAggregateBoolExp = {
  bool_and?: InputMaybe<TableAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<TableAggregateBoolExpBoolOr>;
  count?: InputMaybe<TableAggregateBoolExpCount>;
};

export type TableAggregateBoolExpBoolAnd = {
  arguments: TableSelectColumnTableAggregateBoolExpBoolAndArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TableBoolExp>;
  predicate: BooleanComparisonExp;
};

export type TableAggregateBoolExpBoolOr = {
  arguments: TableSelectColumnTableAggregateBoolExpBoolOrArgumentsColumnsType;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TableBoolExp>;
  predicate: BooleanComparisonExp;
};

export type TableAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TableSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TableBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "table" */
export type TableAggregateFields = {
  __typename?: 'table_aggregate_fields';
  avg?: Maybe<TableAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TableMaxFields>;
  min?: Maybe<TableMinFields>;
  stddev?: Maybe<TableStddevFields>;
  stddev_pop?: Maybe<TableStddevPopFields>;
  stddev_samp?: Maybe<TableStddevSampFields>;
  sum?: Maybe<TableSumFields>;
  var_pop?: Maybe<TableVarPopFields>;
  var_samp?: Maybe<TableVarSampFields>;
  variance?: Maybe<TableVarianceFields>;
};


/** aggregate fields of "table" */
export type TableAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TableSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "table" */
export type TableAggregateOrderBy = {
  avg?: InputMaybe<TableAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TableMaxOrderBy>;
  min?: InputMaybe<TableMinOrderBy>;
  stddev?: InputMaybe<TableStddevOrderBy>;
  stddev_pop?: InputMaybe<TableStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TableStddevSampOrderBy>;
  sum?: InputMaybe<TableSumOrderBy>;
  var_pop?: InputMaybe<TableVarPopOrderBy>;
  var_samp?: InputMaybe<TableVarSampOrderBy>;
  variance?: InputMaybe<TableVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "table" */
export type TableArrRelInsertInput = {
  data: Array<TableInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TableOnConflict>;
};

/** aggregate avg on columns */
export type TableAvgFields = {
  __typename?: 'table_avg_fields';
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
  tableId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "table" */
export type TableAvgOrderBy = {
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "table". All fields are combined with a logical 'AND'. */
export type TableBoolExp = {
  _and?: InputMaybe<Array<TableBoolExp>>;
  _not?: InputMaybe<TableBoolExp>;
  _or?: InputMaybe<Array<TableBoolExp>>;
  code?: InputMaybe<UuidComparisonExp>;
  dishUpSlipGroupTables?: InputMaybe<DishUpSlipGroupTablesBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isArchived?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  paymentReceiptRoleTables?: InputMaybe<PaymentReceiptRoleTableBoolExp>;
  posTableId?: InputMaybe<StringComparisonExp>;
  priority?: InputMaybe<IntComparisonExp>;
  roleTablesTables?: InputMaybe<RoleTablesTableBoolExp>;
  serial?: InputMaybe<IntComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  tableArea?: InputMaybe<TableAreaBoolExp>;
  tableAreaId?: InputMaybe<UuidComparisonExp>;
  tableId?: InputMaybe<IntComparisonExp>;
  tableUsers?: InputMaybe<TableUserBoolExp>;
};

/** unique or primary key constraints on table "table" */
export enum TableConstraint {
  /** unique or primary key constraint on columns "serial" */
  Idx_46683Primary = 'idx_46683_PRIMARY',
  /** unique or primary key constraint on columns "code" */
  TableCodeKey = 'table_code_key',
  /** unique or primary key constraint on columns "id" */
  TableIdKey = 'table_id_key',
  /** unique or primary key constraint on columns "serial", "shopId" */
  TableTableIdShopIdKey = 'table_tableId_shopId_key'
}

/** input type for incrementing numeric columns in table "table" */
export type TableIncInput = {
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "table" */
export type TableInsertInput = {
  code?: InputMaybe<Scalars['uuid']>;
  dishUpSlipGroupTables?: InputMaybe<DishUpSlipGroupTablesArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  paymentReceiptRoleTables?: InputMaybe<PaymentReceiptRoleTableArrRelInsertInput>;
  posTableId?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  roleTablesTables?: InputMaybe<RoleTablesTableArrRelInsertInput>;
  serial?: InputMaybe<Scalars['Int']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  tableArea?: InputMaybe<TableAreaObjRelInsertInput>;
  tableAreaId: Scalars['uuid'];
};

/** aggregate max on columns */
export type TableMaxFields = {
  __typename?: 'table_max_fields';
  code?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  posTableId?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
  shopId?: Maybe<Scalars['uuid']>;
  tableAreaId?: Maybe<Scalars['uuid']>;
  tableId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "table" */
export type TableMaxOrderBy = {
  code?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  posTableId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableAreaId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TableMinFields = {
  __typename?: 'table_min_fields';
  code?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  posTableId?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
  shopId?: Maybe<Scalars['uuid']>;
  tableAreaId?: Maybe<Scalars['uuid']>;
  tableId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "table" */
export type TableMinOrderBy = {
  code?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  posTableId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableAreaId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "table" */
export type TableMutationResponse = {
  __typename?: 'table_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Table>;
};

/** input type for inserting object relation for remote table "table" */
export type TableObjRelInsertInput = {
  data: TableInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TableOnConflict>;
};

/** on_conflict condition type for table "table" */
export type TableOnConflict = {
  constraint: TableConstraint;
  update_columns?: Array<TableUpdateColumn>;
  where?: InputMaybe<TableBoolExp>;
};

/** Ordering options when selecting data from "table". */
export type TableOrderBy = {
  code?: InputMaybe<OrderBy>;
  dishUpSlipGroupTables_aggregate?: InputMaybe<DishUpSlipGroupTablesAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isArchived?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  paymentReceiptRoleTables_aggregate?: InputMaybe<PaymentReceiptRoleTableAggregateOrderBy>;
  posTableId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  roleTablesTables_aggregate?: InputMaybe<RoleTablesTableAggregateOrderBy>;
  serial?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tableArea?: InputMaybe<TableAreaOrderBy>;
  tableAreaId?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
  tableUsers_aggregate?: InputMaybe<TableUserAggregateOrderBy>;
};

/** primary key columns input for table: table */
export type TablePkColumnsInput = {
  serial: Scalars['Int'];
};

/** select columns of table "table" */
export enum TableSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Name = 'name',
  /** column name */
  PosTableId = 'posTableId',
  /** column name */
  Priority = 'priority',
  /** column name */
  Serial = 'serial',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TableAreaId = 'tableAreaId',
  /** column name */
  TableId = 'tableId'
}

/** select "table_aggregate_bool_exp_bool_and_arguments_columns" columns of table "table" */
export enum TableSelectColumnTableAggregateBoolExpBoolAndArgumentsColumnsType {
  /** column name */
  IsArchived = 'isArchived'
}

/** select "table_aggregate_bool_exp_bool_or_arguments_columns" columns of table "table" */
export enum TableSelectColumnTableAggregateBoolExpBoolOrArgumentsColumnsType {
  /** column name */
  IsArchived = 'isArchived'
}

/** input type for updating data in table "table" */
export type TableSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tableAreaId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type TableStddevFields = {
  __typename?: 'table_stddev_fields';
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
  tableId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "table" */
export type TableStddevOrderBy = {
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TableStddevPopFields = {
  __typename?: 'table_stddev_pop_fields';
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
  tableId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "table" */
export type TableStddevPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TableStddevSampFields = {
  __typename?: 'table_stddev_samp_fields';
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
  tableId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "table" */
export type TableStddevSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "table" */
export type TableStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TableStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type TableStreamCursorValueInput = {
  code?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  posTableId?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tableAreaId?: InputMaybe<Scalars['uuid']>;
  tableId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TableSumFields = {
  __typename?: 'table_sum_fields';
  priority?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['Int']>;
  tableId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "table" */
export type TableSumOrderBy = {
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** update columns of table "table" */
export enum TableUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TableAreaId = 'tableAreaId'
}

export type TableUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TableIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TableSetInput>;
  /** filter the rows which have to be updated */
  where: TableBoolExp;
};

/** aggregate var_pop on columns */
export type TableVarPopFields = {
  __typename?: 'table_var_pop_fields';
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
  tableId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "table" */
export type TableVarPopOrderBy = {
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TableVarSampFields = {
  __typename?: 'table_var_samp_fields';
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
  tableId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "table" */
export type TableVarSampOrderBy = {
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TableVarianceFields = {
  __typename?: 'table_variance_fields';
  priority?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
  tableId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "table" */
export type TableVarianceOrderBy = {
  priority?: InputMaybe<OrderBy>;
  serial?: InputMaybe<OrderBy>;
  tableId?: InputMaybe<OrderBy>;
};

export enum TaxMethodEnum {
  Excluded = 'excluded',
  Included = 'included',
  NonTaxable = 'nonTaxable'
}

/** Boolean expression to compare columns of type "taxMethod_enum". All fields are combined with logical 'AND'. */
export type TaxMethodEnumComparisonExp = {
  _eq?: InputMaybe<TaxMethodEnum>;
  _in?: InputMaybe<Array<TaxMethodEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TaxMethodEnum>;
  _nin?: InputMaybe<Array<TaxMethodEnum>>;
};

/** columns and relationships of "taxOffice" */
export type TaxOffice = {
  __typename?: 'taxOffice';
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "taxOffice". All fields are combined with a logical 'AND'. */
export type TaxOfficeBoolExp = {
  _and?: InputMaybe<Array<TaxOfficeBoolExp>>;
  _not?: InputMaybe<TaxOfficeBoolExp>;
  _or?: InputMaybe<Array<TaxOfficeBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "taxOffice". */
export type TaxOfficeOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "taxOffice" */
export enum TaxOfficeSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** Streaming cursor of the table "taxOffice" */
export type TaxOfficeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TaxOfficeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type TaxOfficeStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "tecAggregationConfig" */
export type TecAggregationConfig = {
  __typename?: 'tecAggregationConfig';
  checkDatePutPath?: Maybe<Scalars['String']>;
  companyCode: Scalars['String'];
  enabled: Scalars['Boolean'];
  ftpHost: Scalars['String'];
  ftpPassword: Scalars['String'];
  ftpPort: Scalars['Int'];
  ftpUser: Scalars['String'];
  id: Scalars['uuid'];
  integrationType: TecAggregationIntegrationTypeEnum;
  posNumber: Scalars['String'];
  salesDataPutPath?: Maybe<Scalars['String']>;
  /** An object relationship */
  shop: Shop;
  shopCode: Scalars['String'];
  shopId: Scalars['uuid'];
};

/** order by aggregate values of table "tecAggregationConfig" */
export type TecAggregationConfigAggregateOrderBy = {
  avg?: InputMaybe<TecAggregationConfigAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TecAggregationConfigMaxOrderBy>;
  min?: InputMaybe<TecAggregationConfigMinOrderBy>;
  stddev?: InputMaybe<TecAggregationConfigStddevOrderBy>;
  stddev_pop?: InputMaybe<TecAggregationConfigStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TecAggregationConfigStddevSampOrderBy>;
  sum?: InputMaybe<TecAggregationConfigSumOrderBy>;
  var_pop?: InputMaybe<TecAggregationConfigVarPopOrderBy>;
  var_samp?: InputMaybe<TecAggregationConfigVarSampOrderBy>;
  variance?: InputMaybe<TecAggregationConfigVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "tecAggregationConfig" */
export type TecAggregationConfigArrRelInsertInput = {
  data: Array<TecAggregationConfigInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TecAggregationConfigOnConflict>;
};

/** order by avg() on columns of table "tecAggregationConfig" */
export type TecAggregationConfigAvgOrderBy = {
  ftpPort?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "tecAggregationConfig". All fields are combined with a logical 'AND'. */
export type TecAggregationConfigBoolExp = {
  _and?: InputMaybe<Array<TecAggregationConfigBoolExp>>;
  _not?: InputMaybe<TecAggregationConfigBoolExp>;
  _or?: InputMaybe<Array<TecAggregationConfigBoolExp>>;
  checkDatePutPath?: InputMaybe<StringComparisonExp>;
  companyCode?: InputMaybe<StringComparisonExp>;
  enabled?: InputMaybe<BooleanComparisonExp>;
  ftpHost?: InputMaybe<StringComparisonExp>;
  ftpPassword?: InputMaybe<StringComparisonExp>;
  ftpPort?: InputMaybe<IntComparisonExp>;
  ftpUser?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  integrationType?: InputMaybe<TecAggregationIntegrationTypeEnumComparisonExp>;
  posNumber?: InputMaybe<StringComparisonExp>;
  salesDataPutPath?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopCode?: InputMaybe<StringComparisonExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "tecAggregationConfig" */
export enum TecAggregationConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  TecAggregationConfigPkey = 'tecAggregationConfig_pkey',
  /** unique or primary key constraint on columns "integrationType", "shopId" */
  TecAggregationConfigShopIdIntegrationTypeKey = 'tecAggregationConfig_shopId_integrationType_key'
}

/** input type for incrementing numeric columns in table "tecAggregationConfig" */
export type TecAggregationConfigIncInput = {
  ftpPort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tecAggregationConfig" */
export type TecAggregationConfigInsertInput = {
  checkDatePutPath?: InputMaybe<Scalars['String']>;
  companyCode: Scalars['String'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  ftpHost: Scalars['String'];
  ftpPassword: Scalars['String'];
  ftpPort: Scalars['Int'];
  ftpUser: Scalars['String'];
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<TecAggregationIntegrationTypeEnum>;
  posNumber: Scalars['String'];
  salesDataPutPath?: InputMaybe<Scalars['String']>;
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopCode: Scalars['String'];
  shopId: Scalars['uuid'];
};

/** order by max() on columns of table "tecAggregationConfig" */
export type TecAggregationConfigMaxOrderBy = {
  checkDatePutPath?: InputMaybe<OrderBy>;
  companyCode?: InputMaybe<OrderBy>;
  ftpHost?: InputMaybe<OrderBy>;
  ftpPassword?: InputMaybe<OrderBy>;
  ftpPort?: InputMaybe<OrderBy>;
  ftpUser?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  posNumber?: InputMaybe<OrderBy>;
  salesDataPutPath?: InputMaybe<OrderBy>;
  shopCode?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "tecAggregationConfig" */
export type TecAggregationConfigMinOrderBy = {
  checkDatePutPath?: InputMaybe<OrderBy>;
  companyCode?: InputMaybe<OrderBy>;
  ftpHost?: InputMaybe<OrderBy>;
  ftpPassword?: InputMaybe<OrderBy>;
  ftpPort?: InputMaybe<OrderBy>;
  ftpUser?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  posNumber?: InputMaybe<OrderBy>;
  salesDataPutPath?: InputMaybe<OrderBy>;
  shopCode?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tecAggregationConfig" */
export type TecAggregationConfigMutationResponse = {
  __typename?: 'tecAggregationConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TecAggregationConfig>;
};

/** on_conflict condition type for table "tecAggregationConfig" */
export type TecAggregationConfigOnConflict = {
  constraint: TecAggregationConfigConstraint;
  update_columns?: Array<TecAggregationConfigUpdateColumn>;
  where?: InputMaybe<TecAggregationConfigBoolExp>;
};

/** Ordering options when selecting data from "tecAggregationConfig". */
export type TecAggregationConfigOrderBy = {
  checkDatePutPath?: InputMaybe<OrderBy>;
  companyCode?: InputMaybe<OrderBy>;
  enabled?: InputMaybe<OrderBy>;
  ftpHost?: InputMaybe<OrderBy>;
  ftpPassword?: InputMaybe<OrderBy>;
  ftpPort?: InputMaybe<OrderBy>;
  ftpUser?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  integrationType?: InputMaybe<OrderBy>;
  posNumber?: InputMaybe<OrderBy>;
  salesDataPutPath?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopCode?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tecAggregationConfig */
export type TecAggregationConfigPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "tecAggregationConfig" */
export enum TecAggregationConfigSelectColumn {
  /** column name */
  CheckDatePutPath = 'checkDatePutPath',
  /** column name */
  CompanyCode = 'companyCode',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FtpHost = 'ftpHost',
  /** column name */
  FtpPassword = 'ftpPassword',
  /** column name */
  FtpPort = 'ftpPort',
  /** column name */
  FtpUser = 'ftpUser',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  PosNumber = 'posNumber',
  /** column name */
  SalesDataPutPath = 'salesDataPutPath',
  /** column name */
  ShopCode = 'shopCode',
  /** column name */
  ShopId = 'shopId'
}

/** input type for updating data in table "tecAggregationConfig" */
export type TecAggregationConfigSetInput = {
  checkDatePutPath?: InputMaybe<Scalars['String']>;
  companyCode?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  ftpHost?: InputMaybe<Scalars['String']>;
  ftpPassword?: InputMaybe<Scalars['String']>;
  ftpPort?: InputMaybe<Scalars['Int']>;
  ftpUser?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<TecAggregationIntegrationTypeEnum>;
  posNumber?: InputMaybe<Scalars['String']>;
  salesDataPutPath?: InputMaybe<Scalars['String']>;
  shopCode?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "tecAggregationConfig" */
export type TecAggregationConfigStddevOrderBy = {
  ftpPort?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "tecAggregationConfig" */
export type TecAggregationConfigStddevPopOrderBy = {
  ftpPort?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "tecAggregationConfig" */
export type TecAggregationConfigStddevSampOrderBy = {
  ftpPort?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "tecAggregationConfig" */
export type TecAggregationConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TecAggregationConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type TecAggregationConfigStreamCursorValueInput = {
  checkDatePutPath?: InputMaybe<Scalars['String']>;
  companyCode?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  ftpHost?: InputMaybe<Scalars['String']>;
  ftpPassword?: InputMaybe<Scalars['String']>;
  ftpPort?: InputMaybe<Scalars['Int']>;
  ftpUser?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  integrationType?: InputMaybe<TecAggregationIntegrationTypeEnum>;
  posNumber?: InputMaybe<Scalars['String']>;
  salesDataPutPath?: InputMaybe<Scalars['String']>;
  shopCode?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "tecAggregationConfig" */
export type TecAggregationConfigSumOrderBy = {
  ftpPort?: InputMaybe<OrderBy>;
};

/** update columns of table "tecAggregationConfig" */
export enum TecAggregationConfigUpdateColumn {
  /** column name */
  CheckDatePutPath = 'checkDatePutPath',
  /** column name */
  CompanyCode = 'companyCode',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FtpHost = 'ftpHost',
  /** column name */
  FtpPassword = 'ftpPassword',
  /** column name */
  FtpPort = 'ftpPort',
  /** column name */
  FtpUser = 'ftpUser',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  PosNumber = 'posNumber',
  /** column name */
  SalesDataPutPath = 'salesDataPutPath',
  /** column name */
  ShopCode = 'shopCode',
  /** column name */
  ShopId = 'shopId'
}

export type TecAggregationConfigUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TecAggregationConfigIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TecAggregationConfigSetInput>;
  /** filter the rows which have to be updated */
  where: TecAggregationConfigBoolExp;
};

/** order by var_pop() on columns of table "tecAggregationConfig" */
export type TecAggregationConfigVarPopOrderBy = {
  ftpPort?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "tecAggregationConfig" */
export type TecAggregationConfigVarSampOrderBy = {
  ftpPort?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "tecAggregationConfig" */
export type TecAggregationConfigVarianceOrderBy = {
  ftpPort?: InputMaybe<OrderBy>;
};

export enum TecAggregationIntegrationTypeEnum {
  Aspit = 'aspit',
  Datatrade = 'datatrade',
  GulfNet = 'gulfNet',
  Hanzo = 'hanzo',
  MakaseteNet = 'makaseteNet',
  MierukaSystem = 'mierukaSystem',
  Rakmy = 'rakmy'
}

/**
 * Boolean expression to compare columns of type
 * "tecAggregationIntegrationType_enum". All fields are combined with logical 'AND'.
 */
export type TecAggregationIntegrationTypeEnumComparisonExp = {
  _eq?: InputMaybe<TecAggregationIntegrationTypeEnum>;
  _in?: InputMaybe<Array<TecAggregationIntegrationTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TecAggregationIntegrationTypeEnum>;
  _nin?: InputMaybe<Array<TecAggregationIntegrationTypeEnum>>;
};

/** columns and relationships of "tecAggregationMediaMap" */
export type TecAggregationMediaMap = {
  __typename?: 'tecAggregationMediaMap';
  aggregationNumber: Scalars['Int'];
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  mediaName: Scalars['String'];
  /** An array relationship */
  tecAggregationOnSitePaymentDetailTypes: Array<TecAggregationOnSitePaymentDetailType>;
};


/** columns and relationships of "tecAggregationMediaMap" */
export type TecAggregationMediaMapTecAggregationOnSitePaymentDetailTypesArgs = {
  distinct_on?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeOrderBy>>;
  where?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
};

/** order by aggregate values of table "tecAggregationMediaMap" */
export type TecAggregationMediaMapAggregateOrderBy = {
  avg?: InputMaybe<TecAggregationMediaMapAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TecAggregationMediaMapMaxOrderBy>;
  min?: InputMaybe<TecAggregationMediaMapMinOrderBy>;
  stddev?: InputMaybe<TecAggregationMediaMapStddevOrderBy>;
  stddev_pop?: InputMaybe<TecAggregationMediaMapStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TecAggregationMediaMapStddevSampOrderBy>;
  sum?: InputMaybe<TecAggregationMediaMapSumOrderBy>;
  var_pop?: InputMaybe<TecAggregationMediaMapVarPopOrderBy>;
  var_samp?: InputMaybe<TecAggregationMediaMapVarSampOrderBy>;
  variance?: InputMaybe<TecAggregationMediaMapVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "tecAggregationMediaMap" */
export type TecAggregationMediaMapArrRelInsertInput = {
  data: Array<TecAggregationMediaMapInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TecAggregationMediaMapOnConflict>;
};

/** order by avg() on columns of table "tecAggregationMediaMap" */
export type TecAggregationMediaMapAvgOrderBy = {
  aggregationNumber?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "tecAggregationMediaMap". All fields are combined with a logical 'AND'. */
export type TecAggregationMediaMapBoolExp = {
  _and?: InputMaybe<Array<TecAggregationMediaMapBoolExp>>;
  _not?: InputMaybe<TecAggregationMediaMapBoolExp>;
  _or?: InputMaybe<Array<TecAggregationMediaMapBoolExp>>;
  aggregationNumber?: InputMaybe<IntComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  mediaName?: InputMaybe<StringComparisonExp>;
  tecAggregationOnSitePaymentDetailTypes?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
};

/** unique or primary key constraints on table "tecAggregationMediaMap" */
export enum TecAggregationMediaMapConstraint {
  /** unique or primary key constraint on columns "aggregationNumber", "_companyId" */
  TecAggregationMediaMapCompanyIdAggregationNumberKey = 'tecAggregationMediaMap_companyId_aggregationNumber_key',
  /** unique or primary key constraint on columns "aggregationNumber", "companyId" */
  TecAggregationMediaMapCompanyUuidAggregationNumberKey = 'tecAggregationMediaMap_companyUuid_aggregationNumber_key',
  /** unique or primary key constraint on columns "id" */
  TecAggregationMediaMapPkey = 'tecAggregationMediaMap_pkey'
}

/** input type for incrementing numeric columns in table "tecAggregationMediaMap" */
export type TecAggregationMediaMapIncInput = {
  aggregationNumber?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tecAggregationMediaMap" */
export type TecAggregationMediaMapInsertInput = {
  aggregationNumber: Scalars['Int'];
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  mediaName: Scalars['String'];
  tecAggregationOnSitePaymentDetailTypes?: InputMaybe<TecAggregationOnSitePaymentDetailTypeArrRelInsertInput>;
};

/** order by max() on columns of table "tecAggregationMediaMap" */
export type TecAggregationMediaMapMaxOrderBy = {
  aggregationNumber?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  mediaName?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "tecAggregationMediaMap" */
export type TecAggregationMediaMapMinOrderBy = {
  aggregationNumber?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  mediaName?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tecAggregationMediaMap" */
export type TecAggregationMediaMapMutationResponse = {
  __typename?: 'tecAggregationMediaMap_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TecAggregationMediaMap>;
};

/** input type for inserting object relation for remote table "tecAggregationMediaMap" */
export type TecAggregationMediaMapObjRelInsertInput = {
  data: TecAggregationMediaMapInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TecAggregationMediaMapOnConflict>;
};

/** on_conflict condition type for table "tecAggregationMediaMap" */
export type TecAggregationMediaMapOnConflict = {
  constraint: TecAggregationMediaMapConstraint;
  update_columns?: Array<TecAggregationMediaMapUpdateColumn>;
  where?: InputMaybe<TecAggregationMediaMapBoolExp>;
};

/** Ordering options when selecting data from "tecAggregationMediaMap". */
export type TecAggregationMediaMapOrderBy = {
  aggregationNumber?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  mediaName?: InputMaybe<OrderBy>;
  tecAggregationOnSitePaymentDetailTypes_aggregate?: InputMaybe<TecAggregationOnSitePaymentDetailTypeAggregateOrderBy>;
};

/** primary key columns input for table: tecAggregationMediaMap */
export type TecAggregationMediaMapPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "tecAggregationMediaMap" */
export enum TecAggregationMediaMapSelectColumn {
  /** column name */
  AggregationNumber = 'aggregationNumber',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  MediaName = 'mediaName'
}

/** input type for updating data in table "tecAggregationMediaMap" */
export type TecAggregationMediaMapSetInput = {
  aggregationNumber?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  mediaName?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "tecAggregationMediaMap" */
export type TecAggregationMediaMapStddevOrderBy = {
  aggregationNumber?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "tecAggregationMediaMap" */
export type TecAggregationMediaMapStddevPopOrderBy = {
  aggregationNumber?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "tecAggregationMediaMap" */
export type TecAggregationMediaMapStddevSampOrderBy = {
  aggregationNumber?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "tecAggregationMediaMap" */
export type TecAggregationMediaMapStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TecAggregationMediaMapStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type TecAggregationMediaMapStreamCursorValueInput = {
  aggregationNumber?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  mediaName?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "tecAggregationMediaMap" */
export type TecAggregationMediaMapSumOrderBy = {
  aggregationNumber?: InputMaybe<OrderBy>;
};

/** update columns of table "tecAggregationMediaMap" */
export enum TecAggregationMediaMapUpdateColumn {
  /** column name */
  AggregationNumber = 'aggregationNumber',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  MediaName = 'mediaName'
}

export type TecAggregationMediaMapUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TecAggregationMediaMapIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TecAggregationMediaMapSetInput>;
  /** filter the rows which have to be updated */
  where: TecAggregationMediaMapBoolExp;
};

/** order by var_pop() on columns of table "tecAggregationMediaMap" */
export type TecAggregationMediaMapVarPopOrderBy = {
  aggregationNumber?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "tecAggregationMediaMap" */
export type TecAggregationMediaMapVarSampOrderBy = {
  aggregationNumber?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "tecAggregationMediaMap" */
export type TecAggregationMediaMapVarianceOrderBy = {
  aggregationNumber?: InputMaybe<OrderBy>;
};

/** columns and relationships of "tecAggregationMenu" */
export type TecAggregationMenu = {
  __typename?: 'tecAggregationMenu';
  _choiceId?: Maybe<Scalars['Int']>;
  _menuId?: Maybe<Scalars['Int']>;
  _planChoiceId?: Maybe<Scalars['Int']>;
  _planId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  choice?: Maybe<Choice>;
  choiceId?: Maybe<Scalars['uuid']>;
  companyId: Scalars['uuid'];
  dpCode: Scalars['String'];
  dpName: Scalars['String'];
  gpCode: Scalars['String'];
  gpName: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  menu?: Maybe<Menu>;
  menuCode: Scalars['String'];
  menuId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  plan?: Maybe<Plan>;
  /** An object relationship */
  planChoice?: Maybe<PlanChoice>;
  planChoiceId?: Maybe<Scalars['uuid']>;
  planId?: Maybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "tecAggregationMenu". All fields are combined with a logical 'AND'. */
export type TecAggregationMenuBoolExp = {
  _and?: InputMaybe<Array<TecAggregationMenuBoolExp>>;
  _choiceId?: InputMaybe<IntComparisonExp>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<TecAggregationMenuBoolExp>;
  _or?: InputMaybe<Array<TecAggregationMenuBoolExp>>;
  _planChoiceId?: InputMaybe<IntComparisonExp>;
  _planId?: InputMaybe<IntComparisonExp>;
  choice?: InputMaybe<ChoiceBoolExp>;
  choiceId?: InputMaybe<UuidComparisonExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  dpCode?: InputMaybe<StringComparisonExp>;
  dpName?: InputMaybe<StringComparisonExp>;
  gpCode?: InputMaybe<StringComparisonExp>;
  gpName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuCode?: InputMaybe<StringComparisonExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  plan?: InputMaybe<PlanBoolExp>;
  planChoice?: InputMaybe<PlanChoiceBoolExp>;
  planChoiceId?: InputMaybe<UuidComparisonExp>;
  planId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "tecAggregationMenu" */
export enum TecAggregationMenuConstraint {
  /** unique or primary key constraint on columns "_choiceId" */
  TecAggregationMenuChoiceIdKey = 'tecAggregationMenu_choiceId_key',
  /** unique or primary key constraint on columns "_menuId" */
  TecAggregationMenuMenuIdKey = 'tecAggregationMenu_menuId_key',
  /** unique or primary key constraint on columns "id" */
  TecAggregationMenuPkey = 'tecAggregationMenu_pkey',
  /** unique or primary key constraint on columns "_planChoiceId" */
  TecAggregationMenuPlanChoiceIdKey = 'tecAggregationMenu_planChoiceId_key',
  /** unique or primary key constraint on columns "_planId" */
  TecAggregationMenuPlanIdKey = 'tecAggregationMenu_planId_key'
}

/** input type for incrementing numeric columns in table "tecAggregationMenu" */
export type TecAggregationMenuIncInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tecAggregationMenu" */
export type TecAggregationMenuInsertInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  choice?: InputMaybe<ChoiceObjRelInsertInput>;
  choiceId?: InputMaybe<Scalars['uuid']>;
  companyId: Scalars['uuid'];
  dpCode: Scalars['String'];
  dpName: Scalars['String'];
  gpCode: Scalars['String'];
  gpName: Scalars['String'];
  id?: InputMaybe<Scalars['uuid']>;
  menu?: InputMaybe<MenuObjRelInsertInput>;
  menuCode: Scalars['String'];
  menuId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<PlanObjRelInsertInput>;
  planChoice?: InputMaybe<PlanChoiceObjRelInsertInput>;
  planChoiceId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** response of any mutation on the table "tecAggregationMenu" */
export type TecAggregationMenuMutationResponse = {
  __typename?: 'tecAggregationMenu_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TecAggregationMenu>;
};

/** input type for inserting object relation for remote table "tecAggregationMenu" */
export type TecAggregationMenuObjRelInsertInput = {
  data: TecAggregationMenuInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TecAggregationMenuOnConflict>;
};

/** on_conflict condition type for table "tecAggregationMenu" */
export type TecAggregationMenuOnConflict = {
  constraint: TecAggregationMenuConstraint;
  update_columns?: Array<TecAggregationMenuUpdateColumn>;
  where?: InputMaybe<TecAggregationMenuBoolExp>;
};

/** Ordering options when selecting data from "tecAggregationMenu". */
export type TecAggregationMenuOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  choice?: InputMaybe<ChoiceOrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  dpCode?: InputMaybe<OrderBy>;
  dpName?: InputMaybe<OrderBy>;
  gpCode?: InputMaybe<OrderBy>;
  gpName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuCode?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlanOrderBy>;
  planChoice?: InputMaybe<PlanChoiceOrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tecAggregationMenu */
export type TecAggregationMenuPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "tecAggregationMenu" */
export enum TecAggregationMenuSelectColumn {
  /** column name */
  _ChoiceId = '_choiceId',
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  _PlanChoiceId = '_planChoiceId',
  /** column name */
  _PlanId = '_planId',
  /** column name */
  ChoiceId = 'choiceId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  DpCode = 'dpCode',
  /** column name */
  DpName = 'dpName',
  /** column name */
  GpCode = 'gpCode',
  /** column name */
  GpName = 'gpName',
  /** column name */
  Id = 'id',
  /** column name */
  MenuCode = 'menuCode',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Name = 'name',
  /** column name */
  PlanChoiceId = 'planChoiceId',
  /** column name */
  PlanId = 'planId'
}

/** input type for updating data in table "tecAggregationMenu" */
export type TecAggregationMenuSetInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  choiceId?: InputMaybe<Scalars['uuid']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  dpCode?: InputMaybe<Scalars['String']>;
  dpName?: InputMaybe<Scalars['String']>;
  gpCode?: InputMaybe<Scalars['String']>;
  gpName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuCode?: InputMaybe<Scalars['String']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  planChoiceId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "tecAggregationMenu" */
export type TecAggregationMenuStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TecAggregationMenuStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type TecAggregationMenuStreamCursorValueInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  choiceId?: InputMaybe<Scalars['uuid']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  dpCode?: InputMaybe<Scalars['String']>;
  dpName?: InputMaybe<Scalars['String']>;
  gpCode?: InputMaybe<Scalars['String']>;
  gpName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuCode?: InputMaybe<Scalars['String']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  planChoiceId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "tecAggregationMenu" */
export enum TecAggregationMenuUpdateColumn {
  /** column name */
  _ChoiceId = '_choiceId',
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  _PlanChoiceId = '_planChoiceId',
  /** column name */
  _PlanId = '_planId',
  /** column name */
  ChoiceId = 'choiceId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  DpCode = 'dpCode',
  /** column name */
  DpName = 'dpName',
  /** column name */
  GpCode = 'gpCode',
  /** column name */
  GpName = 'gpName',
  /** column name */
  Id = 'id',
  /** column name */
  MenuCode = 'menuCode',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Name = 'name',
  /** column name */
  PlanChoiceId = 'planChoiceId',
  /** column name */
  PlanId = 'planId'
}

export type TecAggregationMenuUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TecAggregationMenuIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TecAggregationMenuSetInput>;
  /** filter the rows which have to be updated */
  where: TecAggregationMenuBoolExp;
};

/** columns and relationships of "tecAggregationOnSitePaymentDetailType" */
export type TecAggregationOnSitePaymentDetailType = {
  __typename?: 'tecAggregationOnSitePaymentDetailType';
  /** An object relationship */
  company: Company;
  id: Scalars['uuid'];
  mediaCode: Scalars['String'];
  mediaName: Scalars['String'];
  /** An object relationship */
  onSitePaymentDetailType: OnSitePaymentDetailType;
  onSitePaymentDetailTypeEntityType: Scalars['String'];
  onSitePaymentDetailTypeId: Scalars['uuid'];
  paymentType: Scalars['String'];
  /** An object relationship */
  shop?: Maybe<Shop>;
  shopId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  tecAggregationMediaMap?: Maybe<TecAggregationMediaMap>;
  tecAggregationMediaMapId?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "tecAggregationOnSitePaymentDetailType" */
export type TecAggregationOnSitePaymentDetailTypeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TecAggregationOnSitePaymentDetailTypeMaxOrderBy>;
  min?: InputMaybe<TecAggregationOnSitePaymentDetailTypeMinOrderBy>;
};

/** input type for inserting array relation for remote table "tecAggregationOnSitePaymentDetailType" */
export type TecAggregationOnSitePaymentDetailTypeArrRelInsertInput = {
  data: Array<TecAggregationOnSitePaymentDetailTypeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TecAggregationOnSitePaymentDetailTypeOnConflict>;
};

/**
 * Boolean expression to filter rows from the table
 * "tecAggregationOnSitePaymentDetailType". All fields are combined with a logical 'AND'.
 */
export type TecAggregationOnSitePaymentDetailTypeBoolExp = {
  _and?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeBoolExp>>;
  _not?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
  _or?: InputMaybe<Array<TecAggregationOnSitePaymentDetailTypeBoolExp>>;
  company?: InputMaybe<CompanyBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  mediaCode?: InputMaybe<StringComparisonExp>;
  mediaName?: InputMaybe<StringComparisonExp>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeBoolExp>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<StringComparisonExp>;
  onSitePaymentDetailTypeId?: InputMaybe<UuidComparisonExp>;
  paymentType?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  tecAggregationMediaMap?: InputMaybe<TecAggregationMediaMapBoolExp>;
  tecAggregationMediaMapId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "tecAggregationOnSitePaymentDetailType" */
export enum TecAggregationOnSitePaymentDetailTypeConstraint {
  /** unique or primary key constraint on columns "onSitePaymentDetailTypeEntityType", "shopId" */
  TecAggOnSitePaymentDetailTypeShopIdOnSitePaymentDetailTypeKe = 'tecAggOnSitePaymentDetailType_shopId_onSitePaymentDetailType_ke',
  /** unique or primary key constraint on columns "id" */
  TecAggregationOnSitePaymentDetailTypePkey = 'tecAggregationOnSitePaymentDetailType_pkey'
}

/** input type for inserting data into table "tecAggregationOnSitePaymentDetailType" */
export type TecAggregationOnSitePaymentDetailTypeInsertInput = {
  company?: InputMaybe<CompanyObjRelInsertInput>;
  companyId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  mediaCode: Scalars['String'];
  mediaName: Scalars['String'];
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeObjRelInsertInput>;
  onSitePaymentDetailTypeEntityType: Scalars['String'];
  onSitePaymentDetailTypeId: Scalars['uuid'];
  paymentType: Scalars['String'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tecAggregationMediaMap?: InputMaybe<TecAggregationMediaMapObjRelInsertInput>;
  tecAggregationMediaMapId?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tecAggregationOnSitePaymentDetailType" */
export type TecAggregationOnSitePaymentDetailTypeMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  mediaCode?: InputMaybe<OrderBy>;
  mediaName?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
  paymentType?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tecAggregationMediaMapId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "tecAggregationOnSitePaymentDetailType" */
export type TecAggregationOnSitePaymentDetailTypeMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  mediaCode?: InputMaybe<OrderBy>;
  mediaName?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
  paymentType?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tecAggregationMediaMapId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tecAggregationOnSitePaymentDetailType" */
export type TecAggregationOnSitePaymentDetailTypeMutationResponse = {
  __typename?: 'tecAggregationOnSitePaymentDetailType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TecAggregationOnSitePaymentDetailType>;
};

/** on_conflict condition type for table "tecAggregationOnSitePaymentDetailType" */
export type TecAggregationOnSitePaymentDetailTypeOnConflict = {
  constraint: TecAggregationOnSitePaymentDetailTypeConstraint;
  update_columns?: Array<TecAggregationOnSitePaymentDetailTypeUpdateColumn>;
  where?: InputMaybe<TecAggregationOnSitePaymentDetailTypeBoolExp>;
};

/** Ordering options when selecting data from "tecAggregationOnSitePaymentDetailType". */
export type TecAggregationOnSitePaymentDetailTypeOrderBy = {
  company?: InputMaybe<CompanyOrderBy>;
  id?: InputMaybe<OrderBy>;
  mediaCode?: InputMaybe<OrderBy>;
  mediaName?: InputMaybe<OrderBy>;
  onSitePaymentDetailType?: InputMaybe<OnSitePaymentDetailTypeOrderBy>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<OrderBy>;
  onSitePaymentDetailTypeId?: InputMaybe<OrderBy>;
  paymentType?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  tecAggregationMediaMap?: InputMaybe<TecAggregationMediaMapOrderBy>;
  tecAggregationMediaMapId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tecAggregationOnSitePaymentDetailType */
export type TecAggregationOnSitePaymentDetailTypePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "tecAggregationOnSitePaymentDetailType" */
export enum TecAggregationOnSitePaymentDetailTypeSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MediaCode = 'mediaCode',
  /** column name */
  MediaName = 'mediaName',
  /** column name */
  OnSitePaymentDetailTypeEntityType = 'onSitePaymentDetailTypeEntityType',
  /** column name */
  OnSitePaymentDetailTypeId = 'onSitePaymentDetailTypeId',
  /** column name */
  PaymentType = 'paymentType',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TecAggregationMediaMapId = 'tecAggregationMediaMapId'
}

/** input type for updating data in table "tecAggregationOnSitePaymentDetailType" */
export type TecAggregationOnSitePaymentDetailTypeSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  mediaCode?: InputMaybe<Scalars['String']>;
  mediaName?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeId?: InputMaybe<Scalars['uuid']>;
  paymentType?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tecAggregationMediaMapId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "tecAggregationOnSitePaymentDetailType" */
export type TecAggregationOnSitePaymentDetailTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TecAggregationOnSitePaymentDetailTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type TecAggregationOnSitePaymentDetailTypeStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  mediaCode?: InputMaybe<Scalars['String']>;
  mediaName?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeEntityType?: InputMaybe<Scalars['String']>;
  onSitePaymentDetailTypeId?: InputMaybe<Scalars['uuid']>;
  paymentType?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  tecAggregationMediaMapId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "tecAggregationOnSitePaymentDetailType" */
export enum TecAggregationOnSitePaymentDetailTypeUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MediaCode = 'mediaCode',
  /** column name */
  MediaName = 'mediaName',
  /** column name */
  OnSitePaymentDetailTypeEntityType = 'onSitePaymentDetailTypeEntityType',
  /** column name */
  OnSitePaymentDetailTypeId = 'onSitePaymentDetailTypeId',
  /** column name */
  PaymentType = 'paymentType',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  TecAggregationMediaMapId = 'tecAggregationMediaMapId'
}

export type TecAggregationOnSitePaymentDetailTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TecAggregationOnSitePaymentDetailTypeSetInput>;
  /** filter the rows which have to be updated */
  where: TecAggregationOnSitePaymentDetailTypeBoolExp;
};

/** columns and relationships of "textTypeQuestionAnswer" */
export type TextTypeQuestionAnswer = {
  __typename?: 'textTypeQuestionAnswer';
  /** An object relationship */
  questionAnswer?: Maybe<QuestionAnswer>;
  text: Scalars['String'];
};

/** Boolean expression to filter rows from the table "textTypeQuestionAnswer". All fields are combined with a logical 'AND'. */
export type TextTypeQuestionAnswerBoolExp = {
  _and?: InputMaybe<Array<TextTypeQuestionAnswerBoolExp>>;
  _not?: InputMaybe<TextTypeQuestionAnswerBoolExp>;
  _or?: InputMaybe<Array<TextTypeQuestionAnswerBoolExp>>;
  questionAnswer?: InputMaybe<QuestionAnswerBoolExp>;
  text?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "textTypeQuestionAnswer". */
export type TextTypeQuestionAnswerOrderBy = {
  questionAnswer?: InputMaybe<QuestionAnswerOrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** select columns of table "textTypeQuestionAnswer" */
export enum TextTypeQuestionAnswerSelectColumn {
  /** column name */
  Text = 'text'
}

/** Streaming cursor of the table "textTypeQuestionAnswer" */
export type TextTypeQuestionAnswerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TextTypeQuestionAnswerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type TextTypeQuestionAnswerStreamCursorValueInput = {
  text?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type TimeComparisonExp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type TimetzComparisonExp = {
  _eq?: InputMaybe<Scalars['timetz']>;
  _gt?: InputMaybe<Scalars['timetz']>;
  _gte?: InputMaybe<Scalars['timetz']>;
  _in?: InputMaybe<Array<Scalars['timetz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timetz']>;
  _lte?: InputMaybe<Scalars['timetz']>;
  _neq?: InputMaybe<Scalars['timetz']>;
  _nin?: InputMaybe<Array<Scalars['timetz']>>;
};

/** columns and relationships of "translation" */
export type Translation = {
  __typename?: 'translation';
  columnName: Scalars['String'];
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  locale: SupportedLocaleEnum;
  recordId: Scalars['uuid'];
  source: Scalars['String'];
  tableName: Scalars['String'];
  value: Scalars['String'];
};

/** order by aggregate values of table "translation" */
export type TranslationAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TranslationMaxOrderBy>;
  min?: InputMaybe<TranslationMinOrderBy>;
};

/** Boolean expression to filter rows from the table "translation". All fields are combined with a logical 'AND'. */
export type TranslationBoolExp = {
  _and?: InputMaybe<Array<TranslationBoolExp>>;
  _not?: InputMaybe<TranslationBoolExp>;
  _or?: InputMaybe<Array<TranslationBoolExp>>;
  columnName?: InputMaybe<StringComparisonExp>;
  company?: InputMaybe<CompanyBoolExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  locale?: InputMaybe<SupportedLocaleEnumComparisonExp>;
  recordId?: InputMaybe<UuidComparisonExp>;
  source?: InputMaybe<StringComparisonExp>;
  tableName?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "translation" */
export type TranslationMaxOrderBy = {
  columnName?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recordId?: InputMaybe<OrderBy>;
  source?: InputMaybe<OrderBy>;
  tableName?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "translation" */
export type TranslationMinOrderBy = {
  columnName?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  recordId?: InputMaybe<OrderBy>;
  source?: InputMaybe<OrderBy>;
  tableName?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "translation" */
export type TranslationMutationResponse = {
  __typename?: 'translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Translation>;
};

/** Ordering options when selecting data from "translation". */
export type TranslationOrderBy = {
  columnName?: InputMaybe<OrderBy>;
  company?: InputMaybe<CompanyOrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  locale?: InputMaybe<OrderBy>;
  recordId?: InputMaybe<OrderBy>;
  source?: InputMaybe<OrderBy>;
  tableName?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: translation */
export type TranslationPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "translation" */
export enum TranslationSelectColumn {
  /** column name */
  ColumnName = 'columnName',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  RecordId = 'recordId',
  /** column name */
  Source = 'source',
  /** column name */
  TableName = 'tableName',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "translation" */
export type TranslationSetInput = {
  columnName?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isTranslatedAutomatically?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<SupportedLocaleEnum>;
  recordId?: InputMaybe<Scalars['uuid']>;
  source?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  translatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "translation" */
export type TranslationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TranslationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type TranslationStreamCursorValueInput = {
  columnName?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locale?: InputMaybe<SupportedLocaleEnum>;
  recordId?: InputMaybe<Scalars['uuid']>;
  source?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type TranslationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TranslationSetInput>;
  /** filter the rows which have to be updated */
  where: TranslationBoolExp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "winboardConfig" */
export type WinboardConfig = {
  __typename?: 'winboardConfig';
  enabled: Scalars['Boolean'];
  ftpHost: Scalars['String'];
  ftpPassword: Scalars['String'];
  ftpPort: Scalars['Int'];
  ftpUser: Scalars['String'];
  putPath: Scalars['String'];
  /** An object relationship */
  shop: Shop;
  shopId: Scalars['uuid'];
  winboardCompanyCd: Scalars['String'];
  winboardConfigId: Scalars['uuid'];
  winboardPosNumber: Scalars['String'];
  winboardShopCd: Scalars['String'];
};

/** Boolean expression to filter rows from the table "winboardConfig". All fields are combined with a logical 'AND'. */
export type WinboardConfigBoolExp = {
  _and?: InputMaybe<Array<WinboardConfigBoolExp>>;
  _not?: InputMaybe<WinboardConfigBoolExp>;
  _or?: InputMaybe<Array<WinboardConfigBoolExp>>;
  enabled?: InputMaybe<BooleanComparisonExp>;
  ftpHost?: InputMaybe<StringComparisonExp>;
  ftpPassword?: InputMaybe<StringComparisonExp>;
  ftpPort?: InputMaybe<IntComparisonExp>;
  ftpUser?: InputMaybe<StringComparisonExp>;
  putPath?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<ShopBoolExp>;
  shopId?: InputMaybe<UuidComparisonExp>;
  winboardCompanyCd?: InputMaybe<StringComparisonExp>;
  winboardConfigId?: InputMaybe<UuidComparisonExp>;
  winboardPosNumber?: InputMaybe<StringComparisonExp>;
  winboardShopCd?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "winboardConfig" */
export enum WinboardConfigConstraint {
  /** unique or primary key constraint on columns "winboardConfigId" */
  WinboardConfigPkey = 'winboardConfig_pkey',
  /** unique or primary key constraint on columns "shopId" */
  WinboardConfigShopIdKey = 'winboardConfig_shopId_key'
}

/** input type for incrementing numeric columns in table "winboardConfig" */
export type WinboardConfigIncInput = {
  ftpPort?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "winboardConfig" */
export type WinboardConfigInsertInput = {
  enabled: Scalars['Boolean'];
  ftpHost: Scalars['String'];
  ftpPassword: Scalars['String'];
  ftpPort: Scalars['Int'];
  ftpUser: Scalars['String'];
  putPath: Scalars['String'];
  shop?: InputMaybe<ShopObjRelInsertInput>;
  shopId: Scalars['uuid'];
  winboardCompanyCd: Scalars['String'];
  winboardConfigId?: InputMaybe<Scalars['uuid']>;
  winboardPosNumber: Scalars['String'];
  winboardShopCd: Scalars['String'];
};

/** response of any mutation on the table "winboardConfig" */
export type WinboardConfigMutationResponse = {
  __typename?: 'winboardConfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WinboardConfig>;
};

/** input type for inserting object relation for remote table "winboardConfig" */
export type WinboardConfigObjRelInsertInput = {
  data: WinboardConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<WinboardConfigOnConflict>;
};

/** on_conflict condition type for table "winboardConfig" */
export type WinboardConfigOnConflict = {
  constraint: WinboardConfigConstraint;
  update_columns?: Array<WinboardConfigUpdateColumn>;
  where?: InputMaybe<WinboardConfigBoolExp>;
};

/** Ordering options when selecting data from "winboardConfig". */
export type WinboardConfigOrderBy = {
  enabled?: InputMaybe<OrderBy>;
  ftpHost?: InputMaybe<OrderBy>;
  ftpPassword?: InputMaybe<OrderBy>;
  ftpPort?: InputMaybe<OrderBy>;
  ftpUser?: InputMaybe<OrderBy>;
  putPath?: InputMaybe<OrderBy>;
  shop?: InputMaybe<ShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  winboardCompanyCd?: InputMaybe<OrderBy>;
  winboardConfigId?: InputMaybe<OrderBy>;
  winboardPosNumber?: InputMaybe<OrderBy>;
  winboardShopCd?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: winboardConfig */
export type WinboardConfigPkColumnsInput = {
  winboardConfigId: Scalars['uuid'];
};

/** select columns of table "winboardConfig" */
export enum WinboardConfigSelectColumn {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FtpHost = 'ftpHost',
  /** column name */
  FtpPassword = 'ftpPassword',
  /** column name */
  FtpPort = 'ftpPort',
  /** column name */
  FtpUser = 'ftpUser',
  /** column name */
  PutPath = 'putPath',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  WinboardCompanyCd = 'winboardCompanyCd',
  /** column name */
  WinboardConfigId = 'winboardConfigId',
  /** column name */
  WinboardPosNumber = 'winboardPosNumber',
  /** column name */
  WinboardShopCd = 'winboardShopCd'
}

/** input type for updating data in table "winboardConfig" */
export type WinboardConfigSetInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  ftpHost?: InputMaybe<Scalars['String']>;
  ftpPassword?: InputMaybe<Scalars['String']>;
  ftpPort?: InputMaybe<Scalars['Int']>;
  ftpUser?: InputMaybe<Scalars['String']>;
  putPath?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  winboardCompanyCd?: InputMaybe<Scalars['String']>;
  winboardConfigId?: InputMaybe<Scalars['uuid']>;
  winboardPosNumber?: InputMaybe<Scalars['String']>;
  winboardShopCd?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "winboardConfig" */
export type WinboardConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WinboardConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type WinboardConfigStreamCursorValueInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  ftpHost?: InputMaybe<Scalars['String']>;
  ftpPassword?: InputMaybe<Scalars['String']>;
  ftpPort?: InputMaybe<Scalars['Int']>;
  ftpUser?: InputMaybe<Scalars['String']>;
  putPath?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['uuid']>;
  winboardCompanyCd?: InputMaybe<Scalars['String']>;
  winboardConfigId?: InputMaybe<Scalars['uuid']>;
  winboardPosNumber?: InputMaybe<Scalars['String']>;
  winboardShopCd?: InputMaybe<Scalars['String']>;
};

/** update columns of table "winboardConfig" */
export enum WinboardConfigUpdateColumn {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  FtpHost = 'ftpHost',
  /** column name */
  FtpPassword = 'ftpPassword',
  /** column name */
  FtpPort = 'ftpPort',
  /** column name */
  FtpUser = 'ftpUser',
  /** column name */
  PutPath = 'putPath',
  /** column name */
  ShopId = 'shopId',
  /** column name */
  WinboardCompanyCd = 'winboardCompanyCd',
  /** column name */
  WinboardConfigId = 'winboardConfigId',
  /** column name */
  WinboardPosNumber = 'winboardPosNumber',
  /** column name */
  WinboardShopCd = 'winboardShopCd'
}

export type WinboardConfigUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WinboardConfigIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WinboardConfigSetInput>;
  /** filter the rows which have to be updated */
  where: WinboardConfigBoolExp;
};

/** columns and relationships of "winboardInflowSourceTag" */
export type WinboardInflowSourceTag = {
  __typename?: 'winboardInflowSourceTag';
  code: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  inflowSourceTag: InflowSourceTag;
  inflowSourceTagId: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "winboardInflowSourceTag". All fields are combined with a logical 'AND'. */
export type WinboardInflowSourceTagBoolExp = {
  _and?: InputMaybe<Array<WinboardInflowSourceTagBoolExp>>;
  _not?: InputMaybe<WinboardInflowSourceTagBoolExp>;
  _or?: InputMaybe<Array<WinboardInflowSourceTagBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  inflowSourceTag?: InputMaybe<InflowSourceTagBoolExp>;
  inflowSourceTagId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "winboardInflowSourceTag" */
export enum WinboardInflowSourceTagConstraint {
  /** unique or primary key constraint on columns "inflowSourceTagId" */
  WinboardInflowSourceTagInflowSourceTagIdKey = 'winboardInflowSourceTag_inflowSourceTagId_key',
  /** unique or primary key constraint on columns "id" */
  WinboardInflowSourceTagPkey = 'winboardInflowSourceTag_pkey'
}

/** input type for inserting data into table "winboardInflowSourceTag" */
export type WinboardInflowSourceTagInsertInput = {
  code: Scalars['String'];
  id?: InputMaybe<Scalars['uuid']>;
  inflowSourceTag?: InputMaybe<InflowSourceTagObjRelInsertInput>;
  inflowSourceTagId: Scalars['uuid'];
};

/** response of any mutation on the table "winboardInflowSourceTag" */
export type WinboardInflowSourceTagMutationResponse = {
  __typename?: 'winboardInflowSourceTag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WinboardInflowSourceTag>;
};

/** input type for inserting object relation for remote table "winboardInflowSourceTag" */
export type WinboardInflowSourceTagObjRelInsertInput = {
  data: WinboardInflowSourceTagInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<WinboardInflowSourceTagOnConflict>;
};

/** on_conflict condition type for table "winboardInflowSourceTag" */
export type WinboardInflowSourceTagOnConflict = {
  constraint: WinboardInflowSourceTagConstraint;
  update_columns?: Array<WinboardInflowSourceTagUpdateColumn>;
  where?: InputMaybe<WinboardInflowSourceTagBoolExp>;
};

/** Ordering options when selecting data from "winboardInflowSourceTag". */
export type WinboardInflowSourceTagOrderBy = {
  code?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  inflowSourceTag?: InputMaybe<InflowSourceTagOrderBy>;
  inflowSourceTagId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: winboardInflowSourceTag */
export type WinboardInflowSourceTagPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "winboardInflowSourceTag" */
export enum WinboardInflowSourceTagSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  InflowSourceTagId = 'inflowSourceTagId'
}

/** input type for updating data in table "winboardInflowSourceTag" */
export type WinboardInflowSourceTagSetInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inflowSourceTagId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "winboardInflowSourceTag" */
export type WinboardInflowSourceTagStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WinboardInflowSourceTagStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type WinboardInflowSourceTagStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  inflowSourceTagId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "winboardInflowSourceTag" */
export enum WinboardInflowSourceTagUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  InflowSourceTagId = 'inflowSourceTagId'
}

export type WinboardInflowSourceTagUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WinboardInflowSourceTagSetInput>;
  /** filter the rows which have to be updated */
  where: WinboardInflowSourceTagBoolExp;
};

/** columns and relationships of "winboardMenu" */
export type WinboardMenu = {
  __typename?: 'winboardMenu';
  _choiceId?: Maybe<Scalars['Int']>;
  _menuId?: Maybe<Scalars['Int']>;
  _planChoiceId?: Maybe<Scalars['Int']>;
  _planId?: Maybe<Scalars['Int']>;
  bumonCode?: Maybe<Scalars['String']>;
  bumonName?: Maybe<Scalars['String']>;
  categoryCode?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  /** An object relationship */
  choice?: Maybe<Choice>;
  choiceId?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  menu?: Maybe<Menu>;
  menuId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  plan?: Maybe<Plan>;
  /** An object relationship */
  planChoice?: Maybe<PlanChoice>;
  planChoiceId?: Maybe<Scalars['uuid']>;
  planId?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "winboardMenu" */
export type WinboardMenuAggregateOrderBy = {
  avg?: InputMaybe<WinboardMenuAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WinboardMenuMaxOrderBy>;
  min?: InputMaybe<WinboardMenuMinOrderBy>;
  stddev?: InputMaybe<WinboardMenuStddevOrderBy>;
  stddev_pop?: InputMaybe<WinboardMenuStddevPopOrderBy>;
  stddev_samp?: InputMaybe<WinboardMenuStddevSampOrderBy>;
  sum?: InputMaybe<WinboardMenuSumOrderBy>;
  var_pop?: InputMaybe<WinboardMenuVarPopOrderBy>;
  var_samp?: InputMaybe<WinboardMenuVarSampOrderBy>;
  variance?: InputMaybe<WinboardMenuVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "winboardMenu" */
export type WinboardMenuArrRelInsertInput = {
  data: Array<WinboardMenuInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<WinboardMenuOnConflict>;
};

/** order by avg() on columns of table "winboardMenu" */
export type WinboardMenuAvgOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "winboardMenu". All fields are combined with a logical 'AND'. */
export type WinboardMenuBoolExp = {
  _and?: InputMaybe<Array<WinboardMenuBoolExp>>;
  _choiceId?: InputMaybe<IntComparisonExp>;
  _menuId?: InputMaybe<IntComparisonExp>;
  _not?: InputMaybe<WinboardMenuBoolExp>;
  _or?: InputMaybe<Array<WinboardMenuBoolExp>>;
  _planChoiceId?: InputMaybe<IntComparisonExp>;
  _planId?: InputMaybe<IntComparisonExp>;
  bumonCode?: InputMaybe<StringComparisonExp>;
  bumonName?: InputMaybe<StringComparisonExp>;
  categoryCode?: InputMaybe<StringComparisonExp>;
  categoryName?: InputMaybe<StringComparisonExp>;
  choice?: InputMaybe<ChoiceBoolExp>;
  choiceId?: InputMaybe<UuidComparisonExp>;
  code?: InputMaybe<StringComparisonExp>;
  companyId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  menu?: InputMaybe<MenuBoolExp>;
  menuId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  plan?: InputMaybe<PlanBoolExp>;
  planChoice?: InputMaybe<PlanChoiceBoolExp>;
  planChoiceId?: InputMaybe<UuidComparisonExp>;
  planId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "winboardMenu" */
export enum WinboardMenuConstraint {
  /** unique or primary key constraint on columns "_choiceId", "_menuId" */
  WinboardMenuMenuIdChoiceIdKey = 'winboardMenu_menuId_choiceId_key',
  /** unique or primary key constraint on columns "_menuId" */
  WinboardMenuMenuIdKey = 'winboardMenu_menuId_key',
  /** unique or primary key constraint on columns "id" */
  WinboardMenuPkey = 'winboardMenu_pkey',
  /** unique or primary key constraint on columns "_planId", "_planChoiceId" */
  WinboardMenuPlanIdPlanChoiceIdKey = 'winboardMenu_planId_planChoiceId_key'
}

/** input type for incrementing numeric columns in table "winboardMenu" */
export type WinboardMenuIncInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "winboardMenu" */
export type WinboardMenuInsertInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  bumonCode?: InputMaybe<Scalars['String']>;
  bumonName?: InputMaybe<Scalars['String']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  categoryName?: InputMaybe<Scalars['String']>;
  choice?: InputMaybe<ChoiceObjRelInsertInput>;
  choiceId?: InputMaybe<Scalars['uuid']>;
  code?: InputMaybe<Scalars['String']>;
  companyId: Scalars['uuid'];
  id?: InputMaybe<Scalars['uuid']>;
  menu?: InputMaybe<MenuObjRelInsertInput>;
  menuId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<PlanObjRelInsertInput>;
  planChoice?: InputMaybe<PlanChoiceObjRelInsertInput>;
  planChoiceId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "winboardMenu" */
export type WinboardMenuMaxOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  bumonCode?: InputMaybe<OrderBy>;
  bumonName?: InputMaybe<OrderBy>;
  categoryCode?: InputMaybe<OrderBy>;
  categoryName?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "winboardMenu" */
export type WinboardMenuMinOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  bumonCode?: InputMaybe<OrderBy>;
  bumonName?: InputMaybe<OrderBy>;
  categoryCode?: InputMaybe<OrderBy>;
  categoryName?: InputMaybe<OrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menuId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "winboardMenu" */
export type WinboardMenuMutationResponse = {
  __typename?: 'winboardMenu_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WinboardMenu>;
};

/** on_conflict condition type for table "winboardMenu" */
export type WinboardMenuOnConflict = {
  constraint: WinboardMenuConstraint;
  update_columns?: Array<WinboardMenuUpdateColumn>;
  where?: InputMaybe<WinboardMenuBoolExp>;
};

/** Ordering options when selecting data from "winboardMenu". */
export type WinboardMenuOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
  bumonCode?: InputMaybe<OrderBy>;
  bumonName?: InputMaybe<OrderBy>;
  categoryCode?: InputMaybe<OrderBy>;
  categoryName?: InputMaybe<OrderBy>;
  choice?: InputMaybe<ChoiceOrderBy>;
  choiceId?: InputMaybe<OrderBy>;
  code?: InputMaybe<OrderBy>;
  companyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  menu?: InputMaybe<MenuOrderBy>;
  menuId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlanOrderBy>;
  planChoice?: InputMaybe<PlanChoiceOrderBy>;
  planChoiceId?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: winboardMenu */
export type WinboardMenuPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "winboardMenu" */
export enum WinboardMenuSelectColumn {
  /** column name */
  _ChoiceId = '_choiceId',
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  _PlanChoiceId = '_planChoiceId',
  /** column name */
  _PlanId = '_planId',
  /** column name */
  BumonCode = 'bumonCode',
  /** column name */
  BumonName = 'bumonName',
  /** column name */
  CategoryCode = 'categoryCode',
  /** column name */
  CategoryName = 'categoryName',
  /** column name */
  ChoiceId = 'choiceId',
  /** column name */
  Code = 'code',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Name = 'name',
  /** column name */
  PlanChoiceId = 'planChoiceId',
  /** column name */
  PlanId = 'planId'
}

/** input type for updating data in table "winboardMenu" */
export type WinboardMenuSetInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  bumonCode?: InputMaybe<Scalars['String']>;
  bumonName?: InputMaybe<Scalars['String']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  categoryName?: InputMaybe<Scalars['String']>;
  choiceId?: InputMaybe<Scalars['uuid']>;
  code?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  planChoiceId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "winboardMenu" */
export type WinboardMenuStddevOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "winboardMenu" */
export type WinboardMenuStddevPopOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "winboardMenu" */
export type WinboardMenuStddevSampOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "winboardMenu" */
export type WinboardMenuStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WinboardMenuStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrderingType>;
};

/** Initial value of the column from where the streaming should start */
export type WinboardMenuStreamCursorValueInput = {
  _choiceId?: InputMaybe<Scalars['Int']>;
  _menuId?: InputMaybe<Scalars['Int']>;
  _planChoiceId?: InputMaybe<Scalars['Int']>;
  _planId?: InputMaybe<Scalars['Int']>;
  bumonCode?: InputMaybe<Scalars['String']>;
  bumonName?: InputMaybe<Scalars['String']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  categoryName?: InputMaybe<Scalars['String']>;
  choiceId?: InputMaybe<Scalars['uuid']>;
  code?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  menuId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  planChoiceId?: InputMaybe<Scalars['uuid']>;
  planId?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "winboardMenu" */
export type WinboardMenuSumOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** update columns of table "winboardMenu" */
export enum WinboardMenuUpdateColumn {
  /** column name */
  _ChoiceId = '_choiceId',
  /** column name */
  _MenuId = '_menuId',
  /** column name */
  _PlanChoiceId = '_planChoiceId',
  /** column name */
  _PlanId = '_planId',
  /** column name */
  BumonCode = 'bumonCode',
  /** column name */
  BumonName = 'bumonName',
  /** column name */
  CategoryCode = 'categoryCode',
  /** column name */
  CategoryName = 'categoryName',
  /** column name */
  ChoiceId = 'choiceId',
  /** column name */
  Code = 'code',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  MenuId = 'menuId',
  /** column name */
  Name = 'name',
  /** column name */
  PlanChoiceId = 'planChoiceId',
  /** column name */
  PlanId = 'planId'
}

export type WinboardMenuUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WinboardMenuIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WinboardMenuSetInput>;
  /** filter the rows which have to be updated */
  where: WinboardMenuBoolExp;
};

/** order by var_pop() on columns of table "winboardMenu" */
export type WinboardMenuVarPopOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "winboardMenu" */
export type WinboardMenuVarSampOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "winboardMenu" */
export type WinboardMenuVarianceOrderBy = {
  _choiceId?: InputMaybe<OrderBy>;
  _menuId?: InputMaybe<OrderBy>;
  _planChoiceId?: InputMaybe<OrderBy>;
  _planId?: InputMaybe<OrderBy>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AdyenPaymentCreateStoreMerchantCategoryInput: AdyenPaymentCreateStoreMerchantCategoryInput;
  String: ResolverTypeWrapper<Scalars['String']>;
  AdyenPaymentCreateStoreMerchantCategoryResult: ResolverTypeWrapper<AdyenPaymentCreateStoreMerchantCategoryResult>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  AdyenPaymentUpdateStoreInput: AdyenPaymentUpdateStoreInput;
  AdyenPaymentUpdateStoreResult: ResolverTypeWrapper<AdyenPaymentUpdateStoreResult>;
  AggregatedCategory: ResolverTypeWrapper<AggregatedCategory>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  AggregatedChoice: ResolverTypeWrapper<AggregatedChoice>;
  AggregatedMenu: ResolverTypeWrapper<AggregatedMenu>;
  AggregatedOption: ResolverTypeWrapper<AggregatedOption>;
  AggregatedPlan: ResolverTypeWrapper<AggregatedPlan>;
  AggregatedPlanOption: ResolverTypeWrapper<AggregatedPlanOption>;
  AggregatedQuestionnaireScore: ResolverTypeWrapper<AggregatedQuestionnaireScore>;
  AggregatedSales: ResolverTypeWrapper<AggregatedSales>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  AmbassadorTransition: ResolverTypeWrapper<AmbassadorTransition>;
  ArchiveCompanyInput: ArchiveCompanyInput;
  ArchiveMenuInput: ArchiveMenuInput;
  ArchiveMenuResult: ResolverTypeWrapper<ArchiveMenuResult>;
  ArchivePlanInput: ArchivePlanInput;
  ArchivePlanResult: ResolverTypeWrapper<ArchivePlanResult>;
  ArchiveShopInput: ArchiveShopInput;
  ArchiveTableInput: ArchiveTableInput;
  AverageAndMaxSalesByDayOfWeek: ResolverTypeWrapper<AverageAndMaxSalesByDayOfWeek>;
  AverageAndMaxSalesByDayOfWeekInput: AverageAndMaxSalesByDayOfWeekInput;
  Boolean_comparison_exp: BooleanComparisonExp;
  CashBalanceCashDenominationCount: ResolverTypeWrapper<CashBalanceCashDenominationCount>;
  CashManagement: ResolverTypeWrapper<CashManagement>;
  CompanyCustomerSummary: ResolverTypeWrapper<CompanyCustomerSummary>;
  CompanyCustomerSummaryInput: CompanyCustomerSummaryInput;
  CompanyManagerDeleteDashboardAccountInput: CompanyManagerDeleteDashboardAccountInput;
  CompanySalesAnalyticsInput: CompanySalesAnalyticsInput;
  CompanySalesAnalyticsOutput: ResolverTypeWrapper<CompanySalesAnalyticsOutput>;
  CompanySalesAnalyticsOutputItem: ResolverTypeWrapper<CompanySalesAnalyticsOutputItem>;
  CopyDataInput: CopyDataInput;
  CountMessageDeliveryTargetsInput: CountMessageDeliveryTargetsInput;
  CouponAnalyticsInput: CouponAnalyticsInput;
  CouponAnalyticsPerCoupon: ResolverTypeWrapper<CouponAnalyticsPerCoupon>;
  CouponAnalyticsPerCouponItem: ResolverTypeWrapper<CouponAnalyticsPerCouponItem>;
  CouponAnalyticsSummary: ResolverTypeWrapper<CouponAnalyticsSummary>;
  CreateCategoryInputCategorySource: CreateCategoryInputCategorySource;
  CreateCategoryInputTranslationSource: CreateCategoryInputTranslationSource;
  CreateCategoryOutput: ResolverTypeWrapper<CreateCategoryOutput>;
  CreateCategoryTranslationSource: CreateCategoryTranslationSource;
  CreateCompanyInput: CreateCompanyInput;
  CreateCorporationInput: CreateCorporationInput;
  CreateDashboardAccountInput: CreateDashboardAccountInput;
  CreateLineOfficialAccountInput: CreateLineOfficialAccountInput;
  CreateMenuInput: CreateMenuInput;
  CreateMenuInputCategoryMenu: CreateMenuInputCategoryMenu;
  CreateMenuInputMenuSource: CreateMenuInputMenuSource;
  CreateMenuInputMenuSourceOption: CreateMenuInputMenuSourceOption;
  CreateMenuInputTranslationSource: CreateMenuInputTranslationSource;
  CreateMenuOutput: ResolverTypeWrapper<CreateMenuOutput>;
  CreateMenuRecommendationInputSource: CreateMenuRecommendationInputSource;
  CreateMenuRecommendationInputTranslationSource: CreateMenuRecommendationInputTranslationSource;
  CreateMenuRecommendationOutput: ResolverTypeWrapper<CreateMenuRecommendationOutput>;
  CreateMenuRecommendationTranslationSource: CreateMenuRecommendationTranslationSource;
  CreateMessageDeliveryInput: CreateMessageDeliveryInput;
  CreateMessageDeliveryInputMessageDeliveryCustomerList: CreateMessageDeliveryInputMessageDeliveryCustomerList;
  CreateMessageDeliveryInputMessageDeliveryCustomerListCustomer: CreateMessageDeliveryInputMessageDeliveryCustomerListCustomer;
  CreateMessageDeliveryInputMessageDeliveryCustomerMenu: CreateMessageDeliveryInputMessageDeliveryCustomerMenu;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegment: CreateMessageDeliveryInputMessageDeliveryCustomerSegment;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentBusinessOperationHour: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentBusinessOperationHour;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDayOfWeek: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDayOfWeek;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDaysCountUntilBirthday: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDaysCountUntilBirthday;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentGender: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentGender;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentNumericCondition: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentNumericCondition;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentQuestionnaireAnswer: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentQuestionnaireAnswer;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentUnVisitedDayCount: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentUnVisitedDayCount;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentVisitedDayCount: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentVisitedDayCount;
  CreateMessageDeliveryInputMessageDeliveryCustomerShop: CreateMessageDeliveryInputMessageDeliveryCustomerShop;
  CreateMessageDeliveryInputMessageDeliveryMessage: CreateMessageDeliveryInputMessageDeliveryMessage;
  CreateMessageDeliveryInputMessageDeliveryMessageCouponTypeMeta: CreateMessageDeliveryInputMessageDeliveryMessageCouponTypeMeta;
  CreateMessageDeliveryInputMessageDeliveryMessageImageTypeMeta: CreateMessageDeliveryInputMessageDeliveryMessageImageTypeMeta;
  CreateMessageDeliveryInputMessageDeliveryMessageQuestionnaireTypeMeta: CreateMessageDeliveryInputMessageDeliveryMessageQuestionnaireTypeMeta;
  CreateMessageDeliveryInputMessageDeliveryMessageTextTypeMeta: CreateMessageDeliveryInputMessageDeliveryMessageTextTypeMeta;
  CreateOptionInputOptionSource: CreateOptionInputOptionSource;
  CreateOptionInputOptionSourceChoice: CreateOptionInputOptionSourceChoice;
  CreateOptionInputTranslationSource: CreateOptionInputTranslationSource;
  CreateOptionOutput: ResolverTypeWrapper<CreateOptionOutput>;
  CreateOptionTranslationSource: CreateOptionTranslationSource;
  CreatePlanChoiceInput: CreatePlanChoiceInput;
  CreatePlanChoiceInputPlanChoiceSource: CreatePlanChoiceInputPlanChoiceSource;
  CreatePlanChoiceInputTranslationSource: CreatePlanChoiceInputTranslationSource;
  CreatePlanChoiceOutput: ResolverTypeWrapper<CreatePlanChoiceOutput>;
  CreatePlanChoiceTranslationSource: CreatePlanChoiceTranslationSource;
  CreatePlanInput: CreatePlanInput;
  CreatePlanInputPlanSource: CreatePlanInputPlanSource;
  CreatePlanInputTranslationSource: CreatePlanInputTranslationSource;
  CreatePlanOptionInput: CreatePlanOptionInput;
  CreatePlanOptionInputPlanOptionSource: CreatePlanOptionInputPlanOptionSource;
  CreatePlanOptionInputTranslationSource: CreatePlanOptionInputTranslationSource;
  CreatePlanOptionOutput: ResolverTypeWrapper<CreatePlanOptionOutput>;
  CreatePlanOptionTranslationSource: CreatePlanOptionTranslationSource;
  CreatePlanOutput: ResolverTypeWrapper<CreatePlanOutput>;
  CreatePlanTranslationSource: CreatePlanTranslationSource;
  CreateServiceAdminInput: CreateServiceAdminInput;
  CreateShopInput: CreateShopInput;
  CreateShopMenusInput: CreateShopMenusInput;
  CreateShopMenusResult: ResolverTypeWrapper<CreateShopMenusResult>;
  CreateShopPlanInput: CreateShopPlanInput;
  CreateShopPlanResult: ResolverTypeWrapper<CreateShopPlanResult>;
  CreateSurchargeConfigsInput: CreateSurchargeConfigsInput;
  CreateSurchargeConfigsResult: ResolverTypeWrapper<CreateSurchargeConfigsResult>;
  CreateTranslationSource: CreateTranslationSource;
  CumulativeCount: ResolverTypeWrapper<CumulativeCount>;
  CurrentSales: ResolverTypeWrapper<CurrentSales>;
  CurrentSaleses: ResolverTypeWrapper<CurrentSaleses>;
  CustomerCountTransition: ResolverTypeWrapper<CustomerCountTransition>;
  CustomerDashboardKpi: ResolverTypeWrapper<CustomerDashboardKpi>;
  CustomerDashboardKpis: ResolverTypeWrapper<CustomerDashboardKpis>;
  CustomerDashboardKpisInput: CustomerDashboardKpisInput;
  CustomerDashboardTransitions: ResolverTypeWrapper<CustomerDashboardTransitions>;
  CustomerFaveYellRanking: ResolverTypeWrapper<CustomerFaveYellRanking>;
  CustomerFaveYellRankingCustomer: ResolverTypeWrapper<CustomerFaveYellRankingCustomer>;
  CustomerRanking: ResolverTypeWrapper<CustomerRanking>;
  CustomerRankingCustomer: ResolverTypeWrapper<CustomerRankingCustomer>;
  DailyCashRegisterBalancing: ResolverTypeWrapper<DailyCashRegisterBalancing>;
  DailySalesAmount: ResolverTypeWrapper<DailySalesAmount>;
  DailySalesSummary: ResolverTypeWrapper<DailySalesSummary>;
  DashboardAccountCancelOnSitePaymentInput: DashboardAccountCancelOnSitePaymentInput;
  DashboardAccountCreateOrUpdateGmoBankAccountInput: DashboardAccountCreateOrUpdateGmoBankAccountInput;
  DashboardAccountCreateOrUpdateGmoBankAccountResult: ResolverTypeWrapper<DashboardAccountCreateOrUpdateGmoBankAccountResult>;
  DashboardAccountCreateTecAggregationMenuOutput: ResolversTypes['DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode'] | ResolversTypes['DashboardUpsertTecAggregationMenuSuccess'];
  DashboardAccountGmoBankAccountDetailInput: DashboardAccountGmoBankAccountDetailInput;
  DashboardAccountGmoBankAccountDetailResult: ResolverTypeWrapper<DashboardAccountGmoBankAccountDetailResult>;
  DashboardAccountGmoShopConfigDetailInput: DashboardAccountGmoShopConfigDetailInput;
  DashboardAccountGmoShopConfigDetailOutput: ResolverTypeWrapper<DashboardAccountGmoShopConfigDetailOutput>;
  DashboardAccountOnSitePaymentDetail: DashboardAccountOnSitePaymentDetail;
  DashboardAccountOnSitePaymentDiscount: DashboardAccountOnSitePaymentDiscount;
  DashboardAccountPayingActivePlanChoice: DashboardAccountPayingActivePlanChoice;
  DashboardAccountPayingActivePlanOpenPriceMeta: DashboardAccountPayingActivePlanOpenPriceMeta;
  DashboardAccountPayingMenuOrderItem: DashboardAccountPayingMenuOrderItem;
  DashboardAccountUpsertDiniiPayConfigInput: DashboardAccountUpsertDiniiPayConfigInput;
  DashboardAccountUpsertDiniiPayConfigOnlinePaymentConfig: DashboardAccountUpsertDiniiPayConfigOnlinePaymentConfig;
  DashboardAccountUpsertDiniiPayConfigOutput: ResolverTypeWrapper<DashboardAccountUpsertDiniiPayConfigOutput>;
  DashboardAccountUpsertDiniiPayConfigTerminalPaymentConfig: DashboardAccountUpsertDiniiPayConfigTerminalPaymentConfig;
  DashboardAccountUpsertTecAggregationMenuInput: DashboardAccountUpsertTecAggregationMenuInput;
  DashboardAccountUpsertTecAggregationMenusBulkInput: DashboardAccountUpsertTecAggregationMenusBulkInput;
  DashboardAccountUpsertTecAggregationMenusBulkOutput: ResolversTypes['DashboardUpsertTecAggregationMenuSuccess'] | ResolversTypes['DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode'];
  DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode: ResolverTypeWrapper<DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode>;
  DashboardUpsertTecAggregationMenuSuccess: ResolverTypeWrapper<DashboardUpsertTecAggregationMenuSuccess>;
  DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode: ResolverTypeWrapper<DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DeleteShopChoicesInput: DeleteShopChoicesInput;
  DeleteShopChoicesResult: ResolverTypeWrapper<DeleteShopChoicesResult>;
  DeleteShopMenusInput: DeleteShopMenusInput;
  DeleteShopMenusResult: ResolverTypeWrapper<DeleteShopMenusResult>;
  DeleteShopPlanChoicesInput: DeleteShopPlanChoicesInput;
  DeleteShopPlanChoicesResult: ResolverTypeWrapper<DeleteShopPlanChoicesResult>;
  DeleteShopPlansInput: DeleteShopPlansInput;
  DeleteShopPlansResult: ResolverTypeWrapper<DeleteShopPlansResult>;
  DepositCycleShopIds: DepositCycleShopIds;
  DistributionItem: ResolverTypeWrapper<DistributionItem>;
  EbicaApiTable: ResolverTypeWrapper<EbicaApiTable>;
  EbicaApiTablesInput: EbicaApiTablesInput;
  EnableOnlinePaymentInput: EnableOnlinePaymentInput;
  EnableOnlinePaymentOutput: ResolverTypeWrapper<EnableOnlinePaymentOutput>;
  FaveYellAnalyticsByCorporationInput: FaveYellAnalyticsByCorporationInput;
  FaveYellAnalyticsByCorporationOutput: ResolverTypeWrapper<FaveYellAnalyticsByCorporationOutput>;
  FaveYellAnalyticsPerStaffByCorporationItem: ResolverTypeWrapper<FaveYellAnalyticsPerStaffByCorporationItem>;
  FaveYellAnalyticsSummaryByCorporationOutputItem: ResolverTypeWrapper<FaveYellAnalyticsSummaryByCorporationOutputItem>;
  FaveYellTransition: ResolverTypeWrapper<FaveYellTransition>;
  FluctuatingStatus: FluctuatingStatusType;
  FoodingJournalUploadSalesInput: FoodingJournalUploadSalesInput;
  GeneralTransition: ResolverTypeWrapper<GeneralTransition>;
  GmoBankAccountType: GmoBankAccountTypeType;
  Int_comparison_exp: IntComparisonExp;
  IssueLineReportingBotVerificationCodeInput: IssueLineReportingBotVerificationCodeInput;
  IssueLineReportingBotVerificationCodeResponse: ResolverTypeWrapper<IssueLineReportingBotVerificationCodeResponse>;
  LineOfficialAccountProfile: ResolverTypeWrapper<LineOfficialAccountProfile>;
  LineOfficialAccountSummary: ResolverTypeWrapper<LineOfficialAccountSummary>;
  MenuOrderItemAnalyticsInput: MenuOrderItemAnalyticsInput;
  MenuOrderItemAnalyticsOutput: ResolverTypeWrapper<MenuOrderItemAnalyticsOutput>;
  MenuOrderItemAnalyticsOutputItem: ResolverTypeWrapper<MenuOrderItemAnalyticsOutputItem>;
  MessageDeliveryAnalyticsInput: MessageDeliveryAnalyticsInput;
  MessageDeliveryAnalyticsRecentVisitedTableUser: ResolverTypeWrapper<MessageDeliveryAnalyticsRecentVisitedTableUser>;
  MessageDeliveryAnalyticsRecentVisitedTableUsersReport: ResolverTypeWrapper<MessageDeliveryAnalyticsRecentVisitedTableUsersReport>;
  MessageDeliveryAnalyticsSummary: ResolverTypeWrapper<MessageDeliveryAnalyticsSummary>;
  MessageDeliveryAnalyticsSummaryPerMessageDelivery: ResolverTypeWrapper<MessageDeliveryAnalyticsSummaryPerMessageDelivery>;
  MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem: ResolverTypeWrapper<MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem>;
  MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem: ResolverTypeWrapper<MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem>;
  MessageDeliveryAnalyticsSummaryPerShop: ResolverTypeWrapper<MessageDeliveryAnalyticsSummaryPerShop>;
  MessageDeliveryAnalyticsSummaryPerShopItem: ResolverTypeWrapper<MessageDeliveryAnalyticsSummaryPerShopItem>;
  MessageDeliveryConsumption: ResolverTypeWrapper<MessageDeliveryConsumption>;
  MonthlySalesAmount: ResolverTypeWrapper<MonthlySalesAmount>;
  PaymentProvider: PaymentProviderType;
  QueryCashBalanceInput: QueryCashBalanceInput;
  QueryCashManagementInput: QueryCashManagementInput;
  QueryCumulativeCountInput: QueryCumulativeCountInput;
  QueryCurrentSalesInput: QueryCurrentSalesInput;
  QueryCustomerRankingInput: QueryCustomerRankingInput;
  QueryDailyCashRegisterBalancingInput: QueryDailyCashRegisterBalancingInput;
  QueryDailySalesSummaryInput: QueryDailySalesSummaryInput;
  QueryLineOfficialAccountInput: QueryLineOfficialAccountInput;
  QueryLineOfficialAccountSummaryInput: QueryLineOfficialAccountSummaryInput;
  QueryMessageDeliveryConsumptionInput: QueryMessageDeliveryConsumptionInput;
  QuerySalesBreakdownByPaymentMethodInput: QuerySalesBreakdownByPaymentMethodInput;
  QuerySalesInput: QuerySalesInput;
  QueryTransitionsInput: QueryTransitionsInput;
  QuestionCategory: QuestionCategoryType;
  QuestionnaireAnswererAttributeMetricsDistributionItem: ResolverTypeWrapper<QuestionnaireAnswererAttributeMetricsDistributionItem>;
  QuestionnaireAnswererAttributeMetricsInput: QuestionnaireAnswererAttributeMetricsInput;
  QuestionnaireAnswererAttributeMetricsOutput: ResolverTypeWrapper<QuestionnaireAnswererAttributeMetricsOutput>;
  QuestionnaireAnswererSegment: QuestionnaireAnswererSegmentType;
  QuestionnaireComment: ResolverTypeWrapper<QuestionnaireComment>;
  QuestionnaireCommentCountOutput: ResolverTypeWrapper<QuestionnaireCommentCountOutput>;
  QuestionnaireCommentsInput: QuestionnaireCommentsInput;
  QuestionnaireCorporationAverageScore: ResolverTypeWrapper<QuestionnaireCorporationAverageScore>;
  QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics: ResolverTypeWrapper<QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics>;
  QuestionnaireMetricsPerCategoryInput: QuestionnaireMetricsPerCategoryInput;
  QuestionnaireMetricsPerCategoryOutput: ResolverTypeWrapper<Omit<QuestionnaireMetricsPerCategoryOutput, 'questionAnswerMetrics'> & { questionAnswerMetrics: Array<ResolversTypes['QuestionnaireMetricsPerCategoryQuestionAnswerMetrics']> }>;
  QuestionnaireMetricsPerCategoryQuestionAnswerMetrics: ResolversTypes['QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics'] | ResolversTypes['QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics'];
  QuestionnaireMetricsPerCategoryScoreDistributionMetrics: ResolverTypeWrapper<QuestionnaireMetricsPerCategoryScoreDistributionMetrics>;
  QuestionnaireMetricsPerCategoryScoreMetrics: ResolverTypeWrapper<QuestionnaireMetricsPerCategoryScoreMetrics>;
  QuestionnaireMetricsPerCategoryTextTypeAnswer: ResolverTypeWrapper<QuestionnaireMetricsPerCategoryTextTypeAnswer>;
  QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics: ResolverTypeWrapper<QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics>;
  QuestionnaireScoreChangesDimension: QuestionnaireScoreChangesDimensionType;
  QuestionnaireScoreChangesMetrics: ResolverTypeWrapper<QuestionnaireScoreChangesMetrics>;
  QuestionnaireScoreChangesMetricsInput: QuestionnaireScoreChangesMetricsInput;
  QuestionnaireScoreMetricsInput: QuestionnaireScoreMetricsInput;
  QuestionnaireScoreMetricsOutput: ResolverTypeWrapper<QuestionnaireScoreMetricsOutput>;
  QuestionnaireScoreWithFluctuation: ResolverTypeWrapper<QuestionnaireScoreWithFluctuation>;
  QuestionnaireShopAverageScore: ResolverTypeWrapper<QuestionnaireShopAverageScore>;
  RepeaterTransition: ResolverTypeWrapper<RepeaterTransition>;
  Sales: ResolverTypeWrapper<Sales>;
  SalesAggregationDimension: SalesAggregationDimensionType;
  SalesAnalyticsInput: SalesAnalyticsInput;
  SalesAnalyticsOutput: ResolverTypeWrapper<SalesAnalyticsOutput>;
  SalesAnalyticsOutputItem: ResolverTypeWrapper<SalesAnalyticsOutputItem>;
  SalesAnalyticsReportingType: SalesAnalyticsReportingTypeType;
  SalesBreakdownByPaymentMethod: ResolverTypeWrapper<SalesBreakdownByPaymentMethod>;
  SalesByDayOfWeekAndHourAnalyticsInput: SalesByDayOfWeekAndHourAnalyticsInput;
  SalesByDayOfWeekAndHourAnalyticsOutput: ResolverTypeWrapper<SalesByDayOfWeekAndHourAnalyticsOutput>;
  SalesByDayOfWeekAndHourAnalyticsOutputItem: ResolverTypeWrapper<SalesByDayOfWeekAndHourAnalyticsOutputItem>;
  SalesByDays: ResolverTypeWrapper<SalesByDays>;
  SalesByDaysInput: SalesByDaysInput;
  SalesByMonths: ResolverTypeWrapper<SalesByMonths>;
  SalesByMonthsInput: SalesByMonthsInput;
  SalesForBudget: ResolverTypeWrapper<SalesForBudget>;
  SalesForBudgetInput: SalesForBudgetInput;
  SalesWithPaymentMethod: ResolverTypeWrapper<SalesWithPaymentMethod>;
  SegmentedCustomer: ResolverTypeWrapper<SegmentedCustomer>;
  ServiceChargeConfigInput: ServiceChargeConfigInput;
  SetCurrentCompanyInput: SetCurrentCompanyInput;
  SetCurrentShopInput: SetCurrentShopInput;
  ShopApiAggregationGetQuantityAndSalesAggregationInput: ShopApiAggregationGetQuantityAndSalesAggregationInput;
  ShopApiAggregationGetQuantityAndSalesAggregationInputWithShopId: ShopApiAggregationGetQuantityAndSalesAggregationInputWithShopId;
  ShopApiAggregationGetQuantityAndSalesAggregationOutput: ResolverTypeWrapper<ShopApiAggregationGetQuantityAndSalesAggregationOutput>;
  ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutput: ResolverTypeWrapper<ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutput>;
  ShopCouponUsage: ResolverTypeWrapper<ShopCouponUsage>;
  ShopDailyCashRegisterBalancing: ResolverTypeWrapper<ShopDailyCashRegisterBalancing>;
  SpecificTimeSurchargeConfigInput: SpecificTimeSurchargeConfigInput;
  String_comparison_exp: StringComparisonExp;
  SupportedLocaleEnum: SupportedLocaleEnumType;
  UpdateBankDepositAmountInput: UpdateBankDepositAmountInput;
  UpdateCategoryMenuBulkInput: UpdateCategoryMenuBulkInput;
  UpdateCategoryMenusBulkInput: UpdateCategoryMenusBulkInput;
  UpdateDashboardAccountInput: UpdateDashboardAccountInput;
  UpdateLineOfficialAccountInput: UpdateLineOfficialAccountInput;
  UpdateMenuBulkInput: UpdateMenuBulkInput;
  UpdateMessageDeliveryInput: UpdateMessageDeliveryInput;
  UpdateOnSitePaymentInput: UpdateOnSitePaymentInput;
  UpdateShopMenusBulkInput: UpdateShopMenusBulkInput;
  UpdateShopMenusBulkResult: ResolverTypeWrapper<UpdateShopMenusBulkResult>;
  UpdateShopMenusInput: UpdateShopMenusInput;
  UpdateShopMenusInputChoice: UpdateShopMenusInputChoice;
  UpdateShopMenusInputMenu: UpdateShopMenusInputMenu;
  UpdateShopPlansBulkInput: UpdateShopPlansBulkInput;
  UpdateShopPlansBulkResult: ResolverTypeWrapper<UpdateShopPlansBulkResult>;
  UpdateTargetCountOutput: ResolverTypeWrapper<UpdateTargetCountOutput>;
  UpsertShopChoicesInput: UpsertShopChoicesInput;
  UpsertShopChoicesResult: ResolverTypeWrapper<UpsertShopChoicesResult>;
  UpsertShopPlanChoiceInput: UpsertShopPlanChoiceInput;
  UpsertShopPlanChoiceResult: ResolverTypeWrapper<UpsertShopPlanChoiceResult>;
  UpsertTranslationsForCategoryInput: UpsertTranslationsForCategoryInput;
  UpsertTranslationsForChoiceInput: UpsertTranslationsForChoiceInput;
  UpsertTranslationsForMenuInput: UpsertTranslationsForMenuInput;
  UpsertTranslationsForMenuRecommendationMetaInput: UpsertTranslationsForMenuRecommendationMetaInput;
  UpsertTranslationsForOptionInput: UpsertTranslationsForOptionInput;
  UpsertTranslationsForPlanChoiceInput: UpsertTranslationsForPlanChoiceInput;
  UpsertTranslationsForPlanInput: UpsertTranslationsForPlanInput;
  UpsertTranslationsForPlanOptionInput: UpsertTranslationsForPlanOptionInput;
  UpsertTranslationsSource: UpsertTranslationsSource;
  ValidLineOfficialAccount: ResolverTypeWrapper<ValidLineOfficialAccount>;
  ValidLineOfficialAccountInput: ValidLineOfficialAccountInput;
  VisitedCustomerSegments: ResolverTypeWrapper<VisitedCustomerSegments>;
  VisitedLineCustomer: ResolverTypeWrapper<VisitedLineCustomer>;
  accountingActionType_enum: AccountingActionTypeEnum;
  accountingActionType_enum_comparison_exp: AccountingActionTypeEnumComparisonExp;
  accountingSlipImage: ResolverTypeWrapper<AccountingSlipImage>;
  accountingSlipImagePosition_enum: AccountingSlipImagePositionEnum;
  accountingSlipImagePosition_enum_comparison_exp: AccountingSlipImagePositionEnumComparisonExp;
  accountingSlipImage_aggregate_order_by: AccountingSlipImageAggregateOrderBy;
  accountingSlipImage_arr_rel_insert_input: AccountingSlipImageArrRelInsertInput;
  accountingSlipImage_avg_order_by: AccountingSlipImageAvgOrderBy;
  accountingSlipImage_bool_exp: AccountingSlipImageBoolExp;
  accountingSlipImage_constraint: AccountingSlipImageConstraint;
  accountingSlipImage_inc_input: AccountingSlipImageIncInput;
  accountingSlipImage_insert_input: AccountingSlipImageInsertInput;
  accountingSlipImage_max_order_by: AccountingSlipImageMaxOrderBy;
  accountingSlipImage_min_order_by: AccountingSlipImageMinOrderBy;
  accountingSlipImage_mutation_response: ResolverTypeWrapper<AccountingSlipImageMutationResponse>;
  accountingSlipImage_on_conflict: AccountingSlipImageOnConflict;
  accountingSlipImage_order_by: AccountingSlipImageOrderBy;
  accountingSlipImage_pk_columns_input: AccountingSlipImagePkColumnsInput;
  accountingSlipImage_select_column: AccountingSlipImageSelectColumn;
  accountingSlipImage_set_input: AccountingSlipImageSetInput;
  accountingSlipImage_stddev_order_by: AccountingSlipImageStddevOrderBy;
  accountingSlipImage_stddev_pop_order_by: AccountingSlipImageStddevPopOrderBy;
  accountingSlipImage_stddev_samp_order_by: AccountingSlipImageStddevSampOrderBy;
  accountingSlipImage_stream_cursor_input: AccountingSlipImageStreamCursorInput;
  accountingSlipImage_stream_cursor_value_input: AccountingSlipImageStreamCursorValueInput;
  accountingSlipImage_sum_order_by: AccountingSlipImageSumOrderBy;
  accountingSlipImage_update_column: AccountingSlipImageUpdateColumn;
  accountingSlipImage_updates: AccountingSlipImageUpdates;
  accountingSlipImage_var_pop_order_by: AccountingSlipImageVarPopOrderBy;
  accountingSlipImage_var_samp_order_by: AccountingSlipImageVarSampOrderBy;
  accountingSlipImage_variance_order_by: AccountingSlipImageVarianceOrderBy;
  activePlan: ResolverTypeWrapper<ActivePlan>;
  activePlanChoice: ResolverTypeWrapper<ActivePlanChoice>;
  activePlanChoice_aggregate_order_by: ActivePlanChoiceAggregateOrderBy;
  activePlanChoice_avg_order_by: ActivePlanChoiceAvgOrderBy;
  activePlanChoice_bool_exp: ActivePlanChoiceBoolExp;
  activePlanChoice_max_order_by: ActivePlanChoiceMaxOrderBy;
  activePlanChoice_min_order_by: ActivePlanChoiceMinOrderBy;
  activePlanChoice_order_by: ActivePlanChoiceOrderBy;
  activePlanChoice_select_column: ActivePlanChoiceSelectColumn;
  activePlanChoice_stddev_order_by: ActivePlanChoiceStddevOrderBy;
  activePlanChoice_stddev_pop_order_by: ActivePlanChoiceStddevPopOrderBy;
  activePlanChoice_stddev_samp_order_by: ActivePlanChoiceStddevSampOrderBy;
  activePlanChoice_stream_cursor_input: ActivePlanChoiceStreamCursorInput;
  activePlanChoice_stream_cursor_value_input: ActivePlanChoiceStreamCursorValueInput;
  activePlanChoice_sum_order_by: ActivePlanChoiceSumOrderBy;
  activePlanChoice_var_pop_order_by: ActivePlanChoiceVarPopOrderBy;
  activePlanChoice_var_samp_order_by: ActivePlanChoiceVarSampOrderBy;
  activePlanChoice_variance_order_by: ActivePlanChoiceVarianceOrderBy;
  activePlanOpenPriceMeta: ResolverTypeWrapper<ActivePlanOpenPriceMeta>;
  activePlanOpenPriceMeta_bool_exp: ActivePlanOpenPriceMetaBoolExp;
  activePlanOpenPriceMeta_order_by: ActivePlanOpenPriceMetaOrderBy;
  activePlanOpenPriceMeta_select_column: ActivePlanOpenPriceMetaSelectColumn;
  activePlanOpenPriceMeta_stream_cursor_input: ActivePlanOpenPriceMetaStreamCursorInput;
  activePlanOpenPriceMeta_stream_cursor_value_input: ActivePlanOpenPriceMetaStreamCursorValueInput;
  activePlanOption: ResolverTypeWrapper<ActivePlanOption>;
  activePlanOption_aggregate_order_by: ActivePlanOptionAggregateOrderBy;
  activePlanOption_avg_order_by: ActivePlanOptionAvgOrderBy;
  activePlanOption_bool_exp: ActivePlanOptionBoolExp;
  activePlanOption_max_order_by: ActivePlanOptionMaxOrderBy;
  activePlanOption_min_order_by: ActivePlanOptionMinOrderBy;
  activePlanOption_order_by: ActivePlanOptionOrderBy;
  activePlanOption_select_column: ActivePlanOptionSelectColumn;
  activePlanOption_stddev_order_by: ActivePlanOptionStddevOrderBy;
  activePlanOption_stddev_pop_order_by: ActivePlanOptionStddevPopOrderBy;
  activePlanOption_stddev_samp_order_by: ActivePlanOptionStddevSampOrderBy;
  activePlanOption_stream_cursor_input: ActivePlanOptionStreamCursorInput;
  activePlanOption_stream_cursor_value_input: ActivePlanOptionStreamCursorValueInput;
  activePlanOption_sum_order_by: ActivePlanOptionSumOrderBy;
  activePlanOption_var_pop_order_by: ActivePlanOptionVarPopOrderBy;
  activePlanOption_var_samp_order_by: ActivePlanOptionVarSampOrderBy;
  activePlanOption_variance_order_by: ActivePlanOptionVarianceOrderBy;
  activePlan_aggregate_order_by: ActivePlanAggregateOrderBy;
  activePlan_avg_order_by: ActivePlanAvgOrderBy;
  activePlan_bool_exp: ActivePlanBoolExp;
  activePlan_max_order_by: ActivePlanMaxOrderBy;
  activePlan_min_order_by: ActivePlanMinOrderBy;
  activePlan_order_by: ActivePlanOrderBy;
  activePlan_select_column: ActivePlanSelectColumn;
  activePlan_stddev_order_by: ActivePlanStddevOrderBy;
  activePlan_stddev_pop_order_by: ActivePlanStddevPopOrderBy;
  activePlan_stddev_samp_order_by: ActivePlanStddevSampOrderBy;
  activePlan_stream_cursor_input: ActivePlanStreamCursorInput;
  activePlan_stream_cursor_value_input: ActivePlanStreamCursorValueInput;
  activePlan_sum_order_by: ActivePlanSumOrderBy;
  activePlan_var_pop_order_by: ActivePlanVarPopOrderBy;
  activePlan_var_samp_order_by: ActivePlanVarSampOrderBy;
  activePlan_variance_order_by: ActivePlanVarianceOrderBy;
  adyenPaymentMethodType_enum: AdyenPaymentMethodTypeEnum;
  adyenPaymentMethodType_enum_comparison_exp: AdyenPaymentMethodTypeEnumComparisonExp;
  adyenPaymentReportTerminalPaymentAuthorizedEvent: ResolverTypeWrapper<AdyenPaymentReportTerminalPaymentAuthorizedEvent>;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_bool_exp: AdyenPaymentReportTerminalPaymentAuthorizedEventBoolExp;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_order_by: AdyenPaymentReportTerminalPaymentAuthorizedEventOrderBy;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_select_column: AdyenPaymentReportTerminalPaymentAuthorizedEventSelectColumn;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_stream_cursor_input: AdyenPaymentReportTerminalPaymentAuthorizedEventStreamCursorInput;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_stream_cursor_value_input: AdyenPaymentReportTerminalPaymentAuthorizedEventStreamCursorValueInput;
  adyenPaymentReportTerminalPaymentCanceledEvent: ResolverTypeWrapper<AdyenPaymentReportTerminalPaymentCanceledEvent>;
  adyenPaymentReportTerminalPaymentCanceledEvent_bool_exp: AdyenPaymentReportTerminalPaymentCanceledEventBoolExp;
  adyenPaymentReportTerminalPaymentCanceledEvent_order_by: AdyenPaymentReportTerminalPaymentCanceledEventOrderBy;
  adyenPaymentReportTerminalPaymentCanceledEvent_select_column: AdyenPaymentReportTerminalPaymentCanceledEventSelectColumn;
  adyenPaymentReportTerminalPaymentCanceledEvent_stream_cursor_input: AdyenPaymentReportTerminalPaymentCanceledEventStreamCursorInput;
  adyenPaymentReportTerminalPaymentCanceledEvent_stream_cursor_value_input: AdyenPaymentReportTerminalPaymentCanceledEventStreamCursorValueInput;
  adyenPaymentShopConfig: ResolverTypeWrapper<AdyenPaymentShopConfig>;
  adyenPaymentShopConfig_bool_exp: AdyenPaymentShopConfigBoolExp;
  adyenPaymentShopConfig_order_by: AdyenPaymentShopConfigOrderBy;
  adyenPaymentShopConfig_select_column: AdyenPaymentShopConfigSelectColumn;
  adyenPaymentShopConfig_stream_cursor_input: AdyenPaymentShopConfigStreamCursorInput;
  adyenPaymentShopConfig_stream_cursor_value_input: AdyenPaymentShopConfigStreamCursorValueInput;
  adyenPaymentShopPaymentMethod: ResolverTypeWrapper<AdyenPaymentShopPaymentMethod>;
  adyenPaymentShopPaymentMethod_aggregate_order_by: AdyenPaymentShopPaymentMethodAggregateOrderBy;
  adyenPaymentShopPaymentMethod_avg_order_by: AdyenPaymentShopPaymentMethodAvgOrderBy;
  adyenPaymentShopPaymentMethod_bool_exp: AdyenPaymentShopPaymentMethodBoolExp;
  adyenPaymentShopPaymentMethod_max_order_by: AdyenPaymentShopPaymentMethodMaxOrderBy;
  adyenPaymentShopPaymentMethod_min_order_by: AdyenPaymentShopPaymentMethodMinOrderBy;
  adyenPaymentShopPaymentMethod_order_by: AdyenPaymentShopPaymentMethodOrderBy;
  adyenPaymentShopPaymentMethod_select_column: AdyenPaymentShopPaymentMethodSelectColumn;
  adyenPaymentShopPaymentMethod_stddev_order_by: AdyenPaymentShopPaymentMethodStddevOrderBy;
  adyenPaymentShopPaymentMethod_stddev_pop_order_by: AdyenPaymentShopPaymentMethodStddevPopOrderBy;
  adyenPaymentShopPaymentMethod_stddev_samp_order_by: AdyenPaymentShopPaymentMethodStddevSampOrderBy;
  adyenPaymentShopPaymentMethod_stream_cursor_input: AdyenPaymentShopPaymentMethodStreamCursorInput;
  adyenPaymentShopPaymentMethod_stream_cursor_value_input: AdyenPaymentShopPaymentMethodStreamCursorValueInput;
  adyenPaymentShopPaymentMethod_sum_order_by: AdyenPaymentShopPaymentMethodSumOrderBy;
  adyenPaymentShopPaymentMethod_var_pop_order_by: AdyenPaymentShopPaymentMethodVarPopOrderBy;
  adyenPaymentShopPaymentMethod_var_samp_order_by: AdyenPaymentShopPaymentMethodVarSampOrderBy;
  adyenPaymentShopPaymentMethod_variance_order_by: AdyenPaymentShopPaymentMethodVarianceOrderBy;
  adyenPaymentStoreMeta: ResolverTypeWrapper<AdyenPaymentStoreMeta>;
  adyenPaymentStoreMeta_bool_exp: AdyenPaymentStoreMetaBoolExp;
  adyenPaymentStoreMeta_constraint: AdyenPaymentStoreMetaConstraint;
  adyenPaymentStoreMeta_insert_input: AdyenPaymentStoreMetaInsertInput;
  adyenPaymentStoreMeta_mutation_response: ResolverTypeWrapper<AdyenPaymentStoreMetaMutationResponse>;
  adyenPaymentStoreMeta_on_conflict: AdyenPaymentStoreMetaOnConflict;
  adyenPaymentStoreMeta_order_by: AdyenPaymentStoreMetaOrderBy;
  adyenPaymentStoreMeta_select_column: AdyenPaymentStoreMetaSelectColumn;
  adyenPaymentStoreMeta_stream_cursor_input: AdyenPaymentStoreMetaStreamCursorInput;
  adyenPaymentStoreMeta_stream_cursor_value_input: AdyenPaymentStoreMetaStreamCursorValueInput;
  adyenPaymentStoreMeta_update_column: AdyenPaymentStoreMetaUpdateColumn;
  adyenTerminalPaymentShopConfig: ResolverTypeWrapper<AdyenTerminalPaymentShopConfig>;
  adyenTerminalPaymentShopConfig_bool_exp: AdyenTerminalPaymentShopConfigBoolExp;
  adyenTerminalPaymentShopConfig_order_by: AdyenTerminalPaymentShopConfigOrderBy;
  adyenTerminalPaymentShopConfig_select_column: AdyenTerminalPaymentShopConfigSelectColumn;
  adyenTerminalPaymentShopConfig_stream_cursor_input: AdyenTerminalPaymentShopConfigStreamCursorInput;
  adyenTerminalPaymentShopConfig_stream_cursor_value_input: AdyenTerminalPaymentShopConfigStreamCursorValueInput;
  adyenTerminalPaymentShopPaymentMethod: ResolverTypeWrapper<AdyenTerminalPaymentShopPaymentMethod>;
  adyenTerminalPaymentShopPaymentMethod_aggregate_order_by: AdyenTerminalPaymentShopPaymentMethodAggregateOrderBy;
  adyenTerminalPaymentShopPaymentMethod_avg_order_by: AdyenTerminalPaymentShopPaymentMethodAvgOrderBy;
  adyenTerminalPaymentShopPaymentMethod_bool_exp: AdyenTerminalPaymentShopPaymentMethodBoolExp;
  adyenTerminalPaymentShopPaymentMethod_max_order_by: AdyenTerminalPaymentShopPaymentMethodMaxOrderBy;
  adyenTerminalPaymentShopPaymentMethod_min_order_by: AdyenTerminalPaymentShopPaymentMethodMinOrderBy;
  adyenTerminalPaymentShopPaymentMethod_order_by: AdyenTerminalPaymentShopPaymentMethodOrderBy;
  adyenTerminalPaymentShopPaymentMethod_select_column: AdyenTerminalPaymentShopPaymentMethodSelectColumn;
  adyenTerminalPaymentShopPaymentMethod_stddev_order_by: AdyenTerminalPaymentShopPaymentMethodStddevOrderBy;
  adyenTerminalPaymentShopPaymentMethod_stddev_pop_order_by: AdyenTerminalPaymentShopPaymentMethodStddevPopOrderBy;
  adyenTerminalPaymentShopPaymentMethod_stddev_samp_order_by: AdyenTerminalPaymentShopPaymentMethodStddevSampOrderBy;
  adyenTerminalPaymentShopPaymentMethod_stream_cursor_input: AdyenTerminalPaymentShopPaymentMethodStreamCursorInput;
  adyenTerminalPaymentShopPaymentMethod_stream_cursor_value_input: AdyenTerminalPaymentShopPaymentMethodStreamCursorValueInput;
  adyenTerminalPaymentShopPaymentMethod_sum_order_by: AdyenTerminalPaymentShopPaymentMethodSumOrderBy;
  adyenTerminalPaymentShopPaymentMethod_var_pop_order_by: AdyenTerminalPaymentShopPaymentMethodVarPopOrderBy;
  adyenTerminalPaymentShopPaymentMethod_var_samp_order_by: AdyenTerminalPaymentShopPaymentMethodVarSampOrderBy;
  adyenTerminalPaymentShopPaymentMethod_variance_order_by: AdyenTerminalPaymentShopPaymentMethodVarianceOrderBy;
  analyticsSetting: ResolverTypeWrapper<AnalyticsSetting>;
  analyticsSetting_bool_exp: AnalyticsSettingBoolExp;
  analyticsSetting_constraint: AnalyticsSettingConstraint;
  analyticsSetting_insert_input: AnalyticsSettingInsertInput;
  analyticsSetting_mutation_response: ResolverTypeWrapper<AnalyticsSettingMutationResponse>;
  analyticsSetting_on_conflict: AnalyticsSettingOnConflict;
  analyticsSetting_order_by: AnalyticsSettingOrderBy;
  analyticsSetting_pk_columns_input: AnalyticsSettingPkColumnsInput;
  analyticsSetting_select_column: AnalyticsSettingSelectColumn;
  analyticsSetting_set_input: AnalyticsSettingSetInput;
  analyticsSetting_stream_cursor_input: AnalyticsSettingStreamCursorInput;
  analyticsSetting_stream_cursor_value_input: AnalyticsSettingStreamCursorValueInput;
  analyticsSetting_update_column: AnalyticsSettingUpdateColumn;
  analyticsSetting_updates: AnalyticsSettingUpdates;
  bigint: ResolverTypeWrapper<Scalars['bigint']>;
  bigint_comparison_exp: BigintComparisonExp;
  birthdayTypeQuestionAnswer: ResolverTypeWrapper<BirthdayTypeQuestionAnswer>;
  birthdayTypeQuestionAnswer_bool_exp: BirthdayTypeQuestionAnswerBoolExp;
  birthdayTypeQuestionAnswer_order_by: BirthdayTypeQuestionAnswerOrderBy;
  birthdayTypeQuestionAnswer_select_column: BirthdayTypeQuestionAnswerSelectColumn;
  birthdayTypeQuestionAnswer_stream_cursor_input: BirthdayTypeQuestionAnswerStreamCursorInput;
  birthdayTypeQuestionAnswer_stream_cursor_value_input: BirthdayTypeQuestionAnswerStreamCursorValueInput;
  businessOperationHourTypeEnum: BusinessOperationHourTypeEnumType;
  businessOperationHourType_enum: BusinessOperationHourTypeEnum;
  businessOperationHourType_enum_comparison_exp: BusinessOperationHourTypeEnumComparisonExp;
  cashRegisterConfig: ResolverTypeWrapper<CashRegisterConfig>;
  cashRegisterConfig_aggregate: ResolverTypeWrapper<CashRegisterConfigAggregate>;
  cashRegisterConfig_aggregate_fields: ResolverTypeWrapper<CashRegisterConfigAggregateFields>;
  cashRegisterConfig_bool_exp: CashRegisterConfigBoolExp;
  cashRegisterConfig_constraint: CashRegisterConfigConstraint;
  cashRegisterConfig_insert_input: CashRegisterConfigInsertInput;
  cashRegisterConfig_max_fields: ResolverTypeWrapper<CashRegisterConfigMaxFields>;
  cashRegisterConfig_min_fields: ResolverTypeWrapper<CashRegisterConfigMinFields>;
  cashRegisterConfig_mutation_response: ResolverTypeWrapper<CashRegisterConfigMutationResponse>;
  cashRegisterConfig_obj_rel_insert_input: CashRegisterConfigObjRelInsertInput;
  cashRegisterConfig_on_conflict: CashRegisterConfigOnConflict;
  cashRegisterConfig_order_by: CashRegisterConfigOrderBy;
  cashRegisterConfig_pk_columns_input: CashRegisterConfigPkColumnsInput;
  cashRegisterConfig_select_column: CashRegisterConfigSelectColumn;
  cashRegisterConfig_set_input: CashRegisterConfigSetInput;
  cashRegisterConfig_stream_cursor_input: CashRegisterConfigStreamCursorInput;
  cashRegisterConfig_stream_cursor_value_input: CashRegisterConfigStreamCursorValueInput;
  cashRegisterConfig_update_column: CashRegisterConfigUpdateColumn;
  cashRegisterConfig_updates: CashRegisterConfigUpdates;
  cashRegisterSecurityLevel: ResolverTypeWrapper<CashRegisterSecurityLevel>;
  cashRegisterSecurityLevel_bool_exp: CashRegisterSecurityLevelBoolExp;
  cashRegisterSecurityLevel_enum: CashRegisterSecurityLevelEnum;
  cashRegisterSecurityLevel_enum_comparison_exp: CashRegisterSecurityLevelEnumComparisonExp;
  cashRegisterSecurityLevel_order_by: CashRegisterSecurityLevelOrderBy;
  cashRegisterSecurityLevel_select_column: CashRegisterSecurityLevelSelectColumn;
  cashRegisterSecurityLevel_stream_cursor_input: CashRegisterSecurityLevelStreamCursorInput;
  cashRegisterSecurityLevel_stream_cursor_value_input: CashRegisterSecurityLevelStreamCursorValueInput;
  category: ResolverTypeWrapper<Category>;
  categoryMenu: ResolverTypeWrapper<CategoryMenu>;
  categoryMenu_aggregate: ResolverTypeWrapper<CategoryMenuAggregate>;
  categoryMenu_aggregate_bool_exp: CategoryMenuAggregateBoolExp;
  categoryMenu_aggregate_bool_exp_count: CategoryMenuAggregateBoolExpCount;
  categoryMenu_aggregate_fields: ResolverTypeWrapper<CategoryMenuAggregateFields>;
  categoryMenu_aggregate_order_by: CategoryMenuAggregateOrderBy;
  categoryMenu_arr_rel_insert_input: CategoryMenuArrRelInsertInput;
  categoryMenu_avg_fields: ResolverTypeWrapper<CategoryMenuAvgFields>;
  categoryMenu_avg_order_by: CategoryMenuAvgOrderBy;
  categoryMenu_bool_exp: CategoryMenuBoolExp;
  categoryMenu_constraint: CategoryMenuConstraint;
  categoryMenu_inc_input: CategoryMenuIncInput;
  categoryMenu_insert_input: CategoryMenuInsertInput;
  categoryMenu_max_fields: ResolverTypeWrapper<CategoryMenuMaxFields>;
  categoryMenu_max_order_by: CategoryMenuMaxOrderBy;
  categoryMenu_min_fields: ResolverTypeWrapper<CategoryMenuMinFields>;
  categoryMenu_min_order_by: CategoryMenuMinOrderBy;
  categoryMenu_mutation_response: ResolverTypeWrapper<CategoryMenuMutationResponse>;
  categoryMenu_obj_rel_insert_input: CategoryMenuObjRelInsertInput;
  categoryMenu_on_conflict: CategoryMenuOnConflict;
  categoryMenu_order_by: CategoryMenuOrderBy;
  categoryMenu_pk_columns_input: CategoryMenuPkColumnsInput;
  categoryMenu_select_column: CategoryMenuSelectColumn;
  categoryMenu_set_input: CategoryMenuSetInput;
  categoryMenu_stddev_fields: ResolverTypeWrapper<CategoryMenuStddevFields>;
  categoryMenu_stddev_order_by: CategoryMenuStddevOrderBy;
  categoryMenu_stddev_pop_fields: ResolverTypeWrapper<CategoryMenuStddevPopFields>;
  categoryMenu_stddev_pop_order_by: CategoryMenuStddevPopOrderBy;
  categoryMenu_stddev_samp_fields: ResolverTypeWrapper<CategoryMenuStddevSampFields>;
  categoryMenu_stddev_samp_order_by: CategoryMenuStddevSampOrderBy;
  categoryMenu_stream_cursor_input: CategoryMenuStreamCursorInput;
  categoryMenu_stream_cursor_value_input: CategoryMenuStreamCursorValueInput;
  categoryMenu_sum_fields: ResolverTypeWrapper<CategoryMenuSumFields>;
  categoryMenu_sum_order_by: CategoryMenuSumOrderBy;
  categoryMenu_update_column: CategoryMenuUpdateColumn;
  categoryMenu_updates: CategoryMenuUpdates;
  categoryMenu_var_pop_fields: ResolverTypeWrapper<CategoryMenuVarPopFields>;
  categoryMenu_var_pop_order_by: CategoryMenuVarPopOrderBy;
  categoryMenu_var_samp_fields: ResolverTypeWrapper<CategoryMenuVarSampFields>;
  categoryMenu_var_samp_order_by: CategoryMenuVarSampOrderBy;
  categoryMenu_variance_fields: ResolverTypeWrapper<CategoryMenuVarianceFields>;
  categoryMenu_variance_order_by: CategoryMenuVarianceOrderBy;
  category_aggregate: ResolverTypeWrapper<CategoryAggregate>;
  category_aggregate_bool_exp: CategoryAggregateBoolExp;
  category_aggregate_bool_exp_count: CategoryAggregateBoolExpCount;
  category_aggregate_fields: ResolverTypeWrapper<CategoryAggregateFields>;
  category_aggregate_order_by: CategoryAggregateOrderBy;
  category_arr_rel_insert_input: CategoryArrRelInsertInput;
  category_avg_fields: ResolverTypeWrapper<CategoryAvgFields>;
  category_avg_order_by: CategoryAvgOrderBy;
  category_bool_exp: CategoryBoolExp;
  category_constraint: CategoryConstraint;
  category_inc_input: CategoryIncInput;
  category_insert_input: CategoryInsertInput;
  category_max_fields: ResolverTypeWrapper<CategoryMaxFields>;
  category_max_order_by: CategoryMaxOrderBy;
  category_min_fields: ResolverTypeWrapper<CategoryMinFields>;
  category_min_order_by: CategoryMinOrderBy;
  category_mutation_response: ResolverTypeWrapper<CategoryMutationResponse>;
  category_obj_rel_insert_input: CategoryObjRelInsertInput;
  category_on_conflict: CategoryOnConflict;
  category_order_by: CategoryOrderBy;
  category_pk_columns_input: CategoryPkColumnsInput;
  category_select_column: CategorySelectColumn;
  category_set_input: CategorySetInput;
  category_stddev_fields: ResolverTypeWrapper<CategoryStddevFields>;
  category_stddev_order_by: CategoryStddevOrderBy;
  category_stddev_pop_fields: ResolverTypeWrapper<CategoryStddevPopFields>;
  category_stddev_pop_order_by: CategoryStddevPopOrderBy;
  category_stddev_samp_fields: ResolverTypeWrapper<CategoryStddevSampFields>;
  category_stddev_samp_order_by: CategoryStddevSampOrderBy;
  category_stream_cursor_input: CategoryStreamCursorInput;
  category_stream_cursor_value_input: CategoryStreamCursorValueInput;
  category_sum_fields: ResolverTypeWrapper<CategorySumFields>;
  category_sum_order_by: CategorySumOrderBy;
  category_update_column: CategoryUpdateColumn;
  category_updates: CategoryUpdates;
  category_var_pop_fields: ResolverTypeWrapper<CategoryVarPopFields>;
  category_var_pop_order_by: CategoryVarPopOrderBy;
  category_var_samp_fields: ResolverTypeWrapper<CategoryVarSampFields>;
  category_var_samp_order_by: CategoryVarSampOrderBy;
  category_variance_fields: ResolverTypeWrapper<CategoryVarianceFields>;
  category_variance_order_by: CategoryVarianceOrderBy;
  checkInEvent: ResolverTypeWrapper<CheckInEvent>;
  checkInEvent_aggregate_order_by: CheckInEventAggregateOrderBy;
  checkInEvent_bool_exp: CheckInEventBoolExp;
  checkInEvent_max_order_by: CheckInEventMaxOrderBy;
  checkInEvent_min_order_by: CheckInEventMinOrderBy;
  checkInEvent_order_by: CheckInEventOrderBy;
  checkInEvent_select_column: CheckInEventSelectColumn;
  checkInEvent_stream_cursor_input: CheckInEventStreamCursorInput;
  checkInEvent_stream_cursor_value_input: CheckInEventStreamCursorValueInput;
  choice: ResolverTypeWrapper<Choice>;
  choice_aggregate: ResolverTypeWrapper<ChoiceAggregate>;
  choice_aggregate_bool_exp: ChoiceAggregateBoolExp;
  choice_aggregate_bool_exp_bool_and: ChoiceAggregateBoolExpBoolAnd;
  choice_aggregate_bool_exp_bool_or: ChoiceAggregateBoolExpBoolOr;
  choice_aggregate_bool_exp_count: ChoiceAggregateBoolExpCount;
  choice_aggregate_fields: ResolverTypeWrapper<ChoiceAggregateFields>;
  choice_aggregate_order_by: ChoiceAggregateOrderBy;
  choice_arr_rel_insert_input: ChoiceArrRelInsertInput;
  choice_avg_fields: ResolverTypeWrapper<ChoiceAvgFields>;
  choice_avg_order_by: ChoiceAvgOrderBy;
  choice_bool_exp: ChoiceBoolExp;
  choice_constraint: ChoiceConstraint;
  choice_inc_input: ChoiceIncInput;
  choice_insert_input: ChoiceInsertInput;
  choice_max_fields: ResolverTypeWrapper<ChoiceMaxFields>;
  choice_max_order_by: ChoiceMaxOrderBy;
  choice_min_fields: ResolverTypeWrapper<ChoiceMinFields>;
  choice_min_order_by: ChoiceMinOrderBy;
  choice_mutation_response: ResolverTypeWrapper<ChoiceMutationResponse>;
  choice_obj_rel_insert_input: ChoiceObjRelInsertInput;
  choice_on_conflict: ChoiceOnConflict;
  choice_order_by: ChoiceOrderBy;
  choice_pk_columns_input: ChoicePkColumnsInput;
  choice_select_column: ChoiceSelectColumn;
  choice_select_column_choice_aggregate_bool_exp_bool_and_arguments_columns: ChoiceSelectColumnChoiceAggregateBoolExpBoolAndArgumentsColumnsType;
  choice_select_column_choice_aggregate_bool_exp_bool_or_arguments_columns: ChoiceSelectColumnChoiceAggregateBoolExpBoolOrArgumentsColumnsType;
  choice_set_input: ChoiceSetInput;
  choice_stddev_fields: ResolverTypeWrapper<ChoiceStddevFields>;
  choice_stddev_order_by: ChoiceStddevOrderBy;
  choice_stddev_pop_fields: ResolverTypeWrapper<ChoiceStddevPopFields>;
  choice_stddev_pop_order_by: ChoiceStddevPopOrderBy;
  choice_stddev_samp_fields: ResolverTypeWrapper<ChoiceStddevSampFields>;
  choice_stddev_samp_order_by: ChoiceStddevSampOrderBy;
  choice_stream_cursor_input: ChoiceStreamCursorInput;
  choice_stream_cursor_value_input: ChoiceStreamCursorValueInput;
  choice_sum_fields: ResolverTypeWrapper<ChoiceSumFields>;
  choice_sum_order_by: ChoiceSumOrderBy;
  choice_update_column: ChoiceUpdateColumn;
  choice_updates: ChoiceUpdates;
  choice_var_pop_fields: ResolverTypeWrapper<ChoiceVarPopFields>;
  choice_var_pop_order_by: ChoiceVarPopOrderBy;
  choice_var_samp_fields: ResolverTypeWrapper<ChoiceVarSampFields>;
  choice_var_samp_order_by: ChoiceVarSampOrderBy;
  choice_variance_fields: ResolverTypeWrapper<ChoiceVarianceFields>;
  choice_variance_order_by: ChoiceVarianceOrderBy;
  clerk: ResolverTypeWrapper<Clerk>;
  clerk_aggregate_order_by: ClerkAggregateOrderBy;
  clerk_arr_rel_insert_input: ClerkArrRelInsertInput;
  clerk_avg_order_by: ClerkAvgOrderBy;
  clerk_bool_exp: ClerkBoolExp;
  clerk_constraint: ClerkConstraint;
  clerk_inc_input: ClerkIncInput;
  clerk_insert_input: ClerkInsertInput;
  clerk_max_order_by: ClerkMaxOrderBy;
  clerk_min_order_by: ClerkMinOrderBy;
  clerk_mutation_response: ResolverTypeWrapper<ClerkMutationResponse>;
  clerk_obj_rel_insert_input: ClerkObjRelInsertInput;
  clerk_on_conflict: ClerkOnConflict;
  clerk_order_by: ClerkOrderBy;
  clerk_pk_columns_input: ClerkPkColumnsInput;
  clerk_select_column: ClerkSelectColumn;
  clerk_set_input: ClerkSetInput;
  clerk_stddev_order_by: ClerkStddevOrderBy;
  clerk_stddev_pop_order_by: ClerkStddevPopOrderBy;
  clerk_stddev_samp_order_by: ClerkStddevSampOrderBy;
  clerk_stream_cursor_input: ClerkStreamCursorInput;
  clerk_stream_cursor_value_input: ClerkStreamCursorValueInput;
  clerk_sum_order_by: ClerkSumOrderBy;
  clerk_update_column: ClerkUpdateColumn;
  clerk_updates: ClerkUpdates;
  clerk_var_pop_order_by: ClerkVarPopOrderBy;
  clerk_var_samp_order_by: ClerkVarSampOrderBy;
  clerk_variance_order_by: ClerkVarianceOrderBy;
  closeCashRegister: ResolverTypeWrapper<CloseCashRegister>;
  closeCashRegister_aggregate_order_by: CloseCashRegisterAggregateOrderBy;
  closeCashRegister_avg_order_by: CloseCashRegisterAvgOrderBy;
  closeCashRegister_bool_exp: CloseCashRegisterBoolExp;
  closeCashRegister_max_order_by: CloseCashRegisterMaxOrderBy;
  closeCashRegister_min_order_by: CloseCashRegisterMinOrderBy;
  closeCashRegister_mutation_response: ResolverTypeWrapper<CloseCashRegisterMutationResponse>;
  closeCashRegister_order_by: CloseCashRegisterOrderBy;
  closeCashRegister_pk_columns_input: CloseCashRegisterPkColumnsInput;
  closeCashRegister_select_column: CloseCashRegisterSelectColumn;
  closeCashRegister_set_input: CloseCashRegisterSetInput;
  closeCashRegister_stddev_order_by: CloseCashRegisterStddevOrderBy;
  closeCashRegister_stddev_pop_order_by: CloseCashRegisterStddevPopOrderBy;
  closeCashRegister_stddev_samp_order_by: CloseCashRegisterStddevSampOrderBy;
  closeCashRegister_stream_cursor_input: CloseCashRegisterStreamCursorInput;
  closeCashRegister_stream_cursor_value_input: CloseCashRegisterStreamCursorValueInput;
  closeCashRegister_sum_order_by: CloseCashRegisterSumOrderBy;
  closeCashRegister_updates: CloseCashRegisterUpdates;
  closeCashRegister_var_pop_order_by: CloseCashRegisterVarPopOrderBy;
  closeCashRegister_var_samp_order_by: CloseCashRegisterVarSampOrderBy;
  closeCashRegister_variance_order_by: CloseCashRegisterVarianceOrderBy;
  coineyPayment: ResolverTypeWrapper<CoineyPayment>;
  coineyPaymentOrder: ResolverTypeWrapper<CoineyPaymentOrder>;
  coineyPaymentOrder_aggregate_order_by: CoineyPaymentOrderAggregateOrderBy;
  coineyPaymentOrder_avg_order_by: CoineyPaymentOrderAvgOrderBy;
  coineyPaymentOrder_bool_exp: CoineyPaymentOrderBoolExp;
  coineyPaymentOrder_max_order_by: CoineyPaymentOrderMaxOrderBy;
  coineyPaymentOrder_min_order_by: CoineyPaymentOrderMinOrderBy;
  coineyPaymentOrder_order_by: CoineyPaymentOrderOrderBy;
  coineyPaymentOrder_select_column: CoineyPaymentOrderSelectColumn;
  coineyPaymentOrder_stddev_order_by: CoineyPaymentOrderStddevOrderBy;
  coineyPaymentOrder_stddev_pop_order_by: CoineyPaymentOrderStddevPopOrderBy;
  coineyPaymentOrder_stddev_samp_order_by: CoineyPaymentOrderStddevSampOrderBy;
  coineyPaymentOrder_stream_cursor_input: CoineyPaymentOrderStreamCursorInput;
  coineyPaymentOrder_stream_cursor_value_input: CoineyPaymentOrderStreamCursorValueInput;
  coineyPaymentOrder_sum_order_by: CoineyPaymentOrderSumOrderBy;
  coineyPaymentOrder_var_pop_order_by: CoineyPaymentOrderVarPopOrderBy;
  coineyPaymentOrder_var_samp_order_by: CoineyPaymentOrderVarSampOrderBy;
  coineyPaymentOrder_variance_order_by: CoineyPaymentOrderVarianceOrderBy;
  coineyPaymentSales: ResolverTypeWrapper<CoineyPaymentSales>;
  coineyPaymentSales_aggregate_order_by: CoineyPaymentSalesAggregateOrderBy;
  coineyPaymentSales_avg_order_by: CoineyPaymentSalesAvgOrderBy;
  coineyPaymentSales_bool_exp: CoineyPaymentSalesBoolExp;
  coineyPaymentSales_max_order_by: CoineyPaymentSalesMaxOrderBy;
  coineyPaymentSales_min_order_by: CoineyPaymentSalesMinOrderBy;
  coineyPaymentSales_order_by: CoineyPaymentSalesOrderBy;
  coineyPaymentSales_select_column: CoineyPaymentSalesSelectColumn;
  coineyPaymentSales_stddev_order_by: CoineyPaymentSalesStddevOrderBy;
  coineyPaymentSales_stddev_pop_order_by: CoineyPaymentSalesStddevPopOrderBy;
  coineyPaymentSales_stddev_samp_order_by: CoineyPaymentSalesStddevSampOrderBy;
  coineyPaymentSales_stream_cursor_input: CoineyPaymentSalesStreamCursorInput;
  coineyPaymentSales_stream_cursor_value_input: CoineyPaymentSalesStreamCursorValueInput;
  coineyPaymentSales_sum_order_by: CoineyPaymentSalesSumOrderBy;
  coineyPaymentSales_var_pop_order_by: CoineyPaymentSalesVarPopOrderBy;
  coineyPaymentSales_var_samp_order_by: CoineyPaymentSalesVarSampOrderBy;
  coineyPaymentSales_variance_order_by: CoineyPaymentSalesVarianceOrderBy;
  coineyPayment_aggregate_order_by: CoineyPaymentAggregateOrderBy;
  coineyPayment_avg_order_by: CoineyPaymentAvgOrderBy;
  coineyPayment_bool_exp: CoineyPaymentBoolExp;
  coineyPayment_max_order_by: CoineyPaymentMaxOrderBy;
  coineyPayment_min_order_by: CoineyPaymentMinOrderBy;
  coineyPayment_mutation_response: ResolverTypeWrapper<CoineyPaymentMutationResponse>;
  coineyPayment_order_by: CoineyPaymentOrderBy;
  coineyPayment_pk_columns_input: CoineyPaymentPkColumnsInput;
  coineyPayment_select_column: CoineyPaymentSelectColumn;
  coineyPayment_set_input: CoineyPaymentSetInput;
  coineyPayment_stddev_order_by: CoineyPaymentStddevOrderBy;
  coineyPayment_stddev_pop_order_by: CoineyPaymentStddevPopOrderBy;
  coineyPayment_stddev_samp_order_by: CoineyPaymentStddevSampOrderBy;
  coineyPayment_stream_cursor_input: CoineyPaymentStreamCursorInput;
  coineyPayment_stream_cursor_value_input: CoineyPaymentStreamCursorValueInput;
  coineyPayment_sum_order_by: CoineyPaymentSumOrderBy;
  coineyPayment_updates: CoineyPaymentUpdates;
  coineyPayment_var_pop_order_by: CoineyPaymentVarPopOrderBy;
  coineyPayment_var_samp_order_by: CoineyPaymentVarSampOrderBy;
  coineyPayment_variance_order_by: CoineyPaymentVarianceOrderBy;
  coineySettlement: ResolverTypeWrapper<CoineySettlement>;
  coineySettlement_aggregate_order_by: CoineySettlementAggregateOrderBy;
  coineySettlement_avg_order_by: CoineySettlementAvgOrderBy;
  coineySettlement_bool_exp: CoineySettlementBoolExp;
  coineySettlement_max_order_by: CoineySettlementMaxOrderBy;
  coineySettlement_min_order_by: CoineySettlementMinOrderBy;
  coineySettlement_order_by: CoineySettlementOrderBy;
  coineySettlement_select_column: CoineySettlementSelectColumn;
  coineySettlement_stddev_order_by: CoineySettlementStddevOrderBy;
  coineySettlement_stddev_pop_order_by: CoineySettlementStddevPopOrderBy;
  coineySettlement_stddev_samp_order_by: CoineySettlementStddevSampOrderBy;
  coineySettlement_stream_cursor_input: CoineySettlementStreamCursorInput;
  coineySettlement_stream_cursor_value_input: CoineySettlementStreamCursorValueInput;
  coineySettlement_sum_order_by: CoineySettlementSumOrderBy;
  coineySettlement_var_pop_order_by: CoineySettlementVarPopOrderBy;
  coineySettlement_var_samp_order_by: CoineySettlementVarSampOrderBy;
  coineySettlement_variance_order_by: CoineySettlementVarianceOrderBy;
  company: ResolverTypeWrapper<Company>;
  companyMember: ResolverTypeWrapper<CompanyMember>;
  companyMember_aggregate: ResolverTypeWrapper<CompanyMemberAggregate>;
  companyMember_aggregate_bool_exp: CompanyMemberAggregateBoolExp;
  companyMember_aggregate_bool_exp_count: CompanyMemberAggregateBoolExpCount;
  companyMember_aggregate_fields: ResolverTypeWrapper<CompanyMemberAggregateFields>;
  companyMember_aggregate_order_by: CompanyMemberAggregateOrderBy;
  companyMember_bool_exp: CompanyMemberBoolExp;
  companyMember_max_fields: ResolverTypeWrapper<CompanyMemberMaxFields>;
  companyMember_max_order_by: CompanyMemberMaxOrderBy;
  companyMember_min_fields: ResolverTypeWrapper<CompanyMemberMinFields>;
  companyMember_min_order_by: CompanyMemberMinOrderBy;
  companyMember_order_by: CompanyMemberOrderBy;
  companyMember_select_column: CompanyMemberSelectColumn;
  companyMember_stream_cursor_input: CompanyMemberStreamCursorInput;
  companyMember_stream_cursor_value_input: CompanyMemberStreamCursorValueInput;
  company_aggregate_order_by: CompanyAggregateOrderBy;
  company_arr_rel_insert_input: CompanyArrRelInsertInput;
  company_avg_order_by: CompanyAvgOrderBy;
  company_bool_exp: CompanyBoolExp;
  company_constraint: CompanyConstraint;
  company_inc_input: CompanyIncInput;
  company_insert_input: CompanyInsertInput;
  company_max_order_by: CompanyMaxOrderBy;
  company_min_order_by: CompanyMinOrderBy;
  company_mutation_response: ResolverTypeWrapper<CompanyMutationResponse>;
  company_obj_rel_insert_input: CompanyObjRelInsertInput;
  company_on_conflict: CompanyOnConflict;
  company_order_by: CompanyOrderBy;
  company_pk_columns_input: CompanyPkColumnsInput;
  company_select_column: CompanySelectColumn;
  company_set_input: CompanySetInput;
  company_stddev_order_by: CompanyStddevOrderBy;
  company_stddev_pop_order_by: CompanyStddevPopOrderBy;
  company_stddev_samp_order_by: CompanyStddevSampOrderBy;
  company_stream_cursor_input: CompanyStreamCursorInput;
  company_stream_cursor_value_input: CompanyStreamCursorValueInput;
  company_sum_order_by: CompanySumOrderBy;
  company_update_column: CompanyUpdateColumn;
  company_updates: CompanyUpdates;
  company_var_pop_order_by: CompanyVarPopOrderBy;
  company_var_samp_order_by: CompanyVarSampOrderBy;
  company_variance_order_by: CompanyVarianceOrderBy;
  compulsoryAppetizerConfig: ResolverTypeWrapper<CompulsoryAppetizerConfig>;
  compulsoryAppetizerConfig_aggregate_order_by: CompulsoryAppetizerConfigAggregateOrderBy;
  compulsoryAppetizerConfig_arr_rel_insert_input: CompulsoryAppetizerConfigArrRelInsertInput;
  compulsoryAppetizerConfig_bool_exp: CompulsoryAppetizerConfigBoolExp;
  compulsoryAppetizerConfig_constraint: CompulsoryAppetizerConfigConstraint;
  compulsoryAppetizerConfig_insert_input: CompulsoryAppetizerConfigInsertInput;
  compulsoryAppetizerConfig_max_order_by: CompulsoryAppetizerConfigMaxOrderBy;
  compulsoryAppetizerConfig_min_order_by: CompulsoryAppetizerConfigMinOrderBy;
  compulsoryAppetizerConfig_mutation_response: ResolverTypeWrapper<CompulsoryAppetizerConfigMutationResponse>;
  compulsoryAppetizerConfig_on_conflict: CompulsoryAppetizerConfigOnConflict;
  compulsoryAppetizerConfig_order_by: CompulsoryAppetizerConfigOrderBy;
  compulsoryAppetizerConfig_pk_columns_input: CompulsoryAppetizerConfigPkColumnsInput;
  compulsoryAppetizerConfig_select_column: CompulsoryAppetizerConfigSelectColumn;
  compulsoryAppetizerConfig_set_input: CompulsoryAppetizerConfigSetInput;
  compulsoryAppetizerConfig_stream_cursor_input: CompulsoryAppetizerConfigStreamCursorInput;
  compulsoryAppetizerConfig_stream_cursor_value_input: CompulsoryAppetizerConfigStreamCursorValueInput;
  compulsoryAppetizerConfig_update_column: CompulsoryAppetizerConfigUpdateColumn;
  compulsoryAppetizerConfig_updates: CompulsoryAppetizerConfigUpdates;
  connectGameConfig: ResolverTypeWrapper<ConnectGameConfig>;
  connectGameConfigShop: ResolverTypeWrapper<ConnectGameConfigShop>;
  connectGameConfigShop_aggregate_order_by: ConnectGameConfigShopAggregateOrderBy;
  connectGameConfigShop_arr_rel_insert_input: ConnectGameConfigShopArrRelInsertInput;
  connectGameConfigShop_bool_exp: ConnectGameConfigShopBoolExp;
  connectGameConfigShop_constraint: ConnectGameConfigShopConstraint;
  connectGameConfigShop_insert_input: ConnectGameConfigShopInsertInput;
  connectGameConfigShop_max_order_by: ConnectGameConfigShopMaxOrderBy;
  connectGameConfigShop_min_order_by: ConnectGameConfigShopMinOrderBy;
  connectGameConfigShop_mutation_response: ResolverTypeWrapper<ConnectGameConfigShopMutationResponse>;
  connectGameConfigShop_on_conflict: ConnectGameConfigShopOnConflict;
  connectGameConfigShop_order_by: ConnectGameConfigShopOrderBy;
  connectGameConfigShop_pk_columns_input: ConnectGameConfigShopPkColumnsInput;
  connectGameConfigShop_select_column: ConnectGameConfigShopSelectColumn;
  connectGameConfigShop_set_input: ConnectGameConfigShopSetInput;
  connectGameConfigShop_stream_cursor_input: ConnectGameConfigShopStreamCursorInput;
  connectGameConfigShop_stream_cursor_value_input: ConnectGameConfigShopStreamCursorValueInput;
  connectGameConfigShop_update_column: ConnectGameConfigShopUpdateColumn;
  connectGameConfigShop_updates: ConnectGameConfigShopUpdates;
  connectGameConfig_aggregate_order_by: ConnectGameConfigAggregateOrderBy;
  connectGameConfig_arr_rel_insert_input: ConnectGameConfigArrRelInsertInput;
  connectGameConfig_avg_order_by: ConnectGameConfigAvgOrderBy;
  connectGameConfig_bool_exp: ConnectGameConfigBoolExp;
  connectGameConfig_constraint: ConnectGameConfigConstraint;
  connectGameConfig_inc_input: ConnectGameConfigIncInput;
  connectGameConfig_insert_input: ConnectGameConfigInsertInput;
  connectGameConfig_max_order_by: ConnectGameConfigMaxOrderBy;
  connectGameConfig_min_order_by: ConnectGameConfigMinOrderBy;
  connectGameConfig_mutation_response: ResolverTypeWrapper<ConnectGameConfigMutationResponse>;
  connectGameConfig_obj_rel_insert_input: ConnectGameConfigObjRelInsertInput;
  connectGameConfig_on_conflict: ConnectGameConfigOnConflict;
  connectGameConfig_order_by: ConnectGameConfigOrderBy;
  connectGameConfig_pk_columns_input: ConnectGameConfigPkColumnsInput;
  connectGameConfig_select_column: ConnectGameConfigSelectColumn;
  connectGameConfig_set_input: ConnectGameConfigSetInput;
  connectGameConfig_stddev_order_by: ConnectGameConfigStddevOrderBy;
  connectGameConfig_stddev_pop_order_by: ConnectGameConfigStddevPopOrderBy;
  connectGameConfig_stddev_samp_order_by: ConnectGameConfigStddevSampOrderBy;
  connectGameConfig_stream_cursor_input: ConnectGameConfigStreamCursorInput;
  connectGameConfig_stream_cursor_value_input: ConnectGameConfigStreamCursorValueInput;
  connectGameConfig_sum_order_by: ConnectGameConfigSumOrderBy;
  connectGameConfig_update_column: ConnectGameConfigUpdateColumn;
  connectGameConfig_updates: ConnectGameConfigUpdates;
  connectGameConfig_var_pop_order_by: ConnectGameConfigVarPopOrderBy;
  connectGameConfig_var_samp_order_by: ConnectGameConfigVarSampOrderBy;
  connectGameConfig_variance_order_by: ConnectGameConfigVarianceOrderBy;
  cookingItem: ResolverTypeWrapper<CookingItem>;
  cookingItem_aggregate_order_by: CookingItemAggregateOrderBy;
  cookingItem_arr_rel_insert_input: CookingItemArrRelInsertInput;
  cookingItem_avg_order_by: CookingItemAvgOrderBy;
  cookingItem_bool_exp: CookingItemBoolExp;
  cookingItem_constraint: CookingItemConstraint;
  cookingItem_inc_input: CookingItemIncInput;
  cookingItem_insert_input: CookingItemInsertInput;
  cookingItem_max_order_by: CookingItemMaxOrderBy;
  cookingItem_min_order_by: CookingItemMinOrderBy;
  cookingItem_mutation_response: ResolverTypeWrapper<CookingItemMutationResponse>;
  cookingItem_obj_rel_insert_input: CookingItemObjRelInsertInput;
  cookingItem_on_conflict: CookingItemOnConflict;
  cookingItem_order_by: CookingItemOrderBy;
  cookingItem_pk_columns_input: CookingItemPkColumnsInput;
  cookingItem_select_column: CookingItemSelectColumn;
  cookingItem_set_input: CookingItemSetInput;
  cookingItem_stddev_order_by: CookingItemStddevOrderBy;
  cookingItem_stddev_pop_order_by: CookingItemStddevPopOrderBy;
  cookingItem_stddev_samp_order_by: CookingItemStddevSampOrderBy;
  cookingItem_stream_cursor_input: CookingItemStreamCursorInput;
  cookingItem_stream_cursor_value_input: CookingItemStreamCursorValueInput;
  cookingItem_sum_order_by: CookingItemSumOrderBy;
  cookingItem_update_column: CookingItemUpdateColumn;
  cookingItem_updates: CookingItemUpdates;
  cookingItem_var_pop_order_by: CookingItemVarPopOrderBy;
  cookingItem_var_samp_order_by: CookingItemVarSampOrderBy;
  cookingItem_variance_order_by: CookingItemVarianceOrderBy;
  corporation: ResolverTypeWrapper<Corporation>;
  corporation_bool_exp: CorporationBoolExp;
  corporation_constraint: CorporationConstraint;
  corporation_insert_input: CorporationInsertInput;
  corporation_mutation_response: ResolverTypeWrapper<CorporationMutationResponse>;
  corporation_obj_rel_insert_input: CorporationObjRelInsertInput;
  corporation_on_conflict: CorporationOnConflict;
  corporation_order_by: CorporationOrderBy;
  corporation_pk_columns_input: CorporationPkColumnsInput;
  corporation_select_column: CorporationSelectColumn;
  corporation_set_input: CorporationSetInput;
  corporation_stream_cursor_input: CorporationStreamCursorInput;
  corporation_stream_cursor_value_input: CorporationStreamCursorValueInput;
  corporation_update_column: CorporationUpdateColumn;
  corporation_updates: CorporationUpdates;
  coupon: ResolverTypeWrapper<Coupon>;
  couponAppliedEvent: ResolverTypeWrapper<CouponAppliedEvent>;
  couponAppliedEvent_aggregate: ResolverTypeWrapper<CouponAppliedEventAggregate>;
  couponAppliedEvent_aggregate_bool_exp: CouponAppliedEventAggregateBoolExp;
  couponAppliedEvent_aggregate_bool_exp_count: CouponAppliedEventAggregateBoolExpCount;
  couponAppliedEvent_aggregate_fields: ResolverTypeWrapper<CouponAppliedEventAggregateFields>;
  couponAppliedEvent_aggregate_order_by: CouponAppliedEventAggregateOrderBy;
  couponAppliedEvent_bool_exp: CouponAppliedEventBoolExp;
  couponAppliedEvent_max_fields: ResolverTypeWrapper<CouponAppliedEventMaxFields>;
  couponAppliedEvent_max_order_by: CouponAppliedEventMaxOrderBy;
  couponAppliedEvent_min_fields: ResolverTypeWrapper<CouponAppliedEventMinFields>;
  couponAppliedEvent_min_order_by: CouponAppliedEventMinOrderBy;
  couponAppliedEvent_order_by: CouponAppliedEventOrderBy;
  couponAppliedEvent_select_column: CouponAppliedEventSelectColumn;
  couponAppliedEvent_stream_cursor_input: CouponAppliedEventStreamCursorInput;
  couponAppliedEvent_stream_cursor_value_input: CouponAppliedEventStreamCursorValueInput;
  couponIssuer_enum: CouponIssuerEnum;
  couponIssuer_enum_comparison_exp: CouponIssuerEnumComparisonExp;
  couponMenuDiscount: ResolverTypeWrapper<CouponMenuDiscount>;
  couponMenuDiscount_bool_exp: CouponMenuDiscountBoolExp;
  couponMenuDiscount_constraint: CouponMenuDiscountConstraint;
  couponMenuDiscount_inc_input: CouponMenuDiscountIncInput;
  couponMenuDiscount_insert_input: CouponMenuDiscountInsertInput;
  couponMenuDiscount_mutation_response: ResolverTypeWrapper<CouponMenuDiscountMutationResponse>;
  couponMenuDiscount_obj_rel_insert_input: CouponMenuDiscountObjRelInsertInput;
  couponMenuDiscount_on_conflict: CouponMenuDiscountOnConflict;
  couponMenuDiscount_order_by: CouponMenuDiscountOrderBy;
  couponMenuDiscount_pk_columns_input: CouponMenuDiscountPkColumnsInput;
  couponMenuDiscount_select_column: CouponMenuDiscountSelectColumn;
  couponMenuDiscount_set_input: CouponMenuDiscountSetInput;
  couponMenuDiscount_stream_cursor_input: CouponMenuDiscountStreamCursorInput;
  couponMenuDiscount_stream_cursor_value_input: CouponMenuDiscountStreamCursorValueInput;
  couponMenuDiscount_update_column: CouponMenuDiscountUpdateColumn;
  couponMenuDiscount_updates: CouponMenuDiscountUpdates;
  couponType_enum: CouponTypeEnum;
  couponType_enum_comparison_exp: CouponTypeEnumComparisonExp;
  coupon_aggregate_order_by: CouponAggregateOrderBy;
  coupon_arr_rel_insert_input: CouponArrRelInsertInput;
  coupon_avg_order_by: CouponAvgOrderBy;
  coupon_bool_exp: CouponBoolExp;
  coupon_constraint: CouponConstraint;
  coupon_inc_input: CouponIncInput;
  coupon_insert_input: CouponInsertInput;
  coupon_max_order_by: CouponMaxOrderBy;
  coupon_min_order_by: CouponMinOrderBy;
  coupon_mutation_response: ResolverTypeWrapper<CouponMutationResponse>;
  coupon_obj_rel_insert_input: CouponObjRelInsertInput;
  coupon_on_conflict: CouponOnConflict;
  coupon_order_by: CouponOrderBy;
  coupon_pk_columns_input: CouponPkColumnsInput;
  coupon_select_column: CouponSelectColumn;
  coupon_set_input: CouponSetInput;
  coupon_stddev_order_by: CouponStddevOrderBy;
  coupon_stddev_pop_order_by: CouponStddevPopOrderBy;
  coupon_stddev_samp_order_by: CouponStddevSampOrderBy;
  coupon_stream_cursor_input: CouponStreamCursorInput;
  coupon_stream_cursor_value_input: CouponStreamCursorValueInput;
  coupon_sum_order_by: CouponSumOrderBy;
  coupon_update_column: CouponUpdateColumn;
  coupon_updates: CouponUpdates;
  coupon_var_pop_order_by: CouponVarPopOrderBy;
  coupon_var_samp_order_by: CouponVarSampOrderBy;
  coupon_variance_order_by: CouponVarianceOrderBy;
  cursor_ordering: CursorOrderingType;
  customer: ResolverTypeWrapper<Customer>;
  customerQuestionnaire: ResolverTypeWrapper<CustomerQuestionnaire>;
  customerQuestionnaire_aggregate_order_by: CustomerQuestionnaireAggregateOrderBy;
  customerQuestionnaire_bool_exp: CustomerQuestionnaireBoolExp;
  customerQuestionnaire_max_order_by: CustomerQuestionnaireMaxOrderBy;
  customerQuestionnaire_min_order_by: CustomerQuestionnaireMinOrderBy;
  customerQuestionnaire_order_by: CustomerQuestionnaireOrderBy;
  customerQuestionnaire_select_column: CustomerQuestionnaireSelectColumn;
  customerQuestionnaire_stream_cursor_input: CustomerQuestionnaireStreamCursorInput;
  customerQuestionnaire_stream_cursor_value_input: CustomerQuestionnaireStreamCursorValueInput;
  customer_bool_exp: CustomerBoolExp;
  customer_order_by: CustomerOrderBy;
  customer_select_column: CustomerSelectColumn;
  customer_stream_cursor_input: CustomerStreamCursorInput;
  customer_stream_cursor_value_input: CustomerStreamCursorValueInput;
  dailySalesBudget: ResolverTypeWrapper<DailySalesBudget>;
  dailySalesBudget_aggregate_order_by: DailySalesBudgetAggregateOrderBy;
  dailySalesBudget_arr_rel_insert_input: DailySalesBudgetArrRelInsertInput;
  dailySalesBudget_avg_order_by: DailySalesBudgetAvgOrderBy;
  dailySalesBudget_bool_exp: DailySalesBudgetBoolExp;
  dailySalesBudget_constraint: DailySalesBudgetConstraint;
  dailySalesBudget_inc_input: DailySalesBudgetIncInput;
  dailySalesBudget_insert_input: DailySalesBudgetInsertInput;
  dailySalesBudget_max_order_by: DailySalesBudgetMaxOrderBy;
  dailySalesBudget_min_order_by: DailySalesBudgetMinOrderBy;
  dailySalesBudget_mutation_response: ResolverTypeWrapper<DailySalesBudgetMutationResponse>;
  dailySalesBudget_on_conflict: DailySalesBudgetOnConflict;
  dailySalesBudget_order_by: DailySalesBudgetOrderBy;
  dailySalesBudget_pk_columns_input: DailySalesBudgetPkColumnsInput;
  dailySalesBudget_select_column: DailySalesBudgetSelectColumn;
  dailySalesBudget_set_input: DailySalesBudgetSetInput;
  dailySalesBudget_stddev_order_by: DailySalesBudgetStddevOrderBy;
  dailySalesBudget_stddev_pop_order_by: DailySalesBudgetStddevPopOrderBy;
  dailySalesBudget_stddev_samp_order_by: DailySalesBudgetStddevSampOrderBy;
  dailySalesBudget_stream_cursor_input: DailySalesBudgetStreamCursorInput;
  dailySalesBudget_stream_cursor_value_input: DailySalesBudgetStreamCursorValueInput;
  dailySalesBudget_sum_order_by: DailySalesBudgetSumOrderBy;
  dailySalesBudget_update_column: DailySalesBudgetUpdateColumn;
  dailySalesBudget_updates: DailySalesBudgetUpdates;
  dailySalesBudget_var_pop_order_by: DailySalesBudgetVarPopOrderBy;
  dailySalesBudget_var_samp_order_by: DailySalesBudgetVarSampOrderBy;
  dailySalesBudget_variance_order_by: DailySalesBudgetVarianceOrderBy;
  dashboardAccount: ResolverTypeWrapper<DashboardAccount>;
  dashboardAccountAccessibleCompany: ResolverTypeWrapper<DashboardAccountAccessibleCompany>;
  dashboardAccountAccessibleCompany_aggregate_order_by: DashboardAccountAccessibleCompanyAggregateOrderBy;
  dashboardAccountAccessibleCompany_bool_exp: DashboardAccountAccessibleCompanyBoolExp;
  dashboardAccountAccessibleCompany_max_order_by: DashboardAccountAccessibleCompanyMaxOrderBy;
  dashboardAccountAccessibleCompany_min_order_by: DashboardAccountAccessibleCompanyMinOrderBy;
  dashboardAccountAccessibleCompany_order_by: DashboardAccountAccessibleCompanyOrderBy;
  dashboardAccountAccessibleCompany_select_column: DashboardAccountAccessibleCompanySelectColumn;
  dashboardAccountAccessibleCompany_stream_cursor_input: DashboardAccountAccessibleCompanyStreamCursorInput;
  dashboardAccountAccessibleCompany_stream_cursor_value_input: DashboardAccountAccessibleCompanyStreamCursorValueInput;
  dashboardAccountAccessibleShop: ResolverTypeWrapper<DashboardAccountAccessibleShop>;
  dashboardAccountAccessibleShop_aggregate_order_by: DashboardAccountAccessibleShopAggregateOrderBy;
  dashboardAccountAccessibleShop_bool_exp: DashboardAccountAccessibleShopBoolExp;
  dashboardAccountAccessibleShop_max_order_by: DashboardAccountAccessibleShopMaxOrderBy;
  dashboardAccountAccessibleShop_min_order_by: DashboardAccountAccessibleShopMinOrderBy;
  dashboardAccountAccessibleShop_order_by: DashboardAccountAccessibleShopOrderBy;
  dashboardAccountAccessibleShop_select_column: DashboardAccountAccessibleShopSelectColumn;
  dashboardAccountAccessibleShop_stream_cursor_input: DashboardAccountAccessibleShopStreamCursorInput;
  dashboardAccountAccessibleShop_stream_cursor_value_input: DashboardAccountAccessibleShopStreamCursorValueInput;
  dashboardAccountRole: ResolverTypeWrapper<DashboardAccountRole>;
  dashboardAccountRolePermission: ResolverTypeWrapper<DashboardAccountRolePermission>;
  dashboardAccountRolePermissionType_enum: DashboardAccountRolePermissionTypeEnum;
  dashboardAccountRolePermissionType_enum_comparison_exp: DashboardAccountRolePermissionTypeEnumComparisonExp;
  dashboardAccountRolePermission_aggregate_order_by: DashboardAccountRolePermissionAggregateOrderBy;
  dashboardAccountRolePermission_arr_rel_insert_input: DashboardAccountRolePermissionArrRelInsertInput;
  dashboardAccountRolePermission_bool_exp: DashboardAccountRolePermissionBoolExp;
  dashboardAccountRolePermission_constraint: DashboardAccountRolePermissionConstraint;
  dashboardAccountRolePermission_insert_input: DashboardAccountRolePermissionInsertInput;
  dashboardAccountRolePermission_max_order_by: DashboardAccountRolePermissionMaxOrderBy;
  dashboardAccountRolePermission_min_order_by: DashboardAccountRolePermissionMinOrderBy;
  dashboardAccountRolePermission_mutation_response: ResolverTypeWrapper<DashboardAccountRolePermissionMutationResponse>;
  dashboardAccountRolePermission_on_conflict: DashboardAccountRolePermissionOnConflict;
  dashboardAccountRolePermission_order_by: DashboardAccountRolePermissionOrderBy;
  dashboardAccountRolePermission_pk_columns_input: DashboardAccountRolePermissionPkColumnsInput;
  dashboardAccountRolePermission_select_column: DashboardAccountRolePermissionSelectColumn;
  dashboardAccountRolePermission_set_input: DashboardAccountRolePermissionSetInput;
  dashboardAccountRolePermission_stream_cursor_input: DashboardAccountRolePermissionStreamCursorInput;
  dashboardAccountRolePermission_stream_cursor_value_input: DashboardAccountRolePermissionStreamCursorValueInput;
  dashboardAccountRolePermission_update_column: DashboardAccountRolePermissionUpdateColumn;
  dashboardAccountRolePermission_updates: DashboardAccountRolePermissionUpdates;
  dashboardAccountRoleType: ResolverTypeWrapper<DashboardAccountRoleType>;
  dashboardAccountRoleType_bool_exp: DashboardAccountRoleTypeBoolExp;
  dashboardAccountRoleType_enum: DashboardAccountRoleTypeEnum;
  dashboardAccountRoleType_enum_comparison_exp: DashboardAccountRoleTypeEnumComparisonExp;
  dashboardAccountRoleType_order_by: DashboardAccountRoleTypeOrderBy;
  dashboardAccountRoleType_select_column: DashboardAccountRoleTypeSelectColumn;
  dashboardAccountRoleType_stream_cursor_input: DashboardAccountRoleTypeStreamCursorInput;
  dashboardAccountRoleType_stream_cursor_value_input: DashboardAccountRoleTypeStreamCursorValueInput;
  dashboardAccountRole_bool_exp: DashboardAccountRoleBoolExp;
  dashboardAccountRole_constraint: DashboardAccountRoleConstraint;
  dashboardAccountRole_insert_input: DashboardAccountRoleInsertInput;
  dashboardAccountRole_mutation_response: ResolverTypeWrapper<DashboardAccountRoleMutationResponse>;
  dashboardAccountRole_obj_rel_insert_input: DashboardAccountRoleObjRelInsertInput;
  dashboardAccountRole_on_conflict: DashboardAccountRoleOnConflict;
  dashboardAccountRole_order_by: DashboardAccountRoleOrderBy;
  dashboardAccountRole_pk_columns_input: DashboardAccountRolePkColumnsInput;
  dashboardAccountRole_select_column: DashboardAccountRoleSelectColumn;
  dashboardAccountRole_set_input: DashboardAccountRoleSetInput;
  dashboardAccountRole_stream_cursor_input: DashboardAccountRoleStreamCursorInput;
  dashboardAccountRole_stream_cursor_value_input: DashboardAccountRoleStreamCursorValueInput;
  dashboardAccountRole_update_column: DashboardAccountRoleUpdateColumn;
  dashboardAccountRole_updates: DashboardAccountRoleUpdates;
  dashboardAccount_aggregate_order_by: DashboardAccountAggregateOrderBy;
  dashboardAccount_bool_exp: DashboardAccountBoolExp;
  dashboardAccount_max_order_by: DashboardAccountMaxOrderBy;
  dashboardAccount_min_order_by: DashboardAccountMinOrderBy;
  dashboardAccount_order_by: DashboardAccountOrderBy;
  dashboardAccount_select_column: DashboardAccountSelectColumn;
  dashboardAccount_stream_cursor_input: DashboardAccountStreamCursorInput;
  dashboardAccount_stream_cursor_value_input: DashboardAccountStreamCursorValueInput;
  dashboardFeature_enum: DashboardFeatureEnum;
  dashboardFeature_enum_comparison_exp: DashboardFeatureEnumComparisonExp;
  date: ResolverTypeWrapper<Scalars['date']>;
  date_comparison_exp: DateComparisonExp;
  depositCycleType_enum: DepositCycleTypeEnum;
  depositCycleType_enum_comparison_exp: DepositCycleTypeEnumComparisonExp;
  dishUpSlipGroup: ResolverTypeWrapper<DishUpSlipGroup>;
  dishUpSlipGroupRoles: ResolverTypeWrapper<DishUpSlipGroupRoles>;
  dishUpSlipGroupRoles_aggregate_order_by: DishUpSlipGroupRolesAggregateOrderBy;
  dishUpSlipGroupRoles_arr_rel_insert_input: DishUpSlipGroupRolesArrRelInsertInput;
  dishUpSlipGroupRoles_avg_order_by: DishUpSlipGroupRolesAvgOrderBy;
  dishUpSlipGroupRoles_bool_exp: DishUpSlipGroupRolesBoolExp;
  dishUpSlipGroupRoles_constraint: DishUpSlipGroupRolesConstraint;
  dishUpSlipGroupRoles_inc_input: DishUpSlipGroupRolesIncInput;
  dishUpSlipGroupRoles_insert_input: DishUpSlipGroupRolesInsertInput;
  dishUpSlipGroupRoles_max_order_by: DishUpSlipGroupRolesMaxOrderBy;
  dishUpSlipGroupRoles_min_order_by: DishUpSlipGroupRolesMinOrderBy;
  dishUpSlipGroupRoles_mutation_response: ResolverTypeWrapper<DishUpSlipGroupRolesMutationResponse>;
  dishUpSlipGroupRoles_on_conflict: DishUpSlipGroupRolesOnConflict;
  dishUpSlipGroupRoles_order_by: DishUpSlipGroupRolesOrderBy;
  dishUpSlipGroupRoles_pk_columns_input: DishUpSlipGroupRolesPkColumnsInput;
  dishUpSlipGroupRoles_select_column: DishUpSlipGroupRolesSelectColumn;
  dishUpSlipGroupRoles_set_input: DishUpSlipGroupRolesSetInput;
  dishUpSlipGroupRoles_stddev_order_by: DishUpSlipGroupRolesStddevOrderBy;
  dishUpSlipGroupRoles_stddev_pop_order_by: DishUpSlipGroupRolesStddevPopOrderBy;
  dishUpSlipGroupRoles_stddev_samp_order_by: DishUpSlipGroupRolesStddevSampOrderBy;
  dishUpSlipGroupRoles_stream_cursor_input: DishUpSlipGroupRolesStreamCursorInput;
  dishUpSlipGroupRoles_stream_cursor_value_input: DishUpSlipGroupRolesStreamCursorValueInput;
  dishUpSlipGroupRoles_sum_order_by: DishUpSlipGroupRolesSumOrderBy;
  dishUpSlipGroupRoles_update_column: DishUpSlipGroupRolesUpdateColumn;
  dishUpSlipGroupRoles_updates: DishUpSlipGroupRolesUpdates;
  dishUpSlipGroupRoles_var_pop_order_by: DishUpSlipGroupRolesVarPopOrderBy;
  dishUpSlipGroupRoles_var_samp_order_by: DishUpSlipGroupRolesVarSampOrderBy;
  dishUpSlipGroupRoles_variance_order_by: DishUpSlipGroupRolesVarianceOrderBy;
  dishUpSlipGroupShopMenus: ResolverTypeWrapper<DishUpSlipGroupShopMenus>;
  dishUpSlipGroupShopMenus_aggregate_order_by: DishUpSlipGroupShopMenusAggregateOrderBy;
  dishUpSlipGroupShopMenus_arr_rel_insert_input: DishUpSlipGroupShopMenusArrRelInsertInput;
  dishUpSlipGroupShopMenus_avg_order_by: DishUpSlipGroupShopMenusAvgOrderBy;
  dishUpSlipGroupShopMenus_bool_exp: DishUpSlipGroupShopMenusBoolExp;
  dishUpSlipGroupShopMenus_constraint: DishUpSlipGroupShopMenusConstraint;
  dishUpSlipGroupShopMenus_inc_input: DishUpSlipGroupShopMenusIncInput;
  dishUpSlipGroupShopMenus_insert_input: DishUpSlipGroupShopMenusInsertInput;
  dishUpSlipGroupShopMenus_max_order_by: DishUpSlipGroupShopMenusMaxOrderBy;
  dishUpSlipGroupShopMenus_min_order_by: DishUpSlipGroupShopMenusMinOrderBy;
  dishUpSlipGroupShopMenus_mutation_response: ResolverTypeWrapper<DishUpSlipGroupShopMenusMutationResponse>;
  dishUpSlipGroupShopMenus_on_conflict: DishUpSlipGroupShopMenusOnConflict;
  dishUpSlipGroupShopMenus_order_by: DishUpSlipGroupShopMenusOrderBy;
  dishUpSlipGroupShopMenus_pk_columns_input: DishUpSlipGroupShopMenusPkColumnsInput;
  dishUpSlipGroupShopMenus_select_column: DishUpSlipGroupShopMenusSelectColumn;
  dishUpSlipGroupShopMenus_set_input: DishUpSlipGroupShopMenusSetInput;
  dishUpSlipGroupShopMenus_stddev_order_by: DishUpSlipGroupShopMenusStddevOrderBy;
  dishUpSlipGroupShopMenus_stddev_pop_order_by: DishUpSlipGroupShopMenusStddevPopOrderBy;
  dishUpSlipGroupShopMenus_stddev_samp_order_by: DishUpSlipGroupShopMenusStddevSampOrderBy;
  dishUpSlipGroupShopMenus_stream_cursor_input: DishUpSlipGroupShopMenusStreamCursorInput;
  dishUpSlipGroupShopMenus_stream_cursor_value_input: DishUpSlipGroupShopMenusStreamCursorValueInput;
  dishUpSlipGroupShopMenus_sum_order_by: DishUpSlipGroupShopMenusSumOrderBy;
  dishUpSlipGroupShopMenus_update_column: DishUpSlipGroupShopMenusUpdateColumn;
  dishUpSlipGroupShopMenus_updates: DishUpSlipGroupShopMenusUpdates;
  dishUpSlipGroupShopMenus_var_pop_order_by: DishUpSlipGroupShopMenusVarPopOrderBy;
  dishUpSlipGroupShopMenus_var_samp_order_by: DishUpSlipGroupShopMenusVarSampOrderBy;
  dishUpSlipGroupShopMenus_variance_order_by: DishUpSlipGroupShopMenusVarianceOrderBy;
  dishUpSlipGroupShopPlans: ResolverTypeWrapper<DishUpSlipGroupShopPlans>;
  dishUpSlipGroupShopPlans_aggregate_order_by: DishUpSlipGroupShopPlansAggregateOrderBy;
  dishUpSlipGroupShopPlans_arr_rel_insert_input: DishUpSlipGroupShopPlansArrRelInsertInput;
  dishUpSlipGroupShopPlans_avg_order_by: DishUpSlipGroupShopPlansAvgOrderBy;
  dishUpSlipGroupShopPlans_bool_exp: DishUpSlipGroupShopPlansBoolExp;
  dishUpSlipGroupShopPlans_constraint: DishUpSlipGroupShopPlansConstraint;
  dishUpSlipGroupShopPlans_inc_input: DishUpSlipGroupShopPlansIncInput;
  dishUpSlipGroupShopPlans_insert_input: DishUpSlipGroupShopPlansInsertInput;
  dishUpSlipGroupShopPlans_max_order_by: DishUpSlipGroupShopPlansMaxOrderBy;
  dishUpSlipGroupShopPlans_min_order_by: DishUpSlipGroupShopPlansMinOrderBy;
  dishUpSlipGroupShopPlans_mutation_response: ResolverTypeWrapper<DishUpSlipGroupShopPlansMutationResponse>;
  dishUpSlipGroupShopPlans_on_conflict: DishUpSlipGroupShopPlansOnConflict;
  dishUpSlipGroupShopPlans_order_by: DishUpSlipGroupShopPlansOrderBy;
  dishUpSlipGroupShopPlans_pk_columns_input: DishUpSlipGroupShopPlansPkColumnsInput;
  dishUpSlipGroupShopPlans_select_column: DishUpSlipGroupShopPlansSelectColumn;
  dishUpSlipGroupShopPlans_set_input: DishUpSlipGroupShopPlansSetInput;
  dishUpSlipGroupShopPlans_stddev_order_by: DishUpSlipGroupShopPlansStddevOrderBy;
  dishUpSlipGroupShopPlans_stddev_pop_order_by: DishUpSlipGroupShopPlansStddevPopOrderBy;
  dishUpSlipGroupShopPlans_stddev_samp_order_by: DishUpSlipGroupShopPlansStddevSampOrderBy;
  dishUpSlipGroupShopPlans_stream_cursor_input: DishUpSlipGroupShopPlansStreamCursorInput;
  dishUpSlipGroupShopPlans_stream_cursor_value_input: DishUpSlipGroupShopPlansStreamCursorValueInput;
  dishUpSlipGroupShopPlans_sum_order_by: DishUpSlipGroupShopPlansSumOrderBy;
  dishUpSlipGroupShopPlans_update_column: DishUpSlipGroupShopPlansUpdateColumn;
  dishUpSlipGroupShopPlans_updates: DishUpSlipGroupShopPlansUpdates;
  dishUpSlipGroupShopPlans_var_pop_order_by: DishUpSlipGroupShopPlansVarPopOrderBy;
  dishUpSlipGroupShopPlans_var_samp_order_by: DishUpSlipGroupShopPlansVarSampOrderBy;
  dishUpSlipGroupShopPlans_variance_order_by: DishUpSlipGroupShopPlansVarianceOrderBy;
  dishUpSlipGroupTables: ResolverTypeWrapper<DishUpSlipGroupTables>;
  dishUpSlipGroupTables_aggregate_order_by: DishUpSlipGroupTablesAggregateOrderBy;
  dishUpSlipGroupTables_arr_rel_insert_input: DishUpSlipGroupTablesArrRelInsertInput;
  dishUpSlipGroupTables_avg_order_by: DishUpSlipGroupTablesAvgOrderBy;
  dishUpSlipGroupTables_bool_exp: DishUpSlipGroupTablesBoolExp;
  dishUpSlipGroupTables_constraint: DishUpSlipGroupTablesConstraint;
  dishUpSlipGroupTables_insert_input: DishUpSlipGroupTablesInsertInput;
  dishUpSlipGroupTables_max_order_by: DishUpSlipGroupTablesMaxOrderBy;
  dishUpSlipGroupTables_min_order_by: DishUpSlipGroupTablesMinOrderBy;
  dishUpSlipGroupTables_mutation_response: ResolverTypeWrapper<DishUpSlipGroupTablesMutationResponse>;
  dishUpSlipGroupTables_on_conflict: DishUpSlipGroupTablesOnConflict;
  dishUpSlipGroupTables_order_by: DishUpSlipGroupTablesOrderBy;
  dishUpSlipGroupTables_select_column: DishUpSlipGroupTablesSelectColumn;
  dishUpSlipGroupTables_stddev_order_by: DishUpSlipGroupTablesStddevOrderBy;
  dishUpSlipGroupTables_stddev_pop_order_by: DishUpSlipGroupTablesStddevPopOrderBy;
  dishUpSlipGroupTables_stddev_samp_order_by: DishUpSlipGroupTablesStddevSampOrderBy;
  dishUpSlipGroupTables_stream_cursor_input: DishUpSlipGroupTablesStreamCursorInput;
  dishUpSlipGroupTables_stream_cursor_value_input: DishUpSlipGroupTablesStreamCursorValueInput;
  dishUpSlipGroupTables_sum_order_by: DishUpSlipGroupTablesSumOrderBy;
  dishUpSlipGroupTables_update_column: DishUpSlipGroupTablesUpdateColumn;
  dishUpSlipGroupTables_var_pop_order_by: DishUpSlipGroupTablesVarPopOrderBy;
  dishUpSlipGroupTables_var_samp_order_by: DishUpSlipGroupTablesVarSampOrderBy;
  dishUpSlipGroupTables_variance_order_by: DishUpSlipGroupTablesVarianceOrderBy;
  dishUpSlipGroup_aggregate_order_by: DishUpSlipGroupAggregateOrderBy;
  dishUpSlipGroup_arr_rel_insert_input: DishUpSlipGroupArrRelInsertInput;
  dishUpSlipGroup_avg_order_by: DishUpSlipGroupAvgOrderBy;
  dishUpSlipGroup_bool_exp: DishUpSlipGroupBoolExp;
  dishUpSlipGroup_constraint: DishUpSlipGroupConstraint;
  dishUpSlipGroup_inc_input: DishUpSlipGroupIncInput;
  dishUpSlipGroup_insert_input: DishUpSlipGroupInsertInput;
  dishUpSlipGroup_max_order_by: DishUpSlipGroupMaxOrderBy;
  dishUpSlipGroup_min_order_by: DishUpSlipGroupMinOrderBy;
  dishUpSlipGroup_mutation_response: ResolverTypeWrapper<DishUpSlipGroupMutationResponse>;
  dishUpSlipGroup_obj_rel_insert_input: DishUpSlipGroupObjRelInsertInput;
  dishUpSlipGroup_on_conflict: DishUpSlipGroupOnConflict;
  dishUpSlipGroup_order_by: DishUpSlipGroupOrderBy;
  dishUpSlipGroup_pk_columns_input: DishUpSlipGroupPkColumnsInput;
  dishUpSlipGroup_select_column: DishUpSlipGroupSelectColumn;
  dishUpSlipGroup_set_input: DishUpSlipGroupSetInput;
  dishUpSlipGroup_stddev_order_by: DishUpSlipGroupStddevOrderBy;
  dishUpSlipGroup_stddev_pop_order_by: DishUpSlipGroupStddevPopOrderBy;
  dishUpSlipGroup_stddev_samp_order_by: DishUpSlipGroupStddevSampOrderBy;
  dishUpSlipGroup_stream_cursor_input: DishUpSlipGroupStreamCursorInput;
  dishUpSlipGroup_stream_cursor_value_input: DishUpSlipGroupStreamCursorValueInput;
  dishUpSlipGroup_sum_order_by: DishUpSlipGroupSumOrderBy;
  dishUpSlipGroup_update_column: DishUpSlipGroupUpdateColumn;
  dishUpSlipGroup_updates: DishUpSlipGroupUpdates;
  dishUpSlipGroup_var_pop_order_by: DishUpSlipGroupVarPopOrderBy;
  dishUpSlipGroup_var_samp_order_by: DishUpSlipGroupVarSampOrderBy;
  dishUpSlipGroup_variance_order_by: DishUpSlipGroupVarianceOrderBy;
  displayType_enum: DisplayTypeEnum;
  displayType_enum_comparison_exp: DisplayTypeEnumComparisonExp;
  ebicaConfig: ResolverTypeWrapper<EbicaConfig>;
  ebicaConfig_bool_exp: EbicaConfigBoolExp;
  ebicaConfig_constraint: EbicaConfigConstraint;
  ebicaConfig_inc_input: EbicaConfigIncInput;
  ebicaConfig_insert_input: EbicaConfigInsertInput;
  ebicaConfig_mutation_response: ResolverTypeWrapper<EbicaConfigMutationResponse>;
  ebicaConfig_on_conflict: EbicaConfigOnConflict;
  ebicaConfig_order_by: EbicaConfigOrderBy;
  ebicaConfig_pk_columns_input: EbicaConfigPkColumnsInput;
  ebicaConfig_select_column: EbicaConfigSelectColumn;
  ebicaConfig_set_input: EbicaConfigSetInput;
  ebicaConfig_stream_cursor_input: EbicaConfigStreamCursorInput;
  ebicaConfig_stream_cursor_value_input: EbicaConfigStreamCursorValueInput;
  ebicaConfig_update_column: EbicaConfigUpdateColumn;
  ebicaConfig_updates: EbicaConfigUpdates;
  ebicaTable: ResolverTypeWrapper<EbicaTable>;
  ebicaTable_bool_exp: EbicaTableBoolExp;
  ebicaTable_constraint: EbicaTableConstraint;
  ebicaTable_insert_input: EbicaTableInsertInput;
  ebicaTable_mutation_response: ResolverTypeWrapper<EbicaTableMutationResponse>;
  ebicaTable_on_conflict: EbicaTableOnConflict;
  ebicaTable_order_by: EbicaTableOrderBy;
  ebicaTable_select_column: EbicaTableSelectColumn;
  ebicaTable_stream_cursor_input: EbicaTableStreamCursorInput;
  ebicaTable_stream_cursor_value_input: EbicaTableStreamCursorValueInput;
  ebicaTable_update_column: EbicaTableUpdateColumn;
  externalOnlineMenu: ResolverTypeWrapper<ExternalOnlineMenu>;
  externalOnlineMenuAvailableTimeTerm: ResolverTypeWrapper<ExternalOnlineMenuAvailableTimeTerm>;
  externalOnlineMenuAvailableTimeTerm_aggregate_order_by: ExternalOnlineMenuAvailableTimeTermAggregateOrderBy;
  externalOnlineMenuAvailableTimeTerm_arr_rel_insert_input: ExternalOnlineMenuAvailableTimeTermArrRelInsertInput;
  externalOnlineMenuAvailableTimeTerm_bool_exp: ExternalOnlineMenuAvailableTimeTermBoolExp;
  externalOnlineMenuAvailableTimeTerm_constraint: ExternalOnlineMenuAvailableTimeTermConstraint;
  externalOnlineMenuAvailableTimeTerm_insert_input: ExternalOnlineMenuAvailableTimeTermInsertInput;
  externalOnlineMenuAvailableTimeTerm_max_order_by: ExternalOnlineMenuAvailableTimeTermMaxOrderBy;
  externalOnlineMenuAvailableTimeTerm_min_order_by: ExternalOnlineMenuAvailableTimeTermMinOrderBy;
  externalOnlineMenuAvailableTimeTerm_mutation_response: ResolverTypeWrapper<ExternalOnlineMenuAvailableTimeTermMutationResponse>;
  externalOnlineMenuAvailableTimeTerm_on_conflict: ExternalOnlineMenuAvailableTimeTermOnConflict;
  externalOnlineMenuAvailableTimeTerm_order_by: ExternalOnlineMenuAvailableTimeTermOrderBy;
  externalOnlineMenuAvailableTimeTerm_pk_columns_input: ExternalOnlineMenuAvailableTimeTermPkColumnsInput;
  externalOnlineMenuAvailableTimeTerm_select_column: ExternalOnlineMenuAvailableTimeTermSelectColumn;
  externalOnlineMenuAvailableTimeTerm_set_input: ExternalOnlineMenuAvailableTimeTermSetInput;
  externalOnlineMenuAvailableTimeTerm_stream_cursor_input: ExternalOnlineMenuAvailableTimeTermStreamCursorInput;
  externalOnlineMenuAvailableTimeTerm_stream_cursor_value_input: ExternalOnlineMenuAvailableTimeTermStreamCursorValueInput;
  externalOnlineMenuAvailableTimeTerm_update_column: ExternalOnlineMenuAvailableTimeTermUpdateColumn;
  externalOnlineMenuAvailableTimeTerm_updates: ExternalOnlineMenuAvailableTimeTermUpdates;
  externalOnlineMenuConfig: ResolverTypeWrapper<ExternalOnlineMenuConfig>;
  externalOnlineMenuConfig_aggregate_order_by: ExternalOnlineMenuConfigAggregateOrderBy;
  externalOnlineMenuConfig_arr_rel_insert_input: ExternalOnlineMenuConfigArrRelInsertInput;
  externalOnlineMenuConfig_bool_exp: ExternalOnlineMenuConfigBoolExp;
  externalOnlineMenuConfig_constraint: ExternalOnlineMenuConfigConstraint;
  externalOnlineMenuConfig_insert_input: ExternalOnlineMenuConfigInsertInput;
  externalOnlineMenuConfig_max_order_by: ExternalOnlineMenuConfigMaxOrderBy;
  externalOnlineMenuConfig_min_order_by: ExternalOnlineMenuConfigMinOrderBy;
  externalOnlineMenuConfig_mutation_response: ResolverTypeWrapper<ExternalOnlineMenuConfigMutationResponse>;
  externalOnlineMenuConfig_obj_rel_insert_input: ExternalOnlineMenuConfigObjRelInsertInput;
  externalOnlineMenuConfig_on_conflict: ExternalOnlineMenuConfigOnConflict;
  externalOnlineMenuConfig_order_by: ExternalOnlineMenuConfigOrderBy;
  externalOnlineMenuConfig_pk_columns_input: ExternalOnlineMenuConfigPkColumnsInput;
  externalOnlineMenuConfig_select_column: ExternalOnlineMenuConfigSelectColumn;
  externalOnlineMenuConfig_set_input: ExternalOnlineMenuConfigSetInput;
  externalOnlineMenuConfig_stream_cursor_input: ExternalOnlineMenuConfigStreamCursorInput;
  externalOnlineMenuConfig_stream_cursor_value_input: ExternalOnlineMenuConfigStreamCursorValueInput;
  externalOnlineMenuConfig_update_column: ExternalOnlineMenuConfigUpdateColumn;
  externalOnlineMenuConfig_updates: ExternalOnlineMenuConfigUpdates;
  externalOnlineMenu_aggregate_order_by: ExternalOnlineMenuAggregateOrderBy;
  externalOnlineMenu_arr_rel_insert_input: ExternalOnlineMenuArrRelInsertInput;
  externalOnlineMenu_bool_exp: ExternalOnlineMenuBoolExp;
  externalOnlineMenu_constraint: ExternalOnlineMenuConstraint;
  externalOnlineMenu_insert_input: ExternalOnlineMenuInsertInput;
  externalOnlineMenu_max_order_by: ExternalOnlineMenuMaxOrderBy;
  externalOnlineMenu_min_order_by: ExternalOnlineMenuMinOrderBy;
  externalOnlineMenu_mutation_response: ResolverTypeWrapper<ExternalOnlineMenuMutationResponse>;
  externalOnlineMenu_obj_rel_insert_input: ExternalOnlineMenuObjRelInsertInput;
  externalOnlineMenu_on_conflict: ExternalOnlineMenuOnConflict;
  externalOnlineMenu_order_by: ExternalOnlineMenuOrderBy;
  externalOnlineMenu_pk_columns_input: ExternalOnlineMenuPkColumnsInput;
  externalOnlineMenu_select_column: ExternalOnlineMenuSelectColumn;
  externalOnlineMenu_set_input: ExternalOnlineMenuSetInput;
  externalOnlineMenu_stream_cursor_input: ExternalOnlineMenuStreamCursorInput;
  externalOnlineMenu_stream_cursor_value_input: ExternalOnlineMenuStreamCursorValueInput;
  externalOnlineMenu_update_column: ExternalOnlineMenuUpdateColumn;
  externalOnlineMenu_updates: ExternalOnlineMenuUpdates;
  featureFlag: ResolverTypeWrapper<FeatureFlag>;
  featureFlagShop: ResolverTypeWrapper<FeatureFlagShop>;
  featureFlagShop_aggregate_order_by: FeatureFlagShopAggregateOrderBy;
  featureFlagShop_arr_rel_insert_input: FeatureFlagShopArrRelInsertInput;
  featureFlagShop_bool_exp: FeatureFlagShopBoolExp;
  featureFlagShop_constraint: FeatureFlagShopConstraint;
  featureFlagShop_insert_input: FeatureFlagShopInsertInput;
  featureFlagShop_max_order_by: FeatureFlagShopMaxOrderBy;
  featureFlagShop_min_order_by: FeatureFlagShopMinOrderBy;
  featureFlagShop_mutation_response: ResolverTypeWrapper<FeatureFlagShopMutationResponse>;
  featureFlagShop_on_conflict: FeatureFlagShopOnConflict;
  featureFlagShop_order_by: FeatureFlagShopOrderBy;
  featureFlagShop_pk_columns_input: FeatureFlagShopPkColumnsInput;
  featureFlagShop_select_column: FeatureFlagShopSelectColumn;
  featureFlagShop_set_input: FeatureFlagShopSetInput;
  featureFlagShop_stream_cursor_input: FeatureFlagShopStreamCursorInput;
  featureFlagShop_stream_cursor_value_input: FeatureFlagShopStreamCursorValueInput;
  featureFlagShop_update_column: FeatureFlagShopUpdateColumn;
  featureFlagShop_updates: FeatureFlagShopUpdates;
  featureFlag_bool_exp: FeatureFlagBoolExp;
  featureFlag_enum: FeatureFlagEnum;
  featureFlag_enum_comparison_exp: FeatureFlagEnumComparisonExp;
  featureFlag_order_by: FeatureFlagOrderBy;
  featureFlag_select_column: FeatureFlagSelectColumn;
  featureFlag_stream_cursor_input: FeatureFlagStreamCursorInput;
  featureFlag_stream_cursor_value_input: FeatureFlagStreamCursorValueInput;
  foodingJournalConfig: ResolverTypeWrapper<FoodingJournalConfig>;
  foodingJournalConfig_bool_exp: FoodingJournalConfigBoolExp;
  foodingJournalConfig_constraint: FoodingJournalConfigConstraint;
  foodingJournalConfig_insert_input: FoodingJournalConfigInsertInput;
  foodingJournalConfig_mutation_response: ResolverTypeWrapper<FoodingJournalConfigMutationResponse>;
  foodingJournalConfig_obj_rel_insert_input: FoodingJournalConfigObjRelInsertInput;
  foodingJournalConfig_on_conflict: FoodingJournalConfigOnConflict;
  foodingJournalConfig_order_by: FoodingJournalConfigOrderBy;
  foodingJournalConfig_pk_columns_input: FoodingJournalConfigPkColumnsInput;
  foodingJournalConfig_select_column: FoodingJournalConfigSelectColumn;
  foodingJournalConfig_set_input: FoodingJournalConfigSetInput;
  foodingJournalConfig_stream_cursor_input: FoodingJournalConfigStreamCursorInput;
  foodingJournalConfig_stream_cursor_value_input: FoodingJournalConfigStreamCursorValueInput;
  foodingJournalConfig_update_column: FoodingJournalConfigUpdateColumn;
  foodingJournalConfig_updates: FoodingJournalConfigUpdates;
  foodingJournalDepartmentMaster: ResolverTypeWrapper<FoodingJournalDepartmentMaster>;
  foodingJournalDepartmentMaster_bool_exp: FoodingJournalDepartmentMasterBoolExp;
  foodingJournalDepartmentMaster_constraint: FoodingJournalDepartmentMasterConstraint;
  foodingJournalDepartmentMaster_insert_input: FoodingJournalDepartmentMasterInsertInput;
  foodingJournalDepartmentMaster_mutation_response: ResolverTypeWrapper<FoodingJournalDepartmentMasterMutationResponse>;
  foodingJournalDepartmentMaster_obj_rel_insert_input: FoodingJournalDepartmentMasterObjRelInsertInput;
  foodingJournalDepartmentMaster_on_conflict: FoodingJournalDepartmentMasterOnConflict;
  foodingJournalDepartmentMaster_order_by: FoodingJournalDepartmentMasterOrderBy;
  foodingJournalDepartmentMaster_pk_columns_input: FoodingJournalDepartmentMasterPkColumnsInput;
  foodingJournalDepartmentMaster_select_column: FoodingJournalDepartmentMasterSelectColumn;
  foodingJournalDepartmentMaster_set_input: FoodingJournalDepartmentMasterSetInput;
  foodingJournalDepartmentMaster_stream_cursor_input: FoodingJournalDepartmentMasterStreamCursorInput;
  foodingJournalDepartmentMaster_stream_cursor_value_input: FoodingJournalDepartmentMasterStreamCursorValueInput;
  foodingJournalDepartmentMaster_update_column: FoodingJournalDepartmentMasterUpdateColumn;
  foodingJournalDepartmentMaster_updates: FoodingJournalDepartmentMasterUpdates;
  foodingJournalGroupMaster: ResolverTypeWrapper<FoodingJournalGroupMaster>;
  foodingJournalGroupMaster_bool_exp: FoodingJournalGroupMasterBoolExp;
  foodingJournalGroupMaster_constraint: FoodingJournalGroupMasterConstraint;
  foodingJournalGroupMaster_insert_input: FoodingJournalGroupMasterInsertInput;
  foodingJournalGroupMaster_mutation_response: ResolverTypeWrapper<FoodingJournalGroupMasterMutationResponse>;
  foodingJournalGroupMaster_obj_rel_insert_input: FoodingJournalGroupMasterObjRelInsertInput;
  foodingJournalGroupMaster_on_conflict: FoodingJournalGroupMasterOnConflict;
  foodingJournalGroupMaster_order_by: FoodingJournalGroupMasterOrderBy;
  foodingJournalGroupMaster_pk_columns_input: FoodingJournalGroupMasterPkColumnsInput;
  foodingJournalGroupMaster_select_column: FoodingJournalGroupMasterSelectColumn;
  foodingJournalGroupMaster_set_input: FoodingJournalGroupMasterSetInput;
  foodingJournalGroupMaster_stream_cursor_input: FoodingJournalGroupMasterStreamCursorInput;
  foodingJournalGroupMaster_stream_cursor_value_input: FoodingJournalGroupMasterStreamCursorValueInput;
  foodingJournalGroupMaster_update_column: FoodingJournalGroupMasterUpdateColumn;
  foodingJournalGroupMaster_updates: FoodingJournalGroupMasterUpdates;
  foodingJournalMenu: ResolverTypeWrapper<FoodingJournalMenu>;
  foodingJournalMenu_aggregate_order_by: FoodingJournalMenuAggregateOrderBy;
  foodingJournalMenu_arr_rel_insert_input: FoodingJournalMenuArrRelInsertInput;
  foodingJournalMenu_bool_exp: FoodingJournalMenuBoolExp;
  foodingJournalMenu_constraint: FoodingJournalMenuConstraint;
  foodingJournalMenu_insert_input: FoodingJournalMenuInsertInput;
  foodingJournalMenu_max_order_by: FoodingJournalMenuMaxOrderBy;
  foodingJournalMenu_min_order_by: FoodingJournalMenuMinOrderBy;
  foodingJournalMenu_mutation_response: ResolverTypeWrapper<FoodingJournalMenuMutationResponse>;
  foodingJournalMenu_obj_rel_insert_input: FoodingJournalMenuObjRelInsertInput;
  foodingJournalMenu_on_conflict: FoodingJournalMenuOnConflict;
  foodingJournalMenu_order_by: FoodingJournalMenuOrderBy;
  foodingJournalMenu_pk_columns_input: FoodingJournalMenuPkColumnsInput;
  foodingJournalMenu_select_column: FoodingJournalMenuSelectColumn;
  foodingJournalMenu_set_input: FoodingJournalMenuSetInput;
  foodingJournalMenu_stream_cursor_input: FoodingJournalMenuStreamCursorInput;
  foodingJournalMenu_stream_cursor_value_input: FoodingJournalMenuStreamCursorValueInput;
  foodingJournalMenu_update_column: FoodingJournalMenuUpdateColumn;
  foodingJournalMenu_updates: FoodingJournalMenuUpdates;
  foodingJournalOnSitePaymentDetailType: ResolverTypeWrapper<FoodingJournalOnSitePaymentDetailType>;
  foodingJournalOnSitePaymentDetailType_bool_exp: FoodingJournalOnSitePaymentDetailTypeBoolExp;
  foodingJournalOnSitePaymentDetailType_constraint: FoodingJournalOnSitePaymentDetailTypeConstraint;
  foodingJournalOnSitePaymentDetailType_inc_input: FoodingJournalOnSitePaymentDetailTypeIncInput;
  foodingJournalOnSitePaymentDetailType_insert_input: FoodingJournalOnSitePaymentDetailTypeInsertInput;
  foodingJournalOnSitePaymentDetailType_mutation_response: ResolverTypeWrapper<FoodingJournalOnSitePaymentDetailTypeMutationResponse>;
  foodingJournalOnSitePaymentDetailType_obj_rel_insert_input: FoodingJournalOnSitePaymentDetailTypeObjRelInsertInput;
  foodingJournalOnSitePaymentDetailType_on_conflict: FoodingJournalOnSitePaymentDetailTypeOnConflict;
  foodingJournalOnSitePaymentDetailType_order_by: FoodingJournalOnSitePaymentDetailTypeOrderBy;
  foodingJournalOnSitePaymentDetailType_pk_columns_input: FoodingJournalOnSitePaymentDetailTypePkColumnsInput;
  foodingJournalOnSitePaymentDetailType_select_column: FoodingJournalOnSitePaymentDetailTypeSelectColumn;
  foodingJournalOnSitePaymentDetailType_set_input: FoodingJournalOnSitePaymentDetailTypeSetInput;
  foodingJournalOnSitePaymentDetailType_stream_cursor_input: FoodingJournalOnSitePaymentDetailTypeStreamCursorInput;
  foodingJournalOnSitePaymentDetailType_stream_cursor_value_input: FoodingJournalOnSitePaymentDetailTypeStreamCursorValueInput;
  foodingJournalOnSitePaymentDetailType_update_column: FoodingJournalOnSitePaymentDetailTypeUpdateColumn;
  foodingJournalOnSitePaymentDetailType_updates: FoodingJournalOnSitePaymentDetailTypeUpdates;
  foodingJournalOnSitePaymentDiscountType: ResolverTypeWrapper<FoodingJournalOnSitePaymentDiscountType>;
  foodingJournalOnSitePaymentDiscountType_bool_exp: FoodingJournalOnSitePaymentDiscountTypeBoolExp;
  foodingJournalOnSitePaymentDiscountType_constraint: FoodingJournalOnSitePaymentDiscountTypeConstraint;
  foodingJournalOnSitePaymentDiscountType_inc_input: FoodingJournalOnSitePaymentDiscountTypeIncInput;
  foodingJournalOnSitePaymentDiscountType_insert_input: FoodingJournalOnSitePaymentDiscountTypeInsertInput;
  foodingJournalOnSitePaymentDiscountType_mutation_response: ResolverTypeWrapper<FoodingJournalOnSitePaymentDiscountTypeMutationResponse>;
  foodingJournalOnSitePaymentDiscountType_obj_rel_insert_input: FoodingJournalOnSitePaymentDiscountTypeObjRelInsertInput;
  foodingJournalOnSitePaymentDiscountType_on_conflict: FoodingJournalOnSitePaymentDiscountTypeOnConflict;
  foodingJournalOnSitePaymentDiscountType_order_by: FoodingJournalOnSitePaymentDiscountTypeOrderBy;
  foodingJournalOnSitePaymentDiscountType_pk_columns_input: FoodingJournalOnSitePaymentDiscountTypePkColumnsInput;
  foodingJournalOnSitePaymentDiscountType_select_column: FoodingJournalOnSitePaymentDiscountTypeSelectColumn;
  foodingJournalOnSitePaymentDiscountType_set_input: FoodingJournalOnSitePaymentDiscountTypeSetInput;
  foodingJournalOnSitePaymentDiscountType_stream_cursor_input: FoodingJournalOnSitePaymentDiscountTypeStreamCursorInput;
  foodingJournalOnSitePaymentDiscountType_stream_cursor_value_input: FoodingJournalOnSitePaymentDiscountTypeStreamCursorValueInput;
  foodingJournalOnSitePaymentDiscountType_update_column: FoodingJournalOnSitePaymentDiscountTypeUpdateColumn;
  foodingJournalOnSitePaymentDiscountType_updates: FoodingJournalOnSitePaymentDiscountTypeUpdates;
  giftMeta: ResolverTypeWrapper<GiftMeta>;
  giftMeta_aggregate: ResolverTypeWrapper<GiftMetaAggregate>;
  giftMeta_aggregate_fields: ResolverTypeWrapper<GiftMetaAggregateFields>;
  giftMeta_avg_fields: ResolverTypeWrapper<GiftMetaAvgFields>;
  giftMeta_bool_exp: GiftMetaBoolExp;
  giftMeta_constraint: GiftMetaConstraint;
  giftMeta_inc_input: GiftMetaIncInput;
  giftMeta_insert_input: GiftMetaInsertInput;
  giftMeta_max_fields: ResolverTypeWrapper<GiftMetaMaxFields>;
  giftMeta_min_fields: ResolverTypeWrapper<GiftMetaMinFields>;
  giftMeta_mutation_response: ResolverTypeWrapper<GiftMetaMutationResponse>;
  giftMeta_obj_rel_insert_input: GiftMetaObjRelInsertInput;
  giftMeta_on_conflict: GiftMetaOnConflict;
  giftMeta_order_by: GiftMetaOrderBy;
  giftMeta_pk_columns_input: GiftMetaPkColumnsInput;
  giftMeta_select_column: GiftMetaSelectColumn;
  giftMeta_set_input: GiftMetaSetInput;
  giftMeta_stddev_fields: ResolverTypeWrapper<GiftMetaStddevFields>;
  giftMeta_stddev_pop_fields: ResolverTypeWrapper<GiftMetaStddevPopFields>;
  giftMeta_stddev_samp_fields: ResolverTypeWrapper<GiftMetaStddevSampFields>;
  giftMeta_stream_cursor_input: GiftMetaStreamCursorInput;
  giftMeta_stream_cursor_value_input: GiftMetaStreamCursorValueInput;
  giftMeta_sum_fields: ResolverTypeWrapper<GiftMetaSumFields>;
  giftMeta_update_column: GiftMetaUpdateColumn;
  giftMeta_updates: GiftMetaUpdates;
  giftMeta_var_pop_fields: ResolverTypeWrapper<GiftMetaVarPopFields>;
  giftMeta_var_samp_fields: ResolverTypeWrapper<GiftMetaVarSampFields>;
  giftMeta_variance_fields: ResolverTypeWrapper<GiftMetaVarianceFields>;
  giftOptionMeta: ResolverTypeWrapper<GiftOptionMeta>;
  giftOptionMeta_aggregate_order_by: GiftOptionMetaAggregateOrderBy;
  giftOptionMeta_arr_rel_insert_input: GiftOptionMetaArrRelInsertInput;
  giftOptionMeta_avg_order_by: GiftOptionMetaAvgOrderBy;
  giftOptionMeta_bool_exp: GiftOptionMetaBoolExp;
  giftOptionMeta_constraint: GiftOptionMetaConstraint;
  giftOptionMeta_insert_input: GiftOptionMetaInsertInput;
  giftOptionMeta_max_order_by: GiftOptionMetaMaxOrderBy;
  giftOptionMeta_min_order_by: GiftOptionMetaMinOrderBy;
  giftOptionMeta_mutation_response: ResolverTypeWrapper<GiftOptionMetaMutationResponse>;
  giftOptionMeta_obj_rel_insert_input: GiftOptionMetaObjRelInsertInput;
  giftOptionMeta_on_conflict: GiftOptionMetaOnConflict;
  giftOptionMeta_order_by: GiftOptionMetaOrderBy;
  giftOptionMeta_select_column: GiftOptionMetaSelectColumn;
  giftOptionMeta_stddev_order_by: GiftOptionMetaStddevOrderBy;
  giftOptionMeta_stddev_pop_order_by: GiftOptionMetaStddevPopOrderBy;
  giftOptionMeta_stddev_samp_order_by: GiftOptionMetaStddevSampOrderBy;
  giftOptionMeta_stream_cursor_input: GiftOptionMetaStreamCursorInput;
  giftOptionMeta_stream_cursor_value_input: GiftOptionMetaStreamCursorValueInput;
  giftOptionMeta_sum_order_by: GiftOptionMetaSumOrderBy;
  giftOptionMeta_update_column: GiftOptionMetaUpdateColumn;
  giftOptionMeta_var_pop_order_by: GiftOptionMetaVarPopOrderBy;
  giftOptionMeta_var_samp_order_by: GiftOptionMetaVarSampOrderBy;
  giftOptionMeta_variance_order_by: GiftOptionMetaVarianceOrderBy;
  gmoBankAccount: ResolverTypeWrapper<GmoBankAccount>;
  gmoBankAccountShop: ResolverTypeWrapper<GmoBankAccountShop>;
  gmoBankAccountShop_aggregate_order_by: GmoBankAccountShopAggregateOrderBy;
  gmoBankAccountShop_bool_exp: GmoBankAccountShopBoolExp;
  gmoBankAccountShop_max_order_by: GmoBankAccountShopMaxOrderBy;
  gmoBankAccountShop_min_order_by: GmoBankAccountShopMinOrderBy;
  gmoBankAccountShop_order_by: GmoBankAccountShopOrderBy;
  gmoBankAccountShop_select_column: GmoBankAccountShopSelectColumn;
  gmoBankAccountShop_stream_cursor_input: GmoBankAccountShopStreamCursorInput;
  gmoBankAccountShop_stream_cursor_value_input: GmoBankAccountShopStreamCursorValueInput;
  gmoBankAccount_bool_exp: GmoBankAccountBoolExp;
  gmoBankAccount_order_by: GmoBankAccountOrderBy;
  gmoBankAccount_select_column: GmoBankAccountSelectColumn;
  gmoBankAccount_stream_cursor_input: GmoBankAccountStreamCursorInput;
  gmoBankAccount_stream_cursor_value_input: GmoBankAccountStreamCursorValueInput;
  gmoOffPaymentMethodType: ResolverTypeWrapper<GmoOffPaymentMethodType>;
  gmoOffPaymentMethodType_bool_exp: GmoOffPaymentMethodTypeBoolExp;
  gmoOffPaymentMethodType_enum: GmoOffPaymentMethodTypeEnum;
  gmoOffPaymentMethodType_enum_comparison_exp: GmoOffPaymentMethodTypeEnumComparisonExp;
  gmoOffPaymentMethodType_order_by: GmoOffPaymentMethodTypeOrderBy;
  gmoOffPaymentMethodType_select_column: GmoOffPaymentMethodTypeSelectColumn;
  gmoOffPaymentMethodType_stream_cursor_input: GmoOffPaymentMethodTypeStreamCursorInput;
  gmoOffPaymentMethodType_stream_cursor_value_input: GmoOffPaymentMethodTypeStreamCursorValueInput;
  gmoOffPaymentOnSitePaymentDetailType: ResolverTypeWrapper<GmoOffPaymentOnSitePaymentDetailType>;
  gmoOffPaymentOnSitePaymentDetailType_aggregate_order_by: GmoOffPaymentOnSitePaymentDetailTypeAggregateOrderBy;
  gmoOffPaymentOnSitePaymentDetailType_bool_exp: GmoOffPaymentOnSitePaymentDetailTypeBoolExp;
  gmoOffPaymentOnSitePaymentDetailType_constraint: GmoOffPaymentOnSitePaymentDetailTypeConstraint;
  gmoOffPaymentOnSitePaymentDetailType_insert_input: GmoOffPaymentOnSitePaymentDetailTypeInsertInput;
  gmoOffPaymentOnSitePaymentDetailType_max_order_by: GmoOffPaymentOnSitePaymentDetailTypeMaxOrderBy;
  gmoOffPaymentOnSitePaymentDetailType_min_order_by: GmoOffPaymentOnSitePaymentDetailTypeMinOrderBy;
  gmoOffPaymentOnSitePaymentDetailType_mutation_response: ResolverTypeWrapper<GmoOffPaymentOnSitePaymentDetailTypeMutationResponse>;
  gmoOffPaymentOnSitePaymentDetailType_on_conflict: GmoOffPaymentOnSitePaymentDetailTypeOnConflict;
  gmoOffPaymentOnSitePaymentDetailType_order_by: GmoOffPaymentOnSitePaymentDetailTypeOrderBy;
  gmoOffPaymentOnSitePaymentDetailType_select_column: GmoOffPaymentOnSitePaymentDetailTypeSelectColumn;
  gmoOffPaymentOnSitePaymentDetailType_stream_cursor_input: GmoOffPaymentOnSitePaymentDetailTypeStreamCursorInput;
  gmoOffPaymentOnSitePaymentDetailType_stream_cursor_value_input: GmoOffPaymentOnSitePaymentDetailTypeStreamCursorValueInput;
  gmoOffPaymentOnSitePaymentDetailType_update_column: GmoOffPaymentOnSitePaymentDetailTypeUpdateColumn;
  gmoOffPaymentShopConfig: ResolverTypeWrapper<GmoOffPaymentShopConfig>;
  gmoOffPaymentShopConfigTransactionFeeRate: ResolverTypeWrapper<GmoOffPaymentShopConfigTransactionFeeRate>;
  gmoOffPaymentShopConfigTransactionFeeRate_aggregate_order_by: GmoOffPaymentShopConfigTransactionFeeRateAggregateOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_arr_rel_insert_input: GmoOffPaymentShopConfigTransactionFeeRateArrRelInsertInput;
  gmoOffPaymentShopConfigTransactionFeeRate_avg_order_by: GmoOffPaymentShopConfigTransactionFeeRateAvgOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_bool_exp: GmoOffPaymentShopConfigTransactionFeeRateBoolExp;
  gmoOffPaymentShopConfigTransactionFeeRate_constraint: GmoOffPaymentShopConfigTransactionFeeRateConstraint;
  gmoOffPaymentShopConfigTransactionFeeRate_inc_input: GmoOffPaymentShopConfigTransactionFeeRateIncInput;
  gmoOffPaymentShopConfigTransactionFeeRate_insert_input: GmoOffPaymentShopConfigTransactionFeeRateInsertInput;
  gmoOffPaymentShopConfigTransactionFeeRate_max_order_by: GmoOffPaymentShopConfigTransactionFeeRateMaxOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_min_order_by: GmoOffPaymentShopConfigTransactionFeeRateMinOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_mutation_response: ResolverTypeWrapper<GmoOffPaymentShopConfigTransactionFeeRateMutationResponse>;
  gmoOffPaymentShopConfigTransactionFeeRate_on_conflict: GmoOffPaymentShopConfigTransactionFeeRateOnConflict;
  gmoOffPaymentShopConfigTransactionFeeRate_order_by: GmoOffPaymentShopConfigTransactionFeeRateOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_pk_columns_input: GmoOffPaymentShopConfigTransactionFeeRatePkColumnsInput;
  gmoOffPaymentShopConfigTransactionFeeRate_select_column: GmoOffPaymentShopConfigTransactionFeeRateSelectColumn;
  gmoOffPaymentShopConfigTransactionFeeRate_set_input: GmoOffPaymentShopConfigTransactionFeeRateSetInput;
  gmoOffPaymentShopConfigTransactionFeeRate_stddev_order_by: GmoOffPaymentShopConfigTransactionFeeRateStddevOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_stddev_pop_order_by: GmoOffPaymentShopConfigTransactionFeeRateStddevPopOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_stddev_samp_order_by: GmoOffPaymentShopConfigTransactionFeeRateStddevSampOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_stream_cursor_input: GmoOffPaymentShopConfigTransactionFeeRateStreamCursorInput;
  gmoOffPaymentShopConfigTransactionFeeRate_stream_cursor_value_input: GmoOffPaymentShopConfigTransactionFeeRateStreamCursorValueInput;
  gmoOffPaymentShopConfigTransactionFeeRate_sum_order_by: GmoOffPaymentShopConfigTransactionFeeRateSumOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_update_column: GmoOffPaymentShopConfigTransactionFeeRateUpdateColumn;
  gmoOffPaymentShopConfigTransactionFeeRate_updates: GmoOffPaymentShopConfigTransactionFeeRateUpdates;
  gmoOffPaymentShopConfigTransactionFeeRate_var_pop_order_by: GmoOffPaymentShopConfigTransactionFeeRateVarPopOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_var_samp_order_by: GmoOffPaymentShopConfigTransactionFeeRateVarSampOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_variance_order_by: GmoOffPaymentShopConfigTransactionFeeRateVarianceOrderBy;
  gmoOffPaymentShopConfig_bool_exp: GmoOffPaymentShopConfigBoolExp;
  gmoOffPaymentShopConfig_constraint: GmoOffPaymentShopConfigConstraint;
  gmoOffPaymentShopConfig_insert_input: GmoOffPaymentShopConfigInsertInput;
  gmoOffPaymentShopConfig_mutation_response: ResolverTypeWrapper<GmoOffPaymentShopConfigMutationResponse>;
  gmoOffPaymentShopConfig_obj_rel_insert_input: GmoOffPaymentShopConfigObjRelInsertInput;
  gmoOffPaymentShopConfig_on_conflict: GmoOffPaymentShopConfigOnConflict;
  gmoOffPaymentShopConfig_order_by: GmoOffPaymentShopConfigOrderBy;
  gmoOffPaymentShopConfig_pk_columns_input: GmoOffPaymentShopConfigPkColumnsInput;
  gmoOffPaymentShopConfig_select_column: GmoOffPaymentShopConfigSelectColumn;
  gmoOffPaymentShopConfig_set_input: GmoOffPaymentShopConfigSetInput;
  gmoOffPaymentShopConfig_stream_cursor_input: GmoOffPaymentShopConfigStreamCursorInput;
  gmoOffPaymentShopConfig_stream_cursor_value_input: GmoOffPaymentShopConfigStreamCursorValueInput;
  gmoOffPaymentShopConfig_update_column: GmoOffPaymentShopConfigUpdateColumn;
  gmoOffPaymentShopConfig_updates: GmoOffPaymentShopConfigUpdates;
  inflowSourceTag: ResolverTypeWrapper<InflowSourceTag>;
  inflowSourceTag_aggregate: ResolverTypeWrapper<InflowSourceTagAggregate>;
  inflowSourceTag_aggregate_bool_exp: InflowSourceTagAggregateBoolExp;
  inflowSourceTag_aggregate_bool_exp_bool_and: InflowSourceTagAggregateBoolExpBoolAnd;
  inflowSourceTag_aggregate_bool_exp_bool_or: InflowSourceTagAggregateBoolExpBoolOr;
  inflowSourceTag_aggregate_bool_exp_count: InflowSourceTagAggregateBoolExpCount;
  inflowSourceTag_aggregate_fields: ResolverTypeWrapper<InflowSourceTagAggregateFields>;
  inflowSourceTag_aggregate_order_by: InflowSourceTagAggregateOrderBy;
  inflowSourceTag_arr_rel_insert_input: InflowSourceTagArrRelInsertInput;
  inflowSourceTag_avg_fields: ResolverTypeWrapper<InflowSourceTagAvgFields>;
  inflowSourceTag_avg_order_by: InflowSourceTagAvgOrderBy;
  inflowSourceTag_bool_exp: InflowSourceTagBoolExp;
  inflowSourceTag_constraint: InflowSourceTagConstraint;
  inflowSourceTag_inc_input: InflowSourceTagIncInput;
  inflowSourceTag_insert_input: InflowSourceTagInsertInput;
  inflowSourceTag_max_fields: ResolverTypeWrapper<InflowSourceTagMaxFields>;
  inflowSourceTag_max_order_by: InflowSourceTagMaxOrderBy;
  inflowSourceTag_min_fields: ResolverTypeWrapper<InflowSourceTagMinFields>;
  inflowSourceTag_min_order_by: InflowSourceTagMinOrderBy;
  inflowSourceTag_mutation_response: ResolverTypeWrapper<InflowSourceTagMutationResponse>;
  inflowSourceTag_obj_rel_insert_input: InflowSourceTagObjRelInsertInput;
  inflowSourceTag_on_conflict: InflowSourceTagOnConflict;
  inflowSourceTag_order_by: InflowSourceTagOrderBy;
  inflowSourceTag_pk_columns_input: InflowSourceTagPkColumnsInput;
  inflowSourceTag_select_column: InflowSourceTagSelectColumn;
  inflowSourceTag_select_column_inflowSourceTag_aggregate_bool_exp_bool_and_arguments_columns: InflowSourceTagSelectColumnInflowSourceTagAggregateBoolExpBoolAndArgumentsColumnsType;
  inflowSourceTag_select_column_inflowSourceTag_aggregate_bool_exp_bool_or_arguments_columns: InflowSourceTagSelectColumnInflowSourceTagAggregateBoolExpBoolOrArgumentsColumnsType;
  inflowSourceTag_set_input: InflowSourceTagSetInput;
  inflowSourceTag_stddev_fields: ResolverTypeWrapper<InflowSourceTagStddevFields>;
  inflowSourceTag_stddev_order_by: InflowSourceTagStddevOrderBy;
  inflowSourceTag_stddev_pop_fields: ResolverTypeWrapper<InflowSourceTagStddevPopFields>;
  inflowSourceTag_stddev_pop_order_by: InflowSourceTagStddevPopOrderBy;
  inflowSourceTag_stddev_samp_fields: ResolverTypeWrapper<InflowSourceTagStddevSampFields>;
  inflowSourceTag_stddev_samp_order_by: InflowSourceTagStddevSampOrderBy;
  inflowSourceTag_stream_cursor_input: InflowSourceTagStreamCursorInput;
  inflowSourceTag_stream_cursor_value_input: InflowSourceTagStreamCursorValueInput;
  inflowSourceTag_sum_fields: ResolverTypeWrapper<InflowSourceTagSumFields>;
  inflowSourceTag_sum_order_by: InflowSourceTagSumOrderBy;
  inflowSourceTag_update_column: InflowSourceTagUpdateColumn;
  inflowSourceTag_updates: InflowSourceTagUpdates;
  inflowSourceTag_var_pop_fields: ResolverTypeWrapper<InflowSourceTagVarPopFields>;
  inflowSourceTag_var_pop_order_by: InflowSourceTagVarPopOrderBy;
  inflowSourceTag_var_samp_fields: ResolverTypeWrapper<InflowSourceTagVarSampFields>;
  inflowSourceTag_var_samp_order_by: InflowSourceTagVarSampOrderBy;
  inflowSourceTag_variance_fields: ResolverTypeWrapper<InflowSourceTagVarianceFields>;
  inflowSourceTag_variance_order_by: InflowSourceTagVarianceOrderBy;
  itemSales: ResolverTypeWrapper<ItemSales>;
  itemSales_bool_exp: ItemSalesBoolExp;
  itemSales_order_by: ItemSalesOrderBy;
  itemSales_select_column: ItemSalesSelectColumn;
  itemSales_stream_cursor_input: ItemSalesStreamCursorInput;
  itemSales_stream_cursor_value_input: ItemSalesStreamCursorValueInput;
  jsonb: ResolverTypeWrapper<Scalars['jsonb']>;
  jsonb_cast_exp: JsonbCastExp;
  jsonb_comparison_exp: JsonbComparisonExp;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget: ResolverTypeWrapper<KdDisplayItemKdDisplayTargetsKdDisplayTarget>;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_aggregate_order_by: KdDisplayItemKdDisplayTargetsKdDisplayTargetAggregateOrderBy;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_bool_exp: KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_max_order_by: KdDisplayItemKdDisplayTargetsKdDisplayTargetMaxOrderBy;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_min_order_by: KdDisplayItemKdDisplayTargetsKdDisplayTargetMinOrderBy;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_mutation_response: ResolverTypeWrapper<KdDisplayItemKdDisplayTargetsKdDisplayTargetMutationResponse>;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_order_by: KdDisplayItemKdDisplayTargetsKdDisplayTargetOrderBy;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_select_column: KdDisplayItemKdDisplayTargetsKdDisplayTargetSelectColumn;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_stream_cursor_input: KdDisplayItemKdDisplayTargetsKdDisplayTargetStreamCursorInput;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_stream_cursor_value_input: KdDisplayItemKdDisplayTargetsKdDisplayTargetStreamCursorValueInput;
  kdDisplayTarget: ResolverTypeWrapper<KdDisplayTarget>;
  kdDisplayTarget_aggregate_order_by: KdDisplayTargetAggregateOrderBy;
  kdDisplayTarget_arr_rel_insert_input: KdDisplayTargetArrRelInsertInput;
  kdDisplayTarget_avg_order_by: KdDisplayTargetAvgOrderBy;
  kdDisplayTarget_bool_exp: KdDisplayTargetBoolExp;
  kdDisplayTarget_constraint: KdDisplayTargetConstraint;
  kdDisplayTarget_inc_input: KdDisplayTargetIncInput;
  kdDisplayTarget_insert_input: KdDisplayTargetInsertInput;
  kdDisplayTarget_max_order_by: KdDisplayTargetMaxOrderBy;
  kdDisplayTarget_min_order_by: KdDisplayTargetMinOrderBy;
  kdDisplayTarget_mutation_response: ResolverTypeWrapper<KdDisplayTargetMutationResponse>;
  kdDisplayTarget_obj_rel_insert_input: KdDisplayTargetObjRelInsertInput;
  kdDisplayTarget_on_conflict: KdDisplayTargetOnConflict;
  kdDisplayTarget_order_by: KdDisplayTargetOrderBy;
  kdDisplayTarget_pk_columns_input: KdDisplayTargetPkColumnsInput;
  kdDisplayTarget_select_column: KdDisplayTargetSelectColumn;
  kdDisplayTarget_set_input: KdDisplayTargetSetInput;
  kdDisplayTarget_stddev_order_by: KdDisplayTargetStddevOrderBy;
  kdDisplayTarget_stddev_pop_order_by: KdDisplayTargetStddevPopOrderBy;
  kdDisplayTarget_stddev_samp_order_by: KdDisplayTargetStddevSampOrderBy;
  kdDisplayTarget_stream_cursor_input: KdDisplayTargetStreamCursorInput;
  kdDisplayTarget_stream_cursor_value_input: KdDisplayTargetStreamCursorValueInput;
  kdDisplayTarget_sum_order_by: KdDisplayTargetSumOrderBy;
  kdDisplayTarget_update_column: KdDisplayTargetUpdateColumn;
  kdDisplayTarget_updates: KdDisplayTargetUpdates;
  kdDisplayTarget_var_pop_order_by: KdDisplayTargetVarPopOrderBy;
  kdDisplayTarget_var_samp_order_by: KdDisplayTargetVarSampOrderBy;
  kdDisplayTarget_variance_order_by: KdDisplayTargetVarianceOrderBy;
  lineChannelConfig: ResolverTypeWrapper<LineChannelConfig>;
  lineChannelConfig_bool_exp: LineChannelConfigBoolExp;
  lineChannelConfig_constraint: LineChannelConfigConstraint;
  lineChannelConfig_insert_input: LineChannelConfigInsertInput;
  lineChannelConfig_mutation_response: ResolverTypeWrapper<LineChannelConfigMutationResponse>;
  lineChannelConfig_obj_rel_insert_input: LineChannelConfigObjRelInsertInput;
  lineChannelConfig_on_conflict: LineChannelConfigOnConflict;
  lineChannelConfig_order_by: LineChannelConfigOrderBy;
  lineChannelConfig_pk_columns_input: LineChannelConfigPkColumnsInput;
  lineChannelConfig_select_column: LineChannelConfigSelectColumn;
  lineChannelConfig_set_input: LineChannelConfigSetInput;
  lineChannelConfig_stream_cursor_input: LineChannelConfigStreamCursorInput;
  lineChannelConfig_stream_cursor_value_input: LineChannelConfigStreamCursorValueInput;
  lineChannelConfig_update_column: LineChannelConfigUpdateColumn;
  lineChannelConfig_updates: LineChannelConfigUpdates;
  lineOfficialAccount: ResolverTypeWrapper<LineOfficialAccount>;
  lineOfficialAccount_aggregate_order_by: LineOfficialAccountAggregateOrderBy;
  lineOfficialAccount_avg_order_by: LineOfficialAccountAvgOrderBy;
  lineOfficialAccount_bool_exp: LineOfficialAccountBoolExp;
  lineOfficialAccount_max_order_by: LineOfficialAccountMaxOrderBy;
  lineOfficialAccount_min_order_by: LineOfficialAccountMinOrderBy;
  lineOfficialAccount_order_by: LineOfficialAccountOrderBy;
  lineOfficialAccount_select_column: LineOfficialAccountSelectColumn;
  lineOfficialAccount_stddev_order_by: LineOfficialAccountStddevOrderBy;
  lineOfficialAccount_stddev_pop_order_by: LineOfficialAccountStddevPopOrderBy;
  lineOfficialAccount_stddev_samp_order_by: LineOfficialAccountStddevSampOrderBy;
  lineOfficialAccount_stream_cursor_input: LineOfficialAccountStreamCursorInput;
  lineOfficialAccount_stream_cursor_value_input: LineOfficialAccountStreamCursorValueInput;
  lineOfficialAccount_sum_order_by: LineOfficialAccountSumOrderBy;
  lineOfficialAccount_var_pop_order_by: LineOfficialAccountVarPopOrderBy;
  lineOfficialAccount_var_samp_order_by: LineOfficialAccountVarSampOrderBy;
  lineOfficialAccount_variance_order_by: LineOfficialAccountVarianceOrderBy;
  lineProfile: ResolverTypeWrapper<LineProfile>;
  lineProfile_bool_exp: LineProfileBoolExp;
  lineProfile_order_by: LineProfileOrderBy;
  lineProfile_select_column: LineProfileSelectColumn;
  lineProfile_stream_cursor_input: LineProfileStreamCursorInput;
  lineProfile_stream_cursor_value_input: LineProfileStreamCursorValueInput;
  lineReportingBotConfig: ResolverTypeWrapper<LineReportingBotConfig>;
  lineReportingBotConfigShop: ResolverTypeWrapper<LineReportingBotConfigShop>;
  lineReportingBotConfigShop_aggregate_order_by: LineReportingBotConfigShopAggregateOrderBy;
  lineReportingBotConfigShop_bool_exp: LineReportingBotConfigShopBoolExp;
  lineReportingBotConfigShop_constraint: LineReportingBotConfigShopConstraint;
  lineReportingBotConfigShop_insert_input: LineReportingBotConfigShopInsertInput;
  lineReportingBotConfigShop_max_order_by: LineReportingBotConfigShopMaxOrderBy;
  lineReportingBotConfigShop_min_order_by: LineReportingBotConfigShopMinOrderBy;
  lineReportingBotConfigShop_mutation_response: ResolverTypeWrapper<LineReportingBotConfigShopMutationResponse>;
  lineReportingBotConfigShop_on_conflict: LineReportingBotConfigShopOnConflict;
  lineReportingBotConfigShop_order_by: LineReportingBotConfigShopOrderBy;
  lineReportingBotConfigShop_select_column: LineReportingBotConfigShopSelectColumn;
  lineReportingBotConfigShop_stream_cursor_input: LineReportingBotConfigShopStreamCursorInput;
  lineReportingBotConfigShop_stream_cursor_value_input: LineReportingBotConfigShopStreamCursorValueInput;
  lineReportingBotConfigShop_update_column: LineReportingBotConfigShopUpdateColumn;
  lineReportingBotConfig_bool_exp: LineReportingBotConfigBoolExp;
  lineReportingBotConfig_mutation_response: ResolverTypeWrapper<LineReportingBotConfigMutationResponse>;
  lineReportingBotConfig_order_by: LineReportingBotConfigOrderBy;
  lineReportingBotConfig_pk_columns_input: LineReportingBotConfigPkColumnsInput;
  lineReportingBotConfig_select_column: LineReportingBotConfigSelectColumn;
  lineReportingBotConfig_set_input: LineReportingBotConfigSetInput;
  lineReportingBotConfig_stream_cursor_input: LineReportingBotConfigStreamCursorInput;
  lineReportingBotConfig_stream_cursor_value_input: LineReportingBotConfigStreamCursorValueInput;
  lineReportingBotConfig_updates: LineReportingBotConfigUpdates;
  lineReportingBotVerification: ResolverTypeWrapper<LineReportingBotVerification>;
  lineReportingBotVerification_bool_exp: LineReportingBotVerificationBoolExp;
  lineReportingBotVerification_order_by: LineReportingBotVerificationOrderBy;
  lineReportingBotVerification_select_column: LineReportingBotVerificationSelectColumn;
  lineReportingBotVerification_stream_cursor_input: LineReportingBotVerificationStreamCursorInput;
  lineReportingBotVerification_stream_cursor_value_input: LineReportingBotVerificationStreamCursorValueInput;
  membershipCard: ResolverTypeWrapper<MembershipCard>;
  membershipCardAppearanceType: ResolverTypeWrapper<MembershipCardAppearanceType>;
  membershipCardAppearanceType_bool_exp: MembershipCardAppearanceTypeBoolExp;
  membershipCardAppearanceType_enum: MembershipCardAppearanceTypeEnum;
  membershipCardAppearanceType_enum_comparison_exp: MembershipCardAppearanceTypeEnumComparisonExp;
  membershipCardAppearanceType_order_by: MembershipCardAppearanceTypeOrderBy;
  membershipCardAppearanceType_select_column: MembershipCardAppearanceTypeSelectColumn;
  membershipCardAppearanceType_stream_cursor_input: MembershipCardAppearanceTypeStreamCursorInput;
  membershipCardAppearanceType_stream_cursor_value_input: MembershipCardAppearanceTypeStreamCursorValueInput;
  membershipCardTitle: ResolverTypeWrapper<MembershipCardTitle>;
  membershipCardTitle_aggregate_order_by: MembershipCardTitleAggregateOrderBy;
  membershipCardTitle_arr_rel_insert_input: MembershipCardTitleArrRelInsertInput;
  membershipCardTitle_avg_order_by: MembershipCardTitleAvgOrderBy;
  membershipCardTitle_bool_exp: MembershipCardTitleBoolExp;
  membershipCardTitle_constraint: MembershipCardTitleConstraint;
  membershipCardTitle_inc_input: MembershipCardTitleIncInput;
  membershipCardTitle_insert_input: MembershipCardTitleInsertInput;
  membershipCardTitle_max_order_by: MembershipCardTitleMaxOrderBy;
  membershipCardTitle_min_order_by: MembershipCardTitleMinOrderBy;
  membershipCardTitle_mutation_response: ResolverTypeWrapper<MembershipCardTitleMutationResponse>;
  membershipCardTitle_on_conflict: MembershipCardTitleOnConflict;
  membershipCardTitle_order_by: MembershipCardTitleOrderBy;
  membershipCardTitle_pk_columns_input: MembershipCardTitlePkColumnsInput;
  membershipCardTitle_select_column: MembershipCardTitleSelectColumn;
  membershipCardTitle_set_input: MembershipCardTitleSetInput;
  membershipCardTitle_stddev_order_by: MembershipCardTitleStddevOrderBy;
  membershipCardTitle_stddev_pop_order_by: MembershipCardTitleStddevPopOrderBy;
  membershipCardTitle_stddev_samp_order_by: MembershipCardTitleStddevSampOrderBy;
  membershipCardTitle_stream_cursor_input: MembershipCardTitleStreamCursorInput;
  membershipCardTitle_stream_cursor_value_input: MembershipCardTitleStreamCursorValueInput;
  membershipCardTitle_sum_order_by: MembershipCardTitleSumOrderBy;
  membershipCardTitle_update_column: MembershipCardTitleUpdateColumn;
  membershipCardTitle_updates: MembershipCardTitleUpdates;
  membershipCardTitle_var_pop_order_by: MembershipCardTitleVarPopOrderBy;
  membershipCardTitle_var_samp_order_by: MembershipCardTitleVarSampOrderBy;
  membershipCardTitle_variance_order_by: MembershipCardTitleVarianceOrderBy;
  membershipCard_aggregate_order_by: MembershipCardAggregateOrderBy;
  membershipCard_bool_exp: MembershipCardBoolExp;
  membershipCard_constraint: MembershipCardConstraint;
  membershipCard_insert_input: MembershipCardInsertInput;
  membershipCard_max_order_by: MembershipCardMaxOrderBy;
  membershipCard_min_order_by: MembershipCardMinOrderBy;
  membershipCard_mutation_response: ResolverTypeWrapper<MembershipCardMutationResponse>;
  membershipCard_obj_rel_insert_input: MembershipCardObjRelInsertInput;
  membershipCard_on_conflict: MembershipCardOnConflict;
  membershipCard_order_by: MembershipCardOrderBy;
  membershipCard_pk_columns_input: MembershipCardPkColumnsInput;
  membershipCard_select_column: MembershipCardSelectColumn;
  membershipCard_set_input: MembershipCardSetInput;
  membershipCard_stream_cursor_input: MembershipCardStreamCursorInput;
  membershipCard_stream_cursor_value_input: MembershipCardStreamCursorValueInput;
  membershipCard_update_column: MembershipCardUpdateColumn;
  membershipCard_updates: MembershipCardUpdates;
  membershipRank: ResolverTypeWrapper<MembershipRank>;
  membershipRankConfig: ResolverTypeWrapper<MembershipRankConfig>;
  membershipRankConfig_aggregate_order_by: MembershipRankConfigAggregateOrderBy;
  membershipRankConfig_avg_order_by: MembershipRankConfigAvgOrderBy;
  membershipRankConfig_bool_exp: MembershipRankConfigBoolExp;
  membershipRankConfig_inc_input: MembershipRankConfigIncInput;
  membershipRankConfig_max_order_by: MembershipRankConfigMaxOrderBy;
  membershipRankConfig_min_order_by: MembershipRankConfigMinOrderBy;
  membershipRankConfig_mutation_response: ResolverTypeWrapper<MembershipRankConfigMutationResponse>;
  membershipRankConfig_order_by: MembershipRankConfigOrderBy;
  membershipRankConfig_pk_columns_input: MembershipRankConfigPkColumnsInput;
  membershipRankConfig_select_column: MembershipRankConfigSelectColumn;
  membershipRankConfig_set_input: MembershipRankConfigSetInput;
  membershipRankConfig_stddev_order_by: MembershipRankConfigStddevOrderBy;
  membershipRankConfig_stddev_pop_order_by: MembershipRankConfigStddevPopOrderBy;
  membershipRankConfig_stddev_samp_order_by: MembershipRankConfigStddevSampOrderBy;
  membershipRankConfig_stream_cursor_input: MembershipRankConfigStreamCursorInput;
  membershipRankConfig_stream_cursor_value_input: MembershipRankConfigStreamCursorValueInput;
  membershipRankConfig_sum_order_by: MembershipRankConfigSumOrderBy;
  membershipRankConfig_updates: MembershipRankConfigUpdates;
  membershipRankConfig_var_pop_order_by: MembershipRankConfigVarPopOrderBy;
  membershipRankConfig_var_samp_order_by: MembershipRankConfigVarSampOrderBy;
  membershipRankConfig_variance_order_by: MembershipRankConfigVarianceOrderBy;
  membershipRank_bool_exp: MembershipRankBoolExp;
  membershipRank_enum: MembershipRankEnum;
  membershipRank_enum_comparison_exp: MembershipRankEnumComparisonExp;
  membershipRank_order_by: MembershipRankOrderBy;
  membershipRank_select_column: MembershipRankSelectColumn;
  membershipRank_stream_cursor_input: MembershipRankStreamCursorInput;
  membershipRank_stream_cursor_value_input: MembershipRankStreamCursorValueInput;
  menu: ResolverTypeWrapper<Menu>;
  menuOption: ResolverTypeWrapper<MenuOption>;
  menuOption_aggregate_order_by: MenuOptionAggregateOrderBy;
  menuOption_arr_rel_insert_input: MenuOptionArrRelInsertInput;
  menuOption_avg_order_by: MenuOptionAvgOrderBy;
  menuOption_bool_exp: MenuOptionBoolExp;
  menuOption_constraint: MenuOptionConstraint;
  menuOption_inc_input: MenuOptionIncInput;
  menuOption_insert_input: MenuOptionInsertInput;
  menuOption_max_order_by: MenuOptionMaxOrderBy;
  menuOption_min_order_by: MenuOptionMinOrderBy;
  menuOption_mutation_response: ResolverTypeWrapper<MenuOptionMutationResponse>;
  menuOption_on_conflict: MenuOptionOnConflict;
  menuOption_order_by: MenuOptionOrderBy;
  menuOption_pk_columns_input: MenuOptionPkColumnsInput;
  menuOption_select_column: MenuOptionSelectColumn;
  menuOption_set_input: MenuOptionSetInput;
  menuOption_stddev_order_by: MenuOptionStddevOrderBy;
  menuOption_stddev_pop_order_by: MenuOptionStddevPopOrderBy;
  menuOption_stddev_samp_order_by: MenuOptionStddevSampOrderBy;
  menuOption_stream_cursor_input: MenuOptionStreamCursorInput;
  menuOption_stream_cursor_value_input: MenuOptionStreamCursorValueInput;
  menuOption_sum_order_by: MenuOptionSumOrderBy;
  menuOption_update_column: MenuOptionUpdateColumn;
  menuOption_updates: MenuOptionUpdates;
  menuOption_var_pop_order_by: MenuOptionVarPopOrderBy;
  menuOption_var_samp_order_by: MenuOptionVarSampOrderBy;
  menuOption_variance_order_by: MenuOptionVarianceOrderBy;
  menuOrderItem: ResolverTypeWrapper<MenuOrderItem>;
  menuOrderItemChoice: ResolverTypeWrapper<MenuOrderItemChoice>;
  menuOrderItemChoice_aggregate_order_by: MenuOrderItemChoiceAggregateOrderBy;
  menuOrderItemChoice_avg_order_by: MenuOrderItemChoiceAvgOrderBy;
  menuOrderItemChoice_bool_exp: MenuOrderItemChoiceBoolExp;
  menuOrderItemChoice_max_order_by: MenuOrderItemChoiceMaxOrderBy;
  menuOrderItemChoice_min_order_by: MenuOrderItemChoiceMinOrderBy;
  menuOrderItemChoice_order_by: MenuOrderItemChoiceOrderBy;
  menuOrderItemChoice_select_column: MenuOrderItemChoiceSelectColumn;
  menuOrderItemChoice_stddev_order_by: MenuOrderItemChoiceStddevOrderBy;
  menuOrderItemChoice_stddev_pop_order_by: MenuOrderItemChoiceStddevPopOrderBy;
  menuOrderItemChoice_stddev_samp_order_by: MenuOrderItemChoiceStddevSampOrderBy;
  menuOrderItemChoice_stream_cursor_input: MenuOrderItemChoiceStreamCursorInput;
  menuOrderItemChoice_stream_cursor_value_input: MenuOrderItemChoiceStreamCursorValueInput;
  menuOrderItemChoice_sum_order_by: MenuOrderItemChoiceSumOrderBy;
  menuOrderItemChoice_var_pop_order_by: MenuOrderItemChoiceVarPopOrderBy;
  menuOrderItemChoice_var_samp_order_by: MenuOrderItemChoiceVarSampOrderBy;
  menuOrderItemChoice_variance_order_by: MenuOrderItemChoiceVarianceOrderBy;
  menuOrderItemOption: ResolverTypeWrapper<MenuOrderItemOption>;
  menuOrderItemOption_aggregate_order_by: MenuOrderItemOptionAggregateOrderBy;
  menuOrderItemOption_avg_order_by: MenuOrderItemOptionAvgOrderBy;
  menuOrderItemOption_bool_exp: MenuOrderItemOptionBoolExp;
  menuOrderItemOption_max_order_by: MenuOrderItemOptionMaxOrderBy;
  menuOrderItemOption_min_order_by: MenuOrderItemOptionMinOrderBy;
  menuOrderItemOption_order_by: MenuOrderItemOptionOrderBy;
  menuOrderItemOption_select_column: MenuOrderItemOptionSelectColumn;
  menuOrderItemOption_stddev_order_by: MenuOrderItemOptionStddevOrderBy;
  menuOrderItemOption_stddev_pop_order_by: MenuOrderItemOptionStddevPopOrderBy;
  menuOrderItemOption_stddev_samp_order_by: MenuOrderItemOptionStddevSampOrderBy;
  menuOrderItemOption_stream_cursor_input: MenuOrderItemOptionStreamCursorInput;
  menuOrderItemOption_stream_cursor_value_input: MenuOrderItemOptionStreamCursorValueInput;
  menuOrderItemOption_sum_order_by: MenuOrderItemOptionSumOrderBy;
  menuOrderItemOption_var_pop_order_by: MenuOrderItemOptionVarPopOrderBy;
  menuOrderItemOption_var_samp_order_by: MenuOrderItemOptionVarSampOrderBy;
  menuOrderItemOption_variance_order_by: MenuOrderItemOptionVarianceOrderBy;
  menuOrderItem_aggregate_order_by: MenuOrderItemAggregateOrderBy;
  menuOrderItem_avg_order_by: MenuOrderItemAvgOrderBy;
  menuOrderItem_bool_exp: MenuOrderItemBoolExp;
  menuOrderItem_max_order_by: MenuOrderItemMaxOrderBy;
  menuOrderItem_min_order_by: MenuOrderItemMinOrderBy;
  menuOrderItem_order_by: MenuOrderItemOrderBy;
  menuOrderItem_select_column: MenuOrderItemSelectColumn;
  menuOrderItem_stddev_order_by: MenuOrderItemStddevOrderBy;
  menuOrderItem_stddev_pop_order_by: MenuOrderItemStddevPopOrderBy;
  menuOrderItem_stddev_samp_order_by: MenuOrderItemStddevSampOrderBy;
  menuOrderItem_stream_cursor_input: MenuOrderItemStreamCursorInput;
  menuOrderItem_stream_cursor_value_input: MenuOrderItemStreamCursorValueInput;
  menuOrderItem_sum_order_by: MenuOrderItemSumOrderBy;
  menuOrderItem_var_pop_order_by: MenuOrderItemVarPopOrderBy;
  menuOrderItem_var_samp_order_by: MenuOrderItemVarSampOrderBy;
  menuOrderItem_variance_order_by: MenuOrderItemVarianceOrderBy;
  menuRecommendationMeta: ResolverTypeWrapper<MenuRecommendationMeta>;
  menuRecommendationMeta_bool_exp: MenuRecommendationMetaBoolExp;
  menuRecommendationMeta_constraint: MenuRecommendationMetaConstraint;
  menuRecommendationMeta_inc_input: MenuRecommendationMetaIncInput;
  menuRecommendationMeta_insert_input: MenuRecommendationMetaInsertInput;
  menuRecommendationMeta_mutation_response: ResolverTypeWrapper<MenuRecommendationMetaMutationResponse>;
  menuRecommendationMeta_obj_rel_insert_input: MenuRecommendationMetaObjRelInsertInput;
  menuRecommendationMeta_on_conflict: MenuRecommendationMetaOnConflict;
  menuRecommendationMeta_order_by: MenuRecommendationMetaOrderBy;
  menuRecommendationMeta_pk_columns_input: MenuRecommendationMetaPkColumnsInput;
  menuRecommendationMeta_select_column: MenuRecommendationMetaSelectColumn;
  menuRecommendationMeta_set_input: MenuRecommendationMetaSetInput;
  menuRecommendationMeta_stream_cursor_input: MenuRecommendationMetaStreamCursorInput;
  menuRecommendationMeta_stream_cursor_value_input: MenuRecommendationMetaStreamCursorValueInput;
  menuRecommendationMeta_update_column: MenuRecommendationMetaUpdateColumn;
  menuRecommendationMeta_updates: MenuRecommendationMetaUpdates;
  menuType_enum: MenuTypeEnum;
  menuType_enum_comparison_exp: MenuTypeEnumComparisonExp;
  menu_aggregate_order_by: MenuAggregateOrderBy;
  menu_arr_rel_insert_input: MenuArrRelInsertInput;
  menu_avg_order_by: MenuAvgOrderBy;
  menu_bool_exp: MenuBoolExp;
  menu_constraint: MenuConstraint;
  menu_inc_input: MenuIncInput;
  menu_insert_input: MenuInsertInput;
  menu_max_order_by: MenuMaxOrderBy;
  menu_min_order_by: MenuMinOrderBy;
  menu_mutation_response: ResolverTypeWrapper<MenuMutationResponse>;
  menu_obj_rel_insert_input: MenuObjRelInsertInput;
  menu_on_conflict: MenuOnConflict;
  menu_order_by: MenuOrderBy;
  menu_pk_columns_input: MenuPkColumnsInput;
  menu_select_column: MenuSelectColumn;
  menu_set_input: MenuSetInput;
  menu_stddev_order_by: MenuStddevOrderBy;
  menu_stddev_pop_order_by: MenuStddevPopOrderBy;
  menu_stddev_samp_order_by: MenuStddevSampOrderBy;
  menu_stream_cursor_input: MenuStreamCursorInput;
  menu_stream_cursor_value_input: MenuStreamCursorValueInput;
  menu_sum_order_by: MenuSumOrderBy;
  menu_update_column: MenuUpdateColumn;
  menu_updates: MenuUpdates;
  menu_var_pop_order_by: MenuVarPopOrderBy;
  menu_var_samp_order_by: MenuVarSampOrderBy;
  menu_variance_order_by: MenuVarianceOrderBy;
  messageDelivery: ResolverTypeWrapper<MessageDelivery>;
  messageDeliveryCustomerList: ResolverTypeWrapper<MessageDeliveryCustomerList>;
  messageDeliveryCustomerListCustomer: ResolverTypeWrapper<MessageDeliveryCustomerListCustomer>;
  messageDeliveryCustomerListCustomer_aggregate: ResolverTypeWrapper<MessageDeliveryCustomerListCustomerAggregate>;
  messageDeliveryCustomerListCustomer_aggregate_bool_exp: MessageDeliveryCustomerListCustomerAggregateBoolExp;
  messageDeliveryCustomerListCustomer_aggregate_bool_exp_count: MessageDeliveryCustomerListCustomerAggregateBoolExpCount;
  messageDeliveryCustomerListCustomer_aggregate_fields: ResolverTypeWrapper<MessageDeliveryCustomerListCustomerAggregateFields>;
  messageDeliveryCustomerListCustomer_aggregate_order_by: MessageDeliveryCustomerListCustomerAggregateOrderBy;
  messageDeliveryCustomerListCustomer_bool_exp: MessageDeliveryCustomerListCustomerBoolExp;
  messageDeliveryCustomerListCustomer_max_fields: ResolverTypeWrapper<MessageDeliveryCustomerListCustomerMaxFields>;
  messageDeliveryCustomerListCustomer_max_order_by: MessageDeliveryCustomerListCustomerMaxOrderBy;
  messageDeliveryCustomerListCustomer_min_fields: ResolverTypeWrapper<MessageDeliveryCustomerListCustomerMinFields>;
  messageDeliveryCustomerListCustomer_min_order_by: MessageDeliveryCustomerListCustomerMinOrderBy;
  messageDeliveryCustomerListCustomer_order_by: MessageDeliveryCustomerListCustomerOrderBy;
  messageDeliveryCustomerListCustomer_select_column: MessageDeliveryCustomerListCustomerSelectColumn;
  messageDeliveryCustomerListCustomer_stream_cursor_input: MessageDeliveryCustomerListCustomerStreamCursorInput;
  messageDeliveryCustomerListCustomer_stream_cursor_value_input: MessageDeliveryCustomerListCustomerStreamCursorValueInput;
  messageDeliveryCustomerList_bool_exp: MessageDeliveryCustomerListBoolExp;
  messageDeliveryCustomerList_order_by: MessageDeliveryCustomerListOrderBy;
  messageDeliveryCustomerList_select_column: MessageDeliveryCustomerListSelectColumn;
  messageDeliveryCustomerList_stream_cursor_input: MessageDeliveryCustomerListStreamCursorInput;
  messageDeliveryCustomerList_stream_cursor_value_input: MessageDeliveryCustomerListStreamCursorValueInput;
  messageDeliveryCustomerSegment: ResolverTypeWrapper<MessageDeliveryCustomerSegment>;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition: ResolverTypeWrapper<MessageDeliveryCustomerSegmentBusinessOperationHoursCondition>;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_aggregate_order_by: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionAggregateOrderBy;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_bool_exp: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionBoolExp;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_max_order_by: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionMaxOrderBy;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_min_order_by: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionMinOrderBy;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_order_by: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionOrderBy;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_select_column: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionSelectColumn;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_stream_cursor_input: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionStreamCursorInput;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentDayOfWeekCondition: ResolverTypeWrapper<MessageDeliveryCustomerSegmentDayOfWeekCondition>;
  messageDeliveryCustomerSegmentDayOfWeekCondition_aggregate_order_by: MessageDeliveryCustomerSegmentDayOfWeekConditionAggregateOrderBy;
  messageDeliveryCustomerSegmentDayOfWeekCondition_bool_exp: MessageDeliveryCustomerSegmentDayOfWeekConditionBoolExp;
  messageDeliveryCustomerSegmentDayOfWeekCondition_max_order_by: MessageDeliveryCustomerSegmentDayOfWeekConditionMaxOrderBy;
  messageDeliveryCustomerSegmentDayOfWeekCondition_min_order_by: MessageDeliveryCustomerSegmentDayOfWeekConditionMinOrderBy;
  messageDeliveryCustomerSegmentDayOfWeekCondition_order_by: MessageDeliveryCustomerSegmentDayOfWeekConditionOrderBy;
  messageDeliveryCustomerSegmentDayOfWeekCondition_select_column: MessageDeliveryCustomerSegmentDayOfWeekConditionSelectColumn;
  messageDeliveryCustomerSegmentDayOfWeekCondition_stream_cursor_input: MessageDeliveryCustomerSegmentDayOfWeekConditionStreamCursorInput;
  messageDeliveryCustomerSegmentDayOfWeekCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentDayOfWeekConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentDayOfWeekType_enum: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum;
  messageDeliveryCustomerSegmentDayOfWeekType_enum_comparison_exp: MessageDeliveryCustomerSegmentDayOfWeekTypeEnumComparisonExp;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition: ResolverTypeWrapper<MessageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_bool_exp: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionBoolExp;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_order_by: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionOrderBy;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_select_column: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionSelectColumn;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_stream_cursor_input: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionStreamCursorInput;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentGender: ResolverTypeWrapper<MessageDeliveryCustomerSegmentGender>;
  messageDeliveryCustomerSegmentGenderCondition: ResolverTypeWrapper<MessageDeliveryCustomerSegmentGenderCondition>;
  messageDeliveryCustomerSegmentGenderCondition_bool_exp: MessageDeliveryCustomerSegmentGenderConditionBoolExp;
  messageDeliveryCustomerSegmentGenderCondition_order_by: MessageDeliveryCustomerSegmentGenderConditionOrderBy;
  messageDeliveryCustomerSegmentGenderCondition_select_column: MessageDeliveryCustomerSegmentGenderConditionSelectColumn;
  messageDeliveryCustomerSegmentGenderCondition_stream_cursor_input: MessageDeliveryCustomerSegmentGenderConditionStreamCursorInput;
  messageDeliveryCustomerSegmentGenderCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentGenderConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentGender_bool_exp: MessageDeliveryCustomerSegmentGenderBoolExp;
  messageDeliveryCustomerSegmentGender_enum: MessageDeliveryCustomerSegmentGenderEnum;
  messageDeliveryCustomerSegmentGender_enum_comparison_exp: MessageDeliveryCustomerSegmentGenderEnumComparisonExp;
  messageDeliveryCustomerSegmentGender_order_by: MessageDeliveryCustomerSegmentGenderOrderBy;
  messageDeliveryCustomerSegmentGender_select_column: MessageDeliveryCustomerSegmentGenderSelectColumn;
  messageDeliveryCustomerSegmentGender_stream_cursor_input: MessageDeliveryCustomerSegmentGenderStreamCursorInput;
  messageDeliveryCustomerSegmentGender_stream_cursor_value_input: MessageDeliveryCustomerSegmentGenderStreamCursorValueInput;
  messageDeliveryCustomerSegmentMenuCondition: ResolverTypeWrapper<MessageDeliveryCustomerSegmentMenuCondition>;
  messageDeliveryCustomerSegmentMenuCondition_aggregate_order_by: MessageDeliveryCustomerSegmentMenuConditionAggregateOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_avg_order_by: MessageDeliveryCustomerSegmentMenuConditionAvgOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_bool_exp: MessageDeliveryCustomerSegmentMenuConditionBoolExp;
  messageDeliveryCustomerSegmentMenuCondition_max_order_by: MessageDeliveryCustomerSegmentMenuConditionMaxOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_min_order_by: MessageDeliveryCustomerSegmentMenuConditionMinOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_order_by: MessageDeliveryCustomerSegmentMenuConditionOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_select_column: MessageDeliveryCustomerSegmentMenuConditionSelectColumn;
  messageDeliveryCustomerSegmentMenuCondition_stddev_order_by: MessageDeliveryCustomerSegmentMenuConditionStddevOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_stddev_pop_order_by: MessageDeliveryCustomerSegmentMenuConditionStddevPopOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_stddev_samp_order_by: MessageDeliveryCustomerSegmentMenuConditionStddevSampOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_stream_cursor_input: MessageDeliveryCustomerSegmentMenuConditionStreamCursorInput;
  messageDeliveryCustomerSegmentMenuCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentMenuConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentMenuCondition_sum_order_by: MessageDeliveryCustomerSegmentMenuConditionSumOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_var_pop_order_by: MessageDeliveryCustomerSegmentMenuConditionVarPopOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_var_samp_order_by: MessageDeliveryCustomerSegmentMenuConditionVarSampOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_variance_order_by: MessageDeliveryCustomerSegmentMenuConditionVarianceOrderBy;
  messageDeliveryCustomerSegmentNumericCondition: ResolverTypeWrapper<MessageDeliveryCustomerSegmentNumericCondition>;
  messageDeliveryCustomerSegmentNumericCondition_bool_exp: MessageDeliveryCustomerSegmentNumericConditionBoolExp;
  messageDeliveryCustomerSegmentNumericCondition_order_by: MessageDeliveryCustomerSegmentNumericConditionOrderBy;
  messageDeliveryCustomerSegmentNumericCondition_select_column: MessageDeliveryCustomerSegmentNumericConditionSelectColumn;
  messageDeliveryCustomerSegmentNumericCondition_stream_cursor_input: MessageDeliveryCustomerSegmentNumericConditionStreamCursorInput;
  messageDeliveryCustomerSegmentNumericCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentNumericConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition: ResolverTypeWrapper<MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_bool_exp: MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionBoolExp;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_order_by: MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionOrderBy;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_select_column: MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionSelectColumn;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_stream_cursor_input: MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionStreamCursorInput;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType: ResolverTypeWrapper<MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusType>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_bool_exp: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeBoolExp;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_enum: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_enum_comparison_exp: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnumComparisonExp;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_order_by: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeOrderBy;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_select_column: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeSelectColumn;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_stream_cursor_input: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeStreamCursorInput;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_stream_cursor_value_input: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeStreamCursorValueInput;
  messageDeliveryCustomerSegmentShopCondition: ResolverTypeWrapper<MessageDeliveryCustomerSegmentShopCondition>;
  messageDeliveryCustomerSegmentShopCondition_aggregate_order_by: MessageDeliveryCustomerSegmentShopConditionAggregateOrderBy;
  messageDeliveryCustomerSegmentShopCondition_bool_exp: MessageDeliveryCustomerSegmentShopConditionBoolExp;
  messageDeliveryCustomerSegmentShopCondition_max_order_by: MessageDeliveryCustomerSegmentShopConditionMaxOrderBy;
  messageDeliveryCustomerSegmentShopCondition_min_order_by: MessageDeliveryCustomerSegmentShopConditionMinOrderBy;
  messageDeliveryCustomerSegmentShopCondition_order_by: MessageDeliveryCustomerSegmentShopConditionOrderBy;
  messageDeliveryCustomerSegmentShopCondition_select_column: MessageDeliveryCustomerSegmentShopConditionSelectColumn;
  messageDeliveryCustomerSegmentShopCondition_stream_cursor_input: MessageDeliveryCustomerSegmentShopConditionStreamCursorInput;
  messageDeliveryCustomerSegmentShopCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentShopConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition: ResolverTypeWrapper<MessageDeliveryCustomerSegmentUnvisitedDayCountCondition>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_bool_exp: MessageDeliveryCustomerSegmentUnvisitedDayCountConditionBoolExp;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_order_by: MessageDeliveryCustomerSegmentUnvisitedDayCountConditionOrderBy;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_select_column: MessageDeliveryCustomerSegmentUnvisitedDayCountConditionSelectColumn;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_stream_cursor_input: MessageDeliveryCustomerSegmentUnvisitedDayCountConditionStreamCursorInput;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentUnvisitedDayCountConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentVisitedDayCountCondition: ResolverTypeWrapper<MessageDeliveryCustomerSegmentVisitedDayCountCondition>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_bool_exp: MessageDeliveryCustomerSegmentVisitedDayCountConditionBoolExp;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_order_by: MessageDeliveryCustomerSegmentVisitedDayCountConditionOrderBy;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_select_column: MessageDeliveryCustomerSegmentVisitedDayCountConditionSelectColumn;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_stream_cursor_input: MessageDeliveryCustomerSegmentVisitedDayCountConditionStreamCursorInput;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentVisitedDayCountConditionStreamCursorValueInput;
  messageDeliveryCustomerSegment_bool_exp: MessageDeliveryCustomerSegmentBoolExp;
  messageDeliveryCustomerSegment_order_by: MessageDeliveryCustomerSegmentOrderBy;
  messageDeliveryCustomerSegment_select_column: MessageDeliveryCustomerSegmentSelectColumn;
  messageDeliveryCustomerSegment_stream_cursor_input: MessageDeliveryCustomerSegmentStreamCursorInput;
  messageDeliveryCustomerSegment_stream_cursor_value_input: MessageDeliveryCustomerSegmentStreamCursorValueInput;
  messageDeliveryDayOfWeekType: MessageDeliveryDayOfWeekTypeType;
  messageDeliveryGender: MessageDeliveryGenderType;
  messageDeliveryJob: ResolverTypeWrapper<MessageDeliveryJob>;
  messageDeliveryJob_aggregate_order_by: MessageDeliveryJobAggregateOrderBy;
  messageDeliveryJob_avg_order_by: MessageDeliveryJobAvgOrderBy;
  messageDeliveryJob_bool_exp: MessageDeliveryJobBoolExp;
  messageDeliveryJob_max_order_by: MessageDeliveryJobMaxOrderBy;
  messageDeliveryJob_min_order_by: MessageDeliveryJobMinOrderBy;
  messageDeliveryJob_order_by: MessageDeliveryJobOrderBy;
  messageDeliveryJob_select_column: MessageDeliveryJobSelectColumn;
  messageDeliveryJob_stddev_order_by: MessageDeliveryJobStddevOrderBy;
  messageDeliveryJob_stddev_pop_order_by: MessageDeliveryJobStddevPopOrderBy;
  messageDeliveryJob_stddev_samp_order_by: MessageDeliveryJobStddevSampOrderBy;
  messageDeliveryJob_stream_cursor_input: MessageDeliveryJobStreamCursorInput;
  messageDeliveryJob_stream_cursor_value_input: MessageDeliveryJobStreamCursorValueInput;
  messageDeliveryJob_sum_order_by: MessageDeliveryJobSumOrderBy;
  messageDeliveryJob_var_pop_order_by: MessageDeliveryJobVarPopOrderBy;
  messageDeliveryJob_var_samp_order_by: MessageDeliveryJobVarSampOrderBy;
  messageDeliveryJob_variance_order_by: MessageDeliveryJobVarianceOrderBy;
  messageDeliveryMessage: ResolverTypeWrapper<MessageDeliveryMessage>;
  messageDeliveryMessageCouponTypeMeta: ResolverTypeWrapper<MessageDeliveryMessageCouponTypeMeta>;
  messageDeliveryMessageCouponTypeMeta_aggregate_order_by: MessageDeliveryMessageCouponTypeMetaAggregateOrderBy;
  messageDeliveryMessageCouponTypeMeta_bool_exp: MessageDeliveryMessageCouponTypeMetaBoolExp;
  messageDeliveryMessageCouponTypeMeta_max_order_by: MessageDeliveryMessageCouponTypeMetaMaxOrderBy;
  messageDeliveryMessageCouponTypeMeta_min_order_by: MessageDeliveryMessageCouponTypeMetaMinOrderBy;
  messageDeliveryMessageCouponTypeMeta_order_by: MessageDeliveryMessageCouponTypeMetaOrderBy;
  messageDeliveryMessageCouponTypeMeta_select_column: MessageDeliveryMessageCouponTypeMetaSelectColumn;
  messageDeliveryMessageCouponTypeMeta_stream_cursor_input: MessageDeliveryMessageCouponTypeMetaStreamCursorInput;
  messageDeliveryMessageCouponTypeMeta_stream_cursor_value_input: MessageDeliveryMessageCouponTypeMetaStreamCursorValueInput;
  messageDeliveryMessageImageTypeMeta: ResolverTypeWrapper<MessageDeliveryMessageImageTypeMeta>;
  messageDeliveryMessageImageTypeMeta_bool_exp: MessageDeliveryMessageImageTypeMetaBoolExp;
  messageDeliveryMessageImageTypeMeta_order_by: MessageDeliveryMessageImageTypeMetaOrderBy;
  messageDeliveryMessageImageTypeMeta_select_column: MessageDeliveryMessageImageTypeMetaSelectColumn;
  messageDeliveryMessageImageTypeMeta_stream_cursor_input: MessageDeliveryMessageImageTypeMetaStreamCursorInput;
  messageDeliveryMessageImageTypeMeta_stream_cursor_value_input: MessageDeliveryMessageImageTypeMetaStreamCursorValueInput;
  messageDeliveryMessageQuestionnaireTypeMeta: ResolverTypeWrapper<MessageDeliveryMessageQuestionnaireTypeMeta>;
  messageDeliveryMessageQuestionnaireTypeMeta_bool_exp: MessageDeliveryMessageQuestionnaireTypeMetaBoolExp;
  messageDeliveryMessageQuestionnaireTypeMeta_order_by: MessageDeliveryMessageQuestionnaireTypeMetaOrderBy;
  messageDeliveryMessageQuestionnaireTypeMeta_select_column: MessageDeliveryMessageQuestionnaireTypeMetaSelectColumn;
  messageDeliveryMessageQuestionnaireTypeMeta_stream_cursor_input: MessageDeliveryMessageQuestionnaireTypeMetaStreamCursorInput;
  messageDeliveryMessageQuestionnaireTypeMeta_stream_cursor_value_input: MessageDeliveryMessageQuestionnaireTypeMetaStreamCursorValueInput;
  messageDeliveryMessageTextTypeMeta: ResolverTypeWrapper<MessageDeliveryMessageTextTypeMeta>;
  messageDeliveryMessageTextTypeMeta_bool_exp: MessageDeliveryMessageTextTypeMetaBoolExp;
  messageDeliveryMessageTextTypeMeta_order_by: MessageDeliveryMessageTextTypeMetaOrderBy;
  messageDeliveryMessageTextTypeMeta_select_column: MessageDeliveryMessageTextTypeMetaSelectColumn;
  messageDeliveryMessageTextTypeMeta_stream_cursor_input: MessageDeliveryMessageTextTypeMetaStreamCursorInput;
  messageDeliveryMessageTextTypeMeta_stream_cursor_value_input: MessageDeliveryMessageTextTypeMetaStreamCursorValueInput;
  messageDeliveryMessageType_enum: MessageDeliveryMessageTypeEnum;
  messageDeliveryMessageType_enum_comparison_exp: MessageDeliveryMessageTypeEnumComparisonExp;
  messageDeliveryMessage_aggregate_order_by: MessageDeliveryMessageAggregateOrderBy;
  messageDeliveryMessage_avg_order_by: MessageDeliveryMessageAvgOrderBy;
  messageDeliveryMessage_bool_exp: MessageDeliveryMessageBoolExp;
  messageDeliveryMessage_max_order_by: MessageDeliveryMessageMaxOrderBy;
  messageDeliveryMessage_min_order_by: MessageDeliveryMessageMinOrderBy;
  messageDeliveryMessage_order_by: MessageDeliveryMessageOrderBy;
  messageDeliveryMessage_select_column: MessageDeliveryMessageSelectColumn;
  messageDeliveryMessage_stddev_order_by: MessageDeliveryMessageStddevOrderBy;
  messageDeliveryMessage_stddev_pop_order_by: MessageDeliveryMessageStddevPopOrderBy;
  messageDeliveryMessage_stddev_samp_order_by: MessageDeliveryMessageStddevSampOrderBy;
  messageDeliveryMessage_stream_cursor_input: MessageDeliveryMessageStreamCursorInput;
  messageDeliveryMessage_stream_cursor_value_input: MessageDeliveryMessageStreamCursorValueInput;
  messageDeliveryMessage_sum_order_by: MessageDeliveryMessageSumOrderBy;
  messageDeliveryMessage_var_pop_order_by: MessageDeliveryMessageVarPopOrderBy;
  messageDeliveryMessage_var_samp_order_by: MessageDeliveryMessageVarSampOrderBy;
  messageDeliveryMessage_variance_order_by: MessageDeliveryMessageVarianceOrderBy;
  messageDeliveryQuestionnaireAnswerStatus: MessageDeliveryQuestionnaireAnswerStatusType;
  messageDeliveryShopJob: ResolverTypeWrapper<MessageDeliveryShopJob>;
  messageDeliveryShopJobErrorType_enum: MessageDeliveryShopJobErrorTypeEnum;
  messageDeliveryShopJobErrorType_enum_comparison_exp: MessageDeliveryShopJobErrorTypeEnumComparisonExp;
  messageDeliveryShopJob_aggregate_order_by: MessageDeliveryShopJobAggregateOrderBy;
  messageDeliveryShopJob_avg_order_by: MessageDeliveryShopJobAvgOrderBy;
  messageDeliveryShopJob_bool_exp: MessageDeliveryShopJobBoolExp;
  messageDeliveryShopJob_max_order_by: MessageDeliveryShopJobMaxOrderBy;
  messageDeliveryShopJob_min_order_by: MessageDeliveryShopJobMinOrderBy;
  messageDeliveryShopJob_order_by: MessageDeliveryShopJobOrderBy;
  messageDeliveryShopJob_select_column: MessageDeliveryShopJobSelectColumn;
  messageDeliveryShopJob_stddev_order_by: MessageDeliveryShopJobStddevOrderBy;
  messageDeliveryShopJob_stddev_pop_order_by: MessageDeliveryShopJobStddevPopOrderBy;
  messageDeliveryShopJob_stddev_samp_order_by: MessageDeliveryShopJobStddevSampOrderBy;
  messageDeliveryShopJob_stream_cursor_input: MessageDeliveryShopJobStreamCursorInput;
  messageDeliveryShopJob_stream_cursor_value_input: MessageDeliveryShopJobStreamCursorValueInput;
  messageDeliveryShopJob_sum_order_by: MessageDeliveryShopJobSumOrderBy;
  messageDeliveryShopJob_var_pop_order_by: MessageDeliveryShopJobVarPopOrderBy;
  messageDeliveryShopJob_var_samp_order_by: MessageDeliveryShopJobVarSampOrderBy;
  messageDeliveryShopJob_variance_order_by: MessageDeliveryShopJobVarianceOrderBy;
  messageDelivery_aggregate_order_by: MessageDeliveryAggregateOrderBy;
  messageDelivery_avg_order_by: MessageDeliveryAvgOrderBy;
  messageDelivery_bool_exp: MessageDeliveryBoolExp;
  messageDelivery_inc_input: MessageDeliveryIncInput;
  messageDelivery_max_order_by: MessageDeliveryMaxOrderBy;
  messageDelivery_min_order_by: MessageDeliveryMinOrderBy;
  messageDelivery_mutation_response: ResolverTypeWrapper<MessageDeliveryMutationResponse>;
  messageDelivery_order_by: MessageDeliveryOrderBy;
  messageDelivery_pk_columns_input: MessageDeliveryPkColumnsInput;
  messageDelivery_select_column: MessageDeliverySelectColumn;
  messageDelivery_set_input: MessageDeliverySetInput;
  messageDelivery_stddev_order_by: MessageDeliveryStddevOrderBy;
  messageDelivery_stddev_pop_order_by: MessageDeliveryStddevPopOrderBy;
  messageDelivery_stddev_samp_order_by: MessageDeliveryStddevSampOrderBy;
  messageDelivery_stream_cursor_input: MessageDeliveryStreamCursorInput;
  messageDelivery_stream_cursor_value_input: MessageDeliveryStreamCursorValueInput;
  messageDelivery_sum_order_by: MessageDeliverySumOrderBy;
  messageDelivery_updates: MessageDeliveryUpdates;
  messageDelivery_var_pop_order_by: MessageDeliveryVarPopOrderBy;
  messageDelivery_var_samp_order_by: MessageDeliveryVarSampOrderBy;
  messageDelivery_variance_order_by: MessageDeliveryVarianceOrderBy;
  monthlySalesBudget: ResolverTypeWrapper<MonthlySalesBudget>;
  monthlySalesBudget_bool_exp: MonthlySalesBudgetBoolExp;
  monthlySalesBudget_constraint: MonthlySalesBudgetConstraint;
  monthlySalesBudget_inc_input: MonthlySalesBudgetIncInput;
  monthlySalesBudget_insert_input: MonthlySalesBudgetInsertInput;
  monthlySalesBudget_mutation_response: ResolverTypeWrapper<MonthlySalesBudgetMutationResponse>;
  monthlySalesBudget_obj_rel_insert_input: MonthlySalesBudgetObjRelInsertInput;
  monthlySalesBudget_on_conflict: MonthlySalesBudgetOnConflict;
  monthlySalesBudget_order_by: MonthlySalesBudgetOrderBy;
  monthlySalesBudget_pk_columns_input: MonthlySalesBudgetPkColumnsInput;
  monthlySalesBudget_select_column: MonthlySalesBudgetSelectColumn;
  monthlySalesBudget_set_input: MonthlySalesBudgetSetInput;
  monthlySalesBudget_stream_cursor_input: MonthlySalesBudgetStreamCursorInput;
  monthlySalesBudget_stream_cursor_value_input: MonthlySalesBudgetStreamCursorValueInput;
  monthlySalesBudget_update_column: MonthlySalesBudgetUpdateColumn;
  monthlySalesBudget_updates: MonthlySalesBudgetUpdates;
  multipleChoiceTypeQuestionAnswer: ResolverTypeWrapper<MultipleChoiceTypeQuestionAnswer>;
  multipleChoiceTypeQuestionAnswer_aggregate_order_by: MultipleChoiceTypeQuestionAnswerAggregateOrderBy;
  multipleChoiceTypeQuestionAnswer_bool_exp: MultipleChoiceTypeQuestionAnswerBoolExp;
  multipleChoiceTypeQuestionAnswer_max_order_by: MultipleChoiceTypeQuestionAnswerMaxOrderBy;
  multipleChoiceTypeQuestionAnswer_min_order_by: MultipleChoiceTypeQuestionAnswerMinOrderBy;
  multipleChoiceTypeQuestionAnswer_order_by: MultipleChoiceTypeQuestionAnswerOrderBy;
  multipleChoiceTypeQuestionAnswer_select_column: MultipleChoiceTypeQuestionAnswerSelectColumn;
  multipleChoiceTypeQuestionAnswer_stream_cursor_input: MultipleChoiceTypeQuestionAnswerStreamCursorInput;
  multipleChoiceTypeQuestionAnswer_stream_cursor_value_input: MultipleChoiceTypeQuestionAnswerStreamCursorValueInput;
  multipleChoiceTypeQuestionChoice: ResolverTypeWrapper<MultipleChoiceTypeQuestionChoice>;
  multipleChoiceTypeQuestionChoiceAnswer: ResolverTypeWrapper<MultipleChoiceTypeQuestionChoiceAnswer>;
  multipleChoiceTypeQuestionChoiceAnswer_aggregate_order_by: MultipleChoiceTypeQuestionChoiceAnswerAggregateOrderBy;
  multipleChoiceTypeQuestionChoiceAnswer_bool_exp: MultipleChoiceTypeQuestionChoiceAnswerBoolExp;
  multipleChoiceTypeQuestionChoiceAnswer_max_order_by: MultipleChoiceTypeQuestionChoiceAnswerMaxOrderBy;
  multipleChoiceTypeQuestionChoiceAnswer_min_order_by: MultipleChoiceTypeQuestionChoiceAnswerMinOrderBy;
  multipleChoiceTypeQuestionChoiceAnswer_order_by: MultipleChoiceTypeQuestionChoiceAnswerOrderBy;
  multipleChoiceTypeQuestionChoiceAnswer_select_column: MultipleChoiceTypeQuestionChoiceAnswerSelectColumn;
  multipleChoiceTypeQuestionChoiceAnswer_stream_cursor_input: MultipleChoiceTypeQuestionChoiceAnswerStreamCursorInput;
  multipleChoiceTypeQuestionChoiceAnswer_stream_cursor_value_input: MultipleChoiceTypeQuestionChoiceAnswerStreamCursorValueInput;
  multipleChoiceTypeQuestionChoice_aggregate_order_by: MultipleChoiceTypeQuestionChoiceAggregateOrderBy;
  multipleChoiceTypeQuestionChoice_bool_exp: MultipleChoiceTypeQuestionChoiceBoolExp;
  multipleChoiceTypeQuestionChoice_max_order_by: MultipleChoiceTypeQuestionChoiceMaxOrderBy;
  multipleChoiceTypeQuestionChoice_min_order_by: MultipleChoiceTypeQuestionChoiceMinOrderBy;
  multipleChoiceTypeQuestionChoice_order_by: MultipleChoiceTypeQuestionChoiceOrderBy;
  multipleChoiceTypeQuestionChoice_select_column: MultipleChoiceTypeQuestionChoiceSelectColumn;
  multipleChoiceTypeQuestionChoice_stream_cursor_input: MultipleChoiceTypeQuestionChoiceStreamCursorInput;
  multipleChoiceTypeQuestionChoice_stream_cursor_value_input: MultipleChoiceTypeQuestionChoiceStreamCursorValueInput;
  multipleChoiceTypeQuestionMeta: ResolverTypeWrapper<MultipleChoiceTypeQuestionMeta>;
  multipleChoiceTypeQuestionMeta_bool_exp: MultipleChoiceTypeQuestionMetaBoolExp;
  multipleChoiceTypeQuestionMeta_order_by: MultipleChoiceTypeQuestionMetaOrderBy;
  multipleChoiceTypeQuestionMeta_select_column: MultipleChoiceTypeQuestionMetaSelectColumn;
  multipleChoiceTypeQuestionMeta_stream_cursor_input: MultipleChoiceTypeQuestionMetaStreamCursorInput;
  multipleChoiceTypeQuestionMeta_stream_cursor_value_input: MultipleChoiceTypeQuestionMetaStreamCursorValueInput;
  mutation_root: ResolverTypeWrapper<{}>;
  numeric: ResolverTypeWrapper<Scalars['numeric']>;
  numeric_comparison_exp: NumericComparisonExp;
  onSitePayment: ResolverTypeWrapper<OnSitePayment>;
  onSitePaymentDetail: ResolverTypeWrapper<OnSitePaymentDetail>;
  onSitePaymentDetailType: ResolverTypeWrapper<OnSitePaymentDetailType>;
  onSitePaymentDetailTypeCategoryPriority: ResolverTypeWrapper<OnSitePaymentDetailTypeCategoryPriority>;
  onSitePaymentDetailTypeCategoryPriority_bool_exp: OnSitePaymentDetailTypeCategoryPriorityBoolExp;
  onSitePaymentDetailTypeCategoryPriority_order_by: OnSitePaymentDetailTypeCategoryPriorityOrderBy;
  onSitePaymentDetailTypeCategoryPriority_select_column: OnSitePaymentDetailTypeCategoryPrioritySelectColumn;
  onSitePaymentDetailTypeCategoryPriority_stream_cursor_input: OnSitePaymentDetailTypeCategoryPriorityStreamCursorInput;
  onSitePaymentDetailTypeCategoryPriority_stream_cursor_value_input: OnSitePaymentDetailTypeCategoryPriorityStreamCursorValueInput;
  onSitePaymentDetailTypeCategory_enum: OnSitePaymentDetailTypeCategoryEnum;
  onSitePaymentDetailTypeCategory_enum_comparison_exp: OnSitePaymentDetailTypeCategoryEnumComparisonExp;
  onSitePaymentDetailType_aggregate: ResolverTypeWrapper<OnSitePaymentDetailTypeAggregate>;
  onSitePaymentDetailType_aggregate_bool_exp: OnSitePaymentDetailTypeAggregateBoolExp;
  onSitePaymentDetailType_aggregate_bool_exp_bool_and: OnSitePaymentDetailTypeAggregateBoolExpBoolAnd;
  onSitePaymentDetailType_aggregate_bool_exp_bool_or: OnSitePaymentDetailTypeAggregateBoolExpBoolOr;
  onSitePaymentDetailType_aggregate_bool_exp_count: OnSitePaymentDetailTypeAggregateBoolExpCount;
  onSitePaymentDetailType_aggregate_fields: ResolverTypeWrapper<OnSitePaymentDetailTypeAggregateFields>;
  onSitePaymentDetailType_aggregate_order_by: OnSitePaymentDetailTypeAggregateOrderBy;
  onSitePaymentDetailType_arr_rel_insert_input: OnSitePaymentDetailTypeArrRelInsertInput;
  onSitePaymentDetailType_avg_fields: ResolverTypeWrapper<OnSitePaymentDetailTypeAvgFields>;
  onSitePaymentDetailType_avg_order_by: OnSitePaymentDetailTypeAvgOrderBy;
  onSitePaymentDetailType_bool_exp: OnSitePaymentDetailTypeBoolExp;
  onSitePaymentDetailType_constraint: OnSitePaymentDetailTypeConstraint;
  onSitePaymentDetailType_inc_input: OnSitePaymentDetailTypeIncInput;
  onSitePaymentDetailType_insert_input: OnSitePaymentDetailTypeInsertInput;
  onSitePaymentDetailType_max_fields: ResolverTypeWrapper<OnSitePaymentDetailTypeMaxFields>;
  onSitePaymentDetailType_max_order_by: OnSitePaymentDetailTypeMaxOrderBy;
  onSitePaymentDetailType_min_fields: ResolverTypeWrapper<OnSitePaymentDetailTypeMinFields>;
  onSitePaymentDetailType_min_order_by: OnSitePaymentDetailTypeMinOrderBy;
  onSitePaymentDetailType_mutation_response: ResolverTypeWrapper<OnSitePaymentDetailTypeMutationResponse>;
  onSitePaymentDetailType_obj_rel_insert_input: OnSitePaymentDetailTypeObjRelInsertInput;
  onSitePaymentDetailType_on_conflict: OnSitePaymentDetailTypeOnConflict;
  onSitePaymentDetailType_order_by: OnSitePaymentDetailTypeOrderBy;
  onSitePaymentDetailType_pk_columns_input: OnSitePaymentDetailTypePkColumnsInput;
  onSitePaymentDetailType_select_column: OnSitePaymentDetailTypeSelectColumn;
  onSitePaymentDetailType_select_column_onSitePaymentDetailType_aggregate_bool_exp_bool_and_arguments_columns: OnSitePaymentDetailTypeSelectColumnOnSitePaymentDetailTypeAggregateBoolExpBoolAndArgumentsColumnsType;
  onSitePaymentDetailType_select_column_onSitePaymentDetailType_aggregate_bool_exp_bool_or_arguments_columns: OnSitePaymentDetailTypeSelectColumnOnSitePaymentDetailTypeAggregateBoolExpBoolOrArgumentsColumnsType;
  onSitePaymentDetailType_set_input: OnSitePaymentDetailTypeSetInput;
  onSitePaymentDetailType_stddev_fields: ResolverTypeWrapper<OnSitePaymentDetailTypeStddevFields>;
  onSitePaymentDetailType_stddev_order_by: OnSitePaymentDetailTypeStddevOrderBy;
  onSitePaymentDetailType_stddev_pop_fields: ResolverTypeWrapper<OnSitePaymentDetailTypeStddevPopFields>;
  onSitePaymentDetailType_stddev_pop_order_by: OnSitePaymentDetailTypeStddevPopOrderBy;
  onSitePaymentDetailType_stddev_samp_fields: ResolverTypeWrapper<OnSitePaymentDetailTypeStddevSampFields>;
  onSitePaymentDetailType_stddev_samp_order_by: OnSitePaymentDetailTypeStddevSampOrderBy;
  onSitePaymentDetailType_stream_cursor_input: OnSitePaymentDetailTypeStreamCursorInput;
  onSitePaymentDetailType_stream_cursor_value_input: OnSitePaymentDetailTypeStreamCursorValueInput;
  onSitePaymentDetailType_sum_fields: ResolverTypeWrapper<OnSitePaymentDetailTypeSumFields>;
  onSitePaymentDetailType_sum_order_by: OnSitePaymentDetailTypeSumOrderBy;
  onSitePaymentDetailType_update_column: OnSitePaymentDetailTypeUpdateColumn;
  onSitePaymentDetailType_updates: OnSitePaymentDetailTypeUpdates;
  onSitePaymentDetailType_var_pop_fields: ResolverTypeWrapper<OnSitePaymentDetailTypeVarPopFields>;
  onSitePaymentDetailType_var_pop_order_by: OnSitePaymentDetailTypeVarPopOrderBy;
  onSitePaymentDetailType_var_samp_fields: ResolverTypeWrapper<OnSitePaymentDetailTypeVarSampFields>;
  onSitePaymentDetailType_var_samp_order_by: OnSitePaymentDetailTypeVarSampOrderBy;
  onSitePaymentDetailType_variance_fields: ResolverTypeWrapper<OnSitePaymentDetailTypeVarianceFields>;
  onSitePaymentDetailType_variance_order_by: OnSitePaymentDetailTypeVarianceOrderBy;
  onSitePaymentDetail_aggregate_order_by: OnSitePaymentDetailAggregateOrderBy;
  onSitePaymentDetail_avg_order_by: OnSitePaymentDetailAvgOrderBy;
  onSitePaymentDetail_bool_exp: OnSitePaymentDetailBoolExp;
  onSitePaymentDetail_max_order_by: OnSitePaymentDetailMaxOrderBy;
  onSitePaymentDetail_min_order_by: OnSitePaymentDetailMinOrderBy;
  onSitePaymentDetail_order_by: OnSitePaymentDetailOrderBy;
  onSitePaymentDetail_select_column: OnSitePaymentDetailSelectColumn;
  onSitePaymentDetail_stddev_order_by: OnSitePaymentDetailStddevOrderBy;
  onSitePaymentDetail_stddev_pop_order_by: OnSitePaymentDetailStddevPopOrderBy;
  onSitePaymentDetail_stddev_samp_order_by: OnSitePaymentDetailStddevSampOrderBy;
  onSitePaymentDetail_stream_cursor_input: OnSitePaymentDetailStreamCursorInput;
  onSitePaymentDetail_stream_cursor_value_input: OnSitePaymentDetailStreamCursorValueInput;
  onSitePaymentDetail_sum_order_by: OnSitePaymentDetailSumOrderBy;
  onSitePaymentDetail_var_pop_order_by: OnSitePaymentDetailVarPopOrderBy;
  onSitePaymentDetail_var_samp_order_by: OnSitePaymentDetailVarSampOrderBy;
  onSitePaymentDetail_variance_order_by: OnSitePaymentDetailVarianceOrderBy;
  onSitePaymentDiscount: ResolverTypeWrapper<OnSitePaymentDiscount>;
  onSitePaymentDiscountType: ResolverTypeWrapper<OnSitePaymentDiscountType>;
  onSitePaymentDiscountType_aggregate: ResolverTypeWrapper<OnSitePaymentDiscountTypeAggregate>;
  onSitePaymentDiscountType_aggregate_bool_exp: OnSitePaymentDiscountTypeAggregateBoolExp;
  onSitePaymentDiscountType_aggregate_bool_exp_bool_and: OnSitePaymentDiscountTypeAggregateBoolExpBoolAnd;
  onSitePaymentDiscountType_aggregate_bool_exp_bool_or: OnSitePaymentDiscountTypeAggregateBoolExpBoolOr;
  onSitePaymentDiscountType_aggregate_bool_exp_count: OnSitePaymentDiscountTypeAggregateBoolExpCount;
  onSitePaymentDiscountType_aggregate_fields: ResolverTypeWrapper<OnSitePaymentDiscountTypeAggregateFields>;
  onSitePaymentDiscountType_aggregate_order_by: OnSitePaymentDiscountTypeAggregateOrderBy;
  onSitePaymentDiscountType_arr_rel_insert_input: OnSitePaymentDiscountTypeArrRelInsertInput;
  onSitePaymentDiscountType_avg_fields: ResolverTypeWrapper<OnSitePaymentDiscountTypeAvgFields>;
  onSitePaymentDiscountType_avg_order_by: OnSitePaymentDiscountTypeAvgOrderBy;
  onSitePaymentDiscountType_bool_exp: OnSitePaymentDiscountTypeBoolExp;
  onSitePaymentDiscountType_constraint: OnSitePaymentDiscountTypeConstraint;
  onSitePaymentDiscountType_inc_input: OnSitePaymentDiscountTypeIncInput;
  onSitePaymentDiscountType_insert_input: OnSitePaymentDiscountTypeInsertInput;
  onSitePaymentDiscountType_max_fields: ResolverTypeWrapper<OnSitePaymentDiscountTypeMaxFields>;
  onSitePaymentDiscountType_max_order_by: OnSitePaymentDiscountTypeMaxOrderBy;
  onSitePaymentDiscountType_min_fields: ResolverTypeWrapper<OnSitePaymentDiscountTypeMinFields>;
  onSitePaymentDiscountType_min_order_by: OnSitePaymentDiscountTypeMinOrderBy;
  onSitePaymentDiscountType_mutation_response: ResolverTypeWrapper<OnSitePaymentDiscountTypeMutationResponse>;
  onSitePaymentDiscountType_obj_rel_insert_input: OnSitePaymentDiscountTypeObjRelInsertInput;
  onSitePaymentDiscountType_on_conflict: OnSitePaymentDiscountTypeOnConflict;
  onSitePaymentDiscountType_order_by: OnSitePaymentDiscountTypeOrderBy;
  onSitePaymentDiscountType_pk_columns_input: OnSitePaymentDiscountTypePkColumnsInput;
  onSitePaymentDiscountType_select_column: OnSitePaymentDiscountTypeSelectColumn;
  onSitePaymentDiscountType_select_column_onSitePaymentDiscountType_aggregate_bool_exp_bool_and_arguments_columns: OnSitePaymentDiscountTypeSelectColumnOnSitePaymentDiscountTypeAggregateBoolExpBoolAndArgumentsColumnsType;
  onSitePaymentDiscountType_select_column_onSitePaymentDiscountType_aggregate_bool_exp_bool_or_arguments_columns: OnSitePaymentDiscountTypeSelectColumnOnSitePaymentDiscountTypeAggregateBoolExpBoolOrArgumentsColumnsType;
  onSitePaymentDiscountType_set_input: OnSitePaymentDiscountTypeSetInput;
  onSitePaymentDiscountType_stddev_fields: ResolverTypeWrapper<OnSitePaymentDiscountTypeStddevFields>;
  onSitePaymentDiscountType_stddev_order_by: OnSitePaymentDiscountTypeStddevOrderBy;
  onSitePaymentDiscountType_stddev_pop_fields: ResolverTypeWrapper<OnSitePaymentDiscountTypeStddevPopFields>;
  onSitePaymentDiscountType_stddev_pop_order_by: OnSitePaymentDiscountTypeStddevPopOrderBy;
  onSitePaymentDiscountType_stddev_samp_fields: ResolverTypeWrapper<OnSitePaymentDiscountTypeStddevSampFields>;
  onSitePaymentDiscountType_stddev_samp_order_by: OnSitePaymentDiscountTypeStddevSampOrderBy;
  onSitePaymentDiscountType_stream_cursor_input: OnSitePaymentDiscountTypeStreamCursorInput;
  onSitePaymentDiscountType_stream_cursor_value_input: OnSitePaymentDiscountTypeStreamCursorValueInput;
  onSitePaymentDiscountType_sum_fields: ResolverTypeWrapper<OnSitePaymentDiscountTypeSumFields>;
  onSitePaymentDiscountType_sum_order_by: OnSitePaymentDiscountTypeSumOrderBy;
  onSitePaymentDiscountType_update_column: OnSitePaymentDiscountTypeUpdateColumn;
  onSitePaymentDiscountType_updates: OnSitePaymentDiscountTypeUpdates;
  onSitePaymentDiscountType_var_pop_fields: ResolverTypeWrapper<OnSitePaymentDiscountTypeVarPopFields>;
  onSitePaymentDiscountType_var_pop_order_by: OnSitePaymentDiscountTypeVarPopOrderBy;
  onSitePaymentDiscountType_var_samp_fields: ResolverTypeWrapper<OnSitePaymentDiscountTypeVarSampFields>;
  onSitePaymentDiscountType_var_samp_order_by: OnSitePaymentDiscountTypeVarSampOrderBy;
  onSitePaymentDiscountType_variance_fields: ResolverTypeWrapper<OnSitePaymentDiscountTypeVarianceFields>;
  onSitePaymentDiscountType_variance_order_by: OnSitePaymentDiscountTypeVarianceOrderBy;
  onSitePaymentDiscount_aggregate_order_by: OnSitePaymentDiscountAggregateOrderBy;
  onSitePaymentDiscount_avg_order_by: OnSitePaymentDiscountAvgOrderBy;
  onSitePaymentDiscount_bool_exp: OnSitePaymentDiscountBoolExp;
  onSitePaymentDiscount_max_order_by: OnSitePaymentDiscountMaxOrderBy;
  onSitePaymentDiscount_min_order_by: OnSitePaymentDiscountMinOrderBy;
  onSitePaymentDiscount_order_by: OnSitePaymentDiscountOrderBy;
  onSitePaymentDiscount_select_column: OnSitePaymentDiscountSelectColumn;
  onSitePaymentDiscount_stddev_order_by: OnSitePaymentDiscountStddevOrderBy;
  onSitePaymentDiscount_stddev_pop_order_by: OnSitePaymentDiscountStddevPopOrderBy;
  onSitePaymentDiscount_stddev_samp_order_by: OnSitePaymentDiscountStddevSampOrderBy;
  onSitePaymentDiscount_stream_cursor_input: OnSitePaymentDiscountStreamCursorInput;
  onSitePaymentDiscount_stream_cursor_value_input: OnSitePaymentDiscountStreamCursorValueInput;
  onSitePaymentDiscount_sum_order_by: OnSitePaymentDiscountSumOrderBy;
  onSitePaymentDiscount_var_pop_order_by: OnSitePaymentDiscountVarPopOrderBy;
  onSitePaymentDiscount_var_samp_order_by: OnSitePaymentDiscountVarSampOrderBy;
  onSitePaymentDiscount_variance_order_by: OnSitePaymentDiscountVarianceOrderBy;
  onSitePaymentSales: ResolverTypeWrapper<OnSitePaymentSales>;
  onSitePaymentSales_aggregate_order_by: OnSitePaymentSalesAggregateOrderBy;
  onSitePaymentSales_avg_order_by: OnSitePaymentSalesAvgOrderBy;
  onSitePaymentSales_bool_exp: OnSitePaymentSalesBoolExp;
  onSitePaymentSales_max_order_by: OnSitePaymentSalesMaxOrderBy;
  onSitePaymentSales_min_order_by: OnSitePaymentSalesMinOrderBy;
  onSitePaymentSales_order_by: OnSitePaymentSalesOrderBy;
  onSitePaymentSales_select_column: OnSitePaymentSalesSelectColumn;
  onSitePaymentSales_stddev_order_by: OnSitePaymentSalesStddevOrderBy;
  onSitePaymentSales_stddev_pop_order_by: OnSitePaymentSalesStddevPopOrderBy;
  onSitePaymentSales_stddev_samp_order_by: OnSitePaymentSalesStddevSampOrderBy;
  onSitePaymentSales_stream_cursor_input: OnSitePaymentSalesStreamCursorInput;
  onSitePaymentSales_stream_cursor_value_input: OnSitePaymentSalesStreamCursorValueInput;
  onSitePaymentSales_sum_order_by: OnSitePaymentSalesSumOrderBy;
  onSitePaymentSales_var_pop_order_by: OnSitePaymentSalesVarPopOrderBy;
  onSitePaymentSales_var_samp_order_by: OnSitePaymentSalesVarSampOrderBy;
  onSitePaymentSales_variance_order_by: OnSitePaymentSalesVarianceOrderBy;
  onSitePaymentTableUsers: ResolverTypeWrapper<OnSitePaymentTableUsers>;
  onSitePaymentTableUsers_aggregate_order_by: OnSitePaymentTableUsersAggregateOrderBy;
  onSitePaymentTableUsers_avg_order_by: OnSitePaymentTableUsersAvgOrderBy;
  onSitePaymentTableUsers_bool_exp: OnSitePaymentTableUsersBoolExp;
  onSitePaymentTableUsers_max_order_by: OnSitePaymentTableUsersMaxOrderBy;
  onSitePaymentTableUsers_min_order_by: OnSitePaymentTableUsersMinOrderBy;
  onSitePaymentTableUsers_order_by: OnSitePaymentTableUsersOrderBy;
  onSitePaymentTableUsers_select_column: OnSitePaymentTableUsersSelectColumn;
  onSitePaymentTableUsers_stddev_order_by: OnSitePaymentTableUsersStddevOrderBy;
  onSitePaymentTableUsers_stddev_pop_order_by: OnSitePaymentTableUsersStddevPopOrderBy;
  onSitePaymentTableUsers_stddev_samp_order_by: OnSitePaymentTableUsersStddevSampOrderBy;
  onSitePaymentTableUsers_stream_cursor_input: OnSitePaymentTableUsersStreamCursorInput;
  onSitePaymentTableUsers_stream_cursor_value_input: OnSitePaymentTableUsersStreamCursorValueInput;
  onSitePaymentTableUsers_sum_order_by: OnSitePaymentTableUsersSumOrderBy;
  onSitePaymentTableUsers_var_pop_order_by: OnSitePaymentTableUsersVarPopOrderBy;
  onSitePaymentTableUsers_var_samp_order_by: OnSitePaymentTableUsersVarSampOrderBy;
  onSitePaymentTableUsers_variance_order_by: OnSitePaymentTableUsersVarianceOrderBy;
  onSitePayment_aggregate_order_by: OnSitePaymentAggregateOrderBy;
  onSitePayment_avg_order_by: OnSitePaymentAvgOrderBy;
  onSitePayment_bool_exp: OnSitePaymentBoolExp;
  onSitePayment_max_order_by: OnSitePaymentMaxOrderBy;
  onSitePayment_min_order_by: OnSitePaymentMinOrderBy;
  onSitePayment_order_by: OnSitePaymentOrderBy;
  onSitePayment_select_column: OnSitePaymentSelectColumn;
  onSitePayment_stddev_order_by: OnSitePaymentStddevOrderBy;
  onSitePayment_stddev_pop_order_by: OnSitePaymentStddevPopOrderBy;
  onSitePayment_stddev_samp_order_by: OnSitePaymentStddevSampOrderBy;
  onSitePayment_stream_cursor_input: OnSitePaymentStreamCursorInput;
  onSitePayment_stream_cursor_value_input: OnSitePaymentStreamCursorValueInput;
  onSitePayment_sum_order_by: OnSitePaymentSumOrderBy;
  onSitePayment_var_pop_order_by: OnSitePaymentVarPopOrderBy;
  onSitePayment_var_samp_order_by: OnSitePaymentVarSampOrderBy;
  onSitePayment_variance_order_by: OnSitePaymentVarianceOrderBy;
  onlinePaymentPrinterRoleMeta: ResolverTypeWrapper<OnlinePaymentPrinterRoleMeta>;
  onlinePaymentPrinterRoleMeta_bool_exp: OnlinePaymentPrinterRoleMetaBoolExp;
  onlinePaymentPrinterRoleMeta_constraint: OnlinePaymentPrinterRoleMetaConstraint;
  onlinePaymentPrinterRoleMeta_insert_input: OnlinePaymentPrinterRoleMetaInsertInput;
  onlinePaymentPrinterRoleMeta_mutation_response: ResolverTypeWrapper<OnlinePaymentPrinterRoleMetaMutationResponse>;
  onlinePaymentPrinterRoleMeta_on_conflict: OnlinePaymentPrinterRoleMetaOnConflict;
  onlinePaymentPrinterRoleMeta_order_by: OnlinePaymentPrinterRoleMetaOrderBy;
  onlinePaymentPrinterRoleMeta_select_column: OnlinePaymentPrinterRoleMetaSelectColumn;
  onlinePaymentPrinterRoleMeta_stream_cursor_input: OnlinePaymentPrinterRoleMetaStreamCursorInput;
  onlinePaymentPrinterRoleMeta_stream_cursor_value_input: OnlinePaymentPrinterRoleMetaStreamCursorValueInput;
  onlinePaymentPrinterRoleMeta_update_column: OnlinePaymentPrinterRoleMetaUpdateColumn;
  onlinePaymentRootMutation: ResolverTypeWrapper<OnlinePaymentRootMutation>;
  onlinePaymentRootQuery: ResolverTypeWrapper<OnlinePaymentRootQuery>;
  option: ResolverTypeWrapper<Option>;
  optionInputType: ResolverTypeWrapper<OptionInputType>;
  optionInputType_bool_exp: OptionInputTypeBoolExp;
  optionInputType_constraint: OptionInputTypeConstraint;
  optionInputType_enum: OptionInputTypeEnum;
  optionInputType_enum_comparison_exp: OptionInputTypeEnumComparisonExp;
  optionInputType_insert_input: OptionInputTypeInsertInput;
  optionInputType_mutation_response: ResolverTypeWrapper<OptionInputTypeMutationResponse>;
  optionInputType_obj_rel_insert_input: OptionInputTypeObjRelInsertInput;
  optionInputType_on_conflict: OptionInputTypeOnConflict;
  optionInputType_order_by: OptionInputTypeOrderBy;
  optionInputType_pk_columns_input: OptionInputTypePkColumnsInput;
  optionInputType_select_column: OptionInputTypeSelectColumn;
  optionInputType_set_input: OptionInputTypeSetInput;
  optionInputType_stream_cursor_input: OptionInputTypeStreamCursorInput;
  optionInputType_stream_cursor_value_input: OptionInputTypeStreamCursorValueInput;
  optionInputType_update_column: OptionInputTypeUpdateColumn;
  optionInputType_updates: OptionInputTypeUpdates;
  option_aggregate: ResolverTypeWrapper<OptionAggregate>;
  option_aggregate_bool_exp: OptionAggregateBoolExp;
  option_aggregate_bool_exp_count: OptionAggregateBoolExpCount;
  option_aggregate_fields: ResolverTypeWrapper<OptionAggregateFields>;
  option_aggregate_order_by: OptionAggregateOrderBy;
  option_arr_rel_insert_input: OptionArrRelInsertInput;
  option_avg_fields: ResolverTypeWrapper<OptionAvgFields>;
  option_avg_order_by: OptionAvgOrderBy;
  option_bool_exp: OptionBoolExp;
  option_constraint: OptionConstraint;
  option_inc_input: OptionIncInput;
  option_insert_input: OptionInsertInput;
  option_max_fields: ResolverTypeWrapper<OptionMaxFields>;
  option_max_order_by: OptionMaxOrderBy;
  option_min_fields: ResolverTypeWrapper<OptionMinFields>;
  option_min_order_by: OptionMinOrderBy;
  option_mutation_response: ResolverTypeWrapper<OptionMutationResponse>;
  option_obj_rel_insert_input: OptionObjRelInsertInput;
  option_on_conflict: OptionOnConflict;
  option_order_by: OptionOrderBy;
  option_pk_columns_input: OptionPkColumnsInput;
  option_select_column: OptionSelectColumn;
  option_set_input: OptionSetInput;
  option_stddev_fields: ResolverTypeWrapper<OptionStddevFields>;
  option_stddev_order_by: OptionStddevOrderBy;
  option_stddev_pop_fields: ResolverTypeWrapper<OptionStddevPopFields>;
  option_stddev_pop_order_by: OptionStddevPopOrderBy;
  option_stddev_samp_fields: ResolverTypeWrapper<OptionStddevSampFields>;
  option_stddev_samp_order_by: OptionStddevSampOrderBy;
  option_stream_cursor_input: OptionStreamCursorInput;
  option_stream_cursor_value_input: OptionStreamCursorValueInput;
  option_sum_fields: ResolverTypeWrapper<OptionSumFields>;
  option_sum_order_by: OptionSumOrderBy;
  option_update_column: OptionUpdateColumn;
  option_updates: OptionUpdates;
  option_var_pop_fields: ResolverTypeWrapper<OptionVarPopFields>;
  option_var_pop_order_by: OptionVarPopOrderBy;
  option_var_samp_fields: ResolverTypeWrapper<OptionVarSampFields>;
  option_var_samp_order_by: OptionVarSampOrderBy;
  option_variance_fields: ResolverTypeWrapper<OptionVarianceFields>;
  option_variance_order_by: OptionVarianceOrderBy;
  order: ResolverTypeWrapper<Order>;
  order_aggregate_order_by: OrderAggregateOrderBy;
  order_bool_exp: OrderBoolExp;
  order_by: OrderBy;
  order_max_order_by: OrderMaxOrderBy;
  order_min_order_by: OrderMinOrderBy;
  order_order_by: OrderOrderBy;
  order_select_column: OrderSelectColumn;
  order_stream_cursor_input: OrderStreamCursorInput;
  order_stream_cursor_value_input: OrderStreamCursorValueInput;
  orderableTime: ResolverTypeWrapper<OrderableTime>;
  orderableTimeTermDayWeek_enum: OrderableTimeTermDayWeekEnum;
  orderableTimeTermDayWeek_enum_comparison_exp: OrderableTimeTermDayWeekEnumComparisonExp;
  orderableTime_aggregate_order_by: OrderableTimeAggregateOrderBy;
  orderableTime_arr_rel_insert_input: OrderableTimeArrRelInsertInput;
  orderableTime_bool_exp: OrderableTimeBoolExp;
  orderableTime_constraint: OrderableTimeConstraint;
  orderableTime_insert_input: OrderableTimeInsertInput;
  orderableTime_max_order_by: OrderableTimeMaxOrderBy;
  orderableTime_min_order_by: OrderableTimeMinOrderBy;
  orderableTime_mutation_response: ResolverTypeWrapper<OrderableTimeMutationResponse>;
  orderableTime_obj_rel_insert_input: OrderableTimeObjRelInsertInput;
  orderableTime_on_conflict: OrderableTimeOnConflict;
  orderableTime_order_by: OrderableTimeOrderBy;
  orderableTime_pk_columns_input: OrderableTimePkColumnsInput;
  orderableTime_select_column: OrderableTimeSelectColumn;
  orderableTime_set_input: OrderableTimeSetInput;
  orderableTime_stream_cursor_input: OrderableTimeStreamCursorInput;
  orderableTime_stream_cursor_value_input: OrderableTimeStreamCursorValueInput;
  orderableTime_update_column: OrderableTimeUpdateColumn;
  orderableTime_updates: OrderableTimeUpdates;
  payingActivePlanChoice: ResolverTypeWrapper<PayingActivePlanChoice>;
  payingActivePlanChoice_aggregate_order_by: PayingActivePlanChoiceAggregateOrderBy;
  payingActivePlanChoice_avg_order_by: PayingActivePlanChoiceAvgOrderBy;
  payingActivePlanChoice_bool_exp: PayingActivePlanChoiceBoolExp;
  payingActivePlanChoice_max_order_by: PayingActivePlanChoiceMaxOrderBy;
  payingActivePlanChoice_min_order_by: PayingActivePlanChoiceMinOrderBy;
  payingActivePlanChoice_order_by: PayingActivePlanChoiceOrderBy;
  payingActivePlanChoice_select_column: PayingActivePlanChoiceSelectColumn;
  payingActivePlanChoice_stddev_order_by: PayingActivePlanChoiceStddevOrderBy;
  payingActivePlanChoice_stddev_pop_order_by: PayingActivePlanChoiceStddevPopOrderBy;
  payingActivePlanChoice_stddev_samp_order_by: PayingActivePlanChoiceStddevSampOrderBy;
  payingActivePlanChoice_stream_cursor_input: PayingActivePlanChoiceStreamCursorInput;
  payingActivePlanChoice_stream_cursor_value_input: PayingActivePlanChoiceStreamCursorValueInput;
  payingActivePlanChoice_sum_order_by: PayingActivePlanChoiceSumOrderBy;
  payingActivePlanChoice_var_pop_order_by: PayingActivePlanChoiceVarPopOrderBy;
  payingActivePlanChoice_var_samp_order_by: PayingActivePlanChoiceVarSampOrderBy;
  payingActivePlanChoice_variance_order_by: PayingActivePlanChoiceVarianceOrderBy;
  payingActivePlanOpenPriceMeta: ResolverTypeWrapper<PayingActivePlanOpenPriceMeta>;
  payingActivePlanOpenPriceMeta_aggregate_order_by: PayingActivePlanOpenPriceMetaAggregateOrderBy;
  payingActivePlanOpenPriceMeta_avg_order_by: PayingActivePlanOpenPriceMetaAvgOrderBy;
  payingActivePlanOpenPriceMeta_bool_exp: PayingActivePlanOpenPriceMetaBoolExp;
  payingActivePlanOpenPriceMeta_max_order_by: PayingActivePlanOpenPriceMetaMaxOrderBy;
  payingActivePlanOpenPriceMeta_min_order_by: PayingActivePlanOpenPriceMetaMinOrderBy;
  payingActivePlanOpenPriceMeta_order_by: PayingActivePlanOpenPriceMetaOrderBy;
  payingActivePlanOpenPriceMeta_select_column: PayingActivePlanOpenPriceMetaSelectColumn;
  payingActivePlanOpenPriceMeta_stddev_order_by: PayingActivePlanOpenPriceMetaStddevOrderBy;
  payingActivePlanOpenPriceMeta_stddev_pop_order_by: PayingActivePlanOpenPriceMetaStddevPopOrderBy;
  payingActivePlanOpenPriceMeta_stddev_samp_order_by: PayingActivePlanOpenPriceMetaStddevSampOrderBy;
  payingActivePlanOpenPriceMeta_stream_cursor_input: PayingActivePlanOpenPriceMetaStreamCursorInput;
  payingActivePlanOpenPriceMeta_stream_cursor_value_input: PayingActivePlanOpenPriceMetaStreamCursorValueInput;
  payingActivePlanOpenPriceMeta_sum_order_by: PayingActivePlanOpenPriceMetaSumOrderBy;
  payingActivePlanOpenPriceMeta_var_pop_order_by: PayingActivePlanOpenPriceMetaVarPopOrderBy;
  payingActivePlanOpenPriceMeta_var_samp_order_by: PayingActivePlanOpenPriceMetaVarSampOrderBy;
  payingActivePlanOpenPriceMeta_variance_order_by: PayingActivePlanOpenPriceMetaVarianceOrderBy;
  payingMenuOrderItem: ResolverTypeWrapper<PayingMenuOrderItem>;
  payingMenuOrderItem_aggregate_order_by: PayingMenuOrderItemAggregateOrderBy;
  payingMenuOrderItem_avg_order_by: PayingMenuOrderItemAvgOrderBy;
  payingMenuOrderItem_bool_exp: PayingMenuOrderItemBoolExp;
  payingMenuOrderItem_max_order_by: PayingMenuOrderItemMaxOrderBy;
  payingMenuOrderItem_min_order_by: PayingMenuOrderItemMinOrderBy;
  payingMenuOrderItem_order_by: PayingMenuOrderItemOrderBy;
  payingMenuOrderItem_select_column: PayingMenuOrderItemSelectColumn;
  payingMenuOrderItem_stddev_order_by: PayingMenuOrderItemStddevOrderBy;
  payingMenuOrderItem_stddev_pop_order_by: PayingMenuOrderItemStddevPopOrderBy;
  payingMenuOrderItem_stddev_samp_order_by: PayingMenuOrderItemStddevSampOrderBy;
  payingMenuOrderItem_stream_cursor_input: PayingMenuOrderItemStreamCursorInput;
  payingMenuOrderItem_stream_cursor_value_input: PayingMenuOrderItemStreamCursorValueInput;
  payingMenuOrderItem_sum_order_by: PayingMenuOrderItemSumOrderBy;
  payingMenuOrderItem_var_pop_order_by: PayingMenuOrderItemVarPopOrderBy;
  payingMenuOrderItem_var_samp_order_by: PayingMenuOrderItemVarSampOrderBy;
  payingMenuOrderItem_variance_order_by: PayingMenuOrderItemVarianceOrderBy;
  paymentReceiptRoleTable: ResolverTypeWrapper<PaymentReceiptRoleTable>;
  paymentReceiptRoleTable_aggregate_order_by: PaymentReceiptRoleTableAggregateOrderBy;
  paymentReceiptRoleTable_arr_rel_insert_input: PaymentReceiptRoleTableArrRelInsertInput;
  paymentReceiptRoleTable_avg_order_by: PaymentReceiptRoleTableAvgOrderBy;
  paymentReceiptRoleTable_bool_exp: PaymentReceiptRoleTableBoolExp;
  paymentReceiptRoleTable_constraint: PaymentReceiptRoleTableConstraint;
  paymentReceiptRoleTable_inc_input: PaymentReceiptRoleTableIncInput;
  paymentReceiptRoleTable_insert_input: PaymentReceiptRoleTableInsertInput;
  paymentReceiptRoleTable_max_order_by: PaymentReceiptRoleTableMaxOrderBy;
  paymentReceiptRoleTable_min_order_by: PaymentReceiptRoleTableMinOrderBy;
  paymentReceiptRoleTable_mutation_response: ResolverTypeWrapper<PaymentReceiptRoleTableMutationResponse>;
  paymentReceiptRoleTable_on_conflict: PaymentReceiptRoleTableOnConflict;
  paymentReceiptRoleTable_order_by: PaymentReceiptRoleTableOrderBy;
  paymentReceiptRoleTable_select_column: PaymentReceiptRoleTableSelectColumn;
  paymentReceiptRoleTable_set_input: PaymentReceiptRoleTableSetInput;
  paymentReceiptRoleTable_stddev_order_by: PaymentReceiptRoleTableStddevOrderBy;
  paymentReceiptRoleTable_stddev_pop_order_by: PaymentReceiptRoleTableStddevPopOrderBy;
  paymentReceiptRoleTable_stddev_samp_order_by: PaymentReceiptRoleTableStddevSampOrderBy;
  paymentReceiptRoleTable_stream_cursor_input: PaymentReceiptRoleTableStreamCursorInput;
  paymentReceiptRoleTable_stream_cursor_value_input: PaymentReceiptRoleTableStreamCursorValueInput;
  paymentReceiptRoleTable_sum_order_by: PaymentReceiptRoleTableSumOrderBy;
  paymentReceiptRoleTable_update_column: PaymentReceiptRoleTableUpdateColumn;
  paymentReceiptRoleTable_updates: PaymentReceiptRoleTableUpdates;
  paymentReceiptRoleTable_var_pop_order_by: PaymentReceiptRoleTableVarPopOrderBy;
  paymentReceiptRoleTable_var_samp_order_by: PaymentReceiptRoleTableVarSampOrderBy;
  paymentReceiptRoleTable_variance_order_by: PaymentReceiptRoleTableVarianceOrderBy;
  pikaichiConfig: ResolverTypeWrapper<PikaichiConfig>;
  pikaichiConfig_bool_exp: PikaichiConfigBoolExp;
  pikaichiConfig_constraint: PikaichiConfigConstraint;
  pikaichiConfig_inc_input: PikaichiConfigIncInput;
  pikaichiConfig_insert_input: PikaichiConfigInsertInput;
  pikaichiConfig_mutation_response: ResolverTypeWrapper<PikaichiConfigMutationResponse>;
  pikaichiConfig_obj_rel_insert_input: PikaichiConfigObjRelInsertInput;
  pikaichiConfig_on_conflict: PikaichiConfigOnConflict;
  pikaichiConfig_order_by: PikaichiConfigOrderBy;
  pikaichiConfig_pk_columns_input: PikaichiConfigPkColumnsInput;
  pikaichiConfig_select_column: PikaichiConfigSelectColumn;
  pikaichiConfig_set_input: PikaichiConfigSetInput;
  pikaichiConfig_stream_cursor_input: PikaichiConfigStreamCursorInput;
  pikaichiConfig_stream_cursor_value_input: PikaichiConfigStreamCursorValueInput;
  pikaichiConfig_update_column: PikaichiConfigUpdateColumn;
  pikaichiConfig_updates: PikaichiConfigUpdates;
  pikaichiMenu: ResolverTypeWrapper<PikaichiMenu>;
  pikaichiMenuChoice: ResolverTypeWrapper<PikaichiMenuChoice>;
  pikaichiMenuChoice_aggregate_order_by: PikaichiMenuChoiceAggregateOrderBy;
  pikaichiMenuChoice_arr_rel_insert_input: PikaichiMenuChoiceArrRelInsertInput;
  pikaichiMenuChoice_avg_order_by: PikaichiMenuChoiceAvgOrderBy;
  pikaichiMenuChoice_bool_exp: PikaichiMenuChoiceBoolExp;
  pikaichiMenuChoice_constraint: PikaichiMenuChoiceConstraint;
  pikaichiMenuChoice_inc_input: PikaichiMenuChoiceIncInput;
  pikaichiMenuChoice_insert_input: PikaichiMenuChoiceInsertInput;
  pikaichiMenuChoice_max_order_by: PikaichiMenuChoiceMaxOrderBy;
  pikaichiMenuChoice_min_order_by: PikaichiMenuChoiceMinOrderBy;
  pikaichiMenuChoice_mutation_response: ResolverTypeWrapper<PikaichiMenuChoiceMutationResponse>;
  pikaichiMenuChoice_on_conflict: PikaichiMenuChoiceOnConflict;
  pikaichiMenuChoice_order_by: PikaichiMenuChoiceOrderBy;
  pikaichiMenuChoice_pk_columns_input: PikaichiMenuChoicePkColumnsInput;
  pikaichiMenuChoice_select_column: PikaichiMenuChoiceSelectColumn;
  pikaichiMenuChoice_set_input: PikaichiMenuChoiceSetInput;
  pikaichiMenuChoice_stddev_order_by: PikaichiMenuChoiceStddevOrderBy;
  pikaichiMenuChoice_stddev_pop_order_by: PikaichiMenuChoiceStddevPopOrderBy;
  pikaichiMenuChoice_stddev_samp_order_by: PikaichiMenuChoiceStddevSampOrderBy;
  pikaichiMenuChoice_stream_cursor_input: PikaichiMenuChoiceStreamCursorInput;
  pikaichiMenuChoice_stream_cursor_value_input: PikaichiMenuChoiceStreamCursorValueInput;
  pikaichiMenuChoice_sum_order_by: PikaichiMenuChoiceSumOrderBy;
  pikaichiMenuChoice_update_column: PikaichiMenuChoiceUpdateColumn;
  pikaichiMenuChoice_updates: PikaichiMenuChoiceUpdates;
  pikaichiMenuChoice_var_pop_order_by: PikaichiMenuChoiceVarPopOrderBy;
  pikaichiMenuChoice_var_samp_order_by: PikaichiMenuChoiceVarSampOrderBy;
  pikaichiMenuChoice_variance_order_by: PikaichiMenuChoiceVarianceOrderBy;
  pikaichiMenuMenu: ResolverTypeWrapper<PikaichiMenuMenu>;
  pikaichiMenuMenu_aggregate_order_by: PikaichiMenuMenuAggregateOrderBy;
  pikaichiMenuMenu_arr_rel_insert_input: PikaichiMenuMenuArrRelInsertInput;
  pikaichiMenuMenu_avg_order_by: PikaichiMenuMenuAvgOrderBy;
  pikaichiMenuMenu_bool_exp: PikaichiMenuMenuBoolExp;
  pikaichiMenuMenu_constraint: PikaichiMenuMenuConstraint;
  pikaichiMenuMenu_inc_input: PikaichiMenuMenuIncInput;
  pikaichiMenuMenu_insert_input: PikaichiMenuMenuInsertInput;
  pikaichiMenuMenu_max_order_by: PikaichiMenuMenuMaxOrderBy;
  pikaichiMenuMenu_min_order_by: PikaichiMenuMenuMinOrderBy;
  pikaichiMenuMenu_mutation_response: ResolverTypeWrapper<PikaichiMenuMenuMutationResponse>;
  pikaichiMenuMenu_on_conflict: PikaichiMenuMenuOnConflict;
  pikaichiMenuMenu_order_by: PikaichiMenuMenuOrderBy;
  pikaichiMenuMenu_pk_columns_input: PikaichiMenuMenuPkColumnsInput;
  pikaichiMenuMenu_select_column: PikaichiMenuMenuSelectColumn;
  pikaichiMenuMenu_set_input: PikaichiMenuMenuSetInput;
  pikaichiMenuMenu_stddev_order_by: PikaichiMenuMenuStddevOrderBy;
  pikaichiMenuMenu_stddev_pop_order_by: PikaichiMenuMenuStddevPopOrderBy;
  pikaichiMenuMenu_stddev_samp_order_by: PikaichiMenuMenuStddevSampOrderBy;
  pikaichiMenuMenu_stream_cursor_input: PikaichiMenuMenuStreamCursorInput;
  pikaichiMenuMenu_stream_cursor_value_input: PikaichiMenuMenuStreamCursorValueInput;
  pikaichiMenuMenu_sum_order_by: PikaichiMenuMenuSumOrderBy;
  pikaichiMenuMenu_update_column: PikaichiMenuMenuUpdateColumn;
  pikaichiMenuMenu_updates: PikaichiMenuMenuUpdates;
  pikaichiMenuMenu_var_pop_order_by: PikaichiMenuMenuVarPopOrderBy;
  pikaichiMenuMenu_var_samp_order_by: PikaichiMenuMenuVarSampOrderBy;
  pikaichiMenuMenu_variance_order_by: PikaichiMenuMenuVarianceOrderBy;
  pikaichiMenuPlan: ResolverTypeWrapper<PikaichiMenuPlan>;
  pikaichiMenuPlanChoice: ResolverTypeWrapper<PikaichiMenuPlanChoice>;
  pikaichiMenuPlanChoice_aggregate_order_by: PikaichiMenuPlanChoiceAggregateOrderBy;
  pikaichiMenuPlanChoice_arr_rel_insert_input: PikaichiMenuPlanChoiceArrRelInsertInput;
  pikaichiMenuPlanChoice_avg_order_by: PikaichiMenuPlanChoiceAvgOrderBy;
  pikaichiMenuPlanChoice_bool_exp: PikaichiMenuPlanChoiceBoolExp;
  pikaichiMenuPlanChoice_constraint: PikaichiMenuPlanChoiceConstraint;
  pikaichiMenuPlanChoice_inc_input: PikaichiMenuPlanChoiceIncInput;
  pikaichiMenuPlanChoice_insert_input: PikaichiMenuPlanChoiceInsertInput;
  pikaichiMenuPlanChoice_max_order_by: PikaichiMenuPlanChoiceMaxOrderBy;
  pikaichiMenuPlanChoice_min_order_by: PikaichiMenuPlanChoiceMinOrderBy;
  pikaichiMenuPlanChoice_mutation_response: ResolverTypeWrapper<PikaichiMenuPlanChoiceMutationResponse>;
  pikaichiMenuPlanChoice_on_conflict: PikaichiMenuPlanChoiceOnConflict;
  pikaichiMenuPlanChoice_order_by: PikaichiMenuPlanChoiceOrderBy;
  pikaichiMenuPlanChoice_pk_columns_input: PikaichiMenuPlanChoicePkColumnsInput;
  pikaichiMenuPlanChoice_select_column: PikaichiMenuPlanChoiceSelectColumn;
  pikaichiMenuPlanChoice_set_input: PikaichiMenuPlanChoiceSetInput;
  pikaichiMenuPlanChoice_stddev_order_by: PikaichiMenuPlanChoiceStddevOrderBy;
  pikaichiMenuPlanChoice_stddev_pop_order_by: PikaichiMenuPlanChoiceStddevPopOrderBy;
  pikaichiMenuPlanChoice_stddev_samp_order_by: PikaichiMenuPlanChoiceStddevSampOrderBy;
  pikaichiMenuPlanChoice_stream_cursor_input: PikaichiMenuPlanChoiceStreamCursorInput;
  pikaichiMenuPlanChoice_stream_cursor_value_input: PikaichiMenuPlanChoiceStreamCursorValueInput;
  pikaichiMenuPlanChoice_sum_order_by: PikaichiMenuPlanChoiceSumOrderBy;
  pikaichiMenuPlanChoice_update_column: PikaichiMenuPlanChoiceUpdateColumn;
  pikaichiMenuPlanChoice_updates: PikaichiMenuPlanChoiceUpdates;
  pikaichiMenuPlanChoice_var_pop_order_by: PikaichiMenuPlanChoiceVarPopOrderBy;
  pikaichiMenuPlanChoice_var_samp_order_by: PikaichiMenuPlanChoiceVarSampOrderBy;
  pikaichiMenuPlanChoice_variance_order_by: PikaichiMenuPlanChoiceVarianceOrderBy;
  pikaichiMenuPlan_aggregate_order_by: PikaichiMenuPlanAggregateOrderBy;
  pikaichiMenuPlan_arr_rel_insert_input: PikaichiMenuPlanArrRelInsertInput;
  pikaichiMenuPlan_avg_order_by: PikaichiMenuPlanAvgOrderBy;
  pikaichiMenuPlan_bool_exp: PikaichiMenuPlanBoolExp;
  pikaichiMenuPlan_constraint: PikaichiMenuPlanConstraint;
  pikaichiMenuPlan_inc_input: PikaichiMenuPlanIncInput;
  pikaichiMenuPlan_insert_input: PikaichiMenuPlanInsertInput;
  pikaichiMenuPlan_max_order_by: PikaichiMenuPlanMaxOrderBy;
  pikaichiMenuPlan_min_order_by: PikaichiMenuPlanMinOrderBy;
  pikaichiMenuPlan_mutation_response: ResolverTypeWrapper<PikaichiMenuPlanMutationResponse>;
  pikaichiMenuPlan_on_conflict: PikaichiMenuPlanOnConflict;
  pikaichiMenuPlan_order_by: PikaichiMenuPlanOrderBy;
  pikaichiMenuPlan_pk_columns_input: PikaichiMenuPlanPkColumnsInput;
  pikaichiMenuPlan_select_column: PikaichiMenuPlanSelectColumn;
  pikaichiMenuPlan_set_input: PikaichiMenuPlanSetInput;
  pikaichiMenuPlan_stddev_order_by: PikaichiMenuPlanStddevOrderBy;
  pikaichiMenuPlan_stddev_pop_order_by: PikaichiMenuPlanStddevPopOrderBy;
  pikaichiMenuPlan_stddev_samp_order_by: PikaichiMenuPlanStddevSampOrderBy;
  pikaichiMenuPlan_stream_cursor_input: PikaichiMenuPlanStreamCursorInput;
  pikaichiMenuPlan_stream_cursor_value_input: PikaichiMenuPlanStreamCursorValueInput;
  pikaichiMenuPlan_sum_order_by: PikaichiMenuPlanSumOrderBy;
  pikaichiMenuPlan_update_column: PikaichiMenuPlanUpdateColumn;
  pikaichiMenuPlan_updates: PikaichiMenuPlanUpdates;
  pikaichiMenuPlan_var_pop_order_by: PikaichiMenuPlanVarPopOrderBy;
  pikaichiMenuPlan_var_samp_order_by: PikaichiMenuPlanVarSampOrderBy;
  pikaichiMenuPlan_variance_order_by: PikaichiMenuPlanVarianceOrderBy;
  pikaichiMenu_aggregate_order_by: PikaichiMenuAggregateOrderBy;
  pikaichiMenu_arr_rel_insert_input: PikaichiMenuArrRelInsertInput;
  pikaichiMenu_bool_exp: PikaichiMenuBoolExp;
  pikaichiMenu_constraint: PikaichiMenuConstraint;
  pikaichiMenu_insert_input: PikaichiMenuInsertInput;
  pikaichiMenu_max_order_by: PikaichiMenuMaxOrderBy;
  pikaichiMenu_min_order_by: PikaichiMenuMinOrderBy;
  pikaichiMenu_mutation_response: ResolverTypeWrapper<PikaichiMenuMutationResponse>;
  pikaichiMenu_obj_rel_insert_input: PikaichiMenuObjRelInsertInput;
  pikaichiMenu_on_conflict: PikaichiMenuOnConflict;
  pikaichiMenu_order_by: PikaichiMenuOrderBy;
  pikaichiMenu_pk_columns_input: PikaichiMenuPkColumnsInput;
  pikaichiMenu_select_column: PikaichiMenuSelectColumn;
  pikaichiMenu_set_input: PikaichiMenuSetInput;
  pikaichiMenu_stream_cursor_input: PikaichiMenuStreamCursorInput;
  pikaichiMenu_stream_cursor_value_input: PikaichiMenuStreamCursorValueInput;
  pikaichiMenu_update_column: PikaichiMenuUpdateColumn;
  pikaichiMenu_updates: PikaichiMenuUpdates;
  pikaichiOnSitePaymentDetailType: ResolverTypeWrapper<PikaichiOnSitePaymentDetailType>;
  pikaichiOnSitePaymentDetailType_aggregate_order_by: PikaichiOnSitePaymentDetailTypeAggregateOrderBy;
  pikaichiOnSitePaymentDetailType_arr_rel_insert_input: PikaichiOnSitePaymentDetailTypeArrRelInsertInput;
  pikaichiOnSitePaymentDetailType_bool_exp: PikaichiOnSitePaymentDetailTypeBoolExp;
  pikaichiOnSitePaymentDetailType_constraint: PikaichiOnSitePaymentDetailTypeConstraint;
  pikaichiOnSitePaymentDetailType_insert_input: PikaichiOnSitePaymentDetailTypeInsertInput;
  pikaichiOnSitePaymentDetailType_max_order_by: PikaichiOnSitePaymentDetailTypeMaxOrderBy;
  pikaichiOnSitePaymentDetailType_min_order_by: PikaichiOnSitePaymentDetailTypeMinOrderBy;
  pikaichiOnSitePaymentDetailType_mutation_response: ResolverTypeWrapper<PikaichiOnSitePaymentDetailTypeMutationResponse>;
  pikaichiOnSitePaymentDetailType_on_conflict: PikaichiOnSitePaymentDetailTypeOnConflict;
  pikaichiOnSitePaymentDetailType_order_by: PikaichiOnSitePaymentDetailTypeOrderBy;
  pikaichiOnSitePaymentDetailType_pk_columns_input: PikaichiOnSitePaymentDetailTypePkColumnsInput;
  pikaichiOnSitePaymentDetailType_select_column: PikaichiOnSitePaymentDetailTypeSelectColumn;
  pikaichiOnSitePaymentDetailType_set_input: PikaichiOnSitePaymentDetailTypeSetInput;
  pikaichiOnSitePaymentDetailType_stream_cursor_input: PikaichiOnSitePaymentDetailTypeStreamCursorInput;
  pikaichiOnSitePaymentDetailType_stream_cursor_value_input: PikaichiOnSitePaymentDetailTypeStreamCursorValueInput;
  pikaichiOnSitePaymentDetailType_update_column: PikaichiOnSitePaymentDetailTypeUpdateColumn;
  pikaichiOnSitePaymentDetailType_updates: PikaichiOnSitePaymentDetailTypeUpdates;
  pikaichiOnSitePaymentDiscountType: ResolverTypeWrapper<PikaichiOnSitePaymentDiscountType>;
  pikaichiOnSitePaymentDiscountType_aggregate_order_by: PikaichiOnSitePaymentDiscountTypeAggregateOrderBy;
  pikaichiOnSitePaymentDiscountType_arr_rel_insert_input: PikaichiOnSitePaymentDiscountTypeArrRelInsertInput;
  pikaichiOnSitePaymentDiscountType_avg_order_by: PikaichiOnSitePaymentDiscountTypeAvgOrderBy;
  pikaichiOnSitePaymentDiscountType_bool_exp: PikaichiOnSitePaymentDiscountTypeBoolExp;
  pikaichiOnSitePaymentDiscountType_constraint: PikaichiOnSitePaymentDiscountTypeConstraint;
  pikaichiOnSitePaymentDiscountType_inc_input: PikaichiOnSitePaymentDiscountTypeIncInput;
  pikaichiOnSitePaymentDiscountType_insert_input: PikaichiOnSitePaymentDiscountTypeInsertInput;
  pikaichiOnSitePaymentDiscountType_max_order_by: PikaichiOnSitePaymentDiscountTypeMaxOrderBy;
  pikaichiOnSitePaymentDiscountType_min_order_by: PikaichiOnSitePaymentDiscountTypeMinOrderBy;
  pikaichiOnSitePaymentDiscountType_mutation_response: ResolverTypeWrapper<PikaichiOnSitePaymentDiscountTypeMutationResponse>;
  pikaichiOnSitePaymentDiscountType_on_conflict: PikaichiOnSitePaymentDiscountTypeOnConflict;
  pikaichiOnSitePaymentDiscountType_order_by: PikaichiOnSitePaymentDiscountTypeOrderBy;
  pikaichiOnSitePaymentDiscountType_pk_columns_input: PikaichiOnSitePaymentDiscountTypePkColumnsInput;
  pikaichiOnSitePaymentDiscountType_select_column: PikaichiOnSitePaymentDiscountTypeSelectColumn;
  pikaichiOnSitePaymentDiscountType_set_input: PikaichiOnSitePaymentDiscountTypeSetInput;
  pikaichiOnSitePaymentDiscountType_stddev_order_by: PikaichiOnSitePaymentDiscountTypeStddevOrderBy;
  pikaichiOnSitePaymentDiscountType_stddev_pop_order_by: PikaichiOnSitePaymentDiscountTypeStddevPopOrderBy;
  pikaichiOnSitePaymentDiscountType_stddev_samp_order_by: PikaichiOnSitePaymentDiscountTypeStddevSampOrderBy;
  pikaichiOnSitePaymentDiscountType_stream_cursor_input: PikaichiOnSitePaymentDiscountTypeStreamCursorInput;
  pikaichiOnSitePaymentDiscountType_stream_cursor_value_input: PikaichiOnSitePaymentDiscountTypeStreamCursorValueInput;
  pikaichiOnSitePaymentDiscountType_sum_order_by: PikaichiOnSitePaymentDiscountTypeSumOrderBy;
  pikaichiOnSitePaymentDiscountType_update_column: PikaichiOnSitePaymentDiscountTypeUpdateColumn;
  pikaichiOnSitePaymentDiscountType_updates: PikaichiOnSitePaymentDiscountTypeUpdates;
  pikaichiOnSitePaymentDiscountType_var_pop_order_by: PikaichiOnSitePaymentDiscountTypeVarPopOrderBy;
  pikaichiOnSitePaymentDiscountType_var_samp_order_by: PikaichiOnSitePaymentDiscountTypeVarSampOrderBy;
  pikaichiOnSitePaymentDiscountType_variance_order_by: PikaichiOnSitePaymentDiscountTypeVarianceOrderBy;
  plan: ResolverTypeWrapper<Plan>;
  planChoice: ResolverTypeWrapper<PlanChoice>;
  planChoice_aggregate: ResolverTypeWrapper<PlanChoiceAggregate>;
  planChoice_aggregate_bool_exp: PlanChoiceAggregateBoolExp;
  planChoice_aggregate_bool_exp_bool_and: PlanChoiceAggregateBoolExpBoolAnd;
  planChoice_aggregate_bool_exp_bool_or: PlanChoiceAggregateBoolExpBoolOr;
  planChoice_aggregate_bool_exp_count: PlanChoiceAggregateBoolExpCount;
  planChoice_aggregate_fields: ResolverTypeWrapper<PlanChoiceAggregateFields>;
  planChoice_aggregate_order_by: PlanChoiceAggregateOrderBy;
  planChoice_arr_rel_insert_input: PlanChoiceArrRelInsertInput;
  planChoice_avg_fields: ResolverTypeWrapper<PlanChoiceAvgFields>;
  planChoice_avg_order_by: PlanChoiceAvgOrderBy;
  planChoice_bool_exp: PlanChoiceBoolExp;
  planChoice_constraint: PlanChoiceConstraint;
  planChoice_inc_input: PlanChoiceIncInput;
  planChoice_insert_input: PlanChoiceInsertInput;
  planChoice_max_fields: ResolverTypeWrapper<PlanChoiceMaxFields>;
  planChoice_max_order_by: PlanChoiceMaxOrderBy;
  planChoice_min_fields: ResolverTypeWrapper<PlanChoiceMinFields>;
  planChoice_min_order_by: PlanChoiceMinOrderBy;
  planChoice_mutation_response: ResolverTypeWrapper<PlanChoiceMutationResponse>;
  planChoice_obj_rel_insert_input: PlanChoiceObjRelInsertInput;
  planChoice_on_conflict: PlanChoiceOnConflict;
  planChoice_order_by: PlanChoiceOrderBy;
  planChoice_pk_columns_input: PlanChoicePkColumnsInput;
  planChoice_select_column: PlanChoiceSelectColumn;
  planChoice_select_column_planChoice_aggregate_bool_exp_bool_and_arguments_columns: PlanChoiceSelectColumnPlanChoiceAggregateBoolExpBoolAndArgumentsColumnsType;
  planChoice_select_column_planChoice_aggregate_bool_exp_bool_or_arguments_columns: PlanChoiceSelectColumnPlanChoiceAggregateBoolExpBoolOrArgumentsColumnsType;
  planChoice_set_input: PlanChoiceSetInput;
  planChoice_stddev_fields: ResolverTypeWrapper<PlanChoiceStddevFields>;
  planChoice_stddev_order_by: PlanChoiceStddevOrderBy;
  planChoice_stddev_pop_fields: ResolverTypeWrapper<PlanChoiceStddevPopFields>;
  planChoice_stddev_pop_order_by: PlanChoiceStddevPopOrderBy;
  planChoice_stddev_samp_fields: ResolverTypeWrapper<PlanChoiceStddevSampFields>;
  planChoice_stddev_samp_order_by: PlanChoiceStddevSampOrderBy;
  planChoice_stream_cursor_input: PlanChoiceStreamCursorInput;
  planChoice_stream_cursor_value_input: PlanChoiceStreamCursorValueInput;
  planChoice_sum_fields: ResolverTypeWrapper<PlanChoiceSumFields>;
  planChoice_sum_order_by: PlanChoiceSumOrderBy;
  planChoice_update_column: PlanChoiceUpdateColumn;
  planChoice_updates: PlanChoiceUpdates;
  planChoice_var_pop_fields: ResolverTypeWrapper<PlanChoiceVarPopFields>;
  planChoice_var_pop_order_by: PlanChoiceVarPopOrderBy;
  planChoice_var_samp_fields: ResolverTypeWrapper<PlanChoiceVarSampFields>;
  planChoice_var_samp_order_by: PlanChoiceVarSampOrderBy;
  planChoice_variance_fields: ResolverTypeWrapper<PlanChoiceVarianceFields>;
  planChoice_variance_order_by: PlanChoiceVarianceOrderBy;
  planFirstOrderCategoryMenu: ResolverTypeWrapper<PlanFirstOrderCategoryMenu>;
  planFirstOrderCategoryMenu_aggregate_order_by: PlanFirstOrderCategoryMenuAggregateOrderBy;
  planFirstOrderCategoryMenu_arr_rel_insert_input: PlanFirstOrderCategoryMenuArrRelInsertInput;
  planFirstOrderCategoryMenu_avg_order_by: PlanFirstOrderCategoryMenuAvgOrderBy;
  planFirstOrderCategoryMenu_bool_exp: PlanFirstOrderCategoryMenuBoolExp;
  planFirstOrderCategoryMenu_constraint: PlanFirstOrderCategoryMenuConstraint;
  planFirstOrderCategoryMenu_inc_input: PlanFirstOrderCategoryMenuIncInput;
  planFirstOrderCategoryMenu_insert_input: PlanFirstOrderCategoryMenuInsertInput;
  planFirstOrderCategoryMenu_max_order_by: PlanFirstOrderCategoryMenuMaxOrderBy;
  planFirstOrderCategoryMenu_min_order_by: PlanFirstOrderCategoryMenuMinOrderBy;
  planFirstOrderCategoryMenu_mutation_response: ResolverTypeWrapper<PlanFirstOrderCategoryMenuMutationResponse>;
  planFirstOrderCategoryMenu_on_conflict: PlanFirstOrderCategoryMenuOnConflict;
  planFirstOrderCategoryMenu_order_by: PlanFirstOrderCategoryMenuOrderBy;
  planFirstOrderCategoryMenu_pk_columns_input: PlanFirstOrderCategoryMenuPkColumnsInput;
  planFirstOrderCategoryMenu_select_column: PlanFirstOrderCategoryMenuSelectColumn;
  planFirstOrderCategoryMenu_set_input: PlanFirstOrderCategoryMenuSetInput;
  planFirstOrderCategoryMenu_stddev_order_by: PlanFirstOrderCategoryMenuStddevOrderBy;
  planFirstOrderCategoryMenu_stddev_pop_order_by: PlanFirstOrderCategoryMenuStddevPopOrderBy;
  planFirstOrderCategoryMenu_stddev_samp_order_by: PlanFirstOrderCategoryMenuStddevSampOrderBy;
  planFirstOrderCategoryMenu_stream_cursor_input: PlanFirstOrderCategoryMenuStreamCursorInput;
  planFirstOrderCategoryMenu_stream_cursor_value_input: PlanFirstOrderCategoryMenuStreamCursorValueInput;
  planFirstOrderCategoryMenu_sum_order_by: PlanFirstOrderCategoryMenuSumOrderBy;
  planFirstOrderCategoryMenu_update_column: PlanFirstOrderCategoryMenuUpdateColumn;
  planFirstOrderCategoryMenu_updates: PlanFirstOrderCategoryMenuUpdates;
  planFirstOrderCategoryMenu_var_pop_order_by: PlanFirstOrderCategoryMenuVarPopOrderBy;
  planFirstOrderCategoryMenu_var_samp_order_by: PlanFirstOrderCategoryMenuVarSampOrderBy;
  planFirstOrderCategoryMenu_variance_order_by: PlanFirstOrderCategoryMenuVarianceOrderBy;
  planGroup: ResolverTypeWrapper<PlanGroup>;
  planGroup_aggregate_order_by: PlanGroupAggregateOrderBy;
  planGroup_arr_rel_insert_input: PlanGroupArrRelInsertInput;
  planGroup_avg_order_by: PlanGroupAvgOrderBy;
  planGroup_bool_exp: PlanGroupBoolExp;
  planGroup_constraint: PlanGroupConstraint;
  planGroup_inc_input: PlanGroupIncInput;
  planGroup_insert_input: PlanGroupInsertInput;
  planGroup_max_order_by: PlanGroupMaxOrderBy;
  planGroup_min_order_by: PlanGroupMinOrderBy;
  planGroup_mutation_response: ResolverTypeWrapper<PlanGroupMutationResponse>;
  planGroup_obj_rel_insert_input: PlanGroupObjRelInsertInput;
  planGroup_on_conflict: PlanGroupOnConflict;
  planGroup_order_by: PlanGroupOrderBy;
  planGroup_pk_columns_input: PlanGroupPkColumnsInput;
  planGroup_select_column: PlanGroupSelectColumn;
  planGroup_set_input: PlanGroupSetInput;
  planGroup_stddev_order_by: PlanGroupStddevOrderBy;
  planGroup_stddev_pop_order_by: PlanGroupStddevPopOrderBy;
  planGroup_stddev_samp_order_by: PlanGroupStddevSampOrderBy;
  planGroup_stream_cursor_input: PlanGroupStreamCursorInput;
  planGroup_stream_cursor_value_input: PlanGroupStreamCursorValueInput;
  planGroup_sum_order_by: PlanGroupSumOrderBy;
  planGroup_update_column: PlanGroupUpdateColumn;
  planGroup_updates: PlanGroupUpdates;
  planGroup_var_pop_order_by: PlanGroupVarPopOrderBy;
  planGroup_var_samp_order_by: PlanGroupVarSampOrderBy;
  planGroup_variance_order_by: PlanGroupVarianceOrderBy;
  planMenuCategory: ResolverTypeWrapper<PlanMenuCategory>;
  planMenuCategory_aggregate_order_by: PlanMenuCategoryAggregateOrderBy;
  planMenuCategory_arr_rel_insert_input: PlanMenuCategoryArrRelInsertInput;
  planMenuCategory_avg_order_by: PlanMenuCategoryAvgOrderBy;
  planMenuCategory_bool_exp: PlanMenuCategoryBoolExp;
  planMenuCategory_constraint: PlanMenuCategoryConstraint;
  planMenuCategory_inc_input: PlanMenuCategoryIncInput;
  planMenuCategory_insert_input: PlanMenuCategoryInsertInput;
  planMenuCategory_max_order_by: PlanMenuCategoryMaxOrderBy;
  planMenuCategory_min_order_by: PlanMenuCategoryMinOrderBy;
  planMenuCategory_mutation_response: ResolverTypeWrapper<PlanMenuCategoryMutationResponse>;
  planMenuCategory_on_conflict: PlanMenuCategoryOnConflict;
  planMenuCategory_order_by: PlanMenuCategoryOrderBy;
  planMenuCategory_pk_columns_input: PlanMenuCategoryPkColumnsInput;
  planMenuCategory_select_column: PlanMenuCategorySelectColumn;
  planMenuCategory_set_input: PlanMenuCategorySetInput;
  planMenuCategory_stddev_order_by: PlanMenuCategoryStddevOrderBy;
  planMenuCategory_stddev_pop_order_by: PlanMenuCategoryStddevPopOrderBy;
  planMenuCategory_stddev_samp_order_by: PlanMenuCategoryStddevSampOrderBy;
  planMenuCategory_stream_cursor_input: PlanMenuCategoryStreamCursorInput;
  planMenuCategory_stream_cursor_value_input: PlanMenuCategoryStreamCursorValueInput;
  planMenuCategory_sum_order_by: PlanMenuCategorySumOrderBy;
  planMenuCategory_update_column: PlanMenuCategoryUpdateColumn;
  planMenuCategory_updates: PlanMenuCategoryUpdates;
  planMenuCategory_var_pop_order_by: PlanMenuCategoryVarPopOrderBy;
  planMenuCategory_var_samp_order_by: PlanMenuCategoryVarSampOrderBy;
  planMenuCategory_variance_order_by: PlanMenuCategoryVarianceOrderBy;
  planOption: ResolverTypeWrapper<PlanOption>;
  planOption_aggregate: ResolverTypeWrapper<PlanOptionAggregate>;
  planOption_aggregate_bool_exp: PlanOptionAggregateBoolExp;
  planOption_aggregate_bool_exp_bool_and: PlanOptionAggregateBoolExpBoolAnd;
  planOption_aggregate_bool_exp_bool_or: PlanOptionAggregateBoolExpBoolOr;
  planOption_aggregate_bool_exp_count: PlanOptionAggregateBoolExpCount;
  planOption_aggregate_fields: ResolverTypeWrapper<PlanOptionAggregateFields>;
  planOption_aggregate_order_by: PlanOptionAggregateOrderBy;
  planOption_arr_rel_insert_input: PlanOptionArrRelInsertInput;
  planOption_avg_fields: ResolverTypeWrapper<PlanOptionAvgFields>;
  planOption_avg_order_by: PlanOptionAvgOrderBy;
  planOption_bool_exp: PlanOptionBoolExp;
  planOption_constraint: PlanOptionConstraint;
  planOption_inc_input: PlanOptionIncInput;
  planOption_insert_input: PlanOptionInsertInput;
  planOption_max_fields: ResolverTypeWrapper<PlanOptionMaxFields>;
  planOption_max_order_by: PlanOptionMaxOrderBy;
  planOption_min_fields: ResolverTypeWrapper<PlanOptionMinFields>;
  planOption_min_order_by: PlanOptionMinOrderBy;
  planOption_mutation_response: ResolverTypeWrapper<PlanOptionMutationResponse>;
  planOption_obj_rel_insert_input: PlanOptionObjRelInsertInput;
  planOption_on_conflict: PlanOptionOnConflict;
  planOption_order_by: PlanOptionOrderBy;
  planOption_pk_columns_input: PlanOptionPkColumnsInput;
  planOption_select_column: PlanOptionSelectColumn;
  planOption_select_column_planOption_aggregate_bool_exp_bool_and_arguments_columns: PlanOptionSelectColumnPlanOptionAggregateBoolExpBoolAndArgumentsColumnsType;
  planOption_select_column_planOption_aggregate_bool_exp_bool_or_arguments_columns: PlanOptionSelectColumnPlanOptionAggregateBoolExpBoolOrArgumentsColumnsType;
  planOption_set_input: PlanOptionSetInput;
  planOption_stddev_fields: ResolverTypeWrapper<PlanOptionStddevFields>;
  planOption_stddev_order_by: PlanOptionStddevOrderBy;
  planOption_stddev_pop_fields: ResolverTypeWrapper<PlanOptionStddevPopFields>;
  planOption_stddev_pop_order_by: PlanOptionStddevPopOrderBy;
  planOption_stddev_samp_fields: ResolverTypeWrapper<PlanOptionStddevSampFields>;
  planOption_stddev_samp_order_by: PlanOptionStddevSampOrderBy;
  planOption_stream_cursor_input: PlanOptionStreamCursorInput;
  planOption_stream_cursor_value_input: PlanOptionStreamCursorValueInput;
  planOption_sum_fields: ResolverTypeWrapper<PlanOptionSumFields>;
  planOption_sum_order_by: PlanOptionSumOrderBy;
  planOption_update_column: PlanOptionUpdateColumn;
  planOption_updates: PlanOptionUpdates;
  planOption_var_pop_fields: ResolverTypeWrapper<PlanOptionVarPopFields>;
  planOption_var_pop_order_by: PlanOptionVarPopOrderBy;
  planOption_var_samp_fields: ResolverTypeWrapper<PlanOptionVarSampFields>;
  planOption_var_samp_order_by: PlanOptionVarSampOrderBy;
  planOption_variance_fields: ResolverTypeWrapper<PlanOptionVarianceFields>;
  planOption_variance_order_by: PlanOptionVarianceOrderBy;
  plan_aggregate_order_by: PlanAggregateOrderBy;
  plan_arr_rel_insert_input: PlanArrRelInsertInput;
  plan_avg_order_by: PlanAvgOrderBy;
  plan_bool_exp: PlanBoolExp;
  plan_constraint: PlanConstraint;
  plan_inc_input: PlanIncInput;
  plan_insert_input: PlanInsertInput;
  plan_max_order_by: PlanMaxOrderBy;
  plan_menu_categories_category: ResolverTypeWrapper<PlanMenuCategoriesCategory>;
  plan_menu_categories_category_aggregate_order_by: PlanMenuCategoriesCategoryAggregateOrderBy;
  plan_menu_categories_category_arr_rel_insert_input: PlanMenuCategoriesCategoryArrRelInsertInput;
  plan_menu_categories_category_avg_order_by: PlanMenuCategoriesCategoryAvgOrderBy;
  plan_menu_categories_category_bool_exp: PlanMenuCategoriesCategoryBoolExp;
  plan_menu_categories_category_inc_input: PlanMenuCategoriesCategoryIncInput;
  plan_menu_categories_category_insert_input: PlanMenuCategoriesCategoryInsertInput;
  plan_menu_categories_category_max_order_by: PlanMenuCategoriesCategoryMaxOrderBy;
  plan_menu_categories_category_min_order_by: PlanMenuCategoriesCategoryMinOrderBy;
  plan_menu_categories_category_mutation_response: ResolverTypeWrapper<PlanMenuCategoriesCategoryMutationResponse>;
  plan_menu_categories_category_order_by: PlanMenuCategoriesCategoryOrderBy;
  plan_menu_categories_category_select_column: PlanMenuCategoriesCategorySelectColumn;
  plan_menu_categories_category_set_input: PlanMenuCategoriesCategorySetInput;
  plan_menu_categories_category_stddev_order_by: PlanMenuCategoriesCategoryStddevOrderBy;
  plan_menu_categories_category_stddev_pop_order_by: PlanMenuCategoriesCategoryStddevPopOrderBy;
  plan_menu_categories_category_stddev_samp_order_by: PlanMenuCategoriesCategoryStddevSampOrderBy;
  plan_menu_categories_category_stream_cursor_input: PlanMenuCategoriesCategoryStreamCursorInput;
  plan_menu_categories_category_stream_cursor_value_input: PlanMenuCategoriesCategoryStreamCursorValueInput;
  plan_menu_categories_category_sum_order_by: PlanMenuCategoriesCategorySumOrderBy;
  plan_menu_categories_category_updates: PlanMenuCategoriesCategoryUpdates;
  plan_menu_categories_category_var_pop_order_by: PlanMenuCategoriesCategoryVarPopOrderBy;
  plan_menu_categories_category_var_samp_order_by: PlanMenuCategoriesCategoryVarSampOrderBy;
  plan_menu_categories_category_variance_order_by: PlanMenuCategoriesCategoryVarianceOrderBy;
  plan_min_order_by: PlanMinOrderBy;
  plan_mutation_response: ResolverTypeWrapper<PlanMutationResponse>;
  plan_obj_rel_insert_input: PlanObjRelInsertInput;
  plan_on_conflict: PlanOnConflict;
  plan_order_by: PlanOrderBy;
  plan_pk_columns_input: PlanPkColumnsInput;
  plan_select_column: PlanSelectColumn;
  plan_set_input: PlanSetInput;
  plan_stddev_order_by: PlanStddevOrderBy;
  plan_stddev_pop_order_by: PlanStddevPopOrderBy;
  plan_stddev_samp_order_by: PlanStddevSampOrderBy;
  plan_stream_cursor_input: PlanStreamCursorInput;
  plan_stream_cursor_value_input: PlanStreamCursorValueInput;
  plan_sum_order_by: PlanSumOrderBy;
  plan_update_column: PlanUpdateColumn;
  plan_updates: PlanUpdates;
  plan_var_pop_order_by: PlanVarPopOrderBy;
  plan_var_samp_order_by: PlanVarSampOrderBy;
  plan_variance_order_by: PlanVarianceOrderBy;
  query_root: ResolverTypeWrapper<{}>;
  question: ResolverTypeWrapper<Question>;
  questionAnswer: ResolverTypeWrapper<QuestionAnswer>;
  questionAnswer_aggregate_order_by: QuestionAnswerAggregateOrderBy;
  questionAnswer_bool_exp: QuestionAnswerBoolExp;
  questionAnswer_max_order_by: QuestionAnswerMaxOrderBy;
  questionAnswer_min_order_by: QuestionAnswerMinOrderBy;
  questionAnswer_order_by: QuestionAnswerOrderBy;
  questionAnswer_select_column: QuestionAnswerSelectColumn;
  questionAnswer_stream_cursor_input: QuestionAnswerStreamCursorInput;
  questionAnswer_stream_cursor_value_input: QuestionAnswerStreamCursorValueInput;
  questionCategory_enum: QuestionCategoryEnum;
  questionCategory_enum_comparison_exp: QuestionCategoryEnumComparisonExp;
  questionType_enum: QuestionTypeEnum;
  questionType_enum_comparison_exp: QuestionTypeEnumComparisonExp;
  question_aggregate_order_by: QuestionAggregateOrderBy;
  question_bool_exp: QuestionBoolExp;
  question_max_order_by: QuestionMaxOrderBy;
  question_min_order_by: QuestionMinOrderBy;
  question_order_by: QuestionOrderBy;
  question_select_column: QuestionSelectColumn;
  question_stream_cursor_input: QuestionStreamCursorInput;
  question_stream_cursor_value_input: QuestionStreamCursorValueInput;
  questionnaire: ResolverTypeWrapper<Questionnaire>;
  questionnaireAnswer: ResolverTypeWrapper<QuestionnaireAnswer>;
  questionnaireAnswer_aggregate_order_by: QuestionnaireAnswerAggregateOrderBy;
  questionnaireAnswer_bool_exp: QuestionnaireAnswerBoolExp;
  questionnaireAnswer_max_order_by: QuestionnaireAnswerMaxOrderBy;
  questionnaireAnswer_min_order_by: QuestionnaireAnswerMinOrderBy;
  questionnaireAnswer_order_by: QuestionnaireAnswerOrderBy;
  questionnaireAnswer_select_column: QuestionnaireAnswerSelectColumn;
  questionnaireAnswer_stream_cursor_input: QuestionnaireAnswerStreamCursorInput;
  questionnaireAnswer_stream_cursor_value_input: QuestionnaireAnswerStreamCursorValueInput;
  questionnaireConfig: ResolverTypeWrapper<QuestionnaireConfig>;
  questionnaireConfig_aggregate_order_by: QuestionnaireConfigAggregateOrderBy;
  questionnaireConfig_arr_rel_insert_input: QuestionnaireConfigArrRelInsertInput;
  questionnaireConfig_bool_exp: QuestionnaireConfigBoolExp;
  questionnaireConfig_constraint: QuestionnaireConfigConstraint;
  questionnaireConfig_insert_input: QuestionnaireConfigInsertInput;
  questionnaireConfig_max_order_by: QuestionnaireConfigMaxOrderBy;
  questionnaireConfig_min_order_by: QuestionnaireConfigMinOrderBy;
  questionnaireConfig_mutation_response: ResolverTypeWrapper<QuestionnaireConfigMutationResponse>;
  questionnaireConfig_on_conflict: QuestionnaireConfigOnConflict;
  questionnaireConfig_order_by: QuestionnaireConfigOrderBy;
  questionnaireConfig_pk_columns_input: QuestionnaireConfigPkColumnsInput;
  questionnaireConfig_select_column: QuestionnaireConfigSelectColumn;
  questionnaireConfig_set_input: QuestionnaireConfigSetInput;
  questionnaireConfig_stream_cursor_input: QuestionnaireConfigStreamCursorInput;
  questionnaireConfig_stream_cursor_value_input: QuestionnaireConfigStreamCursorValueInput;
  questionnaireConfig_update_column: QuestionnaireConfigUpdateColumn;
  questionnaireConfig_updates: QuestionnaireConfigUpdates;
  questionnaireQuestion: ResolverTypeWrapper<QuestionnaireQuestion>;
  questionnaireQuestion_aggregate_order_by: QuestionnaireQuestionAggregateOrderBy;
  questionnaireQuestion_avg_order_by: QuestionnaireQuestionAvgOrderBy;
  questionnaireQuestion_bool_exp: QuestionnaireQuestionBoolExp;
  questionnaireQuestion_max_order_by: QuestionnaireQuestionMaxOrderBy;
  questionnaireQuestion_min_order_by: QuestionnaireQuestionMinOrderBy;
  questionnaireQuestion_order_by: QuestionnaireQuestionOrderBy;
  questionnaireQuestion_select_column: QuestionnaireQuestionSelectColumn;
  questionnaireQuestion_stddev_order_by: QuestionnaireQuestionStddevOrderBy;
  questionnaireQuestion_stddev_pop_order_by: QuestionnaireQuestionStddevPopOrderBy;
  questionnaireQuestion_stddev_samp_order_by: QuestionnaireQuestionStddevSampOrderBy;
  questionnaireQuestion_stream_cursor_input: QuestionnaireQuestionStreamCursorInput;
  questionnaireQuestion_stream_cursor_value_input: QuestionnaireQuestionStreamCursorValueInput;
  questionnaireQuestion_sum_order_by: QuestionnaireQuestionSumOrderBy;
  questionnaireQuestion_var_pop_order_by: QuestionnaireQuestionVarPopOrderBy;
  questionnaireQuestion_var_samp_order_by: QuestionnaireQuestionVarSampOrderBy;
  questionnaireQuestion_variance_order_by: QuestionnaireQuestionVarianceOrderBy;
  questionnaire_bool_exp: QuestionnaireBoolExp;
  questionnaire_constraint: QuestionnaireConstraint;
  questionnaire_insert_input: QuestionnaireInsertInput;
  questionnaire_mutation_response: ResolverTypeWrapper<QuestionnaireMutationResponse>;
  questionnaire_obj_rel_insert_input: QuestionnaireObjRelInsertInput;
  questionnaire_on_conflict: QuestionnaireOnConflict;
  questionnaire_order_by: QuestionnaireOrderBy;
  questionnaire_pk_columns_input: QuestionnairePkColumnsInput;
  questionnaire_select_column: QuestionnaireSelectColumn;
  questionnaire_set_input: QuestionnaireSetInput;
  questionnaire_stream_cursor_input: QuestionnaireStreamCursorInput;
  questionnaire_stream_cursor_value_input: QuestionnaireStreamCursorValueInput;
  questionnaire_update_column: QuestionnaireUpdateColumn;
  questionnaire_updates: QuestionnaireUpdates;
  receipt: ResolverTypeWrapper<Receipt>;
  receipt_aggregate_order_by: ReceiptAggregateOrderBy;
  receipt_avg_order_by: ReceiptAvgOrderBy;
  receipt_bool_exp: ReceiptBoolExp;
  receipt_max_order_by: ReceiptMaxOrderBy;
  receipt_min_order_by: ReceiptMinOrderBy;
  receipt_order_by: ReceiptOrderBy;
  receipt_select_column: ReceiptSelectColumn;
  receipt_stddev_order_by: ReceiptStddevOrderBy;
  receipt_stddev_pop_order_by: ReceiptStddevPopOrderBy;
  receipt_stddev_samp_order_by: ReceiptStddevSampOrderBy;
  receipt_stream_cursor_input: ReceiptStreamCursorInput;
  receipt_stream_cursor_value_input: ReceiptStreamCursorValueInput;
  receipt_sum_order_by: ReceiptSumOrderBy;
  receipt_var_pop_order_by: ReceiptVarPopOrderBy;
  receipt_var_samp_order_by: ReceiptVarSampOrderBy;
  receipt_variance_order_by: ReceiptVarianceOrderBy;
  reservationDashboardAccountAccessibleShop: ResolverTypeWrapper<ReservationDashboardAccountAccessibleShop>;
  reservationDashboardAccountAccessibleShop_aggregate_order_by: ReservationDashboardAccountAccessibleShopAggregateOrderBy;
  reservationDashboardAccountAccessibleShop_bool_exp: ReservationDashboardAccountAccessibleShopBoolExp;
  reservationDashboardAccountAccessibleShop_max_order_by: ReservationDashboardAccountAccessibleShopMaxOrderBy;
  reservationDashboardAccountAccessibleShop_min_order_by: ReservationDashboardAccountAccessibleShopMinOrderBy;
  reservationDashboardAccountAccessibleShop_order_by: ReservationDashboardAccountAccessibleShopOrderBy;
  reservationDashboardAccountAccessibleShop_select_column: ReservationDashboardAccountAccessibleShopSelectColumn;
  reservationDashboardAccountAccessibleShop_stream_cursor_input: ReservationDashboardAccountAccessibleShopStreamCursorInput;
  reservationDashboardAccountAccessibleShop_stream_cursor_value_input: ReservationDashboardAccountAccessibleShopStreamCursorValueInput;
  role: ResolverTypeWrapper<Role>;
  roleTablesTable: ResolverTypeWrapper<RoleTablesTable>;
  roleTablesTable_aggregate_order_by: RoleTablesTableAggregateOrderBy;
  roleTablesTable_arr_rel_insert_input: RoleTablesTableArrRelInsertInput;
  roleTablesTable_avg_order_by: RoleTablesTableAvgOrderBy;
  roleTablesTable_bool_exp: RoleTablesTableBoolExp;
  roleTablesTable_constraint: RoleTablesTableConstraint;
  roleTablesTable_inc_input: RoleTablesTableIncInput;
  roleTablesTable_insert_input: RoleTablesTableInsertInput;
  roleTablesTable_max_order_by: RoleTablesTableMaxOrderBy;
  roleTablesTable_min_order_by: RoleTablesTableMinOrderBy;
  roleTablesTable_mutation_response: ResolverTypeWrapper<RoleTablesTableMutationResponse>;
  roleTablesTable_on_conflict: RoleTablesTableOnConflict;
  roleTablesTable_order_by: RoleTablesTableOrderBy;
  roleTablesTable_select_column: RoleTablesTableSelectColumn;
  roleTablesTable_set_input: RoleTablesTableSetInput;
  roleTablesTable_stddev_order_by: RoleTablesTableStddevOrderBy;
  roleTablesTable_stddev_pop_order_by: RoleTablesTableStddevPopOrderBy;
  roleTablesTable_stddev_samp_order_by: RoleTablesTableStddevSampOrderBy;
  roleTablesTable_stream_cursor_input: RoleTablesTableStreamCursorInput;
  roleTablesTable_stream_cursor_value_input: RoleTablesTableStreamCursorValueInput;
  roleTablesTable_sum_order_by: RoleTablesTableSumOrderBy;
  roleTablesTable_update_column: RoleTablesTableUpdateColumn;
  roleTablesTable_updates: RoleTablesTableUpdates;
  roleTablesTable_var_pop_order_by: RoleTablesTableVarPopOrderBy;
  roleTablesTable_var_samp_order_by: RoleTablesTableVarSampOrderBy;
  roleTablesTable_variance_order_by: RoleTablesTableVarianceOrderBy;
  role_aggregate_order_by: RoleAggregateOrderBy;
  role_arr_rel_insert_input: RoleArrRelInsertInput;
  role_avg_order_by: RoleAvgOrderBy;
  role_bool_exp: RoleBoolExp;
  role_constraint: RoleConstraint;
  role_inc_input: RoleIncInput;
  role_insert_input: RoleInsertInput;
  role_max_order_by: RoleMaxOrderBy;
  role_min_order_by: RoleMinOrderBy;
  role_mutation_response: ResolverTypeWrapper<RoleMutationResponse>;
  role_obj_rel_insert_input: RoleObjRelInsertInput;
  role_on_conflict: RoleOnConflict;
  role_order_by: RoleOrderBy;
  role_pk_columns_input: RolePkColumnsInput;
  role_select_column: RoleSelectColumn;
  role_set_input: RoleSetInput;
  role_stddev_order_by: RoleStddevOrderBy;
  role_stddev_pop_order_by: RoleStddevPopOrderBy;
  role_stddev_samp_order_by: RoleStddevSampOrderBy;
  role_stream_cursor_input: RoleStreamCursorInput;
  role_stream_cursor_value_input: RoleStreamCursorValueInput;
  role_sum_order_by: RoleSumOrderBy;
  role_update_column: RoleUpdateColumn;
  role_updates: RoleUpdates;
  role_var_pop_order_by: RoleVarPopOrderBy;
  role_var_samp_order_by: RoleVarSampOrderBy;
  role_variance_order_by: RoleVarianceOrderBy;
  salesBudgetAllocationSetting: ResolverTypeWrapper<SalesBudgetAllocationSetting>;
  salesBudgetAllocationSetting_bool_exp: SalesBudgetAllocationSettingBoolExp;
  salesBudgetAllocationSetting_constraint: SalesBudgetAllocationSettingConstraint;
  salesBudgetAllocationSetting_inc_input: SalesBudgetAllocationSettingIncInput;
  salesBudgetAllocationSetting_insert_input: SalesBudgetAllocationSettingInsertInput;
  salesBudgetAllocationSetting_mutation_response: ResolverTypeWrapper<SalesBudgetAllocationSettingMutationResponse>;
  salesBudgetAllocationSetting_on_conflict: SalesBudgetAllocationSettingOnConflict;
  salesBudgetAllocationSetting_order_by: SalesBudgetAllocationSettingOrderBy;
  salesBudgetAllocationSetting_pk_columns_input: SalesBudgetAllocationSettingPkColumnsInput;
  salesBudgetAllocationSetting_select_column: SalesBudgetAllocationSettingSelectColumn;
  salesBudgetAllocationSetting_set_input: SalesBudgetAllocationSettingSetInput;
  salesBudgetAllocationSetting_stream_cursor_input: SalesBudgetAllocationSettingStreamCursorInput;
  salesBudgetAllocationSetting_stream_cursor_value_input: SalesBudgetAllocationSettingStreamCursorValueInput;
  salesBudgetAllocationSetting_update_column: SalesBudgetAllocationSettingUpdateColumn;
  salesBudgetAllocationSetting_updates: SalesBudgetAllocationSettingUpdates;
  scoreTypeQuestionAnswer: ResolverTypeWrapper<ScoreTypeQuestionAnswer>;
  scoreTypeQuestionAnswer_bool_exp: ScoreTypeQuestionAnswerBoolExp;
  scoreTypeQuestionAnswer_order_by: ScoreTypeQuestionAnswerOrderBy;
  scoreTypeQuestionAnswer_select_column: ScoreTypeQuestionAnswerSelectColumn;
  scoreTypeQuestionAnswer_stream_cursor_input: ScoreTypeQuestionAnswerStreamCursorInput;
  scoreTypeQuestionAnswer_stream_cursor_value_input: ScoreTypeQuestionAnswerStreamCursorValueInput;
  serviceAdmin: ResolverTypeWrapper<ServiceAdmin>;
  serviceAdmin_bool_exp: ServiceAdminBoolExp;
  serviceAdmin_order_by: ServiceAdminOrderBy;
  serviceAdmin_select_column: ServiceAdminSelectColumn;
  serviceAdmin_stream_cursor_input: ServiceAdminStreamCursorInput;
  serviceAdmin_stream_cursor_value_input: ServiceAdminStreamCursorValueInput;
  serviceChargeConfig: ResolverTypeWrapper<ServiceChargeConfig>;
  serviceChargeConfig_aggregate_order_by: ServiceChargeConfigAggregateOrderBy;
  serviceChargeConfig_avg_order_by: ServiceChargeConfigAvgOrderBy;
  serviceChargeConfig_bool_exp: ServiceChargeConfigBoolExp;
  serviceChargeConfig_constraint: ServiceChargeConfigConstraint;
  serviceChargeConfig_inc_input: ServiceChargeConfigIncInput;
  serviceChargeConfig_insert_input: ServiceChargeConfigInsertInput;
  serviceChargeConfig_max_order_by: ServiceChargeConfigMaxOrderBy;
  serviceChargeConfig_min_order_by: ServiceChargeConfigMinOrderBy;
  serviceChargeConfig_mutation_response: ResolverTypeWrapper<ServiceChargeConfigMutationResponse>;
  serviceChargeConfig_obj_rel_insert_input: ServiceChargeConfigObjRelInsertInput;
  serviceChargeConfig_on_conflict: ServiceChargeConfigOnConflict;
  serviceChargeConfig_order_by: ServiceChargeConfigOrderBy;
  serviceChargeConfig_pk_columns_input: ServiceChargeConfigPkColumnsInput;
  serviceChargeConfig_select_column: ServiceChargeConfigSelectColumn;
  serviceChargeConfig_set_input: ServiceChargeConfigSetInput;
  serviceChargeConfig_stddev_order_by: ServiceChargeConfigStddevOrderBy;
  serviceChargeConfig_stddev_pop_order_by: ServiceChargeConfigStddevPopOrderBy;
  serviceChargeConfig_stddev_samp_order_by: ServiceChargeConfigStddevSampOrderBy;
  serviceChargeConfig_stream_cursor_input: ServiceChargeConfigStreamCursorInput;
  serviceChargeConfig_stream_cursor_value_input: ServiceChargeConfigStreamCursorValueInput;
  serviceChargeConfig_sum_order_by: ServiceChargeConfigSumOrderBy;
  serviceChargeConfig_update_column: ServiceChargeConfigUpdateColumn;
  serviceChargeConfig_updates: ServiceChargeConfigUpdates;
  serviceChargeConfig_var_pop_order_by: ServiceChargeConfigVarPopOrderBy;
  serviceChargeConfig_var_samp_order_by: ServiceChargeConfigVarSampOrderBy;
  serviceChargeConfig_variance_order_by: ServiceChargeConfigVarianceOrderBy;
  serviceChargeMeta: ResolverTypeWrapper<ServiceChargeMeta>;
  serviceChargeMeta_bool_exp: ServiceChargeMetaBoolExp;
  serviceChargeMeta_order_by: ServiceChargeMetaOrderBy;
  serviceChargeMeta_select_column: ServiceChargeMetaSelectColumn;
  serviceChargeMeta_stream_cursor_input: ServiceChargeMetaStreamCursorInput;
  serviceChargeMeta_stream_cursor_value_input: ServiceChargeMetaStreamCursorValueInput;
  shop: ResolverTypeWrapper<Shop>;
  shopBusinessOperationHour: ResolverTypeWrapper<ShopBusinessOperationHour>;
  shopBusinessOperationHour_aggregate_order_by: ShopBusinessOperationHourAggregateOrderBy;
  shopBusinessOperationHour_arr_rel_insert_input: ShopBusinessOperationHourArrRelInsertInput;
  shopBusinessOperationHour_bool_exp: ShopBusinessOperationHourBoolExp;
  shopBusinessOperationHour_constraint: ShopBusinessOperationHourConstraint;
  shopBusinessOperationHour_insert_input: ShopBusinessOperationHourInsertInput;
  shopBusinessOperationHour_max_order_by: ShopBusinessOperationHourMaxOrderBy;
  shopBusinessOperationHour_min_order_by: ShopBusinessOperationHourMinOrderBy;
  shopBusinessOperationHour_mutation_response: ResolverTypeWrapper<ShopBusinessOperationHourMutationResponse>;
  shopBusinessOperationHour_on_conflict: ShopBusinessOperationHourOnConflict;
  shopBusinessOperationHour_order_by: ShopBusinessOperationHourOrderBy;
  shopBusinessOperationHour_pk_columns_input: ShopBusinessOperationHourPkColumnsInput;
  shopBusinessOperationHour_select_column: ShopBusinessOperationHourSelectColumn;
  shopBusinessOperationHour_set_input: ShopBusinessOperationHourSetInput;
  shopBusinessOperationHour_stream_cursor_input: ShopBusinessOperationHourStreamCursorInput;
  shopBusinessOperationHour_stream_cursor_value_input: ShopBusinessOperationHourStreamCursorValueInput;
  shopBusinessOperationHour_update_column: ShopBusinessOperationHourUpdateColumn;
  shopBusinessOperationHour_updates: ShopBusinessOperationHourUpdates;
  shopChoice: ResolverTypeWrapper<ShopChoice>;
  shopChoice_aggregate_order_by: ShopChoiceAggregateOrderBy;
  shopChoice_avg_order_by: ShopChoiceAvgOrderBy;
  shopChoice_bool_exp: ShopChoiceBoolExp;
  shopChoice_max_order_by: ShopChoiceMaxOrderBy;
  shopChoice_min_order_by: ShopChoiceMinOrderBy;
  shopChoice_order_by: ShopChoiceOrderBy;
  shopChoice_select_column: ShopChoiceSelectColumn;
  shopChoice_stddev_order_by: ShopChoiceStddevOrderBy;
  shopChoice_stddev_pop_order_by: ShopChoiceStddevPopOrderBy;
  shopChoice_stddev_samp_order_by: ShopChoiceStddevSampOrderBy;
  shopChoice_stream_cursor_input: ShopChoiceStreamCursorInput;
  shopChoice_stream_cursor_value_input: ShopChoiceStreamCursorValueInput;
  shopChoice_sum_order_by: ShopChoiceSumOrderBy;
  shopChoice_var_pop_order_by: ShopChoiceVarPopOrderBy;
  shopChoice_var_samp_order_by: ShopChoiceVarSampOrderBy;
  shopChoice_variance_order_by: ShopChoiceVarianceOrderBy;
  shopClerk: ResolverTypeWrapper<ShopClerk>;
  shopClerk_aggregate_order_by: ShopClerkAggregateOrderBy;
  shopClerk_arr_rel_insert_input: ShopClerkArrRelInsertInput;
  shopClerk_avg_order_by: ShopClerkAvgOrderBy;
  shopClerk_bool_exp: ShopClerkBoolExp;
  shopClerk_constraint: ShopClerkConstraint;
  shopClerk_inc_input: ShopClerkIncInput;
  shopClerk_insert_input: ShopClerkInsertInput;
  shopClerk_max_order_by: ShopClerkMaxOrderBy;
  shopClerk_min_order_by: ShopClerkMinOrderBy;
  shopClerk_mutation_response: ResolverTypeWrapper<ShopClerkMutationResponse>;
  shopClerk_on_conflict: ShopClerkOnConflict;
  shopClerk_order_by: ShopClerkOrderBy;
  shopClerk_pk_columns_input: ShopClerkPkColumnsInput;
  shopClerk_select_column: ShopClerkSelectColumn;
  shopClerk_set_input: ShopClerkSetInput;
  shopClerk_stddev_order_by: ShopClerkStddevOrderBy;
  shopClerk_stddev_pop_order_by: ShopClerkStddevPopOrderBy;
  shopClerk_stddev_samp_order_by: ShopClerkStddevSampOrderBy;
  shopClerk_stream_cursor_input: ShopClerkStreamCursorInput;
  shopClerk_stream_cursor_value_input: ShopClerkStreamCursorValueInput;
  shopClerk_sum_order_by: ShopClerkSumOrderBy;
  shopClerk_update_column: ShopClerkUpdateColumn;
  shopClerk_updates: ShopClerkUpdates;
  shopClerk_var_pop_order_by: ShopClerkVarPopOrderBy;
  shopClerk_var_samp_order_by: ShopClerkVarSampOrderBy;
  shopClerk_variance_order_by: ShopClerkVarianceOrderBy;
  shopClient: ResolverTypeWrapper<ShopClient>;
  shopClient_aggregate_order_by: ShopClientAggregateOrderBy;
  shopClient_arr_rel_insert_input: ShopClientArrRelInsertInput;
  shopClient_bool_exp: ShopClientBoolExp;
  shopClient_constraint: ShopClientConstraint;
  shopClient_insert_input: ShopClientInsertInput;
  shopClient_max_order_by: ShopClientMaxOrderBy;
  shopClient_min_order_by: ShopClientMinOrderBy;
  shopClient_mutation_response: ResolverTypeWrapper<ShopClientMutationResponse>;
  shopClient_on_conflict: ShopClientOnConflict;
  shopClient_order_by: ShopClientOrderBy;
  shopClient_pk_columns_input: ShopClientPkColumnsInput;
  shopClient_select_column: ShopClientSelectColumn;
  shopClient_set_input: ShopClientSetInput;
  shopClient_stream_cursor_input: ShopClientStreamCursorInput;
  shopClient_stream_cursor_value_input: ShopClientStreamCursorValueInput;
  shopClient_update_column: ShopClientUpdateColumn;
  shopClient_updates: ShopClientUpdates;
  shopCookingItemRole: ResolverTypeWrapper<ShopCookingItemRole>;
  shopCookingItemRole_aggregate_order_by: ShopCookingItemRoleAggregateOrderBy;
  shopCookingItemRole_arr_rel_insert_input: ShopCookingItemRoleArrRelInsertInput;
  shopCookingItemRole_avg_order_by: ShopCookingItemRoleAvgOrderBy;
  shopCookingItemRole_bool_exp: ShopCookingItemRoleBoolExp;
  shopCookingItemRole_constraint: ShopCookingItemRoleConstraint;
  shopCookingItemRole_inc_input: ShopCookingItemRoleIncInput;
  shopCookingItemRole_insert_input: ShopCookingItemRoleInsertInput;
  shopCookingItemRole_max_order_by: ShopCookingItemRoleMaxOrderBy;
  shopCookingItemRole_min_order_by: ShopCookingItemRoleMinOrderBy;
  shopCookingItemRole_mutation_response: ResolverTypeWrapper<ShopCookingItemRoleMutationResponse>;
  shopCookingItemRole_obj_rel_insert_input: ShopCookingItemRoleObjRelInsertInput;
  shopCookingItemRole_on_conflict: ShopCookingItemRoleOnConflict;
  shopCookingItemRole_order_by: ShopCookingItemRoleOrderBy;
  shopCookingItemRole_pk_columns_input: ShopCookingItemRolePkColumnsInput;
  shopCookingItemRole_select_column: ShopCookingItemRoleSelectColumn;
  shopCookingItemRole_set_input: ShopCookingItemRoleSetInput;
  shopCookingItemRole_stddev_order_by: ShopCookingItemRoleStddevOrderBy;
  shopCookingItemRole_stddev_pop_order_by: ShopCookingItemRoleStddevPopOrderBy;
  shopCookingItemRole_stddev_samp_order_by: ShopCookingItemRoleStddevSampOrderBy;
  shopCookingItemRole_stream_cursor_input: ShopCookingItemRoleStreamCursorInput;
  shopCookingItemRole_stream_cursor_value_input: ShopCookingItemRoleStreamCursorValueInput;
  shopCookingItemRole_sum_order_by: ShopCookingItemRoleSumOrderBy;
  shopCookingItemRole_update_column: ShopCookingItemRoleUpdateColumn;
  shopCookingItemRole_updates: ShopCookingItemRoleUpdates;
  shopCookingItemRole_var_pop_order_by: ShopCookingItemRoleVarPopOrderBy;
  shopCookingItemRole_var_samp_order_by: ShopCookingItemRoleVarSampOrderBy;
  shopCookingItemRole_variance_order_by: ShopCookingItemRoleVarianceOrderBy;
  shopDiscountType: ResolverTypeWrapper<ShopDiscountType>;
  shopDiscountType_aggregate_order_by: ShopDiscountTypeAggregateOrderBy;
  shopDiscountType_arr_rel_insert_input: ShopDiscountTypeArrRelInsertInput;
  shopDiscountType_bool_exp: ShopDiscountTypeBoolExp;
  shopDiscountType_constraint: ShopDiscountTypeConstraint;
  shopDiscountType_insert_input: ShopDiscountTypeInsertInput;
  shopDiscountType_max_order_by: ShopDiscountTypeMaxOrderBy;
  shopDiscountType_min_order_by: ShopDiscountTypeMinOrderBy;
  shopDiscountType_mutation_response: ResolverTypeWrapper<ShopDiscountTypeMutationResponse>;
  shopDiscountType_on_conflict: ShopDiscountTypeOnConflict;
  shopDiscountType_order_by: ShopDiscountTypeOrderBy;
  shopDiscountType_pk_columns_input: ShopDiscountTypePkColumnsInput;
  shopDiscountType_select_column: ShopDiscountTypeSelectColumn;
  shopDiscountType_set_input: ShopDiscountTypeSetInput;
  shopDiscountType_stream_cursor_input: ShopDiscountTypeStreamCursorInput;
  shopDiscountType_stream_cursor_value_input: ShopDiscountTypeStreamCursorValueInput;
  shopDiscountType_update_column: ShopDiscountTypeUpdateColumn;
  shopDiscountType_updates: ShopDiscountTypeUpdates;
  shopInflowSourceTag: ResolverTypeWrapper<ShopInflowSourceTag>;
  shopInflowSourceTag_aggregate_order_by: ShopInflowSourceTagAggregateOrderBy;
  shopInflowSourceTag_arr_rel_insert_input: ShopInflowSourceTagArrRelInsertInput;
  shopInflowSourceTag_bool_exp: ShopInflowSourceTagBoolExp;
  shopInflowSourceTag_constraint: ShopInflowSourceTagConstraint;
  shopInflowSourceTag_insert_input: ShopInflowSourceTagInsertInput;
  shopInflowSourceTag_max_order_by: ShopInflowSourceTagMaxOrderBy;
  shopInflowSourceTag_min_order_by: ShopInflowSourceTagMinOrderBy;
  shopInflowSourceTag_mutation_response: ResolverTypeWrapper<ShopInflowSourceTagMutationResponse>;
  shopInflowSourceTag_on_conflict: ShopInflowSourceTagOnConflict;
  shopInflowSourceTag_order_by: ShopInflowSourceTagOrderBy;
  shopInflowSourceTag_select_column: ShopInflowSourceTagSelectColumn;
  shopInflowSourceTag_set_input: ShopInflowSourceTagSetInput;
  shopInflowSourceTag_stream_cursor_input: ShopInflowSourceTagStreamCursorInput;
  shopInflowSourceTag_stream_cursor_value_input: ShopInflowSourceTagStreamCursorValueInput;
  shopInflowSourceTag_update_column: ShopInflowSourceTagUpdateColumn;
  shopInflowSourceTag_updates: ShopInflowSourceTagUpdates;
  shopMember: ResolverTypeWrapper<ShopMember>;
  shopMember_aggregate_order_by: ShopMemberAggregateOrderBy;
  shopMember_bool_exp: ShopMemberBoolExp;
  shopMember_max_order_by: ShopMemberMaxOrderBy;
  shopMember_min_order_by: ShopMemberMinOrderBy;
  shopMember_order_by: ShopMemberOrderBy;
  shopMember_select_column: ShopMemberSelectColumn;
  shopMember_stream_cursor_input: ShopMemberStreamCursorInput;
  shopMember_stream_cursor_value_input: ShopMemberStreamCursorValueInput;
  shopMenu: ResolverTypeWrapper<ShopMenu>;
  shopMenuCookingItem: ResolverTypeWrapper<ShopMenuCookingItem>;
  shopMenuCookingItem_aggregate_order_by: ShopMenuCookingItemAggregateOrderBy;
  shopMenuCookingItem_arr_rel_insert_input: ShopMenuCookingItemArrRelInsertInput;
  shopMenuCookingItem_avg_order_by: ShopMenuCookingItemAvgOrderBy;
  shopMenuCookingItem_bool_exp: ShopMenuCookingItemBoolExp;
  shopMenuCookingItem_constraint: ShopMenuCookingItemConstraint;
  shopMenuCookingItem_inc_input: ShopMenuCookingItemIncInput;
  shopMenuCookingItem_insert_input: ShopMenuCookingItemInsertInput;
  shopMenuCookingItem_max_order_by: ShopMenuCookingItemMaxOrderBy;
  shopMenuCookingItem_min_order_by: ShopMenuCookingItemMinOrderBy;
  shopMenuCookingItem_mutation_response: ResolverTypeWrapper<ShopMenuCookingItemMutationResponse>;
  shopMenuCookingItem_on_conflict: ShopMenuCookingItemOnConflict;
  shopMenuCookingItem_order_by: ShopMenuCookingItemOrderBy;
  shopMenuCookingItem_select_column: ShopMenuCookingItemSelectColumn;
  shopMenuCookingItem_set_input: ShopMenuCookingItemSetInput;
  shopMenuCookingItem_stddev_order_by: ShopMenuCookingItemStddevOrderBy;
  shopMenuCookingItem_stddev_pop_order_by: ShopMenuCookingItemStddevPopOrderBy;
  shopMenuCookingItem_stddev_samp_order_by: ShopMenuCookingItemStddevSampOrderBy;
  shopMenuCookingItem_stream_cursor_input: ShopMenuCookingItemStreamCursorInput;
  shopMenuCookingItem_stream_cursor_value_input: ShopMenuCookingItemStreamCursorValueInput;
  shopMenuCookingItem_sum_order_by: ShopMenuCookingItemSumOrderBy;
  shopMenuCookingItem_update_column: ShopMenuCookingItemUpdateColumn;
  shopMenuCookingItem_updates: ShopMenuCookingItemUpdates;
  shopMenuCookingItem_var_pop_order_by: ShopMenuCookingItemVarPopOrderBy;
  shopMenuCookingItem_var_samp_order_by: ShopMenuCookingItemVarSampOrderBy;
  shopMenuCookingItem_variance_order_by: ShopMenuCookingItemVarianceOrderBy;
  shopMenuKdDisplayTarget: ResolverTypeWrapper<ShopMenuKdDisplayTarget>;
  shopMenuKdDisplayTarget_aggregate_order_by: ShopMenuKdDisplayTargetAggregateOrderBy;
  shopMenuKdDisplayTarget_arr_rel_insert_input: ShopMenuKdDisplayTargetArrRelInsertInput;
  shopMenuKdDisplayTarget_avg_order_by: ShopMenuKdDisplayTargetAvgOrderBy;
  shopMenuKdDisplayTarget_bool_exp: ShopMenuKdDisplayTargetBoolExp;
  shopMenuKdDisplayTarget_constraint: ShopMenuKdDisplayTargetConstraint;
  shopMenuKdDisplayTarget_inc_input: ShopMenuKdDisplayTargetIncInput;
  shopMenuKdDisplayTarget_insert_input: ShopMenuKdDisplayTargetInsertInput;
  shopMenuKdDisplayTarget_max_order_by: ShopMenuKdDisplayTargetMaxOrderBy;
  shopMenuKdDisplayTarget_min_order_by: ShopMenuKdDisplayTargetMinOrderBy;
  shopMenuKdDisplayTarget_mutation_response: ResolverTypeWrapper<ShopMenuKdDisplayTargetMutationResponse>;
  shopMenuKdDisplayTarget_on_conflict: ShopMenuKdDisplayTargetOnConflict;
  shopMenuKdDisplayTarget_order_by: ShopMenuKdDisplayTargetOrderBy;
  shopMenuKdDisplayTarget_pk_columns_input: ShopMenuKdDisplayTargetPkColumnsInput;
  shopMenuKdDisplayTarget_select_column: ShopMenuKdDisplayTargetSelectColumn;
  shopMenuKdDisplayTarget_set_input: ShopMenuKdDisplayTargetSetInput;
  shopMenuKdDisplayTarget_stddev_order_by: ShopMenuKdDisplayTargetStddevOrderBy;
  shopMenuKdDisplayTarget_stddev_pop_order_by: ShopMenuKdDisplayTargetStddevPopOrderBy;
  shopMenuKdDisplayTarget_stddev_samp_order_by: ShopMenuKdDisplayTargetStddevSampOrderBy;
  shopMenuKdDisplayTarget_stream_cursor_input: ShopMenuKdDisplayTargetStreamCursorInput;
  shopMenuKdDisplayTarget_stream_cursor_value_input: ShopMenuKdDisplayTargetStreamCursorValueInput;
  shopMenuKdDisplayTarget_sum_order_by: ShopMenuKdDisplayTargetSumOrderBy;
  shopMenuKdDisplayTarget_update_column: ShopMenuKdDisplayTargetUpdateColumn;
  shopMenuKdDisplayTarget_updates: ShopMenuKdDisplayTargetUpdates;
  shopMenuKdDisplayTarget_var_pop_order_by: ShopMenuKdDisplayTargetVarPopOrderBy;
  shopMenuKdDisplayTarget_var_samp_order_by: ShopMenuKdDisplayTargetVarSampOrderBy;
  shopMenuKdDisplayTarget_variance_order_by: ShopMenuKdDisplayTargetVarianceOrderBy;
  shopMenuKitchenRole: ResolverTypeWrapper<ShopMenuKitchenRole>;
  shopMenuKitchenRole_aggregate_order_by: ShopMenuKitchenRoleAggregateOrderBy;
  shopMenuKitchenRole_arr_rel_insert_input: ShopMenuKitchenRoleArrRelInsertInput;
  shopMenuKitchenRole_avg_order_by: ShopMenuKitchenRoleAvgOrderBy;
  shopMenuKitchenRole_bool_exp: ShopMenuKitchenRoleBoolExp;
  shopMenuKitchenRole_constraint: ShopMenuKitchenRoleConstraint;
  shopMenuKitchenRole_inc_input: ShopMenuKitchenRoleIncInput;
  shopMenuKitchenRole_insert_input: ShopMenuKitchenRoleInsertInput;
  shopMenuKitchenRole_max_order_by: ShopMenuKitchenRoleMaxOrderBy;
  shopMenuKitchenRole_min_order_by: ShopMenuKitchenRoleMinOrderBy;
  shopMenuKitchenRole_mutation_response: ResolverTypeWrapper<ShopMenuKitchenRoleMutationResponse>;
  shopMenuKitchenRole_on_conflict: ShopMenuKitchenRoleOnConflict;
  shopMenuKitchenRole_order_by: ShopMenuKitchenRoleOrderBy;
  shopMenuKitchenRole_select_column: ShopMenuKitchenRoleSelectColumn;
  shopMenuKitchenRole_set_input: ShopMenuKitchenRoleSetInput;
  shopMenuKitchenRole_stddev_order_by: ShopMenuKitchenRoleStddevOrderBy;
  shopMenuKitchenRole_stddev_pop_order_by: ShopMenuKitchenRoleStddevPopOrderBy;
  shopMenuKitchenRole_stddev_samp_order_by: ShopMenuKitchenRoleStddevSampOrderBy;
  shopMenuKitchenRole_stream_cursor_input: ShopMenuKitchenRoleStreamCursorInput;
  shopMenuKitchenRole_stream_cursor_value_input: ShopMenuKitchenRoleStreamCursorValueInput;
  shopMenuKitchenRole_sum_order_by: ShopMenuKitchenRoleSumOrderBy;
  shopMenuKitchenRole_update_column: ShopMenuKitchenRoleUpdateColumn;
  shopMenuKitchenRole_updates: ShopMenuKitchenRoleUpdates;
  shopMenuKitchenRole_var_pop_order_by: ShopMenuKitchenRoleVarPopOrderBy;
  shopMenuKitchenRole_var_samp_order_by: ShopMenuKitchenRoleVarSampOrderBy;
  shopMenuKitchenRole_variance_order_by: ShopMenuKitchenRoleVarianceOrderBy;
  shopMenu_aggregate_order_by: ShopMenuAggregateOrderBy;
  shopMenu_avg_order_by: ShopMenuAvgOrderBy;
  shopMenu_bool_exp: ShopMenuBoolExp;
  shopMenu_inc_input: ShopMenuIncInput;
  shopMenu_max_order_by: ShopMenuMaxOrderBy;
  shopMenu_min_order_by: ShopMenuMinOrderBy;
  shopMenu_mutation_response: ResolverTypeWrapper<ShopMenuMutationResponse>;
  shopMenu_order_by: ShopMenuOrderBy;
  shopMenu_pk_columns_input: ShopMenuPkColumnsInput;
  shopMenu_select_column: ShopMenuSelectColumn;
  shopMenu_set_input: ShopMenuSetInput;
  shopMenu_stddev_order_by: ShopMenuStddevOrderBy;
  shopMenu_stddev_pop_order_by: ShopMenuStddevPopOrderBy;
  shopMenu_stddev_samp_order_by: ShopMenuStddevSampOrderBy;
  shopMenu_stream_cursor_input: ShopMenuStreamCursorInput;
  shopMenu_stream_cursor_value_input: ShopMenuStreamCursorValueInput;
  shopMenu_sum_order_by: ShopMenuSumOrderBy;
  shopMenu_updates: ShopMenuUpdates;
  shopMenu_var_pop_order_by: ShopMenuVarPopOrderBy;
  shopMenu_var_samp_order_by: ShopMenuVarSampOrderBy;
  shopMenu_variance_order_by: ShopMenuVarianceOrderBy;
  shopOptionKitchenRole: ResolverTypeWrapper<ShopOptionKitchenRole>;
  shopOptionKitchenRole_aggregate_order_by: ShopOptionKitchenRoleAggregateOrderBy;
  shopOptionKitchenRole_arr_rel_insert_input: ShopOptionKitchenRoleArrRelInsertInput;
  shopOptionKitchenRole_bool_exp: ShopOptionKitchenRoleBoolExp;
  shopOptionKitchenRole_constraint: ShopOptionKitchenRoleConstraint;
  shopOptionKitchenRole_insert_input: ShopOptionKitchenRoleInsertInput;
  shopOptionKitchenRole_max_order_by: ShopOptionKitchenRoleMaxOrderBy;
  shopOptionKitchenRole_min_order_by: ShopOptionKitchenRoleMinOrderBy;
  shopOptionKitchenRole_mutation_response: ResolverTypeWrapper<ShopOptionKitchenRoleMutationResponse>;
  shopOptionKitchenRole_on_conflict: ShopOptionKitchenRoleOnConflict;
  shopOptionKitchenRole_order_by: ShopOptionKitchenRoleOrderBy;
  shopOptionKitchenRole_select_column: ShopOptionKitchenRoleSelectColumn;
  shopOptionKitchenRole_stream_cursor_input: ShopOptionKitchenRoleStreamCursorInput;
  shopOptionKitchenRole_stream_cursor_value_input: ShopOptionKitchenRoleStreamCursorValueInput;
  shopOptionKitchenRole_update_column: ShopOptionKitchenRoleUpdateColumn;
  shopOrderableTimeTerm: ResolverTypeWrapper<ShopOrderableTimeTerm>;
  shopOrderableTimeTerm_aggregate_order_by: ShopOrderableTimeTermAggregateOrderBy;
  shopOrderableTimeTerm_arr_rel_insert_input: ShopOrderableTimeTermArrRelInsertInput;
  shopOrderableTimeTerm_bool_exp: ShopOrderableTimeTermBoolExp;
  shopOrderableTimeTerm_constraint: ShopOrderableTimeTermConstraint;
  shopOrderableTimeTerm_insert_input: ShopOrderableTimeTermInsertInput;
  shopOrderableTimeTerm_max_order_by: ShopOrderableTimeTermMaxOrderBy;
  shopOrderableTimeTerm_min_order_by: ShopOrderableTimeTermMinOrderBy;
  shopOrderableTimeTerm_mutation_response: ResolverTypeWrapper<ShopOrderableTimeTermMutationResponse>;
  shopOrderableTimeTerm_on_conflict: ShopOrderableTimeTermOnConflict;
  shopOrderableTimeTerm_order_by: ShopOrderableTimeTermOrderBy;
  shopOrderableTimeTerm_pk_columns_input: ShopOrderableTimeTermPkColumnsInput;
  shopOrderableTimeTerm_select_column: ShopOrderableTimeTermSelectColumn;
  shopOrderableTimeTerm_set_input: ShopOrderableTimeTermSetInput;
  shopOrderableTimeTerm_stream_cursor_input: ShopOrderableTimeTermStreamCursorInput;
  shopOrderableTimeTerm_stream_cursor_value_input: ShopOrderableTimeTermStreamCursorValueInput;
  shopOrderableTimeTerm_update_column: ShopOrderableTimeTermUpdateColumn;
  shopOrderableTimeTerm_updates: ShopOrderableTimeTermUpdates;
  shopPaymentType: ResolverTypeWrapper<ShopPaymentType>;
  shopPaymentType_aggregate_order_by: ShopPaymentTypeAggregateOrderBy;
  shopPaymentType_arr_rel_insert_input: ShopPaymentTypeArrRelInsertInput;
  shopPaymentType_bool_exp: ShopPaymentTypeBoolExp;
  shopPaymentType_constraint: ShopPaymentTypeConstraint;
  shopPaymentType_insert_input: ShopPaymentTypeInsertInput;
  shopPaymentType_max_order_by: ShopPaymentTypeMaxOrderBy;
  shopPaymentType_min_order_by: ShopPaymentTypeMinOrderBy;
  shopPaymentType_mutation_response: ResolverTypeWrapper<ShopPaymentTypeMutationResponse>;
  shopPaymentType_on_conflict: ShopPaymentTypeOnConflict;
  shopPaymentType_order_by: ShopPaymentTypeOrderBy;
  shopPaymentType_pk_columns_input: ShopPaymentTypePkColumnsInput;
  shopPaymentType_select_column: ShopPaymentTypeSelectColumn;
  shopPaymentType_set_input: ShopPaymentTypeSetInput;
  shopPaymentType_stream_cursor_input: ShopPaymentTypeStreamCursorInput;
  shopPaymentType_stream_cursor_value_input: ShopPaymentTypeStreamCursorValueInput;
  shopPaymentType_update_column: ShopPaymentTypeUpdateColumn;
  shopPaymentType_updates: ShopPaymentTypeUpdates;
  shopPlan: ResolverTypeWrapper<ShopPlan>;
  shopPlanChoice: ResolverTypeWrapper<ShopPlanChoice>;
  shopPlanChoice_aggregate_order_by: ShopPlanChoiceAggregateOrderBy;
  shopPlanChoice_avg_order_by: ShopPlanChoiceAvgOrderBy;
  shopPlanChoice_bool_exp: ShopPlanChoiceBoolExp;
  shopPlanChoice_inc_input: ShopPlanChoiceIncInput;
  shopPlanChoice_max_order_by: ShopPlanChoiceMaxOrderBy;
  shopPlanChoice_min_order_by: ShopPlanChoiceMinOrderBy;
  shopPlanChoice_mutation_response: ResolverTypeWrapper<ShopPlanChoiceMutationResponse>;
  shopPlanChoice_order_by: ShopPlanChoiceOrderBy;
  shopPlanChoice_pk_columns_input: ShopPlanChoicePkColumnsInput;
  shopPlanChoice_select_column: ShopPlanChoiceSelectColumn;
  shopPlanChoice_set_input: ShopPlanChoiceSetInput;
  shopPlanChoice_stddev_order_by: ShopPlanChoiceStddevOrderBy;
  shopPlanChoice_stddev_pop_order_by: ShopPlanChoiceStddevPopOrderBy;
  shopPlanChoice_stddev_samp_order_by: ShopPlanChoiceStddevSampOrderBy;
  shopPlanChoice_stream_cursor_input: ShopPlanChoiceStreamCursorInput;
  shopPlanChoice_stream_cursor_value_input: ShopPlanChoiceStreamCursorValueInput;
  shopPlanChoice_sum_order_by: ShopPlanChoiceSumOrderBy;
  shopPlanChoice_updates: ShopPlanChoiceUpdates;
  shopPlanChoice_var_pop_order_by: ShopPlanChoiceVarPopOrderBy;
  shopPlanChoice_var_samp_order_by: ShopPlanChoiceVarSampOrderBy;
  shopPlanChoice_variance_order_by: ShopPlanChoiceVarianceOrderBy;
  shopPlanKitchenRole: ResolverTypeWrapper<ShopPlanKitchenRole>;
  shopPlanKitchenRole_aggregate_order_by: ShopPlanKitchenRoleAggregateOrderBy;
  shopPlanKitchenRole_arr_rel_insert_input: ShopPlanKitchenRoleArrRelInsertInput;
  shopPlanKitchenRole_avg_order_by: ShopPlanKitchenRoleAvgOrderBy;
  shopPlanKitchenRole_bool_exp: ShopPlanKitchenRoleBoolExp;
  shopPlanKitchenRole_constraint: ShopPlanKitchenRoleConstraint;
  shopPlanKitchenRole_inc_input: ShopPlanKitchenRoleIncInput;
  shopPlanKitchenRole_insert_input: ShopPlanKitchenRoleInsertInput;
  shopPlanKitchenRole_max_order_by: ShopPlanKitchenRoleMaxOrderBy;
  shopPlanKitchenRole_min_order_by: ShopPlanKitchenRoleMinOrderBy;
  shopPlanKitchenRole_mutation_response: ResolverTypeWrapper<ShopPlanKitchenRoleMutationResponse>;
  shopPlanKitchenRole_on_conflict: ShopPlanKitchenRoleOnConflict;
  shopPlanKitchenRole_order_by: ShopPlanKitchenRoleOrderBy;
  shopPlanKitchenRole_select_column: ShopPlanKitchenRoleSelectColumn;
  shopPlanKitchenRole_set_input: ShopPlanKitchenRoleSetInput;
  shopPlanKitchenRole_stddev_order_by: ShopPlanKitchenRoleStddevOrderBy;
  shopPlanKitchenRole_stddev_pop_order_by: ShopPlanKitchenRoleStddevPopOrderBy;
  shopPlanKitchenRole_stddev_samp_order_by: ShopPlanKitchenRoleStddevSampOrderBy;
  shopPlanKitchenRole_stream_cursor_input: ShopPlanKitchenRoleStreamCursorInput;
  shopPlanKitchenRole_stream_cursor_value_input: ShopPlanKitchenRoleStreamCursorValueInput;
  shopPlanKitchenRole_sum_order_by: ShopPlanKitchenRoleSumOrderBy;
  shopPlanKitchenRole_update_column: ShopPlanKitchenRoleUpdateColumn;
  shopPlanKitchenRole_updates: ShopPlanKitchenRoleUpdates;
  shopPlanKitchenRole_var_pop_order_by: ShopPlanKitchenRoleVarPopOrderBy;
  shopPlanKitchenRole_var_samp_order_by: ShopPlanKitchenRoleVarSampOrderBy;
  shopPlanKitchenRole_variance_order_by: ShopPlanKitchenRoleVarianceOrderBy;
  shopPlanOption: ResolverTypeWrapper<ShopPlanOption>;
  shopPlanOptionKitchenRole: ResolverTypeWrapper<ShopPlanOptionKitchenRole>;
  shopPlanOptionKitchenRole_aggregate_order_by: ShopPlanOptionKitchenRoleAggregateOrderBy;
  shopPlanOptionKitchenRole_arr_rel_insert_input: ShopPlanOptionKitchenRoleArrRelInsertInput;
  shopPlanOptionKitchenRole_avg_order_by: ShopPlanOptionKitchenRoleAvgOrderBy;
  shopPlanOptionKitchenRole_bool_exp: ShopPlanOptionKitchenRoleBoolExp;
  shopPlanOptionKitchenRole_constraint: ShopPlanOptionKitchenRoleConstraint;
  shopPlanOptionKitchenRole_inc_input: ShopPlanOptionKitchenRoleIncInput;
  shopPlanOptionKitchenRole_insert_input: ShopPlanOptionKitchenRoleInsertInput;
  shopPlanOptionKitchenRole_max_order_by: ShopPlanOptionKitchenRoleMaxOrderBy;
  shopPlanOptionKitchenRole_min_order_by: ShopPlanOptionKitchenRoleMinOrderBy;
  shopPlanOptionKitchenRole_mutation_response: ResolverTypeWrapper<ShopPlanOptionKitchenRoleMutationResponse>;
  shopPlanOptionKitchenRole_on_conflict: ShopPlanOptionKitchenRoleOnConflict;
  shopPlanOptionKitchenRole_order_by: ShopPlanOptionKitchenRoleOrderBy;
  shopPlanOptionKitchenRole_select_column: ShopPlanOptionKitchenRoleSelectColumn;
  shopPlanOptionKitchenRole_set_input: ShopPlanOptionKitchenRoleSetInput;
  shopPlanOptionKitchenRole_stddev_order_by: ShopPlanOptionKitchenRoleStddevOrderBy;
  shopPlanOptionKitchenRole_stddev_pop_order_by: ShopPlanOptionKitchenRoleStddevPopOrderBy;
  shopPlanOptionKitchenRole_stddev_samp_order_by: ShopPlanOptionKitchenRoleStddevSampOrderBy;
  shopPlanOptionKitchenRole_stream_cursor_input: ShopPlanOptionKitchenRoleStreamCursorInput;
  shopPlanOptionKitchenRole_stream_cursor_value_input: ShopPlanOptionKitchenRoleStreamCursorValueInput;
  shopPlanOptionKitchenRole_sum_order_by: ShopPlanOptionKitchenRoleSumOrderBy;
  shopPlanOptionKitchenRole_update_column: ShopPlanOptionKitchenRoleUpdateColumn;
  shopPlanOptionKitchenRole_updates: ShopPlanOptionKitchenRoleUpdates;
  shopPlanOptionKitchenRole_var_pop_order_by: ShopPlanOptionKitchenRoleVarPopOrderBy;
  shopPlanOptionKitchenRole_var_samp_order_by: ShopPlanOptionKitchenRoleVarSampOrderBy;
  shopPlanOptionKitchenRole_variance_order_by: ShopPlanOptionKitchenRoleVarianceOrderBy;
  shopPlanOption_aggregate_order_by: ShopPlanOptionAggregateOrderBy;
  shopPlanOption_arr_rel_insert_input: ShopPlanOptionArrRelInsertInput;
  shopPlanOption_avg_order_by: ShopPlanOptionAvgOrderBy;
  shopPlanOption_bool_exp: ShopPlanOptionBoolExp;
  shopPlanOption_constraint: ShopPlanOptionConstraint;
  shopPlanOption_inc_input: ShopPlanOptionIncInput;
  shopPlanOption_insert_input: ShopPlanOptionInsertInput;
  shopPlanOption_max_order_by: ShopPlanOptionMaxOrderBy;
  shopPlanOption_min_order_by: ShopPlanOptionMinOrderBy;
  shopPlanOption_mutation_response: ResolverTypeWrapper<ShopPlanOptionMutationResponse>;
  shopPlanOption_on_conflict: ShopPlanOptionOnConflict;
  shopPlanOption_order_by: ShopPlanOptionOrderBy;
  shopPlanOption_pk_columns_input: ShopPlanOptionPkColumnsInput;
  shopPlanOption_select_column: ShopPlanOptionSelectColumn;
  shopPlanOption_set_input: ShopPlanOptionSetInput;
  shopPlanOption_stddev_order_by: ShopPlanOptionStddevOrderBy;
  shopPlanOption_stddev_pop_order_by: ShopPlanOptionStddevPopOrderBy;
  shopPlanOption_stddev_samp_order_by: ShopPlanOptionStddevSampOrderBy;
  shopPlanOption_stream_cursor_input: ShopPlanOptionStreamCursorInput;
  shopPlanOption_stream_cursor_value_input: ShopPlanOptionStreamCursorValueInput;
  shopPlanOption_sum_order_by: ShopPlanOptionSumOrderBy;
  shopPlanOption_update_column: ShopPlanOptionUpdateColumn;
  shopPlanOption_updates: ShopPlanOptionUpdates;
  shopPlanOption_var_pop_order_by: ShopPlanOptionVarPopOrderBy;
  shopPlanOption_var_samp_order_by: ShopPlanOptionVarSampOrderBy;
  shopPlanOption_variance_order_by: ShopPlanOptionVarianceOrderBy;
  shopPlan_aggregate_order_by: ShopPlanAggregateOrderBy;
  shopPlan_avg_order_by: ShopPlanAvgOrderBy;
  shopPlan_bool_exp: ShopPlanBoolExp;
  shopPlan_inc_input: ShopPlanIncInput;
  shopPlan_max_order_by: ShopPlanMaxOrderBy;
  shopPlan_min_order_by: ShopPlanMinOrderBy;
  shopPlan_mutation_response: ResolverTypeWrapper<ShopPlanMutationResponse>;
  shopPlan_order_by: ShopPlanOrderBy;
  shopPlan_pk_columns_input: ShopPlanPkColumnsInput;
  shopPlan_select_column: ShopPlanSelectColumn;
  shopPlan_set_input: ShopPlanSetInput;
  shopPlan_stddev_order_by: ShopPlanStddevOrderBy;
  shopPlan_stddev_pop_order_by: ShopPlanStddevPopOrderBy;
  shopPlan_stddev_samp_order_by: ShopPlanStddevSampOrderBy;
  shopPlan_stream_cursor_input: ShopPlanStreamCursorInput;
  shopPlan_stream_cursor_value_input: ShopPlanStreamCursorValueInput;
  shopPlan_sum_order_by: ShopPlanSumOrderBy;
  shopPlan_updates: ShopPlanUpdates;
  shopPlan_var_pop_order_by: ShopPlanVarPopOrderBy;
  shopPlan_var_samp_order_by: ShopPlanVarSampOrderBy;
  shopPlan_variance_order_by: ShopPlanVarianceOrderBy;
  shop_aggregate_order_by: ShopAggregateOrderBy;
  shop_arr_rel_insert_input: ShopArrRelInsertInput;
  shop_avg_order_by: ShopAvgOrderBy;
  shop_bool_exp: ShopBoolExp;
  shop_constraint: ShopConstraint;
  shop_inc_input: ShopIncInput;
  shop_insert_input: ShopInsertInput;
  shop_max_order_by: ShopMaxOrderBy;
  shop_min_order_by: ShopMinOrderBy;
  shop_mutation_response: ResolverTypeWrapper<ShopMutationResponse>;
  shop_obj_rel_insert_input: ShopObjRelInsertInput;
  shop_on_conflict: ShopOnConflict;
  shop_order_by: ShopOrderBy;
  shop_pk_columns_input: ShopPkColumnsInput;
  shop_select_column: ShopSelectColumn;
  shop_set_input: ShopSetInput;
  shop_stddev_order_by: ShopStddevOrderBy;
  shop_stddev_pop_order_by: ShopStddevPopOrderBy;
  shop_stddev_samp_order_by: ShopStddevSampOrderBy;
  shop_stream_cursor_input: ShopStreamCursorInput;
  shop_stream_cursor_value_input: ShopStreamCursorValueInput;
  shop_sum_order_by: ShopSumOrderBy;
  shop_update_column: ShopUpdateColumn;
  shop_updates: ShopUpdates;
  shop_var_pop_order_by: ShopVarPopOrderBy;
  shop_var_samp_order_by: ShopVarSampOrderBy;
  shop_variance_order_by: ShopVarianceOrderBy;
  specificTimeSurchargeConfig: ResolverTypeWrapper<SpecificTimeSurchargeConfig>;
  specificTimeSurchargeConfig_aggregate_order_by: SpecificTimeSurchargeConfigAggregateOrderBy;
  specificTimeSurchargeConfig_avg_order_by: SpecificTimeSurchargeConfigAvgOrderBy;
  specificTimeSurchargeConfig_bool_exp: SpecificTimeSurchargeConfigBoolExp;
  specificTimeSurchargeConfig_constraint: SpecificTimeSurchargeConfigConstraint;
  specificTimeSurchargeConfig_inc_input: SpecificTimeSurchargeConfigIncInput;
  specificTimeSurchargeConfig_insert_input: SpecificTimeSurchargeConfigInsertInput;
  specificTimeSurchargeConfig_max_order_by: SpecificTimeSurchargeConfigMaxOrderBy;
  specificTimeSurchargeConfig_min_order_by: SpecificTimeSurchargeConfigMinOrderBy;
  specificTimeSurchargeConfig_mutation_response: ResolverTypeWrapper<SpecificTimeSurchargeConfigMutationResponse>;
  specificTimeSurchargeConfig_obj_rel_insert_input: SpecificTimeSurchargeConfigObjRelInsertInput;
  specificTimeSurchargeConfig_on_conflict: SpecificTimeSurchargeConfigOnConflict;
  specificTimeSurchargeConfig_order_by: SpecificTimeSurchargeConfigOrderBy;
  specificTimeSurchargeConfig_pk_columns_input: SpecificTimeSurchargeConfigPkColumnsInput;
  specificTimeSurchargeConfig_select_column: SpecificTimeSurchargeConfigSelectColumn;
  specificTimeSurchargeConfig_set_input: SpecificTimeSurchargeConfigSetInput;
  specificTimeSurchargeConfig_stddev_order_by: SpecificTimeSurchargeConfigStddevOrderBy;
  specificTimeSurchargeConfig_stddev_pop_order_by: SpecificTimeSurchargeConfigStddevPopOrderBy;
  specificTimeSurchargeConfig_stddev_samp_order_by: SpecificTimeSurchargeConfigStddevSampOrderBy;
  specificTimeSurchargeConfig_stream_cursor_input: SpecificTimeSurchargeConfigStreamCursorInput;
  specificTimeSurchargeConfig_stream_cursor_value_input: SpecificTimeSurchargeConfigStreamCursorValueInput;
  specificTimeSurchargeConfig_sum_order_by: SpecificTimeSurchargeConfigSumOrderBy;
  specificTimeSurchargeConfig_update_column: SpecificTimeSurchargeConfigUpdateColumn;
  specificTimeSurchargeConfig_updates: SpecificTimeSurchargeConfigUpdates;
  specificTimeSurchargeConfig_var_pop_order_by: SpecificTimeSurchargeConfigVarPopOrderBy;
  specificTimeSurchargeConfig_var_samp_order_by: SpecificTimeSurchargeConfigVarSampOrderBy;
  specificTimeSurchargeConfig_variance_order_by: SpecificTimeSurchargeConfigVarianceOrderBy;
  specificTimeSurchargeMeta: ResolverTypeWrapper<SpecificTimeSurchargeMeta>;
  specificTimeSurchargeMeta_bool_exp: SpecificTimeSurchargeMetaBoolExp;
  specificTimeSurchargeMeta_order_by: SpecificTimeSurchargeMetaOrderBy;
  specificTimeSurchargeMeta_select_column: SpecificTimeSurchargeMetaSelectColumn;
  specificTimeSurchargeMeta_stream_cursor_input: SpecificTimeSurchargeMetaStreamCursorInput;
  specificTimeSurchargeMeta_stream_cursor_value_input: SpecificTimeSurchargeMetaStreamCursorValueInput;
  steraOnSitePaymentDetailType: ResolverTypeWrapper<SteraOnSitePaymentDetailType>;
  steraOnSitePaymentDetailType_aggregate_order_by: SteraOnSitePaymentDetailTypeAggregateOrderBy;
  steraOnSitePaymentDetailType_arr_rel_insert_input: SteraOnSitePaymentDetailTypeArrRelInsertInput;
  steraOnSitePaymentDetailType_bool_exp: SteraOnSitePaymentDetailTypeBoolExp;
  steraOnSitePaymentDetailType_constraint: SteraOnSitePaymentDetailTypeConstraint;
  steraOnSitePaymentDetailType_insert_input: SteraOnSitePaymentDetailTypeInsertInput;
  steraOnSitePaymentDetailType_max_order_by: SteraOnSitePaymentDetailTypeMaxOrderBy;
  steraOnSitePaymentDetailType_min_order_by: SteraOnSitePaymentDetailTypeMinOrderBy;
  steraOnSitePaymentDetailType_mutation_response: ResolverTypeWrapper<SteraOnSitePaymentDetailTypeMutationResponse>;
  steraOnSitePaymentDetailType_obj_rel_insert_input: SteraOnSitePaymentDetailTypeObjRelInsertInput;
  steraOnSitePaymentDetailType_on_conflict: SteraOnSitePaymentDetailTypeOnConflict;
  steraOnSitePaymentDetailType_order_by: SteraOnSitePaymentDetailTypeOrderBy;
  steraOnSitePaymentDetailType_pk_columns_input: SteraOnSitePaymentDetailTypePkColumnsInput;
  steraOnSitePaymentDetailType_select_column: SteraOnSitePaymentDetailTypeSelectColumn;
  steraOnSitePaymentDetailType_set_input: SteraOnSitePaymentDetailTypeSetInput;
  steraOnSitePaymentDetailType_stream_cursor_input: SteraOnSitePaymentDetailTypeStreamCursorInput;
  steraOnSitePaymentDetailType_stream_cursor_value_input: SteraOnSitePaymentDetailTypeStreamCursorValueInput;
  steraOnSitePaymentDetailType_update_column: SteraOnSitePaymentDetailTypeUpdateColumn;
  steraOnSitePaymentDetailType_updates: SteraOnSitePaymentDetailTypeUpdates;
  steraPaymentMethodType_enum: SteraPaymentMethodTypeEnum;
  steraPaymentMethodType_enum_comparison_exp: SteraPaymentMethodTypeEnumComparisonExp;
  stock: ResolverTypeWrapper<Stock>;
  stock_aggregate_order_by: StockAggregateOrderBy;
  stock_arr_rel_insert_input: StockArrRelInsertInput;
  stock_avg_order_by: StockAvgOrderBy;
  stock_bool_exp: StockBoolExp;
  stock_constraint: StockConstraint;
  stock_inc_input: StockIncInput;
  stock_insert_input: StockInsertInput;
  stock_max_order_by: StockMaxOrderBy;
  stock_min_order_by: StockMinOrderBy;
  stock_mutation_response: ResolverTypeWrapper<StockMutationResponse>;
  stock_on_conflict: StockOnConflict;
  stock_order_by: StockOrderBy;
  stock_pk_columns_input: StockPkColumnsInput;
  stock_select_column: StockSelectColumn;
  stock_set_input: StockSetInput;
  stock_stddev_order_by: StockStddevOrderBy;
  stock_stddev_pop_order_by: StockStddevPopOrderBy;
  stock_stddev_samp_order_by: StockStddevSampOrderBy;
  stock_stream_cursor_input: StockStreamCursorInput;
  stock_stream_cursor_value_input: StockStreamCursorValueInput;
  stock_sum_order_by: StockSumOrderBy;
  stock_update_column: StockUpdateColumn;
  stock_updates: StockUpdates;
  stock_var_pop_order_by: StockVarPopOrderBy;
  stock_var_samp_order_by: StockVarSampOrderBy;
  stock_variance_order_by: StockVarianceOrderBy;
  subscription_root: ResolverTypeWrapper<{}>;
  supportedLocale: ResolverTypeWrapper<SupportedLocale>;
  supportedLocale_bool_exp: SupportedLocaleBoolExp;
  supportedLocale_enum: SupportedLocaleEnum;
  supportedLocale_enum_comparison_exp: SupportedLocaleEnumComparisonExp;
  supportedLocale_order_by: SupportedLocaleOrderBy;
  supportedLocale_select_column: SupportedLocaleSelectColumn;
  supportedLocale_stream_cursor_input: SupportedLocaleStreamCursorInput;
  supportedLocale_stream_cursor_value_input: SupportedLocaleStreamCursorValueInput;
  table: ResolverTypeWrapper<Table>;
  tableArea: ResolverTypeWrapper<TableArea>;
  tableArea_aggregate: ResolverTypeWrapper<TableAreaAggregate>;
  tableArea_aggregate_bool_exp: TableAreaAggregateBoolExp;
  tableArea_aggregate_bool_exp_count: TableAreaAggregateBoolExpCount;
  tableArea_aggregate_fields: ResolverTypeWrapper<TableAreaAggregateFields>;
  tableArea_aggregate_order_by: TableAreaAggregateOrderBy;
  tableArea_arr_rel_insert_input: TableAreaArrRelInsertInput;
  tableArea_avg_fields: ResolverTypeWrapper<TableAreaAvgFields>;
  tableArea_avg_order_by: TableAreaAvgOrderBy;
  tableArea_bool_exp: TableAreaBoolExp;
  tableArea_constraint: TableAreaConstraint;
  tableArea_inc_input: TableAreaIncInput;
  tableArea_insert_input: TableAreaInsertInput;
  tableArea_max_fields: ResolverTypeWrapper<TableAreaMaxFields>;
  tableArea_max_order_by: TableAreaMaxOrderBy;
  tableArea_min_fields: ResolverTypeWrapper<TableAreaMinFields>;
  tableArea_min_order_by: TableAreaMinOrderBy;
  tableArea_mutation_response: ResolverTypeWrapper<TableAreaMutationResponse>;
  tableArea_obj_rel_insert_input: TableAreaObjRelInsertInput;
  tableArea_on_conflict: TableAreaOnConflict;
  tableArea_order_by: TableAreaOrderBy;
  tableArea_pk_columns_input: TableAreaPkColumnsInput;
  tableArea_select_column: TableAreaSelectColumn;
  tableArea_set_input: TableAreaSetInput;
  tableArea_stddev_fields: ResolverTypeWrapper<TableAreaStddevFields>;
  tableArea_stddev_order_by: TableAreaStddevOrderBy;
  tableArea_stddev_pop_fields: ResolverTypeWrapper<TableAreaStddevPopFields>;
  tableArea_stddev_pop_order_by: TableAreaStddevPopOrderBy;
  tableArea_stddev_samp_fields: ResolverTypeWrapper<TableAreaStddevSampFields>;
  tableArea_stddev_samp_order_by: TableAreaStddevSampOrderBy;
  tableArea_stream_cursor_input: TableAreaStreamCursorInput;
  tableArea_stream_cursor_value_input: TableAreaStreamCursorValueInput;
  tableArea_sum_fields: ResolverTypeWrapper<TableAreaSumFields>;
  tableArea_sum_order_by: TableAreaSumOrderBy;
  tableArea_update_column: TableAreaUpdateColumn;
  tableArea_updates: TableAreaUpdates;
  tableArea_var_pop_fields: ResolverTypeWrapper<TableAreaVarPopFields>;
  tableArea_var_pop_order_by: TableAreaVarPopOrderBy;
  tableArea_var_samp_fields: ResolverTypeWrapper<TableAreaVarSampFields>;
  tableArea_var_samp_order_by: TableAreaVarSampOrderBy;
  tableArea_variance_fields: ResolverTypeWrapper<TableAreaVarianceFields>;
  tableArea_variance_order_by: TableAreaVarianceOrderBy;
  tableUser: ResolverTypeWrapper<TableUser>;
  tableUserCustomer: ResolverTypeWrapper<TableUserCustomer>;
  tableUserCustomer_aggregate_order_by: TableUserCustomerAggregateOrderBy;
  tableUserCustomer_bool_exp: TableUserCustomerBoolExp;
  tableUserCustomer_max_order_by: TableUserCustomerMaxOrderBy;
  tableUserCustomer_min_order_by: TableUserCustomerMinOrderBy;
  tableUserCustomer_order_by: TableUserCustomerOrderBy;
  tableUserCustomer_select_column: TableUserCustomerSelectColumn;
  tableUserCustomer_stream_cursor_input: TableUserCustomerStreamCursorInput;
  tableUserCustomer_stream_cursor_value_input: TableUserCustomerStreamCursorValueInput;
  tableUserState_enum: TableUserStateEnum;
  tableUserState_enum_comparison_exp: TableUserStateEnumComparisonExp;
  tableUser_aggregate_order_by: TableUserAggregateOrderBy;
  tableUser_avg_order_by: TableUserAvgOrderBy;
  tableUser_bool_exp: TableUserBoolExp;
  tableUser_max_order_by: TableUserMaxOrderBy;
  tableUser_min_order_by: TableUserMinOrderBy;
  tableUser_order_by: TableUserOrderBy;
  tableUser_select_column: TableUserSelectColumn;
  tableUser_stddev_order_by: TableUserStddevOrderBy;
  tableUser_stddev_pop_order_by: TableUserStddevPopOrderBy;
  tableUser_stddev_samp_order_by: TableUserStddevSampOrderBy;
  tableUser_stream_cursor_input: TableUserStreamCursorInput;
  tableUser_stream_cursor_value_input: TableUserStreamCursorValueInput;
  tableUser_sum_order_by: TableUserSumOrderBy;
  tableUser_var_pop_order_by: TableUserVarPopOrderBy;
  tableUser_var_samp_order_by: TableUserVarSampOrderBy;
  tableUser_variance_order_by: TableUserVarianceOrderBy;
  table_aggregate: ResolverTypeWrapper<TableAggregate>;
  table_aggregate_bool_exp: TableAggregateBoolExp;
  table_aggregate_bool_exp_bool_and: TableAggregateBoolExpBoolAnd;
  table_aggregate_bool_exp_bool_or: TableAggregateBoolExpBoolOr;
  table_aggregate_bool_exp_count: TableAggregateBoolExpCount;
  table_aggregate_fields: ResolverTypeWrapper<TableAggregateFields>;
  table_aggregate_order_by: TableAggregateOrderBy;
  table_arr_rel_insert_input: TableArrRelInsertInput;
  table_avg_fields: ResolverTypeWrapper<TableAvgFields>;
  table_avg_order_by: TableAvgOrderBy;
  table_bool_exp: TableBoolExp;
  table_constraint: TableConstraint;
  table_inc_input: TableIncInput;
  table_insert_input: TableInsertInput;
  table_max_fields: ResolverTypeWrapper<TableMaxFields>;
  table_max_order_by: TableMaxOrderBy;
  table_min_fields: ResolverTypeWrapper<TableMinFields>;
  table_min_order_by: TableMinOrderBy;
  table_mutation_response: ResolverTypeWrapper<TableMutationResponse>;
  table_obj_rel_insert_input: TableObjRelInsertInput;
  table_on_conflict: TableOnConflict;
  table_order_by: TableOrderBy;
  table_pk_columns_input: TablePkColumnsInput;
  table_select_column: TableSelectColumn;
  table_select_column_table_aggregate_bool_exp_bool_and_arguments_columns: TableSelectColumnTableAggregateBoolExpBoolAndArgumentsColumnsType;
  table_select_column_table_aggregate_bool_exp_bool_or_arguments_columns: TableSelectColumnTableAggregateBoolExpBoolOrArgumentsColumnsType;
  table_set_input: TableSetInput;
  table_stddev_fields: ResolverTypeWrapper<TableStddevFields>;
  table_stddev_order_by: TableStddevOrderBy;
  table_stddev_pop_fields: ResolverTypeWrapper<TableStddevPopFields>;
  table_stddev_pop_order_by: TableStddevPopOrderBy;
  table_stddev_samp_fields: ResolverTypeWrapper<TableStddevSampFields>;
  table_stddev_samp_order_by: TableStddevSampOrderBy;
  table_stream_cursor_input: TableStreamCursorInput;
  table_stream_cursor_value_input: TableStreamCursorValueInput;
  table_sum_fields: ResolverTypeWrapper<TableSumFields>;
  table_sum_order_by: TableSumOrderBy;
  table_update_column: TableUpdateColumn;
  table_updates: TableUpdates;
  table_var_pop_fields: ResolverTypeWrapper<TableVarPopFields>;
  table_var_pop_order_by: TableVarPopOrderBy;
  table_var_samp_fields: ResolverTypeWrapper<TableVarSampFields>;
  table_var_samp_order_by: TableVarSampOrderBy;
  table_variance_fields: ResolverTypeWrapper<TableVarianceFields>;
  table_variance_order_by: TableVarianceOrderBy;
  taxMethod_enum: TaxMethodEnum;
  taxMethod_enum_comparison_exp: TaxMethodEnumComparisonExp;
  taxOffice: ResolverTypeWrapper<TaxOffice>;
  taxOffice_bool_exp: TaxOfficeBoolExp;
  taxOffice_order_by: TaxOfficeOrderBy;
  taxOffice_select_column: TaxOfficeSelectColumn;
  taxOffice_stream_cursor_input: TaxOfficeStreamCursorInput;
  taxOffice_stream_cursor_value_input: TaxOfficeStreamCursorValueInput;
  tecAggregationConfig: ResolverTypeWrapper<TecAggregationConfig>;
  tecAggregationConfig_aggregate_order_by: TecAggregationConfigAggregateOrderBy;
  tecAggregationConfig_arr_rel_insert_input: TecAggregationConfigArrRelInsertInput;
  tecAggregationConfig_avg_order_by: TecAggregationConfigAvgOrderBy;
  tecAggregationConfig_bool_exp: TecAggregationConfigBoolExp;
  tecAggregationConfig_constraint: TecAggregationConfigConstraint;
  tecAggregationConfig_inc_input: TecAggregationConfigIncInput;
  tecAggregationConfig_insert_input: TecAggregationConfigInsertInput;
  tecAggregationConfig_max_order_by: TecAggregationConfigMaxOrderBy;
  tecAggregationConfig_min_order_by: TecAggregationConfigMinOrderBy;
  tecAggregationConfig_mutation_response: ResolverTypeWrapper<TecAggregationConfigMutationResponse>;
  tecAggregationConfig_on_conflict: TecAggregationConfigOnConflict;
  tecAggregationConfig_order_by: TecAggregationConfigOrderBy;
  tecAggregationConfig_pk_columns_input: TecAggregationConfigPkColumnsInput;
  tecAggregationConfig_select_column: TecAggregationConfigSelectColumn;
  tecAggregationConfig_set_input: TecAggregationConfigSetInput;
  tecAggregationConfig_stddev_order_by: TecAggregationConfigStddevOrderBy;
  tecAggregationConfig_stddev_pop_order_by: TecAggregationConfigStddevPopOrderBy;
  tecAggregationConfig_stddev_samp_order_by: TecAggregationConfigStddevSampOrderBy;
  tecAggregationConfig_stream_cursor_input: TecAggregationConfigStreamCursorInput;
  tecAggregationConfig_stream_cursor_value_input: TecAggregationConfigStreamCursorValueInput;
  tecAggregationConfig_sum_order_by: TecAggregationConfigSumOrderBy;
  tecAggregationConfig_update_column: TecAggregationConfigUpdateColumn;
  tecAggregationConfig_updates: TecAggregationConfigUpdates;
  tecAggregationConfig_var_pop_order_by: TecAggregationConfigVarPopOrderBy;
  tecAggregationConfig_var_samp_order_by: TecAggregationConfigVarSampOrderBy;
  tecAggregationConfig_variance_order_by: TecAggregationConfigVarianceOrderBy;
  tecAggregationIntegrationType_enum: TecAggregationIntegrationTypeEnum;
  tecAggregationIntegrationType_enum_comparison_exp: TecAggregationIntegrationTypeEnumComparisonExp;
  tecAggregationMediaMap: ResolverTypeWrapper<TecAggregationMediaMap>;
  tecAggregationMediaMap_aggregate_order_by: TecAggregationMediaMapAggregateOrderBy;
  tecAggregationMediaMap_arr_rel_insert_input: TecAggregationMediaMapArrRelInsertInput;
  tecAggregationMediaMap_avg_order_by: TecAggregationMediaMapAvgOrderBy;
  tecAggregationMediaMap_bool_exp: TecAggregationMediaMapBoolExp;
  tecAggregationMediaMap_constraint: TecAggregationMediaMapConstraint;
  tecAggregationMediaMap_inc_input: TecAggregationMediaMapIncInput;
  tecAggregationMediaMap_insert_input: TecAggregationMediaMapInsertInput;
  tecAggregationMediaMap_max_order_by: TecAggregationMediaMapMaxOrderBy;
  tecAggregationMediaMap_min_order_by: TecAggregationMediaMapMinOrderBy;
  tecAggregationMediaMap_mutation_response: ResolverTypeWrapper<TecAggregationMediaMapMutationResponse>;
  tecAggregationMediaMap_obj_rel_insert_input: TecAggregationMediaMapObjRelInsertInput;
  tecAggregationMediaMap_on_conflict: TecAggregationMediaMapOnConflict;
  tecAggregationMediaMap_order_by: TecAggregationMediaMapOrderBy;
  tecAggregationMediaMap_pk_columns_input: TecAggregationMediaMapPkColumnsInput;
  tecAggregationMediaMap_select_column: TecAggregationMediaMapSelectColumn;
  tecAggregationMediaMap_set_input: TecAggregationMediaMapSetInput;
  tecAggregationMediaMap_stddev_order_by: TecAggregationMediaMapStddevOrderBy;
  tecAggregationMediaMap_stddev_pop_order_by: TecAggregationMediaMapStddevPopOrderBy;
  tecAggregationMediaMap_stddev_samp_order_by: TecAggregationMediaMapStddevSampOrderBy;
  tecAggregationMediaMap_stream_cursor_input: TecAggregationMediaMapStreamCursorInput;
  tecAggregationMediaMap_stream_cursor_value_input: TecAggregationMediaMapStreamCursorValueInput;
  tecAggregationMediaMap_sum_order_by: TecAggregationMediaMapSumOrderBy;
  tecAggregationMediaMap_update_column: TecAggregationMediaMapUpdateColumn;
  tecAggregationMediaMap_updates: TecAggregationMediaMapUpdates;
  tecAggregationMediaMap_var_pop_order_by: TecAggregationMediaMapVarPopOrderBy;
  tecAggregationMediaMap_var_samp_order_by: TecAggregationMediaMapVarSampOrderBy;
  tecAggregationMediaMap_variance_order_by: TecAggregationMediaMapVarianceOrderBy;
  tecAggregationMenu: ResolverTypeWrapper<TecAggregationMenu>;
  tecAggregationMenu_bool_exp: TecAggregationMenuBoolExp;
  tecAggregationMenu_constraint: TecAggregationMenuConstraint;
  tecAggregationMenu_inc_input: TecAggregationMenuIncInput;
  tecAggregationMenu_insert_input: TecAggregationMenuInsertInput;
  tecAggregationMenu_mutation_response: ResolverTypeWrapper<TecAggregationMenuMutationResponse>;
  tecAggregationMenu_obj_rel_insert_input: TecAggregationMenuObjRelInsertInput;
  tecAggregationMenu_on_conflict: TecAggregationMenuOnConflict;
  tecAggregationMenu_order_by: TecAggregationMenuOrderBy;
  tecAggregationMenu_pk_columns_input: TecAggregationMenuPkColumnsInput;
  tecAggregationMenu_select_column: TecAggregationMenuSelectColumn;
  tecAggregationMenu_set_input: TecAggregationMenuSetInput;
  tecAggregationMenu_stream_cursor_input: TecAggregationMenuStreamCursorInput;
  tecAggregationMenu_stream_cursor_value_input: TecAggregationMenuStreamCursorValueInput;
  tecAggregationMenu_update_column: TecAggregationMenuUpdateColumn;
  tecAggregationMenu_updates: TecAggregationMenuUpdates;
  tecAggregationOnSitePaymentDetailType: ResolverTypeWrapper<TecAggregationOnSitePaymentDetailType>;
  tecAggregationOnSitePaymentDetailType_aggregate_order_by: TecAggregationOnSitePaymentDetailTypeAggregateOrderBy;
  tecAggregationOnSitePaymentDetailType_arr_rel_insert_input: TecAggregationOnSitePaymentDetailTypeArrRelInsertInput;
  tecAggregationOnSitePaymentDetailType_bool_exp: TecAggregationOnSitePaymentDetailTypeBoolExp;
  tecAggregationOnSitePaymentDetailType_constraint: TecAggregationOnSitePaymentDetailTypeConstraint;
  tecAggregationOnSitePaymentDetailType_insert_input: TecAggregationOnSitePaymentDetailTypeInsertInput;
  tecAggregationOnSitePaymentDetailType_max_order_by: TecAggregationOnSitePaymentDetailTypeMaxOrderBy;
  tecAggregationOnSitePaymentDetailType_min_order_by: TecAggregationOnSitePaymentDetailTypeMinOrderBy;
  tecAggregationOnSitePaymentDetailType_mutation_response: ResolverTypeWrapper<TecAggregationOnSitePaymentDetailTypeMutationResponse>;
  tecAggregationOnSitePaymentDetailType_on_conflict: TecAggregationOnSitePaymentDetailTypeOnConflict;
  tecAggregationOnSitePaymentDetailType_order_by: TecAggregationOnSitePaymentDetailTypeOrderBy;
  tecAggregationOnSitePaymentDetailType_pk_columns_input: TecAggregationOnSitePaymentDetailTypePkColumnsInput;
  tecAggregationOnSitePaymentDetailType_select_column: TecAggregationOnSitePaymentDetailTypeSelectColumn;
  tecAggregationOnSitePaymentDetailType_set_input: TecAggregationOnSitePaymentDetailTypeSetInput;
  tecAggregationOnSitePaymentDetailType_stream_cursor_input: TecAggregationOnSitePaymentDetailTypeStreamCursorInput;
  tecAggregationOnSitePaymentDetailType_stream_cursor_value_input: TecAggregationOnSitePaymentDetailTypeStreamCursorValueInput;
  tecAggregationOnSitePaymentDetailType_update_column: TecAggregationOnSitePaymentDetailTypeUpdateColumn;
  tecAggregationOnSitePaymentDetailType_updates: TecAggregationOnSitePaymentDetailTypeUpdates;
  textTypeQuestionAnswer: ResolverTypeWrapper<TextTypeQuestionAnswer>;
  textTypeQuestionAnswer_bool_exp: TextTypeQuestionAnswerBoolExp;
  textTypeQuestionAnswer_order_by: TextTypeQuestionAnswerOrderBy;
  textTypeQuestionAnswer_select_column: TextTypeQuestionAnswerSelectColumn;
  textTypeQuestionAnswer_stream_cursor_input: TextTypeQuestionAnswerStreamCursorInput;
  textTypeQuestionAnswer_stream_cursor_value_input: TextTypeQuestionAnswerStreamCursorValueInput;
  time: ResolverTypeWrapper<Scalars['time']>;
  time_comparison_exp: TimeComparisonExp;
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']>;
  timestamptz_comparison_exp: TimestamptzComparisonExp;
  timetz: ResolverTypeWrapper<Scalars['timetz']>;
  timetz_comparison_exp: TimetzComparisonExp;
  translation: ResolverTypeWrapper<Translation>;
  translation_aggregate_order_by: TranslationAggregateOrderBy;
  translation_bool_exp: TranslationBoolExp;
  translation_max_order_by: TranslationMaxOrderBy;
  translation_min_order_by: TranslationMinOrderBy;
  translation_mutation_response: ResolverTypeWrapper<TranslationMutationResponse>;
  translation_order_by: TranslationOrderBy;
  translation_pk_columns_input: TranslationPkColumnsInput;
  translation_select_column: TranslationSelectColumn;
  translation_set_input: TranslationSetInput;
  translation_stream_cursor_input: TranslationStreamCursorInput;
  translation_stream_cursor_value_input: TranslationStreamCursorValueInput;
  translation_updates: TranslationUpdates;
  uuid: ResolverTypeWrapper<Scalars['uuid']>;
  uuid_comparison_exp: UuidComparisonExp;
  winboardConfig: ResolverTypeWrapper<WinboardConfig>;
  winboardConfig_bool_exp: WinboardConfigBoolExp;
  winboardConfig_constraint: WinboardConfigConstraint;
  winboardConfig_inc_input: WinboardConfigIncInput;
  winboardConfig_insert_input: WinboardConfigInsertInput;
  winboardConfig_mutation_response: ResolverTypeWrapper<WinboardConfigMutationResponse>;
  winboardConfig_obj_rel_insert_input: WinboardConfigObjRelInsertInput;
  winboardConfig_on_conflict: WinboardConfigOnConflict;
  winboardConfig_order_by: WinboardConfigOrderBy;
  winboardConfig_pk_columns_input: WinboardConfigPkColumnsInput;
  winboardConfig_select_column: WinboardConfigSelectColumn;
  winboardConfig_set_input: WinboardConfigSetInput;
  winboardConfig_stream_cursor_input: WinboardConfigStreamCursorInput;
  winboardConfig_stream_cursor_value_input: WinboardConfigStreamCursorValueInput;
  winboardConfig_update_column: WinboardConfigUpdateColumn;
  winboardConfig_updates: WinboardConfigUpdates;
  winboardInflowSourceTag: ResolverTypeWrapper<WinboardInflowSourceTag>;
  winboardInflowSourceTag_bool_exp: WinboardInflowSourceTagBoolExp;
  winboardInflowSourceTag_constraint: WinboardInflowSourceTagConstraint;
  winboardInflowSourceTag_insert_input: WinboardInflowSourceTagInsertInput;
  winboardInflowSourceTag_mutation_response: ResolverTypeWrapper<WinboardInflowSourceTagMutationResponse>;
  winboardInflowSourceTag_obj_rel_insert_input: WinboardInflowSourceTagObjRelInsertInput;
  winboardInflowSourceTag_on_conflict: WinboardInflowSourceTagOnConflict;
  winboardInflowSourceTag_order_by: WinboardInflowSourceTagOrderBy;
  winboardInflowSourceTag_pk_columns_input: WinboardInflowSourceTagPkColumnsInput;
  winboardInflowSourceTag_select_column: WinboardInflowSourceTagSelectColumn;
  winboardInflowSourceTag_set_input: WinboardInflowSourceTagSetInput;
  winboardInflowSourceTag_stream_cursor_input: WinboardInflowSourceTagStreamCursorInput;
  winboardInflowSourceTag_stream_cursor_value_input: WinboardInflowSourceTagStreamCursorValueInput;
  winboardInflowSourceTag_update_column: WinboardInflowSourceTagUpdateColumn;
  winboardInflowSourceTag_updates: WinboardInflowSourceTagUpdates;
  winboardMenu: ResolverTypeWrapper<WinboardMenu>;
  winboardMenu_aggregate_order_by: WinboardMenuAggregateOrderBy;
  winboardMenu_arr_rel_insert_input: WinboardMenuArrRelInsertInput;
  winboardMenu_avg_order_by: WinboardMenuAvgOrderBy;
  winboardMenu_bool_exp: WinboardMenuBoolExp;
  winboardMenu_constraint: WinboardMenuConstraint;
  winboardMenu_inc_input: WinboardMenuIncInput;
  winboardMenu_insert_input: WinboardMenuInsertInput;
  winboardMenu_max_order_by: WinboardMenuMaxOrderBy;
  winboardMenu_min_order_by: WinboardMenuMinOrderBy;
  winboardMenu_mutation_response: ResolverTypeWrapper<WinboardMenuMutationResponse>;
  winboardMenu_on_conflict: WinboardMenuOnConflict;
  winboardMenu_order_by: WinboardMenuOrderBy;
  winboardMenu_pk_columns_input: WinboardMenuPkColumnsInput;
  winboardMenu_select_column: WinboardMenuSelectColumn;
  winboardMenu_set_input: WinboardMenuSetInput;
  winboardMenu_stddev_order_by: WinboardMenuStddevOrderBy;
  winboardMenu_stddev_pop_order_by: WinboardMenuStddevPopOrderBy;
  winboardMenu_stddev_samp_order_by: WinboardMenuStddevSampOrderBy;
  winboardMenu_stream_cursor_input: WinboardMenuStreamCursorInput;
  winboardMenu_stream_cursor_value_input: WinboardMenuStreamCursorValueInput;
  winboardMenu_sum_order_by: WinboardMenuSumOrderBy;
  winboardMenu_update_column: WinboardMenuUpdateColumn;
  winboardMenu_updates: WinboardMenuUpdates;
  winboardMenu_var_pop_order_by: WinboardMenuVarPopOrderBy;
  winboardMenu_var_samp_order_by: WinboardMenuVarSampOrderBy;
  winboardMenu_variance_order_by: WinboardMenuVarianceOrderBy;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AdyenPaymentCreateStoreMerchantCategoryInput: AdyenPaymentCreateStoreMerchantCategoryInput;
  String: Scalars['String'];
  AdyenPaymentCreateStoreMerchantCategoryResult: AdyenPaymentCreateStoreMerchantCategoryResult;
  Boolean: Scalars['Boolean'];
  AdyenPaymentUpdateStoreInput: AdyenPaymentUpdateStoreInput;
  AdyenPaymentUpdateStoreResult: AdyenPaymentUpdateStoreResult;
  AggregatedCategory: AggregatedCategory;
  Int: Scalars['Int'];
  AggregatedChoice: AggregatedChoice;
  AggregatedMenu: AggregatedMenu;
  AggregatedOption: AggregatedOption;
  AggregatedPlan: AggregatedPlan;
  AggregatedPlanOption: AggregatedPlanOption;
  AggregatedQuestionnaireScore: AggregatedQuestionnaireScore;
  AggregatedSales: AggregatedSales;
  Float: Scalars['Float'];
  AmbassadorTransition: AmbassadorTransition;
  ArchiveCompanyInput: ArchiveCompanyInput;
  ArchiveMenuInput: ArchiveMenuInput;
  ArchiveMenuResult: ArchiveMenuResult;
  ArchivePlanInput: ArchivePlanInput;
  ArchivePlanResult: ArchivePlanResult;
  ArchiveShopInput: ArchiveShopInput;
  ArchiveTableInput: ArchiveTableInput;
  AverageAndMaxSalesByDayOfWeek: AverageAndMaxSalesByDayOfWeek;
  AverageAndMaxSalesByDayOfWeekInput: AverageAndMaxSalesByDayOfWeekInput;
  Boolean_comparison_exp: BooleanComparisonExp;
  CashBalanceCashDenominationCount: CashBalanceCashDenominationCount;
  CashManagement: CashManagement;
  CompanyCustomerSummary: CompanyCustomerSummary;
  CompanyCustomerSummaryInput: CompanyCustomerSummaryInput;
  CompanyManagerDeleteDashboardAccountInput: CompanyManagerDeleteDashboardAccountInput;
  CompanySalesAnalyticsInput: CompanySalesAnalyticsInput;
  CompanySalesAnalyticsOutput: CompanySalesAnalyticsOutput;
  CompanySalesAnalyticsOutputItem: CompanySalesAnalyticsOutputItem;
  CopyDataInput: CopyDataInput;
  CountMessageDeliveryTargetsInput: CountMessageDeliveryTargetsInput;
  CouponAnalyticsInput: CouponAnalyticsInput;
  CouponAnalyticsPerCoupon: CouponAnalyticsPerCoupon;
  CouponAnalyticsPerCouponItem: CouponAnalyticsPerCouponItem;
  CouponAnalyticsSummary: CouponAnalyticsSummary;
  CreateCategoryInputCategorySource: CreateCategoryInputCategorySource;
  CreateCategoryInputTranslationSource: CreateCategoryInputTranslationSource;
  CreateCategoryOutput: CreateCategoryOutput;
  CreateCategoryTranslationSource: CreateCategoryTranslationSource;
  CreateCompanyInput: CreateCompanyInput;
  CreateCorporationInput: CreateCorporationInput;
  CreateDashboardAccountInput: CreateDashboardAccountInput;
  CreateLineOfficialAccountInput: CreateLineOfficialAccountInput;
  CreateMenuInput: CreateMenuInput;
  CreateMenuInputCategoryMenu: CreateMenuInputCategoryMenu;
  CreateMenuInputMenuSource: CreateMenuInputMenuSource;
  CreateMenuInputMenuSourceOption: CreateMenuInputMenuSourceOption;
  CreateMenuInputTranslationSource: CreateMenuInputTranslationSource;
  CreateMenuOutput: CreateMenuOutput;
  CreateMenuRecommendationInputSource: CreateMenuRecommendationInputSource;
  CreateMenuRecommendationInputTranslationSource: CreateMenuRecommendationInputTranslationSource;
  CreateMenuRecommendationOutput: CreateMenuRecommendationOutput;
  CreateMenuRecommendationTranslationSource: CreateMenuRecommendationTranslationSource;
  CreateMessageDeliveryInput: CreateMessageDeliveryInput;
  CreateMessageDeliveryInputMessageDeliveryCustomerList: CreateMessageDeliveryInputMessageDeliveryCustomerList;
  CreateMessageDeliveryInputMessageDeliveryCustomerListCustomer: CreateMessageDeliveryInputMessageDeliveryCustomerListCustomer;
  CreateMessageDeliveryInputMessageDeliveryCustomerMenu: CreateMessageDeliveryInputMessageDeliveryCustomerMenu;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegment: CreateMessageDeliveryInputMessageDeliveryCustomerSegment;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentBusinessOperationHour: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentBusinessOperationHour;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDayOfWeek: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDayOfWeek;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDaysCountUntilBirthday: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentDaysCountUntilBirthday;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentGender: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentGender;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentNumericCondition: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentNumericCondition;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentQuestionnaireAnswer: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentQuestionnaireAnswer;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentUnVisitedDayCount: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentUnVisitedDayCount;
  CreateMessageDeliveryInputMessageDeliveryCustomerSegmentVisitedDayCount: CreateMessageDeliveryInputMessageDeliveryCustomerSegmentVisitedDayCount;
  CreateMessageDeliveryInputMessageDeliveryCustomerShop: CreateMessageDeliveryInputMessageDeliveryCustomerShop;
  CreateMessageDeliveryInputMessageDeliveryMessage: CreateMessageDeliveryInputMessageDeliveryMessage;
  CreateMessageDeliveryInputMessageDeliveryMessageCouponTypeMeta: CreateMessageDeliveryInputMessageDeliveryMessageCouponTypeMeta;
  CreateMessageDeliveryInputMessageDeliveryMessageImageTypeMeta: CreateMessageDeliveryInputMessageDeliveryMessageImageTypeMeta;
  CreateMessageDeliveryInputMessageDeliveryMessageQuestionnaireTypeMeta: CreateMessageDeliveryInputMessageDeliveryMessageQuestionnaireTypeMeta;
  CreateMessageDeliveryInputMessageDeliveryMessageTextTypeMeta: CreateMessageDeliveryInputMessageDeliveryMessageTextTypeMeta;
  CreateOptionInputOptionSource: CreateOptionInputOptionSource;
  CreateOptionInputOptionSourceChoice: CreateOptionInputOptionSourceChoice;
  CreateOptionInputTranslationSource: CreateOptionInputTranslationSource;
  CreateOptionOutput: CreateOptionOutput;
  CreateOptionTranslationSource: CreateOptionTranslationSource;
  CreatePlanChoiceInput: CreatePlanChoiceInput;
  CreatePlanChoiceInputPlanChoiceSource: CreatePlanChoiceInputPlanChoiceSource;
  CreatePlanChoiceInputTranslationSource: CreatePlanChoiceInputTranslationSource;
  CreatePlanChoiceOutput: CreatePlanChoiceOutput;
  CreatePlanChoiceTranslationSource: CreatePlanChoiceTranslationSource;
  CreatePlanInput: CreatePlanInput;
  CreatePlanInputPlanSource: CreatePlanInputPlanSource;
  CreatePlanInputTranslationSource: CreatePlanInputTranslationSource;
  CreatePlanOptionInput: CreatePlanOptionInput;
  CreatePlanOptionInputPlanOptionSource: CreatePlanOptionInputPlanOptionSource;
  CreatePlanOptionInputTranslationSource: CreatePlanOptionInputTranslationSource;
  CreatePlanOptionOutput: CreatePlanOptionOutput;
  CreatePlanOptionTranslationSource: CreatePlanOptionTranslationSource;
  CreatePlanOutput: CreatePlanOutput;
  CreatePlanTranslationSource: CreatePlanTranslationSource;
  CreateServiceAdminInput: CreateServiceAdminInput;
  CreateShopInput: CreateShopInput;
  CreateShopMenusInput: CreateShopMenusInput;
  CreateShopMenusResult: CreateShopMenusResult;
  CreateShopPlanInput: CreateShopPlanInput;
  CreateShopPlanResult: CreateShopPlanResult;
  CreateSurchargeConfigsInput: CreateSurchargeConfigsInput;
  CreateSurchargeConfigsResult: CreateSurchargeConfigsResult;
  CreateTranslationSource: CreateTranslationSource;
  CumulativeCount: CumulativeCount;
  CurrentSales: CurrentSales;
  CurrentSaleses: CurrentSaleses;
  CustomerCountTransition: CustomerCountTransition;
  CustomerDashboardKpi: CustomerDashboardKpi;
  CustomerDashboardKpis: CustomerDashboardKpis;
  CustomerDashboardKpisInput: CustomerDashboardKpisInput;
  CustomerDashboardTransitions: CustomerDashboardTransitions;
  CustomerFaveYellRanking: CustomerFaveYellRanking;
  CustomerFaveYellRankingCustomer: CustomerFaveYellRankingCustomer;
  CustomerRanking: CustomerRanking;
  CustomerRankingCustomer: CustomerRankingCustomer;
  DailyCashRegisterBalancing: DailyCashRegisterBalancing;
  DailySalesAmount: DailySalesAmount;
  DailySalesSummary: DailySalesSummary;
  DashboardAccountCancelOnSitePaymentInput: DashboardAccountCancelOnSitePaymentInput;
  DashboardAccountCreateOrUpdateGmoBankAccountInput: DashboardAccountCreateOrUpdateGmoBankAccountInput;
  DashboardAccountCreateOrUpdateGmoBankAccountResult: DashboardAccountCreateOrUpdateGmoBankAccountResult;
  DashboardAccountCreateTecAggregationMenuOutput: ResolversParentTypes['DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode'] | ResolversParentTypes['DashboardUpsertTecAggregationMenuSuccess'];
  DashboardAccountGmoBankAccountDetailInput: DashboardAccountGmoBankAccountDetailInput;
  DashboardAccountGmoBankAccountDetailResult: DashboardAccountGmoBankAccountDetailResult;
  DashboardAccountGmoShopConfigDetailInput: DashboardAccountGmoShopConfigDetailInput;
  DashboardAccountGmoShopConfigDetailOutput: DashboardAccountGmoShopConfigDetailOutput;
  DashboardAccountOnSitePaymentDetail: DashboardAccountOnSitePaymentDetail;
  DashboardAccountOnSitePaymentDiscount: DashboardAccountOnSitePaymentDiscount;
  DashboardAccountPayingActivePlanChoice: DashboardAccountPayingActivePlanChoice;
  DashboardAccountPayingActivePlanOpenPriceMeta: DashboardAccountPayingActivePlanOpenPriceMeta;
  DashboardAccountPayingMenuOrderItem: DashboardAccountPayingMenuOrderItem;
  DashboardAccountUpsertDiniiPayConfigInput: DashboardAccountUpsertDiniiPayConfigInput;
  DashboardAccountUpsertDiniiPayConfigOnlinePaymentConfig: DashboardAccountUpsertDiniiPayConfigOnlinePaymentConfig;
  DashboardAccountUpsertDiniiPayConfigOutput: DashboardAccountUpsertDiniiPayConfigOutput;
  DashboardAccountUpsertDiniiPayConfigTerminalPaymentConfig: DashboardAccountUpsertDiniiPayConfigTerminalPaymentConfig;
  DashboardAccountUpsertTecAggregationMenuInput: DashboardAccountUpsertTecAggregationMenuInput;
  DashboardAccountUpsertTecAggregationMenusBulkInput: DashboardAccountUpsertTecAggregationMenusBulkInput;
  DashboardAccountUpsertTecAggregationMenusBulkOutput: ResolversParentTypes['DashboardUpsertTecAggregationMenuSuccess'] | ResolversParentTypes['DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode'];
  DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode: DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode;
  DashboardUpsertTecAggregationMenuSuccess: DashboardUpsertTecAggregationMenuSuccess;
  DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode: DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode;
  DateTime: Scalars['DateTime'];
  DeleteShopChoicesInput: DeleteShopChoicesInput;
  DeleteShopChoicesResult: DeleteShopChoicesResult;
  DeleteShopMenusInput: DeleteShopMenusInput;
  DeleteShopMenusResult: DeleteShopMenusResult;
  DeleteShopPlanChoicesInput: DeleteShopPlanChoicesInput;
  DeleteShopPlanChoicesResult: DeleteShopPlanChoicesResult;
  DeleteShopPlansInput: DeleteShopPlansInput;
  DeleteShopPlansResult: DeleteShopPlansResult;
  DepositCycleShopIds: DepositCycleShopIds;
  DistributionItem: DistributionItem;
  EbicaApiTable: EbicaApiTable;
  EbicaApiTablesInput: EbicaApiTablesInput;
  EnableOnlinePaymentInput: EnableOnlinePaymentInput;
  EnableOnlinePaymentOutput: EnableOnlinePaymentOutput;
  FaveYellAnalyticsByCorporationInput: FaveYellAnalyticsByCorporationInput;
  FaveYellAnalyticsByCorporationOutput: FaveYellAnalyticsByCorporationOutput;
  FaveYellAnalyticsPerStaffByCorporationItem: FaveYellAnalyticsPerStaffByCorporationItem;
  FaveYellAnalyticsSummaryByCorporationOutputItem: FaveYellAnalyticsSummaryByCorporationOutputItem;
  FaveYellTransition: FaveYellTransition;
  FoodingJournalUploadSalesInput: FoodingJournalUploadSalesInput;
  GeneralTransition: GeneralTransition;
  Int_comparison_exp: IntComparisonExp;
  IssueLineReportingBotVerificationCodeInput: IssueLineReportingBotVerificationCodeInput;
  IssueLineReportingBotVerificationCodeResponse: IssueLineReportingBotVerificationCodeResponse;
  LineOfficialAccountProfile: LineOfficialAccountProfile;
  LineOfficialAccountSummary: LineOfficialAccountSummary;
  MenuOrderItemAnalyticsInput: MenuOrderItemAnalyticsInput;
  MenuOrderItemAnalyticsOutput: MenuOrderItemAnalyticsOutput;
  MenuOrderItemAnalyticsOutputItem: MenuOrderItemAnalyticsOutputItem;
  MessageDeliveryAnalyticsInput: MessageDeliveryAnalyticsInput;
  MessageDeliveryAnalyticsRecentVisitedTableUser: MessageDeliveryAnalyticsRecentVisitedTableUser;
  MessageDeliveryAnalyticsRecentVisitedTableUsersReport: MessageDeliveryAnalyticsRecentVisitedTableUsersReport;
  MessageDeliveryAnalyticsSummary: MessageDeliveryAnalyticsSummary;
  MessageDeliveryAnalyticsSummaryPerMessageDelivery: MessageDeliveryAnalyticsSummaryPerMessageDelivery;
  MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem: MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem;
  MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem: MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem;
  MessageDeliveryAnalyticsSummaryPerShop: MessageDeliveryAnalyticsSummaryPerShop;
  MessageDeliveryAnalyticsSummaryPerShopItem: MessageDeliveryAnalyticsSummaryPerShopItem;
  MessageDeliveryConsumption: MessageDeliveryConsumption;
  MonthlySalesAmount: MonthlySalesAmount;
  QueryCashBalanceInput: QueryCashBalanceInput;
  QueryCashManagementInput: QueryCashManagementInput;
  QueryCumulativeCountInput: QueryCumulativeCountInput;
  QueryCurrentSalesInput: QueryCurrentSalesInput;
  QueryCustomerRankingInput: QueryCustomerRankingInput;
  QueryDailyCashRegisterBalancingInput: QueryDailyCashRegisterBalancingInput;
  QueryDailySalesSummaryInput: QueryDailySalesSummaryInput;
  QueryLineOfficialAccountInput: QueryLineOfficialAccountInput;
  QueryLineOfficialAccountSummaryInput: QueryLineOfficialAccountSummaryInput;
  QueryMessageDeliveryConsumptionInput: QueryMessageDeliveryConsumptionInput;
  QuerySalesBreakdownByPaymentMethodInput: QuerySalesBreakdownByPaymentMethodInput;
  QuerySalesInput: QuerySalesInput;
  QueryTransitionsInput: QueryTransitionsInput;
  QuestionnaireAnswererAttributeMetricsDistributionItem: QuestionnaireAnswererAttributeMetricsDistributionItem;
  QuestionnaireAnswererAttributeMetricsInput: QuestionnaireAnswererAttributeMetricsInput;
  QuestionnaireAnswererAttributeMetricsOutput: QuestionnaireAnswererAttributeMetricsOutput;
  QuestionnaireComment: QuestionnaireComment;
  QuestionnaireCommentCountOutput: QuestionnaireCommentCountOutput;
  QuestionnaireCommentsInput: QuestionnaireCommentsInput;
  QuestionnaireCorporationAverageScore: QuestionnaireCorporationAverageScore;
  QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics: QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics;
  QuestionnaireMetricsPerCategoryInput: QuestionnaireMetricsPerCategoryInput;
  QuestionnaireMetricsPerCategoryOutput: Omit<QuestionnaireMetricsPerCategoryOutput, 'questionAnswerMetrics'> & { questionAnswerMetrics: Array<ResolversParentTypes['QuestionnaireMetricsPerCategoryQuestionAnswerMetrics']> };
  QuestionnaireMetricsPerCategoryQuestionAnswerMetrics: ResolversParentTypes['QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics'] | ResolversParentTypes['QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics'];
  QuestionnaireMetricsPerCategoryScoreDistributionMetrics: QuestionnaireMetricsPerCategoryScoreDistributionMetrics;
  QuestionnaireMetricsPerCategoryScoreMetrics: QuestionnaireMetricsPerCategoryScoreMetrics;
  QuestionnaireMetricsPerCategoryTextTypeAnswer: QuestionnaireMetricsPerCategoryTextTypeAnswer;
  QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics: QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics;
  QuestionnaireScoreChangesMetrics: QuestionnaireScoreChangesMetrics;
  QuestionnaireScoreChangesMetricsInput: QuestionnaireScoreChangesMetricsInput;
  QuestionnaireScoreMetricsInput: QuestionnaireScoreMetricsInput;
  QuestionnaireScoreMetricsOutput: QuestionnaireScoreMetricsOutput;
  QuestionnaireScoreWithFluctuation: QuestionnaireScoreWithFluctuation;
  QuestionnaireShopAverageScore: QuestionnaireShopAverageScore;
  RepeaterTransition: RepeaterTransition;
  Sales: Sales;
  SalesAnalyticsInput: SalesAnalyticsInput;
  SalesAnalyticsOutput: SalesAnalyticsOutput;
  SalesAnalyticsOutputItem: SalesAnalyticsOutputItem;
  SalesBreakdownByPaymentMethod: SalesBreakdownByPaymentMethod;
  SalesByDayOfWeekAndHourAnalyticsInput: SalesByDayOfWeekAndHourAnalyticsInput;
  SalesByDayOfWeekAndHourAnalyticsOutput: SalesByDayOfWeekAndHourAnalyticsOutput;
  SalesByDayOfWeekAndHourAnalyticsOutputItem: SalesByDayOfWeekAndHourAnalyticsOutputItem;
  SalesByDays: SalesByDays;
  SalesByDaysInput: SalesByDaysInput;
  SalesByMonths: SalesByMonths;
  SalesByMonthsInput: SalesByMonthsInput;
  SalesForBudget: SalesForBudget;
  SalesForBudgetInput: SalesForBudgetInput;
  SalesWithPaymentMethod: SalesWithPaymentMethod;
  SegmentedCustomer: SegmentedCustomer;
  ServiceChargeConfigInput: ServiceChargeConfigInput;
  SetCurrentCompanyInput: SetCurrentCompanyInput;
  SetCurrentShopInput: SetCurrentShopInput;
  ShopApiAggregationGetQuantityAndSalesAggregationInput: ShopApiAggregationGetQuantityAndSalesAggregationInput;
  ShopApiAggregationGetQuantityAndSalesAggregationInputWithShopId: ShopApiAggregationGetQuantityAndSalesAggregationInputWithShopId;
  ShopApiAggregationGetQuantityAndSalesAggregationOutput: ShopApiAggregationGetQuantityAndSalesAggregationOutput;
  ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutput: ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutput;
  ShopCouponUsage: ShopCouponUsage;
  ShopDailyCashRegisterBalancing: ShopDailyCashRegisterBalancing;
  SpecificTimeSurchargeConfigInput: SpecificTimeSurchargeConfigInput;
  String_comparison_exp: StringComparisonExp;
  UpdateBankDepositAmountInput: UpdateBankDepositAmountInput;
  UpdateCategoryMenuBulkInput: UpdateCategoryMenuBulkInput;
  UpdateCategoryMenusBulkInput: UpdateCategoryMenusBulkInput;
  UpdateDashboardAccountInput: UpdateDashboardAccountInput;
  UpdateLineOfficialAccountInput: UpdateLineOfficialAccountInput;
  UpdateMenuBulkInput: UpdateMenuBulkInput;
  UpdateMessageDeliveryInput: UpdateMessageDeliveryInput;
  UpdateOnSitePaymentInput: UpdateOnSitePaymentInput;
  UpdateShopMenusBulkInput: UpdateShopMenusBulkInput;
  UpdateShopMenusBulkResult: UpdateShopMenusBulkResult;
  UpdateShopMenusInput: UpdateShopMenusInput;
  UpdateShopMenusInputChoice: UpdateShopMenusInputChoice;
  UpdateShopMenusInputMenu: UpdateShopMenusInputMenu;
  UpdateShopPlansBulkInput: UpdateShopPlansBulkInput;
  UpdateShopPlansBulkResult: UpdateShopPlansBulkResult;
  UpdateTargetCountOutput: UpdateTargetCountOutput;
  UpsertShopChoicesInput: UpsertShopChoicesInput;
  UpsertShopChoicesResult: UpsertShopChoicesResult;
  UpsertShopPlanChoiceInput: UpsertShopPlanChoiceInput;
  UpsertShopPlanChoiceResult: UpsertShopPlanChoiceResult;
  UpsertTranslationsForCategoryInput: UpsertTranslationsForCategoryInput;
  UpsertTranslationsForChoiceInput: UpsertTranslationsForChoiceInput;
  UpsertTranslationsForMenuInput: UpsertTranslationsForMenuInput;
  UpsertTranslationsForMenuRecommendationMetaInput: UpsertTranslationsForMenuRecommendationMetaInput;
  UpsertTranslationsForOptionInput: UpsertTranslationsForOptionInput;
  UpsertTranslationsForPlanChoiceInput: UpsertTranslationsForPlanChoiceInput;
  UpsertTranslationsForPlanInput: UpsertTranslationsForPlanInput;
  UpsertTranslationsForPlanOptionInput: UpsertTranslationsForPlanOptionInput;
  UpsertTranslationsSource: UpsertTranslationsSource;
  ValidLineOfficialAccount: ValidLineOfficialAccount;
  ValidLineOfficialAccountInput: ValidLineOfficialAccountInput;
  VisitedCustomerSegments: VisitedCustomerSegments;
  VisitedLineCustomer: VisitedLineCustomer;
  accountingActionType_enum_comparison_exp: AccountingActionTypeEnumComparisonExp;
  accountingSlipImage: AccountingSlipImage;
  accountingSlipImagePosition_enum_comparison_exp: AccountingSlipImagePositionEnumComparisonExp;
  accountingSlipImage_aggregate_order_by: AccountingSlipImageAggregateOrderBy;
  accountingSlipImage_arr_rel_insert_input: AccountingSlipImageArrRelInsertInput;
  accountingSlipImage_avg_order_by: AccountingSlipImageAvgOrderBy;
  accountingSlipImage_bool_exp: AccountingSlipImageBoolExp;
  accountingSlipImage_inc_input: AccountingSlipImageIncInput;
  accountingSlipImage_insert_input: AccountingSlipImageInsertInput;
  accountingSlipImage_max_order_by: AccountingSlipImageMaxOrderBy;
  accountingSlipImage_min_order_by: AccountingSlipImageMinOrderBy;
  accountingSlipImage_mutation_response: AccountingSlipImageMutationResponse;
  accountingSlipImage_on_conflict: AccountingSlipImageOnConflict;
  accountingSlipImage_order_by: AccountingSlipImageOrderBy;
  accountingSlipImage_pk_columns_input: AccountingSlipImagePkColumnsInput;
  accountingSlipImage_set_input: AccountingSlipImageSetInput;
  accountingSlipImage_stddev_order_by: AccountingSlipImageStddevOrderBy;
  accountingSlipImage_stddev_pop_order_by: AccountingSlipImageStddevPopOrderBy;
  accountingSlipImage_stddev_samp_order_by: AccountingSlipImageStddevSampOrderBy;
  accountingSlipImage_stream_cursor_input: AccountingSlipImageStreamCursorInput;
  accountingSlipImage_stream_cursor_value_input: AccountingSlipImageStreamCursorValueInput;
  accountingSlipImage_sum_order_by: AccountingSlipImageSumOrderBy;
  accountingSlipImage_updates: AccountingSlipImageUpdates;
  accountingSlipImage_var_pop_order_by: AccountingSlipImageVarPopOrderBy;
  accountingSlipImage_var_samp_order_by: AccountingSlipImageVarSampOrderBy;
  accountingSlipImage_variance_order_by: AccountingSlipImageVarianceOrderBy;
  activePlan: ActivePlan;
  activePlanChoice: ActivePlanChoice;
  activePlanChoice_aggregate_order_by: ActivePlanChoiceAggregateOrderBy;
  activePlanChoice_avg_order_by: ActivePlanChoiceAvgOrderBy;
  activePlanChoice_bool_exp: ActivePlanChoiceBoolExp;
  activePlanChoice_max_order_by: ActivePlanChoiceMaxOrderBy;
  activePlanChoice_min_order_by: ActivePlanChoiceMinOrderBy;
  activePlanChoice_order_by: ActivePlanChoiceOrderBy;
  activePlanChoice_stddev_order_by: ActivePlanChoiceStddevOrderBy;
  activePlanChoice_stddev_pop_order_by: ActivePlanChoiceStddevPopOrderBy;
  activePlanChoice_stddev_samp_order_by: ActivePlanChoiceStddevSampOrderBy;
  activePlanChoice_stream_cursor_input: ActivePlanChoiceStreamCursorInput;
  activePlanChoice_stream_cursor_value_input: ActivePlanChoiceStreamCursorValueInput;
  activePlanChoice_sum_order_by: ActivePlanChoiceSumOrderBy;
  activePlanChoice_var_pop_order_by: ActivePlanChoiceVarPopOrderBy;
  activePlanChoice_var_samp_order_by: ActivePlanChoiceVarSampOrderBy;
  activePlanChoice_variance_order_by: ActivePlanChoiceVarianceOrderBy;
  activePlanOpenPriceMeta: ActivePlanOpenPriceMeta;
  activePlanOpenPriceMeta_bool_exp: ActivePlanOpenPriceMetaBoolExp;
  activePlanOpenPriceMeta_order_by: ActivePlanOpenPriceMetaOrderBy;
  activePlanOpenPriceMeta_stream_cursor_input: ActivePlanOpenPriceMetaStreamCursorInput;
  activePlanOpenPriceMeta_stream_cursor_value_input: ActivePlanOpenPriceMetaStreamCursorValueInput;
  activePlanOption: ActivePlanOption;
  activePlanOption_aggregate_order_by: ActivePlanOptionAggregateOrderBy;
  activePlanOption_avg_order_by: ActivePlanOptionAvgOrderBy;
  activePlanOption_bool_exp: ActivePlanOptionBoolExp;
  activePlanOption_max_order_by: ActivePlanOptionMaxOrderBy;
  activePlanOption_min_order_by: ActivePlanOptionMinOrderBy;
  activePlanOption_order_by: ActivePlanOptionOrderBy;
  activePlanOption_stddev_order_by: ActivePlanOptionStddevOrderBy;
  activePlanOption_stddev_pop_order_by: ActivePlanOptionStddevPopOrderBy;
  activePlanOption_stddev_samp_order_by: ActivePlanOptionStddevSampOrderBy;
  activePlanOption_stream_cursor_input: ActivePlanOptionStreamCursorInput;
  activePlanOption_stream_cursor_value_input: ActivePlanOptionStreamCursorValueInput;
  activePlanOption_sum_order_by: ActivePlanOptionSumOrderBy;
  activePlanOption_var_pop_order_by: ActivePlanOptionVarPopOrderBy;
  activePlanOption_var_samp_order_by: ActivePlanOptionVarSampOrderBy;
  activePlanOption_variance_order_by: ActivePlanOptionVarianceOrderBy;
  activePlan_aggregate_order_by: ActivePlanAggregateOrderBy;
  activePlan_avg_order_by: ActivePlanAvgOrderBy;
  activePlan_bool_exp: ActivePlanBoolExp;
  activePlan_max_order_by: ActivePlanMaxOrderBy;
  activePlan_min_order_by: ActivePlanMinOrderBy;
  activePlan_order_by: ActivePlanOrderBy;
  activePlan_stddev_order_by: ActivePlanStddevOrderBy;
  activePlan_stddev_pop_order_by: ActivePlanStddevPopOrderBy;
  activePlan_stddev_samp_order_by: ActivePlanStddevSampOrderBy;
  activePlan_stream_cursor_input: ActivePlanStreamCursorInput;
  activePlan_stream_cursor_value_input: ActivePlanStreamCursorValueInput;
  activePlan_sum_order_by: ActivePlanSumOrderBy;
  activePlan_var_pop_order_by: ActivePlanVarPopOrderBy;
  activePlan_var_samp_order_by: ActivePlanVarSampOrderBy;
  activePlan_variance_order_by: ActivePlanVarianceOrderBy;
  adyenPaymentMethodType_enum_comparison_exp: AdyenPaymentMethodTypeEnumComparisonExp;
  adyenPaymentReportTerminalPaymentAuthorizedEvent: AdyenPaymentReportTerminalPaymentAuthorizedEvent;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_bool_exp: AdyenPaymentReportTerminalPaymentAuthorizedEventBoolExp;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_order_by: AdyenPaymentReportTerminalPaymentAuthorizedEventOrderBy;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_stream_cursor_input: AdyenPaymentReportTerminalPaymentAuthorizedEventStreamCursorInput;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_stream_cursor_value_input: AdyenPaymentReportTerminalPaymentAuthorizedEventStreamCursorValueInput;
  adyenPaymentReportTerminalPaymentCanceledEvent: AdyenPaymentReportTerminalPaymentCanceledEvent;
  adyenPaymentReportTerminalPaymentCanceledEvent_bool_exp: AdyenPaymentReportTerminalPaymentCanceledEventBoolExp;
  adyenPaymentReportTerminalPaymentCanceledEvent_order_by: AdyenPaymentReportTerminalPaymentCanceledEventOrderBy;
  adyenPaymentReportTerminalPaymentCanceledEvent_stream_cursor_input: AdyenPaymentReportTerminalPaymentCanceledEventStreamCursorInput;
  adyenPaymentReportTerminalPaymentCanceledEvent_stream_cursor_value_input: AdyenPaymentReportTerminalPaymentCanceledEventStreamCursorValueInput;
  adyenPaymentShopConfig: AdyenPaymentShopConfig;
  adyenPaymentShopConfig_bool_exp: AdyenPaymentShopConfigBoolExp;
  adyenPaymentShopConfig_order_by: AdyenPaymentShopConfigOrderBy;
  adyenPaymentShopConfig_stream_cursor_input: AdyenPaymentShopConfigStreamCursorInput;
  adyenPaymentShopConfig_stream_cursor_value_input: AdyenPaymentShopConfigStreamCursorValueInput;
  adyenPaymentShopPaymentMethod: AdyenPaymentShopPaymentMethod;
  adyenPaymentShopPaymentMethod_aggregate_order_by: AdyenPaymentShopPaymentMethodAggregateOrderBy;
  adyenPaymentShopPaymentMethod_avg_order_by: AdyenPaymentShopPaymentMethodAvgOrderBy;
  adyenPaymentShopPaymentMethod_bool_exp: AdyenPaymentShopPaymentMethodBoolExp;
  adyenPaymentShopPaymentMethod_max_order_by: AdyenPaymentShopPaymentMethodMaxOrderBy;
  adyenPaymentShopPaymentMethod_min_order_by: AdyenPaymentShopPaymentMethodMinOrderBy;
  adyenPaymentShopPaymentMethod_order_by: AdyenPaymentShopPaymentMethodOrderBy;
  adyenPaymentShopPaymentMethod_stddev_order_by: AdyenPaymentShopPaymentMethodStddevOrderBy;
  adyenPaymentShopPaymentMethod_stddev_pop_order_by: AdyenPaymentShopPaymentMethodStddevPopOrderBy;
  adyenPaymentShopPaymentMethod_stddev_samp_order_by: AdyenPaymentShopPaymentMethodStddevSampOrderBy;
  adyenPaymentShopPaymentMethod_stream_cursor_input: AdyenPaymentShopPaymentMethodStreamCursorInput;
  adyenPaymentShopPaymentMethod_stream_cursor_value_input: AdyenPaymentShopPaymentMethodStreamCursorValueInput;
  adyenPaymentShopPaymentMethod_sum_order_by: AdyenPaymentShopPaymentMethodSumOrderBy;
  adyenPaymentShopPaymentMethod_var_pop_order_by: AdyenPaymentShopPaymentMethodVarPopOrderBy;
  adyenPaymentShopPaymentMethod_var_samp_order_by: AdyenPaymentShopPaymentMethodVarSampOrderBy;
  adyenPaymentShopPaymentMethod_variance_order_by: AdyenPaymentShopPaymentMethodVarianceOrderBy;
  adyenPaymentStoreMeta: AdyenPaymentStoreMeta;
  adyenPaymentStoreMeta_bool_exp: AdyenPaymentStoreMetaBoolExp;
  adyenPaymentStoreMeta_insert_input: AdyenPaymentStoreMetaInsertInput;
  adyenPaymentStoreMeta_mutation_response: AdyenPaymentStoreMetaMutationResponse;
  adyenPaymentStoreMeta_on_conflict: AdyenPaymentStoreMetaOnConflict;
  adyenPaymentStoreMeta_order_by: AdyenPaymentStoreMetaOrderBy;
  adyenPaymentStoreMeta_stream_cursor_input: AdyenPaymentStoreMetaStreamCursorInput;
  adyenPaymentStoreMeta_stream_cursor_value_input: AdyenPaymentStoreMetaStreamCursorValueInput;
  adyenTerminalPaymentShopConfig: AdyenTerminalPaymentShopConfig;
  adyenTerminalPaymentShopConfig_bool_exp: AdyenTerminalPaymentShopConfigBoolExp;
  adyenTerminalPaymentShopConfig_order_by: AdyenTerminalPaymentShopConfigOrderBy;
  adyenTerminalPaymentShopConfig_stream_cursor_input: AdyenTerminalPaymentShopConfigStreamCursorInput;
  adyenTerminalPaymentShopConfig_stream_cursor_value_input: AdyenTerminalPaymentShopConfigStreamCursorValueInput;
  adyenTerminalPaymentShopPaymentMethod: AdyenTerminalPaymentShopPaymentMethod;
  adyenTerminalPaymentShopPaymentMethod_aggregate_order_by: AdyenTerminalPaymentShopPaymentMethodAggregateOrderBy;
  adyenTerminalPaymentShopPaymentMethod_avg_order_by: AdyenTerminalPaymentShopPaymentMethodAvgOrderBy;
  adyenTerminalPaymentShopPaymentMethod_bool_exp: AdyenTerminalPaymentShopPaymentMethodBoolExp;
  adyenTerminalPaymentShopPaymentMethod_max_order_by: AdyenTerminalPaymentShopPaymentMethodMaxOrderBy;
  adyenTerminalPaymentShopPaymentMethod_min_order_by: AdyenTerminalPaymentShopPaymentMethodMinOrderBy;
  adyenTerminalPaymentShopPaymentMethod_order_by: AdyenTerminalPaymentShopPaymentMethodOrderBy;
  adyenTerminalPaymentShopPaymentMethod_stddev_order_by: AdyenTerminalPaymentShopPaymentMethodStddevOrderBy;
  adyenTerminalPaymentShopPaymentMethod_stddev_pop_order_by: AdyenTerminalPaymentShopPaymentMethodStddevPopOrderBy;
  adyenTerminalPaymentShopPaymentMethod_stddev_samp_order_by: AdyenTerminalPaymentShopPaymentMethodStddevSampOrderBy;
  adyenTerminalPaymentShopPaymentMethod_stream_cursor_input: AdyenTerminalPaymentShopPaymentMethodStreamCursorInput;
  adyenTerminalPaymentShopPaymentMethod_stream_cursor_value_input: AdyenTerminalPaymentShopPaymentMethodStreamCursorValueInput;
  adyenTerminalPaymentShopPaymentMethod_sum_order_by: AdyenTerminalPaymentShopPaymentMethodSumOrderBy;
  adyenTerminalPaymentShopPaymentMethod_var_pop_order_by: AdyenTerminalPaymentShopPaymentMethodVarPopOrderBy;
  adyenTerminalPaymentShopPaymentMethod_var_samp_order_by: AdyenTerminalPaymentShopPaymentMethodVarSampOrderBy;
  adyenTerminalPaymentShopPaymentMethod_variance_order_by: AdyenTerminalPaymentShopPaymentMethodVarianceOrderBy;
  analyticsSetting: AnalyticsSetting;
  analyticsSetting_bool_exp: AnalyticsSettingBoolExp;
  analyticsSetting_insert_input: AnalyticsSettingInsertInput;
  analyticsSetting_mutation_response: AnalyticsSettingMutationResponse;
  analyticsSetting_on_conflict: AnalyticsSettingOnConflict;
  analyticsSetting_order_by: AnalyticsSettingOrderBy;
  analyticsSetting_pk_columns_input: AnalyticsSettingPkColumnsInput;
  analyticsSetting_set_input: AnalyticsSettingSetInput;
  analyticsSetting_stream_cursor_input: AnalyticsSettingStreamCursorInput;
  analyticsSetting_stream_cursor_value_input: AnalyticsSettingStreamCursorValueInput;
  analyticsSetting_updates: AnalyticsSettingUpdates;
  bigint: Scalars['bigint'];
  bigint_comparison_exp: BigintComparisonExp;
  birthdayTypeQuestionAnswer: BirthdayTypeQuestionAnswer;
  birthdayTypeQuestionAnswer_bool_exp: BirthdayTypeQuestionAnswerBoolExp;
  birthdayTypeQuestionAnswer_order_by: BirthdayTypeQuestionAnswerOrderBy;
  birthdayTypeQuestionAnswer_stream_cursor_input: BirthdayTypeQuestionAnswerStreamCursorInput;
  birthdayTypeQuestionAnswer_stream_cursor_value_input: BirthdayTypeQuestionAnswerStreamCursorValueInput;
  businessOperationHourType_enum_comparison_exp: BusinessOperationHourTypeEnumComparisonExp;
  cashRegisterConfig: CashRegisterConfig;
  cashRegisterConfig_aggregate: CashRegisterConfigAggregate;
  cashRegisterConfig_aggregate_fields: CashRegisterConfigAggregateFields;
  cashRegisterConfig_bool_exp: CashRegisterConfigBoolExp;
  cashRegisterConfig_insert_input: CashRegisterConfigInsertInput;
  cashRegisterConfig_max_fields: CashRegisterConfigMaxFields;
  cashRegisterConfig_min_fields: CashRegisterConfigMinFields;
  cashRegisterConfig_mutation_response: CashRegisterConfigMutationResponse;
  cashRegisterConfig_obj_rel_insert_input: CashRegisterConfigObjRelInsertInput;
  cashRegisterConfig_on_conflict: CashRegisterConfigOnConflict;
  cashRegisterConfig_order_by: CashRegisterConfigOrderBy;
  cashRegisterConfig_pk_columns_input: CashRegisterConfigPkColumnsInput;
  cashRegisterConfig_set_input: CashRegisterConfigSetInput;
  cashRegisterConfig_stream_cursor_input: CashRegisterConfigStreamCursorInput;
  cashRegisterConfig_stream_cursor_value_input: CashRegisterConfigStreamCursorValueInput;
  cashRegisterConfig_updates: CashRegisterConfigUpdates;
  cashRegisterSecurityLevel: CashRegisterSecurityLevel;
  cashRegisterSecurityLevel_bool_exp: CashRegisterSecurityLevelBoolExp;
  cashRegisterSecurityLevel_enum_comparison_exp: CashRegisterSecurityLevelEnumComparisonExp;
  cashRegisterSecurityLevel_order_by: CashRegisterSecurityLevelOrderBy;
  cashRegisterSecurityLevel_stream_cursor_input: CashRegisterSecurityLevelStreamCursorInput;
  cashRegisterSecurityLevel_stream_cursor_value_input: CashRegisterSecurityLevelStreamCursorValueInput;
  category: Category;
  categoryMenu: CategoryMenu;
  categoryMenu_aggregate: CategoryMenuAggregate;
  categoryMenu_aggregate_bool_exp: CategoryMenuAggregateBoolExp;
  categoryMenu_aggregate_bool_exp_count: CategoryMenuAggregateBoolExpCount;
  categoryMenu_aggregate_fields: CategoryMenuAggregateFields;
  categoryMenu_aggregate_order_by: CategoryMenuAggregateOrderBy;
  categoryMenu_arr_rel_insert_input: CategoryMenuArrRelInsertInput;
  categoryMenu_avg_fields: CategoryMenuAvgFields;
  categoryMenu_avg_order_by: CategoryMenuAvgOrderBy;
  categoryMenu_bool_exp: CategoryMenuBoolExp;
  categoryMenu_inc_input: CategoryMenuIncInput;
  categoryMenu_insert_input: CategoryMenuInsertInput;
  categoryMenu_max_fields: CategoryMenuMaxFields;
  categoryMenu_max_order_by: CategoryMenuMaxOrderBy;
  categoryMenu_min_fields: CategoryMenuMinFields;
  categoryMenu_min_order_by: CategoryMenuMinOrderBy;
  categoryMenu_mutation_response: CategoryMenuMutationResponse;
  categoryMenu_obj_rel_insert_input: CategoryMenuObjRelInsertInput;
  categoryMenu_on_conflict: CategoryMenuOnConflict;
  categoryMenu_order_by: CategoryMenuOrderBy;
  categoryMenu_pk_columns_input: CategoryMenuPkColumnsInput;
  categoryMenu_set_input: CategoryMenuSetInput;
  categoryMenu_stddev_fields: CategoryMenuStddevFields;
  categoryMenu_stddev_order_by: CategoryMenuStddevOrderBy;
  categoryMenu_stddev_pop_fields: CategoryMenuStddevPopFields;
  categoryMenu_stddev_pop_order_by: CategoryMenuStddevPopOrderBy;
  categoryMenu_stddev_samp_fields: CategoryMenuStddevSampFields;
  categoryMenu_stddev_samp_order_by: CategoryMenuStddevSampOrderBy;
  categoryMenu_stream_cursor_input: CategoryMenuStreamCursorInput;
  categoryMenu_stream_cursor_value_input: CategoryMenuStreamCursorValueInput;
  categoryMenu_sum_fields: CategoryMenuSumFields;
  categoryMenu_sum_order_by: CategoryMenuSumOrderBy;
  categoryMenu_updates: CategoryMenuUpdates;
  categoryMenu_var_pop_fields: CategoryMenuVarPopFields;
  categoryMenu_var_pop_order_by: CategoryMenuVarPopOrderBy;
  categoryMenu_var_samp_fields: CategoryMenuVarSampFields;
  categoryMenu_var_samp_order_by: CategoryMenuVarSampOrderBy;
  categoryMenu_variance_fields: CategoryMenuVarianceFields;
  categoryMenu_variance_order_by: CategoryMenuVarianceOrderBy;
  category_aggregate: CategoryAggregate;
  category_aggregate_bool_exp: CategoryAggregateBoolExp;
  category_aggregate_bool_exp_count: CategoryAggregateBoolExpCount;
  category_aggregate_fields: CategoryAggregateFields;
  category_aggregate_order_by: CategoryAggregateOrderBy;
  category_arr_rel_insert_input: CategoryArrRelInsertInput;
  category_avg_fields: CategoryAvgFields;
  category_avg_order_by: CategoryAvgOrderBy;
  category_bool_exp: CategoryBoolExp;
  category_inc_input: CategoryIncInput;
  category_insert_input: CategoryInsertInput;
  category_max_fields: CategoryMaxFields;
  category_max_order_by: CategoryMaxOrderBy;
  category_min_fields: CategoryMinFields;
  category_min_order_by: CategoryMinOrderBy;
  category_mutation_response: CategoryMutationResponse;
  category_obj_rel_insert_input: CategoryObjRelInsertInput;
  category_on_conflict: CategoryOnConflict;
  category_order_by: CategoryOrderBy;
  category_pk_columns_input: CategoryPkColumnsInput;
  category_set_input: CategorySetInput;
  category_stddev_fields: CategoryStddevFields;
  category_stddev_order_by: CategoryStddevOrderBy;
  category_stddev_pop_fields: CategoryStddevPopFields;
  category_stddev_pop_order_by: CategoryStddevPopOrderBy;
  category_stddev_samp_fields: CategoryStddevSampFields;
  category_stddev_samp_order_by: CategoryStddevSampOrderBy;
  category_stream_cursor_input: CategoryStreamCursorInput;
  category_stream_cursor_value_input: CategoryStreamCursorValueInput;
  category_sum_fields: CategorySumFields;
  category_sum_order_by: CategorySumOrderBy;
  category_updates: CategoryUpdates;
  category_var_pop_fields: CategoryVarPopFields;
  category_var_pop_order_by: CategoryVarPopOrderBy;
  category_var_samp_fields: CategoryVarSampFields;
  category_var_samp_order_by: CategoryVarSampOrderBy;
  category_variance_fields: CategoryVarianceFields;
  category_variance_order_by: CategoryVarianceOrderBy;
  checkInEvent: CheckInEvent;
  checkInEvent_aggregate_order_by: CheckInEventAggregateOrderBy;
  checkInEvent_bool_exp: CheckInEventBoolExp;
  checkInEvent_max_order_by: CheckInEventMaxOrderBy;
  checkInEvent_min_order_by: CheckInEventMinOrderBy;
  checkInEvent_order_by: CheckInEventOrderBy;
  checkInEvent_stream_cursor_input: CheckInEventStreamCursorInput;
  checkInEvent_stream_cursor_value_input: CheckInEventStreamCursorValueInput;
  choice: Choice;
  choice_aggregate: ChoiceAggregate;
  choice_aggregate_bool_exp: ChoiceAggregateBoolExp;
  choice_aggregate_bool_exp_bool_and: ChoiceAggregateBoolExpBoolAnd;
  choice_aggregate_bool_exp_bool_or: ChoiceAggregateBoolExpBoolOr;
  choice_aggregate_bool_exp_count: ChoiceAggregateBoolExpCount;
  choice_aggregate_fields: ChoiceAggregateFields;
  choice_aggregate_order_by: ChoiceAggregateOrderBy;
  choice_arr_rel_insert_input: ChoiceArrRelInsertInput;
  choice_avg_fields: ChoiceAvgFields;
  choice_avg_order_by: ChoiceAvgOrderBy;
  choice_bool_exp: ChoiceBoolExp;
  choice_inc_input: ChoiceIncInput;
  choice_insert_input: ChoiceInsertInput;
  choice_max_fields: ChoiceMaxFields;
  choice_max_order_by: ChoiceMaxOrderBy;
  choice_min_fields: ChoiceMinFields;
  choice_min_order_by: ChoiceMinOrderBy;
  choice_mutation_response: ChoiceMutationResponse;
  choice_obj_rel_insert_input: ChoiceObjRelInsertInput;
  choice_on_conflict: ChoiceOnConflict;
  choice_order_by: ChoiceOrderBy;
  choice_pk_columns_input: ChoicePkColumnsInput;
  choice_set_input: ChoiceSetInput;
  choice_stddev_fields: ChoiceStddevFields;
  choice_stddev_order_by: ChoiceStddevOrderBy;
  choice_stddev_pop_fields: ChoiceStddevPopFields;
  choice_stddev_pop_order_by: ChoiceStddevPopOrderBy;
  choice_stddev_samp_fields: ChoiceStddevSampFields;
  choice_stddev_samp_order_by: ChoiceStddevSampOrderBy;
  choice_stream_cursor_input: ChoiceStreamCursorInput;
  choice_stream_cursor_value_input: ChoiceStreamCursorValueInput;
  choice_sum_fields: ChoiceSumFields;
  choice_sum_order_by: ChoiceSumOrderBy;
  choice_updates: ChoiceUpdates;
  choice_var_pop_fields: ChoiceVarPopFields;
  choice_var_pop_order_by: ChoiceVarPopOrderBy;
  choice_var_samp_fields: ChoiceVarSampFields;
  choice_var_samp_order_by: ChoiceVarSampOrderBy;
  choice_variance_fields: ChoiceVarianceFields;
  choice_variance_order_by: ChoiceVarianceOrderBy;
  clerk: Clerk;
  clerk_aggregate_order_by: ClerkAggregateOrderBy;
  clerk_arr_rel_insert_input: ClerkArrRelInsertInput;
  clerk_avg_order_by: ClerkAvgOrderBy;
  clerk_bool_exp: ClerkBoolExp;
  clerk_inc_input: ClerkIncInput;
  clerk_insert_input: ClerkInsertInput;
  clerk_max_order_by: ClerkMaxOrderBy;
  clerk_min_order_by: ClerkMinOrderBy;
  clerk_mutation_response: ClerkMutationResponse;
  clerk_obj_rel_insert_input: ClerkObjRelInsertInput;
  clerk_on_conflict: ClerkOnConflict;
  clerk_order_by: ClerkOrderBy;
  clerk_pk_columns_input: ClerkPkColumnsInput;
  clerk_set_input: ClerkSetInput;
  clerk_stddev_order_by: ClerkStddevOrderBy;
  clerk_stddev_pop_order_by: ClerkStddevPopOrderBy;
  clerk_stddev_samp_order_by: ClerkStddevSampOrderBy;
  clerk_stream_cursor_input: ClerkStreamCursorInput;
  clerk_stream_cursor_value_input: ClerkStreamCursorValueInput;
  clerk_sum_order_by: ClerkSumOrderBy;
  clerk_updates: ClerkUpdates;
  clerk_var_pop_order_by: ClerkVarPopOrderBy;
  clerk_var_samp_order_by: ClerkVarSampOrderBy;
  clerk_variance_order_by: ClerkVarianceOrderBy;
  closeCashRegister: CloseCashRegister;
  closeCashRegister_aggregate_order_by: CloseCashRegisterAggregateOrderBy;
  closeCashRegister_avg_order_by: CloseCashRegisterAvgOrderBy;
  closeCashRegister_bool_exp: CloseCashRegisterBoolExp;
  closeCashRegister_max_order_by: CloseCashRegisterMaxOrderBy;
  closeCashRegister_min_order_by: CloseCashRegisterMinOrderBy;
  closeCashRegister_mutation_response: CloseCashRegisterMutationResponse;
  closeCashRegister_order_by: CloseCashRegisterOrderBy;
  closeCashRegister_pk_columns_input: CloseCashRegisterPkColumnsInput;
  closeCashRegister_set_input: CloseCashRegisterSetInput;
  closeCashRegister_stddev_order_by: CloseCashRegisterStddevOrderBy;
  closeCashRegister_stddev_pop_order_by: CloseCashRegisterStddevPopOrderBy;
  closeCashRegister_stddev_samp_order_by: CloseCashRegisterStddevSampOrderBy;
  closeCashRegister_stream_cursor_input: CloseCashRegisterStreamCursorInput;
  closeCashRegister_stream_cursor_value_input: CloseCashRegisterStreamCursorValueInput;
  closeCashRegister_sum_order_by: CloseCashRegisterSumOrderBy;
  closeCashRegister_updates: CloseCashRegisterUpdates;
  closeCashRegister_var_pop_order_by: CloseCashRegisterVarPopOrderBy;
  closeCashRegister_var_samp_order_by: CloseCashRegisterVarSampOrderBy;
  closeCashRegister_variance_order_by: CloseCashRegisterVarianceOrderBy;
  coineyPayment: CoineyPayment;
  coineyPaymentOrder: CoineyPaymentOrder;
  coineyPaymentOrder_aggregate_order_by: CoineyPaymentOrderAggregateOrderBy;
  coineyPaymentOrder_avg_order_by: CoineyPaymentOrderAvgOrderBy;
  coineyPaymentOrder_bool_exp: CoineyPaymentOrderBoolExp;
  coineyPaymentOrder_max_order_by: CoineyPaymentOrderMaxOrderBy;
  coineyPaymentOrder_min_order_by: CoineyPaymentOrderMinOrderBy;
  coineyPaymentOrder_order_by: CoineyPaymentOrderOrderBy;
  coineyPaymentOrder_stddev_order_by: CoineyPaymentOrderStddevOrderBy;
  coineyPaymentOrder_stddev_pop_order_by: CoineyPaymentOrderStddevPopOrderBy;
  coineyPaymentOrder_stddev_samp_order_by: CoineyPaymentOrderStddevSampOrderBy;
  coineyPaymentOrder_stream_cursor_input: CoineyPaymentOrderStreamCursorInput;
  coineyPaymentOrder_stream_cursor_value_input: CoineyPaymentOrderStreamCursorValueInput;
  coineyPaymentOrder_sum_order_by: CoineyPaymentOrderSumOrderBy;
  coineyPaymentOrder_var_pop_order_by: CoineyPaymentOrderVarPopOrderBy;
  coineyPaymentOrder_var_samp_order_by: CoineyPaymentOrderVarSampOrderBy;
  coineyPaymentOrder_variance_order_by: CoineyPaymentOrderVarianceOrderBy;
  coineyPaymentSales: CoineyPaymentSales;
  coineyPaymentSales_aggregate_order_by: CoineyPaymentSalesAggregateOrderBy;
  coineyPaymentSales_avg_order_by: CoineyPaymentSalesAvgOrderBy;
  coineyPaymentSales_bool_exp: CoineyPaymentSalesBoolExp;
  coineyPaymentSales_max_order_by: CoineyPaymentSalesMaxOrderBy;
  coineyPaymentSales_min_order_by: CoineyPaymentSalesMinOrderBy;
  coineyPaymentSales_order_by: CoineyPaymentSalesOrderBy;
  coineyPaymentSales_stddev_order_by: CoineyPaymentSalesStddevOrderBy;
  coineyPaymentSales_stddev_pop_order_by: CoineyPaymentSalesStddevPopOrderBy;
  coineyPaymentSales_stddev_samp_order_by: CoineyPaymentSalesStddevSampOrderBy;
  coineyPaymentSales_stream_cursor_input: CoineyPaymentSalesStreamCursorInput;
  coineyPaymentSales_stream_cursor_value_input: CoineyPaymentSalesStreamCursorValueInput;
  coineyPaymentSales_sum_order_by: CoineyPaymentSalesSumOrderBy;
  coineyPaymentSales_var_pop_order_by: CoineyPaymentSalesVarPopOrderBy;
  coineyPaymentSales_var_samp_order_by: CoineyPaymentSalesVarSampOrderBy;
  coineyPaymentSales_variance_order_by: CoineyPaymentSalesVarianceOrderBy;
  coineyPayment_aggregate_order_by: CoineyPaymentAggregateOrderBy;
  coineyPayment_avg_order_by: CoineyPaymentAvgOrderBy;
  coineyPayment_bool_exp: CoineyPaymentBoolExp;
  coineyPayment_max_order_by: CoineyPaymentMaxOrderBy;
  coineyPayment_min_order_by: CoineyPaymentMinOrderBy;
  coineyPayment_mutation_response: CoineyPaymentMutationResponse;
  coineyPayment_order_by: CoineyPaymentOrderBy;
  coineyPayment_pk_columns_input: CoineyPaymentPkColumnsInput;
  coineyPayment_set_input: CoineyPaymentSetInput;
  coineyPayment_stddev_order_by: CoineyPaymentStddevOrderBy;
  coineyPayment_stddev_pop_order_by: CoineyPaymentStddevPopOrderBy;
  coineyPayment_stddev_samp_order_by: CoineyPaymentStddevSampOrderBy;
  coineyPayment_stream_cursor_input: CoineyPaymentStreamCursorInput;
  coineyPayment_stream_cursor_value_input: CoineyPaymentStreamCursorValueInput;
  coineyPayment_sum_order_by: CoineyPaymentSumOrderBy;
  coineyPayment_updates: CoineyPaymentUpdates;
  coineyPayment_var_pop_order_by: CoineyPaymentVarPopOrderBy;
  coineyPayment_var_samp_order_by: CoineyPaymentVarSampOrderBy;
  coineyPayment_variance_order_by: CoineyPaymentVarianceOrderBy;
  coineySettlement: CoineySettlement;
  coineySettlement_aggregate_order_by: CoineySettlementAggregateOrderBy;
  coineySettlement_avg_order_by: CoineySettlementAvgOrderBy;
  coineySettlement_bool_exp: CoineySettlementBoolExp;
  coineySettlement_max_order_by: CoineySettlementMaxOrderBy;
  coineySettlement_min_order_by: CoineySettlementMinOrderBy;
  coineySettlement_order_by: CoineySettlementOrderBy;
  coineySettlement_stddev_order_by: CoineySettlementStddevOrderBy;
  coineySettlement_stddev_pop_order_by: CoineySettlementStddevPopOrderBy;
  coineySettlement_stddev_samp_order_by: CoineySettlementStddevSampOrderBy;
  coineySettlement_stream_cursor_input: CoineySettlementStreamCursorInput;
  coineySettlement_stream_cursor_value_input: CoineySettlementStreamCursorValueInput;
  coineySettlement_sum_order_by: CoineySettlementSumOrderBy;
  coineySettlement_var_pop_order_by: CoineySettlementVarPopOrderBy;
  coineySettlement_var_samp_order_by: CoineySettlementVarSampOrderBy;
  coineySettlement_variance_order_by: CoineySettlementVarianceOrderBy;
  company: Company;
  companyMember: CompanyMember;
  companyMember_aggregate: CompanyMemberAggregate;
  companyMember_aggregate_bool_exp: CompanyMemberAggregateBoolExp;
  companyMember_aggregate_bool_exp_count: CompanyMemberAggregateBoolExpCount;
  companyMember_aggregate_fields: CompanyMemberAggregateFields;
  companyMember_aggregate_order_by: CompanyMemberAggregateOrderBy;
  companyMember_bool_exp: CompanyMemberBoolExp;
  companyMember_max_fields: CompanyMemberMaxFields;
  companyMember_max_order_by: CompanyMemberMaxOrderBy;
  companyMember_min_fields: CompanyMemberMinFields;
  companyMember_min_order_by: CompanyMemberMinOrderBy;
  companyMember_order_by: CompanyMemberOrderBy;
  companyMember_stream_cursor_input: CompanyMemberStreamCursorInput;
  companyMember_stream_cursor_value_input: CompanyMemberStreamCursorValueInput;
  company_aggregate_order_by: CompanyAggregateOrderBy;
  company_arr_rel_insert_input: CompanyArrRelInsertInput;
  company_avg_order_by: CompanyAvgOrderBy;
  company_bool_exp: CompanyBoolExp;
  company_inc_input: CompanyIncInput;
  company_insert_input: CompanyInsertInput;
  company_max_order_by: CompanyMaxOrderBy;
  company_min_order_by: CompanyMinOrderBy;
  company_mutation_response: CompanyMutationResponse;
  company_obj_rel_insert_input: CompanyObjRelInsertInput;
  company_on_conflict: CompanyOnConflict;
  company_order_by: CompanyOrderBy;
  company_pk_columns_input: CompanyPkColumnsInput;
  company_set_input: CompanySetInput;
  company_stddev_order_by: CompanyStddevOrderBy;
  company_stddev_pop_order_by: CompanyStddevPopOrderBy;
  company_stddev_samp_order_by: CompanyStddevSampOrderBy;
  company_stream_cursor_input: CompanyStreamCursorInput;
  company_stream_cursor_value_input: CompanyStreamCursorValueInput;
  company_sum_order_by: CompanySumOrderBy;
  company_updates: CompanyUpdates;
  company_var_pop_order_by: CompanyVarPopOrderBy;
  company_var_samp_order_by: CompanyVarSampOrderBy;
  company_variance_order_by: CompanyVarianceOrderBy;
  compulsoryAppetizerConfig: CompulsoryAppetizerConfig;
  compulsoryAppetizerConfig_aggregate_order_by: CompulsoryAppetizerConfigAggregateOrderBy;
  compulsoryAppetizerConfig_arr_rel_insert_input: CompulsoryAppetizerConfigArrRelInsertInput;
  compulsoryAppetizerConfig_bool_exp: CompulsoryAppetizerConfigBoolExp;
  compulsoryAppetizerConfig_insert_input: CompulsoryAppetizerConfigInsertInput;
  compulsoryAppetizerConfig_max_order_by: CompulsoryAppetizerConfigMaxOrderBy;
  compulsoryAppetizerConfig_min_order_by: CompulsoryAppetizerConfigMinOrderBy;
  compulsoryAppetizerConfig_mutation_response: CompulsoryAppetizerConfigMutationResponse;
  compulsoryAppetizerConfig_on_conflict: CompulsoryAppetizerConfigOnConflict;
  compulsoryAppetizerConfig_order_by: CompulsoryAppetizerConfigOrderBy;
  compulsoryAppetizerConfig_pk_columns_input: CompulsoryAppetizerConfigPkColumnsInput;
  compulsoryAppetizerConfig_set_input: CompulsoryAppetizerConfigSetInput;
  compulsoryAppetizerConfig_stream_cursor_input: CompulsoryAppetizerConfigStreamCursorInput;
  compulsoryAppetizerConfig_stream_cursor_value_input: CompulsoryAppetizerConfigStreamCursorValueInput;
  compulsoryAppetizerConfig_updates: CompulsoryAppetizerConfigUpdates;
  connectGameConfig: ConnectGameConfig;
  connectGameConfigShop: ConnectGameConfigShop;
  connectGameConfigShop_aggregate_order_by: ConnectGameConfigShopAggregateOrderBy;
  connectGameConfigShop_arr_rel_insert_input: ConnectGameConfigShopArrRelInsertInput;
  connectGameConfigShop_bool_exp: ConnectGameConfigShopBoolExp;
  connectGameConfigShop_insert_input: ConnectGameConfigShopInsertInput;
  connectGameConfigShop_max_order_by: ConnectGameConfigShopMaxOrderBy;
  connectGameConfigShop_min_order_by: ConnectGameConfigShopMinOrderBy;
  connectGameConfigShop_mutation_response: ConnectGameConfigShopMutationResponse;
  connectGameConfigShop_on_conflict: ConnectGameConfigShopOnConflict;
  connectGameConfigShop_order_by: ConnectGameConfigShopOrderBy;
  connectGameConfigShop_pk_columns_input: ConnectGameConfigShopPkColumnsInput;
  connectGameConfigShop_set_input: ConnectGameConfigShopSetInput;
  connectGameConfigShop_stream_cursor_input: ConnectGameConfigShopStreamCursorInput;
  connectGameConfigShop_stream_cursor_value_input: ConnectGameConfigShopStreamCursorValueInput;
  connectGameConfigShop_updates: ConnectGameConfigShopUpdates;
  connectGameConfig_aggregate_order_by: ConnectGameConfigAggregateOrderBy;
  connectGameConfig_arr_rel_insert_input: ConnectGameConfigArrRelInsertInput;
  connectGameConfig_avg_order_by: ConnectGameConfigAvgOrderBy;
  connectGameConfig_bool_exp: ConnectGameConfigBoolExp;
  connectGameConfig_inc_input: ConnectGameConfigIncInput;
  connectGameConfig_insert_input: ConnectGameConfigInsertInput;
  connectGameConfig_max_order_by: ConnectGameConfigMaxOrderBy;
  connectGameConfig_min_order_by: ConnectGameConfigMinOrderBy;
  connectGameConfig_mutation_response: ConnectGameConfigMutationResponse;
  connectGameConfig_obj_rel_insert_input: ConnectGameConfigObjRelInsertInput;
  connectGameConfig_on_conflict: ConnectGameConfigOnConflict;
  connectGameConfig_order_by: ConnectGameConfigOrderBy;
  connectGameConfig_pk_columns_input: ConnectGameConfigPkColumnsInput;
  connectGameConfig_set_input: ConnectGameConfigSetInput;
  connectGameConfig_stddev_order_by: ConnectGameConfigStddevOrderBy;
  connectGameConfig_stddev_pop_order_by: ConnectGameConfigStddevPopOrderBy;
  connectGameConfig_stddev_samp_order_by: ConnectGameConfigStddevSampOrderBy;
  connectGameConfig_stream_cursor_input: ConnectGameConfigStreamCursorInput;
  connectGameConfig_stream_cursor_value_input: ConnectGameConfigStreamCursorValueInput;
  connectGameConfig_sum_order_by: ConnectGameConfigSumOrderBy;
  connectGameConfig_updates: ConnectGameConfigUpdates;
  connectGameConfig_var_pop_order_by: ConnectGameConfigVarPopOrderBy;
  connectGameConfig_var_samp_order_by: ConnectGameConfigVarSampOrderBy;
  connectGameConfig_variance_order_by: ConnectGameConfigVarianceOrderBy;
  cookingItem: CookingItem;
  cookingItem_aggregate_order_by: CookingItemAggregateOrderBy;
  cookingItem_arr_rel_insert_input: CookingItemArrRelInsertInput;
  cookingItem_avg_order_by: CookingItemAvgOrderBy;
  cookingItem_bool_exp: CookingItemBoolExp;
  cookingItem_inc_input: CookingItemIncInput;
  cookingItem_insert_input: CookingItemInsertInput;
  cookingItem_max_order_by: CookingItemMaxOrderBy;
  cookingItem_min_order_by: CookingItemMinOrderBy;
  cookingItem_mutation_response: CookingItemMutationResponse;
  cookingItem_obj_rel_insert_input: CookingItemObjRelInsertInput;
  cookingItem_on_conflict: CookingItemOnConflict;
  cookingItem_order_by: CookingItemOrderBy;
  cookingItem_pk_columns_input: CookingItemPkColumnsInput;
  cookingItem_set_input: CookingItemSetInput;
  cookingItem_stddev_order_by: CookingItemStddevOrderBy;
  cookingItem_stddev_pop_order_by: CookingItemStddevPopOrderBy;
  cookingItem_stddev_samp_order_by: CookingItemStddevSampOrderBy;
  cookingItem_stream_cursor_input: CookingItemStreamCursorInput;
  cookingItem_stream_cursor_value_input: CookingItemStreamCursorValueInput;
  cookingItem_sum_order_by: CookingItemSumOrderBy;
  cookingItem_updates: CookingItemUpdates;
  cookingItem_var_pop_order_by: CookingItemVarPopOrderBy;
  cookingItem_var_samp_order_by: CookingItemVarSampOrderBy;
  cookingItem_variance_order_by: CookingItemVarianceOrderBy;
  corporation: Corporation;
  corporation_bool_exp: CorporationBoolExp;
  corporation_insert_input: CorporationInsertInput;
  corporation_mutation_response: CorporationMutationResponse;
  corporation_obj_rel_insert_input: CorporationObjRelInsertInput;
  corporation_on_conflict: CorporationOnConflict;
  corporation_order_by: CorporationOrderBy;
  corporation_pk_columns_input: CorporationPkColumnsInput;
  corporation_set_input: CorporationSetInput;
  corporation_stream_cursor_input: CorporationStreamCursorInput;
  corporation_stream_cursor_value_input: CorporationStreamCursorValueInput;
  corporation_updates: CorporationUpdates;
  coupon: Coupon;
  couponAppliedEvent: CouponAppliedEvent;
  couponAppliedEvent_aggregate: CouponAppliedEventAggregate;
  couponAppliedEvent_aggregate_bool_exp: CouponAppliedEventAggregateBoolExp;
  couponAppliedEvent_aggregate_bool_exp_count: CouponAppliedEventAggregateBoolExpCount;
  couponAppliedEvent_aggregate_fields: CouponAppliedEventAggregateFields;
  couponAppliedEvent_aggregate_order_by: CouponAppliedEventAggregateOrderBy;
  couponAppliedEvent_bool_exp: CouponAppliedEventBoolExp;
  couponAppliedEvent_max_fields: CouponAppliedEventMaxFields;
  couponAppliedEvent_max_order_by: CouponAppliedEventMaxOrderBy;
  couponAppliedEvent_min_fields: CouponAppliedEventMinFields;
  couponAppliedEvent_min_order_by: CouponAppliedEventMinOrderBy;
  couponAppliedEvent_order_by: CouponAppliedEventOrderBy;
  couponAppliedEvent_stream_cursor_input: CouponAppliedEventStreamCursorInput;
  couponAppliedEvent_stream_cursor_value_input: CouponAppliedEventStreamCursorValueInput;
  couponIssuer_enum_comparison_exp: CouponIssuerEnumComparisonExp;
  couponMenuDiscount: CouponMenuDiscount;
  couponMenuDiscount_bool_exp: CouponMenuDiscountBoolExp;
  couponMenuDiscount_inc_input: CouponMenuDiscountIncInput;
  couponMenuDiscount_insert_input: CouponMenuDiscountInsertInput;
  couponMenuDiscount_mutation_response: CouponMenuDiscountMutationResponse;
  couponMenuDiscount_obj_rel_insert_input: CouponMenuDiscountObjRelInsertInput;
  couponMenuDiscount_on_conflict: CouponMenuDiscountOnConflict;
  couponMenuDiscount_order_by: CouponMenuDiscountOrderBy;
  couponMenuDiscount_pk_columns_input: CouponMenuDiscountPkColumnsInput;
  couponMenuDiscount_set_input: CouponMenuDiscountSetInput;
  couponMenuDiscount_stream_cursor_input: CouponMenuDiscountStreamCursorInput;
  couponMenuDiscount_stream_cursor_value_input: CouponMenuDiscountStreamCursorValueInput;
  couponMenuDiscount_updates: CouponMenuDiscountUpdates;
  couponType_enum_comparison_exp: CouponTypeEnumComparisonExp;
  coupon_aggregate_order_by: CouponAggregateOrderBy;
  coupon_arr_rel_insert_input: CouponArrRelInsertInput;
  coupon_avg_order_by: CouponAvgOrderBy;
  coupon_bool_exp: CouponBoolExp;
  coupon_inc_input: CouponIncInput;
  coupon_insert_input: CouponInsertInput;
  coupon_max_order_by: CouponMaxOrderBy;
  coupon_min_order_by: CouponMinOrderBy;
  coupon_mutation_response: CouponMutationResponse;
  coupon_obj_rel_insert_input: CouponObjRelInsertInput;
  coupon_on_conflict: CouponOnConflict;
  coupon_order_by: CouponOrderBy;
  coupon_pk_columns_input: CouponPkColumnsInput;
  coupon_set_input: CouponSetInput;
  coupon_stddev_order_by: CouponStddevOrderBy;
  coupon_stddev_pop_order_by: CouponStddevPopOrderBy;
  coupon_stddev_samp_order_by: CouponStddevSampOrderBy;
  coupon_stream_cursor_input: CouponStreamCursorInput;
  coupon_stream_cursor_value_input: CouponStreamCursorValueInput;
  coupon_sum_order_by: CouponSumOrderBy;
  coupon_updates: CouponUpdates;
  coupon_var_pop_order_by: CouponVarPopOrderBy;
  coupon_var_samp_order_by: CouponVarSampOrderBy;
  coupon_variance_order_by: CouponVarianceOrderBy;
  customer: Customer;
  customerQuestionnaire: CustomerQuestionnaire;
  customerQuestionnaire_aggregate_order_by: CustomerQuestionnaireAggregateOrderBy;
  customerQuestionnaire_bool_exp: CustomerQuestionnaireBoolExp;
  customerQuestionnaire_max_order_by: CustomerQuestionnaireMaxOrderBy;
  customerQuestionnaire_min_order_by: CustomerQuestionnaireMinOrderBy;
  customerQuestionnaire_order_by: CustomerQuestionnaireOrderBy;
  customerQuestionnaire_stream_cursor_input: CustomerQuestionnaireStreamCursorInput;
  customerQuestionnaire_stream_cursor_value_input: CustomerQuestionnaireStreamCursorValueInput;
  customer_bool_exp: CustomerBoolExp;
  customer_order_by: CustomerOrderBy;
  customer_stream_cursor_input: CustomerStreamCursorInput;
  customer_stream_cursor_value_input: CustomerStreamCursorValueInput;
  dailySalesBudget: DailySalesBudget;
  dailySalesBudget_aggregate_order_by: DailySalesBudgetAggregateOrderBy;
  dailySalesBudget_arr_rel_insert_input: DailySalesBudgetArrRelInsertInput;
  dailySalesBudget_avg_order_by: DailySalesBudgetAvgOrderBy;
  dailySalesBudget_bool_exp: DailySalesBudgetBoolExp;
  dailySalesBudget_inc_input: DailySalesBudgetIncInput;
  dailySalesBudget_insert_input: DailySalesBudgetInsertInput;
  dailySalesBudget_max_order_by: DailySalesBudgetMaxOrderBy;
  dailySalesBudget_min_order_by: DailySalesBudgetMinOrderBy;
  dailySalesBudget_mutation_response: DailySalesBudgetMutationResponse;
  dailySalesBudget_on_conflict: DailySalesBudgetOnConflict;
  dailySalesBudget_order_by: DailySalesBudgetOrderBy;
  dailySalesBudget_pk_columns_input: DailySalesBudgetPkColumnsInput;
  dailySalesBudget_set_input: DailySalesBudgetSetInput;
  dailySalesBudget_stddev_order_by: DailySalesBudgetStddevOrderBy;
  dailySalesBudget_stddev_pop_order_by: DailySalesBudgetStddevPopOrderBy;
  dailySalesBudget_stddev_samp_order_by: DailySalesBudgetStddevSampOrderBy;
  dailySalesBudget_stream_cursor_input: DailySalesBudgetStreamCursorInput;
  dailySalesBudget_stream_cursor_value_input: DailySalesBudgetStreamCursorValueInput;
  dailySalesBudget_sum_order_by: DailySalesBudgetSumOrderBy;
  dailySalesBudget_updates: DailySalesBudgetUpdates;
  dailySalesBudget_var_pop_order_by: DailySalesBudgetVarPopOrderBy;
  dailySalesBudget_var_samp_order_by: DailySalesBudgetVarSampOrderBy;
  dailySalesBudget_variance_order_by: DailySalesBudgetVarianceOrderBy;
  dashboardAccount: DashboardAccount;
  dashboardAccountAccessibleCompany: DashboardAccountAccessibleCompany;
  dashboardAccountAccessibleCompany_aggregate_order_by: DashboardAccountAccessibleCompanyAggregateOrderBy;
  dashboardAccountAccessibleCompany_bool_exp: DashboardAccountAccessibleCompanyBoolExp;
  dashboardAccountAccessibleCompany_max_order_by: DashboardAccountAccessibleCompanyMaxOrderBy;
  dashboardAccountAccessibleCompany_min_order_by: DashboardAccountAccessibleCompanyMinOrderBy;
  dashboardAccountAccessibleCompany_order_by: DashboardAccountAccessibleCompanyOrderBy;
  dashboardAccountAccessibleCompany_stream_cursor_input: DashboardAccountAccessibleCompanyStreamCursorInput;
  dashboardAccountAccessibleCompany_stream_cursor_value_input: DashboardAccountAccessibleCompanyStreamCursorValueInput;
  dashboardAccountAccessibleShop: DashboardAccountAccessibleShop;
  dashboardAccountAccessibleShop_aggregate_order_by: DashboardAccountAccessibleShopAggregateOrderBy;
  dashboardAccountAccessibleShop_bool_exp: DashboardAccountAccessibleShopBoolExp;
  dashboardAccountAccessibleShop_max_order_by: DashboardAccountAccessibleShopMaxOrderBy;
  dashboardAccountAccessibleShop_min_order_by: DashboardAccountAccessibleShopMinOrderBy;
  dashboardAccountAccessibleShop_order_by: DashboardAccountAccessibleShopOrderBy;
  dashboardAccountAccessibleShop_stream_cursor_input: DashboardAccountAccessibleShopStreamCursorInput;
  dashboardAccountAccessibleShop_stream_cursor_value_input: DashboardAccountAccessibleShopStreamCursorValueInput;
  dashboardAccountRole: DashboardAccountRole;
  dashboardAccountRolePermission: DashboardAccountRolePermission;
  dashboardAccountRolePermissionType_enum_comparison_exp: DashboardAccountRolePermissionTypeEnumComparisonExp;
  dashboardAccountRolePermission_aggregate_order_by: DashboardAccountRolePermissionAggregateOrderBy;
  dashboardAccountRolePermission_arr_rel_insert_input: DashboardAccountRolePermissionArrRelInsertInput;
  dashboardAccountRolePermission_bool_exp: DashboardAccountRolePermissionBoolExp;
  dashboardAccountRolePermission_insert_input: DashboardAccountRolePermissionInsertInput;
  dashboardAccountRolePermission_max_order_by: DashboardAccountRolePermissionMaxOrderBy;
  dashboardAccountRolePermission_min_order_by: DashboardAccountRolePermissionMinOrderBy;
  dashboardAccountRolePermission_mutation_response: DashboardAccountRolePermissionMutationResponse;
  dashboardAccountRolePermission_on_conflict: DashboardAccountRolePermissionOnConflict;
  dashboardAccountRolePermission_order_by: DashboardAccountRolePermissionOrderBy;
  dashboardAccountRolePermission_pk_columns_input: DashboardAccountRolePermissionPkColumnsInput;
  dashboardAccountRolePermission_set_input: DashboardAccountRolePermissionSetInput;
  dashboardAccountRolePermission_stream_cursor_input: DashboardAccountRolePermissionStreamCursorInput;
  dashboardAccountRolePermission_stream_cursor_value_input: DashboardAccountRolePermissionStreamCursorValueInput;
  dashboardAccountRolePermission_updates: DashboardAccountRolePermissionUpdates;
  dashboardAccountRoleType: DashboardAccountRoleType;
  dashboardAccountRoleType_bool_exp: DashboardAccountRoleTypeBoolExp;
  dashboardAccountRoleType_enum_comparison_exp: DashboardAccountRoleTypeEnumComparisonExp;
  dashboardAccountRoleType_order_by: DashboardAccountRoleTypeOrderBy;
  dashboardAccountRoleType_stream_cursor_input: DashboardAccountRoleTypeStreamCursorInput;
  dashboardAccountRoleType_stream_cursor_value_input: DashboardAccountRoleTypeStreamCursorValueInput;
  dashboardAccountRole_bool_exp: DashboardAccountRoleBoolExp;
  dashboardAccountRole_insert_input: DashboardAccountRoleInsertInput;
  dashboardAccountRole_mutation_response: DashboardAccountRoleMutationResponse;
  dashboardAccountRole_obj_rel_insert_input: DashboardAccountRoleObjRelInsertInput;
  dashboardAccountRole_on_conflict: DashboardAccountRoleOnConflict;
  dashboardAccountRole_order_by: DashboardAccountRoleOrderBy;
  dashboardAccountRole_pk_columns_input: DashboardAccountRolePkColumnsInput;
  dashboardAccountRole_set_input: DashboardAccountRoleSetInput;
  dashboardAccountRole_stream_cursor_input: DashboardAccountRoleStreamCursorInput;
  dashboardAccountRole_stream_cursor_value_input: DashboardAccountRoleStreamCursorValueInput;
  dashboardAccountRole_updates: DashboardAccountRoleUpdates;
  dashboardAccount_aggregate_order_by: DashboardAccountAggregateOrderBy;
  dashboardAccount_bool_exp: DashboardAccountBoolExp;
  dashboardAccount_max_order_by: DashboardAccountMaxOrderBy;
  dashboardAccount_min_order_by: DashboardAccountMinOrderBy;
  dashboardAccount_order_by: DashboardAccountOrderBy;
  dashboardAccount_stream_cursor_input: DashboardAccountStreamCursorInput;
  dashboardAccount_stream_cursor_value_input: DashboardAccountStreamCursorValueInput;
  dashboardFeature_enum_comparison_exp: DashboardFeatureEnumComparisonExp;
  date: Scalars['date'];
  date_comparison_exp: DateComparisonExp;
  depositCycleType_enum_comparison_exp: DepositCycleTypeEnumComparisonExp;
  dishUpSlipGroup: DishUpSlipGroup;
  dishUpSlipGroupRoles: DishUpSlipGroupRoles;
  dishUpSlipGroupRoles_aggregate_order_by: DishUpSlipGroupRolesAggregateOrderBy;
  dishUpSlipGroupRoles_arr_rel_insert_input: DishUpSlipGroupRolesArrRelInsertInput;
  dishUpSlipGroupRoles_avg_order_by: DishUpSlipGroupRolesAvgOrderBy;
  dishUpSlipGroupRoles_bool_exp: DishUpSlipGroupRolesBoolExp;
  dishUpSlipGroupRoles_inc_input: DishUpSlipGroupRolesIncInput;
  dishUpSlipGroupRoles_insert_input: DishUpSlipGroupRolesInsertInput;
  dishUpSlipGroupRoles_max_order_by: DishUpSlipGroupRolesMaxOrderBy;
  dishUpSlipGroupRoles_min_order_by: DishUpSlipGroupRolesMinOrderBy;
  dishUpSlipGroupRoles_mutation_response: DishUpSlipGroupRolesMutationResponse;
  dishUpSlipGroupRoles_on_conflict: DishUpSlipGroupRolesOnConflict;
  dishUpSlipGroupRoles_order_by: DishUpSlipGroupRolesOrderBy;
  dishUpSlipGroupRoles_pk_columns_input: DishUpSlipGroupRolesPkColumnsInput;
  dishUpSlipGroupRoles_set_input: DishUpSlipGroupRolesSetInput;
  dishUpSlipGroupRoles_stddev_order_by: DishUpSlipGroupRolesStddevOrderBy;
  dishUpSlipGroupRoles_stddev_pop_order_by: DishUpSlipGroupRolesStddevPopOrderBy;
  dishUpSlipGroupRoles_stddev_samp_order_by: DishUpSlipGroupRolesStddevSampOrderBy;
  dishUpSlipGroupRoles_stream_cursor_input: DishUpSlipGroupRolesStreamCursorInput;
  dishUpSlipGroupRoles_stream_cursor_value_input: DishUpSlipGroupRolesStreamCursorValueInput;
  dishUpSlipGroupRoles_sum_order_by: DishUpSlipGroupRolesSumOrderBy;
  dishUpSlipGroupRoles_updates: DishUpSlipGroupRolesUpdates;
  dishUpSlipGroupRoles_var_pop_order_by: DishUpSlipGroupRolesVarPopOrderBy;
  dishUpSlipGroupRoles_var_samp_order_by: DishUpSlipGroupRolesVarSampOrderBy;
  dishUpSlipGroupRoles_variance_order_by: DishUpSlipGroupRolesVarianceOrderBy;
  dishUpSlipGroupShopMenus: DishUpSlipGroupShopMenus;
  dishUpSlipGroupShopMenus_aggregate_order_by: DishUpSlipGroupShopMenusAggregateOrderBy;
  dishUpSlipGroupShopMenus_arr_rel_insert_input: DishUpSlipGroupShopMenusArrRelInsertInput;
  dishUpSlipGroupShopMenus_avg_order_by: DishUpSlipGroupShopMenusAvgOrderBy;
  dishUpSlipGroupShopMenus_bool_exp: DishUpSlipGroupShopMenusBoolExp;
  dishUpSlipGroupShopMenus_inc_input: DishUpSlipGroupShopMenusIncInput;
  dishUpSlipGroupShopMenus_insert_input: DishUpSlipGroupShopMenusInsertInput;
  dishUpSlipGroupShopMenus_max_order_by: DishUpSlipGroupShopMenusMaxOrderBy;
  dishUpSlipGroupShopMenus_min_order_by: DishUpSlipGroupShopMenusMinOrderBy;
  dishUpSlipGroupShopMenus_mutation_response: DishUpSlipGroupShopMenusMutationResponse;
  dishUpSlipGroupShopMenus_on_conflict: DishUpSlipGroupShopMenusOnConflict;
  dishUpSlipGroupShopMenus_order_by: DishUpSlipGroupShopMenusOrderBy;
  dishUpSlipGroupShopMenus_pk_columns_input: DishUpSlipGroupShopMenusPkColumnsInput;
  dishUpSlipGroupShopMenus_set_input: DishUpSlipGroupShopMenusSetInput;
  dishUpSlipGroupShopMenus_stddev_order_by: DishUpSlipGroupShopMenusStddevOrderBy;
  dishUpSlipGroupShopMenus_stddev_pop_order_by: DishUpSlipGroupShopMenusStddevPopOrderBy;
  dishUpSlipGroupShopMenus_stddev_samp_order_by: DishUpSlipGroupShopMenusStddevSampOrderBy;
  dishUpSlipGroupShopMenus_stream_cursor_input: DishUpSlipGroupShopMenusStreamCursorInput;
  dishUpSlipGroupShopMenus_stream_cursor_value_input: DishUpSlipGroupShopMenusStreamCursorValueInput;
  dishUpSlipGroupShopMenus_sum_order_by: DishUpSlipGroupShopMenusSumOrderBy;
  dishUpSlipGroupShopMenus_updates: DishUpSlipGroupShopMenusUpdates;
  dishUpSlipGroupShopMenus_var_pop_order_by: DishUpSlipGroupShopMenusVarPopOrderBy;
  dishUpSlipGroupShopMenus_var_samp_order_by: DishUpSlipGroupShopMenusVarSampOrderBy;
  dishUpSlipGroupShopMenus_variance_order_by: DishUpSlipGroupShopMenusVarianceOrderBy;
  dishUpSlipGroupShopPlans: DishUpSlipGroupShopPlans;
  dishUpSlipGroupShopPlans_aggregate_order_by: DishUpSlipGroupShopPlansAggregateOrderBy;
  dishUpSlipGroupShopPlans_arr_rel_insert_input: DishUpSlipGroupShopPlansArrRelInsertInput;
  dishUpSlipGroupShopPlans_avg_order_by: DishUpSlipGroupShopPlansAvgOrderBy;
  dishUpSlipGroupShopPlans_bool_exp: DishUpSlipGroupShopPlansBoolExp;
  dishUpSlipGroupShopPlans_inc_input: DishUpSlipGroupShopPlansIncInput;
  dishUpSlipGroupShopPlans_insert_input: DishUpSlipGroupShopPlansInsertInput;
  dishUpSlipGroupShopPlans_max_order_by: DishUpSlipGroupShopPlansMaxOrderBy;
  dishUpSlipGroupShopPlans_min_order_by: DishUpSlipGroupShopPlansMinOrderBy;
  dishUpSlipGroupShopPlans_mutation_response: DishUpSlipGroupShopPlansMutationResponse;
  dishUpSlipGroupShopPlans_on_conflict: DishUpSlipGroupShopPlansOnConflict;
  dishUpSlipGroupShopPlans_order_by: DishUpSlipGroupShopPlansOrderBy;
  dishUpSlipGroupShopPlans_pk_columns_input: DishUpSlipGroupShopPlansPkColumnsInput;
  dishUpSlipGroupShopPlans_set_input: DishUpSlipGroupShopPlansSetInput;
  dishUpSlipGroupShopPlans_stddev_order_by: DishUpSlipGroupShopPlansStddevOrderBy;
  dishUpSlipGroupShopPlans_stddev_pop_order_by: DishUpSlipGroupShopPlansStddevPopOrderBy;
  dishUpSlipGroupShopPlans_stddev_samp_order_by: DishUpSlipGroupShopPlansStddevSampOrderBy;
  dishUpSlipGroupShopPlans_stream_cursor_input: DishUpSlipGroupShopPlansStreamCursorInput;
  dishUpSlipGroupShopPlans_stream_cursor_value_input: DishUpSlipGroupShopPlansStreamCursorValueInput;
  dishUpSlipGroupShopPlans_sum_order_by: DishUpSlipGroupShopPlansSumOrderBy;
  dishUpSlipGroupShopPlans_updates: DishUpSlipGroupShopPlansUpdates;
  dishUpSlipGroupShopPlans_var_pop_order_by: DishUpSlipGroupShopPlansVarPopOrderBy;
  dishUpSlipGroupShopPlans_var_samp_order_by: DishUpSlipGroupShopPlansVarSampOrderBy;
  dishUpSlipGroupShopPlans_variance_order_by: DishUpSlipGroupShopPlansVarianceOrderBy;
  dishUpSlipGroupTables: DishUpSlipGroupTables;
  dishUpSlipGroupTables_aggregate_order_by: DishUpSlipGroupTablesAggregateOrderBy;
  dishUpSlipGroupTables_arr_rel_insert_input: DishUpSlipGroupTablesArrRelInsertInput;
  dishUpSlipGroupTables_avg_order_by: DishUpSlipGroupTablesAvgOrderBy;
  dishUpSlipGroupTables_bool_exp: DishUpSlipGroupTablesBoolExp;
  dishUpSlipGroupTables_insert_input: DishUpSlipGroupTablesInsertInput;
  dishUpSlipGroupTables_max_order_by: DishUpSlipGroupTablesMaxOrderBy;
  dishUpSlipGroupTables_min_order_by: DishUpSlipGroupTablesMinOrderBy;
  dishUpSlipGroupTables_mutation_response: DishUpSlipGroupTablesMutationResponse;
  dishUpSlipGroupTables_on_conflict: DishUpSlipGroupTablesOnConflict;
  dishUpSlipGroupTables_order_by: DishUpSlipGroupTablesOrderBy;
  dishUpSlipGroupTables_stddev_order_by: DishUpSlipGroupTablesStddevOrderBy;
  dishUpSlipGroupTables_stddev_pop_order_by: DishUpSlipGroupTablesStddevPopOrderBy;
  dishUpSlipGroupTables_stddev_samp_order_by: DishUpSlipGroupTablesStddevSampOrderBy;
  dishUpSlipGroupTables_stream_cursor_input: DishUpSlipGroupTablesStreamCursorInput;
  dishUpSlipGroupTables_stream_cursor_value_input: DishUpSlipGroupTablesStreamCursorValueInput;
  dishUpSlipGroupTables_sum_order_by: DishUpSlipGroupTablesSumOrderBy;
  dishUpSlipGroupTables_var_pop_order_by: DishUpSlipGroupTablesVarPopOrderBy;
  dishUpSlipGroupTables_var_samp_order_by: DishUpSlipGroupTablesVarSampOrderBy;
  dishUpSlipGroupTables_variance_order_by: DishUpSlipGroupTablesVarianceOrderBy;
  dishUpSlipGroup_aggregate_order_by: DishUpSlipGroupAggregateOrderBy;
  dishUpSlipGroup_arr_rel_insert_input: DishUpSlipGroupArrRelInsertInput;
  dishUpSlipGroup_avg_order_by: DishUpSlipGroupAvgOrderBy;
  dishUpSlipGroup_bool_exp: DishUpSlipGroupBoolExp;
  dishUpSlipGroup_inc_input: DishUpSlipGroupIncInput;
  dishUpSlipGroup_insert_input: DishUpSlipGroupInsertInput;
  dishUpSlipGroup_max_order_by: DishUpSlipGroupMaxOrderBy;
  dishUpSlipGroup_min_order_by: DishUpSlipGroupMinOrderBy;
  dishUpSlipGroup_mutation_response: DishUpSlipGroupMutationResponse;
  dishUpSlipGroup_obj_rel_insert_input: DishUpSlipGroupObjRelInsertInput;
  dishUpSlipGroup_on_conflict: DishUpSlipGroupOnConflict;
  dishUpSlipGroup_order_by: DishUpSlipGroupOrderBy;
  dishUpSlipGroup_pk_columns_input: DishUpSlipGroupPkColumnsInput;
  dishUpSlipGroup_set_input: DishUpSlipGroupSetInput;
  dishUpSlipGroup_stddev_order_by: DishUpSlipGroupStddevOrderBy;
  dishUpSlipGroup_stddev_pop_order_by: DishUpSlipGroupStddevPopOrderBy;
  dishUpSlipGroup_stddev_samp_order_by: DishUpSlipGroupStddevSampOrderBy;
  dishUpSlipGroup_stream_cursor_input: DishUpSlipGroupStreamCursorInput;
  dishUpSlipGroup_stream_cursor_value_input: DishUpSlipGroupStreamCursorValueInput;
  dishUpSlipGroup_sum_order_by: DishUpSlipGroupSumOrderBy;
  dishUpSlipGroup_updates: DishUpSlipGroupUpdates;
  dishUpSlipGroup_var_pop_order_by: DishUpSlipGroupVarPopOrderBy;
  dishUpSlipGroup_var_samp_order_by: DishUpSlipGroupVarSampOrderBy;
  dishUpSlipGroup_variance_order_by: DishUpSlipGroupVarianceOrderBy;
  displayType_enum_comparison_exp: DisplayTypeEnumComparisonExp;
  ebicaConfig: EbicaConfig;
  ebicaConfig_bool_exp: EbicaConfigBoolExp;
  ebicaConfig_inc_input: EbicaConfigIncInput;
  ebicaConfig_insert_input: EbicaConfigInsertInput;
  ebicaConfig_mutation_response: EbicaConfigMutationResponse;
  ebicaConfig_on_conflict: EbicaConfigOnConflict;
  ebicaConfig_order_by: EbicaConfigOrderBy;
  ebicaConfig_pk_columns_input: EbicaConfigPkColumnsInput;
  ebicaConfig_set_input: EbicaConfigSetInput;
  ebicaConfig_stream_cursor_input: EbicaConfigStreamCursorInput;
  ebicaConfig_stream_cursor_value_input: EbicaConfigStreamCursorValueInput;
  ebicaConfig_updates: EbicaConfigUpdates;
  ebicaTable: EbicaTable;
  ebicaTable_bool_exp: EbicaTableBoolExp;
  ebicaTable_insert_input: EbicaTableInsertInput;
  ebicaTable_mutation_response: EbicaTableMutationResponse;
  ebicaTable_on_conflict: EbicaTableOnConflict;
  ebicaTable_order_by: EbicaTableOrderBy;
  ebicaTable_stream_cursor_input: EbicaTableStreamCursorInput;
  ebicaTable_stream_cursor_value_input: EbicaTableStreamCursorValueInput;
  externalOnlineMenu: ExternalOnlineMenu;
  externalOnlineMenuAvailableTimeTerm: ExternalOnlineMenuAvailableTimeTerm;
  externalOnlineMenuAvailableTimeTerm_aggregate_order_by: ExternalOnlineMenuAvailableTimeTermAggregateOrderBy;
  externalOnlineMenuAvailableTimeTerm_arr_rel_insert_input: ExternalOnlineMenuAvailableTimeTermArrRelInsertInput;
  externalOnlineMenuAvailableTimeTerm_bool_exp: ExternalOnlineMenuAvailableTimeTermBoolExp;
  externalOnlineMenuAvailableTimeTerm_insert_input: ExternalOnlineMenuAvailableTimeTermInsertInput;
  externalOnlineMenuAvailableTimeTerm_max_order_by: ExternalOnlineMenuAvailableTimeTermMaxOrderBy;
  externalOnlineMenuAvailableTimeTerm_min_order_by: ExternalOnlineMenuAvailableTimeTermMinOrderBy;
  externalOnlineMenuAvailableTimeTerm_mutation_response: ExternalOnlineMenuAvailableTimeTermMutationResponse;
  externalOnlineMenuAvailableTimeTerm_on_conflict: ExternalOnlineMenuAvailableTimeTermOnConflict;
  externalOnlineMenuAvailableTimeTerm_order_by: ExternalOnlineMenuAvailableTimeTermOrderBy;
  externalOnlineMenuAvailableTimeTerm_pk_columns_input: ExternalOnlineMenuAvailableTimeTermPkColumnsInput;
  externalOnlineMenuAvailableTimeTerm_set_input: ExternalOnlineMenuAvailableTimeTermSetInput;
  externalOnlineMenuAvailableTimeTerm_stream_cursor_input: ExternalOnlineMenuAvailableTimeTermStreamCursorInput;
  externalOnlineMenuAvailableTimeTerm_stream_cursor_value_input: ExternalOnlineMenuAvailableTimeTermStreamCursorValueInput;
  externalOnlineMenuAvailableTimeTerm_updates: ExternalOnlineMenuAvailableTimeTermUpdates;
  externalOnlineMenuConfig: ExternalOnlineMenuConfig;
  externalOnlineMenuConfig_aggregate_order_by: ExternalOnlineMenuConfigAggregateOrderBy;
  externalOnlineMenuConfig_arr_rel_insert_input: ExternalOnlineMenuConfigArrRelInsertInput;
  externalOnlineMenuConfig_bool_exp: ExternalOnlineMenuConfigBoolExp;
  externalOnlineMenuConfig_insert_input: ExternalOnlineMenuConfigInsertInput;
  externalOnlineMenuConfig_max_order_by: ExternalOnlineMenuConfigMaxOrderBy;
  externalOnlineMenuConfig_min_order_by: ExternalOnlineMenuConfigMinOrderBy;
  externalOnlineMenuConfig_mutation_response: ExternalOnlineMenuConfigMutationResponse;
  externalOnlineMenuConfig_obj_rel_insert_input: ExternalOnlineMenuConfigObjRelInsertInput;
  externalOnlineMenuConfig_on_conflict: ExternalOnlineMenuConfigOnConflict;
  externalOnlineMenuConfig_order_by: ExternalOnlineMenuConfigOrderBy;
  externalOnlineMenuConfig_pk_columns_input: ExternalOnlineMenuConfigPkColumnsInput;
  externalOnlineMenuConfig_set_input: ExternalOnlineMenuConfigSetInput;
  externalOnlineMenuConfig_stream_cursor_input: ExternalOnlineMenuConfigStreamCursorInput;
  externalOnlineMenuConfig_stream_cursor_value_input: ExternalOnlineMenuConfigStreamCursorValueInput;
  externalOnlineMenuConfig_updates: ExternalOnlineMenuConfigUpdates;
  externalOnlineMenu_aggregate_order_by: ExternalOnlineMenuAggregateOrderBy;
  externalOnlineMenu_arr_rel_insert_input: ExternalOnlineMenuArrRelInsertInput;
  externalOnlineMenu_bool_exp: ExternalOnlineMenuBoolExp;
  externalOnlineMenu_insert_input: ExternalOnlineMenuInsertInput;
  externalOnlineMenu_max_order_by: ExternalOnlineMenuMaxOrderBy;
  externalOnlineMenu_min_order_by: ExternalOnlineMenuMinOrderBy;
  externalOnlineMenu_mutation_response: ExternalOnlineMenuMutationResponse;
  externalOnlineMenu_obj_rel_insert_input: ExternalOnlineMenuObjRelInsertInput;
  externalOnlineMenu_on_conflict: ExternalOnlineMenuOnConflict;
  externalOnlineMenu_order_by: ExternalOnlineMenuOrderBy;
  externalOnlineMenu_pk_columns_input: ExternalOnlineMenuPkColumnsInput;
  externalOnlineMenu_set_input: ExternalOnlineMenuSetInput;
  externalOnlineMenu_stream_cursor_input: ExternalOnlineMenuStreamCursorInput;
  externalOnlineMenu_stream_cursor_value_input: ExternalOnlineMenuStreamCursorValueInput;
  externalOnlineMenu_updates: ExternalOnlineMenuUpdates;
  featureFlag: FeatureFlag;
  featureFlagShop: FeatureFlagShop;
  featureFlagShop_aggregate_order_by: FeatureFlagShopAggregateOrderBy;
  featureFlagShop_arr_rel_insert_input: FeatureFlagShopArrRelInsertInput;
  featureFlagShop_bool_exp: FeatureFlagShopBoolExp;
  featureFlagShop_insert_input: FeatureFlagShopInsertInput;
  featureFlagShop_max_order_by: FeatureFlagShopMaxOrderBy;
  featureFlagShop_min_order_by: FeatureFlagShopMinOrderBy;
  featureFlagShop_mutation_response: FeatureFlagShopMutationResponse;
  featureFlagShop_on_conflict: FeatureFlagShopOnConflict;
  featureFlagShop_order_by: FeatureFlagShopOrderBy;
  featureFlagShop_pk_columns_input: FeatureFlagShopPkColumnsInput;
  featureFlagShop_set_input: FeatureFlagShopSetInput;
  featureFlagShop_stream_cursor_input: FeatureFlagShopStreamCursorInput;
  featureFlagShop_stream_cursor_value_input: FeatureFlagShopStreamCursorValueInput;
  featureFlagShop_updates: FeatureFlagShopUpdates;
  featureFlag_bool_exp: FeatureFlagBoolExp;
  featureFlag_enum_comparison_exp: FeatureFlagEnumComparisonExp;
  featureFlag_order_by: FeatureFlagOrderBy;
  featureFlag_stream_cursor_input: FeatureFlagStreamCursorInput;
  featureFlag_stream_cursor_value_input: FeatureFlagStreamCursorValueInput;
  foodingJournalConfig: FoodingJournalConfig;
  foodingJournalConfig_bool_exp: FoodingJournalConfigBoolExp;
  foodingJournalConfig_insert_input: FoodingJournalConfigInsertInput;
  foodingJournalConfig_mutation_response: FoodingJournalConfigMutationResponse;
  foodingJournalConfig_obj_rel_insert_input: FoodingJournalConfigObjRelInsertInput;
  foodingJournalConfig_on_conflict: FoodingJournalConfigOnConflict;
  foodingJournalConfig_order_by: FoodingJournalConfigOrderBy;
  foodingJournalConfig_pk_columns_input: FoodingJournalConfigPkColumnsInput;
  foodingJournalConfig_set_input: FoodingJournalConfigSetInput;
  foodingJournalConfig_stream_cursor_input: FoodingJournalConfigStreamCursorInput;
  foodingJournalConfig_stream_cursor_value_input: FoodingJournalConfigStreamCursorValueInput;
  foodingJournalConfig_updates: FoodingJournalConfigUpdates;
  foodingJournalDepartmentMaster: FoodingJournalDepartmentMaster;
  foodingJournalDepartmentMaster_bool_exp: FoodingJournalDepartmentMasterBoolExp;
  foodingJournalDepartmentMaster_insert_input: FoodingJournalDepartmentMasterInsertInput;
  foodingJournalDepartmentMaster_mutation_response: FoodingJournalDepartmentMasterMutationResponse;
  foodingJournalDepartmentMaster_obj_rel_insert_input: FoodingJournalDepartmentMasterObjRelInsertInput;
  foodingJournalDepartmentMaster_on_conflict: FoodingJournalDepartmentMasterOnConflict;
  foodingJournalDepartmentMaster_order_by: FoodingJournalDepartmentMasterOrderBy;
  foodingJournalDepartmentMaster_pk_columns_input: FoodingJournalDepartmentMasterPkColumnsInput;
  foodingJournalDepartmentMaster_set_input: FoodingJournalDepartmentMasterSetInput;
  foodingJournalDepartmentMaster_stream_cursor_input: FoodingJournalDepartmentMasterStreamCursorInput;
  foodingJournalDepartmentMaster_stream_cursor_value_input: FoodingJournalDepartmentMasterStreamCursorValueInput;
  foodingJournalDepartmentMaster_updates: FoodingJournalDepartmentMasterUpdates;
  foodingJournalGroupMaster: FoodingJournalGroupMaster;
  foodingJournalGroupMaster_bool_exp: FoodingJournalGroupMasterBoolExp;
  foodingJournalGroupMaster_insert_input: FoodingJournalGroupMasterInsertInput;
  foodingJournalGroupMaster_mutation_response: FoodingJournalGroupMasterMutationResponse;
  foodingJournalGroupMaster_obj_rel_insert_input: FoodingJournalGroupMasterObjRelInsertInput;
  foodingJournalGroupMaster_on_conflict: FoodingJournalGroupMasterOnConflict;
  foodingJournalGroupMaster_order_by: FoodingJournalGroupMasterOrderBy;
  foodingJournalGroupMaster_pk_columns_input: FoodingJournalGroupMasterPkColumnsInput;
  foodingJournalGroupMaster_set_input: FoodingJournalGroupMasterSetInput;
  foodingJournalGroupMaster_stream_cursor_input: FoodingJournalGroupMasterStreamCursorInput;
  foodingJournalGroupMaster_stream_cursor_value_input: FoodingJournalGroupMasterStreamCursorValueInput;
  foodingJournalGroupMaster_updates: FoodingJournalGroupMasterUpdates;
  foodingJournalMenu: FoodingJournalMenu;
  foodingJournalMenu_aggregate_order_by: FoodingJournalMenuAggregateOrderBy;
  foodingJournalMenu_arr_rel_insert_input: FoodingJournalMenuArrRelInsertInput;
  foodingJournalMenu_bool_exp: FoodingJournalMenuBoolExp;
  foodingJournalMenu_insert_input: FoodingJournalMenuInsertInput;
  foodingJournalMenu_max_order_by: FoodingJournalMenuMaxOrderBy;
  foodingJournalMenu_min_order_by: FoodingJournalMenuMinOrderBy;
  foodingJournalMenu_mutation_response: FoodingJournalMenuMutationResponse;
  foodingJournalMenu_obj_rel_insert_input: FoodingJournalMenuObjRelInsertInput;
  foodingJournalMenu_on_conflict: FoodingJournalMenuOnConflict;
  foodingJournalMenu_order_by: FoodingJournalMenuOrderBy;
  foodingJournalMenu_pk_columns_input: FoodingJournalMenuPkColumnsInput;
  foodingJournalMenu_set_input: FoodingJournalMenuSetInput;
  foodingJournalMenu_stream_cursor_input: FoodingJournalMenuStreamCursorInput;
  foodingJournalMenu_stream_cursor_value_input: FoodingJournalMenuStreamCursorValueInput;
  foodingJournalMenu_updates: FoodingJournalMenuUpdates;
  foodingJournalOnSitePaymentDetailType: FoodingJournalOnSitePaymentDetailType;
  foodingJournalOnSitePaymentDetailType_bool_exp: FoodingJournalOnSitePaymentDetailTypeBoolExp;
  foodingJournalOnSitePaymentDetailType_inc_input: FoodingJournalOnSitePaymentDetailTypeIncInput;
  foodingJournalOnSitePaymentDetailType_insert_input: FoodingJournalOnSitePaymentDetailTypeInsertInput;
  foodingJournalOnSitePaymentDetailType_mutation_response: FoodingJournalOnSitePaymentDetailTypeMutationResponse;
  foodingJournalOnSitePaymentDetailType_obj_rel_insert_input: FoodingJournalOnSitePaymentDetailTypeObjRelInsertInput;
  foodingJournalOnSitePaymentDetailType_on_conflict: FoodingJournalOnSitePaymentDetailTypeOnConflict;
  foodingJournalOnSitePaymentDetailType_order_by: FoodingJournalOnSitePaymentDetailTypeOrderBy;
  foodingJournalOnSitePaymentDetailType_pk_columns_input: FoodingJournalOnSitePaymentDetailTypePkColumnsInput;
  foodingJournalOnSitePaymentDetailType_set_input: FoodingJournalOnSitePaymentDetailTypeSetInput;
  foodingJournalOnSitePaymentDetailType_stream_cursor_input: FoodingJournalOnSitePaymentDetailTypeStreamCursorInput;
  foodingJournalOnSitePaymentDetailType_stream_cursor_value_input: FoodingJournalOnSitePaymentDetailTypeStreamCursorValueInput;
  foodingJournalOnSitePaymentDetailType_updates: FoodingJournalOnSitePaymentDetailTypeUpdates;
  foodingJournalOnSitePaymentDiscountType: FoodingJournalOnSitePaymentDiscountType;
  foodingJournalOnSitePaymentDiscountType_bool_exp: FoodingJournalOnSitePaymentDiscountTypeBoolExp;
  foodingJournalOnSitePaymentDiscountType_inc_input: FoodingJournalOnSitePaymentDiscountTypeIncInput;
  foodingJournalOnSitePaymentDiscountType_insert_input: FoodingJournalOnSitePaymentDiscountTypeInsertInput;
  foodingJournalOnSitePaymentDiscountType_mutation_response: FoodingJournalOnSitePaymentDiscountTypeMutationResponse;
  foodingJournalOnSitePaymentDiscountType_obj_rel_insert_input: FoodingJournalOnSitePaymentDiscountTypeObjRelInsertInput;
  foodingJournalOnSitePaymentDiscountType_on_conflict: FoodingJournalOnSitePaymentDiscountTypeOnConflict;
  foodingJournalOnSitePaymentDiscountType_order_by: FoodingJournalOnSitePaymentDiscountTypeOrderBy;
  foodingJournalOnSitePaymentDiscountType_pk_columns_input: FoodingJournalOnSitePaymentDiscountTypePkColumnsInput;
  foodingJournalOnSitePaymentDiscountType_set_input: FoodingJournalOnSitePaymentDiscountTypeSetInput;
  foodingJournalOnSitePaymentDiscountType_stream_cursor_input: FoodingJournalOnSitePaymentDiscountTypeStreamCursorInput;
  foodingJournalOnSitePaymentDiscountType_stream_cursor_value_input: FoodingJournalOnSitePaymentDiscountTypeStreamCursorValueInput;
  foodingJournalOnSitePaymentDiscountType_updates: FoodingJournalOnSitePaymentDiscountTypeUpdates;
  giftMeta: GiftMeta;
  giftMeta_aggregate: GiftMetaAggregate;
  giftMeta_aggregate_fields: GiftMetaAggregateFields;
  giftMeta_avg_fields: GiftMetaAvgFields;
  giftMeta_bool_exp: GiftMetaBoolExp;
  giftMeta_inc_input: GiftMetaIncInput;
  giftMeta_insert_input: GiftMetaInsertInput;
  giftMeta_max_fields: GiftMetaMaxFields;
  giftMeta_min_fields: GiftMetaMinFields;
  giftMeta_mutation_response: GiftMetaMutationResponse;
  giftMeta_obj_rel_insert_input: GiftMetaObjRelInsertInput;
  giftMeta_on_conflict: GiftMetaOnConflict;
  giftMeta_order_by: GiftMetaOrderBy;
  giftMeta_pk_columns_input: GiftMetaPkColumnsInput;
  giftMeta_set_input: GiftMetaSetInput;
  giftMeta_stddev_fields: GiftMetaStddevFields;
  giftMeta_stddev_pop_fields: GiftMetaStddevPopFields;
  giftMeta_stddev_samp_fields: GiftMetaStddevSampFields;
  giftMeta_stream_cursor_input: GiftMetaStreamCursorInput;
  giftMeta_stream_cursor_value_input: GiftMetaStreamCursorValueInput;
  giftMeta_sum_fields: GiftMetaSumFields;
  giftMeta_updates: GiftMetaUpdates;
  giftMeta_var_pop_fields: GiftMetaVarPopFields;
  giftMeta_var_samp_fields: GiftMetaVarSampFields;
  giftMeta_variance_fields: GiftMetaVarianceFields;
  giftOptionMeta: GiftOptionMeta;
  giftOptionMeta_aggregate_order_by: GiftOptionMetaAggregateOrderBy;
  giftOptionMeta_arr_rel_insert_input: GiftOptionMetaArrRelInsertInput;
  giftOptionMeta_avg_order_by: GiftOptionMetaAvgOrderBy;
  giftOptionMeta_bool_exp: GiftOptionMetaBoolExp;
  giftOptionMeta_insert_input: GiftOptionMetaInsertInput;
  giftOptionMeta_max_order_by: GiftOptionMetaMaxOrderBy;
  giftOptionMeta_min_order_by: GiftOptionMetaMinOrderBy;
  giftOptionMeta_mutation_response: GiftOptionMetaMutationResponse;
  giftOptionMeta_obj_rel_insert_input: GiftOptionMetaObjRelInsertInput;
  giftOptionMeta_on_conflict: GiftOptionMetaOnConflict;
  giftOptionMeta_order_by: GiftOptionMetaOrderBy;
  giftOptionMeta_stddev_order_by: GiftOptionMetaStddevOrderBy;
  giftOptionMeta_stddev_pop_order_by: GiftOptionMetaStddevPopOrderBy;
  giftOptionMeta_stddev_samp_order_by: GiftOptionMetaStddevSampOrderBy;
  giftOptionMeta_stream_cursor_input: GiftOptionMetaStreamCursorInput;
  giftOptionMeta_stream_cursor_value_input: GiftOptionMetaStreamCursorValueInput;
  giftOptionMeta_sum_order_by: GiftOptionMetaSumOrderBy;
  giftOptionMeta_var_pop_order_by: GiftOptionMetaVarPopOrderBy;
  giftOptionMeta_var_samp_order_by: GiftOptionMetaVarSampOrderBy;
  giftOptionMeta_variance_order_by: GiftOptionMetaVarianceOrderBy;
  gmoBankAccount: GmoBankAccount;
  gmoBankAccountShop: GmoBankAccountShop;
  gmoBankAccountShop_aggregate_order_by: GmoBankAccountShopAggregateOrderBy;
  gmoBankAccountShop_bool_exp: GmoBankAccountShopBoolExp;
  gmoBankAccountShop_max_order_by: GmoBankAccountShopMaxOrderBy;
  gmoBankAccountShop_min_order_by: GmoBankAccountShopMinOrderBy;
  gmoBankAccountShop_order_by: GmoBankAccountShopOrderBy;
  gmoBankAccountShop_stream_cursor_input: GmoBankAccountShopStreamCursorInput;
  gmoBankAccountShop_stream_cursor_value_input: GmoBankAccountShopStreamCursorValueInput;
  gmoBankAccount_bool_exp: GmoBankAccountBoolExp;
  gmoBankAccount_order_by: GmoBankAccountOrderBy;
  gmoBankAccount_stream_cursor_input: GmoBankAccountStreamCursorInput;
  gmoBankAccount_stream_cursor_value_input: GmoBankAccountStreamCursorValueInput;
  gmoOffPaymentMethodType: GmoOffPaymentMethodType;
  gmoOffPaymentMethodType_bool_exp: GmoOffPaymentMethodTypeBoolExp;
  gmoOffPaymentMethodType_enum_comparison_exp: GmoOffPaymentMethodTypeEnumComparisonExp;
  gmoOffPaymentMethodType_order_by: GmoOffPaymentMethodTypeOrderBy;
  gmoOffPaymentMethodType_stream_cursor_input: GmoOffPaymentMethodTypeStreamCursorInput;
  gmoOffPaymentMethodType_stream_cursor_value_input: GmoOffPaymentMethodTypeStreamCursorValueInput;
  gmoOffPaymentOnSitePaymentDetailType: GmoOffPaymentOnSitePaymentDetailType;
  gmoOffPaymentOnSitePaymentDetailType_aggregate_order_by: GmoOffPaymentOnSitePaymentDetailTypeAggregateOrderBy;
  gmoOffPaymentOnSitePaymentDetailType_bool_exp: GmoOffPaymentOnSitePaymentDetailTypeBoolExp;
  gmoOffPaymentOnSitePaymentDetailType_insert_input: GmoOffPaymentOnSitePaymentDetailTypeInsertInput;
  gmoOffPaymentOnSitePaymentDetailType_max_order_by: GmoOffPaymentOnSitePaymentDetailTypeMaxOrderBy;
  gmoOffPaymentOnSitePaymentDetailType_min_order_by: GmoOffPaymentOnSitePaymentDetailTypeMinOrderBy;
  gmoOffPaymentOnSitePaymentDetailType_mutation_response: GmoOffPaymentOnSitePaymentDetailTypeMutationResponse;
  gmoOffPaymentOnSitePaymentDetailType_on_conflict: GmoOffPaymentOnSitePaymentDetailTypeOnConflict;
  gmoOffPaymentOnSitePaymentDetailType_order_by: GmoOffPaymentOnSitePaymentDetailTypeOrderBy;
  gmoOffPaymentOnSitePaymentDetailType_stream_cursor_input: GmoOffPaymentOnSitePaymentDetailTypeStreamCursorInput;
  gmoOffPaymentOnSitePaymentDetailType_stream_cursor_value_input: GmoOffPaymentOnSitePaymentDetailTypeStreamCursorValueInput;
  gmoOffPaymentShopConfig: GmoOffPaymentShopConfig;
  gmoOffPaymentShopConfigTransactionFeeRate: GmoOffPaymentShopConfigTransactionFeeRate;
  gmoOffPaymentShopConfigTransactionFeeRate_aggregate_order_by: GmoOffPaymentShopConfigTransactionFeeRateAggregateOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_arr_rel_insert_input: GmoOffPaymentShopConfigTransactionFeeRateArrRelInsertInput;
  gmoOffPaymentShopConfigTransactionFeeRate_avg_order_by: GmoOffPaymentShopConfigTransactionFeeRateAvgOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_bool_exp: GmoOffPaymentShopConfigTransactionFeeRateBoolExp;
  gmoOffPaymentShopConfigTransactionFeeRate_inc_input: GmoOffPaymentShopConfigTransactionFeeRateIncInput;
  gmoOffPaymentShopConfigTransactionFeeRate_insert_input: GmoOffPaymentShopConfigTransactionFeeRateInsertInput;
  gmoOffPaymentShopConfigTransactionFeeRate_max_order_by: GmoOffPaymentShopConfigTransactionFeeRateMaxOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_min_order_by: GmoOffPaymentShopConfigTransactionFeeRateMinOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_mutation_response: GmoOffPaymentShopConfigTransactionFeeRateMutationResponse;
  gmoOffPaymentShopConfigTransactionFeeRate_on_conflict: GmoOffPaymentShopConfigTransactionFeeRateOnConflict;
  gmoOffPaymentShopConfigTransactionFeeRate_order_by: GmoOffPaymentShopConfigTransactionFeeRateOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_pk_columns_input: GmoOffPaymentShopConfigTransactionFeeRatePkColumnsInput;
  gmoOffPaymentShopConfigTransactionFeeRate_set_input: GmoOffPaymentShopConfigTransactionFeeRateSetInput;
  gmoOffPaymentShopConfigTransactionFeeRate_stddev_order_by: GmoOffPaymentShopConfigTransactionFeeRateStddevOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_stddev_pop_order_by: GmoOffPaymentShopConfigTransactionFeeRateStddevPopOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_stddev_samp_order_by: GmoOffPaymentShopConfigTransactionFeeRateStddevSampOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_stream_cursor_input: GmoOffPaymentShopConfigTransactionFeeRateStreamCursorInput;
  gmoOffPaymentShopConfigTransactionFeeRate_stream_cursor_value_input: GmoOffPaymentShopConfigTransactionFeeRateStreamCursorValueInput;
  gmoOffPaymentShopConfigTransactionFeeRate_sum_order_by: GmoOffPaymentShopConfigTransactionFeeRateSumOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_updates: GmoOffPaymentShopConfigTransactionFeeRateUpdates;
  gmoOffPaymentShopConfigTransactionFeeRate_var_pop_order_by: GmoOffPaymentShopConfigTransactionFeeRateVarPopOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_var_samp_order_by: GmoOffPaymentShopConfigTransactionFeeRateVarSampOrderBy;
  gmoOffPaymentShopConfigTransactionFeeRate_variance_order_by: GmoOffPaymentShopConfigTransactionFeeRateVarianceOrderBy;
  gmoOffPaymentShopConfig_bool_exp: GmoOffPaymentShopConfigBoolExp;
  gmoOffPaymentShopConfig_insert_input: GmoOffPaymentShopConfigInsertInput;
  gmoOffPaymentShopConfig_mutation_response: GmoOffPaymentShopConfigMutationResponse;
  gmoOffPaymentShopConfig_obj_rel_insert_input: GmoOffPaymentShopConfigObjRelInsertInput;
  gmoOffPaymentShopConfig_on_conflict: GmoOffPaymentShopConfigOnConflict;
  gmoOffPaymentShopConfig_order_by: GmoOffPaymentShopConfigOrderBy;
  gmoOffPaymentShopConfig_pk_columns_input: GmoOffPaymentShopConfigPkColumnsInput;
  gmoOffPaymentShopConfig_set_input: GmoOffPaymentShopConfigSetInput;
  gmoOffPaymentShopConfig_stream_cursor_input: GmoOffPaymentShopConfigStreamCursorInput;
  gmoOffPaymentShopConfig_stream_cursor_value_input: GmoOffPaymentShopConfigStreamCursorValueInput;
  gmoOffPaymentShopConfig_updates: GmoOffPaymentShopConfigUpdates;
  inflowSourceTag: InflowSourceTag;
  inflowSourceTag_aggregate: InflowSourceTagAggregate;
  inflowSourceTag_aggregate_bool_exp: InflowSourceTagAggregateBoolExp;
  inflowSourceTag_aggregate_bool_exp_bool_and: InflowSourceTagAggregateBoolExpBoolAnd;
  inflowSourceTag_aggregate_bool_exp_bool_or: InflowSourceTagAggregateBoolExpBoolOr;
  inflowSourceTag_aggregate_bool_exp_count: InflowSourceTagAggregateBoolExpCount;
  inflowSourceTag_aggregate_fields: InflowSourceTagAggregateFields;
  inflowSourceTag_aggregate_order_by: InflowSourceTagAggregateOrderBy;
  inflowSourceTag_arr_rel_insert_input: InflowSourceTagArrRelInsertInput;
  inflowSourceTag_avg_fields: InflowSourceTagAvgFields;
  inflowSourceTag_avg_order_by: InflowSourceTagAvgOrderBy;
  inflowSourceTag_bool_exp: InflowSourceTagBoolExp;
  inflowSourceTag_inc_input: InflowSourceTagIncInput;
  inflowSourceTag_insert_input: InflowSourceTagInsertInput;
  inflowSourceTag_max_fields: InflowSourceTagMaxFields;
  inflowSourceTag_max_order_by: InflowSourceTagMaxOrderBy;
  inflowSourceTag_min_fields: InflowSourceTagMinFields;
  inflowSourceTag_min_order_by: InflowSourceTagMinOrderBy;
  inflowSourceTag_mutation_response: InflowSourceTagMutationResponse;
  inflowSourceTag_obj_rel_insert_input: InflowSourceTagObjRelInsertInput;
  inflowSourceTag_on_conflict: InflowSourceTagOnConflict;
  inflowSourceTag_order_by: InflowSourceTagOrderBy;
  inflowSourceTag_pk_columns_input: InflowSourceTagPkColumnsInput;
  inflowSourceTag_set_input: InflowSourceTagSetInput;
  inflowSourceTag_stddev_fields: InflowSourceTagStddevFields;
  inflowSourceTag_stddev_order_by: InflowSourceTagStddevOrderBy;
  inflowSourceTag_stddev_pop_fields: InflowSourceTagStddevPopFields;
  inflowSourceTag_stddev_pop_order_by: InflowSourceTagStddevPopOrderBy;
  inflowSourceTag_stddev_samp_fields: InflowSourceTagStddevSampFields;
  inflowSourceTag_stddev_samp_order_by: InflowSourceTagStddevSampOrderBy;
  inflowSourceTag_stream_cursor_input: InflowSourceTagStreamCursorInput;
  inflowSourceTag_stream_cursor_value_input: InflowSourceTagStreamCursorValueInput;
  inflowSourceTag_sum_fields: InflowSourceTagSumFields;
  inflowSourceTag_sum_order_by: InflowSourceTagSumOrderBy;
  inflowSourceTag_updates: InflowSourceTagUpdates;
  inflowSourceTag_var_pop_fields: InflowSourceTagVarPopFields;
  inflowSourceTag_var_pop_order_by: InflowSourceTagVarPopOrderBy;
  inflowSourceTag_var_samp_fields: InflowSourceTagVarSampFields;
  inflowSourceTag_var_samp_order_by: InflowSourceTagVarSampOrderBy;
  inflowSourceTag_variance_fields: InflowSourceTagVarianceFields;
  inflowSourceTag_variance_order_by: InflowSourceTagVarianceOrderBy;
  itemSales: ItemSales;
  itemSales_bool_exp: ItemSalesBoolExp;
  itemSales_order_by: ItemSalesOrderBy;
  itemSales_stream_cursor_input: ItemSalesStreamCursorInput;
  itemSales_stream_cursor_value_input: ItemSalesStreamCursorValueInput;
  jsonb: Scalars['jsonb'];
  jsonb_cast_exp: JsonbCastExp;
  jsonb_comparison_exp: JsonbComparisonExp;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget: KdDisplayItemKdDisplayTargetsKdDisplayTarget;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_aggregate_order_by: KdDisplayItemKdDisplayTargetsKdDisplayTargetAggregateOrderBy;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_bool_exp: KdDisplayItemKdDisplayTargetsKdDisplayTargetBoolExp;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_max_order_by: KdDisplayItemKdDisplayTargetsKdDisplayTargetMaxOrderBy;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_min_order_by: KdDisplayItemKdDisplayTargetsKdDisplayTargetMinOrderBy;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_mutation_response: KdDisplayItemKdDisplayTargetsKdDisplayTargetMutationResponse;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_order_by: KdDisplayItemKdDisplayTargetsKdDisplayTargetOrderBy;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_stream_cursor_input: KdDisplayItemKdDisplayTargetsKdDisplayTargetStreamCursorInput;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_stream_cursor_value_input: KdDisplayItemKdDisplayTargetsKdDisplayTargetStreamCursorValueInput;
  kdDisplayTarget: KdDisplayTarget;
  kdDisplayTarget_aggregate_order_by: KdDisplayTargetAggregateOrderBy;
  kdDisplayTarget_arr_rel_insert_input: KdDisplayTargetArrRelInsertInput;
  kdDisplayTarget_avg_order_by: KdDisplayTargetAvgOrderBy;
  kdDisplayTarget_bool_exp: KdDisplayTargetBoolExp;
  kdDisplayTarget_inc_input: KdDisplayTargetIncInput;
  kdDisplayTarget_insert_input: KdDisplayTargetInsertInput;
  kdDisplayTarget_max_order_by: KdDisplayTargetMaxOrderBy;
  kdDisplayTarget_min_order_by: KdDisplayTargetMinOrderBy;
  kdDisplayTarget_mutation_response: KdDisplayTargetMutationResponse;
  kdDisplayTarget_obj_rel_insert_input: KdDisplayTargetObjRelInsertInput;
  kdDisplayTarget_on_conflict: KdDisplayTargetOnConflict;
  kdDisplayTarget_order_by: KdDisplayTargetOrderBy;
  kdDisplayTarget_pk_columns_input: KdDisplayTargetPkColumnsInput;
  kdDisplayTarget_set_input: KdDisplayTargetSetInput;
  kdDisplayTarget_stddev_order_by: KdDisplayTargetStddevOrderBy;
  kdDisplayTarget_stddev_pop_order_by: KdDisplayTargetStddevPopOrderBy;
  kdDisplayTarget_stddev_samp_order_by: KdDisplayTargetStddevSampOrderBy;
  kdDisplayTarget_stream_cursor_input: KdDisplayTargetStreamCursorInput;
  kdDisplayTarget_stream_cursor_value_input: KdDisplayTargetStreamCursorValueInput;
  kdDisplayTarget_sum_order_by: KdDisplayTargetSumOrderBy;
  kdDisplayTarget_updates: KdDisplayTargetUpdates;
  kdDisplayTarget_var_pop_order_by: KdDisplayTargetVarPopOrderBy;
  kdDisplayTarget_var_samp_order_by: KdDisplayTargetVarSampOrderBy;
  kdDisplayTarget_variance_order_by: KdDisplayTargetVarianceOrderBy;
  lineChannelConfig: LineChannelConfig;
  lineChannelConfig_bool_exp: LineChannelConfigBoolExp;
  lineChannelConfig_insert_input: LineChannelConfigInsertInput;
  lineChannelConfig_mutation_response: LineChannelConfigMutationResponse;
  lineChannelConfig_obj_rel_insert_input: LineChannelConfigObjRelInsertInput;
  lineChannelConfig_on_conflict: LineChannelConfigOnConflict;
  lineChannelConfig_order_by: LineChannelConfigOrderBy;
  lineChannelConfig_pk_columns_input: LineChannelConfigPkColumnsInput;
  lineChannelConfig_set_input: LineChannelConfigSetInput;
  lineChannelConfig_stream_cursor_input: LineChannelConfigStreamCursorInput;
  lineChannelConfig_stream_cursor_value_input: LineChannelConfigStreamCursorValueInput;
  lineChannelConfig_updates: LineChannelConfigUpdates;
  lineOfficialAccount: LineOfficialAccount;
  lineOfficialAccount_aggregate_order_by: LineOfficialAccountAggregateOrderBy;
  lineOfficialAccount_avg_order_by: LineOfficialAccountAvgOrderBy;
  lineOfficialAccount_bool_exp: LineOfficialAccountBoolExp;
  lineOfficialAccount_max_order_by: LineOfficialAccountMaxOrderBy;
  lineOfficialAccount_min_order_by: LineOfficialAccountMinOrderBy;
  lineOfficialAccount_order_by: LineOfficialAccountOrderBy;
  lineOfficialAccount_stddev_order_by: LineOfficialAccountStddevOrderBy;
  lineOfficialAccount_stddev_pop_order_by: LineOfficialAccountStddevPopOrderBy;
  lineOfficialAccount_stddev_samp_order_by: LineOfficialAccountStddevSampOrderBy;
  lineOfficialAccount_stream_cursor_input: LineOfficialAccountStreamCursorInput;
  lineOfficialAccount_stream_cursor_value_input: LineOfficialAccountStreamCursorValueInput;
  lineOfficialAccount_sum_order_by: LineOfficialAccountSumOrderBy;
  lineOfficialAccount_var_pop_order_by: LineOfficialAccountVarPopOrderBy;
  lineOfficialAccount_var_samp_order_by: LineOfficialAccountVarSampOrderBy;
  lineOfficialAccount_variance_order_by: LineOfficialAccountVarianceOrderBy;
  lineProfile: LineProfile;
  lineProfile_bool_exp: LineProfileBoolExp;
  lineProfile_order_by: LineProfileOrderBy;
  lineProfile_stream_cursor_input: LineProfileStreamCursorInput;
  lineProfile_stream_cursor_value_input: LineProfileStreamCursorValueInput;
  lineReportingBotConfig: LineReportingBotConfig;
  lineReportingBotConfigShop: LineReportingBotConfigShop;
  lineReportingBotConfigShop_aggregate_order_by: LineReportingBotConfigShopAggregateOrderBy;
  lineReportingBotConfigShop_bool_exp: LineReportingBotConfigShopBoolExp;
  lineReportingBotConfigShop_insert_input: LineReportingBotConfigShopInsertInput;
  lineReportingBotConfigShop_max_order_by: LineReportingBotConfigShopMaxOrderBy;
  lineReportingBotConfigShop_min_order_by: LineReportingBotConfigShopMinOrderBy;
  lineReportingBotConfigShop_mutation_response: LineReportingBotConfigShopMutationResponse;
  lineReportingBotConfigShop_on_conflict: LineReportingBotConfigShopOnConflict;
  lineReportingBotConfigShop_order_by: LineReportingBotConfigShopOrderBy;
  lineReportingBotConfigShop_stream_cursor_input: LineReportingBotConfigShopStreamCursorInput;
  lineReportingBotConfigShop_stream_cursor_value_input: LineReportingBotConfigShopStreamCursorValueInput;
  lineReportingBotConfig_bool_exp: LineReportingBotConfigBoolExp;
  lineReportingBotConfig_mutation_response: LineReportingBotConfigMutationResponse;
  lineReportingBotConfig_order_by: LineReportingBotConfigOrderBy;
  lineReportingBotConfig_pk_columns_input: LineReportingBotConfigPkColumnsInput;
  lineReportingBotConfig_set_input: LineReportingBotConfigSetInput;
  lineReportingBotConfig_stream_cursor_input: LineReportingBotConfigStreamCursorInput;
  lineReportingBotConfig_stream_cursor_value_input: LineReportingBotConfigStreamCursorValueInput;
  lineReportingBotConfig_updates: LineReportingBotConfigUpdates;
  lineReportingBotVerification: LineReportingBotVerification;
  lineReportingBotVerification_bool_exp: LineReportingBotVerificationBoolExp;
  lineReportingBotVerification_order_by: LineReportingBotVerificationOrderBy;
  lineReportingBotVerification_stream_cursor_input: LineReportingBotVerificationStreamCursorInput;
  lineReportingBotVerification_stream_cursor_value_input: LineReportingBotVerificationStreamCursorValueInput;
  membershipCard: MembershipCard;
  membershipCardAppearanceType: MembershipCardAppearanceType;
  membershipCardAppearanceType_bool_exp: MembershipCardAppearanceTypeBoolExp;
  membershipCardAppearanceType_enum_comparison_exp: MembershipCardAppearanceTypeEnumComparisonExp;
  membershipCardAppearanceType_order_by: MembershipCardAppearanceTypeOrderBy;
  membershipCardAppearanceType_stream_cursor_input: MembershipCardAppearanceTypeStreamCursorInput;
  membershipCardAppearanceType_stream_cursor_value_input: MembershipCardAppearanceTypeStreamCursorValueInput;
  membershipCardTitle: MembershipCardTitle;
  membershipCardTitle_aggregate_order_by: MembershipCardTitleAggregateOrderBy;
  membershipCardTitle_arr_rel_insert_input: MembershipCardTitleArrRelInsertInput;
  membershipCardTitle_avg_order_by: MembershipCardTitleAvgOrderBy;
  membershipCardTitle_bool_exp: MembershipCardTitleBoolExp;
  membershipCardTitle_inc_input: MembershipCardTitleIncInput;
  membershipCardTitle_insert_input: MembershipCardTitleInsertInput;
  membershipCardTitle_max_order_by: MembershipCardTitleMaxOrderBy;
  membershipCardTitle_min_order_by: MembershipCardTitleMinOrderBy;
  membershipCardTitle_mutation_response: MembershipCardTitleMutationResponse;
  membershipCardTitle_on_conflict: MembershipCardTitleOnConflict;
  membershipCardTitle_order_by: MembershipCardTitleOrderBy;
  membershipCardTitle_pk_columns_input: MembershipCardTitlePkColumnsInput;
  membershipCardTitle_set_input: MembershipCardTitleSetInput;
  membershipCardTitle_stddev_order_by: MembershipCardTitleStddevOrderBy;
  membershipCardTitle_stddev_pop_order_by: MembershipCardTitleStddevPopOrderBy;
  membershipCardTitle_stddev_samp_order_by: MembershipCardTitleStddevSampOrderBy;
  membershipCardTitle_stream_cursor_input: MembershipCardTitleStreamCursorInput;
  membershipCardTitle_stream_cursor_value_input: MembershipCardTitleStreamCursorValueInput;
  membershipCardTitle_sum_order_by: MembershipCardTitleSumOrderBy;
  membershipCardTitle_updates: MembershipCardTitleUpdates;
  membershipCardTitle_var_pop_order_by: MembershipCardTitleVarPopOrderBy;
  membershipCardTitle_var_samp_order_by: MembershipCardTitleVarSampOrderBy;
  membershipCardTitle_variance_order_by: MembershipCardTitleVarianceOrderBy;
  membershipCard_aggregate_order_by: MembershipCardAggregateOrderBy;
  membershipCard_bool_exp: MembershipCardBoolExp;
  membershipCard_insert_input: MembershipCardInsertInput;
  membershipCard_max_order_by: MembershipCardMaxOrderBy;
  membershipCard_min_order_by: MembershipCardMinOrderBy;
  membershipCard_mutation_response: MembershipCardMutationResponse;
  membershipCard_obj_rel_insert_input: MembershipCardObjRelInsertInput;
  membershipCard_on_conflict: MembershipCardOnConflict;
  membershipCard_order_by: MembershipCardOrderBy;
  membershipCard_pk_columns_input: MembershipCardPkColumnsInput;
  membershipCard_set_input: MembershipCardSetInput;
  membershipCard_stream_cursor_input: MembershipCardStreamCursorInput;
  membershipCard_stream_cursor_value_input: MembershipCardStreamCursorValueInput;
  membershipCard_updates: MembershipCardUpdates;
  membershipRank: MembershipRank;
  membershipRankConfig: MembershipRankConfig;
  membershipRankConfig_aggregate_order_by: MembershipRankConfigAggregateOrderBy;
  membershipRankConfig_avg_order_by: MembershipRankConfigAvgOrderBy;
  membershipRankConfig_bool_exp: MembershipRankConfigBoolExp;
  membershipRankConfig_inc_input: MembershipRankConfigIncInput;
  membershipRankConfig_max_order_by: MembershipRankConfigMaxOrderBy;
  membershipRankConfig_min_order_by: MembershipRankConfigMinOrderBy;
  membershipRankConfig_mutation_response: MembershipRankConfigMutationResponse;
  membershipRankConfig_order_by: MembershipRankConfigOrderBy;
  membershipRankConfig_pk_columns_input: MembershipRankConfigPkColumnsInput;
  membershipRankConfig_set_input: MembershipRankConfigSetInput;
  membershipRankConfig_stddev_order_by: MembershipRankConfigStddevOrderBy;
  membershipRankConfig_stddev_pop_order_by: MembershipRankConfigStddevPopOrderBy;
  membershipRankConfig_stddev_samp_order_by: MembershipRankConfigStddevSampOrderBy;
  membershipRankConfig_stream_cursor_input: MembershipRankConfigStreamCursorInput;
  membershipRankConfig_stream_cursor_value_input: MembershipRankConfigStreamCursorValueInput;
  membershipRankConfig_sum_order_by: MembershipRankConfigSumOrderBy;
  membershipRankConfig_updates: MembershipRankConfigUpdates;
  membershipRankConfig_var_pop_order_by: MembershipRankConfigVarPopOrderBy;
  membershipRankConfig_var_samp_order_by: MembershipRankConfigVarSampOrderBy;
  membershipRankConfig_variance_order_by: MembershipRankConfigVarianceOrderBy;
  membershipRank_bool_exp: MembershipRankBoolExp;
  membershipRank_enum_comparison_exp: MembershipRankEnumComparisonExp;
  membershipRank_order_by: MembershipRankOrderBy;
  membershipRank_stream_cursor_input: MembershipRankStreamCursorInput;
  membershipRank_stream_cursor_value_input: MembershipRankStreamCursorValueInput;
  menu: Menu;
  menuOption: MenuOption;
  menuOption_aggregate_order_by: MenuOptionAggregateOrderBy;
  menuOption_arr_rel_insert_input: MenuOptionArrRelInsertInput;
  menuOption_avg_order_by: MenuOptionAvgOrderBy;
  menuOption_bool_exp: MenuOptionBoolExp;
  menuOption_inc_input: MenuOptionIncInput;
  menuOption_insert_input: MenuOptionInsertInput;
  menuOption_max_order_by: MenuOptionMaxOrderBy;
  menuOption_min_order_by: MenuOptionMinOrderBy;
  menuOption_mutation_response: MenuOptionMutationResponse;
  menuOption_on_conflict: MenuOptionOnConflict;
  menuOption_order_by: MenuOptionOrderBy;
  menuOption_pk_columns_input: MenuOptionPkColumnsInput;
  menuOption_set_input: MenuOptionSetInput;
  menuOption_stddev_order_by: MenuOptionStddevOrderBy;
  menuOption_stddev_pop_order_by: MenuOptionStddevPopOrderBy;
  menuOption_stddev_samp_order_by: MenuOptionStddevSampOrderBy;
  menuOption_stream_cursor_input: MenuOptionStreamCursorInput;
  menuOption_stream_cursor_value_input: MenuOptionStreamCursorValueInput;
  menuOption_sum_order_by: MenuOptionSumOrderBy;
  menuOption_updates: MenuOptionUpdates;
  menuOption_var_pop_order_by: MenuOptionVarPopOrderBy;
  menuOption_var_samp_order_by: MenuOptionVarSampOrderBy;
  menuOption_variance_order_by: MenuOptionVarianceOrderBy;
  menuOrderItem: MenuOrderItem;
  menuOrderItemChoice: MenuOrderItemChoice;
  menuOrderItemChoice_aggregate_order_by: MenuOrderItemChoiceAggregateOrderBy;
  menuOrderItemChoice_avg_order_by: MenuOrderItemChoiceAvgOrderBy;
  menuOrderItemChoice_bool_exp: MenuOrderItemChoiceBoolExp;
  menuOrderItemChoice_max_order_by: MenuOrderItemChoiceMaxOrderBy;
  menuOrderItemChoice_min_order_by: MenuOrderItemChoiceMinOrderBy;
  menuOrderItemChoice_order_by: MenuOrderItemChoiceOrderBy;
  menuOrderItemChoice_stddev_order_by: MenuOrderItemChoiceStddevOrderBy;
  menuOrderItemChoice_stddev_pop_order_by: MenuOrderItemChoiceStddevPopOrderBy;
  menuOrderItemChoice_stddev_samp_order_by: MenuOrderItemChoiceStddevSampOrderBy;
  menuOrderItemChoice_stream_cursor_input: MenuOrderItemChoiceStreamCursorInput;
  menuOrderItemChoice_stream_cursor_value_input: MenuOrderItemChoiceStreamCursorValueInput;
  menuOrderItemChoice_sum_order_by: MenuOrderItemChoiceSumOrderBy;
  menuOrderItemChoice_var_pop_order_by: MenuOrderItemChoiceVarPopOrderBy;
  menuOrderItemChoice_var_samp_order_by: MenuOrderItemChoiceVarSampOrderBy;
  menuOrderItemChoice_variance_order_by: MenuOrderItemChoiceVarianceOrderBy;
  menuOrderItemOption: MenuOrderItemOption;
  menuOrderItemOption_aggregate_order_by: MenuOrderItemOptionAggregateOrderBy;
  menuOrderItemOption_avg_order_by: MenuOrderItemOptionAvgOrderBy;
  menuOrderItemOption_bool_exp: MenuOrderItemOptionBoolExp;
  menuOrderItemOption_max_order_by: MenuOrderItemOptionMaxOrderBy;
  menuOrderItemOption_min_order_by: MenuOrderItemOptionMinOrderBy;
  menuOrderItemOption_order_by: MenuOrderItemOptionOrderBy;
  menuOrderItemOption_stddev_order_by: MenuOrderItemOptionStddevOrderBy;
  menuOrderItemOption_stddev_pop_order_by: MenuOrderItemOptionStddevPopOrderBy;
  menuOrderItemOption_stddev_samp_order_by: MenuOrderItemOptionStddevSampOrderBy;
  menuOrderItemOption_stream_cursor_input: MenuOrderItemOptionStreamCursorInput;
  menuOrderItemOption_stream_cursor_value_input: MenuOrderItemOptionStreamCursorValueInput;
  menuOrderItemOption_sum_order_by: MenuOrderItemOptionSumOrderBy;
  menuOrderItemOption_var_pop_order_by: MenuOrderItemOptionVarPopOrderBy;
  menuOrderItemOption_var_samp_order_by: MenuOrderItemOptionVarSampOrderBy;
  menuOrderItemOption_variance_order_by: MenuOrderItemOptionVarianceOrderBy;
  menuOrderItem_aggregate_order_by: MenuOrderItemAggregateOrderBy;
  menuOrderItem_avg_order_by: MenuOrderItemAvgOrderBy;
  menuOrderItem_bool_exp: MenuOrderItemBoolExp;
  menuOrderItem_max_order_by: MenuOrderItemMaxOrderBy;
  menuOrderItem_min_order_by: MenuOrderItemMinOrderBy;
  menuOrderItem_order_by: MenuOrderItemOrderBy;
  menuOrderItem_stddev_order_by: MenuOrderItemStddevOrderBy;
  menuOrderItem_stddev_pop_order_by: MenuOrderItemStddevPopOrderBy;
  menuOrderItem_stddev_samp_order_by: MenuOrderItemStddevSampOrderBy;
  menuOrderItem_stream_cursor_input: MenuOrderItemStreamCursorInput;
  menuOrderItem_stream_cursor_value_input: MenuOrderItemStreamCursorValueInput;
  menuOrderItem_sum_order_by: MenuOrderItemSumOrderBy;
  menuOrderItem_var_pop_order_by: MenuOrderItemVarPopOrderBy;
  menuOrderItem_var_samp_order_by: MenuOrderItemVarSampOrderBy;
  menuOrderItem_variance_order_by: MenuOrderItemVarianceOrderBy;
  menuRecommendationMeta: MenuRecommendationMeta;
  menuRecommendationMeta_bool_exp: MenuRecommendationMetaBoolExp;
  menuRecommendationMeta_inc_input: MenuRecommendationMetaIncInput;
  menuRecommendationMeta_insert_input: MenuRecommendationMetaInsertInput;
  menuRecommendationMeta_mutation_response: MenuRecommendationMetaMutationResponse;
  menuRecommendationMeta_obj_rel_insert_input: MenuRecommendationMetaObjRelInsertInput;
  menuRecommendationMeta_on_conflict: MenuRecommendationMetaOnConflict;
  menuRecommendationMeta_order_by: MenuRecommendationMetaOrderBy;
  menuRecommendationMeta_pk_columns_input: MenuRecommendationMetaPkColumnsInput;
  menuRecommendationMeta_set_input: MenuRecommendationMetaSetInput;
  menuRecommendationMeta_stream_cursor_input: MenuRecommendationMetaStreamCursorInput;
  menuRecommendationMeta_stream_cursor_value_input: MenuRecommendationMetaStreamCursorValueInput;
  menuRecommendationMeta_updates: MenuRecommendationMetaUpdates;
  menuType_enum_comparison_exp: MenuTypeEnumComparisonExp;
  menu_aggregate_order_by: MenuAggregateOrderBy;
  menu_arr_rel_insert_input: MenuArrRelInsertInput;
  menu_avg_order_by: MenuAvgOrderBy;
  menu_bool_exp: MenuBoolExp;
  menu_inc_input: MenuIncInput;
  menu_insert_input: MenuInsertInput;
  menu_max_order_by: MenuMaxOrderBy;
  menu_min_order_by: MenuMinOrderBy;
  menu_mutation_response: MenuMutationResponse;
  menu_obj_rel_insert_input: MenuObjRelInsertInput;
  menu_on_conflict: MenuOnConflict;
  menu_order_by: MenuOrderBy;
  menu_pk_columns_input: MenuPkColumnsInput;
  menu_set_input: MenuSetInput;
  menu_stddev_order_by: MenuStddevOrderBy;
  menu_stddev_pop_order_by: MenuStddevPopOrderBy;
  menu_stddev_samp_order_by: MenuStddevSampOrderBy;
  menu_stream_cursor_input: MenuStreamCursorInput;
  menu_stream_cursor_value_input: MenuStreamCursorValueInput;
  menu_sum_order_by: MenuSumOrderBy;
  menu_updates: MenuUpdates;
  menu_var_pop_order_by: MenuVarPopOrderBy;
  menu_var_samp_order_by: MenuVarSampOrderBy;
  menu_variance_order_by: MenuVarianceOrderBy;
  messageDelivery: MessageDelivery;
  messageDeliveryCustomerList: MessageDeliveryCustomerList;
  messageDeliveryCustomerListCustomer: MessageDeliveryCustomerListCustomer;
  messageDeliveryCustomerListCustomer_aggregate: MessageDeliveryCustomerListCustomerAggregate;
  messageDeliveryCustomerListCustomer_aggregate_bool_exp: MessageDeliveryCustomerListCustomerAggregateBoolExp;
  messageDeliveryCustomerListCustomer_aggregate_bool_exp_count: MessageDeliveryCustomerListCustomerAggregateBoolExpCount;
  messageDeliveryCustomerListCustomer_aggregate_fields: MessageDeliveryCustomerListCustomerAggregateFields;
  messageDeliveryCustomerListCustomer_aggregate_order_by: MessageDeliveryCustomerListCustomerAggregateOrderBy;
  messageDeliveryCustomerListCustomer_bool_exp: MessageDeliveryCustomerListCustomerBoolExp;
  messageDeliveryCustomerListCustomer_max_fields: MessageDeliveryCustomerListCustomerMaxFields;
  messageDeliveryCustomerListCustomer_max_order_by: MessageDeliveryCustomerListCustomerMaxOrderBy;
  messageDeliveryCustomerListCustomer_min_fields: MessageDeliveryCustomerListCustomerMinFields;
  messageDeliveryCustomerListCustomer_min_order_by: MessageDeliveryCustomerListCustomerMinOrderBy;
  messageDeliveryCustomerListCustomer_order_by: MessageDeliveryCustomerListCustomerOrderBy;
  messageDeliveryCustomerListCustomer_stream_cursor_input: MessageDeliveryCustomerListCustomerStreamCursorInput;
  messageDeliveryCustomerListCustomer_stream_cursor_value_input: MessageDeliveryCustomerListCustomerStreamCursorValueInput;
  messageDeliveryCustomerList_bool_exp: MessageDeliveryCustomerListBoolExp;
  messageDeliveryCustomerList_order_by: MessageDeliveryCustomerListOrderBy;
  messageDeliveryCustomerList_stream_cursor_input: MessageDeliveryCustomerListStreamCursorInput;
  messageDeliveryCustomerList_stream_cursor_value_input: MessageDeliveryCustomerListStreamCursorValueInput;
  messageDeliveryCustomerSegment: MessageDeliveryCustomerSegment;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition: MessageDeliveryCustomerSegmentBusinessOperationHoursCondition;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_aggregate_order_by: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionAggregateOrderBy;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_bool_exp: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionBoolExp;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_max_order_by: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionMaxOrderBy;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_min_order_by: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionMinOrderBy;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_order_by: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionOrderBy;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_stream_cursor_input: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionStreamCursorInput;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentDayOfWeekCondition: MessageDeliveryCustomerSegmentDayOfWeekCondition;
  messageDeliveryCustomerSegmentDayOfWeekCondition_aggregate_order_by: MessageDeliveryCustomerSegmentDayOfWeekConditionAggregateOrderBy;
  messageDeliveryCustomerSegmentDayOfWeekCondition_bool_exp: MessageDeliveryCustomerSegmentDayOfWeekConditionBoolExp;
  messageDeliveryCustomerSegmentDayOfWeekCondition_max_order_by: MessageDeliveryCustomerSegmentDayOfWeekConditionMaxOrderBy;
  messageDeliveryCustomerSegmentDayOfWeekCondition_min_order_by: MessageDeliveryCustomerSegmentDayOfWeekConditionMinOrderBy;
  messageDeliveryCustomerSegmentDayOfWeekCondition_order_by: MessageDeliveryCustomerSegmentDayOfWeekConditionOrderBy;
  messageDeliveryCustomerSegmentDayOfWeekCondition_stream_cursor_input: MessageDeliveryCustomerSegmentDayOfWeekConditionStreamCursorInput;
  messageDeliveryCustomerSegmentDayOfWeekCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentDayOfWeekConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentDayOfWeekType_enum_comparison_exp: MessageDeliveryCustomerSegmentDayOfWeekTypeEnumComparisonExp;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_bool_exp: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionBoolExp;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_order_by: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionOrderBy;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_stream_cursor_input: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionStreamCursorInput;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentGender: MessageDeliveryCustomerSegmentGender;
  messageDeliveryCustomerSegmentGenderCondition: MessageDeliveryCustomerSegmentGenderCondition;
  messageDeliveryCustomerSegmentGenderCondition_bool_exp: MessageDeliveryCustomerSegmentGenderConditionBoolExp;
  messageDeliveryCustomerSegmentGenderCondition_order_by: MessageDeliveryCustomerSegmentGenderConditionOrderBy;
  messageDeliveryCustomerSegmentGenderCondition_stream_cursor_input: MessageDeliveryCustomerSegmentGenderConditionStreamCursorInput;
  messageDeliveryCustomerSegmentGenderCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentGenderConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentGender_bool_exp: MessageDeliveryCustomerSegmentGenderBoolExp;
  messageDeliveryCustomerSegmentGender_enum_comparison_exp: MessageDeliveryCustomerSegmentGenderEnumComparisonExp;
  messageDeliveryCustomerSegmentGender_order_by: MessageDeliveryCustomerSegmentGenderOrderBy;
  messageDeliveryCustomerSegmentGender_stream_cursor_input: MessageDeliveryCustomerSegmentGenderStreamCursorInput;
  messageDeliveryCustomerSegmentGender_stream_cursor_value_input: MessageDeliveryCustomerSegmentGenderStreamCursorValueInput;
  messageDeliveryCustomerSegmentMenuCondition: MessageDeliveryCustomerSegmentMenuCondition;
  messageDeliveryCustomerSegmentMenuCondition_aggregate_order_by: MessageDeliveryCustomerSegmentMenuConditionAggregateOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_avg_order_by: MessageDeliveryCustomerSegmentMenuConditionAvgOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_bool_exp: MessageDeliveryCustomerSegmentMenuConditionBoolExp;
  messageDeliveryCustomerSegmentMenuCondition_max_order_by: MessageDeliveryCustomerSegmentMenuConditionMaxOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_min_order_by: MessageDeliveryCustomerSegmentMenuConditionMinOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_order_by: MessageDeliveryCustomerSegmentMenuConditionOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_stddev_order_by: MessageDeliveryCustomerSegmentMenuConditionStddevOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_stddev_pop_order_by: MessageDeliveryCustomerSegmentMenuConditionStddevPopOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_stddev_samp_order_by: MessageDeliveryCustomerSegmentMenuConditionStddevSampOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_stream_cursor_input: MessageDeliveryCustomerSegmentMenuConditionStreamCursorInput;
  messageDeliveryCustomerSegmentMenuCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentMenuConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentMenuCondition_sum_order_by: MessageDeliveryCustomerSegmentMenuConditionSumOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_var_pop_order_by: MessageDeliveryCustomerSegmentMenuConditionVarPopOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_var_samp_order_by: MessageDeliveryCustomerSegmentMenuConditionVarSampOrderBy;
  messageDeliveryCustomerSegmentMenuCondition_variance_order_by: MessageDeliveryCustomerSegmentMenuConditionVarianceOrderBy;
  messageDeliveryCustomerSegmentNumericCondition: MessageDeliveryCustomerSegmentNumericCondition;
  messageDeliveryCustomerSegmentNumericCondition_bool_exp: MessageDeliveryCustomerSegmentNumericConditionBoolExp;
  messageDeliveryCustomerSegmentNumericCondition_order_by: MessageDeliveryCustomerSegmentNumericConditionOrderBy;
  messageDeliveryCustomerSegmentNumericCondition_stream_cursor_input: MessageDeliveryCustomerSegmentNumericConditionStreamCursorInput;
  messageDeliveryCustomerSegmentNumericCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentNumericConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition: MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_bool_exp: MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionBoolExp;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_order_by: MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionOrderBy;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_stream_cursor_input: MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionStreamCursorInput;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusType;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_bool_exp: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeBoolExp;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_enum_comparison_exp: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnumComparisonExp;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_order_by: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeOrderBy;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_stream_cursor_input: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeStreamCursorInput;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_stream_cursor_value_input: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeStreamCursorValueInput;
  messageDeliveryCustomerSegmentShopCondition: MessageDeliveryCustomerSegmentShopCondition;
  messageDeliveryCustomerSegmentShopCondition_aggregate_order_by: MessageDeliveryCustomerSegmentShopConditionAggregateOrderBy;
  messageDeliveryCustomerSegmentShopCondition_bool_exp: MessageDeliveryCustomerSegmentShopConditionBoolExp;
  messageDeliveryCustomerSegmentShopCondition_max_order_by: MessageDeliveryCustomerSegmentShopConditionMaxOrderBy;
  messageDeliveryCustomerSegmentShopCondition_min_order_by: MessageDeliveryCustomerSegmentShopConditionMinOrderBy;
  messageDeliveryCustomerSegmentShopCondition_order_by: MessageDeliveryCustomerSegmentShopConditionOrderBy;
  messageDeliveryCustomerSegmentShopCondition_stream_cursor_input: MessageDeliveryCustomerSegmentShopConditionStreamCursorInput;
  messageDeliveryCustomerSegmentShopCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentShopConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition: MessageDeliveryCustomerSegmentUnvisitedDayCountCondition;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_bool_exp: MessageDeliveryCustomerSegmentUnvisitedDayCountConditionBoolExp;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_order_by: MessageDeliveryCustomerSegmentUnvisitedDayCountConditionOrderBy;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_stream_cursor_input: MessageDeliveryCustomerSegmentUnvisitedDayCountConditionStreamCursorInput;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentUnvisitedDayCountConditionStreamCursorValueInput;
  messageDeliveryCustomerSegmentVisitedDayCountCondition: MessageDeliveryCustomerSegmentVisitedDayCountCondition;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_bool_exp: MessageDeliveryCustomerSegmentVisitedDayCountConditionBoolExp;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_order_by: MessageDeliveryCustomerSegmentVisitedDayCountConditionOrderBy;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_stream_cursor_input: MessageDeliveryCustomerSegmentVisitedDayCountConditionStreamCursorInput;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_stream_cursor_value_input: MessageDeliveryCustomerSegmentVisitedDayCountConditionStreamCursorValueInput;
  messageDeliveryCustomerSegment_bool_exp: MessageDeliveryCustomerSegmentBoolExp;
  messageDeliveryCustomerSegment_order_by: MessageDeliveryCustomerSegmentOrderBy;
  messageDeliveryCustomerSegment_stream_cursor_input: MessageDeliveryCustomerSegmentStreamCursorInput;
  messageDeliveryCustomerSegment_stream_cursor_value_input: MessageDeliveryCustomerSegmentStreamCursorValueInput;
  messageDeliveryJob: MessageDeliveryJob;
  messageDeliveryJob_aggregate_order_by: MessageDeliveryJobAggregateOrderBy;
  messageDeliveryJob_avg_order_by: MessageDeliveryJobAvgOrderBy;
  messageDeliveryJob_bool_exp: MessageDeliveryJobBoolExp;
  messageDeliveryJob_max_order_by: MessageDeliveryJobMaxOrderBy;
  messageDeliveryJob_min_order_by: MessageDeliveryJobMinOrderBy;
  messageDeliveryJob_order_by: MessageDeliveryJobOrderBy;
  messageDeliveryJob_stddev_order_by: MessageDeliveryJobStddevOrderBy;
  messageDeliveryJob_stddev_pop_order_by: MessageDeliveryJobStddevPopOrderBy;
  messageDeliveryJob_stddev_samp_order_by: MessageDeliveryJobStddevSampOrderBy;
  messageDeliveryJob_stream_cursor_input: MessageDeliveryJobStreamCursorInput;
  messageDeliveryJob_stream_cursor_value_input: MessageDeliveryJobStreamCursorValueInput;
  messageDeliveryJob_sum_order_by: MessageDeliveryJobSumOrderBy;
  messageDeliveryJob_var_pop_order_by: MessageDeliveryJobVarPopOrderBy;
  messageDeliveryJob_var_samp_order_by: MessageDeliveryJobVarSampOrderBy;
  messageDeliveryJob_variance_order_by: MessageDeliveryJobVarianceOrderBy;
  messageDeliveryMessage: MessageDeliveryMessage;
  messageDeliveryMessageCouponTypeMeta: MessageDeliveryMessageCouponTypeMeta;
  messageDeliveryMessageCouponTypeMeta_aggregate_order_by: MessageDeliveryMessageCouponTypeMetaAggregateOrderBy;
  messageDeliveryMessageCouponTypeMeta_bool_exp: MessageDeliveryMessageCouponTypeMetaBoolExp;
  messageDeliveryMessageCouponTypeMeta_max_order_by: MessageDeliveryMessageCouponTypeMetaMaxOrderBy;
  messageDeliveryMessageCouponTypeMeta_min_order_by: MessageDeliveryMessageCouponTypeMetaMinOrderBy;
  messageDeliveryMessageCouponTypeMeta_order_by: MessageDeliveryMessageCouponTypeMetaOrderBy;
  messageDeliveryMessageCouponTypeMeta_stream_cursor_input: MessageDeliveryMessageCouponTypeMetaStreamCursorInput;
  messageDeliveryMessageCouponTypeMeta_stream_cursor_value_input: MessageDeliveryMessageCouponTypeMetaStreamCursorValueInput;
  messageDeliveryMessageImageTypeMeta: MessageDeliveryMessageImageTypeMeta;
  messageDeliveryMessageImageTypeMeta_bool_exp: MessageDeliveryMessageImageTypeMetaBoolExp;
  messageDeliveryMessageImageTypeMeta_order_by: MessageDeliveryMessageImageTypeMetaOrderBy;
  messageDeliveryMessageImageTypeMeta_stream_cursor_input: MessageDeliveryMessageImageTypeMetaStreamCursorInput;
  messageDeliveryMessageImageTypeMeta_stream_cursor_value_input: MessageDeliveryMessageImageTypeMetaStreamCursorValueInput;
  messageDeliveryMessageQuestionnaireTypeMeta: MessageDeliveryMessageQuestionnaireTypeMeta;
  messageDeliveryMessageQuestionnaireTypeMeta_bool_exp: MessageDeliveryMessageQuestionnaireTypeMetaBoolExp;
  messageDeliveryMessageQuestionnaireTypeMeta_order_by: MessageDeliveryMessageQuestionnaireTypeMetaOrderBy;
  messageDeliveryMessageQuestionnaireTypeMeta_stream_cursor_input: MessageDeliveryMessageQuestionnaireTypeMetaStreamCursorInput;
  messageDeliveryMessageQuestionnaireTypeMeta_stream_cursor_value_input: MessageDeliveryMessageQuestionnaireTypeMetaStreamCursorValueInput;
  messageDeliveryMessageTextTypeMeta: MessageDeliveryMessageTextTypeMeta;
  messageDeliveryMessageTextTypeMeta_bool_exp: MessageDeliveryMessageTextTypeMetaBoolExp;
  messageDeliveryMessageTextTypeMeta_order_by: MessageDeliveryMessageTextTypeMetaOrderBy;
  messageDeliveryMessageTextTypeMeta_stream_cursor_input: MessageDeliveryMessageTextTypeMetaStreamCursorInput;
  messageDeliveryMessageTextTypeMeta_stream_cursor_value_input: MessageDeliveryMessageTextTypeMetaStreamCursorValueInput;
  messageDeliveryMessageType_enum_comparison_exp: MessageDeliveryMessageTypeEnumComparisonExp;
  messageDeliveryMessage_aggregate_order_by: MessageDeliveryMessageAggregateOrderBy;
  messageDeliveryMessage_avg_order_by: MessageDeliveryMessageAvgOrderBy;
  messageDeliveryMessage_bool_exp: MessageDeliveryMessageBoolExp;
  messageDeliveryMessage_max_order_by: MessageDeliveryMessageMaxOrderBy;
  messageDeliveryMessage_min_order_by: MessageDeliveryMessageMinOrderBy;
  messageDeliveryMessage_order_by: MessageDeliveryMessageOrderBy;
  messageDeliveryMessage_stddev_order_by: MessageDeliveryMessageStddevOrderBy;
  messageDeliveryMessage_stddev_pop_order_by: MessageDeliveryMessageStddevPopOrderBy;
  messageDeliveryMessage_stddev_samp_order_by: MessageDeliveryMessageStddevSampOrderBy;
  messageDeliveryMessage_stream_cursor_input: MessageDeliveryMessageStreamCursorInput;
  messageDeliveryMessage_stream_cursor_value_input: MessageDeliveryMessageStreamCursorValueInput;
  messageDeliveryMessage_sum_order_by: MessageDeliveryMessageSumOrderBy;
  messageDeliveryMessage_var_pop_order_by: MessageDeliveryMessageVarPopOrderBy;
  messageDeliveryMessage_var_samp_order_by: MessageDeliveryMessageVarSampOrderBy;
  messageDeliveryMessage_variance_order_by: MessageDeliveryMessageVarianceOrderBy;
  messageDeliveryShopJob: MessageDeliveryShopJob;
  messageDeliveryShopJobErrorType_enum_comparison_exp: MessageDeliveryShopJobErrorTypeEnumComparisonExp;
  messageDeliveryShopJob_aggregate_order_by: MessageDeliveryShopJobAggregateOrderBy;
  messageDeliveryShopJob_avg_order_by: MessageDeliveryShopJobAvgOrderBy;
  messageDeliveryShopJob_bool_exp: MessageDeliveryShopJobBoolExp;
  messageDeliveryShopJob_max_order_by: MessageDeliveryShopJobMaxOrderBy;
  messageDeliveryShopJob_min_order_by: MessageDeliveryShopJobMinOrderBy;
  messageDeliveryShopJob_order_by: MessageDeliveryShopJobOrderBy;
  messageDeliveryShopJob_stddev_order_by: MessageDeliveryShopJobStddevOrderBy;
  messageDeliveryShopJob_stddev_pop_order_by: MessageDeliveryShopJobStddevPopOrderBy;
  messageDeliveryShopJob_stddev_samp_order_by: MessageDeliveryShopJobStddevSampOrderBy;
  messageDeliveryShopJob_stream_cursor_input: MessageDeliveryShopJobStreamCursorInput;
  messageDeliveryShopJob_stream_cursor_value_input: MessageDeliveryShopJobStreamCursorValueInput;
  messageDeliveryShopJob_sum_order_by: MessageDeliveryShopJobSumOrderBy;
  messageDeliveryShopJob_var_pop_order_by: MessageDeliveryShopJobVarPopOrderBy;
  messageDeliveryShopJob_var_samp_order_by: MessageDeliveryShopJobVarSampOrderBy;
  messageDeliveryShopJob_variance_order_by: MessageDeliveryShopJobVarianceOrderBy;
  messageDelivery_aggregate_order_by: MessageDeliveryAggregateOrderBy;
  messageDelivery_avg_order_by: MessageDeliveryAvgOrderBy;
  messageDelivery_bool_exp: MessageDeliveryBoolExp;
  messageDelivery_inc_input: MessageDeliveryIncInput;
  messageDelivery_max_order_by: MessageDeliveryMaxOrderBy;
  messageDelivery_min_order_by: MessageDeliveryMinOrderBy;
  messageDelivery_mutation_response: MessageDeliveryMutationResponse;
  messageDelivery_order_by: MessageDeliveryOrderBy;
  messageDelivery_pk_columns_input: MessageDeliveryPkColumnsInput;
  messageDelivery_set_input: MessageDeliverySetInput;
  messageDelivery_stddev_order_by: MessageDeliveryStddevOrderBy;
  messageDelivery_stddev_pop_order_by: MessageDeliveryStddevPopOrderBy;
  messageDelivery_stddev_samp_order_by: MessageDeliveryStddevSampOrderBy;
  messageDelivery_stream_cursor_input: MessageDeliveryStreamCursorInput;
  messageDelivery_stream_cursor_value_input: MessageDeliveryStreamCursorValueInput;
  messageDelivery_sum_order_by: MessageDeliverySumOrderBy;
  messageDelivery_updates: MessageDeliveryUpdates;
  messageDelivery_var_pop_order_by: MessageDeliveryVarPopOrderBy;
  messageDelivery_var_samp_order_by: MessageDeliveryVarSampOrderBy;
  messageDelivery_variance_order_by: MessageDeliveryVarianceOrderBy;
  monthlySalesBudget: MonthlySalesBudget;
  monthlySalesBudget_bool_exp: MonthlySalesBudgetBoolExp;
  monthlySalesBudget_inc_input: MonthlySalesBudgetIncInput;
  monthlySalesBudget_insert_input: MonthlySalesBudgetInsertInput;
  monthlySalesBudget_mutation_response: MonthlySalesBudgetMutationResponse;
  monthlySalesBudget_obj_rel_insert_input: MonthlySalesBudgetObjRelInsertInput;
  monthlySalesBudget_on_conflict: MonthlySalesBudgetOnConflict;
  monthlySalesBudget_order_by: MonthlySalesBudgetOrderBy;
  monthlySalesBudget_pk_columns_input: MonthlySalesBudgetPkColumnsInput;
  monthlySalesBudget_set_input: MonthlySalesBudgetSetInput;
  monthlySalesBudget_stream_cursor_input: MonthlySalesBudgetStreamCursorInput;
  monthlySalesBudget_stream_cursor_value_input: MonthlySalesBudgetStreamCursorValueInput;
  monthlySalesBudget_updates: MonthlySalesBudgetUpdates;
  multipleChoiceTypeQuestionAnswer: MultipleChoiceTypeQuestionAnswer;
  multipleChoiceTypeQuestionAnswer_aggregate_order_by: MultipleChoiceTypeQuestionAnswerAggregateOrderBy;
  multipleChoiceTypeQuestionAnswer_bool_exp: MultipleChoiceTypeQuestionAnswerBoolExp;
  multipleChoiceTypeQuestionAnswer_max_order_by: MultipleChoiceTypeQuestionAnswerMaxOrderBy;
  multipleChoiceTypeQuestionAnswer_min_order_by: MultipleChoiceTypeQuestionAnswerMinOrderBy;
  multipleChoiceTypeQuestionAnswer_order_by: MultipleChoiceTypeQuestionAnswerOrderBy;
  multipleChoiceTypeQuestionAnswer_stream_cursor_input: MultipleChoiceTypeQuestionAnswerStreamCursorInput;
  multipleChoiceTypeQuestionAnswer_stream_cursor_value_input: MultipleChoiceTypeQuestionAnswerStreamCursorValueInput;
  multipleChoiceTypeQuestionChoice: MultipleChoiceTypeQuestionChoice;
  multipleChoiceTypeQuestionChoiceAnswer: MultipleChoiceTypeQuestionChoiceAnswer;
  multipleChoiceTypeQuestionChoiceAnswer_aggregate_order_by: MultipleChoiceTypeQuestionChoiceAnswerAggregateOrderBy;
  multipleChoiceTypeQuestionChoiceAnswer_bool_exp: MultipleChoiceTypeQuestionChoiceAnswerBoolExp;
  multipleChoiceTypeQuestionChoiceAnswer_max_order_by: MultipleChoiceTypeQuestionChoiceAnswerMaxOrderBy;
  multipleChoiceTypeQuestionChoiceAnswer_min_order_by: MultipleChoiceTypeQuestionChoiceAnswerMinOrderBy;
  multipleChoiceTypeQuestionChoiceAnswer_order_by: MultipleChoiceTypeQuestionChoiceAnswerOrderBy;
  multipleChoiceTypeQuestionChoiceAnswer_stream_cursor_input: MultipleChoiceTypeQuestionChoiceAnswerStreamCursorInput;
  multipleChoiceTypeQuestionChoiceAnswer_stream_cursor_value_input: MultipleChoiceTypeQuestionChoiceAnswerStreamCursorValueInput;
  multipleChoiceTypeQuestionChoice_aggregate_order_by: MultipleChoiceTypeQuestionChoiceAggregateOrderBy;
  multipleChoiceTypeQuestionChoice_bool_exp: MultipleChoiceTypeQuestionChoiceBoolExp;
  multipleChoiceTypeQuestionChoice_max_order_by: MultipleChoiceTypeQuestionChoiceMaxOrderBy;
  multipleChoiceTypeQuestionChoice_min_order_by: MultipleChoiceTypeQuestionChoiceMinOrderBy;
  multipleChoiceTypeQuestionChoice_order_by: MultipleChoiceTypeQuestionChoiceOrderBy;
  multipleChoiceTypeQuestionChoice_stream_cursor_input: MultipleChoiceTypeQuestionChoiceStreamCursorInput;
  multipleChoiceTypeQuestionChoice_stream_cursor_value_input: MultipleChoiceTypeQuestionChoiceStreamCursorValueInput;
  multipleChoiceTypeQuestionMeta: MultipleChoiceTypeQuestionMeta;
  multipleChoiceTypeQuestionMeta_bool_exp: MultipleChoiceTypeQuestionMetaBoolExp;
  multipleChoiceTypeQuestionMeta_order_by: MultipleChoiceTypeQuestionMetaOrderBy;
  multipleChoiceTypeQuestionMeta_stream_cursor_input: MultipleChoiceTypeQuestionMetaStreamCursorInput;
  multipleChoiceTypeQuestionMeta_stream_cursor_value_input: MultipleChoiceTypeQuestionMetaStreamCursorValueInput;
  mutation_root: {};
  numeric: Scalars['numeric'];
  numeric_comparison_exp: NumericComparisonExp;
  onSitePayment: OnSitePayment;
  onSitePaymentDetail: OnSitePaymentDetail;
  onSitePaymentDetailType: OnSitePaymentDetailType;
  onSitePaymentDetailTypeCategoryPriority: OnSitePaymentDetailTypeCategoryPriority;
  onSitePaymentDetailTypeCategoryPriority_bool_exp: OnSitePaymentDetailTypeCategoryPriorityBoolExp;
  onSitePaymentDetailTypeCategoryPriority_order_by: OnSitePaymentDetailTypeCategoryPriorityOrderBy;
  onSitePaymentDetailTypeCategoryPriority_stream_cursor_input: OnSitePaymentDetailTypeCategoryPriorityStreamCursorInput;
  onSitePaymentDetailTypeCategoryPriority_stream_cursor_value_input: OnSitePaymentDetailTypeCategoryPriorityStreamCursorValueInput;
  onSitePaymentDetailTypeCategory_enum_comparison_exp: OnSitePaymentDetailTypeCategoryEnumComparisonExp;
  onSitePaymentDetailType_aggregate: OnSitePaymentDetailTypeAggregate;
  onSitePaymentDetailType_aggregate_bool_exp: OnSitePaymentDetailTypeAggregateBoolExp;
  onSitePaymentDetailType_aggregate_bool_exp_bool_and: OnSitePaymentDetailTypeAggregateBoolExpBoolAnd;
  onSitePaymentDetailType_aggregate_bool_exp_bool_or: OnSitePaymentDetailTypeAggregateBoolExpBoolOr;
  onSitePaymentDetailType_aggregate_bool_exp_count: OnSitePaymentDetailTypeAggregateBoolExpCount;
  onSitePaymentDetailType_aggregate_fields: OnSitePaymentDetailTypeAggregateFields;
  onSitePaymentDetailType_aggregate_order_by: OnSitePaymentDetailTypeAggregateOrderBy;
  onSitePaymentDetailType_arr_rel_insert_input: OnSitePaymentDetailTypeArrRelInsertInput;
  onSitePaymentDetailType_avg_fields: OnSitePaymentDetailTypeAvgFields;
  onSitePaymentDetailType_avg_order_by: OnSitePaymentDetailTypeAvgOrderBy;
  onSitePaymentDetailType_bool_exp: OnSitePaymentDetailTypeBoolExp;
  onSitePaymentDetailType_inc_input: OnSitePaymentDetailTypeIncInput;
  onSitePaymentDetailType_insert_input: OnSitePaymentDetailTypeInsertInput;
  onSitePaymentDetailType_max_fields: OnSitePaymentDetailTypeMaxFields;
  onSitePaymentDetailType_max_order_by: OnSitePaymentDetailTypeMaxOrderBy;
  onSitePaymentDetailType_min_fields: OnSitePaymentDetailTypeMinFields;
  onSitePaymentDetailType_min_order_by: OnSitePaymentDetailTypeMinOrderBy;
  onSitePaymentDetailType_mutation_response: OnSitePaymentDetailTypeMutationResponse;
  onSitePaymentDetailType_obj_rel_insert_input: OnSitePaymentDetailTypeObjRelInsertInput;
  onSitePaymentDetailType_on_conflict: OnSitePaymentDetailTypeOnConflict;
  onSitePaymentDetailType_order_by: OnSitePaymentDetailTypeOrderBy;
  onSitePaymentDetailType_pk_columns_input: OnSitePaymentDetailTypePkColumnsInput;
  onSitePaymentDetailType_set_input: OnSitePaymentDetailTypeSetInput;
  onSitePaymentDetailType_stddev_fields: OnSitePaymentDetailTypeStddevFields;
  onSitePaymentDetailType_stddev_order_by: OnSitePaymentDetailTypeStddevOrderBy;
  onSitePaymentDetailType_stddev_pop_fields: OnSitePaymentDetailTypeStddevPopFields;
  onSitePaymentDetailType_stddev_pop_order_by: OnSitePaymentDetailTypeStddevPopOrderBy;
  onSitePaymentDetailType_stddev_samp_fields: OnSitePaymentDetailTypeStddevSampFields;
  onSitePaymentDetailType_stddev_samp_order_by: OnSitePaymentDetailTypeStddevSampOrderBy;
  onSitePaymentDetailType_stream_cursor_input: OnSitePaymentDetailTypeStreamCursorInput;
  onSitePaymentDetailType_stream_cursor_value_input: OnSitePaymentDetailTypeStreamCursorValueInput;
  onSitePaymentDetailType_sum_fields: OnSitePaymentDetailTypeSumFields;
  onSitePaymentDetailType_sum_order_by: OnSitePaymentDetailTypeSumOrderBy;
  onSitePaymentDetailType_updates: OnSitePaymentDetailTypeUpdates;
  onSitePaymentDetailType_var_pop_fields: OnSitePaymentDetailTypeVarPopFields;
  onSitePaymentDetailType_var_pop_order_by: OnSitePaymentDetailTypeVarPopOrderBy;
  onSitePaymentDetailType_var_samp_fields: OnSitePaymentDetailTypeVarSampFields;
  onSitePaymentDetailType_var_samp_order_by: OnSitePaymentDetailTypeVarSampOrderBy;
  onSitePaymentDetailType_variance_fields: OnSitePaymentDetailTypeVarianceFields;
  onSitePaymentDetailType_variance_order_by: OnSitePaymentDetailTypeVarianceOrderBy;
  onSitePaymentDetail_aggregate_order_by: OnSitePaymentDetailAggregateOrderBy;
  onSitePaymentDetail_avg_order_by: OnSitePaymentDetailAvgOrderBy;
  onSitePaymentDetail_bool_exp: OnSitePaymentDetailBoolExp;
  onSitePaymentDetail_max_order_by: OnSitePaymentDetailMaxOrderBy;
  onSitePaymentDetail_min_order_by: OnSitePaymentDetailMinOrderBy;
  onSitePaymentDetail_order_by: OnSitePaymentDetailOrderBy;
  onSitePaymentDetail_stddev_order_by: OnSitePaymentDetailStddevOrderBy;
  onSitePaymentDetail_stddev_pop_order_by: OnSitePaymentDetailStddevPopOrderBy;
  onSitePaymentDetail_stddev_samp_order_by: OnSitePaymentDetailStddevSampOrderBy;
  onSitePaymentDetail_stream_cursor_input: OnSitePaymentDetailStreamCursorInput;
  onSitePaymentDetail_stream_cursor_value_input: OnSitePaymentDetailStreamCursorValueInput;
  onSitePaymentDetail_sum_order_by: OnSitePaymentDetailSumOrderBy;
  onSitePaymentDetail_var_pop_order_by: OnSitePaymentDetailVarPopOrderBy;
  onSitePaymentDetail_var_samp_order_by: OnSitePaymentDetailVarSampOrderBy;
  onSitePaymentDetail_variance_order_by: OnSitePaymentDetailVarianceOrderBy;
  onSitePaymentDiscount: OnSitePaymentDiscount;
  onSitePaymentDiscountType: OnSitePaymentDiscountType;
  onSitePaymentDiscountType_aggregate: OnSitePaymentDiscountTypeAggregate;
  onSitePaymentDiscountType_aggregate_bool_exp: OnSitePaymentDiscountTypeAggregateBoolExp;
  onSitePaymentDiscountType_aggregate_bool_exp_bool_and: OnSitePaymentDiscountTypeAggregateBoolExpBoolAnd;
  onSitePaymentDiscountType_aggregate_bool_exp_bool_or: OnSitePaymentDiscountTypeAggregateBoolExpBoolOr;
  onSitePaymentDiscountType_aggregate_bool_exp_count: OnSitePaymentDiscountTypeAggregateBoolExpCount;
  onSitePaymentDiscountType_aggregate_fields: OnSitePaymentDiscountTypeAggregateFields;
  onSitePaymentDiscountType_aggregate_order_by: OnSitePaymentDiscountTypeAggregateOrderBy;
  onSitePaymentDiscountType_arr_rel_insert_input: OnSitePaymentDiscountTypeArrRelInsertInput;
  onSitePaymentDiscountType_avg_fields: OnSitePaymentDiscountTypeAvgFields;
  onSitePaymentDiscountType_avg_order_by: OnSitePaymentDiscountTypeAvgOrderBy;
  onSitePaymentDiscountType_bool_exp: OnSitePaymentDiscountTypeBoolExp;
  onSitePaymentDiscountType_inc_input: OnSitePaymentDiscountTypeIncInput;
  onSitePaymentDiscountType_insert_input: OnSitePaymentDiscountTypeInsertInput;
  onSitePaymentDiscountType_max_fields: OnSitePaymentDiscountTypeMaxFields;
  onSitePaymentDiscountType_max_order_by: OnSitePaymentDiscountTypeMaxOrderBy;
  onSitePaymentDiscountType_min_fields: OnSitePaymentDiscountTypeMinFields;
  onSitePaymentDiscountType_min_order_by: OnSitePaymentDiscountTypeMinOrderBy;
  onSitePaymentDiscountType_mutation_response: OnSitePaymentDiscountTypeMutationResponse;
  onSitePaymentDiscountType_obj_rel_insert_input: OnSitePaymentDiscountTypeObjRelInsertInput;
  onSitePaymentDiscountType_on_conflict: OnSitePaymentDiscountTypeOnConflict;
  onSitePaymentDiscountType_order_by: OnSitePaymentDiscountTypeOrderBy;
  onSitePaymentDiscountType_pk_columns_input: OnSitePaymentDiscountTypePkColumnsInput;
  onSitePaymentDiscountType_set_input: OnSitePaymentDiscountTypeSetInput;
  onSitePaymentDiscountType_stddev_fields: OnSitePaymentDiscountTypeStddevFields;
  onSitePaymentDiscountType_stddev_order_by: OnSitePaymentDiscountTypeStddevOrderBy;
  onSitePaymentDiscountType_stddev_pop_fields: OnSitePaymentDiscountTypeStddevPopFields;
  onSitePaymentDiscountType_stddev_pop_order_by: OnSitePaymentDiscountTypeStddevPopOrderBy;
  onSitePaymentDiscountType_stddev_samp_fields: OnSitePaymentDiscountTypeStddevSampFields;
  onSitePaymentDiscountType_stddev_samp_order_by: OnSitePaymentDiscountTypeStddevSampOrderBy;
  onSitePaymentDiscountType_stream_cursor_input: OnSitePaymentDiscountTypeStreamCursorInput;
  onSitePaymentDiscountType_stream_cursor_value_input: OnSitePaymentDiscountTypeStreamCursorValueInput;
  onSitePaymentDiscountType_sum_fields: OnSitePaymentDiscountTypeSumFields;
  onSitePaymentDiscountType_sum_order_by: OnSitePaymentDiscountTypeSumOrderBy;
  onSitePaymentDiscountType_updates: OnSitePaymentDiscountTypeUpdates;
  onSitePaymentDiscountType_var_pop_fields: OnSitePaymentDiscountTypeVarPopFields;
  onSitePaymentDiscountType_var_pop_order_by: OnSitePaymentDiscountTypeVarPopOrderBy;
  onSitePaymentDiscountType_var_samp_fields: OnSitePaymentDiscountTypeVarSampFields;
  onSitePaymentDiscountType_var_samp_order_by: OnSitePaymentDiscountTypeVarSampOrderBy;
  onSitePaymentDiscountType_variance_fields: OnSitePaymentDiscountTypeVarianceFields;
  onSitePaymentDiscountType_variance_order_by: OnSitePaymentDiscountTypeVarianceOrderBy;
  onSitePaymentDiscount_aggregate_order_by: OnSitePaymentDiscountAggregateOrderBy;
  onSitePaymentDiscount_avg_order_by: OnSitePaymentDiscountAvgOrderBy;
  onSitePaymentDiscount_bool_exp: OnSitePaymentDiscountBoolExp;
  onSitePaymentDiscount_max_order_by: OnSitePaymentDiscountMaxOrderBy;
  onSitePaymentDiscount_min_order_by: OnSitePaymentDiscountMinOrderBy;
  onSitePaymentDiscount_order_by: OnSitePaymentDiscountOrderBy;
  onSitePaymentDiscount_stddev_order_by: OnSitePaymentDiscountStddevOrderBy;
  onSitePaymentDiscount_stddev_pop_order_by: OnSitePaymentDiscountStddevPopOrderBy;
  onSitePaymentDiscount_stddev_samp_order_by: OnSitePaymentDiscountStddevSampOrderBy;
  onSitePaymentDiscount_stream_cursor_input: OnSitePaymentDiscountStreamCursorInput;
  onSitePaymentDiscount_stream_cursor_value_input: OnSitePaymentDiscountStreamCursorValueInput;
  onSitePaymentDiscount_sum_order_by: OnSitePaymentDiscountSumOrderBy;
  onSitePaymentDiscount_var_pop_order_by: OnSitePaymentDiscountVarPopOrderBy;
  onSitePaymentDiscount_var_samp_order_by: OnSitePaymentDiscountVarSampOrderBy;
  onSitePaymentDiscount_variance_order_by: OnSitePaymentDiscountVarianceOrderBy;
  onSitePaymentSales: OnSitePaymentSales;
  onSitePaymentSales_aggregate_order_by: OnSitePaymentSalesAggregateOrderBy;
  onSitePaymentSales_avg_order_by: OnSitePaymentSalesAvgOrderBy;
  onSitePaymentSales_bool_exp: OnSitePaymentSalesBoolExp;
  onSitePaymentSales_max_order_by: OnSitePaymentSalesMaxOrderBy;
  onSitePaymentSales_min_order_by: OnSitePaymentSalesMinOrderBy;
  onSitePaymentSales_order_by: OnSitePaymentSalesOrderBy;
  onSitePaymentSales_stddev_order_by: OnSitePaymentSalesStddevOrderBy;
  onSitePaymentSales_stddev_pop_order_by: OnSitePaymentSalesStddevPopOrderBy;
  onSitePaymentSales_stddev_samp_order_by: OnSitePaymentSalesStddevSampOrderBy;
  onSitePaymentSales_stream_cursor_input: OnSitePaymentSalesStreamCursorInput;
  onSitePaymentSales_stream_cursor_value_input: OnSitePaymentSalesStreamCursorValueInput;
  onSitePaymentSales_sum_order_by: OnSitePaymentSalesSumOrderBy;
  onSitePaymentSales_var_pop_order_by: OnSitePaymentSalesVarPopOrderBy;
  onSitePaymentSales_var_samp_order_by: OnSitePaymentSalesVarSampOrderBy;
  onSitePaymentSales_variance_order_by: OnSitePaymentSalesVarianceOrderBy;
  onSitePaymentTableUsers: OnSitePaymentTableUsers;
  onSitePaymentTableUsers_aggregate_order_by: OnSitePaymentTableUsersAggregateOrderBy;
  onSitePaymentTableUsers_avg_order_by: OnSitePaymentTableUsersAvgOrderBy;
  onSitePaymentTableUsers_bool_exp: OnSitePaymentTableUsersBoolExp;
  onSitePaymentTableUsers_max_order_by: OnSitePaymentTableUsersMaxOrderBy;
  onSitePaymentTableUsers_min_order_by: OnSitePaymentTableUsersMinOrderBy;
  onSitePaymentTableUsers_order_by: OnSitePaymentTableUsersOrderBy;
  onSitePaymentTableUsers_stddev_order_by: OnSitePaymentTableUsersStddevOrderBy;
  onSitePaymentTableUsers_stddev_pop_order_by: OnSitePaymentTableUsersStddevPopOrderBy;
  onSitePaymentTableUsers_stddev_samp_order_by: OnSitePaymentTableUsersStddevSampOrderBy;
  onSitePaymentTableUsers_stream_cursor_input: OnSitePaymentTableUsersStreamCursorInput;
  onSitePaymentTableUsers_stream_cursor_value_input: OnSitePaymentTableUsersStreamCursorValueInput;
  onSitePaymentTableUsers_sum_order_by: OnSitePaymentTableUsersSumOrderBy;
  onSitePaymentTableUsers_var_pop_order_by: OnSitePaymentTableUsersVarPopOrderBy;
  onSitePaymentTableUsers_var_samp_order_by: OnSitePaymentTableUsersVarSampOrderBy;
  onSitePaymentTableUsers_variance_order_by: OnSitePaymentTableUsersVarianceOrderBy;
  onSitePayment_aggregate_order_by: OnSitePaymentAggregateOrderBy;
  onSitePayment_avg_order_by: OnSitePaymentAvgOrderBy;
  onSitePayment_bool_exp: OnSitePaymentBoolExp;
  onSitePayment_max_order_by: OnSitePaymentMaxOrderBy;
  onSitePayment_min_order_by: OnSitePaymentMinOrderBy;
  onSitePayment_order_by: OnSitePaymentOrderBy;
  onSitePayment_stddev_order_by: OnSitePaymentStddevOrderBy;
  onSitePayment_stddev_pop_order_by: OnSitePaymentStddevPopOrderBy;
  onSitePayment_stddev_samp_order_by: OnSitePaymentStddevSampOrderBy;
  onSitePayment_stream_cursor_input: OnSitePaymentStreamCursorInput;
  onSitePayment_stream_cursor_value_input: OnSitePaymentStreamCursorValueInput;
  onSitePayment_sum_order_by: OnSitePaymentSumOrderBy;
  onSitePayment_var_pop_order_by: OnSitePaymentVarPopOrderBy;
  onSitePayment_var_samp_order_by: OnSitePaymentVarSampOrderBy;
  onSitePayment_variance_order_by: OnSitePaymentVarianceOrderBy;
  onlinePaymentPrinterRoleMeta: OnlinePaymentPrinterRoleMeta;
  onlinePaymentPrinterRoleMeta_bool_exp: OnlinePaymentPrinterRoleMetaBoolExp;
  onlinePaymentPrinterRoleMeta_insert_input: OnlinePaymentPrinterRoleMetaInsertInput;
  onlinePaymentPrinterRoleMeta_mutation_response: OnlinePaymentPrinterRoleMetaMutationResponse;
  onlinePaymentPrinterRoleMeta_on_conflict: OnlinePaymentPrinterRoleMetaOnConflict;
  onlinePaymentPrinterRoleMeta_order_by: OnlinePaymentPrinterRoleMetaOrderBy;
  onlinePaymentPrinterRoleMeta_stream_cursor_input: OnlinePaymentPrinterRoleMetaStreamCursorInput;
  onlinePaymentPrinterRoleMeta_stream_cursor_value_input: OnlinePaymentPrinterRoleMetaStreamCursorValueInput;
  onlinePaymentRootMutation: OnlinePaymentRootMutation;
  onlinePaymentRootQuery: OnlinePaymentRootQuery;
  option: Option;
  optionInputType: OptionInputType;
  optionInputType_bool_exp: OptionInputTypeBoolExp;
  optionInputType_enum_comparison_exp: OptionInputTypeEnumComparisonExp;
  optionInputType_insert_input: OptionInputTypeInsertInput;
  optionInputType_mutation_response: OptionInputTypeMutationResponse;
  optionInputType_obj_rel_insert_input: OptionInputTypeObjRelInsertInput;
  optionInputType_on_conflict: OptionInputTypeOnConflict;
  optionInputType_order_by: OptionInputTypeOrderBy;
  optionInputType_pk_columns_input: OptionInputTypePkColumnsInput;
  optionInputType_set_input: OptionInputTypeSetInput;
  optionInputType_stream_cursor_input: OptionInputTypeStreamCursorInput;
  optionInputType_stream_cursor_value_input: OptionInputTypeStreamCursorValueInput;
  optionInputType_updates: OptionInputTypeUpdates;
  option_aggregate: OptionAggregate;
  option_aggregate_bool_exp: OptionAggregateBoolExp;
  option_aggregate_bool_exp_count: OptionAggregateBoolExpCount;
  option_aggregate_fields: OptionAggregateFields;
  option_aggregate_order_by: OptionAggregateOrderBy;
  option_arr_rel_insert_input: OptionArrRelInsertInput;
  option_avg_fields: OptionAvgFields;
  option_avg_order_by: OptionAvgOrderBy;
  option_bool_exp: OptionBoolExp;
  option_inc_input: OptionIncInput;
  option_insert_input: OptionInsertInput;
  option_max_fields: OptionMaxFields;
  option_max_order_by: OptionMaxOrderBy;
  option_min_fields: OptionMinFields;
  option_min_order_by: OptionMinOrderBy;
  option_mutation_response: OptionMutationResponse;
  option_obj_rel_insert_input: OptionObjRelInsertInput;
  option_on_conflict: OptionOnConflict;
  option_order_by: OptionOrderBy;
  option_pk_columns_input: OptionPkColumnsInput;
  option_set_input: OptionSetInput;
  option_stddev_fields: OptionStddevFields;
  option_stddev_order_by: OptionStddevOrderBy;
  option_stddev_pop_fields: OptionStddevPopFields;
  option_stddev_pop_order_by: OptionStddevPopOrderBy;
  option_stddev_samp_fields: OptionStddevSampFields;
  option_stddev_samp_order_by: OptionStddevSampOrderBy;
  option_stream_cursor_input: OptionStreamCursorInput;
  option_stream_cursor_value_input: OptionStreamCursorValueInput;
  option_sum_fields: OptionSumFields;
  option_sum_order_by: OptionSumOrderBy;
  option_updates: OptionUpdates;
  option_var_pop_fields: OptionVarPopFields;
  option_var_pop_order_by: OptionVarPopOrderBy;
  option_var_samp_fields: OptionVarSampFields;
  option_var_samp_order_by: OptionVarSampOrderBy;
  option_variance_fields: OptionVarianceFields;
  option_variance_order_by: OptionVarianceOrderBy;
  order: Order;
  order_aggregate_order_by: OrderAggregateOrderBy;
  order_bool_exp: OrderBoolExp;
  order_max_order_by: OrderMaxOrderBy;
  order_min_order_by: OrderMinOrderBy;
  order_order_by: OrderOrderBy;
  order_stream_cursor_input: OrderStreamCursorInput;
  order_stream_cursor_value_input: OrderStreamCursorValueInput;
  orderableTime: OrderableTime;
  orderableTimeTermDayWeek_enum_comparison_exp: OrderableTimeTermDayWeekEnumComparisonExp;
  orderableTime_aggregate_order_by: OrderableTimeAggregateOrderBy;
  orderableTime_arr_rel_insert_input: OrderableTimeArrRelInsertInput;
  orderableTime_bool_exp: OrderableTimeBoolExp;
  orderableTime_insert_input: OrderableTimeInsertInput;
  orderableTime_max_order_by: OrderableTimeMaxOrderBy;
  orderableTime_min_order_by: OrderableTimeMinOrderBy;
  orderableTime_mutation_response: OrderableTimeMutationResponse;
  orderableTime_obj_rel_insert_input: OrderableTimeObjRelInsertInput;
  orderableTime_on_conflict: OrderableTimeOnConflict;
  orderableTime_order_by: OrderableTimeOrderBy;
  orderableTime_pk_columns_input: OrderableTimePkColumnsInput;
  orderableTime_set_input: OrderableTimeSetInput;
  orderableTime_stream_cursor_input: OrderableTimeStreamCursorInput;
  orderableTime_stream_cursor_value_input: OrderableTimeStreamCursorValueInput;
  orderableTime_updates: OrderableTimeUpdates;
  payingActivePlanChoice: PayingActivePlanChoice;
  payingActivePlanChoice_aggregate_order_by: PayingActivePlanChoiceAggregateOrderBy;
  payingActivePlanChoice_avg_order_by: PayingActivePlanChoiceAvgOrderBy;
  payingActivePlanChoice_bool_exp: PayingActivePlanChoiceBoolExp;
  payingActivePlanChoice_max_order_by: PayingActivePlanChoiceMaxOrderBy;
  payingActivePlanChoice_min_order_by: PayingActivePlanChoiceMinOrderBy;
  payingActivePlanChoice_order_by: PayingActivePlanChoiceOrderBy;
  payingActivePlanChoice_stddev_order_by: PayingActivePlanChoiceStddevOrderBy;
  payingActivePlanChoice_stddev_pop_order_by: PayingActivePlanChoiceStddevPopOrderBy;
  payingActivePlanChoice_stddev_samp_order_by: PayingActivePlanChoiceStddevSampOrderBy;
  payingActivePlanChoice_stream_cursor_input: PayingActivePlanChoiceStreamCursorInput;
  payingActivePlanChoice_stream_cursor_value_input: PayingActivePlanChoiceStreamCursorValueInput;
  payingActivePlanChoice_sum_order_by: PayingActivePlanChoiceSumOrderBy;
  payingActivePlanChoice_var_pop_order_by: PayingActivePlanChoiceVarPopOrderBy;
  payingActivePlanChoice_var_samp_order_by: PayingActivePlanChoiceVarSampOrderBy;
  payingActivePlanChoice_variance_order_by: PayingActivePlanChoiceVarianceOrderBy;
  payingActivePlanOpenPriceMeta: PayingActivePlanOpenPriceMeta;
  payingActivePlanOpenPriceMeta_aggregate_order_by: PayingActivePlanOpenPriceMetaAggregateOrderBy;
  payingActivePlanOpenPriceMeta_avg_order_by: PayingActivePlanOpenPriceMetaAvgOrderBy;
  payingActivePlanOpenPriceMeta_bool_exp: PayingActivePlanOpenPriceMetaBoolExp;
  payingActivePlanOpenPriceMeta_max_order_by: PayingActivePlanOpenPriceMetaMaxOrderBy;
  payingActivePlanOpenPriceMeta_min_order_by: PayingActivePlanOpenPriceMetaMinOrderBy;
  payingActivePlanOpenPriceMeta_order_by: PayingActivePlanOpenPriceMetaOrderBy;
  payingActivePlanOpenPriceMeta_stddev_order_by: PayingActivePlanOpenPriceMetaStddevOrderBy;
  payingActivePlanOpenPriceMeta_stddev_pop_order_by: PayingActivePlanOpenPriceMetaStddevPopOrderBy;
  payingActivePlanOpenPriceMeta_stddev_samp_order_by: PayingActivePlanOpenPriceMetaStddevSampOrderBy;
  payingActivePlanOpenPriceMeta_stream_cursor_input: PayingActivePlanOpenPriceMetaStreamCursorInput;
  payingActivePlanOpenPriceMeta_stream_cursor_value_input: PayingActivePlanOpenPriceMetaStreamCursorValueInput;
  payingActivePlanOpenPriceMeta_sum_order_by: PayingActivePlanOpenPriceMetaSumOrderBy;
  payingActivePlanOpenPriceMeta_var_pop_order_by: PayingActivePlanOpenPriceMetaVarPopOrderBy;
  payingActivePlanOpenPriceMeta_var_samp_order_by: PayingActivePlanOpenPriceMetaVarSampOrderBy;
  payingActivePlanOpenPriceMeta_variance_order_by: PayingActivePlanOpenPriceMetaVarianceOrderBy;
  payingMenuOrderItem: PayingMenuOrderItem;
  payingMenuOrderItem_aggregate_order_by: PayingMenuOrderItemAggregateOrderBy;
  payingMenuOrderItem_avg_order_by: PayingMenuOrderItemAvgOrderBy;
  payingMenuOrderItem_bool_exp: PayingMenuOrderItemBoolExp;
  payingMenuOrderItem_max_order_by: PayingMenuOrderItemMaxOrderBy;
  payingMenuOrderItem_min_order_by: PayingMenuOrderItemMinOrderBy;
  payingMenuOrderItem_order_by: PayingMenuOrderItemOrderBy;
  payingMenuOrderItem_stddev_order_by: PayingMenuOrderItemStddevOrderBy;
  payingMenuOrderItem_stddev_pop_order_by: PayingMenuOrderItemStddevPopOrderBy;
  payingMenuOrderItem_stddev_samp_order_by: PayingMenuOrderItemStddevSampOrderBy;
  payingMenuOrderItem_stream_cursor_input: PayingMenuOrderItemStreamCursorInput;
  payingMenuOrderItem_stream_cursor_value_input: PayingMenuOrderItemStreamCursorValueInput;
  payingMenuOrderItem_sum_order_by: PayingMenuOrderItemSumOrderBy;
  payingMenuOrderItem_var_pop_order_by: PayingMenuOrderItemVarPopOrderBy;
  payingMenuOrderItem_var_samp_order_by: PayingMenuOrderItemVarSampOrderBy;
  payingMenuOrderItem_variance_order_by: PayingMenuOrderItemVarianceOrderBy;
  paymentReceiptRoleTable: PaymentReceiptRoleTable;
  paymentReceiptRoleTable_aggregate_order_by: PaymentReceiptRoleTableAggregateOrderBy;
  paymentReceiptRoleTable_arr_rel_insert_input: PaymentReceiptRoleTableArrRelInsertInput;
  paymentReceiptRoleTable_avg_order_by: PaymentReceiptRoleTableAvgOrderBy;
  paymentReceiptRoleTable_bool_exp: PaymentReceiptRoleTableBoolExp;
  paymentReceiptRoleTable_inc_input: PaymentReceiptRoleTableIncInput;
  paymentReceiptRoleTable_insert_input: PaymentReceiptRoleTableInsertInput;
  paymentReceiptRoleTable_max_order_by: PaymentReceiptRoleTableMaxOrderBy;
  paymentReceiptRoleTable_min_order_by: PaymentReceiptRoleTableMinOrderBy;
  paymentReceiptRoleTable_mutation_response: PaymentReceiptRoleTableMutationResponse;
  paymentReceiptRoleTable_on_conflict: PaymentReceiptRoleTableOnConflict;
  paymentReceiptRoleTable_order_by: PaymentReceiptRoleTableOrderBy;
  paymentReceiptRoleTable_set_input: PaymentReceiptRoleTableSetInput;
  paymentReceiptRoleTable_stddev_order_by: PaymentReceiptRoleTableStddevOrderBy;
  paymentReceiptRoleTable_stddev_pop_order_by: PaymentReceiptRoleTableStddevPopOrderBy;
  paymentReceiptRoleTable_stddev_samp_order_by: PaymentReceiptRoleTableStddevSampOrderBy;
  paymentReceiptRoleTable_stream_cursor_input: PaymentReceiptRoleTableStreamCursorInput;
  paymentReceiptRoleTable_stream_cursor_value_input: PaymentReceiptRoleTableStreamCursorValueInput;
  paymentReceiptRoleTable_sum_order_by: PaymentReceiptRoleTableSumOrderBy;
  paymentReceiptRoleTable_updates: PaymentReceiptRoleTableUpdates;
  paymentReceiptRoleTable_var_pop_order_by: PaymentReceiptRoleTableVarPopOrderBy;
  paymentReceiptRoleTable_var_samp_order_by: PaymentReceiptRoleTableVarSampOrderBy;
  paymentReceiptRoleTable_variance_order_by: PaymentReceiptRoleTableVarianceOrderBy;
  pikaichiConfig: PikaichiConfig;
  pikaichiConfig_bool_exp: PikaichiConfigBoolExp;
  pikaichiConfig_inc_input: PikaichiConfigIncInput;
  pikaichiConfig_insert_input: PikaichiConfigInsertInput;
  pikaichiConfig_mutation_response: PikaichiConfigMutationResponse;
  pikaichiConfig_obj_rel_insert_input: PikaichiConfigObjRelInsertInput;
  pikaichiConfig_on_conflict: PikaichiConfigOnConflict;
  pikaichiConfig_order_by: PikaichiConfigOrderBy;
  pikaichiConfig_pk_columns_input: PikaichiConfigPkColumnsInput;
  pikaichiConfig_set_input: PikaichiConfigSetInput;
  pikaichiConfig_stream_cursor_input: PikaichiConfigStreamCursorInput;
  pikaichiConfig_stream_cursor_value_input: PikaichiConfigStreamCursorValueInput;
  pikaichiConfig_updates: PikaichiConfigUpdates;
  pikaichiMenu: PikaichiMenu;
  pikaichiMenuChoice: PikaichiMenuChoice;
  pikaichiMenuChoice_aggregate_order_by: PikaichiMenuChoiceAggregateOrderBy;
  pikaichiMenuChoice_arr_rel_insert_input: PikaichiMenuChoiceArrRelInsertInput;
  pikaichiMenuChoice_avg_order_by: PikaichiMenuChoiceAvgOrderBy;
  pikaichiMenuChoice_bool_exp: PikaichiMenuChoiceBoolExp;
  pikaichiMenuChoice_inc_input: PikaichiMenuChoiceIncInput;
  pikaichiMenuChoice_insert_input: PikaichiMenuChoiceInsertInput;
  pikaichiMenuChoice_max_order_by: PikaichiMenuChoiceMaxOrderBy;
  pikaichiMenuChoice_min_order_by: PikaichiMenuChoiceMinOrderBy;
  pikaichiMenuChoice_mutation_response: PikaichiMenuChoiceMutationResponse;
  pikaichiMenuChoice_on_conflict: PikaichiMenuChoiceOnConflict;
  pikaichiMenuChoice_order_by: PikaichiMenuChoiceOrderBy;
  pikaichiMenuChoice_pk_columns_input: PikaichiMenuChoicePkColumnsInput;
  pikaichiMenuChoice_set_input: PikaichiMenuChoiceSetInput;
  pikaichiMenuChoice_stddev_order_by: PikaichiMenuChoiceStddevOrderBy;
  pikaichiMenuChoice_stddev_pop_order_by: PikaichiMenuChoiceStddevPopOrderBy;
  pikaichiMenuChoice_stddev_samp_order_by: PikaichiMenuChoiceStddevSampOrderBy;
  pikaichiMenuChoice_stream_cursor_input: PikaichiMenuChoiceStreamCursorInput;
  pikaichiMenuChoice_stream_cursor_value_input: PikaichiMenuChoiceStreamCursorValueInput;
  pikaichiMenuChoice_sum_order_by: PikaichiMenuChoiceSumOrderBy;
  pikaichiMenuChoice_updates: PikaichiMenuChoiceUpdates;
  pikaichiMenuChoice_var_pop_order_by: PikaichiMenuChoiceVarPopOrderBy;
  pikaichiMenuChoice_var_samp_order_by: PikaichiMenuChoiceVarSampOrderBy;
  pikaichiMenuChoice_variance_order_by: PikaichiMenuChoiceVarianceOrderBy;
  pikaichiMenuMenu: PikaichiMenuMenu;
  pikaichiMenuMenu_aggregate_order_by: PikaichiMenuMenuAggregateOrderBy;
  pikaichiMenuMenu_arr_rel_insert_input: PikaichiMenuMenuArrRelInsertInput;
  pikaichiMenuMenu_avg_order_by: PikaichiMenuMenuAvgOrderBy;
  pikaichiMenuMenu_bool_exp: PikaichiMenuMenuBoolExp;
  pikaichiMenuMenu_inc_input: PikaichiMenuMenuIncInput;
  pikaichiMenuMenu_insert_input: PikaichiMenuMenuInsertInput;
  pikaichiMenuMenu_max_order_by: PikaichiMenuMenuMaxOrderBy;
  pikaichiMenuMenu_min_order_by: PikaichiMenuMenuMinOrderBy;
  pikaichiMenuMenu_mutation_response: PikaichiMenuMenuMutationResponse;
  pikaichiMenuMenu_on_conflict: PikaichiMenuMenuOnConflict;
  pikaichiMenuMenu_order_by: PikaichiMenuMenuOrderBy;
  pikaichiMenuMenu_pk_columns_input: PikaichiMenuMenuPkColumnsInput;
  pikaichiMenuMenu_set_input: PikaichiMenuMenuSetInput;
  pikaichiMenuMenu_stddev_order_by: PikaichiMenuMenuStddevOrderBy;
  pikaichiMenuMenu_stddev_pop_order_by: PikaichiMenuMenuStddevPopOrderBy;
  pikaichiMenuMenu_stddev_samp_order_by: PikaichiMenuMenuStddevSampOrderBy;
  pikaichiMenuMenu_stream_cursor_input: PikaichiMenuMenuStreamCursorInput;
  pikaichiMenuMenu_stream_cursor_value_input: PikaichiMenuMenuStreamCursorValueInput;
  pikaichiMenuMenu_sum_order_by: PikaichiMenuMenuSumOrderBy;
  pikaichiMenuMenu_updates: PikaichiMenuMenuUpdates;
  pikaichiMenuMenu_var_pop_order_by: PikaichiMenuMenuVarPopOrderBy;
  pikaichiMenuMenu_var_samp_order_by: PikaichiMenuMenuVarSampOrderBy;
  pikaichiMenuMenu_variance_order_by: PikaichiMenuMenuVarianceOrderBy;
  pikaichiMenuPlan: PikaichiMenuPlan;
  pikaichiMenuPlanChoice: PikaichiMenuPlanChoice;
  pikaichiMenuPlanChoice_aggregate_order_by: PikaichiMenuPlanChoiceAggregateOrderBy;
  pikaichiMenuPlanChoice_arr_rel_insert_input: PikaichiMenuPlanChoiceArrRelInsertInput;
  pikaichiMenuPlanChoice_avg_order_by: PikaichiMenuPlanChoiceAvgOrderBy;
  pikaichiMenuPlanChoice_bool_exp: PikaichiMenuPlanChoiceBoolExp;
  pikaichiMenuPlanChoice_inc_input: PikaichiMenuPlanChoiceIncInput;
  pikaichiMenuPlanChoice_insert_input: PikaichiMenuPlanChoiceInsertInput;
  pikaichiMenuPlanChoice_max_order_by: PikaichiMenuPlanChoiceMaxOrderBy;
  pikaichiMenuPlanChoice_min_order_by: PikaichiMenuPlanChoiceMinOrderBy;
  pikaichiMenuPlanChoice_mutation_response: PikaichiMenuPlanChoiceMutationResponse;
  pikaichiMenuPlanChoice_on_conflict: PikaichiMenuPlanChoiceOnConflict;
  pikaichiMenuPlanChoice_order_by: PikaichiMenuPlanChoiceOrderBy;
  pikaichiMenuPlanChoice_pk_columns_input: PikaichiMenuPlanChoicePkColumnsInput;
  pikaichiMenuPlanChoice_set_input: PikaichiMenuPlanChoiceSetInput;
  pikaichiMenuPlanChoice_stddev_order_by: PikaichiMenuPlanChoiceStddevOrderBy;
  pikaichiMenuPlanChoice_stddev_pop_order_by: PikaichiMenuPlanChoiceStddevPopOrderBy;
  pikaichiMenuPlanChoice_stddev_samp_order_by: PikaichiMenuPlanChoiceStddevSampOrderBy;
  pikaichiMenuPlanChoice_stream_cursor_input: PikaichiMenuPlanChoiceStreamCursorInput;
  pikaichiMenuPlanChoice_stream_cursor_value_input: PikaichiMenuPlanChoiceStreamCursorValueInput;
  pikaichiMenuPlanChoice_sum_order_by: PikaichiMenuPlanChoiceSumOrderBy;
  pikaichiMenuPlanChoice_updates: PikaichiMenuPlanChoiceUpdates;
  pikaichiMenuPlanChoice_var_pop_order_by: PikaichiMenuPlanChoiceVarPopOrderBy;
  pikaichiMenuPlanChoice_var_samp_order_by: PikaichiMenuPlanChoiceVarSampOrderBy;
  pikaichiMenuPlanChoice_variance_order_by: PikaichiMenuPlanChoiceVarianceOrderBy;
  pikaichiMenuPlan_aggregate_order_by: PikaichiMenuPlanAggregateOrderBy;
  pikaichiMenuPlan_arr_rel_insert_input: PikaichiMenuPlanArrRelInsertInput;
  pikaichiMenuPlan_avg_order_by: PikaichiMenuPlanAvgOrderBy;
  pikaichiMenuPlan_bool_exp: PikaichiMenuPlanBoolExp;
  pikaichiMenuPlan_inc_input: PikaichiMenuPlanIncInput;
  pikaichiMenuPlan_insert_input: PikaichiMenuPlanInsertInput;
  pikaichiMenuPlan_max_order_by: PikaichiMenuPlanMaxOrderBy;
  pikaichiMenuPlan_min_order_by: PikaichiMenuPlanMinOrderBy;
  pikaichiMenuPlan_mutation_response: PikaichiMenuPlanMutationResponse;
  pikaichiMenuPlan_on_conflict: PikaichiMenuPlanOnConflict;
  pikaichiMenuPlan_order_by: PikaichiMenuPlanOrderBy;
  pikaichiMenuPlan_pk_columns_input: PikaichiMenuPlanPkColumnsInput;
  pikaichiMenuPlan_set_input: PikaichiMenuPlanSetInput;
  pikaichiMenuPlan_stddev_order_by: PikaichiMenuPlanStddevOrderBy;
  pikaichiMenuPlan_stddev_pop_order_by: PikaichiMenuPlanStddevPopOrderBy;
  pikaichiMenuPlan_stddev_samp_order_by: PikaichiMenuPlanStddevSampOrderBy;
  pikaichiMenuPlan_stream_cursor_input: PikaichiMenuPlanStreamCursorInput;
  pikaichiMenuPlan_stream_cursor_value_input: PikaichiMenuPlanStreamCursorValueInput;
  pikaichiMenuPlan_sum_order_by: PikaichiMenuPlanSumOrderBy;
  pikaichiMenuPlan_updates: PikaichiMenuPlanUpdates;
  pikaichiMenuPlan_var_pop_order_by: PikaichiMenuPlanVarPopOrderBy;
  pikaichiMenuPlan_var_samp_order_by: PikaichiMenuPlanVarSampOrderBy;
  pikaichiMenuPlan_variance_order_by: PikaichiMenuPlanVarianceOrderBy;
  pikaichiMenu_aggregate_order_by: PikaichiMenuAggregateOrderBy;
  pikaichiMenu_arr_rel_insert_input: PikaichiMenuArrRelInsertInput;
  pikaichiMenu_bool_exp: PikaichiMenuBoolExp;
  pikaichiMenu_insert_input: PikaichiMenuInsertInput;
  pikaichiMenu_max_order_by: PikaichiMenuMaxOrderBy;
  pikaichiMenu_min_order_by: PikaichiMenuMinOrderBy;
  pikaichiMenu_mutation_response: PikaichiMenuMutationResponse;
  pikaichiMenu_obj_rel_insert_input: PikaichiMenuObjRelInsertInput;
  pikaichiMenu_on_conflict: PikaichiMenuOnConflict;
  pikaichiMenu_order_by: PikaichiMenuOrderBy;
  pikaichiMenu_pk_columns_input: PikaichiMenuPkColumnsInput;
  pikaichiMenu_set_input: PikaichiMenuSetInput;
  pikaichiMenu_stream_cursor_input: PikaichiMenuStreamCursorInput;
  pikaichiMenu_stream_cursor_value_input: PikaichiMenuStreamCursorValueInput;
  pikaichiMenu_updates: PikaichiMenuUpdates;
  pikaichiOnSitePaymentDetailType: PikaichiOnSitePaymentDetailType;
  pikaichiOnSitePaymentDetailType_aggregate_order_by: PikaichiOnSitePaymentDetailTypeAggregateOrderBy;
  pikaichiOnSitePaymentDetailType_arr_rel_insert_input: PikaichiOnSitePaymentDetailTypeArrRelInsertInput;
  pikaichiOnSitePaymentDetailType_bool_exp: PikaichiOnSitePaymentDetailTypeBoolExp;
  pikaichiOnSitePaymentDetailType_insert_input: PikaichiOnSitePaymentDetailTypeInsertInput;
  pikaichiOnSitePaymentDetailType_max_order_by: PikaichiOnSitePaymentDetailTypeMaxOrderBy;
  pikaichiOnSitePaymentDetailType_min_order_by: PikaichiOnSitePaymentDetailTypeMinOrderBy;
  pikaichiOnSitePaymentDetailType_mutation_response: PikaichiOnSitePaymentDetailTypeMutationResponse;
  pikaichiOnSitePaymentDetailType_on_conflict: PikaichiOnSitePaymentDetailTypeOnConflict;
  pikaichiOnSitePaymentDetailType_order_by: PikaichiOnSitePaymentDetailTypeOrderBy;
  pikaichiOnSitePaymentDetailType_pk_columns_input: PikaichiOnSitePaymentDetailTypePkColumnsInput;
  pikaichiOnSitePaymentDetailType_set_input: PikaichiOnSitePaymentDetailTypeSetInput;
  pikaichiOnSitePaymentDetailType_stream_cursor_input: PikaichiOnSitePaymentDetailTypeStreamCursorInput;
  pikaichiOnSitePaymentDetailType_stream_cursor_value_input: PikaichiOnSitePaymentDetailTypeStreamCursorValueInput;
  pikaichiOnSitePaymentDetailType_updates: PikaichiOnSitePaymentDetailTypeUpdates;
  pikaichiOnSitePaymentDiscountType: PikaichiOnSitePaymentDiscountType;
  pikaichiOnSitePaymentDiscountType_aggregate_order_by: PikaichiOnSitePaymentDiscountTypeAggregateOrderBy;
  pikaichiOnSitePaymentDiscountType_arr_rel_insert_input: PikaichiOnSitePaymentDiscountTypeArrRelInsertInput;
  pikaichiOnSitePaymentDiscountType_avg_order_by: PikaichiOnSitePaymentDiscountTypeAvgOrderBy;
  pikaichiOnSitePaymentDiscountType_bool_exp: PikaichiOnSitePaymentDiscountTypeBoolExp;
  pikaichiOnSitePaymentDiscountType_inc_input: PikaichiOnSitePaymentDiscountTypeIncInput;
  pikaichiOnSitePaymentDiscountType_insert_input: PikaichiOnSitePaymentDiscountTypeInsertInput;
  pikaichiOnSitePaymentDiscountType_max_order_by: PikaichiOnSitePaymentDiscountTypeMaxOrderBy;
  pikaichiOnSitePaymentDiscountType_min_order_by: PikaichiOnSitePaymentDiscountTypeMinOrderBy;
  pikaichiOnSitePaymentDiscountType_mutation_response: PikaichiOnSitePaymentDiscountTypeMutationResponse;
  pikaichiOnSitePaymentDiscountType_on_conflict: PikaichiOnSitePaymentDiscountTypeOnConflict;
  pikaichiOnSitePaymentDiscountType_order_by: PikaichiOnSitePaymentDiscountTypeOrderBy;
  pikaichiOnSitePaymentDiscountType_pk_columns_input: PikaichiOnSitePaymentDiscountTypePkColumnsInput;
  pikaichiOnSitePaymentDiscountType_set_input: PikaichiOnSitePaymentDiscountTypeSetInput;
  pikaichiOnSitePaymentDiscountType_stddev_order_by: PikaichiOnSitePaymentDiscountTypeStddevOrderBy;
  pikaichiOnSitePaymentDiscountType_stddev_pop_order_by: PikaichiOnSitePaymentDiscountTypeStddevPopOrderBy;
  pikaichiOnSitePaymentDiscountType_stddev_samp_order_by: PikaichiOnSitePaymentDiscountTypeStddevSampOrderBy;
  pikaichiOnSitePaymentDiscountType_stream_cursor_input: PikaichiOnSitePaymentDiscountTypeStreamCursorInput;
  pikaichiOnSitePaymentDiscountType_stream_cursor_value_input: PikaichiOnSitePaymentDiscountTypeStreamCursorValueInput;
  pikaichiOnSitePaymentDiscountType_sum_order_by: PikaichiOnSitePaymentDiscountTypeSumOrderBy;
  pikaichiOnSitePaymentDiscountType_updates: PikaichiOnSitePaymentDiscountTypeUpdates;
  pikaichiOnSitePaymentDiscountType_var_pop_order_by: PikaichiOnSitePaymentDiscountTypeVarPopOrderBy;
  pikaichiOnSitePaymentDiscountType_var_samp_order_by: PikaichiOnSitePaymentDiscountTypeVarSampOrderBy;
  pikaichiOnSitePaymentDiscountType_variance_order_by: PikaichiOnSitePaymentDiscountTypeVarianceOrderBy;
  plan: Plan;
  planChoice: PlanChoice;
  planChoice_aggregate: PlanChoiceAggregate;
  planChoice_aggregate_bool_exp: PlanChoiceAggregateBoolExp;
  planChoice_aggregate_bool_exp_bool_and: PlanChoiceAggregateBoolExpBoolAnd;
  planChoice_aggregate_bool_exp_bool_or: PlanChoiceAggregateBoolExpBoolOr;
  planChoice_aggregate_bool_exp_count: PlanChoiceAggregateBoolExpCount;
  planChoice_aggregate_fields: PlanChoiceAggregateFields;
  planChoice_aggregate_order_by: PlanChoiceAggregateOrderBy;
  planChoice_arr_rel_insert_input: PlanChoiceArrRelInsertInput;
  planChoice_avg_fields: PlanChoiceAvgFields;
  planChoice_avg_order_by: PlanChoiceAvgOrderBy;
  planChoice_bool_exp: PlanChoiceBoolExp;
  planChoice_inc_input: PlanChoiceIncInput;
  planChoice_insert_input: PlanChoiceInsertInput;
  planChoice_max_fields: PlanChoiceMaxFields;
  planChoice_max_order_by: PlanChoiceMaxOrderBy;
  planChoice_min_fields: PlanChoiceMinFields;
  planChoice_min_order_by: PlanChoiceMinOrderBy;
  planChoice_mutation_response: PlanChoiceMutationResponse;
  planChoice_obj_rel_insert_input: PlanChoiceObjRelInsertInput;
  planChoice_on_conflict: PlanChoiceOnConflict;
  planChoice_order_by: PlanChoiceOrderBy;
  planChoice_pk_columns_input: PlanChoicePkColumnsInput;
  planChoice_set_input: PlanChoiceSetInput;
  planChoice_stddev_fields: PlanChoiceStddevFields;
  planChoice_stddev_order_by: PlanChoiceStddevOrderBy;
  planChoice_stddev_pop_fields: PlanChoiceStddevPopFields;
  planChoice_stddev_pop_order_by: PlanChoiceStddevPopOrderBy;
  planChoice_stddev_samp_fields: PlanChoiceStddevSampFields;
  planChoice_stddev_samp_order_by: PlanChoiceStddevSampOrderBy;
  planChoice_stream_cursor_input: PlanChoiceStreamCursorInput;
  planChoice_stream_cursor_value_input: PlanChoiceStreamCursorValueInput;
  planChoice_sum_fields: PlanChoiceSumFields;
  planChoice_sum_order_by: PlanChoiceSumOrderBy;
  planChoice_updates: PlanChoiceUpdates;
  planChoice_var_pop_fields: PlanChoiceVarPopFields;
  planChoice_var_pop_order_by: PlanChoiceVarPopOrderBy;
  planChoice_var_samp_fields: PlanChoiceVarSampFields;
  planChoice_var_samp_order_by: PlanChoiceVarSampOrderBy;
  planChoice_variance_fields: PlanChoiceVarianceFields;
  planChoice_variance_order_by: PlanChoiceVarianceOrderBy;
  planFirstOrderCategoryMenu: PlanFirstOrderCategoryMenu;
  planFirstOrderCategoryMenu_aggregate_order_by: PlanFirstOrderCategoryMenuAggregateOrderBy;
  planFirstOrderCategoryMenu_arr_rel_insert_input: PlanFirstOrderCategoryMenuArrRelInsertInput;
  planFirstOrderCategoryMenu_avg_order_by: PlanFirstOrderCategoryMenuAvgOrderBy;
  planFirstOrderCategoryMenu_bool_exp: PlanFirstOrderCategoryMenuBoolExp;
  planFirstOrderCategoryMenu_inc_input: PlanFirstOrderCategoryMenuIncInput;
  planFirstOrderCategoryMenu_insert_input: PlanFirstOrderCategoryMenuInsertInput;
  planFirstOrderCategoryMenu_max_order_by: PlanFirstOrderCategoryMenuMaxOrderBy;
  planFirstOrderCategoryMenu_min_order_by: PlanFirstOrderCategoryMenuMinOrderBy;
  planFirstOrderCategoryMenu_mutation_response: PlanFirstOrderCategoryMenuMutationResponse;
  planFirstOrderCategoryMenu_on_conflict: PlanFirstOrderCategoryMenuOnConflict;
  planFirstOrderCategoryMenu_order_by: PlanFirstOrderCategoryMenuOrderBy;
  planFirstOrderCategoryMenu_pk_columns_input: PlanFirstOrderCategoryMenuPkColumnsInput;
  planFirstOrderCategoryMenu_set_input: PlanFirstOrderCategoryMenuSetInput;
  planFirstOrderCategoryMenu_stddev_order_by: PlanFirstOrderCategoryMenuStddevOrderBy;
  planFirstOrderCategoryMenu_stddev_pop_order_by: PlanFirstOrderCategoryMenuStddevPopOrderBy;
  planFirstOrderCategoryMenu_stddev_samp_order_by: PlanFirstOrderCategoryMenuStddevSampOrderBy;
  planFirstOrderCategoryMenu_stream_cursor_input: PlanFirstOrderCategoryMenuStreamCursorInput;
  planFirstOrderCategoryMenu_stream_cursor_value_input: PlanFirstOrderCategoryMenuStreamCursorValueInput;
  planFirstOrderCategoryMenu_sum_order_by: PlanFirstOrderCategoryMenuSumOrderBy;
  planFirstOrderCategoryMenu_updates: PlanFirstOrderCategoryMenuUpdates;
  planFirstOrderCategoryMenu_var_pop_order_by: PlanFirstOrderCategoryMenuVarPopOrderBy;
  planFirstOrderCategoryMenu_var_samp_order_by: PlanFirstOrderCategoryMenuVarSampOrderBy;
  planFirstOrderCategoryMenu_variance_order_by: PlanFirstOrderCategoryMenuVarianceOrderBy;
  planGroup: PlanGroup;
  planGroup_aggregate_order_by: PlanGroupAggregateOrderBy;
  planGroup_arr_rel_insert_input: PlanGroupArrRelInsertInput;
  planGroup_avg_order_by: PlanGroupAvgOrderBy;
  planGroup_bool_exp: PlanGroupBoolExp;
  planGroup_inc_input: PlanGroupIncInput;
  planGroup_insert_input: PlanGroupInsertInput;
  planGroup_max_order_by: PlanGroupMaxOrderBy;
  planGroup_min_order_by: PlanGroupMinOrderBy;
  planGroup_mutation_response: PlanGroupMutationResponse;
  planGroup_obj_rel_insert_input: PlanGroupObjRelInsertInput;
  planGroup_on_conflict: PlanGroupOnConflict;
  planGroup_order_by: PlanGroupOrderBy;
  planGroup_pk_columns_input: PlanGroupPkColumnsInput;
  planGroup_set_input: PlanGroupSetInput;
  planGroup_stddev_order_by: PlanGroupStddevOrderBy;
  planGroup_stddev_pop_order_by: PlanGroupStddevPopOrderBy;
  planGroup_stddev_samp_order_by: PlanGroupStddevSampOrderBy;
  planGroup_stream_cursor_input: PlanGroupStreamCursorInput;
  planGroup_stream_cursor_value_input: PlanGroupStreamCursorValueInput;
  planGroup_sum_order_by: PlanGroupSumOrderBy;
  planGroup_updates: PlanGroupUpdates;
  planGroup_var_pop_order_by: PlanGroupVarPopOrderBy;
  planGroup_var_samp_order_by: PlanGroupVarSampOrderBy;
  planGroup_variance_order_by: PlanGroupVarianceOrderBy;
  planMenuCategory: PlanMenuCategory;
  planMenuCategory_aggregate_order_by: PlanMenuCategoryAggregateOrderBy;
  planMenuCategory_arr_rel_insert_input: PlanMenuCategoryArrRelInsertInput;
  planMenuCategory_avg_order_by: PlanMenuCategoryAvgOrderBy;
  planMenuCategory_bool_exp: PlanMenuCategoryBoolExp;
  planMenuCategory_inc_input: PlanMenuCategoryIncInput;
  planMenuCategory_insert_input: PlanMenuCategoryInsertInput;
  planMenuCategory_max_order_by: PlanMenuCategoryMaxOrderBy;
  planMenuCategory_min_order_by: PlanMenuCategoryMinOrderBy;
  planMenuCategory_mutation_response: PlanMenuCategoryMutationResponse;
  planMenuCategory_on_conflict: PlanMenuCategoryOnConflict;
  planMenuCategory_order_by: PlanMenuCategoryOrderBy;
  planMenuCategory_pk_columns_input: PlanMenuCategoryPkColumnsInput;
  planMenuCategory_set_input: PlanMenuCategorySetInput;
  planMenuCategory_stddev_order_by: PlanMenuCategoryStddevOrderBy;
  planMenuCategory_stddev_pop_order_by: PlanMenuCategoryStddevPopOrderBy;
  planMenuCategory_stddev_samp_order_by: PlanMenuCategoryStddevSampOrderBy;
  planMenuCategory_stream_cursor_input: PlanMenuCategoryStreamCursorInput;
  planMenuCategory_stream_cursor_value_input: PlanMenuCategoryStreamCursorValueInput;
  planMenuCategory_sum_order_by: PlanMenuCategorySumOrderBy;
  planMenuCategory_updates: PlanMenuCategoryUpdates;
  planMenuCategory_var_pop_order_by: PlanMenuCategoryVarPopOrderBy;
  planMenuCategory_var_samp_order_by: PlanMenuCategoryVarSampOrderBy;
  planMenuCategory_variance_order_by: PlanMenuCategoryVarianceOrderBy;
  planOption: PlanOption;
  planOption_aggregate: PlanOptionAggregate;
  planOption_aggregate_bool_exp: PlanOptionAggregateBoolExp;
  planOption_aggregate_bool_exp_bool_and: PlanOptionAggregateBoolExpBoolAnd;
  planOption_aggregate_bool_exp_bool_or: PlanOptionAggregateBoolExpBoolOr;
  planOption_aggregate_bool_exp_count: PlanOptionAggregateBoolExpCount;
  planOption_aggregate_fields: PlanOptionAggregateFields;
  planOption_aggregate_order_by: PlanOptionAggregateOrderBy;
  planOption_arr_rel_insert_input: PlanOptionArrRelInsertInput;
  planOption_avg_fields: PlanOptionAvgFields;
  planOption_avg_order_by: PlanOptionAvgOrderBy;
  planOption_bool_exp: PlanOptionBoolExp;
  planOption_inc_input: PlanOptionIncInput;
  planOption_insert_input: PlanOptionInsertInput;
  planOption_max_fields: PlanOptionMaxFields;
  planOption_max_order_by: PlanOptionMaxOrderBy;
  planOption_min_fields: PlanOptionMinFields;
  planOption_min_order_by: PlanOptionMinOrderBy;
  planOption_mutation_response: PlanOptionMutationResponse;
  planOption_obj_rel_insert_input: PlanOptionObjRelInsertInput;
  planOption_on_conflict: PlanOptionOnConflict;
  planOption_order_by: PlanOptionOrderBy;
  planOption_pk_columns_input: PlanOptionPkColumnsInput;
  planOption_set_input: PlanOptionSetInput;
  planOption_stddev_fields: PlanOptionStddevFields;
  planOption_stddev_order_by: PlanOptionStddevOrderBy;
  planOption_stddev_pop_fields: PlanOptionStddevPopFields;
  planOption_stddev_pop_order_by: PlanOptionStddevPopOrderBy;
  planOption_stddev_samp_fields: PlanOptionStddevSampFields;
  planOption_stddev_samp_order_by: PlanOptionStddevSampOrderBy;
  planOption_stream_cursor_input: PlanOptionStreamCursorInput;
  planOption_stream_cursor_value_input: PlanOptionStreamCursorValueInput;
  planOption_sum_fields: PlanOptionSumFields;
  planOption_sum_order_by: PlanOptionSumOrderBy;
  planOption_updates: PlanOptionUpdates;
  planOption_var_pop_fields: PlanOptionVarPopFields;
  planOption_var_pop_order_by: PlanOptionVarPopOrderBy;
  planOption_var_samp_fields: PlanOptionVarSampFields;
  planOption_var_samp_order_by: PlanOptionVarSampOrderBy;
  planOption_variance_fields: PlanOptionVarianceFields;
  planOption_variance_order_by: PlanOptionVarianceOrderBy;
  plan_aggregate_order_by: PlanAggregateOrderBy;
  plan_arr_rel_insert_input: PlanArrRelInsertInput;
  plan_avg_order_by: PlanAvgOrderBy;
  plan_bool_exp: PlanBoolExp;
  plan_inc_input: PlanIncInput;
  plan_insert_input: PlanInsertInput;
  plan_max_order_by: PlanMaxOrderBy;
  plan_menu_categories_category: PlanMenuCategoriesCategory;
  plan_menu_categories_category_aggregate_order_by: PlanMenuCategoriesCategoryAggregateOrderBy;
  plan_menu_categories_category_arr_rel_insert_input: PlanMenuCategoriesCategoryArrRelInsertInput;
  plan_menu_categories_category_avg_order_by: PlanMenuCategoriesCategoryAvgOrderBy;
  plan_menu_categories_category_bool_exp: PlanMenuCategoriesCategoryBoolExp;
  plan_menu_categories_category_inc_input: PlanMenuCategoriesCategoryIncInput;
  plan_menu_categories_category_insert_input: PlanMenuCategoriesCategoryInsertInput;
  plan_menu_categories_category_max_order_by: PlanMenuCategoriesCategoryMaxOrderBy;
  plan_menu_categories_category_min_order_by: PlanMenuCategoriesCategoryMinOrderBy;
  plan_menu_categories_category_mutation_response: PlanMenuCategoriesCategoryMutationResponse;
  plan_menu_categories_category_order_by: PlanMenuCategoriesCategoryOrderBy;
  plan_menu_categories_category_set_input: PlanMenuCategoriesCategorySetInput;
  plan_menu_categories_category_stddev_order_by: PlanMenuCategoriesCategoryStddevOrderBy;
  plan_menu_categories_category_stddev_pop_order_by: PlanMenuCategoriesCategoryStddevPopOrderBy;
  plan_menu_categories_category_stddev_samp_order_by: PlanMenuCategoriesCategoryStddevSampOrderBy;
  plan_menu_categories_category_stream_cursor_input: PlanMenuCategoriesCategoryStreamCursorInput;
  plan_menu_categories_category_stream_cursor_value_input: PlanMenuCategoriesCategoryStreamCursorValueInput;
  plan_menu_categories_category_sum_order_by: PlanMenuCategoriesCategorySumOrderBy;
  plan_menu_categories_category_updates: PlanMenuCategoriesCategoryUpdates;
  plan_menu_categories_category_var_pop_order_by: PlanMenuCategoriesCategoryVarPopOrderBy;
  plan_menu_categories_category_var_samp_order_by: PlanMenuCategoriesCategoryVarSampOrderBy;
  plan_menu_categories_category_variance_order_by: PlanMenuCategoriesCategoryVarianceOrderBy;
  plan_min_order_by: PlanMinOrderBy;
  plan_mutation_response: PlanMutationResponse;
  plan_obj_rel_insert_input: PlanObjRelInsertInput;
  plan_on_conflict: PlanOnConflict;
  plan_order_by: PlanOrderBy;
  plan_pk_columns_input: PlanPkColumnsInput;
  plan_set_input: PlanSetInput;
  plan_stddev_order_by: PlanStddevOrderBy;
  plan_stddev_pop_order_by: PlanStddevPopOrderBy;
  plan_stddev_samp_order_by: PlanStddevSampOrderBy;
  plan_stream_cursor_input: PlanStreamCursorInput;
  plan_stream_cursor_value_input: PlanStreamCursorValueInput;
  plan_sum_order_by: PlanSumOrderBy;
  plan_updates: PlanUpdates;
  plan_var_pop_order_by: PlanVarPopOrderBy;
  plan_var_samp_order_by: PlanVarSampOrderBy;
  plan_variance_order_by: PlanVarianceOrderBy;
  query_root: {};
  question: Question;
  questionAnswer: QuestionAnswer;
  questionAnswer_aggregate_order_by: QuestionAnswerAggregateOrderBy;
  questionAnswer_bool_exp: QuestionAnswerBoolExp;
  questionAnswer_max_order_by: QuestionAnswerMaxOrderBy;
  questionAnswer_min_order_by: QuestionAnswerMinOrderBy;
  questionAnswer_order_by: QuestionAnswerOrderBy;
  questionAnswer_stream_cursor_input: QuestionAnswerStreamCursorInput;
  questionAnswer_stream_cursor_value_input: QuestionAnswerStreamCursorValueInput;
  questionCategory_enum_comparison_exp: QuestionCategoryEnumComparisonExp;
  questionType_enum_comparison_exp: QuestionTypeEnumComparisonExp;
  question_aggregate_order_by: QuestionAggregateOrderBy;
  question_bool_exp: QuestionBoolExp;
  question_max_order_by: QuestionMaxOrderBy;
  question_min_order_by: QuestionMinOrderBy;
  question_order_by: QuestionOrderBy;
  question_stream_cursor_input: QuestionStreamCursorInput;
  question_stream_cursor_value_input: QuestionStreamCursorValueInput;
  questionnaire: Questionnaire;
  questionnaireAnswer: QuestionnaireAnswer;
  questionnaireAnswer_aggregate_order_by: QuestionnaireAnswerAggregateOrderBy;
  questionnaireAnswer_bool_exp: QuestionnaireAnswerBoolExp;
  questionnaireAnswer_max_order_by: QuestionnaireAnswerMaxOrderBy;
  questionnaireAnswer_min_order_by: QuestionnaireAnswerMinOrderBy;
  questionnaireAnswer_order_by: QuestionnaireAnswerOrderBy;
  questionnaireAnswer_stream_cursor_input: QuestionnaireAnswerStreamCursorInput;
  questionnaireAnswer_stream_cursor_value_input: QuestionnaireAnswerStreamCursorValueInput;
  questionnaireConfig: QuestionnaireConfig;
  questionnaireConfig_aggregate_order_by: QuestionnaireConfigAggregateOrderBy;
  questionnaireConfig_arr_rel_insert_input: QuestionnaireConfigArrRelInsertInput;
  questionnaireConfig_bool_exp: QuestionnaireConfigBoolExp;
  questionnaireConfig_insert_input: QuestionnaireConfigInsertInput;
  questionnaireConfig_max_order_by: QuestionnaireConfigMaxOrderBy;
  questionnaireConfig_min_order_by: QuestionnaireConfigMinOrderBy;
  questionnaireConfig_mutation_response: QuestionnaireConfigMutationResponse;
  questionnaireConfig_on_conflict: QuestionnaireConfigOnConflict;
  questionnaireConfig_order_by: QuestionnaireConfigOrderBy;
  questionnaireConfig_pk_columns_input: QuestionnaireConfigPkColumnsInput;
  questionnaireConfig_set_input: QuestionnaireConfigSetInput;
  questionnaireConfig_stream_cursor_input: QuestionnaireConfigStreamCursorInput;
  questionnaireConfig_stream_cursor_value_input: QuestionnaireConfigStreamCursorValueInput;
  questionnaireConfig_updates: QuestionnaireConfigUpdates;
  questionnaireQuestion: QuestionnaireQuestion;
  questionnaireQuestion_aggregate_order_by: QuestionnaireQuestionAggregateOrderBy;
  questionnaireQuestion_avg_order_by: QuestionnaireQuestionAvgOrderBy;
  questionnaireQuestion_bool_exp: QuestionnaireQuestionBoolExp;
  questionnaireQuestion_max_order_by: QuestionnaireQuestionMaxOrderBy;
  questionnaireQuestion_min_order_by: QuestionnaireQuestionMinOrderBy;
  questionnaireQuestion_order_by: QuestionnaireQuestionOrderBy;
  questionnaireQuestion_stddev_order_by: QuestionnaireQuestionStddevOrderBy;
  questionnaireQuestion_stddev_pop_order_by: QuestionnaireQuestionStddevPopOrderBy;
  questionnaireQuestion_stddev_samp_order_by: QuestionnaireQuestionStddevSampOrderBy;
  questionnaireQuestion_stream_cursor_input: QuestionnaireQuestionStreamCursorInput;
  questionnaireQuestion_stream_cursor_value_input: QuestionnaireQuestionStreamCursorValueInput;
  questionnaireQuestion_sum_order_by: QuestionnaireQuestionSumOrderBy;
  questionnaireQuestion_var_pop_order_by: QuestionnaireQuestionVarPopOrderBy;
  questionnaireQuestion_var_samp_order_by: QuestionnaireQuestionVarSampOrderBy;
  questionnaireQuestion_variance_order_by: QuestionnaireQuestionVarianceOrderBy;
  questionnaire_bool_exp: QuestionnaireBoolExp;
  questionnaire_insert_input: QuestionnaireInsertInput;
  questionnaire_mutation_response: QuestionnaireMutationResponse;
  questionnaire_obj_rel_insert_input: QuestionnaireObjRelInsertInput;
  questionnaire_on_conflict: QuestionnaireOnConflict;
  questionnaire_order_by: QuestionnaireOrderBy;
  questionnaire_pk_columns_input: QuestionnairePkColumnsInput;
  questionnaire_set_input: QuestionnaireSetInput;
  questionnaire_stream_cursor_input: QuestionnaireStreamCursorInput;
  questionnaire_stream_cursor_value_input: QuestionnaireStreamCursorValueInput;
  questionnaire_updates: QuestionnaireUpdates;
  receipt: Receipt;
  receipt_aggregate_order_by: ReceiptAggregateOrderBy;
  receipt_avg_order_by: ReceiptAvgOrderBy;
  receipt_bool_exp: ReceiptBoolExp;
  receipt_max_order_by: ReceiptMaxOrderBy;
  receipt_min_order_by: ReceiptMinOrderBy;
  receipt_order_by: ReceiptOrderBy;
  receipt_stddev_order_by: ReceiptStddevOrderBy;
  receipt_stddev_pop_order_by: ReceiptStddevPopOrderBy;
  receipt_stddev_samp_order_by: ReceiptStddevSampOrderBy;
  receipt_stream_cursor_input: ReceiptStreamCursorInput;
  receipt_stream_cursor_value_input: ReceiptStreamCursorValueInput;
  receipt_sum_order_by: ReceiptSumOrderBy;
  receipt_var_pop_order_by: ReceiptVarPopOrderBy;
  receipt_var_samp_order_by: ReceiptVarSampOrderBy;
  receipt_variance_order_by: ReceiptVarianceOrderBy;
  reservationDashboardAccountAccessibleShop: ReservationDashboardAccountAccessibleShop;
  reservationDashboardAccountAccessibleShop_aggregate_order_by: ReservationDashboardAccountAccessibleShopAggregateOrderBy;
  reservationDashboardAccountAccessibleShop_bool_exp: ReservationDashboardAccountAccessibleShopBoolExp;
  reservationDashboardAccountAccessibleShop_max_order_by: ReservationDashboardAccountAccessibleShopMaxOrderBy;
  reservationDashboardAccountAccessibleShop_min_order_by: ReservationDashboardAccountAccessibleShopMinOrderBy;
  reservationDashboardAccountAccessibleShop_order_by: ReservationDashboardAccountAccessibleShopOrderBy;
  reservationDashboardAccountAccessibleShop_stream_cursor_input: ReservationDashboardAccountAccessibleShopStreamCursorInput;
  reservationDashboardAccountAccessibleShop_stream_cursor_value_input: ReservationDashboardAccountAccessibleShopStreamCursorValueInput;
  role: Role;
  roleTablesTable: RoleTablesTable;
  roleTablesTable_aggregate_order_by: RoleTablesTableAggregateOrderBy;
  roleTablesTable_arr_rel_insert_input: RoleTablesTableArrRelInsertInput;
  roleTablesTable_avg_order_by: RoleTablesTableAvgOrderBy;
  roleTablesTable_bool_exp: RoleTablesTableBoolExp;
  roleTablesTable_inc_input: RoleTablesTableIncInput;
  roleTablesTable_insert_input: RoleTablesTableInsertInput;
  roleTablesTable_max_order_by: RoleTablesTableMaxOrderBy;
  roleTablesTable_min_order_by: RoleTablesTableMinOrderBy;
  roleTablesTable_mutation_response: RoleTablesTableMutationResponse;
  roleTablesTable_on_conflict: RoleTablesTableOnConflict;
  roleTablesTable_order_by: RoleTablesTableOrderBy;
  roleTablesTable_set_input: RoleTablesTableSetInput;
  roleTablesTable_stddev_order_by: RoleTablesTableStddevOrderBy;
  roleTablesTable_stddev_pop_order_by: RoleTablesTableStddevPopOrderBy;
  roleTablesTable_stddev_samp_order_by: RoleTablesTableStddevSampOrderBy;
  roleTablesTable_stream_cursor_input: RoleTablesTableStreamCursorInput;
  roleTablesTable_stream_cursor_value_input: RoleTablesTableStreamCursorValueInput;
  roleTablesTable_sum_order_by: RoleTablesTableSumOrderBy;
  roleTablesTable_updates: RoleTablesTableUpdates;
  roleTablesTable_var_pop_order_by: RoleTablesTableVarPopOrderBy;
  roleTablesTable_var_samp_order_by: RoleTablesTableVarSampOrderBy;
  roleTablesTable_variance_order_by: RoleTablesTableVarianceOrderBy;
  role_aggregate_order_by: RoleAggregateOrderBy;
  role_arr_rel_insert_input: RoleArrRelInsertInput;
  role_avg_order_by: RoleAvgOrderBy;
  role_bool_exp: RoleBoolExp;
  role_inc_input: RoleIncInput;
  role_insert_input: RoleInsertInput;
  role_max_order_by: RoleMaxOrderBy;
  role_min_order_by: RoleMinOrderBy;
  role_mutation_response: RoleMutationResponse;
  role_obj_rel_insert_input: RoleObjRelInsertInput;
  role_on_conflict: RoleOnConflict;
  role_order_by: RoleOrderBy;
  role_pk_columns_input: RolePkColumnsInput;
  role_set_input: RoleSetInput;
  role_stddev_order_by: RoleStddevOrderBy;
  role_stddev_pop_order_by: RoleStddevPopOrderBy;
  role_stddev_samp_order_by: RoleStddevSampOrderBy;
  role_stream_cursor_input: RoleStreamCursorInput;
  role_stream_cursor_value_input: RoleStreamCursorValueInput;
  role_sum_order_by: RoleSumOrderBy;
  role_updates: RoleUpdates;
  role_var_pop_order_by: RoleVarPopOrderBy;
  role_var_samp_order_by: RoleVarSampOrderBy;
  role_variance_order_by: RoleVarianceOrderBy;
  salesBudgetAllocationSetting: SalesBudgetAllocationSetting;
  salesBudgetAllocationSetting_bool_exp: SalesBudgetAllocationSettingBoolExp;
  salesBudgetAllocationSetting_inc_input: SalesBudgetAllocationSettingIncInput;
  salesBudgetAllocationSetting_insert_input: SalesBudgetAllocationSettingInsertInput;
  salesBudgetAllocationSetting_mutation_response: SalesBudgetAllocationSettingMutationResponse;
  salesBudgetAllocationSetting_on_conflict: SalesBudgetAllocationSettingOnConflict;
  salesBudgetAllocationSetting_order_by: SalesBudgetAllocationSettingOrderBy;
  salesBudgetAllocationSetting_pk_columns_input: SalesBudgetAllocationSettingPkColumnsInput;
  salesBudgetAllocationSetting_set_input: SalesBudgetAllocationSettingSetInput;
  salesBudgetAllocationSetting_stream_cursor_input: SalesBudgetAllocationSettingStreamCursorInput;
  salesBudgetAllocationSetting_stream_cursor_value_input: SalesBudgetAllocationSettingStreamCursorValueInput;
  salesBudgetAllocationSetting_updates: SalesBudgetAllocationSettingUpdates;
  scoreTypeQuestionAnswer: ScoreTypeQuestionAnswer;
  scoreTypeQuestionAnswer_bool_exp: ScoreTypeQuestionAnswerBoolExp;
  scoreTypeQuestionAnswer_order_by: ScoreTypeQuestionAnswerOrderBy;
  scoreTypeQuestionAnswer_stream_cursor_input: ScoreTypeQuestionAnswerStreamCursorInput;
  scoreTypeQuestionAnswer_stream_cursor_value_input: ScoreTypeQuestionAnswerStreamCursorValueInput;
  serviceAdmin: ServiceAdmin;
  serviceAdmin_bool_exp: ServiceAdminBoolExp;
  serviceAdmin_order_by: ServiceAdminOrderBy;
  serviceAdmin_stream_cursor_input: ServiceAdminStreamCursorInput;
  serviceAdmin_stream_cursor_value_input: ServiceAdminStreamCursorValueInput;
  serviceChargeConfig: ServiceChargeConfig;
  serviceChargeConfig_aggregate_order_by: ServiceChargeConfigAggregateOrderBy;
  serviceChargeConfig_avg_order_by: ServiceChargeConfigAvgOrderBy;
  serviceChargeConfig_bool_exp: ServiceChargeConfigBoolExp;
  serviceChargeConfig_inc_input: ServiceChargeConfigIncInput;
  serviceChargeConfig_insert_input: ServiceChargeConfigInsertInput;
  serviceChargeConfig_max_order_by: ServiceChargeConfigMaxOrderBy;
  serviceChargeConfig_min_order_by: ServiceChargeConfigMinOrderBy;
  serviceChargeConfig_mutation_response: ServiceChargeConfigMutationResponse;
  serviceChargeConfig_obj_rel_insert_input: ServiceChargeConfigObjRelInsertInput;
  serviceChargeConfig_on_conflict: ServiceChargeConfigOnConflict;
  serviceChargeConfig_order_by: ServiceChargeConfigOrderBy;
  serviceChargeConfig_pk_columns_input: ServiceChargeConfigPkColumnsInput;
  serviceChargeConfig_set_input: ServiceChargeConfigSetInput;
  serviceChargeConfig_stddev_order_by: ServiceChargeConfigStddevOrderBy;
  serviceChargeConfig_stddev_pop_order_by: ServiceChargeConfigStddevPopOrderBy;
  serviceChargeConfig_stddev_samp_order_by: ServiceChargeConfigStddevSampOrderBy;
  serviceChargeConfig_stream_cursor_input: ServiceChargeConfigStreamCursorInput;
  serviceChargeConfig_stream_cursor_value_input: ServiceChargeConfigStreamCursorValueInput;
  serviceChargeConfig_sum_order_by: ServiceChargeConfigSumOrderBy;
  serviceChargeConfig_updates: ServiceChargeConfigUpdates;
  serviceChargeConfig_var_pop_order_by: ServiceChargeConfigVarPopOrderBy;
  serviceChargeConfig_var_samp_order_by: ServiceChargeConfigVarSampOrderBy;
  serviceChargeConfig_variance_order_by: ServiceChargeConfigVarianceOrderBy;
  serviceChargeMeta: ServiceChargeMeta;
  serviceChargeMeta_bool_exp: ServiceChargeMetaBoolExp;
  serviceChargeMeta_order_by: ServiceChargeMetaOrderBy;
  serviceChargeMeta_stream_cursor_input: ServiceChargeMetaStreamCursorInput;
  serviceChargeMeta_stream_cursor_value_input: ServiceChargeMetaStreamCursorValueInput;
  shop: Shop;
  shopBusinessOperationHour: ShopBusinessOperationHour;
  shopBusinessOperationHour_aggregate_order_by: ShopBusinessOperationHourAggregateOrderBy;
  shopBusinessOperationHour_arr_rel_insert_input: ShopBusinessOperationHourArrRelInsertInput;
  shopBusinessOperationHour_bool_exp: ShopBusinessOperationHourBoolExp;
  shopBusinessOperationHour_insert_input: ShopBusinessOperationHourInsertInput;
  shopBusinessOperationHour_max_order_by: ShopBusinessOperationHourMaxOrderBy;
  shopBusinessOperationHour_min_order_by: ShopBusinessOperationHourMinOrderBy;
  shopBusinessOperationHour_mutation_response: ShopBusinessOperationHourMutationResponse;
  shopBusinessOperationHour_on_conflict: ShopBusinessOperationHourOnConflict;
  shopBusinessOperationHour_order_by: ShopBusinessOperationHourOrderBy;
  shopBusinessOperationHour_pk_columns_input: ShopBusinessOperationHourPkColumnsInput;
  shopBusinessOperationHour_set_input: ShopBusinessOperationHourSetInput;
  shopBusinessOperationHour_stream_cursor_input: ShopBusinessOperationHourStreamCursorInput;
  shopBusinessOperationHour_stream_cursor_value_input: ShopBusinessOperationHourStreamCursorValueInput;
  shopBusinessOperationHour_updates: ShopBusinessOperationHourUpdates;
  shopChoice: ShopChoice;
  shopChoice_aggregate_order_by: ShopChoiceAggregateOrderBy;
  shopChoice_avg_order_by: ShopChoiceAvgOrderBy;
  shopChoice_bool_exp: ShopChoiceBoolExp;
  shopChoice_max_order_by: ShopChoiceMaxOrderBy;
  shopChoice_min_order_by: ShopChoiceMinOrderBy;
  shopChoice_order_by: ShopChoiceOrderBy;
  shopChoice_stddev_order_by: ShopChoiceStddevOrderBy;
  shopChoice_stddev_pop_order_by: ShopChoiceStddevPopOrderBy;
  shopChoice_stddev_samp_order_by: ShopChoiceStddevSampOrderBy;
  shopChoice_stream_cursor_input: ShopChoiceStreamCursorInput;
  shopChoice_stream_cursor_value_input: ShopChoiceStreamCursorValueInput;
  shopChoice_sum_order_by: ShopChoiceSumOrderBy;
  shopChoice_var_pop_order_by: ShopChoiceVarPopOrderBy;
  shopChoice_var_samp_order_by: ShopChoiceVarSampOrderBy;
  shopChoice_variance_order_by: ShopChoiceVarianceOrderBy;
  shopClerk: ShopClerk;
  shopClerk_aggregate_order_by: ShopClerkAggregateOrderBy;
  shopClerk_arr_rel_insert_input: ShopClerkArrRelInsertInput;
  shopClerk_avg_order_by: ShopClerkAvgOrderBy;
  shopClerk_bool_exp: ShopClerkBoolExp;
  shopClerk_inc_input: ShopClerkIncInput;
  shopClerk_insert_input: ShopClerkInsertInput;
  shopClerk_max_order_by: ShopClerkMaxOrderBy;
  shopClerk_min_order_by: ShopClerkMinOrderBy;
  shopClerk_mutation_response: ShopClerkMutationResponse;
  shopClerk_on_conflict: ShopClerkOnConflict;
  shopClerk_order_by: ShopClerkOrderBy;
  shopClerk_pk_columns_input: ShopClerkPkColumnsInput;
  shopClerk_set_input: ShopClerkSetInput;
  shopClerk_stddev_order_by: ShopClerkStddevOrderBy;
  shopClerk_stddev_pop_order_by: ShopClerkStddevPopOrderBy;
  shopClerk_stddev_samp_order_by: ShopClerkStddevSampOrderBy;
  shopClerk_stream_cursor_input: ShopClerkStreamCursorInput;
  shopClerk_stream_cursor_value_input: ShopClerkStreamCursorValueInput;
  shopClerk_sum_order_by: ShopClerkSumOrderBy;
  shopClerk_updates: ShopClerkUpdates;
  shopClerk_var_pop_order_by: ShopClerkVarPopOrderBy;
  shopClerk_var_samp_order_by: ShopClerkVarSampOrderBy;
  shopClerk_variance_order_by: ShopClerkVarianceOrderBy;
  shopClient: ShopClient;
  shopClient_aggregate_order_by: ShopClientAggregateOrderBy;
  shopClient_arr_rel_insert_input: ShopClientArrRelInsertInput;
  shopClient_bool_exp: ShopClientBoolExp;
  shopClient_insert_input: ShopClientInsertInput;
  shopClient_max_order_by: ShopClientMaxOrderBy;
  shopClient_min_order_by: ShopClientMinOrderBy;
  shopClient_mutation_response: ShopClientMutationResponse;
  shopClient_on_conflict: ShopClientOnConflict;
  shopClient_order_by: ShopClientOrderBy;
  shopClient_pk_columns_input: ShopClientPkColumnsInput;
  shopClient_set_input: ShopClientSetInput;
  shopClient_stream_cursor_input: ShopClientStreamCursorInput;
  shopClient_stream_cursor_value_input: ShopClientStreamCursorValueInput;
  shopClient_updates: ShopClientUpdates;
  shopCookingItemRole: ShopCookingItemRole;
  shopCookingItemRole_aggregate_order_by: ShopCookingItemRoleAggregateOrderBy;
  shopCookingItemRole_arr_rel_insert_input: ShopCookingItemRoleArrRelInsertInput;
  shopCookingItemRole_avg_order_by: ShopCookingItemRoleAvgOrderBy;
  shopCookingItemRole_bool_exp: ShopCookingItemRoleBoolExp;
  shopCookingItemRole_inc_input: ShopCookingItemRoleIncInput;
  shopCookingItemRole_insert_input: ShopCookingItemRoleInsertInput;
  shopCookingItemRole_max_order_by: ShopCookingItemRoleMaxOrderBy;
  shopCookingItemRole_min_order_by: ShopCookingItemRoleMinOrderBy;
  shopCookingItemRole_mutation_response: ShopCookingItemRoleMutationResponse;
  shopCookingItemRole_obj_rel_insert_input: ShopCookingItemRoleObjRelInsertInput;
  shopCookingItemRole_on_conflict: ShopCookingItemRoleOnConflict;
  shopCookingItemRole_order_by: ShopCookingItemRoleOrderBy;
  shopCookingItemRole_pk_columns_input: ShopCookingItemRolePkColumnsInput;
  shopCookingItemRole_set_input: ShopCookingItemRoleSetInput;
  shopCookingItemRole_stddev_order_by: ShopCookingItemRoleStddevOrderBy;
  shopCookingItemRole_stddev_pop_order_by: ShopCookingItemRoleStddevPopOrderBy;
  shopCookingItemRole_stddev_samp_order_by: ShopCookingItemRoleStddevSampOrderBy;
  shopCookingItemRole_stream_cursor_input: ShopCookingItemRoleStreamCursorInput;
  shopCookingItemRole_stream_cursor_value_input: ShopCookingItemRoleStreamCursorValueInput;
  shopCookingItemRole_sum_order_by: ShopCookingItemRoleSumOrderBy;
  shopCookingItemRole_updates: ShopCookingItemRoleUpdates;
  shopCookingItemRole_var_pop_order_by: ShopCookingItemRoleVarPopOrderBy;
  shopCookingItemRole_var_samp_order_by: ShopCookingItemRoleVarSampOrderBy;
  shopCookingItemRole_variance_order_by: ShopCookingItemRoleVarianceOrderBy;
  shopDiscountType: ShopDiscountType;
  shopDiscountType_aggregate_order_by: ShopDiscountTypeAggregateOrderBy;
  shopDiscountType_arr_rel_insert_input: ShopDiscountTypeArrRelInsertInput;
  shopDiscountType_bool_exp: ShopDiscountTypeBoolExp;
  shopDiscountType_insert_input: ShopDiscountTypeInsertInput;
  shopDiscountType_max_order_by: ShopDiscountTypeMaxOrderBy;
  shopDiscountType_min_order_by: ShopDiscountTypeMinOrderBy;
  shopDiscountType_mutation_response: ShopDiscountTypeMutationResponse;
  shopDiscountType_on_conflict: ShopDiscountTypeOnConflict;
  shopDiscountType_order_by: ShopDiscountTypeOrderBy;
  shopDiscountType_pk_columns_input: ShopDiscountTypePkColumnsInput;
  shopDiscountType_set_input: ShopDiscountTypeSetInput;
  shopDiscountType_stream_cursor_input: ShopDiscountTypeStreamCursorInput;
  shopDiscountType_stream_cursor_value_input: ShopDiscountTypeStreamCursorValueInput;
  shopDiscountType_updates: ShopDiscountTypeUpdates;
  shopInflowSourceTag: ShopInflowSourceTag;
  shopInflowSourceTag_aggregate_order_by: ShopInflowSourceTagAggregateOrderBy;
  shopInflowSourceTag_arr_rel_insert_input: ShopInflowSourceTagArrRelInsertInput;
  shopInflowSourceTag_bool_exp: ShopInflowSourceTagBoolExp;
  shopInflowSourceTag_insert_input: ShopInflowSourceTagInsertInput;
  shopInflowSourceTag_max_order_by: ShopInflowSourceTagMaxOrderBy;
  shopInflowSourceTag_min_order_by: ShopInflowSourceTagMinOrderBy;
  shopInflowSourceTag_mutation_response: ShopInflowSourceTagMutationResponse;
  shopInflowSourceTag_on_conflict: ShopInflowSourceTagOnConflict;
  shopInflowSourceTag_order_by: ShopInflowSourceTagOrderBy;
  shopInflowSourceTag_set_input: ShopInflowSourceTagSetInput;
  shopInflowSourceTag_stream_cursor_input: ShopInflowSourceTagStreamCursorInput;
  shopInflowSourceTag_stream_cursor_value_input: ShopInflowSourceTagStreamCursorValueInput;
  shopInflowSourceTag_updates: ShopInflowSourceTagUpdates;
  shopMember: ShopMember;
  shopMember_aggregate_order_by: ShopMemberAggregateOrderBy;
  shopMember_bool_exp: ShopMemberBoolExp;
  shopMember_max_order_by: ShopMemberMaxOrderBy;
  shopMember_min_order_by: ShopMemberMinOrderBy;
  shopMember_order_by: ShopMemberOrderBy;
  shopMember_stream_cursor_input: ShopMemberStreamCursorInput;
  shopMember_stream_cursor_value_input: ShopMemberStreamCursorValueInput;
  shopMenu: ShopMenu;
  shopMenuCookingItem: ShopMenuCookingItem;
  shopMenuCookingItem_aggregate_order_by: ShopMenuCookingItemAggregateOrderBy;
  shopMenuCookingItem_arr_rel_insert_input: ShopMenuCookingItemArrRelInsertInput;
  shopMenuCookingItem_avg_order_by: ShopMenuCookingItemAvgOrderBy;
  shopMenuCookingItem_bool_exp: ShopMenuCookingItemBoolExp;
  shopMenuCookingItem_inc_input: ShopMenuCookingItemIncInput;
  shopMenuCookingItem_insert_input: ShopMenuCookingItemInsertInput;
  shopMenuCookingItem_max_order_by: ShopMenuCookingItemMaxOrderBy;
  shopMenuCookingItem_min_order_by: ShopMenuCookingItemMinOrderBy;
  shopMenuCookingItem_mutation_response: ShopMenuCookingItemMutationResponse;
  shopMenuCookingItem_on_conflict: ShopMenuCookingItemOnConflict;
  shopMenuCookingItem_order_by: ShopMenuCookingItemOrderBy;
  shopMenuCookingItem_set_input: ShopMenuCookingItemSetInput;
  shopMenuCookingItem_stddev_order_by: ShopMenuCookingItemStddevOrderBy;
  shopMenuCookingItem_stddev_pop_order_by: ShopMenuCookingItemStddevPopOrderBy;
  shopMenuCookingItem_stddev_samp_order_by: ShopMenuCookingItemStddevSampOrderBy;
  shopMenuCookingItem_stream_cursor_input: ShopMenuCookingItemStreamCursorInput;
  shopMenuCookingItem_stream_cursor_value_input: ShopMenuCookingItemStreamCursorValueInput;
  shopMenuCookingItem_sum_order_by: ShopMenuCookingItemSumOrderBy;
  shopMenuCookingItem_updates: ShopMenuCookingItemUpdates;
  shopMenuCookingItem_var_pop_order_by: ShopMenuCookingItemVarPopOrderBy;
  shopMenuCookingItem_var_samp_order_by: ShopMenuCookingItemVarSampOrderBy;
  shopMenuCookingItem_variance_order_by: ShopMenuCookingItemVarianceOrderBy;
  shopMenuKdDisplayTarget: ShopMenuKdDisplayTarget;
  shopMenuKdDisplayTarget_aggregate_order_by: ShopMenuKdDisplayTargetAggregateOrderBy;
  shopMenuKdDisplayTarget_arr_rel_insert_input: ShopMenuKdDisplayTargetArrRelInsertInput;
  shopMenuKdDisplayTarget_avg_order_by: ShopMenuKdDisplayTargetAvgOrderBy;
  shopMenuKdDisplayTarget_bool_exp: ShopMenuKdDisplayTargetBoolExp;
  shopMenuKdDisplayTarget_inc_input: ShopMenuKdDisplayTargetIncInput;
  shopMenuKdDisplayTarget_insert_input: ShopMenuKdDisplayTargetInsertInput;
  shopMenuKdDisplayTarget_max_order_by: ShopMenuKdDisplayTargetMaxOrderBy;
  shopMenuKdDisplayTarget_min_order_by: ShopMenuKdDisplayTargetMinOrderBy;
  shopMenuKdDisplayTarget_mutation_response: ShopMenuKdDisplayTargetMutationResponse;
  shopMenuKdDisplayTarget_on_conflict: ShopMenuKdDisplayTargetOnConflict;
  shopMenuKdDisplayTarget_order_by: ShopMenuKdDisplayTargetOrderBy;
  shopMenuKdDisplayTarget_pk_columns_input: ShopMenuKdDisplayTargetPkColumnsInput;
  shopMenuKdDisplayTarget_set_input: ShopMenuKdDisplayTargetSetInput;
  shopMenuKdDisplayTarget_stddev_order_by: ShopMenuKdDisplayTargetStddevOrderBy;
  shopMenuKdDisplayTarget_stddev_pop_order_by: ShopMenuKdDisplayTargetStddevPopOrderBy;
  shopMenuKdDisplayTarget_stddev_samp_order_by: ShopMenuKdDisplayTargetStddevSampOrderBy;
  shopMenuKdDisplayTarget_stream_cursor_input: ShopMenuKdDisplayTargetStreamCursorInput;
  shopMenuKdDisplayTarget_stream_cursor_value_input: ShopMenuKdDisplayTargetStreamCursorValueInput;
  shopMenuKdDisplayTarget_sum_order_by: ShopMenuKdDisplayTargetSumOrderBy;
  shopMenuKdDisplayTarget_updates: ShopMenuKdDisplayTargetUpdates;
  shopMenuKdDisplayTarget_var_pop_order_by: ShopMenuKdDisplayTargetVarPopOrderBy;
  shopMenuKdDisplayTarget_var_samp_order_by: ShopMenuKdDisplayTargetVarSampOrderBy;
  shopMenuKdDisplayTarget_variance_order_by: ShopMenuKdDisplayTargetVarianceOrderBy;
  shopMenuKitchenRole: ShopMenuKitchenRole;
  shopMenuKitchenRole_aggregate_order_by: ShopMenuKitchenRoleAggregateOrderBy;
  shopMenuKitchenRole_arr_rel_insert_input: ShopMenuKitchenRoleArrRelInsertInput;
  shopMenuKitchenRole_avg_order_by: ShopMenuKitchenRoleAvgOrderBy;
  shopMenuKitchenRole_bool_exp: ShopMenuKitchenRoleBoolExp;
  shopMenuKitchenRole_inc_input: ShopMenuKitchenRoleIncInput;
  shopMenuKitchenRole_insert_input: ShopMenuKitchenRoleInsertInput;
  shopMenuKitchenRole_max_order_by: ShopMenuKitchenRoleMaxOrderBy;
  shopMenuKitchenRole_min_order_by: ShopMenuKitchenRoleMinOrderBy;
  shopMenuKitchenRole_mutation_response: ShopMenuKitchenRoleMutationResponse;
  shopMenuKitchenRole_on_conflict: ShopMenuKitchenRoleOnConflict;
  shopMenuKitchenRole_order_by: ShopMenuKitchenRoleOrderBy;
  shopMenuKitchenRole_set_input: ShopMenuKitchenRoleSetInput;
  shopMenuKitchenRole_stddev_order_by: ShopMenuKitchenRoleStddevOrderBy;
  shopMenuKitchenRole_stddev_pop_order_by: ShopMenuKitchenRoleStddevPopOrderBy;
  shopMenuKitchenRole_stddev_samp_order_by: ShopMenuKitchenRoleStddevSampOrderBy;
  shopMenuKitchenRole_stream_cursor_input: ShopMenuKitchenRoleStreamCursorInput;
  shopMenuKitchenRole_stream_cursor_value_input: ShopMenuKitchenRoleStreamCursorValueInput;
  shopMenuKitchenRole_sum_order_by: ShopMenuKitchenRoleSumOrderBy;
  shopMenuKitchenRole_updates: ShopMenuKitchenRoleUpdates;
  shopMenuKitchenRole_var_pop_order_by: ShopMenuKitchenRoleVarPopOrderBy;
  shopMenuKitchenRole_var_samp_order_by: ShopMenuKitchenRoleVarSampOrderBy;
  shopMenuKitchenRole_variance_order_by: ShopMenuKitchenRoleVarianceOrderBy;
  shopMenu_aggregate_order_by: ShopMenuAggregateOrderBy;
  shopMenu_avg_order_by: ShopMenuAvgOrderBy;
  shopMenu_bool_exp: ShopMenuBoolExp;
  shopMenu_inc_input: ShopMenuIncInput;
  shopMenu_max_order_by: ShopMenuMaxOrderBy;
  shopMenu_min_order_by: ShopMenuMinOrderBy;
  shopMenu_mutation_response: ShopMenuMutationResponse;
  shopMenu_order_by: ShopMenuOrderBy;
  shopMenu_pk_columns_input: ShopMenuPkColumnsInput;
  shopMenu_set_input: ShopMenuSetInput;
  shopMenu_stddev_order_by: ShopMenuStddevOrderBy;
  shopMenu_stddev_pop_order_by: ShopMenuStddevPopOrderBy;
  shopMenu_stddev_samp_order_by: ShopMenuStddevSampOrderBy;
  shopMenu_stream_cursor_input: ShopMenuStreamCursorInput;
  shopMenu_stream_cursor_value_input: ShopMenuStreamCursorValueInput;
  shopMenu_sum_order_by: ShopMenuSumOrderBy;
  shopMenu_updates: ShopMenuUpdates;
  shopMenu_var_pop_order_by: ShopMenuVarPopOrderBy;
  shopMenu_var_samp_order_by: ShopMenuVarSampOrderBy;
  shopMenu_variance_order_by: ShopMenuVarianceOrderBy;
  shopOptionKitchenRole: ShopOptionKitchenRole;
  shopOptionKitchenRole_aggregate_order_by: ShopOptionKitchenRoleAggregateOrderBy;
  shopOptionKitchenRole_arr_rel_insert_input: ShopOptionKitchenRoleArrRelInsertInput;
  shopOptionKitchenRole_bool_exp: ShopOptionKitchenRoleBoolExp;
  shopOptionKitchenRole_insert_input: ShopOptionKitchenRoleInsertInput;
  shopOptionKitchenRole_max_order_by: ShopOptionKitchenRoleMaxOrderBy;
  shopOptionKitchenRole_min_order_by: ShopOptionKitchenRoleMinOrderBy;
  shopOptionKitchenRole_mutation_response: ShopOptionKitchenRoleMutationResponse;
  shopOptionKitchenRole_on_conflict: ShopOptionKitchenRoleOnConflict;
  shopOptionKitchenRole_order_by: ShopOptionKitchenRoleOrderBy;
  shopOptionKitchenRole_stream_cursor_input: ShopOptionKitchenRoleStreamCursorInput;
  shopOptionKitchenRole_stream_cursor_value_input: ShopOptionKitchenRoleStreamCursorValueInput;
  shopOrderableTimeTerm: ShopOrderableTimeTerm;
  shopOrderableTimeTerm_aggregate_order_by: ShopOrderableTimeTermAggregateOrderBy;
  shopOrderableTimeTerm_arr_rel_insert_input: ShopOrderableTimeTermArrRelInsertInput;
  shopOrderableTimeTerm_bool_exp: ShopOrderableTimeTermBoolExp;
  shopOrderableTimeTerm_insert_input: ShopOrderableTimeTermInsertInput;
  shopOrderableTimeTerm_max_order_by: ShopOrderableTimeTermMaxOrderBy;
  shopOrderableTimeTerm_min_order_by: ShopOrderableTimeTermMinOrderBy;
  shopOrderableTimeTerm_mutation_response: ShopOrderableTimeTermMutationResponse;
  shopOrderableTimeTerm_on_conflict: ShopOrderableTimeTermOnConflict;
  shopOrderableTimeTerm_order_by: ShopOrderableTimeTermOrderBy;
  shopOrderableTimeTerm_pk_columns_input: ShopOrderableTimeTermPkColumnsInput;
  shopOrderableTimeTerm_set_input: ShopOrderableTimeTermSetInput;
  shopOrderableTimeTerm_stream_cursor_input: ShopOrderableTimeTermStreamCursorInput;
  shopOrderableTimeTerm_stream_cursor_value_input: ShopOrderableTimeTermStreamCursorValueInput;
  shopOrderableTimeTerm_updates: ShopOrderableTimeTermUpdates;
  shopPaymentType: ShopPaymentType;
  shopPaymentType_aggregate_order_by: ShopPaymentTypeAggregateOrderBy;
  shopPaymentType_arr_rel_insert_input: ShopPaymentTypeArrRelInsertInput;
  shopPaymentType_bool_exp: ShopPaymentTypeBoolExp;
  shopPaymentType_insert_input: ShopPaymentTypeInsertInput;
  shopPaymentType_max_order_by: ShopPaymentTypeMaxOrderBy;
  shopPaymentType_min_order_by: ShopPaymentTypeMinOrderBy;
  shopPaymentType_mutation_response: ShopPaymentTypeMutationResponse;
  shopPaymentType_on_conflict: ShopPaymentTypeOnConflict;
  shopPaymentType_order_by: ShopPaymentTypeOrderBy;
  shopPaymentType_pk_columns_input: ShopPaymentTypePkColumnsInput;
  shopPaymentType_set_input: ShopPaymentTypeSetInput;
  shopPaymentType_stream_cursor_input: ShopPaymentTypeStreamCursorInput;
  shopPaymentType_stream_cursor_value_input: ShopPaymentTypeStreamCursorValueInput;
  shopPaymentType_updates: ShopPaymentTypeUpdates;
  shopPlan: ShopPlan;
  shopPlanChoice: ShopPlanChoice;
  shopPlanChoice_aggregate_order_by: ShopPlanChoiceAggregateOrderBy;
  shopPlanChoice_avg_order_by: ShopPlanChoiceAvgOrderBy;
  shopPlanChoice_bool_exp: ShopPlanChoiceBoolExp;
  shopPlanChoice_inc_input: ShopPlanChoiceIncInput;
  shopPlanChoice_max_order_by: ShopPlanChoiceMaxOrderBy;
  shopPlanChoice_min_order_by: ShopPlanChoiceMinOrderBy;
  shopPlanChoice_mutation_response: ShopPlanChoiceMutationResponse;
  shopPlanChoice_order_by: ShopPlanChoiceOrderBy;
  shopPlanChoice_pk_columns_input: ShopPlanChoicePkColumnsInput;
  shopPlanChoice_set_input: ShopPlanChoiceSetInput;
  shopPlanChoice_stddev_order_by: ShopPlanChoiceStddevOrderBy;
  shopPlanChoice_stddev_pop_order_by: ShopPlanChoiceStddevPopOrderBy;
  shopPlanChoice_stddev_samp_order_by: ShopPlanChoiceStddevSampOrderBy;
  shopPlanChoice_stream_cursor_input: ShopPlanChoiceStreamCursorInput;
  shopPlanChoice_stream_cursor_value_input: ShopPlanChoiceStreamCursorValueInput;
  shopPlanChoice_sum_order_by: ShopPlanChoiceSumOrderBy;
  shopPlanChoice_updates: ShopPlanChoiceUpdates;
  shopPlanChoice_var_pop_order_by: ShopPlanChoiceVarPopOrderBy;
  shopPlanChoice_var_samp_order_by: ShopPlanChoiceVarSampOrderBy;
  shopPlanChoice_variance_order_by: ShopPlanChoiceVarianceOrderBy;
  shopPlanKitchenRole: ShopPlanKitchenRole;
  shopPlanKitchenRole_aggregate_order_by: ShopPlanKitchenRoleAggregateOrderBy;
  shopPlanKitchenRole_arr_rel_insert_input: ShopPlanKitchenRoleArrRelInsertInput;
  shopPlanKitchenRole_avg_order_by: ShopPlanKitchenRoleAvgOrderBy;
  shopPlanKitchenRole_bool_exp: ShopPlanKitchenRoleBoolExp;
  shopPlanKitchenRole_inc_input: ShopPlanKitchenRoleIncInput;
  shopPlanKitchenRole_insert_input: ShopPlanKitchenRoleInsertInput;
  shopPlanKitchenRole_max_order_by: ShopPlanKitchenRoleMaxOrderBy;
  shopPlanKitchenRole_min_order_by: ShopPlanKitchenRoleMinOrderBy;
  shopPlanKitchenRole_mutation_response: ShopPlanKitchenRoleMutationResponse;
  shopPlanKitchenRole_on_conflict: ShopPlanKitchenRoleOnConflict;
  shopPlanKitchenRole_order_by: ShopPlanKitchenRoleOrderBy;
  shopPlanKitchenRole_set_input: ShopPlanKitchenRoleSetInput;
  shopPlanKitchenRole_stddev_order_by: ShopPlanKitchenRoleStddevOrderBy;
  shopPlanKitchenRole_stddev_pop_order_by: ShopPlanKitchenRoleStddevPopOrderBy;
  shopPlanKitchenRole_stddev_samp_order_by: ShopPlanKitchenRoleStddevSampOrderBy;
  shopPlanKitchenRole_stream_cursor_input: ShopPlanKitchenRoleStreamCursorInput;
  shopPlanKitchenRole_stream_cursor_value_input: ShopPlanKitchenRoleStreamCursorValueInput;
  shopPlanKitchenRole_sum_order_by: ShopPlanKitchenRoleSumOrderBy;
  shopPlanKitchenRole_updates: ShopPlanKitchenRoleUpdates;
  shopPlanKitchenRole_var_pop_order_by: ShopPlanKitchenRoleVarPopOrderBy;
  shopPlanKitchenRole_var_samp_order_by: ShopPlanKitchenRoleVarSampOrderBy;
  shopPlanKitchenRole_variance_order_by: ShopPlanKitchenRoleVarianceOrderBy;
  shopPlanOption: ShopPlanOption;
  shopPlanOptionKitchenRole: ShopPlanOptionKitchenRole;
  shopPlanOptionKitchenRole_aggregate_order_by: ShopPlanOptionKitchenRoleAggregateOrderBy;
  shopPlanOptionKitchenRole_arr_rel_insert_input: ShopPlanOptionKitchenRoleArrRelInsertInput;
  shopPlanOptionKitchenRole_avg_order_by: ShopPlanOptionKitchenRoleAvgOrderBy;
  shopPlanOptionKitchenRole_bool_exp: ShopPlanOptionKitchenRoleBoolExp;
  shopPlanOptionKitchenRole_inc_input: ShopPlanOptionKitchenRoleIncInput;
  shopPlanOptionKitchenRole_insert_input: ShopPlanOptionKitchenRoleInsertInput;
  shopPlanOptionKitchenRole_max_order_by: ShopPlanOptionKitchenRoleMaxOrderBy;
  shopPlanOptionKitchenRole_min_order_by: ShopPlanOptionKitchenRoleMinOrderBy;
  shopPlanOptionKitchenRole_mutation_response: ShopPlanOptionKitchenRoleMutationResponse;
  shopPlanOptionKitchenRole_on_conflict: ShopPlanOptionKitchenRoleOnConflict;
  shopPlanOptionKitchenRole_order_by: ShopPlanOptionKitchenRoleOrderBy;
  shopPlanOptionKitchenRole_set_input: ShopPlanOptionKitchenRoleSetInput;
  shopPlanOptionKitchenRole_stddev_order_by: ShopPlanOptionKitchenRoleStddevOrderBy;
  shopPlanOptionKitchenRole_stddev_pop_order_by: ShopPlanOptionKitchenRoleStddevPopOrderBy;
  shopPlanOptionKitchenRole_stddev_samp_order_by: ShopPlanOptionKitchenRoleStddevSampOrderBy;
  shopPlanOptionKitchenRole_stream_cursor_input: ShopPlanOptionKitchenRoleStreamCursorInput;
  shopPlanOptionKitchenRole_stream_cursor_value_input: ShopPlanOptionKitchenRoleStreamCursorValueInput;
  shopPlanOptionKitchenRole_sum_order_by: ShopPlanOptionKitchenRoleSumOrderBy;
  shopPlanOptionKitchenRole_updates: ShopPlanOptionKitchenRoleUpdates;
  shopPlanOptionKitchenRole_var_pop_order_by: ShopPlanOptionKitchenRoleVarPopOrderBy;
  shopPlanOptionKitchenRole_var_samp_order_by: ShopPlanOptionKitchenRoleVarSampOrderBy;
  shopPlanOptionKitchenRole_variance_order_by: ShopPlanOptionKitchenRoleVarianceOrderBy;
  shopPlanOption_aggregate_order_by: ShopPlanOptionAggregateOrderBy;
  shopPlanOption_arr_rel_insert_input: ShopPlanOptionArrRelInsertInput;
  shopPlanOption_avg_order_by: ShopPlanOptionAvgOrderBy;
  shopPlanOption_bool_exp: ShopPlanOptionBoolExp;
  shopPlanOption_inc_input: ShopPlanOptionIncInput;
  shopPlanOption_insert_input: ShopPlanOptionInsertInput;
  shopPlanOption_max_order_by: ShopPlanOptionMaxOrderBy;
  shopPlanOption_min_order_by: ShopPlanOptionMinOrderBy;
  shopPlanOption_mutation_response: ShopPlanOptionMutationResponse;
  shopPlanOption_on_conflict: ShopPlanOptionOnConflict;
  shopPlanOption_order_by: ShopPlanOptionOrderBy;
  shopPlanOption_pk_columns_input: ShopPlanOptionPkColumnsInput;
  shopPlanOption_set_input: ShopPlanOptionSetInput;
  shopPlanOption_stddev_order_by: ShopPlanOptionStddevOrderBy;
  shopPlanOption_stddev_pop_order_by: ShopPlanOptionStddevPopOrderBy;
  shopPlanOption_stddev_samp_order_by: ShopPlanOptionStddevSampOrderBy;
  shopPlanOption_stream_cursor_input: ShopPlanOptionStreamCursorInput;
  shopPlanOption_stream_cursor_value_input: ShopPlanOptionStreamCursorValueInput;
  shopPlanOption_sum_order_by: ShopPlanOptionSumOrderBy;
  shopPlanOption_updates: ShopPlanOptionUpdates;
  shopPlanOption_var_pop_order_by: ShopPlanOptionVarPopOrderBy;
  shopPlanOption_var_samp_order_by: ShopPlanOptionVarSampOrderBy;
  shopPlanOption_variance_order_by: ShopPlanOptionVarianceOrderBy;
  shopPlan_aggregate_order_by: ShopPlanAggregateOrderBy;
  shopPlan_avg_order_by: ShopPlanAvgOrderBy;
  shopPlan_bool_exp: ShopPlanBoolExp;
  shopPlan_inc_input: ShopPlanIncInput;
  shopPlan_max_order_by: ShopPlanMaxOrderBy;
  shopPlan_min_order_by: ShopPlanMinOrderBy;
  shopPlan_mutation_response: ShopPlanMutationResponse;
  shopPlan_order_by: ShopPlanOrderBy;
  shopPlan_pk_columns_input: ShopPlanPkColumnsInput;
  shopPlan_set_input: ShopPlanSetInput;
  shopPlan_stddev_order_by: ShopPlanStddevOrderBy;
  shopPlan_stddev_pop_order_by: ShopPlanStddevPopOrderBy;
  shopPlan_stddev_samp_order_by: ShopPlanStddevSampOrderBy;
  shopPlan_stream_cursor_input: ShopPlanStreamCursorInput;
  shopPlan_stream_cursor_value_input: ShopPlanStreamCursorValueInput;
  shopPlan_sum_order_by: ShopPlanSumOrderBy;
  shopPlan_updates: ShopPlanUpdates;
  shopPlan_var_pop_order_by: ShopPlanVarPopOrderBy;
  shopPlan_var_samp_order_by: ShopPlanVarSampOrderBy;
  shopPlan_variance_order_by: ShopPlanVarianceOrderBy;
  shop_aggregate_order_by: ShopAggregateOrderBy;
  shop_arr_rel_insert_input: ShopArrRelInsertInput;
  shop_avg_order_by: ShopAvgOrderBy;
  shop_bool_exp: ShopBoolExp;
  shop_inc_input: ShopIncInput;
  shop_insert_input: ShopInsertInput;
  shop_max_order_by: ShopMaxOrderBy;
  shop_min_order_by: ShopMinOrderBy;
  shop_mutation_response: ShopMutationResponse;
  shop_obj_rel_insert_input: ShopObjRelInsertInput;
  shop_on_conflict: ShopOnConflict;
  shop_order_by: ShopOrderBy;
  shop_pk_columns_input: ShopPkColumnsInput;
  shop_set_input: ShopSetInput;
  shop_stddev_order_by: ShopStddevOrderBy;
  shop_stddev_pop_order_by: ShopStddevPopOrderBy;
  shop_stddev_samp_order_by: ShopStddevSampOrderBy;
  shop_stream_cursor_input: ShopStreamCursorInput;
  shop_stream_cursor_value_input: ShopStreamCursorValueInput;
  shop_sum_order_by: ShopSumOrderBy;
  shop_updates: ShopUpdates;
  shop_var_pop_order_by: ShopVarPopOrderBy;
  shop_var_samp_order_by: ShopVarSampOrderBy;
  shop_variance_order_by: ShopVarianceOrderBy;
  specificTimeSurchargeConfig: SpecificTimeSurchargeConfig;
  specificTimeSurchargeConfig_aggregate_order_by: SpecificTimeSurchargeConfigAggregateOrderBy;
  specificTimeSurchargeConfig_avg_order_by: SpecificTimeSurchargeConfigAvgOrderBy;
  specificTimeSurchargeConfig_bool_exp: SpecificTimeSurchargeConfigBoolExp;
  specificTimeSurchargeConfig_inc_input: SpecificTimeSurchargeConfigIncInput;
  specificTimeSurchargeConfig_insert_input: SpecificTimeSurchargeConfigInsertInput;
  specificTimeSurchargeConfig_max_order_by: SpecificTimeSurchargeConfigMaxOrderBy;
  specificTimeSurchargeConfig_min_order_by: SpecificTimeSurchargeConfigMinOrderBy;
  specificTimeSurchargeConfig_mutation_response: SpecificTimeSurchargeConfigMutationResponse;
  specificTimeSurchargeConfig_obj_rel_insert_input: SpecificTimeSurchargeConfigObjRelInsertInput;
  specificTimeSurchargeConfig_on_conflict: SpecificTimeSurchargeConfigOnConflict;
  specificTimeSurchargeConfig_order_by: SpecificTimeSurchargeConfigOrderBy;
  specificTimeSurchargeConfig_pk_columns_input: SpecificTimeSurchargeConfigPkColumnsInput;
  specificTimeSurchargeConfig_set_input: SpecificTimeSurchargeConfigSetInput;
  specificTimeSurchargeConfig_stddev_order_by: SpecificTimeSurchargeConfigStddevOrderBy;
  specificTimeSurchargeConfig_stddev_pop_order_by: SpecificTimeSurchargeConfigStddevPopOrderBy;
  specificTimeSurchargeConfig_stddev_samp_order_by: SpecificTimeSurchargeConfigStddevSampOrderBy;
  specificTimeSurchargeConfig_stream_cursor_input: SpecificTimeSurchargeConfigStreamCursorInput;
  specificTimeSurchargeConfig_stream_cursor_value_input: SpecificTimeSurchargeConfigStreamCursorValueInput;
  specificTimeSurchargeConfig_sum_order_by: SpecificTimeSurchargeConfigSumOrderBy;
  specificTimeSurchargeConfig_updates: SpecificTimeSurchargeConfigUpdates;
  specificTimeSurchargeConfig_var_pop_order_by: SpecificTimeSurchargeConfigVarPopOrderBy;
  specificTimeSurchargeConfig_var_samp_order_by: SpecificTimeSurchargeConfigVarSampOrderBy;
  specificTimeSurchargeConfig_variance_order_by: SpecificTimeSurchargeConfigVarianceOrderBy;
  specificTimeSurchargeMeta: SpecificTimeSurchargeMeta;
  specificTimeSurchargeMeta_bool_exp: SpecificTimeSurchargeMetaBoolExp;
  specificTimeSurchargeMeta_order_by: SpecificTimeSurchargeMetaOrderBy;
  specificTimeSurchargeMeta_stream_cursor_input: SpecificTimeSurchargeMetaStreamCursorInput;
  specificTimeSurchargeMeta_stream_cursor_value_input: SpecificTimeSurchargeMetaStreamCursorValueInput;
  steraOnSitePaymentDetailType: SteraOnSitePaymentDetailType;
  steraOnSitePaymentDetailType_aggregate_order_by: SteraOnSitePaymentDetailTypeAggregateOrderBy;
  steraOnSitePaymentDetailType_arr_rel_insert_input: SteraOnSitePaymentDetailTypeArrRelInsertInput;
  steraOnSitePaymentDetailType_bool_exp: SteraOnSitePaymentDetailTypeBoolExp;
  steraOnSitePaymentDetailType_insert_input: SteraOnSitePaymentDetailTypeInsertInput;
  steraOnSitePaymentDetailType_max_order_by: SteraOnSitePaymentDetailTypeMaxOrderBy;
  steraOnSitePaymentDetailType_min_order_by: SteraOnSitePaymentDetailTypeMinOrderBy;
  steraOnSitePaymentDetailType_mutation_response: SteraOnSitePaymentDetailTypeMutationResponse;
  steraOnSitePaymentDetailType_obj_rel_insert_input: SteraOnSitePaymentDetailTypeObjRelInsertInput;
  steraOnSitePaymentDetailType_on_conflict: SteraOnSitePaymentDetailTypeOnConflict;
  steraOnSitePaymentDetailType_order_by: SteraOnSitePaymentDetailTypeOrderBy;
  steraOnSitePaymentDetailType_pk_columns_input: SteraOnSitePaymentDetailTypePkColumnsInput;
  steraOnSitePaymentDetailType_set_input: SteraOnSitePaymentDetailTypeSetInput;
  steraOnSitePaymentDetailType_stream_cursor_input: SteraOnSitePaymentDetailTypeStreamCursorInput;
  steraOnSitePaymentDetailType_stream_cursor_value_input: SteraOnSitePaymentDetailTypeStreamCursorValueInput;
  steraOnSitePaymentDetailType_updates: SteraOnSitePaymentDetailTypeUpdates;
  steraPaymentMethodType_enum_comparison_exp: SteraPaymentMethodTypeEnumComparisonExp;
  stock: Stock;
  stock_aggregate_order_by: StockAggregateOrderBy;
  stock_arr_rel_insert_input: StockArrRelInsertInput;
  stock_avg_order_by: StockAvgOrderBy;
  stock_bool_exp: StockBoolExp;
  stock_inc_input: StockIncInput;
  stock_insert_input: StockInsertInput;
  stock_max_order_by: StockMaxOrderBy;
  stock_min_order_by: StockMinOrderBy;
  stock_mutation_response: StockMutationResponse;
  stock_on_conflict: StockOnConflict;
  stock_order_by: StockOrderBy;
  stock_pk_columns_input: StockPkColumnsInput;
  stock_set_input: StockSetInput;
  stock_stddev_order_by: StockStddevOrderBy;
  stock_stddev_pop_order_by: StockStddevPopOrderBy;
  stock_stddev_samp_order_by: StockStddevSampOrderBy;
  stock_stream_cursor_input: StockStreamCursorInput;
  stock_stream_cursor_value_input: StockStreamCursorValueInput;
  stock_sum_order_by: StockSumOrderBy;
  stock_updates: StockUpdates;
  stock_var_pop_order_by: StockVarPopOrderBy;
  stock_var_samp_order_by: StockVarSampOrderBy;
  stock_variance_order_by: StockVarianceOrderBy;
  subscription_root: {};
  supportedLocale: SupportedLocale;
  supportedLocale_bool_exp: SupportedLocaleBoolExp;
  supportedLocale_enum_comparison_exp: SupportedLocaleEnumComparisonExp;
  supportedLocale_order_by: SupportedLocaleOrderBy;
  supportedLocale_stream_cursor_input: SupportedLocaleStreamCursorInput;
  supportedLocale_stream_cursor_value_input: SupportedLocaleStreamCursorValueInput;
  table: Table;
  tableArea: TableArea;
  tableArea_aggregate: TableAreaAggregate;
  tableArea_aggregate_bool_exp: TableAreaAggregateBoolExp;
  tableArea_aggregate_bool_exp_count: TableAreaAggregateBoolExpCount;
  tableArea_aggregate_fields: TableAreaAggregateFields;
  tableArea_aggregate_order_by: TableAreaAggregateOrderBy;
  tableArea_arr_rel_insert_input: TableAreaArrRelInsertInput;
  tableArea_avg_fields: TableAreaAvgFields;
  tableArea_avg_order_by: TableAreaAvgOrderBy;
  tableArea_bool_exp: TableAreaBoolExp;
  tableArea_inc_input: TableAreaIncInput;
  tableArea_insert_input: TableAreaInsertInput;
  tableArea_max_fields: TableAreaMaxFields;
  tableArea_max_order_by: TableAreaMaxOrderBy;
  tableArea_min_fields: TableAreaMinFields;
  tableArea_min_order_by: TableAreaMinOrderBy;
  tableArea_mutation_response: TableAreaMutationResponse;
  tableArea_obj_rel_insert_input: TableAreaObjRelInsertInput;
  tableArea_on_conflict: TableAreaOnConflict;
  tableArea_order_by: TableAreaOrderBy;
  tableArea_pk_columns_input: TableAreaPkColumnsInput;
  tableArea_set_input: TableAreaSetInput;
  tableArea_stddev_fields: TableAreaStddevFields;
  tableArea_stddev_order_by: TableAreaStddevOrderBy;
  tableArea_stddev_pop_fields: TableAreaStddevPopFields;
  tableArea_stddev_pop_order_by: TableAreaStddevPopOrderBy;
  tableArea_stddev_samp_fields: TableAreaStddevSampFields;
  tableArea_stddev_samp_order_by: TableAreaStddevSampOrderBy;
  tableArea_stream_cursor_input: TableAreaStreamCursorInput;
  tableArea_stream_cursor_value_input: TableAreaStreamCursorValueInput;
  tableArea_sum_fields: TableAreaSumFields;
  tableArea_sum_order_by: TableAreaSumOrderBy;
  tableArea_updates: TableAreaUpdates;
  tableArea_var_pop_fields: TableAreaVarPopFields;
  tableArea_var_pop_order_by: TableAreaVarPopOrderBy;
  tableArea_var_samp_fields: TableAreaVarSampFields;
  tableArea_var_samp_order_by: TableAreaVarSampOrderBy;
  tableArea_variance_fields: TableAreaVarianceFields;
  tableArea_variance_order_by: TableAreaVarianceOrderBy;
  tableUser: TableUser;
  tableUserCustomer: TableUserCustomer;
  tableUserCustomer_aggregate_order_by: TableUserCustomerAggregateOrderBy;
  tableUserCustomer_bool_exp: TableUserCustomerBoolExp;
  tableUserCustomer_max_order_by: TableUserCustomerMaxOrderBy;
  tableUserCustomer_min_order_by: TableUserCustomerMinOrderBy;
  tableUserCustomer_order_by: TableUserCustomerOrderBy;
  tableUserCustomer_stream_cursor_input: TableUserCustomerStreamCursorInput;
  tableUserCustomer_stream_cursor_value_input: TableUserCustomerStreamCursorValueInput;
  tableUserState_enum_comparison_exp: TableUserStateEnumComparisonExp;
  tableUser_aggregate_order_by: TableUserAggregateOrderBy;
  tableUser_avg_order_by: TableUserAvgOrderBy;
  tableUser_bool_exp: TableUserBoolExp;
  tableUser_max_order_by: TableUserMaxOrderBy;
  tableUser_min_order_by: TableUserMinOrderBy;
  tableUser_order_by: TableUserOrderBy;
  tableUser_stddev_order_by: TableUserStddevOrderBy;
  tableUser_stddev_pop_order_by: TableUserStddevPopOrderBy;
  tableUser_stddev_samp_order_by: TableUserStddevSampOrderBy;
  tableUser_stream_cursor_input: TableUserStreamCursorInput;
  tableUser_stream_cursor_value_input: TableUserStreamCursorValueInput;
  tableUser_sum_order_by: TableUserSumOrderBy;
  tableUser_var_pop_order_by: TableUserVarPopOrderBy;
  tableUser_var_samp_order_by: TableUserVarSampOrderBy;
  tableUser_variance_order_by: TableUserVarianceOrderBy;
  table_aggregate: TableAggregate;
  table_aggregate_bool_exp: TableAggregateBoolExp;
  table_aggregate_bool_exp_bool_and: TableAggregateBoolExpBoolAnd;
  table_aggregate_bool_exp_bool_or: TableAggregateBoolExpBoolOr;
  table_aggregate_bool_exp_count: TableAggregateBoolExpCount;
  table_aggregate_fields: TableAggregateFields;
  table_aggregate_order_by: TableAggregateOrderBy;
  table_arr_rel_insert_input: TableArrRelInsertInput;
  table_avg_fields: TableAvgFields;
  table_avg_order_by: TableAvgOrderBy;
  table_bool_exp: TableBoolExp;
  table_inc_input: TableIncInput;
  table_insert_input: TableInsertInput;
  table_max_fields: TableMaxFields;
  table_max_order_by: TableMaxOrderBy;
  table_min_fields: TableMinFields;
  table_min_order_by: TableMinOrderBy;
  table_mutation_response: TableMutationResponse;
  table_obj_rel_insert_input: TableObjRelInsertInput;
  table_on_conflict: TableOnConflict;
  table_order_by: TableOrderBy;
  table_pk_columns_input: TablePkColumnsInput;
  table_set_input: TableSetInput;
  table_stddev_fields: TableStddevFields;
  table_stddev_order_by: TableStddevOrderBy;
  table_stddev_pop_fields: TableStddevPopFields;
  table_stddev_pop_order_by: TableStddevPopOrderBy;
  table_stddev_samp_fields: TableStddevSampFields;
  table_stddev_samp_order_by: TableStddevSampOrderBy;
  table_stream_cursor_input: TableStreamCursorInput;
  table_stream_cursor_value_input: TableStreamCursorValueInput;
  table_sum_fields: TableSumFields;
  table_sum_order_by: TableSumOrderBy;
  table_updates: TableUpdates;
  table_var_pop_fields: TableVarPopFields;
  table_var_pop_order_by: TableVarPopOrderBy;
  table_var_samp_fields: TableVarSampFields;
  table_var_samp_order_by: TableVarSampOrderBy;
  table_variance_fields: TableVarianceFields;
  table_variance_order_by: TableVarianceOrderBy;
  taxMethod_enum_comparison_exp: TaxMethodEnumComparisonExp;
  taxOffice: TaxOffice;
  taxOffice_bool_exp: TaxOfficeBoolExp;
  taxOffice_order_by: TaxOfficeOrderBy;
  taxOffice_stream_cursor_input: TaxOfficeStreamCursorInput;
  taxOffice_stream_cursor_value_input: TaxOfficeStreamCursorValueInput;
  tecAggregationConfig: TecAggregationConfig;
  tecAggregationConfig_aggregate_order_by: TecAggregationConfigAggregateOrderBy;
  tecAggregationConfig_arr_rel_insert_input: TecAggregationConfigArrRelInsertInput;
  tecAggregationConfig_avg_order_by: TecAggregationConfigAvgOrderBy;
  tecAggregationConfig_bool_exp: TecAggregationConfigBoolExp;
  tecAggregationConfig_inc_input: TecAggregationConfigIncInput;
  tecAggregationConfig_insert_input: TecAggregationConfigInsertInput;
  tecAggregationConfig_max_order_by: TecAggregationConfigMaxOrderBy;
  tecAggregationConfig_min_order_by: TecAggregationConfigMinOrderBy;
  tecAggregationConfig_mutation_response: TecAggregationConfigMutationResponse;
  tecAggregationConfig_on_conflict: TecAggregationConfigOnConflict;
  tecAggregationConfig_order_by: TecAggregationConfigOrderBy;
  tecAggregationConfig_pk_columns_input: TecAggregationConfigPkColumnsInput;
  tecAggregationConfig_set_input: TecAggregationConfigSetInput;
  tecAggregationConfig_stddev_order_by: TecAggregationConfigStddevOrderBy;
  tecAggregationConfig_stddev_pop_order_by: TecAggregationConfigStddevPopOrderBy;
  tecAggregationConfig_stddev_samp_order_by: TecAggregationConfigStddevSampOrderBy;
  tecAggregationConfig_stream_cursor_input: TecAggregationConfigStreamCursorInput;
  tecAggregationConfig_stream_cursor_value_input: TecAggregationConfigStreamCursorValueInput;
  tecAggregationConfig_sum_order_by: TecAggregationConfigSumOrderBy;
  tecAggregationConfig_updates: TecAggregationConfigUpdates;
  tecAggregationConfig_var_pop_order_by: TecAggregationConfigVarPopOrderBy;
  tecAggregationConfig_var_samp_order_by: TecAggregationConfigVarSampOrderBy;
  tecAggregationConfig_variance_order_by: TecAggregationConfigVarianceOrderBy;
  tecAggregationIntegrationType_enum_comparison_exp: TecAggregationIntegrationTypeEnumComparisonExp;
  tecAggregationMediaMap: TecAggregationMediaMap;
  tecAggregationMediaMap_aggregate_order_by: TecAggregationMediaMapAggregateOrderBy;
  tecAggregationMediaMap_arr_rel_insert_input: TecAggregationMediaMapArrRelInsertInput;
  tecAggregationMediaMap_avg_order_by: TecAggregationMediaMapAvgOrderBy;
  tecAggregationMediaMap_bool_exp: TecAggregationMediaMapBoolExp;
  tecAggregationMediaMap_inc_input: TecAggregationMediaMapIncInput;
  tecAggregationMediaMap_insert_input: TecAggregationMediaMapInsertInput;
  tecAggregationMediaMap_max_order_by: TecAggregationMediaMapMaxOrderBy;
  tecAggregationMediaMap_min_order_by: TecAggregationMediaMapMinOrderBy;
  tecAggregationMediaMap_mutation_response: TecAggregationMediaMapMutationResponse;
  tecAggregationMediaMap_obj_rel_insert_input: TecAggregationMediaMapObjRelInsertInput;
  tecAggregationMediaMap_on_conflict: TecAggregationMediaMapOnConflict;
  tecAggregationMediaMap_order_by: TecAggregationMediaMapOrderBy;
  tecAggregationMediaMap_pk_columns_input: TecAggregationMediaMapPkColumnsInput;
  tecAggregationMediaMap_set_input: TecAggregationMediaMapSetInput;
  tecAggregationMediaMap_stddev_order_by: TecAggregationMediaMapStddevOrderBy;
  tecAggregationMediaMap_stddev_pop_order_by: TecAggregationMediaMapStddevPopOrderBy;
  tecAggregationMediaMap_stddev_samp_order_by: TecAggregationMediaMapStddevSampOrderBy;
  tecAggregationMediaMap_stream_cursor_input: TecAggregationMediaMapStreamCursorInput;
  tecAggregationMediaMap_stream_cursor_value_input: TecAggregationMediaMapStreamCursorValueInput;
  tecAggregationMediaMap_sum_order_by: TecAggregationMediaMapSumOrderBy;
  tecAggregationMediaMap_updates: TecAggregationMediaMapUpdates;
  tecAggregationMediaMap_var_pop_order_by: TecAggregationMediaMapVarPopOrderBy;
  tecAggregationMediaMap_var_samp_order_by: TecAggregationMediaMapVarSampOrderBy;
  tecAggregationMediaMap_variance_order_by: TecAggregationMediaMapVarianceOrderBy;
  tecAggregationMenu: TecAggregationMenu;
  tecAggregationMenu_bool_exp: TecAggregationMenuBoolExp;
  tecAggregationMenu_inc_input: TecAggregationMenuIncInput;
  tecAggregationMenu_insert_input: TecAggregationMenuInsertInput;
  tecAggregationMenu_mutation_response: TecAggregationMenuMutationResponse;
  tecAggregationMenu_obj_rel_insert_input: TecAggregationMenuObjRelInsertInput;
  tecAggregationMenu_on_conflict: TecAggregationMenuOnConflict;
  tecAggregationMenu_order_by: TecAggregationMenuOrderBy;
  tecAggregationMenu_pk_columns_input: TecAggregationMenuPkColumnsInput;
  tecAggregationMenu_set_input: TecAggregationMenuSetInput;
  tecAggregationMenu_stream_cursor_input: TecAggregationMenuStreamCursorInput;
  tecAggregationMenu_stream_cursor_value_input: TecAggregationMenuStreamCursorValueInput;
  tecAggregationMenu_updates: TecAggregationMenuUpdates;
  tecAggregationOnSitePaymentDetailType: TecAggregationOnSitePaymentDetailType;
  tecAggregationOnSitePaymentDetailType_aggregate_order_by: TecAggregationOnSitePaymentDetailTypeAggregateOrderBy;
  tecAggregationOnSitePaymentDetailType_arr_rel_insert_input: TecAggregationOnSitePaymentDetailTypeArrRelInsertInput;
  tecAggregationOnSitePaymentDetailType_bool_exp: TecAggregationOnSitePaymentDetailTypeBoolExp;
  tecAggregationOnSitePaymentDetailType_insert_input: TecAggregationOnSitePaymentDetailTypeInsertInput;
  tecAggregationOnSitePaymentDetailType_max_order_by: TecAggregationOnSitePaymentDetailTypeMaxOrderBy;
  tecAggregationOnSitePaymentDetailType_min_order_by: TecAggregationOnSitePaymentDetailTypeMinOrderBy;
  tecAggregationOnSitePaymentDetailType_mutation_response: TecAggregationOnSitePaymentDetailTypeMutationResponse;
  tecAggregationOnSitePaymentDetailType_on_conflict: TecAggregationOnSitePaymentDetailTypeOnConflict;
  tecAggregationOnSitePaymentDetailType_order_by: TecAggregationOnSitePaymentDetailTypeOrderBy;
  tecAggregationOnSitePaymentDetailType_pk_columns_input: TecAggregationOnSitePaymentDetailTypePkColumnsInput;
  tecAggregationOnSitePaymentDetailType_set_input: TecAggregationOnSitePaymentDetailTypeSetInput;
  tecAggregationOnSitePaymentDetailType_stream_cursor_input: TecAggregationOnSitePaymentDetailTypeStreamCursorInput;
  tecAggregationOnSitePaymentDetailType_stream_cursor_value_input: TecAggregationOnSitePaymentDetailTypeStreamCursorValueInput;
  tecAggregationOnSitePaymentDetailType_updates: TecAggregationOnSitePaymentDetailTypeUpdates;
  textTypeQuestionAnswer: TextTypeQuestionAnswer;
  textTypeQuestionAnswer_bool_exp: TextTypeQuestionAnswerBoolExp;
  textTypeQuestionAnswer_order_by: TextTypeQuestionAnswerOrderBy;
  textTypeQuestionAnswer_stream_cursor_input: TextTypeQuestionAnswerStreamCursorInput;
  textTypeQuestionAnswer_stream_cursor_value_input: TextTypeQuestionAnswerStreamCursorValueInput;
  time: Scalars['time'];
  time_comparison_exp: TimeComparisonExp;
  timestamptz: Scalars['timestamptz'];
  timestamptz_comparison_exp: TimestamptzComparisonExp;
  timetz: Scalars['timetz'];
  timetz_comparison_exp: TimetzComparisonExp;
  translation: Translation;
  translation_aggregate_order_by: TranslationAggregateOrderBy;
  translation_bool_exp: TranslationBoolExp;
  translation_max_order_by: TranslationMaxOrderBy;
  translation_min_order_by: TranslationMinOrderBy;
  translation_mutation_response: TranslationMutationResponse;
  translation_order_by: TranslationOrderBy;
  translation_pk_columns_input: TranslationPkColumnsInput;
  translation_set_input: TranslationSetInput;
  translation_stream_cursor_input: TranslationStreamCursorInput;
  translation_stream_cursor_value_input: TranslationStreamCursorValueInput;
  translation_updates: TranslationUpdates;
  uuid: Scalars['uuid'];
  uuid_comparison_exp: UuidComparisonExp;
  winboardConfig: WinboardConfig;
  winboardConfig_bool_exp: WinboardConfigBoolExp;
  winboardConfig_inc_input: WinboardConfigIncInput;
  winboardConfig_insert_input: WinboardConfigInsertInput;
  winboardConfig_mutation_response: WinboardConfigMutationResponse;
  winboardConfig_obj_rel_insert_input: WinboardConfigObjRelInsertInput;
  winboardConfig_on_conflict: WinboardConfigOnConflict;
  winboardConfig_order_by: WinboardConfigOrderBy;
  winboardConfig_pk_columns_input: WinboardConfigPkColumnsInput;
  winboardConfig_set_input: WinboardConfigSetInput;
  winboardConfig_stream_cursor_input: WinboardConfigStreamCursorInput;
  winboardConfig_stream_cursor_value_input: WinboardConfigStreamCursorValueInput;
  winboardConfig_updates: WinboardConfigUpdates;
  winboardInflowSourceTag: WinboardInflowSourceTag;
  winboardInflowSourceTag_bool_exp: WinboardInflowSourceTagBoolExp;
  winboardInflowSourceTag_insert_input: WinboardInflowSourceTagInsertInput;
  winboardInflowSourceTag_mutation_response: WinboardInflowSourceTagMutationResponse;
  winboardInflowSourceTag_obj_rel_insert_input: WinboardInflowSourceTagObjRelInsertInput;
  winboardInflowSourceTag_on_conflict: WinboardInflowSourceTagOnConflict;
  winboardInflowSourceTag_order_by: WinboardInflowSourceTagOrderBy;
  winboardInflowSourceTag_pk_columns_input: WinboardInflowSourceTagPkColumnsInput;
  winboardInflowSourceTag_set_input: WinboardInflowSourceTagSetInput;
  winboardInflowSourceTag_stream_cursor_input: WinboardInflowSourceTagStreamCursorInput;
  winboardInflowSourceTag_stream_cursor_value_input: WinboardInflowSourceTagStreamCursorValueInput;
  winboardInflowSourceTag_updates: WinboardInflowSourceTagUpdates;
  winboardMenu: WinboardMenu;
  winboardMenu_aggregate_order_by: WinboardMenuAggregateOrderBy;
  winboardMenu_arr_rel_insert_input: WinboardMenuArrRelInsertInput;
  winboardMenu_avg_order_by: WinboardMenuAvgOrderBy;
  winboardMenu_bool_exp: WinboardMenuBoolExp;
  winboardMenu_inc_input: WinboardMenuIncInput;
  winboardMenu_insert_input: WinboardMenuInsertInput;
  winboardMenu_max_order_by: WinboardMenuMaxOrderBy;
  winboardMenu_min_order_by: WinboardMenuMinOrderBy;
  winboardMenu_mutation_response: WinboardMenuMutationResponse;
  winboardMenu_on_conflict: WinboardMenuOnConflict;
  winboardMenu_order_by: WinboardMenuOrderBy;
  winboardMenu_pk_columns_input: WinboardMenuPkColumnsInput;
  winboardMenu_set_input: WinboardMenuSetInput;
  winboardMenu_stddev_order_by: WinboardMenuStddevOrderBy;
  winboardMenu_stddev_pop_order_by: WinboardMenuStddevPopOrderBy;
  winboardMenu_stddev_samp_order_by: WinboardMenuStddevSampOrderBy;
  winboardMenu_stream_cursor_input: WinboardMenuStreamCursorInput;
  winboardMenu_stream_cursor_value_input: WinboardMenuStreamCursorValueInput;
  winboardMenu_sum_order_by: WinboardMenuSumOrderBy;
  winboardMenu_updates: WinboardMenuUpdates;
  winboardMenu_var_pop_order_by: WinboardMenuVarPopOrderBy;
  winboardMenu_var_samp_order_by: WinboardMenuVarSampOrderBy;
  winboardMenu_variance_order_by: WinboardMenuVarianceOrderBy;
};

export type AsScalarDirectiveArgs = {
  key?: Maybe<Scalars['String']>;
};

export type AsScalarDirectiveResolver<Result, Parent, ContextType = any, Args = AsScalarDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type CachedDirectiveArgs = {
  ttl?: Scalars['Int'];
  refresh?: Scalars['Boolean'];
};

export type CachedDirectiveResolver<Result, Parent, ContextType = any, Args = CachedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AdyenPaymentCreateStoreMerchantCategoryResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenPaymentCreateStoreMerchantCategoryResult'] = ResolversParentTypes['AdyenPaymentCreateStoreMerchantCategoryResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenPaymentUpdateStoreResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdyenPaymentUpdateStoreResult'] = ResolversParentTypes['AdyenPaymentUpdateStoreResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AggregatedCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatedCategory'] = ResolversParentTypes['AggregatedCategory']> = {
  categoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxExcludedSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxExcludedSalesIncludingChoiceSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxIncludedSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxIncludedSalesIncludingChoiceSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AggregatedChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatedChoice'] = ResolversParentTypes['AggregatedChoice']> = {
  choiceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxIncludedSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AggregatedMenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatedMenu'] = ResolversParentTypes['AggregatedMenu']> = {
  menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['AggregatedOption']>, ParentType, ContextType>;
  totalQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxExcludedSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxExcludedSalesIncludingChoiceSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxIncludedSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxIncludedSalesIncludingChoiceSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AggregatedOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatedOption'] = ResolversParentTypes['AggregatedOption']> = {
  choices?: Resolver<Array<ResolversTypes['AggregatedChoice']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  optionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AggregatedPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatedPlan'] = ResolversParentTypes['AggregatedPlan']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['AggregatedPlanOption']>, ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxExcludedSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxExcludedSalesIncludingChoiceSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxIncludedSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxIncludedSalesIncludingChoiceSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AggregatedPlanOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatedPlanOption'] = ResolversParentTypes['AggregatedPlanOption']> = {
  choices?: Resolver<Array<ResolversTypes['AggregatedChoice']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  optionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AggregatedQuestionnaireScoreResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatedQuestionnaireScore'] = ResolversParentTypes['AggregatedQuestionnaireScore']> = {
  corporationAverageScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  dimension?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopAverageScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AggregatedSalesResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregatedSales'] = ResolversParentTypes['AggregatedSales']> = {
  dimension?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  taxExcludedCostPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  taxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  taxIncludedCostPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmbassadorTransitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AmbassadorTransition'] = ResolversParentTypes['AmbassadorTransition']> = {
  ambassadorRate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newCustomerViaAmbassadorCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArchiveMenuResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArchiveMenuResult'] = ResolversParentTypes['ArchiveMenuResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArchivePlanResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ArchivePlanResult'] = ResolversParentTypes['ArchivePlanResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AverageAndMaxSalesByDayOfWeekResolvers<ContextType = any, ParentType extends ResolversParentTypes['AverageAndMaxSalesByDayOfWeek'] = ResolversParentTypes['AverageAndMaxSalesByDayOfWeek']> = {
  averageTaxExcludedSalesAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dayOfWeek?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxTaxExcludedSalesAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CashBalanceCashDenominationCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['CashBalanceCashDenominationCount'] = ResolversParentTypes['CashBalanceCashDenominationCount']> = {
  cashDenominationCountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closeCashRegisterId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fiftyYenCoinCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fiveHundredYenCoinCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fiveThousandYenBillCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fiveYenCoinCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  oneHundredYenCoinCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  oneThousandYenBillCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  oneYenCoinCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tenThousandYenBillCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tenYenCoinCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  twoThousandYenBillCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CashManagementResolvers<ContextType = any, ParentType extends ResolversParentTypes['CashManagement'] = ResolversParentTypes['CashManagement']> = {
  actualCashAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bankDepositAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  carriedForwardReserveCashAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cashAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cashDiscrepancies?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cashSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  closeCashRegisterId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  depositAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  previousReserveCashAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  remark?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  withdrawalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyCustomerSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyCustomerSummary'] = ResolversParentTypes['CompanyCustomerSummary']> = {
  shopMembers?: Resolver<Array<ResolversTypes['VisitedLineCustomer']>, ParentType, ContextType>;
  targetDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visitedCustomerSegments?: Resolver<ResolversTypes['VisitedCustomerSegments'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanySalesAnalyticsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanySalesAnalyticsOutput'] = ResolversParentTypes['CompanySalesAnalyticsOutput']> = {
  rows?: Resolver<Array<ResolversTypes['CompanySalesAnalyticsOutputItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanySalesAnalyticsOutputItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanySalesAnalyticsOutputItem'] = ResolversParentTypes['CompanySalesAnalyticsOutputItem']> = {
  ambassadorCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  businessDaysCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  checkedInGroupCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  customerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  dinnerCustomerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  dinnerTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  dinnerTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  drinkTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  drinkTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  eatInNumPeople?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  eatInTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  eatInTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  faveYellTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  faveYellTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstTimeOrderQuantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  foodTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  foodTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  groupCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  introducedCustomerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lunchCustomerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lunchTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lunchTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  mobileOrderQuantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newCustomerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  nonMobileOrderQuantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numPeople?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  orderedQuantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  otherTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  otherTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  planTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  planTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repeatOrderQuantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repeatVisitCustomerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repeaterTableTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repeaterTableTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  salesTargetAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  takeOutNumPeople?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  takeOutTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  takeOutTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalTaxExcludedCostAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTaxExcludedNetProfitAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalTaxIncludedCostAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTaxIncludedNetProfitAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponAnalyticsPerCouponResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponAnalyticsPerCoupon'] = ResolversParentTypes['CouponAnalyticsPerCoupon']> = {
  items?: Resolver<Array<ResolversTypes['CouponAnalyticsPerCouponItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponAnalyticsPerCouponItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponAnalyticsPerCouponItem'] = ResolversParentTypes['CouponAnalyticsPerCouponItem']> = {
  couponId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  couponImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  couponName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  couponUsedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopUsages?: Resolver<Array<ResolversTypes['ShopCouponUsage']>, ParentType, ContextType>;
  visitedTableUserCustomersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedTableUserCustomersSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponAnalyticsSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['CouponAnalyticsSummary'] = ResolversParentTypes['CouponAnalyticsSummary']> = {
  couponUsedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopUsages?: Resolver<Array<ResolversTypes['ShopCouponUsage']>, ParentType, ContextType>;
  visitedTableUserCustomersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedTableUserCustomersSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCategoryOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCategoryOutput'] = ResolversParentTypes['CreateCategoryOutput']> = {
  categoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMenuOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateMenuOutput'] = ResolversParentTypes['CreateMenuOutput']> = {
  menuId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMenuRecommendationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateMenuRecommendationOutput'] = ResolversParentTypes['CreateMenuRecommendationOutput']> = {
  menuRecommendationMetaId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOptionOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateOptionOutput'] = ResolversParentTypes['CreateOptionOutput']> = {
  _optionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  optionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePlanChoiceOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePlanChoiceOutput'] = ResolversParentTypes['CreatePlanChoiceOutput']> = {
  planChoiceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePlanOptionOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePlanOptionOutput'] = ResolversParentTypes['CreatePlanOptionOutput']> = {
  planOptionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePlanOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePlanOutput'] = ResolversParentTypes['CreatePlanOutput']> = {
  planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateShopMenusResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateShopMenusResult'] = ResolversParentTypes['CreateShopMenusResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateShopPlanResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateShopPlanResult'] = ResolversParentTypes['CreateShopPlanResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateSurchargeConfigsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateSurchargeConfigsResult'] = ResolversParentTypes['CreateSurchargeConfigsResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CumulativeCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['CumulativeCount'] = ResolversParentTypes['CumulativeCount']> = {
  lineIdCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentSalesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrentSales'] = ResolversParentTypes['CurrentSales']> = {
  activeNumberOfGroup?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeNumberOfPeople?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeNumberOfTableUser?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activeUnitSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentNumberOfGroup?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentNumberOfPeople?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentTaxExcludedSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentUnitSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  salesBudgetAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tableCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentSalesesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrentSaleses'] = ResolversParentTypes['CurrentSaleses']> = {
  currentSaleses?: Resolver<Array<ResolversTypes['CurrentSales']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerCountTransitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerCountTransition'] = ResolversParentTypes['CustomerCountTransition']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newCustomer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  newCustomerViaAmbassador?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  repeater?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerDashboardKpiResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerDashboardKpi'] = ResolversParentTypes['CustomerDashboardKpi']> = {
  fluctuatingStatus?: Resolver<ResolversTypes['FluctuatingStatus'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerDashboardKpisResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerDashboardKpis'] = ResolversParentTypes['CustomerDashboardKpis']> = {
  ambassadorCount?: Resolver<ResolversTypes['CustomerDashboardKpi'], ParentType, ContextType>;
  checkInRate?: Resolver<ResolversTypes['CustomerDashboardKpi'], ParentType, ContextType>;
  customerCount?: Resolver<ResolversTypes['CustomerDashboardKpi'], ParentType, ContextType>;
  faveYellAmount?: Resolver<ResolversTypes['CustomerDashboardKpi'], ParentType, ContextType>;
  faveYellUserCount?: Resolver<ResolversTypes['CustomerDashboardKpi'], ParentType, ContextType>;
  groupCount?: Resolver<ResolversTypes['CustomerDashboardKpi'], ParentType, ContextType>;
  newCustomerViaAmbassadorCount?: Resolver<ResolversTypes['CustomerDashboardKpi'], ParentType, ContextType>;
  newLineIdCount?: Resolver<ResolversTypes['CustomerDashboardKpi'], ParentType, ContextType>;
  repeaterCount?: Resolver<ResolversTypes['CustomerDashboardKpi'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['CustomerDashboardKpi'], ParentType, ContextType>;
  salesPerCustomer?: Resolver<ResolversTypes['CustomerDashboardKpi'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerDashboardTransitionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerDashboardTransitions'] = ResolversParentTypes['CustomerDashboardTransitions']> = {
  ambassador?: Resolver<Array<ResolversTypes['AmbassadorTransition']>, ParentType, ContextType>;
  checkInRate?: Resolver<Array<ResolversTypes['GeneralTransition']>, ParentType, ContextType>;
  customerCount?: Resolver<Array<ResolversTypes['CustomerCountTransition']>, ParentType, ContextType>;
  faveYell?: Resolver<Array<ResolversTypes['FaveYellTransition']>, ParentType, ContextType>;
  newLineIdCount?: Resolver<Array<ResolversTypes['GeneralTransition']>, ParentType, ContextType>;
  repeater?: Resolver<Array<ResolversTypes['RepeaterTransition']>, ParentType, ContextType>;
  sales?: Resolver<Array<ResolversTypes['GeneralTransition']>, ParentType, ContextType>;
  salesPerCustomer?: Resolver<Array<ResolversTypes['GeneralTransition']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerFaveYellRankingResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerFaveYellRanking'] = ResolversParentTypes['CustomerFaveYellRanking']> = {
  customers?: Resolver<Array<ResolversTypes['CustomerFaveYellRankingCustomer']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerFaveYellRankingCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerFaveYellRankingCustomer'] = ResolversParentTypes['CustomerFaveYellRankingCustomer']> = {
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastFaveYellCreatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineProfileImage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineProfileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerRankingResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerRanking'] = ResolversParentTypes['CustomerRanking']> = {
  customers?: Resolver<Array<ResolversTypes['CustomerRankingCustomer']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerRankingCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerRankingCustomer'] = ResolversParentTypes['CustomerRankingCustomer']> = {
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastVisitedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineProfileImage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineProfileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DailyCashRegisterBalancingResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailyCashRegisterBalancing'] = ResolversParentTypes['DailyCashRegisterBalancing']> = {
  shopDailyCashRegisterBalancings?: Resolver<Array<ResolversTypes['ShopDailyCashRegisterBalancing']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DailySalesAmountResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailySalesAmount'] = ResolversParentTypes['DailySalesAmount']> = {
  businessDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxExcludedSalesAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DailySalesSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['DailySalesSummary'] = ResolversParentTypes['DailySalesSummary']> = {
  closeCashRegisterId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  groupCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountCreateOrUpdateGmoBankAccountResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardAccountCreateOrUpdateGmoBankAccountResult'] = ResolversParentTypes['DashboardAccountCreateOrUpdateGmoBankAccountResult']> = {
  gmoBankAccountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountCreateTecAggregationMenuOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardAccountCreateTecAggregationMenuOutput'] = ResolversParentTypes['DashboardAccountCreateTecAggregationMenuOutput']> = {
  __resolveType: TypeResolveFn<'DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode' | 'DashboardUpsertTecAggregationMenuSuccess', ParentType, ContextType>;
};

export type DashboardAccountGmoBankAccountDetailResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardAccountGmoBankAccountDetailResult'] = ResolversParentTypes['DashboardAccountGmoBankAccountDetailResult']> = {
  accountName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountType?: Resolver<ResolversTypes['GmoBankAccountType'], ParentType, ContextType>;
  bankCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  branchCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gmoBankAccountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountGmoShopConfigDetailOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardAccountGmoShopConfigDetailOutput'] = ResolversParentTypes['DashboardAccountGmoShopConfigDetailOutput']> = {
  amexCardFeeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dinersCardFeeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gmoShopId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gmoShopPassword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jcbCardFeeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  masterCardFeeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  visaCardFeeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountUpsertDiniiPayConfigOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardAccountUpsertDiniiPayConfigOutput'] = ResolversParentTypes['DashboardAccountUpsertDiniiPayConfigOutput']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountUpsertTecAggregationMenusBulkOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardAccountUpsertTecAggregationMenusBulkOutput'] = ResolversParentTypes['DashboardAccountUpsertTecAggregationMenusBulkOutput']> = {
  __resolveType: TypeResolveFn<'DashboardUpsertTecAggregationMenuSuccess' | 'DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode', ParentType, ContextType>;
};

export type DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode'] = ResolversParentTypes['DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode']> = {
  dpCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dpName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gpCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gpName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  menuCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardUpsertTecAggregationMenuSuccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardUpsertTecAggregationMenuSuccess'] = ResolversParentTypes['DashboardUpsertTecAggregationMenuSuccess']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode'] = ResolversParentTypes['DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode']> = {
  dashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode?: Resolver<Array<ResolversTypes['DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeleteShopChoicesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteShopChoicesResult'] = ResolversParentTypes['DeleteShopChoicesResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteShopMenusResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteShopMenusResult'] = ResolversParentTypes['DeleteShopMenusResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteShopPlanChoicesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteShopPlanChoicesResult'] = ResolversParentTypes['DeleteShopPlanChoicesResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteShopPlansResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteShopPlansResult'] = ResolversParentTypes['DeleteShopPlansResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistributionItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['DistributionItem'] = ResolversParentTypes['DistributionItem']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EbicaApiTableResolvers<ContextType = any, ParentType extends ResolversParentTypes['EbicaApiTable'] = ResolversParentTypes['EbicaApiTable']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maximumCapacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minimumCapacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnableOnlinePaymentOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnableOnlinePaymentOutput'] = ResolversParentTypes['EnableOnlinePaymentOutput']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FaveYellAnalyticsByCorporationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['FaveYellAnalyticsByCorporationOutput'] = ResolversParentTypes['FaveYellAnalyticsByCorporationOutput']> = {
  items?: Resolver<Array<ResolversTypes['FaveYellAnalyticsPerStaffByCorporationItem']>, ParentType, ContextType>;
  summary?: Resolver<Array<ResolversTypes['FaveYellAnalyticsSummaryByCorporationOutputItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FaveYellAnalyticsPerStaffByCorporationItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['FaveYellAnalyticsPerStaffByCorporationItem'] = ResolversParentTypes['FaveYellAnalyticsPerStaffByCorporationItem']> = {
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  corporationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  faveYellCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  faveYellCustomerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  faveYellSalesRank?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  staffImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  staffName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalTaxIncludedSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FaveYellAnalyticsSummaryByCorporationOutputItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['FaveYellAnalyticsSummaryByCorporationOutputItem'] = ResolversParentTypes['FaveYellAnalyticsSummaryByCorporationOutputItem']> = {
  corporationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  faveYellCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  faveYellCustomerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxIncludedSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FaveYellTransitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FaveYellTransition'] = ResolversParentTypes['FaveYellTransition']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  faveYellAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  faveYellUserCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneralTransitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeneralTransition'] = ResolversParentTypes['GeneralTransition']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IssueLineReportingBotVerificationCodeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['IssueLineReportingBotVerificationCodeResponse'] = ResolversParentTypes['IssueLineReportingBotVerificationCodeResponse']> = {
  expiredAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lineReportingBotVerificationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verificationCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineOfficialAccountProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['LineOfficialAccountProfile'] = ResolversParentTypes['LineOfficialAccountProfile']> = {
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineOfficialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pictureUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineOfficialAccountSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['LineOfficialAccountSummary'] = ResolversParentTypes['LineOfficialAccountSummary']> = {
  blocks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  channelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isInvalidAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lineId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineOfficialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pictureUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quotaLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  targetedReaches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuOrderItemAnalyticsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuOrderItemAnalyticsOutput'] = ResolversParentTypes['MenuOrderItemAnalyticsOutput']> = {
  rows?: Resolver<Array<ResolversTypes['MenuOrderItemAnalyticsOutputItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuOrderItemAnalyticsOutputItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['MenuOrderItemAnalyticsOutputItem'] = ResolversParentTypes['MenuOrderItemAnalyticsOutputItem']> = {
  businessOperationHourType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  categoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  categoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstTimeOrderQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isRepeaterTable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  menuName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  menuType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderedQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  repeatOrderQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxExcludedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxExcludedCostAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxExcludedNetProfitAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxIncludedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxIncludedCostAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTaxIncludedNetProfitAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryAnalyticsRecentVisitedTableUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageDeliveryAnalyticsRecentVisitedTableUser'] = ResolversParentTypes['MessageDeliveryAnalyticsRecentVisitedTableUser']> = {
  customerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerProfileImages?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  numPeople?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  visitedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryAnalyticsRecentVisitedTableUsersReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageDeliveryAnalyticsRecentVisitedTableUsersReport'] = ResolversParentTypes['MessageDeliveryAnalyticsRecentVisitedTableUsersReport']> = {
  recentVisitedTableUsers?: Resolver<Array<ResolversTypes['MessageDeliveryAnalyticsRecentVisitedTableUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryAnalyticsSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageDeliveryAnalyticsSummary'] = ResolversParentTypes['MessageDeliveryAnalyticsSummary']> = {
  messageOpenCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  messageSentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedCustomersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedTableUserCustomersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedTableUserCustomersSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryAnalyticsSummaryPerMessageDeliveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageDeliveryAnalyticsSummaryPerMessageDelivery'] = ResolversParentTypes['MessageDeliveryAnalyticsSummaryPerMessageDelivery']> = {
  items?: Resolver<Array<ResolversTypes['MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem'] = ResolversParentTypes['MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem']> = {
  messageOpenCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  messageSentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visitedCustomersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedTableUserCustomersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedTableUserCustomersSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryAnalyticsSummaryPerMessageDeliveryItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem'] = ResolversParentTypes['MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem']> = {
  endAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem']>, ParentType, ContextType>;
  lastMessageDeliveryJobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messageDeliveryImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageDeliveryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messageOpenCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  messageSentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  visitedCustomersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedTableUserCustomersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedTableUserCustomersSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryAnalyticsSummaryPerShopResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageDeliveryAnalyticsSummaryPerShop'] = ResolversParentTypes['MessageDeliveryAnalyticsSummaryPerShop']> = {
  items?: Resolver<Array<ResolversTypes['MessageDeliveryAnalyticsSummaryPerShopItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryAnalyticsSummaryPerShopItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageDeliveryAnalyticsSummaryPerShopItem'] = ResolversParentTypes['MessageDeliveryAnalyticsSummaryPerShopItem']> = {
  messageOpenCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  messageSentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visitedCustomersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedTableUserCustomersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedTableUserCustomersSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryConsumptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageDeliveryConsumption'] = ResolversParentTypes['MessageDeliveryConsumption']> = {
  messageDeliveryConsumption?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  messageDeliveryLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonthlySalesAmountResolvers<ContextType = any, ParentType extends ResolversParentTypes['MonthlySalesAmount'] = ResolversParentTypes['MonthlySalesAmount']> = {
  businessDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxExcludedSalesAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireAnswererAttributeMetricsDistributionItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireAnswererAttributeMetricsDistributionItem'] = ResolversParentTypes['QuestionnaireAnswererAttributeMetricsDistributionItem']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireAnswererAttributeMetricsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireAnswererAttributeMetricsOutput'] = ResolversParentTypes['QuestionnaireAnswererAttributeMetricsOutput']> = {
  distributionItems?: Resolver<Array<ResolversTypes['QuestionnaireAnswererAttributeMetricsDistributionItem']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireComment'] = ResolversParentTypes['QuestionnaireComment']> = {
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['QuestionCategory'], ParentType, ContextType>;
  checkedInCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  customerQuestionnaireId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionnaireAnswerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireCommentCountOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireCommentCountOutput'] = ResolversParentTypes['QuestionnaireCommentCountOutput']> = {
  totalCommentsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireCorporationAverageScoreResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireCorporationAverageScore'] = ResolversParentTypes['QuestionnaireCorporationAverageScore']> = {
  answerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cleanliness?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  corporationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerService?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deliciousness?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  repeatability?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  speed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics'] = ResolversParentTypes['QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics']> = {
  distributionItems?: Resolver<Array<ResolversTypes['DistributionItem']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireMetricsPerCategoryOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireMetricsPerCategoryOutput'] = ResolversParentTypes['QuestionnaireMetricsPerCategoryOutput']> = {
  questionAnswerMetrics?: Resolver<Array<ResolversTypes['QuestionnaireMetricsPerCategoryQuestionAnswerMetrics']>, ParentType, ContextType>;
  scoreDistributionMetrics?: Resolver<Maybe<ResolversTypes['QuestionnaireMetricsPerCategoryScoreDistributionMetrics']>, ParentType, ContextType>;
  scoreMetrics?: Resolver<Maybe<ResolversTypes['QuestionnaireMetricsPerCategoryScoreMetrics']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireMetricsPerCategoryQuestionAnswerMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireMetricsPerCategoryQuestionAnswerMetrics'] = ResolversParentTypes['QuestionnaireMetricsPerCategoryQuestionAnswerMetrics']> = {
  __resolveType: TypeResolveFn<'QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics' | 'QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics', ParentType, ContextType>;
};

export type QuestionnaireMetricsPerCategoryScoreDistributionMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireMetricsPerCategoryScoreDistributionMetrics'] = ResolversParentTypes['QuestionnaireMetricsPerCategoryScoreDistributionMetrics']> = {
  distributionItems?: Resolver<Array<ResolversTypes['DistributionItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireMetricsPerCategoryScoreMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireMetricsPerCategoryScoreMetrics'] = ResolversParentTypes['QuestionnaireMetricsPerCategoryScoreMetrics']> = {
  corporationAverageScore?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopAverageScore?: Resolver<ResolversTypes['QuestionnaireScoreWithFluctuation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireMetricsPerCategoryTextTypeAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireMetricsPerCategoryTextTypeAnswer'] = ResolversParentTypes['QuestionnaireMetricsPerCategoryTextTypeAnswer']> = {
  attribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerQuestionnaireId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  detail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  score?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireMetricsPerCategoryTextTypeAnswerMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics'] = ResolversParentTypes['QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics']> = {
  answers?: Resolver<Array<ResolversTypes['QuestionnaireMetricsPerCategoryTextTypeAnswer']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireScoreChangesMetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireScoreChangesMetrics'] = ResolversParentTypes['QuestionnaireScoreChangesMetrics']> = {
  cleanliness?: Resolver<Array<ResolversTypes['AggregatedQuestionnaireScore']>, ParentType, ContextType>;
  customerService?: Resolver<Array<ResolversTypes['AggregatedQuestionnaireScore']>, ParentType, ContextType>;
  deliciousness?: Resolver<Array<ResolversTypes['AggregatedQuestionnaireScore']>, ParentType, ContextType>;
  repeatability?: Resolver<Array<ResolversTypes['AggregatedQuestionnaireScore']>, ParentType, ContextType>;
  speed?: Resolver<Array<ResolversTypes['AggregatedQuestionnaireScore']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireScoreMetricsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireScoreMetricsOutput'] = ResolversParentTypes['QuestionnaireScoreMetricsOutput']> = {
  corporationAverageScore?: Resolver<ResolversTypes['QuestionnaireCorporationAverageScore'], ParentType, ContextType>;
  shopAverageScores?: Resolver<Array<ResolversTypes['QuestionnaireShopAverageScore']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireScoreWithFluctuationResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireScoreWithFluctuation'] = ResolversParentTypes['QuestionnaireScoreWithFluctuation']> = {
  fluctuation?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireShopAverageScoreResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireShopAverageScore'] = ResolversParentTypes['QuestionnaireShopAverageScore']> = {
  answerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cleanliness?: Resolver<ResolversTypes['QuestionnaireScoreWithFluctuation'], ParentType, ContextType>;
  customerService?: Resolver<ResolversTypes['QuestionnaireScoreWithFluctuation'], ParentType, ContextType>;
  deliciousness?: Resolver<ResolversTypes['QuestionnaireScoreWithFluctuation'], ParentType, ContextType>;
  repeatability?: Resolver<ResolversTypes['QuestionnaireScoreWithFluctuation'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  speed?: Resolver<ResolversTypes['QuestionnaireScoreWithFluctuation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RepeaterTransitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RepeaterTransition'] = ResolversParentTypes['RepeaterTransition']> = {
  coreRepeaterRate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  repeaterRate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Sales'] = ResolversParentTypes['Sales']> = {
  aggregatedSales?: Resolver<Array<ResolversTypes['AggregatedSales']>, ParentType, ContextType>;
  dimension?: Resolver<ResolversTypes['SalesAggregationDimension'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesAnalyticsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesAnalyticsOutput'] = ResolversParentTypes['SalesAnalyticsOutput']> = {
  rows?: Resolver<Array<ResolversTypes['SalesAnalyticsOutputItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesAnalyticsOutputItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesAnalyticsOutputItem'] = ResolversParentTypes['SalesAnalyticsOutputItem']> = {
  ambassadorCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  businessDaysCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  checkedInGroupCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  customerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  dinnerCustomerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  dinnerTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  dinnerTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  drinkTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  drinkTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  eatInNumPeople?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  eatInTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  eatInTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  faveYellTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  faveYellTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  firstTimeOrderQuantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  foodTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  foodTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  groupCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  introducedCustomerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lunchCustomerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lunchTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lunchTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  mobileOrderQuantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newCustomerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  nonMobileOrderQuantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numPeople?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  orderedQuantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  otherTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  otherTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  planTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  planTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  previousMonthSameDowTotalTaxExcludedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  previousMonthSameDowTotalTaxIncludedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  previousMonthTotalTaxExcludedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  previousMonthTotalTaxIncludedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  previousYearSameDowTotalTaxExcludedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  previousYearSameDowTotalTaxIncludedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  previousYearTotalTaxExcludedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  previousYearTotalTaxIncludedAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  repeatOrderQuantity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repeatVisitCustomerCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repeaterTableTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  repeaterTableTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  salesTargetAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  takeOutNumPeople?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  takeOutTotalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  takeOutTotalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalTaxExcludedCostAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTaxExcludedNetProfitAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalTaxIncludedCostAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalTaxIncludedNetProfitAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesBreakdownByPaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesBreakdownByPaymentMethod'] = ResolversParentTypes['SalesBreakdownByPaymentMethod']> = {
  closeCashRegisterId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  salesBreakdown?: Resolver<Array<ResolversTypes['SalesWithPaymentMethod']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesByDayOfWeekAndHourAnalyticsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesByDayOfWeekAndHourAnalyticsOutput'] = ResolversParentTypes['SalesByDayOfWeekAndHourAnalyticsOutput']> = {
  rows?: Resolver<Array<ResolversTypes['SalesByDayOfWeekAndHourAnalyticsOutputItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesByDayOfWeekAndHourAnalyticsOutputItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesByDayOfWeekAndHourAnalyticsOutputItem'] = ResolversParentTypes['SalesByDayOfWeekAndHourAnalyticsOutputItem']> = {
  dayofweek?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hour?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numPeople?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalTaxExcludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalTaxIncludedAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesByDaysResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesByDays'] = ResolversParentTypes['SalesByDays']> = {
  dailySalesAmount?: Resolver<Array<ResolversTypes['DailySalesAmount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesByMonthsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesByMonths'] = ResolversParentTypes['SalesByMonths']> = {
  monthlySalesAmount?: Resolver<Array<ResolversTypes['MonthlySalesAmount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesForBudgetResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesForBudget'] = ResolversParentTypes['SalesForBudget']> = {
  averageAndMaxSalesByDayOfWeek?: Resolver<Maybe<Array<ResolversTypes['AverageAndMaxSalesByDayOfWeek']>>, ParentType, ContextType>;
  salesByDays?: Resolver<Maybe<ResolversTypes['SalesByDays']>, ParentType, ContextType>;
  salesByMonths?: Resolver<Maybe<ResolversTypes['SalesByMonths']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesWithPaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesWithPaymentMethod'] = ResolversParentTypes['SalesWithPaymentMethod']> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SegmentedCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['SegmentedCustomer'] = ResolversParentTypes['SegmentedCustomer']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopApiAggregationGetQuantityAndSalesAggregationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShopApiAggregationGetQuantityAndSalesAggregationOutput'] = ResolversParentTypes['ShopApiAggregationGetQuantityAndSalesAggregationOutput']> = {
  aggregatedMenus?: Resolver<Array<ResolversTypes['AggregatedMenu']>, ParentType, ContextType>;
  aggregatedPlans?: Resolver<Array<ResolversTypes['AggregatedPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutput'] = ResolversParentTypes['ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutput']> = {
  aggregatedCategories?: Resolver<Array<ResolversTypes['AggregatedCategory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopCouponUsageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShopCouponUsage'] = ResolversParentTypes['ShopCouponUsage']> = {
  couponUsedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visitedTableUserCustomersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  visitedTableUserCustomersSales?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopDailyCashRegisterBalancingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShopDailyCashRegisterBalancing'] = ResolversParentTypes['ShopDailyCashRegisterBalancing']> = {
  cashDiscrepancies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  closeCashRegisterId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  depositAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalSales?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateShopMenusBulkResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateShopMenusBulkResult'] = ResolversParentTypes['UpdateShopMenusBulkResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateShopPlansBulkResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateShopPlansBulkResult'] = ResolversParentTypes['UpdateShopPlansBulkResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTargetCountOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateTargetCountOutput'] = ResolversParentTypes['UpdateTargetCountOutput']> = {
  targetCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpsertShopChoicesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpsertShopChoicesResult'] = ResolversParentTypes['UpsertShopChoicesResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpsertShopPlanChoiceResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpsertShopPlanChoiceResult'] = ResolversParentTypes['UpsertShopPlanChoiceResult']> = {
  result?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ValidLineOfficialAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['ValidLineOfficialAccount'] = ResolversParentTypes['ValidLineOfficialAccount']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineOfficialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitedCustomerSegmentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitedCustomerSegments'] = ResolversParentTypes['VisitedCustomerSegments']> = {
  first?: Resolver<ResolversTypes['SegmentedCustomer'], ParentType, ContextType>;
  nonMember?: Resolver<ResolversTypes['SegmentedCustomer'], ParentType, ContextType>;
  regular?: Resolver<ResolversTypes['SegmentedCustomer'], ParentType, ContextType>;
  repeater?: Resolver<ResolversTypes['SegmentedCustomer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitedLineCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['VisitedLineCustomer'] = ResolversParentTypes['VisitedLineCustomer']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileImage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visitedCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountingSlipImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['accountingSlipImage'] = ResolversParentTypes['accountingSlipImage']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['accountingSlipImagePosition_enum'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountingSlipImageMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['accountingSlipImage_mutation_response'] = ResolversParentTypes['accountingSlipImage_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['accountingSlipImage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivePlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['activePlan'] = ResolversParentTypes['activePlan']> = {
  _planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activatedClerk?: Resolver<Maybe<ResolversTypes['clerk']>, ParentType, ContextType>;
  activatedCustomer?: Resolver<Maybe<ResolversTypes['customer']>, ParentType, ContextType>;
  activePlanId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  activePlanOpenPriceMeta?: Resolver<Maybe<ResolversTypes['activePlanOpenPriceMeta']>, ParentType, ContextType>;
  activePlanOpenPriceMetaId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  activePlanOpenPriceMetum?: Resolver<Maybe<ResolversTypes['activePlanOpenPriceMeta']>, ParentType, ContextType>;
  activePlanOptions?: Resolver<Array<ResolversTypes['activePlanOption']>, ParentType, ContextType, Partial<ActivePlanActivePlanOptionsArgs>>;
  canceledAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  endAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['plan']>, ParentType, ContextType>;
  planCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  planId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  receiptDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  remark?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  startAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  tableUser?: Resolver<Maybe<ResolversTypes['tableUser']>, ParentType, ContextType>;
  tableUserId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tableUsersInGroup?: Resolver<Array<ResolversTypes['tableUser']>, ParentType, ContextType, Partial<ActivePlanTableUsersInGroupArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivePlanChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['activePlanChoice'] = ResolversParentTypes['activePlanChoice']> = {
  _choiceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activePlanChoiceId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  activePlanOption?: Resolver<ResolversTypes['activePlanOption'], ParentType, ContextType>;
  activePlanOptionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paidNum?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payingActivePlanChoices?: Resolver<Array<ResolversTypes['payingActivePlanChoice']>, ParentType, ContextType, Partial<ActivePlanChoicePayingActivePlanChoicesArgs>>;
  planChoice?: Resolver<Maybe<ResolversTypes['planChoice']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  receiptDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivePlanOpenPriceMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['activePlanOpenPriceMeta'] = ResolversParentTypes['activePlanOpenPriceMeta']> = {
  activePlan?: Resolver<ResolversTypes['activePlan'], ParentType, ContextType>;
  activePlanById?: Resolver<Maybe<ResolversTypes['activePlan']>, ParentType, ContextType>;
  activePlanId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  paidNum?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payingActivePlanOpenPriceMeta?: Resolver<Array<ResolversTypes['payingActivePlanOpenPriceMeta']>, ParentType, ContextType, Partial<ActivePlanOpenPriceMetaPayingActivePlanOpenPriceMetaArgs>>;
  price?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivePlanOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['activePlanOption'] = ResolversParentTypes['activePlanOption']> = {
  _optionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activePlan?: Resolver<ResolversTypes['activePlan'], ParentType, ContextType>;
  activePlanChoices?: Resolver<Array<ResolversTypes['activePlanChoice']>, ParentType, ContextType, Partial<ActivePlanOptionActivePlanChoicesArgs>>;
  activePlanId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  activePlanOptionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  isMainOption?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  planOption?: Resolver<Maybe<ResolversTypes['planOption']>, ParentType, ContextType>;
  receiptDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenPaymentReportTerminalPaymentAuthorizedEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['adyenPaymentReportTerminalPaymentAuthorizedEvent'] = ResolversParentTypes['adyenPaymentReportTerminalPaymentAuthorizedEvent']> = {
  adyenPaymentReportTerminalPaymentCanceledEvent?: Resolver<Maybe<ResolversTypes['adyenPaymentReportTerminalPaymentCanceledEvent']>, ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  merchantAccountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  merchantReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentMethod?: Resolver<ResolversTypes['adyenPaymentMethodType_enum'], ParentType, ContextType>;
  pspReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  terminalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenPaymentReportTerminalPaymentCanceledEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['adyenPaymentReportTerminalPaymentCanceledEvent'] = ResolversParentTypes['adyenPaymentReportTerminalPaymentCanceledEvent']> = {
  adyenPaymentReportTerminalPaymentAuthorizedEvent?: Resolver<ResolversTypes['adyenPaymentReportTerminalPaymentAuthorizedEvent'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  originalReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenPaymentShopConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['adyenPaymentShopConfig'] = ResolversParentTypes['adyenPaymentShopConfig']> = {
  adyenPaymentShopPaymentMethods?: Resolver<Array<ResolversTypes['adyenPaymentShopPaymentMethod']>, ParentType, ContextType, Partial<AdyenPaymentShopConfigAdyenPaymentShopPaymentMethodsArgs>>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenPaymentShopPaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['adyenPaymentShopPaymentMethod'] = ResolversParentTypes['adyenPaymentShopPaymentMethod']> = {
  adyenPaymentShopConfig?: Resolver<ResolversTypes['adyenPaymentShopConfig'], ParentType, ContextType>;
  adyenPaymentShopConfigId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  paymentMethod?: Resolver<ResolversTypes['adyenPaymentMethodType_enum'], ParentType, ContextType>;
  transactionFeeRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenPaymentStoreMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['adyenPaymentStoreMeta'] = ResolversParentTypes['adyenPaymentStoreMeta']> = {
  address1?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address2?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address3?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopNameEn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  telephoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenPaymentStoreMetaMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['adyenPaymentStoreMeta_mutation_response'] = ResolversParentTypes['adyenPaymentStoreMeta_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['adyenPaymentStoreMeta']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenTerminalPaymentShopConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['adyenTerminalPaymentShopConfig'] = ResolversParentTypes['adyenTerminalPaymentShopConfig']> = {
  adyenTerminalPaymentShopPaymentMethods?: Resolver<Array<ResolversTypes['adyenTerminalPaymentShopPaymentMethod']>, ParentType, ContextType, Partial<AdyenTerminalPaymentShopConfigAdyenTerminalPaymentShopPaymentMethodsArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdyenTerminalPaymentShopPaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['adyenTerminalPaymentShopPaymentMethod'] = ResolversParentTypes['adyenTerminalPaymentShopPaymentMethod']> = {
  adyenTerminalPaymentShopConfig?: Resolver<ResolversTypes['adyenTerminalPaymentShopConfig'], ParentType, ContextType>;
  adyenTerminalPaymentShopConfigId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  paymentMethod?: Resolver<ResolversTypes['adyenPaymentMethodType_enum'], ParentType, ContextType>;
  transactionFeeRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsSettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['analyticsSetting'] = ResolversParentTypes['analyticsSetting']> = {
  company?: Resolver<Maybe<ResolversTypes['company']>, ParentType, ContextType>;
  corporation?: Resolver<ResolversTypes['corporation'], ParentType, ContextType>;
  corporationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  displayTaxIncluded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsSettingMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['analyticsSetting_mutation_response'] = ResolversParentTypes['analyticsSetting_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['analyticsSetting']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigintScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['bigint'], any> {
  name: 'bigint';
}

export type BirthdayTypeQuestionAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['birthdayTypeQuestionAnswer'] = ResolversParentTypes['birthdayTypeQuestionAnswer']> = {
  birthday?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  questionAnswer?: Resolver<Maybe<ResolversTypes['questionAnswer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CashRegisterConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['cashRegisterConfig'] = ResolversParentTypes['cashRegisterConfig']> = {
  drawerOpenPassword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enableRequiringPasswordForDrawerOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  useStera?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CashRegisterConfigAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['cashRegisterConfig_aggregate'] = ResolversParentTypes['cashRegisterConfig_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['cashRegisterConfig_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['cashRegisterConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CashRegisterConfigAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cashRegisterConfig_aggregate_fields'] = ResolversParentTypes['cashRegisterConfig_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CashRegisterConfigAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['cashRegisterConfig_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['cashRegisterConfig_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CashRegisterConfigMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cashRegisterConfig_max_fields'] = ResolversParentTypes['cashRegisterConfig_max_fields']> = {
  drawerOpenPassword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shopId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CashRegisterConfigMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['cashRegisterConfig_min_fields'] = ResolversParentTypes['cashRegisterConfig_min_fields']> = {
  drawerOpenPassword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shopId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CashRegisterConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['cashRegisterConfig_mutation_response'] = ResolversParentTypes['cashRegisterConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['cashRegisterConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CashRegisterSecurityLevelResolvers<ContextType = any, ParentType extends ResolversParentTypes['cashRegisterSecurityLevel'] = ResolversParentTypes['cashRegisterSecurityLevel']> = {
  shops?: Resolver<Array<ResolversTypes['shop']>, ParentType, ContextType, Partial<CashRegisterSecurityLevelShopsArgs>>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['category'] = ResolversParentTypes['category']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  categoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  categoryMenus?: Resolver<Array<ResolversTypes['categoryMenu']>, ParentType, ContextType, Partial<CategoryCategoryMenusArgs>>;
  categoryMenus_aggregate?: Resolver<ResolversTypes['categoryMenu_aggregate'], ParentType, ContextType, Partial<CategoryCategoryMenusAggregateArgs>>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  menuRecommendationMeta?: Resolver<Maybe<ResolversTypes['menuRecommendationMeta']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  planMenuCategories?: Resolver<Array<ResolversTypes['planMenuCategory']>, ParentType, ContextType, Partial<CategoryPlanMenuCategoriesArgs>>;
  plan_menu_categories_categories?: Resolver<Array<ResolversTypes['plan_menu_categories_category']>, ParentType, ContextType, Partial<CategoryPlanMenuCategoriesCategoriesArgs>>;
  plans?: Resolver<Array<ResolversTypes['plan']>, ParentType, ContextType, Partial<CategoryPlansArgs>>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopSideBackgroundColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopSideName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  translations?: Resolver<Array<ResolversTypes['translation']>, ParentType, ContextType, Partial<CategoryTranslationsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu'] = ResolversParentTypes['categoryMenu']> = {
  _categoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['category'], ParentType, ContextType>;
  categoryId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  categoryMenuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  displayType?: Resolver<ResolversTypes['displayType_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  planFirstOrderCategoryMenus?: Resolver<Array<ResolversTypes['planFirstOrderCategoryMenu']>, ParentType, ContextType, Partial<CategoryMenuPlanFirstOrderCategoryMenusArgs>>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_aggregate'] = ResolversParentTypes['categoryMenu_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['categoryMenu_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['categoryMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_aggregate_fields'] = ResolversParentTypes['categoryMenu_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['categoryMenu_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CategoryMenuAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['categoryMenu_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['categoryMenu_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['categoryMenu_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['categoryMenu_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['categoryMenu_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['categoryMenu_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['categoryMenu_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['categoryMenu_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['categoryMenu_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_avg_fields'] = ResolversParentTypes['categoryMenu_avg_fields']> = {
  _categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categoryMenuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_max_fields'] = ResolversParentTypes['categoryMenu_max_fields']> = {
  _categoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  categoryMenuId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  menuId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_min_fields'] = ResolversParentTypes['categoryMenu_min_fields']> = {
  _categoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  categoryMenuId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  menuId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_mutation_response'] = ResolversParentTypes['categoryMenu_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['categoryMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_stddev_fields'] = ResolversParentTypes['categoryMenu_stddev_fields']> = {
  _categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categoryMenuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_stddev_pop_fields'] = ResolversParentTypes['categoryMenu_stddev_pop_fields']> = {
  _categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categoryMenuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_stddev_samp_fields'] = ResolversParentTypes['categoryMenu_stddev_samp_fields']> = {
  _categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categoryMenuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_sum_fields'] = ResolversParentTypes['categoryMenu_sum_fields']> = {
  _categoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  categoryMenuId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_var_pop_fields'] = ResolversParentTypes['categoryMenu_var_pop_fields']> = {
  _categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categoryMenuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_var_samp_fields'] = ResolversParentTypes['categoryMenu_var_samp_fields']> = {
  _categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categoryMenuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMenuVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['categoryMenu_variance_fields'] = ResolversParentTypes['categoryMenu_variance_fields']> = {
  _categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  categoryMenuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_aggregate'] = ResolversParentTypes['category_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['category_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['category']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_aggregate_fields'] = ResolversParentTypes['category_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['category_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CategoryAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['category_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['category_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['category_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['category_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['category_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['category_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['category_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['category_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['category_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_avg_fields'] = ResolversParentTypes['category_avg_fields']> = {
  categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_max_fields'] = ResolversParentTypes['category_max_fields']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  companyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopSideBackgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopSideName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_min_fields'] = ResolversParentTypes['category_min_fields']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  companyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopSideBackgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shopSideName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_mutation_response'] = ResolversParentTypes['category_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['category']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_stddev_fields'] = ResolversParentTypes['category_stddev_fields']> = {
  categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_stddev_pop_fields'] = ResolversParentTypes['category_stddev_pop_fields']> = {
  categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_stddev_samp_fields'] = ResolversParentTypes['category_stddev_samp_fields']> = {
  categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategorySumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_sum_fields'] = ResolversParentTypes['category_sum_fields']> = {
  categoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_var_pop_fields'] = ResolversParentTypes['category_var_pop_fields']> = {
  categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_var_samp_fields'] = ResolversParentTypes['category_var_samp_fields']> = {
  categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['category_variance_fields'] = ResolversParentTypes['category_variance_fields']> = {
  categoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CheckInEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['checkInEvent'] = ResolversParentTypes['checkInEvent']> = {
  checkedInAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['customer'], ParentType, ContextType>;
  customerQuestionnaire?: Resolver<Maybe<ResolversTypes['customerQuestionnaire']>, ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tableUser?: Resolver<Maybe<ResolversTypes['tableUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice'] = ResolversParentTypes['choice']> = {
  _optionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  choiceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  foodingJournalMenu?: Resolver<Maybe<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isDefaultSelection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  menuType?: Resolver<Maybe<ResolversTypes['menuType_enum']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  option?: Resolver<ResolversTypes['option'], ParentType, ContextType>;
  optionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pikaichiMenuChoices?: Resolver<Array<ResolversTypes['pikaichiMenuChoice']>, ParentType, ContextType, Partial<ChoicePikaichiMenuChoicesArgs>>;
  posChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  receiptDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopChoices?: Resolver<Array<ResolversTypes['shopChoice']>, ParentType, ContextType, Partial<ChoiceShopChoicesArgs>>;
  tecAggregationMenu?: Resolver<Maybe<ResolversTypes['tecAggregationMenu']>, ParentType, ContextType>;
  translations?: Resolver<Array<ResolversTypes['translation']>, ParentType, ContextType, Partial<ChoiceTranslationsArgs>>;
  winboardMenus?: Resolver<Array<ResolversTypes['winboardMenu']>, ParentType, ContextType, Partial<ChoiceWinboardMenusArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_aggregate'] = ResolversParentTypes['choice_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['choice_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['choice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_aggregate_fields'] = ResolversParentTypes['choice_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['choice_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<ChoiceAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['choice_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['choice_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['choice_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['choice_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['choice_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['choice_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['choice_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['choice_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['choice_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_avg_fields'] = ResolversParentTypes['choice_avg_fields']> = {
  _optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_max_fields'] = ResolversParentTypes['choice_max_fields']> = {
  _optionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  posChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receiptDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_min_fields'] = ResolversParentTypes['choice_min_fields']> = {
  _optionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  posChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receiptDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_mutation_response'] = ResolversParentTypes['choice_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['choice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_stddev_fields'] = ResolversParentTypes['choice_stddev_fields']> = {
  _optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_stddev_pop_fields'] = ResolversParentTypes['choice_stddev_pop_fields']> = {
  _optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_stddev_samp_fields'] = ResolversParentTypes['choice_stddev_samp_fields']> = {
  _optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_sum_fields'] = ResolversParentTypes['choice_sum_fields']> = {
  _optionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  posChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_var_pop_fields'] = ResolversParentTypes['choice_var_pop_fields']> = {
  _optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_var_samp_fields'] = ResolversParentTypes['choice_var_samp_fields']> = {
  _optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChoiceVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['choice_variance_fields'] = ResolversParentTypes['choice_variance_fields']> = {
  _optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClerkResolvers<ContextType = any, ParentType extends ResolversParentTypes['clerk'] = ResolversParentTypes['clerk']> = {
  clearedTableUsers?: Resolver<Array<ResolversTypes['tableUser']>, ParentType, ContextType, Partial<ClerkClearedTableUsersArgs>>;
  clerkId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  closeCashRegisters?: Resolver<Array<ResolversTypes['closeCashRegister']>, ParentType, ContextType, Partial<ClerkCloseCashRegistersArgs>>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDetails?: Resolver<Array<ResolversTypes['onSitePaymentDetail']>, ParentType, ContextType, Partial<ClerkOnSitePaymentDetailsArgs>>;
  onSitePaymentDiscounts?: Resolver<Array<ResolversTypes['onSitePaymentDiscount']>, ParentType, ContextType, Partial<ClerkOnSitePaymentDiscountsArgs>>;
  onSitePayments?: Resolver<Array<ResolversTypes['onSitePayment']>, ParentType, ContextType, Partial<ClerkOnSitePaymentsArgs>>;
  receipts?: Resolver<Array<ResolversTypes['receipt']>, ParentType, ContextType, Partial<ClerkReceiptsArgs>>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopClerks?: Resolver<Array<ResolversTypes['shopClerk']>, ParentType, ContextType, Partial<ClerkShopClerksArgs>>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClerkMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['clerk_mutation_response'] = ResolversParentTypes['clerk_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['clerk']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CloseCashRegisterResolvers<ContextType = any, ParentType extends ResolversParentTypes['closeCashRegister'] = ResolversParentTypes['closeCashRegister']> = {
  _clerkId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _closeCashRegisterId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  carriedForwardReserveCashAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  carriedForwardReserveCashDenominationCountId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  cashDenominationCountId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  clerk?: Resolver<ResolversTypes['clerk'], ParentType, ContextType>;
  clerkId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  coineySettlements?: Resolver<Array<ResolversTypes['coineySettlement']>, ParentType, ContextType, Partial<CloseCashRegisterCoineySettlementsArgs>>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  excludedFromAggregation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  onSitePayments?: Resolver<Array<ResolversTypes['onSitePayment']>, ParentType, ContextType, Partial<CloseCashRegisterOnSitePaymentsArgs>>;
  reserveCashId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  targetDate?: Resolver<ResolversTypes['date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CloseCashRegisterMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['closeCashRegister_mutation_response'] = ResolversParentTypes['closeCashRegister_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['closeCashRegister']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoineyPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['coineyPayment'] = ResolversParentTypes['coineyPayment']> = {
  canceledAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  coineyPaymentId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  coineyPaymentOrders?: Resolver<Array<ResolversTypes['coineyPaymentOrder']>, ParentType, ContextType, Partial<CoineyPaymentCoineyPaymentOrdersArgs>>;
  coineyPaymentSalesList?: Resolver<Array<ResolversTypes['coineyPaymentSales']>, ParentType, ContextType, Partial<CoineyPaymentCoineyPaymentSalesListArgs>>;
  coineySettlement?: Resolver<Maybe<ResolversTypes['coineySettlement']>, ParentType, ContextType>;
  coineySettlements?: Resolver<Array<ResolversTypes['coineySettlement']>, ParentType, ContextType, Partial<CoineyPaymentCoineySettlementsArgs>>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tableUser?: Resolver<ResolversTypes['tableUser'], ParentType, ContextType>;
  tableUserId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoineyPaymentOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['coineyPaymentOrder'] = ResolversParentTypes['coineyPaymentOrder']> = {
  _coineyPaymentId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  coineyPayment?: Resolver<ResolversTypes['coineyPayment'], ParentType, ContextType>;
  coineyPaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  coineyPaymentOrderId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoineyPaymentSalesResolvers<ContextType = any, ParentType extends ResolversParentTypes['coineyPaymentSales'] = ResolversParentTypes['coineyPaymentSales']> = {
  _coineyPaymentId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  coineyPayment?: Resolver<ResolversTypes['coineyPayment'], ParentType, ContextType>;
  coineyPaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  taxExcludedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxIncludedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoineyPaymentMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['coineyPayment_mutation_response'] = ResolversParentTypes['coineyPayment_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['coineyPayment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoineySettlementResolvers<ContextType = any, ParentType extends ResolversParentTypes['coineySettlement'] = ResolversParentTypes['coineySettlement']> = {
  _coineyPaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardBrand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chargeDate?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  closeCashRegister?: Resolver<Maybe<ResolversTypes['closeCashRegister']>, ParentType, ContextType>;
  coineyPayment?: Resolver<Maybe<ResolversTypes['coineyPayment']>, ParentType, ContextType>;
  coineyPaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  emoneyBrand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  humanReadableIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  identifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  transactionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['company'] = ResolversParentTypes['company']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['category']>, ParentType, ContextType, Partial<CompanyCategoriesArgs>>;
  categories_aggregate?: Resolver<ResolversTypes['category_aggregate'], ParentType, ContextType, Partial<CompanyCategoriesAggregateArgs>>;
  clerks?: Resolver<Array<ResolversTypes['clerk']>, ParentType, ContextType, Partial<CompanyClerksArgs>>;
  companyId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  connectGameConfig?: Resolver<Maybe<ResolversTypes['connectGameConfig']>, ParentType, ContextType>;
  cookingItems?: Resolver<Array<ResolversTypes['cookingItem']>, ParentType, ContextType, Partial<CompanyCookingItemsArgs>>;
  corporation?: Resolver<Maybe<ResolversTypes['corporation']>, ParentType, ContextType>;
  corporationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  coupons?: Resolver<Array<ResolversTypes['coupon']>, ParentType, ContextType, Partial<CompanyCouponsArgs>>;
  dashboardAccountAccessibleCompanies?: Resolver<Array<ResolversTypes['dashboardAccountAccessibleCompany']>, ParentType, ContextType, Partial<CompanyDashboardAccountAccessibleCompaniesArgs>>;
  defaultCostTaxMethod?: Resolver<ResolversTypes['taxMethod_enum'], ParentType, ContextType>;
  defaultMenuTaxMethod?: Resolver<ResolversTypes['taxMethod_enum'], ParentType, ContextType>;
  enableAutoTranslation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableDarkTheme?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inflowSourceTags?: Resolver<Array<ResolversTypes['inflowSourceTag']>, ParentType, ContextType, Partial<CompanyInflowSourceTagsArgs>>;
  inflowSourceTags_aggregate?: Resolver<ResolversTypes['inflowSourceTag_aggregate'], ParentType, ContextType, Partial<CompanyInflowSourceTagsAggregateArgs>>;
  lineOfficialAccounts?: Resolver<Array<ResolversTypes['lineOfficialAccount']>, ParentType, ContextType, Partial<CompanyLineOfficialAccountsArgs>>;
  membershipCard?: Resolver<Maybe<ResolversTypes['membershipCard']>, ParentType, ContextType>;
  menus?: Resolver<Array<ResolversTypes['menu']>, ParentType, ContextType, Partial<CompanyMenusArgs>>;
  messageDeliveries?: Resolver<Array<ResolversTypes['messageDelivery']>, ParentType, ContextType, Partial<CompanyMessageDeliveriesArgs>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDetailTypes?: Resolver<Array<ResolversTypes['onSitePaymentDetailType']>, ParentType, ContextType, Partial<CompanyOnSitePaymentDetailTypesArgs>>;
  onSitePaymentDetailTypes_aggregate?: Resolver<ResolversTypes['onSitePaymentDetailType_aggregate'], ParentType, ContextType, Partial<CompanyOnSitePaymentDetailTypesAggregateArgs>>;
  onSitePaymentDetails?: Resolver<Array<ResolversTypes['onSitePaymentDetail']>, ParentType, ContextType, Partial<CompanyOnSitePaymentDetailsArgs>>;
  onSitePaymentDiscountTypes?: Resolver<Array<ResolversTypes['onSitePaymentDiscountType']>, ParentType, ContextType, Partial<CompanyOnSitePaymentDiscountTypesArgs>>;
  onSitePaymentDiscountTypes_aggregate?: Resolver<ResolversTypes['onSitePaymentDiscountType_aggregate'], ParentType, ContextType, Partial<CompanyOnSitePaymentDiscountTypesAggregateArgs>>;
  onSitePaymentDiscounts?: Resolver<Array<ResolversTypes['onSitePaymentDiscount']>, ParentType, ContextType, Partial<CompanyOnSitePaymentDiscountsArgs>>;
  options?: Resolver<Array<ResolversTypes['option']>, ParentType, ContextType, Partial<CompanyOptionsArgs>>;
  options_aggregate?: Resolver<ResolversTypes['option_aggregate'], ParentType, ContextType, Partial<CompanyOptionsAggregateArgs>>;
  orderableTimes?: Resolver<Array<ResolversTypes['orderableTime']>, ParentType, ContextType, Partial<CompanyOrderableTimesArgs>>;
  pikaichiOnSitePaymentDetailTypes?: Resolver<Array<ResolversTypes['pikaichiOnSitePaymentDetailType']>, ParentType, ContextType, Partial<CompanyPikaichiOnSitePaymentDetailTypesArgs>>;
  pikaichiOnSitePaymentDiscountTypes?: Resolver<Array<ResolversTypes['pikaichiOnSitePaymentDiscountType']>, ParentType, ContextType, Partial<CompanyPikaichiOnSitePaymentDiscountTypesArgs>>;
  planGroups?: Resolver<Array<ResolversTypes['planGroup']>, ParentType, ContextType, Partial<CompanyPlanGroupsArgs>>;
  plans?: Resolver<Array<ResolversTypes['plan']>, ParentType, ContextType, Partial<CompanyPlansArgs>>;
  questionnaireConfigs?: Resolver<Array<ResolversTypes['questionnaireConfig']>, ParentType, ContextType, Partial<CompanyQuestionnaireConfigsArgs>>;
  questions?: Resolver<Array<ResolversTypes['question']>, ParentType, ContextType, Partial<CompanyQuestionsArgs>>;
  roles?: Resolver<Array<ResolversTypes['role']>, ParentType, ContextType, Partial<CompanyRolesArgs>>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  serviceChargeMeta?: Resolver<Maybe<ResolversTypes['serviceChargeMeta']>, ParentType, ContextType>;
  shopClients?: Resolver<Array<ResolversTypes['shopClient']>, ParentType, ContextType, Partial<CompanyShopClientsArgs>>;
  shopDiscountTypes?: Resolver<Array<ResolversTypes['shopDiscountType']>, ParentType, ContextType, Partial<CompanyShopDiscountTypesArgs>>;
  shopPaymentTypes?: Resolver<Array<ResolversTypes['shopPaymentType']>, ParentType, ContextType, Partial<CompanyShopPaymentTypesArgs>>;
  shops?: Resolver<Array<ResolversTypes['shop']>, ParentType, ContextType, Partial<CompanyShopsArgs>>;
  specificTimeSurchargeMeta?: Resolver<Maybe<ResolversTypes['specificTimeSurchargeMeta']>, ParentType, ContextType>;
  steraOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['steraOnSitePaymentDetailType']>, ParentType, ContextType>;
  steraOnSitePaymentDetailTypes?: Resolver<Array<ResolversTypes['steraOnSitePaymentDetailType']>, ParentType, ContextType, Partial<CompanySteraOnSitePaymentDetailTypesArgs>>;
  tableUsers?: Resolver<Array<ResolversTypes['tableUser']>, ParentType, ContextType, Partial<CompanyTableUsersArgs>>;
  tecAggregationMediaMaps?: Resolver<Array<ResolversTypes['tecAggregationMediaMap']>, ParentType, ContextType, Partial<CompanyTecAggregationMediaMapsArgs>>;
  tecAggregationOnSitePaymentDetailTypes?: Resolver<Array<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, ParentType, ContextType, Partial<CompanyTecAggregationOnSitePaymentDetailTypesArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['companyMember'] = ResolversParentTypes['companyMember']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['customer'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  membershipRank?: Resolver<ResolversTypes['membershipRank_enum'], ParentType, ContextType>;
  membershipRankByMembershiprank?: Resolver<ResolversTypes['membershipRank'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyMemberAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['companyMember_aggregate'] = ResolversParentTypes['companyMember_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['companyMember_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['companyMember']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyMemberAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['companyMember_aggregate_fields'] = ResolversParentTypes['companyMember_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CompanyMemberAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['companyMember_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['companyMember_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyMemberMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['companyMember_max_fields'] = ResolversParentTypes['companyMember_max_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyMemberMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['companyMember_min_fields'] = ResolversParentTypes['companyMember_min_fields']> = {
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['company_mutation_response'] = ResolversParentTypes['company_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['company']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompulsoryAppetizerConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['compulsoryAppetizerConfig'] = ResolversParentTypes['compulsoryAppetizerConfig']> = {
  autoOrderEndTime?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  autoOrderStartTime?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompulsoryAppetizerConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['compulsoryAppetizerConfig_mutation_response'] = ResolversParentTypes['compulsoryAppetizerConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['compulsoryAppetizerConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectGameConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['connectGameConfig'] = ResolversParentTypes['connectGameConfig']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  connectGameConfigShops?: Resolver<Array<ResolversTypes['connectGameConfigShop']>, ParentType, ContextType, Partial<ConnectGameConfigConnectGameConfigShopsArgs>>;
  consolationCoupon?: Resolver<Maybe<ResolversTypes['coupon']>, ParentType, ContextType>;
  consolationCouponId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isDemo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxWinningRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  prizeCoupon?: Resolver<ResolversTypes['coupon'], ParentType, ContextType>;
  prizeCouponId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  startImageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectGameConfigShopResolvers<ContextType = any, ParentType extends ResolversParentTypes['connectGameConfigShop'] = ResolversParentTypes['connectGameConfigShop']> = {
  connectGameConfig?: Resolver<ResolversTypes['connectGameConfig'], ParentType, ContextType>;
  connectGameConfigId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectGameConfigShopMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['connectGameConfigShop_mutation_response'] = ResolversParentTypes['connectGameConfigShop_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['connectGameConfigShop']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectGameConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['connectGameConfig_mutation_response'] = ResolversParentTypes['connectGameConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['connectGameConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CookingItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['cookingItem'] = ResolversParentTypes['cookingItem']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  cookingItemId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopCookingItemRole?: Resolver<Maybe<ResolversTypes['shopCookingItemRole']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopMenuCookingItems?: Resolver<Array<ResolversTypes['shopMenuCookingItem']>, ParentType, ContextType, Partial<CookingItemShopMenuCookingItemsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CookingItemMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['cookingItem_mutation_response'] = ResolversParentTypes['cookingItem_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['cookingItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CorporationResolvers<ContextType = any, ParentType extends ResolversParentTypes['corporation'] = ResolversParentTypes['corporation']> = {
  companies?: Resolver<Array<ResolversTypes['company']>, ParentType, ContextType, Partial<CorporationCompaniesArgs>>;
  dashboardAccounts?: Resolver<Array<ResolversTypes['dashboardAccount']>, ParentType, ContextType, Partial<CorporationDashboardAccountsArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CorporationMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['corporation_mutation_response'] = ResolversParentTypes['corporation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['corporation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponResolvers<ContextType = any, ParentType extends ResolversParentTypes['coupon'] = ResolversParentTypes['coupon']> = {
  availableDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  availableFrom?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  availableUntil?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  consolationCouponGameConfigs?: Resolver<Array<ResolversTypes['connectGameConfig']>, ParentType, ContextType, Partial<CouponConsolationCouponGameConfigsArgs>>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  couponAppliedEvents?: Resolver<Array<ResolversTypes['couponAppliedEvent']>, ParentType, ContextType, Partial<CouponCouponAppliedEventsArgs>>;
  couponAppliedEvents_aggregate?: Resolver<ResolversTypes['couponAppliedEvent_aggregate'], ParentType, ContextType, Partial<CouponCouponAppliedEventsAggregateArgs>>;
  couponMenuDiscount?: Resolver<Maybe<ResolversTypes['couponMenuDiscount']>, ParentType, ContextType>;
  couponMessages?: Resolver<Array<ResolversTypes['messageDeliveryMessageCouponTypeMeta']>, ParentType, ContextType, Partial<CouponCouponMessagesArgs>>;
  couponType?: Resolver<ResolversTypes['couponType_enum'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  membershipRankConfigs?: Resolver<Array<ResolversTypes['membershipRankConfig']>, ParentType, ContextType, Partial<CouponMembershipRankConfigsArgs>>;
  monetaryValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prizeCouponGameConfigs?: Resolver<Array<ResolversTypes['connectGameConfig']>, ParentType, ContextType, Partial<CouponPrizeCouponGameConfigsArgs>>;
  questionnaireConfigs?: Resolver<Array<ResolversTypes['questionnaireConfig']>, ParentType, ContextType, Partial<CouponQuestionnaireConfigsArgs>>;
  termsOfUse?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponAppliedEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['couponAppliedEvent'] = ResolversParentTypes['couponAppliedEvent']> = {
  appliedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  coupon?: Resolver<ResolversTypes['coupon'], ParentType, ContextType>;
  couponId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  couponIssuer?: Resolver<Maybe<ResolversTypes['couponIssuer_enum']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDelivery?: Resolver<Maybe<ResolversTypes['messageDelivery']>, ParentType, ContextType>;
  messageDeliveryId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponAppliedEventAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['couponAppliedEvent_aggregate'] = ResolversParentTypes['couponAppliedEvent_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['couponAppliedEvent_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['couponAppliedEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponAppliedEventAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['couponAppliedEvent_aggregate_fields'] = ResolversParentTypes['couponAppliedEvent_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<CouponAppliedEventAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['couponAppliedEvent_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['couponAppliedEvent_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponAppliedEventMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['couponAppliedEvent_max_fields'] = ResolversParentTypes['couponAppliedEvent_max_fields']> = {
  appliedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  couponId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  messageDeliveryId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponAppliedEventMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['couponAppliedEvent_min_fields'] = ResolversParentTypes['couponAppliedEvent_min_fields']> = {
  appliedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  couponId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  messageDeliveryId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponMenuDiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['couponMenuDiscount'] = ResolversParentTypes['couponMenuDiscount']> = {
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  coupon?: Resolver<ResolversTypes['coupon'], ParentType, ContextType>;
  couponId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  discountedPrice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponMenuDiscountMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['couponMenuDiscount_mutation_response'] = ResolversParentTypes['couponMenuDiscount_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['couponMenuDiscount']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['coupon_mutation_response'] = ResolversParentTypes['coupon_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['coupon']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['customer'] = ResolversParentTypes['customer']> = {
  activePlans?: Resolver<Array<ResolversTypes['activePlan']>, ParentType, ContextType, Partial<CustomerActivePlansArgs>>;
  checkInEvents?: Resolver<Array<ResolversTypes['checkInEvent']>, ParentType, ContextType, Partial<CustomerCheckInEventsArgs>>;
  customerListCustomers?: Resolver<Array<ResolversTypes['messageDeliveryCustomerListCustomer']>, ParentType, ContextType, Partial<CustomerCustomerListCustomersArgs>>;
  customerListCustomers_aggregate?: Resolver<ResolversTypes['messageDeliveryCustomerListCustomer_aggregate'], ParentType, ContextType, Partial<CustomerCustomerListCustomersAggregateArgs>>;
  customerQuestionnaires?: Resolver<Array<ResolversTypes['customerQuestionnaire']>, ParentType, ContextType, Partial<CustomerCustomerQuestionnairesArgs>>;
  emailAndPasswordVerificationInfoId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineProfile?: Resolver<Maybe<ResolversTypes['lineProfile']>, ParentType, ContextType>;
  menuOrderItems?: Resolver<Array<ResolversTypes['menuOrderItem']>, ParentType, ContextType, Partial<CustomerMenuOrderItemsArgs>>;
  orders?: Resolver<Array<ResolversTypes['order']>, ParentType, ContextType, Partial<CustomerOrdersArgs>>;
  questionnaireAnswers?: Resolver<Array<ResolversTypes['questionnaireAnswer']>, ParentType, ContextType, Partial<CustomerQuestionnaireAnswersArgs>>;
  shopMembers?: Resolver<Array<ResolversTypes['shopMember']>, ParentType, ContextType, Partial<CustomerShopMembersArgs>>;
  tableUserCustomers?: Resolver<Array<ResolversTypes['tableUserCustomer']>, ParentType, ContextType, Partial<CustomerTableUserCustomersArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerQuestionnaireResolvers<ContextType = any, ParentType extends ResolversParentTypes['customerQuestionnaire'] = ResolversParentTypes['customerQuestionnaire']> = {
  checkInEvent?: Resolver<Maybe<ResolversTypes['checkInEvent']>, ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['customer'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  questionnaire?: Resolver<Maybe<ResolversTypes['questionnaire']>, ParentType, ContextType>;
  questionnaireAnswer?: Resolver<Maybe<ResolversTypes['questionnaireAnswer']>, ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DailySalesBudgetResolvers<ContextType = any, ParentType extends ResolversParentTypes['dailySalesBudget'] = ResolversParentTypes['dailySalesBudget']> = {
  businessDate?: Resolver<ResolversTypes['date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  monthlySalesBudget?: Resolver<ResolversTypes['monthlySalesBudget'], ParentType, ContextType>;
  monthlySalesBudgetId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  taxExcludedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DailySalesBudgetMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['dailySalesBudget_mutation_response'] = ResolversParentTypes['dailySalesBudget_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['dailySalesBudget']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['dashboardAccount'] = ResolversParentTypes['dashboardAccount']> = {
  corporation?: Resolver<Maybe<ResolversTypes['corporation']>, ParentType, ContextType>;
  corporationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dashboardAccountAccessibleCompanies?: Resolver<Array<ResolversTypes['dashboardAccountAccessibleCompany']>, ParentType, ContextType, Partial<DashboardAccountDashboardAccountAccessibleCompaniesArgs>>;
  dashboardAccountAccessibleShops?: Resolver<Array<ResolversTypes['dashboardAccountAccessibleShop']>, ParentType, ContextType, Partial<DashboardAccountDashboardAccountAccessibleShopsArgs>>;
  dashboardAccountRole?: Resolver<ResolversTypes['dashboardAccountRole'], ParentType, ContextType>;
  dashboardAccountRoleType?: Resolver<ResolversTypes['dashboardAccountRoleType'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['dashboardAccountRoleType_enum'], ParentType, ContextType>;
  userName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountAccessibleCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['dashboardAccountAccessibleCompany'] = ResolversParentTypes['dashboardAccountAccessibleCompany']> = {
  company?: Resolver<Maybe<ResolversTypes['company']>, ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  dashboardAccount?: Resolver<ResolversTypes['dashboardAccount'], ParentType, ContextType>;
  dashboardAccountId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountAccessibleShopResolvers<ContextType = any, ParentType extends ResolversParentTypes['dashboardAccountAccessibleShop'] = ResolversParentTypes['dashboardAccountAccessibleShop']> = {
  dashboardAccount?: Resolver<ResolversTypes['dashboardAccount'], ParentType, ContextType>;
  dashboardAccountId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['dashboardAccountRole'] = ResolversParentTypes['dashboardAccountRole']> = {
  corporation?: Resolver<ResolversTypes['corporation'], ParentType, ContextType>;
  corporationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  dashboardAccountRolePermissions?: Resolver<Array<ResolversTypes['dashboardAccountRolePermission']>, ParentType, ContextType, Partial<DashboardAccountRoleDashboardAccountRolePermissionsArgs>>;
  dashboardAccounts?: Resolver<Array<ResolversTypes['dashboardAccount']>, ParentType, ContextType, Partial<DashboardAccountRoleDashboardAccountsArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountRolePermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['dashboardAccountRolePermission'] = ResolversParentTypes['dashboardAccountRolePermission']> = {
  dashboardAccountRole?: Resolver<ResolversTypes['dashboardAccountRole'], ParentType, ContextType>;
  dashboardAccountRoleId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  feature?: Resolver<ResolversTypes['dashboardFeature_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['dashboardAccountRolePermissionType_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountRolePermissionMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['dashboardAccountRolePermission_mutation_response'] = ResolversParentTypes['dashboardAccountRolePermission_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['dashboardAccountRolePermission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountRoleTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['dashboardAccountRoleType'] = ResolversParentTypes['dashboardAccountRoleType']> = {
  dashboardAccounts?: Resolver<Array<ResolversTypes['dashboardAccount']>, ParentType, ContextType, Partial<DashboardAccountRoleTypeDashboardAccountsArgs>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DashboardAccountRoleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['dashboardAccountRole_mutation_response'] = ResolversParentTypes['dashboardAccountRole_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['dashboardAccountRole']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['date'], any> {
  name: 'date';
}

export type DishUpSlipGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['dishUpSlipGroup'] = ResolversParentTypes['dishUpSlipGroup']> = {
  dishUpSlipGroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  dishUpSlipGroupRoles?: Resolver<Array<ResolversTypes['dishUpSlipGroupRoles']>, ParentType, ContextType, Partial<DishUpSlipGroupDishUpSlipGroupRolesArgs>>;
  dishUpSlipGroupShopMenus?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopMenus']>, ParentType, ContextType, Partial<DishUpSlipGroupDishUpSlipGroupShopMenusArgs>>;
  dishUpSlipGroupShopPlans?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopPlans']>, ParentType, ContextType, Partial<DishUpSlipGroupDishUpSlipGroupShopPlansArgs>>;
  dishUpSlipGroupTables?: Resolver<Array<ResolversTypes['dishUpSlipGroupTables']>, ParentType, ContextType, Partial<DishUpSlipGroupDishUpSlipGroupTablesArgs>>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DishUpSlipGroupRolesResolvers<ContextType = any, ParentType extends ResolversParentTypes['dishUpSlipGroupRoles'] = ResolversParentTypes['dishUpSlipGroupRoles']> = {
  _dishUpSlipGroupId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _roleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dishUpSlipGroup?: Resolver<Maybe<ResolversTypes['dishUpSlipGroup']>, ParentType, ContextType>;
  dishUpSlipGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['role'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DishUpSlipGroupRolesMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['dishUpSlipGroupRoles_mutation_response'] = ResolversParentTypes['dishUpSlipGroupRoles_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['dishUpSlipGroupRoles']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DishUpSlipGroupShopMenusResolvers<ContextType = any, ParentType extends ResolversParentTypes['dishUpSlipGroupShopMenus'] = ResolversParentTypes['dishUpSlipGroupShopMenus']> = {
  _dishUpSlipGroupId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dishUpSlipGroup?: Resolver<Maybe<ResolversTypes['dishUpSlipGroup']>, ParentType, ContextType>;
  dishUpSlipGroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopMenu?: Resolver<Maybe<ResolversTypes['shopMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DishUpSlipGroupShopMenusMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['dishUpSlipGroupShopMenus_mutation_response'] = ResolversParentTypes['dishUpSlipGroupShopMenus_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopMenus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DishUpSlipGroupShopPlansResolvers<ContextType = any, ParentType extends ResolversParentTypes['dishUpSlipGroupShopPlans'] = ResolversParentTypes['dishUpSlipGroupShopPlans']> = {
  _dishUpSlipGroupId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dishUpSlipGroup?: Resolver<Maybe<ResolversTypes['dishUpSlipGroup']>, ParentType, ContextType>;
  dishUpSlipGroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['plan'], ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopPlan?: Resolver<Maybe<ResolversTypes['shopPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DishUpSlipGroupShopPlansMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['dishUpSlipGroupShopPlans_mutation_response'] = ResolversParentTypes['dishUpSlipGroupShopPlans_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopPlans']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DishUpSlipGroupTablesResolvers<ContextType = any, ParentType extends ResolversParentTypes['dishUpSlipGroupTables'] = ResolversParentTypes['dishUpSlipGroupTables']> = {
  _dishUpSlipGroupId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _tableId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dishUpSlipGroup?: Resolver<Maybe<ResolversTypes['dishUpSlipGroup']>, ParentType, ContextType>;
  dishUpSlipGroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['table']>, ParentType, ContextType>;
  tableId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DishUpSlipGroupTablesMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['dishUpSlipGroupTables_mutation_response'] = ResolversParentTypes['dishUpSlipGroupTables_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['dishUpSlipGroupTables']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DishUpSlipGroupMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['dishUpSlipGroup_mutation_response'] = ResolversParentTypes['dishUpSlipGroup_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['dishUpSlipGroup']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EbicaConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['ebicaConfig'] = ResolversParentTypes['ebicaConfig']> = {
  ebicaConfigAccessibleDashboardAccounts?: Resolver<Array<ResolversTypes['reservationDashboardAccountAccessibleShop']>, ParentType, ContextType, Partial<EbicaConfigEbicaConfigAccessibleDashboardAccountsArgs>>;
  ebicaShopId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  walkInStayTimeInMinute?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EbicaConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ebicaConfig_mutation_response'] = ResolversParentTypes['ebicaConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ebicaConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EbicaTableResolvers<ContextType = any, ParentType extends ResolversParentTypes['ebicaTable'] = ResolversParentTypes['ebicaTable']> = {
  ebicaTableAccessibleDashboardAccounts?: Resolver<Array<ResolversTypes['reservationDashboardAccountAccessibleShop']>, ParentType, ContextType, Partial<EbicaTableEbicaTableAccessibleDashboardAccountsArgs>>;
  ebicaTableId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<Maybe<ResolversTypes['shop']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tableId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EbicaTableMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ebicaTable_mutation_response'] = ResolversParentTypes['ebicaTable_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['ebicaTable']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalOnlineMenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['externalOnlineMenu'] = ResolversParentTypes['externalOnlineMenu']> = {
  externalOnlineMenuAvailableTimeTerms?: Resolver<Array<ResolversTypes['externalOnlineMenuAvailableTimeTerm']>, ParentType, ContextType, Partial<ExternalOnlineMenuExternalOnlineMenuAvailableTimeTermsArgs>>;
  externalOnlineMenuConfig?: Resolver<ResolversTypes['externalOnlineMenuConfig'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalOnlineMenuAvailableTimeTermResolvers<ContextType = any, ParentType extends ResolversParentTypes['externalOnlineMenuAvailableTimeTerm'] = ResolversParentTypes['externalOnlineMenuAvailableTimeTerm']> = {
  dayWeek?: Resolver<ResolversTypes['orderableTimeTermDayWeek_enum'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  externalOnlineMenu?: Resolver<ResolversTypes['externalOnlineMenu'], ParentType, ContextType>;
  externalOnlineMenuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalOnlineMenuAvailableTimeTermMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['externalOnlineMenuAvailableTimeTerm_mutation_response'] = ResolversParentTypes['externalOnlineMenuAvailableTimeTerm_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['externalOnlineMenuAvailableTimeTerm']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalOnlineMenuConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['externalOnlineMenuConfig'] = ResolversParentTypes['externalOnlineMenuConfig']> = {
  defaultMenuUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalOnlineMenus?: Resolver<Array<ResolversTypes['externalOnlineMenu']>, ParentType, ContextType, Partial<ExternalOnlineMenuConfigExternalOnlineMenusArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalOnlineMenuConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['externalOnlineMenuConfig_mutation_response'] = ResolversParentTypes['externalOnlineMenuConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['externalOnlineMenuConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalOnlineMenuMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['externalOnlineMenu_mutation_response'] = ResolversParentTypes['externalOnlineMenu_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['externalOnlineMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureFlagResolvers<ContextType = any, ParentType extends ResolversParentTypes['featureFlag'] = ResolversParentTypes['featureFlag']> = {
  featureFlagShops?: Resolver<Array<ResolversTypes['featureFlagShop']>, ParentType, ContextType, Partial<FeatureFlagFeatureFlagShopsArgs>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureFlagShopResolvers<ContextType = any, ParentType extends ResolversParentTypes['featureFlagShop'] = ResolversParentTypes['featureFlagShop']> = {
  availableFrom?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  availableUntil?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  featureFlag?: Resolver<ResolversTypes['featureFlag'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['featureFlag_enum'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureFlagShopMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['featureFlagShop_mutation_response'] = ResolversParentTypes['featureFlagShop_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['featureFlagShop']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalConfig'] = ResolversParentTypes['foodingJournalConfig']> = {
  configId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  configPassword?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  configSecretKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inputUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalConfig_mutation_response'] = ResolversParentTypes['foodingJournalConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['foodingJournalConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalDepartmentMasterResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalDepartmentMaster'] = ResolversParentTypes['foodingJournalDepartmentMaster']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  foodingJournalMenus?: Resolver<Array<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType, Partial<FoodingJournalDepartmentMasterFoodingJournalMenusArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalDepartmentMasterMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalDepartmentMaster_mutation_response'] = ResolversParentTypes['foodingJournalDepartmentMaster_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['foodingJournalDepartmentMaster']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalGroupMasterResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalGroupMaster'] = ResolversParentTypes['foodingJournalGroupMaster']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  foodingJournalMenus?: Resolver<Array<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType, Partial<FoodingJournalGroupMasterFoodingJournalMenusArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalGroupMasterMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalGroupMaster_mutation_response'] = ResolversParentTypes['foodingJournalGroupMaster_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['foodingJournalGroupMaster']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalMenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalMenu'] = ResolversParentTypes['foodingJournalMenu']> = {
  choiceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  foodingJournalDepartmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  foodingJournalDepartmentMaster?: Resolver<ResolversTypes['foodingJournalDepartmentMaster'], ParentType, ContextType>;
  foodingJournalGroupId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  foodingJournalGroupMaster?: Resolver<ResolversTypes['foodingJournalGroupMaster'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menuId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  planId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalMenuMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalMenu_mutation_response'] = ResolversParentTypes['foodingJournalMenu_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalOnSitePaymentDetailTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalOnSitePaymentDetailType'] = ResolversParentTypes['foodingJournalOnSitePaymentDetailType']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDetailType?: Resolver<ResolversTypes['onSitePaymentDetailType'], ParentType, ContextType>;
  onSitePaymentDetailTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  paymentType?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalOnSitePaymentDetailTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalOnSitePaymentDetailType_mutation_response'] = ResolversParentTypes['foodingJournalOnSitePaymentDetailType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['foodingJournalOnSitePaymentDetailType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalOnSitePaymentDiscountTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalOnSitePaymentDiscountType'] = ResolversParentTypes['foodingJournalOnSitePaymentDiscountType']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  discountType?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDiscountTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FoodingJournalOnSitePaymentDiscountTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['foodingJournalOnSitePaymentDiscountType_mutation_response'] = ResolversParentTypes['foodingJournalOnSitePaymentDiscountType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['foodingJournalOnSitePaymentDiscountType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta'] = ResolversParentTypes['giftMeta']> = {
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_aggregate'] = ResolversParentTypes['giftMeta_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['giftMeta_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['giftMeta']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_aggregate_fields'] = ResolversParentTypes['giftMeta_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['giftMeta_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<GiftMetaAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['giftMeta_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['giftMeta_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['giftMeta_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['giftMeta_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['giftMeta_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['giftMeta_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['giftMeta_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['giftMeta_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['giftMeta_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_avg_fields'] = ResolversParentTypes['giftMeta_avg_fields']> = {
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_max_fields'] = ResolversParentTypes['giftMeta_max_fields']> = {
  _menuId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  menuId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_min_fields'] = ResolversParentTypes['giftMeta_min_fields']> = {
  _menuId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  menuId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_mutation_response'] = ResolversParentTypes['giftMeta_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['giftMeta']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_stddev_fields'] = ResolversParentTypes['giftMeta_stddev_fields']> = {
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_stddev_pop_fields'] = ResolversParentTypes['giftMeta_stddev_pop_fields']> = {
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_stddev_samp_fields'] = ResolversParentTypes['giftMeta_stddev_samp_fields']> = {
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_sum_fields'] = ResolversParentTypes['giftMeta_sum_fields']> = {
  _menuId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_var_pop_fields'] = ResolversParentTypes['giftMeta_var_pop_fields']> = {
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_var_samp_fields'] = ResolversParentTypes['giftMeta_var_samp_fields']> = {
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftMetaVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftMeta_variance_fields'] = ResolversParentTypes['giftMeta_variance_fields']> = {
  _menuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftOptionMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftOptionMeta'] = ResolversParentTypes['giftOptionMeta']> = {
  _optionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  option?: Resolver<ResolversTypes['option'], ParentType, ContextType>;
  optionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftOptionMetaMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['giftOptionMeta_mutation_response'] = ResolversParentTypes['giftOptionMeta_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['giftOptionMeta']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GmoBankAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['gmoBankAccount'] = ResolversParentTypes['gmoBankAccount']> = {
  gmoBankAccountShops?: Resolver<Array<ResolversTypes['gmoBankAccountShop']>, ParentType, ContextType, Partial<GmoBankAccountGmoBankAccountShopsArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GmoBankAccountShopResolvers<ContextType = any, ParentType extends ResolversParentTypes['gmoBankAccountShop'] = ResolversParentTypes['gmoBankAccountShop']> = {
  depositCycleType?: Resolver<ResolversTypes['depositCycleType_enum'], ParentType, ContextType>;
  gmoBankAccount?: Resolver<ResolversTypes['gmoBankAccount'], ParentType, ContextType>;
  gmoBankAccountId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GmoOffPaymentMethodTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['gmoOffPaymentMethodType'] = ResolversParentTypes['gmoOffPaymentMethodType']> = {
  gmoOffPaymentOnSitePaymentDetailTypes?: Resolver<Array<ResolversTypes['gmoOffPaymentOnSitePaymentDetailType']>, ParentType, ContextType, Partial<GmoOffPaymentMethodTypeGmoOffPaymentOnSitePaymentDetailTypesArgs>>;
  gmoOffPaymentShopConfigTransactionFeeRates?: Resolver<Array<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate']>, ParentType, ContextType, Partial<GmoOffPaymentMethodTypeGmoOffPaymentShopConfigTransactionFeeRatesArgs>>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GmoOffPaymentOnSitePaymentDetailTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['gmoOffPaymentOnSitePaymentDetailType'] = ResolversParentTypes['gmoOffPaymentOnSitePaymentDetailType']> = {
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  gmoOffPaymentMethodType?: Resolver<ResolversTypes['gmoOffPaymentMethodType_enum'], ParentType, ContextType>;
  gmoOffPaymentMethodTypeByGmooffpaymentmethodtype?: Resolver<ResolversTypes['gmoOffPaymentMethodType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  onSitePaymentDetailTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GmoOffPaymentOnSitePaymentDetailTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['gmoOffPaymentOnSitePaymentDetailType_mutation_response'] = ResolversParentTypes['gmoOffPaymentOnSitePaymentDetailType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['gmoOffPaymentOnSitePaymentDetailType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GmoOffPaymentShopConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['gmoOffPaymentShopConfig'] = ResolversParentTypes['gmoOffPaymentShopConfig']> = {
  gmoOffPaymentShopConfigTransactionFeeRates?: Resolver<Array<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate']>, ParentType, ContextType, Partial<GmoOffPaymentShopConfigGmoOffPaymentShopConfigTransactionFeeRatesArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  terminalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GmoOffPaymentShopConfigTransactionFeeRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['gmoOffPaymentShopConfigTransactionFeeRate'] = ResolversParentTypes['gmoOffPaymentShopConfigTransactionFeeRate']> = {
  gmoOffPaymentMethodType?: Resolver<ResolversTypes['gmoOffPaymentMethodType'], ParentType, ContextType>;
  gmoOffPaymentShopConfig?: Resolver<ResolversTypes['gmoOffPaymentShopConfig'], ParentType, ContextType>;
  gmoOffPaymentShopConfigId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  paymentMethodType?: Resolver<ResolversTypes['gmoOffPaymentMethodType_enum'], ParentType, ContextType>;
  transactionFeeRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GmoOffPaymentShopConfigTransactionFeeRateMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['gmoOffPaymentShopConfigTransactionFeeRate_mutation_response'] = ResolversParentTypes['gmoOffPaymentShopConfigTransactionFeeRate_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GmoOffPaymentShopConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['gmoOffPaymentShopConfig_mutation_response'] = ResolversParentTypes['gmoOffPaymentShopConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['gmoOffPaymentShopConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag'] = ResolversParentTypes['inflowSourceTag']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isWalkIn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopInflowSourceTags?: Resolver<Array<ResolversTypes['shopInflowSourceTag']>, ParentType, ContextType, Partial<InflowSourceTagShopInflowSourceTagsArgs>>;
  tableUsers?: Resolver<Array<ResolversTypes['tableUser']>, ParentType, ContextType, Partial<InflowSourceTagTableUsersArgs>>;
  winboardInflowSourceTag?: Resolver<Maybe<ResolversTypes['winboardInflowSourceTag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_aggregate'] = ResolversParentTypes['inflowSourceTag_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['inflowSourceTag_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['inflowSourceTag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_aggregate_fields'] = ResolversParentTypes['inflowSourceTag_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['inflowSourceTag_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<InflowSourceTagAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['inflowSourceTag_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['inflowSourceTag_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['inflowSourceTag_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['inflowSourceTag_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['inflowSourceTag_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['inflowSourceTag_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['inflowSourceTag_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['inflowSourceTag_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['inflowSourceTag_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_avg_fields'] = ResolversParentTypes['inflowSourceTag_avg_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_max_fields'] = ResolversParentTypes['inflowSourceTag_max_fields']> = {
  companyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_min_fields'] = ResolversParentTypes['inflowSourceTag_min_fields']> = {
  companyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_mutation_response'] = ResolversParentTypes['inflowSourceTag_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['inflowSourceTag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_stddev_fields'] = ResolversParentTypes['inflowSourceTag_stddev_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_stddev_pop_fields'] = ResolversParentTypes['inflowSourceTag_stddev_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_stddev_samp_fields'] = ResolversParentTypes['inflowSourceTag_stddev_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_sum_fields'] = ResolversParentTypes['inflowSourceTag_sum_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_var_pop_fields'] = ResolversParentTypes['inflowSourceTag_var_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_var_samp_fields'] = ResolversParentTypes['inflowSourceTag_var_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InflowSourceTagVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['inflowSourceTag_variance_fields'] = ResolversParentTypes['inflowSourceTag_variance_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemSalesResolvers<ContextType = any, ParentType extends ResolversParentTypes['itemSales'] = ResolversParentTypes['itemSales']> = {
  billingAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  discountAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  payingActivePlanChoice?: Resolver<Maybe<ResolversTypes['payingActivePlanChoice']>, ParentType, ContextType>;
  payingActivePlanChoiceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  payingActivePlanOpenPriceMeta?: Resolver<Maybe<ResolversTypes['payingActivePlanOpenPriceMeta']>, ParentType, ContextType>;
  payingActivePlanOpenPriceMetaId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  payingMenuOrderItem?: Resolver<Maybe<ResolversTypes['payingMenuOrderItem']>, ParentType, ContextType>;
  payingMenuOrderItemId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sellingAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonbScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export type KdDisplayItemKdDisplayTargetsKdDisplayTargetResolvers<ContextType = any, ParentType extends ResolversParentTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget'] = ResolversParentTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  kdDisplayItemId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  kdDisplayTarget?: Resolver<ResolversTypes['kdDisplayTarget'], ParentType, ContextType>;
  kdDisplayTargetId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KdDisplayItemKdDisplayTargetsKdDisplayTargetMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget_mutation_response'] = ResolversParentTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KdDisplayTargetResolvers<ContextType = any, ParentType extends ResolversParentTypes['kdDisplayTarget'] = ResolversParentTypes['kdDisplayTarget']> = {
  _roleId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  kdDisplayItemKdDisplayTargetsKdDisplayTargets?: Resolver<Array<ResolversTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget']>, ParentType, ContextType, Partial<KdDisplayTargetKdDisplayItemKdDisplayTargetsKdDisplayTargetsArgs>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['role']>, ParentType, ContextType>;
  roleId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopMenuKdDisplayTargets?: Resolver<Array<ResolversTypes['shopMenuKdDisplayTarget']>, ParentType, ContextType, Partial<KdDisplayTargetShopMenuKdDisplayTargetsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KdDisplayTargetMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['kdDisplayTarget_mutation_response'] = ResolversParentTypes['kdDisplayTarget_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['kdDisplayTarget']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineChannelConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['lineChannelConfig'] = ResolversParentTypes['lineChannelConfig']> = {
  channelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  liffId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineChannelConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['lineChannelConfig_mutation_response'] = ResolversParentTypes['lineChannelConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['lineChannelConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineOfficialAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['lineOfficialAccount'] = ResolversParentTypes['lineOfficialAccount']> = {
  channelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  lineId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineOfficialAccountId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  longLivedChannelAccessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shops?: Resolver<Array<ResolversTypes['shop']>, ParentType, ContextType, Partial<LineOfficialAccountShopsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['lineProfile'] = ResolversParentTypes['lineProfile']> = {
  customer?: Resolver<ResolversTypes['customer'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileImage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineReportingBotConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['lineReportingBotConfig'] = ResolversParentTypes['lineReportingBotConfig']> = {
  corporation?: Resolver<ResolversTypes['corporation'], ParentType, ContextType>;
  corporationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isSuspended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lineGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lineReportingBotConfigShops?: Resolver<Array<ResolversTypes['lineReportingBotConfigShop']>, ParentType, ContextType, Partial<LineReportingBotConfigLineReportingBotConfigShopsArgs>>;
  lineReportingBotVerificationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineReportingBotConfigShopResolvers<ContextType = any, ParentType extends ResolversParentTypes['lineReportingBotConfigShop'] = ResolversParentTypes['lineReportingBotConfigShop']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  lineReportingBotConfig?: Resolver<ResolversTypes['lineReportingBotConfig'], ParentType, ContextType>;
  lineReportingBotConfigId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineReportingBotConfigShopMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['lineReportingBotConfigShop_mutation_response'] = ResolversParentTypes['lineReportingBotConfigShop_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['lineReportingBotConfigShop']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineReportingBotConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['lineReportingBotConfig_mutation_response'] = ResolversParentTypes['lineReportingBotConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['lineReportingBotConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineReportingBotVerificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['lineReportingBotVerification'] = ResolversParentTypes['lineReportingBotVerification']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  corporation?: Resolver<ResolversTypes['corporation'], ParentType, ContextType>;
  corporationId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  expiredAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  lineReportingBotConfig?: Resolver<Maybe<ResolversTypes['lineReportingBotConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['membershipCard'] = ResolversParentTypes['membershipCard']> = {
  appearanceType?: Resolver<ResolversTypes['membershipCardAppearanceType_enum'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  membershipCardAppearanceType?: Resolver<ResolversTypes['membershipCardAppearanceType'], ParentType, ContextType>;
  membershipCardTitles?: Resolver<Array<ResolversTypes['membershipCardTitle']>, ParentType, ContextType, Partial<MembershipCardMembershipCardTitlesArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipCardAppearanceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['membershipCardAppearanceType'] = ResolversParentTypes['membershipCardAppearanceType']> = {
  membershipCards?: Resolver<Array<ResolversTypes['membershipCard']>, ParentType, ContextType, Partial<MembershipCardAppearanceTypeMembershipCardsArgs>>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipCardTitleResolvers<ContextType = any, ParentType extends ResolversParentTypes['membershipCardTitle'] = ResolversParentTypes['membershipCardTitle']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  membershipCard?: Resolver<ResolversTypes['membershipCard'], ParentType, ContextType>;
  membershipCardId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipCardTitleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['membershipCardTitle_mutation_response'] = ResolversParentTypes['membershipCardTitle_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['membershipCardTitle']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipCardMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['membershipCard_mutation_response'] = ResolversParentTypes['membershipCard_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['membershipCard']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipRankResolvers<ContextType = any, ParentType extends ResolversParentTypes['membershipRank'] = ResolversParentTypes['membershipRank']> = {
  companyMembers?: Resolver<Array<ResolversTypes['companyMember']>, ParentType, ContextType, Partial<MembershipRankCompanyMembersArgs>>;
  companyMembers_aggregate?: Resolver<ResolversTypes['companyMember_aggregate'], ParentType, ContextType, Partial<MembershipRankCompanyMembersAggregateArgs>>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipRankConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['membershipRankConfig'] = ResolversParentTypes['membershipRankConfig']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  membershipRank?: Resolver<ResolversTypes['membershipRank_enum'], ParentType, ContextType>;
  membershipRankByMembershipRank?: Resolver<ResolversTypes['membershipRank'], ParentType, ContextType>;
  requiredVisitCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardCoupon?: Resolver<Maybe<ResolversTypes['coupon']>, ParentType, ContextType>;
  rewardCouponId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipRankConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['membershipRankConfig_mutation_response'] = ResolversParentTypes['membershipRankConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['membershipRankConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['menu'] = ResolversParentTypes['menu']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  categoryMenus?: Resolver<Array<ResolversTypes['categoryMenu']>, ParentType, ContextType, Partial<MenuCategoryMenusArgs>>;
  categoryMenus_aggregate?: Resolver<ResolversTypes['categoryMenu_aggregate'], ParentType, ContextType, Partial<MenuCategoryMenusAggregateArgs>>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  compulsoryAppetizerConfigs?: Resolver<Array<ResolversTypes['compulsoryAppetizerConfig']>, ParentType, ContextType, Partial<MenuCompulsoryAppetizerConfigsArgs>>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dishUpSlipGroupShopMenus?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopMenus']>, ParentType, ContextType, Partial<MenuDishUpSlipGroupShopMenusArgs>>;
  featuredLabelText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  foodingJournalMenu?: Resolver<Maybe<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType>;
  giftMeta?: Resolver<Maybe<ResolversTypes['giftMeta']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isTakeOut?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  menuOptions?: Resolver<Array<ResolversTypes['menuOption']>, ParentType, ContextType, Partial<MenuMenuOptionsArgs>>;
  menuType?: Resolver<Maybe<ResolversTypes['menuType_enum']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  openPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  orderMaxNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  orderMaxNumPerTableUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  orderableTime?: Resolver<Maybe<ResolversTypes['orderableTime']>, ParentType, ContextType>;
  orderableTimeId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  pikaichiMenuMenus?: Resolver<Array<ResolversTypes['pikaichiMenuMenu']>, ParentType, ContextType, Partial<MenuPikaichiMenuMenusArgs>>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  receiptDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  serviceChargeMeta?: Resolver<Maybe<ResolversTypes['serviceChargeMeta']>, ParentType, ContextType>;
  shopMenuCookingItems?: Resolver<Array<ResolversTypes['shopMenuCookingItem']>, ParentType, ContextType, Partial<MenuShopMenuCookingItemsArgs>>;
  shopMenuKitchenRoles?: Resolver<Array<ResolversTypes['shopMenuKitchenRole']>, ParentType, ContextType, Partial<MenuShopMenuKitchenRolesArgs>>;
  shopMenus?: Resolver<Array<ResolversTypes['shopMenu']>, ParentType, ContextType, Partial<MenuShopMenusArgs>>;
  shopSideName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shouldMergeSlipOptions?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  specificTimeSurchargeMeta?: Resolver<Maybe<ResolversTypes['specificTimeSurchargeMeta']>, ParentType, ContextType>;
  taxMethod?: Resolver<ResolversTypes['taxMethod_enum'], ParentType, ContextType>;
  taxRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  tecAggregationMenu?: Resolver<Maybe<ResolversTypes['tecAggregationMenu']>, ParentType, ContextType>;
  totalDealingShopCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  translations?: Resolver<Array<ResolversTypes['translation']>, ParentType, ContextType, Partial<MenuTranslationsArgs>>;
  unitMaxOrderNumForNumPeople?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  unitMaxOrderNumPerCustomer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  winboardMenus?: Resolver<Array<ResolversTypes['winboardMenu']>, ParentType, ContextType, Partial<MenuWinboardMenusArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['menuOption'] = ResolversParentTypes['menuOption']> = {
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _optionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  option?: Resolver<ResolversTypes['option'], ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuOptionMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['menuOption_mutation_response'] = ResolversParentTypes['menuOption_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['menuOption']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuOrderItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['menuOrderItem'] = ResolversParentTypes['menuOrderItem']> = {
  _categoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  addedCustomer?: Resolver<Maybe<ResolversTypes['customer']>, ParentType, ContextType>;
  addedUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addedUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addedUserProfileImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  giftMeta?: Resolver<Maybe<ResolversTypes['giftMeta']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isOpenPrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  menu?: Resolver<Maybe<ResolversTypes['menu']>, ParentType, ContextType>;
  menuId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  menuOrderItemOptions?: Resolver<Array<ResolversTypes['menuOrderItemOption']>, ParentType, ContextType, Partial<MenuOrderItemMenuOrderItemOptionsArgs>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['order'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  payingMenuOrderItems?: Resolver<Array<ResolversTypes['payingMenuOrderItem']>, ParentType, ContextType, Partial<MenuOrderItemPayingMenuOrderItemsArgs>>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  remark?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taxMethod?: Resolver<ResolversTypes['taxMethod_enum'], ParentType, ContextType>;
  taxRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuOrderItemChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['menuOrderItemChoice'] = ResolversParentTypes['menuOrderItemChoice']> = {
  _choiceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  choice?: Resolver<Maybe<ResolversTypes['choice']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menuOrderItemOption?: Resolver<ResolversTypes['menuOrderItemOption'], ParentType, ContextType>;
  menuOrderItemOptionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuOrderItemOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['menuOrderItemOption'] = ResolversParentTypes['menuOrderItemOption']> = {
  _optionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menuOrderItem?: Resolver<ResolversTypes['menuOrderItem'], ParentType, ContextType>;
  menuOrderItemChoices?: Resolver<Array<ResolversTypes['menuOrderItemChoice']>, ParentType, ContextType, Partial<MenuOrderItemOptionMenuOrderItemChoicesArgs>>;
  menuOrderItemId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  option?: Resolver<Maybe<ResolversTypes['option']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuRecommendationMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['menuRecommendationMeta'] = ResolversParentTypes['menuRecommendationMeta']> = {
  _categoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['category'], ParentType, ContextType>;
  categoryId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  translations?: Resolver<Array<ResolversTypes['translation']>, ParentType, ContextType, Partial<MenuRecommendationMetaTranslationsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuRecommendationMetaMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['menuRecommendationMeta_mutation_response'] = ResolversParentTypes['menuRecommendationMeta_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['menuRecommendationMeta']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['menu_mutation_response'] = ResolversParentTypes['menu_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['menu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDelivery'] = ResolversParentTypes['messageDelivery']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  couponAppliedEvents?: Resolver<Array<ResolversTypes['couponAppliedEvent']>, ParentType, ContextType, Partial<MessageDeliveryCouponAppliedEventsArgs>>;
  couponAppliedEvents_aggregate?: Resolver<ResolversTypes['couponAppliedEvent_aggregate'], ParentType, ContextType, Partial<MessageDeliveryCouponAppliedEventsAggregateArgs>>;
  customerList?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerList']>, ParentType, ContextType>;
  customerListId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  customerQuestionnaires?: Resolver<Array<ResolversTypes['customerQuestionnaire']>, ParentType, ContextType, Partial<MessageDeliveryCustomerQuestionnairesArgs>>;
  customerSegment?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegment']>, ParentType, ContextType>;
  customerSegmentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  deliverAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isDraft?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSuspended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  messageDeliveryJobs?: Resolver<Array<ResolversTypes['messageDeliveryJob']>, ParentType, ContextType, Partial<MessageDeliveryMessageDeliveryJobsArgs>>;
  messageDeliveryMessages?: Resolver<Array<ResolversTypes['messageDeliveryMessage']>, ParentType, ContextType, Partial<MessageDeliveryMessageDeliveryMessagesArgs>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  repeatDeliveryTime?: Resolver<Maybe<ResolversTypes['timetz']>, ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  targetCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  targetCountUpdatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerListResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerList'] = ResolversParentTypes['messageDeliveryCustomerList']> = {
  customerListCustomers?: Resolver<Array<ResolversTypes['messageDeliveryCustomerListCustomer']>, ParentType, ContextType, Partial<MessageDeliveryCustomerListCustomerListCustomersArgs>>;
  customerListCustomers_aggregate?: Resolver<ResolversTypes['messageDeliveryCustomerListCustomer_aggregate'], ParentType, ContextType, Partial<MessageDeliveryCustomerListCustomerListCustomersAggregateArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isFromCsv?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  messageDelivery?: Resolver<Maybe<ResolversTypes['messageDelivery']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerListCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerListCustomer'] = ResolversParentTypes['messageDeliveryCustomerListCustomer']> = {
  customer?: Resolver<ResolversTypes['customer'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerList?: Resolver<ResolversTypes['messageDeliveryCustomerList'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerListId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerListCustomerAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerListCustomer_aggregate'] = ResolversParentTypes['messageDeliveryCustomerListCustomer_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerListCustomer_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['messageDeliveryCustomerListCustomer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerListCustomerAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerListCustomer_aggregate_fields'] = ResolversParentTypes['messageDeliveryCustomerListCustomer_aggregate_fields']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<MessageDeliveryCustomerListCustomerAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerListCustomer_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerListCustomer_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerListCustomerMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerListCustomer_max_fields'] = ResolversParentTypes['messageDeliveryCustomerListCustomer_max_fields']> = {
  customerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  messageDeliveryCustomerListId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerListCustomerMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerListCustomer_min_fields'] = ResolversParentTypes['messageDeliveryCustomerListCustomer_min_fields']> = {
  customerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  messageDeliveryCustomerListId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegment'] = ResolversParentTypes['messageDeliveryCustomerSegment']> = {
  conditions?: Resolver<Maybe<ResolversTypes['jsonb']>, ParentType, ContextType, Partial<MessageDeliveryCustomerSegmentConditionsArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDelivery?: Resolver<Maybe<ResolversTypes['messageDelivery']>, ParentType, ContextType>;
  messageDeliveryCustomerSegmentBusinessOperationHoursConditions?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentBusinessOperationHoursCondition']>, ParentType, ContextType, Partial<MessageDeliveryCustomerSegmentMessageDeliveryCustomerSegmentBusinessOperationHoursConditionsArgs>>;
  messageDeliveryCustomerSegmentDayOfWeekConditions?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentDayOfWeekCondition']>, ParentType, ContextType, Partial<MessageDeliveryCustomerSegmentMessageDeliveryCustomerSegmentDayOfWeekConditionsArgs>>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition']>, ParentType, ContextType>;
  messageDeliveryCustomerSegmentGenderCondition?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentGenderCondition']>, ParentType, ContextType>;
  messageDeliveryCustomerSegmentMenuConditions?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentMenuCondition']>, ParentType, ContextType, Partial<MessageDeliveryCustomerSegmentMessageDeliveryCustomerSegmentMenuConditionsArgs>>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerCondition']>, ParentType, ContextType>;
  messageDeliveryCustomerSegmentShopConditions?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentShopCondition']>, ParentType, ContextType, Partial<MessageDeliveryCustomerSegmentMessageDeliveryCustomerSegmentShopConditionsArgs>>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentUnvisitedDayCountCondition']>, ParentType, ContextType>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentVisitedDayCountCondition']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentBusinessOperationHoursConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentBusinessOperationHoursCondition'] = ResolversParentTypes['messageDeliveryCustomerSegmentBusinessOperationHoursCondition']> = {
  businessOperationHourType?: Resolver<ResolversTypes['businessOperationHourType_enum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegment?: Resolver<ResolversTypes['messageDeliveryCustomerSegment'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentDayOfWeekConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentDayOfWeekCondition'] = ResolversParentTypes['messageDeliveryCustomerSegmentDayOfWeekCondition']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegment?: Resolver<ResolversTypes['messageDeliveryCustomerSegment'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryDayOfWeek?: Resolver<ResolversTypes['messageDeliveryCustomerSegmentDayOfWeekType_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition'] = ResolversParentTypes['messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegment?: Resolver<ResolversTypes['messageDeliveryCustomerSegment'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentNumericCondition?: Resolver<ResolversTypes['messageDeliveryCustomerSegmentNumericCondition'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentNumericConditionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentGenderResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentGender'] = ResolversParentTypes['messageDeliveryCustomerSegmentGender']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentGenderConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentGenderCondition'] = ResolversParentTypes['messageDeliveryCustomerSegmentGenderCondition']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegment?: Resolver<ResolversTypes['messageDeliveryCustomerSegment'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentGender?: Resolver<ResolversTypes['messageDeliveryCustomerSegmentGender'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryGender?: Resolver<ResolversTypes['messageDeliveryCustomerSegmentGender_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentMenuConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentMenuCondition'] = ResolversParentTypes['messageDeliveryCustomerSegmentMenuCondition']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegment?: Resolver<ResolversTypes['messageDeliveryCustomerSegment'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  moreThanOrEqualQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentNumericConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentNumericCondition'] = ResolversParentTypes['messageDeliveryCustomerSegmentNumericCondition']> = {
  equal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  lessThanOrEqual?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition']>, ParentType, ContextType>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentUnvisitedDayCountCondition']>, ParentType, ContextType>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentVisitedDayCountCondition']>, ParentType, ContextType>;
  moreThanOrEqual?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerCondition'] = ResolversParentTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerCondition']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegment?: Resolver<ResolversTypes['messageDeliveryCustomerSegment'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatus?: Resolver<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType'], ParentType, ContextType>;
  messageDeliveryQuestionnaireAnswerStatus?: Resolver<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType'] = ResolversParentTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentShopConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentShopCondition'] = ResolversParentTypes['messageDeliveryCustomerSegmentShopCondition']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegment?: Resolver<ResolversTypes['messageDeliveryCustomerSegment'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentUnvisitedDayCountConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentUnvisitedDayCountCondition'] = ResolversParentTypes['messageDeliveryCustomerSegmentUnvisitedDayCountCondition']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegment?: Resolver<ResolversTypes['messageDeliveryCustomerSegment'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentNumericCondition?: Resolver<ResolversTypes['messageDeliveryCustomerSegmentNumericCondition'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentNumericConditionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryCustomerSegmentVisitedDayCountConditionResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryCustomerSegmentVisitedDayCountCondition'] = ResolversParentTypes['messageDeliveryCustomerSegmentVisitedDayCountCondition']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegment?: Resolver<ResolversTypes['messageDeliveryCustomerSegment'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentNumericCondition?: Resolver<ResolversTypes['messageDeliveryCustomerSegmentNumericCondition'], ParentType, ContextType>;
  messageDeliveryCustomerSegmentNumericConditionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryJobResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryJob'] = ResolversParentTypes['messageDeliveryJob']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  executedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDelivery?: Resolver<ResolversTypes['messageDelivery'], ParentType, ContextType>;
  messageDeliveryId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryShopJobs?: Resolver<Array<ResolversTypes['messageDeliveryShopJob']>, ParentType, ContextType, Partial<MessageDeliveryJobMessageDeliveryShopJobsArgs>>;
  succeededLineMessagingCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  targetLineMessagingCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryMessage'] = ResolversParentTypes['messageDeliveryMessage']> = {
  couponTypeMeta?: Resolver<Maybe<ResolversTypes['messageDeliveryMessageCouponTypeMeta']>, ParentType, ContextType>;
  couponTypeMetaId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  imageTypeMeta?: Resolver<Maybe<ResolversTypes['messageDeliveryMessageImageTypeMeta']>, ParentType, ContextType>;
  imageTypeMetaId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  messageDelivery?: Resolver<ResolversTypes['messageDelivery'], ParentType, ContextType>;
  messageDeliveryId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  questionnaireTypeMeta?: Resolver<Maybe<ResolversTypes['messageDeliveryMessageQuestionnaireTypeMeta']>, ParentType, ContextType>;
  questionnaireTypeMetaId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  textTypeMeta?: Resolver<Maybe<ResolversTypes['messageDeliveryMessageTextTypeMeta']>, ParentType, ContextType>;
  textTypeMetaId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['messageDeliveryMessageType_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryMessageCouponTypeMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryMessageCouponTypeMeta'] = ResolversParentTypes['messageDeliveryMessageCouponTypeMeta']> = {
  coupon?: Resolver<ResolversTypes['coupon'], ParentType, ContextType>;
  couponId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryMessage?: Resolver<Maybe<ResolversTypes['messageDeliveryMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryMessageImageTypeMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryMessageImageTypeMeta'] = ResolversParentTypes['messageDeliveryMessageImageTypeMeta']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messageDeliveryMessage?: Resolver<Maybe<ResolversTypes['messageDeliveryMessage']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryMessageQuestionnaireTypeMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryMessageQuestionnaireTypeMeta'] = ResolversParentTypes['messageDeliveryMessageQuestionnaireTypeMeta']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryMessage?: Resolver<Maybe<ResolversTypes['messageDeliveryMessage']>, ParentType, ContextType>;
  questionnaireConfig?: Resolver<ResolversTypes['questionnaireConfig'], ParentType, ContextType>;
  questionnaireConfigId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryMessageTextTypeMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryMessageTextTypeMeta'] = ResolversParentTypes['messageDeliveryMessageTextTypeMeta']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryMessage?: Resolver<Maybe<ResolversTypes['messageDeliveryMessage']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryShopJobResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDeliveryShopJob'] = ResolversParentTypes['messageDeliveryShopJob']> = {
  errorType?: Resolver<Maybe<ResolversTypes['messageDeliveryShopJobErrorType_enum']>, ParentType, ContextType>;
  executedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  messageDeliveryJob?: Resolver<ResolversTypes['messageDeliveryJob'], ParentType, ContextType>;
  messageDeliveryJobId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  succeededLineMessagingPerShopCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  targetLineMessagingPerShopCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageDeliveryMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['messageDelivery_mutation_response'] = ResolversParentTypes['messageDelivery_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['messageDelivery']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonthlySalesBudgetResolvers<ContextType = any, ParentType extends ResolversParentTypes['monthlySalesBudget'] = ResolversParentTypes['monthlySalesBudget']> = {
  businessDate?: Resolver<ResolversTypes['date'], ParentType, ContextType>;
  dailySalesBudgets?: Resolver<Array<ResolversTypes['dailySalesBudget']>, ParentType, ContextType, Partial<MonthlySalesBudgetDailySalesBudgetsArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  taxExcludedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonthlySalesBudgetMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['monthlySalesBudget_mutation_response'] = ResolversParentTypes['monthlySalesBudget_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['monthlySalesBudget']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MultipleChoiceTypeQuestionAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['multipleChoiceTypeQuestionAnswer'] = ResolversParentTypes['multipleChoiceTypeQuestionAnswer']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  multipleChoiceTypeQuestionChoiceAnswers?: Resolver<Array<ResolversTypes['multipleChoiceTypeQuestionChoiceAnswer']>, ParentType, ContextType, Partial<MultipleChoiceTypeQuestionAnswerMultipleChoiceTypeQuestionChoiceAnswersArgs>>;
  multipleChoiceTypeQuestionMeta?: Resolver<ResolversTypes['multipleChoiceTypeQuestionMeta'], ParentType, ContextType>;
  multipleChoiceTypeQuestionMetaId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  questionAnswer?: Resolver<Maybe<ResolversTypes['questionAnswer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MultipleChoiceTypeQuestionChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['multipleChoiceTypeQuestionChoice'] = ResolversParentTypes['multipleChoiceTypeQuestionChoice']> = {
  multipleChoiceTypeQuestionChoiceAnswers?: Resolver<Array<ResolversTypes['multipleChoiceTypeQuestionChoiceAnswer']>, ParentType, ContextType, Partial<MultipleChoiceTypeQuestionChoiceMultipleChoiceTypeQuestionChoiceAnswersArgs>>;
  multipleChoiceTypeQuestionMeta?: Resolver<ResolversTypes['multipleChoiceTypeQuestionMeta'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MultipleChoiceTypeQuestionChoiceAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['multipleChoiceTypeQuestionChoiceAnswer'] = ResolversParentTypes['multipleChoiceTypeQuestionChoiceAnswer']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  multipleChoiceTypeQuestionAnswer?: Resolver<ResolversTypes['multipleChoiceTypeQuestionAnswer'], ParentType, ContextType>;
  questionChoice?: Resolver<ResolversTypes['multipleChoiceTypeQuestionChoice'], ParentType, ContextType>;
  questionChoiceId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MultipleChoiceTypeQuestionMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['multipleChoiceTypeQuestionMeta'] = ResolversParentTypes['multipleChoiceTypeQuestionMeta']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  multipleChoiceTypeQuestionAnswers?: Resolver<Array<ResolversTypes['multipleChoiceTypeQuestionAnswer']>, ParentType, ContextType, Partial<MultipleChoiceTypeQuestionMetaMultipleChoiceTypeQuestionAnswersArgs>>;
  multipleChoiceTypeQuestionChoices?: Resolver<Array<ResolversTypes['multipleChoiceTypeQuestionChoice']>, ParentType, ContextType, Partial<MultipleChoiceTypeQuestionMetaMultipleChoiceTypeQuestionChoicesArgs>>;
  question?: Resolver<Maybe<ResolversTypes['question']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationRootResolvers<ContextType = any, ParentType extends ResolversParentTypes['mutation_root'] = ResolversParentTypes['mutation_root']> = {
  archiveMenu?: Resolver<ResolversTypes['ArchiveMenuResult'], ParentType, ContextType, RequireFields<MutationRootArchiveMenuArgs, 'input'>>;
  archivePlan?: Resolver<ResolversTypes['ArchivePlanResult'], ParentType, ContextType, RequireFields<MutationRootArchivePlanArgs, 'input'>>;
  archiveTable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootArchiveTableArgs, 'input'>>;
  companyManagerDeleteDashboardAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootCompanyManagerDeleteDashboardAccountArgs, 'input'>>;
  createCategory?: Resolver<ResolversTypes['CreateCategoryOutput'], ParentType, ContextType, RequireFields<MutationRootCreateCategoryArgs, 'input'>>;
  createDashboardAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootCreateDashboardAccountArgs, 'input'>>;
  createLineOfficialAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootCreateLineOfficialAccountArgs, 'input'>>;
  createMenu?: Resolver<ResolversTypes['CreateMenuOutput'], ParentType, ContextType, RequireFields<MutationRootCreateMenuArgs, 'input'>>;
  createMenuRecommendation?: Resolver<ResolversTypes['CreateMenuRecommendationOutput'], ParentType, ContextType, RequireFields<MutationRootCreateMenuRecommendationArgs, 'input'>>;
  createOption?: Resolver<ResolversTypes['CreateOptionOutput'], ParentType, ContextType, RequireFields<MutationRootCreateOptionArgs, 'input'>>;
  createPlan?: Resolver<ResolversTypes['CreatePlanOutput'], ParentType, ContextType, RequireFields<MutationRootCreatePlanArgs, 'input'>>;
  createPlanChoice?: Resolver<ResolversTypes['CreatePlanChoiceOutput'], ParentType, ContextType, RequireFields<MutationRootCreatePlanChoiceArgs, 'input'>>;
  createPlanOption?: Resolver<ResolversTypes['CreatePlanOptionOutput'], ParentType, ContextType, RequireFields<MutationRootCreatePlanOptionArgs, 'input'>>;
  createServiceAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootCreateServiceAdminArgs, 'input'>>;
  createShop?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootCreateShopArgs, 'input'>>;
  createShopMenus?: Resolver<ResolversTypes['CreateShopMenusResult'], ParentType, ContextType, RequireFields<MutationRootCreateShopMenusArgs, 'input'>>;
  createShopPlan?: Resolver<ResolversTypes['CreateShopPlanResult'], ParentType, ContextType, RequireFields<MutationRootCreateShopPlanArgs, 'input'>>;
  dashboardAccountArchiveCompany?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountArchiveCompanyArgs, 'input'>>;
  dashboardAccountArchiveShop?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountArchiveShopArgs, 'input'>>;
  dashboardAccountCancelOnSitePayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountCancelOnSitePaymentArgs, 'input'>>;
  dashboardAccountCopyDealingData?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountCopyDealingDataArgs, 'input'>>;
  dashboardAccountCopyMasterData?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountCopyMasterDataArgs, 'input'>>;
  dashboardAccountCountMessageDeliveryTargets?: Resolver<ResolversTypes['UpdateTargetCountOutput'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountCountMessageDeliveryTargetsArgs, 'input'>>;
  dashboardAccountCreateCompany?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountCreateCompanyArgs, 'input'>>;
  dashboardAccountCreateCorporation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountCreateCorporationArgs, 'input'>>;
  dashboardAccountCreateMessageDelivery?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountCreateMessageDeliveryArgs, 'input'>>;
  dashboardAccountCreateSurchargeConfigs?: Resolver<ResolversTypes['CreateSurchargeConfigsResult'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountCreateSurchargeConfigsArgs, 'input'>>;
  dashboardAccountEnableAdyenOnlinePayment?: Resolver<ResolversTypes['EnableOnlinePaymentOutput'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountEnableAdyenOnlinePaymentArgs, 'input'>>;
  dashboardAccountEnableGmoOnlinePayment?: Resolver<ResolversTypes['EnableOnlinePaymentOutput'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountEnableGmoOnlinePaymentArgs, 'input'>>;
  dashboardAccountEnableStripeOnlinePayment?: Resolver<ResolversTypes['EnableOnlinePaymentOutput'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountEnableStripeOnlinePaymentArgs, 'input'>>;
  dashboardAccountIssueLineReportingBotVerificationCode?: Resolver<ResolversTypes['IssueLineReportingBotVerificationCodeResponse'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountIssueLineReportingBotVerificationCodeArgs, 'input'>>;
  dashboardAccountUpdateBankDepositAmount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountUpdateBankDepositAmountArgs, 'input'>>;
  dashboardAccountUpdateDashboardAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountUpdateDashboardAccountArgs, 'input'>>;
  dashboardAccountUpdateMessageDelivery?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountUpdateMessageDeliveryArgs, 'input'>>;
  dashboardAccountUpdateOnSitePayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootDashboardAccountUpdateOnSitePaymentArgs, 'input'>>;
  deleteShopChoices?: Resolver<ResolversTypes['DeleteShopChoicesResult'], ParentType, ContextType, RequireFields<MutationRootDeleteShopChoicesArgs, 'input'>>;
  deleteShopMenus?: Resolver<ResolversTypes['DeleteShopMenusResult'], ParentType, ContextType, RequireFields<MutationRootDeleteShopMenusArgs, 'input'>>;
  deleteShopPlanChoices?: Resolver<ResolversTypes['DeleteShopPlanChoicesResult'], ParentType, ContextType, RequireFields<MutationRootDeleteShopPlanChoicesArgs, 'input'>>;
  deleteShopPlans?: Resolver<ResolversTypes['DeleteShopPlansResult'], ParentType, ContextType, RequireFields<MutationRootDeleteShopPlansArgs, 'input'>>;
  delete_accountingSlipImage?: Resolver<Maybe<ResolversTypes['accountingSlipImage_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteAccountingSlipImageArgs, 'where'>>;
  delete_accountingSlipImage_by_pk?: Resolver<Maybe<ResolversTypes['accountingSlipImage']>, ParentType, ContextType, RequireFields<MutationRootDeleteAccountingSlipImageByPkArgs, 'id'>>;
  delete_cashRegisterConfig?: Resolver<Maybe<ResolversTypes['cashRegisterConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteCashRegisterConfigArgs, 'where'>>;
  delete_cashRegisterConfig_by_pk?: Resolver<Maybe<ResolversTypes['cashRegisterConfig']>, ParentType, ContextType, RequireFields<MutationRootDeleteCashRegisterConfigByPkArgs, 'id'>>;
  delete_categoryMenu?: Resolver<Maybe<ResolversTypes['categoryMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteCategoryMenuArgs, 'where'>>;
  delete_categoryMenu_by_pk?: Resolver<Maybe<ResolversTypes['categoryMenu']>, ParentType, ContextType, RequireFields<MutationRootDeleteCategoryMenuByPkArgs, 'serial'>>;
  delete_clerk?: Resolver<Maybe<ResolversTypes['clerk_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteClerkArgs, 'where'>>;
  delete_clerk_by_pk?: Resolver<Maybe<ResolversTypes['clerk']>, ParentType, ContextType, RequireFields<MutationRootDeleteClerkByPkArgs, 'serial'>>;
  delete_company?: Resolver<Maybe<ResolversTypes['company_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteCompanyArgs, 'where'>>;
  delete_company_by_pk?: Resolver<Maybe<ResolversTypes['company']>, ParentType, ContextType, RequireFields<MutationRootDeleteCompanyByPkArgs, 'serial'>>;
  delete_compulsoryAppetizerConfig?: Resolver<Maybe<ResolversTypes['compulsoryAppetizerConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteCompulsoryAppetizerConfigArgs, 'where'>>;
  delete_compulsoryAppetizerConfig_by_pk?: Resolver<Maybe<ResolversTypes['compulsoryAppetizerConfig']>, ParentType, ContextType, RequireFields<MutationRootDeleteCompulsoryAppetizerConfigByPkArgs, 'id'>>;
  delete_connectGameConfigShop?: Resolver<Maybe<ResolversTypes['connectGameConfigShop_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteConnectGameConfigShopArgs, 'where'>>;
  delete_connectGameConfigShop_by_pk?: Resolver<Maybe<ResolversTypes['connectGameConfigShop']>, ParentType, ContextType, RequireFields<MutationRootDeleteConnectGameConfigShopByPkArgs, 'id'>>;
  delete_cookingItem?: Resolver<Maybe<ResolversTypes['cookingItem_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteCookingItemArgs, 'where'>>;
  delete_cookingItem_by_pk?: Resolver<Maybe<ResolversTypes['cookingItem']>, ParentType, ContextType, RequireFields<MutationRootDeleteCookingItemByPkArgs, 'serial'>>;
  delete_corporation?: Resolver<Maybe<ResolversTypes['corporation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteCorporationArgs, 'where'>>;
  delete_corporation_by_pk?: Resolver<Maybe<ResolversTypes['corporation']>, ParentType, ContextType, RequireFields<MutationRootDeleteCorporationByPkArgs, 'id'>>;
  delete_couponMenuDiscount?: Resolver<Maybe<ResolversTypes['couponMenuDiscount_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteCouponMenuDiscountArgs, 'where'>>;
  delete_couponMenuDiscount_by_pk?: Resolver<Maybe<ResolversTypes['couponMenuDiscount']>, ParentType, ContextType, RequireFields<MutationRootDeleteCouponMenuDiscountByPkArgs, 'id'>>;
  delete_dashboardAccountRole?: Resolver<Maybe<ResolversTypes['dashboardAccountRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDashboardAccountRoleArgs, 'where'>>;
  delete_dashboardAccountRolePermission?: Resolver<Maybe<ResolversTypes['dashboardAccountRolePermission_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDashboardAccountRolePermissionArgs, 'where'>>;
  delete_dashboardAccountRolePermission_by_pk?: Resolver<Maybe<ResolversTypes['dashboardAccountRolePermission']>, ParentType, ContextType, RequireFields<MutationRootDeleteDashboardAccountRolePermissionByPkArgs, 'id'>>;
  delete_dashboardAccountRole_by_pk?: Resolver<Maybe<ResolversTypes['dashboardAccountRole']>, ParentType, ContextType, RequireFields<MutationRootDeleteDashboardAccountRoleByPkArgs, 'id'>>;
  delete_dishUpSlipGroup?: Resolver<Maybe<ResolversTypes['dishUpSlipGroup_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDishUpSlipGroupArgs, 'where'>>;
  delete_dishUpSlipGroupRoles?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupRoles_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDishUpSlipGroupRolesArgs, 'where'>>;
  delete_dishUpSlipGroupRoles_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupRoles']>, ParentType, ContextType, RequireFields<MutationRootDeleteDishUpSlipGroupRolesByPkArgs, 'id'>>;
  delete_dishUpSlipGroupShopMenus?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopMenus_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDishUpSlipGroupShopMenusArgs, 'where'>>;
  delete_dishUpSlipGroupShopMenus_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopMenus']>, ParentType, ContextType, RequireFields<MutationRootDeleteDishUpSlipGroupShopMenusByPkArgs, 'id'>>;
  delete_dishUpSlipGroupShopPlans?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopPlans_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDishUpSlipGroupShopPlansArgs, 'where'>>;
  delete_dishUpSlipGroupShopPlans_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopPlans']>, ParentType, ContextType, RequireFields<MutationRootDeleteDishUpSlipGroupShopPlansByPkArgs, 'id'>>;
  delete_dishUpSlipGroupTables?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupTables_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteDishUpSlipGroupTablesArgs, 'where'>>;
  delete_dishUpSlipGroupTables_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupTables']>, ParentType, ContextType, RequireFields<MutationRootDeleteDishUpSlipGroupTablesByPkArgs, 'id'>>;
  delete_dishUpSlipGroup_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroup']>, ParentType, ContextType, RequireFields<MutationRootDeleteDishUpSlipGroupByPkArgs, 'serial'>>;
  delete_ebicaTable?: Resolver<Maybe<ResolversTypes['ebicaTable_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteEbicaTableArgs, 'where'>>;
  delete_ebicaTable_by_pk?: Resolver<Maybe<ResolversTypes['ebicaTable']>, ParentType, ContextType, RequireFields<MutationRootDeleteEbicaTableByPkArgs, 'id'>>;
  delete_externalOnlineMenuAvailableTimeTerm?: Resolver<Maybe<ResolversTypes['externalOnlineMenuAvailableTimeTerm_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteExternalOnlineMenuAvailableTimeTermArgs, 'where'>>;
  delete_externalOnlineMenuAvailableTimeTerm_by_pk?: Resolver<Maybe<ResolversTypes['externalOnlineMenuAvailableTimeTerm']>, ParentType, ContextType, RequireFields<MutationRootDeleteExternalOnlineMenuAvailableTimeTermByPkArgs, 'id'>>;
  delete_featureFlagShop?: Resolver<Maybe<ResolversTypes['featureFlagShop_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteFeatureFlagShopArgs, 'where'>>;
  delete_featureFlagShop_by_pk?: Resolver<Maybe<ResolversTypes['featureFlagShop']>, ParentType, ContextType, RequireFields<MutationRootDeleteFeatureFlagShopByPkArgs, 'name' | 'shopId'>>;
  delete_foodingJournalDepartmentMaster?: Resolver<Maybe<ResolversTypes['foodingJournalDepartmentMaster_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteFoodingJournalDepartmentMasterArgs, 'where'>>;
  delete_foodingJournalDepartmentMaster_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalDepartmentMaster']>, ParentType, ContextType, RequireFields<MutationRootDeleteFoodingJournalDepartmentMasterByPkArgs, 'id'>>;
  delete_foodingJournalGroupMaster?: Resolver<Maybe<ResolversTypes['foodingJournalGroupMaster_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteFoodingJournalGroupMasterArgs, 'where'>>;
  delete_foodingJournalGroupMaster_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalGroupMaster']>, ParentType, ContextType, RequireFields<MutationRootDeleteFoodingJournalGroupMasterByPkArgs, 'id'>>;
  delete_foodingJournalMenu?: Resolver<Maybe<ResolversTypes['foodingJournalMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteFoodingJournalMenuArgs, 'where'>>;
  delete_foodingJournalMenu_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType, RequireFields<MutationRootDeleteFoodingJournalMenuByPkArgs, 'id'>>;
  delete_foodingJournalOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteFoodingJournalOnSitePaymentDetailTypeArgs, 'where'>>;
  delete_foodingJournalOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootDeleteFoodingJournalOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  delete_foodingJournalOnSitePaymentDiscountType?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDiscountType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteFoodingJournalOnSitePaymentDiscountTypeArgs, 'where'>>;
  delete_foodingJournalOnSitePaymentDiscountType_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDiscountType']>, ParentType, ContextType, RequireFields<MutationRootDeleteFoodingJournalOnSitePaymentDiscountTypeByPkArgs, 'id'>>;
  delete_giftMeta?: Resolver<Maybe<ResolversTypes['giftMeta_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteGiftMetaArgs, 'where'>>;
  delete_giftMeta_by_pk?: Resolver<Maybe<ResolversTypes['giftMeta']>, ParentType, ContextType, RequireFields<MutationRootDeleteGiftMetaByPkArgs, 'id'>>;
  delete_gmoOffPaymentOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['gmoOffPaymentOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteGmoOffPaymentOnSitePaymentDetailTypeArgs, 'where'>>;
  delete_gmoOffPaymentOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['gmoOffPaymentOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootDeleteGmoOffPaymentOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  delete_gmoOffPaymentShopConfigTransactionFeeRate?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteGmoOffPaymentShopConfigTransactionFeeRateArgs, 'where'>>;
  delete_gmoOffPaymentShopConfigTransactionFeeRate_by_pk?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate']>, ParentType, ContextType, RequireFields<MutationRootDeleteGmoOffPaymentShopConfigTransactionFeeRateByPkArgs, 'id'>>;
  delete_inflowSourceTag?: Resolver<Maybe<ResolversTypes['inflowSourceTag_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteInflowSourceTagArgs, 'where'>>;
  delete_inflowSourceTag_by_pk?: Resolver<Maybe<ResolversTypes['inflowSourceTag']>, ParentType, ContextType, RequireFields<MutationRootDeleteInflowSourceTagByPkArgs, 'id'>>;
  delete_kdDisplayItemKdDisplayTargetsKdDisplayTarget?: Resolver<Maybe<ResolversTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteKdDisplayItemKdDisplayTargetsKdDisplayTargetArgs, 'where'>>;
  delete_kdDisplayItemKdDisplayTargetsKdDisplayTarget_by_pk?: Resolver<Maybe<ResolversTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget']>, ParentType, ContextType, RequireFields<MutationRootDeleteKdDisplayItemKdDisplayTargetsKdDisplayTargetByPkArgs, 'id'>>;
  delete_kdDisplayTarget?: Resolver<Maybe<ResolversTypes['kdDisplayTarget_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteKdDisplayTargetArgs, 'where'>>;
  delete_kdDisplayTarget_by_pk?: Resolver<Maybe<ResolversTypes['kdDisplayTarget']>, ParentType, ContextType, RequireFields<MutationRootDeleteKdDisplayTargetByPkArgs, 'id'>>;
  delete_lineChannelConfig?: Resolver<Maybe<ResolversTypes['lineChannelConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteLineChannelConfigArgs, 'where'>>;
  delete_lineChannelConfig_by_pk?: Resolver<Maybe<ResolversTypes['lineChannelConfig']>, ParentType, ContextType, RequireFields<MutationRootDeleteLineChannelConfigByPkArgs, 'id'>>;
  delete_lineReportingBotConfigShop?: Resolver<Maybe<ResolversTypes['lineReportingBotConfigShop_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteLineReportingBotConfigShopArgs, 'where'>>;
  delete_lineReportingBotConfigShop_by_pk?: Resolver<Maybe<ResolversTypes['lineReportingBotConfigShop']>, ParentType, ContextType, RequireFields<MutationRootDeleteLineReportingBotConfigShopByPkArgs, 'id'>>;
  delete_membershipCardTitle?: Resolver<Maybe<ResolversTypes['membershipCardTitle_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteMembershipCardTitleArgs, 'where'>>;
  delete_membershipCardTitle_by_pk?: Resolver<Maybe<ResolversTypes['membershipCardTitle']>, ParentType, ContextType, RequireFields<MutationRootDeleteMembershipCardTitleByPkArgs, 'id'>>;
  delete_membershipRankConfig?: Resolver<Maybe<ResolversTypes['membershipRankConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteMembershipRankConfigArgs, 'where'>>;
  delete_membershipRankConfig_by_pk?: Resolver<Maybe<ResolversTypes['membershipRankConfig']>, ParentType, ContextType, RequireFields<MutationRootDeleteMembershipRankConfigByPkArgs, 'id'>>;
  delete_menuOption?: Resolver<Maybe<ResolversTypes['menuOption_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteMenuOptionArgs, 'where'>>;
  delete_menuOption_by_pk?: Resolver<Maybe<ResolversTypes['menuOption']>, ParentType, ContextType, RequireFields<MutationRootDeleteMenuOptionByPkArgs, 'id'>>;
  delete_onSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteOnSitePaymentDetailTypeArgs, 'where'>>;
  delete_onSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootDeleteOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  delete_onSitePaymentDiscountType?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteOnSitePaymentDiscountTypeArgs, 'where'>>;
  delete_onSitePaymentDiscountType_by_pk?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType']>, ParentType, ContextType, RequireFields<MutationRootDeleteOnSitePaymentDiscountTypeByPkArgs, 'id'>>;
  delete_onlinePaymentPrinterRoleMeta?: Resolver<Maybe<ResolversTypes['onlinePaymentPrinterRoleMeta_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteOnlinePaymentPrinterRoleMetaArgs, 'where'>>;
  delete_onlinePaymentPrinterRoleMeta_by_pk?: Resolver<Maybe<ResolversTypes['onlinePaymentPrinterRoleMeta']>, ParentType, ContextType, RequireFields<MutationRootDeleteOnlinePaymentPrinterRoleMetaByPkArgs, 'id'>>;
  delete_optionInputType?: Resolver<Maybe<ResolversTypes['optionInputType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteOptionInputTypeArgs, 'where'>>;
  delete_optionInputType_by_pk?: Resolver<Maybe<ResolversTypes['optionInputType']>, ParentType, ContextType, RequireFields<MutationRootDeleteOptionInputTypeByPkArgs, 'value'>>;
  delete_orderableTime?: Resolver<Maybe<ResolversTypes['orderableTime_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteOrderableTimeArgs, 'where'>>;
  delete_orderableTime_by_pk?: Resolver<Maybe<ResolversTypes['orderableTime']>, ParentType, ContextType, RequireFields<MutationRootDeleteOrderableTimeByPkArgs, 'id'>>;
  delete_paymentReceiptRoleTable?: Resolver<Maybe<ResolversTypes['paymentReceiptRoleTable_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeletePaymentReceiptRoleTableArgs, 'where'>>;
  delete_pikaichiMenu?: Resolver<Maybe<ResolversTypes['pikaichiMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiMenuArgs, 'where'>>;
  delete_pikaichiMenuChoice?: Resolver<Maybe<ResolversTypes['pikaichiMenuChoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiMenuChoiceArgs, 'where'>>;
  delete_pikaichiMenuChoice_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuChoice']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiMenuChoiceByPkArgs, 'pikaichiMenuChoiceId'>>;
  delete_pikaichiMenuMenu?: Resolver<Maybe<ResolversTypes['pikaichiMenuMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiMenuMenuArgs, 'where'>>;
  delete_pikaichiMenuMenu_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuMenu']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiMenuMenuByPkArgs, 'pikaichiMenuMenuId'>>;
  delete_pikaichiMenuPlan?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlan_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiMenuPlanArgs, 'where'>>;
  delete_pikaichiMenuPlanChoice?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlanChoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiMenuPlanChoiceArgs, 'where'>>;
  delete_pikaichiMenuPlanChoice_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlanChoice']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiMenuPlanChoiceByPkArgs, 'pikaichiMenuPlanChoiceId'>>;
  delete_pikaichiMenuPlan_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlan']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiMenuPlanByPkArgs, 'pikaichiMenuPlanId'>>;
  delete_pikaichiMenu_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenu']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiMenuByPkArgs, 'pikaichiMenuId'>>;
  delete_pikaichiOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiOnSitePaymentDetailTypeArgs, 'where'>>;
  delete_pikaichiOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootDeletePikaichiOnSitePaymentDetailTypeByPkArgs, 'pikaichiPaymentDetailTypeId'>>;
  delete_planFirstOrderCategoryMenu?: Resolver<Maybe<ResolversTypes['planFirstOrderCategoryMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeletePlanFirstOrderCategoryMenuArgs, 'where'>>;
  delete_planFirstOrderCategoryMenu_by_pk?: Resolver<Maybe<ResolversTypes['planFirstOrderCategoryMenu']>, ParentType, ContextType, RequireFields<MutationRootDeletePlanFirstOrderCategoryMenuByPkArgs, 'id'>>;
  delete_planGroup?: Resolver<Maybe<ResolversTypes['planGroup_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeletePlanGroupArgs, 'where'>>;
  delete_planGroup_by_pk?: Resolver<Maybe<ResolversTypes['planGroup']>, ParentType, ContextType, RequireFields<MutationRootDeletePlanGroupByPkArgs, 'serial'>>;
  delete_planMenuCategory?: Resolver<Maybe<ResolversTypes['planMenuCategory_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeletePlanMenuCategoryArgs, 'where'>>;
  delete_planMenuCategory_by_pk?: Resolver<Maybe<ResolversTypes['planMenuCategory']>, ParentType, ContextType, RequireFields<MutationRootDeletePlanMenuCategoryByPkArgs, 'id'>>;
  delete_plan_menu_categories_category?: Resolver<Maybe<ResolversTypes['plan_menu_categories_category_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeletePlanMenuCategoriesCategoryArgs, 'where'>>;
  delete_questionnaire?: Resolver<Maybe<ResolversTypes['questionnaire_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteQuestionnaireArgs, 'where'>>;
  delete_questionnaire_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire']>, ParentType, ContextType, RequireFields<MutationRootDeleteQuestionnaireByPkArgs, 'id'>>;
  delete_role?: Resolver<Maybe<ResolversTypes['role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteRoleArgs, 'where'>>;
  delete_roleTablesTable?: Resolver<Maybe<ResolversTypes['roleTablesTable_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteRoleTablesTableArgs, 'where'>>;
  delete_role_by_pk?: Resolver<Maybe<ResolversTypes['role']>, ParentType, ContextType, RequireFields<MutationRootDeleteRoleByPkArgs, 'serial'>>;
  delete_shopBusinessOperationHour?: Resolver<Maybe<ResolversTypes['shopBusinessOperationHour_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopBusinessOperationHourArgs, 'where'>>;
  delete_shopBusinessOperationHour_by_pk?: Resolver<Maybe<ResolversTypes['shopBusinessOperationHour']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopBusinessOperationHourByPkArgs, 'id'>>;
  delete_shopClerk?: Resolver<Maybe<ResolversTypes['shopClerk_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopClerkArgs, 'where'>>;
  delete_shopClerk_by_pk?: Resolver<Maybe<ResolversTypes['shopClerk']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopClerkByPkArgs, '_clerkId' | 'shopId'>>;
  delete_shopClient?: Resolver<Maybe<ResolversTypes['shopClient_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopClientArgs, 'where'>>;
  delete_shopClient_by_pk?: Resolver<Maybe<ResolversTypes['shopClient']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopClientByPkArgs, 'shopClientId'>>;
  delete_shopCookingItemRole?: Resolver<Maybe<ResolversTypes['shopCookingItemRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopCookingItemRoleArgs, 'where'>>;
  delete_shopCookingItemRole_by_pk?: Resolver<Maybe<ResolversTypes['shopCookingItemRole']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopCookingItemRoleByPkArgs, 'id'>>;
  delete_shopMenuCookingItem?: Resolver<Maybe<ResolversTypes['shopMenuCookingItem_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopMenuCookingItemArgs, 'where'>>;
  delete_shopMenuKdDisplayTarget?: Resolver<Maybe<ResolversTypes['shopMenuKdDisplayTarget_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopMenuKdDisplayTargetArgs, 'where'>>;
  delete_shopMenuKdDisplayTarget_by_pk?: Resolver<Maybe<ResolversTypes['shopMenuKdDisplayTarget']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopMenuKdDisplayTargetByPkArgs, 'id'>>;
  delete_shopMenuKitchenRole?: Resolver<Maybe<ResolversTypes['shopMenuKitchenRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopMenuKitchenRoleArgs, 'where'>>;
  delete_shopOptionKitchenRole?: Resolver<Maybe<ResolversTypes['shopOptionKitchenRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopOptionKitchenRoleArgs, 'where'>>;
  delete_shopOptionKitchenRole_by_pk?: Resolver<Maybe<ResolversTypes['shopOptionKitchenRole']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopOptionKitchenRoleByPkArgs, 'id'>>;
  delete_shopOrderableTimeTerm?: Resolver<Maybe<ResolversTypes['shopOrderableTimeTerm_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopOrderableTimeTermArgs, 'where'>>;
  delete_shopOrderableTimeTerm_by_pk?: Resolver<Maybe<ResolversTypes['shopOrderableTimeTerm']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopOrderableTimeTermByPkArgs, 'id'>>;
  delete_shopPlanKitchenRole?: Resolver<Maybe<ResolversTypes['shopPlanKitchenRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopPlanKitchenRoleArgs, 'where'>>;
  delete_shopPlanOption?: Resolver<Maybe<ResolversTypes['shopPlanOption_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopPlanOptionArgs, 'where'>>;
  delete_shopPlanOptionKitchenRole?: Resolver<Maybe<ResolversTypes['shopPlanOptionKitchenRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopPlanOptionKitchenRoleArgs, 'where'>>;
  delete_shopPlanOption_by_pk?: Resolver<Maybe<ResolversTypes['shopPlanOption']>, ParentType, ContextType, RequireFields<MutationRootDeleteShopPlanOptionByPkArgs, 'id'>>;
  delete_steraOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['steraOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteSteraOnSitePaymentDetailTypeArgs, 'where'>>;
  delete_steraOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['steraOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootDeleteSteraOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  delete_stock?: Resolver<Maybe<ResolversTypes['stock_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteStockArgs, 'where'>>;
  delete_stock_by_pk?: Resolver<Maybe<ResolversTypes['stock']>, ParentType, ContextType, RequireFields<MutationRootDeleteStockByPkArgs, 'id'>>;
  delete_tecAggregationMenu?: Resolver<Maybe<ResolversTypes['tecAggregationMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteTecAggregationMenuArgs, 'where'>>;
  delete_tecAggregationMenu_by_pk?: Resolver<Maybe<ResolversTypes['tecAggregationMenu']>, ParentType, ContextType, RequireFields<MutationRootDeleteTecAggregationMenuByPkArgs, 'id'>>;
  delete_tecAggregationOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['tecAggregationOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteTecAggregationOnSitePaymentDetailTypeArgs, 'where'>>;
  delete_tecAggregationOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootDeleteTecAggregationOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  delete_winboardConfig?: Resolver<Maybe<ResolversTypes['winboardConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteWinboardConfigArgs, 'where'>>;
  delete_winboardConfig_by_pk?: Resolver<Maybe<ResolversTypes['winboardConfig']>, ParentType, ContextType, RequireFields<MutationRootDeleteWinboardConfigByPkArgs, 'winboardConfigId'>>;
  delete_winboardInflowSourceTag?: Resolver<Maybe<ResolversTypes['winboardInflowSourceTag_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteWinboardInflowSourceTagArgs, 'where'>>;
  delete_winboardInflowSourceTag_by_pk?: Resolver<Maybe<ResolversTypes['winboardInflowSourceTag']>, ParentType, ContextType, RequireFields<MutationRootDeleteWinboardInflowSourceTagByPkArgs, 'id'>>;
  delete_winboardMenu?: Resolver<Maybe<ResolversTypes['winboardMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootDeleteWinboardMenuArgs, 'where'>>;
  delete_winboardMenu_by_pk?: Resolver<Maybe<ResolversTypes['winboardMenu']>, ParentType, ContextType, RequireFields<MutationRootDeleteWinboardMenuByPkArgs, 'id'>>;
  foodingJournalUploadSales?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootFoodingJournalUploadSalesArgs, 'input'>>;
  insert_accountingSlipImage?: Resolver<Maybe<ResolversTypes['accountingSlipImage_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertAccountingSlipImageArgs, 'objects'>>;
  insert_accountingSlipImage_one?: Resolver<Maybe<ResolversTypes['accountingSlipImage']>, ParentType, ContextType, RequireFields<MutationRootInsertAccountingSlipImageOneArgs, 'object'>>;
  insert_adyenPaymentStoreMeta?: Resolver<Maybe<ResolversTypes['adyenPaymentStoreMeta_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertAdyenPaymentStoreMetaArgs, 'objects'>>;
  insert_adyenPaymentStoreMeta_one?: Resolver<Maybe<ResolversTypes['adyenPaymentStoreMeta']>, ParentType, ContextType, RequireFields<MutationRootInsertAdyenPaymentStoreMetaOneArgs, 'object'>>;
  insert_analyticsSetting?: Resolver<Maybe<ResolversTypes['analyticsSetting_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertAnalyticsSettingArgs, 'objects'>>;
  insert_analyticsSetting_one?: Resolver<Maybe<ResolversTypes['analyticsSetting']>, ParentType, ContextType, RequireFields<MutationRootInsertAnalyticsSettingOneArgs, 'object'>>;
  insert_cashRegisterConfig?: Resolver<Maybe<ResolversTypes['cashRegisterConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertCashRegisterConfigArgs, 'objects'>>;
  insert_cashRegisterConfig_one?: Resolver<Maybe<ResolversTypes['cashRegisterConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertCashRegisterConfigOneArgs, 'object'>>;
  insert_category?: Resolver<Maybe<ResolversTypes['category_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertCategoryArgs, 'objects'>>;
  insert_categoryMenu?: Resolver<Maybe<ResolversTypes['categoryMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertCategoryMenuArgs, 'objects'>>;
  insert_categoryMenu_one?: Resolver<Maybe<ResolversTypes['categoryMenu']>, ParentType, ContextType, RequireFields<MutationRootInsertCategoryMenuOneArgs, 'object'>>;
  insert_category_one?: Resolver<Maybe<ResolversTypes['category']>, ParentType, ContextType, RequireFields<MutationRootInsertCategoryOneArgs, 'object'>>;
  insert_choice?: Resolver<Maybe<ResolversTypes['choice_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertChoiceArgs, 'objects'>>;
  insert_choice_one?: Resolver<Maybe<ResolversTypes['choice']>, ParentType, ContextType, RequireFields<MutationRootInsertChoiceOneArgs, 'object'>>;
  insert_clerk?: Resolver<Maybe<ResolversTypes['clerk_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertClerkArgs, 'objects'>>;
  insert_clerk_one?: Resolver<Maybe<ResolversTypes['clerk']>, ParentType, ContextType, RequireFields<MutationRootInsertClerkOneArgs, 'object'>>;
  insert_company?: Resolver<Maybe<ResolversTypes['company_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertCompanyArgs, 'objects'>>;
  insert_company_one?: Resolver<Maybe<ResolversTypes['company']>, ParentType, ContextType, RequireFields<MutationRootInsertCompanyOneArgs, 'object'>>;
  insert_compulsoryAppetizerConfig?: Resolver<Maybe<ResolversTypes['compulsoryAppetizerConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertCompulsoryAppetizerConfigArgs, 'objects'>>;
  insert_compulsoryAppetizerConfig_one?: Resolver<Maybe<ResolversTypes['compulsoryAppetizerConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertCompulsoryAppetizerConfigOneArgs, 'object'>>;
  insert_connectGameConfig?: Resolver<Maybe<ResolversTypes['connectGameConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertConnectGameConfigArgs, 'objects'>>;
  insert_connectGameConfigShop?: Resolver<Maybe<ResolversTypes['connectGameConfigShop_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertConnectGameConfigShopArgs, 'objects'>>;
  insert_connectGameConfigShop_one?: Resolver<Maybe<ResolversTypes['connectGameConfigShop']>, ParentType, ContextType, RequireFields<MutationRootInsertConnectGameConfigShopOneArgs, 'object'>>;
  insert_connectGameConfig_one?: Resolver<Maybe<ResolversTypes['connectGameConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertConnectGameConfigOneArgs, 'object'>>;
  insert_cookingItem?: Resolver<Maybe<ResolversTypes['cookingItem_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertCookingItemArgs, 'objects'>>;
  insert_cookingItem_one?: Resolver<Maybe<ResolversTypes['cookingItem']>, ParentType, ContextType, RequireFields<MutationRootInsertCookingItemOneArgs, 'object'>>;
  insert_corporation?: Resolver<Maybe<ResolversTypes['corporation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertCorporationArgs, 'objects'>>;
  insert_corporation_one?: Resolver<Maybe<ResolversTypes['corporation']>, ParentType, ContextType, RequireFields<MutationRootInsertCorporationOneArgs, 'object'>>;
  insert_coupon?: Resolver<Maybe<ResolversTypes['coupon_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertCouponArgs, 'objects'>>;
  insert_couponMenuDiscount?: Resolver<Maybe<ResolversTypes['couponMenuDiscount_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertCouponMenuDiscountArgs, 'objects'>>;
  insert_couponMenuDiscount_one?: Resolver<Maybe<ResolversTypes['couponMenuDiscount']>, ParentType, ContextType, RequireFields<MutationRootInsertCouponMenuDiscountOneArgs, 'object'>>;
  insert_coupon_one?: Resolver<Maybe<ResolversTypes['coupon']>, ParentType, ContextType, RequireFields<MutationRootInsertCouponOneArgs, 'object'>>;
  insert_dailySalesBudget?: Resolver<Maybe<ResolversTypes['dailySalesBudget_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDailySalesBudgetArgs, 'objects'>>;
  insert_dailySalesBudget_one?: Resolver<Maybe<ResolversTypes['dailySalesBudget']>, ParentType, ContextType, RequireFields<MutationRootInsertDailySalesBudgetOneArgs, 'object'>>;
  insert_dashboardAccountRole?: Resolver<Maybe<ResolversTypes['dashboardAccountRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDashboardAccountRoleArgs, 'objects'>>;
  insert_dashboardAccountRolePermission?: Resolver<Maybe<ResolversTypes['dashboardAccountRolePermission_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDashboardAccountRolePermissionArgs, 'objects'>>;
  insert_dashboardAccountRolePermission_one?: Resolver<Maybe<ResolversTypes['dashboardAccountRolePermission']>, ParentType, ContextType, RequireFields<MutationRootInsertDashboardAccountRolePermissionOneArgs, 'object'>>;
  insert_dashboardAccountRole_one?: Resolver<Maybe<ResolversTypes['dashboardAccountRole']>, ParentType, ContextType, RequireFields<MutationRootInsertDashboardAccountRoleOneArgs, 'object'>>;
  insert_dishUpSlipGroup?: Resolver<Maybe<ResolversTypes['dishUpSlipGroup_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDishUpSlipGroupArgs, 'objects'>>;
  insert_dishUpSlipGroupRoles?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupRoles_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDishUpSlipGroupRolesArgs, 'objects'>>;
  insert_dishUpSlipGroupRoles_one?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupRoles']>, ParentType, ContextType, RequireFields<MutationRootInsertDishUpSlipGroupRolesOneArgs, 'object'>>;
  insert_dishUpSlipGroupShopMenus?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopMenus_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDishUpSlipGroupShopMenusArgs, 'objects'>>;
  insert_dishUpSlipGroupShopMenus_one?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopMenus']>, ParentType, ContextType, RequireFields<MutationRootInsertDishUpSlipGroupShopMenusOneArgs, 'object'>>;
  insert_dishUpSlipGroupShopPlans?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopPlans_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDishUpSlipGroupShopPlansArgs, 'objects'>>;
  insert_dishUpSlipGroupShopPlans_one?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopPlans']>, ParentType, ContextType, RequireFields<MutationRootInsertDishUpSlipGroupShopPlansOneArgs, 'object'>>;
  insert_dishUpSlipGroupTables?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupTables_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertDishUpSlipGroupTablesArgs, 'objects'>>;
  insert_dishUpSlipGroupTables_one?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupTables']>, ParentType, ContextType, RequireFields<MutationRootInsertDishUpSlipGroupTablesOneArgs, 'object'>>;
  insert_dishUpSlipGroup_one?: Resolver<Maybe<ResolversTypes['dishUpSlipGroup']>, ParentType, ContextType, RequireFields<MutationRootInsertDishUpSlipGroupOneArgs, 'object'>>;
  insert_ebicaConfig?: Resolver<Maybe<ResolversTypes['ebicaConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertEbicaConfigArgs, 'objects'>>;
  insert_ebicaConfig_one?: Resolver<Maybe<ResolversTypes['ebicaConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertEbicaConfigOneArgs, 'object'>>;
  insert_ebicaTable?: Resolver<Maybe<ResolversTypes['ebicaTable_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertEbicaTableArgs, 'objects'>>;
  insert_ebicaTable_one?: Resolver<Maybe<ResolversTypes['ebicaTable']>, ParentType, ContextType, RequireFields<MutationRootInsertEbicaTableOneArgs, 'object'>>;
  insert_externalOnlineMenu?: Resolver<Maybe<ResolversTypes['externalOnlineMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertExternalOnlineMenuArgs, 'objects'>>;
  insert_externalOnlineMenuAvailableTimeTerm?: Resolver<Maybe<ResolversTypes['externalOnlineMenuAvailableTimeTerm_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertExternalOnlineMenuAvailableTimeTermArgs, 'objects'>>;
  insert_externalOnlineMenuAvailableTimeTerm_one?: Resolver<Maybe<ResolversTypes['externalOnlineMenuAvailableTimeTerm']>, ParentType, ContextType, RequireFields<MutationRootInsertExternalOnlineMenuAvailableTimeTermOneArgs, 'object'>>;
  insert_externalOnlineMenuConfig?: Resolver<Maybe<ResolversTypes['externalOnlineMenuConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertExternalOnlineMenuConfigArgs, 'objects'>>;
  insert_externalOnlineMenuConfig_one?: Resolver<Maybe<ResolversTypes['externalOnlineMenuConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertExternalOnlineMenuConfigOneArgs, 'object'>>;
  insert_externalOnlineMenu_one?: Resolver<Maybe<ResolversTypes['externalOnlineMenu']>, ParentType, ContextType, RequireFields<MutationRootInsertExternalOnlineMenuOneArgs, 'object'>>;
  insert_featureFlagShop?: Resolver<Maybe<ResolversTypes['featureFlagShop_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertFeatureFlagShopArgs, 'objects'>>;
  insert_featureFlagShop_one?: Resolver<Maybe<ResolversTypes['featureFlagShop']>, ParentType, ContextType, RequireFields<MutationRootInsertFeatureFlagShopOneArgs, 'object'>>;
  insert_foodingJournalConfig?: Resolver<Maybe<ResolversTypes['foodingJournalConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalConfigArgs, 'objects'>>;
  insert_foodingJournalConfig_one?: Resolver<Maybe<ResolversTypes['foodingJournalConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalConfigOneArgs, 'object'>>;
  insert_foodingJournalDepartmentMaster?: Resolver<Maybe<ResolversTypes['foodingJournalDepartmentMaster_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalDepartmentMasterArgs, 'objects'>>;
  insert_foodingJournalDepartmentMaster_one?: Resolver<Maybe<ResolversTypes['foodingJournalDepartmentMaster']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalDepartmentMasterOneArgs, 'object'>>;
  insert_foodingJournalGroupMaster?: Resolver<Maybe<ResolversTypes['foodingJournalGroupMaster_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalGroupMasterArgs, 'objects'>>;
  insert_foodingJournalGroupMaster_one?: Resolver<Maybe<ResolversTypes['foodingJournalGroupMaster']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalGroupMasterOneArgs, 'object'>>;
  insert_foodingJournalMenu?: Resolver<Maybe<ResolversTypes['foodingJournalMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalMenuArgs, 'objects'>>;
  insert_foodingJournalMenu_one?: Resolver<Maybe<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalMenuOneArgs, 'object'>>;
  insert_foodingJournalOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalOnSitePaymentDetailTypeArgs, 'objects'>>;
  insert_foodingJournalOnSitePaymentDetailType_one?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalOnSitePaymentDetailTypeOneArgs, 'object'>>;
  insert_foodingJournalOnSitePaymentDiscountType?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDiscountType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalOnSitePaymentDiscountTypeArgs, 'objects'>>;
  insert_foodingJournalOnSitePaymentDiscountType_one?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDiscountType']>, ParentType, ContextType, RequireFields<MutationRootInsertFoodingJournalOnSitePaymentDiscountTypeOneArgs, 'object'>>;
  insert_giftMeta?: Resolver<Maybe<ResolversTypes['giftMeta_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertGiftMetaArgs, 'objects'>>;
  insert_giftMeta_one?: Resolver<Maybe<ResolversTypes['giftMeta']>, ParentType, ContextType, RequireFields<MutationRootInsertGiftMetaOneArgs, 'object'>>;
  insert_giftOptionMeta?: Resolver<Maybe<ResolversTypes['giftOptionMeta_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertGiftOptionMetaArgs, 'objects'>>;
  insert_giftOptionMeta_one?: Resolver<Maybe<ResolversTypes['giftOptionMeta']>, ParentType, ContextType, RequireFields<MutationRootInsertGiftOptionMetaOneArgs, 'object'>>;
  insert_gmoOffPaymentOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['gmoOffPaymentOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertGmoOffPaymentOnSitePaymentDetailTypeArgs, 'objects'>>;
  insert_gmoOffPaymentOnSitePaymentDetailType_one?: Resolver<Maybe<ResolversTypes['gmoOffPaymentOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootInsertGmoOffPaymentOnSitePaymentDetailTypeOneArgs, 'object'>>;
  insert_gmoOffPaymentShopConfig?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertGmoOffPaymentShopConfigArgs, 'objects'>>;
  insert_gmoOffPaymentShopConfigTransactionFeeRate?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertGmoOffPaymentShopConfigTransactionFeeRateArgs, 'objects'>>;
  insert_gmoOffPaymentShopConfigTransactionFeeRate_one?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate']>, ParentType, ContextType, RequireFields<MutationRootInsertGmoOffPaymentShopConfigTransactionFeeRateOneArgs, 'object'>>;
  insert_gmoOffPaymentShopConfig_one?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertGmoOffPaymentShopConfigOneArgs, 'object'>>;
  insert_inflowSourceTag?: Resolver<Maybe<ResolversTypes['inflowSourceTag_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertInflowSourceTagArgs, 'objects'>>;
  insert_inflowSourceTag_one?: Resolver<Maybe<ResolversTypes['inflowSourceTag']>, ParentType, ContextType, RequireFields<MutationRootInsertInflowSourceTagOneArgs, 'object'>>;
  insert_kdDisplayTarget?: Resolver<Maybe<ResolversTypes['kdDisplayTarget_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertKdDisplayTargetArgs, 'objects'>>;
  insert_kdDisplayTarget_one?: Resolver<Maybe<ResolversTypes['kdDisplayTarget']>, ParentType, ContextType, RequireFields<MutationRootInsertKdDisplayTargetOneArgs, 'object'>>;
  insert_lineChannelConfig?: Resolver<Maybe<ResolversTypes['lineChannelConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertLineChannelConfigArgs, 'objects'>>;
  insert_lineChannelConfig_one?: Resolver<Maybe<ResolversTypes['lineChannelConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertLineChannelConfigOneArgs, 'object'>>;
  insert_lineReportingBotConfigShop?: Resolver<Maybe<ResolversTypes['lineReportingBotConfigShop_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertLineReportingBotConfigShopArgs, 'objects'>>;
  insert_lineReportingBotConfigShop_one?: Resolver<Maybe<ResolversTypes['lineReportingBotConfigShop']>, ParentType, ContextType, RequireFields<MutationRootInsertLineReportingBotConfigShopOneArgs, 'object'>>;
  insert_membershipCard?: Resolver<Maybe<ResolversTypes['membershipCard_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertMembershipCardArgs, 'objects'>>;
  insert_membershipCardTitle?: Resolver<Maybe<ResolversTypes['membershipCardTitle_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertMembershipCardTitleArgs, 'objects'>>;
  insert_membershipCardTitle_one?: Resolver<Maybe<ResolversTypes['membershipCardTitle']>, ParentType, ContextType, RequireFields<MutationRootInsertMembershipCardTitleOneArgs, 'object'>>;
  insert_membershipCard_one?: Resolver<Maybe<ResolversTypes['membershipCard']>, ParentType, ContextType, RequireFields<MutationRootInsertMembershipCardOneArgs, 'object'>>;
  insert_menu?: Resolver<Maybe<ResolversTypes['menu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertMenuArgs, 'objects'>>;
  insert_menuOption?: Resolver<Maybe<ResolversTypes['menuOption_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertMenuOptionArgs, 'objects'>>;
  insert_menuOption_one?: Resolver<Maybe<ResolversTypes['menuOption']>, ParentType, ContextType, RequireFields<MutationRootInsertMenuOptionOneArgs, 'object'>>;
  insert_menuRecommendationMeta?: Resolver<Maybe<ResolversTypes['menuRecommendationMeta_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertMenuRecommendationMetaArgs, 'objects'>>;
  insert_menuRecommendationMeta_one?: Resolver<Maybe<ResolversTypes['menuRecommendationMeta']>, ParentType, ContextType, RequireFields<MutationRootInsertMenuRecommendationMetaOneArgs, 'object'>>;
  insert_menu_one?: Resolver<Maybe<ResolversTypes['menu']>, ParentType, ContextType, RequireFields<MutationRootInsertMenuOneArgs, 'object'>>;
  insert_monthlySalesBudget?: Resolver<Maybe<ResolversTypes['monthlySalesBudget_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertMonthlySalesBudgetArgs, 'objects'>>;
  insert_monthlySalesBudget_one?: Resolver<Maybe<ResolversTypes['monthlySalesBudget']>, ParentType, ContextType, RequireFields<MutationRootInsertMonthlySalesBudgetOneArgs, 'object'>>;
  insert_onSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertOnSitePaymentDetailTypeArgs, 'objects'>>;
  insert_onSitePaymentDetailType_one?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootInsertOnSitePaymentDetailTypeOneArgs, 'object'>>;
  insert_onSitePaymentDiscountType?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertOnSitePaymentDiscountTypeArgs, 'objects'>>;
  insert_onSitePaymentDiscountType_one?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType']>, ParentType, ContextType, RequireFields<MutationRootInsertOnSitePaymentDiscountTypeOneArgs, 'object'>>;
  insert_onlinePaymentPrinterRoleMeta?: Resolver<Maybe<ResolversTypes['onlinePaymentPrinterRoleMeta_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertOnlinePaymentPrinterRoleMetaArgs, 'objects'>>;
  insert_onlinePaymentPrinterRoleMeta_one?: Resolver<Maybe<ResolversTypes['onlinePaymentPrinterRoleMeta']>, ParentType, ContextType, RequireFields<MutationRootInsertOnlinePaymentPrinterRoleMetaOneArgs, 'object'>>;
  insert_option?: Resolver<Maybe<ResolversTypes['option_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertOptionArgs, 'objects'>>;
  insert_optionInputType?: Resolver<Maybe<ResolversTypes['optionInputType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertOptionInputTypeArgs, 'objects'>>;
  insert_optionInputType_one?: Resolver<Maybe<ResolversTypes['optionInputType']>, ParentType, ContextType, RequireFields<MutationRootInsertOptionInputTypeOneArgs, 'object'>>;
  insert_option_one?: Resolver<Maybe<ResolversTypes['option']>, ParentType, ContextType, RequireFields<MutationRootInsertOptionOneArgs, 'object'>>;
  insert_orderableTime?: Resolver<Maybe<ResolversTypes['orderableTime_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertOrderableTimeArgs, 'objects'>>;
  insert_orderableTime_one?: Resolver<Maybe<ResolversTypes['orderableTime']>, ParentType, ContextType, RequireFields<MutationRootInsertOrderableTimeOneArgs, 'object'>>;
  insert_paymentReceiptRoleTable?: Resolver<Maybe<ResolversTypes['paymentReceiptRoleTable_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPaymentReceiptRoleTableArgs, 'objects'>>;
  insert_paymentReceiptRoleTable_one?: Resolver<Maybe<ResolversTypes['paymentReceiptRoleTable']>, ParentType, ContextType, RequireFields<MutationRootInsertPaymentReceiptRoleTableOneArgs, 'object'>>;
  insert_pikaichiConfig?: Resolver<Maybe<ResolversTypes['pikaichiConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiConfigArgs, 'objects'>>;
  insert_pikaichiConfig_one?: Resolver<Maybe<ResolversTypes['pikaichiConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiConfigOneArgs, 'object'>>;
  insert_pikaichiMenu?: Resolver<Maybe<ResolversTypes['pikaichiMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiMenuArgs, 'objects'>>;
  insert_pikaichiMenuChoice?: Resolver<Maybe<ResolversTypes['pikaichiMenuChoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiMenuChoiceArgs, 'objects'>>;
  insert_pikaichiMenuChoice_one?: Resolver<Maybe<ResolversTypes['pikaichiMenuChoice']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiMenuChoiceOneArgs, 'object'>>;
  insert_pikaichiMenuMenu?: Resolver<Maybe<ResolversTypes['pikaichiMenuMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiMenuMenuArgs, 'objects'>>;
  insert_pikaichiMenuMenu_one?: Resolver<Maybe<ResolversTypes['pikaichiMenuMenu']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiMenuMenuOneArgs, 'object'>>;
  insert_pikaichiMenuPlan?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlan_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiMenuPlanArgs, 'objects'>>;
  insert_pikaichiMenuPlanChoice?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlanChoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiMenuPlanChoiceArgs, 'objects'>>;
  insert_pikaichiMenuPlanChoice_one?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlanChoice']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiMenuPlanChoiceOneArgs, 'object'>>;
  insert_pikaichiMenuPlan_one?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlan']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiMenuPlanOneArgs, 'object'>>;
  insert_pikaichiMenu_one?: Resolver<Maybe<ResolversTypes['pikaichiMenu']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiMenuOneArgs, 'object'>>;
  insert_pikaichiOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiOnSitePaymentDetailTypeArgs, 'objects'>>;
  insert_pikaichiOnSitePaymentDetailType_one?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiOnSitePaymentDetailTypeOneArgs, 'object'>>;
  insert_pikaichiOnSitePaymentDiscountType?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDiscountType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiOnSitePaymentDiscountTypeArgs, 'objects'>>;
  insert_pikaichiOnSitePaymentDiscountType_one?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDiscountType']>, ParentType, ContextType, RequireFields<MutationRootInsertPikaichiOnSitePaymentDiscountTypeOneArgs, 'object'>>;
  insert_plan?: Resolver<Maybe<ResolversTypes['plan_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanArgs, 'objects'>>;
  insert_planChoice?: Resolver<Maybe<ResolversTypes['planChoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanChoiceArgs, 'objects'>>;
  insert_planChoice_one?: Resolver<Maybe<ResolversTypes['planChoice']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanChoiceOneArgs, 'object'>>;
  insert_planFirstOrderCategoryMenu?: Resolver<Maybe<ResolversTypes['planFirstOrderCategoryMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanFirstOrderCategoryMenuArgs, 'objects'>>;
  insert_planFirstOrderCategoryMenu_one?: Resolver<Maybe<ResolversTypes['planFirstOrderCategoryMenu']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanFirstOrderCategoryMenuOneArgs, 'object'>>;
  insert_planGroup?: Resolver<Maybe<ResolversTypes['planGroup_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanGroupArgs, 'objects'>>;
  insert_planGroup_one?: Resolver<Maybe<ResolversTypes['planGroup']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanGroupOneArgs, 'object'>>;
  insert_planMenuCategory?: Resolver<Maybe<ResolversTypes['planMenuCategory_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanMenuCategoryArgs, 'objects'>>;
  insert_planMenuCategory_one?: Resolver<Maybe<ResolversTypes['planMenuCategory']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanMenuCategoryOneArgs, 'object'>>;
  insert_planOption?: Resolver<Maybe<ResolversTypes['planOption_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanOptionArgs, 'objects'>>;
  insert_planOption_one?: Resolver<Maybe<ResolversTypes['planOption']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanOptionOneArgs, 'object'>>;
  insert_plan_menu_categories_category?: Resolver<Maybe<ResolversTypes['plan_menu_categories_category_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanMenuCategoriesCategoryArgs, 'objects'>>;
  insert_plan_menu_categories_category_one?: Resolver<Maybe<ResolversTypes['plan_menu_categories_category']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanMenuCategoriesCategoryOneArgs, 'object'>>;
  insert_plan_one?: Resolver<Maybe<ResolversTypes['plan']>, ParentType, ContextType, RequireFields<MutationRootInsertPlanOneArgs, 'object'>>;
  insert_questionnaire?: Resolver<Maybe<ResolversTypes['questionnaire_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertQuestionnaireArgs, 'objects'>>;
  insert_questionnaireConfig?: Resolver<Maybe<ResolversTypes['questionnaireConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertQuestionnaireConfigArgs, 'objects'>>;
  insert_questionnaireConfig_one?: Resolver<Maybe<ResolversTypes['questionnaireConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertQuestionnaireConfigOneArgs, 'object'>>;
  insert_questionnaire_one?: Resolver<Maybe<ResolversTypes['questionnaire']>, ParentType, ContextType, RequireFields<MutationRootInsertQuestionnaireOneArgs, 'object'>>;
  insert_role?: Resolver<Maybe<ResolversTypes['role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertRoleArgs, 'objects'>>;
  insert_roleTablesTable?: Resolver<Maybe<ResolversTypes['roleTablesTable_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertRoleTablesTableArgs, 'objects'>>;
  insert_roleTablesTable_one?: Resolver<Maybe<ResolversTypes['roleTablesTable']>, ParentType, ContextType, RequireFields<MutationRootInsertRoleTablesTableOneArgs, 'object'>>;
  insert_role_one?: Resolver<Maybe<ResolversTypes['role']>, ParentType, ContextType, RequireFields<MutationRootInsertRoleOneArgs, 'object'>>;
  insert_salesBudgetAllocationSetting?: Resolver<Maybe<ResolversTypes['salesBudgetAllocationSetting_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertSalesBudgetAllocationSettingArgs, 'objects'>>;
  insert_salesBudgetAllocationSetting_one?: Resolver<Maybe<ResolversTypes['salesBudgetAllocationSetting']>, ParentType, ContextType, RequireFields<MutationRootInsertSalesBudgetAllocationSettingOneArgs, 'object'>>;
  insert_serviceChargeConfig?: Resolver<Maybe<ResolversTypes['serviceChargeConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertServiceChargeConfigArgs, 'objects'>>;
  insert_serviceChargeConfig_one?: Resolver<Maybe<ResolversTypes['serviceChargeConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertServiceChargeConfigOneArgs, 'object'>>;
  insert_shop?: Resolver<Maybe<ResolversTypes['shop_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopArgs, 'objects'>>;
  insert_shopBusinessOperationHour?: Resolver<Maybe<ResolversTypes['shopBusinessOperationHour_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopBusinessOperationHourArgs, 'objects'>>;
  insert_shopBusinessOperationHour_one?: Resolver<Maybe<ResolversTypes['shopBusinessOperationHour']>, ParentType, ContextType, RequireFields<MutationRootInsertShopBusinessOperationHourOneArgs, 'object'>>;
  insert_shopClerk?: Resolver<Maybe<ResolversTypes['shopClerk_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopClerkArgs, 'objects'>>;
  insert_shopClerk_one?: Resolver<Maybe<ResolversTypes['shopClerk']>, ParentType, ContextType, RequireFields<MutationRootInsertShopClerkOneArgs, 'object'>>;
  insert_shopClient?: Resolver<Maybe<ResolversTypes['shopClient_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopClientArgs, 'objects'>>;
  insert_shopClient_one?: Resolver<Maybe<ResolversTypes['shopClient']>, ParentType, ContextType, RequireFields<MutationRootInsertShopClientOneArgs, 'object'>>;
  insert_shopCookingItemRole?: Resolver<Maybe<ResolversTypes['shopCookingItemRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopCookingItemRoleArgs, 'objects'>>;
  insert_shopCookingItemRole_one?: Resolver<Maybe<ResolversTypes['shopCookingItemRole']>, ParentType, ContextType, RequireFields<MutationRootInsertShopCookingItemRoleOneArgs, 'object'>>;
  insert_shopDiscountType?: Resolver<Maybe<ResolversTypes['shopDiscountType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopDiscountTypeArgs, 'objects'>>;
  insert_shopDiscountType_one?: Resolver<Maybe<ResolversTypes['shopDiscountType']>, ParentType, ContextType, RequireFields<MutationRootInsertShopDiscountTypeOneArgs, 'object'>>;
  insert_shopInflowSourceTag?: Resolver<Maybe<ResolversTypes['shopInflowSourceTag_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopInflowSourceTagArgs, 'objects'>>;
  insert_shopInflowSourceTag_one?: Resolver<Maybe<ResolversTypes['shopInflowSourceTag']>, ParentType, ContextType, RequireFields<MutationRootInsertShopInflowSourceTagOneArgs, 'object'>>;
  insert_shopMenuCookingItem?: Resolver<Maybe<ResolversTypes['shopMenuCookingItem_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopMenuCookingItemArgs, 'objects'>>;
  insert_shopMenuCookingItem_one?: Resolver<Maybe<ResolversTypes['shopMenuCookingItem']>, ParentType, ContextType, RequireFields<MutationRootInsertShopMenuCookingItemOneArgs, 'object'>>;
  insert_shopMenuKdDisplayTarget?: Resolver<Maybe<ResolversTypes['shopMenuKdDisplayTarget_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopMenuKdDisplayTargetArgs, 'objects'>>;
  insert_shopMenuKdDisplayTarget_one?: Resolver<Maybe<ResolversTypes['shopMenuKdDisplayTarget']>, ParentType, ContextType, RequireFields<MutationRootInsertShopMenuKdDisplayTargetOneArgs, 'object'>>;
  insert_shopMenuKitchenRole?: Resolver<Maybe<ResolversTypes['shopMenuKitchenRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopMenuKitchenRoleArgs, 'objects'>>;
  insert_shopMenuKitchenRole_one?: Resolver<Maybe<ResolversTypes['shopMenuKitchenRole']>, ParentType, ContextType, RequireFields<MutationRootInsertShopMenuKitchenRoleOneArgs, 'object'>>;
  insert_shopOptionKitchenRole?: Resolver<Maybe<ResolversTypes['shopOptionKitchenRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopOptionKitchenRoleArgs, 'objects'>>;
  insert_shopOptionKitchenRole_one?: Resolver<Maybe<ResolversTypes['shopOptionKitchenRole']>, ParentType, ContextType, RequireFields<MutationRootInsertShopOptionKitchenRoleOneArgs, 'object'>>;
  insert_shopOrderableTimeTerm?: Resolver<Maybe<ResolversTypes['shopOrderableTimeTerm_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopOrderableTimeTermArgs, 'objects'>>;
  insert_shopOrderableTimeTerm_one?: Resolver<Maybe<ResolversTypes['shopOrderableTimeTerm']>, ParentType, ContextType, RequireFields<MutationRootInsertShopOrderableTimeTermOneArgs, 'object'>>;
  insert_shopPaymentType?: Resolver<Maybe<ResolversTypes['shopPaymentType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopPaymentTypeArgs, 'objects'>>;
  insert_shopPaymentType_one?: Resolver<Maybe<ResolversTypes['shopPaymentType']>, ParentType, ContextType, RequireFields<MutationRootInsertShopPaymentTypeOneArgs, 'object'>>;
  insert_shopPlanKitchenRole?: Resolver<Maybe<ResolversTypes['shopPlanKitchenRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopPlanKitchenRoleArgs, 'objects'>>;
  insert_shopPlanKitchenRole_one?: Resolver<Maybe<ResolversTypes['shopPlanKitchenRole']>, ParentType, ContextType, RequireFields<MutationRootInsertShopPlanKitchenRoleOneArgs, 'object'>>;
  insert_shopPlanOption?: Resolver<Maybe<ResolversTypes['shopPlanOption_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopPlanOptionArgs, 'objects'>>;
  insert_shopPlanOptionKitchenRole?: Resolver<Maybe<ResolversTypes['shopPlanOptionKitchenRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertShopPlanOptionKitchenRoleArgs, 'objects'>>;
  insert_shopPlanOptionKitchenRole_one?: Resolver<Maybe<ResolversTypes['shopPlanOptionKitchenRole']>, ParentType, ContextType, RequireFields<MutationRootInsertShopPlanOptionKitchenRoleOneArgs, 'object'>>;
  insert_shopPlanOption_one?: Resolver<Maybe<ResolversTypes['shopPlanOption']>, ParentType, ContextType, RequireFields<MutationRootInsertShopPlanOptionOneArgs, 'object'>>;
  insert_shop_one?: Resolver<Maybe<ResolversTypes['shop']>, ParentType, ContextType, RequireFields<MutationRootInsertShopOneArgs, 'object'>>;
  insert_specificTimeSurchargeConfig?: Resolver<Maybe<ResolversTypes['specificTimeSurchargeConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertSpecificTimeSurchargeConfigArgs, 'objects'>>;
  insert_specificTimeSurchargeConfig_one?: Resolver<Maybe<ResolversTypes['specificTimeSurchargeConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertSpecificTimeSurchargeConfigOneArgs, 'object'>>;
  insert_steraOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['steraOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertSteraOnSitePaymentDetailTypeArgs, 'objects'>>;
  insert_steraOnSitePaymentDetailType_one?: Resolver<Maybe<ResolversTypes['steraOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootInsertSteraOnSitePaymentDetailTypeOneArgs, 'object'>>;
  insert_stock?: Resolver<Maybe<ResolversTypes['stock_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertStockArgs, 'objects'>>;
  insert_stock_one?: Resolver<Maybe<ResolversTypes['stock']>, ParentType, ContextType, RequireFields<MutationRootInsertStockOneArgs, 'object'>>;
  insert_table?: Resolver<Maybe<ResolversTypes['table_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertTableArgs, 'objects'>>;
  insert_tableArea?: Resolver<Maybe<ResolversTypes['tableArea_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertTableAreaArgs, 'objects'>>;
  insert_tableArea_one?: Resolver<Maybe<ResolversTypes['tableArea']>, ParentType, ContextType, RequireFields<MutationRootInsertTableAreaOneArgs, 'object'>>;
  insert_table_one?: Resolver<Maybe<ResolversTypes['table']>, ParentType, ContextType, RequireFields<MutationRootInsertTableOneArgs, 'object'>>;
  insert_tecAggregationConfig?: Resolver<Maybe<ResolversTypes['tecAggregationConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertTecAggregationConfigArgs, 'objects'>>;
  insert_tecAggregationConfig_one?: Resolver<Maybe<ResolversTypes['tecAggregationConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertTecAggregationConfigOneArgs, 'object'>>;
  insert_tecAggregationMediaMap?: Resolver<Maybe<ResolversTypes['tecAggregationMediaMap_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertTecAggregationMediaMapArgs, 'objects'>>;
  insert_tecAggregationMediaMap_one?: Resolver<Maybe<ResolversTypes['tecAggregationMediaMap']>, ParentType, ContextType, RequireFields<MutationRootInsertTecAggregationMediaMapOneArgs, 'object'>>;
  insert_tecAggregationMenu?: Resolver<Maybe<ResolversTypes['tecAggregationMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertTecAggregationMenuArgs, 'objects'>>;
  insert_tecAggregationMenu_one?: Resolver<Maybe<ResolversTypes['tecAggregationMenu']>, ParentType, ContextType, RequireFields<MutationRootInsertTecAggregationMenuOneArgs, 'object'>>;
  insert_tecAggregationOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['tecAggregationOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertTecAggregationOnSitePaymentDetailTypeArgs, 'objects'>>;
  insert_tecAggregationOnSitePaymentDetailType_one?: Resolver<Maybe<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootInsertTecAggregationOnSitePaymentDetailTypeOneArgs, 'object'>>;
  insert_winboardConfig?: Resolver<Maybe<ResolversTypes['winboardConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertWinboardConfigArgs, 'objects'>>;
  insert_winboardConfig_one?: Resolver<Maybe<ResolversTypes['winboardConfig']>, ParentType, ContextType, RequireFields<MutationRootInsertWinboardConfigOneArgs, 'object'>>;
  insert_winboardInflowSourceTag?: Resolver<Maybe<ResolversTypes['winboardInflowSourceTag_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertWinboardInflowSourceTagArgs, 'objects'>>;
  insert_winboardInflowSourceTag_one?: Resolver<Maybe<ResolversTypes['winboardInflowSourceTag']>, ParentType, ContextType, RequireFields<MutationRootInsertWinboardInflowSourceTagOneArgs, 'object'>>;
  insert_winboardMenu?: Resolver<Maybe<ResolversTypes['winboardMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootInsertWinboardMenuArgs, 'objects'>>;
  insert_winboardMenu_one?: Resolver<Maybe<ResolversTypes['winboardMenu']>, ParentType, ContextType, RequireFields<MutationRootInsertWinboardMenuOneArgs, 'object'>>;
  onlinePaymentRoot?: Resolver<Maybe<ResolversTypes['onlinePaymentRootMutation']>, ParentType, ContextType>;
  signIn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootSignInArgs, 'email' | 'password'>>;
  signInToDashboardAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  signOut?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updateLineOfficialAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootUpdateLineOfficialAccountArgs, 'input'>>;
  updateMenusBulk?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootUpdateMenusBulkArgs, 'input'>>;
  updateShopMenus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootUpdateShopMenusArgs, 'input'>>;
  updateShopMenusBulk?: Resolver<ResolversTypes['UpdateShopMenusBulkResult'], ParentType, ContextType, RequireFields<MutationRootUpdateShopMenusBulkArgs, 'input'>>;
  updateShopPlansBulk?: Resolver<ResolversTypes['UpdateShopPlansBulkResult'], ParentType, ContextType, RequireFields<MutationRootUpdateShopPlansBulkArgs, 'input'>>;
  update_accountingSlipImage?: Resolver<Maybe<ResolversTypes['accountingSlipImage_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateAccountingSlipImageArgs, 'where'>>;
  update_accountingSlipImage_by_pk?: Resolver<Maybe<ResolversTypes['accountingSlipImage']>, ParentType, ContextType, RequireFields<MutationRootUpdateAccountingSlipImageByPkArgs, 'pk_columns'>>;
  update_accountingSlipImage_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['accountingSlipImage_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateAccountingSlipImageManyArgs, 'updates'>>;
  update_analyticsSetting?: Resolver<Maybe<ResolversTypes['analyticsSetting_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateAnalyticsSettingArgs, 'where'>>;
  update_analyticsSetting_by_pk?: Resolver<Maybe<ResolversTypes['analyticsSetting']>, ParentType, ContextType, RequireFields<MutationRootUpdateAnalyticsSettingByPkArgs, 'pk_columns'>>;
  update_analyticsSetting_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['analyticsSetting_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateAnalyticsSettingManyArgs, 'updates'>>;
  update_cashRegisterConfig?: Resolver<Maybe<ResolversTypes['cashRegisterConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateCashRegisterConfigArgs, 'where'>>;
  update_cashRegisterConfig_by_pk?: Resolver<Maybe<ResolversTypes['cashRegisterConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateCashRegisterConfigByPkArgs, 'pk_columns'>>;
  update_cashRegisterConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['cashRegisterConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateCashRegisterConfigManyArgs, 'updates'>>;
  update_category?: Resolver<Maybe<ResolversTypes['category_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateCategoryArgs, 'where'>>;
  update_categoryMenu?: Resolver<Maybe<ResolversTypes['categoryMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateCategoryMenuArgs, 'where'>>;
  update_categoryMenu_by_pk?: Resolver<Maybe<ResolversTypes['categoryMenu']>, ParentType, ContextType, RequireFields<MutationRootUpdateCategoryMenuByPkArgs, 'pk_columns'>>;
  update_categoryMenu_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['categoryMenu_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateCategoryMenuManyArgs, 'updates'>>;
  update_category_by_pk?: Resolver<Maybe<ResolversTypes['category']>, ParentType, ContextType, RequireFields<MutationRootUpdateCategoryByPkArgs, 'pk_columns'>>;
  update_category_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['category_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateCategoryManyArgs, 'updates'>>;
  update_choice?: Resolver<Maybe<ResolversTypes['choice_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateChoiceArgs, 'where'>>;
  update_choice_by_pk?: Resolver<Maybe<ResolversTypes['choice']>, ParentType, ContextType, RequireFields<MutationRootUpdateChoiceByPkArgs, 'pk_columns'>>;
  update_choice_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['choice_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateChoiceManyArgs, 'updates'>>;
  update_clerk?: Resolver<Maybe<ResolversTypes['clerk_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateClerkArgs, 'where'>>;
  update_clerk_by_pk?: Resolver<Maybe<ResolversTypes['clerk']>, ParentType, ContextType, RequireFields<MutationRootUpdateClerkByPkArgs, 'pk_columns'>>;
  update_clerk_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['clerk_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateClerkManyArgs, 'updates'>>;
  update_closeCashRegister?: Resolver<Maybe<ResolversTypes['closeCashRegister_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateCloseCashRegisterArgs, 'where'>>;
  update_closeCashRegister_by_pk?: Resolver<Maybe<ResolversTypes['closeCashRegister']>, ParentType, ContextType, RequireFields<MutationRootUpdateCloseCashRegisterByPkArgs, 'pk_columns'>>;
  update_closeCashRegister_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['closeCashRegister_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateCloseCashRegisterManyArgs, 'updates'>>;
  update_coineyPayment?: Resolver<Maybe<ResolversTypes['coineyPayment_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateCoineyPaymentArgs, 'where'>>;
  update_coineyPayment_by_pk?: Resolver<Maybe<ResolversTypes['coineyPayment']>, ParentType, ContextType, RequireFields<MutationRootUpdateCoineyPaymentByPkArgs, 'pk_columns'>>;
  update_coineyPayment_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['coineyPayment_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateCoineyPaymentManyArgs, 'updates'>>;
  update_company?: Resolver<Maybe<ResolversTypes['company_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateCompanyArgs, 'where'>>;
  update_company_by_pk?: Resolver<Maybe<ResolversTypes['company']>, ParentType, ContextType, RequireFields<MutationRootUpdateCompanyByPkArgs, 'pk_columns'>>;
  update_company_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['company_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateCompanyManyArgs, 'updates'>>;
  update_compulsoryAppetizerConfig?: Resolver<Maybe<ResolversTypes['compulsoryAppetizerConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateCompulsoryAppetizerConfigArgs, 'where'>>;
  update_compulsoryAppetizerConfig_by_pk?: Resolver<Maybe<ResolversTypes['compulsoryAppetizerConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateCompulsoryAppetizerConfigByPkArgs, 'pk_columns'>>;
  update_compulsoryAppetizerConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['compulsoryAppetizerConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateCompulsoryAppetizerConfigManyArgs, 'updates'>>;
  update_connectGameConfig?: Resolver<Maybe<ResolversTypes['connectGameConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateConnectGameConfigArgs, 'where'>>;
  update_connectGameConfigShop?: Resolver<Maybe<ResolversTypes['connectGameConfigShop_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateConnectGameConfigShopArgs, 'where'>>;
  update_connectGameConfigShop_by_pk?: Resolver<Maybe<ResolversTypes['connectGameConfigShop']>, ParentType, ContextType, RequireFields<MutationRootUpdateConnectGameConfigShopByPkArgs, 'pk_columns'>>;
  update_connectGameConfigShop_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['connectGameConfigShop_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateConnectGameConfigShopManyArgs, 'updates'>>;
  update_connectGameConfig_by_pk?: Resolver<Maybe<ResolversTypes['connectGameConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateConnectGameConfigByPkArgs, 'pk_columns'>>;
  update_connectGameConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['connectGameConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateConnectGameConfigManyArgs, 'updates'>>;
  update_cookingItem?: Resolver<Maybe<ResolversTypes['cookingItem_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateCookingItemArgs, 'where'>>;
  update_cookingItem_by_pk?: Resolver<Maybe<ResolversTypes['cookingItem']>, ParentType, ContextType, RequireFields<MutationRootUpdateCookingItemByPkArgs, 'pk_columns'>>;
  update_cookingItem_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['cookingItem_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateCookingItemManyArgs, 'updates'>>;
  update_corporation?: Resolver<Maybe<ResolversTypes['corporation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateCorporationArgs, 'where'>>;
  update_corporation_by_pk?: Resolver<Maybe<ResolversTypes['corporation']>, ParentType, ContextType, RequireFields<MutationRootUpdateCorporationByPkArgs, 'pk_columns'>>;
  update_corporation_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['corporation_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateCorporationManyArgs, 'updates'>>;
  update_coupon?: Resolver<Maybe<ResolversTypes['coupon_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateCouponArgs, 'where'>>;
  update_couponMenuDiscount?: Resolver<Maybe<ResolversTypes['couponMenuDiscount_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateCouponMenuDiscountArgs, 'where'>>;
  update_couponMenuDiscount_by_pk?: Resolver<Maybe<ResolversTypes['couponMenuDiscount']>, ParentType, ContextType, RequireFields<MutationRootUpdateCouponMenuDiscountByPkArgs, 'pk_columns'>>;
  update_couponMenuDiscount_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['couponMenuDiscount_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateCouponMenuDiscountManyArgs, 'updates'>>;
  update_coupon_by_pk?: Resolver<Maybe<ResolversTypes['coupon']>, ParentType, ContextType, RequireFields<MutationRootUpdateCouponByPkArgs, 'pk_columns'>>;
  update_coupon_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['coupon_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateCouponManyArgs, 'updates'>>;
  update_dailySalesBudget?: Resolver<Maybe<ResolversTypes['dailySalesBudget_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDailySalesBudgetArgs, 'where'>>;
  update_dailySalesBudget_by_pk?: Resolver<Maybe<ResolversTypes['dailySalesBudget']>, ParentType, ContextType, RequireFields<MutationRootUpdateDailySalesBudgetByPkArgs, 'pk_columns'>>;
  update_dailySalesBudget_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['dailySalesBudget_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateDailySalesBudgetManyArgs, 'updates'>>;
  update_dashboardAccountRole?: Resolver<Maybe<ResolversTypes['dashboardAccountRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDashboardAccountRoleArgs, 'where'>>;
  update_dashboardAccountRolePermission?: Resolver<Maybe<ResolversTypes['dashboardAccountRolePermission_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDashboardAccountRolePermissionArgs, 'where'>>;
  update_dashboardAccountRolePermission_by_pk?: Resolver<Maybe<ResolversTypes['dashboardAccountRolePermission']>, ParentType, ContextType, RequireFields<MutationRootUpdateDashboardAccountRolePermissionByPkArgs, 'pk_columns'>>;
  update_dashboardAccountRolePermission_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['dashboardAccountRolePermission_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateDashboardAccountRolePermissionManyArgs, 'updates'>>;
  update_dashboardAccountRole_by_pk?: Resolver<Maybe<ResolversTypes['dashboardAccountRole']>, ParentType, ContextType, RequireFields<MutationRootUpdateDashboardAccountRoleByPkArgs, 'pk_columns'>>;
  update_dashboardAccountRole_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['dashboardAccountRole_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateDashboardAccountRoleManyArgs, 'updates'>>;
  update_dishUpSlipGroup?: Resolver<Maybe<ResolversTypes['dishUpSlipGroup_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupArgs, 'where'>>;
  update_dishUpSlipGroupRoles?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupRoles_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupRolesArgs, 'where'>>;
  update_dishUpSlipGroupRoles_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupRoles']>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupRolesByPkArgs, 'pk_columns'>>;
  update_dishUpSlipGroupRoles_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['dishUpSlipGroupRoles_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupRolesManyArgs, 'updates'>>;
  update_dishUpSlipGroupShopMenus?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopMenus_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupShopMenusArgs, 'where'>>;
  update_dishUpSlipGroupShopMenus_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopMenus']>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupShopMenusByPkArgs, 'pk_columns'>>;
  update_dishUpSlipGroupShopMenus_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['dishUpSlipGroupShopMenus_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupShopMenusManyArgs, 'updates'>>;
  update_dishUpSlipGroupShopPlans?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopPlans_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupShopPlansArgs, 'where'>>;
  update_dishUpSlipGroupShopPlans_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopPlans']>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupShopPlansByPkArgs, 'pk_columns'>>;
  update_dishUpSlipGroupShopPlans_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['dishUpSlipGroupShopPlans_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupShopPlansManyArgs, 'updates'>>;
  update_dishUpSlipGroup_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroup']>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupByPkArgs, 'pk_columns'>>;
  update_dishUpSlipGroup_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['dishUpSlipGroup_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateDishUpSlipGroupManyArgs, 'updates'>>;
  update_ebicaConfig?: Resolver<Maybe<ResolversTypes['ebicaConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateEbicaConfigArgs, 'where'>>;
  update_ebicaConfig_by_pk?: Resolver<Maybe<ResolversTypes['ebicaConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateEbicaConfigByPkArgs, 'pk_columns'>>;
  update_ebicaConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['ebicaConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateEbicaConfigManyArgs, 'updates'>>;
  update_externalOnlineMenu?: Resolver<Maybe<ResolversTypes['externalOnlineMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateExternalOnlineMenuArgs, 'where'>>;
  update_externalOnlineMenuAvailableTimeTerm?: Resolver<Maybe<ResolversTypes['externalOnlineMenuAvailableTimeTerm_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateExternalOnlineMenuAvailableTimeTermArgs, 'where'>>;
  update_externalOnlineMenuAvailableTimeTerm_by_pk?: Resolver<Maybe<ResolversTypes['externalOnlineMenuAvailableTimeTerm']>, ParentType, ContextType, RequireFields<MutationRootUpdateExternalOnlineMenuAvailableTimeTermByPkArgs, 'pk_columns'>>;
  update_externalOnlineMenuAvailableTimeTerm_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['externalOnlineMenuAvailableTimeTerm_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateExternalOnlineMenuAvailableTimeTermManyArgs, 'updates'>>;
  update_externalOnlineMenuConfig?: Resolver<Maybe<ResolversTypes['externalOnlineMenuConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateExternalOnlineMenuConfigArgs, 'where'>>;
  update_externalOnlineMenuConfig_by_pk?: Resolver<Maybe<ResolversTypes['externalOnlineMenuConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateExternalOnlineMenuConfigByPkArgs, 'pk_columns'>>;
  update_externalOnlineMenuConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['externalOnlineMenuConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateExternalOnlineMenuConfigManyArgs, 'updates'>>;
  update_externalOnlineMenu_by_pk?: Resolver<Maybe<ResolversTypes['externalOnlineMenu']>, ParentType, ContextType, RequireFields<MutationRootUpdateExternalOnlineMenuByPkArgs, 'pk_columns'>>;
  update_externalOnlineMenu_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['externalOnlineMenu_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateExternalOnlineMenuManyArgs, 'updates'>>;
  update_featureFlagShop?: Resolver<Maybe<ResolversTypes['featureFlagShop_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateFeatureFlagShopArgs, 'where'>>;
  update_featureFlagShop_by_pk?: Resolver<Maybe<ResolversTypes['featureFlagShop']>, ParentType, ContextType, RequireFields<MutationRootUpdateFeatureFlagShopByPkArgs, 'pk_columns'>>;
  update_featureFlagShop_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['featureFlagShop_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateFeatureFlagShopManyArgs, 'updates'>>;
  update_foodingJournalConfig?: Resolver<Maybe<ResolversTypes['foodingJournalConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalConfigArgs, 'where'>>;
  update_foodingJournalConfig_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalConfigByPkArgs, 'pk_columns'>>;
  update_foodingJournalConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['foodingJournalConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalConfigManyArgs, 'updates'>>;
  update_foodingJournalDepartmentMaster?: Resolver<Maybe<ResolversTypes['foodingJournalDepartmentMaster_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalDepartmentMasterArgs, 'where'>>;
  update_foodingJournalDepartmentMaster_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalDepartmentMaster']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalDepartmentMasterByPkArgs, 'pk_columns'>>;
  update_foodingJournalDepartmentMaster_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['foodingJournalDepartmentMaster_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalDepartmentMasterManyArgs, 'updates'>>;
  update_foodingJournalGroupMaster?: Resolver<Maybe<ResolversTypes['foodingJournalGroupMaster_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalGroupMasterArgs, 'where'>>;
  update_foodingJournalGroupMaster_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalGroupMaster']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalGroupMasterByPkArgs, 'pk_columns'>>;
  update_foodingJournalGroupMaster_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['foodingJournalGroupMaster_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalGroupMasterManyArgs, 'updates'>>;
  update_foodingJournalMenu?: Resolver<Maybe<ResolversTypes['foodingJournalMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalMenuArgs, 'where'>>;
  update_foodingJournalMenu_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalMenuByPkArgs, 'pk_columns'>>;
  update_foodingJournalMenu_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['foodingJournalMenu_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalMenuManyArgs, 'updates'>>;
  update_foodingJournalOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalOnSitePaymentDetailTypeArgs, 'where'>>;
  update_foodingJournalOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalOnSitePaymentDetailTypeByPkArgs, 'pk_columns'>>;
  update_foodingJournalOnSitePaymentDetailType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['foodingJournalOnSitePaymentDetailType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalOnSitePaymentDetailTypeManyArgs, 'updates'>>;
  update_foodingJournalOnSitePaymentDiscountType?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDiscountType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalOnSitePaymentDiscountTypeArgs, 'where'>>;
  update_foodingJournalOnSitePaymentDiscountType_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDiscountType']>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalOnSitePaymentDiscountTypeByPkArgs, 'pk_columns'>>;
  update_foodingJournalOnSitePaymentDiscountType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['foodingJournalOnSitePaymentDiscountType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateFoodingJournalOnSitePaymentDiscountTypeManyArgs, 'updates'>>;
  update_giftMeta?: Resolver<Maybe<ResolversTypes['giftMeta_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateGiftMetaArgs, 'where'>>;
  update_giftMeta_by_pk?: Resolver<Maybe<ResolversTypes['giftMeta']>, ParentType, ContextType, RequireFields<MutationRootUpdateGiftMetaByPkArgs, 'pk_columns'>>;
  update_giftMeta_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['giftMeta_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateGiftMetaManyArgs, 'updates'>>;
  update_gmoOffPaymentShopConfig?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateGmoOffPaymentShopConfigArgs, 'where'>>;
  update_gmoOffPaymentShopConfigTransactionFeeRate?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateGmoOffPaymentShopConfigTransactionFeeRateArgs, 'where'>>;
  update_gmoOffPaymentShopConfigTransactionFeeRate_by_pk?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate']>, ParentType, ContextType, RequireFields<MutationRootUpdateGmoOffPaymentShopConfigTransactionFeeRateByPkArgs, 'pk_columns'>>;
  update_gmoOffPaymentShopConfigTransactionFeeRate_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateGmoOffPaymentShopConfigTransactionFeeRateManyArgs, 'updates'>>;
  update_gmoOffPaymentShopConfig_by_pk?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateGmoOffPaymentShopConfigByPkArgs, 'pk_columns'>>;
  update_gmoOffPaymentShopConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['gmoOffPaymentShopConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateGmoOffPaymentShopConfigManyArgs, 'updates'>>;
  update_inflowSourceTag?: Resolver<Maybe<ResolversTypes['inflowSourceTag_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateInflowSourceTagArgs, 'where'>>;
  update_inflowSourceTag_by_pk?: Resolver<Maybe<ResolversTypes['inflowSourceTag']>, ParentType, ContextType, RequireFields<MutationRootUpdateInflowSourceTagByPkArgs, 'pk_columns'>>;
  update_inflowSourceTag_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['inflowSourceTag_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateInflowSourceTagManyArgs, 'updates'>>;
  update_kdDisplayTarget?: Resolver<Maybe<ResolversTypes['kdDisplayTarget_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateKdDisplayTargetArgs, 'where'>>;
  update_kdDisplayTarget_by_pk?: Resolver<Maybe<ResolversTypes['kdDisplayTarget']>, ParentType, ContextType, RequireFields<MutationRootUpdateKdDisplayTargetByPkArgs, 'pk_columns'>>;
  update_kdDisplayTarget_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['kdDisplayTarget_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateKdDisplayTargetManyArgs, 'updates'>>;
  update_lineChannelConfig?: Resolver<Maybe<ResolversTypes['lineChannelConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateLineChannelConfigArgs, 'where'>>;
  update_lineChannelConfig_by_pk?: Resolver<Maybe<ResolversTypes['lineChannelConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateLineChannelConfigByPkArgs, 'pk_columns'>>;
  update_lineChannelConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['lineChannelConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateLineChannelConfigManyArgs, 'updates'>>;
  update_lineReportingBotConfig?: Resolver<Maybe<ResolversTypes['lineReportingBotConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateLineReportingBotConfigArgs, 'where'>>;
  update_lineReportingBotConfig_by_pk?: Resolver<Maybe<ResolversTypes['lineReportingBotConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateLineReportingBotConfigByPkArgs, 'pk_columns'>>;
  update_lineReportingBotConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['lineReportingBotConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateLineReportingBotConfigManyArgs, 'updates'>>;
  update_membershipCard?: Resolver<Maybe<ResolversTypes['membershipCard_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateMembershipCardArgs, 'where'>>;
  update_membershipCardTitle?: Resolver<Maybe<ResolversTypes['membershipCardTitle_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateMembershipCardTitleArgs, 'where'>>;
  update_membershipCardTitle_by_pk?: Resolver<Maybe<ResolversTypes['membershipCardTitle']>, ParentType, ContextType, RequireFields<MutationRootUpdateMembershipCardTitleByPkArgs, 'pk_columns'>>;
  update_membershipCardTitle_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['membershipCardTitle_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateMembershipCardTitleManyArgs, 'updates'>>;
  update_membershipCard_by_pk?: Resolver<Maybe<ResolversTypes['membershipCard']>, ParentType, ContextType, RequireFields<MutationRootUpdateMembershipCardByPkArgs, 'pk_columns'>>;
  update_membershipCard_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['membershipCard_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateMembershipCardManyArgs, 'updates'>>;
  update_membershipRankConfig?: Resolver<Maybe<ResolversTypes['membershipRankConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateMembershipRankConfigArgs, 'where'>>;
  update_membershipRankConfig_by_pk?: Resolver<Maybe<ResolversTypes['membershipRankConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateMembershipRankConfigByPkArgs, 'pk_columns'>>;
  update_membershipRankConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['membershipRankConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateMembershipRankConfigManyArgs, 'updates'>>;
  update_menu?: Resolver<Maybe<ResolversTypes['menu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateMenuArgs, 'where'>>;
  update_menuOption?: Resolver<Maybe<ResolversTypes['menuOption_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateMenuOptionArgs, 'where'>>;
  update_menuOption_by_pk?: Resolver<Maybe<ResolversTypes['menuOption']>, ParentType, ContextType, RequireFields<MutationRootUpdateMenuOptionByPkArgs, 'pk_columns'>>;
  update_menuOption_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['menuOption_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateMenuOptionManyArgs, 'updates'>>;
  update_menuRecommendationMeta?: Resolver<Maybe<ResolversTypes['menuRecommendationMeta_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateMenuRecommendationMetaArgs, 'where'>>;
  update_menuRecommendationMeta_by_pk?: Resolver<Maybe<ResolversTypes['menuRecommendationMeta']>, ParentType, ContextType, RequireFields<MutationRootUpdateMenuRecommendationMetaByPkArgs, 'pk_columns'>>;
  update_menuRecommendationMeta_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['menuRecommendationMeta_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateMenuRecommendationMetaManyArgs, 'updates'>>;
  update_menu_by_pk?: Resolver<Maybe<ResolversTypes['menu']>, ParentType, ContextType, RequireFields<MutationRootUpdateMenuByPkArgs, 'pk_columns'>>;
  update_menu_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['menu_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateMenuManyArgs, 'updates'>>;
  update_messageDelivery?: Resolver<Maybe<ResolversTypes['messageDelivery_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateMessageDeliveryArgs, 'where'>>;
  update_messageDelivery_by_pk?: Resolver<Maybe<ResolversTypes['messageDelivery']>, ParentType, ContextType, RequireFields<MutationRootUpdateMessageDeliveryByPkArgs, 'pk_columns'>>;
  update_messageDelivery_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['messageDelivery_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateMessageDeliveryManyArgs, 'updates'>>;
  update_monthlySalesBudget?: Resolver<Maybe<ResolversTypes['monthlySalesBudget_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateMonthlySalesBudgetArgs, 'where'>>;
  update_monthlySalesBudget_by_pk?: Resolver<Maybe<ResolversTypes['monthlySalesBudget']>, ParentType, ContextType, RequireFields<MutationRootUpdateMonthlySalesBudgetByPkArgs, 'pk_columns'>>;
  update_monthlySalesBudget_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['monthlySalesBudget_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateMonthlySalesBudgetManyArgs, 'updates'>>;
  update_onSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateOnSitePaymentDetailTypeArgs, 'where'>>;
  update_onSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootUpdateOnSitePaymentDetailTypeByPkArgs, 'pk_columns'>>;
  update_onSitePaymentDetailType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['onSitePaymentDetailType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateOnSitePaymentDetailTypeManyArgs, 'updates'>>;
  update_onSitePaymentDiscountType?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateOnSitePaymentDiscountTypeArgs, 'where'>>;
  update_onSitePaymentDiscountType_by_pk?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType']>, ParentType, ContextType, RequireFields<MutationRootUpdateOnSitePaymentDiscountTypeByPkArgs, 'pk_columns'>>;
  update_onSitePaymentDiscountType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['onSitePaymentDiscountType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateOnSitePaymentDiscountTypeManyArgs, 'updates'>>;
  update_option?: Resolver<Maybe<ResolversTypes['option_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateOptionArgs, 'where'>>;
  update_optionInputType?: Resolver<Maybe<ResolversTypes['optionInputType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateOptionInputTypeArgs, 'where'>>;
  update_optionInputType_by_pk?: Resolver<Maybe<ResolversTypes['optionInputType']>, ParentType, ContextType, RequireFields<MutationRootUpdateOptionInputTypeByPkArgs, 'pk_columns'>>;
  update_optionInputType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['optionInputType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateOptionInputTypeManyArgs, 'updates'>>;
  update_option_by_pk?: Resolver<Maybe<ResolversTypes['option']>, ParentType, ContextType, RequireFields<MutationRootUpdateOptionByPkArgs, 'pk_columns'>>;
  update_option_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['option_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateOptionManyArgs, 'updates'>>;
  update_orderableTime?: Resolver<Maybe<ResolversTypes['orderableTime_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateOrderableTimeArgs, 'where'>>;
  update_orderableTime_by_pk?: Resolver<Maybe<ResolversTypes['orderableTime']>, ParentType, ContextType, RequireFields<MutationRootUpdateOrderableTimeByPkArgs, 'pk_columns'>>;
  update_orderableTime_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['orderableTime_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateOrderableTimeManyArgs, 'updates'>>;
  update_paymentReceiptRoleTable?: Resolver<Maybe<ResolversTypes['paymentReceiptRoleTable_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePaymentReceiptRoleTableArgs, 'where'>>;
  update_paymentReceiptRoleTable_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['paymentReceiptRoleTable_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePaymentReceiptRoleTableManyArgs, 'updates'>>;
  update_pikaichiConfig?: Resolver<Maybe<ResolversTypes['pikaichiConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiConfigArgs, 'where'>>;
  update_pikaichiConfig_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiConfigByPkArgs, 'pk_columns'>>;
  update_pikaichiConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['pikaichiConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiConfigManyArgs, 'updates'>>;
  update_pikaichiMenu?: Resolver<Maybe<ResolversTypes['pikaichiMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuArgs, 'where'>>;
  update_pikaichiMenuChoice?: Resolver<Maybe<ResolversTypes['pikaichiMenuChoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuChoiceArgs, 'where'>>;
  update_pikaichiMenuChoice_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuChoice']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuChoiceByPkArgs, 'pk_columns'>>;
  update_pikaichiMenuChoice_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['pikaichiMenuChoice_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuChoiceManyArgs, 'updates'>>;
  update_pikaichiMenuMenu?: Resolver<Maybe<ResolversTypes['pikaichiMenuMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuMenuArgs, 'where'>>;
  update_pikaichiMenuMenu_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuMenu']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuMenuByPkArgs, 'pk_columns'>>;
  update_pikaichiMenuMenu_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['pikaichiMenuMenu_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuMenuManyArgs, 'updates'>>;
  update_pikaichiMenuPlan?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlan_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuPlanArgs, 'where'>>;
  update_pikaichiMenuPlanChoice?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlanChoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuPlanChoiceArgs, 'where'>>;
  update_pikaichiMenuPlanChoice_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlanChoice']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuPlanChoiceByPkArgs, 'pk_columns'>>;
  update_pikaichiMenuPlanChoice_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['pikaichiMenuPlanChoice_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuPlanChoiceManyArgs, 'updates'>>;
  update_pikaichiMenuPlan_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlan']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuPlanByPkArgs, 'pk_columns'>>;
  update_pikaichiMenuPlan_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['pikaichiMenuPlan_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuPlanManyArgs, 'updates'>>;
  update_pikaichiMenu_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenu']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuByPkArgs, 'pk_columns'>>;
  update_pikaichiMenu_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['pikaichiMenu_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiMenuManyArgs, 'updates'>>;
  update_pikaichiOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiOnSitePaymentDetailTypeArgs, 'where'>>;
  update_pikaichiOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiOnSitePaymentDetailTypeByPkArgs, 'pk_columns'>>;
  update_pikaichiOnSitePaymentDetailType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['pikaichiOnSitePaymentDetailType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiOnSitePaymentDetailTypeManyArgs, 'updates'>>;
  update_pikaichiOnSitePaymentDiscountType?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDiscountType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiOnSitePaymentDiscountTypeArgs, 'where'>>;
  update_pikaichiOnSitePaymentDiscountType_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDiscountType']>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiOnSitePaymentDiscountTypeByPkArgs, 'pk_columns'>>;
  update_pikaichiOnSitePaymentDiscountType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['pikaichiOnSitePaymentDiscountType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePikaichiOnSitePaymentDiscountTypeManyArgs, 'updates'>>;
  update_plan?: Resolver<Maybe<ResolversTypes['plan_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanArgs, 'where'>>;
  update_planChoice?: Resolver<Maybe<ResolversTypes['planChoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanChoiceArgs, 'where'>>;
  update_planChoice_by_pk?: Resolver<Maybe<ResolversTypes['planChoice']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanChoiceByPkArgs, 'pk_columns'>>;
  update_planChoice_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['planChoice_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanChoiceManyArgs, 'updates'>>;
  update_planFirstOrderCategoryMenu?: Resolver<Maybe<ResolversTypes['planFirstOrderCategoryMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanFirstOrderCategoryMenuArgs, 'where'>>;
  update_planFirstOrderCategoryMenu_by_pk?: Resolver<Maybe<ResolversTypes['planFirstOrderCategoryMenu']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanFirstOrderCategoryMenuByPkArgs, 'pk_columns'>>;
  update_planFirstOrderCategoryMenu_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['planFirstOrderCategoryMenu_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanFirstOrderCategoryMenuManyArgs, 'updates'>>;
  update_planGroup?: Resolver<Maybe<ResolversTypes['planGroup_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanGroupArgs, 'where'>>;
  update_planGroup_by_pk?: Resolver<Maybe<ResolversTypes['planGroup']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanGroupByPkArgs, 'pk_columns'>>;
  update_planGroup_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['planGroup_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanGroupManyArgs, 'updates'>>;
  update_planMenuCategory?: Resolver<Maybe<ResolversTypes['planMenuCategory_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanMenuCategoryArgs, 'where'>>;
  update_planMenuCategory_by_pk?: Resolver<Maybe<ResolversTypes['planMenuCategory']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanMenuCategoryByPkArgs, 'pk_columns'>>;
  update_planMenuCategory_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['planMenuCategory_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanMenuCategoryManyArgs, 'updates'>>;
  update_planOption?: Resolver<Maybe<ResolversTypes['planOption_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanOptionArgs, 'where'>>;
  update_planOption_by_pk?: Resolver<Maybe<ResolversTypes['planOption']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanOptionByPkArgs, 'pk_columns'>>;
  update_planOption_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['planOption_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanOptionManyArgs, 'updates'>>;
  update_plan_by_pk?: Resolver<Maybe<ResolversTypes['plan']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanByPkArgs, 'pk_columns'>>;
  update_plan_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['plan_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanManyArgs, 'updates'>>;
  update_plan_menu_categories_category?: Resolver<Maybe<ResolversTypes['plan_menu_categories_category_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanMenuCategoriesCategoryArgs, 'where'>>;
  update_plan_menu_categories_category_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['plan_menu_categories_category_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdatePlanMenuCategoriesCategoryManyArgs, 'updates'>>;
  update_questionnaire?: Resolver<Maybe<ResolversTypes['questionnaire_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateQuestionnaireArgs, 'where'>>;
  update_questionnaireConfig?: Resolver<Maybe<ResolversTypes['questionnaireConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateQuestionnaireConfigArgs, 'where'>>;
  update_questionnaireConfig_by_pk?: Resolver<Maybe<ResolversTypes['questionnaireConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateQuestionnaireConfigByPkArgs, 'pk_columns'>>;
  update_questionnaireConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['questionnaireConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateQuestionnaireConfigManyArgs, 'updates'>>;
  update_questionnaire_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire']>, ParentType, ContextType, RequireFields<MutationRootUpdateQuestionnaireByPkArgs, 'pk_columns'>>;
  update_questionnaire_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['questionnaire_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateQuestionnaireManyArgs, 'updates'>>;
  update_role?: Resolver<Maybe<ResolversTypes['role_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateRoleArgs, 'where'>>;
  update_roleTablesTable?: Resolver<Maybe<ResolversTypes['roleTablesTable_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateRoleTablesTableArgs, 'where'>>;
  update_roleTablesTable_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['roleTablesTable_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateRoleTablesTableManyArgs, 'updates'>>;
  update_role_by_pk?: Resolver<Maybe<ResolversTypes['role']>, ParentType, ContextType, RequireFields<MutationRootUpdateRoleByPkArgs, 'pk_columns'>>;
  update_role_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['role_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateRoleManyArgs, 'updates'>>;
  update_salesBudgetAllocationSetting?: Resolver<Maybe<ResolversTypes['salesBudgetAllocationSetting_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateSalesBudgetAllocationSettingArgs, 'where'>>;
  update_salesBudgetAllocationSetting_by_pk?: Resolver<Maybe<ResolversTypes['salesBudgetAllocationSetting']>, ParentType, ContextType, RequireFields<MutationRootUpdateSalesBudgetAllocationSettingByPkArgs, 'pk_columns'>>;
  update_salesBudgetAllocationSetting_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['salesBudgetAllocationSetting_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateSalesBudgetAllocationSettingManyArgs, 'updates'>>;
  update_serviceChargeConfig?: Resolver<Maybe<ResolversTypes['serviceChargeConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateServiceChargeConfigArgs, 'where'>>;
  update_serviceChargeConfig_by_pk?: Resolver<Maybe<ResolversTypes['serviceChargeConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateServiceChargeConfigByPkArgs, 'pk_columns'>>;
  update_serviceChargeConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['serviceChargeConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateServiceChargeConfigManyArgs, 'updates'>>;
  update_shop?: Resolver<Maybe<ResolversTypes['shop_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopArgs, 'where'>>;
  update_shopBusinessOperationHour?: Resolver<Maybe<ResolversTypes['shopBusinessOperationHour_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopBusinessOperationHourArgs, 'where'>>;
  update_shopBusinessOperationHour_by_pk?: Resolver<Maybe<ResolversTypes['shopBusinessOperationHour']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopBusinessOperationHourByPkArgs, 'pk_columns'>>;
  update_shopBusinessOperationHour_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopBusinessOperationHour_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopBusinessOperationHourManyArgs, 'updates'>>;
  update_shopClerk?: Resolver<Maybe<ResolversTypes['shopClerk_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopClerkArgs, 'where'>>;
  update_shopClerk_by_pk?: Resolver<Maybe<ResolversTypes['shopClerk']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopClerkByPkArgs, 'pk_columns'>>;
  update_shopClerk_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopClerk_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopClerkManyArgs, 'updates'>>;
  update_shopClient?: Resolver<Maybe<ResolversTypes['shopClient_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopClientArgs, 'where'>>;
  update_shopClient_by_pk?: Resolver<Maybe<ResolversTypes['shopClient']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopClientByPkArgs, 'pk_columns'>>;
  update_shopClient_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopClient_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopClientManyArgs, 'updates'>>;
  update_shopCookingItemRole?: Resolver<Maybe<ResolversTypes['shopCookingItemRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopCookingItemRoleArgs, 'where'>>;
  update_shopCookingItemRole_by_pk?: Resolver<Maybe<ResolversTypes['shopCookingItemRole']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopCookingItemRoleByPkArgs, 'pk_columns'>>;
  update_shopCookingItemRole_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopCookingItemRole_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopCookingItemRoleManyArgs, 'updates'>>;
  update_shopDiscountType?: Resolver<Maybe<ResolversTypes['shopDiscountType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopDiscountTypeArgs, 'where'>>;
  update_shopDiscountType_by_pk?: Resolver<Maybe<ResolversTypes['shopDiscountType']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopDiscountTypeByPkArgs, 'pk_columns'>>;
  update_shopDiscountType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopDiscountType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopDiscountTypeManyArgs, 'updates'>>;
  update_shopInflowSourceTag?: Resolver<Maybe<ResolversTypes['shopInflowSourceTag_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopInflowSourceTagArgs, 'where'>>;
  update_shopInflowSourceTag_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopInflowSourceTag_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopInflowSourceTagManyArgs, 'updates'>>;
  update_shopMenu?: Resolver<Maybe<ResolversTypes['shopMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopMenuArgs, 'where'>>;
  update_shopMenuCookingItem?: Resolver<Maybe<ResolversTypes['shopMenuCookingItem_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopMenuCookingItemArgs, 'where'>>;
  update_shopMenuCookingItem_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopMenuCookingItem_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopMenuCookingItemManyArgs, 'updates'>>;
  update_shopMenuKdDisplayTarget?: Resolver<Maybe<ResolversTypes['shopMenuKdDisplayTarget_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopMenuKdDisplayTargetArgs, 'where'>>;
  update_shopMenuKdDisplayTarget_by_pk?: Resolver<Maybe<ResolversTypes['shopMenuKdDisplayTarget']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopMenuKdDisplayTargetByPkArgs, 'pk_columns'>>;
  update_shopMenuKdDisplayTarget_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopMenuKdDisplayTarget_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopMenuKdDisplayTargetManyArgs, 'updates'>>;
  update_shopMenuKitchenRole?: Resolver<Maybe<ResolversTypes['shopMenuKitchenRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopMenuKitchenRoleArgs, 'where'>>;
  update_shopMenuKitchenRole_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopMenuKitchenRole_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopMenuKitchenRoleManyArgs, 'updates'>>;
  update_shopMenu_by_pk?: Resolver<Maybe<ResolversTypes['shopMenu']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopMenuByPkArgs, 'pk_columns'>>;
  update_shopMenu_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopMenu_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopMenuManyArgs, 'updates'>>;
  update_shopOrderableTimeTerm?: Resolver<Maybe<ResolversTypes['shopOrderableTimeTerm_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopOrderableTimeTermArgs, 'where'>>;
  update_shopOrderableTimeTerm_by_pk?: Resolver<Maybe<ResolversTypes['shopOrderableTimeTerm']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopOrderableTimeTermByPkArgs, 'pk_columns'>>;
  update_shopOrderableTimeTerm_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopOrderableTimeTerm_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopOrderableTimeTermManyArgs, 'updates'>>;
  update_shopPaymentType?: Resolver<Maybe<ResolversTypes['shopPaymentType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPaymentTypeArgs, 'where'>>;
  update_shopPaymentType_by_pk?: Resolver<Maybe<ResolversTypes['shopPaymentType']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPaymentTypeByPkArgs, 'pk_columns'>>;
  update_shopPaymentType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopPaymentType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPaymentTypeManyArgs, 'updates'>>;
  update_shopPlan?: Resolver<Maybe<ResolversTypes['shopPlan_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanArgs, 'where'>>;
  update_shopPlanChoice?: Resolver<Maybe<ResolversTypes['shopPlanChoice_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanChoiceArgs, 'where'>>;
  update_shopPlanChoice_by_pk?: Resolver<Maybe<ResolversTypes['shopPlanChoice']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanChoiceByPkArgs, 'pk_columns'>>;
  update_shopPlanChoice_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopPlanChoice_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanChoiceManyArgs, 'updates'>>;
  update_shopPlanKitchenRole?: Resolver<Maybe<ResolversTypes['shopPlanKitchenRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanKitchenRoleArgs, 'where'>>;
  update_shopPlanKitchenRole_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopPlanKitchenRole_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanKitchenRoleManyArgs, 'updates'>>;
  update_shopPlanOption?: Resolver<Maybe<ResolversTypes['shopPlanOption_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanOptionArgs, 'where'>>;
  update_shopPlanOptionKitchenRole?: Resolver<Maybe<ResolversTypes['shopPlanOptionKitchenRole_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanOptionKitchenRoleArgs, 'where'>>;
  update_shopPlanOptionKitchenRole_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopPlanOptionKitchenRole_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanOptionKitchenRoleManyArgs, 'updates'>>;
  update_shopPlanOption_by_pk?: Resolver<Maybe<ResolversTypes['shopPlanOption']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanOptionByPkArgs, 'pk_columns'>>;
  update_shopPlanOption_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopPlanOption_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanOptionManyArgs, 'updates'>>;
  update_shopPlan_by_pk?: Resolver<Maybe<ResolversTypes['shopPlan']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanByPkArgs, 'pk_columns'>>;
  update_shopPlan_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shopPlan_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopPlanManyArgs, 'updates'>>;
  update_shop_by_pk?: Resolver<Maybe<ResolversTypes['shop']>, ParentType, ContextType, RequireFields<MutationRootUpdateShopByPkArgs, 'pk_columns'>>;
  update_shop_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['shop_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateShopManyArgs, 'updates'>>;
  update_specificTimeSurchargeConfig?: Resolver<Maybe<ResolversTypes['specificTimeSurchargeConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateSpecificTimeSurchargeConfigArgs, 'where'>>;
  update_specificTimeSurchargeConfig_by_pk?: Resolver<Maybe<ResolversTypes['specificTimeSurchargeConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateSpecificTimeSurchargeConfigByPkArgs, 'pk_columns'>>;
  update_specificTimeSurchargeConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['specificTimeSurchargeConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateSpecificTimeSurchargeConfigManyArgs, 'updates'>>;
  update_steraOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['steraOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateSteraOnSitePaymentDetailTypeArgs, 'where'>>;
  update_steraOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['steraOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootUpdateSteraOnSitePaymentDetailTypeByPkArgs, 'pk_columns'>>;
  update_steraOnSitePaymentDetailType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['steraOnSitePaymentDetailType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateSteraOnSitePaymentDetailTypeManyArgs, 'updates'>>;
  update_stock?: Resolver<Maybe<ResolversTypes['stock_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateStockArgs, 'where'>>;
  update_stock_by_pk?: Resolver<Maybe<ResolversTypes['stock']>, ParentType, ContextType, RequireFields<MutationRootUpdateStockByPkArgs, 'pk_columns'>>;
  update_stock_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['stock_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateStockManyArgs, 'updates'>>;
  update_table?: Resolver<Maybe<ResolversTypes['table_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateTableArgs, 'where'>>;
  update_tableArea?: Resolver<Maybe<ResolversTypes['tableArea_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateTableAreaArgs, 'where'>>;
  update_tableArea_by_pk?: Resolver<Maybe<ResolversTypes['tableArea']>, ParentType, ContextType, RequireFields<MutationRootUpdateTableAreaByPkArgs, 'pk_columns'>>;
  update_tableArea_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['tableArea_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateTableAreaManyArgs, 'updates'>>;
  update_table_by_pk?: Resolver<Maybe<ResolversTypes['table']>, ParentType, ContextType, RequireFields<MutationRootUpdateTableByPkArgs, 'pk_columns'>>;
  update_table_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['table_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateTableManyArgs, 'updates'>>;
  update_tecAggregationConfig?: Resolver<Maybe<ResolversTypes['tecAggregationConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationConfigArgs, 'where'>>;
  update_tecAggregationConfig_by_pk?: Resolver<Maybe<ResolversTypes['tecAggregationConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationConfigByPkArgs, 'pk_columns'>>;
  update_tecAggregationConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['tecAggregationConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationConfigManyArgs, 'updates'>>;
  update_tecAggregationMediaMap?: Resolver<Maybe<ResolversTypes['tecAggregationMediaMap_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationMediaMapArgs, 'where'>>;
  update_tecAggregationMediaMap_by_pk?: Resolver<Maybe<ResolversTypes['tecAggregationMediaMap']>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationMediaMapByPkArgs, 'pk_columns'>>;
  update_tecAggregationMediaMap_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['tecAggregationMediaMap_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationMediaMapManyArgs, 'updates'>>;
  update_tecAggregationMenu?: Resolver<Maybe<ResolversTypes['tecAggregationMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationMenuArgs, 'where'>>;
  update_tecAggregationMenu_by_pk?: Resolver<Maybe<ResolversTypes['tecAggregationMenu']>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationMenuByPkArgs, 'pk_columns'>>;
  update_tecAggregationMenu_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['tecAggregationMenu_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationMenuManyArgs, 'updates'>>;
  update_tecAggregationOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['tecAggregationOnSitePaymentDetailType_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationOnSitePaymentDetailTypeArgs, 'where'>>;
  update_tecAggregationOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationOnSitePaymentDetailTypeByPkArgs, 'pk_columns'>>;
  update_tecAggregationOnSitePaymentDetailType_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['tecAggregationOnSitePaymentDetailType_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateTecAggregationOnSitePaymentDetailTypeManyArgs, 'updates'>>;
  update_translation?: Resolver<Maybe<ResolversTypes['translation_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateTranslationArgs, 'where'>>;
  update_translation_by_pk?: Resolver<Maybe<ResolversTypes['translation']>, ParentType, ContextType, RequireFields<MutationRootUpdateTranslationByPkArgs, 'pk_columns'>>;
  update_translation_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['translation_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateTranslationManyArgs, 'updates'>>;
  update_winboardConfig?: Resolver<Maybe<ResolversTypes['winboardConfig_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateWinboardConfigArgs, 'where'>>;
  update_winboardConfig_by_pk?: Resolver<Maybe<ResolversTypes['winboardConfig']>, ParentType, ContextType, RequireFields<MutationRootUpdateWinboardConfigByPkArgs, 'pk_columns'>>;
  update_winboardConfig_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['winboardConfig_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateWinboardConfigManyArgs, 'updates'>>;
  update_winboardInflowSourceTag?: Resolver<Maybe<ResolversTypes['winboardInflowSourceTag_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateWinboardInflowSourceTagArgs, 'where'>>;
  update_winboardInflowSourceTag_by_pk?: Resolver<Maybe<ResolversTypes['winboardInflowSourceTag']>, ParentType, ContextType, RequireFields<MutationRootUpdateWinboardInflowSourceTagByPkArgs, 'pk_columns'>>;
  update_winboardInflowSourceTag_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['winboardInflowSourceTag_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateWinboardInflowSourceTagManyArgs, 'updates'>>;
  update_winboardMenu?: Resolver<Maybe<ResolversTypes['winboardMenu_mutation_response']>, ParentType, ContextType, RequireFields<MutationRootUpdateWinboardMenuArgs, 'where'>>;
  update_winboardMenu_by_pk?: Resolver<Maybe<ResolversTypes['winboardMenu']>, ParentType, ContextType, RequireFields<MutationRootUpdateWinboardMenuByPkArgs, 'pk_columns'>>;
  update_winboardMenu_many?: Resolver<Maybe<Array<Maybe<ResolversTypes['winboardMenu_mutation_response']>>>, ParentType, ContextType, RequireFields<MutationRootUpdateWinboardMenuManyArgs, 'updates'>>;
  upsertShopChoices?: Resolver<ResolversTypes['UpsertShopChoicesResult'], ParentType, ContextType, RequireFields<MutationRootUpsertShopChoicesArgs, 'input'>>;
  upsertShopPlanChoice?: Resolver<ResolversTypes['UpsertShopPlanChoiceResult'], ParentType, ContextType, RequireFields<MutationRootUpsertShopPlanChoiceArgs, 'input'>>;
  upsertTecAggregationMenu?: Resolver<ResolversTypes['DashboardAccountCreateTecAggregationMenuOutput'], ParentType, ContextType, RequireFields<MutationRootUpsertTecAggregationMenuArgs, 'input'>>;
  upsertTecAggregationMenusBulk?: Resolver<ResolversTypes['DashboardAccountUpsertTecAggregationMenusBulkOutput'], ParentType, ContextType, RequireFields<MutationRootUpsertTecAggregationMenusBulkArgs, 'input'>>;
  upsertTranslationsForCategory?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootUpsertTranslationsForCategoryArgs, 'input'>>;
  upsertTranslationsForMenu?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootUpsertTranslationsForMenuArgs, 'input'>>;
  upsertTranslationsForMenuRecommendationMeta?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootUpsertTranslationsForMenuRecommendationMetaArgs, 'input'>>;
  upsertTranslationsForOption?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootUpsertTranslationsForOptionArgs, 'input'>>;
  upsertTranslationsForPlan?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootUpsertTranslationsForPlanArgs, 'input'>>;
  upsertTranslationsForPlanChoice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootUpsertTranslationsForPlanChoiceArgs, 'input'>>;
  upsertTranslationsForPlanOption?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRootUpsertTranslationsForPlanOptionArgs, 'input'>>;
};

export interface NumericScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['numeric'], any> {
  name: 'numeric';
}

export type OnSitePaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePayment'] = ResolversParentTypes['onSitePayment']> = {
  closeCashRegister?: Resolver<Maybe<ResolversTypes['closeCashRegister']>, ParentType, ContextType>;
  closeCashRegisterId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  localAccountingSlipId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  onSitePaymentDetails?: Resolver<Array<ResolversTypes['onSitePaymentDetail']>, ParentType, ContextType, Partial<OnSitePaymentOnSitePaymentDetailsArgs>>;
  onSitePaymentDiscounts?: Resolver<Array<ResolversTypes['onSitePaymentDiscount']>, ParentType, ContextType, Partial<OnSitePaymentOnSitePaymentDiscountsArgs>>;
  onSitePaymentId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  onSitePaymentSaleses?: Resolver<Array<ResolversTypes['onSitePaymentSales']>, ParentType, ContextType, Partial<OnSitePaymentOnSitePaymentSalesesArgs>>;
  onSitePaymentTableUsers?: Resolver<Array<ResolversTypes['onSitePaymentTableUsers']>, ParentType, ContextType, Partial<OnSitePaymentOnSitePaymentTableUsersArgs>>;
  payingActivePlanChoices?: Resolver<Array<ResolversTypes['payingActivePlanChoice']>, ParentType, ContextType, Partial<OnSitePaymentPayingActivePlanChoicesArgs>>;
  payingActivePlanOpenPriceMetas?: Resolver<Array<ResolversTypes['payingActivePlanOpenPriceMeta']>, ParentType, ContextType, Partial<OnSitePaymentPayingActivePlanOpenPriceMetasArgs>>;
  payingMenuOrderItems?: Resolver<Array<ResolversTypes['payingMenuOrderItem']>, ParentType, ContextType, Partial<OnSitePaymentPayingMenuOrderItemsArgs>>;
  receipts?: Resolver<Array<ResolversTypes['receipt']>, ParentType, ContextType, Partial<OnSitePaymentReceiptsArgs>>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  voidedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  voidedByClerk?: Resolver<Maybe<ResolversTypes['clerk']>, ParentType, ContextType>;
  voidedByClerkId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetail'] = ResolversParentTypes['onSitePaymentDetail']> = {
  _clerkId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _onSitePaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  changePrice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  clerk?: Resolver<Maybe<ResolversTypes['clerk']>, ParentType, ContextType>;
  clerkId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  netPrice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  onSitePayment?: Resolver<Maybe<ResolversTypes['onSitePayment']>, ParentType, ContextType>;
  onSitePaymentDetailType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype?: Resolver<ResolversTypes['onSitePaymentDetailType'], ParentType, ContextType>;
  onSitePaymentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  receivedPrice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType'] = ResolversParentTypes['onSitePaymentDetailType']> = {
  allowChange?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  foodingJournalOnSitePaymentDetailType?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDetailType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDetailTypeCategory?: Resolver<ResolversTypes['onSitePaymentDetailTypeCategory_enum'], ParentType, ContextType>;
  onSitePaymentDetails?: Resolver<Array<ResolversTypes['onSitePaymentDetail']>, ParentType, ContextType, Partial<OnSitePaymentDetailTypeOnSitePaymentDetailsArgs>>;
  pikaichiOnSitePaymentDetailTypes?: Resolver<Array<ResolversTypes['pikaichiOnSitePaymentDetailType']>, ParentType, ContextType, Partial<OnSitePaymentDetailTypePikaichiOnSitePaymentDetailTypesArgs>>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopPaymentTypes?: Resolver<Array<ResolversTypes['shopPaymentType']>, ParentType, ContextType, Partial<OnSitePaymentDetailTypeShopPaymentTypesArgs>>;
  tecAggregationOnSitePaymentDetailTypes?: Resolver<Array<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, ParentType, ContextType, Partial<OnSitePaymentDetailTypeTecAggregationOnSitePaymentDetailTypesArgs>>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeCategoryPriorityResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailTypeCategoryPriority'] = ResolversParentTypes['onSitePaymentDetailTypeCategoryPriority']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['onSitePaymentDetailTypeCategory_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_aggregate'] = ResolversParentTypes['onSitePaymentDetailType_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['onSitePaymentDetailType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_aggregate_fields'] = ResolversParentTypes['onSitePaymentDetailType_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<OnSitePaymentDetailTypeAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_avg_fields'] = ResolversParentTypes['onSitePaymentDetailType_avg_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_max_fields'] = ResolversParentTypes['onSitePaymentDetailType_max_fields']> = {
  companyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_min_fields'] = ResolversParentTypes['onSitePaymentDetailType_min_fields']> = {
  companyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_mutation_response'] = ResolversParentTypes['onSitePaymentDetailType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['onSitePaymentDetailType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_stddev_fields'] = ResolversParentTypes['onSitePaymentDetailType_stddev_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_stddev_pop_fields'] = ResolversParentTypes['onSitePaymentDetailType_stddev_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_stddev_samp_fields'] = ResolversParentTypes['onSitePaymentDetailType_stddev_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_sum_fields'] = ResolversParentTypes['onSitePaymentDetailType_sum_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_var_pop_fields'] = ResolversParentTypes['onSitePaymentDetailType_var_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_var_samp_fields'] = ResolversParentTypes['onSitePaymentDetailType_var_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDetailTypeVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDetailType_variance_fields'] = ResolversParentTypes['onSitePaymentDetailType_variance_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscount'] = ResolversParentTypes['onSitePaymentDiscount']> = {
  _clerkId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _onSitePaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  clerk?: Resolver<Maybe<ResolversTypes['clerk']>, ParentType, ContextType>;
  clerkId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onSitePayment?: Resolver<Maybe<ResolversTypes['onSitePayment']>, ParentType, ContextType>;
  onSitePaymentDiscountId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  onSitePaymentDiscountType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype?: Resolver<ResolversTypes['onSitePaymentDiscountType'], ParentType, ContextType>;
  onSitePaymentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType'] = ResolversParentTypes['onSitePaymentDiscountType']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  foodingJournalOnSitePaymentDiscountType?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDiscountType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDiscounts?: Resolver<Array<ResolversTypes['onSitePaymentDiscount']>, ParentType, ContextType, Partial<OnSitePaymentDiscountTypeOnSitePaymentDiscountsArgs>>;
  pikaichiOnSitePaymentDiscountTypes?: Resolver<Array<ResolversTypes['pikaichiOnSitePaymentDiscountType']>, ParentType, ContextType, Partial<OnSitePaymentDiscountTypePikaichiOnSitePaymentDiscountTypesArgs>>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopDiscountTypes?: Resolver<Array<ResolversTypes['shopDiscountType']>, ParentType, ContextType, Partial<OnSitePaymentDiscountTypeShopDiscountTypesArgs>>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_aggregate'] = ResolversParentTypes['onSitePaymentDiscountType_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['onSitePaymentDiscountType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_aggregate_fields'] = ResolversParentTypes['onSitePaymentDiscountType_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<OnSitePaymentDiscountTypeAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_avg_fields'] = ResolversParentTypes['onSitePaymentDiscountType_avg_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_max_fields'] = ResolversParentTypes['onSitePaymentDiscountType_max_fields']> = {
  companyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_min_fields'] = ResolversParentTypes['onSitePaymentDiscountType_min_fields']> = {
  companyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_mutation_response'] = ResolversParentTypes['onSitePaymentDiscountType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['onSitePaymentDiscountType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_stddev_fields'] = ResolversParentTypes['onSitePaymentDiscountType_stddev_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_stddev_pop_fields'] = ResolversParentTypes['onSitePaymentDiscountType_stddev_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_stddev_samp_fields'] = ResolversParentTypes['onSitePaymentDiscountType_stddev_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_sum_fields'] = ResolversParentTypes['onSitePaymentDiscountType_sum_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_var_pop_fields'] = ResolversParentTypes['onSitePaymentDiscountType_var_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_var_samp_fields'] = ResolversParentTypes['onSitePaymentDiscountType_var_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentDiscountTypeVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentDiscountType_variance_fields'] = ResolversParentTypes['onSitePaymentDiscountType_variance_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentSalesResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentSales'] = ResolversParentTypes['onSitePaymentSales']> = {
  _onSitePaymentId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  onSitePayment?: Resolver<ResolversTypes['onSitePayment'], ParentType, ContextType>;
  onSitePaymentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  onSitePaymentSalesId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  taxExcludedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxIncludedAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnSitePaymentTableUsersResolvers<ContextType = any, ParentType extends ResolversParentTypes['onSitePaymentTableUsers'] = ResolversParentTypes['onSitePaymentTableUsers']> = {
  _onSitePaymentId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  onSitePayment?: Resolver<ResolversTypes['onSitePayment'], ParentType, ContextType>;
  onSitePaymentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tableUser?: Resolver<ResolversTypes['tableUser'], ParentType, ContextType>;
  tableUserId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnlinePaymentPrinterRoleMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['onlinePaymentPrinterRoleMeta'] = ResolversParentTypes['onlinePaymentPrinterRoleMeta']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  printerRoleId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnlinePaymentPrinterRoleMetaMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['onlinePaymentPrinterRoleMeta_mutation_response'] = ResolversParentTypes['onlinePaymentPrinterRoleMeta_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['onlinePaymentPrinterRoleMeta']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnlinePaymentRootMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['onlinePaymentRootMutation'] = ResolversParentTypes['onlinePaymentRootMutation']> = {
  createAdyenPaymentStoreMerchantCategory?: Resolver<ResolversTypes['AdyenPaymentCreateStoreMerchantCategoryResult'], ParentType, ContextType, RequireFields<OnlinePaymentRootMutationCreateAdyenPaymentStoreMerchantCategoryArgs, 'input'>>;
  createOrUpdateGmoBankAccount?: Resolver<ResolversTypes['DashboardAccountCreateOrUpdateGmoBankAccountResult'], ParentType, ContextType, RequireFields<OnlinePaymentRootMutationCreateOrUpdateGmoBankAccountArgs, 'input'>>;
  updateAdyenPaymentStoreMeta?: Resolver<ResolversTypes['AdyenPaymentUpdateStoreResult'], ParentType, ContextType, RequireFields<OnlinePaymentRootMutationUpdateAdyenPaymentStoreMetaArgs, 'input'>>;
  upsertDiniiPayConfig?: Resolver<ResolversTypes['DashboardAccountUpsertDiniiPayConfigOutput'], ParentType, ContextType, RequireFields<OnlinePaymentRootMutationUpsertDiniiPayConfigArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OnlinePaymentRootQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['onlinePaymentRootQuery'] = ResolversParentTypes['onlinePaymentRootQuery']> = {
  gmoBankAccountDetail?: Resolver<ResolversTypes['DashboardAccountGmoBankAccountDetailResult'], ParentType, ContextType, RequireFields<OnlinePaymentRootQueryGmoBankAccountDetailArgs, 'input'>>;
  gmoShopConfigDetail?: Resolver<Maybe<ResolversTypes['DashboardAccountGmoShopConfigDetailOutput']>, ParentType, ContextType, RequireFields<OnlinePaymentRootQueryGmoShopConfigDetailArgs, 'input'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['option'] = ResolversParentTypes['option']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  choices?: Resolver<Array<ResolversTypes['choice']>, ParentType, ContextType, Partial<OptionChoicesArgs>>;
  choices_aggregate?: Resolver<ResolversTypes['choice_aggregate'], ParentType, ContextType, Partial<OptionChoicesAggregateArgs>>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  giftOptionMeta?: Resolver<Maybe<ResolversTypes['giftOptionMeta']>, ParentType, ContextType>;
  giftOptionMetaByOptionid?: Resolver<Array<ResolversTypes['giftOptionMeta']>, ParentType, ContextType, Partial<OptionGiftOptionMetaByOptionidArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inputType?: Resolver<ResolversTypes['optionInputType_enum'], ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  menuOptions?: Resolver<Array<ResolversTypes['menuOption']>, ParentType, ContextType, Partial<OptionMenuOptionsArgs>>;
  minChoiceNum?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  optionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  optionInputType?: Resolver<ResolversTypes['optionInputType'], ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receiptDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopOptionKitchenRoles?: Resolver<Array<ResolversTypes['shopOptionKitchenRole']>, ParentType, ContextType, Partial<OptionShopOptionKitchenRolesArgs>>;
  translations?: Resolver<Array<ResolversTypes['translation']>, ParentType, ContextType, Partial<OptionTranslationsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionInputTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['optionInputType'] = ResolversParentTypes['optionInputType']> = {
  options?: Resolver<Array<ResolversTypes['option']>, ParentType, ContextType, Partial<OptionInputTypeOptionsArgs>>;
  options_aggregate?: Resolver<ResolversTypes['option_aggregate'], ParentType, ContextType, Partial<OptionInputTypeOptionsAggregateArgs>>;
  planOptions?: Resolver<Array<ResolversTypes['planOption']>, ParentType, ContextType, Partial<OptionInputTypePlanOptionsArgs>>;
  planOptions_aggregate?: Resolver<ResolversTypes['planOption_aggregate'], ParentType, ContextType, Partial<OptionInputTypePlanOptionsAggregateArgs>>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionInputTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['optionInputType_mutation_response'] = ResolversParentTypes['optionInputType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['optionInputType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_aggregate'] = ResolversParentTypes['option_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['option_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['option']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_aggregate_fields'] = ResolversParentTypes['option_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['option_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<OptionAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['option_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['option_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['option_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['option_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['option_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['option_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['option_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['option_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['option_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_avg_fields'] = ResolversParentTypes['option_avg_fields']> = {
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_max_fields'] = ResolversParentTypes['option_max_fields']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  companyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receiptDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_min_fields'] = ResolversParentTypes['option_min_fields']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  companyId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receiptDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_mutation_response'] = ResolversParentTypes['option_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['option']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_stddev_fields'] = ResolversParentTypes['option_stddev_fields']> = {
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_stddev_pop_fields'] = ResolversParentTypes['option_stddev_pop_fields']> = {
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_stddev_samp_fields'] = ResolversParentTypes['option_stddev_samp_fields']> = {
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_sum_fields'] = ResolversParentTypes['option_sum_fields']> = {
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_var_pop_fields'] = ResolversParentTypes['option_var_pop_fields']> = {
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_var_samp_fields'] = ResolversParentTypes['option_var_samp_fields']> = {
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['option_variance_fields'] = ResolversParentTypes['option_variance_fields']> = {
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  optionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['order'] = ResolversParentTypes['order']> = {
  billableTableUserId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isCanceled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  menuOrderItems?: Resolver<Array<ResolversTypes['menuOrderItem']>, ParentType, ContextType, Partial<OrderMenuOrderItemsArgs>>;
  orderedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  orderedClerk?: Resolver<Maybe<ResolversTypes['clerk']>, ParentType, ContextType>;
  orderedCustomer?: Resolver<Maybe<ResolversTypes['customer']>, ParentType, ContextType>;
  orderedTableUserId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  orderedUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderedUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderedUserProfileImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tableUser?: Resolver<ResolversTypes['tableUser'], ParentType, ContextType>;
  tableUserByOrderedtableuserid?: Resolver<ResolversTypes['tableUser'], ParentType, ContextType>;
  tableUsersInGroup?: Resolver<Array<ResolversTypes['tableUser']>, ParentType, ContextType, Partial<OrderTableUsersInGroupArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderableTimeResolvers<ContextType = any, ParentType extends ResolversParentTypes['orderableTime'] = ResolversParentTypes['orderableTime']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menus?: Resolver<Array<ResolversTypes['menu']>, ParentType, ContextType, Partial<OrderableTimeMenusArgs>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plans?: Resolver<Array<ResolversTypes['plan']>, ParentType, ContextType, Partial<OrderableTimePlansArgs>>;
  shopOrderableTimeTerms?: Resolver<Array<ResolversTypes['shopOrderableTimeTerm']>, ParentType, ContextType, Partial<OrderableTimeShopOrderableTimeTermsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderableTimeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['orderableTime_mutation_response'] = ResolversParentTypes['orderableTime_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['orderableTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayingActivePlanChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['payingActivePlanChoice'] = ResolversParentTypes['payingActivePlanChoice']> = {
  _onSitePaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  activePlanChoice?: Resolver<ResolversTypes['activePlanChoice'], ParentType, ContextType>;
  activePlanChoiceId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  editedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  itemSales?: Resolver<Maybe<ResolversTypes['itemSales']>, ParentType, ContextType>;
  onSitePayment?: Resolver<Maybe<ResolversTypes['onSitePayment']>, ParentType, ContextType>;
  onSitePaymentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayingActivePlanOpenPriceMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['payingActivePlanOpenPriceMeta'] = ResolversParentTypes['payingActivePlanOpenPriceMeta']> = {
  _onSitePaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  activePlanOpenPriceMeta?: Resolver<ResolversTypes['activePlanOpenPriceMeta'], ParentType, ContextType>;
  activePlanOpenPriceMetaId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  editedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  itemSales?: Resolver<Maybe<ResolversTypes['itemSales']>, ParentType, ContextType>;
  onSitePayment?: Resolver<Maybe<ResolversTypes['onSitePayment']>, ParentType, ContextType>;
  onSitePaymentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  payingActivePlanOpenPriceMetaId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayingMenuOrderItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['payingMenuOrderItem'] = ResolversParentTypes['payingMenuOrderItem']> = {
  _onSitePaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  editedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  itemSales?: Resolver<Maybe<ResolversTypes['itemSales']>, ParentType, ContextType>;
  menuOrderItem?: Resolver<ResolversTypes['menuOrderItem'], ParentType, ContextType>;
  menuOrderItemId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  onSitePayment?: Resolver<Maybe<ResolversTypes['onSitePayment']>, ParentType, ContextType>;
  onSitePaymentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentReceiptRoleTableResolvers<ContextType = any, ParentType extends ResolversParentTypes['paymentReceiptRoleTable'] = ResolversParentTypes['paymentReceiptRoleTable']> = {
  _roleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _tableId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['role']>, ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['table']>, ParentType, ContextType>;
  tableId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentReceiptRoleTableMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['paymentReceiptRoleTable_mutation_response'] = ResolversParentTypes['paymentReceiptRoleTable_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['paymentReceiptRoleTable']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiConfig'] = ResolversParentTypes['pikaichiConfig']> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  freeCsvType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ftpHost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ftpPassword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ftpPort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ftpUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pikaichiCompanyCd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pikaichiConfigId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pikaichiPosNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pikaichiShopCd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  useKansa?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  useKinken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  useNewari?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiConfig_mutation_response'] = ResolversParentTypes['pikaichiConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['pikaichiConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiMenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiMenu'] = ResolversParentTypes['pikaichiMenu']> = {
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pikaichiBumonCd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pikaichiBumonName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pikaichiCategoryCd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pikaichiCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pikaichiMenuCd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pikaichiMenuChoices?: Resolver<Array<ResolversTypes['pikaichiMenuChoice']>, ParentType, ContextType, Partial<PikaichiMenuPikaichiMenuChoicesArgs>>;
  pikaichiMenuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pikaichiMenuMenus?: Resolver<Array<ResolversTypes['pikaichiMenuMenu']>, ParentType, ContextType, Partial<PikaichiMenuPikaichiMenuMenusArgs>>;
  pikaichiMenuName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pikaichiMenuPlanChoices?: Resolver<Array<ResolversTypes['pikaichiMenuPlanChoice']>, ParentType, ContextType, Partial<PikaichiMenuPikaichiMenuPlanChoicesArgs>>;
  pikaichiMenuPlans?: Resolver<Array<ResolversTypes['pikaichiMenuPlan']>, ParentType, ContextType, Partial<PikaichiMenuPikaichiMenuPlansArgs>>;
  shop?: Resolver<Maybe<ResolversTypes['shop']>, ParentType, ContextType>;
  shopId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiMenuChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiMenuChoice'] = ResolversParentTypes['pikaichiMenuChoice']> = {
  _choiceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  choice?: Resolver<ResolversTypes['choice'], ParentType, ContextType>;
  choiceId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pikaichiMenu?: Resolver<ResolversTypes['pikaichiMenu'], ParentType, ContextType>;
  pikaichiMenuChoiceId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pikaichiMenuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiMenuChoiceMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiMenuChoice_mutation_response'] = ResolversParentTypes['pikaichiMenuChoice_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['pikaichiMenuChoice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiMenuMenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiMenuMenu'] = ResolversParentTypes['pikaichiMenuMenu']> = {
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pikaichiMenu?: Resolver<ResolversTypes['pikaichiMenu'], ParentType, ContextType>;
  pikaichiMenuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pikaichiMenuMenuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiMenuMenuMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiMenuMenu_mutation_response'] = ResolversParentTypes['pikaichiMenuMenu_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['pikaichiMenuMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiMenuPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiMenuPlan'] = ResolversParentTypes['pikaichiMenuPlan']> = {
  _planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pikaichiMenu?: Resolver<ResolversTypes['pikaichiMenu'], ParentType, ContextType>;
  pikaichiMenuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pikaichiMenuPlanId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['plan'], ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiMenuPlanChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiMenuPlanChoice'] = ResolversParentTypes['pikaichiMenuPlanChoice']> = {
  _planChoiceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  pikaichiMenu?: Resolver<ResolversTypes['pikaichiMenu'], ParentType, ContextType>;
  pikaichiMenuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pikaichiMenuPlanChoiceId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  planChoice?: Resolver<ResolversTypes['planChoice'], ParentType, ContextType>;
  planChoiceId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiMenuPlanChoiceMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiMenuPlanChoice_mutation_response'] = ResolversParentTypes['pikaichiMenuPlanChoice_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['pikaichiMenuPlanChoice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiMenuPlanMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiMenuPlan_mutation_response'] = ResolversParentTypes['pikaichiMenuPlan_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['pikaichiMenuPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiMenuMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiMenu_mutation_response'] = ResolversParentTypes['pikaichiMenu_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['pikaichiMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiOnSitePaymentDetailTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiOnSitePaymentDetailType'] = ResolversParentTypes['pikaichiOnSitePaymentDetailType']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  onSitePaymentDetailType?: Resolver<ResolversTypes['onSitePaymentDetailType'], ParentType, ContextType>;
  onSitePaymentDetailTypeEntityType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDetailTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  paymentMediaCd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentMediaName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentUchiwakeCd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentUchiwakeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pikaichiPaymentDetailTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<Maybe<ResolversTypes['shop']>, ParentType, ContextType>;
  shopId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiOnSitePaymentDetailTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiOnSitePaymentDetailType_mutation_response'] = ResolversParentTypes['pikaichiOnSitePaymentDetailType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['pikaichiOnSitePaymentDetailType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiOnSitePaymentDiscountTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiOnSitePaymentDiscountType'] = ResolversParentTypes['pikaichiOnSitePaymentDiscountType']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  newariCd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  newariKubun?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  newariName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDiscountType?: Resolver<ResolversTypes['onSitePaymentDiscountType'], ParentType, ContextType>;
  onSitePaymentDiscountTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  onSitePaymentDiscountTypeKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pikaichiDiscountTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<Maybe<ResolversTypes['shop']>, ParentType, ContextType>;
  shopId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PikaichiOnSitePaymentDiscountTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['pikaichiOnSitePaymentDiscountType_mutation_response'] = ResolversParentTypes['pikaichiOnSitePaymentDiscountType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['pikaichiOnSitePaymentDiscountType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan'] = ResolversParentTypes['plan']> = {
  _planGroupId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['category'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dishUpSlipGroupShopPlans?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopPlans']>, ParentType, ContextType, Partial<PlanDishUpSlipGroupShopPlansArgs>>;
  displayType?: Resolver<Maybe<ResolversTypes['displayType_enum']>, ParentType, ContextType>;
  endNotificationThresholdMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  featuredLabelText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  foodingJournalMenu?: Resolver<Maybe<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orderableTime?: Resolver<ResolversTypes['orderableTime'], ParentType, ContextType>;
  orderableTimeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  pikaichiMenuPlans?: Resolver<Array<ResolversTypes['pikaichiMenuPlan']>, ParentType, ContextType, Partial<PlanPikaichiMenuPlansArgs>>;
  planCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  planFirstOrderCategoryMenus?: Resolver<Array<ResolversTypes['planFirstOrderCategoryMenu']>, ParentType, ContextType, Partial<PlanPlanFirstOrderCategoryMenusArgs>>;
  planGroup?: Resolver<Maybe<ResolversTypes['planGroup']>, ParentType, ContextType>;
  planGroupId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  planMenuCategories?: Resolver<Array<ResolversTypes['planMenuCategory']>, ParentType, ContextType, Partial<PlanPlanMenuCategoriesArgs>>;
  planName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  planOptions?: Resolver<Array<ResolversTypes['planOption']>, ParentType, ContextType, Partial<PlanPlanOptionsArgs>>;
  planOptions_aggregate?: Resolver<ResolversTypes['planOption_aggregate'], ParentType, ContextType, Partial<PlanPlanOptionsAggregateArgs>>;
  planTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan_menu_categories_categories?: Resolver<Array<ResolversTypes['plan_menu_categories_category']>, ParentType, ContextType, Partial<PlanPlanMenuCategoriesCategoriesArgs>>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  receiptDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopPlanKitchenRoles?: Resolver<Array<ResolversTypes['shopPlanKitchenRole']>, ParentType, ContextType, Partial<PlanShopPlanKitchenRolesArgs>>;
  shopPlans?: Resolver<Array<ResolversTypes['shopPlan']>, ParentType, ContextType, Partial<PlanShopPlansArgs>>;
  shopSideName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxMethod?: Resolver<ResolversTypes['taxMethod_enum'], ParentType, ContextType>;
  taxRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  tecAggregationMenu?: Resolver<Maybe<ResolversTypes['tecAggregationMenu']>, ParentType, ContextType>;
  totalDealingShopCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  translations?: Resolver<Array<ResolversTypes['translation']>, ParentType, ContextType, Partial<PlanTranslationsArgs>>;
  winboardMenus?: Resolver<Array<ResolversTypes['winboardMenu']>, ParentType, ContextType, Partial<PlanWinboardMenusArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice'] = ResolversParentTypes['planChoice']> = {
  _planOptionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  countAsFirstOrderMenuQuantity?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  foodingJournalMenu?: Resolver<Maybe<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isDefaultSelection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  menuType?: Resolver<Maybe<ResolversTypes['menuType_enum']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pikaichiMenuPlanChoices?: Resolver<Array<ResolversTypes['pikaichiMenuPlanChoice']>, ParentType, ContextType, Partial<PlanChoicePikaichiMenuPlanChoicesArgs>>;
  planChoiceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  planOption?: Resolver<ResolversTypes['planOption'], ParentType, ContextType>;
  planOptionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  posPlanChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  receiptDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopPlanChoices?: Resolver<Array<ResolversTypes['shopPlanChoice']>, ParentType, ContextType, Partial<PlanChoiceShopPlanChoicesArgs>>;
  tecAggregationMenu?: Resolver<Maybe<ResolversTypes['tecAggregationMenu']>, ParentType, ContextType>;
  translations?: Resolver<Array<ResolversTypes['translation']>, ParentType, ContextType, Partial<PlanChoiceTranslationsArgs>>;
  winboardMenus?: Resolver<Array<ResolversTypes['winboardMenu']>, ParentType, ContextType, Partial<PlanChoiceWinboardMenusArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_aggregate'] = ResolversParentTypes['planChoice_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['planChoice_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['planChoice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_aggregate_fields'] = ResolversParentTypes['planChoice_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['planChoice_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PlanChoiceAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['planChoice_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['planChoice_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['planChoice_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['planChoice_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['planChoice_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['planChoice_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['planChoice_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['planChoice_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['planChoice_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_avg_fields'] = ResolversParentTypes['planChoice_avg_fields']> = {
  _planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posPlanChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_max_fields'] = ResolversParentTypes['planChoice_max_fields']> = {
  _planOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  posPlanChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receiptDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_min_fields'] = ResolversParentTypes['planChoice_min_fields']> = {
  _planOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  posPlanChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receiptDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_mutation_response'] = ResolversParentTypes['planChoice_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['planChoice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_stddev_fields'] = ResolversParentTypes['planChoice_stddev_fields']> = {
  _planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posPlanChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_stddev_pop_fields'] = ResolversParentTypes['planChoice_stddev_pop_fields']> = {
  _planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posPlanChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_stddev_samp_fields'] = ResolversParentTypes['planChoice_stddev_samp_fields']> = {
  _planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posPlanChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_sum_fields'] = ResolversParentTypes['planChoice_sum_fields']> = {
  _planOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  posPlanChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_var_pop_fields'] = ResolversParentTypes['planChoice_var_pop_fields']> = {
  _planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posPlanChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_var_samp_fields'] = ResolversParentTypes['planChoice_var_samp_fields']> = {
  _planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posPlanChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanChoiceVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planChoice_variance_fields'] = ResolversParentTypes['planChoice_variance_fields']> = {
  _planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTaxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxOrderableNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posPlanChoiceId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanFirstOrderCategoryMenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['planFirstOrderCategoryMenu'] = ResolversParentTypes['planFirstOrderCategoryMenu']> = {
  _categoryMenuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  categoryMenu?: Resolver<ResolversTypes['categoryMenu'], ParentType, ContextType>;
  categoryMenuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['plan'], ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanFirstOrderCategoryMenuMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['planFirstOrderCategoryMenu_mutation_response'] = ResolversParentTypes['planFirstOrderCategoryMenu_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['planFirstOrderCategoryMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['planGroup'] = ResolversParentTypes['planGroup']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  planGroupId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plans?: Resolver<Array<ResolversTypes['plan']>, ParentType, ContextType, Partial<PlanGroupPlansArgs>>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanGroupMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['planGroup_mutation_response'] = ResolversParentTypes['planGroup_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['planGroup']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanMenuCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['planMenuCategory'] = ResolversParentTypes['planMenuCategory']> = {
  _categoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['category'], ParentType, ContextType>;
  categoryId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['plan'], ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanMenuCategoryMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['planMenuCategory_mutation_response'] = ResolversParentTypes['planMenuCategory_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['planMenuCategory']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption'] = ResolversParentTypes['planOption']> = {
  _planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inputType?: Resolver<ResolversTypes['optionInputType_enum'], ParentType, ContextType>;
  isMainOption?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  optionInputType?: Resolver<ResolversTypes['optionInputType'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['plan'], ParentType, ContextType>;
  planChoices?: Resolver<Array<ResolversTypes['planChoice']>, ParentType, ContextType, Partial<PlanOptionPlanChoicesArgs>>;
  planChoices_aggregate?: Resolver<ResolversTypes['planChoice_aggregate'], ParentType, ContextType, Partial<PlanOptionPlanChoicesAggregateArgs>>;
  planId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  planOptionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  receiptDisplayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopPlanOptionKitchenRoles?: Resolver<Array<ResolversTypes['shopPlanOptionKitchenRole']>, ParentType, ContextType, Partial<PlanOptionShopPlanOptionKitchenRolesArgs>>;
  shopPlanOptions?: Resolver<Array<ResolversTypes['shopPlanOption']>, ParentType, ContextType, Partial<PlanOptionShopPlanOptionsArgs>>;
  translations?: Resolver<Array<ResolversTypes['translation']>, ParentType, ContextType, Partial<PlanOptionTranslationsArgs>>;
  validateChoiceSelectionByNumPeople?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_aggregate'] = ResolversParentTypes['planOption_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['planOption_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['planOption']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_aggregate_fields'] = ResolversParentTypes['planOption_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['planOption_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<PlanOptionAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['planOption_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['planOption_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['planOption_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['planOption_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['planOption_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['planOption_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['planOption_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['planOption_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['planOption_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_avg_fields'] = ResolversParentTypes['planOption_avg_fields']> = {
  _planId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_max_fields'] = ResolversParentTypes['planOption_max_fields']> = {
  _planId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  planId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receiptDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_min_fields'] = ResolversParentTypes['planOption_min_fields']> = {
  _planId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  planId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receiptDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_mutation_response'] = ResolversParentTypes['planOption_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['planOption']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_stddev_fields'] = ResolversParentTypes['planOption_stddev_fields']> = {
  _planId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_stddev_pop_fields'] = ResolversParentTypes['planOption_stddev_pop_fields']> = {
  _planId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_stddev_samp_fields'] = ResolversParentTypes['planOption_stddev_samp_fields']> = {
  _planId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_sum_fields'] = ResolversParentTypes['planOption_sum_fields']> = {
  _planId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_var_pop_fields'] = ResolversParentTypes['planOption_var_pop_fields']> = {
  _planId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_var_samp_fields'] = ResolversParentTypes['planOption_var_samp_fields']> = {
  _planId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanOptionVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['planOption_variance_fields'] = ResolversParentTypes['planOption_variance_fields']> = {
  _planId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChoiceNum?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  planOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posOptionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanMenuCategoriesCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_menu_categories_category'] = ResolversParentTypes['plan_menu_categories_category']> = {
  category?: Resolver<Maybe<ResolversTypes['category']>, ParentType, ContextType>;
  categoryCategoryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['plan']>, ParentType, ContextType>;
  planPlanId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanMenuCategoriesCategoryMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_menu_categories_category_mutation_response'] = ResolversParentTypes['plan_menu_categories_category_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['plan_menu_categories_category']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['plan_mutation_response'] = ResolversParentTypes['plan_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['plan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryRootResolvers<ContextType = any, ParentType extends ResolversParentTypes['query_root'] = ResolversParentTypes['query_root']> = {
  accountingSlipImage?: Resolver<Array<ResolversTypes['accountingSlipImage']>, ParentType, ContextType, Partial<QueryRootAccountingSlipImageArgs>>;
  accountingSlipImage_by_pk?: Resolver<Maybe<ResolversTypes['accountingSlipImage']>, ParentType, ContextType, RequireFields<QueryRootAccountingSlipImageByPkArgs, 'id'>>;
  activePlan?: Resolver<Array<ResolversTypes['activePlan']>, ParentType, ContextType, Partial<QueryRootActivePlanArgs>>;
  activePlanChoice?: Resolver<Array<ResolversTypes['activePlanChoice']>, ParentType, ContextType, Partial<QueryRootActivePlanChoiceArgs>>;
  activePlanChoice_by_pk?: Resolver<Maybe<ResolversTypes['activePlanChoice']>, ParentType, ContextType, RequireFields<QueryRootActivePlanChoiceByPkArgs, 'activePlanChoiceId'>>;
  activePlanOpenPriceMeta?: Resolver<Array<ResolversTypes['activePlanOpenPriceMeta']>, ParentType, ContextType, Partial<QueryRootActivePlanOpenPriceMetaArgs>>;
  activePlanOpenPriceMeta_by_pk?: Resolver<Maybe<ResolversTypes['activePlanOpenPriceMeta']>, ParentType, ContextType, RequireFields<QueryRootActivePlanOpenPriceMetaByPkArgs, 'id'>>;
  activePlanOption?: Resolver<Array<ResolversTypes['activePlanOption']>, ParentType, ContextType, Partial<QueryRootActivePlanOptionArgs>>;
  activePlanOption_by_pk?: Resolver<Maybe<ResolversTypes['activePlanOption']>, ParentType, ContextType, RequireFields<QueryRootActivePlanOptionByPkArgs, 'activePlanOptionId'>>;
  activePlan_by_pk?: Resolver<Maybe<ResolversTypes['activePlan']>, ParentType, ContextType, RequireFields<QueryRootActivePlanByPkArgs, 'id'>>;
  adyenPaymentReportTerminalPaymentAuthorizedEvent?: Resolver<Array<ResolversTypes['adyenPaymentReportTerminalPaymentAuthorizedEvent']>, ParentType, ContextType, Partial<QueryRootAdyenPaymentReportTerminalPaymentAuthorizedEventArgs>>;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_by_pk?: Resolver<Maybe<ResolversTypes['adyenPaymentReportTerminalPaymentAuthorizedEvent']>, ParentType, ContextType, RequireFields<QueryRootAdyenPaymentReportTerminalPaymentAuthorizedEventByPkArgs, 'id'>>;
  adyenPaymentReportTerminalPaymentCanceledEvent?: Resolver<Array<ResolversTypes['adyenPaymentReportTerminalPaymentCanceledEvent']>, ParentType, ContextType, Partial<QueryRootAdyenPaymentReportTerminalPaymentCanceledEventArgs>>;
  adyenPaymentReportTerminalPaymentCanceledEvent_by_pk?: Resolver<Maybe<ResolversTypes['adyenPaymentReportTerminalPaymentCanceledEvent']>, ParentType, ContextType, RequireFields<QueryRootAdyenPaymentReportTerminalPaymentCanceledEventByPkArgs, 'id'>>;
  adyenPaymentShopConfig?: Resolver<Array<ResolversTypes['adyenPaymentShopConfig']>, ParentType, ContextType, Partial<QueryRootAdyenPaymentShopConfigArgs>>;
  adyenPaymentShopConfig_by_pk?: Resolver<Maybe<ResolversTypes['adyenPaymentShopConfig']>, ParentType, ContextType, RequireFields<QueryRootAdyenPaymentShopConfigByPkArgs, 'id'>>;
  adyenPaymentShopPaymentMethod?: Resolver<Array<ResolversTypes['adyenPaymentShopPaymentMethod']>, ParentType, ContextType, Partial<QueryRootAdyenPaymentShopPaymentMethodArgs>>;
  adyenPaymentShopPaymentMethod_by_pk?: Resolver<Maybe<ResolversTypes['adyenPaymentShopPaymentMethod']>, ParentType, ContextType, RequireFields<QueryRootAdyenPaymentShopPaymentMethodByPkArgs, 'id'>>;
  adyenPaymentStoreMeta?: Resolver<Array<ResolversTypes['adyenPaymentStoreMeta']>, ParentType, ContextType, Partial<QueryRootAdyenPaymentStoreMetaArgs>>;
  adyenPaymentStoreMeta_by_pk?: Resolver<Maybe<ResolversTypes['adyenPaymentStoreMeta']>, ParentType, ContextType, RequireFields<QueryRootAdyenPaymentStoreMetaByPkArgs, 'id'>>;
  adyenTerminalPaymentShopConfig?: Resolver<Array<ResolversTypes['adyenTerminalPaymentShopConfig']>, ParentType, ContextType, Partial<QueryRootAdyenTerminalPaymentShopConfigArgs>>;
  adyenTerminalPaymentShopConfig_by_pk?: Resolver<Maybe<ResolversTypes['adyenTerminalPaymentShopConfig']>, ParentType, ContextType, RequireFields<QueryRootAdyenTerminalPaymentShopConfigByPkArgs, 'id'>>;
  adyenTerminalPaymentShopPaymentMethod?: Resolver<Array<ResolversTypes['adyenTerminalPaymentShopPaymentMethod']>, ParentType, ContextType, Partial<QueryRootAdyenTerminalPaymentShopPaymentMethodArgs>>;
  adyenTerminalPaymentShopPaymentMethod_by_pk?: Resolver<Maybe<ResolversTypes['adyenTerminalPaymentShopPaymentMethod']>, ParentType, ContextType, RequireFields<QueryRootAdyenTerminalPaymentShopPaymentMethodByPkArgs, 'id'>>;
  analyticsSetting?: Resolver<Array<ResolversTypes['analyticsSetting']>, ParentType, ContextType, Partial<QueryRootAnalyticsSettingArgs>>;
  analyticsSetting_by_pk?: Resolver<Maybe<ResolversTypes['analyticsSetting']>, ParentType, ContextType, RequireFields<QueryRootAnalyticsSettingByPkArgs, 'id'>>;
  birthdayTypeQuestionAnswer?: Resolver<Array<ResolversTypes['birthdayTypeQuestionAnswer']>, ParentType, ContextType, Partial<QueryRootBirthdayTypeQuestionAnswerArgs>>;
  cashBalance?: Resolver<Array<ResolversTypes['CashBalanceCashDenominationCount']>, ParentType, ContextType, RequireFields<QueryRootCashBalanceArgs, 'input'>>;
  cashManagement?: Resolver<Array<ResolversTypes['CashManagement']>, ParentType, ContextType, RequireFields<QueryRootCashManagementArgs, 'input'>>;
  cashRegisterConfig?: Resolver<Array<ResolversTypes['cashRegisterConfig']>, ParentType, ContextType, Partial<QueryRootCashRegisterConfigArgs>>;
  cashRegisterConfig_aggregate?: Resolver<ResolversTypes['cashRegisterConfig_aggregate'], ParentType, ContextType, Partial<QueryRootCashRegisterConfigAggregateArgs>>;
  cashRegisterConfig_by_pk?: Resolver<Maybe<ResolversTypes['cashRegisterConfig']>, ParentType, ContextType, RequireFields<QueryRootCashRegisterConfigByPkArgs, 'id'>>;
  cashRegisterSecurityLevel?: Resolver<Array<ResolversTypes['cashRegisterSecurityLevel']>, ParentType, ContextType, Partial<QueryRootCashRegisterSecurityLevelArgs>>;
  cashRegisterSecurityLevel_by_pk?: Resolver<Maybe<ResolversTypes['cashRegisterSecurityLevel']>, ParentType, ContextType, RequireFields<QueryRootCashRegisterSecurityLevelByPkArgs, 'value'>>;
  category?: Resolver<Array<ResolversTypes['category']>, ParentType, ContextType, Partial<QueryRootCategoryArgs>>;
  categoryMenu?: Resolver<Array<ResolversTypes['categoryMenu']>, ParentType, ContextType, Partial<QueryRootCategoryMenuArgs>>;
  categoryMenu_aggregate?: Resolver<ResolversTypes['categoryMenu_aggregate'], ParentType, ContextType, Partial<QueryRootCategoryMenuAggregateArgs>>;
  categoryMenu_by_pk?: Resolver<Maybe<ResolversTypes['categoryMenu']>, ParentType, ContextType, RequireFields<QueryRootCategoryMenuByPkArgs, 'serial'>>;
  category_aggregate?: Resolver<ResolversTypes['category_aggregate'], ParentType, ContextType, Partial<QueryRootCategoryAggregateArgs>>;
  category_by_pk?: Resolver<Maybe<ResolversTypes['category']>, ParentType, ContextType, RequireFields<QueryRootCategoryByPkArgs, 'serial'>>;
  checkInEvent?: Resolver<Array<ResolversTypes['checkInEvent']>, ParentType, ContextType, Partial<QueryRootCheckInEventArgs>>;
  choice?: Resolver<Array<ResolversTypes['choice']>, ParentType, ContextType, Partial<QueryRootChoiceArgs>>;
  choice_aggregate?: Resolver<ResolversTypes['choice_aggregate'], ParentType, ContextType, Partial<QueryRootChoiceAggregateArgs>>;
  choice_by_pk?: Resolver<Maybe<ResolversTypes['choice']>, ParentType, ContextType, RequireFields<QueryRootChoiceByPkArgs, 'serial'>>;
  clerk?: Resolver<Array<ResolversTypes['clerk']>, ParentType, ContextType, Partial<QueryRootClerkArgs>>;
  clerk_by_pk?: Resolver<Maybe<ResolversTypes['clerk']>, ParentType, ContextType, RequireFields<QueryRootClerkByPkArgs, 'serial'>>;
  closeCashRegister?: Resolver<Array<ResolversTypes['closeCashRegister']>, ParentType, ContextType, Partial<QueryRootCloseCashRegisterArgs>>;
  closeCashRegister_by_pk?: Resolver<Maybe<ResolversTypes['closeCashRegister']>, ParentType, ContextType, RequireFields<QueryRootCloseCashRegisterByPkArgs, 'id'>>;
  coineyPayment?: Resolver<Array<ResolversTypes['coineyPayment']>, ParentType, ContextType, Partial<QueryRootCoineyPaymentArgs>>;
  coineyPaymentOrder?: Resolver<Array<ResolversTypes['coineyPaymentOrder']>, ParentType, ContextType, Partial<QueryRootCoineyPaymentOrderArgs>>;
  coineyPaymentOrder_by_pk?: Resolver<Maybe<ResolversTypes['coineyPaymentOrder']>, ParentType, ContextType, RequireFields<QueryRootCoineyPaymentOrderByPkArgs, 'coineyPaymentOrderId' | 'orderId'>>;
  coineyPaymentSales?: Resolver<Array<ResolversTypes['coineyPaymentSales']>, ParentType, ContextType, Partial<QueryRootCoineyPaymentSalesArgs>>;
  coineyPaymentSales_by_pk?: Resolver<Maybe<ResolversTypes['coineyPaymentSales']>, ParentType, ContextType, RequireFields<QueryRootCoineyPaymentSalesByPkArgs, 'id'>>;
  coineyPayment_by_pk?: Resolver<Maybe<ResolversTypes['coineyPayment']>, ParentType, ContextType, RequireFields<QueryRootCoineyPaymentByPkArgs, 'serial'>>;
  coineySettlement?: Resolver<Array<ResolversTypes['coineySettlement']>, ParentType, ContextType, Partial<QueryRootCoineySettlementArgs>>;
  coineySettlement_by_pk?: Resolver<Maybe<ResolversTypes['coineySettlement']>, ParentType, ContextType, RequireFields<QueryRootCoineySettlementByPkArgs, 'id'>>;
  company?: Resolver<Array<ResolversTypes['company']>, ParentType, ContextType, Partial<QueryRootCompanyArgs>>;
  companyMember?: Resolver<Array<ResolversTypes['companyMember']>, ParentType, ContextType, Partial<QueryRootCompanyMemberArgs>>;
  companyMember_aggregate?: Resolver<ResolversTypes['companyMember_aggregate'], ParentType, ContextType, Partial<QueryRootCompanyMemberAggregateArgs>>;
  companyMember_by_pk?: Resolver<Maybe<ResolversTypes['companyMember']>, ParentType, ContextType, RequireFields<QueryRootCompanyMemberByPkArgs, 'id'>>;
  companySalesAnalytics?: Resolver<ResolversTypes['CompanySalesAnalyticsOutput'], ParentType, ContextType, RequireFields<QueryRootCompanySalesAnalyticsArgs, 'input'>>;
  company_by_pk?: Resolver<Maybe<ResolversTypes['company']>, ParentType, ContextType, RequireFields<QueryRootCompanyByPkArgs, 'serial'>>;
  compulsoryAppetizerConfig?: Resolver<Array<ResolversTypes['compulsoryAppetizerConfig']>, ParentType, ContextType, Partial<QueryRootCompulsoryAppetizerConfigArgs>>;
  compulsoryAppetizerConfig_by_pk?: Resolver<Maybe<ResolversTypes['compulsoryAppetizerConfig']>, ParentType, ContextType, RequireFields<QueryRootCompulsoryAppetizerConfigByPkArgs, 'id'>>;
  connectGameConfig?: Resolver<Array<ResolversTypes['connectGameConfig']>, ParentType, ContextType, Partial<QueryRootConnectGameConfigArgs>>;
  connectGameConfigShop?: Resolver<Array<ResolversTypes['connectGameConfigShop']>, ParentType, ContextType, Partial<QueryRootConnectGameConfigShopArgs>>;
  connectGameConfigShop_by_pk?: Resolver<Maybe<ResolversTypes['connectGameConfigShop']>, ParentType, ContextType, RequireFields<QueryRootConnectGameConfigShopByPkArgs, 'id'>>;
  connectGameConfig_by_pk?: Resolver<Maybe<ResolversTypes['connectGameConfig']>, ParentType, ContextType, RequireFields<QueryRootConnectGameConfigByPkArgs, 'id'>>;
  cookingItem?: Resolver<Array<ResolversTypes['cookingItem']>, ParentType, ContextType, Partial<QueryRootCookingItemArgs>>;
  cookingItem_by_pk?: Resolver<Maybe<ResolversTypes['cookingItem']>, ParentType, ContextType, RequireFields<QueryRootCookingItemByPkArgs, 'serial'>>;
  corporation?: Resolver<Array<ResolversTypes['corporation']>, ParentType, ContextType, Partial<QueryRootCorporationArgs>>;
  corporation_by_pk?: Resolver<Maybe<ResolversTypes['corporation']>, ParentType, ContextType, RequireFields<QueryRootCorporationByPkArgs, 'id'>>;
  coupon?: Resolver<Array<ResolversTypes['coupon']>, ParentType, ContextType, Partial<QueryRootCouponArgs>>;
  couponAnalyticsPerCoupon?: Resolver<ResolversTypes['CouponAnalyticsPerCoupon'], ParentType, ContextType, RequireFields<QueryRootCouponAnalyticsPerCouponArgs, 'input'>>;
  couponAnalyticsSummary?: Resolver<ResolversTypes['CouponAnalyticsSummary'], ParentType, ContextType, RequireFields<QueryRootCouponAnalyticsSummaryArgs, 'input'>>;
  couponAppliedEvent?: Resolver<Array<ResolversTypes['couponAppliedEvent']>, ParentType, ContextType, Partial<QueryRootCouponAppliedEventArgs>>;
  couponAppliedEvent_aggregate?: Resolver<ResolversTypes['couponAppliedEvent_aggregate'], ParentType, ContextType, Partial<QueryRootCouponAppliedEventAggregateArgs>>;
  couponAppliedEvent_by_pk?: Resolver<Maybe<ResolversTypes['couponAppliedEvent']>, ParentType, ContextType, RequireFields<QueryRootCouponAppliedEventByPkArgs, 'id'>>;
  couponMenuDiscount?: Resolver<Array<ResolversTypes['couponMenuDiscount']>, ParentType, ContextType, Partial<QueryRootCouponMenuDiscountArgs>>;
  couponMenuDiscount_by_pk?: Resolver<Maybe<ResolversTypes['couponMenuDiscount']>, ParentType, ContextType, RequireFields<QueryRootCouponMenuDiscountByPkArgs, 'id'>>;
  coupon_by_pk?: Resolver<Maybe<ResolversTypes['coupon']>, ParentType, ContextType, RequireFields<QueryRootCouponByPkArgs, 'id'>>;
  cumulativeCount?: Resolver<ResolversTypes['CumulativeCount'], ParentType, ContextType, RequireFields<QueryRootCumulativeCountArgs, 'input'>>;
  customer?: Resolver<Array<ResolversTypes['customer']>, ParentType, ContextType, Partial<QueryRootCustomerArgs>>;
  customerDashboardKpis?: Resolver<ResolversTypes['CustomerDashboardKpis'], ParentType, ContextType, RequireFields<QueryRootCustomerDashboardKpisArgs, 'input'>>;
  customerDashboardTransitions?: Resolver<ResolversTypes['CustomerDashboardTransitions'], ParentType, ContextType, RequireFields<QueryRootCustomerDashboardTransitionsArgs, 'input'>>;
  customerFaveYellAmountRanking?: Resolver<ResolversTypes['CustomerFaveYellRanking'], ParentType, ContextType, RequireFields<QueryRootCustomerFaveYellAmountRankingArgs, 'input'>>;
  customerFaveYellCountRanking?: Resolver<ResolversTypes['CustomerFaveYellRanking'], ParentType, ContextType, RequireFields<QueryRootCustomerFaveYellCountRankingArgs, 'input'>>;
  customerQuestionnaire?: Resolver<Array<ResolversTypes['customerQuestionnaire']>, ParentType, ContextType, Partial<QueryRootCustomerQuestionnaireArgs>>;
  customerQuestionnaire_by_pk?: Resolver<Maybe<ResolversTypes['customerQuestionnaire']>, ParentType, ContextType, RequireFields<QueryRootCustomerQuestionnaireByPkArgs, 'id'>>;
  customerViralCountRanking?: Resolver<ResolversTypes['CustomerRanking'], ParentType, ContextType, RequireFields<QueryRootCustomerViralCountRankingArgs, 'input'>>;
  customerVisitedCountRanking?: Resolver<ResolversTypes['CustomerRanking'], ParentType, ContextType, RequireFields<QueryRootCustomerVisitedCountRankingArgs, 'input'>>;
  customer_by_pk?: Resolver<Maybe<ResolversTypes['customer']>, ParentType, ContextType, RequireFields<QueryRootCustomerByPkArgs, 'id'>>;
  dailyCashRegisterBalancing?: Resolver<ResolversTypes['DailyCashRegisterBalancing'], ParentType, ContextType, RequireFields<QueryRootDailyCashRegisterBalancingArgs, 'input'>>;
  dailySalesBudget?: Resolver<Array<ResolversTypes['dailySalesBudget']>, ParentType, ContextType, Partial<QueryRootDailySalesBudgetArgs>>;
  dailySalesBudget_by_pk?: Resolver<Maybe<ResolversTypes['dailySalesBudget']>, ParentType, ContextType, RequireFields<QueryRootDailySalesBudgetByPkArgs, 'id'>>;
  dailySalesSummary?: Resolver<Array<ResolversTypes['DailySalesSummary']>, ParentType, ContextType, RequireFields<QueryRootDailySalesSummaryArgs, 'input'>>;
  dashboardAccount?: Resolver<Array<ResolversTypes['dashboardAccount']>, ParentType, ContextType, Partial<QueryRootDashboardAccountArgs>>;
  dashboardAccountAccessibleCompany?: Resolver<Array<ResolversTypes['dashboardAccountAccessibleCompany']>, ParentType, ContextType, Partial<QueryRootDashboardAccountAccessibleCompanyArgs>>;
  dashboardAccountAccessibleCompany_by_pk?: Resolver<Maybe<ResolversTypes['dashboardAccountAccessibleCompany']>, ParentType, ContextType, RequireFields<QueryRootDashboardAccountAccessibleCompanyByPkArgs, 'id'>>;
  dashboardAccountAccessibleShop?: Resolver<Array<ResolversTypes['dashboardAccountAccessibleShop']>, ParentType, ContextType, Partial<QueryRootDashboardAccountAccessibleShopArgs>>;
  dashboardAccountAccessibleShop_by_pk?: Resolver<Maybe<ResolversTypes['dashboardAccountAccessibleShop']>, ParentType, ContextType, RequireFields<QueryRootDashboardAccountAccessibleShopByPkArgs, 'id'>>;
  dashboardAccountGetMessageDeliveryConsumption?: Resolver<ResolversTypes['MessageDeliveryConsumption'], ParentType, ContextType, RequireFields<QueryRootDashboardAccountGetMessageDeliveryConsumptionArgs, 'input'>>;
  dashboardAccountPing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dashboardAccountRole?: Resolver<Array<ResolversTypes['dashboardAccountRole']>, ParentType, ContextType, Partial<QueryRootDashboardAccountRoleArgs>>;
  dashboardAccountRolePermission?: Resolver<Array<ResolversTypes['dashboardAccountRolePermission']>, ParentType, ContextType, Partial<QueryRootDashboardAccountRolePermissionArgs>>;
  dashboardAccountRolePermission_by_pk?: Resolver<Maybe<ResolversTypes['dashboardAccountRolePermission']>, ParentType, ContextType, RequireFields<QueryRootDashboardAccountRolePermissionByPkArgs, 'id'>>;
  dashboardAccountRoleType?: Resolver<Array<ResolversTypes['dashboardAccountRoleType']>, ParentType, ContextType, Partial<QueryRootDashboardAccountRoleTypeArgs>>;
  dashboardAccountRoleType_by_pk?: Resolver<Maybe<ResolversTypes['dashboardAccountRoleType']>, ParentType, ContextType, RequireFields<QueryRootDashboardAccountRoleTypeByPkArgs, 'name'>>;
  dashboardAccountRole_by_pk?: Resolver<Maybe<ResolversTypes['dashboardAccountRole']>, ParentType, ContextType, RequireFields<QueryRootDashboardAccountRoleByPkArgs, 'id'>>;
  dashboardAccount_by_pk?: Resolver<Maybe<ResolversTypes['dashboardAccount']>, ParentType, ContextType, RequireFields<QueryRootDashboardAccountByPkArgs, 'id'>>;
  dashboardCurrentSaleses?: Resolver<ResolversTypes['CurrentSaleses'], ParentType, ContextType, RequireFields<QueryRootDashboardCurrentSalesesArgs, 'input'>>;
  dishUpSlipGroup?: Resolver<Array<ResolversTypes['dishUpSlipGroup']>, ParentType, ContextType, Partial<QueryRootDishUpSlipGroupArgs>>;
  dishUpSlipGroupRoles?: Resolver<Array<ResolversTypes['dishUpSlipGroupRoles']>, ParentType, ContextType, Partial<QueryRootDishUpSlipGroupRolesArgs>>;
  dishUpSlipGroupRoles_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupRoles']>, ParentType, ContextType, RequireFields<QueryRootDishUpSlipGroupRolesByPkArgs, 'id'>>;
  dishUpSlipGroupShopMenus?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopMenus']>, ParentType, ContextType, Partial<QueryRootDishUpSlipGroupShopMenusArgs>>;
  dishUpSlipGroupShopMenus_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopMenus']>, ParentType, ContextType, RequireFields<QueryRootDishUpSlipGroupShopMenusByPkArgs, 'id'>>;
  dishUpSlipGroupShopPlans?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopPlans']>, ParentType, ContextType, Partial<QueryRootDishUpSlipGroupShopPlansArgs>>;
  dishUpSlipGroupShopPlans_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupShopPlans']>, ParentType, ContextType, RequireFields<QueryRootDishUpSlipGroupShopPlansByPkArgs, 'id'>>;
  dishUpSlipGroupTables?: Resolver<Array<ResolversTypes['dishUpSlipGroupTables']>, ParentType, ContextType, Partial<QueryRootDishUpSlipGroupTablesArgs>>;
  dishUpSlipGroupTables_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroupTables']>, ParentType, ContextType, RequireFields<QueryRootDishUpSlipGroupTablesByPkArgs, 'id'>>;
  dishUpSlipGroup_by_pk?: Resolver<Maybe<ResolversTypes['dishUpSlipGroup']>, ParentType, ContextType, RequireFields<QueryRootDishUpSlipGroupByPkArgs, 'serial'>>;
  ebicaApiTables?: Resolver<Array<ResolversTypes['EbicaApiTable']>, ParentType, ContextType, RequireFields<QueryRootEbicaApiTablesArgs, 'input'>>;
  ebicaConfig?: Resolver<Array<ResolversTypes['ebicaConfig']>, ParentType, ContextType, Partial<QueryRootEbicaConfigArgs>>;
  ebicaConfig_by_pk?: Resolver<Maybe<ResolversTypes['ebicaConfig']>, ParentType, ContextType, RequireFields<QueryRootEbicaConfigByPkArgs, 'id'>>;
  ebicaTable?: Resolver<Array<ResolversTypes['ebicaTable']>, ParentType, ContextType, Partial<QueryRootEbicaTableArgs>>;
  ebicaTable_by_pk?: Resolver<Maybe<ResolversTypes['ebicaTable']>, ParentType, ContextType, RequireFields<QueryRootEbicaTableByPkArgs, 'id'>>;
  externalOnlineMenu?: Resolver<Array<ResolversTypes['externalOnlineMenu']>, ParentType, ContextType, Partial<QueryRootExternalOnlineMenuArgs>>;
  externalOnlineMenuAvailableTimeTerm?: Resolver<Array<ResolversTypes['externalOnlineMenuAvailableTimeTerm']>, ParentType, ContextType, Partial<QueryRootExternalOnlineMenuAvailableTimeTermArgs>>;
  externalOnlineMenuAvailableTimeTerm_by_pk?: Resolver<Maybe<ResolversTypes['externalOnlineMenuAvailableTimeTerm']>, ParentType, ContextType, RequireFields<QueryRootExternalOnlineMenuAvailableTimeTermByPkArgs, 'id'>>;
  externalOnlineMenuConfig?: Resolver<Array<ResolversTypes['externalOnlineMenuConfig']>, ParentType, ContextType, Partial<QueryRootExternalOnlineMenuConfigArgs>>;
  externalOnlineMenuConfig_by_pk?: Resolver<Maybe<ResolversTypes['externalOnlineMenuConfig']>, ParentType, ContextType, RequireFields<QueryRootExternalOnlineMenuConfigByPkArgs, 'id'>>;
  externalOnlineMenu_by_pk?: Resolver<Maybe<ResolversTypes['externalOnlineMenu']>, ParentType, ContextType, RequireFields<QueryRootExternalOnlineMenuByPkArgs, 'id'>>;
  faveYellAnalyticsByCorporation?: Resolver<ResolversTypes['FaveYellAnalyticsByCorporationOutput'], ParentType, ContextType, RequireFields<QueryRootFaveYellAnalyticsByCorporationArgs, 'input'>>;
  featureFlag?: Resolver<Array<ResolversTypes['featureFlag']>, ParentType, ContextType, Partial<QueryRootFeatureFlagArgs>>;
  featureFlagShop?: Resolver<Array<ResolversTypes['featureFlagShop']>, ParentType, ContextType, Partial<QueryRootFeatureFlagShopArgs>>;
  featureFlagShop_by_pk?: Resolver<Maybe<ResolversTypes['featureFlagShop']>, ParentType, ContextType, RequireFields<QueryRootFeatureFlagShopByPkArgs, 'name' | 'shopId'>>;
  featureFlag_by_pk?: Resolver<Maybe<ResolversTypes['featureFlag']>, ParentType, ContextType, RequireFields<QueryRootFeatureFlagByPkArgs, 'name'>>;
  foodingJournalConfig?: Resolver<Array<ResolversTypes['foodingJournalConfig']>, ParentType, ContextType, Partial<QueryRootFoodingJournalConfigArgs>>;
  foodingJournalConfig_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalConfig']>, ParentType, ContextType, RequireFields<QueryRootFoodingJournalConfigByPkArgs, 'id'>>;
  foodingJournalDepartmentMaster?: Resolver<Array<ResolversTypes['foodingJournalDepartmentMaster']>, ParentType, ContextType, Partial<QueryRootFoodingJournalDepartmentMasterArgs>>;
  foodingJournalDepartmentMaster_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalDepartmentMaster']>, ParentType, ContextType, RequireFields<QueryRootFoodingJournalDepartmentMasterByPkArgs, 'id'>>;
  foodingJournalGroupMaster?: Resolver<Array<ResolversTypes['foodingJournalGroupMaster']>, ParentType, ContextType, Partial<QueryRootFoodingJournalGroupMasterArgs>>;
  foodingJournalGroupMaster_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalGroupMaster']>, ParentType, ContextType, RequireFields<QueryRootFoodingJournalGroupMasterByPkArgs, 'id'>>;
  foodingJournalMenu?: Resolver<Array<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType, Partial<QueryRootFoodingJournalMenuArgs>>;
  foodingJournalMenu_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalMenu']>, ParentType, ContextType, RequireFields<QueryRootFoodingJournalMenuByPkArgs, 'id'>>;
  foodingJournalOnSitePaymentDetailType?: Resolver<Array<ResolversTypes['foodingJournalOnSitePaymentDetailType']>, ParentType, ContextType, Partial<QueryRootFoodingJournalOnSitePaymentDetailTypeArgs>>;
  foodingJournalOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<QueryRootFoodingJournalOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  foodingJournalOnSitePaymentDiscountType?: Resolver<Array<ResolversTypes['foodingJournalOnSitePaymentDiscountType']>, ParentType, ContextType, Partial<QueryRootFoodingJournalOnSitePaymentDiscountTypeArgs>>;
  foodingJournalOnSitePaymentDiscountType_by_pk?: Resolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDiscountType']>, ParentType, ContextType, RequireFields<QueryRootFoodingJournalOnSitePaymentDiscountTypeByPkArgs, 'id'>>;
  giftMeta?: Resolver<Array<ResolversTypes['giftMeta']>, ParentType, ContextType, Partial<QueryRootGiftMetaArgs>>;
  giftMeta_aggregate?: Resolver<ResolversTypes['giftMeta_aggregate'], ParentType, ContextType, Partial<QueryRootGiftMetaAggregateArgs>>;
  giftMeta_by_pk?: Resolver<Maybe<ResolversTypes['giftMeta']>, ParentType, ContextType, RequireFields<QueryRootGiftMetaByPkArgs, 'id'>>;
  giftOptionMeta?: Resolver<Array<ResolversTypes['giftOptionMeta']>, ParentType, ContextType, Partial<QueryRootGiftOptionMetaArgs>>;
  giftOptionMeta_by_pk?: Resolver<Maybe<ResolversTypes['giftOptionMeta']>, ParentType, ContextType, RequireFields<QueryRootGiftOptionMetaByPkArgs, 'id'>>;
  gmoBankAccount?: Resolver<Array<ResolversTypes['gmoBankAccount']>, ParentType, ContextType, Partial<QueryRootGmoBankAccountArgs>>;
  gmoBankAccountShop?: Resolver<Array<ResolversTypes['gmoBankAccountShop']>, ParentType, ContextType, Partial<QueryRootGmoBankAccountShopArgs>>;
  gmoBankAccountShop_by_pk?: Resolver<Maybe<ResolversTypes['gmoBankAccountShop']>, ParentType, ContextType, RequireFields<QueryRootGmoBankAccountShopByPkArgs, 'id'>>;
  gmoBankAccount_by_pk?: Resolver<Maybe<ResolversTypes['gmoBankAccount']>, ParentType, ContextType, RequireFields<QueryRootGmoBankAccountByPkArgs, 'id'>>;
  gmoOffPaymentMethodType?: Resolver<Array<ResolversTypes['gmoOffPaymentMethodType']>, ParentType, ContextType, Partial<QueryRootGmoOffPaymentMethodTypeArgs>>;
  gmoOffPaymentMethodType_by_pk?: Resolver<Maybe<ResolversTypes['gmoOffPaymentMethodType']>, ParentType, ContextType, RequireFields<QueryRootGmoOffPaymentMethodTypeByPkArgs, 'value'>>;
  gmoOffPaymentOnSitePaymentDetailType?: Resolver<Array<ResolversTypes['gmoOffPaymentOnSitePaymentDetailType']>, ParentType, ContextType, Partial<QueryRootGmoOffPaymentOnSitePaymentDetailTypeArgs>>;
  gmoOffPaymentOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['gmoOffPaymentOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<QueryRootGmoOffPaymentOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  gmoOffPaymentShopConfig?: Resolver<Array<ResolversTypes['gmoOffPaymentShopConfig']>, ParentType, ContextType, Partial<QueryRootGmoOffPaymentShopConfigArgs>>;
  gmoOffPaymentShopConfigTransactionFeeRate?: Resolver<Array<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate']>, ParentType, ContextType, Partial<QueryRootGmoOffPaymentShopConfigTransactionFeeRateArgs>>;
  gmoOffPaymentShopConfigTransactionFeeRate_by_pk?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate']>, ParentType, ContextType, RequireFields<QueryRootGmoOffPaymentShopConfigTransactionFeeRateByPkArgs, 'id'>>;
  gmoOffPaymentShopConfig_by_pk?: Resolver<Maybe<ResolversTypes['gmoOffPaymentShopConfig']>, ParentType, ContextType, RequireFields<QueryRootGmoOffPaymentShopConfigByPkArgs, 'id'>>;
  inflowSourceTag?: Resolver<Array<ResolversTypes['inflowSourceTag']>, ParentType, ContextType, Partial<QueryRootInflowSourceTagArgs>>;
  inflowSourceTag_aggregate?: Resolver<ResolversTypes['inflowSourceTag_aggregate'], ParentType, ContextType, Partial<QueryRootInflowSourceTagAggregateArgs>>;
  inflowSourceTag_by_pk?: Resolver<Maybe<ResolversTypes['inflowSourceTag']>, ParentType, ContextType, RequireFields<QueryRootInflowSourceTagByPkArgs, 'id'>>;
  itemSales?: Resolver<Array<ResolversTypes['itemSales']>, ParentType, ContextType, Partial<QueryRootItemSalesArgs>>;
  itemSales_by_pk?: Resolver<Maybe<ResolversTypes['itemSales']>, ParentType, ContextType, RequireFields<QueryRootItemSalesByPkArgs, 'id'>>;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget?: Resolver<Array<ResolversTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget']>, ParentType, ContextType, Partial<QueryRootKdDisplayItemKdDisplayTargetsKdDisplayTargetArgs>>;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_by_pk?: Resolver<Maybe<ResolversTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget']>, ParentType, ContextType, RequireFields<QueryRootKdDisplayItemKdDisplayTargetsKdDisplayTargetByPkArgs, 'id'>>;
  kdDisplayTarget?: Resolver<Array<ResolversTypes['kdDisplayTarget']>, ParentType, ContextType, Partial<QueryRootKdDisplayTargetArgs>>;
  kdDisplayTarget_by_pk?: Resolver<Maybe<ResolversTypes['kdDisplayTarget']>, ParentType, ContextType, RequireFields<QueryRootKdDisplayTargetByPkArgs, 'id'>>;
  latestCompanyCustomerSummaryByDate?: Resolver<Maybe<ResolversTypes['CompanyCustomerSummary']>, ParentType, ContextType, RequireFields<QueryRootLatestCompanyCustomerSummaryByDateArgs, 'input'>>;
  lineChannelConfig?: Resolver<Array<ResolversTypes['lineChannelConfig']>, ParentType, ContextType, Partial<QueryRootLineChannelConfigArgs>>;
  lineChannelConfig_by_pk?: Resolver<Maybe<ResolversTypes['lineChannelConfig']>, ParentType, ContextType, RequireFields<QueryRootLineChannelConfigByPkArgs, 'id'>>;
  lineOfficialAccount?: Resolver<Array<ResolversTypes['lineOfficialAccount']>, ParentType, ContextType, Partial<QueryRootLineOfficialAccountArgs>>;
  lineOfficialAccountSummaries?: Resolver<Array<ResolversTypes['LineOfficialAccountSummary']>, ParentType, ContextType, RequireFields<QueryRootLineOfficialAccountSummariesArgs, 'input'>>;
  lineOfficialAccount_by_pk?: Resolver<Maybe<ResolversTypes['lineOfficialAccount']>, ParentType, ContextType, RequireFields<QueryRootLineOfficialAccountByPkArgs, 'serial'>>;
  lineOfficialAccounts?: Resolver<Array<ResolversTypes['LineOfficialAccountProfile']>, ParentType, ContextType, RequireFields<QueryRootLineOfficialAccountsArgs, 'input'>>;
  lineProfile?: Resolver<Array<ResolversTypes['lineProfile']>, ParentType, ContextType, Partial<QueryRootLineProfileArgs>>;
  lineReportingBotConfig?: Resolver<Array<ResolversTypes['lineReportingBotConfig']>, ParentType, ContextType, Partial<QueryRootLineReportingBotConfigArgs>>;
  lineReportingBotConfigShop?: Resolver<Array<ResolversTypes['lineReportingBotConfigShop']>, ParentType, ContextType, Partial<QueryRootLineReportingBotConfigShopArgs>>;
  lineReportingBotConfigShop_by_pk?: Resolver<Maybe<ResolversTypes['lineReportingBotConfigShop']>, ParentType, ContextType, RequireFields<QueryRootLineReportingBotConfigShopByPkArgs, 'id'>>;
  lineReportingBotConfig_by_pk?: Resolver<Maybe<ResolversTypes['lineReportingBotConfig']>, ParentType, ContextType, RequireFields<QueryRootLineReportingBotConfigByPkArgs, 'id'>>;
  lineReportingBotVerification?: Resolver<Array<ResolversTypes['lineReportingBotVerification']>, ParentType, ContextType, Partial<QueryRootLineReportingBotVerificationArgs>>;
  lineReportingBotVerification_by_pk?: Resolver<Maybe<ResolversTypes['lineReportingBotVerification']>, ParentType, ContextType, RequireFields<QueryRootLineReportingBotVerificationByPkArgs, 'id'>>;
  membershipCard?: Resolver<Array<ResolversTypes['membershipCard']>, ParentType, ContextType, Partial<QueryRootMembershipCardArgs>>;
  membershipCardAppearanceType?: Resolver<Array<ResolversTypes['membershipCardAppearanceType']>, ParentType, ContextType, Partial<QueryRootMembershipCardAppearanceTypeArgs>>;
  membershipCardAppearanceType_by_pk?: Resolver<Maybe<ResolversTypes['membershipCardAppearanceType']>, ParentType, ContextType, RequireFields<QueryRootMembershipCardAppearanceTypeByPkArgs, 'value'>>;
  membershipCardTitle?: Resolver<Array<ResolversTypes['membershipCardTitle']>, ParentType, ContextType, Partial<QueryRootMembershipCardTitleArgs>>;
  membershipCardTitle_by_pk?: Resolver<Maybe<ResolversTypes['membershipCardTitle']>, ParentType, ContextType, RequireFields<QueryRootMembershipCardTitleByPkArgs, 'id'>>;
  membershipCard_by_pk?: Resolver<Maybe<ResolversTypes['membershipCard']>, ParentType, ContextType, RequireFields<QueryRootMembershipCardByPkArgs, 'id'>>;
  membershipRank?: Resolver<Array<ResolversTypes['membershipRank']>, ParentType, ContextType, Partial<QueryRootMembershipRankArgs>>;
  membershipRankConfig?: Resolver<Array<ResolversTypes['membershipRankConfig']>, ParentType, ContextType, Partial<QueryRootMembershipRankConfigArgs>>;
  membershipRankConfig_by_pk?: Resolver<Maybe<ResolversTypes['membershipRankConfig']>, ParentType, ContextType, RequireFields<QueryRootMembershipRankConfigByPkArgs, 'id'>>;
  membershipRank_by_pk?: Resolver<Maybe<ResolversTypes['membershipRank']>, ParentType, ContextType, RequireFields<QueryRootMembershipRankByPkArgs, 'value'>>;
  menu?: Resolver<Array<ResolversTypes['menu']>, ParentType, ContextType, Partial<QueryRootMenuArgs>>;
  menuOption?: Resolver<Array<ResolversTypes['menuOption']>, ParentType, ContextType, Partial<QueryRootMenuOptionArgs>>;
  menuOption_by_pk?: Resolver<Maybe<ResolversTypes['menuOption']>, ParentType, ContextType, RequireFields<QueryRootMenuOptionByPkArgs, 'id'>>;
  menuOrderItem?: Resolver<Array<ResolversTypes['menuOrderItem']>, ParentType, ContextType, Partial<QueryRootMenuOrderItemArgs>>;
  menuOrderItemAnalytics?: Resolver<ResolversTypes['MenuOrderItemAnalyticsOutput'], ParentType, ContextType, RequireFields<QueryRootMenuOrderItemAnalyticsArgs, 'input'>>;
  menuOrderItemChoice?: Resolver<Array<ResolversTypes['menuOrderItemChoice']>, ParentType, ContextType, Partial<QueryRootMenuOrderItemChoiceArgs>>;
  menuOrderItemChoice_by_pk?: Resolver<Maybe<ResolversTypes['menuOrderItemChoice']>, ParentType, ContextType, RequireFields<QueryRootMenuOrderItemChoiceByPkArgs, 'id'>>;
  menuOrderItemOption?: Resolver<Array<ResolversTypes['menuOrderItemOption']>, ParentType, ContextType, Partial<QueryRootMenuOrderItemOptionArgs>>;
  menuOrderItemOption_by_pk?: Resolver<Maybe<ResolversTypes['menuOrderItemOption']>, ParentType, ContextType, RequireFields<QueryRootMenuOrderItemOptionByPkArgs, 'id'>>;
  menuOrderItem_by_pk?: Resolver<Maybe<ResolversTypes['menuOrderItem']>, ParentType, ContextType, RequireFields<QueryRootMenuOrderItemByPkArgs, 'id'>>;
  menuRecommendationMeta?: Resolver<Array<ResolversTypes['menuRecommendationMeta']>, ParentType, ContextType, Partial<QueryRootMenuRecommendationMetaArgs>>;
  menuRecommendationMeta_by_pk?: Resolver<Maybe<ResolversTypes['menuRecommendationMeta']>, ParentType, ContextType, RequireFields<QueryRootMenuRecommendationMetaByPkArgs, 'id'>>;
  menu_by_pk?: Resolver<Maybe<ResolversTypes['menu']>, ParentType, ContextType, RequireFields<QueryRootMenuByPkArgs, 'serial'>>;
  messageDelivery?: Resolver<Array<ResolversTypes['messageDelivery']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryArgs>>;
  messageDeliveryAnalyticsRecentVisitedTableUserReport?: Resolver<ResolversTypes['MessageDeliveryAnalyticsRecentVisitedTableUsersReport'], ParentType, ContextType, RequireFields<QueryRootMessageDeliveryAnalyticsRecentVisitedTableUserReportArgs, 'input'>>;
  messageDeliveryAnalyticsSummary?: Resolver<ResolversTypes['MessageDeliveryAnalyticsSummary'], ParentType, ContextType, RequireFields<QueryRootMessageDeliveryAnalyticsSummaryArgs, 'input'>>;
  messageDeliveryAnalyticsSummaryPerMessageDelivery?: Resolver<ResolversTypes['MessageDeliveryAnalyticsSummaryPerMessageDelivery'], ParentType, ContextType, RequireFields<QueryRootMessageDeliveryAnalyticsSummaryPerMessageDeliveryArgs, 'input'>>;
  messageDeliveryAnalyticsSummaryPerShop?: Resolver<ResolversTypes['MessageDeliveryAnalyticsSummaryPerShop'], ParentType, ContextType, RequireFields<QueryRootMessageDeliveryAnalyticsSummaryPerShopArgs, 'input'>>;
  messageDeliveryCustomerList?: Resolver<Array<ResolversTypes['messageDeliveryCustomerList']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerListArgs>>;
  messageDeliveryCustomerListCustomer?: Resolver<Array<ResolversTypes['messageDeliveryCustomerListCustomer']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerListCustomerArgs>>;
  messageDeliveryCustomerListCustomer_aggregate?: Resolver<ResolversTypes['messageDeliveryCustomerListCustomer_aggregate'], ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerListCustomerAggregateArgs>>;
  messageDeliveryCustomerListCustomer_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerListCustomer']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerListCustomerByPkArgs, 'id'>>;
  messageDeliveryCustomerList_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerList']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerListByPkArgs, 'id'>>;
  messageDeliveryCustomerSegment?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegment']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentArgs>>;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentBusinessOperationHoursCondition']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentBusinessOperationHoursConditionArgs>>;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentBusinessOperationHoursCondition']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentBusinessOperationHoursConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentDayOfWeekCondition?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentDayOfWeekCondition']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentDayOfWeekConditionArgs>>;
  messageDeliveryCustomerSegmentDayOfWeekCondition_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentDayOfWeekCondition']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentDayOfWeekConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionArgs>>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentGender?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentGender']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentGenderArgs>>;
  messageDeliveryCustomerSegmentGenderCondition?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentGenderCondition']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentGenderConditionArgs>>;
  messageDeliveryCustomerSegmentGenderCondition_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentGenderCondition']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentGenderConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentGender_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentGender']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentGenderByPkArgs, 'value'>>;
  messageDeliveryCustomerSegmentMenuCondition?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentMenuCondition']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentMenuConditionArgs>>;
  messageDeliveryCustomerSegmentMenuCondition_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentMenuCondition']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentMenuConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentNumericCondition?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentNumericCondition']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentNumericConditionArgs>>;
  messageDeliveryCustomerSegmentNumericCondition_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentNumericCondition']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentNumericConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerCondition']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentQuestionnaireAnswerConditionArgs>>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerCondition']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentQuestionnaireAnswerConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeArgs>>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeByPkArgs, 'value'>>;
  messageDeliveryCustomerSegmentShopCondition?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentShopCondition']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentShopConditionArgs>>;
  messageDeliveryCustomerSegmentShopCondition_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentShopCondition']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentShopConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentUnvisitedDayCountCondition']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentUnvisitedDayCountConditionArgs>>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentUnvisitedDayCountCondition']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentUnvisitedDayCountConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition?: Resolver<Array<ResolversTypes['messageDeliveryCustomerSegmentVisitedDayCountCondition']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryCustomerSegmentVisitedDayCountConditionArgs>>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentVisitedDayCountCondition']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentVisitedDayCountConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegment_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryCustomerSegment']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryCustomerSegmentByPkArgs, 'id'>>;
  messageDeliveryJob?: Resolver<Array<ResolversTypes['messageDeliveryJob']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryJobArgs>>;
  messageDeliveryJob_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryJob']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryJobByPkArgs, 'id'>>;
  messageDeliveryMessage?: Resolver<Array<ResolversTypes['messageDeliveryMessage']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryMessageArgs>>;
  messageDeliveryMessageCouponTypeMeta?: Resolver<Array<ResolversTypes['messageDeliveryMessageCouponTypeMeta']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryMessageCouponTypeMetaArgs>>;
  messageDeliveryMessageCouponTypeMeta_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryMessageCouponTypeMeta']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryMessageCouponTypeMetaByPkArgs, 'id'>>;
  messageDeliveryMessageImageTypeMeta?: Resolver<Array<ResolversTypes['messageDeliveryMessageImageTypeMeta']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryMessageImageTypeMetaArgs>>;
  messageDeliveryMessageImageTypeMeta_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryMessageImageTypeMeta']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryMessageImageTypeMetaByPkArgs, 'id'>>;
  messageDeliveryMessageQuestionnaireTypeMeta?: Resolver<Array<ResolversTypes['messageDeliveryMessageQuestionnaireTypeMeta']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryMessageQuestionnaireTypeMetaArgs>>;
  messageDeliveryMessageQuestionnaireTypeMeta_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryMessageQuestionnaireTypeMeta']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryMessageQuestionnaireTypeMetaByPkArgs, 'id'>>;
  messageDeliveryMessageTextTypeMeta?: Resolver<Array<ResolversTypes['messageDeliveryMessageTextTypeMeta']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryMessageTextTypeMetaArgs>>;
  messageDeliveryMessageTextTypeMeta_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryMessageTextTypeMeta']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryMessageTextTypeMetaByPkArgs, 'id'>>;
  messageDeliveryMessage_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryMessage']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryMessageByPkArgs, 'id'>>;
  messageDeliveryShopJob?: Resolver<Array<ResolversTypes['messageDeliveryShopJob']>, ParentType, ContextType, Partial<QueryRootMessageDeliveryShopJobArgs>>;
  messageDeliveryShopJob_by_pk?: Resolver<Maybe<ResolversTypes['messageDeliveryShopJob']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryShopJobByPkArgs, 'id'>>;
  messageDelivery_by_pk?: Resolver<Maybe<ResolversTypes['messageDelivery']>, ParentType, ContextType, RequireFields<QueryRootMessageDeliveryByPkArgs, 'id'>>;
  monthlySalesBudget?: Resolver<Array<ResolversTypes['monthlySalesBudget']>, ParentType, ContextType, Partial<QueryRootMonthlySalesBudgetArgs>>;
  monthlySalesBudget_by_pk?: Resolver<Maybe<ResolversTypes['monthlySalesBudget']>, ParentType, ContextType, RequireFields<QueryRootMonthlySalesBudgetByPkArgs, 'id'>>;
  multipleChoiceTypeQuestionAnswer?: Resolver<Array<ResolversTypes['multipleChoiceTypeQuestionAnswer']>, ParentType, ContextType, Partial<QueryRootMultipleChoiceTypeQuestionAnswerArgs>>;
  multipleChoiceTypeQuestionAnswer_by_pk?: Resolver<Maybe<ResolversTypes['multipleChoiceTypeQuestionAnswer']>, ParentType, ContextType, RequireFields<QueryRootMultipleChoiceTypeQuestionAnswerByPkArgs, 'id'>>;
  multipleChoiceTypeQuestionChoice?: Resolver<Array<ResolversTypes['multipleChoiceTypeQuestionChoice']>, ParentType, ContextType, Partial<QueryRootMultipleChoiceTypeQuestionChoiceArgs>>;
  multipleChoiceTypeQuestionChoiceAnswer?: Resolver<Array<ResolversTypes['multipleChoiceTypeQuestionChoiceAnswer']>, ParentType, ContextType, Partial<QueryRootMultipleChoiceTypeQuestionChoiceAnswerArgs>>;
  multipleChoiceTypeQuestionChoiceAnswer_by_pk?: Resolver<Maybe<ResolversTypes['multipleChoiceTypeQuestionChoiceAnswer']>, ParentType, ContextType, RequireFields<QueryRootMultipleChoiceTypeQuestionChoiceAnswerByPkArgs, 'id'>>;
  multipleChoiceTypeQuestionMeta?: Resolver<Array<ResolversTypes['multipleChoiceTypeQuestionMeta']>, ParentType, ContextType, Partial<QueryRootMultipleChoiceTypeQuestionMetaArgs>>;
  multipleChoiceTypeQuestionMeta_by_pk?: Resolver<Maybe<ResolversTypes['multipleChoiceTypeQuestionMeta']>, ParentType, ContextType, RequireFields<QueryRootMultipleChoiceTypeQuestionMetaByPkArgs, 'id'>>;
  onSitePayment?: Resolver<Array<ResolversTypes['onSitePayment']>, ParentType, ContextType, Partial<QueryRootOnSitePaymentArgs>>;
  onSitePaymentDetail?: Resolver<Array<ResolversTypes['onSitePaymentDetail']>, ParentType, ContextType, Partial<QueryRootOnSitePaymentDetailArgs>>;
  onSitePaymentDetailType?: Resolver<Array<ResolversTypes['onSitePaymentDetailType']>, ParentType, ContextType, Partial<QueryRootOnSitePaymentDetailTypeArgs>>;
  onSitePaymentDetailTypeCategoryPriority?: Resolver<Array<ResolversTypes['onSitePaymentDetailTypeCategoryPriority']>, ParentType, ContextType, Partial<QueryRootOnSitePaymentDetailTypeCategoryPriorityArgs>>;
  onSitePaymentDetailTypeCategoryPriority_by_pk?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailTypeCategoryPriority']>, ParentType, ContextType, RequireFields<QueryRootOnSitePaymentDetailTypeCategoryPriorityByPkArgs, 'id'>>;
  onSitePaymentDetailType_aggregate?: Resolver<ResolversTypes['onSitePaymentDetailType_aggregate'], ParentType, ContextType, Partial<QueryRootOnSitePaymentDetailTypeAggregateArgs>>;
  onSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['onSitePaymentDetailType']>, ParentType, ContextType, RequireFields<QueryRootOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  onSitePaymentDetail_by_pk?: Resolver<Maybe<ResolversTypes['onSitePaymentDetail']>, ParentType, ContextType, RequireFields<QueryRootOnSitePaymentDetailByPkArgs, 'id'>>;
  onSitePaymentDiscount?: Resolver<Array<ResolversTypes['onSitePaymentDiscount']>, ParentType, ContextType, Partial<QueryRootOnSitePaymentDiscountArgs>>;
  onSitePaymentDiscountType?: Resolver<Array<ResolversTypes['onSitePaymentDiscountType']>, ParentType, ContextType, Partial<QueryRootOnSitePaymentDiscountTypeArgs>>;
  onSitePaymentDiscountType_aggregate?: Resolver<ResolversTypes['onSitePaymentDiscountType_aggregate'], ParentType, ContextType, Partial<QueryRootOnSitePaymentDiscountTypeAggregateArgs>>;
  onSitePaymentDiscountType_by_pk?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscountType']>, ParentType, ContextType, RequireFields<QueryRootOnSitePaymentDiscountTypeByPkArgs, 'id'>>;
  onSitePaymentDiscount_by_pk?: Resolver<Maybe<ResolversTypes['onSitePaymentDiscount']>, ParentType, ContextType, RequireFields<QueryRootOnSitePaymentDiscountByPkArgs, 'onSitePaymentDiscountId'>>;
  onSitePaymentSales?: Resolver<Array<ResolversTypes['onSitePaymentSales']>, ParentType, ContextType, Partial<QueryRootOnSitePaymentSalesArgs>>;
  onSitePaymentSales_by_pk?: Resolver<Maybe<ResolversTypes['onSitePaymentSales']>, ParentType, ContextType, RequireFields<QueryRootOnSitePaymentSalesByPkArgs, 'onSitePaymentSalesId'>>;
  onSitePaymentTableUsers?: Resolver<Array<ResolversTypes['onSitePaymentTableUsers']>, ParentType, ContextType, Partial<QueryRootOnSitePaymentTableUsersArgs>>;
  onSitePayment_by_pk?: Resolver<Maybe<ResolversTypes['onSitePayment']>, ParentType, ContextType, RequireFields<QueryRootOnSitePaymentByPkArgs, 'onSitePaymentId'>>;
  onlinePaymentPrinterRoleMeta?: Resolver<Array<ResolversTypes['onlinePaymentPrinterRoleMeta']>, ParentType, ContextType, Partial<QueryRootOnlinePaymentPrinterRoleMetaArgs>>;
  onlinePaymentPrinterRoleMeta_by_pk?: Resolver<Maybe<ResolversTypes['onlinePaymentPrinterRoleMeta']>, ParentType, ContextType, RequireFields<QueryRootOnlinePaymentPrinterRoleMetaByPkArgs, 'id'>>;
  onlinePaymentRoot?: Resolver<Maybe<ResolversTypes['onlinePaymentRootQuery']>, ParentType, ContextType>;
  option?: Resolver<Array<ResolversTypes['option']>, ParentType, ContextType, Partial<QueryRootOptionArgs>>;
  optionInputType?: Resolver<Array<ResolversTypes['optionInputType']>, ParentType, ContextType, Partial<QueryRootOptionInputTypeArgs>>;
  optionInputType_by_pk?: Resolver<Maybe<ResolversTypes['optionInputType']>, ParentType, ContextType, RequireFields<QueryRootOptionInputTypeByPkArgs, 'value'>>;
  option_aggregate?: Resolver<ResolversTypes['option_aggregate'], ParentType, ContextType, Partial<QueryRootOptionAggregateArgs>>;
  option_by_pk?: Resolver<Maybe<ResolversTypes['option']>, ParentType, ContextType, RequireFields<QueryRootOptionByPkArgs, 'serial'>>;
  order?: Resolver<Array<ResolversTypes['order']>, ParentType, ContextType, Partial<QueryRootOrderArgs>>;
  order_by_pk?: Resolver<Maybe<ResolversTypes['order']>, ParentType, ContextType, RequireFields<QueryRootOrderByPkArgs, 'id'>>;
  orderableTime?: Resolver<Array<ResolversTypes['orderableTime']>, ParentType, ContextType, Partial<QueryRootOrderableTimeArgs>>;
  orderableTime_by_pk?: Resolver<Maybe<ResolversTypes['orderableTime']>, ParentType, ContextType, RequireFields<QueryRootOrderableTimeByPkArgs, 'id'>>;
  payingActivePlanChoice?: Resolver<Array<ResolversTypes['payingActivePlanChoice']>, ParentType, ContextType, Partial<QueryRootPayingActivePlanChoiceArgs>>;
  payingActivePlanChoice_by_pk?: Resolver<Maybe<ResolversTypes['payingActivePlanChoice']>, ParentType, ContextType, RequireFields<QueryRootPayingActivePlanChoiceByPkArgs, 'id'>>;
  payingActivePlanOpenPriceMeta?: Resolver<Array<ResolversTypes['payingActivePlanOpenPriceMeta']>, ParentType, ContextType, Partial<QueryRootPayingActivePlanOpenPriceMetaArgs>>;
  payingActivePlanOpenPriceMeta_by_pk?: Resolver<Maybe<ResolversTypes['payingActivePlanOpenPriceMeta']>, ParentType, ContextType, RequireFields<QueryRootPayingActivePlanOpenPriceMetaByPkArgs, 'payingActivePlanOpenPriceMetaId'>>;
  payingMenuOrderItem?: Resolver<Array<ResolversTypes['payingMenuOrderItem']>, ParentType, ContextType, Partial<QueryRootPayingMenuOrderItemArgs>>;
  payingMenuOrderItem_by_pk?: Resolver<Maybe<ResolversTypes['payingMenuOrderItem']>, ParentType, ContextType, RequireFields<QueryRootPayingMenuOrderItemByPkArgs, 'id'>>;
  paymentReceiptRoleTable?: Resolver<Array<ResolversTypes['paymentReceiptRoleTable']>, ParentType, ContextType, Partial<QueryRootPaymentReceiptRoleTableArgs>>;
  pikaichiConfig?: Resolver<Array<ResolversTypes['pikaichiConfig']>, ParentType, ContextType, Partial<QueryRootPikaichiConfigArgs>>;
  pikaichiConfig_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiConfig']>, ParentType, ContextType, RequireFields<QueryRootPikaichiConfigByPkArgs, 'pikaichiConfigId'>>;
  pikaichiMenu?: Resolver<Array<ResolversTypes['pikaichiMenu']>, ParentType, ContextType, Partial<QueryRootPikaichiMenuArgs>>;
  pikaichiMenuChoice?: Resolver<Array<ResolversTypes['pikaichiMenuChoice']>, ParentType, ContextType, Partial<QueryRootPikaichiMenuChoiceArgs>>;
  pikaichiMenuChoice_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuChoice']>, ParentType, ContextType, RequireFields<QueryRootPikaichiMenuChoiceByPkArgs, 'pikaichiMenuChoiceId'>>;
  pikaichiMenuMenu?: Resolver<Array<ResolversTypes['pikaichiMenuMenu']>, ParentType, ContextType, Partial<QueryRootPikaichiMenuMenuArgs>>;
  pikaichiMenuMenu_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuMenu']>, ParentType, ContextType, RequireFields<QueryRootPikaichiMenuMenuByPkArgs, 'pikaichiMenuMenuId'>>;
  pikaichiMenuPlan?: Resolver<Array<ResolversTypes['pikaichiMenuPlan']>, ParentType, ContextType, Partial<QueryRootPikaichiMenuPlanArgs>>;
  pikaichiMenuPlanChoice?: Resolver<Array<ResolversTypes['pikaichiMenuPlanChoice']>, ParentType, ContextType, Partial<QueryRootPikaichiMenuPlanChoiceArgs>>;
  pikaichiMenuPlanChoice_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlanChoice']>, ParentType, ContextType, RequireFields<QueryRootPikaichiMenuPlanChoiceByPkArgs, 'pikaichiMenuPlanChoiceId'>>;
  pikaichiMenuPlan_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenuPlan']>, ParentType, ContextType, RequireFields<QueryRootPikaichiMenuPlanByPkArgs, 'pikaichiMenuPlanId'>>;
  pikaichiMenu_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiMenu']>, ParentType, ContextType, RequireFields<QueryRootPikaichiMenuByPkArgs, 'pikaichiMenuId'>>;
  pikaichiOnSitePaymentDetailType?: Resolver<Array<ResolversTypes['pikaichiOnSitePaymentDetailType']>, ParentType, ContextType, Partial<QueryRootPikaichiOnSitePaymentDetailTypeArgs>>;
  pikaichiOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<QueryRootPikaichiOnSitePaymentDetailTypeByPkArgs, 'pikaichiPaymentDetailTypeId'>>;
  pikaichiOnSitePaymentDiscountType?: Resolver<Array<ResolversTypes['pikaichiOnSitePaymentDiscountType']>, ParentType, ContextType, Partial<QueryRootPikaichiOnSitePaymentDiscountTypeArgs>>;
  pikaichiOnSitePaymentDiscountType_by_pk?: Resolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDiscountType']>, ParentType, ContextType, RequireFields<QueryRootPikaichiOnSitePaymentDiscountTypeByPkArgs, 'pikaichiDiscountTypeId'>>;
  ping?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  plan?: Resolver<Array<ResolversTypes['plan']>, ParentType, ContextType, Partial<QueryRootPlanArgs>>;
  planChoice?: Resolver<Array<ResolversTypes['planChoice']>, ParentType, ContextType, Partial<QueryRootPlanChoiceArgs>>;
  planChoice_aggregate?: Resolver<ResolversTypes['planChoice_aggregate'], ParentType, ContextType, Partial<QueryRootPlanChoiceAggregateArgs>>;
  planChoice_by_pk?: Resolver<Maybe<ResolversTypes['planChoice']>, ParentType, ContextType, RequireFields<QueryRootPlanChoiceByPkArgs, 'serial'>>;
  planFirstOrderCategoryMenu?: Resolver<Array<ResolversTypes['planFirstOrderCategoryMenu']>, ParentType, ContextType, Partial<QueryRootPlanFirstOrderCategoryMenuArgs>>;
  planFirstOrderCategoryMenu_by_pk?: Resolver<Maybe<ResolversTypes['planFirstOrderCategoryMenu']>, ParentType, ContextType, RequireFields<QueryRootPlanFirstOrderCategoryMenuByPkArgs, 'id'>>;
  planGroup?: Resolver<Array<ResolversTypes['planGroup']>, ParentType, ContextType, Partial<QueryRootPlanGroupArgs>>;
  planGroup_by_pk?: Resolver<Maybe<ResolversTypes['planGroup']>, ParentType, ContextType, RequireFields<QueryRootPlanGroupByPkArgs, 'serial'>>;
  planMenuCategory?: Resolver<Array<ResolversTypes['planMenuCategory']>, ParentType, ContextType, Partial<QueryRootPlanMenuCategoryArgs>>;
  planMenuCategory_by_pk?: Resolver<Maybe<ResolversTypes['planMenuCategory']>, ParentType, ContextType, RequireFields<QueryRootPlanMenuCategoryByPkArgs, 'id'>>;
  planOption?: Resolver<Array<ResolversTypes['planOption']>, ParentType, ContextType, Partial<QueryRootPlanOptionArgs>>;
  planOption_aggregate?: Resolver<ResolversTypes['planOption_aggregate'], ParentType, ContextType, Partial<QueryRootPlanOptionAggregateArgs>>;
  planOption_by_pk?: Resolver<Maybe<ResolversTypes['planOption']>, ParentType, ContextType, RequireFields<QueryRootPlanOptionByPkArgs, 'serial'>>;
  plan_by_pk?: Resolver<Maybe<ResolversTypes['plan']>, ParentType, ContextType, RequireFields<QueryRootPlanByPkArgs, 'serial'>>;
  plan_menu_categories_category?: Resolver<Array<ResolversTypes['plan_menu_categories_category']>, ParentType, ContextType, Partial<QueryRootPlanMenuCategoriesCategoryArgs>>;
  quantityAndSalesAggregationByActivePlanAndMenuOrderItem?: Resolver<ResolversTypes['ShopApiAggregationGetQuantityAndSalesAggregationOutput'], ParentType, ContextType, RequireFields<QueryRootQuantityAndSalesAggregationByActivePlanAndMenuOrderItemArgs, 'input'>>;
  quantityAndSalesAggregationByActivePlanAndMenuOrderItemByShopId?: Resolver<ResolversTypes['ShopApiAggregationGetQuantityAndSalesAggregationOutput'], ParentType, ContextType, RequireFields<QueryRootQuantityAndSalesAggregationByActivePlanAndMenuOrderItemByShopIdArgs, 'input'>>;
  quantityAndSalesAggregationByActivePlanAndMenuOrderItemForBreakingNews?: Resolver<ResolversTypes['ShopApiAggregationGetQuantityAndSalesAggregationOutput'], ParentType, ContextType>;
  quantityAndSalesAggregationByCategoryByShopId?: Resolver<ResolversTypes['ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutput'], ParentType, ContextType, RequireFields<QueryRootQuantityAndSalesAggregationByCategoryByShopIdArgs, 'input'>>;
  question?: Resolver<Array<ResolversTypes['question']>, ParentType, ContextType, Partial<QueryRootQuestionArgs>>;
  questionAnswer?: Resolver<Array<ResolversTypes['questionAnswer']>, ParentType, ContextType, Partial<QueryRootQuestionAnswerArgs>>;
  questionAnswer_by_pk?: Resolver<Maybe<ResolversTypes['questionAnswer']>, ParentType, ContextType, RequireFields<QueryRootQuestionAnswerByPkArgs, 'id'>>;
  question_by_pk?: Resolver<Maybe<ResolversTypes['question']>, ParentType, ContextType, RequireFields<QueryRootQuestionByPkArgs, 'id'>>;
  questionnaire?: Resolver<Array<ResolversTypes['questionnaire']>, ParentType, ContextType, Partial<QueryRootQuestionnaireArgs>>;
  questionnaireAnswer?: Resolver<Array<ResolversTypes['questionnaireAnswer']>, ParentType, ContextType, Partial<QueryRootQuestionnaireAnswerArgs>>;
  questionnaireAnswer_by_pk?: Resolver<Maybe<ResolversTypes['questionnaireAnswer']>, ParentType, ContextType, RequireFields<QueryRootQuestionnaireAnswerByPkArgs, 'id'>>;
  questionnaireAnswererAttributeMetrics?: Resolver<Array<ResolversTypes['QuestionnaireAnswererAttributeMetricsOutput']>, ParentType, ContextType, RequireFields<QueryRootQuestionnaireAnswererAttributeMetricsArgs, 'input'>>;
  questionnaireComments?: Resolver<Array<ResolversTypes['QuestionnaireComment']>, ParentType, ContextType, RequireFields<QueryRootQuestionnaireCommentsArgs, 'input'>>;
  questionnaireCommentsCount?: Resolver<ResolversTypes['QuestionnaireCommentCountOutput'], ParentType, ContextType, RequireFields<QueryRootQuestionnaireCommentsCountArgs, 'input'>>;
  questionnaireConfig?: Resolver<Array<ResolversTypes['questionnaireConfig']>, ParentType, ContextType, Partial<QueryRootQuestionnaireConfigArgs>>;
  questionnaireConfig_by_pk?: Resolver<Maybe<ResolversTypes['questionnaireConfig']>, ParentType, ContextType, RequireFields<QueryRootQuestionnaireConfigByPkArgs, 'id'>>;
  questionnaireMetricsPerCategory?: Resolver<ResolversTypes['QuestionnaireMetricsPerCategoryOutput'], ParentType, ContextType, RequireFields<QueryRootQuestionnaireMetricsPerCategoryArgs, 'input'>>;
  questionnaireQuestion?: Resolver<Array<ResolversTypes['questionnaireQuestion']>, ParentType, ContextType, Partial<QueryRootQuestionnaireQuestionArgs>>;
  questionnaireScoreChangesMetrics?: Resolver<ResolversTypes['QuestionnaireScoreChangesMetrics'], ParentType, ContextType, RequireFields<QueryRootQuestionnaireScoreChangesMetricsArgs, 'input'>>;
  questionnaireScoreMetrics?: Resolver<ResolversTypes['QuestionnaireScoreMetricsOutput'], ParentType, ContextType, RequireFields<QueryRootQuestionnaireScoreMetricsArgs, 'input'>>;
  questionnaire_by_pk?: Resolver<Maybe<ResolversTypes['questionnaire']>, ParentType, ContextType, RequireFields<QueryRootQuestionnaireByPkArgs, 'id'>>;
  receipt?: Resolver<Array<ResolversTypes['receipt']>, ParentType, ContextType, Partial<QueryRootReceiptArgs>>;
  receipt_by_pk?: Resolver<Maybe<ResolversTypes['receipt']>, ParentType, ContextType, RequireFields<QueryRootReceiptByPkArgs, 'receiptId'>>;
  reservationDashboardAccountAccessibleShop?: Resolver<Array<ResolversTypes['reservationDashboardAccountAccessibleShop']>, ParentType, ContextType, Partial<QueryRootReservationDashboardAccountAccessibleShopArgs>>;
  reservationDashboardAccountAccessibleShop_by_pk?: Resolver<Maybe<ResolversTypes['reservationDashboardAccountAccessibleShop']>, ParentType, ContextType, RequireFields<QueryRootReservationDashboardAccountAccessibleShopByPkArgs, 'id'>>;
  role?: Resolver<Array<ResolversTypes['role']>, ParentType, ContextType, Partial<QueryRootRoleArgs>>;
  roleTablesTable?: Resolver<Array<ResolversTypes['roleTablesTable']>, ParentType, ContextType, Partial<QueryRootRoleTablesTableArgs>>;
  role_by_pk?: Resolver<Maybe<ResolversTypes['role']>, ParentType, ContextType, RequireFields<QueryRootRoleByPkArgs, 'serial'>>;
  sales?: Resolver<ResolversTypes['Sales'], ParentType, ContextType, RequireFields<QueryRootSalesArgs, 'input'>>;
  salesAnalytics?: Resolver<ResolversTypes['SalesAnalyticsOutput'], ParentType, ContextType, RequireFields<QueryRootSalesAnalyticsArgs, 'input'>>;
  salesBreakdownByPaymentMethod?: Resolver<Array<ResolversTypes['SalesBreakdownByPaymentMethod']>, ParentType, ContextType, RequireFields<QueryRootSalesBreakdownByPaymentMethodArgs, 'input'>>;
  salesBudgetAllocationSetting?: Resolver<Array<ResolversTypes['salesBudgetAllocationSetting']>, ParentType, ContextType, Partial<QueryRootSalesBudgetAllocationSettingArgs>>;
  salesBudgetAllocationSetting_by_pk?: Resolver<Maybe<ResolversTypes['salesBudgetAllocationSetting']>, ParentType, ContextType, RequireFields<QueryRootSalesBudgetAllocationSettingByPkArgs, 'id'>>;
  salesByDayOfWeekAndHourAnalytics?: Resolver<ResolversTypes['SalesByDayOfWeekAndHourAnalyticsOutput'], ParentType, ContextType, RequireFields<QueryRootSalesByDayOfWeekAndHourAnalyticsArgs, 'input'>>;
  salesForBudget?: Resolver<ResolversTypes['SalesForBudget'], ParentType, ContextType, RequireFields<QueryRootSalesForBudgetArgs, 'input'>>;
  scoreTypeQuestionAnswer?: Resolver<Array<ResolversTypes['scoreTypeQuestionAnswer']>, ParentType, ContextType, Partial<QueryRootScoreTypeQuestionAnswerArgs>>;
  serviceAdmin?: Resolver<Array<ResolversTypes['serviceAdmin']>, ParentType, ContextType, Partial<QueryRootServiceAdminArgs>>;
  serviceAdmin_by_pk?: Resolver<Maybe<ResolversTypes['serviceAdmin']>, ParentType, ContextType, RequireFields<QueryRootServiceAdminByPkArgs, 'id'>>;
  serviceChargeConfig?: Resolver<Array<ResolversTypes['serviceChargeConfig']>, ParentType, ContextType, Partial<QueryRootServiceChargeConfigArgs>>;
  serviceChargeConfig_by_pk?: Resolver<Maybe<ResolversTypes['serviceChargeConfig']>, ParentType, ContextType, RequireFields<QueryRootServiceChargeConfigByPkArgs, 'id'>>;
  serviceChargeMeta?: Resolver<Array<ResolversTypes['serviceChargeMeta']>, ParentType, ContextType, Partial<QueryRootServiceChargeMetaArgs>>;
  serviceChargeMeta_by_pk?: Resolver<Maybe<ResolversTypes['serviceChargeMeta']>, ParentType, ContextType, RequireFields<QueryRootServiceChargeMetaByPkArgs, 'id'>>;
  shop?: Resolver<Array<ResolversTypes['shop']>, ParentType, ContextType, Partial<QueryRootShopArgs>>;
  shopBusinessOperationHour?: Resolver<Array<ResolversTypes['shopBusinessOperationHour']>, ParentType, ContextType, Partial<QueryRootShopBusinessOperationHourArgs>>;
  shopBusinessOperationHour_by_pk?: Resolver<Maybe<ResolversTypes['shopBusinessOperationHour']>, ParentType, ContextType, RequireFields<QueryRootShopBusinessOperationHourByPkArgs, 'id'>>;
  shopChoice?: Resolver<Array<ResolversTypes['shopChoice']>, ParentType, ContextType, Partial<QueryRootShopChoiceArgs>>;
  shopChoice_by_pk?: Resolver<Maybe<ResolversTypes['shopChoice']>, ParentType, ContextType, RequireFields<QueryRootShopChoiceByPkArgs, 'id'>>;
  shopClerk?: Resolver<Array<ResolversTypes['shopClerk']>, ParentType, ContextType, Partial<QueryRootShopClerkArgs>>;
  shopClerk_by_pk?: Resolver<Maybe<ResolversTypes['shopClerk']>, ParentType, ContextType, RequireFields<QueryRootShopClerkByPkArgs, '_clerkId' | 'shopId'>>;
  shopClient?: Resolver<Array<ResolversTypes['shopClient']>, ParentType, ContextType, Partial<QueryRootShopClientArgs>>;
  shopClient_by_pk?: Resolver<Maybe<ResolversTypes['shopClient']>, ParentType, ContextType, RequireFields<QueryRootShopClientByPkArgs, 'shopClientId'>>;
  shopCookingItemRole?: Resolver<Array<ResolversTypes['shopCookingItemRole']>, ParentType, ContextType, Partial<QueryRootShopCookingItemRoleArgs>>;
  shopCookingItemRole_by_pk?: Resolver<Maybe<ResolversTypes['shopCookingItemRole']>, ParentType, ContextType, RequireFields<QueryRootShopCookingItemRoleByPkArgs, 'id'>>;
  shopDiscountType?: Resolver<Array<ResolversTypes['shopDiscountType']>, ParentType, ContextType, Partial<QueryRootShopDiscountTypeArgs>>;
  shopDiscountType_by_pk?: Resolver<Maybe<ResolversTypes['shopDiscountType']>, ParentType, ContextType, RequireFields<QueryRootShopDiscountTypeByPkArgs, 'shopId' | 'type'>>;
  shopInflowSourceTag?: Resolver<Array<ResolversTypes['shopInflowSourceTag']>, ParentType, ContextType, Partial<QueryRootShopInflowSourceTagArgs>>;
  shopMember?: Resolver<Array<ResolversTypes['shopMember']>, ParentType, ContextType, Partial<QueryRootShopMemberArgs>>;
  shopMenu?: Resolver<Array<ResolversTypes['shopMenu']>, ParentType, ContextType, Partial<QueryRootShopMenuArgs>>;
  shopMenuCookingItem?: Resolver<Array<ResolversTypes['shopMenuCookingItem']>, ParentType, ContextType, Partial<QueryRootShopMenuCookingItemArgs>>;
  shopMenuKdDisplayTarget?: Resolver<Array<ResolversTypes['shopMenuKdDisplayTarget']>, ParentType, ContextType, Partial<QueryRootShopMenuKdDisplayTargetArgs>>;
  shopMenuKdDisplayTarget_by_pk?: Resolver<Maybe<ResolversTypes['shopMenuKdDisplayTarget']>, ParentType, ContextType, RequireFields<QueryRootShopMenuKdDisplayTargetByPkArgs, 'id'>>;
  shopMenuKitchenRole?: Resolver<Array<ResolversTypes['shopMenuKitchenRole']>, ParentType, ContextType, Partial<QueryRootShopMenuKitchenRoleArgs>>;
  shopMenu_by_pk?: Resolver<Maybe<ResolversTypes['shopMenu']>, ParentType, ContextType, RequireFields<QueryRootShopMenuByPkArgs, 'id'>>;
  shopOptionKitchenRole?: Resolver<Array<ResolversTypes['shopOptionKitchenRole']>, ParentType, ContextType, Partial<QueryRootShopOptionKitchenRoleArgs>>;
  shopOptionKitchenRole_by_pk?: Resolver<Maybe<ResolversTypes['shopOptionKitchenRole']>, ParentType, ContextType, RequireFields<QueryRootShopOptionKitchenRoleByPkArgs, 'id'>>;
  shopOrderableTimeTerm?: Resolver<Array<ResolversTypes['shopOrderableTimeTerm']>, ParentType, ContextType, Partial<QueryRootShopOrderableTimeTermArgs>>;
  shopOrderableTimeTerm_by_pk?: Resolver<Maybe<ResolversTypes['shopOrderableTimeTerm']>, ParentType, ContextType, RequireFields<QueryRootShopOrderableTimeTermByPkArgs, 'id'>>;
  shopPaymentType?: Resolver<Array<ResolversTypes['shopPaymentType']>, ParentType, ContextType, Partial<QueryRootShopPaymentTypeArgs>>;
  shopPaymentType_by_pk?: Resolver<Maybe<ResolversTypes['shopPaymentType']>, ParentType, ContextType, RequireFields<QueryRootShopPaymentTypeByPkArgs, 'shopId' | 'type'>>;
  shopPlan?: Resolver<Array<ResolversTypes['shopPlan']>, ParentType, ContextType, Partial<QueryRootShopPlanArgs>>;
  shopPlanChoice?: Resolver<Array<ResolversTypes['shopPlanChoice']>, ParentType, ContextType, Partial<QueryRootShopPlanChoiceArgs>>;
  shopPlanChoice_by_pk?: Resolver<Maybe<ResolversTypes['shopPlanChoice']>, ParentType, ContextType, RequireFields<QueryRootShopPlanChoiceByPkArgs, 'id'>>;
  shopPlanKitchenRole?: Resolver<Array<ResolversTypes['shopPlanKitchenRole']>, ParentType, ContextType, Partial<QueryRootShopPlanKitchenRoleArgs>>;
  shopPlanOption?: Resolver<Array<ResolversTypes['shopPlanOption']>, ParentType, ContextType, Partial<QueryRootShopPlanOptionArgs>>;
  shopPlanOptionKitchenRole?: Resolver<Array<ResolversTypes['shopPlanOptionKitchenRole']>, ParentType, ContextType, Partial<QueryRootShopPlanOptionKitchenRoleArgs>>;
  shopPlanOption_by_pk?: Resolver<Maybe<ResolversTypes['shopPlanOption']>, ParentType, ContextType, RequireFields<QueryRootShopPlanOptionByPkArgs, 'id'>>;
  shopPlan_by_pk?: Resolver<Maybe<ResolversTypes['shopPlan']>, ParentType, ContextType, RequireFields<QueryRootShopPlanByPkArgs, 'id'>>;
  shop_by_pk?: Resolver<Maybe<ResolversTypes['shop']>, ParentType, ContextType, RequireFields<QueryRootShopByPkArgs, 'shopId'>>;
  specificTimeSurchargeConfig?: Resolver<Array<ResolversTypes['specificTimeSurchargeConfig']>, ParentType, ContextType, Partial<QueryRootSpecificTimeSurchargeConfigArgs>>;
  specificTimeSurchargeConfig_by_pk?: Resolver<Maybe<ResolversTypes['specificTimeSurchargeConfig']>, ParentType, ContextType, RequireFields<QueryRootSpecificTimeSurchargeConfigByPkArgs, 'id'>>;
  specificTimeSurchargeMeta?: Resolver<Array<ResolversTypes['specificTimeSurchargeMeta']>, ParentType, ContextType, Partial<QueryRootSpecificTimeSurchargeMetaArgs>>;
  specificTimeSurchargeMeta_by_pk?: Resolver<Maybe<ResolversTypes['specificTimeSurchargeMeta']>, ParentType, ContextType, RequireFields<QueryRootSpecificTimeSurchargeMetaByPkArgs, 'id'>>;
  steraOnSitePaymentDetailType?: Resolver<Array<ResolversTypes['steraOnSitePaymentDetailType']>, ParentType, ContextType, Partial<QueryRootSteraOnSitePaymentDetailTypeArgs>>;
  steraOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['steraOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<QueryRootSteraOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  stock?: Resolver<Array<ResolversTypes['stock']>, ParentType, ContextType, Partial<QueryRootStockArgs>>;
  stock_by_pk?: Resolver<Maybe<ResolversTypes['stock']>, ParentType, ContextType, RequireFields<QueryRootStockByPkArgs, 'id'>>;
  supportedLocale?: Resolver<Array<ResolversTypes['supportedLocale']>, ParentType, ContextType, Partial<QueryRootSupportedLocaleArgs>>;
  supportedLocale_by_pk?: Resolver<Maybe<ResolversTypes['supportedLocale']>, ParentType, ContextType, RequireFields<QueryRootSupportedLocaleByPkArgs, 'value'>>;
  table?: Resolver<Array<ResolversTypes['table']>, ParentType, ContextType, Partial<QueryRootTableArgs>>;
  tableArea?: Resolver<Array<ResolversTypes['tableArea']>, ParentType, ContextType, Partial<QueryRootTableAreaArgs>>;
  tableArea_aggregate?: Resolver<ResolversTypes['tableArea_aggregate'], ParentType, ContextType, Partial<QueryRootTableAreaAggregateArgs>>;
  tableArea_by_pk?: Resolver<Maybe<ResolversTypes['tableArea']>, ParentType, ContextType, RequireFields<QueryRootTableAreaByPkArgs, 'id'>>;
  tableUser?: Resolver<Array<ResolversTypes['tableUser']>, ParentType, ContextType, Partial<QueryRootTableUserArgs>>;
  tableUserCustomer?: Resolver<Array<ResolversTypes['tableUserCustomer']>, ParentType, ContextType, Partial<QueryRootTableUserCustomerArgs>>;
  tableUserCustomer_by_pk?: Resolver<Maybe<ResolversTypes['tableUserCustomer']>, ParentType, ContextType, RequireFields<QueryRootTableUserCustomerByPkArgs, 'id'>>;
  tableUser_by_pk?: Resolver<Maybe<ResolversTypes['tableUser']>, ParentType, ContextType, RequireFields<QueryRootTableUserByPkArgs, 'id'>>;
  table_aggregate?: Resolver<ResolversTypes['table_aggregate'], ParentType, ContextType, Partial<QueryRootTableAggregateArgs>>;
  table_by_pk?: Resolver<Maybe<ResolversTypes['table']>, ParentType, ContextType, RequireFields<QueryRootTableByPkArgs, 'serial'>>;
  taxOffice?: Resolver<Array<ResolversTypes['taxOffice']>, ParentType, ContextType, Partial<QueryRootTaxOfficeArgs>>;
  taxOffice_by_pk?: Resolver<Maybe<ResolversTypes['taxOffice']>, ParentType, ContextType, RequireFields<QueryRootTaxOfficeByPkArgs, 'id'>>;
  tecAggregationConfig?: Resolver<Array<ResolversTypes['tecAggregationConfig']>, ParentType, ContextType, Partial<QueryRootTecAggregationConfigArgs>>;
  tecAggregationConfig_by_pk?: Resolver<Maybe<ResolversTypes['tecAggregationConfig']>, ParentType, ContextType, RequireFields<QueryRootTecAggregationConfigByPkArgs, 'id'>>;
  tecAggregationMediaMap?: Resolver<Array<ResolversTypes['tecAggregationMediaMap']>, ParentType, ContextType, Partial<QueryRootTecAggregationMediaMapArgs>>;
  tecAggregationMediaMap_by_pk?: Resolver<Maybe<ResolversTypes['tecAggregationMediaMap']>, ParentType, ContextType, RequireFields<QueryRootTecAggregationMediaMapByPkArgs, 'id'>>;
  tecAggregationMenu?: Resolver<Array<ResolversTypes['tecAggregationMenu']>, ParentType, ContextType, Partial<QueryRootTecAggregationMenuArgs>>;
  tecAggregationMenu_by_pk?: Resolver<Maybe<ResolversTypes['tecAggregationMenu']>, ParentType, ContextType, RequireFields<QueryRootTecAggregationMenuByPkArgs, 'id'>>;
  tecAggregationOnSitePaymentDetailType?: Resolver<Array<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, ParentType, ContextType, Partial<QueryRootTecAggregationOnSitePaymentDetailTypeArgs>>;
  tecAggregationOnSitePaymentDetailType_by_pk?: Resolver<Maybe<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, ParentType, ContextType, RequireFields<QueryRootTecAggregationOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  textTypeQuestionAnswer?: Resolver<Array<ResolversTypes['textTypeQuestionAnswer']>, ParentType, ContextType, Partial<QueryRootTextTypeQuestionAnswerArgs>>;
  translation?: Resolver<Array<ResolversTypes['translation']>, ParentType, ContextType, Partial<QueryRootTranslationArgs>>;
  translation_by_pk?: Resolver<Maybe<ResolversTypes['translation']>, ParentType, ContextType, RequireFields<QueryRootTranslationByPkArgs, 'id'>>;
  validLineOfficialAccounts?: Resolver<Array<ResolversTypes['ValidLineOfficialAccount']>, ParentType, ContextType, RequireFields<QueryRootValidLineOfficialAccountsArgs, 'input'>>;
  winboardConfig?: Resolver<Array<ResolversTypes['winboardConfig']>, ParentType, ContextType, Partial<QueryRootWinboardConfigArgs>>;
  winboardConfig_by_pk?: Resolver<Maybe<ResolversTypes['winboardConfig']>, ParentType, ContextType, RequireFields<QueryRootWinboardConfigByPkArgs, 'winboardConfigId'>>;
  winboardInflowSourceTag?: Resolver<Array<ResolversTypes['winboardInflowSourceTag']>, ParentType, ContextType, Partial<QueryRootWinboardInflowSourceTagArgs>>;
  winboardInflowSourceTag_by_pk?: Resolver<Maybe<ResolversTypes['winboardInflowSourceTag']>, ParentType, ContextType, RequireFields<QueryRootWinboardInflowSourceTagByPkArgs, 'id'>>;
  winboardMenu?: Resolver<Array<ResolversTypes['winboardMenu']>, ParentType, ContextType, Partial<QueryRootWinboardMenuArgs>>;
  winboardMenu_by_pk?: Resolver<Maybe<ResolversTypes['winboardMenu']>, ParentType, ContextType, RequireFields<QueryRootWinboardMenuByPkArgs, 'id'>>;
};

export type QuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['question'] = ResolversParentTypes['question']> = {
  category?: Resolver<ResolversTypes['questionCategory_enum'], ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['company']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  multipleChoiceTypeQuestionMeta?: Resolver<Maybe<ResolversTypes['multipleChoiceTypeQuestionMeta']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionAnswers?: Resolver<Array<ResolversTypes['questionAnswer']>, ParentType, ContextType, Partial<QuestionQuestionAnswersArgs>>;
  questionnaireQuestions?: Resolver<Array<ResolversTypes['questionnaireQuestion']>, ParentType, ContextType, Partial<QuestionQuestionnaireQuestionsArgs>>;
  required?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['questionType_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionAnswer'] = ResolversParentTypes['questionAnswer']> = {
  birthdayTypeQuestionAnswer?: Resolver<Maybe<ResolversTypes['birthdayTypeQuestionAnswer']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  multipleChoiceTypeQuestionAnswer?: Resolver<Maybe<ResolversTypes['multipleChoiceTypeQuestionAnswer']>, ParentType, ContextType>;
  multipleChoiceTypeQuestionAnswerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  question?: Resolver<ResolversTypes['question'], ParentType, ContextType>;
  questionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  questionnaireAnswer?: Resolver<ResolversTypes['questionnaireAnswer'], ParentType, ContextType>;
  scoreTypeQuestionAnswer?: Resolver<Maybe<ResolversTypes['scoreTypeQuestionAnswer']>, ParentType, ContextType>;
  textTypeQuestionAnswer?: Resolver<Maybe<ResolversTypes['textTypeQuestionAnswer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaire'] = ResolversParentTypes['questionnaire']> = {
  customerQuestionnaires?: Resolver<Array<ResolversTypes['customerQuestionnaire']>, ParentType, ContextType, Partial<QuestionnaireCustomerQuestionnairesArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionnaireAnswers?: Resolver<Array<ResolversTypes['questionnaireAnswer']>, ParentType, ContextType, Partial<QuestionnaireQuestionnaireAnswersArgs>>;
  questionnaireConfigs?: Resolver<Array<ResolversTypes['questionnaireConfig']>, ParentType, ContextType, Partial<QuestionnaireQuestionnaireConfigsArgs>>;
  questionnaireQuestions?: Resolver<Array<ResolversTypes['questionnaireQuestion']>, ParentType, ContextType, Partial<QuestionnaireQuestionnaireQuestionsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaireAnswer'] = ResolversParentTypes['questionnaireAnswer']> = {
  answeredAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['customer'], ParentType, ContextType>;
  customerQuestionnaire?: Resolver<ResolversTypes['customerQuestionnaire'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  questionAnswers?: Resolver<Array<ResolversTypes['questionAnswer']>, ParentType, ContextType, Partial<QuestionnaireAnswerQuestionAnswersArgs>>;
  questionnaire?: Resolver<Maybe<ResolversTypes['questionnaire']>, ParentType, ContextType>;
  questionnaireId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  visitedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaireConfig'] = ResolversParentTypes['questionnaireConfig']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  coupon?: Resolver<Maybe<ResolversTypes['coupon']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  questionnaire?: Resolver<ResolversTypes['questionnaire'], ParentType, ContextType>;
  questionnaireId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  rewardCouponId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaireConfig_mutation_response'] = ResolversParentTypes['questionnaireConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['questionnaireConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaireQuestion'] = ResolversParentTypes['questionnaireQuestion']> = {
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  question?: Resolver<ResolversTypes['question'], ParentType, ContextType>;
  questionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  questionnaire?: Resolver<ResolversTypes['questionnaire'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['questionnaire_mutation_response'] = ResolversParentTypes['questionnaire_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['questionnaire']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReceiptResolvers<ContextType = any, ParentType extends ResolversParentTypes['receipt'] = ResolversParentTypes['receipt']> = {
  _clerkId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _onSitePaymentId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  charge?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  clerk?: Resolver<ResolversTypes['clerk'], ParentType, ContextType>;
  clerkId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  onSitePayment?: Resolver<Maybe<ResolversTypes['onSitePayment']>, ParentType, ContextType>;
  onSitePaymentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  receiptId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationDashboardAccountAccessibleShopResolvers<ContextType = any, ParentType extends ResolversParentTypes['reservationDashboardAccountAccessibleShop'] = ResolversParentTypes['reservationDashboardAccountAccessibleShop']> = {
  dashboardAccountId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['role'] = ResolversParentTypes['role']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  dishUpSlipGroupRoles?: Resolver<Array<ResolversTypes['dishUpSlipGroupRoles']>, ParentType, ContextType, Partial<RoleDishUpSlipGroupRolesArgs>>;
  firstSpareRole?: Resolver<Maybe<ResolversTypes['role']>, ParentType, ContextType>;
  firstSpareRoleId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  kdDisplayTargets?: Resolver<Array<ResolversTypes['kdDisplayTarget']>, ParentType, ContextType, Partial<RoleKdDisplayTargetsArgs>>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentReceiptRoleTables?: Resolver<Array<ResolversTypes['paymentReceiptRoleTable']>, ParentType, ContextType, Partial<RolePaymentReceiptRoleTablesArgs>>;
  printDishUpSlipAsPrimarySlip?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  printerSound?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  removeFreeOrderFromDishUpSlip?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roleTablesTables?: Resolver<Array<ResolversTypes['roleTablesTable']>, ParentType, ContextType, Partial<RoleRoleTablesTablesArgs>>;
  roles?: Resolver<Array<ResolversTypes['role']>, ParentType, ContextType, Partial<RoleRolesArgs>>;
  rolesBySecondspareroleidShopid?: Resolver<Array<ResolversTypes['role']>, ParentType, ContextType, Partial<RoleRolesBySecondspareroleidShopidArgs>>;
  secondSpareRole?: Resolver<Maybe<ResolversTypes['role']>, ParentType, ContextType>;
  secondSpareRoleId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopCookingItemRoles?: Resolver<Array<ResolversTypes['shopCookingItemRole']>, ParentType, ContextType, Partial<RoleShopCookingItemRolesArgs>>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopMenuKitchenRoles?: Resolver<Array<ResolversTypes['shopMenuKitchenRole']>, ParentType, ContextType, Partial<RoleShopMenuKitchenRolesArgs>>;
  shopOptionKitchenRoles?: Resolver<Array<ResolversTypes['shopOptionKitchenRole']>, ParentType, ContextType, Partial<RoleShopOptionKitchenRolesArgs>>;
  shopPlanKitchenRoles?: Resolver<Array<ResolversTypes['shopPlanKitchenRole']>, ParentType, ContextType, Partial<RoleShopPlanKitchenRolesArgs>>;
  shopPlanOptionKitchenRoles?: Resolver<Array<ResolversTypes['shopPlanOptionKitchenRole']>, ParentType, ContextType, Partial<RoleShopPlanOptionKitchenRolesArgs>>;
  shouldPrintClerkCallSlip?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shouldPrintDishUpSlip?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shouldPrintPaymentReceipt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleTablesTableResolvers<ContextType = any, ParentType extends ResolversParentTypes['roleTablesTable'] = ResolversParentTypes['roleTablesTable']> = {
  role?: Resolver<Maybe<ResolversTypes['role']>, ParentType, ContextType>;
  roleId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  roleRoleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  table?: Resolver<Maybe<ResolversTypes['table']>, ParentType, ContextType>;
  tableId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tableTableId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleTablesTableMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['roleTablesTable_mutation_response'] = ResolversParentTypes['roleTablesTable_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['roleTablesTable']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['role_mutation_response'] = ResolversParentTypes['role_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['role']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesBudgetAllocationSettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['salesBudgetAllocationSetting'] = ResolversParentTypes['salesBudgetAllocationSetting']> = {
  friRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  monRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  satRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  sunRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  thuRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  tueRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  wedRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesBudgetAllocationSettingMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['salesBudgetAllocationSetting_mutation_response'] = ResolversParentTypes['salesBudgetAllocationSetting_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['salesBudgetAllocationSetting']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScoreTypeQuestionAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['scoreTypeQuestionAnswer'] = ResolversParentTypes['scoreTypeQuestionAnswer']> = {
  questionAnswer?: Resolver<Maybe<ResolversTypes['questionAnswer']>, ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceAdminResolvers<ContextType = any, ParentType extends ResolversParentTypes['serviceAdmin'] = ResolversParentTypes['serviceAdmin']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceChargeConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['serviceChargeConfig'] = ResolversParentTypes['serviceChargeConfig']> = {
  chargeRate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  serviceChargeMeta?: Resolver<ResolversTypes['serviceChargeMeta'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceChargeConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['serviceChargeConfig_mutation_response'] = ResolversParentTypes['serviceChargeConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['serviceChargeConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ServiceChargeMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['serviceChargeMeta'] = ResolversParentTypes['serviceChargeMeta']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  serviceChargeConfigs?: Resolver<Array<ResolversTypes['serviceChargeConfig']>, ParentType, ContextType, Partial<ServiceChargeMetaServiceChargeConfigsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopResolvers<ContextType = any, ParentType extends ResolversParentTypes['shop'] = ResolversParentTypes['shop']> = {
  _lineOfficialAccountId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  accountingActionType?: Resolver<Maybe<ResolversTypes['accountingActionType_enum']>, ParentType, ContextType>;
  accountingSlipImages?: Resolver<Array<ResolversTypes['accountingSlipImage']>, ParentType, ContextType, Partial<ShopAccountingSlipImagesArgs>>;
  activePlans?: Resolver<Array<ResolversTypes['activePlan']>, ParentType, ContextType, Partial<ShopActivePlansArgs>>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  cashRegisterConfig?: Resolver<Maybe<ResolversTypes['cashRegisterConfig']>, ParentType, ContextType>;
  cashRegisterSecurityLevel?: Resolver<Maybe<ResolversTypes['cashRegisterSecurityLevel_enum']>, ParentType, ContextType>;
  cashRegisterSecurityLevelByCashregistersecuritylevel?: Resolver<Maybe<ResolversTypes['cashRegisterSecurityLevel']>, ParentType, ContextType>;
  changeDateTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checkInElapsedTimeAlertThresholdMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  checkInEvents?: Resolver<Array<ResolversTypes['checkInEvent']>, ParentType, ContextType, Partial<ShopCheckInEventsArgs>>;
  clerks?: Resolver<Array<ResolversTypes['clerk']>, ParentType, ContextType, Partial<ShopClerksArgs>>;
  close?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closeCashRegisters?: Resolver<Array<ResolversTypes['closeCashRegister']>, ParentType, ContextType, Partial<ShopCloseCashRegistersArgs>>;
  coineyPayments?: Resolver<Array<ResolversTypes['coineyPayment']>, ParentType, ContextType, Partial<ShopCoineyPaymentsArgs>>;
  coineySettlements?: Resolver<Array<ResolversTypes['coineySettlement']>, ParentType, ContextType, Partial<ShopCoineySettlementsArgs>>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  connectGameConfigShops?: Resolver<Array<ResolversTypes['connectGameConfigShop']>, ParentType, ContextType, Partial<ShopConnectGameConfigShopsArgs>>;
  contactInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cookingItems?: Resolver<Array<ResolversTypes['cookingItem']>, ParentType, ContextType, Partial<ShopCookingItemsArgs>>;
  customerQuestionnaires?: Resolver<Array<ResolversTypes['customerQuestionnaire']>, ParentType, ContextType, Partial<ShopCustomerQuestionnairesArgs>>;
  dashboardAccountAccessibleShops?: Resolver<Array<ResolversTypes['dashboardAccountAccessibleShop']>, ParentType, ContextType, Partial<ShopDashboardAccountAccessibleShopsArgs>>;
  defaultTaxMethod?: Resolver<ResolversTypes['taxMethod_enum'], ParentType, ContextType>;
  defaultTaxRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  disableCallClerk?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  disableClerkCache?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  disableSelfAccountingStart?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dishUpSlipGroupShopMenus?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopMenus']>, ParentType, ContextType, Partial<ShopDishUpSlipGroupShopMenusArgs>>;
  dishUpSlipGroupShopPlans?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopPlans']>, ParentType, ContextType, Partial<ShopDishUpSlipGroupShopPlansArgs>>;
  dishUpSlipGroups?: Resolver<Array<ResolversTypes['dishUpSlipGroup']>, ParentType, ContextType, Partial<ShopDishUpSlipGroupsArgs>>;
  eligibleInvoiceIssuerRegistrationNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enableAlert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableAutoCompulsoryAppetizerOrder?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  enableAutoFractionalDiscount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableAutoPrintReceipt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableAutoResetMenuSoldOut?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableCheckInElapsedTimeAlert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableCustomerSegmentSelection?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  enableDefaultInflowSourceTag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableDisplayTaxExcludedPriceForCustomer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableGoogleMapReview?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableLatestOrderElapsedTimeAlert?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableRemark?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableReserveCashSeparation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enableTableUserGrouping?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  exTax?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  externalOnlineMenuConfigs?: Resolver<Array<ResolversTypes['externalOnlineMenuConfig']>, ParentType, ContextType, Partial<ShopExternalOnlineMenuConfigsArgs>>;
  featureFlagShops?: Resolver<Array<ResolversTypes['featureFlagShop']>, ParentType, ContextType, Partial<ShopFeatureFlagShopsArgs>>;
  foodingJournalConfig?: Resolver<Maybe<ResolversTypes['foodingJournalConfig']>, ParentType, ContextType>;
  giftOptionMeta?: Resolver<Array<ResolversTypes['giftOptionMeta']>, ParentType, ContextType, Partial<ShopGiftOptionMetaArgs>>;
  googleMapPlaceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hideFreeItemInAccountingSlip?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  iconImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  issuer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kdDisplayTargets?: Resolver<Array<ResolversTypes['kdDisplayTarget']>, ParentType, ContextType, Partial<ShopKdDisplayTargetsArgs>>;
  lastOrderText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  latestOrderElapsedTimeAlertThresholdMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lineChannelConfig?: Resolver<Maybe<ResolversTypes['lineChannelConfig']>, ParentType, ContextType>;
  lineOfficialAccount?: Resolver<Maybe<ResolversTypes['lineOfficialAccount']>, ParentType, ContextType>;
  lineOfficialAccountId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  maxNumForNumPeople?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nameEn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offlinePaymentDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDetails?: Resolver<Array<ResolversTypes['onSitePaymentDetail']>, ParentType, ContextType, Partial<ShopOnSitePaymentDetailsArgs>>;
  onSitePaymentDiscounts?: Resolver<Array<ResolversTypes['onSitePaymentDiscount']>, ParentType, ContextType, Partial<ShopOnSitePaymentDiscountsArgs>>;
  onSitePayments?: Resolver<Array<ResolversTypes['onSitePayment']>, ParentType, ContextType, Partial<ShopOnSitePaymentsArgs>>;
  onboardingHandout?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  open?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderIntervalSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  orders?: Resolver<Array<ResolversTypes['order']>, ParentType, ContextType, Partial<ShopOrdersArgs>>;
  pikaichiConfig?: Resolver<Maybe<ResolversTypes['pikaichiConfig']>, ParentType, ContextType>;
  pikaichiMenus?: Resolver<Array<ResolversTypes['pikaichiMenu']>, ParentType, ContextType, Partial<ShopPikaichiMenusArgs>>;
  pikaichiOnSitePaymentDetailTypes?: Resolver<Array<ResolversTypes['pikaichiOnSitePaymentDetailType']>, ParentType, ContextType, Partial<ShopPikaichiOnSitePaymentDetailTypesArgs>>;
  pikaichiOnSitePaymentDiscountTypes?: Resolver<Array<ResolversTypes['pikaichiOnSitePaymentDiscountType']>, ParentType, ContextType, Partial<ShopPikaichiOnSitePaymentDiscountTypesArgs>>;
  questionnaireAnswers?: Resolver<Array<ResolversTypes['questionnaireAnswer']>, ParentType, ContextType, Partial<ShopQuestionnaireAnswersArgs>>;
  roles?: Resolver<Array<ResolversTypes['role']>, ParentType, ContextType, Partial<ShopRolesArgs>>;
  serviceChargeConfig?: Resolver<Maybe<ResolversTypes['serviceChargeConfig']>, ParentType, ContextType>;
  shopBusinessOperationHours?: Resolver<Array<ResolversTypes['shopBusinessOperationHour']>, ParentType, ContextType, Partial<ShopShopBusinessOperationHoursArgs>>;
  shopChoices?: Resolver<Array<ResolversTypes['shopChoice']>, ParentType, ContextType, Partial<ShopShopChoicesArgs>>;
  shopClerks?: Resolver<Array<ResolversTypes['shopClerk']>, ParentType, ContextType, Partial<ShopShopClerksArgs>>;
  shopClients?: Resolver<Array<ResolversTypes['shopClient']>, ParentType, ContextType, Partial<ShopShopClientsArgs>>;
  shopCloseConnectionTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopCookingItemRoles?: Resolver<Array<ResolversTypes['shopCookingItemRole']>, ParentType, ContextType, Partial<ShopShopCookingItemRolesArgs>>;
  shopDiscountTypes?: Resolver<Array<ResolversTypes['shopDiscountType']>, ParentType, ContextType, Partial<ShopShopDiscountTypesArgs>>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopInflowSourceTags?: Resolver<Array<ResolversTypes['shopInflowSourceTag']>, ParentType, ContextType, Partial<ShopShopInflowSourceTagsArgs>>;
  shopMembers?: Resolver<Array<ResolversTypes['shopMember']>, ParentType, ContextType, Partial<ShopShopMembersArgs>>;
  shopMenuCookingItems?: Resolver<Array<ResolversTypes['shopMenuCookingItem']>, ParentType, ContextType, Partial<ShopShopMenuCookingItemsArgs>>;
  shopMenuKitchenRoles?: Resolver<Array<ResolversTypes['shopMenuKitchenRole']>, ParentType, ContextType, Partial<ShopShopMenuKitchenRolesArgs>>;
  shopMenus?: Resolver<Array<ResolversTypes['shopMenu']>, ParentType, ContextType, Partial<ShopShopMenusArgs>>;
  shopOptionKitchenRoles?: Resolver<Array<ResolversTypes['shopOptionKitchenRole']>, ParentType, ContextType, Partial<ShopShopOptionKitchenRolesArgs>>;
  shopOrderableTimeTerms?: Resolver<Array<ResolversTypes['shopOrderableTimeTerm']>, ParentType, ContextType, Partial<ShopShopOrderableTimeTermsArgs>>;
  shopPaymentTypes?: Resolver<Array<ResolversTypes['shopPaymentType']>, ParentType, ContextType, Partial<ShopShopPaymentTypesArgs>>;
  shopPlanChoices?: Resolver<Array<ResolversTypes['shopPlanChoice']>, ParentType, ContextType, Partial<ShopShopPlanChoicesArgs>>;
  shopPlanKitchenRoles?: Resolver<Array<ResolversTypes['shopPlanKitchenRole']>, ParentType, ContextType, Partial<ShopShopPlanKitchenRolesArgs>>;
  shopPlanOptionKitchenRoles?: Resolver<Array<ResolversTypes['shopPlanOptionKitchenRole']>, ParentType, ContextType, Partial<ShopShopPlanOptionKitchenRolesArgs>>;
  shopPlanOptions?: Resolver<Array<ResolversTypes['shopPlanOption']>, ParentType, ContextType, Partial<ShopShopPlanOptionsArgs>>;
  shopPlans?: Resolver<Array<ResolversTypes['shopPlan']>, ParentType, ContextType, Partial<ShopShopPlansArgs>>;
  shopRebootTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shouldOpenDrawerAtOnSitePaymentEveryTime?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  specificTimeSurchargeConfig?: Resolver<Maybe<ResolversTypes['specificTimeSurchargeConfig']>, ParentType, ContextType>;
  stocks?: Resolver<Array<ResolversTypes['stock']>, ParentType, ContextType, Partial<ShopStocksArgs>>;
  tableAreas?: Resolver<Array<ResolversTypes['tableArea']>, ParentType, ContextType, Partial<ShopTableAreasArgs>>;
  tableAreas_aggregate?: Resolver<ResolversTypes['tableArea_aggregate'], ParentType, ContextType, Partial<ShopTableAreasAggregateArgs>>;
  tableUsers?: Resolver<Array<ResolversTypes['tableUser']>, ParentType, ContextType, Partial<ShopTableUsersArgs>>;
  tables?: Resolver<Array<ResolversTypes['table']>, ParentType, ContextType, Partial<ShopTablesArgs>>;
  tables_aggregate?: Resolver<ResolversTypes['table_aggregate'], ParentType, ContextType, Partial<ShopTablesAggregateArgs>>;
  taxOffice?: Resolver<Maybe<ResolversTypes['taxOffice']>, ParentType, ContextType>;
  taxOfficeId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tecAggregationConfigs?: Resolver<Array<ResolversTypes['tecAggregationConfig']>, ParentType, ContextType, Partial<ShopTecAggregationConfigsArgs>>;
  tecAggregationOnSitePaymentDetailTypes?: Resolver<Array<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, ParentType, ContextType, Partial<ShopTecAggregationOnSitePaymentDetailTypesArgs>>;
  useKd?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  useKiosk?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  winboardConfig?: Resolver<Maybe<ResolversTypes['winboardConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopBusinessOperationHourResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopBusinessOperationHour'] = ResolversParentTypes['shopBusinessOperationHour']> = {
  businessOperationHourType?: Resolver<ResolversTypes['businessOperationHourType_enum'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopBusinessOperationHourMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopBusinessOperationHour_mutation_response'] = ResolversParentTypes['shopBusinessOperationHour_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopBusinessOperationHour']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopChoice'] = ResolversParentTypes['shopChoice']> = {
  _choiceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  choice?: Resolver<ResolversTypes['choice'], ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isVisibleForCustomer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isVisibleForStaff?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  stock?: Resolver<Maybe<ResolversTypes['stock']>, ParentType, ContextType>;
  stockId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopClerkResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopClerk'] = ResolversParentTypes['shopClerk']> = {
  _clerkId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  clerk?: Resolver<ResolversTypes['clerk'], ParentType, ContextType>;
  clerkId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isDisplay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopClerkMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopClerk_mutation_response'] = ResolversParentTypes['shopClerk_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopClerk']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopClient'] = ResolversParentTypes['shopClient']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopClientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopClientMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopClient_mutation_response'] = ResolversParentTypes['shopClient_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopClient']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopCookingItemRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopCookingItemRole'] = ResolversParentTypes['shopCookingItemRole']> = {
  _cookingItemId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _roleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cookingItem?: Resolver<ResolversTypes['cookingItem'], ParentType, ContextType>;
  cookingItemId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['role'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopCookingItemRoleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopCookingItemRole_mutation_response'] = ResolversParentTypes['shopCookingItemRole_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopCookingItemRole']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopDiscountTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopDiscountType'] = ResolversParentTypes['shopDiscountType']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isDisplay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  onSitePaymentDiscountType?: Resolver<ResolversTypes['onSitePaymentDiscountType'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopDiscountTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopDiscountType_mutation_response'] = ResolversParentTypes['shopDiscountType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopDiscountType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopInflowSourceTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopInflowSourceTag'] = ResolversParentTypes['shopInflowSourceTag']> = {
  inflowSourceTag?: Resolver<ResolversTypes['inflowSourceTag'], ParentType, ContextType>;
  inflowSourceTagId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isDisplay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopInflowSourceTagMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopInflowSourceTag_mutation_response'] = ResolversParentTypes['shopInflowSourceTag_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopInflowSourceTag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopMember'] = ResolversParentTypes['shopMember']> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  customer?: Resolver<ResolversTypes['customer'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopMenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopMenu'] = ResolversParentTypes['shopMenu']> = {
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dishUpSlipGroupShopMenus?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopMenus']>, ParentType, ContextType, Partial<ShopMenuDishUpSlipGroupShopMenusArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isVisibleForCustomer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isVisibleForStaff?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopMenuCookingItems?: Resolver<Array<ResolversTypes['shopMenuCookingItem']>, ParentType, ContextType, Partial<ShopMenuShopMenuCookingItemsArgs>>;
  shopMenuKdDisplayTargets?: Resolver<Array<ResolversTypes['shopMenuKdDisplayTarget']>, ParentType, ContextType, Partial<ShopMenuShopMenuKdDisplayTargetsArgs>>;
  shopMenuKitchenRoles?: Resolver<Array<ResolversTypes['shopMenuKitchenRole']>, ParentType, ContextType, Partial<ShopMenuShopMenuKitchenRolesArgs>>;
  stock?: Resolver<Maybe<ResolversTypes['stock']>, ParentType, ContextType>;
  stockId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopMenuCookingItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopMenuCookingItem'] = ResolversParentTypes['shopMenuCookingItem']> = {
  _cookingItemId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cookingItem?: Resolver<Maybe<ResolversTypes['cookingItem']>, ParentType, ContextType>;
  cookingItemId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<Maybe<ResolversTypes['shop']>, ParentType, ContextType>;
  shopCookingItemRole?: Resolver<Maybe<ResolversTypes['shopCookingItemRole']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopMenu?: Resolver<Maybe<ResolversTypes['shopMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopMenuCookingItemMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopMenuCookingItem_mutation_response'] = ResolversParentTypes['shopMenuCookingItem_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopMenuCookingItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopMenuKdDisplayTargetResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopMenuKdDisplayTarget'] = ResolversParentTypes['shopMenuKdDisplayTarget']> = {
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  kdDisplayTarget?: Resolver<Maybe<ResolversTypes['kdDisplayTarget']>, ParentType, ContextType>;
  kdDisplayTargetId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<Maybe<ResolversTypes['shop']>, ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopMenu?: Resolver<Maybe<ResolversTypes['shopMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopMenuKdDisplayTargetMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopMenuKdDisplayTarget_mutation_response'] = ResolversParentTypes['shopMenuKdDisplayTarget_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopMenuKdDisplayTarget']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopMenuKitchenRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopMenuKitchenRole'] = ResolversParentTypes['shopMenuKitchenRole']> = {
  _menuId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _roleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  menuId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['role'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopMenu?: Resolver<Maybe<ResolversTypes['shopMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopMenuKitchenRoleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopMenuKitchenRole_mutation_response'] = ResolversParentTypes['shopMenuKitchenRole_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopMenuKitchenRole']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopMenuMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopMenu_mutation_response'] = ResolversParentTypes['shopMenu_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopOptionKitchenRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopOptionKitchenRole'] = ResolversParentTypes['shopOptionKitchenRole']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  kitchenRole?: Resolver<ResolversTypes['role'], ParentType, ContextType>;
  kitchenRoleId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  option?: Resolver<ResolversTypes['option'], ParentType, ContextType>;
  optionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopOptionKitchenRoleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopOptionKitchenRole_mutation_response'] = ResolversParentTypes['shopOptionKitchenRole_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopOptionKitchenRole']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopOrderableTimeTermResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopOrderableTimeTerm'] = ResolversParentTypes['shopOrderableTimeTerm']> = {
  dayWeek?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  orderableTime?: Resolver<ResolversTypes['orderableTime'], ParentType, ContextType>;
  orderableTimeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopOrderableTimeTermMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopOrderableTimeTerm_mutation_response'] = ResolversParentTypes['shopOrderableTimeTerm_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopOrderableTimeTerm']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPaymentTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPaymentType'] = ResolversParentTypes['shopPaymentType']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isDisplay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  onSitePaymentDetailType?: Resolver<ResolversTypes['onSitePaymentDetailType'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPaymentTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPaymentType_mutation_response'] = ResolversParentTypes['shopPaymentType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopPaymentType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPlan'] = ResolversParentTypes['shopPlan']> = {
  _planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  dishUpSlipGroupShopPlans?: Resolver<Array<ResolversTypes['dishUpSlipGroupShopPlans']>, ParentType, ContextType, Partial<ShopPlanDishUpSlipGroupShopPlansArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isVisibleForCustomer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isVisibleForStaff?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['plan'], ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopPlanKitchenRoles?: Resolver<Array<ResolversTypes['shopPlanKitchenRole']>, ParentType, ContextType, Partial<ShopPlanShopPlanKitchenRolesArgs>>;
  stock?: Resolver<Maybe<ResolversTypes['stock']>, ParentType, ContextType>;
  stockByStockidShopid?: Resolver<Maybe<ResolversTypes['stock']>, ParentType, ContextType>;
  stockId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPlanChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPlanChoice'] = ResolversParentTypes['shopPlanChoice']> = {
  _planChoiceId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isVisibleForCustomer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isVisibleForStaff?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  planChoice?: Resolver<ResolversTypes['planChoice'], ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  stock?: Resolver<Maybe<ResolversTypes['stock']>, ParentType, ContextType>;
  stockId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPlanChoiceMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPlanChoice_mutation_response'] = ResolversParentTypes['shopPlanChoice_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopPlanChoice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPlanKitchenRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPlanKitchenRole'] = ResolversParentTypes['shopPlanKitchenRole']> = {
  _planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _roleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['plan'], ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['role'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopPlan?: Resolver<Maybe<ResolversTypes['shopPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPlanKitchenRoleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPlanKitchenRole_mutation_response'] = ResolversParentTypes['shopPlanKitchenRole_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopPlanKitchenRole']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPlanOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPlanOption'] = ResolversParentTypes['shopPlanOption']> = {
  _planOptionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDisplay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  planOption?: Resolver<ResolversTypes['planOption'], ParentType, ContextType>;
  planOptionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPlanOptionKitchenRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPlanOptionKitchenRole'] = ResolversParentTypes['shopPlanOptionKitchenRole']> = {
  _planOptionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  _roleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  planOption?: Resolver<ResolversTypes['planOption'], ParentType, ContextType>;
  planOptionId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['role'], ParentType, ContextType>;
  roleId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPlanOptionKitchenRoleMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPlanOptionKitchenRole_mutation_response'] = ResolversParentTypes['shopPlanOptionKitchenRole_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopPlanOptionKitchenRole']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPlanOptionMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPlanOption_mutation_response'] = ResolversParentTypes['shopPlanOption_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopPlanOption']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPlanMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shopPlan_mutation_response'] = ResolversParentTypes['shopPlan_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shopPlan']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['shop_mutation_response'] = ResolversParentTypes['shop_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['shop']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecificTimeSurchargeConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['specificTimeSurchargeConfig'] = ResolversParentTypes['specificTimeSurchargeConfig']> = {
  chargeRate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  specificTimeSurchargeMeta?: Resolver<ResolversTypes['specificTimeSurchargeMeta'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['time'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecificTimeSurchargeConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['specificTimeSurchargeConfig_mutation_response'] = ResolversParentTypes['specificTimeSurchargeConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['specificTimeSurchargeConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecificTimeSurchargeMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['specificTimeSurchargeMeta'] = ResolversParentTypes['specificTimeSurchargeMeta']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<ResolversTypes['menu'], ParentType, ContextType>;
  specificTimeSurchargeConfigs?: Resolver<Array<ResolversTypes['specificTimeSurchargeConfig']>, ParentType, ContextType, Partial<SpecificTimeSurchargeMetaSpecificTimeSurchargeConfigsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SteraOnSitePaymentDetailTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['steraOnSitePaymentDetailType'] = ResolversParentTypes['steraOnSitePaymentDetailType']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  onSitePaymentDetailType?: Resolver<ResolversTypes['onSitePaymentDetailType'], ParentType, ContextType>;
  onSitePaymentDetailTypeEntityType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDetailTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  steraPaymentMethodType?: Resolver<ResolversTypes['steraPaymentMethodType_enum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SteraOnSitePaymentDetailTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['steraOnSitePaymentDetailType_mutation_response'] = ResolversParentTypes['steraOnSitePaymentDetailType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['steraOnSitePaymentDetailType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockResolvers<ContextType = any, ParentType extends ResolversParentTypes['stock'] = ResolversParentTypes['stock']> = {
  currentStockNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  dailyStockNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopChoices?: Resolver<Array<ResolversTypes['shopChoice']>, ParentType, ContextType, Partial<StockShopChoicesArgs>>;
  shopChoicesByStockid?: Resolver<Array<ResolversTypes['shopChoice']>, ParentType, ContextType, Partial<StockShopChoicesByStockidArgs>>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  shopMenus?: Resolver<Array<ResolversTypes['shopMenu']>, ParentType, ContextType, Partial<StockShopMenusArgs>>;
  shopMenusByStockid?: Resolver<Array<ResolversTypes['shopMenu']>, ParentType, ContextType, Partial<StockShopMenusByStockidArgs>>;
  shopPlanChoices?: Resolver<Array<ResolversTypes['shopPlanChoice']>, ParentType, ContextType, Partial<StockShopPlanChoicesArgs>>;
  shopPlanChoicesByStockid?: Resolver<Array<ResolversTypes['shopPlanChoice']>, ParentType, ContextType, Partial<StockShopPlanChoicesByStockidArgs>>;
  shopPlans?: Resolver<Array<ResolversTypes['shopPlan']>, ParentType, ContextType, Partial<StockShopPlansArgs>>;
  shopPlansByStockid?: Resolver<Array<ResolversTypes['shopPlan']>, ParentType, ContextType, Partial<StockShopPlansByStockidArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StockMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['stock_mutation_response'] = ResolversParentTypes['stock_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['stock']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionRootResolvers<ContextType = any, ParentType extends ResolversParentTypes['subscription_root'] = ResolversParentTypes['subscription_root']> = {
  accountingSlipImage?: SubscriptionResolver<Array<ResolversTypes['accountingSlipImage']>, "accountingSlipImage", ParentType, ContextType, Partial<SubscriptionRootAccountingSlipImageArgs>>;
  accountingSlipImage_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['accountingSlipImage']>, "accountingSlipImage_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootAccountingSlipImageByPkArgs, 'id'>>;
  accountingSlipImage_stream?: SubscriptionResolver<Array<ResolversTypes['accountingSlipImage']>, "accountingSlipImage_stream", ParentType, ContextType, RequireFields<SubscriptionRootAccountingSlipImageStreamArgs, 'batch_size' | 'cursor'>>;
  activePlan?: SubscriptionResolver<Array<ResolversTypes['activePlan']>, "activePlan", ParentType, ContextType, Partial<SubscriptionRootActivePlanArgs>>;
  activePlanChoice?: SubscriptionResolver<Array<ResolversTypes['activePlanChoice']>, "activePlanChoice", ParentType, ContextType, Partial<SubscriptionRootActivePlanChoiceArgs>>;
  activePlanChoice_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['activePlanChoice']>, "activePlanChoice_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootActivePlanChoiceByPkArgs, 'activePlanChoiceId'>>;
  activePlanChoice_stream?: SubscriptionResolver<Array<ResolversTypes['activePlanChoice']>, "activePlanChoice_stream", ParentType, ContextType, RequireFields<SubscriptionRootActivePlanChoiceStreamArgs, 'batch_size' | 'cursor'>>;
  activePlanOpenPriceMeta?: SubscriptionResolver<Array<ResolversTypes['activePlanOpenPriceMeta']>, "activePlanOpenPriceMeta", ParentType, ContextType, Partial<SubscriptionRootActivePlanOpenPriceMetaArgs>>;
  activePlanOpenPriceMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['activePlanOpenPriceMeta']>, "activePlanOpenPriceMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootActivePlanOpenPriceMetaByPkArgs, 'id'>>;
  activePlanOpenPriceMeta_stream?: SubscriptionResolver<Array<ResolversTypes['activePlanOpenPriceMeta']>, "activePlanOpenPriceMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootActivePlanOpenPriceMetaStreamArgs, 'batch_size' | 'cursor'>>;
  activePlanOption?: SubscriptionResolver<Array<ResolversTypes['activePlanOption']>, "activePlanOption", ParentType, ContextType, Partial<SubscriptionRootActivePlanOptionArgs>>;
  activePlanOption_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['activePlanOption']>, "activePlanOption_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootActivePlanOptionByPkArgs, 'activePlanOptionId'>>;
  activePlanOption_stream?: SubscriptionResolver<Array<ResolversTypes['activePlanOption']>, "activePlanOption_stream", ParentType, ContextType, RequireFields<SubscriptionRootActivePlanOptionStreamArgs, 'batch_size' | 'cursor'>>;
  activePlan_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['activePlan']>, "activePlan_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootActivePlanByPkArgs, 'id'>>;
  activePlan_stream?: SubscriptionResolver<Array<ResolversTypes['activePlan']>, "activePlan_stream", ParentType, ContextType, RequireFields<SubscriptionRootActivePlanStreamArgs, 'batch_size' | 'cursor'>>;
  adyenPaymentReportTerminalPaymentAuthorizedEvent?: SubscriptionResolver<Array<ResolversTypes['adyenPaymentReportTerminalPaymentAuthorizedEvent']>, "adyenPaymentReportTerminalPaymentAuthorizedEvent", ParentType, ContextType, Partial<SubscriptionRootAdyenPaymentReportTerminalPaymentAuthorizedEventArgs>>;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['adyenPaymentReportTerminalPaymentAuthorizedEvent']>, "adyenPaymentReportTerminalPaymentAuthorizedEvent_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootAdyenPaymentReportTerminalPaymentAuthorizedEventByPkArgs, 'id'>>;
  adyenPaymentReportTerminalPaymentAuthorizedEvent_stream?: SubscriptionResolver<Array<ResolversTypes['adyenPaymentReportTerminalPaymentAuthorizedEvent']>, "adyenPaymentReportTerminalPaymentAuthorizedEvent_stream", ParentType, ContextType, RequireFields<SubscriptionRootAdyenPaymentReportTerminalPaymentAuthorizedEventStreamArgs, 'batch_size' | 'cursor'>>;
  adyenPaymentReportTerminalPaymentCanceledEvent?: SubscriptionResolver<Array<ResolversTypes['adyenPaymentReportTerminalPaymentCanceledEvent']>, "adyenPaymentReportTerminalPaymentCanceledEvent", ParentType, ContextType, Partial<SubscriptionRootAdyenPaymentReportTerminalPaymentCanceledEventArgs>>;
  adyenPaymentReportTerminalPaymentCanceledEvent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['adyenPaymentReportTerminalPaymentCanceledEvent']>, "adyenPaymentReportTerminalPaymentCanceledEvent_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootAdyenPaymentReportTerminalPaymentCanceledEventByPkArgs, 'id'>>;
  adyenPaymentReportTerminalPaymentCanceledEvent_stream?: SubscriptionResolver<Array<ResolversTypes['adyenPaymentReportTerminalPaymentCanceledEvent']>, "adyenPaymentReportTerminalPaymentCanceledEvent_stream", ParentType, ContextType, RequireFields<SubscriptionRootAdyenPaymentReportTerminalPaymentCanceledEventStreamArgs, 'batch_size' | 'cursor'>>;
  adyenPaymentShopConfig?: SubscriptionResolver<Array<ResolversTypes['adyenPaymentShopConfig']>, "adyenPaymentShopConfig", ParentType, ContextType, Partial<SubscriptionRootAdyenPaymentShopConfigArgs>>;
  adyenPaymentShopConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['adyenPaymentShopConfig']>, "adyenPaymentShopConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootAdyenPaymentShopConfigByPkArgs, 'id'>>;
  adyenPaymentShopConfig_stream?: SubscriptionResolver<Array<ResolversTypes['adyenPaymentShopConfig']>, "adyenPaymentShopConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootAdyenPaymentShopConfigStreamArgs, 'batch_size' | 'cursor'>>;
  adyenPaymentShopPaymentMethod?: SubscriptionResolver<Array<ResolversTypes['adyenPaymentShopPaymentMethod']>, "adyenPaymentShopPaymentMethod", ParentType, ContextType, Partial<SubscriptionRootAdyenPaymentShopPaymentMethodArgs>>;
  adyenPaymentShopPaymentMethod_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['adyenPaymentShopPaymentMethod']>, "adyenPaymentShopPaymentMethod_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootAdyenPaymentShopPaymentMethodByPkArgs, 'id'>>;
  adyenPaymentShopPaymentMethod_stream?: SubscriptionResolver<Array<ResolversTypes['adyenPaymentShopPaymentMethod']>, "adyenPaymentShopPaymentMethod_stream", ParentType, ContextType, RequireFields<SubscriptionRootAdyenPaymentShopPaymentMethodStreamArgs, 'batch_size' | 'cursor'>>;
  adyenPaymentStoreMeta?: SubscriptionResolver<Array<ResolversTypes['adyenPaymentStoreMeta']>, "adyenPaymentStoreMeta", ParentType, ContextType, Partial<SubscriptionRootAdyenPaymentStoreMetaArgs>>;
  adyenPaymentStoreMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['adyenPaymentStoreMeta']>, "adyenPaymentStoreMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootAdyenPaymentStoreMetaByPkArgs, 'id'>>;
  adyenPaymentStoreMeta_stream?: SubscriptionResolver<Array<ResolversTypes['adyenPaymentStoreMeta']>, "adyenPaymentStoreMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootAdyenPaymentStoreMetaStreamArgs, 'batch_size' | 'cursor'>>;
  adyenTerminalPaymentShopConfig?: SubscriptionResolver<Array<ResolversTypes['adyenTerminalPaymentShopConfig']>, "adyenTerminalPaymentShopConfig", ParentType, ContextType, Partial<SubscriptionRootAdyenTerminalPaymentShopConfigArgs>>;
  adyenTerminalPaymentShopConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['adyenTerminalPaymentShopConfig']>, "adyenTerminalPaymentShopConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootAdyenTerminalPaymentShopConfigByPkArgs, 'id'>>;
  adyenTerminalPaymentShopConfig_stream?: SubscriptionResolver<Array<ResolversTypes['adyenTerminalPaymentShopConfig']>, "adyenTerminalPaymentShopConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootAdyenTerminalPaymentShopConfigStreamArgs, 'batch_size' | 'cursor'>>;
  adyenTerminalPaymentShopPaymentMethod?: SubscriptionResolver<Array<ResolversTypes['adyenTerminalPaymentShopPaymentMethod']>, "adyenTerminalPaymentShopPaymentMethod", ParentType, ContextType, Partial<SubscriptionRootAdyenTerminalPaymentShopPaymentMethodArgs>>;
  adyenTerminalPaymentShopPaymentMethod_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['adyenTerminalPaymentShopPaymentMethod']>, "adyenTerminalPaymentShopPaymentMethod_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootAdyenTerminalPaymentShopPaymentMethodByPkArgs, 'id'>>;
  adyenTerminalPaymentShopPaymentMethod_stream?: SubscriptionResolver<Array<ResolversTypes['adyenTerminalPaymentShopPaymentMethod']>, "adyenTerminalPaymentShopPaymentMethod_stream", ParentType, ContextType, RequireFields<SubscriptionRootAdyenTerminalPaymentShopPaymentMethodStreamArgs, 'batch_size' | 'cursor'>>;
  analyticsSetting?: SubscriptionResolver<Array<ResolversTypes['analyticsSetting']>, "analyticsSetting", ParentType, ContextType, Partial<SubscriptionRootAnalyticsSettingArgs>>;
  analyticsSetting_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['analyticsSetting']>, "analyticsSetting_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootAnalyticsSettingByPkArgs, 'id'>>;
  analyticsSetting_stream?: SubscriptionResolver<Array<ResolversTypes['analyticsSetting']>, "analyticsSetting_stream", ParentType, ContextType, RequireFields<SubscriptionRootAnalyticsSettingStreamArgs, 'batch_size' | 'cursor'>>;
  birthdayTypeQuestionAnswer?: SubscriptionResolver<Array<ResolversTypes['birthdayTypeQuestionAnswer']>, "birthdayTypeQuestionAnswer", ParentType, ContextType, Partial<SubscriptionRootBirthdayTypeQuestionAnswerArgs>>;
  birthdayTypeQuestionAnswer_stream?: SubscriptionResolver<Array<ResolversTypes['birthdayTypeQuestionAnswer']>, "birthdayTypeQuestionAnswer_stream", ParentType, ContextType, RequireFields<SubscriptionRootBirthdayTypeQuestionAnswerStreamArgs, 'batch_size' | 'cursor'>>;
  cashRegisterConfig?: SubscriptionResolver<Array<ResolversTypes['cashRegisterConfig']>, "cashRegisterConfig", ParentType, ContextType, Partial<SubscriptionRootCashRegisterConfigArgs>>;
  cashRegisterConfig_aggregate?: SubscriptionResolver<ResolversTypes['cashRegisterConfig_aggregate'], "cashRegisterConfig_aggregate", ParentType, ContextType, Partial<SubscriptionRootCashRegisterConfigAggregateArgs>>;
  cashRegisterConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['cashRegisterConfig']>, "cashRegisterConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCashRegisterConfigByPkArgs, 'id'>>;
  cashRegisterConfig_stream?: SubscriptionResolver<Array<ResolversTypes['cashRegisterConfig']>, "cashRegisterConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootCashRegisterConfigStreamArgs, 'batch_size' | 'cursor'>>;
  cashRegisterSecurityLevel?: SubscriptionResolver<Array<ResolversTypes['cashRegisterSecurityLevel']>, "cashRegisterSecurityLevel", ParentType, ContextType, Partial<SubscriptionRootCashRegisterSecurityLevelArgs>>;
  cashRegisterSecurityLevel_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['cashRegisterSecurityLevel']>, "cashRegisterSecurityLevel_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCashRegisterSecurityLevelByPkArgs, 'value'>>;
  cashRegisterSecurityLevel_stream?: SubscriptionResolver<Array<ResolversTypes['cashRegisterSecurityLevel']>, "cashRegisterSecurityLevel_stream", ParentType, ContextType, RequireFields<SubscriptionRootCashRegisterSecurityLevelStreamArgs, 'batch_size' | 'cursor'>>;
  category?: SubscriptionResolver<Array<ResolversTypes['category']>, "category", ParentType, ContextType, Partial<SubscriptionRootCategoryArgs>>;
  categoryMenu?: SubscriptionResolver<Array<ResolversTypes['categoryMenu']>, "categoryMenu", ParentType, ContextType, Partial<SubscriptionRootCategoryMenuArgs>>;
  categoryMenu_aggregate?: SubscriptionResolver<ResolversTypes['categoryMenu_aggregate'], "categoryMenu_aggregate", ParentType, ContextType, Partial<SubscriptionRootCategoryMenuAggregateArgs>>;
  categoryMenu_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['categoryMenu']>, "categoryMenu_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCategoryMenuByPkArgs, 'serial'>>;
  categoryMenu_stream?: SubscriptionResolver<Array<ResolversTypes['categoryMenu']>, "categoryMenu_stream", ParentType, ContextType, RequireFields<SubscriptionRootCategoryMenuStreamArgs, 'batch_size' | 'cursor'>>;
  category_aggregate?: SubscriptionResolver<ResolversTypes['category_aggregate'], "category_aggregate", ParentType, ContextType, Partial<SubscriptionRootCategoryAggregateArgs>>;
  category_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['category']>, "category_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCategoryByPkArgs, 'serial'>>;
  category_stream?: SubscriptionResolver<Array<ResolversTypes['category']>, "category_stream", ParentType, ContextType, RequireFields<SubscriptionRootCategoryStreamArgs, 'batch_size' | 'cursor'>>;
  checkInEvent?: SubscriptionResolver<Array<ResolversTypes['checkInEvent']>, "checkInEvent", ParentType, ContextType, Partial<SubscriptionRootCheckInEventArgs>>;
  checkInEvent_stream?: SubscriptionResolver<Array<ResolversTypes['checkInEvent']>, "checkInEvent_stream", ParentType, ContextType, RequireFields<SubscriptionRootCheckInEventStreamArgs, 'batch_size' | 'cursor'>>;
  choice?: SubscriptionResolver<Array<ResolversTypes['choice']>, "choice", ParentType, ContextType, Partial<SubscriptionRootChoiceArgs>>;
  choice_aggregate?: SubscriptionResolver<ResolversTypes['choice_aggregate'], "choice_aggregate", ParentType, ContextType, Partial<SubscriptionRootChoiceAggregateArgs>>;
  choice_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['choice']>, "choice_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootChoiceByPkArgs, 'serial'>>;
  choice_stream?: SubscriptionResolver<Array<ResolversTypes['choice']>, "choice_stream", ParentType, ContextType, RequireFields<SubscriptionRootChoiceStreamArgs, 'batch_size' | 'cursor'>>;
  clerk?: SubscriptionResolver<Array<ResolversTypes['clerk']>, "clerk", ParentType, ContextType, Partial<SubscriptionRootClerkArgs>>;
  clerk_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['clerk']>, "clerk_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootClerkByPkArgs, 'serial'>>;
  clerk_stream?: SubscriptionResolver<Array<ResolversTypes['clerk']>, "clerk_stream", ParentType, ContextType, RequireFields<SubscriptionRootClerkStreamArgs, 'batch_size' | 'cursor'>>;
  closeCashRegister?: SubscriptionResolver<Array<ResolversTypes['closeCashRegister']>, "closeCashRegister", ParentType, ContextType, Partial<SubscriptionRootCloseCashRegisterArgs>>;
  closeCashRegister_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['closeCashRegister']>, "closeCashRegister_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCloseCashRegisterByPkArgs, 'id'>>;
  closeCashRegister_stream?: SubscriptionResolver<Array<ResolversTypes['closeCashRegister']>, "closeCashRegister_stream", ParentType, ContextType, RequireFields<SubscriptionRootCloseCashRegisterStreamArgs, 'batch_size' | 'cursor'>>;
  coineyPayment?: SubscriptionResolver<Array<ResolversTypes['coineyPayment']>, "coineyPayment", ParentType, ContextType, Partial<SubscriptionRootCoineyPaymentArgs>>;
  coineyPaymentOrder?: SubscriptionResolver<Array<ResolversTypes['coineyPaymentOrder']>, "coineyPaymentOrder", ParentType, ContextType, Partial<SubscriptionRootCoineyPaymentOrderArgs>>;
  coineyPaymentOrder_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['coineyPaymentOrder']>, "coineyPaymentOrder_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCoineyPaymentOrderByPkArgs, 'coineyPaymentOrderId' | 'orderId'>>;
  coineyPaymentOrder_stream?: SubscriptionResolver<Array<ResolversTypes['coineyPaymentOrder']>, "coineyPaymentOrder_stream", ParentType, ContextType, RequireFields<SubscriptionRootCoineyPaymentOrderStreamArgs, 'batch_size' | 'cursor'>>;
  coineyPaymentSales?: SubscriptionResolver<Array<ResolversTypes['coineyPaymentSales']>, "coineyPaymentSales", ParentType, ContextType, Partial<SubscriptionRootCoineyPaymentSalesArgs>>;
  coineyPaymentSales_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['coineyPaymentSales']>, "coineyPaymentSales_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCoineyPaymentSalesByPkArgs, 'id'>>;
  coineyPaymentSales_stream?: SubscriptionResolver<Array<ResolversTypes['coineyPaymentSales']>, "coineyPaymentSales_stream", ParentType, ContextType, RequireFields<SubscriptionRootCoineyPaymentSalesStreamArgs, 'batch_size' | 'cursor'>>;
  coineyPayment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['coineyPayment']>, "coineyPayment_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCoineyPaymentByPkArgs, 'serial'>>;
  coineyPayment_stream?: SubscriptionResolver<Array<ResolversTypes['coineyPayment']>, "coineyPayment_stream", ParentType, ContextType, RequireFields<SubscriptionRootCoineyPaymentStreamArgs, 'batch_size' | 'cursor'>>;
  coineySettlement?: SubscriptionResolver<Array<ResolversTypes['coineySettlement']>, "coineySettlement", ParentType, ContextType, Partial<SubscriptionRootCoineySettlementArgs>>;
  coineySettlement_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['coineySettlement']>, "coineySettlement_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCoineySettlementByPkArgs, 'id'>>;
  coineySettlement_stream?: SubscriptionResolver<Array<ResolversTypes['coineySettlement']>, "coineySettlement_stream", ParentType, ContextType, RequireFields<SubscriptionRootCoineySettlementStreamArgs, 'batch_size' | 'cursor'>>;
  company?: SubscriptionResolver<Array<ResolversTypes['company']>, "company", ParentType, ContextType, Partial<SubscriptionRootCompanyArgs>>;
  companyMember?: SubscriptionResolver<Array<ResolversTypes['companyMember']>, "companyMember", ParentType, ContextType, Partial<SubscriptionRootCompanyMemberArgs>>;
  companyMember_aggregate?: SubscriptionResolver<ResolversTypes['companyMember_aggregate'], "companyMember_aggregate", ParentType, ContextType, Partial<SubscriptionRootCompanyMemberAggregateArgs>>;
  companyMember_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['companyMember']>, "companyMember_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCompanyMemberByPkArgs, 'id'>>;
  companyMember_stream?: SubscriptionResolver<Array<ResolversTypes['companyMember']>, "companyMember_stream", ParentType, ContextType, RequireFields<SubscriptionRootCompanyMemberStreamArgs, 'batch_size' | 'cursor'>>;
  company_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['company']>, "company_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCompanyByPkArgs, 'serial'>>;
  company_stream?: SubscriptionResolver<Array<ResolversTypes['company']>, "company_stream", ParentType, ContextType, RequireFields<SubscriptionRootCompanyStreamArgs, 'batch_size' | 'cursor'>>;
  compulsoryAppetizerConfig?: SubscriptionResolver<Array<ResolversTypes['compulsoryAppetizerConfig']>, "compulsoryAppetizerConfig", ParentType, ContextType, Partial<SubscriptionRootCompulsoryAppetizerConfigArgs>>;
  compulsoryAppetizerConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['compulsoryAppetizerConfig']>, "compulsoryAppetizerConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCompulsoryAppetizerConfigByPkArgs, 'id'>>;
  compulsoryAppetizerConfig_stream?: SubscriptionResolver<Array<ResolversTypes['compulsoryAppetizerConfig']>, "compulsoryAppetizerConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootCompulsoryAppetizerConfigStreamArgs, 'batch_size' | 'cursor'>>;
  connectGameConfig?: SubscriptionResolver<Array<ResolversTypes['connectGameConfig']>, "connectGameConfig", ParentType, ContextType, Partial<SubscriptionRootConnectGameConfigArgs>>;
  connectGameConfigShop?: SubscriptionResolver<Array<ResolversTypes['connectGameConfigShop']>, "connectGameConfigShop", ParentType, ContextType, Partial<SubscriptionRootConnectGameConfigShopArgs>>;
  connectGameConfigShop_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['connectGameConfigShop']>, "connectGameConfigShop_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootConnectGameConfigShopByPkArgs, 'id'>>;
  connectGameConfigShop_stream?: SubscriptionResolver<Array<ResolversTypes['connectGameConfigShop']>, "connectGameConfigShop_stream", ParentType, ContextType, RequireFields<SubscriptionRootConnectGameConfigShopStreamArgs, 'batch_size' | 'cursor'>>;
  connectGameConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['connectGameConfig']>, "connectGameConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootConnectGameConfigByPkArgs, 'id'>>;
  connectGameConfig_stream?: SubscriptionResolver<Array<ResolversTypes['connectGameConfig']>, "connectGameConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootConnectGameConfigStreamArgs, 'batch_size' | 'cursor'>>;
  cookingItem?: SubscriptionResolver<Array<ResolversTypes['cookingItem']>, "cookingItem", ParentType, ContextType, Partial<SubscriptionRootCookingItemArgs>>;
  cookingItem_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['cookingItem']>, "cookingItem_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCookingItemByPkArgs, 'serial'>>;
  cookingItem_stream?: SubscriptionResolver<Array<ResolversTypes['cookingItem']>, "cookingItem_stream", ParentType, ContextType, RequireFields<SubscriptionRootCookingItemStreamArgs, 'batch_size' | 'cursor'>>;
  corporation?: SubscriptionResolver<Array<ResolversTypes['corporation']>, "corporation", ParentType, ContextType, Partial<SubscriptionRootCorporationArgs>>;
  corporation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['corporation']>, "corporation_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCorporationByPkArgs, 'id'>>;
  corporation_stream?: SubscriptionResolver<Array<ResolversTypes['corporation']>, "corporation_stream", ParentType, ContextType, RequireFields<SubscriptionRootCorporationStreamArgs, 'batch_size' | 'cursor'>>;
  coupon?: SubscriptionResolver<Array<ResolversTypes['coupon']>, "coupon", ParentType, ContextType, Partial<SubscriptionRootCouponArgs>>;
  couponAppliedEvent?: SubscriptionResolver<Array<ResolversTypes['couponAppliedEvent']>, "couponAppliedEvent", ParentType, ContextType, Partial<SubscriptionRootCouponAppliedEventArgs>>;
  couponAppliedEvent_aggregate?: SubscriptionResolver<ResolversTypes['couponAppliedEvent_aggregate'], "couponAppliedEvent_aggregate", ParentType, ContextType, Partial<SubscriptionRootCouponAppliedEventAggregateArgs>>;
  couponAppliedEvent_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['couponAppliedEvent']>, "couponAppliedEvent_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCouponAppliedEventByPkArgs, 'id'>>;
  couponAppliedEvent_stream?: SubscriptionResolver<Array<ResolversTypes['couponAppliedEvent']>, "couponAppliedEvent_stream", ParentType, ContextType, RequireFields<SubscriptionRootCouponAppliedEventStreamArgs, 'batch_size' | 'cursor'>>;
  couponMenuDiscount?: SubscriptionResolver<Array<ResolversTypes['couponMenuDiscount']>, "couponMenuDiscount", ParentType, ContextType, Partial<SubscriptionRootCouponMenuDiscountArgs>>;
  couponMenuDiscount_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['couponMenuDiscount']>, "couponMenuDiscount_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCouponMenuDiscountByPkArgs, 'id'>>;
  couponMenuDiscount_stream?: SubscriptionResolver<Array<ResolversTypes['couponMenuDiscount']>, "couponMenuDiscount_stream", ParentType, ContextType, RequireFields<SubscriptionRootCouponMenuDiscountStreamArgs, 'batch_size' | 'cursor'>>;
  coupon_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['coupon']>, "coupon_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCouponByPkArgs, 'id'>>;
  coupon_stream?: SubscriptionResolver<Array<ResolversTypes['coupon']>, "coupon_stream", ParentType, ContextType, RequireFields<SubscriptionRootCouponStreamArgs, 'batch_size' | 'cursor'>>;
  customer?: SubscriptionResolver<Array<ResolversTypes['customer']>, "customer", ParentType, ContextType, Partial<SubscriptionRootCustomerArgs>>;
  customerQuestionnaire?: SubscriptionResolver<Array<ResolversTypes['customerQuestionnaire']>, "customerQuestionnaire", ParentType, ContextType, Partial<SubscriptionRootCustomerQuestionnaireArgs>>;
  customerQuestionnaire_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['customerQuestionnaire']>, "customerQuestionnaire_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCustomerQuestionnaireByPkArgs, 'id'>>;
  customerQuestionnaire_stream?: SubscriptionResolver<Array<ResolversTypes['customerQuestionnaire']>, "customerQuestionnaire_stream", ParentType, ContextType, RequireFields<SubscriptionRootCustomerQuestionnaireStreamArgs, 'batch_size' | 'cursor'>>;
  customer_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['customer']>, "customer_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootCustomerByPkArgs, 'id'>>;
  customer_stream?: SubscriptionResolver<Array<ResolversTypes['customer']>, "customer_stream", ParentType, ContextType, RequireFields<SubscriptionRootCustomerStreamArgs, 'batch_size' | 'cursor'>>;
  dailySalesBudget?: SubscriptionResolver<Array<ResolversTypes['dailySalesBudget']>, "dailySalesBudget", ParentType, ContextType, Partial<SubscriptionRootDailySalesBudgetArgs>>;
  dailySalesBudget_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dailySalesBudget']>, "dailySalesBudget_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDailySalesBudgetByPkArgs, 'id'>>;
  dailySalesBudget_stream?: SubscriptionResolver<Array<ResolversTypes['dailySalesBudget']>, "dailySalesBudget_stream", ParentType, ContextType, RequireFields<SubscriptionRootDailySalesBudgetStreamArgs, 'batch_size' | 'cursor'>>;
  dashboardAccount?: SubscriptionResolver<Array<ResolversTypes['dashboardAccount']>, "dashboardAccount", ParentType, ContextType, Partial<SubscriptionRootDashboardAccountArgs>>;
  dashboardAccountAccessibleCompany?: SubscriptionResolver<Array<ResolversTypes['dashboardAccountAccessibleCompany']>, "dashboardAccountAccessibleCompany", ParentType, ContextType, Partial<SubscriptionRootDashboardAccountAccessibleCompanyArgs>>;
  dashboardAccountAccessibleCompany_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dashboardAccountAccessibleCompany']>, "dashboardAccountAccessibleCompany_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountAccessibleCompanyByPkArgs, 'id'>>;
  dashboardAccountAccessibleCompany_stream?: SubscriptionResolver<Array<ResolversTypes['dashboardAccountAccessibleCompany']>, "dashboardAccountAccessibleCompany_stream", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountAccessibleCompanyStreamArgs, 'batch_size' | 'cursor'>>;
  dashboardAccountAccessibleShop?: SubscriptionResolver<Array<ResolversTypes['dashboardAccountAccessibleShop']>, "dashboardAccountAccessibleShop", ParentType, ContextType, Partial<SubscriptionRootDashboardAccountAccessibleShopArgs>>;
  dashboardAccountAccessibleShop_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dashboardAccountAccessibleShop']>, "dashboardAccountAccessibleShop_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountAccessibleShopByPkArgs, 'id'>>;
  dashboardAccountAccessibleShop_stream?: SubscriptionResolver<Array<ResolversTypes['dashboardAccountAccessibleShop']>, "dashboardAccountAccessibleShop_stream", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountAccessibleShopStreamArgs, 'batch_size' | 'cursor'>>;
  dashboardAccountRole?: SubscriptionResolver<Array<ResolversTypes['dashboardAccountRole']>, "dashboardAccountRole", ParentType, ContextType, Partial<SubscriptionRootDashboardAccountRoleArgs>>;
  dashboardAccountRolePermission?: SubscriptionResolver<Array<ResolversTypes['dashboardAccountRolePermission']>, "dashboardAccountRolePermission", ParentType, ContextType, Partial<SubscriptionRootDashboardAccountRolePermissionArgs>>;
  dashboardAccountRolePermission_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dashboardAccountRolePermission']>, "dashboardAccountRolePermission_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountRolePermissionByPkArgs, 'id'>>;
  dashboardAccountRolePermission_stream?: SubscriptionResolver<Array<ResolversTypes['dashboardAccountRolePermission']>, "dashboardAccountRolePermission_stream", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountRolePermissionStreamArgs, 'batch_size' | 'cursor'>>;
  dashboardAccountRoleType?: SubscriptionResolver<Array<ResolversTypes['dashboardAccountRoleType']>, "dashboardAccountRoleType", ParentType, ContextType, Partial<SubscriptionRootDashboardAccountRoleTypeArgs>>;
  dashboardAccountRoleType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dashboardAccountRoleType']>, "dashboardAccountRoleType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountRoleTypeByPkArgs, 'name'>>;
  dashboardAccountRoleType_stream?: SubscriptionResolver<Array<ResolversTypes['dashboardAccountRoleType']>, "dashboardAccountRoleType_stream", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountRoleTypeStreamArgs, 'batch_size' | 'cursor'>>;
  dashboardAccountRole_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dashboardAccountRole']>, "dashboardAccountRole_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountRoleByPkArgs, 'id'>>;
  dashboardAccountRole_stream?: SubscriptionResolver<Array<ResolversTypes['dashboardAccountRole']>, "dashboardAccountRole_stream", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountRoleStreamArgs, 'batch_size' | 'cursor'>>;
  dashboardAccount_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dashboardAccount']>, "dashboardAccount_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountByPkArgs, 'id'>>;
  dashboardAccount_stream?: SubscriptionResolver<Array<ResolversTypes['dashboardAccount']>, "dashboardAccount_stream", ParentType, ContextType, RequireFields<SubscriptionRootDashboardAccountStreamArgs, 'batch_size' | 'cursor'>>;
  dishUpSlipGroup?: SubscriptionResolver<Array<ResolversTypes['dishUpSlipGroup']>, "dishUpSlipGroup", ParentType, ContextType, Partial<SubscriptionRootDishUpSlipGroupArgs>>;
  dishUpSlipGroupRoles?: SubscriptionResolver<Array<ResolversTypes['dishUpSlipGroupRoles']>, "dishUpSlipGroupRoles", ParentType, ContextType, Partial<SubscriptionRootDishUpSlipGroupRolesArgs>>;
  dishUpSlipGroupRoles_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dishUpSlipGroupRoles']>, "dishUpSlipGroupRoles_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDishUpSlipGroupRolesByPkArgs, 'id'>>;
  dishUpSlipGroupRoles_stream?: SubscriptionResolver<Array<ResolversTypes['dishUpSlipGroupRoles']>, "dishUpSlipGroupRoles_stream", ParentType, ContextType, RequireFields<SubscriptionRootDishUpSlipGroupRolesStreamArgs, 'batch_size' | 'cursor'>>;
  dishUpSlipGroupShopMenus?: SubscriptionResolver<Array<ResolversTypes['dishUpSlipGroupShopMenus']>, "dishUpSlipGroupShopMenus", ParentType, ContextType, Partial<SubscriptionRootDishUpSlipGroupShopMenusArgs>>;
  dishUpSlipGroupShopMenus_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dishUpSlipGroupShopMenus']>, "dishUpSlipGroupShopMenus_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDishUpSlipGroupShopMenusByPkArgs, 'id'>>;
  dishUpSlipGroupShopMenus_stream?: SubscriptionResolver<Array<ResolversTypes['dishUpSlipGroupShopMenus']>, "dishUpSlipGroupShopMenus_stream", ParentType, ContextType, RequireFields<SubscriptionRootDishUpSlipGroupShopMenusStreamArgs, 'batch_size' | 'cursor'>>;
  dishUpSlipGroupShopPlans?: SubscriptionResolver<Array<ResolversTypes['dishUpSlipGroupShopPlans']>, "dishUpSlipGroupShopPlans", ParentType, ContextType, Partial<SubscriptionRootDishUpSlipGroupShopPlansArgs>>;
  dishUpSlipGroupShopPlans_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dishUpSlipGroupShopPlans']>, "dishUpSlipGroupShopPlans_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDishUpSlipGroupShopPlansByPkArgs, 'id'>>;
  dishUpSlipGroupShopPlans_stream?: SubscriptionResolver<Array<ResolversTypes['dishUpSlipGroupShopPlans']>, "dishUpSlipGroupShopPlans_stream", ParentType, ContextType, RequireFields<SubscriptionRootDishUpSlipGroupShopPlansStreamArgs, 'batch_size' | 'cursor'>>;
  dishUpSlipGroupTables?: SubscriptionResolver<Array<ResolversTypes['dishUpSlipGroupTables']>, "dishUpSlipGroupTables", ParentType, ContextType, Partial<SubscriptionRootDishUpSlipGroupTablesArgs>>;
  dishUpSlipGroupTables_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dishUpSlipGroupTables']>, "dishUpSlipGroupTables_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDishUpSlipGroupTablesByPkArgs, 'id'>>;
  dishUpSlipGroupTables_stream?: SubscriptionResolver<Array<ResolversTypes['dishUpSlipGroupTables']>, "dishUpSlipGroupTables_stream", ParentType, ContextType, RequireFields<SubscriptionRootDishUpSlipGroupTablesStreamArgs, 'batch_size' | 'cursor'>>;
  dishUpSlipGroup_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['dishUpSlipGroup']>, "dishUpSlipGroup_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootDishUpSlipGroupByPkArgs, 'serial'>>;
  dishUpSlipGroup_stream?: SubscriptionResolver<Array<ResolversTypes['dishUpSlipGroup']>, "dishUpSlipGroup_stream", ParentType, ContextType, RequireFields<SubscriptionRootDishUpSlipGroupStreamArgs, 'batch_size' | 'cursor'>>;
  ebicaConfig?: SubscriptionResolver<Array<ResolversTypes['ebicaConfig']>, "ebicaConfig", ParentType, ContextType, Partial<SubscriptionRootEbicaConfigArgs>>;
  ebicaConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ebicaConfig']>, "ebicaConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootEbicaConfigByPkArgs, 'id'>>;
  ebicaConfig_stream?: SubscriptionResolver<Array<ResolversTypes['ebicaConfig']>, "ebicaConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootEbicaConfigStreamArgs, 'batch_size' | 'cursor'>>;
  ebicaTable?: SubscriptionResolver<Array<ResolversTypes['ebicaTable']>, "ebicaTable", ParentType, ContextType, Partial<SubscriptionRootEbicaTableArgs>>;
  ebicaTable_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['ebicaTable']>, "ebicaTable_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootEbicaTableByPkArgs, 'id'>>;
  ebicaTable_stream?: SubscriptionResolver<Array<ResolversTypes['ebicaTable']>, "ebicaTable_stream", ParentType, ContextType, RequireFields<SubscriptionRootEbicaTableStreamArgs, 'batch_size' | 'cursor'>>;
  externalOnlineMenu?: SubscriptionResolver<Array<ResolversTypes['externalOnlineMenu']>, "externalOnlineMenu", ParentType, ContextType, Partial<SubscriptionRootExternalOnlineMenuArgs>>;
  externalOnlineMenuAvailableTimeTerm?: SubscriptionResolver<Array<ResolversTypes['externalOnlineMenuAvailableTimeTerm']>, "externalOnlineMenuAvailableTimeTerm", ParentType, ContextType, Partial<SubscriptionRootExternalOnlineMenuAvailableTimeTermArgs>>;
  externalOnlineMenuAvailableTimeTerm_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['externalOnlineMenuAvailableTimeTerm']>, "externalOnlineMenuAvailableTimeTerm_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootExternalOnlineMenuAvailableTimeTermByPkArgs, 'id'>>;
  externalOnlineMenuAvailableTimeTerm_stream?: SubscriptionResolver<Array<ResolversTypes['externalOnlineMenuAvailableTimeTerm']>, "externalOnlineMenuAvailableTimeTerm_stream", ParentType, ContextType, RequireFields<SubscriptionRootExternalOnlineMenuAvailableTimeTermStreamArgs, 'batch_size' | 'cursor'>>;
  externalOnlineMenuConfig?: SubscriptionResolver<Array<ResolversTypes['externalOnlineMenuConfig']>, "externalOnlineMenuConfig", ParentType, ContextType, Partial<SubscriptionRootExternalOnlineMenuConfigArgs>>;
  externalOnlineMenuConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['externalOnlineMenuConfig']>, "externalOnlineMenuConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootExternalOnlineMenuConfigByPkArgs, 'id'>>;
  externalOnlineMenuConfig_stream?: SubscriptionResolver<Array<ResolversTypes['externalOnlineMenuConfig']>, "externalOnlineMenuConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootExternalOnlineMenuConfigStreamArgs, 'batch_size' | 'cursor'>>;
  externalOnlineMenu_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['externalOnlineMenu']>, "externalOnlineMenu_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootExternalOnlineMenuByPkArgs, 'id'>>;
  externalOnlineMenu_stream?: SubscriptionResolver<Array<ResolversTypes['externalOnlineMenu']>, "externalOnlineMenu_stream", ParentType, ContextType, RequireFields<SubscriptionRootExternalOnlineMenuStreamArgs, 'batch_size' | 'cursor'>>;
  featureFlag?: SubscriptionResolver<Array<ResolversTypes['featureFlag']>, "featureFlag", ParentType, ContextType, Partial<SubscriptionRootFeatureFlagArgs>>;
  featureFlagShop?: SubscriptionResolver<Array<ResolversTypes['featureFlagShop']>, "featureFlagShop", ParentType, ContextType, Partial<SubscriptionRootFeatureFlagShopArgs>>;
  featureFlagShop_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['featureFlagShop']>, "featureFlagShop_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootFeatureFlagShopByPkArgs, 'name' | 'shopId'>>;
  featureFlagShop_stream?: SubscriptionResolver<Array<ResolversTypes['featureFlagShop']>, "featureFlagShop_stream", ParentType, ContextType, RequireFields<SubscriptionRootFeatureFlagShopStreamArgs, 'batch_size' | 'cursor'>>;
  featureFlag_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['featureFlag']>, "featureFlag_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootFeatureFlagByPkArgs, 'name'>>;
  featureFlag_stream?: SubscriptionResolver<Array<ResolversTypes['featureFlag']>, "featureFlag_stream", ParentType, ContextType, RequireFields<SubscriptionRootFeatureFlagStreamArgs, 'batch_size' | 'cursor'>>;
  foodingJournalConfig?: SubscriptionResolver<Array<ResolversTypes['foodingJournalConfig']>, "foodingJournalConfig", ParentType, ContextType, Partial<SubscriptionRootFoodingJournalConfigArgs>>;
  foodingJournalConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['foodingJournalConfig']>, "foodingJournalConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalConfigByPkArgs, 'id'>>;
  foodingJournalConfig_stream?: SubscriptionResolver<Array<ResolversTypes['foodingJournalConfig']>, "foodingJournalConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalConfigStreamArgs, 'batch_size' | 'cursor'>>;
  foodingJournalDepartmentMaster?: SubscriptionResolver<Array<ResolversTypes['foodingJournalDepartmentMaster']>, "foodingJournalDepartmentMaster", ParentType, ContextType, Partial<SubscriptionRootFoodingJournalDepartmentMasterArgs>>;
  foodingJournalDepartmentMaster_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['foodingJournalDepartmentMaster']>, "foodingJournalDepartmentMaster_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalDepartmentMasterByPkArgs, 'id'>>;
  foodingJournalDepartmentMaster_stream?: SubscriptionResolver<Array<ResolversTypes['foodingJournalDepartmentMaster']>, "foodingJournalDepartmentMaster_stream", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalDepartmentMasterStreamArgs, 'batch_size' | 'cursor'>>;
  foodingJournalGroupMaster?: SubscriptionResolver<Array<ResolversTypes['foodingJournalGroupMaster']>, "foodingJournalGroupMaster", ParentType, ContextType, Partial<SubscriptionRootFoodingJournalGroupMasterArgs>>;
  foodingJournalGroupMaster_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['foodingJournalGroupMaster']>, "foodingJournalGroupMaster_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalGroupMasterByPkArgs, 'id'>>;
  foodingJournalGroupMaster_stream?: SubscriptionResolver<Array<ResolversTypes['foodingJournalGroupMaster']>, "foodingJournalGroupMaster_stream", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalGroupMasterStreamArgs, 'batch_size' | 'cursor'>>;
  foodingJournalMenu?: SubscriptionResolver<Array<ResolversTypes['foodingJournalMenu']>, "foodingJournalMenu", ParentType, ContextType, Partial<SubscriptionRootFoodingJournalMenuArgs>>;
  foodingJournalMenu_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['foodingJournalMenu']>, "foodingJournalMenu_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalMenuByPkArgs, 'id'>>;
  foodingJournalMenu_stream?: SubscriptionResolver<Array<ResolversTypes['foodingJournalMenu']>, "foodingJournalMenu_stream", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalMenuStreamArgs, 'batch_size' | 'cursor'>>;
  foodingJournalOnSitePaymentDetailType?: SubscriptionResolver<Array<ResolversTypes['foodingJournalOnSitePaymentDetailType']>, "foodingJournalOnSitePaymentDetailType", ParentType, ContextType, Partial<SubscriptionRootFoodingJournalOnSitePaymentDetailTypeArgs>>;
  foodingJournalOnSitePaymentDetailType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDetailType']>, "foodingJournalOnSitePaymentDetailType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  foodingJournalOnSitePaymentDetailType_stream?: SubscriptionResolver<Array<ResolversTypes['foodingJournalOnSitePaymentDetailType']>, "foodingJournalOnSitePaymentDetailType_stream", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalOnSitePaymentDetailTypeStreamArgs, 'batch_size' | 'cursor'>>;
  foodingJournalOnSitePaymentDiscountType?: SubscriptionResolver<Array<ResolversTypes['foodingJournalOnSitePaymentDiscountType']>, "foodingJournalOnSitePaymentDiscountType", ParentType, ContextType, Partial<SubscriptionRootFoodingJournalOnSitePaymentDiscountTypeArgs>>;
  foodingJournalOnSitePaymentDiscountType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['foodingJournalOnSitePaymentDiscountType']>, "foodingJournalOnSitePaymentDiscountType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalOnSitePaymentDiscountTypeByPkArgs, 'id'>>;
  foodingJournalOnSitePaymentDiscountType_stream?: SubscriptionResolver<Array<ResolversTypes['foodingJournalOnSitePaymentDiscountType']>, "foodingJournalOnSitePaymentDiscountType_stream", ParentType, ContextType, RequireFields<SubscriptionRootFoodingJournalOnSitePaymentDiscountTypeStreamArgs, 'batch_size' | 'cursor'>>;
  giftMeta?: SubscriptionResolver<Array<ResolversTypes['giftMeta']>, "giftMeta", ParentType, ContextType, Partial<SubscriptionRootGiftMetaArgs>>;
  giftMeta_aggregate?: SubscriptionResolver<ResolversTypes['giftMeta_aggregate'], "giftMeta_aggregate", ParentType, ContextType, Partial<SubscriptionRootGiftMetaAggregateArgs>>;
  giftMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['giftMeta']>, "giftMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootGiftMetaByPkArgs, 'id'>>;
  giftMeta_stream?: SubscriptionResolver<Array<ResolversTypes['giftMeta']>, "giftMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootGiftMetaStreamArgs, 'batch_size' | 'cursor'>>;
  giftOptionMeta?: SubscriptionResolver<Array<ResolversTypes['giftOptionMeta']>, "giftOptionMeta", ParentType, ContextType, Partial<SubscriptionRootGiftOptionMetaArgs>>;
  giftOptionMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['giftOptionMeta']>, "giftOptionMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootGiftOptionMetaByPkArgs, 'id'>>;
  giftOptionMeta_stream?: SubscriptionResolver<Array<ResolversTypes['giftOptionMeta']>, "giftOptionMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootGiftOptionMetaStreamArgs, 'batch_size' | 'cursor'>>;
  gmoBankAccount?: SubscriptionResolver<Array<ResolversTypes['gmoBankAccount']>, "gmoBankAccount", ParentType, ContextType, Partial<SubscriptionRootGmoBankAccountArgs>>;
  gmoBankAccountShop?: SubscriptionResolver<Array<ResolversTypes['gmoBankAccountShop']>, "gmoBankAccountShop", ParentType, ContextType, Partial<SubscriptionRootGmoBankAccountShopArgs>>;
  gmoBankAccountShop_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['gmoBankAccountShop']>, "gmoBankAccountShop_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootGmoBankAccountShopByPkArgs, 'id'>>;
  gmoBankAccountShop_stream?: SubscriptionResolver<Array<ResolversTypes['gmoBankAccountShop']>, "gmoBankAccountShop_stream", ParentType, ContextType, RequireFields<SubscriptionRootGmoBankAccountShopStreamArgs, 'batch_size' | 'cursor'>>;
  gmoBankAccount_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['gmoBankAccount']>, "gmoBankAccount_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootGmoBankAccountByPkArgs, 'id'>>;
  gmoBankAccount_stream?: SubscriptionResolver<Array<ResolversTypes['gmoBankAccount']>, "gmoBankAccount_stream", ParentType, ContextType, RequireFields<SubscriptionRootGmoBankAccountStreamArgs, 'batch_size' | 'cursor'>>;
  gmoOffPaymentMethodType?: SubscriptionResolver<Array<ResolversTypes['gmoOffPaymentMethodType']>, "gmoOffPaymentMethodType", ParentType, ContextType, Partial<SubscriptionRootGmoOffPaymentMethodTypeArgs>>;
  gmoOffPaymentMethodType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['gmoOffPaymentMethodType']>, "gmoOffPaymentMethodType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootGmoOffPaymentMethodTypeByPkArgs, 'value'>>;
  gmoOffPaymentMethodType_stream?: SubscriptionResolver<Array<ResolversTypes['gmoOffPaymentMethodType']>, "gmoOffPaymentMethodType_stream", ParentType, ContextType, RequireFields<SubscriptionRootGmoOffPaymentMethodTypeStreamArgs, 'batch_size' | 'cursor'>>;
  gmoOffPaymentOnSitePaymentDetailType?: SubscriptionResolver<Array<ResolversTypes['gmoOffPaymentOnSitePaymentDetailType']>, "gmoOffPaymentOnSitePaymentDetailType", ParentType, ContextType, Partial<SubscriptionRootGmoOffPaymentOnSitePaymentDetailTypeArgs>>;
  gmoOffPaymentOnSitePaymentDetailType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['gmoOffPaymentOnSitePaymentDetailType']>, "gmoOffPaymentOnSitePaymentDetailType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootGmoOffPaymentOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  gmoOffPaymentOnSitePaymentDetailType_stream?: SubscriptionResolver<Array<ResolversTypes['gmoOffPaymentOnSitePaymentDetailType']>, "gmoOffPaymentOnSitePaymentDetailType_stream", ParentType, ContextType, RequireFields<SubscriptionRootGmoOffPaymentOnSitePaymentDetailTypeStreamArgs, 'batch_size' | 'cursor'>>;
  gmoOffPaymentShopConfig?: SubscriptionResolver<Array<ResolversTypes['gmoOffPaymentShopConfig']>, "gmoOffPaymentShopConfig", ParentType, ContextType, Partial<SubscriptionRootGmoOffPaymentShopConfigArgs>>;
  gmoOffPaymentShopConfigTransactionFeeRate?: SubscriptionResolver<Array<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate']>, "gmoOffPaymentShopConfigTransactionFeeRate", ParentType, ContextType, Partial<SubscriptionRootGmoOffPaymentShopConfigTransactionFeeRateArgs>>;
  gmoOffPaymentShopConfigTransactionFeeRate_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate']>, "gmoOffPaymentShopConfigTransactionFeeRate_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootGmoOffPaymentShopConfigTransactionFeeRateByPkArgs, 'id'>>;
  gmoOffPaymentShopConfigTransactionFeeRate_stream?: SubscriptionResolver<Array<ResolversTypes['gmoOffPaymentShopConfigTransactionFeeRate']>, "gmoOffPaymentShopConfigTransactionFeeRate_stream", ParentType, ContextType, RequireFields<SubscriptionRootGmoOffPaymentShopConfigTransactionFeeRateStreamArgs, 'batch_size' | 'cursor'>>;
  gmoOffPaymentShopConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['gmoOffPaymentShopConfig']>, "gmoOffPaymentShopConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootGmoOffPaymentShopConfigByPkArgs, 'id'>>;
  gmoOffPaymentShopConfig_stream?: SubscriptionResolver<Array<ResolversTypes['gmoOffPaymentShopConfig']>, "gmoOffPaymentShopConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootGmoOffPaymentShopConfigStreamArgs, 'batch_size' | 'cursor'>>;
  inflowSourceTag?: SubscriptionResolver<Array<ResolversTypes['inflowSourceTag']>, "inflowSourceTag", ParentType, ContextType, Partial<SubscriptionRootInflowSourceTagArgs>>;
  inflowSourceTag_aggregate?: SubscriptionResolver<ResolversTypes['inflowSourceTag_aggregate'], "inflowSourceTag_aggregate", ParentType, ContextType, Partial<SubscriptionRootInflowSourceTagAggregateArgs>>;
  inflowSourceTag_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['inflowSourceTag']>, "inflowSourceTag_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootInflowSourceTagByPkArgs, 'id'>>;
  inflowSourceTag_stream?: SubscriptionResolver<Array<ResolversTypes['inflowSourceTag']>, "inflowSourceTag_stream", ParentType, ContextType, RequireFields<SubscriptionRootInflowSourceTagStreamArgs, 'batch_size' | 'cursor'>>;
  itemSales?: SubscriptionResolver<Array<ResolversTypes['itemSales']>, "itemSales", ParentType, ContextType, Partial<SubscriptionRootItemSalesArgs>>;
  itemSales_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['itemSales']>, "itemSales_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootItemSalesByPkArgs, 'id'>>;
  itemSales_stream?: SubscriptionResolver<Array<ResolversTypes['itemSales']>, "itemSales_stream", ParentType, ContextType, RequireFields<SubscriptionRootItemSalesStreamArgs, 'batch_size' | 'cursor'>>;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget?: SubscriptionResolver<Array<ResolversTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget']>, "kdDisplayItemKdDisplayTargetsKdDisplayTarget", ParentType, ContextType, Partial<SubscriptionRootKdDisplayItemKdDisplayTargetsKdDisplayTargetArgs>>;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget']>, "kdDisplayItemKdDisplayTargetsKdDisplayTarget_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootKdDisplayItemKdDisplayTargetsKdDisplayTargetByPkArgs, 'id'>>;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_stream?: SubscriptionResolver<Array<ResolversTypes['kdDisplayItemKdDisplayTargetsKdDisplayTarget']>, "kdDisplayItemKdDisplayTargetsKdDisplayTarget_stream", ParentType, ContextType, RequireFields<SubscriptionRootKdDisplayItemKdDisplayTargetsKdDisplayTargetStreamArgs, 'batch_size' | 'cursor'>>;
  kdDisplayTarget?: SubscriptionResolver<Array<ResolversTypes['kdDisplayTarget']>, "kdDisplayTarget", ParentType, ContextType, Partial<SubscriptionRootKdDisplayTargetArgs>>;
  kdDisplayTarget_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['kdDisplayTarget']>, "kdDisplayTarget_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootKdDisplayTargetByPkArgs, 'id'>>;
  kdDisplayTarget_stream?: SubscriptionResolver<Array<ResolversTypes['kdDisplayTarget']>, "kdDisplayTarget_stream", ParentType, ContextType, RequireFields<SubscriptionRootKdDisplayTargetStreamArgs, 'batch_size' | 'cursor'>>;
  lineChannelConfig?: SubscriptionResolver<Array<ResolversTypes['lineChannelConfig']>, "lineChannelConfig", ParentType, ContextType, Partial<SubscriptionRootLineChannelConfigArgs>>;
  lineChannelConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['lineChannelConfig']>, "lineChannelConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootLineChannelConfigByPkArgs, 'id'>>;
  lineChannelConfig_stream?: SubscriptionResolver<Array<ResolversTypes['lineChannelConfig']>, "lineChannelConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootLineChannelConfigStreamArgs, 'batch_size' | 'cursor'>>;
  lineOfficialAccount?: SubscriptionResolver<Array<ResolversTypes['lineOfficialAccount']>, "lineOfficialAccount", ParentType, ContextType, Partial<SubscriptionRootLineOfficialAccountArgs>>;
  lineOfficialAccount_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['lineOfficialAccount']>, "lineOfficialAccount_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootLineOfficialAccountByPkArgs, 'serial'>>;
  lineOfficialAccount_stream?: SubscriptionResolver<Array<ResolversTypes['lineOfficialAccount']>, "lineOfficialAccount_stream", ParentType, ContextType, RequireFields<SubscriptionRootLineOfficialAccountStreamArgs, 'batch_size' | 'cursor'>>;
  lineProfile?: SubscriptionResolver<Array<ResolversTypes['lineProfile']>, "lineProfile", ParentType, ContextType, Partial<SubscriptionRootLineProfileArgs>>;
  lineProfile_stream?: SubscriptionResolver<Array<ResolversTypes['lineProfile']>, "lineProfile_stream", ParentType, ContextType, RequireFields<SubscriptionRootLineProfileStreamArgs, 'batch_size' | 'cursor'>>;
  lineReportingBotConfig?: SubscriptionResolver<Array<ResolversTypes['lineReportingBotConfig']>, "lineReportingBotConfig", ParentType, ContextType, Partial<SubscriptionRootLineReportingBotConfigArgs>>;
  lineReportingBotConfigShop?: SubscriptionResolver<Array<ResolversTypes['lineReportingBotConfigShop']>, "lineReportingBotConfigShop", ParentType, ContextType, Partial<SubscriptionRootLineReportingBotConfigShopArgs>>;
  lineReportingBotConfigShop_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['lineReportingBotConfigShop']>, "lineReportingBotConfigShop_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootLineReportingBotConfigShopByPkArgs, 'id'>>;
  lineReportingBotConfigShop_stream?: SubscriptionResolver<Array<ResolversTypes['lineReportingBotConfigShop']>, "lineReportingBotConfigShop_stream", ParentType, ContextType, RequireFields<SubscriptionRootLineReportingBotConfigShopStreamArgs, 'batch_size' | 'cursor'>>;
  lineReportingBotConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['lineReportingBotConfig']>, "lineReportingBotConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootLineReportingBotConfigByPkArgs, 'id'>>;
  lineReportingBotConfig_stream?: SubscriptionResolver<Array<ResolversTypes['lineReportingBotConfig']>, "lineReportingBotConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootLineReportingBotConfigStreamArgs, 'batch_size' | 'cursor'>>;
  lineReportingBotVerification?: SubscriptionResolver<Array<ResolversTypes['lineReportingBotVerification']>, "lineReportingBotVerification", ParentType, ContextType, Partial<SubscriptionRootLineReportingBotVerificationArgs>>;
  lineReportingBotVerification_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['lineReportingBotVerification']>, "lineReportingBotVerification_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootLineReportingBotVerificationByPkArgs, 'id'>>;
  lineReportingBotVerification_stream?: SubscriptionResolver<Array<ResolversTypes['lineReportingBotVerification']>, "lineReportingBotVerification_stream", ParentType, ContextType, RequireFields<SubscriptionRootLineReportingBotVerificationStreamArgs, 'batch_size' | 'cursor'>>;
  membershipCard?: SubscriptionResolver<Array<ResolversTypes['membershipCard']>, "membershipCard", ParentType, ContextType, Partial<SubscriptionRootMembershipCardArgs>>;
  membershipCardAppearanceType?: SubscriptionResolver<Array<ResolversTypes['membershipCardAppearanceType']>, "membershipCardAppearanceType", ParentType, ContextType, Partial<SubscriptionRootMembershipCardAppearanceTypeArgs>>;
  membershipCardAppearanceType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['membershipCardAppearanceType']>, "membershipCardAppearanceType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMembershipCardAppearanceTypeByPkArgs, 'value'>>;
  membershipCardAppearanceType_stream?: SubscriptionResolver<Array<ResolversTypes['membershipCardAppearanceType']>, "membershipCardAppearanceType_stream", ParentType, ContextType, RequireFields<SubscriptionRootMembershipCardAppearanceTypeStreamArgs, 'batch_size' | 'cursor'>>;
  membershipCardTitle?: SubscriptionResolver<Array<ResolversTypes['membershipCardTitle']>, "membershipCardTitle", ParentType, ContextType, Partial<SubscriptionRootMembershipCardTitleArgs>>;
  membershipCardTitle_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['membershipCardTitle']>, "membershipCardTitle_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMembershipCardTitleByPkArgs, 'id'>>;
  membershipCardTitle_stream?: SubscriptionResolver<Array<ResolversTypes['membershipCardTitle']>, "membershipCardTitle_stream", ParentType, ContextType, RequireFields<SubscriptionRootMembershipCardTitleStreamArgs, 'batch_size' | 'cursor'>>;
  membershipCard_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['membershipCard']>, "membershipCard_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMembershipCardByPkArgs, 'id'>>;
  membershipCard_stream?: SubscriptionResolver<Array<ResolversTypes['membershipCard']>, "membershipCard_stream", ParentType, ContextType, RequireFields<SubscriptionRootMembershipCardStreamArgs, 'batch_size' | 'cursor'>>;
  membershipRank?: SubscriptionResolver<Array<ResolversTypes['membershipRank']>, "membershipRank", ParentType, ContextType, Partial<SubscriptionRootMembershipRankArgs>>;
  membershipRankConfig?: SubscriptionResolver<Array<ResolversTypes['membershipRankConfig']>, "membershipRankConfig", ParentType, ContextType, Partial<SubscriptionRootMembershipRankConfigArgs>>;
  membershipRankConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['membershipRankConfig']>, "membershipRankConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMembershipRankConfigByPkArgs, 'id'>>;
  membershipRankConfig_stream?: SubscriptionResolver<Array<ResolversTypes['membershipRankConfig']>, "membershipRankConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootMembershipRankConfigStreamArgs, 'batch_size' | 'cursor'>>;
  membershipRank_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['membershipRank']>, "membershipRank_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMembershipRankByPkArgs, 'value'>>;
  membershipRank_stream?: SubscriptionResolver<Array<ResolversTypes['membershipRank']>, "membershipRank_stream", ParentType, ContextType, RequireFields<SubscriptionRootMembershipRankStreamArgs, 'batch_size' | 'cursor'>>;
  menu?: SubscriptionResolver<Array<ResolversTypes['menu']>, "menu", ParentType, ContextType, Partial<SubscriptionRootMenuArgs>>;
  menuOption?: SubscriptionResolver<Array<ResolversTypes['menuOption']>, "menuOption", ParentType, ContextType, Partial<SubscriptionRootMenuOptionArgs>>;
  menuOption_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['menuOption']>, "menuOption_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMenuOptionByPkArgs, 'id'>>;
  menuOption_stream?: SubscriptionResolver<Array<ResolversTypes['menuOption']>, "menuOption_stream", ParentType, ContextType, RequireFields<SubscriptionRootMenuOptionStreamArgs, 'batch_size' | 'cursor'>>;
  menuOrderItem?: SubscriptionResolver<Array<ResolversTypes['menuOrderItem']>, "menuOrderItem", ParentType, ContextType, Partial<SubscriptionRootMenuOrderItemArgs>>;
  menuOrderItemChoice?: SubscriptionResolver<Array<ResolversTypes['menuOrderItemChoice']>, "menuOrderItemChoice", ParentType, ContextType, Partial<SubscriptionRootMenuOrderItemChoiceArgs>>;
  menuOrderItemChoice_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['menuOrderItemChoice']>, "menuOrderItemChoice_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMenuOrderItemChoiceByPkArgs, 'id'>>;
  menuOrderItemChoice_stream?: SubscriptionResolver<Array<ResolversTypes['menuOrderItemChoice']>, "menuOrderItemChoice_stream", ParentType, ContextType, RequireFields<SubscriptionRootMenuOrderItemChoiceStreamArgs, 'batch_size' | 'cursor'>>;
  menuOrderItemOption?: SubscriptionResolver<Array<ResolversTypes['menuOrderItemOption']>, "menuOrderItemOption", ParentType, ContextType, Partial<SubscriptionRootMenuOrderItemOptionArgs>>;
  menuOrderItemOption_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['menuOrderItemOption']>, "menuOrderItemOption_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMenuOrderItemOptionByPkArgs, 'id'>>;
  menuOrderItemOption_stream?: SubscriptionResolver<Array<ResolversTypes['menuOrderItemOption']>, "menuOrderItemOption_stream", ParentType, ContextType, RequireFields<SubscriptionRootMenuOrderItemOptionStreamArgs, 'batch_size' | 'cursor'>>;
  menuOrderItem_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['menuOrderItem']>, "menuOrderItem_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMenuOrderItemByPkArgs, 'id'>>;
  menuOrderItem_stream?: SubscriptionResolver<Array<ResolversTypes['menuOrderItem']>, "menuOrderItem_stream", ParentType, ContextType, RequireFields<SubscriptionRootMenuOrderItemStreamArgs, 'batch_size' | 'cursor'>>;
  menuRecommendationMeta?: SubscriptionResolver<Array<ResolversTypes['menuRecommendationMeta']>, "menuRecommendationMeta", ParentType, ContextType, Partial<SubscriptionRootMenuRecommendationMetaArgs>>;
  menuRecommendationMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['menuRecommendationMeta']>, "menuRecommendationMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMenuRecommendationMetaByPkArgs, 'id'>>;
  menuRecommendationMeta_stream?: SubscriptionResolver<Array<ResolversTypes['menuRecommendationMeta']>, "menuRecommendationMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootMenuRecommendationMetaStreamArgs, 'batch_size' | 'cursor'>>;
  menu_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['menu']>, "menu_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMenuByPkArgs, 'serial'>>;
  menu_stream?: SubscriptionResolver<Array<ResolversTypes['menu']>, "menu_stream", ParentType, ContextType, RequireFields<SubscriptionRootMenuStreamArgs, 'batch_size' | 'cursor'>>;
  messageDelivery?: SubscriptionResolver<Array<ResolversTypes['messageDelivery']>, "messageDelivery", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryArgs>>;
  messageDeliveryCustomerList?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerList']>, "messageDeliveryCustomerList", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerListArgs>>;
  messageDeliveryCustomerListCustomer?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerListCustomer']>, "messageDeliveryCustomerListCustomer", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerListCustomerArgs>>;
  messageDeliveryCustomerListCustomer_aggregate?: SubscriptionResolver<ResolversTypes['messageDeliveryCustomerListCustomer_aggregate'], "messageDeliveryCustomerListCustomer_aggregate", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerListCustomerAggregateArgs>>;
  messageDeliveryCustomerListCustomer_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerListCustomer']>, "messageDeliveryCustomerListCustomer_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerListCustomerByPkArgs, 'id'>>;
  messageDeliveryCustomerListCustomer_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerListCustomer']>, "messageDeliveryCustomerListCustomer_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerListCustomerStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerList_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerList']>, "messageDeliveryCustomerList_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerListByPkArgs, 'id'>>;
  messageDeliveryCustomerList_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerList']>, "messageDeliveryCustomerList_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerListStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegment?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegment']>, "messageDeliveryCustomerSegment", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentArgs>>;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentBusinessOperationHoursCondition']>, "messageDeliveryCustomerSegmentBusinessOperationHoursCondition", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentBusinessOperationHoursConditionArgs>>;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentBusinessOperationHoursCondition']>, "messageDeliveryCustomerSegmentBusinessOperationHoursCondition_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentBusinessOperationHoursConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentBusinessOperationHoursCondition']>, "messageDeliveryCustomerSegmentBusinessOperationHoursCondition_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentBusinessOperationHoursConditionStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegmentDayOfWeekCondition?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentDayOfWeekCondition']>, "messageDeliveryCustomerSegmentDayOfWeekCondition", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentDayOfWeekConditionArgs>>;
  messageDeliveryCustomerSegmentDayOfWeekCondition_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentDayOfWeekCondition']>, "messageDeliveryCustomerSegmentDayOfWeekCondition_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentDayOfWeekConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentDayOfWeekCondition_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentDayOfWeekCondition']>, "messageDeliveryCustomerSegmentDayOfWeekCondition_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentDayOfWeekConditionStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition']>, "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionArgs>>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition']>, "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition']>, "messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegmentGender?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentGender']>, "messageDeliveryCustomerSegmentGender", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentGenderArgs>>;
  messageDeliveryCustomerSegmentGenderCondition?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentGenderCondition']>, "messageDeliveryCustomerSegmentGenderCondition", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentGenderConditionArgs>>;
  messageDeliveryCustomerSegmentGenderCondition_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentGenderCondition']>, "messageDeliveryCustomerSegmentGenderCondition_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentGenderConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentGenderCondition_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentGenderCondition']>, "messageDeliveryCustomerSegmentGenderCondition_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentGenderConditionStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegmentGender_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentGender']>, "messageDeliveryCustomerSegmentGender_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentGenderByPkArgs, 'value'>>;
  messageDeliveryCustomerSegmentGender_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentGender']>, "messageDeliveryCustomerSegmentGender_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentGenderStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegmentMenuCondition?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentMenuCondition']>, "messageDeliveryCustomerSegmentMenuCondition", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentMenuConditionArgs>>;
  messageDeliveryCustomerSegmentMenuCondition_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentMenuCondition']>, "messageDeliveryCustomerSegmentMenuCondition_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentMenuConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentMenuCondition_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentMenuCondition']>, "messageDeliveryCustomerSegmentMenuCondition_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentMenuConditionStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegmentNumericCondition?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentNumericCondition']>, "messageDeliveryCustomerSegmentNumericCondition", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentNumericConditionArgs>>;
  messageDeliveryCustomerSegmentNumericCondition_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentNumericCondition']>, "messageDeliveryCustomerSegmentNumericCondition_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentNumericConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentNumericCondition_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentNumericCondition']>, "messageDeliveryCustomerSegmentNumericCondition_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentNumericConditionStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerCondition']>, "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerConditionArgs>>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerCondition']>, "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerCondition']>, "messageDeliveryCustomerSegmentQuestionnaireAnswerCondition_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerConditionStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType']>, "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeArgs>>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType']>, "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeByPkArgs, 'value'>>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType']>, "messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegmentShopCondition?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentShopCondition']>, "messageDeliveryCustomerSegmentShopCondition", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentShopConditionArgs>>;
  messageDeliveryCustomerSegmentShopCondition_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentShopCondition']>, "messageDeliveryCustomerSegmentShopCondition_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentShopConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentShopCondition_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentShopCondition']>, "messageDeliveryCustomerSegmentShopCondition_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentShopConditionStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentUnvisitedDayCountCondition']>, "messageDeliveryCustomerSegmentUnvisitedDayCountCondition", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentUnvisitedDayCountConditionArgs>>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentUnvisitedDayCountCondition']>, "messageDeliveryCustomerSegmentUnvisitedDayCountCondition_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentUnvisitedDayCountConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentUnvisitedDayCountCondition']>, "messageDeliveryCustomerSegmentUnvisitedDayCountCondition_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentUnvisitedDayCountConditionStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentVisitedDayCountCondition']>, "messageDeliveryCustomerSegmentVisitedDayCountCondition", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryCustomerSegmentVisitedDayCountConditionArgs>>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegmentVisitedDayCountCondition']>, "messageDeliveryCustomerSegmentVisitedDayCountCondition_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentVisitedDayCountConditionByPkArgs, 'id'>>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegmentVisitedDayCountCondition']>, "messageDeliveryCustomerSegmentVisitedDayCountCondition_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentVisitedDayCountConditionStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryCustomerSegment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryCustomerSegment']>, "messageDeliveryCustomerSegment_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentByPkArgs, 'id'>>;
  messageDeliveryCustomerSegment_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryCustomerSegment']>, "messageDeliveryCustomerSegment_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryCustomerSegmentStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryJob?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryJob']>, "messageDeliveryJob", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryJobArgs>>;
  messageDeliveryJob_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryJob']>, "messageDeliveryJob_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryJobByPkArgs, 'id'>>;
  messageDeliveryJob_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryJob']>, "messageDeliveryJob_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryJobStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryMessage?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryMessage']>, "messageDeliveryMessage", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryMessageArgs>>;
  messageDeliveryMessageCouponTypeMeta?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryMessageCouponTypeMeta']>, "messageDeliveryMessageCouponTypeMeta", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryMessageCouponTypeMetaArgs>>;
  messageDeliveryMessageCouponTypeMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryMessageCouponTypeMeta']>, "messageDeliveryMessageCouponTypeMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryMessageCouponTypeMetaByPkArgs, 'id'>>;
  messageDeliveryMessageCouponTypeMeta_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryMessageCouponTypeMeta']>, "messageDeliveryMessageCouponTypeMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryMessageCouponTypeMetaStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryMessageImageTypeMeta?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryMessageImageTypeMeta']>, "messageDeliveryMessageImageTypeMeta", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryMessageImageTypeMetaArgs>>;
  messageDeliveryMessageImageTypeMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryMessageImageTypeMeta']>, "messageDeliveryMessageImageTypeMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryMessageImageTypeMetaByPkArgs, 'id'>>;
  messageDeliveryMessageImageTypeMeta_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryMessageImageTypeMeta']>, "messageDeliveryMessageImageTypeMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryMessageImageTypeMetaStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryMessageQuestionnaireTypeMeta?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryMessageQuestionnaireTypeMeta']>, "messageDeliveryMessageQuestionnaireTypeMeta", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryMessageQuestionnaireTypeMetaArgs>>;
  messageDeliveryMessageQuestionnaireTypeMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryMessageQuestionnaireTypeMeta']>, "messageDeliveryMessageQuestionnaireTypeMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryMessageQuestionnaireTypeMetaByPkArgs, 'id'>>;
  messageDeliveryMessageQuestionnaireTypeMeta_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryMessageQuestionnaireTypeMeta']>, "messageDeliveryMessageQuestionnaireTypeMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryMessageQuestionnaireTypeMetaStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryMessageTextTypeMeta?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryMessageTextTypeMeta']>, "messageDeliveryMessageTextTypeMeta", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryMessageTextTypeMetaArgs>>;
  messageDeliveryMessageTextTypeMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryMessageTextTypeMeta']>, "messageDeliveryMessageTextTypeMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryMessageTextTypeMetaByPkArgs, 'id'>>;
  messageDeliveryMessageTextTypeMeta_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryMessageTextTypeMeta']>, "messageDeliveryMessageTextTypeMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryMessageTextTypeMetaStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryMessage_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryMessage']>, "messageDeliveryMessage_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryMessageByPkArgs, 'id'>>;
  messageDeliveryMessage_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryMessage']>, "messageDeliveryMessage_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryMessageStreamArgs, 'batch_size' | 'cursor'>>;
  messageDeliveryShopJob?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryShopJob']>, "messageDeliveryShopJob", ParentType, ContextType, Partial<SubscriptionRootMessageDeliveryShopJobArgs>>;
  messageDeliveryShopJob_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDeliveryShopJob']>, "messageDeliveryShopJob_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryShopJobByPkArgs, 'id'>>;
  messageDeliveryShopJob_stream?: SubscriptionResolver<Array<ResolversTypes['messageDeliveryShopJob']>, "messageDeliveryShopJob_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryShopJobStreamArgs, 'batch_size' | 'cursor'>>;
  messageDelivery_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['messageDelivery']>, "messageDelivery_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryByPkArgs, 'id'>>;
  messageDelivery_stream?: SubscriptionResolver<Array<ResolversTypes['messageDelivery']>, "messageDelivery_stream", ParentType, ContextType, RequireFields<SubscriptionRootMessageDeliveryStreamArgs, 'batch_size' | 'cursor'>>;
  monthlySalesBudget?: SubscriptionResolver<Array<ResolversTypes['monthlySalesBudget']>, "monthlySalesBudget", ParentType, ContextType, Partial<SubscriptionRootMonthlySalesBudgetArgs>>;
  monthlySalesBudget_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['monthlySalesBudget']>, "monthlySalesBudget_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMonthlySalesBudgetByPkArgs, 'id'>>;
  monthlySalesBudget_stream?: SubscriptionResolver<Array<ResolversTypes['monthlySalesBudget']>, "monthlySalesBudget_stream", ParentType, ContextType, RequireFields<SubscriptionRootMonthlySalesBudgetStreamArgs, 'batch_size' | 'cursor'>>;
  multipleChoiceTypeQuestionAnswer?: SubscriptionResolver<Array<ResolversTypes['multipleChoiceTypeQuestionAnswer']>, "multipleChoiceTypeQuestionAnswer", ParentType, ContextType, Partial<SubscriptionRootMultipleChoiceTypeQuestionAnswerArgs>>;
  multipleChoiceTypeQuestionAnswer_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['multipleChoiceTypeQuestionAnswer']>, "multipleChoiceTypeQuestionAnswer_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMultipleChoiceTypeQuestionAnswerByPkArgs, 'id'>>;
  multipleChoiceTypeQuestionAnswer_stream?: SubscriptionResolver<Array<ResolversTypes['multipleChoiceTypeQuestionAnswer']>, "multipleChoiceTypeQuestionAnswer_stream", ParentType, ContextType, RequireFields<SubscriptionRootMultipleChoiceTypeQuestionAnswerStreamArgs, 'batch_size' | 'cursor'>>;
  multipleChoiceTypeQuestionChoice?: SubscriptionResolver<Array<ResolversTypes['multipleChoiceTypeQuestionChoice']>, "multipleChoiceTypeQuestionChoice", ParentType, ContextType, Partial<SubscriptionRootMultipleChoiceTypeQuestionChoiceArgs>>;
  multipleChoiceTypeQuestionChoiceAnswer?: SubscriptionResolver<Array<ResolversTypes['multipleChoiceTypeQuestionChoiceAnswer']>, "multipleChoiceTypeQuestionChoiceAnswer", ParentType, ContextType, Partial<SubscriptionRootMultipleChoiceTypeQuestionChoiceAnswerArgs>>;
  multipleChoiceTypeQuestionChoiceAnswer_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['multipleChoiceTypeQuestionChoiceAnswer']>, "multipleChoiceTypeQuestionChoiceAnswer_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMultipleChoiceTypeQuestionChoiceAnswerByPkArgs, 'id'>>;
  multipleChoiceTypeQuestionChoiceAnswer_stream?: SubscriptionResolver<Array<ResolversTypes['multipleChoiceTypeQuestionChoiceAnswer']>, "multipleChoiceTypeQuestionChoiceAnswer_stream", ParentType, ContextType, RequireFields<SubscriptionRootMultipleChoiceTypeQuestionChoiceAnswerStreamArgs, 'batch_size' | 'cursor'>>;
  multipleChoiceTypeQuestionChoice_stream?: SubscriptionResolver<Array<ResolversTypes['multipleChoiceTypeQuestionChoice']>, "multipleChoiceTypeQuestionChoice_stream", ParentType, ContextType, RequireFields<SubscriptionRootMultipleChoiceTypeQuestionChoiceStreamArgs, 'batch_size' | 'cursor'>>;
  multipleChoiceTypeQuestionMeta?: SubscriptionResolver<Array<ResolversTypes['multipleChoiceTypeQuestionMeta']>, "multipleChoiceTypeQuestionMeta", ParentType, ContextType, Partial<SubscriptionRootMultipleChoiceTypeQuestionMetaArgs>>;
  multipleChoiceTypeQuestionMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['multipleChoiceTypeQuestionMeta']>, "multipleChoiceTypeQuestionMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootMultipleChoiceTypeQuestionMetaByPkArgs, 'id'>>;
  multipleChoiceTypeQuestionMeta_stream?: SubscriptionResolver<Array<ResolversTypes['multipleChoiceTypeQuestionMeta']>, "multipleChoiceTypeQuestionMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootMultipleChoiceTypeQuestionMetaStreamArgs, 'batch_size' | 'cursor'>>;
  onSitePayment?: SubscriptionResolver<Array<ResolversTypes['onSitePayment']>, "onSitePayment", ParentType, ContextType, Partial<SubscriptionRootOnSitePaymentArgs>>;
  onSitePaymentDetail?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentDetail']>, "onSitePaymentDetail", ParentType, ContextType, Partial<SubscriptionRootOnSitePaymentDetailArgs>>;
  onSitePaymentDetailType?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentDetailType']>, "onSitePaymentDetailType", ParentType, ContextType, Partial<SubscriptionRootOnSitePaymentDetailTypeArgs>>;
  onSitePaymentDetailTypeCategoryPriority?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentDetailTypeCategoryPriority']>, "onSitePaymentDetailTypeCategoryPriority", ParentType, ContextType, Partial<SubscriptionRootOnSitePaymentDetailTypeCategoryPriorityArgs>>;
  onSitePaymentDetailTypeCategoryPriority_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['onSitePaymentDetailTypeCategoryPriority']>, "onSitePaymentDetailTypeCategoryPriority_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentDetailTypeCategoryPriorityByPkArgs, 'id'>>;
  onSitePaymentDetailTypeCategoryPriority_stream?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentDetailTypeCategoryPriority']>, "onSitePaymentDetailTypeCategoryPriority_stream", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentDetailTypeCategoryPriorityStreamArgs, 'batch_size' | 'cursor'>>;
  onSitePaymentDetailType_aggregate?: SubscriptionResolver<ResolversTypes['onSitePaymentDetailType_aggregate'], "onSitePaymentDetailType_aggregate", ParentType, ContextType, Partial<SubscriptionRootOnSitePaymentDetailTypeAggregateArgs>>;
  onSitePaymentDetailType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['onSitePaymentDetailType']>, "onSitePaymentDetailType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  onSitePaymentDetailType_stream?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentDetailType']>, "onSitePaymentDetailType_stream", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentDetailTypeStreamArgs, 'batch_size' | 'cursor'>>;
  onSitePaymentDetail_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['onSitePaymentDetail']>, "onSitePaymentDetail_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentDetailByPkArgs, 'id'>>;
  onSitePaymentDetail_stream?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentDetail']>, "onSitePaymentDetail_stream", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentDetailStreamArgs, 'batch_size' | 'cursor'>>;
  onSitePaymentDiscount?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentDiscount']>, "onSitePaymentDiscount", ParentType, ContextType, Partial<SubscriptionRootOnSitePaymentDiscountArgs>>;
  onSitePaymentDiscountType?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentDiscountType']>, "onSitePaymentDiscountType", ParentType, ContextType, Partial<SubscriptionRootOnSitePaymentDiscountTypeArgs>>;
  onSitePaymentDiscountType_aggregate?: SubscriptionResolver<ResolversTypes['onSitePaymentDiscountType_aggregate'], "onSitePaymentDiscountType_aggregate", ParentType, ContextType, Partial<SubscriptionRootOnSitePaymentDiscountTypeAggregateArgs>>;
  onSitePaymentDiscountType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['onSitePaymentDiscountType']>, "onSitePaymentDiscountType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentDiscountTypeByPkArgs, 'id'>>;
  onSitePaymentDiscountType_stream?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentDiscountType']>, "onSitePaymentDiscountType_stream", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentDiscountTypeStreamArgs, 'batch_size' | 'cursor'>>;
  onSitePaymentDiscount_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['onSitePaymentDiscount']>, "onSitePaymentDiscount_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentDiscountByPkArgs, 'onSitePaymentDiscountId'>>;
  onSitePaymentDiscount_stream?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentDiscount']>, "onSitePaymentDiscount_stream", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentDiscountStreamArgs, 'batch_size' | 'cursor'>>;
  onSitePaymentSales?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentSales']>, "onSitePaymentSales", ParentType, ContextType, Partial<SubscriptionRootOnSitePaymentSalesArgs>>;
  onSitePaymentSales_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['onSitePaymentSales']>, "onSitePaymentSales_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentSalesByPkArgs, 'onSitePaymentSalesId'>>;
  onSitePaymentSales_stream?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentSales']>, "onSitePaymentSales_stream", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentSalesStreamArgs, 'batch_size' | 'cursor'>>;
  onSitePaymentTableUsers?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentTableUsers']>, "onSitePaymentTableUsers", ParentType, ContextType, Partial<SubscriptionRootOnSitePaymentTableUsersArgs>>;
  onSitePaymentTableUsers_stream?: SubscriptionResolver<Array<ResolversTypes['onSitePaymentTableUsers']>, "onSitePaymentTableUsers_stream", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentTableUsersStreamArgs, 'batch_size' | 'cursor'>>;
  onSitePayment_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['onSitePayment']>, "onSitePayment_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentByPkArgs, 'onSitePaymentId'>>;
  onSitePayment_stream?: SubscriptionResolver<Array<ResolversTypes['onSitePayment']>, "onSitePayment_stream", ParentType, ContextType, RequireFields<SubscriptionRootOnSitePaymentStreamArgs, 'batch_size' | 'cursor'>>;
  onlinePaymentPrinterRoleMeta?: SubscriptionResolver<Array<ResolversTypes['onlinePaymentPrinterRoleMeta']>, "onlinePaymentPrinterRoleMeta", ParentType, ContextType, Partial<SubscriptionRootOnlinePaymentPrinterRoleMetaArgs>>;
  onlinePaymentPrinterRoleMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['onlinePaymentPrinterRoleMeta']>, "onlinePaymentPrinterRoleMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOnlinePaymentPrinterRoleMetaByPkArgs, 'id'>>;
  onlinePaymentPrinterRoleMeta_stream?: SubscriptionResolver<Array<ResolversTypes['onlinePaymentPrinterRoleMeta']>, "onlinePaymentPrinterRoleMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootOnlinePaymentPrinterRoleMetaStreamArgs, 'batch_size' | 'cursor'>>;
  option?: SubscriptionResolver<Array<ResolversTypes['option']>, "option", ParentType, ContextType, Partial<SubscriptionRootOptionArgs>>;
  optionInputType?: SubscriptionResolver<Array<ResolversTypes['optionInputType']>, "optionInputType", ParentType, ContextType, Partial<SubscriptionRootOptionInputTypeArgs>>;
  optionInputType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['optionInputType']>, "optionInputType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOptionInputTypeByPkArgs, 'value'>>;
  optionInputType_stream?: SubscriptionResolver<Array<ResolversTypes['optionInputType']>, "optionInputType_stream", ParentType, ContextType, RequireFields<SubscriptionRootOptionInputTypeStreamArgs, 'batch_size' | 'cursor'>>;
  option_aggregate?: SubscriptionResolver<ResolversTypes['option_aggregate'], "option_aggregate", ParentType, ContextType, Partial<SubscriptionRootOptionAggregateArgs>>;
  option_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['option']>, "option_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOptionByPkArgs, 'serial'>>;
  option_stream?: SubscriptionResolver<Array<ResolversTypes['option']>, "option_stream", ParentType, ContextType, RequireFields<SubscriptionRootOptionStreamArgs, 'batch_size' | 'cursor'>>;
  order?: SubscriptionResolver<Array<ResolversTypes['order']>, "order", ParentType, ContextType, Partial<SubscriptionRootOrderArgs>>;
  order_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['order']>, "order_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOrderByPkArgs, 'id'>>;
  order_stream?: SubscriptionResolver<Array<ResolversTypes['order']>, "order_stream", ParentType, ContextType, RequireFields<SubscriptionRootOrderStreamArgs, 'batch_size' | 'cursor'>>;
  orderableTime?: SubscriptionResolver<Array<ResolversTypes['orderableTime']>, "orderableTime", ParentType, ContextType, Partial<SubscriptionRootOrderableTimeArgs>>;
  orderableTime_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['orderableTime']>, "orderableTime_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootOrderableTimeByPkArgs, 'id'>>;
  orderableTime_stream?: SubscriptionResolver<Array<ResolversTypes['orderableTime']>, "orderableTime_stream", ParentType, ContextType, RequireFields<SubscriptionRootOrderableTimeStreamArgs, 'batch_size' | 'cursor'>>;
  payingActivePlanChoice?: SubscriptionResolver<Array<ResolversTypes['payingActivePlanChoice']>, "payingActivePlanChoice", ParentType, ContextType, Partial<SubscriptionRootPayingActivePlanChoiceArgs>>;
  payingActivePlanChoice_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['payingActivePlanChoice']>, "payingActivePlanChoice_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPayingActivePlanChoiceByPkArgs, 'id'>>;
  payingActivePlanChoice_stream?: SubscriptionResolver<Array<ResolversTypes['payingActivePlanChoice']>, "payingActivePlanChoice_stream", ParentType, ContextType, RequireFields<SubscriptionRootPayingActivePlanChoiceStreamArgs, 'batch_size' | 'cursor'>>;
  payingActivePlanOpenPriceMeta?: SubscriptionResolver<Array<ResolversTypes['payingActivePlanOpenPriceMeta']>, "payingActivePlanOpenPriceMeta", ParentType, ContextType, Partial<SubscriptionRootPayingActivePlanOpenPriceMetaArgs>>;
  payingActivePlanOpenPriceMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['payingActivePlanOpenPriceMeta']>, "payingActivePlanOpenPriceMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPayingActivePlanOpenPriceMetaByPkArgs, 'payingActivePlanOpenPriceMetaId'>>;
  payingActivePlanOpenPriceMeta_stream?: SubscriptionResolver<Array<ResolversTypes['payingActivePlanOpenPriceMeta']>, "payingActivePlanOpenPriceMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootPayingActivePlanOpenPriceMetaStreamArgs, 'batch_size' | 'cursor'>>;
  payingMenuOrderItem?: SubscriptionResolver<Array<ResolversTypes['payingMenuOrderItem']>, "payingMenuOrderItem", ParentType, ContextType, Partial<SubscriptionRootPayingMenuOrderItemArgs>>;
  payingMenuOrderItem_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['payingMenuOrderItem']>, "payingMenuOrderItem_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPayingMenuOrderItemByPkArgs, 'id'>>;
  payingMenuOrderItem_stream?: SubscriptionResolver<Array<ResolversTypes['payingMenuOrderItem']>, "payingMenuOrderItem_stream", ParentType, ContextType, RequireFields<SubscriptionRootPayingMenuOrderItemStreamArgs, 'batch_size' | 'cursor'>>;
  paymentReceiptRoleTable?: SubscriptionResolver<Array<ResolversTypes['paymentReceiptRoleTable']>, "paymentReceiptRoleTable", ParentType, ContextType, Partial<SubscriptionRootPaymentReceiptRoleTableArgs>>;
  paymentReceiptRoleTable_stream?: SubscriptionResolver<Array<ResolversTypes['paymentReceiptRoleTable']>, "paymentReceiptRoleTable_stream", ParentType, ContextType, RequireFields<SubscriptionRootPaymentReceiptRoleTableStreamArgs, 'batch_size' | 'cursor'>>;
  pikaichiConfig?: SubscriptionResolver<Array<ResolversTypes['pikaichiConfig']>, "pikaichiConfig", ParentType, ContextType, Partial<SubscriptionRootPikaichiConfigArgs>>;
  pikaichiConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['pikaichiConfig']>, "pikaichiConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiConfigByPkArgs, 'pikaichiConfigId'>>;
  pikaichiConfig_stream?: SubscriptionResolver<Array<ResolversTypes['pikaichiConfig']>, "pikaichiConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiConfigStreamArgs, 'batch_size' | 'cursor'>>;
  pikaichiMenu?: SubscriptionResolver<Array<ResolversTypes['pikaichiMenu']>, "pikaichiMenu", ParentType, ContextType, Partial<SubscriptionRootPikaichiMenuArgs>>;
  pikaichiMenuChoice?: SubscriptionResolver<Array<ResolversTypes['pikaichiMenuChoice']>, "pikaichiMenuChoice", ParentType, ContextType, Partial<SubscriptionRootPikaichiMenuChoiceArgs>>;
  pikaichiMenuChoice_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['pikaichiMenuChoice']>, "pikaichiMenuChoice_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiMenuChoiceByPkArgs, 'pikaichiMenuChoiceId'>>;
  pikaichiMenuChoice_stream?: SubscriptionResolver<Array<ResolversTypes['pikaichiMenuChoice']>, "pikaichiMenuChoice_stream", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiMenuChoiceStreamArgs, 'batch_size' | 'cursor'>>;
  pikaichiMenuMenu?: SubscriptionResolver<Array<ResolversTypes['pikaichiMenuMenu']>, "pikaichiMenuMenu", ParentType, ContextType, Partial<SubscriptionRootPikaichiMenuMenuArgs>>;
  pikaichiMenuMenu_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['pikaichiMenuMenu']>, "pikaichiMenuMenu_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiMenuMenuByPkArgs, 'pikaichiMenuMenuId'>>;
  pikaichiMenuMenu_stream?: SubscriptionResolver<Array<ResolversTypes['pikaichiMenuMenu']>, "pikaichiMenuMenu_stream", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiMenuMenuStreamArgs, 'batch_size' | 'cursor'>>;
  pikaichiMenuPlan?: SubscriptionResolver<Array<ResolversTypes['pikaichiMenuPlan']>, "pikaichiMenuPlan", ParentType, ContextType, Partial<SubscriptionRootPikaichiMenuPlanArgs>>;
  pikaichiMenuPlanChoice?: SubscriptionResolver<Array<ResolversTypes['pikaichiMenuPlanChoice']>, "pikaichiMenuPlanChoice", ParentType, ContextType, Partial<SubscriptionRootPikaichiMenuPlanChoiceArgs>>;
  pikaichiMenuPlanChoice_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['pikaichiMenuPlanChoice']>, "pikaichiMenuPlanChoice_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiMenuPlanChoiceByPkArgs, 'pikaichiMenuPlanChoiceId'>>;
  pikaichiMenuPlanChoice_stream?: SubscriptionResolver<Array<ResolversTypes['pikaichiMenuPlanChoice']>, "pikaichiMenuPlanChoice_stream", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiMenuPlanChoiceStreamArgs, 'batch_size' | 'cursor'>>;
  pikaichiMenuPlan_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['pikaichiMenuPlan']>, "pikaichiMenuPlan_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiMenuPlanByPkArgs, 'pikaichiMenuPlanId'>>;
  pikaichiMenuPlan_stream?: SubscriptionResolver<Array<ResolversTypes['pikaichiMenuPlan']>, "pikaichiMenuPlan_stream", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiMenuPlanStreamArgs, 'batch_size' | 'cursor'>>;
  pikaichiMenu_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['pikaichiMenu']>, "pikaichiMenu_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiMenuByPkArgs, 'pikaichiMenuId'>>;
  pikaichiMenu_stream?: SubscriptionResolver<Array<ResolversTypes['pikaichiMenu']>, "pikaichiMenu_stream", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiMenuStreamArgs, 'batch_size' | 'cursor'>>;
  pikaichiOnSitePaymentDetailType?: SubscriptionResolver<Array<ResolversTypes['pikaichiOnSitePaymentDetailType']>, "pikaichiOnSitePaymentDetailType", ParentType, ContextType, Partial<SubscriptionRootPikaichiOnSitePaymentDetailTypeArgs>>;
  pikaichiOnSitePaymentDetailType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDetailType']>, "pikaichiOnSitePaymentDetailType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiOnSitePaymentDetailTypeByPkArgs, 'pikaichiPaymentDetailTypeId'>>;
  pikaichiOnSitePaymentDetailType_stream?: SubscriptionResolver<Array<ResolversTypes['pikaichiOnSitePaymentDetailType']>, "pikaichiOnSitePaymentDetailType_stream", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiOnSitePaymentDetailTypeStreamArgs, 'batch_size' | 'cursor'>>;
  pikaichiOnSitePaymentDiscountType?: SubscriptionResolver<Array<ResolversTypes['pikaichiOnSitePaymentDiscountType']>, "pikaichiOnSitePaymentDiscountType", ParentType, ContextType, Partial<SubscriptionRootPikaichiOnSitePaymentDiscountTypeArgs>>;
  pikaichiOnSitePaymentDiscountType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['pikaichiOnSitePaymentDiscountType']>, "pikaichiOnSitePaymentDiscountType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiOnSitePaymentDiscountTypeByPkArgs, 'pikaichiDiscountTypeId'>>;
  pikaichiOnSitePaymentDiscountType_stream?: SubscriptionResolver<Array<ResolversTypes['pikaichiOnSitePaymentDiscountType']>, "pikaichiOnSitePaymentDiscountType_stream", ParentType, ContextType, RequireFields<SubscriptionRootPikaichiOnSitePaymentDiscountTypeStreamArgs, 'batch_size' | 'cursor'>>;
  plan?: SubscriptionResolver<Array<ResolversTypes['plan']>, "plan", ParentType, ContextType, Partial<SubscriptionRootPlanArgs>>;
  planChoice?: SubscriptionResolver<Array<ResolversTypes['planChoice']>, "planChoice", ParentType, ContextType, Partial<SubscriptionRootPlanChoiceArgs>>;
  planChoice_aggregate?: SubscriptionResolver<ResolversTypes['planChoice_aggregate'], "planChoice_aggregate", ParentType, ContextType, Partial<SubscriptionRootPlanChoiceAggregateArgs>>;
  planChoice_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['planChoice']>, "planChoice_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPlanChoiceByPkArgs, 'serial'>>;
  planChoice_stream?: SubscriptionResolver<Array<ResolversTypes['planChoice']>, "planChoice_stream", ParentType, ContextType, RequireFields<SubscriptionRootPlanChoiceStreamArgs, 'batch_size' | 'cursor'>>;
  planFirstOrderCategoryMenu?: SubscriptionResolver<Array<ResolversTypes['planFirstOrderCategoryMenu']>, "planFirstOrderCategoryMenu", ParentType, ContextType, Partial<SubscriptionRootPlanFirstOrderCategoryMenuArgs>>;
  planFirstOrderCategoryMenu_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['planFirstOrderCategoryMenu']>, "planFirstOrderCategoryMenu_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPlanFirstOrderCategoryMenuByPkArgs, 'id'>>;
  planFirstOrderCategoryMenu_stream?: SubscriptionResolver<Array<ResolversTypes['planFirstOrderCategoryMenu']>, "planFirstOrderCategoryMenu_stream", ParentType, ContextType, RequireFields<SubscriptionRootPlanFirstOrderCategoryMenuStreamArgs, 'batch_size' | 'cursor'>>;
  planGroup?: SubscriptionResolver<Array<ResolversTypes['planGroup']>, "planGroup", ParentType, ContextType, Partial<SubscriptionRootPlanGroupArgs>>;
  planGroup_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['planGroup']>, "planGroup_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPlanGroupByPkArgs, 'serial'>>;
  planGroup_stream?: SubscriptionResolver<Array<ResolversTypes['planGroup']>, "planGroup_stream", ParentType, ContextType, RequireFields<SubscriptionRootPlanGroupStreamArgs, 'batch_size' | 'cursor'>>;
  planMenuCategory?: SubscriptionResolver<Array<ResolversTypes['planMenuCategory']>, "planMenuCategory", ParentType, ContextType, Partial<SubscriptionRootPlanMenuCategoryArgs>>;
  planMenuCategory_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['planMenuCategory']>, "planMenuCategory_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPlanMenuCategoryByPkArgs, 'id'>>;
  planMenuCategory_stream?: SubscriptionResolver<Array<ResolversTypes['planMenuCategory']>, "planMenuCategory_stream", ParentType, ContextType, RequireFields<SubscriptionRootPlanMenuCategoryStreamArgs, 'batch_size' | 'cursor'>>;
  planOption?: SubscriptionResolver<Array<ResolversTypes['planOption']>, "planOption", ParentType, ContextType, Partial<SubscriptionRootPlanOptionArgs>>;
  planOption_aggregate?: SubscriptionResolver<ResolversTypes['planOption_aggregate'], "planOption_aggregate", ParentType, ContextType, Partial<SubscriptionRootPlanOptionAggregateArgs>>;
  planOption_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['planOption']>, "planOption_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPlanOptionByPkArgs, 'serial'>>;
  planOption_stream?: SubscriptionResolver<Array<ResolversTypes['planOption']>, "planOption_stream", ParentType, ContextType, RequireFields<SubscriptionRootPlanOptionStreamArgs, 'batch_size' | 'cursor'>>;
  plan_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['plan']>, "plan_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootPlanByPkArgs, 'serial'>>;
  plan_menu_categories_category?: SubscriptionResolver<Array<ResolversTypes['plan_menu_categories_category']>, "plan_menu_categories_category", ParentType, ContextType, Partial<SubscriptionRootPlanMenuCategoriesCategoryArgs>>;
  plan_menu_categories_category_stream?: SubscriptionResolver<Array<ResolversTypes['plan_menu_categories_category']>, "plan_menu_categories_category_stream", ParentType, ContextType, RequireFields<SubscriptionRootPlanMenuCategoriesCategoryStreamArgs, 'batch_size' | 'cursor'>>;
  plan_stream?: SubscriptionResolver<Array<ResolversTypes['plan']>, "plan_stream", ParentType, ContextType, RequireFields<SubscriptionRootPlanStreamArgs, 'batch_size' | 'cursor'>>;
  question?: SubscriptionResolver<Array<ResolversTypes['question']>, "question", ParentType, ContextType, Partial<SubscriptionRootQuestionArgs>>;
  questionAnswer?: SubscriptionResolver<Array<ResolversTypes['questionAnswer']>, "questionAnswer", ParentType, ContextType, Partial<SubscriptionRootQuestionAnswerArgs>>;
  questionAnswer_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['questionAnswer']>, "questionAnswer_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootQuestionAnswerByPkArgs, 'id'>>;
  questionAnswer_stream?: SubscriptionResolver<Array<ResolversTypes['questionAnswer']>, "questionAnswer_stream", ParentType, ContextType, RequireFields<SubscriptionRootQuestionAnswerStreamArgs, 'batch_size' | 'cursor'>>;
  question_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['question']>, "question_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootQuestionByPkArgs, 'id'>>;
  question_stream?: SubscriptionResolver<Array<ResolversTypes['question']>, "question_stream", ParentType, ContextType, RequireFields<SubscriptionRootQuestionStreamArgs, 'batch_size' | 'cursor'>>;
  questionnaire?: SubscriptionResolver<Array<ResolversTypes['questionnaire']>, "questionnaire", ParentType, ContextType, Partial<SubscriptionRootQuestionnaireArgs>>;
  questionnaireAnswer?: SubscriptionResolver<Array<ResolversTypes['questionnaireAnswer']>, "questionnaireAnswer", ParentType, ContextType, Partial<SubscriptionRootQuestionnaireAnswerArgs>>;
  questionnaireAnswer_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['questionnaireAnswer']>, "questionnaireAnswer_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootQuestionnaireAnswerByPkArgs, 'id'>>;
  questionnaireAnswer_stream?: SubscriptionResolver<Array<ResolversTypes['questionnaireAnswer']>, "questionnaireAnswer_stream", ParentType, ContextType, RequireFields<SubscriptionRootQuestionnaireAnswerStreamArgs, 'batch_size' | 'cursor'>>;
  questionnaireConfig?: SubscriptionResolver<Array<ResolversTypes['questionnaireConfig']>, "questionnaireConfig", ParentType, ContextType, Partial<SubscriptionRootQuestionnaireConfigArgs>>;
  questionnaireConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['questionnaireConfig']>, "questionnaireConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootQuestionnaireConfigByPkArgs, 'id'>>;
  questionnaireConfig_stream?: SubscriptionResolver<Array<ResolversTypes['questionnaireConfig']>, "questionnaireConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootQuestionnaireConfigStreamArgs, 'batch_size' | 'cursor'>>;
  questionnaireQuestion?: SubscriptionResolver<Array<ResolversTypes['questionnaireQuestion']>, "questionnaireQuestion", ParentType, ContextType, Partial<SubscriptionRootQuestionnaireQuestionArgs>>;
  questionnaireQuestion_stream?: SubscriptionResolver<Array<ResolversTypes['questionnaireQuestion']>, "questionnaireQuestion_stream", ParentType, ContextType, RequireFields<SubscriptionRootQuestionnaireQuestionStreamArgs, 'batch_size' | 'cursor'>>;
  questionnaire_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['questionnaire']>, "questionnaire_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootQuestionnaireByPkArgs, 'id'>>;
  questionnaire_stream?: SubscriptionResolver<Array<ResolversTypes['questionnaire']>, "questionnaire_stream", ParentType, ContextType, RequireFields<SubscriptionRootQuestionnaireStreamArgs, 'batch_size' | 'cursor'>>;
  receipt?: SubscriptionResolver<Array<ResolversTypes['receipt']>, "receipt", ParentType, ContextType, Partial<SubscriptionRootReceiptArgs>>;
  receipt_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['receipt']>, "receipt_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootReceiptByPkArgs, 'receiptId'>>;
  receipt_stream?: SubscriptionResolver<Array<ResolversTypes['receipt']>, "receipt_stream", ParentType, ContextType, RequireFields<SubscriptionRootReceiptStreamArgs, 'batch_size' | 'cursor'>>;
  reservationDashboardAccountAccessibleShop?: SubscriptionResolver<Array<ResolversTypes['reservationDashboardAccountAccessibleShop']>, "reservationDashboardAccountAccessibleShop", ParentType, ContextType, Partial<SubscriptionRootReservationDashboardAccountAccessibleShopArgs>>;
  reservationDashboardAccountAccessibleShop_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['reservationDashboardAccountAccessibleShop']>, "reservationDashboardAccountAccessibleShop_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootReservationDashboardAccountAccessibleShopByPkArgs, 'id'>>;
  reservationDashboardAccountAccessibleShop_stream?: SubscriptionResolver<Array<ResolversTypes['reservationDashboardAccountAccessibleShop']>, "reservationDashboardAccountAccessibleShop_stream", ParentType, ContextType, RequireFields<SubscriptionRootReservationDashboardAccountAccessibleShopStreamArgs, 'batch_size' | 'cursor'>>;
  role?: SubscriptionResolver<Array<ResolversTypes['role']>, "role", ParentType, ContextType, Partial<SubscriptionRootRoleArgs>>;
  roleTablesTable?: SubscriptionResolver<Array<ResolversTypes['roleTablesTable']>, "roleTablesTable", ParentType, ContextType, Partial<SubscriptionRootRoleTablesTableArgs>>;
  roleTablesTable_stream?: SubscriptionResolver<Array<ResolversTypes['roleTablesTable']>, "roleTablesTable_stream", ParentType, ContextType, RequireFields<SubscriptionRootRoleTablesTableStreamArgs, 'batch_size' | 'cursor'>>;
  role_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['role']>, "role_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootRoleByPkArgs, 'serial'>>;
  role_stream?: SubscriptionResolver<Array<ResolversTypes['role']>, "role_stream", ParentType, ContextType, RequireFields<SubscriptionRootRoleStreamArgs, 'batch_size' | 'cursor'>>;
  salesBudgetAllocationSetting?: SubscriptionResolver<Array<ResolversTypes['salesBudgetAllocationSetting']>, "salesBudgetAllocationSetting", ParentType, ContextType, Partial<SubscriptionRootSalesBudgetAllocationSettingArgs>>;
  salesBudgetAllocationSetting_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['salesBudgetAllocationSetting']>, "salesBudgetAllocationSetting_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootSalesBudgetAllocationSettingByPkArgs, 'id'>>;
  salesBudgetAllocationSetting_stream?: SubscriptionResolver<Array<ResolversTypes['salesBudgetAllocationSetting']>, "salesBudgetAllocationSetting_stream", ParentType, ContextType, RequireFields<SubscriptionRootSalesBudgetAllocationSettingStreamArgs, 'batch_size' | 'cursor'>>;
  scoreTypeQuestionAnswer?: SubscriptionResolver<Array<ResolversTypes['scoreTypeQuestionAnswer']>, "scoreTypeQuestionAnswer", ParentType, ContextType, Partial<SubscriptionRootScoreTypeQuestionAnswerArgs>>;
  scoreTypeQuestionAnswer_stream?: SubscriptionResolver<Array<ResolversTypes['scoreTypeQuestionAnswer']>, "scoreTypeQuestionAnswer_stream", ParentType, ContextType, RequireFields<SubscriptionRootScoreTypeQuestionAnswerStreamArgs, 'batch_size' | 'cursor'>>;
  serviceAdmin?: SubscriptionResolver<Array<ResolversTypes['serviceAdmin']>, "serviceAdmin", ParentType, ContextType, Partial<SubscriptionRootServiceAdminArgs>>;
  serviceAdmin_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['serviceAdmin']>, "serviceAdmin_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootServiceAdminByPkArgs, 'id'>>;
  serviceAdmin_stream?: SubscriptionResolver<Array<ResolversTypes['serviceAdmin']>, "serviceAdmin_stream", ParentType, ContextType, RequireFields<SubscriptionRootServiceAdminStreamArgs, 'batch_size' | 'cursor'>>;
  serviceChargeConfig?: SubscriptionResolver<Array<ResolversTypes['serviceChargeConfig']>, "serviceChargeConfig", ParentType, ContextType, Partial<SubscriptionRootServiceChargeConfigArgs>>;
  serviceChargeConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['serviceChargeConfig']>, "serviceChargeConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootServiceChargeConfigByPkArgs, 'id'>>;
  serviceChargeConfig_stream?: SubscriptionResolver<Array<ResolversTypes['serviceChargeConfig']>, "serviceChargeConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootServiceChargeConfigStreamArgs, 'batch_size' | 'cursor'>>;
  serviceChargeMeta?: SubscriptionResolver<Array<ResolversTypes['serviceChargeMeta']>, "serviceChargeMeta", ParentType, ContextType, Partial<SubscriptionRootServiceChargeMetaArgs>>;
  serviceChargeMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['serviceChargeMeta']>, "serviceChargeMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootServiceChargeMetaByPkArgs, 'id'>>;
  serviceChargeMeta_stream?: SubscriptionResolver<Array<ResolversTypes['serviceChargeMeta']>, "serviceChargeMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootServiceChargeMetaStreamArgs, 'batch_size' | 'cursor'>>;
  shop?: SubscriptionResolver<Array<ResolversTypes['shop']>, "shop", ParentType, ContextType, Partial<SubscriptionRootShopArgs>>;
  shopBusinessOperationHour?: SubscriptionResolver<Array<ResolversTypes['shopBusinessOperationHour']>, "shopBusinessOperationHour", ParentType, ContextType, Partial<SubscriptionRootShopBusinessOperationHourArgs>>;
  shopBusinessOperationHour_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopBusinessOperationHour']>, "shopBusinessOperationHour_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopBusinessOperationHourByPkArgs, 'id'>>;
  shopBusinessOperationHour_stream?: SubscriptionResolver<Array<ResolversTypes['shopBusinessOperationHour']>, "shopBusinessOperationHour_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopBusinessOperationHourStreamArgs, 'batch_size' | 'cursor'>>;
  shopChoice?: SubscriptionResolver<Array<ResolversTypes['shopChoice']>, "shopChoice", ParentType, ContextType, Partial<SubscriptionRootShopChoiceArgs>>;
  shopChoice_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopChoice']>, "shopChoice_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopChoiceByPkArgs, 'id'>>;
  shopChoice_stream?: SubscriptionResolver<Array<ResolversTypes['shopChoice']>, "shopChoice_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopChoiceStreamArgs, 'batch_size' | 'cursor'>>;
  shopClerk?: SubscriptionResolver<Array<ResolversTypes['shopClerk']>, "shopClerk", ParentType, ContextType, Partial<SubscriptionRootShopClerkArgs>>;
  shopClerk_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopClerk']>, "shopClerk_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopClerkByPkArgs, '_clerkId' | 'shopId'>>;
  shopClerk_stream?: SubscriptionResolver<Array<ResolversTypes['shopClerk']>, "shopClerk_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopClerkStreamArgs, 'batch_size' | 'cursor'>>;
  shopClient?: SubscriptionResolver<Array<ResolversTypes['shopClient']>, "shopClient", ParentType, ContextType, Partial<SubscriptionRootShopClientArgs>>;
  shopClient_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopClient']>, "shopClient_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopClientByPkArgs, 'shopClientId'>>;
  shopClient_stream?: SubscriptionResolver<Array<ResolversTypes['shopClient']>, "shopClient_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopClientStreamArgs, 'batch_size' | 'cursor'>>;
  shopCookingItemRole?: SubscriptionResolver<Array<ResolversTypes['shopCookingItemRole']>, "shopCookingItemRole", ParentType, ContextType, Partial<SubscriptionRootShopCookingItemRoleArgs>>;
  shopCookingItemRole_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopCookingItemRole']>, "shopCookingItemRole_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopCookingItemRoleByPkArgs, 'id'>>;
  shopCookingItemRole_stream?: SubscriptionResolver<Array<ResolversTypes['shopCookingItemRole']>, "shopCookingItemRole_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopCookingItemRoleStreamArgs, 'batch_size' | 'cursor'>>;
  shopDiscountType?: SubscriptionResolver<Array<ResolversTypes['shopDiscountType']>, "shopDiscountType", ParentType, ContextType, Partial<SubscriptionRootShopDiscountTypeArgs>>;
  shopDiscountType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopDiscountType']>, "shopDiscountType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopDiscountTypeByPkArgs, 'shopId' | 'type'>>;
  shopDiscountType_stream?: SubscriptionResolver<Array<ResolversTypes['shopDiscountType']>, "shopDiscountType_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopDiscountTypeStreamArgs, 'batch_size' | 'cursor'>>;
  shopInflowSourceTag?: SubscriptionResolver<Array<ResolversTypes['shopInflowSourceTag']>, "shopInflowSourceTag", ParentType, ContextType, Partial<SubscriptionRootShopInflowSourceTagArgs>>;
  shopInflowSourceTag_stream?: SubscriptionResolver<Array<ResolversTypes['shopInflowSourceTag']>, "shopInflowSourceTag_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopInflowSourceTagStreamArgs, 'batch_size' | 'cursor'>>;
  shopMember?: SubscriptionResolver<Array<ResolversTypes['shopMember']>, "shopMember", ParentType, ContextType, Partial<SubscriptionRootShopMemberArgs>>;
  shopMember_stream?: SubscriptionResolver<Array<ResolversTypes['shopMember']>, "shopMember_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopMemberStreamArgs, 'batch_size' | 'cursor'>>;
  shopMenu?: SubscriptionResolver<Array<ResolversTypes['shopMenu']>, "shopMenu", ParentType, ContextType, Partial<SubscriptionRootShopMenuArgs>>;
  shopMenuCookingItem?: SubscriptionResolver<Array<ResolversTypes['shopMenuCookingItem']>, "shopMenuCookingItem", ParentType, ContextType, Partial<SubscriptionRootShopMenuCookingItemArgs>>;
  shopMenuCookingItem_stream?: SubscriptionResolver<Array<ResolversTypes['shopMenuCookingItem']>, "shopMenuCookingItem_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopMenuCookingItemStreamArgs, 'batch_size' | 'cursor'>>;
  shopMenuKdDisplayTarget?: SubscriptionResolver<Array<ResolversTypes['shopMenuKdDisplayTarget']>, "shopMenuKdDisplayTarget", ParentType, ContextType, Partial<SubscriptionRootShopMenuKdDisplayTargetArgs>>;
  shopMenuKdDisplayTarget_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopMenuKdDisplayTarget']>, "shopMenuKdDisplayTarget_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopMenuKdDisplayTargetByPkArgs, 'id'>>;
  shopMenuKdDisplayTarget_stream?: SubscriptionResolver<Array<ResolversTypes['shopMenuKdDisplayTarget']>, "shopMenuKdDisplayTarget_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopMenuKdDisplayTargetStreamArgs, 'batch_size' | 'cursor'>>;
  shopMenuKitchenRole?: SubscriptionResolver<Array<ResolversTypes['shopMenuKitchenRole']>, "shopMenuKitchenRole", ParentType, ContextType, Partial<SubscriptionRootShopMenuKitchenRoleArgs>>;
  shopMenuKitchenRole_stream?: SubscriptionResolver<Array<ResolversTypes['shopMenuKitchenRole']>, "shopMenuKitchenRole_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopMenuKitchenRoleStreamArgs, 'batch_size' | 'cursor'>>;
  shopMenu_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopMenu']>, "shopMenu_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopMenuByPkArgs, 'id'>>;
  shopMenu_stream?: SubscriptionResolver<Array<ResolversTypes['shopMenu']>, "shopMenu_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopMenuStreamArgs, 'batch_size' | 'cursor'>>;
  shopOptionKitchenRole?: SubscriptionResolver<Array<ResolversTypes['shopOptionKitchenRole']>, "shopOptionKitchenRole", ParentType, ContextType, Partial<SubscriptionRootShopOptionKitchenRoleArgs>>;
  shopOptionKitchenRole_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopOptionKitchenRole']>, "shopOptionKitchenRole_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopOptionKitchenRoleByPkArgs, 'id'>>;
  shopOptionKitchenRole_stream?: SubscriptionResolver<Array<ResolversTypes['shopOptionKitchenRole']>, "shopOptionKitchenRole_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopOptionKitchenRoleStreamArgs, 'batch_size' | 'cursor'>>;
  shopOrderableTimeTerm?: SubscriptionResolver<Array<ResolversTypes['shopOrderableTimeTerm']>, "shopOrderableTimeTerm", ParentType, ContextType, Partial<SubscriptionRootShopOrderableTimeTermArgs>>;
  shopOrderableTimeTerm_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopOrderableTimeTerm']>, "shopOrderableTimeTerm_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopOrderableTimeTermByPkArgs, 'id'>>;
  shopOrderableTimeTerm_stream?: SubscriptionResolver<Array<ResolversTypes['shopOrderableTimeTerm']>, "shopOrderableTimeTerm_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopOrderableTimeTermStreamArgs, 'batch_size' | 'cursor'>>;
  shopPaymentType?: SubscriptionResolver<Array<ResolversTypes['shopPaymentType']>, "shopPaymentType", ParentType, ContextType, Partial<SubscriptionRootShopPaymentTypeArgs>>;
  shopPaymentType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopPaymentType']>, "shopPaymentType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopPaymentTypeByPkArgs, 'shopId' | 'type'>>;
  shopPaymentType_stream?: SubscriptionResolver<Array<ResolversTypes['shopPaymentType']>, "shopPaymentType_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopPaymentTypeStreamArgs, 'batch_size' | 'cursor'>>;
  shopPlan?: SubscriptionResolver<Array<ResolversTypes['shopPlan']>, "shopPlan", ParentType, ContextType, Partial<SubscriptionRootShopPlanArgs>>;
  shopPlanChoice?: SubscriptionResolver<Array<ResolversTypes['shopPlanChoice']>, "shopPlanChoice", ParentType, ContextType, Partial<SubscriptionRootShopPlanChoiceArgs>>;
  shopPlanChoice_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopPlanChoice']>, "shopPlanChoice_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopPlanChoiceByPkArgs, 'id'>>;
  shopPlanChoice_stream?: SubscriptionResolver<Array<ResolversTypes['shopPlanChoice']>, "shopPlanChoice_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopPlanChoiceStreamArgs, 'batch_size' | 'cursor'>>;
  shopPlanKitchenRole?: SubscriptionResolver<Array<ResolversTypes['shopPlanKitchenRole']>, "shopPlanKitchenRole", ParentType, ContextType, Partial<SubscriptionRootShopPlanKitchenRoleArgs>>;
  shopPlanKitchenRole_stream?: SubscriptionResolver<Array<ResolversTypes['shopPlanKitchenRole']>, "shopPlanKitchenRole_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopPlanKitchenRoleStreamArgs, 'batch_size' | 'cursor'>>;
  shopPlanOption?: SubscriptionResolver<Array<ResolversTypes['shopPlanOption']>, "shopPlanOption", ParentType, ContextType, Partial<SubscriptionRootShopPlanOptionArgs>>;
  shopPlanOptionKitchenRole?: SubscriptionResolver<Array<ResolversTypes['shopPlanOptionKitchenRole']>, "shopPlanOptionKitchenRole", ParentType, ContextType, Partial<SubscriptionRootShopPlanOptionKitchenRoleArgs>>;
  shopPlanOptionKitchenRole_stream?: SubscriptionResolver<Array<ResolversTypes['shopPlanOptionKitchenRole']>, "shopPlanOptionKitchenRole_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopPlanOptionKitchenRoleStreamArgs, 'batch_size' | 'cursor'>>;
  shopPlanOption_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopPlanOption']>, "shopPlanOption_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopPlanOptionByPkArgs, 'id'>>;
  shopPlanOption_stream?: SubscriptionResolver<Array<ResolversTypes['shopPlanOption']>, "shopPlanOption_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopPlanOptionStreamArgs, 'batch_size' | 'cursor'>>;
  shopPlan_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shopPlan']>, "shopPlan_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopPlanByPkArgs, 'id'>>;
  shopPlan_stream?: SubscriptionResolver<Array<ResolversTypes['shopPlan']>, "shopPlan_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopPlanStreamArgs, 'batch_size' | 'cursor'>>;
  shop_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['shop']>, "shop_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootShopByPkArgs, 'shopId'>>;
  shop_stream?: SubscriptionResolver<Array<ResolversTypes['shop']>, "shop_stream", ParentType, ContextType, RequireFields<SubscriptionRootShopStreamArgs, 'batch_size' | 'cursor'>>;
  specificTimeSurchargeConfig?: SubscriptionResolver<Array<ResolversTypes['specificTimeSurchargeConfig']>, "specificTimeSurchargeConfig", ParentType, ContextType, Partial<SubscriptionRootSpecificTimeSurchargeConfigArgs>>;
  specificTimeSurchargeConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['specificTimeSurchargeConfig']>, "specificTimeSurchargeConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootSpecificTimeSurchargeConfigByPkArgs, 'id'>>;
  specificTimeSurchargeConfig_stream?: SubscriptionResolver<Array<ResolversTypes['specificTimeSurchargeConfig']>, "specificTimeSurchargeConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootSpecificTimeSurchargeConfigStreamArgs, 'batch_size' | 'cursor'>>;
  specificTimeSurchargeMeta?: SubscriptionResolver<Array<ResolversTypes['specificTimeSurchargeMeta']>, "specificTimeSurchargeMeta", ParentType, ContextType, Partial<SubscriptionRootSpecificTimeSurchargeMetaArgs>>;
  specificTimeSurchargeMeta_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['specificTimeSurchargeMeta']>, "specificTimeSurchargeMeta_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootSpecificTimeSurchargeMetaByPkArgs, 'id'>>;
  specificTimeSurchargeMeta_stream?: SubscriptionResolver<Array<ResolversTypes['specificTimeSurchargeMeta']>, "specificTimeSurchargeMeta_stream", ParentType, ContextType, RequireFields<SubscriptionRootSpecificTimeSurchargeMetaStreamArgs, 'batch_size' | 'cursor'>>;
  steraOnSitePaymentDetailType?: SubscriptionResolver<Array<ResolversTypes['steraOnSitePaymentDetailType']>, "steraOnSitePaymentDetailType", ParentType, ContextType, Partial<SubscriptionRootSteraOnSitePaymentDetailTypeArgs>>;
  steraOnSitePaymentDetailType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['steraOnSitePaymentDetailType']>, "steraOnSitePaymentDetailType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootSteraOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  steraOnSitePaymentDetailType_stream?: SubscriptionResolver<Array<ResolversTypes['steraOnSitePaymentDetailType']>, "steraOnSitePaymentDetailType_stream", ParentType, ContextType, RequireFields<SubscriptionRootSteraOnSitePaymentDetailTypeStreamArgs, 'batch_size' | 'cursor'>>;
  stock?: SubscriptionResolver<Array<ResolversTypes['stock']>, "stock", ParentType, ContextType, Partial<SubscriptionRootStockArgs>>;
  stock_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['stock']>, "stock_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootStockByPkArgs, 'id'>>;
  stock_stream?: SubscriptionResolver<Array<ResolversTypes['stock']>, "stock_stream", ParentType, ContextType, RequireFields<SubscriptionRootStockStreamArgs, 'batch_size' | 'cursor'>>;
  supportedLocale?: SubscriptionResolver<Array<ResolversTypes['supportedLocale']>, "supportedLocale", ParentType, ContextType, Partial<SubscriptionRootSupportedLocaleArgs>>;
  supportedLocale_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['supportedLocale']>, "supportedLocale_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootSupportedLocaleByPkArgs, 'value'>>;
  supportedLocale_stream?: SubscriptionResolver<Array<ResolversTypes['supportedLocale']>, "supportedLocale_stream", ParentType, ContextType, RequireFields<SubscriptionRootSupportedLocaleStreamArgs, 'batch_size' | 'cursor'>>;
  table?: SubscriptionResolver<Array<ResolversTypes['table']>, "table", ParentType, ContextType, Partial<SubscriptionRootTableArgs>>;
  tableArea?: SubscriptionResolver<Array<ResolversTypes['tableArea']>, "tableArea", ParentType, ContextType, Partial<SubscriptionRootTableAreaArgs>>;
  tableArea_aggregate?: SubscriptionResolver<ResolversTypes['tableArea_aggregate'], "tableArea_aggregate", ParentType, ContextType, Partial<SubscriptionRootTableAreaAggregateArgs>>;
  tableArea_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tableArea']>, "tableArea_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTableAreaByPkArgs, 'id'>>;
  tableArea_stream?: SubscriptionResolver<Array<ResolversTypes['tableArea']>, "tableArea_stream", ParentType, ContextType, RequireFields<SubscriptionRootTableAreaStreamArgs, 'batch_size' | 'cursor'>>;
  tableUser?: SubscriptionResolver<Array<ResolversTypes['tableUser']>, "tableUser", ParentType, ContextType, Partial<SubscriptionRootTableUserArgs>>;
  tableUserCustomer?: SubscriptionResolver<Array<ResolversTypes['tableUserCustomer']>, "tableUserCustomer", ParentType, ContextType, Partial<SubscriptionRootTableUserCustomerArgs>>;
  tableUserCustomer_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tableUserCustomer']>, "tableUserCustomer_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTableUserCustomerByPkArgs, 'id'>>;
  tableUserCustomer_stream?: SubscriptionResolver<Array<ResolversTypes['tableUserCustomer']>, "tableUserCustomer_stream", ParentType, ContextType, RequireFields<SubscriptionRootTableUserCustomerStreamArgs, 'batch_size' | 'cursor'>>;
  tableUser_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tableUser']>, "tableUser_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTableUserByPkArgs, 'id'>>;
  tableUser_stream?: SubscriptionResolver<Array<ResolversTypes['tableUser']>, "tableUser_stream", ParentType, ContextType, RequireFields<SubscriptionRootTableUserStreamArgs, 'batch_size' | 'cursor'>>;
  table_aggregate?: SubscriptionResolver<ResolversTypes['table_aggregate'], "table_aggregate", ParentType, ContextType, Partial<SubscriptionRootTableAggregateArgs>>;
  table_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['table']>, "table_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTableByPkArgs, 'serial'>>;
  table_stream?: SubscriptionResolver<Array<ResolversTypes['table']>, "table_stream", ParentType, ContextType, RequireFields<SubscriptionRootTableStreamArgs, 'batch_size' | 'cursor'>>;
  taxOffice?: SubscriptionResolver<Array<ResolversTypes['taxOffice']>, "taxOffice", ParentType, ContextType, Partial<SubscriptionRootTaxOfficeArgs>>;
  taxOffice_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['taxOffice']>, "taxOffice_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTaxOfficeByPkArgs, 'id'>>;
  taxOffice_stream?: SubscriptionResolver<Array<ResolversTypes['taxOffice']>, "taxOffice_stream", ParentType, ContextType, RequireFields<SubscriptionRootTaxOfficeStreamArgs, 'batch_size' | 'cursor'>>;
  tecAggregationConfig?: SubscriptionResolver<Array<ResolversTypes['tecAggregationConfig']>, "tecAggregationConfig", ParentType, ContextType, Partial<SubscriptionRootTecAggregationConfigArgs>>;
  tecAggregationConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tecAggregationConfig']>, "tecAggregationConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTecAggregationConfigByPkArgs, 'id'>>;
  tecAggregationConfig_stream?: SubscriptionResolver<Array<ResolversTypes['tecAggregationConfig']>, "tecAggregationConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootTecAggregationConfigStreamArgs, 'batch_size' | 'cursor'>>;
  tecAggregationMediaMap?: SubscriptionResolver<Array<ResolversTypes['tecAggregationMediaMap']>, "tecAggregationMediaMap", ParentType, ContextType, Partial<SubscriptionRootTecAggregationMediaMapArgs>>;
  tecAggregationMediaMap_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tecAggregationMediaMap']>, "tecAggregationMediaMap_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTecAggregationMediaMapByPkArgs, 'id'>>;
  tecAggregationMediaMap_stream?: SubscriptionResolver<Array<ResolversTypes['tecAggregationMediaMap']>, "tecAggregationMediaMap_stream", ParentType, ContextType, RequireFields<SubscriptionRootTecAggregationMediaMapStreamArgs, 'batch_size' | 'cursor'>>;
  tecAggregationMenu?: SubscriptionResolver<Array<ResolversTypes['tecAggregationMenu']>, "tecAggregationMenu", ParentType, ContextType, Partial<SubscriptionRootTecAggregationMenuArgs>>;
  tecAggregationMenu_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tecAggregationMenu']>, "tecAggregationMenu_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTecAggregationMenuByPkArgs, 'id'>>;
  tecAggregationMenu_stream?: SubscriptionResolver<Array<ResolversTypes['tecAggregationMenu']>, "tecAggregationMenu_stream", ParentType, ContextType, RequireFields<SubscriptionRootTecAggregationMenuStreamArgs, 'batch_size' | 'cursor'>>;
  tecAggregationOnSitePaymentDetailType?: SubscriptionResolver<Array<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, "tecAggregationOnSitePaymentDetailType", ParentType, ContextType, Partial<SubscriptionRootTecAggregationOnSitePaymentDetailTypeArgs>>;
  tecAggregationOnSitePaymentDetailType_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, "tecAggregationOnSitePaymentDetailType_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTecAggregationOnSitePaymentDetailTypeByPkArgs, 'id'>>;
  tecAggregationOnSitePaymentDetailType_stream?: SubscriptionResolver<Array<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, "tecAggregationOnSitePaymentDetailType_stream", ParentType, ContextType, RequireFields<SubscriptionRootTecAggregationOnSitePaymentDetailTypeStreamArgs, 'batch_size' | 'cursor'>>;
  textTypeQuestionAnswer?: SubscriptionResolver<Array<ResolversTypes['textTypeQuestionAnswer']>, "textTypeQuestionAnswer", ParentType, ContextType, Partial<SubscriptionRootTextTypeQuestionAnswerArgs>>;
  textTypeQuestionAnswer_stream?: SubscriptionResolver<Array<ResolversTypes['textTypeQuestionAnswer']>, "textTypeQuestionAnswer_stream", ParentType, ContextType, RequireFields<SubscriptionRootTextTypeQuestionAnswerStreamArgs, 'batch_size' | 'cursor'>>;
  translation?: SubscriptionResolver<Array<ResolversTypes['translation']>, "translation", ParentType, ContextType, Partial<SubscriptionRootTranslationArgs>>;
  translation_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['translation']>, "translation_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootTranslationByPkArgs, 'id'>>;
  translation_stream?: SubscriptionResolver<Array<ResolversTypes['translation']>, "translation_stream", ParentType, ContextType, RequireFields<SubscriptionRootTranslationStreamArgs, 'batch_size' | 'cursor'>>;
  winboardConfig?: SubscriptionResolver<Array<ResolversTypes['winboardConfig']>, "winboardConfig", ParentType, ContextType, Partial<SubscriptionRootWinboardConfigArgs>>;
  winboardConfig_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['winboardConfig']>, "winboardConfig_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootWinboardConfigByPkArgs, 'winboardConfigId'>>;
  winboardConfig_stream?: SubscriptionResolver<Array<ResolversTypes['winboardConfig']>, "winboardConfig_stream", ParentType, ContextType, RequireFields<SubscriptionRootWinboardConfigStreamArgs, 'batch_size' | 'cursor'>>;
  winboardInflowSourceTag?: SubscriptionResolver<Array<ResolversTypes['winboardInflowSourceTag']>, "winboardInflowSourceTag", ParentType, ContextType, Partial<SubscriptionRootWinboardInflowSourceTagArgs>>;
  winboardInflowSourceTag_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['winboardInflowSourceTag']>, "winboardInflowSourceTag_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootWinboardInflowSourceTagByPkArgs, 'id'>>;
  winboardInflowSourceTag_stream?: SubscriptionResolver<Array<ResolversTypes['winboardInflowSourceTag']>, "winboardInflowSourceTag_stream", ParentType, ContextType, RequireFields<SubscriptionRootWinboardInflowSourceTagStreamArgs, 'batch_size' | 'cursor'>>;
  winboardMenu?: SubscriptionResolver<Array<ResolversTypes['winboardMenu']>, "winboardMenu", ParentType, ContextType, Partial<SubscriptionRootWinboardMenuArgs>>;
  winboardMenu_by_pk?: SubscriptionResolver<Maybe<ResolversTypes['winboardMenu']>, "winboardMenu_by_pk", ParentType, ContextType, RequireFields<SubscriptionRootWinboardMenuByPkArgs, 'id'>>;
  winboardMenu_stream?: SubscriptionResolver<Array<ResolversTypes['winboardMenu']>, "winboardMenu_stream", ParentType, ContextType, RequireFields<SubscriptionRootWinboardMenuStreamArgs, 'batch_size' | 'cursor'>>;
};

export type SupportedLocaleResolvers<ContextType = any, ParentType extends ResolversParentTypes['supportedLocale'] = ResolversParentTypes['supportedLocale']> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableResolvers<ContextType = any, ParentType extends ResolversParentTypes['table'] = ResolversParentTypes['table']> = {
  code?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  dishUpSlipGroupTables?: Resolver<Array<ResolversTypes['dishUpSlipGroupTables']>, ParentType, ContextType, Partial<TableDishUpSlipGroupTablesArgs>>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentReceiptRoleTables?: Resolver<Array<ResolversTypes['paymentReceiptRoleTable']>, ParentType, ContextType, Partial<TablePaymentReceiptRoleTablesArgs>>;
  posTableId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roleTablesTables?: Resolver<Array<ResolversTypes['roleTablesTable']>, ParentType, ContextType, Partial<TableRoleTablesTablesArgs>>;
  serial?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tableArea?: Resolver<ResolversTypes['tableArea'], ParentType, ContextType>;
  tableAreaId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tableId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tableUsers?: Resolver<Array<ResolversTypes['tableUser']>, ParentType, ContextType, Partial<TableTableUsersArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea'] = ResolversParentTypes['tableArea']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tables?: Resolver<Array<ResolversTypes['table']>, ParentType, ContextType, Partial<TableAreaTablesArgs>>;
  tables_aggregate?: Resolver<ResolversTypes['table_aggregate'], ParentType, ContextType, Partial<TableAreaTablesAggregateArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_aggregate'] = ResolversParentTypes['tableArea_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['tableArea_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['tableArea']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_aggregate_fields'] = ResolversParentTypes['tableArea_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['tableArea_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<TableAreaAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['tableArea_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['tableArea_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['tableArea_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['tableArea_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['tableArea_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['tableArea_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['tableArea_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['tableArea_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['tableArea_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_avg_fields'] = ResolversParentTypes['tableArea_avg_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_max_fields'] = ResolversParentTypes['tableArea_max_fields']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_min_fields'] = ResolversParentTypes['tableArea_min_fields']> = {
  archivedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_mutation_response'] = ResolversParentTypes['tableArea_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['tableArea']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_stddev_fields'] = ResolversParentTypes['tableArea_stddev_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_stddev_pop_fields'] = ResolversParentTypes['tableArea_stddev_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_stddev_samp_fields'] = ResolversParentTypes['tableArea_stddev_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_sum_fields'] = ResolversParentTypes['tableArea_sum_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_var_pop_fields'] = ResolversParentTypes['tableArea_var_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_var_samp_fields'] = ResolversParentTypes['tableArea_var_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAreaVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableArea_variance_fields'] = ResolversParentTypes['tableArea_variance_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableUser'] = ResolversParentTypes['tableUser']> = {
  _tableId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  activatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  activePlans?: Resolver<Array<ResolversTypes['activePlan']>, ParentType, ContextType, Partial<TableUserActivePlansArgs>>;
  billableTableUserId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  checkInEvents?: Resolver<Array<ResolversTypes['checkInEvent']>, ParentType, ContextType, Partial<TableUserCheckInEventsArgs>>;
  coineyPayments?: Resolver<Array<ResolversTypes['coineyPayment']>, ParentType, ContextType, Partial<TableUserCoineyPaymentsArgs>>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  customerSegment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deactivatedAt?: Resolver<Maybe<ResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inflowSourceTag?: Resolver<ResolversTypes['inflowSourceTag'], ParentType, ContextType>;
  inflowSourceTagId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  numPeople?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  onSitePaymentTableUsers?: Resolver<Array<ResolversTypes['onSitePaymentTableUsers']>, ParentType, ContextType, Partial<TableUserOnSitePaymentTableUsersArgs>>;
  orders?: Resolver<Array<ResolversTypes['order']>, ParentType, ContextType, Partial<TableUserOrdersArgs>>;
  ordersByOrderedtableuserid?: Resolver<Array<ResolversTypes['order']>, ParentType, ContextType, Partial<TableUserOrdersByOrderedtableuseridArgs>>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['tableUserState_enum'], ParentType, ContextType>;
  table?: Resolver<ResolversTypes['table'], ParentType, ContextType>;
  tableClearReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tableClearedClerk?: Resolver<Maybe<ResolversTypes['clerk']>, ParentType, ContextType>;
  tableClearedClerkId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tableId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tableUserCustomers?: Resolver<Array<ResolversTypes['tableUserCustomer']>, ParentType, ContextType, Partial<TableUserTableUserCustomersArgs>>;
  tableUsersInGroup?: Resolver<Array<ResolversTypes['tableUser']>, ParentType, ContextType, Partial<TableUserTableUsersInGroupArgs>>;
  taxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxIncludedPaidAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableUserCustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['tableUserCustomer'] = ResolversParentTypes['tableUserCustomer']> = {
  customer?: Resolver<ResolversTypes['customer'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tableUser?: Resolver<ResolversTypes['tableUser'], ParentType, ContextType>;
  tableUserId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_aggregate'] = ResolversParentTypes['table_aggregate']> = {
  aggregate?: Resolver<Maybe<ResolversTypes['table_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['table']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAggregateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_aggregate_fields'] = ResolversParentTypes['table_aggregate_fields']> = {
  avg?: Resolver<Maybe<ResolversTypes['table_avg_fields']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<TableAggregateFieldsCountArgs>>;
  max?: Resolver<Maybe<ResolversTypes['table_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['table_min_fields']>, ParentType, ContextType>;
  stddev?: Resolver<Maybe<ResolversTypes['table_stddev_fields']>, ParentType, ContextType>;
  stddev_pop?: Resolver<Maybe<ResolversTypes['table_stddev_pop_fields']>, ParentType, ContextType>;
  stddev_samp?: Resolver<Maybe<ResolversTypes['table_stddev_samp_fields']>, ParentType, ContextType>;
  sum?: Resolver<Maybe<ResolversTypes['table_sum_fields']>, ParentType, ContextType>;
  var_pop?: Resolver<Maybe<ResolversTypes['table_var_pop_fields']>, ParentType, ContextType>;
  var_samp?: Resolver<Maybe<ResolversTypes['table_var_samp_fields']>, ParentType, ContextType>;
  variance?: Resolver<Maybe<ResolversTypes['table_variance_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableAvgFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_avg_fields'] = ResolversParentTypes['table_avg_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableMaxFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_max_fields'] = ResolversParentTypes['table_max_fields']> = {
  code?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posTableId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tableAreaId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tableId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableMinFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_min_fields'] = ResolversParentTypes['table_min_fields']> = {
  code?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posTableId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shopId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tableAreaId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tableId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_mutation_response'] = ResolversParentTypes['table_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['table']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableStddevFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_stddev_fields'] = ResolversParentTypes['table_stddev_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableStddevPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_stddev_pop_fields'] = ResolversParentTypes['table_stddev_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableStddevSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_stddev_samp_fields'] = ResolversParentTypes['table_stddev_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableSumFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_sum_fields'] = ResolversParentTypes['table_sum_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tableId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableVarPopFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_var_pop_fields'] = ResolversParentTypes['table_var_pop_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableVarSampFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_var_samp_fields'] = ResolversParentTypes['table_var_samp_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TableVarianceFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['table_variance_fields'] = ResolversParentTypes['table_variance_fields']> = {
  priority?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  serial?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tableId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxOfficeResolvers<ContextType = any, ParentType extends ResolversParentTypes['taxOffice'] = ResolversParentTypes['taxOffice']> = {
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TecAggregationConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['tecAggregationConfig'] = ResolversParentTypes['tecAggregationConfig']> = {
  checkDatePutPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ftpHost?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ftpPassword?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ftpPort?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ftpUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  integrationType?: Resolver<ResolversTypes['tecAggregationIntegrationType_enum'], ParentType, ContextType>;
  posNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  salesDataPutPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TecAggregationConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['tecAggregationConfig_mutation_response'] = ResolversParentTypes['tecAggregationConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['tecAggregationConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TecAggregationMediaMapResolvers<ContextType = any, ParentType extends ResolversParentTypes['tecAggregationMediaMap'] = ResolversParentTypes['tecAggregationMediaMap']> = {
  aggregationNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  mediaName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tecAggregationOnSitePaymentDetailTypes?: Resolver<Array<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, ParentType, ContextType, Partial<TecAggregationMediaMapTecAggregationOnSitePaymentDetailTypesArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TecAggregationMediaMapMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['tecAggregationMediaMap_mutation_response'] = ResolversParentTypes['tecAggregationMediaMap_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['tecAggregationMediaMap']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TecAggregationMenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['tecAggregationMenu'] = ResolversParentTypes['tecAggregationMenu']> = {
  _choiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _planChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _planId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  choice?: Resolver<Maybe<ResolversTypes['choice']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  dpCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dpName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gpCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gpName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<Maybe<ResolversTypes['menu']>, ParentType, ContextType>;
  menuCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  menuId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['plan']>, ParentType, ContextType>;
  planChoice?: Resolver<Maybe<ResolversTypes['planChoice']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  planId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TecAggregationMenuMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['tecAggregationMenu_mutation_response'] = ResolversParentTypes['tecAggregationMenu_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['tecAggregationMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TecAggregationOnSitePaymentDetailTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['tecAggregationOnSitePaymentDetailType'] = ResolversParentTypes['tecAggregationOnSitePaymentDetailType']> = {
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  mediaCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mediaName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDetailType?: Resolver<ResolversTypes['onSitePaymentDetailType'], ParentType, ContextType>;
  onSitePaymentDetailTypeEntityType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  onSitePaymentDetailTypeId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  paymentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shop?: Resolver<Maybe<ResolversTypes['shop']>, ParentType, ContextType>;
  shopId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tecAggregationMediaMap?: Resolver<Maybe<ResolversTypes['tecAggregationMediaMap']>, ParentType, ContextType>;
  tecAggregationMediaMapId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TecAggregationOnSitePaymentDetailTypeMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['tecAggregationOnSitePaymentDetailType_mutation_response'] = ResolversParentTypes['tecAggregationOnSitePaymentDetailType_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['tecAggregationOnSitePaymentDetailType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextTypeQuestionAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['textTypeQuestionAnswer'] = ResolversParentTypes['textTypeQuestionAnswer']> = {
  questionAnswer?: Resolver<Maybe<ResolversTypes['questionAnswer']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['time'], any> {
  name: 'time';
}

export interface TimestamptzScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz';
}

export interface TimetzScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['timetz'], any> {
  name: 'timetz';
}

export type TranslationResolvers<ContextType = any, ParentType extends ResolversParentTypes['translation'] = ResolversParentTypes['translation']> = {
  columnName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['company'], ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['supportedLocale_enum'], ParentType, ContextType>;
  recordId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tableName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TranslationMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['translation_mutation_response'] = ResolversParentTypes['translation_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['translation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid';
}

export type WinboardConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['winboardConfig'] = ResolversParentTypes['winboardConfig']> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ftpHost?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ftpPassword?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ftpPort?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ftpUser?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  putPath?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shop?: Resolver<ResolversTypes['shop'], ParentType, ContextType>;
  shopId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  winboardCompanyCd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  winboardConfigId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  winboardPosNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  winboardShopCd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WinboardConfigMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['winboardConfig_mutation_response'] = ResolversParentTypes['winboardConfig_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['winboardConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WinboardInflowSourceTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['winboardInflowSourceTag'] = ResolversParentTypes['winboardInflowSourceTag']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  inflowSourceTag?: Resolver<ResolversTypes['inflowSourceTag'], ParentType, ContextType>;
  inflowSourceTagId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WinboardInflowSourceTagMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['winboardInflowSourceTag_mutation_response'] = ResolversParentTypes['winboardInflowSourceTag_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['winboardInflowSourceTag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WinboardMenuResolvers<ContextType = any, ParentType extends ResolversParentTypes['winboardMenu'] = ResolversParentTypes['winboardMenu']> = {
  _choiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _menuId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _planChoiceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  _planId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bumonCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bumonName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categoryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  choice?: Resolver<Maybe<ResolversTypes['choice']>, ParentType, ContextType>;
  choiceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  menu?: Resolver<Maybe<ResolversTypes['menu']>, ParentType, ContextType>;
  menuId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['plan']>, ParentType, ContextType>;
  planChoice?: Resolver<Maybe<ResolversTypes['planChoice']>, ParentType, ContextType>;
  planChoiceId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  planId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WinboardMenuMutationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['winboardMenu_mutation_response'] = ResolversParentTypes['winboardMenu_mutation_response']> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['winboardMenu']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AdyenPaymentCreateStoreMerchantCategoryResult?: AdyenPaymentCreateStoreMerchantCategoryResultResolvers<ContextType>;
  AdyenPaymentUpdateStoreResult?: AdyenPaymentUpdateStoreResultResolvers<ContextType>;
  AggregatedCategory?: AggregatedCategoryResolvers<ContextType>;
  AggregatedChoice?: AggregatedChoiceResolvers<ContextType>;
  AggregatedMenu?: AggregatedMenuResolvers<ContextType>;
  AggregatedOption?: AggregatedOptionResolvers<ContextType>;
  AggregatedPlan?: AggregatedPlanResolvers<ContextType>;
  AggregatedPlanOption?: AggregatedPlanOptionResolvers<ContextType>;
  AggregatedQuestionnaireScore?: AggregatedQuestionnaireScoreResolvers<ContextType>;
  AggregatedSales?: AggregatedSalesResolvers<ContextType>;
  AmbassadorTransition?: AmbassadorTransitionResolvers<ContextType>;
  ArchiveMenuResult?: ArchiveMenuResultResolvers<ContextType>;
  ArchivePlanResult?: ArchivePlanResultResolvers<ContextType>;
  AverageAndMaxSalesByDayOfWeek?: AverageAndMaxSalesByDayOfWeekResolvers<ContextType>;
  CashBalanceCashDenominationCount?: CashBalanceCashDenominationCountResolvers<ContextType>;
  CashManagement?: CashManagementResolvers<ContextType>;
  CompanyCustomerSummary?: CompanyCustomerSummaryResolvers<ContextType>;
  CompanySalesAnalyticsOutput?: CompanySalesAnalyticsOutputResolvers<ContextType>;
  CompanySalesAnalyticsOutputItem?: CompanySalesAnalyticsOutputItemResolvers<ContextType>;
  CouponAnalyticsPerCoupon?: CouponAnalyticsPerCouponResolvers<ContextType>;
  CouponAnalyticsPerCouponItem?: CouponAnalyticsPerCouponItemResolvers<ContextType>;
  CouponAnalyticsSummary?: CouponAnalyticsSummaryResolvers<ContextType>;
  CreateCategoryOutput?: CreateCategoryOutputResolvers<ContextType>;
  CreateMenuOutput?: CreateMenuOutputResolvers<ContextType>;
  CreateMenuRecommendationOutput?: CreateMenuRecommendationOutputResolvers<ContextType>;
  CreateOptionOutput?: CreateOptionOutputResolvers<ContextType>;
  CreatePlanChoiceOutput?: CreatePlanChoiceOutputResolvers<ContextType>;
  CreatePlanOptionOutput?: CreatePlanOptionOutputResolvers<ContextType>;
  CreatePlanOutput?: CreatePlanOutputResolvers<ContextType>;
  CreateShopMenusResult?: CreateShopMenusResultResolvers<ContextType>;
  CreateShopPlanResult?: CreateShopPlanResultResolvers<ContextType>;
  CreateSurchargeConfigsResult?: CreateSurchargeConfigsResultResolvers<ContextType>;
  CumulativeCount?: CumulativeCountResolvers<ContextType>;
  CurrentSales?: CurrentSalesResolvers<ContextType>;
  CurrentSaleses?: CurrentSalesesResolvers<ContextType>;
  CustomerCountTransition?: CustomerCountTransitionResolvers<ContextType>;
  CustomerDashboardKpi?: CustomerDashboardKpiResolvers<ContextType>;
  CustomerDashboardKpis?: CustomerDashboardKpisResolvers<ContextType>;
  CustomerDashboardTransitions?: CustomerDashboardTransitionsResolvers<ContextType>;
  CustomerFaveYellRanking?: CustomerFaveYellRankingResolvers<ContextType>;
  CustomerFaveYellRankingCustomer?: CustomerFaveYellRankingCustomerResolvers<ContextType>;
  CustomerRanking?: CustomerRankingResolvers<ContextType>;
  CustomerRankingCustomer?: CustomerRankingCustomerResolvers<ContextType>;
  DailyCashRegisterBalancing?: DailyCashRegisterBalancingResolvers<ContextType>;
  DailySalesAmount?: DailySalesAmountResolvers<ContextType>;
  DailySalesSummary?: DailySalesSummaryResolvers<ContextType>;
  DashboardAccountCreateOrUpdateGmoBankAccountResult?: DashboardAccountCreateOrUpdateGmoBankAccountResultResolvers<ContextType>;
  DashboardAccountCreateTecAggregationMenuOutput?: DashboardAccountCreateTecAggregationMenuOutputResolvers<ContextType>;
  DashboardAccountGmoBankAccountDetailResult?: DashboardAccountGmoBankAccountDetailResultResolvers<ContextType>;
  DashboardAccountGmoShopConfigDetailOutput?: DashboardAccountGmoShopConfigDetailOutputResolvers<ContextType>;
  DashboardAccountUpsertDiniiPayConfigOutput?: DashboardAccountUpsertDiniiPayConfigOutputResolvers<ContextType>;
  DashboardAccountUpsertTecAggregationMenusBulkOutput?: DashboardAccountUpsertTecAggregationMenusBulkOutputResolvers<ContextType>;
  DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode?: DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCodeResolvers<ContextType>;
  DashboardUpsertTecAggregationMenuSuccess?: DashboardUpsertTecAggregationMenuSuccessResolvers<ContextType>;
  DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode?: DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCodeResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DeleteShopChoicesResult?: DeleteShopChoicesResultResolvers<ContextType>;
  DeleteShopMenusResult?: DeleteShopMenusResultResolvers<ContextType>;
  DeleteShopPlanChoicesResult?: DeleteShopPlanChoicesResultResolvers<ContextType>;
  DeleteShopPlansResult?: DeleteShopPlansResultResolvers<ContextType>;
  DistributionItem?: DistributionItemResolvers<ContextType>;
  EbicaApiTable?: EbicaApiTableResolvers<ContextType>;
  EnableOnlinePaymentOutput?: EnableOnlinePaymentOutputResolvers<ContextType>;
  FaveYellAnalyticsByCorporationOutput?: FaveYellAnalyticsByCorporationOutputResolvers<ContextType>;
  FaveYellAnalyticsPerStaffByCorporationItem?: FaveYellAnalyticsPerStaffByCorporationItemResolvers<ContextType>;
  FaveYellAnalyticsSummaryByCorporationOutputItem?: FaveYellAnalyticsSummaryByCorporationOutputItemResolvers<ContextType>;
  FaveYellTransition?: FaveYellTransitionResolvers<ContextType>;
  GeneralTransition?: GeneralTransitionResolvers<ContextType>;
  IssueLineReportingBotVerificationCodeResponse?: IssueLineReportingBotVerificationCodeResponseResolvers<ContextType>;
  LineOfficialAccountProfile?: LineOfficialAccountProfileResolvers<ContextType>;
  LineOfficialAccountSummary?: LineOfficialAccountSummaryResolvers<ContextType>;
  MenuOrderItemAnalyticsOutput?: MenuOrderItemAnalyticsOutputResolvers<ContextType>;
  MenuOrderItemAnalyticsOutputItem?: MenuOrderItemAnalyticsOutputItemResolvers<ContextType>;
  MessageDeliveryAnalyticsRecentVisitedTableUser?: MessageDeliveryAnalyticsRecentVisitedTableUserResolvers<ContextType>;
  MessageDeliveryAnalyticsRecentVisitedTableUsersReport?: MessageDeliveryAnalyticsRecentVisitedTableUsersReportResolvers<ContextType>;
  MessageDeliveryAnalyticsSummary?: MessageDeliveryAnalyticsSummaryResolvers<ContextType>;
  MessageDeliveryAnalyticsSummaryPerMessageDelivery?: MessageDeliveryAnalyticsSummaryPerMessageDeliveryResolvers<ContextType>;
  MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem?: MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItemResolvers<ContextType>;
  MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem?: MessageDeliveryAnalyticsSummaryPerMessageDeliveryItemResolvers<ContextType>;
  MessageDeliveryAnalyticsSummaryPerShop?: MessageDeliveryAnalyticsSummaryPerShopResolvers<ContextType>;
  MessageDeliveryAnalyticsSummaryPerShopItem?: MessageDeliveryAnalyticsSummaryPerShopItemResolvers<ContextType>;
  MessageDeliveryConsumption?: MessageDeliveryConsumptionResolvers<ContextType>;
  MonthlySalesAmount?: MonthlySalesAmountResolvers<ContextType>;
  QuestionnaireAnswererAttributeMetricsDistributionItem?: QuestionnaireAnswererAttributeMetricsDistributionItemResolvers<ContextType>;
  QuestionnaireAnswererAttributeMetricsOutput?: QuestionnaireAnswererAttributeMetricsOutputResolvers<ContextType>;
  QuestionnaireComment?: QuestionnaireCommentResolvers<ContextType>;
  QuestionnaireCommentCountOutput?: QuestionnaireCommentCountOutputResolvers<ContextType>;
  QuestionnaireCorporationAverageScore?: QuestionnaireCorporationAverageScoreResolvers<ContextType>;
  QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics?: QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetricsResolvers<ContextType>;
  QuestionnaireMetricsPerCategoryOutput?: QuestionnaireMetricsPerCategoryOutputResolvers<ContextType>;
  QuestionnaireMetricsPerCategoryQuestionAnswerMetrics?: QuestionnaireMetricsPerCategoryQuestionAnswerMetricsResolvers<ContextType>;
  QuestionnaireMetricsPerCategoryScoreDistributionMetrics?: QuestionnaireMetricsPerCategoryScoreDistributionMetricsResolvers<ContextType>;
  QuestionnaireMetricsPerCategoryScoreMetrics?: QuestionnaireMetricsPerCategoryScoreMetricsResolvers<ContextType>;
  QuestionnaireMetricsPerCategoryTextTypeAnswer?: QuestionnaireMetricsPerCategoryTextTypeAnswerResolvers<ContextType>;
  QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics?: QuestionnaireMetricsPerCategoryTextTypeAnswerMetricsResolvers<ContextType>;
  QuestionnaireScoreChangesMetrics?: QuestionnaireScoreChangesMetricsResolvers<ContextType>;
  QuestionnaireScoreMetricsOutput?: QuestionnaireScoreMetricsOutputResolvers<ContextType>;
  QuestionnaireScoreWithFluctuation?: QuestionnaireScoreWithFluctuationResolvers<ContextType>;
  QuestionnaireShopAverageScore?: QuestionnaireShopAverageScoreResolvers<ContextType>;
  RepeaterTransition?: RepeaterTransitionResolvers<ContextType>;
  Sales?: SalesResolvers<ContextType>;
  SalesAnalyticsOutput?: SalesAnalyticsOutputResolvers<ContextType>;
  SalesAnalyticsOutputItem?: SalesAnalyticsOutputItemResolvers<ContextType>;
  SalesBreakdownByPaymentMethod?: SalesBreakdownByPaymentMethodResolvers<ContextType>;
  SalesByDayOfWeekAndHourAnalyticsOutput?: SalesByDayOfWeekAndHourAnalyticsOutputResolvers<ContextType>;
  SalesByDayOfWeekAndHourAnalyticsOutputItem?: SalesByDayOfWeekAndHourAnalyticsOutputItemResolvers<ContextType>;
  SalesByDays?: SalesByDaysResolvers<ContextType>;
  SalesByMonths?: SalesByMonthsResolvers<ContextType>;
  SalesForBudget?: SalesForBudgetResolvers<ContextType>;
  SalesWithPaymentMethod?: SalesWithPaymentMethodResolvers<ContextType>;
  SegmentedCustomer?: SegmentedCustomerResolvers<ContextType>;
  ShopApiAggregationGetQuantityAndSalesAggregationOutput?: ShopApiAggregationGetQuantityAndSalesAggregationOutputResolvers<ContextType>;
  ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutput?: ShopApiAggregationGetQuantityAndSalesByCategoryAggregationOutputResolvers<ContextType>;
  ShopCouponUsage?: ShopCouponUsageResolvers<ContextType>;
  ShopDailyCashRegisterBalancing?: ShopDailyCashRegisterBalancingResolvers<ContextType>;
  UpdateShopMenusBulkResult?: UpdateShopMenusBulkResultResolvers<ContextType>;
  UpdateShopPlansBulkResult?: UpdateShopPlansBulkResultResolvers<ContextType>;
  UpdateTargetCountOutput?: UpdateTargetCountOutputResolvers<ContextType>;
  UpsertShopChoicesResult?: UpsertShopChoicesResultResolvers<ContextType>;
  UpsertShopPlanChoiceResult?: UpsertShopPlanChoiceResultResolvers<ContextType>;
  ValidLineOfficialAccount?: ValidLineOfficialAccountResolvers<ContextType>;
  VisitedCustomerSegments?: VisitedCustomerSegmentsResolvers<ContextType>;
  VisitedLineCustomer?: VisitedLineCustomerResolvers<ContextType>;
  accountingSlipImage?: AccountingSlipImageResolvers<ContextType>;
  accountingSlipImage_mutation_response?: AccountingSlipImageMutationResponseResolvers<ContextType>;
  activePlan?: ActivePlanResolvers<ContextType>;
  activePlanChoice?: ActivePlanChoiceResolvers<ContextType>;
  activePlanOpenPriceMeta?: ActivePlanOpenPriceMetaResolvers<ContextType>;
  activePlanOption?: ActivePlanOptionResolvers<ContextType>;
  adyenPaymentReportTerminalPaymentAuthorizedEvent?: AdyenPaymentReportTerminalPaymentAuthorizedEventResolvers<ContextType>;
  adyenPaymentReportTerminalPaymentCanceledEvent?: AdyenPaymentReportTerminalPaymentCanceledEventResolvers<ContextType>;
  adyenPaymentShopConfig?: AdyenPaymentShopConfigResolvers<ContextType>;
  adyenPaymentShopPaymentMethod?: AdyenPaymentShopPaymentMethodResolvers<ContextType>;
  adyenPaymentStoreMeta?: AdyenPaymentStoreMetaResolvers<ContextType>;
  adyenPaymentStoreMeta_mutation_response?: AdyenPaymentStoreMetaMutationResponseResolvers<ContextType>;
  adyenTerminalPaymentShopConfig?: AdyenTerminalPaymentShopConfigResolvers<ContextType>;
  adyenTerminalPaymentShopPaymentMethod?: AdyenTerminalPaymentShopPaymentMethodResolvers<ContextType>;
  analyticsSetting?: AnalyticsSettingResolvers<ContextType>;
  analyticsSetting_mutation_response?: AnalyticsSettingMutationResponseResolvers<ContextType>;
  bigint?: GraphQLScalarType;
  birthdayTypeQuestionAnswer?: BirthdayTypeQuestionAnswerResolvers<ContextType>;
  cashRegisterConfig?: CashRegisterConfigResolvers<ContextType>;
  cashRegisterConfig_aggregate?: CashRegisterConfigAggregateResolvers<ContextType>;
  cashRegisterConfig_aggregate_fields?: CashRegisterConfigAggregateFieldsResolvers<ContextType>;
  cashRegisterConfig_max_fields?: CashRegisterConfigMaxFieldsResolvers<ContextType>;
  cashRegisterConfig_min_fields?: CashRegisterConfigMinFieldsResolvers<ContextType>;
  cashRegisterConfig_mutation_response?: CashRegisterConfigMutationResponseResolvers<ContextType>;
  cashRegisterSecurityLevel?: CashRegisterSecurityLevelResolvers<ContextType>;
  category?: CategoryResolvers<ContextType>;
  categoryMenu?: CategoryMenuResolvers<ContextType>;
  categoryMenu_aggregate?: CategoryMenuAggregateResolvers<ContextType>;
  categoryMenu_aggregate_fields?: CategoryMenuAggregateFieldsResolvers<ContextType>;
  categoryMenu_avg_fields?: CategoryMenuAvgFieldsResolvers<ContextType>;
  categoryMenu_max_fields?: CategoryMenuMaxFieldsResolvers<ContextType>;
  categoryMenu_min_fields?: CategoryMenuMinFieldsResolvers<ContextType>;
  categoryMenu_mutation_response?: CategoryMenuMutationResponseResolvers<ContextType>;
  categoryMenu_stddev_fields?: CategoryMenuStddevFieldsResolvers<ContextType>;
  categoryMenu_stddev_pop_fields?: CategoryMenuStddevPopFieldsResolvers<ContextType>;
  categoryMenu_stddev_samp_fields?: CategoryMenuStddevSampFieldsResolvers<ContextType>;
  categoryMenu_sum_fields?: CategoryMenuSumFieldsResolvers<ContextType>;
  categoryMenu_var_pop_fields?: CategoryMenuVarPopFieldsResolvers<ContextType>;
  categoryMenu_var_samp_fields?: CategoryMenuVarSampFieldsResolvers<ContextType>;
  categoryMenu_variance_fields?: CategoryMenuVarianceFieldsResolvers<ContextType>;
  category_aggregate?: CategoryAggregateResolvers<ContextType>;
  category_aggregate_fields?: CategoryAggregateFieldsResolvers<ContextType>;
  category_avg_fields?: CategoryAvgFieldsResolvers<ContextType>;
  category_max_fields?: CategoryMaxFieldsResolvers<ContextType>;
  category_min_fields?: CategoryMinFieldsResolvers<ContextType>;
  category_mutation_response?: CategoryMutationResponseResolvers<ContextType>;
  category_stddev_fields?: CategoryStddevFieldsResolvers<ContextType>;
  category_stddev_pop_fields?: CategoryStddevPopFieldsResolvers<ContextType>;
  category_stddev_samp_fields?: CategoryStddevSampFieldsResolvers<ContextType>;
  category_sum_fields?: CategorySumFieldsResolvers<ContextType>;
  category_var_pop_fields?: CategoryVarPopFieldsResolvers<ContextType>;
  category_var_samp_fields?: CategoryVarSampFieldsResolvers<ContextType>;
  category_variance_fields?: CategoryVarianceFieldsResolvers<ContextType>;
  checkInEvent?: CheckInEventResolvers<ContextType>;
  choice?: ChoiceResolvers<ContextType>;
  choice_aggregate?: ChoiceAggregateResolvers<ContextType>;
  choice_aggregate_fields?: ChoiceAggregateFieldsResolvers<ContextType>;
  choice_avg_fields?: ChoiceAvgFieldsResolvers<ContextType>;
  choice_max_fields?: ChoiceMaxFieldsResolvers<ContextType>;
  choice_min_fields?: ChoiceMinFieldsResolvers<ContextType>;
  choice_mutation_response?: ChoiceMutationResponseResolvers<ContextType>;
  choice_stddev_fields?: ChoiceStddevFieldsResolvers<ContextType>;
  choice_stddev_pop_fields?: ChoiceStddevPopFieldsResolvers<ContextType>;
  choice_stddev_samp_fields?: ChoiceStddevSampFieldsResolvers<ContextType>;
  choice_sum_fields?: ChoiceSumFieldsResolvers<ContextType>;
  choice_var_pop_fields?: ChoiceVarPopFieldsResolvers<ContextType>;
  choice_var_samp_fields?: ChoiceVarSampFieldsResolvers<ContextType>;
  choice_variance_fields?: ChoiceVarianceFieldsResolvers<ContextType>;
  clerk?: ClerkResolvers<ContextType>;
  clerk_mutation_response?: ClerkMutationResponseResolvers<ContextType>;
  closeCashRegister?: CloseCashRegisterResolvers<ContextType>;
  closeCashRegister_mutation_response?: CloseCashRegisterMutationResponseResolvers<ContextType>;
  coineyPayment?: CoineyPaymentResolvers<ContextType>;
  coineyPaymentOrder?: CoineyPaymentOrderResolvers<ContextType>;
  coineyPaymentSales?: CoineyPaymentSalesResolvers<ContextType>;
  coineyPayment_mutation_response?: CoineyPaymentMutationResponseResolvers<ContextType>;
  coineySettlement?: CoineySettlementResolvers<ContextType>;
  company?: CompanyResolvers<ContextType>;
  companyMember?: CompanyMemberResolvers<ContextType>;
  companyMember_aggregate?: CompanyMemberAggregateResolvers<ContextType>;
  companyMember_aggregate_fields?: CompanyMemberAggregateFieldsResolvers<ContextType>;
  companyMember_max_fields?: CompanyMemberMaxFieldsResolvers<ContextType>;
  companyMember_min_fields?: CompanyMemberMinFieldsResolvers<ContextType>;
  company_mutation_response?: CompanyMutationResponseResolvers<ContextType>;
  compulsoryAppetizerConfig?: CompulsoryAppetizerConfigResolvers<ContextType>;
  compulsoryAppetizerConfig_mutation_response?: CompulsoryAppetizerConfigMutationResponseResolvers<ContextType>;
  connectGameConfig?: ConnectGameConfigResolvers<ContextType>;
  connectGameConfigShop?: ConnectGameConfigShopResolvers<ContextType>;
  connectGameConfigShop_mutation_response?: ConnectGameConfigShopMutationResponseResolvers<ContextType>;
  connectGameConfig_mutation_response?: ConnectGameConfigMutationResponseResolvers<ContextType>;
  cookingItem?: CookingItemResolvers<ContextType>;
  cookingItem_mutation_response?: CookingItemMutationResponseResolvers<ContextType>;
  corporation?: CorporationResolvers<ContextType>;
  corporation_mutation_response?: CorporationMutationResponseResolvers<ContextType>;
  coupon?: CouponResolvers<ContextType>;
  couponAppliedEvent?: CouponAppliedEventResolvers<ContextType>;
  couponAppliedEvent_aggregate?: CouponAppliedEventAggregateResolvers<ContextType>;
  couponAppliedEvent_aggregate_fields?: CouponAppliedEventAggregateFieldsResolvers<ContextType>;
  couponAppliedEvent_max_fields?: CouponAppliedEventMaxFieldsResolvers<ContextType>;
  couponAppliedEvent_min_fields?: CouponAppliedEventMinFieldsResolvers<ContextType>;
  couponMenuDiscount?: CouponMenuDiscountResolvers<ContextType>;
  couponMenuDiscount_mutation_response?: CouponMenuDiscountMutationResponseResolvers<ContextType>;
  coupon_mutation_response?: CouponMutationResponseResolvers<ContextType>;
  customer?: CustomerResolvers<ContextType>;
  customerQuestionnaire?: CustomerQuestionnaireResolvers<ContextType>;
  dailySalesBudget?: DailySalesBudgetResolvers<ContextType>;
  dailySalesBudget_mutation_response?: DailySalesBudgetMutationResponseResolvers<ContextType>;
  dashboardAccount?: DashboardAccountResolvers<ContextType>;
  dashboardAccountAccessibleCompany?: DashboardAccountAccessibleCompanyResolvers<ContextType>;
  dashboardAccountAccessibleShop?: DashboardAccountAccessibleShopResolvers<ContextType>;
  dashboardAccountRole?: DashboardAccountRoleResolvers<ContextType>;
  dashboardAccountRolePermission?: DashboardAccountRolePermissionResolvers<ContextType>;
  dashboardAccountRolePermission_mutation_response?: DashboardAccountRolePermissionMutationResponseResolvers<ContextType>;
  dashboardAccountRoleType?: DashboardAccountRoleTypeResolvers<ContextType>;
  dashboardAccountRole_mutation_response?: DashboardAccountRoleMutationResponseResolvers<ContextType>;
  date?: GraphQLScalarType;
  dishUpSlipGroup?: DishUpSlipGroupResolvers<ContextType>;
  dishUpSlipGroupRoles?: DishUpSlipGroupRolesResolvers<ContextType>;
  dishUpSlipGroupRoles_mutation_response?: DishUpSlipGroupRolesMutationResponseResolvers<ContextType>;
  dishUpSlipGroupShopMenus?: DishUpSlipGroupShopMenusResolvers<ContextType>;
  dishUpSlipGroupShopMenus_mutation_response?: DishUpSlipGroupShopMenusMutationResponseResolvers<ContextType>;
  dishUpSlipGroupShopPlans?: DishUpSlipGroupShopPlansResolvers<ContextType>;
  dishUpSlipGroupShopPlans_mutation_response?: DishUpSlipGroupShopPlansMutationResponseResolvers<ContextType>;
  dishUpSlipGroupTables?: DishUpSlipGroupTablesResolvers<ContextType>;
  dishUpSlipGroupTables_mutation_response?: DishUpSlipGroupTablesMutationResponseResolvers<ContextType>;
  dishUpSlipGroup_mutation_response?: DishUpSlipGroupMutationResponseResolvers<ContextType>;
  ebicaConfig?: EbicaConfigResolvers<ContextType>;
  ebicaConfig_mutation_response?: EbicaConfigMutationResponseResolvers<ContextType>;
  ebicaTable?: EbicaTableResolvers<ContextType>;
  ebicaTable_mutation_response?: EbicaTableMutationResponseResolvers<ContextType>;
  externalOnlineMenu?: ExternalOnlineMenuResolvers<ContextType>;
  externalOnlineMenuAvailableTimeTerm?: ExternalOnlineMenuAvailableTimeTermResolvers<ContextType>;
  externalOnlineMenuAvailableTimeTerm_mutation_response?: ExternalOnlineMenuAvailableTimeTermMutationResponseResolvers<ContextType>;
  externalOnlineMenuConfig?: ExternalOnlineMenuConfigResolvers<ContextType>;
  externalOnlineMenuConfig_mutation_response?: ExternalOnlineMenuConfigMutationResponseResolvers<ContextType>;
  externalOnlineMenu_mutation_response?: ExternalOnlineMenuMutationResponseResolvers<ContextType>;
  featureFlag?: FeatureFlagResolvers<ContextType>;
  featureFlagShop?: FeatureFlagShopResolvers<ContextType>;
  featureFlagShop_mutation_response?: FeatureFlagShopMutationResponseResolvers<ContextType>;
  foodingJournalConfig?: FoodingJournalConfigResolvers<ContextType>;
  foodingJournalConfig_mutation_response?: FoodingJournalConfigMutationResponseResolvers<ContextType>;
  foodingJournalDepartmentMaster?: FoodingJournalDepartmentMasterResolvers<ContextType>;
  foodingJournalDepartmentMaster_mutation_response?: FoodingJournalDepartmentMasterMutationResponseResolvers<ContextType>;
  foodingJournalGroupMaster?: FoodingJournalGroupMasterResolvers<ContextType>;
  foodingJournalGroupMaster_mutation_response?: FoodingJournalGroupMasterMutationResponseResolvers<ContextType>;
  foodingJournalMenu?: FoodingJournalMenuResolvers<ContextType>;
  foodingJournalMenu_mutation_response?: FoodingJournalMenuMutationResponseResolvers<ContextType>;
  foodingJournalOnSitePaymentDetailType?: FoodingJournalOnSitePaymentDetailTypeResolvers<ContextType>;
  foodingJournalOnSitePaymentDetailType_mutation_response?: FoodingJournalOnSitePaymentDetailTypeMutationResponseResolvers<ContextType>;
  foodingJournalOnSitePaymentDiscountType?: FoodingJournalOnSitePaymentDiscountTypeResolvers<ContextType>;
  foodingJournalOnSitePaymentDiscountType_mutation_response?: FoodingJournalOnSitePaymentDiscountTypeMutationResponseResolvers<ContextType>;
  giftMeta?: GiftMetaResolvers<ContextType>;
  giftMeta_aggregate?: GiftMetaAggregateResolvers<ContextType>;
  giftMeta_aggregate_fields?: GiftMetaAggregateFieldsResolvers<ContextType>;
  giftMeta_avg_fields?: GiftMetaAvgFieldsResolvers<ContextType>;
  giftMeta_max_fields?: GiftMetaMaxFieldsResolvers<ContextType>;
  giftMeta_min_fields?: GiftMetaMinFieldsResolvers<ContextType>;
  giftMeta_mutation_response?: GiftMetaMutationResponseResolvers<ContextType>;
  giftMeta_stddev_fields?: GiftMetaStddevFieldsResolvers<ContextType>;
  giftMeta_stddev_pop_fields?: GiftMetaStddevPopFieldsResolvers<ContextType>;
  giftMeta_stddev_samp_fields?: GiftMetaStddevSampFieldsResolvers<ContextType>;
  giftMeta_sum_fields?: GiftMetaSumFieldsResolvers<ContextType>;
  giftMeta_var_pop_fields?: GiftMetaVarPopFieldsResolvers<ContextType>;
  giftMeta_var_samp_fields?: GiftMetaVarSampFieldsResolvers<ContextType>;
  giftMeta_variance_fields?: GiftMetaVarianceFieldsResolvers<ContextType>;
  giftOptionMeta?: GiftOptionMetaResolvers<ContextType>;
  giftOptionMeta_mutation_response?: GiftOptionMetaMutationResponseResolvers<ContextType>;
  gmoBankAccount?: GmoBankAccountResolvers<ContextType>;
  gmoBankAccountShop?: GmoBankAccountShopResolvers<ContextType>;
  gmoOffPaymentMethodType?: GmoOffPaymentMethodTypeResolvers<ContextType>;
  gmoOffPaymentOnSitePaymentDetailType?: GmoOffPaymentOnSitePaymentDetailTypeResolvers<ContextType>;
  gmoOffPaymentOnSitePaymentDetailType_mutation_response?: GmoOffPaymentOnSitePaymentDetailTypeMutationResponseResolvers<ContextType>;
  gmoOffPaymentShopConfig?: GmoOffPaymentShopConfigResolvers<ContextType>;
  gmoOffPaymentShopConfigTransactionFeeRate?: GmoOffPaymentShopConfigTransactionFeeRateResolvers<ContextType>;
  gmoOffPaymentShopConfigTransactionFeeRate_mutation_response?: GmoOffPaymentShopConfigTransactionFeeRateMutationResponseResolvers<ContextType>;
  gmoOffPaymentShopConfig_mutation_response?: GmoOffPaymentShopConfigMutationResponseResolvers<ContextType>;
  inflowSourceTag?: InflowSourceTagResolvers<ContextType>;
  inflowSourceTag_aggregate?: InflowSourceTagAggregateResolvers<ContextType>;
  inflowSourceTag_aggregate_fields?: InflowSourceTagAggregateFieldsResolvers<ContextType>;
  inflowSourceTag_avg_fields?: InflowSourceTagAvgFieldsResolvers<ContextType>;
  inflowSourceTag_max_fields?: InflowSourceTagMaxFieldsResolvers<ContextType>;
  inflowSourceTag_min_fields?: InflowSourceTagMinFieldsResolvers<ContextType>;
  inflowSourceTag_mutation_response?: InflowSourceTagMutationResponseResolvers<ContextType>;
  inflowSourceTag_stddev_fields?: InflowSourceTagStddevFieldsResolvers<ContextType>;
  inflowSourceTag_stddev_pop_fields?: InflowSourceTagStddevPopFieldsResolvers<ContextType>;
  inflowSourceTag_stddev_samp_fields?: InflowSourceTagStddevSampFieldsResolvers<ContextType>;
  inflowSourceTag_sum_fields?: InflowSourceTagSumFieldsResolvers<ContextType>;
  inflowSourceTag_var_pop_fields?: InflowSourceTagVarPopFieldsResolvers<ContextType>;
  inflowSourceTag_var_samp_fields?: InflowSourceTagVarSampFieldsResolvers<ContextType>;
  inflowSourceTag_variance_fields?: InflowSourceTagVarianceFieldsResolvers<ContextType>;
  itemSales?: ItemSalesResolvers<ContextType>;
  jsonb?: GraphQLScalarType;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget?: KdDisplayItemKdDisplayTargetsKdDisplayTargetResolvers<ContextType>;
  kdDisplayItemKdDisplayTargetsKdDisplayTarget_mutation_response?: KdDisplayItemKdDisplayTargetsKdDisplayTargetMutationResponseResolvers<ContextType>;
  kdDisplayTarget?: KdDisplayTargetResolvers<ContextType>;
  kdDisplayTarget_mutation_response?: KdDisplayTargetMutationResponseResolvers<ContextType>;
  lineChannelConfig?: LineChannelConfigResolvers<ContextType>;
  lineChannelConfig_mutation_response?: LineChannelConfigMutationResponseResolvers<ContextType>;
  lineOfficialAccount?: LineOfficialAccountResolvers<ContextType>;
  lineProfile?: LineProfileResolvers<ContextType>;
  lineReportingBotConfig?: LineReportingBotConfigResolvers<ContextType>;
  lineReportingBotConfigShop?: LineReportingBotConfigShopResolvers<ContextType>;
  lineReportingBotConfigShop_mutation_response?: LineReportingBotConfigShopMutationResponseResolvers<ContextType>;
  lineReportingBotConfig_mutation_response?: LineReportingBotConfigMutationResponseResolvers<ContextType>;
  lineReportingBotVerification?: LineReportingBotVerificationResolvers<ContextType>;
  membershipCard?: MembershipCardResolvers<ContextType>;
  membershipCardAppearanceType?: MembershipCardAppearanceTypeResolvers<ContextType>;
  membershipCardTitle?: MembershipCardTitleResolvers<ContextType>;
  membershipCardTitle_mutation_response?: MembershipCardTitleMutationResponseResolvers<ContextType>;
  membershipCard_mutation_response?: MembershipCardMutationResponseResolvers<ContextType>;
  membershipRank?: MembershipRankResolvers<ContextType>;
  membershipRankConfig?: MembershipRankConfigResolvers<ContextType>;
  membershipRankConfig_mutation_response?: MembershipRankConfigMutationResponseResolvers<ContextType>;
  menu?: MenuResolvers<ContextType>;
  menuOption?: MenuOptionResolvers<ContextType>;
  menuOption_mutation_response?: MenuOptionMutationResponseResolvers<ContextType>;
  menuOrderItem?: MenuOrderItemResolvers<ContextType>;
  menuOrderItemChoice?: MenuOrderItemChoiceResolvers<ContextType>;
  menuOrderItemOption?: MenuOrderItemOptionResolvers<ContextType>;
  menuRecommendationMeta?: MenuRecommendationMetaResolvers<ContextType>;
  menuRecommendationMeta_mutation_response?: MenuRecommendationMetaMutationResponseResolvers<ContextType>;
  menu_mutation_response?: MenuMutationResponseResolvers<ContextType>;
  messageDelivery?: MessageDeliveryResolvers<ContextType>;
  messageDeliveryCustomerList?: MessageDeliveryCustomerListResolvers<ContextType>;
  messageDeliveryCustomerListCustomer?: MessageDeliveryCustomerListCustomerResolvers<ContextType>;
  messageDeliveryCustomerListCustomer_aggregate?: MessageDeliveryCustomerListCustomerAggregateResolvers<ContextType>;
  messageDeliveryCustomerListCustomer_aggregate_fields?: MessageDeliveryCustomerListCustomerAggregateFieldsResolvers<ContextType>;
  messageDeliveryCustomerListCustomer_max_fields?: MessageDeliveryCustomerListCustomerMaxFieldsResolvers<ContextType>;
  messageDeliveryCustomerListCustomer_min_fields?: MessageDeliveryCustomerListCustomerMinFieldsResolvers<ContextType>;
  messageDeliveryCustomerSegment?: MessageDeliveryCustomerSegmentResolvers<ContextType>;
  messageDeliveryCustomerSegmentBusinessOperationHoursCondition?: MessageDeliveryCustomerSegmentBusinessOperationHoursConditionResolvers<ContextType>;
  messageDeliveryCustomerSegmentDayOfWeekCondition?: MessageDeliveryCustomerSegmentDayOfWeekConditionResolvers<ContextType>;
  messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: MessageDeliveryCustomerSegmentDaysCountUntilBirthdayConditionResolvers<ContextType>;
  messageDeliveryCustomerSegmentGender?: MessageDeliveryCustomerSegmentGenderResolvers<ContextType>;
  messageDeliveryCustomerSegmentGenderCondition?: MessageDeliveryCustomerSegmentGenderConditionResolvers<ContextType>;
  messageDeliveryCustomerSegmentMenuCondition?: MessageDeliveryCustomerSegmentMenuConditionResolvers<ContextType>;
  messageDeliveryCustomerSegmentNumericCondition?: MessageDeliveryCustomerSegmentNumericConditionResolvers<ContextType>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: MessageDeliveryCustomerSegmentQuestionnaireAnswerConditionResolvers<ContextType>;
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusType?: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeResolvers<ContextType>;
  messageDeliveryCustomerSegmentShopCondition?: MessageDeliveryCustomerSegmentShopConditionResolvers<ContextType>;
  messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: MessageDeliveryCustomerSegmentUnvisitedDayCountConditionResolvers<ContextType>;
  messageDeliveryCustomerSegmentVisitedDayCountCondition?: MessageDeliveryCustomerSegmentVisitedDayCountConditionResolvers<ContextType>;
  messageDeliveryJob?: MessageDeliveryJobResolvers<ContextType>;
  messageDeliveryMessage?: MessageDeliveryMessageResolvers<ContextType>;
  messageDeliveryMessageCouponTypeMeta?: MessageDeliveryMessageCouponTypeMetaResolvers<ContextType>;
  messageDeliveryMessageImageTypeMeta?: MessageDeliveryMessageImageTypeMetaResolvers<ContextType>;
  messageDeliveryMessageQuestionnaireTypeMeta?: MessageDeliveryMessageQuestionnaireTypeMetaResolvers<ContextType>;
  messageDeliveryMessageTextTypeMeta?: MessageDeliveryMessageTextTypeMetaResolvers<ContextType>;
  messageDeliveryShopJob?: MessageDeliveryShopJobResolvers<ContextType>;
  messageDelivery_mutation_response?: MessageDeliveryMutationResponseResolvers<ContextType>;
  monthlySalesBudget?: MonthlySalesBudgetResolvers<ContextType>;
  monthlySalesBudget_mutation_response?: MonthlySalesBudgetMutationResponseResolvers<ContextType>;
  multipleChoiceTypeQuestionAnswer?: MultipleChoiceTypeQuestionAnswerResolvers<ContextType>;
  multipleChoiceTypeQuestionChoice?: MultipleChoiceTypeQuestionChoiceResolvers<ContextType>;
  multipleChoiceTypeQuestionChoiceAnswer?: MultipleChoiceTypeQuestionChoiceAnswerResolvers<ContextType>;
  multipleChoiceTypeQuestionMeta?: MultipleChoiceTypeQuestionMetaResolvers<ContextType>;
  mutation_root?: MutationRootResolvers<ContextType>;
  numeric?: GraphQLScalarType;
  onSitePayment?: OnSitePaymentResolvers<ContextType>;
  onSitePaymentDetail?: OnSitePaymentDetailResolvers<ContextType>;
  onSitePaymentDetailType?: OnSitePaymentDetailTypeResolvers<ContextType>;
  onSitePaymentDetailTypeCategoryPriority?: OnSitePaymentDetailTypeCategoryPriorityResolvers<ContextType>;
  onSitePaymentDetailType_aggregate?: OnSitePaymentDetailTypeAggregateResolvers<ContextType>;
  onSitePaymentDetailType_aggregate_fields?: OnSitePaymentDetailTypeAggregateFieldsResolvers<ContextType>;
  onSitePaymentDetailType_avg_fields?: OnSitePaymentDetailTypeAvgFieldsResolvers<ContextType>;
  onSitePaymentDetailType_max_fields?: OnSitePaymentDetailTypeMaxFieldsResolvers<ContextType>;
  onSitePaymentDetailType_min_fields?: OnSitePaymentDetailTypeMinFieldsResolvers<ContextType>;
  onSitePaymentDetailType_mutation_response?: OnSitePaymentDetailTypeMutationResponseResolvers<ContextType>;
  onSitePaymentDetailType_stddev_fields?: OnSitePaymentDetailTypeStddevFieldsResolvers<ContextType>;
  onSitePaymentDetailType_stddev_pop_fields?: OnSitePaymentDetailTypeStddevPopFieldsResolvers<ContextType>;
  onSitePaymentDetailType_stddev_samp_fields?: OnSitePaymentDetailTypeStddevSampFieldsResolvers<ContextType>;
  onSitePaymentDetailType_sum_fields?: OnSitePaymentDetailTypeSumFieldsResolvers<ContextType>;
  onSitePaymentDetailType_var_pop_fields?: OnSitePaymentDetailTypeVarPopFieldsResolvers<ContextType>;
  onSitePaymentDetailType_var_samp_fields?: OnSitePaymentDetailTypeVarSampFieldsResolvers<ContextType>;
  onSitePaymentDetailType_variance_fields?: OnSitePaymentDetailTypeVarianceFieldsResolvers<ContextType>;
  onSitePaymentDiscount?: OnSitePaymentDiscountResolvers<ContextType>;
  onSitePaymentDiscountType?: OnSitePaymentDiscountTypeResolvers<ContextType>;
  onSitePaymentDiscountType_aggregate?: OnSitePaymentDiscountTypeAggregateResolvers<ContextType>;
  onSitePaymentDiscountType_aggregate_fields?: OnSitePaymentDiscountTypeAggregateFieldsResolvers<ContextType>;
  onSitePaymentDiscountType_avg_fields?: OnSitePaymentDiscountTypeAvgFieldsResolvers<ContextType>;
  onSitePaymentDiscountType_max_fields?: OnSitePaymentDiscountTypeMaxFieldsResolvers<ContextType>;
  onSitePaymentDiscountType_min_fields?: OnSitePaymentDiscountTypeMinFieldsResolvers<ContextType>;
  onSitePaymentDiscountType_mutation_response?: OnSitePaymentDiscountTypeMutationResponseResolvers<ContextType>;
  onSitePaymentDiscountType_stddev_fields?: OnSitePaymentDiscountTypeStddevFieldsResolvers<ContextType>;
  onSitePaymentDiscountType_stddev_pop_fields?: OnSitePaymentDiscountTypeStddevPopFieldsResolvers<ContextType>;
  onSitePaymentDiscountType_stddev_samp_fields?: OnSitePaymentDiscountTypeStddevSampFieldsResolvers<ContextType>;
  onSitePaymentDiscountType_sum_fields?: OnSitePaymentDiscountTypeSumFieldsResolvers<ContextType>;
  onSitePaymentDiscountType_var_pop_fields?: OnSitePaymentDiscountTypeVarPopFieldsResolvers<ContextType>;
  onSitePaymentDiscountType_var_samp_fields?: OnSitePaymentDiscountTypeVarSampFieldsResolvers<ContextType>;
  onSitePaymentDiscountType_variance_fields?: OnSitePaymentDiscountTypeVarianceFieldsResolvers<ContextType>;
  onSitePaymentSales?: OnSitePaymentSalesResolvers<ContextType>;
  onSitePaymentTableUsers?: OnSitePaymentTableUsersResolvers<ContextType>;
  onlinePaymentPrinterRoleMeta?: OnlinePaymentPrinterRoleMetaResolvers<ContextType>;
  onlinePaymentPrinterRoleMeta_mutation_response?: OnlinePaymentPrinterRoleMetaMutationResponseResolvers<ContextType>;
  onlinePaymentRootMutation?: OnlinePaymentRootMutationResolvers<ContextType>;
  onlinePaymentRootQuery?: OnlinePaymentRootQueryResolvers<ContextType>;
  option?: OptionResolvers<ContextType>;
  optionInputType?: OptionInputTypeResolvers<ContextType>;
  optionInputType_mutation_response?: OptionInputTypeMutationResponseResolvers<ContextType>;
  option_aggregate?: OptionAggregateResolvers<ContextType>;
  option_aggregate_fields?: OptionAggregateFieldsResolvers<ContextType>;
  option_avg_fields?: OptionAvgFieldsResolvers<ContextType>;
  option_max_fields?: OptionMaxFieldsResolvers<ContextType>;
  option_min_fields?: OptionMinFieldsResolvers<ContextType>;
  option_mutation_response?: OptionMutationResponseResolvers<ContextType>;
  option_stddev_fields?: OptionStddevFieldsResolvers<ContextType>;
  option_stddev_pop_fields?: OptionStddevPopFieldsResolvers<ContextType>;
  option_stddev_samp_fields?: OptionStddevSampFieldsResolvers<ContextType>;
  option_sum_fields?: OptionSumFieldsResolvers<ContextType>;
  option_var_pop_fields?: OptionVarPopFieldsResolvers<ContextType>;
  option_var_samp_fields?: OptionVarSampFieldsResolvers<ContextType>;
  option_variance_fields?: OptionVarianceFieldsResolvers<ContextType>;
  order?: OrderResolvers<ContextType>;
  orderableTime?: OrderableTimeResolvers<ContextType>;
  orderableTime_mutation_response?: OrderableTimeMutationResponseResolvers<ContextType>;
  payingActivePlanChoice?: PayingActivePlanChoiceResolvers<ContextType>;
  payingActivePlanOpenPriceMeta?: PayingActivePlanOpenPriceMetaResolvers<ContextType>;
  payingMenuOrderItem?: PayingMenuOrderItemResolvers<ContextType>;
  paymentReceiptRoleTable?: PaymentReceiptRoleTableResolvers<ContextType>;
  paymentReceiptRoleTable_mutation_response?: PaymentReceiptRoleTableMutationResponseResolvers<ContextType>;
  pikaichiConfig?: PikaichiConfigResolvers<ContextType>;
  pikaichiConfig_mutation_response?: PikaichiConfigMutationResponseResolvers<ContextType>;
  pikaichiMenu?: PikaichiMenuResolvers<ContextType>;
  pikaichiMenuChoice?: PikaichiMenuChoiceResolvers<ContextType>;
  pikaichiMenuChoice_mutation_response?: PikaichiMenuChoiceMutationResponseResolvers<ContextType>;
  pikaichiMenuMenu?: PikaichiMenuMenuResolvers<ContextType>;
  pikaichiMenuMenu_mutation_response?: PikaichiMenuMenuMutationResponseResolvers<ContextType>;
  pikaichiMenuPlan?: PikaichiMenuPlanResolvers<ContextType>;
  pikaichiMenuPlanChoice?: PikaichiMenuPlanChoiceResolvers<ContextType>;
  pikaichiMenuPlanChoice_mutation_response?: PikaichiMenuPlanChoiceMutationResponseResolvers<ContextType>;
  pikaichiMenuPlan_mutation_response?: PikaichiMenuPlanMutationResponseResolvers<ContextType>;
  pikaichiMenu_mutation_response?: PikaichiMenuMutationResponseResolvers<ContextType>;
  pikaichiOnSitePaymentDetailType?: PikaichiOnSitePaymentDetailTypeResolvers<ContextType>;
  pikaichiOnSitePaymentDetailType_mutation_response?: PikaichiOnSitePaymentDetailTypeMutationResponseResolvers<ContextType>;
  pikaichiOnSitePaymentDiscountType?: PikaichiOnSitePaymentDiscountTypeResolvers<ContextType>;
  pikaichiOnSitePaymentDiscountType_mutation_response?: PikaichiOnSitePaymentDiscountTypeMutationResponseResolvers<ContextType>;
  plan?: PlanResolvers<ContextType>;
  planChoice?: PlanChoiceResolvers<ContextType>;
  planChoice_aggregate?: PlanChoiceAggregateResolvers<ContextType>;
  planChoice_aggregate_fields?: PlanChoiceAggregateFieldsResolvers<ContextType>;
  planChoice_avg_fields?: PlanChoiceAvgFieldsResolvers<ContextType>;
  planChoice_max_fields?: PlanChoiceMaxFieldsResolvers<ContextType>;
  planChoice_min_fields?: PlanChoiceMinFieldsResolvers<ContextType>;
  planChoice_mutation_response?: PlanChoiceMutationResponseResolvers<ContextType>;
  planChoice_stddev_fields?: PlanChoiceStddevFieldsResolvers<ContextType>;
  planChoice_stddev_pop_fields?: PlanChoiceStddevPopFieldsResolvers<ContextType>;
  planChoice_stddev_samp_fields?: PlanChoiceStddevSampFieldsResolvers<ContextType>;
  planChoice_sum_fields?: PlanChoiceSumFieldsResolvers<ContextType>;
  planChoice_var_pop_fields?: PlanChoiceVarPopFieldsResolvers<ContextType>;
  planChoice_var_samp_fields?: PlanChoiceVarSampFieldsResolvers<ContextType>;
  planChoice_variance_fields?: PlanChoiceVarianceFieldsResolvers<ContextType>;
  planFirstOrderCategoryMenu?: PlanFirstOrderCategoryMenuResolvers<ContextType>;
  planFirstOrderCategoryMenu_mutation_response?: PlanFirstOrderCategoryMenuMutationResponseResolvers<ContextType>;
  planGroup?: PlanGroupResolvers<ContextType>;
  planGroup_mutation_response?: PlanGroupMutationResponseResolvers<ContextType>;
  planMenuCategory?: PlanMenuCategoryResolvers<ContextType>;
  planMenuCategory_mutation_response?: PlanMenuCategoryMutationResponseResolvers<ContextType>;
  planOption?: PlanOptionResolvers<ContextType>;
  planOption_aggregate?: PlanOptionAggregateResolvers<ContextType>;
  planOption_aggregate_fields?: PlanOptionAggregateFieldsResolvers<ContextType>;
  planOption_avg_fields?: PlanOptionAvgFieldsResolvers<ContextType>;
  planOption_max_fields?: PlanOptionMaxFieldsResolvers<ContextType>;
  planOption_min_fields?: PlanOptionMinFieldsResolvers<ContextType>;
  planOption_mutation_response?: PlanOptionMutationResponseResolvers<ContextType>;
  planOption_stddev_fields?: PlanOptionStddevFieldsResolvers<ContextType>;
  planOption_stddev_pop_fields?: PlanOptionStddevPopFieldsResolvers<ContextType>;
  planOption_stddev_samp_fields?: PlanOptionStddevSampFieldsResolvers<ContextType>;
  planOption_sum_fields?: PlanOptionSumFieldsResolvers<ContextType>;
  planOption_var_pop_fields?: PlanOptionVarPopFieldsResolvers<ContextType>;
  planOption_var_samp_fields?: PlanOptionVarSampFieldsResolvers<ContextType>;
  planOption_variance_fields?: PlanOptionVarianceFieldsResolvers<ContextType>;
  plan_menu_categories_category?: PlanMenuCategoriesCategoryResolvers<ContextType>;
  plan_menu_categories_category_mutation_response?: PlanMenuCategoriesCategoryMutationResponseResolvers<ContextType>;
  plan_mutation_response?: PlanMutationResponseResolvers<ContextType>;
  query_root?: QueryRootResolvers<ContextType>;
  question?: QuestionResolvers<ContextType>;
  questionAnswer?: QuestionAnswerResolvers<ContextType>;
  questionnaire?: QuestionnaireResolvers<ContextType>;
  questionnaireAnswer?: QuestionnaireAnswerResolvers<ContextType>;
  questionnaireConfig?: QuestionnaireConfigResolvers<ContextType>;
  questionnaireConfig_mutation_response?: QuestionnaireConfigMutationResponseResolvers<ContextType>;
  questionnaireQuestion?: QuestionnaireQuestionResolvers<ContextType>;
  questionnaire_mutation_response?: QuestionnaireMutationResponseResolvers<ContextType>;
  receipt?: ReceiptResolvers<ContextType>;
  reservationDashboardAccountAccessibleShop?: ReservationDashboardAccountAccessibleShopResolvers<ContextType>;
  role?: RoleResolvers<ContextType>;
  roleTablesTable?: RoleTablesTableResolvers<ContextType>;
  roleTablesTable_mutation_response?: RoleTablesTableMutationResponseResolvers<ContextType>;
  role_mutation_response?: RoleMutationResponseResolvers<ContextType>;
  salesBudgetAllocationSetting?: SalesBudgetAllocationSettingResolvers<ContextType>;
  salesBudgetAllocationSetting_mutation_response?: SalesBudgetAllocationSettingMutationResponseResolvers<ContextType>;
  scoreTypeQuestionAnswer?: ScoreTypeQuestionAnswerResolvers<ContextType>;
  serviceAdmin?: ServiceAdminResolvers<ContextType>;
  serviceChargeConfig?: ServiceChargeConfigResolvers<ContextType>;
  serviceChargeConfig_mutation_response?: ServiceChargeConfigMutationResponseResolvers<ContextType>;
  serviceChargeMeta?: ServiceChargeMetaResolvers<ContextType>;
  shop?: ShopResolvers<ContextType>;
  shopBusinessOperationHour?: ShopBusinessOperationHourResolvers<ContextType>;
  shopBusinessOperationHour_mutation_response?: ShopBusinessOperationHourMutationResponseResolvers<ContextType>;
  shopChoice?: ShopChoiceResolvers<ContextType>;
  shopClerk?: ShopClerkResolvers<ContextType>;
  shopClerk_mutation_response?: ShopClerkMutationResponseResolvers<ContextType>;
  shopClient?: ShopClientResolvers<ContextType>;
  shopClient_mutation_response?: ShopClientMutationResponseResolvers<ContextType>;
  shopCookingItemRole?: ShopCookingItemRoleResolvers<ContextType>;
  shopCookingItemRole_mutation_response?: ShopCookingItemRoleMutationResponseResolvers<ContextType>;
  shopDiscountType?: ShopDiscountTypeResolvers<ContextType>;
  shopDiscountType_mutation_response?: ShopDiscountTypeMutationResponseResolvers<ContextType>;
  shopInflowSourceTag?: ShopInflowSourceTagResolvers<ContextType>;
  shopInflowSourceTag_mutation_response?: ShopInflowSourceTagMutationResponseResolvers<ContextType>;
  shopMember?: ShopMemberResolvers<ContextType>;
  shopMenu?: ShopMenuResolvers<ContextType>;
  shopMenuCookingItem?: ShopMenuCookingItemResolvers<ContextType>;
  shopMenuCookingItem_mutation_response?: ShopMenuCookingItemMutationResponseResolvers<ContextType>;
  shopMenuKdDisplayTarget?: ShopMenuKdDisplayTargetResolvers<ContextType>;
  shopMenuKdDisplayTarget_mutation_response?: ShopMenuKdDisplayTargetMutationResponseResolvers<ContextType>;
  shopMenuKitchenRole?: ShopMenuKitchenRoleResolvers<ContextType>;
  shopMenuKitchenRole_mutation_response?: ShopMenuKitchenRoleMutationResponseResolvers<ContextType>;
  shopMenu_mutation_response?: ShopMenuMutationResponseResolvers<ContextType>;
  shopOptionKitchenRole?: ShopOptionKitchenRoleResolvers<ContextType>;
  shopOptionKitchenRole_mutation_response?: ShopOptionKitchenRoleMutationResponseResolvers<ContextType>;
  shopOrderableTimeTerm?: ShopOrderableTimeTermResolvers<ContextType>;
  shopOrderableTimeTerm_mutation_response?: ShopOrderableTimeTermMutationResponseResolvers<ContextType>;
  shopPaymentType?: ShopPaymentTypeResolvers<ContextType>;
  shopPaymentType_mutation_response?: ShopPaymentTypeMutationResponseResolvers<ContextType>;
  shopPlan?: ShopPlanResolvers<ContextType>;
  shopPlanChoice?: ShopPlanChoiceResolvers<ContextType>;
  shopPlanChoice_mutation_response?: ShopPlanChoiceMutationResponseResolvers<ContextType>;
  shopPlanKitchenRole?: ShopPlanKitchenRoleResolvers<ContextType>;
  shopPlanKitchenRole_mutation_response?: ShopPlanKitchenRoleMutationResponseResolvers<ContextType>;
  shopPlanOption?: ShopPlanOptionResolvers<ContextType>;
  shopPlanOptionKitchenRole?: ShopPlanOptionKitchenRoleResolvers<ContextType>;
  shopPlanOptionKitchenRole_mutation_response?: ShopPlanOptionKitchenRoleMutationResponseResolvers<ContextType>;
  shopPlanOption_mutation_response?: ShopPlanOptionMutationResponseResolvers<ContextType>;
  shopPlan_mutation_response?: ShopPlanMutationResponseResolvers<ContextType>;
  shop_mutation_response?: ShopMutationResponseResolvers<ContextType>;
  specificTimeSurchargeConfig?: SpecificTimeSurchargeConfigResolvers<ContextType>;
  specificTimeSurchargeConfig_mutation_response?: SpecificTimeSurchargeConfigMutationResponseResolvers<ContextType>;
  specificTimeSurchargeMeta?: SpecificTimeSurchargeMetaResolvers<ContextType>;
  steraOnSitePaymentDetailType?: SteraOnSitePaymentDetailTypeResolvers<ContextType>;
  steraOnSitePaymentDetailType_mutation_response?: SteraOnSitePaymentDetailTypeMutationResponseResolvers<ContextType>;
  stock?: StockResolvers<ContextType>;
  stock_mutation_response?: StockMutationResponseResolvers<ContextType>;
  subscription_root?: SubscriptionRootResolvers<ContextType>;
  supportedLocale?: SupportedLocaleResolvers<ContextType>;
  table?: TableResolvers<ContextType>;
  tableArea?: TableAreaResolvers<ContextType>;
  tableArea_aggregate?: TableAreaAggregateResolvers<ContextType>;
  tableArea_aggregate_fields?: TableAreaAggregateFieldsResolvers<ContextType>;
  tableArea_avg_fields?: TableAreaAvgFieldsResolvers<ContextType>;
  tableArea_max_fields?: TableAreaMaxFieldsResolvers<ContextType>;
  tableArea_min_fields?: TableAreaMinFieldsResolvers<ContextType>;
  tableArea_mutation_response?: TableAreaMutationResponseResolvers<ContextType>;
  tableArea_stddev_fields?: TableAreaStddevFieldsResolvers<ContextType>;
  tableArea_stddev_pop_fields?: TableAreaStddevPopFieldsResolvers<ContextType>;
  tableArea_stddev_samp_fields?: TableAreaStddevSampFieldsResolvers<ContextType>;
  tableArea_sum_fields?: TableAreaSumFieldsResolvers<ContextType>;
  tableArea_var_pop_fields?: TableAreaVarPopFieldsResolvers<ContextType>;
  tableArea_var_samp_fields?: TableAreaVarSampFieldsResolvers<ContextType>;
  tableArea_variance_fields?: TableAreaVarianceFieldsResolvers<ContextType>;
  tableUser?: TableUserResolvers<ContextType>;
  tableUserCustomer?: TableUserCustomerResolvers<ContextType>;
  table_aggregate?: TableAggregateResolvers<ContextType>;
  table_aggregate_fields?: TableAggregateFieldsResolvers<ContextType>;
  table_avg_fields?: TableAvgFieldsResolvers<ContextType>;
  table_max_fields?: TableMaxFieldsResolvers<ContextType>;
  table_min_fields?: TableMinFieldsResolvers<ContextType>;
  table_mutation_response?: TableMutationResponseResolvers<ContextType>;
  table_stddev_fields?: TableStddevFieldsResolvers<ContextType>;
  table_stddev_pop_fields?: TableStddevPopFieldsResolvers<ContextType>;
  table_stddev_samp_fields?: TableStddevSampFieldsResolvers<ContextType>;
  table_sum_fields?: TableSumFieldsResolvers<ContextType>;
  table_var_pop_fields?: TableVarPopFieldsResolvers<ContextType>;
  table_var_samp_fields?: TableVarSampFieldsResolvers<ContextType>;
  table_variance_fields?: TableVarianceFieldsResolvers<ContextType>;
  taxOffice?: TaxOfficeResolvers<ContextType>;
  tecAggregationConfig?: TecAggregationConfigResolvers<ContextType>;
  tecAggregationConfig_mutation_response?: TecAggregationConfigMutationResponseResolvers<ContextType>;
  tecAggregationMediaMap?: TecAggregationMediaMapResolvers<ContextType>;
  tecAggregationMediaMap_mutation_response?: TecAggregationMediaMapMutationResponseResolvers<ContextType>;
  tecAggregationMenu?: TecAggregationMenuResolvers<ContextType>;
  tecAggregationMenu_mutation_response?: TecAggregationMenuMutationResponseResolvers<ContextType>;
  tecAggregationOnSitePaymentDetailType?: TecAggregationOnSitePaymentDetailTypeResolvers<ContextType>;
  tecAggregationOnSitePaymentDetailType_mutation_response?: TecAggregationOnSitePaymentDetailTypeMutationResponseResolvers<ContextType>;
  textTypeQuestionAnswer?: TextTypeQuestionAnswerResolvers<ContextType>;
  time?: GraphQLScalarType;
  timestamptz?: GraphQLScalarType;
  timetz?: GraphQLScalarType;
  translation?: TranslationResolvers<ContextType>;
  translation_mutation_response?: TranslationMutationResponseResolvers<ContextType>;
  uuid?: GraphQLScalarType;
  winboardConfig?: WinboardConfigResolvers<ContextType>;
  winboardConfig_mutation_response?: WinboardConfigMutationResponseResolvers<ContextType>;
  winboardInflowSourceTag?: WinboardInflowSourceTagResolvers<ContextType>;
  winboardInflowSourceTag_mutation_response?: WinboardInflowSourceTagMutationResponseResolvers<ContextType>;
  winboardMenu?: WinboardMenuResolvers<ContextType>;
  winboardMenu_mutation_response?: WinboardMenuMutationResponseResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  asScalar?: AsScalarDirectiveResolver<any, any, ContextType>;
  cached?: CachedDirectiveResolver<any, any, ContextType>;
};
